import { Box, BoxProps, Center, HStack, keyframes } from '@chakra-ui/react'

const animation = keyframes({
  '0%': {
    opacity: 0.4,
    transform: 'scale(1, 1)',
  },
  '50%': {
    opacity: 1,
    transform: 'scale(1.2, 1.2)',
  },
  '100%': {
    opacity: 0.4,
    transform: 'scale(1, 1)',
  },
})

const baseStyle = (dotColor: BoxProps['bgColor']): BoxProps => ({
  animation: `${animation} 1.5s infinite ease-in-out`,
  borderRadius: 'full',
  boxSize: '8px',
  bgColor: dotColor,
})

type Props = {
  dotColor?: BoxProps['bgColor']
}

export const LoadingDots: React.FC<Props> = ({ dotColor = 'blue.500' }) => {
  return (
    <Center boxSize="full">
      <HStack spacing="2">
        <Box {...baseStyle(dotColor)} />
        <Box {...baseStyle(dotColor)} sx={{ animationDelay: '0.5s' }} />
        <Box {...baseStyle(dotColor)} sx={{ animationDelay: '1s' }} />
      </HStack>
    </Center>
  )
}
