import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { SignAuthResult } from '../../types'
import { parseSignAuthResult } from '../parser'

const verifyAuthenticationCode = async ({
  trialUid,
  explanationSignAuthUid,
  authenticationCode,
}: {
  trialUid: string
  explanationSignAuthUid: string
  authenticationCode: string
}): Promise<SignAuthResult> => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/verify',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthUid,
      },
      body: {
        authenticationCode,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return parseSignAuthResult(res.data)
}

export const useVerifyAuthenticationCode = (
  options?: UseMutationOption<SignAuthResult>,
) => {
  return useMutation(
    ({
      trialUid,
      explanationSignAuthUid,
      authenticationCode,
    }: {
      trialUid: string
      explanationSignAuthUid: string
      authenticationCode: string
    }) =>
      verifyAuthenticationCode({
        trialUid,
        explanationSignAuthUid,
        authenticationCode,
      }),
    options,
  )
}
