import { DataType, SmallItem } from '../entities/small-item/entity'
import { SmallItemAnswer } from '../entities/small-item-answer/entity'

export const hasClinicalSmallItem = (
  smallItems: Array<SmallItem | SmallItemAnswer>,
) => {
  const isClinical = (si: SmallItem | SmallItemAnswer) =>
    si.dataType === DataType.Clinical

  return smallItems.some(isClinical)
}
