import React from 'react'

import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'

type Props = {
  onClose: () => void
  url: string
}

export const ExportModalComponent: React.FC<Props> = props => {
  return (
    <Modal onClose={props.onClose} isOpen>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>エクスポート</ModalHeader>

        <ModalBody>
          <Stack spacing="8">
            <Text>エクスポートが完了しました。</Text>
            <Link download href={props.url} color="blue.500">
              ダウンロード
            </Link>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" onClick={props.onClose}>
            戻る
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
