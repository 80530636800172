import { Dictionary } from 'lodash'
import { Choice } from 'src/modules/entities/choice/entity'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { Role } from 'src/modules/entities/member/entity'
import {
  DataType,
  ItemType,
  SmallItem,
} from 'src/modules/entities/small-item/entity'
import { fixtures as templateFixtures } from 'src/modules/entities/template/fixture'
import {
  parse as parseTemplate,
  TemplateResponse,
} from 'src/modules/entities/template/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { hasChoice } from '../../../../../../util/item-type-classification'

type TemplateParams = {
  title: string
  video_enabled: boolean

  medium_items: MediumItemParams[]
}

export const update = async ({
  uid,
  trialUid,
  title,
  videoEnabled,
  mediumItems,
}: {
  uid: string
  trialUid: string
  title: string
  videoEnabled: boolean
  mediumItems: MediumItem[]
}) => {
  if (IS_MOCK_MODE) {
    return templateFixtures.find(t => t.uid === uid) || templateFixtures[0]
  }

  const params = toParams(title, videoEnabled, mediumItems)

  const res = await requestPut<TemplateResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${uid}`,
    params: params,
  })

  return parseTemplate(res.data)
}

type MediumItemParams = {
  uid: string
  title: string
  folder_name: string
  form_name: string
  index: number
  is_logline: boolean

  small_items: SmallItemParams[]
}

type SmallItemParams = {
  uid: string
  title: string
  value: string | null
  description: string
  data_type: DataType
  item_type: ItemType
  index: number
  input_roles: Role[]
  unit: string

  // small_itemのDataTypeをOperationalからClinicalに変更した場合、
  // 下の階層にあるsmall_itemのdata_typeを変更する処理がないのでOperationalの場合もEDC系の値を送る。
  edc_data_item: string

  choices: ChoiceParams[]
  is_certified_copy: boolean
}

type ChoiceParams = {
  uid: string
  description: string
  index: number
  edc_value?: string
  edc_index?: number
  next_small_items: SmallItemParams[]
}

const choiceToParams = (
  c: Choice,
  itemType: ItemType,
  dataType: DataType,
  index: number,
  siMap: Dictionary<SmallItem>,
): ChoiceParams => {
  const params: ChoiceParams = {
    uid: c.uid,
    description: c.description,
    index: index,
    next_small_items: [],
    edc_value: c.edcValue,
  }

  params.next_small_items = c.smallItemUids.map((sUid, nsIndex) =>
    smallItemToParams(siMap[sUid], dataType, siMap, nsIndex),
  )

  return params
}

const smallItemToParams = (
  si: SmallItem,
  dataType: DataType,
  siMap: Dictionary<SmallItem>,
  index?: number,
): SmallItemParams => {
  const params: SmallItemParams = {
    uid: si.uid,
    title: si.title,
    value: si.value,
    description: si.description,
    data_type: si.dataType,
    item_type: si.itemType,
    index: index !== undefined ? index : si.index,
    input_roles: si.inputRoles,
    unit: si.unit,
    edc_data_item: si.edcDataItem,
    choices: [],
    is_certified_copy:
      si.itemType === ItemType.File && (si.isCertifiedCopy ?? false),
  }

  params.choices = hasChoice(si)
    ? si.choices.map((c, i) =>
        choiceToParams(c, si.itemType, dataType, i, siMap),
      )
    : []

  return params
}

const mediumItemToParams = (m: MediumItem, index: number): MediumItemParams => {
  const rootSmallItems = Object.values(m.smallItemMap).filter(
    s => s.parentUid === null,
  )

  const params: MediumItemParams = {
    uid: m.uid,
    title: m.title,
    folder_name: m.folderName,
    form_name: m.formName,
    is_logline: m.isLogline,
    index,
    small_items: [],
  }

  params.small_items = rootSmallItems.map(si =>
    smallItemToParams(si, si.dataType, m.smallItemMap),
  )

  return params
}

// フロントでは normalize して持っている medium item, small item を、API が期待する JSON 形式にする
const toParams = (
  title: string,
  videoEnabled: boolean,
  mediumItems: MediumItem[],
): TemplateParams => {
  return {
    title,
    video_enabled: videoEnabled,
    medium_items: mediumItems.map((m, i) => mediumItemToParams(m, i)),
  }
}
