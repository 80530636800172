import React, { useMemo, useState } from 'react'

import {
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList,
  MenuItemProps,
} from '@chakra-ui/react'
import { Menu as MenuIcon } from 'src/components/icon'
import { PERMISSIONS } from 'src/lib/permission'
import { TemplateStatus } from 'src/modules/entities/template/entity'

import { DeleteContainer } from './delete/delete-container'
import { DisableContainer } from './disable/disable-container'
import { EnableContainer } from './enable/enable-container'
import { ExportContainer } from './export/export-container'
import { FolderMapModalContainer } from './folder-map/folder-map-container'
import { RoleMapModalContainer } from './role-map/role-map-cotainer'
import { VisibilityModalContainer } from './visibility/visibility-modal-container'
import { VisibilityBulkSetModalContainer } from './visibility-bulk-set/visibility-bulk-set-modal-container'
import { VisibilitySetModalContainer } from './visibility-set/visibility-set-modal-container'
import { useCloseModalByDeps } from '../../../../../../../../hooks/use-close-modal-by-deps'
import { usePermission } from '../../../../common/permission'

type ModalType =
  | 'Export'
  | 'RoleMap'
  | 'FolderMap'
  | 'Visibility'
  | 'VisibilitySet' // Visibility Modal から開くモーダル。Visibility Modal と Modal 同士で遷移するためここで定義している。
  | 'VisibilityBulkSet' // Visibility Modal から開くモーダル。Visibility Modal と Modal 同士で遷移するためここで定義している。
  | 'Delete'
  | 'Disable'
  | 'Enable'

const useOpenModal = () => {
  /**
   * modalType: 「その他の操作」 のドロップダウンから開くモーダルの種類
   * modalType === null は、どのモーダルも開いていないことを意味する。
   */
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [smallItemUid, setSmallItemUid] = useState<string>('')

  const handlers = useMemo(
    () => ({
      closeModal: () => {
        setModalType(null)
      },
      openExportModal: () => {
        setModalType('Export')
      },
      openRoleMapModal: () => {
        setModalType('RoleMap')
      },
      openFolderMapModal: () => {
        setModalType('FolderMap')
      },
      openVisibilityModal: () => {
        setModalType('Visibility')
      },
      openVisibilitySetModalFactory: (smallItemUid: string) => () => {
        setSmallItemUid(smallItemUid)
        setModalType('VisibilitySet')
      },
      openVisibilityBulkSetModal: () => {
        setModalType('VisibilityBulkSet')
      },
      openDeleteModal: () => {
        setModalType('Delete')
      },
      openDisableModal: () => {
        setModalType('Disable')
      },
      openEnableModal: () => {
        setModalType('Enable')
      },
    }),
    [],
  )

  return { modalType, smallItemUid, handlers }
}

type Props = {
  status: TemplateStatus
  templateUid: string
  v2: boolean
}

type Item = {
  name: string
  onClick: () => void
  color?: MenuItemProps['color']
}

export const PulldownContainer: React.FC<Props> = props => {
  const {
    modalType,
    smallItemUid,
    handlers: {
      closeModal,
      openExportModal,
      openRoleMapModal,
      openFolderMapModal,
      openVisibilityModal,
      openVisibilitySetModalFactory,
      openVisibilityBulkSetModal,
      openDeleteModal,
      openDisableModal,
      openEnableModal,
    },
  } = useOpenModal()

  const { hasPermission } = usePermission()

  useCloseModalByDeps({
    condition: modalType !== null,
    onClose: closeModal,
    deps: [props.templateUid],
  })

  const items: Item[] = [
    ...(hasPermission(PERMISSIONS.Template_Export)
      ? [
          {
            name: 'エクスポート',
            onClick: openExportModal,
          },
        ]
      : []),
    ...(!props.v2
      ? [
          {
            name: 'フィールド×ロール対応表',
            onClick: openRoleMapModal,
          },
          {
            name: 'セクション×EDCフォルダ名',
            onClick: openFolderMapModal,
          },
        ]
      : []),
    ...(!props.v2 && hasPermission(PERMISSIONS.Template_SetHospitalsWithFields)
      ? [
          {
            name: 'フィールド別医療機関設定',
            onClick: openVisibilityModal,
          },
        ]
      : []),
    ...(hasPermission(PERMISSIONS.Template_Delete) &&
    props.status !== TemplateStatus.Released &&
    props.status !== TemplateStatus.Disabled
      ? [
          {
            name: 'テンプレートを削除',
            onClick: openDeleteModal,
            color: 'red.500',
          },
        ]
      : []),
    ...(hasPermission(PERMISSIONS.Template_Disable) &&
    props.status === TemplateStatus.Released
      ? [
          {
            name: 'テンプレートを無効化',
            onClick: openDisableModal,
            color: 'red.500',
          },
        ]
      : []),
    ...(hasPermission(PERMISSIONS.Template_Enable) &&
    props.status === TemplateStatus.Disabled
      ? [
          {
            name: 'テンプレートを有効化',
            onClick: openEnableModal,
          },
        ]
      : []),
  ]

  if (items.length === 0) return null

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<MenuIcon />}
          variant="customIconButtonGhost"
          aria-label="template-menu"
        />
        <MenuList>
          {items.map(item => (
            <MenuItem key={item.name} onClick={item.onClick} color={item.color}>
              {item.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {modalType === `Export` && <ExportContainer onClose={closeModal} />}
      {modalType === `RoleMap` && (
        <RoleMapModalContainer onClose={closeModal} />
      )}
      {modalType === 'FolderMap' && (
        <FolderMapModalContainer
          onClose={closeModal}
          templateUid={props.templateUid}
        />
      )}
      {modalType === 'Visibility' && (
        <VisibilityModalContainer
          onClose={closeModal}
          onOpenVisibilitySetModalFactory={openVisibilitySetModalFactory}
          onOpenVisibilityBulkSetModal={openVisibilityBulkSetModal}
        ></VisibilityModalContainer>
      )}
      {modalType === 'VisibilityBulkSet' && (
        <VisibilityBulkSetModalContainer
          onClose={openVisibilityModal}
        ></VisibilityBulkSetModalContainer>
      )}
      {modalType === 'VisibilitySet' && (
        <VisibilitySetModalContainer
          smallItemUid={smallItemUid}
          onClose={openVisibilityModal}
        ></VisibilitySetModalContainer>
      )}
      {modalType === 'Delete' && <DeleteContainer onClose={closeModal} />}
      {modalType === 'Disable' && <DisableContainer onClose={closeModal} />}
      {modalType === 'Enable' && <EnableContainer onClose={closeModal} />}
    </>
  )
}
