import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EXPLANATION_QUERY_STORAGE_KEY } from 'src/features/explanation/hooks/useExplanationsQueryParams'
import { useLocalStorage } from 'src/hooks/use-local-storage'
import { actions as logoutActions } from 'src/modules/auth/logout/redux'
import { RootState } from 'src/modules/reducer'
import { routes } from 'src/modules/routes'

export const useLogout = () => {
  const dispatch = useDispatch()
  const { requesting } = useSelector((state: RootState) => state.auth.logout)
  const { resetData } = useLocalStorage(EXPLANATION_QUERY_STORAGE_KEY)
  const navigate = useNavigate()

  const handleLogout = async () => {
    if (requesting) return

    await Auth.signOut()
    dispatch(logoutActions.submit({}))
    navigate(routes.login)

    resetData()
  }

  return { requesting, handleLogout }
}
