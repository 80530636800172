import { useParams } from 'react-router-dom'

import { Video } from './video'

export const videoKeyParamName = 'videoKey'

// FIXME: 未使用っぽいので削除する
export const VideoWithKeyContainer = () => {
  const { videoKey = '' } = useParams<{
    [videoKeyParamName]: string
  }>()

  const videoStreamUrl = `${
    import.meta.env.VITE_WEB_DOMAIN
  }/video/general/${videoKey}.m3u8`

  return <Video videoStreamUrl={videoStreamUrl} />
}
