import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Warning } from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { useModal } from 'src/hooks/use-modal'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import {
  finishExplanationSession,
  useExplanation,
  useUpdateExplanation,
} from '../../api'
import { ExplanationFormSchema } from '../../schema/explanationFormSchema'
import { Explanation, ExplanationDetail } from '../../types'
import { isSignStarted } from '../../utils/isSignStarted'
import { schemaToUpdateInput } from '../../utils/schemaToUpdateInput'
import { ExplanationForm } from '../ExplanationForm/ExplanationForm'

type Param = {
  explanationUid: Explanation['uid']
}

export const EditExplanationScreen: React.FC = () => {
  const { explanationUid = '' } = useParams<Param>()

  const { data: explanation } = useExplanation({
    explanationUid,
  })

  const toast = useMirohaToast()

  const navigate = useNavigate()

  const { selectedTrial } = useSelectedTrial()

  const { request: updateExplanation } = useUpdateExplanation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '説明情報を更新しました',
      })
      navigate(
        generatePath(
          generatePath(Paths.Explanation, {
            trialUid: selectedTrial.uid,
            explanationUid,
          }),
        ),
      )
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const { showModal } = useModal()

  const updateExplanationAndFinishSession = async (
    schema: ExplanationFormSchema,
    explanation: ExplanationDetail,
  ) => {
    if (explanation.latestRevision.latestSessionUid === undefined) return
    // セッションを終了できずに説明の変更を反映すると説明ルームに変更内容が反映されないため、
    // セッションの終了が成功した場合のみ変更を反映する
    try {
      await finishExplanationSession({
        explanationSessionUid: explanation.latestRevision.latestSessionUid,
      })
    } catch (error) {
      toast({
        status: 'error',
        title: error.message,
      })
      return
    }
    await updateExplanation({
      explanationRevisionUid: explanation.latestRevision.uid,
      input: schemaToUpdateInput(schema),
    })
  }
  if (!explanation) return null

  return (
    <Box as="main">
      <ExplanationForm
        formType="edit"
        explanation={explanation}
        onSubmit={async schema => {
          // セッションが終了していない、かつ有効期限内である場合、セッションを閉じて良いか確認する
          const shouldFinishSession =
            explanation.latestRevision.isLatestSessionFinished === false &&
            !explanation.latestRevision.isPreSessionExpired
          if (
            !isSignStarted(explanation.latestRevision.status) &&
            shouldFinishSession
          ) {
            showModal({
              title: (
                <HStack color="red.500">
                  <Warning />
                  <Text>説明が開始されています</Text>
                </HStack>
              ),
              content: (
                <Stack spacing="1">
                  <Text>現在、患者への説明を実施中です。</Text>
                  <Text>
                    更新内容を反映するには実施中の説明を一度終了し、再開する必要があります。
                  </Text>
                  <Text>今すぐ説明を終了して更新を反映しますか？</Text>
                </Stack>
              ),
              submitText: '説明を終了して内容更新する',
              submitButtonColor: 'red',
              size: '2xl',
              onSubmit: async () => {
                await updateExplanationAndFinishSession(schema, explanation)
              },
            })
          } else {
            await updateExplanation({
              explanationRevisionUid: explanation.latestRevision.uid,
              input: schemaToUpdateInput(schema),
            })
          }
        }}
      />
    </Box>
  )
}
