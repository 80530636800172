import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const sendSMS = async ({
  trialUid,
  phoneNumber,
}: {
  trialUid: string
  phoneNumber: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPost<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/test_message`,
    params: {
      phone_number: phoneNumber,
    },
  })

  return {}
}

export const sendEmail = async ({
  trialUid,
  email,
}: {
  trialUid: string
  email: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPost<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/test_message_by_email`,
    params: {
      email: email,
    },
  })

  return {}
}
