import { useCallback } from 'react'

import { PatientStatus } from 'src/lib/gql-client'

import { usePatientQueryParams } from './usePatientsQueryParams'

export const useFilterPatients = () => {
  const { queryParamObject, setQuery } = usePatientQueryParams()

  const handleCheckStatus = useCallback(
    (value: PatientStatus) => {
      const nextStatuses = [...queryParamObject.statuses]
      const idx = nextStatuses.indexOf(value)
      if (idx !== -1) {
        nextStatuses.splice(idx, 1)
      } else {
        nextStatuses.push(value)
      }
      setQuery({
        type: 'statuses',
        value: nextStatuses,
      })
    },
    [setQuery, queryParamObject.statuses],
  )

  const handleChangeHospital = useCallback(
    (hospitalUid: string | undefined) => {
      setQuery({ type: 'trialHospital', value: hospitalUid })
    },
    [setQuery],
  )

  const handleChangeRelatedHospital = useCallback(
    (value: string | undefined) => {
      // 未選択 => 全て
      if (value === undefined) {
        setQuery({ type: 'relatedHospital', value: { type: 'Any' } })
        return
      }

      // OnlyNotSet => relatedHospitalが存在しないもの
      if (value === 'OnlyNotSet') {
        setQuery({ type: 'relatedHospital', value: { type: 'OnlyNotSet' } })
        return
      }

      // その他 => uidが一致するもの
      setQuery({ type: 'relatedHospital', value: { type: 'WithUid', value } })
    },
    [setQuery],
  )

  return {
    handleCheckStatus,
    handleChangeHospital,
    handleChangeRelatedHospital,
    statuses: queryParamObject.statuses,
    trialHospitalUid: queryParamObject.trialHospitalUid,
    relatedHospitalUid: queryParamObject.relatedHospitalUid,
    relatedHospitalType: queryParamObject.relatedHospitalType,
  }
}
