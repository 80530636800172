import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { RootState } from 'src/modules/reducer'
import { hasChoice } from 'src/modules/util/answer-type-classification'

import {
  actions,
  ChoiceValidationError,
  FieldValidationError,
  SectionValidationError,
  ValidationError,
} from '../redux'

const hasChoiceErrors = (err: ChoiceValidationError): boolean =>
  [err.description, err.value].some(x => !!x)

const hasFieldErrors = (err: FieldValidationError): boolean =>
  [err.title, err.choiceSize, err.choices].some(x => !!x)

const hasSectErrors = (err: SectionValidationError): boolean =>
  [err.title, err.fieldSize, err.fields].some(x => !!x)

const hasErrors = (err: ValidationError): boolean =>
  [err.title, err.displayName, err.sectionSize, err.sections].some(x => !!x)

export const useValidate = () => {
  const { showError } = useFlash()
  const dispatch = useDispatch()

  const questionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail,
  )
  const { title, displayName, sections } = questionnaire

  const validate = useCallback(
    ({ onValid }: { onValid: () => void }) => {
      const err: ValidationError = {}

      if (!title) {
        err.title = '質問票名は必須です。'
      }

      if (!displayName) {
        err.displayName = '患者表示名は必須です。'
      }

      if (sections.length === 0) {
        err.sectionSize = 'ページを追加してください。'
      }

      for (const s of sections) {
        const sectErr: SectionValidationError = { id: s.uid }

        if (!s.title) {
          sectErr.title = 'ページ名は必須です。'
        }

        if (Object.values(s.fieldMap).length === 0) {
          sectErr.fieldSize = '質問を追加してください。'
        }

        for (const f of Object.values(s.fieldMap)) {
          const fieldErr: FieldValidationError = { id: f.uid }

          if (!f.title) {
            fieldErr.title = '質問名は必須です。'
          }

          if (hasChoice(f)) {
            if (f.choices.length === 0) {
              fieldErr.choiceSize = '選択肢を追加してください。'
            }

            for (const c of f.choices) {
              const choiceErr: ChoiceValidationError = { id: c.uid }

              if (!c.description) {
                choiceErr.description = '選択肢の説明は必須です。'
              }

              if (!c.value) {
                choiceErr.value = '選択肢の値は必須です。'
              }

              if (hasChoiceErrors(choiceErr)) {
                if (!fieldErr.choices) {
                  fieldErr.choices = []
                }
                fieldErr.choices.push(choiceErr)
              }
            }
          }

          if (hasFieldErrors(fieldErr)) {
            if (!sectErr.fields) {
              sectErr.fields = []
            }
            sectErr.fields.push(fieldErr)
          }
        }
        if (hasSectErrors(sectErr)) {
          if (!err.sections) {
            err.sections = []
          }
          err.sections.push(sectErr)
        }
      }
      if (hasErrors(err)) {
        showError('入力内容に誤りがあります。')
        dispatch(actions.updateValidationError({ err }))
        return
      }

      onValid()
    },
    [dispatch, displayName, sections, showError, title],
  )

  return {
    validate,
  }
}
