import { Button, Center, HStack, IconButton } from '@chakra-ui/react'
import { RoomSessionStatus } from '@micin-jp/call-lib-core'
import { useLocalPlayer, useRoomSession } from '@micin-jp/call-lib-react'
import { CameraOff, CameraOn, MicOff, MicOn } from 'src/components/icon'

export const VideoCallRoomHeader: React.FC = () => {
  const { sessionStatus, leaveRoom } = useRoomSession()

  const {
    toggleMicOnOff,
    toggleCameraOnOff,
    localCameraEnabled,
    localMicEnabled,
  } = useLocalPlayer()

  return (
    <Center as="header" h="full" bg="gray.900" color="white">
      <HStack spacing="12">
        <Button
          colorScheme="red"
          isDisabled={sessionStatus !== RoomSessionStatus.Incall}
          h="48px"
          onClick={async () => {
            await leaveRoom()
          }}
        >
          通話終了
        </Button>
        <HStack spacing="6">
          <IconButton
            colorScheme={localMicEnabled ? 'blue' : 'red'}
            w="48px"
            h="48px"
            variant="customIconButtonSolid"
            icon={localMicEnabled ? <MicOn size="lg" /> : <MicOff size="lg" />}
            aria-label=""
            onClick={() => toggleMicOnOff()}
          />
          <IconButton
            colorScheme={localCameraEnabled ? 'blue' : 'red'}
            w="48px"
            h="48px"
            variant="customIconButtonSolid"
            icon={
              localCameraEnabled ? (
                <CameraOn size="lg" />
              ) : (
                <CameraOff size="lg" />
              )
            }
            aria-label=""
            onClick={toggleCameraOnOff}
          />
        </HStack>
      </HStack>
    </Center>
  )
}
