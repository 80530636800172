import { extendTheme } from '@chakra-ui/react'

import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  FormLabel,
  Input,
  Radio,
  Menu,
  Modal,
  Table,
  Tabs,
  Text,
  Textarea,
} from './components'
import { colors } from './foundations/colors'
import { semanticTokens } from './semanticTokens'
import { styles } from './styles'

export const theme = extendTheme({
  styles,
  colors,
  semanticTokens,
  shadows: {
    outline: '0 0 0 2px #336FCC', // blue.500
    card: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  },

  components: {
    Alert,
    Button,
    Checkbox,
    Drawer,
    FormLabel,
    Input,
    Radio,
    Menu,
    Modal,
    Table,
    Tabs,
    Text,
    Textarea,

    // :focus 時のoutlineを消すための暫定的な対応
    // :focus-visible（キーボード操作）のみshadowを有効にする
    Accordion: {
      baseStyle: {
        button: {
          _focus: { boxShadow: 'none' },
          ':focus-visible': { boxShadow: 'outline' },
        },
      },
    },
    Link: {
      baseStyle: {
        _focus: { boxShadow: 'none' },
        ':focus-visible': { boxShadow: 'outline' },
      },
    },
  },
})
