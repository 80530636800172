import { ChoiceAnswer } from './entity'

export type ChoiceAnswerResponse = {
  uid: string
  small_item_answer_uid: string
  next_small_item_answer_uids: string[]
  choice_uid: string
  description: string
  checked: boolean
  index: number
}

export const parse = (resp: ChoiceAnswerResponse): ChoiceAnswer => {
  return {
    uid: resp.uid,
    description: resp.description,
    checked: resp.checked,

    smallItemAnswerUids: resp.next_small_item_answer_uids || [],
    choiceUid: resp.choice_uid,
    smallItemAnswerUid: resp.small_item_answer_uid,
  }
}
