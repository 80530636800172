import React from 'react'

import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import { Message } from 'src/components/base/message/message'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { PullDown } from 'src/components/layout/pulldown'
import { Spacer } from 'src/components/spacer/spacer'
import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import styled from 'styled-components'

import { IcfDocumentForList } from '../../icf-document/entity'
import { SortableMultiSelect } from '../components/sortable-multi-select'
import { CreateIcfDocumentSetItem } from '../entity'
import { IcfDocumentSetErrors } from '../validate'

type Props = {
  createIcfDocumentSetItem: CreateIcfDocumentSetItem
  approvedIcfDocuments: IcfDocumentForList[] | undefined
  listPath: string
  onChangeName: (value: string) => void
  onChangeSelectedDocuments: (
    items: CreateIcfDocumentSetItem['icfDocuments'],
  ) => void
  canSubmit: boolean
  onSubmit: () => Promise<void>
  pullDownState: ReturnType<typeof useOpenPullDown>
  onCancel: () => void
  errors: IcfDocumentSetErrors
}

export const AddIcfDocumentSet: React.FC<Props> = ({
  createIcfDocumentSetItem,
  approvedIcfDocuments,
  listPath,
  onChangeName,
  onChangeSelectedDocuments,
  canSubmit,
  onSubmit,
  pullDownState,
  onCancel,
  errors,
}) => {
  const breadCrumbParams: BreadcrumbParam[] = [
    { label: '文書セット一覧', isLink: true, path: listPath },
    { label: '文書セット登録', isLink: false },
  ]

  return (
    <Wrapper>
      <Breadcrumb breadcrumbParams={breadCrumbParams} />
      <Spacer size={8} />
      <ButtonContainer>
        <Button
          size="S"
          text="登録"
          buttonType="important"
          onClick={onSubmit}
          disabled={!canSubmit}
        />
        <Spacer size={16} horizontal />
        <PullDown
          pulldownOpen={pullDownState.pullDownOpen}
          closePulldown={pullDownState.handlers.closePullDown}
          items={[
            { name: 'キャンセル', onClick: onCancel, fontColor: red[40] },
          ]}
        >
          <IconButton
            iconName="expand3"
            onClick={pullDownState.handlers.openPullDown}
          />
        </PullDown>
      </ButtonContainer>
      <Spacer size={32} />
      <Content>
        <div>
          <Label bold>文書セット名</Label>
          <Spacer size={8} />
          <Input
            maxWidth={400}
            value={createIcfDocumentSetItem.name}
            onChange={e => onChangeName(e.target.value)}
          />
          {!!errors.name && (
            <>
              <Spacer size={8} />
              <Message type="error" message={errors.name} />
            </>
          )}
        </div>
        <Spacer size={32} />
        <div>
          {approvedIcfDocuments !== undefined && (
            <SortableMultiSelect
              icfDocuments={approvedIcfDocuments}
              onChangeSelectedDocuments={onChangeSelectedDocuments}
              selectedIcfDocuments={createIcfDocumentSetItem.icfDocuments}
              errorMessage={errors.documents}
            />
          )}
        </div>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Content = styled.div``
