import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const challengePatientVideoCallHandler = restHandlerFactory(
  '/trials/{trialUid}/patient_video_calls/{patientVideoCallUid}/challenge',
  'get',
  () => {
    return HttpResponse.json(
      {
        diseaseUid: 'disease_uid',
        newPatientVideoCallUid: generateUUID(),
        result: 'Authorized',
      },
      { status: 200 },
    )
  },
)
