import React from 'react'

import { Text } from '@chakra-ui/react'
import { red } from 'src/components/base/color/palette'

import { WithCommentModal } from './with-comment-modal'
import { IcfDocument, IcfDocumentRevision } from '../entity'

type Props = {
  onClose: () => void
  numberingId: IcfDocument['numberingId']
  documentName: IcfDocumentRevision['name']
  onSubmit: (comment: string) => void
}

export const ApproveModal: React.FC<Props> = ({
  onClose,
  numberingId,
  documentName,
  onSubmit,
}) => {
  return (
    <WithCommentModal
      title="承認"
      onClose={onClose}
      onSubmit={onSubmit}
      required={false}
      submitText="承認"
      submitButtonType="important"
      descriptionComponent={
        <div>
          <Text textAlign="center">
            <strong>{`文書ID:${numberingId} ${documentName}`}</strong>
            を承認します。
          </Text>
          <Text fontSize="sm" textAlign="center" color={red[50]}>
            ※承認後は文書セットに紐付けて下さい。
          </Text>
        </div>
      }
    />
  )
}
