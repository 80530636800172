import { Box, Button, Center, Stack, Text } from '@chakra-ui/react'
import { PatientSimpleScreenContainer } from 'src/components/PatientSimpleScreenContainer/PatientSimpleScreenContainer'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useResendSignAuthEmail } from '../../api/resendSignAuthEmail'

type Props = {
  explanationSignAuthUid: string
  trialUid: string
}

export const InvalidSignAuth: React.FC<Props> = ({
  explanationSignAuthUid,
  trialUid,
}) => {
  const toast = useMirohaToast()
  const { request: resendEmail } = useResendSignAuthEmail({
    onSuccess: () => {
      toast({
        title:
          'メールアドレス宛に署名用リンクを再送しました。メールをご確認下さい。',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  return (
    <PatientSimpleScreenContainer>
      <Stack spacing="8">
        <Center>
          <Text fontWeight="bold" fontSize="lg">
            リンクが無効です
          </Text>
        </Center>
        <Box>
          <Text>
            メールアドレスに有効なリンクを送信しました。
            <br />
            受信メールをご確認ください。
          </Text>
        </Box>

        <Center w="full">
          <Button
            variant="text"
            color="blue.500"
            onClick={async () => {
              await resendEmail({ trialUid, explanationSignAuthUid })
            }}
          >
            メールを再送
          </Button>
        </Center>
      </Stack>
    </PatientSimpleScreenContainer>
  )
}
