import { useCallback, useMemo, useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useApiClientFetch } from 'src/hooks/use-api-client-fetch'
import { useApiClientSubmit } from 'src/hooks/use-api-client-submit'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { usePane } from 'src/hooks/use-pane'
import { ApiResponse } from 'src/lib/api-client'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'

export const useIcfDocumentReAgreementConfirm = (
  icfDocumentList: ApiResponse<'ListICFDocumentsItem'>[] | undefined,
) => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { showInfo, showError } = useFlash()

  const [selectedDocUids, setSelectedDocUids] = useState<string[]>([])

  const isRequiredReAgreementConfirmDocuments = useMemo(() => {
    if (!icfDocumentList) return []
    return icfDocumentList.filter(doc => doc.isRequiredReAgreementConfirmation)
  }, [icfDocumentList])

  useEffect(() => {
    if (!isRequiredReAgreementConfirmDocuments) return
    const defaultSelectedUids = isRequiredReAgreementConfirmDocuments.map(
      doc => doc.uid,
    )
    setSelectedDocUids(defaultSelectedUids)
  }, [isRequiredReAgreementConfirmDocuments])

  const selectedDocuments = useMemo(() => {
    return isRequiredReAgreementConfirmDocuments.filter(doc =>
      selectedDocUids.includes(doc.uid),
    )
  }, [isRequiredReAgreementConfirmDocuments, selectedDocUids])

  const { mutate: mutateIcfDocuments } = useApiClientFetch({
    path: '/trials/{trial_uid}/trial_hospitals/{trial_hospital_uid}/icf_documents',
    shouldCancel: !selectedTrial.selectedTrialHospitalUid,
    params: {
      paths: {
        trial_uid: selectedTrial.uid,
        trial_hospital_uid: selectedTrial.selectedTrialHospitalUid!,
      },
    },
  })
  const { request: confirmReAgreements } = useApiClientSubmit({
    path: '/trials/{trial_uid}/explanation_reagreements',
    httpMethod: 'post',
    params: {
      paths: { trial_uid: selectedTrial.uid },
    },
    onSuccess: () => {
      showInfo('再同意確認処理が完了しました。', {
        description: '説明同意一覧で再同意対象(▲)の説明をご確認ください。',
      })
      mutateIcfDocuments()
    },
    onError: e => {
      showError(e.data.message)
    },
  })

  const {
    handlers: {
      openPane: openReAgreementConfirmPane,
      closePane: closeReAgreementConfirmPane,
    },
    renderPane: renderReAgreementConfirmPane,
  } = usePane({
    paneType: 'modal',
    closeOnOutsideClick: true,
  })

  const {
    handlers: {
      openModal: openReAgreementConfirmModal,
      closeModal: closeReAgreementConfirmModal,
    },
    modalOpen: reAgreementConfirmModalOpen,
  } = useOpenModal()

  const handleToggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedDocUids(docUids => [...docUids, e.target.value])
      } else {
        const docUids = selectedDocUids.filter(uid => uid !== e.target.value)
        setSelectedDocUids(docUids)
      }
    },
    [selectedDocUids],
  )

  const handleClosePane = useCallback(
    () => closeReAgreementConfirmPane(),
    [closeReAgreementConfirmPane],
  )

  const handleConfirm = useCallback(() => {
    closeReAgreementConfirmPane()
    openReAgreementConfirmModal()
  }, [closeReAgreementConfirmPane, openReAgreementConfirmModal])

  const handleCloseModal = useCallback(() => {
    closeReAgreementConfirmModal()
    openReAgreementConfirmPane()
  }, [closeReAgreementConfirmModal, openReAgreementConfirmPane])

  const handleSubmit = useCallback(async () => {
    await confirmReAgreements({ icfDocUids: selectedDocUids })
    closeReAgreementConfirmModal()
  }, [confirmReAgreements, closeReAgreementConfirmModal, selectedDocUids])

  return {
    isRequiredReAgreementConfirmDocuments,
    selectedDocUids,
    selectedDocuments,
    reAgreementConfirmModalOpen,
    openReAgreementConfirmPane,
    closeReAgreementConfirmPane,
    renderReAgreementConfirmPane,
    openReAgreementConfirmModal,
    closeReAgreementConfirmModal,
    handleToggle,
    handleClosePane,
    handleConfirm,
    handleCloseModal,
    handleSubmit,
  }
}
