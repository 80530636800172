import React from 'react'

import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { QuestionnaireChoice } from 'src/modules/entities/questionnaire-choice/entity'
import styled from 'styled-components'

type Props = {
  choice: QuestionnaireChoice
}

export const FixedQuestionnaireChoice: React.FC<Props> = ({ choice }) => {
  const { description, value } = choice
  return (
    <Container>
      <Input width={'280px'} value={description} disabled={true} />
      <Spacer size={16} horizontal />
      <Input width={'120px'} value={value} disabled={true} />
    </Container>
  )
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
