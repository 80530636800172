import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { actions as worksheetActions } from 'src/modules/entities/worksheet/redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { enableLogline } from './request'
import { patientIdParamName } from '../../../../patient-detail'
import { loglineIdParamName } from '../logline-detail/logline-detail'
import { worksheetIdParamName } from '../worksheet-detail'

type Props = {
  onClose: () => void
  lineNumber: number
  edcLinked: boolean
}

export const EnableModal: React.FC<Props> = props => {
  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
    loglineUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
    [loglineIdParamName]: string
  }>()
  const [inputValue, changeInputValue] = useState('')

  const { request, requesting, errorMessage } = useEnableLogline({
    trialUid,
    patientUid,
    worksheetUid,
    loglineUid,
    reason: inputValue,
    edcLinked: props.edcLinked,
  })

  const onSubmit = async () => {
    await request()
    props.onClose()
  }

  return (
    <SubmitModal
      title={`行データを有効化`}
      submitText={`有効化`}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      requesting={requesting}
      onClose={props.onClose}
      disabled={!inputValue}
    >
      <Text textAlign="center">{`Line${props.lineNumber}を有効化します`}</Text>
      <Spacer size={20} />
      <Label bold>有効化理由</Label>
      <Spacer size={10} />
      <Input
        placeholder={'有効化理由を入力してください'}
        onChange={e => changeInputValue(e.target.value)}
      />
    </SubmitModal>
  )
}

const useEnableLogline = ({
  trialUid,
  patientUid,
  worksheetUid,
  loglineUid,
  reason,
  edcLinked,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  loglineUid: string
  reason: string
  edcLinked: boolean
}) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState({
    withSpinner: edcLinked,
  })

  const dispatch = useDispatch()
  const { showSuccess, showError } = useFlash()

  const request = useCallback(async () => {
    try {
      requestStarted()
      await enableLogline({
        trialUid,
        patientUid,
        worksheetUid,
        loglineUid,
        reason,
      })

      showSuccess('行データを有効化しました。')
    } catch (error) {
      showError(error.message, { durationMsec: 30000, closable: true })
    }

    dispatch(
      worksheetActions.fetchSingle({ trialUid, patientUid, worksheetUid }),
    )

    requestDone()
  }, [
    dispatch,
    trialUid,
    patientUid,
    worksheetUid,
    loglineUid,
    reason,
    requestStarted,
    requestDone,
    showSuccess,
    showError,
  ])

  return {
    request,
    requesting,
    requestFailed,
    errorMessage,
  }
}
