import { Box, Flex } from '@chakra-ui/react'
import { generatePath, useParams } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { useBreadcrumb } from 'src/hooks/useBreadcrumb'
import { ChickenSchemaProvider } from 'src/lib/chicken-schema/atom'
import { parseSchema } from 'src/lib/chicken-schema/utils'
import { CriteriaDetailScreen_CriteriaDocument } from 'src/lib/gql-client'
import { useGqlQuerySWR } from 'src/lib/gql-client/request'
import { PERMISSIONS } from 'src/lib/permission'

import { CriteriaDetailMenu } from '../CriteriaDetailMenu/CriteriaDetailMenu'
import { CriteriaTransitionBlocker } from '../CriteriaTransitionBlocker/CriteriaTransitionBlocker'
import { JsonPreview } from '../JsonPreview/JsonPreview'
import { PreviewCriteriaButton } from '../PreviewCriteriaButton/PreviewCriteriaButton'
import { PublishCriteriaButton } from '../PublishCriteriaButton/PublishCriteriaButton'
import { ReadOnlySchema } from '../ReadOnlySchema/ReadOnlySchema'
import { SaveCriteriaButton } from '../SaveCriteriaButton/SaveCriteriaButton'
import { SchemaForm } from '../SchemaForm/SchemaForm'

type Param = {
  criteriaUid: string
}

export const CriteriaDetailScreen: React.FC = () => {
  const { criteriaUid } = useParams<Param>()
  const { hasPermission } = usePermission()
  const {
    account: { selectedTrial, selectedTrialHospitalUid, member },
  } = useAuthenticatedAccount()

  const {
    data: { criteria },
  } = useGqlQuerySWR(
    CriteriaDetailScreen_CriteriaDocument,
    criteriaUid ? { criteriaUid } : null,
  )

  const fetchedSchema = parseSchema(criteria.latestDetail.criteriaSchema.schema)
  const { status } = criteria.latestLog

  const { renderBreadcrumb } = useBreadcrumb()

  const showForm =
    !criteria.publishedAt &&
    hasPermission(PERMISSIONS.Criteria_Edit) &&
    ((member.role === 'DM' && criteria.belongAllTrialHospitals) ||
      (member.role !== 'DM' &&
        !!criteria.trialHospitalUid &&
        criteria.trialHospitalUid === selectedTrialHospitalUid))

  const mainHospitalName = criteria.trialHospital
    ? criteria.trialHospital.hospital.name
    : undefined

  return (
    <ChickenSchemaProvider fetchedSchema={fetchedSchema}>
      <CriteriaTransitionBlocker
        fetchedSchema={fetchedSchema}
        criteriaStatus={status}
      />
      <Flex as="main" direction="column" pt="6" gap={8} h="full">
        <Flex
          justify="space-between"
          align="center"
          wrap="wrap"
          rowGap={4}
          px="6"
        >
          {renderBreadcrumb([
            {
              link: generatePath(Paths.Criteria, {
                trialUid: selectedTrial.uid,
              }),
              text: '選択除外基準一覧',
            },
            {
              isCurrentPage: true,
              text: '選択除外基準設定',
            },
          ])}
          <Flex gap={6} justify="end" flex="1">
            {/* for debug */}
            {import.meta.env.DEV && <JsonPreview />}
            <PreviewCriteriaButton mainHospitalName={mainHospitalName} />
            {showForm && (
              <SaveCriteriaButton
                criteriaUid={criteria.criteriaUid}
                fetchedSchema={fetchedSchema}
              />
            )}
            {showForm && (
              <PublishCriteriaButton
                fetchedSchema={fetchedSchema}
                criteriaUid={criteria.criteriaUid}
                status={status}
              />
            )}
            <CriteriaDetailMenu criteria={criteria} />
          </Flex>
        </Flex>

        <Box flex="1" overflow="auto" px="6" pb="6">
          {showForm ? (
            <SchemaForm status={status} />
          ) : (
            <ReadOnlySchema status={status} schema={fetchedSchema} />
          )}
        </Box>
      </Flex>
    </ChickenSchemaProvider>
  )
}
