import React from 'react'

import { Route, Routes } from 'react-router-dom'
import { getRelativePath, PathKey } from 'src/constants/paths'
import { Authenticator } from 'src/features/auth/components'
import { HospitalExplanationRoom } from 'src/modules/dashboard/trial/detail/e-consent/explanation-room/hospital-explanation-room'

import { useSelectedTrial } from '../../auth/context'
import {
  BrowsingFlowScreen,
  DocumentViewerScreen,
  SignFlowScreen,
} from '../components'
import { HospitalDocumentSignScreen } from '../components/HospitalDocumentSignScreen/HospitalDocumentSignScreen'
import { HospitalLobbyScreen } from '../components/HospitalLobbyScreen/HospitalLobbyScreen'
import { HospitalSignCompletedScreen } from '../components/HospitalSignCompletedScreen/HospitalSignCompletedScreen'

export const HospitalExplanationRoomRoutes: React.FC = () => {
  return (
    <Authenticator>
      <RoomRoutes />
    </Authenticator>
  )
}

const RoomRoutes: React.FC = () => {
  const { selectedTrial } = useSelectedTrial()

  const getPath = (path: PathKey): string => {
    return getRelativePath('HospitalExplanationRoom', path)
  }

  return (
    <Routes>
      {selectedTrial.featureFlags.eConsentNewSignFlow ? (
        <>
          <Route
            path={getPath('HospitalExplanationRoomLobby')}
            element={<HospitalLobbyScreen />}
          />
          <Route
            path={getPath('HospitalExplanationRoomTop')}
            element={<BrowsingFlowScreen />}
          />
          <Route
            path={getPath('HospitalExplanationRoomDocument')}
            element={<DocumentViewerScreen isPartner={false} />}
          />
          <Route
            path={getPath('HospitalExplanationRoomSigning')}
            element={<SignFlowScreen />}
          />
          <Route
            path={getPath('HospitalExplanationRoomDocumentSign')}
            element={<HospitalDocumentSignScreen />}
          />
          <Route
            path={getPath('HospitalExplanationRoomSignCompleted')}
            element={<HospitalSignCompletedScreen />}
          />
        </>
      ) : (
        <Route path="*" element={<HospitalExplanationRoom />} />
      )}
    </Routes>
  )
}
