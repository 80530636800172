import { useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ModalContent,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Select } from 'src/components/Select/Select'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ExplanationRevisionStatusV2,
  ExplanationSession,
  ExpDocRevAgreementFormForSessionInSignFlowFragment,
} from 'src/lib/gql-client'

import { useRejectAgreement } from '../../../explanation/api/rejectAgreement'

type Props = {
  docRevisions: ExpDocRevAgreementFormForSessionInSignFlowFragment[]
  sessionUid: ExplanationSession['uid']
  status: ExplanationRevisionStatusV2
  mutateSession: () => void
}

export const RejectAgreementForRoom: React.FC<Props> = ({
  docRevisions,
  sessionUid,
  status,
  mutateSession,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { selectedTrial } = useSelectedTrial()

  const [reason, setReason] = useState('')

  const toast = useMirohaToast()

  const navigate = useNavigate()

  const [docRevisionUID, setDocRevisionUID] = useState<string>()

  const { request: rejectAgreement } = useRejectAgreement({
    onSuccess: () => {
      onClose()
      mutateSession()
      navigate(
        generatePath(Paths.HospitalExplanationRoomSigning, {
          trialUid: selectedTrial.uid,
          sessionUid,
        }),
      )
      toast({
        status: 'success',
        title: '同意を拒否しました',
      })
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  if (status === 'AgreementCompleted' || status === 'DeliveryCompleted') {
    return null
  }

  if (!docRevisions) {
    return null
  }

  const unRejectedAgreementForms = docRevisions.flatMap(docRev => {
    return docRev.__typename === 'ExplanationDocRevisionAgreementForm' &&
      docRev.signHistories.every(
        h => !h.isRejected && h.signerRole !== 'Patient',
      )
      ? docRev
      : []
  })

  const handleClose = () => {
    onClose()
    setReason('')
    setDocRevisionUID('')
  }

  if (unRejectedAgreementForms.length === 0) {
    return null
  }

  return (
    <>
      <Button colorScheme="red" variant="outline" onClick={onOpen}>
        同意拒否
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>同意拒否</ModalHeader>
          <ModalBody>
            <Stack spacing="4">
              <Text>同意文書について患者の同意拒否を記録します。</Text>
              <FormLabel m="0">同意書</FormLabel>
              <Select
                width="100%"
                value={docRevisionUID}
                items={unRejectedAgreementForms.map(d => ({
                  value: d.uid,
                  label: d.icfDocumentRevision.name,
                }))}
                onChange={uid => {
                  setDocRevisionUID(uid)
                }}
              />
              <FormControl>
                <FormLabel>コメント</FormLabel>
                {/* TODO: 必須ラベル */}
                <Input
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                  placeholder="同意拒否の理由を入力してください。"
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="red"
              isDisabled={!reason || !docRevisionUID}
              onClick={async () => {
                if (!reason) return
                if (!docRevisionUID) return
                await rejectAgreement({
                  explanationDocRevisionUid: docRevisionUID,
                  explanationSessionUid: sessionUid,
                  reason,
                })
              }}
            >
              同意拒否する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
