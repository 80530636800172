import { GetPatientReturn } from '../api'
import { PatientSchema } from '../schema'

export const patientToSchema = (patient: GetPatientReturn): PatientSchema => {
  return {
    trialHospitalUid: patient.trialHospital.uid,
    diseaseId: patient.diseaseUid,
    firstName: patient.firstName,
    lastName: patient.lastName,
    firstNameJa: patient.firstNameJa,
    lastNameJa: patient.lastNameJa,
    birthday: patient.birthday ?? undefined,
    gender: patient.gender,
    phoneNumber: patient.phoneNumber,
    email: patient.email,
    chartId: patient.chartUid,
    partnerTrialHospitalUid: patient.partnerTrialHospital?.uid,
  }
}
