import React, { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { ExplanationRoomTop } from './top'
import { getExplanationDetailRoute } from '../../explanation/routes'
import { SidebarContent } from '../components/sidebar'
import { ExplanationRoomLayout } from '../layout'
import { usePreviewDocSets, usePreviewExplanation } from '../preview-context'
import { getExplanationRoomDocumentPreviewRoute } from '../routes'

export const PreviewTopContainer: React.FC = () => {
  const {
    uid: trialUid,
    name: trialName,
    trialHospitals,
    selectedTrialHospitalUid,
  } = useSelector(getSelectedTrial)!

  const hospitalName = useMemo(() => {
    return trialHospitals.find(th => th.uid === selectedTrialHospitalUid)?.name
  }, [trialHospitals, selectedTrialHospitalUid])

  const { explanation } = usePreviewExplanation()

  const { docSets } = usePreviewDocSets()

  const navigate = useNavigate()

  const navigateToDocumentPage = useCallback(
    (docRevisionUid: string) => {
      if (!explanation) return
      navigate(
        getExplanationRoomDocumentPreviewRoute(
          trialUid,
          explanation.uid,
          docRevisionUid,
        ),
      )
    },
    [explanation, navigate, trialUid],
  )

  const navigateToExplanationDetail = useCallback(() => {
    if (!explanation) return
    navigate(
      getExplanationDetailRoute({ trialUid, explanationUid: explanation.uid }),
    )
  }, [explanation, navigate, trialUid])

  if (!explanation) {
    return null
  }

  return (
    <ExplanationRoomLayout
      headerTitle="説明ルーム（プレビュー）"
      onFinish={navigateToExplanationDetail}
      isPreview
      sidebarContent={
        <SidebarContent
          trialName={trialName}
          candidateId={explanation.candidateId}
          drName={explanation.dr.name}
          crcName={explanation.crc?.name}
          sessionStartedAt={''}
        />
      }
    >
      <ExplanationRoomTop
        userAttributeType="hospital"
        hospitalName={hospitalName ?? ''}
        canDelivery={false}
        isAgreementDone={false}
        isDelivered={false}
        navigateToDocumentPage={navigateToDocumentPage}
        onClickDeliveryDocumentButton={() => {}}
        docSets={docSets}
      />
    </ExplanationRoomLayout>
  )
}
