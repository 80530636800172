import React, { useState } from 'react'

import { ButtonLink } from 'src/components/base/text-link'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'

import { SmallItemLogModalContainer } from './modal-container'

type Props = {
  smallItemAnswer: SmallItemAnswer
}

export const SmallItemLogButton: React.FC<Props> = props => {
  const [modalOpen, toggleModal] = useState(false)
  const { smallItemAnswer } = props

  return (
    <>
      <ButtonLink className="log-link" onClick={() => toggleModal(true)}>
        変更履歴
      </ButtonLink>
      {modalOpen && (
        <SmallItemLogModalContainer
          smallItemAnswer={smallItemAnswer}
          closeModal={() => toggleModal(false)}
        />
      )}
    </>
  )
}
