import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { generatePath, useNavigate } from 'react-router-dom'
import { HasSetBadge } from 'src/components/HasSetBadge/HasSetBadge'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { NotSetBadge } from 'src/components/NotSetBadge/NotSetBadge'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  useSelectedTrial,
} from 'src/features/auth/context'
import {
  CreateOrGetPreSessionRes,
  useCreateExplanationSession,
} from 'src/features/explanation/api'
import { MembersSettingButton } from 'src/features/explanation/components/MembersSettingButton/MembersSettingButton'
import { hasAlreadyPinSet } from 'src/features/explanation/utils/hasAlreadyPinSet'
import { getFullName } from 'src/utils/getFullName'

import { useActiveDevice } from '../../context/ExplanationRoomEvent'
import { ExplanationRoomHeader } from '../ExplanationRoomHeader/ExplanationRoomHeader'
import { LeaveLobbyModal } from '../LeaveLobbyModal/LeaveLobbyModal'

const HEADER_HEIGHT = 64

type Props = {
  preSession: CreateOrGetPreSessionRes
  onFinish: () => void
  children: React.ReactNode
}

export const PartnerLobbyLayout: React.FC<Props> = ({
  preSession,
  onFinish,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { sendDeleted } = useActiveDevice()
  const { selectedTrial } = useSelectedTrial()
  return (
    <Box h="full">
      <Flex width="100%" height="100%" direction="column">
        <LeaveLobbyModal
          isOpen={isOpen}
          onClose={onClose}
          onFinish={async () => {
            await sendDeleted()
            onFinish()
          }}
        />
        <ExplanationRoomHeader
          featureChannel={selectedTrial.featureChannel}
          headerTitle="説明ロビー"
          finishButton={
            <Button variant="outline" colorScheme="red" onClick={onOpen}>
              説明ロビーから退出する
            </Button>
          }
          height={HEADER_HEIGHT}
        />
        <Flex height={`calc(100% - ${HEADER_HEIGHT}px)`}>
          <Box as="main" flex="1" overflowY="auto" h="full" bg="green.50">
            {children}
          </Box>
          <Box
            w={{ base: '240px', lg: '360px' }}
            p="6"
            bg="white"
            height="full"
            overflow="auto"
          >
            <PartnerLobbySidebar preSession={preSession} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

const PartnerLobbySidebar = ({
  preSession,
}: {
  preSession: CreateOrGetPreSessionRes
}) => {
  const { identifiedAt, explanationSessionUid } = preSession

  const navigate = useNavigate()

  const {
    account: { selectedTrial },
  } = useAuthenticatedAccount()

  const { request: createSession } = useCreateExplanationSession({
    onSuccess: ({ explanationSessionUid }) => {
      navigate(
        generatePath(Paths.PartnerExplanationRoomTop, {
          trialUid: selectedTrial.uid,
          sessionUid: explanationSessionUid,
        }),
      )
    },
  })

  const revision = preSession.explanationRevision

  const explanation = revision.explanation

  const patient = explanation.patient

  const mainHospitalMembers = useMemo(
    () =>
      revision.members.filter(
        m => m.trialHospitalUid === explanation.trialHospital.uid,
      ),
    [revision, explanation],
  )

  const partnerHospitalMembers = useMemo(
    () =>
      revision.members.filter(
        m => m.trialHospitalUid === revision.partnerTrialHospital?.uid,
      ),
    [revision],
  )

  const pinSettingStatus = revision.latestPinSettingHistory?.status
  const hasPinSet = !!pinSettingStatus
    ? hasAlreadyPinSet(pinSettingStatus)
    : false
  const hasPhoneNumberSet = !!explanation.patient.mobileNumber

  const isSingerSet = revision.docSets
    .flatMap(ds => ds.documentRevisions)
    .some(dr => {
      if (dr?.__typename === 'ExplanationDocRevisionAgreementForm') {
        return dr.signerMembers.length > 0
      }
      return false
    })

  return (
    <Flex height="full" direction="column" justifyContent="space-between">
      <Stack spacing="8">
        <Text fontSize="xl" fontWeight="bold">
          {selectedTrial.name}
        </Text>
        <Stack spacing="3">
          <Stack spacing="1">
            <Text fontSize="xl" fontWeight="bold">
              候補ID
            </Text>
            <Text>{patient.candidateId}</Text>
          </Stack>
          {selectedTrial.featureFlags.eConsentNewSignFlow && (
            <HStack>
              {hasPinSet ? (
                <HasSetBadge text="暗証番号" />
              ) : (
                <NotSetBadge text="暗証番号" />
              )}
              {hasPhoneNumberSet ? (
                <HasSetBadge text="携帯電話番号" />
              ) : (
                <NotSetBadge text="携帯電話番号" />
              )}
            </HStack>
          )}
        </Stack>
        {patient.expDiseaseId && (
          <Stack spacing="1">
            <Text fontSize="xl" fontWeight="bold">
              症例番号
            </Text>
            <Text>{patient.expDiseaseId}</Text>
          </Stack>
        )}
        <Stack spacing="1" alignItems="start">
          <Text fontSize="xl" fontWeight="bold">
            本人確認
          </Text>
          <HStack spacing="4" alignItems="start">
            <Text>患者</Text>
            <Flex direction="column">
              {identifiedAt && (
                <Text>{dayjs(identifiedAt).format('YYYY/MM/DD HH:mm:ss')}</Text>
              )}
            </Flex>
          </HStack>
        </Stack>
        <VStack alignItems="start" w="full">
          <Text fontSize="xl" fontWeight="bold">
            説明担当者
          </Text>
          <Box maxHeight="192px" w="full">
            {revision.members.length === 0 && (
              <Box my={1}>
                <WarningMessage
                  message="説明担当者が設定されていません。"
                  subMessage="署名を実施するには説明ルームで説明担当者の設定が必要です。"
                />
              </Box>
            )}
            {mainHospitalMembers.length > 0 && (
              <Box>
                <Text fontWeight="bold" as="h3">
                  {explanation.trialHospital.hospital.name}
                </Text>
                <Stack mt="2" spacing="1" pl="6" w="full">
                  {mainHospitalMembers.map(m => (
                    <MemberLabel
                      key={m.trialMember.uid}
                      role={m.trialMember.role}
                      displayName={getFullName(m.trialMember.user)}
                    />
                  ))}
                </Stack>
              </Box>
            )}
            {partnerHospitalMembers.length > 0 && (
              <Box mt="4">
                <Text fontWeight="bold" as="h3">
                  {revision.partnerTrialHospital?.hospital.name}
                </Text>
                <Stack mt="2" spacing="1" pl="6" w="full">
                  {partnerHospitalMembers.map(m => (
                    <MemberLabel
                      key={m.trialMember.uid}
                      role={m.trialMember.role}
                      displayName={getFullName(m.trialMember.user)}
                      isPartner
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Box>
          <MembersSettingButton
            explanationUid={explanation.uid}
            explanationSessionUid={
              preSession.explanationSessionUid ?? undefined
            }
            isSignerSet={isSingerSet}
            defaultMembers={revision.members.map(m => ({
              trialHospitalUid: m.trialHospitalUid,
              trialMemberUid: m.trialMember.uid,
              role: m.trialMember.role,
            }))}
          />
        </VStack>
      </Stack>
      <Flex alignItems="center" justifyContent="center">
        {!!explanationSessionUid && (
          <Button
            colorScheme="green"
            onClick={() =>
              navigate(
                generatePath(Paths.PartnerExplanationRoomTop, {
                  trialUid: selectedTrial.uid,
                  sessionUid: explanationSessionUid,
                }),
              )
            }
          >
            説明ルームへ
          </Button>
        )}
        {/* パートナー施設で同意未完了の場合はセッション作成ができるが、ボタンの文言は「説明ルームへ」 */}
        {!explanationSessionUid &&
          revision.latestHistory.status === 'AgreementNotDone' && (
            <Button
              colorScheme="green"
              onClick={async () => {
                await createSession({
                  type: 'fromPreSession',
                  explanationPreSessionUid: preSession.uid,
                })
              }}
            >
              説明ルームへ
            </Button>
          )}
      </Flex>
    </Flex>
  )
}
