import { IcfDocumentSet } from '../icf-document-set/entity'

export type IcfDocument = {
  uid: string
  numberingId: number
  type: IcfDocumentType
}

export type IcfDocumentForList = IcfDocument & {
  icfDocumentRevisionUid: string
  name: string
  version: string
  status: IcfDocumentRevisionStatus
  isRequiredPatientSign: boolean
  approvedAt: string
  isRevising: boolean
  isLinkedICFDocumentSet: boolean
  isRequiredReAgreementConfirmation: boolean
}

export type IcfDocumentDetail = IcfDocument & {
  canDelete: boolean
  latestRevisionUid: string
  revisions: IcfDocumentRevisionForMenu[]
  sets: IcfDocumentSetForIcfDocumentDetail[]
}

type IcfDocumentRevisionForMenu = {
  uid: string
  version: string
  status: IcfDocumentRevisionStatus
  approvedAt: string
}

export type IcfDocumentSetForIcfDocumentDetail = Pick<
  IcfDocumentSet,
  'uid' | 'numberingId' | 'name' | 'enabled'
>

export type CreateIcfDocumentItem = {
  uid: string
  type: IcfDocumentType | undefined
  tempFileUid: string
  dsTemplateId: string
  revision: IcfDocumentRevisionForCreate
}

type IcfDocumentRevisionForCreate = Pick<
  IcfDocumentRevision,
  'uid' | 'version' | 'name' | 'fileName' | 'isRequiredPatientSign'
>

export type IcfDocumentRevision = {
  uid: string
  icfDocumentUid: string
  version: string
  name: string
  fileName: string
  type: IcfDocumentType
  latestStatus: IcfDocumentRevisionStatus
  approvedAt: string
  isRequiredPatientSign: boolean
  signerRoles: IcfDocumentSignerRole[]
  checkRoles: IcfDocumentCheckRole[]
  canCancel: boolean
  dsTemplateId: string
  dsTemplateModifiedAt?: string
  histories: IcfDocumentRevisionHistory[]
}

export type IcfDocumentRevisionHistory = {
  uid: string
  status: IcfDocumentRevisionStatus
  operatedAt: string
  operatedMemberName: string
  comment: string
}

export type NextIcfDocumentRevisionTemplate = {
  uid: string
  icfDocumentUid: string
  version: string
  name: string
  type: IcfDocumentType
  isRequiredPatientSign: boolean
}

export type CreateIcfDocumentRevisionItem = NextIcfDocumentRevisionTemplate & {
  fileName: string
  dsTemplateId?: string
  tempFileUid: string
}

export type IcfDocumentType =
  (typeof icfDocumentTypes)[keyof typeof icfDocumentTypes]

export const icfDocumentTypes = {
  Undefined: 0,
  AgreementForm: 1,
  CheckUnderstanding: 2,
  Description: 3,
  Video: 4,
} as const

export const icfDocumentTypeText: Record<IcfDocumentType, string> = {
  [icfDocumentTypes.Undefined]: '',
  [icfDocumentTypes.AgreementForm]: '同意書',
  [icfDocumentTypes.CheckUnderstanding]: '理解度確認',
  [icfDocumentTypes.Description]: '説明文書',
  [icfDocumentTypes.Video]: '動画',
}

export const isDocuSignType = (type: IcfDocumentType) =>
  type === icfDocumentTypes.AgreementForm ||
  type === icfDocumentTypes.CheckUnderstanding

export type IcfDocumentRevisionStatus =
  (typeof icfDocumentRevisionStatus)[keyof typeof icfDocumentRevisionStatus]

export const icfDocumentRevisionStatus = {
  NewDocumentCreated: 1,
  Saved: 2,
  WaitApproving: 3,
  Approved: 4,
  Rejected: 5,
  Withdrawn: 6,
  NewRevisionCreated: 7,
  NewRevisionCanceled: 8,
  Disabled: 9,
  Enabled: 10,
} as const

export const icfDocumentRevisionStatusText: Record<
  IcfDocumentRevisionStatus,
  string
> = {
  [icfDocumentRevisionStatus.NewDocumentCreated]: '新規作成',
  [icfDocumentRevisionStatus.Saved]: '一時保存',
  [icfDocumentRevisionStatus.WaitApproving]: '承認依頼',
  [icfDocumentRevisionStatus.Approved]: '承認',
  [icfDocumentRevisionStatus.Rejected]: '承認却下',
  [icfDocumentRevisionStatus.Withdrawn]: '承認依頼取り下げ',
  [icfDocumentRevisionStatus.NewRevisionCreated]: '改版開始',
  [icfDocumentRevisionStatus.NewRevisionCanceled]: '改版取り消し',
  [icfDocumentRevisionStatus.Disabled]: '無効化',
  [icfDocumentRevisionStatus.Enabled]: '有効化',
}

export type IcfDocumentSignerRole =
  (typeof icfDocumentSignerRoles)[keyof typeof icfDocumentSignerRoles]

export type IcfDocumentCheckRole =
  (typeof icfDocumentCheckRoles)[keyof typeof icfDocumentCheckRoles]

export const icfDocumentSignerRoles = {
  Dr: 1,
  CRC: 2,
  Patient: 3,
  Representative: 4,
} as const

export const icfDocumentCheckRoles = {
  Patient: 3,
  Representative: 4,
} as const

export const icfDocumentRoleText: Record<
  IcfDocumentSignerRole | IcfDocumentCheckRole,
  string
> = {
  [icfDocumentSignerRoles.Dr]: 'Dr',
  [icfDocumentSignerRoles.CRC]: 'CRC',
  [icfDocumentSignerRoles.Patient]: '患者',
  [icfDocumentSignerRoles.Representative]: '代諾者',
}
