import { Box, Flex, Link, Stack, VStack } from '@chakra-ui/react'
import { Field, Schema } from '@micin-jp/chicken-schema'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import {
  CheckboxFieldForm,
  DateFieldForm,
  LabelFieldForm,
  NumberFieldForm,
  RadioFieldForm,
  SelectMenuFieldForm,
  TextareaFieldForm,
  TextFieldForm,
  TimeFieldForm,
} from 'src/lib/chicken-schema/components'
import { FileFieldForm } from 'src/lib/chicken-schema/components/FileFieldForm/FileFieldForm'
import {
  NotSectionField,
  withValidateFieldType,
} from 'src/lib/chicken-schema/utils'
import { assertNever } from 'src/utils/assertNever'

type Props = {
  schema: Schema
}
export const PreviewCriteria: React.FC<Props> = ({ schema }) => {
  return (
    <>
      <Navigation schema={schema} />
      <Flex direction="column" gap={12} mt="6" pb="8">
        {schema.fields.map(field => (
          <FieldComponent key={field.fid} field={field} />
        ))}
      </Flex>
    </>
  )
}

const Navigation: React.FC<{ schema: Schema }> = ({ schema }) => {
  return (
    <Box px="4" py="2" bg="blue.50" borderRadius="sm">
      <Flex gap={6} minH="1.5rem">
        {schema.fields
          .filter(
            ({ typeDef: { type } }) =>
              type === 'Section' || type === 'RepeatableSection',
          )
          .map(section => (
            <Flex key={section.fid} align="center" gap={1}>
              <Box h="20px" minW="4px" bg="blue.300" />
              <Link
                key={section.fid}
                as="button"
                color="blue.500"
                textAlign="left"
                onClick={() => {
                  const target = document.getElementById(
                    `preview_section_${section.fid}`,
                  )
                  if (target) {
                    target.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
                {section.name}
              </Link>
            </Flex>
          ))}
      </Flex>
    </Box>
  )
}

const FieldComponent: React.FC<{ field: Field }> = ({ field }) => {
  const type = field.typeDef.type
  return (
    <>
      {type === 'Section' || type === 'RepeatableSection' ? (
        <Stack
          spacing="3"
          as="section"
          aria-label={field.name}
          id={`preview_section_${field.fid}`}
        >
          <TextWithBar>{field.name}</TextWithBar>
          <VStack px="2" spacing="8" align="start">
            {field.typeDef.fields.map(field => (
              <FieldComponent key={field.name} field={field} />
            ))}
          </VStack>
        </Stack>
      ) : (
        <FormComponent field={field as NotSectionField} />
      )}
    </>
  )
}

const FormComponent: React.FC<{ field: NotSectionField }> = ({ field }) => {
  switch (field.typeDef.type) {
    case 'Text':
      return (
        <TextFieldForm isPreview field={withValidateFieldType(field, 'Text')} />
      )
    case 'Textarea':
      return (
        <TextareaFieldForm
          isPreview
          field={withValidateFieldType(field, 'Textarea')}
        />
      )
    case 'Number':
      return (
        <NumberFieldForm
          isPreview
          field={withValidateFieldType(field, 'Number')}
        />
      )
    case 'Checkbox':
      return (
        <CheckboxFieldForm
          isPreview
          field={withValidateFieldType(field, 'Checkbox')}
        />
      )
    case 'Radio':
      return (
        <RadioFieldForm
          isPreview
          field={withValidateFieldType(field, 'Radio')}
        />
      )
    case 'SelectMenu':
      return (
        <SelectMenuFieldForm
          isPreview
          field={withValidateFieldType(field, 'SelectMenu')}
          selectWidth="320px"
        />
      )
    case 'Time':
      return (
        <TimeFieldForm isPreview field={withValidateFieldType(field, 'Time')} />
      )
    case 'Date':
      return (
        <DateFieldForm isPreview field={withValidateFieldType(field, 'Date')} />
      )
    case 'NullableDate':
      return (
        <DateFieldForm
          isPreview
          field={withValidateFieldType(field, 'NullableDate')}
        />
      )
    case 'File':
      return (
        <FileFieldForm isPreview field={withValidateFieldType(field, 'File')} />
      )
    case 'Label':
      return <LabelFieldForm field={withValidateFieldType(field, 'Label')} />
    default:
      return assertNever(field.typeDef)
  }
}
