import React, { useEffect } from 'react'

import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCognitoUser } from 'src/hooks/use-cognito-user'
import { actions } from 'src/modules/auth/login/redux'
import { routes } from 'src/modules/routes'
import { useRequestState } from 'src/modules/server/use-request-state'

import { IProps as ITwoFactorProps, TwoFactor } from './two-factor'
import { useAgreement } from '../../agreement/hooks/use-agreement'

export const TwoFactorContainer: React.FC<{}> = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { cognitoUser } = useCognitoUser()

  const { jumpAfterLogin } = useAgreement()

  useEffect(() => {
    if (cognitoUser?.challengeName !== 'SOFTWARE_TOKEN_MFA')
      navigate(routes.httpError403)
  }, [cognitoUser?.challengeName, navigate])

  const onCancel = () => navigate(routes.login)

  const onSubmit: ITwoFactorProps['onSubmit'] = async ({
    authCode,
    rememberDevice,
  }) => {
    try {
      requestStarted()
      const currentUser = await Auth.confirmSignIn(
        cognitoUser,
        authCode,
        'SOFTWARE_TOKEN_MFA',
      )

      if (rememberDevice) {
        currentUser.setDeviceStatusRemembered({
          onFailure: (error: any) => {
            throw error
          },
        })
      }

      const idToken = currentUser.signInUserSession.idToken.jwtToken

      await dispatch(actions.submit({ idToken: idToken, firstLogin: false }))
      await jumpAfterLogin()
    } catch (error) {
      switch (error.code) {
        case 'CodeMismatchException':
          requestFailed('認証コードが誤っています。')
          break
        case 'LimitExceededException':
          requestFailed(
            'アカウントがロックされています。時間を置いて再度お試し下さい。',
          )
          break
        case 'NotAuthorizedException':
          requestFailed(
            'セッションの有効期限が切れております。再度ログイン画面よりログイン下さい。',
          )
          break
        default:
          // MiROHAのサーバーのエラーはこちらで受け取る
          if (error.message) {
            requestFailed(error.message)
            return
          }
          requestFailed('問題が発生しました。時間を置いて再度お試し下さい。')
      }
    } finally {
      requestDone()
    }
  }

  if (cognitoUser?.challengeName !== 'SOFTWARE_TOKEN_MFA') return <></>

  return (
    <TwoFactor
      errorMessage={errorMessage}
      requesting={requesting}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  )
}
