import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { MediumItemAnswerStatus } from 'src/modules/entities/medium-item-answer/entity'
import { ItemType } from 'src/modules/entities/small-item/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import {
  isCorrectDataFormat,
  toValueString,
} from 'src/modules/entities/small-item-answer/util'
import { getSingle } from 'src/modules/entities/worksheet/selector'
import { RootState } from 'src/modules/reducer'
import { isClinical } from 'src/modules/util/item-type-classification'

import { getMediumItemAnswer } from '../selector'
import { worksheetIdParamName } from '../worksheet-detail'

export const useSaveSection = (mediumItemAnswerUid: string) => {
  const { worksheetUid = '' } = useParams<{ [worksheetIdParamName]: string }>()

  const originalWorksheet = useSelector((state: RootState) =>
    getSingle(state, worksheetUid),
  )
  const mediumItemAnswer = useSelector((state: RootState) =>
    getMediumItemAnswer(state, mediumItemAnswerUid),
  )
  const originalMediumItemAnswer = originalWorksheet?.mediumItemAnswers.find(
    mia => mia.uid === mediumItemAnswerUid,
  )
  if (!mediumItemAnswer || !originalMediumItemAnswer) {
    return { canSave: false, canConfirm: false }
  }

  const areAllAncestorChoiceAnswersChecked = (
    sia: SmallItemAnswer,
  ): boolean => {
    if (!sia.parentUid) {
      return true
    }

    const parent = mediumItemAnswer.smallItemAnswerMap[sia.parentUid]
    const parentChoiceAnswer = parent.choiceAnswers.find(ca =>
      ca.smallItemAnswerUids.includes(sia.uid),
    )!

    if (!parentChoiceAnswer.checked) {
      return false
    }

    return areAllAncestorChoiceAnswersChecked(parent)
  }

  const hasChanged = (smallItemAnswer: SmallItemAnswer) => {
    if (
      smallItemAnswer.parentUid &&
      !areAllAncestorChoiceAnswersChecked(smallItemAnswer)
    ) {
      return false
    }

    return (
      toValueString(smallItemAnswer) !==
      toValueString(
        originalMediumItemAnswer.smallItemAnswerMap[smallItemAnswer.uid],
      )
    )
  }

  const smallItemAnswers = Object.values(mediumItemAnswer.smallItemAnswerMap)

  const changed = smallItemAnswers.some(hasChanged)
  const areCorrectDataFormat = smallItemAnswers.every(isCorrectDataFormat)

  const canSave = changed && areCorrectDataFormat

  const labelOnly = smallItemAnswers.every(
    sia => sia.itemType === ItemType.Label,
  )
  //医療機関設定などによって一時保存済みのセクションがラベルのみになる可能性がある
  //一時保存済みのステータスでラベルのみのセクションは確定できない
  const canConfirmLabelOnlySection =
    labelOnly &&
    smallItemAnswers.some(isClinical) &&
    [MediumItemAnswerStatus.New, MediumItemAnswerStatus.Saved].includes(
      mediumItemAnswer.status,
    )

  const canConfirmSavedSection =
    !changed &&
    !labelOnly &&
    areCorrectDataFormat &&
    mediumItemAnswer.status === MediumItemAnswerStatus.Saved

  const canConfirm = canConfirmLabelOnlySection || canConfirmSavedSection

  return {
    canConfirm,
    canSave,
  }
}
