import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { MessageOption } from 'src/components/layout/message-tab'

import { actions } from './redux'

/** @deprecated useMirohaToastを使ってください */
export const useFlash = () => {
  const dispatch = useDispatch()

  const showSuccess = useCallback(
    (message: string, option?: MessageOption) => {
      dispatch(actions.showSuccess({ message, option }))
    },
    [dispatch],
  )

  const showInfo = useCallback(
    (message: string, option?: MessageOption) => {
      dispatch(actions.showInfo({ message, option }))
    },
    [dispatch],
  )

  const showWarning = useCallback(
    (message: string, option?: MessageOption) => {
      dispatch(actions.showWarning({ message, option }))
    },
    [dispatch],
  )

  const showError = useCallback(
    (message: string, option?: MessageOption) => {
      dispatch(actions.showError({ message, option }))
    },
    [dispatch],
  )

  return { showSuccess, showInfo, showWarning, showError }
}
