import React, { memo } from 'react'

import {
  Box,
  Button,
  Center,
  Checkbox,
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useBoolean,
} from '@chakra-ui/react'
import {
  Choice,
  ErrSchemaDetail,
  LabelTypeDef,
  NumberTypeDef,
  TypeDef,
} from '@micin-jp/chicken-schema'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { Add, Close, Delete } from 'src/components/icon'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import { Select } from 'src/components/Select/Select'
import {
  deleteFieldAtom,
  fieldErrorFamily,
  fieldFamily,
} from 'src/lib/chicken-schema/atom'
import {
  hasValidationTypeDef,
  isChoiceTypeDef,
  WithChoiceTypeDef,
} from 'src/lib/chicken-schema/utils'
import { assertNever } from 'src/utils/assertNever'
import { generateUUID } from 'src/utils/generateUUID'

import { FieldValidationForm } from '../FieldValidationForm/FieldValidationForm'

type Props = {
  fid: string
  isDisabled: boolean
}

type FieldType = TypeDef['type']

const selectableTypes = [
  'Radio',
  'SelectMenu',
  'Checkbox',
  'Date',
  'NullableDate',
  'Time',
  'Number',
  'Text',
  'Textarea',
  'File',
  'Label',
] satisfies FieldType[]

type SelectableFieldType = (typeof selectableTypes)[number]

const isSelectableType = (type: FieldType): type is SelectableFieldType => {
  return selectableTypes.some(t => t === type)
}

type FieldTypeCategory =
  | 'single'
  | 'multiple'
  | 'dateTime'
  | 'number'
  | 'text'
  | 'file'
  | 'label'
const getCategory = (type: FieldType): FieldTypeCategory => {
  if (!isSelectableType(type)) {
    throw new Error('invalid type')
  }
  switch (type) {
    case 'Radio':
    case 'SelectMenu':
      return 'single'
    case 'Checkbox':
      return 'multiple'
    case 'Date':
    case 'NullableDate':
    case 'Time':
      return 'dateTime'
    case 'Number':
      return 'number'
    case 'Text':
    case 'Textarea':
      return 'text'
    case 'File':
      return 'file'
    case 'Label':
      return 'label'
    default:
      return assertNever(type)
  }
}
const categories: Record<
  FieldTypeCategory,
  { defaultType: SelectableFieldType; label: string }
> = {
  single: { defaultType: 'Radio', label: '単一選択' },
  multiple: { defaultType: 'Checkbox', label: '複数選択' },
  dateTime: { defaultType: 'Date', label: '日時入力' },
  number: { defaultType: 'Number', label: '数値入力' },
  text: { defaultType: 'Text', label: 'テキスト入力' },
  file: { defaultType: 'File', label: 'ファイルアップロード' },
  label: { defaultType: 'Label', label: 'テキスト表示' },
}

const getDefaultTypeDef = (
  type: SelectableFieldType,
  choices?: Choice[],
): TypeDef => {
  switch (type) {
    case 'Text':
      return { type: 'Text' }
    case 'Textarea':
      return { type: 'Textarea' }
    case 'Number':
      return { type: 'Number' }
    case 'Date':
      return { type: 'Date' }
    case 'NullableDate':
      return { type: 'NullableDate' }
    case 'Time':
      return { type: 'Time' }
    case 'Checkbox':
      return { type: 'Checkbox', choices: choices ?? [] }
    case 'Radio':
      return { type: 'Radio', choices: choices ?? [] }
    case 'SelectMenu':
      return { type: 'SelectMenu', choices: choices ?? [] }
    case 'File':
      return { type: 'File', isCertifiedCopy: false }
    case 'Label':
      return { type: 'Label' }
    default:
      return assertNever(type)
  }
}
// Object.keysの返り値をstring[]ではなくkeyof T[]にするためのutil
const getKeys = <T extends { [key: string]: unknown }>(obj: T): (keyof T)[] => {
  return Object.keys(obj)
}

export const FieldForm: React.FC<Props> = memo(({ fid, isDisabled }) => {
  const atom = fieldFamily(fid)
  const [field, setField] = useAtom(atom)

  const fieldErrors = useAtomValue(fieldErrorFamily(fid))

  const [descriptionInputOpen, setDescriptionInputOpen] = useBoolean(
    !!field.description,
  )

  const deleteField = useSetAtom(deleteFieldAtom)

  const handleDelete = () => {
    deleteField(fid)
  }

  const fieldTypeCategory = getCategory(field.typeDef.type)

  const handleChangeType = async (type: FieldType) => {
    if (!isSelectableType(type)) {
      throw new Error('invalid type')
    }
    await setField({
      typeDef: getDefaultTypeDef(
        type,
        isChoiceTypeDef(field.typeDef)
          ? field.typeDef.choices.map(c => ({
              ...c,
              validation: type === 'Checkbox' ? c.validation : undefined, // checkbox以外の場合はvalidationを削除
            }))
          : undefined,
      ),
    })
  }

  return (
    <Box
      border="1px solid"
      borderRadius="sm"
      borderColor="gray.100"
      p="5"
      pos="relative"
    >
      <Box
        pos="absolute"
        right="3"
        top="3"
        as="button"
        aria-label="セクションを削除"
        color="blue.500"
        onClick={handleDelete}
      >
        <Close />
      </Box>
      <FormControl
        isRequired
        isDisabled={isDisabled}
        isInvalid={!!fieldErrors['name']}
      >
        <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
          フィールド名
        </FormLabel>
        <Textarea
          as={ReactTextareaAutosize}
          minRows={1}
          maxW="800px"
          minH="40px"
          value={field.name}
          onChange={async e => {
            await setField({ name: e.target.value })
          }}
        />
        <FormErrorMessage>
          {fieldErrors['name']?.map(e => e.message).join('')}
        </FormErrorMessage>
      </FormControl>
      <Box mt="2">
        {descriptionInputOpen ? (
          <Fade in>
            <FormControl
              mt="4"
              isDisabled={isDisabled}
              isInvalid={!!fieldErrors['description']}
            >
              <FormLabel>フィールドの説明</FormLabel>
              <Flex gap={2} align="center">
                <Textarea
                  as={ReactTextareaAutosize}
                  maxW="800px"
                  minH="40px"
                  value={field.description ?? ''}
                  onChange={async e => {
                    await setField({ description: e.target.value })
                  }}
                  isDisabled={isDisabled}
                />
                <IconButton
                  size="sm"
                  variant="customIconButtonGhost"
                  icon={<Close />}
                  onClick={async () => {
                    await setField({ description: undefined })
                    setDescriptionInputOpen.off()
                  }}
                  aria-label="フィールドの説明を削除"
                />
              </Flex>
              <FormErrorMessage>
                {fieldErrors['description']?.map(e => e.message).join('')}
              </FormErrorMessage>
            </FormControl>
          </Fade>
        ) : (
          <Button
            variant="text"
            leftIcon={<Add />}
            onClick={setDescriptionInputOpen.on}
            isDisabled={isDisabled}
          >
            フィールドの説明追加
          </Button>
        )}
      </Box>
      <HStack align="end" spacing="4" mt="6" wrap="wrap">
        <FormControl isRequired isDisabled={isDisabled} w="max-content">
          <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
            回答タイプ
          </FormLabel>
          <Select
            value={fieldTypeCategory}
            items={getKeys(categories).map(key => ({
              value: key,
              label: categories[key].label,
            }))}
            onChange={async categoryLabel => {
              if (fieldTypeCategory === categoryLabel) return
              const { defaultType } = categories[categoryLabel]
              await handleChangeType(defaultType)
            }}
            width={280}
            isDisabled={isDisabled}
          />
        </FormControl>
        {fieldTypeCategory === 'single' && (
          <Select
            value={field.typeDef.type}
            items={[
              { label: 'ボタン選択', value: 'Radio' },
              { label: 'リスト選択', value: 'SelectMenu' },
            ]}
            onChange={handleChangeType}
            width={200}
            isDisabled={isDisabled}
          />
        )}
        {fieldTypeCategory === 'dateTime' && (
          <Stack spacing="2">
            <FormLabel m="0">フォーマット</FormLabel>
            <Select
              value={field.typeDef.type}
              items={[
                { label: 'YYYY/MM/DD', value: 'Date' },
                { label: 'YYYY/MM~/DD~', value: 'NullableDate' },
                { label: 'HI:MI', value: 'Time' },
              ]}
              onChange={handleChangeType}
              width={200}
              isDisabled={isDisabled}
            />
          </Stack>
        )}
        {fieldTypeCategory === 'text' && (
          <Stack>
            <FormLabel m="0">フォーマット</FormLabel>
            <Select
              value={field.typeDef.type}
              items={[
                { label: '単一行', value: 'Text' },
                { label: '複数行', value: 'Textarea' },
              ]}
              onChange={handleChangeType}
              width={200}
              isDisabled={isDisabled}
            />
          </Stack>
        )}
      </HStack>
      {field.typeDef.type === 'Number' && (
        <FormControl
          isDisabled={isDisabled}
          my="4"
          w="auto"
          isInvalid={!!fieldErrors['typeDef.unit']}
        >
          <FormLabel>数値の単位</FormLabel>
          <Input
            value={field.typeDef.unit ?? ''}
            placeholder="℃"
            maxW="100px"
            onChange={async e => {
              if (field.typeDef.type !== 'Number') return
              const typeDef: NumberTypeDef = {
                ...field.typeDef,
                unit: e.target.value,
              }
              await setField({
                typeDef,
              })
            }}
          />
          <FormErrorMessage>
            {fieldErrors['typeDef.unit']?.map(e => e.message).join('')}
          </FormErrorMessage>
        </FormControl>
      )}
      {field.typeDef.type === 'File' && (
        <Checkbox
          my="4"
          isChecked={field.typeDef.isCertifiedCopy}
          onChange={async e => {
            if (field.typeDef.type !== 'File') return
            const typeDef = {
              ...field.typeDef,
              isCertifiedCopy: e.target.checked,
            }
            await setField({
              typeDef,
            })
          }}
        >
          Certified Copyとして扱う
        </Checkbox>
      )}
      {field.typeDef.type === 'Label' && (
        <FormControl
          isDisabled={isDisabled}
          my="4"
          w="auto"
          isInvalid={!!fieldErrors['typeDef.content']}
        >
          <FormLabel>本文テキスト</FormLabel>
          <Textarea
            as={ReactTextareaAutosize}
            maxW="800px"
            minH="100px"
            value={field.typeDef.content ?? ''}
            onChange={async e => {
              if (field.typeDef.type !== 'Label') return
              const typeDef: LabelTypeDef = {
                ...field.typeDef,
                content: !!e.target.value ? e.target.value : undefined,
              }
              await setField({
                typeDef,
              })
            }}
            isDisabled={isDisabled}
          />
          <FormErrorMessage>
            {fieldErrors['typeDef.content']?.map(e => e.message).join('')}
          </FormErrorMessage>
        </FormControl>
      )}
      {isChoiceTypeDef(field.typeDef) && (
        <Box my="4">
          <ChoicesForm
            fid={fid}
            type={field.typeDef.type}
            choices={field.typeDef.choices}
            onChange={async choices => {
              const typeDef = { ...field.typeDef, choices }
              await setField({
                typeDef,
              })
            }}
            isDisabled={isDisabled}
            fieldErrors={fieldErrors}
          />
        </Box>
      )}
      {hasValidationTypeDef(field.typeDef) && (
        <Box mt="6">
          <FieldValidationForm
            fid={fid}
            isDisabled={isDisabled}
            fieldErrors={fieldErrors}
          />
        </Box>
      )}
    </Box>
  )
})

type OnChangeChoiceParam = {
  cid: string
  update: Partial<Choice>
}

const ChoicesForm: React.FC<{
  fid: string
  type: WithChoiceTypeDef['type']
  choices: Choice[]
  isDisabled: boolean
  onChange: (choices: Choice[]) => void
  fieldErrors: Record<string, ErrSchemaDetail[]>
}> = ({ fid, type, choices, isDisabled, onChange, fieldErrors }) => {
  const onChangeChoice = (params: OnChangeChoiceParam[]) => {
    const newChoices = choices.map(choice => {
      const param = params.find(p => p.cid === choice.cid)
      if (choice.cid === param?.cid) {
        return { ...choice, ...param.update }
      }
      return choice
    })
    onChange(newChoices)
  }

  const field = useAtomValue(fieldFamily(fid))

  return (
    <Box>
      <FormControl
        isRequired
        isDisabled={isDisabled}
        isInvalid={fieldErrors['typeDef.choices']?.length > 0}
      >
        <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
          回答の選択肢
        </FormLabel>
        <FormErrorMessage>
          {fieldErrors['typeDef.choices']?.map(e => e.message).join('')}
        </FormErrorMessage>
      </FormControl>
      <Flex direction="column" gap={2} w="full">
        {choices.map((choice, index) => (
          <ChoiceForm
            key={choice.cid}
            type={type}
            choices={choices}
            isDisabled={isDisabled}
            onChange={onChange}
            onChangeChoice={onChangeChoice}
            choice={choice}
            index={index}
            showValidation={
              hasValidationTypeDef(field.typeDef) && !!field.typeDef.validation
            }
          />
        ))}
      </Flex>
      <Button
        mt="2"
        variant="text"
        leftIcon={<Add />}
        onClick={() => {
          onChange([...choices, { cid: generateUUID(), name: '' }])
        }}
        isDisabled={isDisabled}
      >
        選択肢追加
      </Button>
    </Box>
  )
}

const ChoiceForm: React.FC<{
  type: WithChoiceTypeDef['type']
  choices: Choice[]
  isDisabled: boolean
  onChange: (choices: Choice[]) => void
  onChangeChoice: (params: OnChangeChoiceParam[]) => void
  choice: Choice
  index: number
  showValidation: boolean
}> = ({
  type,
  choices,
  isDisabled,
  onChange,
  onChangeChoice,
  choice,
  index,
  showValidation,
}) => {
  const fieldErrors = useAtomValue(fieldErrorFamily(choice.cid))
  return (
    <Flex key={choice.cid} gap={2} align="center" w="full">
      <Flex w="full" maxW="800px" gap={2} align="center">
        <Text wordBreak="keep-all">{`${index + 1}.`}</Text>
        <FormControl isInvalid={!!fieldErrors['name']}>
          <Textarea
            as={ReactTextareaAutosize}
            w="full"
            minH="40px"
            value={choice.name}
            onChange={e => {
              onChangeChoice([
                { cid: choice.cid, update: { name: e.target.value } },
              ])
            }}
            isDisabled={isDisabled}
          />
          <FormErrorMessage>
            {fieldErrors['name']?.map(e => e.message)}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Box>
        <IconButton
          variant="customIconButtonGhost"
          icon={<Delete />}
          aria-label="選択肢を削除"
          onClick={() => {
            onChange(choices.filter(c => c.cid !== choice.cid))
          }}
          isDisabled={isDisabled}
        />
      </Box>
      {type === 'Checkbox' && showValidation && (
        <Center bg="gray.50" p="2" fontSize="sm" borderRadius="base">
          <Checkbox
            wordBreak="keep-all"
            isChecked={choice.validation?.required ?? false}
            onChange={e => {
              const newParams: OnChangeChoiceParam[] = [
                {
                  cid: choice.cid,
                  update: e.target.checked
                    ? {
                        validation: { required: e.target.checked },
                      }
                    : {
                        validation: undefined,
                      },
                },
              ]
              onChangeChoice(newParams)
            }}
          >
            必須
          </Checkbox>
        </Center>
      )}
    </Flex>
  )
}
