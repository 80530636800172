export const swrKeys = {
  fetchCrfDownloadJobs: 'fetchCrfDownloadJobs',
  fetchQuestionnaireAnswers: ({
    trialUid,
    patientUid,
  }: {
    trialUid: string
    patientUid: string
  }) => `/trials/${trialUid}/patients/${patientUid}/questionnaireAnswers`,

  // common
  fetchTrialMembers: ({ trialUid }: { trialUid: string }) =>
    `trials/${trialUid}/members`,

  fetchValidTrialMembers: ({ trialUid }: { trialUid: string }) =>
    `trials/${trialUid}/members?type=valid`,

  //e-Consent
  fetchLatestIcfDocuments: ({
    trialUid,
    trialHospitalUid,
  }: {
    trialUid: string
    trialHospitalUid: string
  }) => `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents`,
  fetchApprovedIcfDocuments: ({
    trialUid,
    trialHospitalUid,
  }: {
    trialUid: string
    trialHospitalUid: string
  }) =>
    `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents?type=approved`,
  fetchIcfDocument: ({
    trialUid,
    trialHospitalUid,
    icfDocumentUid,
  }: {
    trialUid: string
    trialHospitalUid: string
    icfDocumentUid: string
  }) =>
    `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/${icfDocumentUid}`,
  fetchIcfDocumentRevision: ({
    trialUid,
    trialHospitalUid,
    icfDocumentRevisionUid,
  }: {
    trialUid: string
    trialHospitalUid: string
    icfDocumentRevisionUid: string
  }) =>
    `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}`,
  getIcfDocumentVideoStreamUrl: ({
    trialUid,
    icfDocumentRevisionUid,
  }: {
    trialUid: string
    icfDocumentRevisionUid: string
  }) =>
    `/trials/${trialUid}/icf_document_revisions/${icfDocumentRevisionUid}/video_stream_url`,
  fetchNextIcfDocumentRevisionTemplate: ({
    trialUid,
    trialHospitalUid,
    icfDocumentUid,
  }: {
    trialUid: string
    trialHospitalUid: string
    icfDocumentUid: string
  }) =>
    `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/${icfDocumentUid}/next_revision`,
  fetchIcfDocumentSetList: ({
    trialUid,
    trialHospitalUid,
  }: {
    trialUid: string
    trialHospitalUid: string
  }) =>
    `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets`,
  fetchIcfDocumentSet: ({
    trialUid,
    trialHospitalUid,
    icfDocumentSetUid,
  }: {
    trialUid: string
    trialHospitalUid: string
    icfDocumentSetUid: string
  }) =>
    `trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents_sets/${icfDocumentSetUid}`,
  fetchExplanations: ({
    trialUid,
    searchParams,
  }: {
    trialUid: string
    searchParams: string
  }) => `/trials/${trialUid}/explanations${searchParams && searchParams}`,
  fetchExplanationDetail: ({
    trialUid,
    explanationUid,
  }: {
    trialUid: string
    explanationUid: string
  }) => `/trials/${trialUid}/explanations/${explanationUid}`,
  fetchExplanationSessions: ({
    trialUid,
    explanationRevisionUid,
  }: {
    trialUid: string
    explanationRevisionUid: string
  }) =>
    `/trials/${trialUid}/explanation_revisions/${explanationRevisionUid}/sessions`,
  fetchBrowsingHistories: ({
    trialUid,
    explanationRevisionUid,
  }: {
    trialUid: string
    explanationRevisionUid: string
  }) =>
    `/trials/${trialUid}/explanation_revisions/${explanationRevisionUid}/browsing_histories`,
  fetchSignHistories: ({
    trialUid,
    explanationRevisionUid,
  }: {
    trialUid: string
    explanationRevisionUid: string
  }) =>
    `/trials/${trialUid}/explanation_revisions/${explanationRevisionUid}/sign_histories`,
  fetchDeliveryHistories: ({
    trialUid,
    explanationRevisionUid,
  }: {
    trialUid: string
    explanationRevisionUid: string
  }) =>
    `/trials/${trialUid}/explanation_revisions/${explanationRevisionUid}/delivery_histories`,
  fetchExplanationDocRevisions: ({
    trialUid,
    explanationRevisionUid,
  }: {
    trialUid: string
    explanationRevisionUid: string
  }) =>
    `/trials/${trialUid}/explanation_revisions/${explanationRevisionUid}/doc_revisions`,
  fetchLatestPreSession: (trialUid: string, expRevUid: string) =>
    `/trials/${trialUid}/explanation_revisions/${expRevUid}/latest_pre_session`,
  fetchRevision: (trialUid: string, expRevUid: string) =>
    `/trials/${trialUid}/explanation_revisions/${expRevUid}`,
  fetchSession: (trialUid: string, sessionUid: string) =>
    `/trials/${trialUid}/explanation_sessions/${sessionUid}`,
  fetchExplanationDocSets: (trialUid: string, expRevUid: string) =>
    `/trials/${trialUid}/explanation_revisions/${expRevUid}/doc_sets`,
  fetchExplanationDocRevision: (trialUid: string, docRevisionUid: string) =>
    `/trials/${trialUid}/explanation_document_revisions/${docRevisionUid}`,
  fetchExplanationScheduleHistories: (trialUid: string, expRevUid: string) =>
    `/trials/${trialUid}/explanation_revisions/${expRevUid}/schedule_histories`,
} as const

export const eproPatientUserKeys = {
  fetchMe: (trialUid: string) =>
    `/epro_patient_user/trials/${trialUid}/patient`,
  fetchQuestionnaireAnswerList: (trialUid: string) =>
    `/epro_patient_user/trials/${trialUid}/questionnaire_answers`,
  fetchQuestionnaireAnswerSingle: (
    trialUid: string,
    questionnaireAnswerUid: string,
  ) =>
    `/epro_patient_user/trials/${trialUid}/questionnaire_answers/${questionnaireAnswerUid}`,
} as const
