// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgMovieAreaExpand = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M16 9.8c0-.6-.4-1-1-1s-1 .4-1 1v2.8l-4-4c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4H9.8c-.6 0-1 .4-1 1s.4 1 1 1H15c.1 0 .3 0 .4-.1.2-.1.4-.3.5-.5.1-.1.1-.3.1-.4V9.8ZM3.4 2h2.8c.6 0 1-.4 1-1s-.4-1-1-1H1C.9 0 .7 0 .6.1.4.2.2.4.1.6 0 .7 0 .9 0 1v5.2c0 .6.4 1 1 1s1-.4 1-1V3.4l4 4c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-4-4Z"
    />
  </svg>
)
export default SvgMovieAreaExpand
