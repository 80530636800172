import { HttpResponse } from 'msw'
import {
  createExplanationMock,
  GetExplanationsDocument,
  GetExplanationsQuery,
  GetExplanationsQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationsHandler = graphQLHandlerFactory<
  GetExplanationsQuery,
  GetExplanationsQueryVariables
>('query', GetExplanationsDocument, () => {
  return HttpResponse.json({
    data: {
      searchExplanations: {
        explanations: [
          createExplanationMock(),
          createExplanationMock(),
          createExplanationMock(),
          createExplanationMock(),
        ],
        totalCount: 4,
      },
    },
  })
})
