import { useEffect } from 'react'

import { useWorksheetFieldStickiesContext } from '../../context/WorksheetFieldStickies'
import { useStickyQuery } from '../../hooks/useStickyQuery'
import { generateFieldComponentId } from '../../utils/generateFieldComponentId'

export const StickyAutoScroll: React.FC = () => {
  const { stickyQuery } = useStickyQuery()

  const { findField } = useWorksheetFieldStickiesContext()

  useEffect(() => {
    if (!stickyQuery) return
    const field = findField(stickyQuery)
    if (!field) return
    const el = document.getElementById(
      generateFieldComponentId(field.fid, field.fieldIndex),
    )
    if (!el) return
    el.scrollIntoView({ behavior: 'smooth' })
  }, [stickyQuery, findField])

  return null
}
