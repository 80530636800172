import { useCallback, useMemo } from 'react'

import { useDispatch } from 'react-redux'
import { ItemType } from 'src/modules/entities/small-item/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'

import { actions } from '../redux'

export const useSmallItemAnswerForm = (smallItemAnswer: SmallItemAnswer) => {
  const value = useMemo(
    () => smallItemAnswer.value ?? '',
    [smallItemAnswer.value],
  )

  const dispatch = useDispatch()
  const setValue = useCallback(
    (value: string) => {
      dispatch(
        actions.updateSmallItemAnswer({
          smallItemAnswerUid: smallItemAnswer.uid,
          value,
        }),
      )
    },
    [dispatch, smallItemAnswer.uid],
  )
  const updateChoiceAnswer = useCallback(
    (choiceAnswerUids: string[]) => {
      dispatch(
        actions.updateChoiceAnswer({
          smallItemAnswerUid: smallItemAnswer.uid,
          selectedChoiceAnswerUids: choiceAnswerUids,
        }),
      )
    },
    [dispatch, smallItemAnswer.uid],
  )

  const selectedChoiceAnswerUids = useMemo(
    () =>
      smallItemAnswer.choiceAnswers.filter(ca => ca.checked).map(ca => ca.uid),
    [smallItemAnswer.choiceAnswers],
  )

  const onToggleChoiceAnswer = useCallback(
    (uid: string) => {
      if (
        [ItemType.Radio, ItemType.SelectMenu].includes(smallItemAnswer.itemType)
      ) {
        updateChoiceAnswer([uid])
        return
      }

      if (smallItemAnswer.itemType === ItemType.CheckBox) {
        const checked = selectedChoiceAnswerUids.includes(uid)

        if (checked) {
          updateChoiceAnswer(
            selectedChoiceAnswerUids.filter(selectedUid => uid !== selectedUid),
          )
        } else {
          updateChoiceAnswer(selectedChoiceAnswerUids.concat([uid]))
        }
      }
    },
    [selectedChoiceAnswerUids, smallItemAnswer.itemType, updateChoiceAnswer],
  )

  const onRemoveSelected = useCallback(
    (clickedValue: string) => {
      if (selectedChoiceAnswerUids[0] === clickedValue) {
        updateChoiceAnswer([])
      }
    },
    [selectedChoiceAnswerUids, updateChoiceAnswer],
  )

  const onUnselect = useCallback(() => {
    updateChoiceAnswer([])
  }, [updateChoiceAnswer])

  return {
    value,
    setValue,
    selectedChoiceAnswerUids,
    onToggleChoiceAnswer,
    onRemoveSelected,
    onUnselect,
  }
}
