import { useEffect } from 'react'

import { Button, Center, Stack, Text } from '@chakra-ui/react'
import { useRoomSession } from '@micin-jp/call-lib-react'
import {
  StaticScreen,
  StaticScreenContent,
  StaticScreenTitle,
} from 'src/components/StaticScreen/StaticScreen'
import { useLogout } from 'src/modules/auth/logout/use-logout'

type Props = {
  shouldLogout: boolean
}

export const FinishedScreen: React.FC<Props> = ({ shouldLogout }) => {
  const { handleLogout } = useLogout()

  const { leaveRoom } = useRoomSession()

  useEffect(() => {
    // 説明終了時は強制的にビデオ通話ルームから退出
    ;(async () => {
      await leaveRoom()
    })()
  }, [leaveRoom])

  return (
    <StaticScreen colorScheme="green">
      <StaticScreenTitle>説明は終了しました</StaticScreenTitle>
      <StaticScreenContent>
        {shouldLogout ? (
          <Stack spacing="8">
            <Text>再度ログインする場合は以下のボタンを押してください。</Text>
            <Center>
              <Button colorScheme="green" onClick={handleLogout}>
                ログイン画面へ戻る
              </Button>
            </Center>
          </Stack>
        ) : (
          <Text>ブラウザのタブを閉じてください。</Text>
        )}
      </StaticScreenContent>
    </StaticScreen>
  )
}
