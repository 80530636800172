import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import { CancelReferredPatientButton_CancelReferredPatientDocument } from '../../../../lib/gql-client'
import { useGqlMutation } from '../../../../lib/gql-client/request'

type Props = {
  referredPatientReferUid: string
  mainHospitalName: string
  mutateReferredPatient: () => void
}

export const CancelReferredPatientButton: React.FC<Props> = ({
  referredPatientReferUid,
  mainHospitalName,
  mutateReferredPatient,
}) => {
  const {
    isOpen: isCancelReferredPatientModalOpen,
    onOpen: onCancelReferredPatientModalOpen,
    onClose: onCancelReferredPatientModalClose,
  } = useDisclosure()

  const toast = useMirohaToast()

  const { request: cancelRefer } = useGqlMutation(
    CancelReferredPatientButton_CancelReferredPatientDocument,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: '紹介を取り消しました',
        })
        onCancelReferredPatientModalClose()
        mutateReferredPatient()
      },
    },
  )

  return (
    <>
      <Button variant="outline" onClick={onCancelReferredPatientModalOpen}>
        紹介取消
      </Button>

      <Modal
        isOpen={isCancelReferredPatientModalOpen}
        onClose={() => {
          onCancelReferredPatientModalClose()
        }}
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>紹介を取り消しますか？</ModalHeader>
          <ModalBody>
            <FormControl>
              <Text>
                取り消した場合、{mainHospitalName}
                からは紹介患者の情報が参照できなくなりますがよろしいですか？
              </Text>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="red"
              onClick={async () => {
                await cancelRefer({
                  input: {
                    referredPatientReferUid: referredPatientReferUid,
                  },
                })
              }}
            >
              取り消す
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
