import { useCallback, useEffect, useState } from 'react'

const UPDATE_STORAGE_EVENT_NAME = 'update-storage'

export const useLocalStorage = <Data = any>(key: string) => {
  const initialStoredData = localStorage.getItem(key)
  const [_data, _setData] = useState(getData<Data>(initialStoredData))

  const setData = useCallback(
    (newData: Data) => {
      localStorage.setItem(key, JSON.stringify(newData))
      _setData(newData)
      // イベントを強制的に発火させる（同一タブではstorageイベントが発火しない）
      // cf) https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
      window.dispatchEvent(new Event(UPDATE_STORAGE_EVENT_NAME))
    },
    [key],
  )

  const setDataWithCallback = useCallback(
    (callback: (prev: Data | undefined) => Data | undefined) => {
      _setData(prev => {
        const newData = callback(prev)
        if (newData !== undefined) {
          localStorage.setItem(key, JSON.stringify(newData))
        }
        return newData
      })
      window.dispatchEvent(new Event(UPDATE_STORAGE_EVENT_NAME))
    },
    [key],
  )

  const removeData = useCallback(() => {
    localStorage.removeItem(key)
  }, [key])

  const resetData = useCallback(() => {
    localStorage.removeItem(key)
    _setData(undefined)
  }, [key])

  const syncData = useCallback(() => {
    const updatedData = localStorage.getItem(key)
    _setData(getData<Data>(updatedData))
  }, [key])

  // useLocalStorageを呼んでいるすべてのコンポーネントで更新されるようにする
  useEffect(() => {
    window.addEventListener(UPDATE_STORAGE_EVENT_NAME, syncData)
    return () => window.removeEventListener(UPDATE_STORAGE_EVENT_NAME, syncData)
  }, [syncData])

  return { data: _data, setData, setDataWithCallback, resetData, removeData }
}

const getData = <Data>(value: ReturnType<typeof localStorage.getItem>) => {
  return !!value ? (JSON.parse(value) as Data) : undefined
}
