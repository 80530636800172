// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgExternalLink = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M15.9.6c-.1-.2-.3-.4-.5-.5-.1-.1-.3-.1-.4-.1H9.5c-.6 0-1 .4-1 1s.4 1 1 1h3.1L7.8 6.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.2 0 .5-.1.7-.3L14 3.4v3.1c0 .6.4 1 1 1s1-.4 1-1V1c0-.1 0-.3-.1-.4Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M12 14H2V4h5V2H1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V9h-2v5Z"
    />
  </svg>
)
export default SvgExternalLink
