// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgCameraOff = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="m15 4-3 2.4v-1c0-.8-.6-1.4-1.4-1.4H7l8 8h1V4h-1ZM.8 2.2 2.6 4H1.4C.6 4 0 4.6 0 5.4v5.2c0 .8.6 1.4 1.4 1.4h9.2l3.2 3.2 1.4-1.4-13-13L.8 2.2Z"
    />
  </svg>
)
export default SvgCameraOff
