import React from 'react'

import { Button } from 'src/components/base/button/button'

type Props = {
  onSave: () => void
  disabled: boolean
}

export const SaveButton: React.FC<Props> = ({ onSave, disabled }) => {
  return (
    <Button
      text={'一時保存'}
      size="S"
      buttonType="normal"
      onClick={onSave}
      disabled={disabled}
    />
  )
}
