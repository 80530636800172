import React from 'react'

import { Center, Text } from '@chakra-ui/react'

type Props = {}

/**
 * 交付メール再送完了画面
 * @deprecated use DeliveryDocUrlIsExpired
 */
export const ResendDeliveryMail: React.FC<Props> = () => {
  return (
    <Center bg="green.50" w="full" h="full">
      <Center
        w="732px"
        bg="white"
        py={12}
        borderRadius="10px"
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
      >
        <Text fontSize="lg" fontWeight="bold">
          登録メールアドレス宛に交付メールを再送しました。
        </Text>
      </Center>
    </Center>
  )
}
