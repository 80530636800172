import React from 'react'

import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'
import { FinishSession } from 'src/features/explanation/components/FinishSession/FinishSession'
import { ExplanationSession } from 'src/features/explanation/types'

type Props = {
  headerTitle: string
  session: ExplanationSession
  isPatient?: boolean
  sidebarContent: React.ReactNode
  children: React.ReactNode
}

const HEADER_HEIGHT = 64

export const ExplanationRoomLayoutRenewal: React.FC<Props> = ({
  headerTitle,
  session,
  isPatient,
  sidebarContent,
  children,
}) => {
  return (
    <Flex direction="column" w="full" h="full">
      <Grid
        templateColumns="repeat(3, 1fr)"
        h={`${HEADER_HEIGHT}px`}
        w="full"
        as="header"
        alignContent="center"
        p="6"
        borderBottom="1px solid"
        borderBottomColor="gray.400"
        boxSizing="border-box"
      >
        <GridItem>
          <img src={MirohaLogo} alt="miroha-logo" />
        </GridItem>
        <GridItem>
          <Text
            as="h1"
            fontSize="2xl"
            fontWeight="bold"
            color="green.500"
            textAlign="center"
          >
            {headerTitle}
          </Text>
        </GridItem>
        <GridItem w="full" textAlign="right">
          {!isPatient && <FinishSession session={session} />}
        </GridItem>
      </Grid>

      <Flex h={`calc(100% - ${HEADER_HEIGHT}px)`}>
        <Box bg="green.50" flex="1" h="full" overflowY="auto">
          {children}
        </Box>
        {sidebarContent !== null && (
          <Box w="25%" p="6" bg="white" h="full">
            {sidebarContent}
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
