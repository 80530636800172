import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const patientVideoCallSignIn = async ({
  trialUid,
  patientVideoCallUid,
}: {
  trialUid: string
  patientVideoCallUid: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trialUid}/patient_video_calls/{patientVideoCallUid}/signin',
    httpMethod: 'post',
    params: {
      paths: {
        trialUid,
        patientVideoCallUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const usePatientVideoCallSignIn = (option?: UseMutationOption<void>) => {
  return useMutation(
    ({
      trialUid,
      patientVideoCallUid,
    }: {
      trialUid: string
      patientVideoCallUid: string
    }) => patientVideoCallSignIn({ trialUid, patientVideoCallUid }),
    option,
  )
}
