import { Member, Role } from 'src/modules/entities/member/entity'

import { IcfDocumentSet } from './../icf-document/icf-document-set/entity'
import {
  IcfDocumentSignerRole,
  IcfDocumentRevision,
  IcfDocument,
  IcfDocumentType,
} from '../icf-document/icf-document/entity'

//base
/** @deprecated */
type Explanation = {
  uid: string
  trialHospitalUid: string
  candidateId: string //候補ID
  expDiseaseId?: string //症例番号
  status: ExplanationStatus //実施ステータス
  hospitalName: string
  dr: ExplanationTrialMember
  crc?: ExplanationTrialMember
  agreementCompletedAt?: string
  deliveredAt?: string | null
  isRequiredReAgreement: boolean
}

//詳細用
/** @deprecated */
export type ExplanationDetail = Explanation & {
  patientId: string
  email?: string
  mobileNumber?: string
  type: ExplanationType
  withRepresentative: boolean
  lastExplainedAt?: string
  scheduledAt?: string
  docSets: DocSetForExplanationDetail[]
  isReAgreement: boolean
  isRequiredReAgreement: boolean
  revisions: ExplanationRevision[]
  canAgreementDone: boolean
}

/** @deprecated */
type ExplanationRevision = {
  uid: string
  firstOperatedAt: string
}

/** @deprecated */
type ExplanationTrialMember = {
  uid: Member['uid']
  name: string
  email: string
  role: Role
}

/** @deprecated */
export type DocSetForExplanationDetail = {
  uid: string
  index: number
  icfDocumentSetUid: IcfDocumentSet['uid']
  name: string
  numberingId: number
  updatedAfterChecked: boolean
  docs: DocForExplanationDetail[]
}

/** @deprecated */
export type DocForExplanationDetail = {
  icfDocumentRevisionUid: string
  explanationDocRevisionUid?: string
  docName: string
  docNumberingId: number
  docVersion: string
  docType: IcfDocumentType
  disabled: boolean
  docIsRequiredPatientSign: boolean
  index: number
  fileUrl: string
  isVideoConverting: boolean
}

/** @deprecated */
const explanationStatus = {
  SessionNotStarted: 1,
  SessionNotDone: 2,
  AgreementNotDone: 3,
  AgreementDone: 4,
  AgreementRejected: 5,
  Disabled: 6,
  Withdrawn: 7,
} as const

/** @deprecated */
type ExplanationStatus =
  (typeof explanationStatus)[keyof typeof explanationStatus]

/** @deprecated */
export const explanationTypes = {
  inPerson: 1,
  remote: 2,
} as const

/** @deprecated */
export type ExplanationType =
  (typeof explanationTypes)[keyof typeof explanationTypes]

/** @deprecated */
type BrowserRole = (typeof browserRoles)[keyof typeof browserRoles]

/** @deprecated */
export const browserRoles = {
  Dr: 1,
  CRC: 2,
  PatientOrRepresentative: 3,
  InPerson: 4,
} as const

/** @deprecated */
export type ExplanationDocSet = {
  uid: string
  icfDocumentSetUid: IcfDocumentSet['uid']
  docSetNumberingId: number
  docSetName: string
  docs: ExpRevDocSetDocItem[]
}

/** @deprecated */
export type ExpRevDocSetDocItem = {
  docRevisionUid: string
  index: number
  icfDocRevisionUid: IcfDocumentRevision['uid']
  docNumberingId: IcfDocument['numberingId']
  docName: IcfDocumentRevision['name']
  docVersion: IcfDocumentRevision['version']
  docType: IcfDocumentRevision['type']
  isCompleted: boolean
  hasDSEnvelope: boolean
  signCompletedAt?: string
  signerRoles: IcfDocumentSignerRole[]
  browsingHistories: ExpRevDocSetDocItemBrowsingHistory[]
  signHistories: ExpRevDocSetDocIemSignHistory[]
  isRequiredPatientSign: boolean
}
/** @deprecated */
type ExpRevDocSetDocItemBrowsingHistory = {
  uid: string
  operatedAt: string
  browserRole: BrowserRole
}
/** @deprecated */
type ExpRevDocSetDocIemSignHistory = {
  uid: string
  operatedAt: string
  signerRole: IcfDocumentSignerRole
  isRejected: boolean
}
/** @deprecated */
export type ExplanationDocRevision = {
  uid: string
  icfDocRevisionUid: IcfDocumentRevision['uid']
  docNumberingId: number
  docName: string
  docType: IcfDocumentType
  docIsRequiredPatientSign: boolean
  patientSignStatus: ExplanationSignStatus
  fileUrl: string
  isCompleted: boolean
  hasDSEnvelope: boolean
  allPlacedSignerRoles: IcfDocumentSignerRole[]
  completedSignerRoles: IcfDocumentSignerRole[]
}

/** @deprecated */
export const explanationSignStatus = {
  NotDone: 0,
  Done: 1,
  Rejected: 2,
} as const

/** @deprecated */
type ExplanationSignStatus =
  (typeof explanationSignStatus)[keyof typeof explanationSignStatus]

/** @deprecated */
export type ExplanationDocBrowseState = {
  notCreateLog: boolean
}
