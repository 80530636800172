import { useEffect, useRef } from 'react'

import {
  gqlSseClient,
  ExplanationRoomType,
  parseGqlError,
  SubscribeExplanationRoomEventsSubscription,
} from 'src/lib/gql-client'

import query from './subscription.gql?raw' // graphqlファイルを文字列として読み込み

type Param = {
  explanationRoomUid: string
  roomType: ExplanationRoomType
  onNext: (
    data: SubscribeExplanationRoomEventsSubscription['explanationRoomEvents'],
  ) => void
  onComplete?: () => void
  onError?: (error: Error) => void
}

const subscribeExplanationRoomEvents = ({
  explanationRoomUid,
  roomType,
  onNext,
  onComplete = () => {},
  onError = () => {},
}: Param): { dispose: () => void } => {
  const dispose =
    gqlSseClient().subscribe<SubscribeExplanationRoomEventsSubscription>(
      {
        query,
        variables: {
          input: {
            explanationRoomUid,
            roomType,
          },
        },
      },
      {
        next: res => {
          if (res.errors) {
            onError(parseGqlError(res.errors))
          }
          if (!res.data) {
            return
          }
          onNext(res.data.explanationRoomEvents)
        },
        complete: onComplete,
        error: onError,
      },
    )
  return {
    dispose,
  }
}

type HooksParam = {
  /** nullの場合はsubscriptionを実行しません */
  explanationRoomUid: string | null
  roomType: ExplanationRoomType
  onNext: (
    data: SubscribeExplanationRoomEventsSubscription['explanationRoomEvents'],
  ) => void
  onComplete?: () => void
  onError?: (error: Error) => void
  disabled?: boolean
}

export const useSubscribeExplanationRoomEvents = ({
  explanationRoomUid,
  roomType,
  onNext,
  onComplete,
  onError,
  disabled = false,
}: HooksParam) => {
  const ref = useRef({ onNext, onComplete, onError })
  useEffect(() => {
    if (!ref.current) return
    if (disabled || explanationRoomUid === null) return
    const subscription = subscribeExplanationRoomEvents({
      explanationRoomUid,
      roomType,
      onError: ref.current.onError,
      onComplete: ref.current.onComplete,
      onNext: ref.current.onNext,
    })
    return () => {
      subscription.dispose()
    }
  }, [disabled, explanationRoomUid, roomType])
}
