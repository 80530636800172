export const icfDocumentUidParamName = 'icfDocumentUid'
export const icfDocumentRevisionUidParamName = 'icfDocumentRevisionUid'
export const icfDocumentSetUidParamName = 'icfDocumentSetUid'

export const icfDocumentRoutes = {
  addIcfDocument: 'new',
  icfDocumentSetList: 'sets',
  editIcfDocument: `:${icfDocumentUidParamName}`,
  icfDocumentRevisionVideoStream: `:${icfDocumentUidParamName}/icf-document-revisions/:${icfDocumentRevisionUidParamName}/video_stream`,
  addIcfDocumentRevision: `:${icfDocumentUidParamName}/add-revision`,
  addIcfDocumentSet: 'sets/new',
  icfDocumentSetDetail: `sets/:${icfDocumentSetUidParamName}`,
  editIcfDocumentSet: `sets/:${icfDocumentSetUidParamName}/edit`,
} as const

export type IcfDocumentRoute =
  (typeof icfDocumentRoutes)[keyof typeof icfDocumentRoutes]

export const ICF_DOCUMENT_HOSPITAL_QUERY_PARAM_KEY = 'trial-hospital'

const basePath = (trialUid: string) =>
  `/dashboard/trials/${trialUid}/e-consent/icf-documents`

const queryParam = (trialHospitalUid?: string) => {
  if (trialHospitalUid === undefined) {
    return ''
  }

  return `?${ICF_DOCUMENT_HOSPITAL_QUERY_PARAM_KEY}=${trialHospitalUid}`
}

export const getIcfDocumentListRoute = ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}${queryParam(trialHospitalUid)}`
}

export const getIcfDocumentSetListRoute = ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/${
    icfDocumentRoutes.icfDocumentSetList
  }${queryParam(trialHospitalUid)}`
}

export const getAddIcfDocumentRoute = ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/${icfDocumentRoutes.addIcfDocument}${queryParam(
    trialHospitalUid,
  )}`
}

export const getEditIcfDocumentRoute = ({
  trialUid,
  icfDocumentUid,
  trialHospitalUid,
}: {
  trialUid: string
  icfDocumentUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/${icfDocumentUid}${queryParam(
    trialHospitalUid,
  )}`
}

export const getAddIcfDocumentRevisionRoute = ({
  trialUid,
  icfDocumentUid,
  trialHospitalUid,
}: {
  trialUid: string
  icfDocumentUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/${icfDocumentUid}/add-revision${queryParam(
    trialHospitalUid,
  )}`
}

export const getIcfDocumentRevisionVideoStreamRoute = ({
  trialUid,
  icfDocumentRevisionUid,
}: {
  trialUid: string
  icfDocumentRevisionUid: string
}) => {
  return `${basePath(
    trialUid,
  )}/revisions/${icfDocumentRevisionUid}/video_stream`
}

export const getAddIcfDocumentSetRoute = ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/sets/new${queryParam(trialHospitalUid)}`
}

export const getIcfDocumentSetDetailRoute = ({
  trialUid,
  icfDocumentSetUid,
  trialHospitalUid,
}: {
  trialUid: string
  icfDocumentSetUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/sets/${icfDocumentSetUid}${queryParam(
    trialHospitalUid,
  )}`
}

export const getEditIcfDocumentSetRoute = ({
  trialUid,
  icfDocumentSetUid,
  trialHospitalUid,
}: {
  trialUid: string
  icfDocumentSetUid: string
  trialHospitalUid?: string
}) => {
  return `${basePath(trialUid)}/sets/${icfDocumentSetUid}/edit${queryParam(
    trialHospitalUid,
  )}`
}
