import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

type Params = {
  items: Array<{
    medium_item_uid: string
    index: number
    small_items: Array<{
      small_item_uid: string
      index: number
    }>
  }>
}

export type UpdateMediumItemOrderResponse = {
  medium_item_uid: string
  index: number
  small_items: Array<{
    small_item_uid: string
    index: number
  }>
}

export const updateOrder = async ({
  uid,
  trialUid,
  mediumItems,
}: {
  uid: string
  trialUid: string
  mediumItems: MediumItem[]
}): Promise<UpdateMediumItemOrderResponse[]> => {
  if (IS_MOCK_MODE) {
    return []
  }

  const params = toParams(mediumItems)

  const res = await requestPut<UpdateMediumItemOrderResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${uid}/medium_items`,
    params: params,
  })

  return res.data
}

// フロントでは normalize して持っている medium item, small item を、API が期待する JSON 形式にする
const toParams = (mediumItems: MediumItem[]): Params => {
  const items: Params['items'] = mediumItems.map((mediumItem, mediumIndex) => {
    return {
      medium_item_uid: mediumItem.uid,
      index: mediumIndex,
      small_items: Object.values(mediumItem.smallItemMap).map(smallItem => {
        return {
          small_item_uid: smallItem.uid,
          index: smallItem.index,
        }
      }),
    }
  })

  return {
    items,
  }
}
