import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { MessageTab } from 'src/components/layout/message-tab'

import { actions } from './redux'
import { getMessage } from './selector'

/**
 * @deprecated Use import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast' instead.
 */
export const Flash: React.FC<{}> = props => {
  const message = useSelector(getMessage)

  const dispatch = useDispatch()

  const onComplete = useCallback(
    () => dispatch(actions.complete({})),
    [dispatch],
  )

  if (!message) return null

  return <MessageTab message={message} onComplete={onComplete} />
}
