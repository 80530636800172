import React from 'react'

import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { PullDown, PullDownItem } from 'src/components/layout/pulldown'
import { Spacer } from 'src/components/spacer/spacer'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import styled from 'styled-components'

import { ApproveModal } from '../components/approve-modal'
import { DeleteIcfDocumentModal } from '../components/delete-document-modal'
import { DisableModal } from '../components/disable-modal'
import { IcfDocumentCard } from '../components/document-card'
import { EnableModal } from '../components/enable-modal'
import { IcfDocumentRevisionStatusBadge } from '../components/icf-document-status-badge'
import { RejectModal } from '../components/reject-modal'
import { RequestApprovalModal } from '../components/request-approval-modal'
import { WithdrawModal } from '../components/withdraw-modal'
import {
  IcfDocumentRevision,
  IcfDocumentDetail,
  icfDocumentRevisionStatusText,
  icfDocumentRevisionStatus,
  IcfDocumentType,
  IcfDocumentRevisionHistory,
  IcfDocumentSignerRole,
  IcfDocumentCheckRole,
} from '../entity'
import { IcfDocumentErrors } from '../validate'

type Props = {
  icfDocument: IcfDocumentDetail | undefined
  latestRevision: IcfDocumentRevision | undefined
  listPath: string
  shouldSelectHospital: boolean
  hospitalName: string
  latestFilePreviewUrl: string
  onRedirectDocuSignEdit: () => void
  onRedirectDocuSignPreview: (
    role: IcfDocumentSignerRole | IcfDocumentCheckRole,
  ) => void
  onChangeName: (value: string) => void
  onChangeVersion: (value: string) => void
  onChangeDocumentType: (type: IcfDocumentType) => void
  onChangeRequiredPatientSign: () => void
  onSelectFile: (file: File) => Promise<void>
  errors: IcfDocumentErrors
  canChangeDocumentType: boolean
  canUpdate: boolean
  canEdit: boolean
  canRequestApproval: boolean
  canWithdraw: boolean
  canApprove: boolean
  canReject: boolean
  canDisable: boolean
  canAddRevision: boolean
  canSetDocuSignRole: boolean
  hasSetDocuSignRole: boolean
  onValidate: () => boolean
  onSubmitUpdate: () => Promise<void>
  onSubmitDelete: () => Promise<void>
  onRequestApproval: (comment: string) => Promise<void>
  onWithdraw: (comment: string) => Promise<void>
  onApprove: (comment: string) => Promise<void>
  onReject: (comment: string) => Promise<void>
  onDisable: (comment: string) => Promise<void>
  onEnable: (comment: string) => Promise<void>
  onCancelRevision: () => Promise<void>
  navigateToAddRevision: () => void
  pullDownState: ReturnType<typeof useOpenPullDown>
  deleteModalState: ReturnType<typeof useOpenModal>
  requestApprovalModalState: ReturnType<typeof useOpenModal>
  withdrawModalState: ReturnType<typeof useOpenModal>
  approveModalState: ReturnType<typeof useOpenModal>
  rejectModalState: ReturnType<typeof useOpenModal>
  disableModalState: ReturnType<typeof useOpenModal>
  enableModalState: ReturnType<typeof useOpenModal>
  hasMultipleRevisionHistories: boolean
  hasTempFile: boolean
  openRevisionHistoryPane: () => void
}

type HistoryTableKey = keyof Pick<
  IcfDocumentRevision['histories'][number],
  'status' | 'operatedAt' | 'operatedMemberName' | 'comment'
>
const historyTableCellWidth: Record<HistoryTableKey, string> = {
  status: '20%',
  operatedAt: '30%',
  operatedMemberName: '20%',
  comment: '30%',
}

const operatedAtDesc = (
  a: IcfDocumentRevisionHistory,
  b: IcfDocumentRevisionHistory,
) => {
  const aTime = new Date(a.operatedAt).getTime()
  const bTime = new Date(b.operatedAt).getTime()

  return bTime - aTime
}

export const EditIcfDocument: React.FC<Props> = ({
  icfDocument,
  latestRevision,
  errors,
  listPath,
  shouldSelectHospital,
  hospitalName,
  latestFilePreviewUrl,
  canChangeDocumentType,
  canUpdate,
  canEdit,
  canRequestApproval,
  canWithdraw,
  canApprove,
  canReject,
  canDisable,
  canAddRevision,
  canSetDocuSignRole,
  hasSetDocuSignRole,
  pullDownState,
  deleteModalState,
  requestApprovalModalState,
  withdrawModalState,
  approveModalState,
  rejectModalState,
  disableModalState,
  enableModalState,
  hasMultipleRevisionHistories,
  hasTempFile,
  onRedirectDocuSignEdit,
  onRedirectDocuSignPreview,
  onChangeName,
  onChangeVersion,
  onChangeDocumentType,
  onChangeRequiredPatientSign,
  onSelectFile,
  onWithdraw,
  onApprove,
  onReject,
  onCancelRevision,
  onValidate,
  onSubmitUpdate,
  onSubmitDelete,
  onRequestApproval,
  onDisable,
  onEnable,
  navigateToAddRevision,
  openRevisionHistoryPane,
}) => {
  if (!icfDocument || !latestRevision) {
    return null
  }

  const breadcrumbParams: BreadcrumbParam[] = [
    { label: '文書一覧', isLink: true, path: listPath },
    { label: '文書編集', isLink: false },
  ]

  const pulldownItems: PullDownItem[] = [
    ...(icfDocument.canDelete
      ? [
          {
            name: '文書を削除',
            onClick: deleteModalState.handlers.openModal,
            fontColor: red[40],
          },
        ]
      : []),
    ...(latestRevision.canCancel
      ? [
          {
            name: '改版取り消し',
            onClick: onCancelRevision, //モーダル必要?
            fontColor: red[40],
          },
        ]
      : []),
    ...(canDisable
      ? [
          {
            name: '文書を無効化',
            onClick: disableModalState.handlers.openModal,
            fontColor: red[40],
          },
        ]
      : []),
  ]

  return (
    <Wrapper>
      {deleteModalState.modalOpen && (
        <DeleteIcfDocumentModal
          onClose={deleteModalState.handlers.closeModal}
          onSubmitDelete={onSubmitDelete}
        />
      )}
      {requestApprovalModalState.modalOpen && (
        <RequestApprovalModal
          onClose={requestApprovalModalState.handlers.closeModal}
          onSubmit={onRequestApproval}
        />
      )}
      {withdrawModalState.modalOpen && (
        <WithdrawModal
          onClose={withdrawModalState.handlers.closeModal}
          onSubmit={onWithdraw}
        />
      )}
      {approveModalState.modalOpen && (
        <ApproveModal
          onClose={approveModalState.handlers.closeModal}
          numberingId={icfDocument.numberingId}
          documentName={latestRevision.name}
          onSubmit={onApprove}
        />
      )}
      {rejectModalState.modalOpen && (
        <RejectModal
          onClose={rejectModalState.handlers.closeModal}
          onSubmit={onReject}
        />
      )}
      {disableModalState.modalOpen && (
        <DisableModal
          onClose={disableModalState.handlers.closeModal}
          numberingId={icfDocument.numberingId}
          documentName={latestRevision.name}
          onSubmit={onDisable}
        />
      )}
      {enableModalState.modalOpen && (
        <EnableModal
          onClose={enableModalState.handlers.closeModal}
          numberingId={icfDocument.numberingId}
          documentName={latestRevision.name}
          onSubmit={onEnable}
        />
      )}

      <Breadcrumb breadcrumbParams={breadcrumbParams} />
      <Spacer size={32} />
      <ContentMenu>
        {shouldSelectHospital && (
          <HospitalNameContainer>
            <Text fontSize="sm" fontWeight="bold">
              医療機関 : {hospitalName}
            </Text>
          </HospitalNameContainer>
        )}
        <FlexSpacer />
        <ButtonsContainer>
          {canEdit && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="normal"
                text="一時保存"
                onClick={onSubmitUpdate}
                disabled={!canUpdate}
              />
            </ButtonContainer>
          )}
          {canRequestApproval && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="important"
                text="承認依頼"
                onClick={() => {
                  if (!onValidate()) {
                    return
                  }

                  requestApprovalModalState.handlers.openModal()
                }}
                disabled={canUpdate}
              />
            </ButtonContainer>
          )}
          {canWithdraw && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="cancel"
                text="承認依頼取り下げ"
                onClick={withdrawModalState.handlers.openModal}
              />
            </ButtonContainer>
          )}
          {canReject && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="cancel"
                text="承認却下"
                onClick={rejectModalState.handlers.openModal}
              />
            </ButtonContainer>
          )}
          {canApprove && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="important"
                text="承認"
                onClick={approveModalState.handlers.openModal}
              />
            </ButtonContainer>
          )}
          {latestRevision.latestStatus ===
            icfDocumentRevisionStatus.Disabled && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="important"
                text="有効化"
                onClick={enableModalState.handlers.openModal}
              />
            </ButtonContainer>
          )}
          {canAddRevision && (
            <ButtonContainer>
              <Button
                size="S"
                buttonType="important"
                text="改版する"
                onClick={navigateToAddRevision}
              />
            </ButtonContainer>
          )}
          {pulldownItems.length > 0 && (
            <>
              <PullDown
                items={pulldownItems}
                pulldownOpen={pullDownState.pullDownOpen}
                closePulldown={pullDownState.handlers.closePullDown}
              >
                <ButtonContainer>
                  <IconButton
                    iconName="expand3"
                    onClick={pullDownState.handlers.openPullDown}
                  />
                </ButtonContainer>
              </PullDown>
            </>
          )}
        </ButtonsContainer>
      </ContentMenu>

      <Spacer size={32} />

      {latestRevision.latestStatus === icfDocumentRevisionStatus.Disabled && (
        <DisabledLabelContainer>
          <IcfDocumentRevisionStatusBadge
            status={icfDocumentRevisionStatus.Disabled}
          />
        </DisabledLabelContainer>
      )}

      <CardContainer>
        {canEdit ? (
          <IcfDocumentCard
            cardType="edit"
            numberingId={icfDocument.numberingId}
            canChangeDocumentType={canChangeDocumentType}
            canSetDocuSignRole={canSetDocuSignRole}
            version={latestRevision.version}
            name={latestRevision.name}
            documentType={latestRevision.type}
            fileName={latestRevision.fileName}
            isRequiredPatientSign={latestRevision.isRequiredPatientSign}
            filePreviewUrl={latestFilePreviewUrl}
            signerRoles={latestRevision.signerRoles}
            checkRoles={latestRevision.checkRoles}
            errors={errors}
            hasMultipleRevisionHistories={hasMultipleRevisionHistories}
            hasTempFile={hasTempFile}
            hasSetDocuSignRole={hasSetDocuSignRole}
            onChangeName={onChangeName}
            onChangeVersion={onChangeVersion}
            onChangeDocumentType={onChangeDocumentType}
            onChangeRequiredPatientSign={onChangeRequiredPatientSign}
            onSelectFile={onSelectFile}
            onRedirectDocuSignEdit={onRedirectDocuSignEdit}
            openRevisionHistory={openRevisionHistoryPane}
          />
        ) : (
          <IcfDocumentCard
            cardType="readOnly"
            numberingId={icfDocument.numberingId}
            version={latestRevision.version}
            name={latestRevision.name}
            documentType={latestRevision.type}
            fileName={latestRevision.fileName}
            filePreviewUrl={latestFilePreviewUrl}
            isRequiredPatientSign={latestRevision.isRequiredPatientSign}
            disabled={
              latestRevision.latestStatus === icfDocumentRevisionStatus.Disabled
            }
            signerRoles={latestRevision.signerRoles}
            checkRoles={latestRevision.checkRoles}
            onRedirectDocuSignPreview={onRedirectDocuSignPreview}
            hasMultipleRevisionHistories={hasMultipleRevisionHistories}
            hasTempFile={hasTempFile}
            openRevisionHistory={openRevisionHistoryPane}
          />
        )}
      </CardContainer>
      <Spacer size={32} />
      <Text fontWeight="bold">変更履歴</Text>
      <Spacer size={8} />
      <HistoryTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell width={historyTableCellWidth['status']}>
                操作
              </TableHeadCell>
              <TableHeadCell width={historyTableCellWidth['operatedAt']}>
                操作日時
              </TableHeadCell>
              <TableHeadCell
                width={historyTableCellWidth['operatedMemberName']}
              >
                操作者
              </TableHeadCell>
              <TableHeadCell width={historyTableCellWidth['comment']}>
                コメント
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {latestRevision.histories.sort(operatedAtDesc).map(history => (
              <TableRow key={history.uid} borderBottom>
                <TableBodyCell width={historyTableCellWidth['status']}>
                  {icfDocumentRevisionStatusText[history.status]}
                </TableBodyCell>
                <TableBodyCell width={historyTableCellWidth['operatedAt']}>
                  {dayjs(history.operatedAt).format('YYYY/MM/DD HH:mm:ss')}
                </TableBodyCell>
                <TableBodyCell
                  width={historyTableCellWidth['operatedMemberName']}
                >
                  {history.operatedMemberName}
                </TableBodyCell>
                <TableBodyCell width={historyTableCellWidth['comment']}>
                  {history.comment}
                </TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HistoryTableContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ContentMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HospitalNameContainer = styled.div`
  display: flex;
  align-items: center;
`

const FlexSpacer = styled.div`
  flex: 1;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`
const ButtonContainer = styled.div`
  margin-left: 16px;
`

const DisabledLabelContainer = styled.div`
  display: inline-block;
  margin-bottom: 16px;
`

const CardContainer = styled.div`
  width: 100%;
  max-width: 800px;
`

const HistoryTableContainer = styled.div`
  height: 300px;
  max-width: 800px;
`
