import { GqlError, SetPinDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const setPin = async ({
  explanationRevisionUid,
  pin,
}: {
  explanationRevisionUid: string
  pin: string
}): Promise<void> => {
  try {
    await graphqlRequest(SetPinDocument, {
      input: {
        explanationRevisionUid,
        pin,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useSetPin = (option?: UseMutationOption<void, GqlError>) => {
  return useMutation(
    ({
      explanationRevisionUid,
      pin,
    }: {
      explanationRevisionUid: string
      pin: string
    }) =>
      setPin({
        explanationRevisionUid,
        pin,
      }),
    option,
  )
}
