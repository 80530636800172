import React, { createContext, useCallback, useContext, useState } from 'react'

import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import { Button as ButtonLegacy } from 'src/components/base/button/button'
import { Modal as ModalLegacy } from 'src/components/base/modal/modal'
import { ModalActions as ModalActionsLegacy } from 'src/components/base/modal/modal-actions'
import { ModalContent as ModalContentLegacy } from 'src/components/base/modal/modal-content'
import { ModalTitle as ModalTitleLegacy } from 'src/components/base/modal/modal-title'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type ModalOption = {
  title: React.ReactNode
  content: React.ReactNode
  submitText: string
  submitButtonColor?: 'blue' | 'red'
  size?: ModalProps['size']
  legacy?: boolean // 旧モーダルの置き換えが完了したらこのフラグは削除する
  cancelText?: string
  hideCancel?: boolean
  onSubmit: () => void
  /** モーダルを閉じる際に実行する処理 */
  onCancel?: () => void
}

const Context = createContext<
  { showModal: (option: ModalOption) => void; isOpen: boolean } | undefined
>(undefined)

type ModalState =
  | {
      isOpen: true
      option: ModalOption
    }
  | {
      isOpen: false
      option: null
    }

type ModalProviderProps = {
  children: React.ReactNode
}

const isReactText = (content: React.ReactNode): content is React.ReactText => {
  return typeof content === 'string' || typeof content === 'number'
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [state, setState] = useState<ModalState>({
    isOpen: false,
    option: null,
  })

  const showModal = useCallback((option: ModalOption) => {
    setState({ isOpen: true, option })
  }, [])

  const closeModal = useCallback(() => {
    setState({ isOpen: false, option: null })
  }, [])

  const handleClose = useCallback(() => {
    state.option?.onCancel?.()
    setState({ isOpen: false, option: null })
  }, [state.option])

  return (
    <Context.Provider value={{ showModal, isOpen: state.isOpen }}>
      {children}

      {/* ここは削除 */}
      {state.isOpen && state.option.legacy && (
        <LegacyModal onClose={closeModal} option={state.option} />
      )}

      {state.isOpen && !state.option.legacy && (
        <Modal isOpen={true} onClose={handleClose} size={state.option.size}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {isReactText(state.option.title) ? (
                <Text fontSize="lg" fontWeight="bold">
                  {state.option.title}
                </Text>
              ) : (
                state.option.title
              )}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              {isReactText(state.option.content) ? (
                <Text>{state.option.content}</Text>
              ) : (
                state.option.content
              )}
            </ModalBody>

            <ModalFooter>
              {!state.option.hideCancel && <ModalCancelButton />}
              <Button
                colorScheme={state.option.submitButtonColor ?? 'blue'}
                onClick={() => {
                  state.option.onSubmit()
                  closeModal()
                }}
              >
                {state.option.submitText}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Context.Provider>
  )
}

const LegacyModal: React.FC<{
  onClose: () => void
  option: ModalOption
}> = ({ onClose, option }) => {
  return (
    <ModalLegacy onClose={onClose}>
      {isReactText(option.title) ? (
        <ModalTitleLegacy title={option.title.toString()} />
      ) : (
        option.title
      )}
      <ModalContentLegacy>
        <Center>
          {isReactText(option.content) ? (
            <Text>{option.content}</Text>
          ) : (
            option.content
          )}
        </Center>
      </ModalContentLegacy>
      <ModalActionsLegacy>
        <HStack spacing="10">
          {!option.hideCancel && (
            <ButtonLegacy
              buttonType="cancel"
              text={option.cancelText ?? 'キャンセル'}
              onClick={() => {
                onClose()
              }}
            />
          )}
          <ButtonLegacy
            buttonType="important"
            text={option.submitText}
            onClick={() => {
              option.onSubmit()
              onClose()
            }}
          />
        </HStack>
      </ModalActionsLegacy>
    </ModalLegacy>
  )
}

export const useModal = () => {
  const context = useContext(Context)
  // contextがundefined（デフォルト値）である場合、初期化ががなされていないためエラーとする
  if (context === undefined) {
    const error = 'useModal must be called inside ModalProvider'
    console.error(error)
    throw new Error(error)
  }

  return context
}
