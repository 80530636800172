import { Fragment } from 'react'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'
import { useForm } from 'react-hook-form'
import { PageNext } from 'src/components/icon'
import { useCurrentMember } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { isNotNullish } from 'src/utils/isNotNullish'

import { useSaveWorksheet } from '../../api'
import { useDeleteSticky } from '../../api/deleteSticky'
import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { FieldValueParamToSave } from '../../hooks/useWorksheetValue'
import { WorksheetDetail } from '../../types'
import { findSection } from '../../utils/findSection'
import { getFlattenFieldMap } from '../../utils/getFlattenFields'
import { getFieldValueText } from '../../utils/getValueText'
import { moveForwardIndex } from '../../utils/moveForwardIndex'
import { sortFieldValueParam } from '../../utils/sortFieldValueParam'
import { MAX_TEXT_LENGTH } from '../../utils/validate'
import { FileFieldValueView } from '../FileFieldValueView/FileFieldValueView'

type Props = {
  isFollowUp: boolean
  worksheet: WorksheetDetail
  mutateWorksheet: (worksheet: WorksheetDetail) => void
}

// { [fieldValueUid]: reason }
type FormValue = Record<string, string>

export const SaveWorksheet: React.FC<Props> = ({
  isFollowUp,
  worksheet,
  mutateWorksheet,
}) => {
  const {
    isChanged,
    isVisibleField,
    areValuesToSaveValid,
    fieldValueParamsToSave,
    findFieldValue,
    updateFieldValueOnSave,
    droppedIndex,
    reset,
  } = useWorksheetValueContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useMirohaToast()
  const { currentMember } = useCurrentMember()

  const { request: deleteSticky } = useDeleteSticky()

  const deleteHiddenStickies = async () => {
    const requests = worksheet.fields
      .map(f =>
        !!f.stickyUid && !isVisibleField(f.fid, f.fieldIndex)
          ? deleteSticky({ stickyUid: f.stickyUid! })
          : null,
      )
      .filter(isNotNullish)
    try {
      await Promise.all(requests).then(results => {
        if (results.some(r => r.result === 'error')) {
          throw new Error('付箋の削除に失敗しました')
        }
      })
    } catch (error) {
      throw error
    }
  }

  const { request: saveWorksheet } = useSaveWorksheet({
    onRequestStarted: async () => {
      await deleteHiddenStickies()
    },
    onSuccess: async savedWorksheet => {
      mutateWorksheet(savedWorksheet)
      toast({
        status: 'success',
        title: 'ワークシートを一時保存しました',
      })
      reset(savedWorksheet)
      onClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid, errors },
  } = useForm<FormValue>({
    mode: 'onChange',
  })

  const fieldMap = getFlattenFieldMap(worksheet.schema.fields)

  // rootになるフィールドを取得
  // 必ずしもschema上のrootではなく、visibleIfで依存を辿って最も上層にあるvisibleなフィールドを取得する
  const findRootVisibleField = (
    fid: Field['fid'],
    index: number,
  ): Field | undefined => {
    const me = fieldMap.get(fid)
    if (!me) {
      throw new Error(`Field not found: ${fid}`)
    }
    if (me.visibleIf?.operand !== 'hasChoice') {
      return me
    }

    const isVisible = isVisibleField(me.fid, index)
    if (isVisible) {
      return me
    }

    return findRootVisibleField(me.visibleIf.fid, index)
  }

  // リクエストに含める変更理由を取得
  // 入力あり -> 入力値
  // 入力なし && 新規登録 -> '新規登録'
  // 入力なし && 親依存での変更 -> 親の変更理由
  const getReason = (
    data: FormValue,
    param: (typeof fieldValueParamsToSave)[number],
  ) => {
    if (data[param.fieldValue.uid]) {
      return data[param.fieldValue.uid]
    }
    if (param.isNew) {
      return '新規登録'
    }
    if (param.toBeClearedWithCondition) {
      const rootVisibleField = findRootVisibleField(
        param.fieldValue.fid,
        param.fieldValue.index,
      )
      if (!rootVisibleField) {
        throw new Error(`Root visible field not found: ${param.fieldValue.fid}`)
      }
      const rootValue = findFieldValue({
        fid: rootVisibleField.fid,
        index: param.fieldValue.index,
      })
      if (!rootValue) {
        throw new Error(`Root value not found: ${rootVisibleField.fid}`)
      }
      // 親の変更理由と同じ
      return data[rootValue.uid] ?? ''
    }
    return ''
  }

  const onSubmit = handleSubmit(async data => {
    await saveWorksheet({
      worksheetUid: worksheet.uid,
      fieldValues: fieldValueParamsToSave.map(param => ({
        ...updateFieldValueOnSave(param),
        reason: getReason(data, param),
      })),
    })
  })

  // 新規登録の場合、ネストされたフィールドの場合以外は変更理由が必須
  const areReasonsValid =
    fieldValueParamsToSave
      .filter(param => !(param.isNew || param.toBeClearedWithCondition))
      .map(param => watch(param.fieldValue.uid))
      .every(Boolean) && isValid

  // TODO: use permission definition
  if (currentMember.role === 'DM' || currentMember.role === 'CRA') {
    return null
  }

  if (worksheet.status === 'confirmed' || worksheet.status === 'disabled') {
    return null
  }

  const fields = Array.from(fieldMap.values())

  const repeatableSectionChildrenFids = fields.reduce(
    (acc, field) => {
      if (field.typeDef.type === 'RepeatableSection') {
        return [...acc, ...field.typeDef.fields.map(f => f.fid)]
      }
      return acc
    },
    [] as Field['fid'][],
  )

  const isRepeatableSectionChild = (fid: Field['fid']) =>
    repeatableSectionChildrenFids.includes(fid)

  const getFieldNameText = (field: Field, param: FieldValueParamToSave) => {
    if (field.typeDef.type === 'RepeatableSection') {
      // #行番号
      return (
        <Text fontWeight="bold">
          {`#${moveForwardIndex(param.fieldValue.index, droppedIndex)}`}
        </Text>
      )
    }

    if (isRepeatableSectionChild(field.fid)) {
      // #行番号 + フィールド名
      return (
        <Text fontWeight="bold">{`#${moveForwardIndex(
          param.fieldValue.index,
          droppedIndex,
        )} ${field.name}`}</Text>
      )
    }
    return <Text fontWeight="bold">{field.name}</Text>
  }

  const getChangedValueText = (
    param: FieldValueParamToSave,
    type: 'new' | 'old',
  ): React.ReactNode => {
    const value = type === 'new' ? param.fieldValue : param.fetchedFieldValue
    if (!value) {
      return null
    }
    if (value.type === 'repeatableSection') {
      // ログライン行のBeforeの値は常にnull
      if (type === 'old') {
        return null
      }
      if (value.isNew) return <Text>行の追加</Text>
      if (value.isEnabled) return <Text>行の有効化</Text>
      return <Text>行の無効化</Text>
    }
    if (type === 'new' && (param.cleared || param.toBeClearedWithCondition)) {
      return <Text color="red.500">入力内容をクリア</Text>
    }
    if (value.type === 'file' && type === 'new') {
      return (
        <FileFieldValueView
          isNewValue
          oldFileFieldValue={
            param.fetchedFieldValue?.type === 'file'
              ? param.fetchedFieldValue
              : undefined
          }
          newFileFieldValue={value}
        />
      )
    }
    if (value.type === 'file' && type === 'old') {
      const newValue = param.fieldValue
      return (
        <FileFieldValueView
          isNewValue={false}
          oldFileFieldValue={value}
          newFileFieldValue={
            newValue.type === 'file' && !param.toBeClearedWithCondition
              ? newValue
              : undefined
          }
        />
      )
    }
    return (
      <Text
        color={type === 'new' ? 'green.500' : 'red.500'}
        textDecoration={type === 'new' ? 'none' : 'line-through'}
      >
        {getFieldValueText(value, fieldMap)}
      </Text>
    )
  }

  return (
    <>
      <Button
        colorScheme="blue"
        variant="outline"
        isDisabled={!isChanged || !areValuesToSaveValid}
        onClick={onOpen}
      >
        一時保存
      </Button>

      {/* TODO: fix modal size */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        scrollBehavior="inside"
      >
        <form onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              <HStack spacing="1">
                <Text>ワークシート内容の変更:</Text>
                {isFollowUp ? (
                  <HStack>
                    <Text>{`#${worksheet.index} ${worksheet.name}`}</Text>
                    <Text fontSize="sm" fontWeight="bold" color="gray.500">
                      {worksheet.schema.name}
                    </Text>
                  </HStack>
                ) : (
                  <Text>{worksheet.name}</Text>
                )}
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Box mb={4}>
                <Text>フィールドの入力内容を保存します。</Text>
                <Text>変更を保存する場合は変更理由を入力してください。</Text>
              </Box>
              <TableContainer>
                <Box overflowY="auto">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>フィールド名</Th>
                        <Th>
                          <Flex w="full" gap={10}>
                            <Flex flex="1">
                              <Text>変更前</Text>
                            </Flex>
                            <Box flex="1">
                              <Text>入力値（変更後）</Text>
                            </Box>
                          </Flex>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortFieldValueParam(
                        fieldMap,
                        fieldValueParamsToSave,
                      ).map(param => {
                        const field = fieldMap.get(param.fieldValue.fid)
                        if (!field) {
                          throw new Error(
                            `Field not found: ${param.fieldValue.fid}`,
                          )
                        }
                        return (
                          <Fragment key={param.fieldValue.uid}>
                            <Tr>
                              <Td
                                align="center"
                                border="none"
                                verticalAlign="top"
                                w="30%"
                              >
                                <Text fontSize="xs" color="gray.300">
                                  {
                                    findSection(fieldMap, param.fieldValue.fid)
                                      ?.name
                                  }
                                </Text>
                                {getFieldNameText(field, param)}
                              </Td>
                              <Td border="none" pb="0">
                                <Flex w="full" align="start" gap={4}>
                                  <Flex
                                    flex="1"
                                    align="center"
                                    bg="gray.50"
                                    p="3"
                                    color="red.500"
                                    minH="56px"
                                  >
                                    {getChangedValueText(param, 'old')}
                                  </Flex>
                                  <Box color="gray.500" mt="4">
                                    <PageNext size="20px" />
                                  </Box>
                                  <Flex
                                    flex="1"
                                    align="center"
                                    bg="blue.50"
                                    p="3"
                                    color="green.500"
                                    minH="56px"
                                  >
                                    {getChangedValueText(param, 'new')}
                                  </Flex>
                                </Flex>
                              </Td>
                            </Tr>
                            <Tr w="full">
                              <Td />
                              <Td pt="4">
                                {param.isNew ? null : param.toBeClearedWithCondition ? (
                                  <Box>
                                    <FormLabel as="p">変更理由</FormLabel>
                                    <Text>{`「${
                                      findRootVisibleField(
                                        param.fieldValue.fid,
                                        param.fieldValue.index,
                                      )?.name
                                    }」の変更理由と同様`}</Text>
                                  </Box>
                                ) : (
                                  <FormControl
                                    isInvalid={
                                      errors[param.fieldValue.uid]?.type ===
                                      'maxLength'
                                    }
                                    mt={
                                      errors[param.fieldValue.uid]?.type ===
                                      'maxLength'
                                        ? 3
                                        : 0
                                    }
                                  >
                                    <FormLabel>変更理由</FormLabel>
                                    <Input
                                      {...register(param.fieldValue.uid, {
                                        maxLength: {
                                          value: MAX_TEXT_LENGTH,
                                          message: `${MAX_TEXT_LENGTH}文字以内で入力してください。`,
                                        },
                                      })}
                                      placeholder="変更理由を入力してください"
                                    />
                                    {errors[param.fieldValue.uid]?.type ===
                                      'maxLength' && (
                                      <FormErrorMessage
                                        color="red.500"
                                        fontSize="xs"
                                        mt="1.5"
                                        mb="0"
                                      >
                                        {MAX_TEXT_LENGTH}
                                        文字以内で入力してください。
                                      </FormErrorMessage>
                                    )}
                                  </FormControl>
                                )}
                              </Td>
                            </Tr>
                          </Fragment>
                        )
                      })}
                    </Tbody>
                  </Table>
                </Box>
              </TableContainer>
            </ModalBody>
            <ModalFooter>
              <ModalCancelButton />
              <Button
                colorScheme="blue"
                isDisabled={!areReasonsValid}
                onClick={onSubmit}
              >
                一時保存する
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  )
}
