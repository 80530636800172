import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { ReleaseModal } from './modal'
import { useSubmitRelease } from './use-submit-release'

type Props = {
  closeModal: () => void
}

export const ReleaseModalContainer: React.FC<Props> = props => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { request, requesting, errorMessage } = useSubmitRelease({
    trialUid: selectedTrial.uid,
  })
  const onSubmit = useCallback(async () => {
    try {
      await request()
    } finally {
      props.closeModal()
    }
  }, [props, request])

  return (
    <ReleaseModal
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
      closeModal={props.closeModal}
    />
  )
}
