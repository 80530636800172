import { Dictionary } from 'lodash'
import { createFixture, fixture } from 'src/modules/entities/member/fixture'
import { Hospital } from 'src/modules/entities/select-trial/entity'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Member, Role } from './entity'

export type MemberResponse = {
  member_uid: string
  trial_uid: string
  role: Role
  first_name: string
  last_name: string
  email: string
  trial_hospitals: Hospital[]
  created_at: string
  updated_at: string
  deleted_at: string | null
  registered_at: string | null
}

export const parse = (resp: MemberResponse): Member => {
  const hospital: Dictionary<string> = {}
  if (resp.trial_hospitals) {
    resp.trial_hospitals.map(h => {
      return (hospital[h.uid] = h.name)
    })
  }

  return {
    uid: resp.member_uid,
    firstName: resp.first_name,
    lastName: resp.last_name,
    email: resp.email,
    role: resp.role,
    createdAt: resp.created_at,
    updatedAt: resp.updated_at,
    deletedAt: resp.deleted_at,
    registeredAt: resp.registered_at,

    trialHospitals: hospital,
    trialUid: resp.trial_uid,
  }
}

export const fetch = async ({
  trialUid,
  type,
}: {
  trialUid: string
  type: 'all' | 'valid'
}): Promise<Member[]> => {
  if (IS_MOCK_MODE) {
    return [fixture]
  }

  const url =
    type === 'valid'
      ? `${SERVER_URL}/trials/${trialUid}/members?type=valid`
      : `${SERVER_URL}/trials/${trialUid}/members`

  const resp = await requestGet<MemberResponse[]>({
    url: url,
    params: [],
  })

  return resp.data.map(parse)
}

export const fetchDetail = async ({
  trialUid,
  memberUid,
}: {
  trialUid: string
  memberUid: string
}): Promise<Member> => {
  if (IS_MOCK_MODE) {
    const res = createFixture()
    res.uid = memberUid
    return res
  }

  const res = await requestGet<MemberResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/members/${memberUid}`,
    params: {},
  })

  return parse(res.data)
}
