export const getLoginRoute = ({ trialUid }: { trialUid: string }) => {
  return `/epro/trials/${trialUid}/login`
}

export const getListRoute = ({ trialUid }: { trialUid: string }) => {
  return `/epro/trials/${trialUid}/questionnaire_answers`
}

export const getDetailRoute = ({
  trialUid,
  questionnaireAnswerUid,
  questionnaireSectionAnswerUid,
}: {
  trialUid: string
  questionnaireAnswerUid: string
  questionnaireSectionAnswerUid: string
}) => {
  return `/epro/trials/${trialUid}/questionnaire_answers/${questionnaireAnswerUid}/pages/${questionnaireSectionAnswerUid}`
}

export const getConfirmRoute = ({
  trialUid,
  questionnaireAnswerUid,
}: {
  trialUid: string
  questionnaireAnswerUid: string
}) => {
  return `/epro/trials/${trialUid}/questionnaire_answers/${questionnaireAnswerUid}/confirm`
}

export const getCompleteRoute = ({
  trialUid,
  questionnaireAnswerUid,
}: {
  trialUid: string
  questionnaireAnswerUid: string
}) => {
  return `/epro/trials/${trialUid}/questionnaire_answers/${questionnaireAnswerUid}/complete`
}
