import React from 'react'

import { Button } from '../base/button/button'
import { Message } from '../base/message/message'
import { Modal } from '../base/modal/modal'
import { ModalActions } from '../base/modal/modal-actions'
import { ModalContent } from '../base/modal/modal-content'
import { ModalTitle } from '../base/modal/modal-title'
import { Spacer } from '../spacer/spacer'

type Props = {
  title: string
  submitText: string
  cancelText?: string
  errorMessage?: string
  onSubmit: () => void
  onClose: () => void
  requesting?: boolean
  disabled?: boolean
  children: React.ReactNode
}

// children: description
export const SubmitModal: React.FC<Props> = props => {
  const onSubmit = () => {
    props.onSubmit()
  }

  return (
    <Modal onClose={props.onClose} closable={!props.requesting}>
      <ModalTitle title={props.title} />

      <ModalContent>{props.children}</ModalContent>

      <ModalActions>
        <Button
          size="S"
          text={props.cancelText ?? 'キャンセル'}
          buttonType="cancel"
          onClick={props.onClose}
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text={props.submitText}
          buttonType="important"
          onClick={onSubmit}
          disabled={props.disabled}
        ></Button>
      </ModalActions>
      {props.errorMessage && (
        <div>
          <Spacer size={8} />
          <Message type="error" message={props.errorMessage} centered />
        </div>
      )}
    </Modal>
  )
}
