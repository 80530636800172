import { useCallback } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import { QuestionnaireSectionAnswer } from 'src/modules/entities/questionnaire-section-answer/entity'
import { useRequestState } from 'src/modules/server/use-request-state'
import { eproPatientUserKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { Confirm } from './confirm'
import { trialIdParamName } from '../../epro-page'
import { fetchSingle, confirm } from '../request'
import { questionnaireAnswerIdParamName } from '../router'
import { getCompleteRoute, getDetailRoute } from '../routes'

export const ConfirmContainer = () => {
  const { trialUid = '', questionnaireAnswerUid = '' } = useParams<{
    [trialIdParamName]: string
    [questionnaireAnswerIdParamName]: string
  }>()

  const { data: questionnaireAnswer, error } = useSWR<
    QuestionnaireAnswer,
    Error
  >(
    eproPatientUserKeys.fetchQuestionnaireAnswerSingle(
      trialUid,
      questionnaireAnswerUid,
    ),
    fetchSingle,
  )

  const { requestStarted, requestDone, requestFailed } = useRequestState()

  const navigate = useNavigate()

  const onSendAnswer = useCallback(async () => {
    try {
      requestStarted()
      await confirm({ trialUid, questionnaireAnswerUid })
      navigate(
        getCompleteRoute({
          trialUid,
          questionnaireAnswerUid,
        }),
      )
    } catch (e) {
      requestFailed(e.message)
    } finally {
      requestDone()
    }
  }, [
    navigate,
    questionnaireAnswerUid,
    requestDone,
    requestFailed,
    requestStarted,
    trialUid,
  ])

  const onBack = useCallback(() => {
    if (!questionnaireAnswer) return

    const indexAsc = (
      a: QuestionnaireSectionAnswer,
      b: QuestionnaireSectionAnswer,
    ) => a.index - b.index
    const firstQuestionnaireSectionAnswerUid =
      questionnaireAnswer.sectionAnswers.sort(indexAsc)[0].uid

    navigate(
      getDetailRoute({
        trialUid,
        questionnaireAnswerUid,
        questionnaireSectionAnswerUid: firstQuestionnaireSectionAnswerUid,
      }),
    )
  }, [navigate, questionnaireAnswer, questionnaireAnswerUid, trialUid])

  if (error) return <>{error.message}</>
  if (!questionnaireAnswer) return null

  return (
    <Confirm
      questionnaireAnswer={questionnaireAnswer}
      onSendAnswer={onSendAnswer}
      onBack={onBack}
    />
  )
}
