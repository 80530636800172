import { HttpResponse } from 'msw'
import {
  createCreateFollowUpWorksheetsPayloadMock,
  CreateFollowUpWorksheetsDocument,
  CreateFollowUpWorksheetsMutation,
  CreateFollowUpWorksheetsMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const createFollowUpWorksheetsHandler = graphQLHandlerFactory<
  CreateFollowUpWorksheetsMutation,
  CreateFollowUpWorksheetsMutationVariables
>('mutation', CreateFollowUpWorksheetsDocument, () => {
  return HttpResponse.json({
    data: {
      createFollowUpWorksheets: createCreateFollowUpWorksheetsPayloadMock({
        worksheetUids: [generateUUID(), generateUUID(), generateUUID()],
      }),
    },
  })
})
