import { Trial } from 'src/features/trial/types'
import { createApiClient } from 'src/lib/api-client'

import { ExplanationRevision, UpdateExplanationInput } from '../../types'

export const updateExplanationRevision = async ({
  trialUid,
  explanationRevisionUid,
  input,
}: {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
  input: UpdateExplanationInput
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}',
    httpMethod: 'put',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: {
        type: input.type,
        docSets: input.docSets.map(docSet => ({
          icfDocumentSetUID: docSet.uid,
          index: docSet.index,
        })),
        members: input.members.map(member => ({
          trialHospitalUid: member.trialHospitalUid,
          trialMemberUid: member.memberUid,
        })),
        notifyToPatient: input.notifyToPatient ?? false,
        partnerTrialHospital: input.partnerTrialHospitalUid,
        scheduledAt: input.scheduledAt,
        candidateId: input.candidateId,
        expDiseaseId: input.expDiseaseId ?? '',
        email: input.email,
        mobileNumber: input.phoneNumber,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}
