import { useState, useMemo } from 'react'

export const useOpenPullDown = () => {
  const [pullDownOpen, setPullDownOpen] = useState(false)
  const handlers = useMemo(
    () => ({
      openPullDown: () => {
        setPullDownOpen(true)
      },
      closePullDown: () => {
        setPullDownOpen(false)
      },
    }),
    [],
  )

  return { pullDownOpen, handlers }
}
