import React, { useState, useEffect } from 'react'

import { HStack, Text } from '@chakra-ui/react'
import OutsideClickHandler from 'react-outside-click-handler'
import {
  Filter,
  RightArrow,
  Seek,
  Sort,
  SortAscend,
  SortDescend,
} from 'src/components/__legacy__icon/monochrome'
import { blue, gray, white } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import {
  Selectbox,
  SelectboxItem,
} from 'src/components/form-redesigned/selectbox'
import { SingleCheckboxWithoutValue } from 'src/components/form-redesigned/single-checkbox'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import { useSearchPatient } from './use-search-patient'
import { usePermission } from '../../../common/permission'
import { SortKey, sortKeys, canSortByPermission, sortKeyStrings } from '../sort'
import { usePatientQuery } from '../use-patient-query'

type Props = {
  onClose: () => void
}

// FIXME: paneの共通コンポーネントを使う
export const SearchPane: React.FC<Props> = props => {
  const { onClose } = props
  const { onSearch, statusFilterHandlers, sortHandlers } = useSearchPatient()
  const { searchQuery, statusFilter, sortKey, sortOrder } = usePatientQuery()
  const [displayingText, setDisplayingText] = useState(searchQuery)
  const [IMETexting, setIMETexting] = useState(false)
  const [timeoutFunc, setTimeoutFunc] = useState<NodeJS.Timeout | null>(null)
  const { hasPermission } = usePermission()

  //ブラウザバック等によるsearchQueryの変化に追随するため、searchQueryが変化するタイミングで表示文字列を更新する。
  useEffect(() => {
    setDisplayingText(searchQuery)
  }, [searchQuery])

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayingText(e.target.value)
    if (IMETexting) {
      return
    }
    //200ms後にonSearchを実行するtimerを定義し、onChangeが呼ばれるたびに前のtimerをclearして新たなtimerをスタートする。
    //こうすることで、連打などによって200ms内に連続してリクエストを飛ばすことを防ぐ。
    if (timeoutFunc) {
      clearTimeout(timeoutFunc)
    }
    const delayOnSearch = setTimeout(() => {
      onSearch(e.target.value)
    }, 200)

    setTimeoutFunc(delayOnSearch)
  }

  const startIMETexting = () => setIMETexting(true)
  const endIMETexting = () => {
    setIMETexting(false)
    onSearch(displayingText)
  }

  const canReadPatientDetail = hasPermission(PERMISSIONS.Patient_ReadDetail)
  const canReadHospital = hasPermission(PERMISSIONS.Patient_ReadHospitals)

  const validSortKeys = Object.values(sortKeys).filter(sortKey =>
    canSortByPermission({
      sortKey,
      canReadPatientDetail,
      canReadHospital,
    }),
  )

  //フリーワード検索できる対象はソート可能な項目と一致する。
  const searchInputPlaceholder = validSortKeys
    .map(k => sortKeyStrings[k])
    .join('または')

  const sortKeySelectItems: SelectboxItem<SortKey>[] = validSortKeys.map(k => ({
    value: k,
    name: sortKeyStrings[k],
  }))

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Pane>
        <Close onClick={onClose}>
          <RightArrow color={white} />
        </Close>
        <PaneInner>
          <Text fontSize="lg" fontWeight="bold">
            検索/絞り込み
          </Text>
          <Spacer size={40} />
          <Flex>
            <Seek />
            <Spacer size={8} horizontal />
            <Label bold>フリーワード検索</Label>
          </Flex>
          <Spacer size={10} />
          <Input
            value={displayingText}
            placeholder={searchInputPlaceholder}
            onChange={e => onChangeSearchInput(e)}
            onCompositionStart={() => startIMETexting()}
            onCompositionEnd={() => endIMETexting()}
          />

          <Spacer size={50} />

          <div>
            <HStack align="center" spacing="8px">
              <Filter color={gray[80]} />
              <Label bold>絞り込み</Label>
            </HStack>

            <Spacer size={10} />

            <Flex>
              {/* インデント */}
              <Spacer size={24} horizontal />
              <div>
                <Label>ステータス</Label>
                <Spacer size={6} />
                <SingleCheckboxWithoutValue
                  checked={statusFilter === 'enabled' || statusFilter === 'all'}
                  onChange={statusFilterHandlers.onCheckEnabled}
                  label="有効"
                />

                <SingleCheckboxWithoutValue
                  checked={
                    statusFilter === 'disabled' || statusFilter === 'all'
                  }
                  onChange={statusFilterHandlers.onCheckDisabled}
                  label="無効"
                />
              </div>
            </Flex>
          </div>

          <Spacer size={50} />

          <div>
            <HStack align="center" spacing="8px">
              <Sort color={gray[80]} />
              <Label bold>並べ替え</Label>
            </HStack>

            <Spacer size={10} />

            <Flex>
              <SortKeySelectContainer>
                <Selectbox<SortKey>
                  items={sortKeySelectItems}
                  selectedValue={sortKey}
                  onChange={sortHandlers.onChangeSortKey}
                />
              </SortKeySelectContainer>

              <Spacer size={20} horizontal />

              <div>
                <SortButton
                  selected={sortOrder === 'asc'}
                  onClick={() => sortHandlers.onChangeSortOrder('asc')}
                >
                  <SortAscend color={blue[70]} />
                </SortButton>
              </div>

              <Spacer size={20} horizontal />

              <div>
                <SortButton
                  selected={sortOrder === 'desc'}
                  onClick={() => sortHandlers.onChangeSortOrder('desc')}
                >
                  <SortDescend color={blue[70]} />
                </SortButton>
              </div>
            </Flex>
          </div>
        </PaneInner>
      </Pane>
    </OutsideClickHandler>
  )
}

const Pane = styled.div`
  z-index: 1;
  position: fixed;
  top: 64px;
  right: 0;
  transform: translateX(480px);
  width: 33%;
  box-sizing: border-box;
  height: calc(100% - 64px);
  background-color: ${blue[5]};
  transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  padding: 0 0 0 35px;
  box-shadow: -4px 4px 4px rgba(83, 83, 83, 0.1);
  border-left: 2px solid ${blue[70]};
  transform: translateX(0px);
`

const PaneInner = styled.div`
  overflow-y: auto;
  padding: 40px 35px 40px 0;
  height: 100%;
`

const Close = styled.div`
  background-color: ${blue[70]};
  position: absolute;
  left: 0;
  top: 36.5px;
  border-radius: 0 6px 6px 0;
  width: 16px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const SortKeySelectContainer = styled.div`
  flex: 1;
`

const SortButton = styled.button<{ selected: boolean }>`
  outline: none;
  border: none;
  background: none;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: ${p => !p.selected && 'pointer'};
  :hover {
    background: ${p => !p.selected && blue[40]};
  }
  :active {
    background: ${blue[10]};
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  ${p =>
    p.selected &&
    css`
      background: ${blue[10]};
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}
`
