import { useParams, useNavigate } from 'react-router-dom'
import { Patient } from 'src/modules/entities/patient/entity'
import { useFlash } from 'src/modules/flash/use-flash'
import { eproPatientUserKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { trialIdParamName } from './epro-page'
import { fetchMe } from './questionnaire-answer/request'
import { getLoginRoute } from './questionnaire-answer/routes'

const LOGIN_REQUIRED_ERROR = 'ログインしてください'

export const useLoginGuard = () => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()

  const navigate = useNavigate()
  const { showError } = useFlash()

  const { data, error } = useSWR<
    Pick<Patient, 'uid' | 'diseaseUid' | 'eproStatus'>,
    Error
  >(eproPatientUserKeys.fetchMe(trialUid), fetchMe)

  if (error?.message === LOGIN_REQUIRED_ERROR) {
    showError('ログインしてください。', { durationMsec: 30000, closable: true })
    navigate(getLoginRoute({ trialUid }))
  }

  return {
    loading: !data,
  }
}
