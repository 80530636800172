import { UploadTask } from '../task'

export const uploadTaskToJaText = (task: UploadTask) => {
  switch (task.name) {
    case 'template':
      return 'テンプレート'
    case 'section':
      return 'セクション'
    case 'field':
      return 'フィールド'
    case 'choice':
      return '選択肢'
    default:
      const n: never = task.name
      throw new Error(n)
  }
}

export const uploadTaskToEnText = (task: UploadTask) => {
  switch (task.name) {
    case 'template':
      return 'Template'
    case 'section':
      return 'Section'
    case 'field':
      return 'Field'
    case 'choice':
      return 'Choice'
    default:
      const n: never = task.name
      throw new Error(n)
  }
}
