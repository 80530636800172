import { useCallback, useEffect } from 'react'

import { Badge, Box, Center, Flex, HStack, Text } from '@chakra-ui/react'
import { RemoteUser } from '@micin-jp/call-lib-core'
import { useRemotePlayer } from '@micin-jp/call-lib-react'
import { CameraOff, MicOff, MicOn } from 'src/components/icon'

import { useVolumeMeter } from '../../hooks/useVolumeMeter'

type Props = {
  remoteUser: RemoteUser
}

export const VideoCallRoomRemotePlayer: React.FC<Props> = ({ remoteUser }) => {
  const { attachVideo, playAudio, cameraEnabled, micEnabled, audioTrack } =
    useRemotePlayer(remoteUser)

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachVideo(elm, { fit: 'contain' }),
    [attachVideo],
  )

  useEffect(() => playAudio(), [playAudio])

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  return (
    <Box w="full" h="full" borderRadius="sm" pos="relative" bg="gray.600">
      <Box
        pos="absolute"
        zIndex="1"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      {cameraEnabled ? (
        <Flex
          ref={videoRef}
          direction="column"
          justify="flex-end"
          w="full"
          h="full"
        />
      ) : (
        <Center flexDirection="column" color="white" w="full" h="full">
          <CameraOff size="96px" />
        </Center>
      )}
      <Badge
        pos="absolute"
        left="24px"
        bottom="8px"
        px="4"
        bg="gray.500"
        color="white"
        fontWeight="bold"
        fontSize="md"
      >
        <HStack>
          {!!remoteUser.name && (
            // Note: Badgeの内部におけるtextは自動的でUpper caseになるのでtextTransformを明示する。
            <Text textTransform="none">{remoteUser.name}</Text>
          )}
          {micEnabled ? <MicOn /> : <MicOff />}
        </HStack>
      </Badge>
    </Box>
  )
}
