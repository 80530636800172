import { useState } from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Check } from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { useSpinner } from 'src/hooks/use-spinner'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useCreateEnvelope } from '../../api'
import {
  ExplanationDocRevisionAgreementForm,
  ExplanationInPersonSession,
  ExplanationSignerRole,
} from '../../types'
import { isSignedBy } from '../../utils/isSignedBy'

type Props = {
  session: ExplanationInPersonSession
  docRevision: ExplanationDocRevisionAgreementForm
  startSignText: string
}

export const StartInPersonSign: React.FC<Props> = ({
  session,
  docRevision,
  startSignText,
}) => {
  const { selectedTrial } = useSelectedTrial()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedRole, setSelectedRole] = useState<ExplanationSignerRole>()

  const hasCrcField = docRevision.signerMembers.some(m => m.role === 'CRC')

  const navigate = useNavigate()

  const toast = useMirohaToast()
  const { showSpinner, hideSpinner } = useSpinner()

  const navigateToDocuSign = () => {
    if (!selectedRole) {
      throw new Error('role is not set')
    }

    const searchParam = new URLSearchParams({ role: selectedRole })
    navigate(
      [
        generatePath(Paths.ExplanationRoomDocuSign, {
          trialUid: selectedTrial.uid,
          sessionUid: session.uid,
          explanationDocRevisionUid: docRevision.uid,
        }),
        searchParam.toString(),
      ].join('?'),
    )
  }

  const { request: createEnvelope } = useCreateEnvelope({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      navigateToDocuSign()
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const handleSubmit = async () => {
    if (!docRevision.dsEnvelopeId) {
      await createEnvelope({
        type: 'AgreementForm',
        explanationSessionUid: session.uid,
        explanationDocRevisionUid: docRevision.uid,
      })
      return
    }

    // envelope作成済みの場合はそのままDocuSignページに遷移する
    navigateToDocuSign()
  }

  if (docRevision.isCompleted) {
    return null
  }

  const isRejected = docRevision.signHistories.some(h => h.isRejected)
  if (isRejected) {
    return null
  }

  return (
    <>
      <Button onClick={onOpen} colorScheme="green">
        {startSignText}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>署名者を選択してください</ModalHeader>
          <form
            onSubmit={async e => {
              e.preventDefault()
              await handleSubmit()
            }}
          >
            <ModalBody>
              <RadioGroup
                value={selectedRole}
                onChange={value => {
                  setSelectedRole(value as ExplanationSignerRole)
                }}
                colorScheme="green"
                pt="8"
              >
                <HStack spacing="8">
                  {isSignedBy(docRevision, 'Patient') ? (
                    <HStack>
                      <Check color={colors.green[500]} />
                      <Text>患者</Text>
                    </HStack>
                  ) : (
                    <Radio value="Patient">患者</Radio>
                  )}
                  {isSignedBy(docRevision, 'Dr') ? (
                    <HStack>
                      <Check color={colors.green[500]} />
                      <Text>Dr</Text>
                    </HStack>
                  ) : (
                    <Radio value="Dr">Dr</Radio>
                  )}
                  {hasCrcField &&
                    (isSignedBy(docRevision, 'CRC') ? (
                      <HStack>
                        <Check color={colors.green[500]} />
                        <Text>CRC</Text>
                      </HStack>
                    ) : (
                      <Radio value="CRC">CRC</Radio>
                    ))}
                </HStack>
              </RadioGroup>
            </ModalBody>

            <ModalFooter>
              <ModalCancelButton />
              <Button
                colorScheme="green"
                isDisabled={!selectedRole}
                type="submit"
              >
                次へ
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
