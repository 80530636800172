import {
  ExplanationRevisionPinSettingMethod,
  GqlError,
  RequestPinSettingDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const requestPinSetting = async ({
  explanationRevisionUid,
  method,
  deviceActorUid,
}: {
  explanationRevisionUid: string
  method: ExplanationRevisionPinSettingMethod
  deviceActorUid?: string
}): Promise<void> => {
  try {
    await graphqlRequest(RequestPinSettingDocument, {
      input: {
        explanationRevisionUid,
        method,
        deviceActorUid,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useRequestPinSetting = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationRevisionUid,
      method,
      deviceActorUid,
    }: {
      explanationRevisionUid: string
      method: ExplanationRevisionPinSettingMethod
      deviceActorUid?: string
    }) =>
      requestPinSetting({
        explanationRevisionUid,
        method,
        deviceActorUid,
      }),
    option,
  )
}
