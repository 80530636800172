import { Badge, BadgeProps, HStack, Text } from '@chakra-ui/react'

import { CheckBold } from '../icon'

type Props = BadgeProps & {
  text: string
}

export const HasSetBadge: React.FC<Props> = ({ text, ...badgeProps }) => {
  return (
    <Badge
      height="18px"
      display="flex"
      alignItems="center"
      borderRadius="sm"
      bgColor="green.600"
      fontSize="10px"
      fontWeight="bold"
      px="4px"
      py="2px"
      {...badgeProps}
    >
      <HStack spacing="2px" align="center" color="white">
        <CheckBold size="10px" />
        <Text fontSize="10px" fontWeight="bold" as="span">
          {text}
        </Text>
      </HStack>
    </Badge>
  )
}
