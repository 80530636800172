import React from 'react'

import { useSelector } from 'react-redux'

import { VisibilityModalComponent } from './visibility-modal-component'
import { getTrialHospitalsByUid } from '../../../../../../../../entities/account/selector'
import { RootState } from '../../../../../../../../reducer'
import { getState } from '../../selector'

type Props = {
  onClose: () => void
  onOpenVisibilitySetModalFactory: (smallItemUid: string) => () => void
  onOpenVisibilityBulkSetModal: () => void
}

export const VisibilityModalContainer: React.FC<Props> = props => {
  const {
    onClose,
    onOpenVisibilitySetModalFactory,
    onOpenVisibilityBulkSetModal,
  } = props
  const template = useSelector((state: RootState) => getState(state))
  const trialHospitalsByUid = useSelector(getTrialHospitalsByUid)

  return (
    <VisibilityModalComponent
      mediumItems={template.mediumItems}
      templateTitle={template.title}
      trialHospitalsByUid={trialHospitalsByUid}
      templateStatus={template.originalTemplate?.status}
      onClose={onClose}
      onOpenVisibilitySetModalFactory={onOpenVisibilitySetModalFactory}
      onOpenVisibilityBulkSetModal={onOpenVisibilityBulkSetModal}
    ></VisibilityModalComponent>
  )
}
