import { createContext, useContext } from 'react'

import {
  Box,
  Center,
  Circle,
  Collapse,
  Flex,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import {
  EConsent,
  Home,
  MenuClose,
  MenuOpen,
  Preparation,
  ReferredPatient,
  Settings,
  Subject,
} from 'src/components/icon'

import { useCurrentVersion } from '../../hooks/useCurrentVersion'
import {
  NavigationRouteItem,
  NavigationRouteItems,
  NavigationRouteKey,
} from '../../hooks/useNavigationRoutes'

type Props = {
  routeItems: NavigationRouteItems
}

const navText: Record<NavigationRouteKey, string> = {
  home: 'ホーム',
  patients: '被験者',
  explanations: 'eConsent 説明同意',
  referredPatients: '紹介患者',
  templates: 'テンプレート管理',
  icfDocuments: '説明文書管理',
  criteria: '選択除外基準管理',
  trialInfo: '試験情報',
  account: 'アカウント管理',
  crf: 'CRF管理',
  csvExport: '症例データ管理',
}

const isRouteItemActive = (routeItem: NavigationRouteItem) => {
  return window.location.pathname.startsWith(routeItem.linkPath)
}

type NavContext = ReturnType<typeof useDisclosure>
const navContext = createContext<NavContext | undefined>(undefined)

const NavContextProvider: React.FC<{
  children: React.ReactNode
  value: NavContext
}> = ({ children, value }) => {
  return <navContext.Provider value={value}>{children}</navContext.Provider>
}
const useNavContext = () => {
  const context = useContext(navContext)
  if (!context) {
    throw new Error('useNavContext must be used within a NavContextProvider')
  }
  return context
}

export const Navigation: React.FC<Props> = ({ routeItems }) => {
  // 事前準備
  const preparationGroup: NavigationRouteItem[] = [
    routeItems.icfDocuments,
    routeItems.templates,
    routeItems.criteria,
  ].filter(item => item.isEnabled)
  // 管理メニュー
  const managementGroup: NavigationRouteItem[] = [
    routeItems.trialInfo,
    routeItems.account,
    routeItems.crf,
    routeItems.csvExport,
  ].filter(item => item.isEnabled)

  const nav = useDisclosure({ defaultIsOpen: true })

  const { isOpen, onToggle } = nav

  const { currentVersion } = useCurrentVersion()

  return (
    <NavContextProvider value={nav}>
      <Flex
        as="nav"
        w={isOpen ? '216px' : '52px'}
        transition={!isOpen ? 'width 0.5s' : undefined}
        h="full"
        bg="gray.50"
        direction="column"
        justify="space-between"
      >
        <Flex mt="8px" direction="column" gap="40px">
          <Flex direction="column">
            {routeItems.home.isEnabled && (
              <NavItem
                routeItem={routeItems.home}
                text="ホーム"
                icon={<Home />}
              />
            )}
            {routeItems.patients.isEnabled && (
              <NavItem
                routeItem={routeItems.patients}
                text="被験者"
                icon={<Subject />}
              />
            )}
            {routeItems.explanations.isEnabled && (
              <NavGroup
                groupName="eConsent"
                defaultRouteItem={routeItems.explanations}
                isActive={isRouteItemActive(routeItems.explanations)}
                icon={<EConsent />}
              >
                <NavGroupItem
                  routeItem={routeItems.explanations}
                  text="説明同意"
                />
              </NavGroup>
            )}

            {routeItems.referredPatients.isEnabled && (
              <NavItem
                routeItem={routeItems.referredPatients}
                text="紹介患者"
                icon={<ReferredPatient />}
              />
            )}
            {preparationGroup.length > 0 && (
              <NavGroup
                groupName="事前準備"
                defaultRouteItem={preparationGroup[0]}
                isActive={preparationGroup.some(isRouteItemActive)}
                icon={<Preparation />}
              >
                {preparationGroup.map(routeItem => (
                  <NavGroupItem
                    key={routeItem.key}
                    routeItem={routeItem}
                    text={navText[routeItem.key]}
                  />
                ))}
              </NavGroup>
            )}
            {managementGroup.length > 0 && (
              <NavGroup
                groupName="管理メニュー"
                defaultRouteItem={managementGroup[0]}
                isActive={managementGroup.some(isRouteItemActive)}
                icon={<Settings />}
              >
                {managementGroup.map(routeItem => (
                  <NavGroupItem
                    key={routeItem.key}
                    routeItem={routeItem}
                    text={navText[routeItem.key]}
                  />
                ))}
              </NavGroup>
            )}
          </Flex>
        </Flex>
        <HStack
          p="4"
          justify={isOpen ? 'space-between' : 'center'}
          color="gray.600"
        >
          {isOpen && (
            <Text
              as="span"
              aria-label="MiROHA システムバージョン"
            >{`ver.${currentVersion}`}</Text>
          )}
          <Circle
            as="button"
            onClick={onToggle}
            size="36px"
            _hover={{ bg: 'gray.200' }}
            _active={{ bg: 'gray.300' }}
            sx={{ '> svg': { boxSize: '24px' } }}
          >
            {isOpen ? <MenuOpen /> : <MenuClose />}
          </Circle>
        </HStack>
      </Flex>
    </NavContextProvider>
  )
}

type NavItemProps = {
  routeItem: NavigationRouteItem
  icon: React.ReactNode
  text: string
}
const NavItem: React.FC<NavItemProps> = ({ routeItem, icon, text }) => {
  const isActive = isRouteItemActive(routeItem)
  const { isOpen, onOpen } = useNavContext()
  return (
    <Flex w="full" px="8px" py="4px" boxSizing="border-box">
      <Flex
        as={Link}
        to={routeItem.linkPath}
        onClick={onOpen}
        fontWeight={isActive ? 'bold' : 'normal'}
        p="8px"
        align="center"
        w={isOpen ? 'full' : 'max-content'}
        gap="12px"
        borderRadius="base"
        bg={isActive ? 'white' : 'gray.50'}
        _hover={{ bg: isActive ? 'white' : 'gray.200' }}
        _active={{ bg: isActive ? 'white' : 'gray.300' }}
      >
        <IconWrapper>{icon}</IconWrapper>
        {isOpen && (
          <Text as="span" lineHeight={1}>
            {text}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

type NavGroupProps = {
  groupName: string
  isActive: boolean
  defaultRouteItem: NavigationRouteItem
  icon: React.ReactNode
  children?: React.ReactNode
}
const NavGroup: React.FC<NavGroupProps> = ({
  groupName,
  isActive,
  defaultRouteItem,
  icon,
  children,
}) => {
  const { isOpen, onOpen } = useNavContext()
  return (
    <Box as="section" aria-label={groupName} px="8px" py="4px" w="full">
      <Box bg={isActive ? 'white' : undefined} borderRadius="base">
        <Flex
          as={isActive ? 'div' : Link}
          // @ts-ignore
          to={defaultRouteItem.linkPath}
          onClick={onOpen}
          fontWeight={isActive ? 'bold' : 'normal'}
          p="8px"
          align="center"
          w={isOpen ? 'full' : 'max-content'}
          gap="12px"
          bg={isActive ? 'white' : undefined}
          borderRadius="base"
          cursor={isOpen && isActive ? 'normal' : 'pointer'}
          _hover={{ bg: isActive ? 'white' : 'gray.200' }}
          _active={{ bg: isActive ? 'white' : 'gray.300' }}
        >
          <IconWrapper>{icon}</IconWrapper>
          {isOpen && (
            <Text as="span" lineHeight={1}>
              {groupName}
            </Text>
          )}
        </Flex>
        {isOpen && (
          <Collapse in={isActive}>
            <Flex direction="column" pb="12px">
              {children}
            </Flex>
          </Collapse>
        )}
      </Box>
    </Box>
  )
}
type NavGroupItemProps = {
  routeItem: NavigationRouteItem
  text: string
}
const NavGroupItem: React.FC<NavGroupItemProps> = ({ routeItem, text }) => {
  const isActive = isRouteItemActive(routeItem)
  return (
    <Flex pl="30px" w="full" align="center" gap="2px">
      <Box
        as="span"
        h="20px"
        w="4px"
        borderRadius="full"
        bg={isActive ? 'blue.500' : 'transparent'}
      />
      <Box
        as={Link}
        to={routeItem.linkPath}
        fontWeight={isActive ? 'bold' : 'normal'}
        w="full"
        p="4px"
        mr="4px"
        fontSize="sm"
        display="block"
        borderRadius="base"
        _hover={{ bg: 'gray.50' }}
        _active={{ bg: 'gray.100' }}
      >
        {text}
      </Box>
    </Flex>
  )
}

const IconWrapper: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <Center
      boxSize="20px"
      sx={{
        '> svg': {
          color: 'gray.500',
          width: '20px',
          height: '20px',
        },
      }}
    >
      {children}
    </Center>
  )
}
