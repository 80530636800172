import { Button, Text } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary,
} from 'react-error-boundary'

import {
  StaticScreen,
  StaticScreenContent,
  StaticScreenFooter,
  StaticScreenTitle,
} from '../StaticScreen/StaticScreen'

type Props = {
  children: React.ReactNode
}

export const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const handleError = (error: Error) => {
    Sentry.captureException(error, {
      level: 'fatal', // ErrorBoundaryの表示 === 画面のクラッシュなのでfatalにする
      tags: {
        type: 'ErrorBoundary',
      },
    })
  }
  return (
    <ReactErrorBoundary onError={handleError} fallback={<Fallback />}>
      {children}
    </ReactErrorBoundary>
  )
}

const Fallback: React.FC = () => {
  const { resetBoundary } = useErrorBoundary()
  return (
    <StaticScreen>
      <StaticScreenTitle>予期せぬエラーが発生しました</StaticScreenTitle>
      <StaticScreenContent>
        <Text textAlign="center">
          エラーが発生したため処理を中断しました。
          <br />
          時間を置いて再度お試しください。
        </Text>
      </StaticScreenContent>
      <StaticScreenFooter>
        <Button onClick={resetBoundary}>元の操作ページに戻る</Button>
      </StaticScreenFooter>
    </StaticScreen>
  )
}
