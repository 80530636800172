import { WSExportJobStatus } from '../types'

const statusTextMap: Record<WSExportJobStatus, string> = {
  waiting: '待機中',
  running: '処理中',
  done: '完了',
  canceled: '処理中止',
  error: 'Error',
  expired: '期限切れ',
}

export const wsDataExportJobStatusToText = (status: WSExportJobStatus) =>
  statusTextMap[status]
