import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  Button,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Portal,
  Flex,
  Text,
  HStack,
  Box,
  Divider,
  IconButton,
  Skeleton,
  Stack,
} from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'
import { isMobile } from 'react-device-detect'
import { Close, Delete, Important, Sticky } from 'src/components/icon'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { MentionTextarea } from 'src/components/MentionTextarea/MentionTextarea'
import { useCurrentMember } from 'src/features/auth/context'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import {
  StickyPriority,
  GetSticky_StickyMessageFragment,
} from 'src/lib/gql-client'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'
import {
  getMentionTextCount,
  parseMentionTextWithDisplayName,
} from 'src/utils/mention'

import { useStickyControl } from '../../hooks/useStickyControl'
import { useStickyEditingAlert } from '../../hooks/useStickyEditingAlert'
import { StickyMessageContent } from '../StickyMessageContent/StickyMessageContent'

const PopoverTrigger: React.FC<{ children: ReactNode }> = OrigPopoverTrigger

type Props = {
  field: Field
  index: number
}

type Context = ReturnType<typeof useStickyControl>

// このcontextのProvider配下（このファイル内のコンポーネント）ではhooksの返り値をcontext経由で使用することができる
const context = createContext<Context | null>(null)

const useStickyContext = () => {
  const ctx = useContext(context)
  if (!ctx) {
    throw new Error('useStickyContext must be used in StickyPopover')
  }
  return ctx
}
const MAX_MESSAGE_LENGTH = 300

export const StickyPopover: React.FC<Props> = ({ field, index }) => {
  const methods = useStickyControl({
    filed: field,
    fieldIndex: index,
  })

  const {
    sticky,
    hasSticky,
    isPopoverOpen,
    onOpenPopover,
    onClosePopover,
    deleteSticky,
  } = methods

  const maxHeightRatio = window.innerHeight > 800 ? 0.8 : 1
  const popoverMaxHeight = window.innerHeight * maxHeightRatio

  const containerRef = useRef(null)

  return (
    <context.Provider value={methods}>
      <Flex w="full" justify="space-between">
        <Box
          ref={containerRef}
          as="button"
          id={`sticky-button-${field.fid}-${index}`}
          className="sticky-button"
          onClick={onOpenPopover}
          color={hasSticky ? 'yellow.500' : 'gray.500'}
          _hover={{ color: hasSticky ? 'yellow.600' : 'gray.600' }}
          _active={{ color: hasSticky ? 'yellow.700' : 'gray.700' }}
        >
          <Sticky />
        </Box>

        {isPopoverOpen && (
          <Popover
            placement="left-start"
            isOpen={true}
            onOpen={onOpenPopover}
            onClose={onClosePopover}
            closeOnBlur={false}
            returnFocusOnClose={false}
            flip={false}
            preventOverflow={false}
          >
            <PopoverTrigger>
              <Button variant="unstyled" minW="unset" w="0" h="0" />
            </PopoverTrigger>
            {/* mobile端末（iPad等）だとrootにportalをレンダリングしないと画面が一瞬消えてしまう問題がある。
              逆にPCだとrootにportalをレンダリングするとスクロールが効かなくなるため、containerRefを指定して要素内にレンダリングする。
              その場しのぎ的な対応なので見直したい。
            */}
            <Portal containerRef={isMobile ? undefined : containerRef}>
              <PopoverContent
                _focus={{ outline: 'none' }}
                py="3"
                maxH={`${popoverMaxHeight}px`}
                overflowY="scroll"
                color="gray.800"
              >
                <PopoverHeader
                  border="none"
                  fontSize="sm"
                  fontWeight="bold"
                  py="0"
                  px="3"
                >
                  <Flex justify="space-between" align="center">
                    <HStack spacing="1.5" align="center">
                      <Box color="yellow.500">
                        <Sticky />
                      </Box>
                      <Box maxW={hasSticky ? '200px' : '244px'}>
                        <Text as="span" color="gray.600">
                          {field.name}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack spacing="0">
                      {hasSticky && (
                        <IconButton
                          variant="customIconButtonGhost"
                          colorScheme="gray"
                          aria-label="付箋の削除"
                          icon={<Delete />}
                          onClick={async () => {
                            await deleteSticky()
                          }}
                        />
                      )}
                      <IconButton
                        variant="customIconButtonGhost"
                        colorScheme="gray"
                        aria-label="Popoverを閉じる"
                        onClick={onClosePopover}
                        icon={<Close />}
                      />
                    </HStack>
                  </Flex>
                </PopoverHeader>
                <PopoverBody display="block" p="0">
                  {hasSticky && !!sticky ? (
                    <Stack spacing="3">
                      <Stack
                        spacing="4"
                        maxH="240px"
                        overflow="scroll"
                        px="3"
                        py="0"
                      >
                        {sticky.stickyMessages.map(message => (
                          <MessageContent
                            key={message.stickyMessageUid}
                            message={message}
                          />
                        ))}
                      </Stack>
                      <Stack px="3" spacing="4">
                        <Divider />
                        <ReplyMessageForm
                          key={sticky.stickyMessages.length} // reply成功後にフォームをリセットするため作成済messageの数をkeyにする
                        />
                      </Stack>
                    </Stack>
                  ) : !hasSticky ? (
                    <Box px="3">
                      <NewMessageForm />
                    </Box>
                  ) : (
                    <Skeleton h="64px" />
                  )}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Flex>
    </context.Provider>
  )
}

const NewMessageForm: React.FC = () => {
  const { createSticky, onClosePopover, toggleEditing } = useStickyContext()
  const [value, setValue] = useState('')
  const defaultPriority: StickyPriority = 'Middle'
  const [priority, setPriority] = useState<StickyPriority>(defaultPriority)
  const { currentMember } = useCurrentMember()

  const isChanged = (value: string, priority: StickyPriority) => {
    return value !== '' || priority !== defaultPriority
  }

  const handleChange = (val: string) => {
    setValue(val)
    toggleEditing(isChanged(val, priority))
  }

  const togglePriority = () => {
    const newPriority = priority === 'High' ? 'Middle' : 'High'
    setPriority(newPriority)
    toggleEditing(isChanged(value, newPriority))
  }

  const isOverMaxLength = getMentionTextCount(value) > MAX_MESSAGE_LENGTH

  return (
    <Stack>
      <Stack spacing="1.5">
        <Text fontSize="sm" fontWeight="bold" color="gray.600">
          {getFullName(currentMember)}
        </Text>

        <MentionTextareaForSticky value={value} onChange={handleChange} />
      </Stack>
      <Flex justify="end" gap={1} align="center">
        <PriorityButton priority={priority} onClick={togglePriority} />
        <Button variant="text" colorScheme="gray" onClick={onClosePopover}>
          キャンセル
        </Button>
        <Button
          isDisabled={!isChanged(value, priority) || !value || isOverMaxLength}
          variant="text"
          onClick={async () => {
            toggleEditing(false)
            await createSticky({ message: value, priority })
          }}
        >
          送信
        </Button>
      </Flex>
    </Stack>
  )
}

const MessageContent: React.FC<{
  message: GetSticky_StickyMessageFragment
}> = ({ message }) => {
  const { memberMap, deleteStickyMessage } = useStickyContext()

  const [isEditMode, setIsEditMode] = useState(false)
  const { currentMember } = useCurrentMember()

  const canDeleteMessage = (message: GetSticky_StickyMessageFragment) => {
    return message.trialMember.uid === currentMember.uid && !message.isRoot
  }
  const canEditMessage = (message: GetSticky_StickyMessageFragment) => {
    return message.trialMember.uid === currentMember.uid
  }

  const getDisplayName = (id: string, operationMode: 'show' | 'edit') => {
    const member = memberMap.get(id)
    if (member) {
      return getFullName(member.user)
    }
    if (operationMode === 'show') {
      return '非公開ユーザー'
    }
    return ''
  }

  // editModeの場合formを表示
  return isEditMode ? (
    <EditMessageForm
      clearEditMode={() => setIsEditMode(false)}
      message={message}
      getDisplayName={id => getDisplayName(id, 'edit')}
    />
  ) : (
    <Stack spacing="1.5">
      <Flex justify="space-between" align="center">
        <Text fontSize="sm" fontWeight="bold" color="gray.600">
          {getFullName(message.trialMember.user)}
        </Text>
        <HStack spacing="1.5">
          {message.priority === 'High' && <Important color={colors.red[300]} />}
          <Text fontSize="xs">
            {formatDate(message.savedAt, 'YYYY/MM/DD (ddd) HH:mm')}
          </Text>
        </HStack>
      </Flex>
      <Stack spacing="2">
        <StickyMessageContent
          message={message.message}
          getDisplayName={id => getDisplayName(id, 'show')}
          isMe={id => id === currentMember.uid}
        />
        <HStack spacing="0.5" justify="end">
          {canDeleteMessage(message) && (
            <Button
              variant="text"
              colorScheme="blue"
              onClick={async () => {
                await deleteStickyMessage(message.stickyMessageUid)
              }}
            >
              削除
            </Button>
          )}
          {canEditMessage(message) && (
            <Button variant="text" onClick={() => setIsEditMode(true)}>
              編集
            </Button>
          )}
        </HStack>
      </Stack>
    </Stack>
  )
}

const EditMessageForm: React.FC<{
  clearEditMode: () => void
  getDisplayName: (id: string) => string
  message: GetSticky_StickyMessageFragment
}> = ({ clearEditMode, getDisplayName, message }) => {
  const { toggleEditing, editStickyMessage } = useStickyContext()

  const defaultMessage = parseMentionTextWithDisplayName(
    message.message,
    getDisplayName,
  )
  const [value, setValue] = useState(defaultMessage)
  const [priority, setPriority] = useState<StickyPriority>(message.priority)
  const { currentMember } = useCurrentMember()
  const { showStickyEditingAlert } = useStickyEditingAlert()

  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      const value = inputRef.current.value
      inputRef.current.setSelectionRange(value.length, value.length)
    }
  }, [])

  const isChanged = (value: string, priority: StickyPriority) => {
    return value !== defaultMessage || priority !== message.priority
  }

  const handleChange = (val: string) => {
    setValue(val)
    toggleEditing(isChanged(val, priority))
  }
  const togglePriority = () => {
    const newPriority = priority === 'High' ? 'Middle' : 'High'
    setPriority(newPriority)
    toggleEditing(isChanged(value, newPriority))
  }

  const isOverMaxLength = getMentionTextCount(value) > MAX_MESSAGE_LENGTH

  const handleCancel = () => {
    if (!isChanged(value, priority)) {
      toggleEditing(false)
      clearEditMode()
      return
    }
    showStickyEditingAlert({
      onConfirm: () => {
        clearEditMode()
        toggleEditing(false)
      },
    })
  }

  return (
    <Stack spacing="2">
      <Stack spacing="1.5">
        <Text fontSize="sm">{getFullName(currentMember)}</Text>
        <MentionTextareaForSticky
          inputRef={inputRef}
          value={value}
          onChange={handleChange}
        />
      </Stack>
      <Flex justify="end" gap={1} align="center">
        <PriorityButton priority={priority} onClick={togglePriority} />
        <Button variant="text" colorScheme="gray" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button
          isDisabled={!isChanged(value, priority) || !value || isOverMaxLength}
          variant="text"
          onClick={async () => {
            await editStickyMessage({
              messageUid: message.stickyMessageUid,
              message: value,
              priority,
              onSuccess: clearEditMode,
            })
            toggleEditing(false)
          }}
        >
          更新
        </Button>
      </Flex>
    </Stack>
  )
}

const ReplyMessageForm: React.FC = () => {
  const { toggleEditing, replySticky } = useStickyContext()

  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)
  const defaultPriority: StickyPriority = 'Middle'
  const [priority, setPriority] = useState<StickyPriority>(defaultPriority)
  const { currentMember } = useCurrentMember()

  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const { showStickyEditingAlert } = useStickyEditingAlert()

  // 入力モードにした瞬間にフォーカスする
  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus()
      const value = inputRef.current.value
      inputRef.current.setSelectionRange(value.length, value.length)
    }
  }, [focused])

  const isChanged = (value: string, priority: StickyPriority) => {
    return value !== '' || priority !== defaultPriority
  }

  const handleChange = (val: string) => {
    setValue(val)
    toggleEditing(isChanged(val, priority))
  }

  const isOverMaxLength = getMentionTextCount(value) > MAX_MESSAGE_LENGTH

  const handleCancel = () => {
    if (!isChanged(value, priority)) {
      setFocused(false)
      return
    }
    showStickyEditingAlert({
      onConfirm: () => {
        setFocused(false)
        setValue('')
        setPriority(defaultPriority)
        toggleEditing(false)
      },
    })
  }

  const togglePriority = () => {
    const newPriority = priority === 'High' ? 'Middle' : 'High'
    setPriority(newPriority)
    toggleEditing(isChanged(value, newPriority))
  }

  return (
    <Stack>
      <Stack spacing="1.5">
        <Text fontSize="sm" fontWeight="bold" color="gray.600">
          {getFullName(currentMember)}
        </Text>

        {focused ? (
          <MentionTextareaForSticky
            inputRef={inputRef}
            value={value}
            onChange={handleChange}
          />
        ) : (
          <Box
            borderRadius="base"
            as="button"
            cursor="text"
            textAlign="left"
            px="3"
            py="1.5"
            border="1px solid"
            borderColor="gray.200"
            fontSize="sm"
            color="gray.200"
            onClick={() => {
              setFocused(true)
            }}
          >
            返信内容を入力
          </Box>
        )}
      </Stack>
      {focused && (
        <Flex justify="end" gap={1} align="center">
          <PriorityButton priority={priority} onClick={togglePriority} />
          <Button variant="text" onClick={handleCancel}>
            キャンセル
          </Button>
          <Button
            isDisabled={
              !isChanged(value, priority) || !value || isOverMaxLength
            }
            variant="text"
            onClick={async () => {
              await replySticky({ message: value, priority })
              toggleEditing(false)
            }}
          >
            送信
          </Button>
        </Flex>
      )}
    </Stack>
  )
}

const MentionTextareaForSticky: React.FC<{
  value: string
  onChange: (value: string) => void
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | null>
}> = ({ value, onChange, inputRef }) => {
  const { membersToMention, isPartnerMember, memberMap } = useStickyContext()

  return (
    <Stack>
      <MentionTextarea
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        mentionMarkup="@[__display__](__id__)"
        mentionItems={membersToMention}
        placeholder={'「@」を入力してメンション相手を指定できます'}
        styles={{
          control: {
            minH: '120px',
          },
        }}
        suggestionStyle={item => {
          const member = memberMap.get(String(item.id))
          if (!member) return null

          return (
            <MemberLabel
              role={member.role}
              displayName={item.display ?? ''}
              isPartner={isPartnerMember(String(item.id))}
            />
          )
        }}
      />
      {getMentionTextCount(value) > MAX_MESSAGE_LENGTH && (
        <Text as="span" color="red.500" fontSize="xs">
          {`入力できる文字数は${MAX_MESSAGE_LENGTH}文字以内です。`}
        </Text>
      )}
    </Stack>
  )
}

const PriorityButton: React.FC<{
  onClick: () => void
  priority: StickyPriority
}> = ({ onClick, priority }) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      color={priority === 'High' ? 'red.300' : 'gray.400'}
      _hover={{ color: priority === 'High' ? 'red.400' : 'gray.500' }}
      _active={{ color: priority === 'High' ? 'red.500' : 'gray.600' }}
    >
      <Important />
    </Box>
  )
}
