import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient, RequestResponse } from 'src/lib/api-client'

export type CreateEnvelopeViewUrlFromSignAuthRes = Extract<
  RequestResponse<
    '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/create_envelope_view_url',
    'post'
  >,
  { result: 'success' }
>['data']

const createEnvelopeViewUrlFromSignAuth = async ({
  trialUid,
  explanationSignAuthenticationUid,
  createType,
  returnUrl,
}: {
  trialUid: string
  explanationSignAuthenticationUid: string
  createType: 'FromExplanationRoom' | 'FromEmailLink'
  returnUrl: string
}): Promise<CreateEnvelopeViewUrlFromSignAuthRes> => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/create_envelope_view_url',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthenticationUid,
      },
      body: {
        createType: createType,
        returnUrl: returnUrl,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useCreateEnvelopeViewUrlFromSignAuth = (
  options?: UseMutationOption<CreateEnvelopeViewUrlFromSignAuthRes>,
) => {
  return useMutation(
    ({
      trialUid,
      explanationSignAuthenticationUid,
      createType,
      returnUrl,
    }: {
      trialUid: string
      explanationSignAuthenticationUid: string
      createType: 'FromExplanationRoom' | 'FromEmailLink'
      returnUrl: string
    }) =>
      createEnvelopeViewUrlFromSignAuth({
        trialUid,
        explanationSignAuthenticationUid,
        createType,
        returnUrl,
      }),
    options,
  )
}
