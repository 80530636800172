import { generatePath, Navigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'

type Props = {
  trialUid: string
  explanationRevisionUid: string
}

export const RedirectToPatientSignIn: React.FC<Props> = ({
  trialUid,
  explanationRevisionUid,
}) => {
  return (
    <Navigate
      replace
      to={generatePath(Paths.ExplanationRoomPatientSignIn, {
        trialUid,
        explanationRevisionUid,
      })}
    />
  )
}
