// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgCalendar = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M16 2c0-.6-.4-1-1-1h-3V0h-2v1H6V0H4v1H1c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V2Zm-2 12H2V5h12v9Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M6 7H4v2h2V7ZM9 7H7v2h2V7ZM12 7h-2v2h2V7ZM6 10H4v2h2v-2ZM9 10H7v2h2v-2Z"
    />
  </svg>
)
export default SvgCalendar
