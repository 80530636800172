import React, { useState } from 'react'

import { Button } from '@chakra-ui/react'
import { Dictionary } from 'lodash'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { getSingle } from 'src/modules/entities/template/selector'
import { RootState } from 'src/modules/reducer'

import { ReleaseTemplateModal } from './modal'
import { trialIdParamName } from '../../../../trial-detail'
import { templateIdParamName } from '../template-detail'

type Props = {
  onValidate: () => Dictionary<Dictionary<boolean>>
  disabled: boolean
}

export const ReleaseButton: React.FC<Props> = props => {
  const { disabled } = props
  const { trialUid = '', templateUid = '' } = useParams<{
    [templateIdParamName]: string
    [trialIdParamName]: string
  }>()

  const template = useSelector((state: RootState) =>
    getSingle(state, templateUid),
  )

  const mediumItems = useSelector(
    (state: RootState) => state.templateDetail.mediumItems,
  )

  const [modalOpen, setModalOpen] = useState(false)

  const toast = useMirohaToast()

  const onClick = () => {
    if (mediumItems.length === 0) {
      toast({ status: 'error', title: 'セクションを追加してください。' })
      return
    }

    const validated = props.onValidate()
    if (
      Object.values(validated).some(
        errorMap => Object.keys(errorMap).length > 0,
      ) ||
      template.title === ''
    ) {
      toast({
        status: 'error',
        title: '入力に問題があります。再度入力してお試しください。',
      })
      return
    }

    setModalOpen(true)
  }

  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: () => setModalOpen(false),
    deps: [templateUid],
  })

  const onClose = () => setModalOpen(false)

  return (
    <>
      <Button onClick={onClick} isDisabled={disabled}>
        公開
      </Button>

      {modalOpen && (
        <ReleaseTemplateModal
          trialUid={trialUid}
          templateUid={templateUid}
          templateTitle={template.title}
          onClose={onClose}
        />
      )}
    </>
  )
}
