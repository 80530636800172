import { ReactElement } from 'react'

import {
  Box,
  Button,
  Collapse,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { RoomSessionStatus } from '@micin-jp/call-lib-core'
import { useLocalPlayer, useRoomSession } from '@micin-jp/call-lib-react'
import {
  CameraOff,
  CameraOn,
  MicOff,
  MicOn,
  MovieAreaExpand,
  MovieAreaShrink,
} from 'src/components/icon'
import { SPVideoCallRoomInCall } from 'src/features/videoCall/components/SPVideoCallRoomInCall/SPVideoCallRoomInCall'
import { VideoCallRoomLoading } from 'src/features/videoCall/components/VideoCallRoomLoading/VideoCallRoomLoading'
import { assertNever } from 'src/utils/assertNever'

import { useRoomMediaContext } from '../../context/MediaProvider'
import { useInitializePatientVideoCall } from '../../hooks/useInitializePatientVideoCall'

type Props = {
  roomUid: string | undefined
  candidateId: string | undefined
  canShrinkVideoCall?: boolean
}

export const SPExplanationVideoCall: React.FC<Props> = ({
  roomUid,
  candidateId,
  canShrinkVideoCall = false,
}) => {
  useInitializePatientVideoCall({
    roomUid,
    candidateId,
  })

  return (
    <VStack>
      <SPExplanationPlayer canShrinkVideoCall={canShrinkVideoCall}>
        <SPExplanationMainContent />
      </SPExplanationPlayer>
    </VStack>
  )
}

const SPExplanationPlayer: React.FC<{
  children: ReactElement
  canShrinkVideoCall: boolean
}> = ({ children, canShrinkVideoCall }) => {
  const {
    toggleMicOnOff,
    toggleCameraOnOff,
    localCameraEnabled,
    localMicEnabled,
  } = useLocalPlayer()

  const { sessionStatus } = useRoomSession()

  const { videoExpanded, toggleVideoExpanded } = useRoomMediaContext()

  return (
    <VStack width="100%" spacing="0">
      <HStack
        {...(!videoExpanded
          ? {
              right: 0,
              padding: 2,
              roundedLeft: 'lg',
            }
          : { width: 'full' })}
        position={videoExpanded ? 'relative' : 'absolute'}
        justifyContent={videoExpanded ? 'center' : 'end'}
        spacing="4"
        alignItems="center"
        h="12"
        bg="gray.900"
      >
        <HStack spacing={videoExpanded ? '4' : '2'}>
          <IconButton
            colorScheme={localMicEnabled ? 'green' : 'red'}
            w="8"
            h="8"
            variant="customIconButtonSolid"
            icon={localMicEnabled ? <MicOn /> : <MicOff />}
            aria-label="toggle mic button"
            isDisabled={sessionStatus !== RoomSessionStatus.Incall}
            onClick={() => toggleMicOnOff()}
          />
          <IconButton
            colorScheme={localCameraEnabled ? 'green' : 'red'}
            w="8"
            h="8"
            variant="customIconButtonSolid"
            icon={localCameraEnabled ? <CameraOn /> : <CameraOff />}
            aria-label="toggle camera button"
            isDisabled={sessionStatus !== RoomSessionStatus.Incall}
            onClick={toggleCameraOnOff}
          />
        </HStack>
        {canShrinkVideoCall && (
          <IconButton
            top={videoExpanded ? '2' : undefined}
            right={videoExpanded ? '2' : undefined}
            _focus={{ boxShadow: 'none' }}
            position={videoExpanded ? 'absolute' : undefined}
            aria-label="shrink-movie-button"
            variant="customIconButtonSolid"
            onClick={toggleVideoExpanded}
            h="8"
            w="8"
            bg="white"
            border="1px"
            borderColor="green.600"
            color="green.600"
            icon={
              videoExpanded ? (
                <MovieAreaShrink size="16px" />
              ) : (
                <MovieAreaExpand size="16px" />
              )
            }
          />
        )}
      </HStack>
      <Collapse in={videoExpanded} startingHeight="0" style={{ width: '100%' }}>
        <Box h="240px" w="100%" bg="gray.900">
          {children}
        </Box>
      </Collapse>
    </VStack>
  )
}

const SPExplanationMainContent = () => {
  const { sessionStatus, joinRoom } = useRoomSession()
  const { participantName } = useRoomMediaContext()
  switch (sessionStatus) {
    case RoomSessionStatus.None:
    case RoomSessionStatus.FindingRoom:
    case RoomSessionStatus.Joining:
      return <VideoCallRoomLoading />
    case RoomSessionStatus.Ready:
      return (
        <VStack
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="4"
        >
          <Text
            fontWeight="bold"
            color="white"
            px="4"
            textAlign="center"
            fontSize="xs"
          >
            通話を開始するには通話開始ボタンを押してください。
          </Text>
          <Button
            colorScheme="green"
            onClick={() =>
              joinRoom(
                !!participantName ? { name: participantName } : undefined,
              )
            }
          >
            通話開始
          </Button>
        </VStack>
      )
    case RoomSessionStatus.Incall:
      return <SPVideoCallRoomInCall />
    case RoomSessionStatus.Ended:
      return null
    default:
      return assertNever(sessionStatus)
  }
}
