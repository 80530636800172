import {
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { generatePath } from 'react-router-dom'
import { ExternalLink } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { formatDate } from 'src/utils/formatDate'

import { useReleases } from '../../api/getReleases'

type Props = {
  isOpen: boolean
  onClose: () => void
}

// ファイル名に一致する形式に変換する
const convertVersionStr = (version: string) => version.replace(/\./g, '-')

export const ReleaseListModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { data: releases } = useReleases({ revalidateIfStale: false })

  if (!releases) return null

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>バージョン情報</ModalHeader>
        <ModalBody>
          <Text as="h2" fontSize="lg" fontWeight="bold">
            変更履歴
          </Text>
          <TableContainer mt="4" overflowX="hidden" whiteSpace="pre-wrap">
            <Table>
              <Thead>
                <Tr>
                  <Th width="120px">バージョン</Th>
                  <Th width="180px">リリース日</Th>
                  <Th>概要</Th>
                  <Th width="120px">影響機能</Th>
                  <Th width="80px" />
                </Tr>
              </Thead>
              <Tbody>
                {releases.map(release => (
                  <Tr key={release.version}>
                    <Td>{release.version}</Td>
                    <Td>
                      {formatDate(release.releaseDate, 'YYYY/MM/DD (ddd)')}
                    </Td>
                    <Td>
                      {Array.isArray(release.summary)
                        ? release.summary.join('\n\n') // 2行分の改行を入れる
                        : release.summary}
                    </Td>
                    <Td>
                      {release.relatedFeatures.length > 0
                        ? release.relatedFeatures.join('\n')
                        : '-'}
                    </Td>
                    <Td p="0" textAlign="right">
                      {release.hasContent && (
                        <Link
                          isExternal
                          color="blue.500"
                          href={generatePath(Paths.ReleaseNote, {
                            version: convertVersionStr(release.version),
                          })}
                        >
                          <Flex align="center" gap={2}>
                            詳細
                            <ExternalLink />
                          </Flex>
                        </Link>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
