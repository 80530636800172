import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'
import { useScreenOrientationAlert } from 'src/hooks/use-orientation-alert'
import styled from 'styled-components'

import { PatientDocumentContainer } from './document/patient-container'
import { NotFoundError, SessionHasBeenExpired } from './error/error'
import { PatientLobbyContainer } from './lobby/patient-container'
import { PatientTopContainer } from './top/patient-container'
import {
  getRelativePath,
  PathKey,
  Paths,
} from '../../../../../../constants/paths'

export const PatientExplanationRoom: React.FC = () => {
  useScreenOrientationAlert()

  return <PatientExplanationRoomRoutes />
}

const PatientExplanationRoomRoutes: React.FC = () => {
  const getPath = (path: PathKey): string => {
    return getRelativePath('PatientExplanationRoom', path)
  }

  return (
    <Wrapper>
      <Routes>
        <Route
          path={getPath('PatientExplanationRoomLobby')}
          element={<PatientLobbyContainer />}
        />
        <Route
          path={getPath('PatientExplanationRoomTop')}
          element={<PatientTopContainer />}
        />
        <Route
          path={getPath('PatientExplanationRoomDocument')}
          element={<PatientDocumentContainer />}
        />
        <Route
          path={getPath('PatientExplanationRoomExpired')}
          element={<SessionHasBeenExpired />}
        />
        <Route
          path={getPath('PatientExplanationRoomNotFound')}
          element={<NotFoundError />}
        />
        <Route
          element={
            <Navigate replace to={Paths.PatientExplanationRoomNotFound} />
          }
        />
      </Routes>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`
