import {
  CheckboxFieldValue,
  FieldValue,
  RadioFieldValue,
  SelectMenuFieldValue,
} from '../types'

export type ChoiceTypeFieldValue =
  | CheckboxFieldValue
  | RadioFieldValue
  | SelectMenuFieldValue

export const isChoiceTypeFieldValue = (
  value: FieldValue,
): value is ChoiceTypeFieldValue => {
  return (
    value.type === 'checkbox' ||
    value.type === 'radio' ||
    value.type === 'selectMenu'
  )
}
