import React from 'react'

import _ from 'lodash'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { colors } from 'src/assets/colors'
import {
  CheckboxCheckedEconsent,
  CheckboxCheckedDisabled,
  CheckboxChecked,
  CheckboxDisabled,
  CheckboxWarning,
} from 'src/assets/icon/multicolor'
import {
  blue,
  gray,
  green,
  red,
  white,
} from 'src/components/base/color/palette'
import styled, { css } from 'styled-components'

type Props = {
  checked: boolean
  name?: string
  label?: string
  disabled?: boolean
  error?: boolean
  warning?: boolean
  eConsent?: boolean
}

type PropsWithValue = Props & {
  value: string | number
  onChange: (value: string) => void
}

type PropsWithoutValue = Props & {
  onChange?: () => void
}

export const SingleCheckboxWithValue: React.FC<PropsWithValue> = props => {
  const id = _.uniqueId('checkbox-')

  return (
    <Wrapper
      disabled={props.disabled}
      error={props.error}
      withLabelText={!!props.label}
      warning={props.warning}
      eConsent={props.eConsent}
    >
      <input
        type="checkbox"
        id={id}
        name={props.name}
        onChange={() => props.onChange(props.value.toString())}
        checked={props.checked}
        disabled={props.disabled}
      />
      <label htmlFor={id}>{props.label}</label>
    </Wrapper>
  )
}

//単独で可否選択をさせる際などに使う
export const SingleCheckboxWithoutValue: React.FC<
  PropsWithoutValue
> = props => {
  const id = _.uniqueId('checkbox-')

  return (
    <Wrapper
      disabled={props.disabled}
      error={props.error}
      withLabelText={!!props.label}
      warning={props.warning}
      eConsent={props.eConsent}
    >
      <input
        type="checkbox"
        id={id}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
      />
      <label htmlFor={id}>{props.label}</label>
    </Wrapper>
  )
}

type RHFProps<T extends FieldValues> = {
  name: string
  defaultChecked: boolean
  register?: UseFormRegister<T>
  disabled?: boolean
  error?: boolean
  label?: string
}

// Checkbox for react-hook-form
export const RHFSingleCheckbox = <T extends FieldValues>(
  props: RHFProps<T>,
) => {
  const id = _.uniqueId('checkbox-')
  const rhfProps = props.register ? props.register(props.name as Path<T>) : {}
  return (
    <Wrapper
      disabled={props.disabled}
      error={props.error}
      withLabelText={!!props.label}
    >
      <input
        id={id}
        type="checkbox"
        {...rhfProps}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
      />
      <label htmlFor={id}>{props.label}</label>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  disabled?: boolean
  error?: boolean
  withLabelText: boolean
  warning?: boolean
  eConsent?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;

  & > input {
    appearance: button;
    display: none;

    &:focus + label:after {
      border: 2px solid ${p => (p.eConsent ? green[70] : blue[70])};
    }

    &:checked + label:after {
      content: '';
      background-image: ${p =>
        p.eConsent
          ? css`url(${CheckboxCheckedEconsent})`
          : css`url(${CheckboxChecked})`};
      background-color: ${blue[70]};
      background-repeat: no-repeat;
      border: 2px solid ${p => (p.eConsent ? green[70] : blue[70])};
      background-size: cover;
    }
  }

  & > label {
    word-break: break-word;
    min-width: 20px;
    padding: ${props => (props.withLabelText ? '10px 28px' : 0)};
    box-sizing: border-box;
    transition: background-color 0.14s linear;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
    color: ${colors.dark};
    &:hover:after {
      border-color: ${gray[40]};
    }
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      display: block;
      border-radius: 4px;
      border: 2px solid ${gray[40]};
      box-sizing: border-box;
      background-color: ${white};
      transition: border-color 0.2s linear;
    }
  }

  ${({ warning }) =>
    warning &&
    css`
      & > input + label:after {
        content: '';
        background-image: url(${CheckboxWarning});
        background-color: unset;
        background-repeat: no-repeat;
        border: unset;
        background-size: cover;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      & > input + label:after {
        content: '';
        background-image: url(${CheckboxDisabled});
        background-color: ${gray[55]};
        background-repeat: no-repeat;
        border: unset;
        background-size: cover;
      }
      & > input {
        &:checked + label:after {
          content: '';
          background-image: url(${CheckboxCheckedDisabled});
          background-color: ${gray[55]};
          background-repeat: no-repeat;
          border: 2px solid ${gray[55]};
          background-size: cover;
        }
      }

      & > label {
        cursor: not-allowed;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      & > label {
        &:after {
          content: '';
          background: ${red[10]};
          border-color: ${red[10]};
          border: 2px solid ${gray[40]};
        }
      }
    `}
`
