import { useState } from 'react'

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  FormControl,
  FormLabel,
  Stack,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { GetPatientReturn, useDisablePatient } from '../../api'

type Props = {
  patient: GetPatientReturn
  isOpen: boolean
  mutatePatient: () => void
  onClose: () => void
  onCloseEditPatientModal: () => void
}

export const DisablePatientModal: React.FC<Props> = ({
  patient,
  mutatePatient,
  isOpen,
  onClose,
  onCloseEditPatientModal,
}) => {
  const [reason, setReason] = useState('')

  const toast = useMirohaToast()

  const handleClose = () => {
    setReason('')
    onClose()
  }

  const { request: disablePatient } = useDisablePatient({
    onSuccess: () => {
      toast({ status: 'success', title: '被験者を無効化しました' })
      mutatePatient()
      handleClose()
      onCloseEditPatientModal()
    },
    onError: error => {
      toast({ status: 'error', title: error.message })
    },
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!reason) return

    e.preventDefault()

    await disablePatient({ patientUid: patient.uid, reason })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>被験者を無効化</ModalHeader>
          <ModalBody>
            <Stack spacing="8">
              <Text fontSize="md">
                症例番号: <strong>{patient.diseaseUid}</strong>
                さんを無効化します。
              </Text>
              <FormControl>
                <FormLabel>無効化理由</FormLabel>
                <Input
                  placeholder="無効化理由を入力してください"
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button colorScheme="red" type="submit" isDisabled={!reason}>
              無効化する
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
