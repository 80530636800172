import { Button, useDisclosure } from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
  useSelectedTrial,
} from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'

import { useCreateExplanationSession } from '../../api'
import { ExplanationDetail } from '../../types'
import { willStartSession } from '../../utils/willStartSession'
import { MembersConfirmationModal } from '../MembersConfirmationModal/MembersConfirmationModal'
import { MembersSettingModal } from '../MembersSettingModal/MembersSettingModal'

type Props = {
  explanation: ExplanationDetail
  mutateExplanation: () => void
}

/** MiROHA画面側で説明を開始するボタンを含むコンポーネント
 *
 * 対面 -> 説明トップ画面へ遷移
 *
 * 遠隔/遠隔(パートナー) -> ロビーへ遷移
 *
 *  */
export const StartExplanationSession: React.FC<Props> = ({
  mutateExplanation,
  explanation,
}) => {
  const { hasPermission } = usePermission()

  const { selectedTrial } = useSelectedTrial()

  if (!hasPermission(PERMISSIONS.Explanation_Edit)) return null
  if (!willStartSession(explanation.latestRevision.status)) return null
  if (explanation.isRequiredReAgreement) return null

  return explanation.latestRevision.type === 'InPerson' ? (
    <StartInPersonSession
      explanation={explanation}
      trialUid={selectedTrial.uid}
      mutateExplanation={mutateExplanation}
    />
  ) : (
    <StartRemoteOrRemotePartnerSession
      explanation={explanation}
      trialUid={selectedTrial.uid}
      mutateExplanation={mutateExplanation}
    />
  )
}

const StartInPersonSession: React.FC<Props & { trialUid: Trial['uid'] }> = ({
  explanation,
  mutateExplanation,
  trialUid,
}) => {
  const navigate = useNavigate()

  const toast = useMirohaToast()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    isOpen: isOpenSettingModal,
    onOpen: onOpenSettingModal,
    onClose: onCloseSettingModal,
  } = useDisclosure()

  const { request: createSession } = useCreateExplanationSession({
    onSuccess: ({ explanationSessionUid: sessionUid }) => {
      navigate(
        generatePath(Paths.HospitalExplanationRoomTop, {
          trialUid,
          sessionUid,
        }),
      )
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  return (
    <>
      <Button onClick={onOpen}>
        {explanation.latestRevision.status === 'SessionNotStarted'
          ? '説明開始'
          : '説明再開'}
      </Button>
      <MembersConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        explanation={explanation}
        type="Button"
        onClick={async () => {
          await createSession({
            type: 'inPerson',
            explanationRevisionUid: explanation.latestRevision.uid,
          })
        }}
        onOpenSettingModal={() => {
          onClose()
          onOpenSettingModal()
        }}
      />
      <MembersSettingModal
        key={explanation.latestRevision.members
          .map(m => m.trialMember.uid)
          .join()}
        isOpen={isOpenSettingModal}
        onClose={() => {
          onCloseSettingModal()
          onOpen()
        }}
        explanation={explanation}
        explanationSessionUid={undefined}
        mutate={mutateExplanation}
        defaultMembers={explanation.latestRevision.members.map(m => ({
          trialHospitalUid: m.trialHospital.uid,
          trialMemberUid: m.trialMember.uid,
          role: m.trialMember.role,
        }))}
      />
    </>
  )
}

const StartRemoteOrRemotePartnerSession: React.FC<
  Props & { trialUid: Trial['uid'] }
> = ({ explanation, mutateExplanation, trialUid }) => {
  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { selectedTrial } = useSelectedTrial()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const isPartner =
    explanation.latestRevision.partnerTrialHospital?.uid ===
    selectedTrialHospitalUid

  const {
    isOpen: isOpenSettingModal,
    onOpen: onOpenSettingModal,
    onClose: onCloseSettingModal,
  } = useDisclosure()

  // 説明同意を開始できるのは医療機関ユーザーのみ
  if (!selectedTrialHospitalUid) {
    throw new Error('selectedTrialHospitalUid is not defined')
  }

  return (
    <>
      <Button onClick={onOpen}>説明ロビーへ</Button>
      <MembersConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        explanation={explanation}
        type="ExternalLink"
        href={
          selectedTrial.featureFlags.eConsentNewSignFlow && isPartner
            ? generatePath(Paths.PartnerExplanationRoomLobby, {
                trialUid,
                explanationRevisionUid: explanation.latestRevision.uid,
              })
            : generatePath(Paths.HospitalExplanationRoomLobby, {
                trialUid,
                explanationRevisionUid: explanation.latestRevision.uid,
              })
        }
        onOpenSettingModal={() => {
          onClose()
          onOpenSettingModal()
        }}
      />
      <MembersSettingModal
        key={explanation.latestRevision.members
          .map(m => m.trialMember.uid)
          .join()}
        isOpen={isOpenSettingModal}
        onClose={() => {
          onCloseSettingModal()
          onOpen()
        }}
        explanation={explanation}
        explanationSessionUid={explanation.latestRevision.latestSessionUid}
        mutate={mutateExplanation}
        defaultMembers={explanation.latestRevision.members.map(m => ({
          trialHospitalUid: m.trialHospital.uid,
          trialMemberUid: m.trialMember.uid,
          role: m.trialMember.role,
        }))}
      />
    </>
  )
}
