/** @deprecated こちらは使用せず、1かChakraUIの値の値を設定してください
 *
 * cf）https://micin.atlassian.net/wiki/spaces/dct/pages/726631057/MiROHA+ADR#z-index
 */
export const zIndex = {
  calendarComponent: 2,
  dropDown: 3,
  expandScreenIcon: 3,
  dropDownForDropDownButton: 99,
  paneBackground: 100,
  modalBackground: 100,
  blurredPaneBackground: 100,
  answerTypeSelect: 100,
  spinnerBackground: 1000,
  messageTab: 1000,
} as const
