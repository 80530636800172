import { UseMutationOption, useMutation } from 'src/hooks/use-mutation'
import {
  CreateWorksheetFieldStickyInput,
  GqlError,
  CreateWorksheetFieldStickyMutation,
  CreateWorksheetFieldStickyDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type CreateStickyReturn =
  CreateWorksheetFieldStickyMutation['createWorksheetFieldSticky']

const createWorksheetFieldSticky = async (
  input: CreateWorksheetFieldStickyInput,
): Promise<CreateStickyReturn> => {
  try {
    const res = await graphqlRequest(CreateWorksheetFieldStickyDocument, {
      input,
    })
    return res.createWorksheetFieldSticky
  } catch (error) {
    throw error
  }
}

export const useCreateWorksheetFieldSticky = (
  option?: UseMutationOption<CreateStickyReturn, GqlError>,
) => {
  return useMutation(
    (input: CreateWorksheetFieldStickyInput) =>
      createWorksheetFieldSticky(input),
    option,
  )
}
