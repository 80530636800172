import { isAgreementDocRevSigning } from './isAgreementDocRevSigning'
import {
  ExplanationDocRevision,
  ExplanationDocRevisionAgreementForm,
} from '../types'

export const parseDocRevsToCompleteAgreement = (
  docRevisions: ExplanationDocRevision[],
) => {
  const agreementDocRevs = docRevisions.filter(isAgreementDocRev)

  const requiredAgreementDocRevs = agreementDocRevs.filter(docRev =>
    isRequired(docRev),
  )

  const optionalAgreementDocRevs = agreementDocRevs.filter(
    docRev => !isRequired(docRev),
  )

  const uncompletedDocRevs = docRevisions.filter(docRev => {
    // 任意同意書の場合は拒否でもOK
    if (isAgreementDocRev(docRev) && !isRequired(docRev)) {
      return !isAgreementDocRevSignedOrRejected(docRev)
    }
    return !docRev.isCompleted
  })

  const allRequiredAgreementCompleted = requiredAgreementDocRevs.every(
    docRev => docRev.isCompleted,
  )

  const isOptionalAgreementSigning = optionalAgreementDocRevs.some(
    isAgreementDocRevSigning,
  )

  const canComplete =
    allRequiredAgreementCompleted && !isOptionalAgreementSigning

  return {
    uncompletedDocRevs,
    allRequiredAgreementCompleted,
    isOptionalAgreementSigning,
    canComplete,
  }
}

const isAgreementDocRev = (
  docRevision: ExplanationDocRevision,
): docRevision is ExplanationDocRevisionAgreementForm => {
  return docRevision.type === 'AgreementForm'
}

const isAgreementDocRevSignedOrRejected = (
  docRev: ExplanationDocRevisionAgreementForm,
) => {
  const isRejected = docRev.signHistories.some(h => h.isRejected)
  return docRev.isCompleted || isRejected
}

const isRequired = (docRev: ExplanationDocRevisionAgreementForm) => {
  return docRev.icfDocumentRevisionIsRequiredAgreement
}
