import { useState } from 'react'

import { useSelector } from 'react-redux'
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useLocation,
} from 'react-router-dom'
import { Shrink, Expand } from 'src/components/__legacy__icon/monochrome'
import { blue, gray } from 'src/components/base/color/palette'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import { useToggle } from 'src/hooks/use-toggle'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { getSingle as getPatient } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'
import { zIndex } from 'src/modules/util/z-index'
import styled, { css } from 'styled-components'

import { Head } from './head'
import { OutputModalContainer } from './output/modal-container'
import { routes } from './routes'
import { useFetch } from './use-fetch'
import { WorksheetComponent } from './worksheet/worksheet'

export const patientIdParamName = 'patientUid'

export const PatientDetail = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()
  const patient = useSelector((state: RootState) =>
    getPatient(state, patientUid),
  )
  const trial = useSelector(getSelectedTrial)!
  const { pathname } = useLocation()
  const worksheetUid = pathname.split('/')[pathname.split('/').length - 1]
  const { on: expanded, toggle: toggleExpanded } = useToggle()
  const ToggleIcon = expanded ? Shrink : Expand

  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: () => setModalOpen(false),
    deps: [worksheetUid],
  })

  const { fetched } = useFetch({ trialUid: trial.uid, patientUid })

  if (!fetched) {
    return null
  }

  if (!patient) {
    return <Navigate replace to={'./'} />
  }

  return (
    <>
      <Container>
        <HeadContainer>
          <Head patient={patient} backUrl={'../'} worksheetUid={worksheetUid} />
        </HeadContainer>

        <ContentContainer expanded={expanded}>
          <IconWrapper>
            <ToggleIcon size="M" onClick={toggleExpanded} color={blue[70]} />
          </IconWrapper>
          <Routes>
            <Route
              path={`${routes.worksheet}/*`}
              element={<WorksheetComponent />}
            />
            <Route
              path={`*`}
              element={<Navigate replace to={`${routes.worksheet}`} />}
            />
          </Routes>
        </ContentContainer>
      </Container>

      {modalOpen && (
        <OutputModalContainer
          patientUid={patientUid}
          diseaseUid={`${patient.diseaseUid}`}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  height: 100%;
`

const HeadContainer = styled.div`
  width: 25%;
  height: 100%;
  margin: 0;
  border-right: 1px solid ${gray[55]};
`
const ContentContainer = styled.div<{ expanded: boolean }>`
  width: 75%;
  height: 100%;
  margin: 0;

  ${props =>
    props.expanded &&
    css`
      position: absolute;
      right: 0;
      width: 95%;
    `}
`

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  bottom: 30px;
  right: 35px;
  z-index: ${zIndex.expandScreenIcon};
  cursor: pointer;
`
