import { useCallback, useMemo, useState } from 'react'

import { Pane, PaneType } from '../components/base/pane/pane'

type PaneOptions = {
  paneType: PaneType
  defaultOpen?: boolean
  closeOnOutsideClick?: boolean
}

export const usePane = (options: PaneOptions) => {
  const { paneType, defaultOpen, closeOnOutsideClick } = options
  const [paneOpen, setPaneOpen] = useState(!!defaultOpen)
  const handlers = useMemo(
    () => ({
      openPane: () => {
        setPaneOpen(true)
      },
      closePane: () => {
        setPaneOpen(false)
      },
    }),
    [],
  )

  const renderPane = useCallback(
    (content: React.ReactNode) => {
      return (
        paneOpen && (
          <Pane
            paneType={paneType}
            content={content}
            onClose={handlers.closePane}
            closeOnOutsideClick={closeOnOutsideClick}
          />
        )
      )
    },
    [handlers.closePane, paneOpen, paneType, closeOnOutsideClick],
  )

  return { handlers, renderPane }
}
