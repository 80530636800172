import { generatePath, useLocation, useParams } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  useCurrentMember,
  useSelectedTrial,
} from 'src/features/auth/context'

import { useCreateSignAuthentication } from '../../api/createSignAuthentication'
import {
  getSignerDisplayName,
  useDocRevisionAndSessionForSign,
} from '../../api/getDocRevisionAndSessionForSign'
import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { DocuSignIframeContent } from '../DocuSignIframeContent/DocuSignIframeContent'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { SignAuthChecker } from '../SignAuthChecker/SignAuthChecker'

type Param = {
  sessionUid: string
  docRevisionUid: string
  signerActorUid: string
}

export const HospitalDocumentSignScreen: React.FC = () => {
  const {
    sessionUid = '',
    docRevisionUid = '',
    signerActorUid = '',
  } = useParams<Param>()

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const { selectedTrial } = useSelectedTrial()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { currentMember } = useCurrentMember()

  const { data } = useDocRevisionAndSessionForSign({
    explanationDocRevisionUid: docRevisionUid,
    explanationSessionUid: sessionUid,
  })

  const { data: signAuth, isValidating } = useCreateSignAuthentication({
    explanationSessionUid: sessionUid,
    explanationDocRevisionUid: docRevisionUid,
    signerActorUid,
    revalidateOnFocus: false,
  })

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (!data || !selectedTrialHospitalUid || !signAuth || isValidating) {
    return null
  }

  const roomTopPath = generatePath(Paths.HospitalExplanationRoomSigning, {
    trialUid: selectedTrial.uid,
    sessionUid,
  })

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      sessionUid={sessionUid}
      explanationRevisionUid={data.explanationSession.explanationRevision.uid}
      explanationPatientUid={
        data.explanationSession.explanationRevision.explanation.patient.uid
      }
      patientPhoneNumber={
        data.explanationSession.explanationRevision.explanation.patient
          .mobileNumber ?? undefined
      }
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout
        headerTitle={data.explanationDocRevision.icfDocumentRevision.name}
        sessionUid={sessionUid}
        showSidebar={false}
      >
        <SignAuthChecker
          isSP={false}
          trialUid={selectedTrial.uid}
          explanationSignAuthUid={signAuth.signAuthenticationUid}
          roomTopPath={roomTopPath}
        >
          <DocuSignIframeContent
            // 実施医療機関の場合は署名フロー画面に戻る
            roomTopPath={roomTopPath}
            sessionUid={sessionUid}
            docRevisionUid={docRevisionUid}
            signerDisplayName={getSignerDisplayName(data, signerActorUid)}
            signerActorUid={signerActorUid}
            completePath={
              generatePath(Paths.HospitalExplanationRoomSignCompleted, {
                trialUid: selectedTrial.uid,
                sessionUid,
                signerActorUid,
              }) +
              `?${RoomEventUidQueryKey}=${searchParams.get(RoomEventUidQueryKey)}`
            }
            roomEventUid={searchParams.get(RoomEventUidQueryKey) ?? ''}
          />
        </SignAuthChecker>
        {renderFloatingVideoCall({ right: 24, bottom: 96 })}
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}
