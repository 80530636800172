import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  FormControl,
  Input,
  ModalFooter,
  Button,
  FormLabel,
  FormErrorMessage,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useEnableWorksheet } from '../../api/enableWorksheet'
import { WorksheetDetail } from '../../types'
import { MAX_TEXT_LENGTH } from '../../utils/validate'

type Props = {
  isOpen: boolean
  isFollowUp: boolean
  worksheet: WorksheetDetail
  onComplete: (worksheet: WorksheetDetail) => void
  onCancel: () => void
}

export const EnableWorksheetModal: React.FC<Props> = ({
  isOpen,
  isFollowUp,
  onCancel,
  onComplete,
  worksheet,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<{
    reason: string
  }>({
    mode: 'onTouched',
  })

  const toast = useMirohaToast()

  const { request: enableWorksheet } = useEnableWorksheet({
    onSuccess: worksheet => {
      onComplete(worksheet)
      toast({
        title: 'ワークシートを有効化しました。',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  return (
    <Modal onClose={onCancel} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Text fontSize="lg" fontWeight="bold">
            ワークシートを有効化
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text>
            {isFollowUp ? (
              <>
                <Text as="span" fontWeight="bold">
                  {`#${worksheet.index} ${worksheet.name}`}
                </Text>
                <Text
                  mx="1"
                  as="span"
                  fontSize="sm"
                  fontWeight="bold"
                  color="gray.500"
                >
                  {worksheet.schema.name}
                </Text>
              </>
            ) : (
              <Text as="span" fontWeight="bold">
                {worksheet.name}
              </Text>
            )}
            <Text as="span">を有効化します。</Text>
          </Text>
          <FormControl
            id="reason"
            mt="6"
            isInvalid={errors['reason']?.type === 'maxLength'}
          >
            <FormLabel>有効化理由</FormLabel>

            <Input
              placeholder="有効化理由を入力してください"
              {...register('reason', {
                required: true,
                maxLength: MAX_TEXT_LENGTH,
              })}
            />

            {errors['reason']?.type === 'maxLength' && (
              <FormErrorMessage color="red.500" fontSize="xs" mt="1.5" mb="0">
                {MAX_TEXT_LENGTH}文字以内で入力してください。
              </FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            onClick={handleSubmit(({ reason }) =>
              enableWorksheet({
                worksheetUid: worksheet.uid,
                reason,
              }),
            )}
            isDisabled={!isDirty || !isValid}
          >
            有効化する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
