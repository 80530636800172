import { ExplanationRevisionStatusV2 } from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'

export const isAgreementCompletedOrRejected: (
  status: ExplanationRevisionStatusV2,
) => boolean = status => {
  switch (status) {
    case 'Invalid':
    case 'New':
    case 'SessionStarted':
    case 'SignStarted':
    case 'SignReady':
    case 'SignCompleted':
    case 'Withdrawn':
    case 'Disabled':
      return false
    case 'AgreementCompleted':
    case 'DeliveryCompleted':
    case 'AgreementRejected':
      return true
    default:
      return assertNever(status)
  }
}
