import { useCallback, useEffect, useRef, useState } from 'react'

import { LocalAudioTrack, RemoteAudioTrack } from '@micin-jp/call-lib-core'

// https://github.com/micin-jp/call-lib/blob/master/packages/call-lib-react/src/stories/volume-meter.ts を元に実装
export const useVolumeMeter = ({
  audioTrack,
}: {
  audioTrack?: LocalAudioTrack | RemoteAudioTrack | null
}) => {
  const reqIdRef = useRef<number | null>(null)
  const [isSpeaking, setIsSpeaking] = useState(false)
  const threshold = -12
  const timer = useRef<NodeJS.Timeout | null>(null)
  const observeVolume = useCallback(() => {
    reqIdRef.current = requestAnimationFrame(observeVolume)
    if (!audioTrack?.peakLevel) {
      return
    }
    // isSpeakingをtrueにしたら2秒後に消えるtimerをセットする
    // 話続けている場合はtimerを更新し続ける
    if (audioTrack.peakLevel > threshold) {
      timer.current && clearTimeout(timer.current)
      setIsSpeaking(true)
      timer.current = setTimeout(() => {
        setIsSpeaking(false)
      }, 1000)
    }
  }, [audioTrack, threshold, setIsSpeaking])

  useEffect(() => {
    observeVolume()
    return () => {
      reqIdRef.current && cancelAnimationFrame(reqIdRef.current)
      setIsSpeaking(false)
    }
  }, [observeVolume])

  return { isSpeaking }
}
