import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const resendDeliveryDocs = async ({
  trialUid,
  explanationRevisionUid,
  target,
  trialMemberUid,
}: {
  trialUid: string
  explanationRevisionUid: string
  target: 'Member' | 'Patient'
  trialMemberUid?: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/resend_delivery_docs_email',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: {
        target: target,
        trialMemberUid: trialMemberUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return
}

export const useResendDeliveryDocs = (options?: UseMutationOption<void>) => {
  return useMutation(
    ({
      trialUid,
      explanationRevisionUid,
      target,
      trialMemberUid,
    }: {
      trialUid: string
      explanationRevisionUid: string
      target: 'Member' | 'Patient'
      trialMemberUid?: string
    }) =>
      resendDeliveryDocs({
        trialUid,
        explanationRevisionUid,
        target,
        trialMemberUid,
      }),
    options,
  )
}
