import React from 'react'

import { Text } from '@chakra-ui/react'
import { CheckOutline } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import styled from 'styled-components'

import { Header } from '../components/header'

type Props = {
  questionnaireAnswer: QuestionnaireAnswer
  onContinue: () => void
  onBackHome: () => void
  showContinueAnswerButton: boolean
}

export const Complete: React.FC<Props> = ({
  questionnaireAnswer,
  onContinue,
  onBackHome,
  showContinueAnswerButton,
}) => {
  const {
    displayName,
    answerStartDate,
    answerEndDate,
    shouldDeliverAfterTrial,
  } = questionnaireAnswer

  return (
    <Container>
      <Header
        displayName={displayName}
        answerStartDate={answerStartDate}
        answerEndDate={answerEndDate}
        shouldDeliverAfterTrial={shouldDeliverAfterTrial}
        showStopButton={false}
      />

      <Content>
        <CheckOutline size={120} color={blue[70]} />
        <Spacer size={24} />
        {/* TODO: 患者側デザイントークン定義時にサイズ置き換え */}
        <Text fontSize="3xl" textAlign="center">
          回答の入力は以上です。 お疲れさまでした。
        </Text>
        {showContinueAnswerButton && (
          <>
            <Spacer size={80} />
            <Button
              text={'続けて回答する'}
              size="M"
              buttonType="important"
              onClick={onContinue}
            />
          </>
        )}
        <Spacer size={40} />
        <Button
          text={'ホームに戻る'}
          size="M"
          buttonType="normal"
          onClick={onBackHome}
        />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  box-sizing: border-box;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
