import { updateWithdrawExplanationHandler } from './../updateWithdrawExplanation/mock'
import { challengeSignAuthHandler } from '../challengeSignAuth/mock'
import { challengeSignAuthV2Handler } from '../challengeSignAuthV2/mock'
import { completeAgreementHandler } from '../completeAgreement/mock'
import { createAgreementFormEnvelopeHandler } from '../createEnvelope/mock'
import { createEnvelopeViewUrlHandler } from '../createEnvelopeViewUrl/mock'
import { createExplanationHandler } from '../createExplanation/mock'
import { createExplanationRevisionHandler } from '../createExplanationRevision/mock'
import { createSessionHandler } from '../createSession/mock'
import { deliveryDocumentsHandler } from '../deliveryDocuments/mock'
import { disableExplanationRevisionHandler } from '../disableExplanationRevision/mock'
import { finishSessionHandler } from '../finishSession/mock'
import { getExplanationHandler } from '../getExplanation/mock'
import { getExplanationHistoryHandler } from '../getExplanationHistory/mock'
import { getExplanationsHandler } from '../getExplanations/mock'
import { getExplanationWithdrawHistoriesHandler } from '../getExplanationWithdrawHistories/mock'
import { getExplanationSessionHandler } from '../getSession/mock'
import { getExplanationTrialHospitalsHandler } from '../getTrialHospitals/mock'
import { getExplanationTrialMembersHandler } from '../getTrialMembers/mock'
import { rejectAgreementHandler } from '../rejectAgreement/mock'
import { requestPinSettingHandler } from '../requestPinSetting/mock'
import { resendAuthCodeByVoiceHandler } from '../resendAuthCodeByVoice/mock'
import { resendNotificationHandler } from '../resendNotification/mock'
import { resendSignAuthEmailHandler } from '../resendSignAuthEmail/mock'
import {
  sendAgreementFormEnvelopeEmailHandler,
  sendCheckUnderstandingEnvelopeEmailHandler,
} from '../sendEnvelopeEmail/mock'
import { sendTestEmailHandler } from '../sendTestEmail/mock'
import { sendTestSMSHandler } from '../sendTestSMS/mock'
import { setExpDocRevisionSignerMemberHandler } from '../setExpDocRevisionSignerMember/mock'
import { setPinHandler } from '../setPin/mock'
import { setPinForPatientHandler } from '../setPinForPatient/mock'
import { syncAllEnvelopesHandler } from '../syncAllEnvelopes/mock'
import { syncEnvelopeHandler } from '../syncEnvelope/mock'
import { updateDeliveryDateHandler } from '../updateDeliveryDate/mock'
import { updateExpMembersHandler } from '../updateExpMembers/mock'
import { updatePreSessionIdentificationHandler } from '../updatePreSessionIdentification/mock'
import { updateScheduleHandler } from '../updateSchedule/mock'
import { verifyAuthenticationCodeHandler } from '../verifyAuthenticationCode/mock'
import { verifyOTPHandler } from '../verifyOTP/mock'
import { verifyPinHandler } from '../verifyPin/mock'
import { withdrawExplanationHandler } from '../withdrawExplanation/mock'

export const explanationsHandlers = [
  challengeSignAuthHandler(),
  completeAgreementHandler(),
  createAgreementFormEnvelopeHandler(),
  createExplanationHandler(),
  createExplanationRevisionHandler(),
  createEnvelopeViewUrlHandler(),
  createSessionHandler(),
  deliveryDocumentsHandler(),
  disableExplanationRevisionHandler(),
  finishSessionHandler(),
  getExplanationHandler(),
  getExplanationHistoryHandler(),
  getExplanationsHandler(),
  getExplanationTrialHospitalsHandler(),
  getExplanationWithdrawHistoriesHandler(),
  getExplanationSessionHandler(),
  getExplanationTrialMembersHandler(),
  rejectAgreementHandler(),
  resendNotificationHandler(),
  resendSignAuthEmailHandler(),
  sendTestEmailHandler(),
  sendTestSMSHandler(),
  setExpDocRevisionSignerMemberHandler(),
  sendAgreementFormEnvelopeEmailHandler(),
  sendCheckUnderstandingEnvelopeEmailHandler(),
  syncAllEnvelopesHandler(),
  syncEnvelopeHandler(),
  updateDeliveryDateHandler(),
  updateExpMembersHandler(),
  updatePreSessionIdentificationHandler(),
  updateScheduleHandler(),
  updateWithdrawExplanationHandler(),
  withdrawExplanationHandler(),
  requestPinSettingHandler(),
  setPinHandler(),
  setPinForPatientHandler(),
  verifyPinHandler(),
  verifyAuthenticationCodeHandler(),
  resendAuthCodeByVoiceHandler(),
  challengeSignAuthV2Handler(),
  verifyOTPHandler(),
]
