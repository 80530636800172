import {
  Box,
  Divider,
  Image,
  Link,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react'
import ReactMarkdown, { Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MarkdownContent } from 'src/utils/markdown'

import { ExternalLink } from '../icon'

type Props = {
  content: MarkdownContent
  /** markdown内で画像がパスで指定されている場合は画像が配置されているディレクトリのパスを指定してください。 */
  imagePath?: string
}

const chakraComponents = ({
  imagePath,
}: {
  imagePath?: string
}): Components => ({
  h1: ({ children }) => (
    <Text as="h1" fontWeight="bold" fontSize="4xl" mt="8" mb="1.5">
      {children}
    </Text>
  ),
  h2: ({ children }) => (
    <Text as="h2" fontWeight="bold" fontSize="2xl" mt="8">
      {children}
    </Text>
  ),
  h3: ({ children }) => (
    <Text as="h3" fontWeight="bold" fontSize="xl" mt="8">
      {children}
    </Text>
  ),
  h4: ({ children }) => (
    <Text as="h4" fontWeight="bold" fontSize="lg" mt="6">
      {children}
    </Text>
  ),
  h5: ({ children }) => (
    <Text as="h5" fontWeight="bold" fontSize="md" mt="6">
      {children}
    </Text>
  ),
  h6: ({ children }) => (
    <Text as="h6" fontWeight="bold" fontSize="sm" mt="6">
      {children}
    </Text>
  ),
  p: ({ children }) => <Text mt="3">{children}</Text>,
  img: ({ src, alt }) => {
    const fileName = src?.split('/').pop()
    const convertedSrc = imagePath ? `${imagePath}/${fileName}` : src
    return (
      <Box py="4" as="span" display="block">
        <Image
          src={convertedSrc}
          alt={alt}
          boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.15)"
          borderRadius="base"
        />
      </Box>
    )
  },
  table: ({ children }) => (
    <TableContainer my="8">
      <Table>{children}</Table>
    </TableContainer>
  ),
  thead: Thead,
  tbody: Tbody,
  tr: Tr,
  th: Th,
  td: Td,
  ul: ({ children }) => (
    <UnorderedList spacing={2} mt="3" fontSize="md">
      {children}
    </UnorderedList>
  ),
  ol: ({ children }) => (
    <OrderedList spacing={2} mt="3" fontSize="md">
      {children}
    </OrderedList>
  ),
  li: ListItem,
  hr: () => <Divider mt="12" />,
  a: ({ children, href }) => (
    <Link href={href} isExternal color="blue.500" display="inline">
      {children}
      <Box as="span" display="inline-flex" ml="1" alignItems="center">
        <ExternalLink size="1rem" />
      </Box>
    </Link>
  ),
})

export const Markdown: React.FC<Props> = ({ content, imagePath }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        ...chakraComponents({ imagePath }),
      }}
    >
      {content}
    </ReactMarkdown>
  )
}
