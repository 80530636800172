/**
 * "members[0].member.uid"
 * のような文字列から0を抜き取る
 */
export function getIndexFromObjectString(key: string): number | undefined {
  if (!key.includes('[') || !key.includes(']')) return undefined
  const index = Number(key.slice(key.indexOf('[') + 1, key.indexOf(']')))
  if (isNaN(index)) return undefined
  return index
}
