import { useState } from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Add } from 'src/components/icon'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { CreateCriteriaDocument } from 'src/lib/gql-client'
import { useGqlMutation } from 'src/lib/gql-client/request'

import { errorMessages } from '../../../../constants/errorMessages'

export const AddCriteriaButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [title, setTitle] = useState('')

  const { selectedTrial } = useSelectedTrial()
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState('')

  const toast = useMirohaToast()

  const { request: createCriteria } = useGqlMutation(CreateCriteriaDocument, {
    onSuccess: ({ createCriteria: { criteriaUid } }) => {
      toast({
        status: 'success',
        title: '選択除外基準を登録しました。',
      })
      navigate(
        generatePath(Paths.CriteriaDetail, {
          trialUid: selectedTrial.uid,
          criteriaUid,
        }),
      )
    },
    onError: error => {
      setErrorMessage(error.message)
    },
  })

  return (
    <>
      <Button variant="outline" leftIcon={<Add />} onClick={onOpen}>
        新規登録
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          setTitle('')
          setErrorMessage('')
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>選択除外基準登録</ModalHeader>
          <ModalBody>
            <FormControl isRequired isInvalid={!!errorMessage}>
              <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
                作成する選択除外基準のタイトルを入力してください。
              </FormLabel>
              <Input
                value={title}
                onChange={e => {
                  if (e.target.value.length > 100) {
                    setErrorMessage(errorMessages.maxLength(100))
                  } else if (!e.target.value) {
                    setErrorMessage(errorMessages.required)
                  } else {
                    setErrorMessage('')
                  }
                  setTitle(e.target.value)
                }}
                onBlur={e => {
                  if (!e.target.value) {
                    setErrorMessage(errorMessages.required)
                  }
                }}
              />
              {!!errorMessage && (
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              isDisabled={!title || !!errorMessage}
              onClick={async () => {
                await createCriteria({
                  input: {
                    title,
                  },
                })
              }}
            >
              登録
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
