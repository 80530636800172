import { definitions } from 'src/lib/api-client/schema.gen'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost, requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'
import { generateUUID } from 'src/utils/generateUUID'

import { TemplateStatus, Template } from './entity'
import { fixtures, fixtureDetails, fixture } from './fixture'
import { parse as parseMediumItem } from '../medium-item/request'

export type TemplateResponse = definitions['output.TemplateResponse']

export const parse = (resp: TemplateResponse): Template => {
  return {
    uid: resp.uid,
    trialUid: resp.trial_uid,
    title: resp.title,
    templateFolderUid: resp.template_folder_uid,
    presetUsed: resp.preset_used,
    status: resp.status,
    createdAt: resp.created_at,
    updatedAt: resp.updated_at,
    releasedAt: resp.released_at,
    videoEnabled: resp.video_enabled,
    index: resp.index,
    mediumItems: resp.medium_items
      ? resp.medium_items.map(parseMediumItem).sort((a, b) => a.index - b.index)
      : [],
  }
}

export const fetchList = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<Template[]> => {
  if (IS_MOCK_MODE) {
    return fixtures
  }

  const resp = await requestGet<TemplateResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/templates`,
    params: {},
  })

  return resp.data.map(parse)
}

export const fetchSingle = async ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}): Promise<Template> => {
  if (IS_MOCK_MODE) {
    return fixtureDetails.find(t => t.uid === templateUid) || fixtureDetails[0]
  }

  const resp = await requestGet<TemplateResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${templateUid}`,
    params: {},
  })

  return parse(resp.data)
}

export const create = async ({
  trialUid,
  title,
  templateFolderUid,
  presetTemplateUid,
  videoEnabled,
}: {
  trialUid: string
  title: string
  templateFolderUid: string
  presetTemplateUid: string | undefined
  videoEnabled?: boolean
}): Promise<Template> => {
  if (IS_MOCK_MODE) {
    return {
      ...fixtures[0],
      uid: generateUUID(),
      status: TemplateStatus.Draft,
      title,
      templateFolderUid,
    }
  }

  const res = await requestPost<TemplateResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/templates`,
    params: {
      title,
      template_folder_uid: templateFolderUid,
      preset_template_uid: presetTemplateUid,
      video_enabled: !!videoEnabled,
    },
  })

  return parse(res.data)
}

export const importTemplate = async ({
  fileName,
  content,
  trialUid,
  templateFolderUid,
  title,
}: {
  fileName: string
  content: string
  trialUid: string
  templateFolderUid: string
  title: string
}): Promise<Template> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPost<TemplateResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/import`,
    params: {
      file_name: fileName,
      content,
      template_folder_uid: templateFolderUid,
      title,
    },
  })

  return parse(res.data)
}

export const updateTitle = async ({
  trialUid,
  templateUid,
  title,
}: {
  trialUid: string
  templateUid: string
  title: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {
      ...fixture,
      uid: templateUid,
      title: title,
    }
  }

  await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${templateUid}/title`,
    params: { title },
  })

  return {}
}
