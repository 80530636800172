import { generateUUID } from 'src/utils/generateUUID'

export type Choice = {
  uid: string
  description: string
  edcValue: string
  smallItemUids: string[]
}

export const createEmpty = (): Choice => ({
  uid: generateUUID(),
  edcValue: '',
  description: '',
  smallItemUids: [],
})
