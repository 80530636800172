import { Fragment, useState } from 'react'

import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Link,
  Spacer,
  Tab,
  Table,
  TableCellProps,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { Check, Delete } from 'src/components/__legacy__icon/monochrome'
import { Select } from 'src/components/Select/Select'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { usePermission } from 'src/features/auth/context'
import { icfDocumentTypeToText } from 'src/features/icfDocument/utils/icfDocumentTypeToText'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PERMISSIONS } from 'src/lib/permission'
import { sortByDate } from 'src/modules/util/sort'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useExplanation, useExplanationSession } from '../../api'
import { useExplanationHistory } from '../../api/getExplanationHistory'
import {
  ChangeDeliveryDateHistory,
  DeliveryHistory,
  ExplanationDetail,
  ExplanationDocSetHistory,
  ExplanationHistory,
  ExplanationRemotePartnerSession,
  ExplanationRemoteSession,
  ExplanationRevision,
  ExplanationRevisionDetail,
  ExplanationSession,
} from '../../types'
import { explanationRevisionDeliveryTypeToText } from '../../utils/deliveryTypeToText'
import { getExplanationDocBrowsingHistories } from '../../utils/getExplanationDocBrowsingHistories'
import { explanationTypeToText } from '../../utils/typeToText'
import { ExplanationMemberLabel } from '../ExplanationMemberLabel/ExplanationMemberLabel'
import { ExplanationPatientInformationSectionForHistory } from '../ExplanationPatientInformationSectionForHistory/ExplanationPatientInformationSectionForHistory'
import { MembersTable } from '../MembersTable/MembersTable'
import { SyncAllEnvelopes } from '../SyncAllEnvelopes/SyncAllEnvelopes'

type Props = {
  isOpen: boolean
  onClose: () => void
  explanation: ExplanationDetail
}

/** 変更の理由を含めて2行の表示が必要な場合があるcell */
const twoRowTdStyle = (twoRow: boolean): TableCellProps => ({
  borderBottom: twoRow ? 'none' : undefined,
  pb: twoRow ? '1.5' : undefined,
})

export const ExplanationHistoryDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  explanation,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl" placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody py="8" px="10">
          <ExplanationPatientInformationSectionForHistory
            patient={explanation.patient}
            borderRadius="4px"
            bg="blue.50"
          />
          <ExplanationHistoryNavigationTabs
            explanation={explanation}
            candidateId={explanation.patient.candidateId}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const tabs = ['説明履歴', '署名履歴', '交付履歴', '閲覧履歴']

type ExplanationHistoryNavigationTabsProps = {
  candidateId: string
  explanation: ExplanationDetail
}
const ExplanationHistoryNavigationTabs: React.FC<
  ExplanationHistoryNavigationTabsProps
> = ({ candidateId, explanation }) => {
  const { revisionUids } = explanation

  const [explanationRevisionUid, setExplanationRevisionUid] = useState<
    ExplanationRevision['uid']
  >(revisionUids[0])

  const { data: explanationHistory, mutate: mutateHistories } =
    useExplanationHistory({
      explanationRevisionUid,
    })

  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  if (explanationHistory === undefined) return null

  return (
    <Tabs
      pt="8"
      aria-label="explanation-history navigation tabs"
      flexDirection="row"
      sx={{ flexDirection: 'row' }}
      index={selectedIndex}
      onChange={idx => setSelectedIndex(idx)}
    >
      <TabList>
        {tabs.map(label => (
          <Tab key={label} display="inline-flex">
            {label}
          </Tab>
        ))}
        <Spacer />
        <Box mb="2">
          <Select
            width={180}
            placeholder="初回説明"
            items={revisionUids.map((uid, idx) => {
              if (idx === revisionUids.length - 1)
                return { value: uid, label: '初回説明' }

              return {
                value: uid,
                label: `再同意説明(${revisionUids.length - idx - 1})`,
              }
            })}
            onChange={setExplanationRevisionUid}
            value={explanationRevisionUid}
          />
        </Box>
      </TabList>
      <TabPanels>
        <TabPanel px="0" py="6">
          <ExplanationHistorySection
            key={explanationHistory.explanationRevisionUid}
            explanationHistory={explanationHistory}
          />
        </TabPanel>
        <TabPanel px="0" py="6">
          <ExplanationSignHistorySection
            candidateId={candidateId}
            documentSetsHistory={explanationHistory.explanationDocSetHistories}
            explanationRevision={explanation.latestRevision}
            mutateHistories={() => mutateHistories()}
          />
        </TabPanel>
        <TabPanel px="0" py="6">
          <ExplanationDeliveryHistorySection
            deliveryHistories={explanationHistory.deliveryHistories}
            changeDeliveryDateHistories={
              explanationHistory.changeDeliveryHistories
            }
            documentSetsHistory={explanationHistory.explanationDocSetHistories}
          />
        </TabPanel>
        <TabPanel px="0" py="6">
          <ExplanationBrowseHistorySection
            candidateId={candidateId}
            documentSetsHistory={explanationHistory.explanationDocSetHistories}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

const ExplanationHistorySection = ({
  explanationHistory,
}: {
  explanationHistory: ExplanationHistory
}) => {
  const { explanationSessions } = explanationHistory
  const [explanationSessionUid, setExplanationSessionUid] = useState<
    ExplanationSession['uid'] | undefined
  >(explanationSessions.length > 0 ? explanationSessions[0].uid : undefined)

  return (
    <Box as="section">
      <TextWithBar as="h2" id="explanation-histories">
        説明履歴
      </TextWithBar>
      <VStack paddingY="4" paddingX="2" spacing="6" alignItems="start">
        <Box>
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            前回説明履歴
          </Text>
          <Box height="1.5" />
          {!!explanationSessions && (
            <Select
              width={280}
              value={explanationSessionUid}
              onChange={setExplanationSessionUid}
              items={explanationHistory.explanationSessions.map(ses => {
                return {
                  label: formatDate(ses.startedAt, 'YYYY/MM/DD (ddd) HH:mm'),
                  value: ses.uid,
                }
              })}
            />
          )}
        </Box>
      </VStack>
      {explanationSessionUid && (
        <ExplanationHistorySectionDetail
          explanationSessionUid={explanationSessionUid}
        />
      )}
    </Box>
  )
}

const ExplanationHistorySectionDetail = ({
  explanationSessionUid,
}: {
  explanationSessionUid: ExplanationSession['uid']
}) => {
  const { data: explanationSession } = useExplanationSession({
    explanationSessionUid,
    allowFinished: true,
  })
  if (explanationSession === undefined) return null
  let partnerTrialHospitalName: string = ''
  if (explanationSession.explanationType === 'RemotePartner') {
    const remotePartnerSession: ExplanationRemotePartnerSession =
      explanationSession as ExplanationRemotePartnerSession
    partnerTrialHospitalName = remotePartnerSession.partnerTrialHospital.name
  }

  const explanationPreSession =
    explanationSession.explanationType === 'Remote'
      ? (explanationSession as ExplanationRemoteSession).explanationPreSession
      : explanationSession.explanationType === 'RemotePartner'
        ? (explanationSession as ExplanationRemotePartnerSession)
            .explanationPreSession
        : undefined

  return (
    <Box>
      <VStack paddingY="4" paddingX="2" spacing="6" alignItems="start">
        <Flex gap="16">
          <Box>
            <Text fontSize="sm" fontWeight="bold" color="gray.600">
              説明方式
            </Text>
            <Box height="1.5" />
            <Text>
              {explanationTypeToText(explanationSession.explanationType)}
            </Text>
          </Box>
          {explanationSession.explanationType === 'RemotePartner' && (
            <Box>
              <Text fontSize="sm" fontWeight="bold" color="gray.600">
                パートナー施設
              </Text>
              <Box height="2" />
              <Text>{partnerTrialHospitalName}</Text>
            </Box>
          )}
          {explanationPreSession && (
            <Box>
              <Text fontSize="sm" fontWeight="bold" color="gray.600">
                本人確認
              </Text>
              <Box height="2" />
              <HStack>
                {explanationPreSession.identifierTrialMember?.lastName &&
                  explanationPreSession.identifierTrialMember?.firstName && (
                    <Text>{`${explanationPreSession.identifierTrialMember?.lastName} ${explanationPreSession.identifierTrialMember?.firstName}`}</Text>
                  )}
                <Text>
                  {explanationPreSession.identifiedAt &&
                    formatDate(
                      explanationPreSession.identifiedAt,
                      'YYYY/MM/DD (ddd) HH:mm',
                    )}
                </Text>
              </HStack>
            </Box>
          )}
        </Flex>
        <Box width="100%">
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            説明担当者
          </Text>
          <Box height="1.5" />
          <MembersTable members={explanationSession.members} />
        </Box>
        <Box>
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            代諾者
          </Text>
          <Box height="1.5" />
          {/* FIXME: Fix character */}
          <Text>
            {explanationSession?.withRepresentative ? '有り' : '無し'}
          </Text>
        </Box>
      </VStack>
    </Box>
  )
}

type ExplanationSignHistorySectionType = {
  candidateId: string
  documentSetsHistory: ExplanationDocSetHistory[]
  explanationRevision: ExplanationRevisionDetail
  mutateHistories: () => void
}
const ExplanationSignHistorySection: React.FC<
  ExplanationSignHistorySectionType
> = ({
  candidateId,
  documentSetsHistory,
  explanationRevision,
  mutateHistories,
}) => {
  const { hasPermission } = usePermission()

  const canReadSignedDocument = hasPermission(PERMISSIONS.Explanation_Edit)

  const { mutate: mutateExplanation } = useExplanation({
    explanationUid: explanationRevision.explanationUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: false,
  })

  const { status, latestSessionUid } = explanationRevision

  return (
    <Box as="section">
      <HStack spacing="2">
        <TextWithBar as="h2" id="explanation-histories">
          署名履歴
        </TextWithBar>

        {(status === 'AgreementNotDone' || status === 'AgreementDone') &&
          !!latestSessionUid && (
            <SyncAllEnvelopes
              buttonType="icon"
              sessionUid={latestSessionUid}
              onSyncDone={() => {
                mutateHistories()
                mutateExplanation()
              }}
            />
          )}
      </HStack>
      <VStack paddingY="4" alignItems="start" gap="6">
        {documentSetsHistory.map(
          ({ uid, numberingId, name: documentSetName, signHistories }) => {
            return (
              <Box width="100%" key={uid}>
                <Text fontWeight="bold" fontSize="md" color="gray.600">
                  {`${numberingId}.${documentSetName}`}
                </Text>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th textAlign="left">署名/拒否</Th>
                        <Th>完了時間</Th>
                        <Th>文書</Th>
                        <Th>署名担当者</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortByDate(signHistories, 'operatedAt', 'desc').map(
                        ({
                          uid,
                          isRejected,
                          operatedAt,
                          numberingId,
                          name: documentName,
                          signedFileUrl,
                          isPatientConsentRequired,
                          member,
                          isPartner,
                          signerRole,
                          rejectedReason,
                          latestCreateEnvelopeViewUrlHistory: viewUrlHistory,
                        }) => {
                          // 途中から入った機能のため存在しないケースあり
                          // viewUriHistoryがあるがcreatorがいない、かつ署名者が患者の場合は患者が端末で署名したものとみなす
                          // 複雑でわかりにくいので要リファクタ
                          const deviceActor = !!viewUrlHistory
                            ? !!viewUrlHistory.creator
                              ? getFullName(viewUrlHistory.creator)
                              : signerRole === 'Patient'
                                ? candidateId
                                : null
                            : null
                          return (
                            <Fragment key={uid}>
                              <Tr>
                                <Td {...twoRowTdStyle(isRejected)}>
                                  {isRejected ? (
                                    <Flex alignItems="center" gap="2">
                                      <Delete
                                        color={colors.red[600]}
                                        size="M"
                                      />
                                      拒否
                                    </Flex>
                                  ) : (
                                    <Flex alignItems="center" gap="2">
                                      <Check
                                        color={colors.green[600]}
                                        size="M"
                                      />
                                      署名
                                    </Flex>
                                  )}
                                </Td>
                                <Td {...twoRowTdStyle(isRejected)}>
                                  {formatDate(
                                    operatedAt,
                                    'YYYY/MM/DD (ddd) HH:mm',
                                  )}
                                </Td>
                                <Td
                                  {...twoRowTdStyle(isRejected)}
                                  alignItems="center"
                                  maxW="240px"
                                >
                                  <Flex
                                    alignItems="center"
                                    gap="2"
                                    whiteSpace="pre-wrap"
                                  >
                                    {canReadSignedDocument && !isRejected ? (
                                      <Link
                                        isExternal
                                        color="blue.500"
                                        href={signedFileUrl}
                                      >
                                        {`${numberingId}.${documentName}`}
                                        {/* TODO: external link icon */}
                                      </Link>
                                    ) : (
                                      `${numberingId}.${documentName}`
                                    )}
                                    {isPatientConsentRequired && (
                                      <Badge bg="blue.400" color="white">
                                        同意必須
                                      </Badge>
                                    )}
                                  </Flex>
                                </Td>
                                <Td {...twoRowTdStyle(isRejected)} maxW="240px">
                                  <ExplanationMemberLabel
                                    signerRole={signerRole}
                                    candidateId={candidateId}
                                    member={member}
                                    isPartner={isPartner}
                                    isRejected={isRejected}
                                  />
                                  {!isRejected && viewUrlHistory && (
                                    <Box mt="1.5">
                                      <Text fontSize="xs" color="gray.600">
                                        署名開始：
                                        {formatDate(
                                          viewUrlHistory.savedAt,
                                          'YYYY/MM/DD (ddd) HH:mm',
                                        )}
                                      </Text>
                                      <Text
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="gray.600"
                                      >
                                        {viewUrlHistory.createType ===
                                        'FromEmailLink'
                                          ? '署名リンクメール'
                                          : deviceActor
                                            ? `端末署名 (操作者: ${deviceActor})`
                                            : null}
                                      </Text>
                                    </Box>
                                  )}
                                </Td>
                              </Tr>
                              {!!rejectedReason && (
                                <Tr>
                                  <Td
                                    fontSize="sm"
                                    colSpan={4}
                                    color="gray.600"
                                    pt="0"
                                    pb="3"
                                  >
                                    <HStack>
                                      <Text>拒否理由：{rejectedReason}</Text>
                                      <Text fontWeight="bold">
                                        {`(操作者：${member?.lastName} ${member?.firstName})`}
                                      </Text>
                                    </HStack>
                                  </Td>
                                </Tr>
                              )}
                            </Fragment>
                          )
                        },
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )
          },
        )}
      </VStack>
    </Box>
  )
}

type ExplanationDeliveryHistorySectionProps = {
  deliveryHistories: DeliveryHistory[]
  changeDeliveryDateHistories: ChangeDeliveryDateHistory[]
  documentSetsHistory: ExplanationDocSetHistory[]
}
const ExplanationDeliveryHistorySection: React.FC<
  ExplanationDeliveryHistorySectionProps
> = ({
  deliveryHistories,
  changeDeliveryDateHistories,
  documentSetsHistory,
}) => {
  return (
    <VStack alignItems="start" gap="2rem">
      <Box as="section" width="full">
        <TextWithBar as="h2" id="explanation-histories">
          交付文書送信/DL履歴
        </TextWithBar>
        <VStack paddingY="4" alignItems="start" gap="6">
          {documentSetsHistory.map(
            ({ uid: setUid, numberingId, name: documentSetName }) => {
              return (
                <Box key={setUid} width="full">
                  <Text
                    fontWeight="bold"
                    color="gray.600"
                  >{`${numberingId}.${documentSetName}`}</Text>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th textAlign="left">交付時刻</Th>
                          <Th>操作者</Th>
                          <Th>文書</Th>
                          <Th>版数</Th>
                          <Th>文書の種類</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {sortByDate(deliveryHistories, 'createdAt', 'desc').map(
                          ({
                            createdAt,
                            deliveryType,
                            member,
                            email,
                            isPartner,
                            documents,
                          }) => {
                            const targetDocuments = documents.filter(
                              ({ documentSetUid }) => documentSetUid === setUid,
                            )

                            return targetDocuments.map(
                              (
                                {
                                  uid: docUid,
                                  numberingId,
                                  documentName,
                                  documentType,
                                  version,
                                },
                                idx,
                              ) => {
                                return (
                                  <Tr key={docUid}>
                                    {idx === 0 && (
                                      <Td
                                        rowSpan={targetDocuments.length}
                                        px="0"
                                        py="4"
                                        verticalAlign="text-top"
                                      >
                                        <VStack alignItems="start">
                                          <Text>
                                            {formatDate(
                                              createdAt,
                                              'YYYY/MM/DD (ddd) HH:mm',
                                            )}
                                          </Text>
                                          <Text>
                                            {`${explanationRevisionDeliveryTypeToText(
                                              deliveryType,
                                            )}${email ? `：${email}` : ''}`}
                                          </Text>
                                        </VStack>
                                      </Td>
                                    )}
                                    {idx === 0 && (
                                      <Td
                                        rowSpan={targetDocuments.length}
                                        verticalAlign="text-top"
                                        maxW="240px"
                                      >
                                        <ExplanationMemberLabel
                                          member={member}
                                          isPartner={isPartner}
                                        />
                                      </Td>
                                    )}
                                    <Td
                                      verticalAlign="text-top"
                                      py="4"
                                    >{`${numberingId}.${documentName}`}</Td>
                                    <Td verticalAlign="text-top" py="4">
                                      {version}
                                    </Td>
                                    <Td verticalAlign="text-top" py="4">
                                      {icfDocumentTypeToText(documentType)}
                                    </Td>
                                  </Tr>
                                )
                              },
                            )
                          },
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )
            },
          )}
        </VStack>
      </Box>
      <Box as="section" width="full">
        <TextWithBar as="h2" id="explanation-histories">
          交付日変更履歴
        </TextWithBar>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th textAlign="left">交付日変更履歴</Th>
                <Th>交付日</Th>
                <Th>操作者</Th>
                <Th>文書セット</Th>
              </Tr>
            </Thead>
            <Tbody>
              {changeDeliveryDateHistories.map(
                ({
                  uid,
                  updatedDeliveryDate,
                  createdAt,
                  member,
                  isPartner,
                  reason,
                }) => {
                  return (
                    <Fragment key={uid}>
                      <Tr>
                        <Td {...twoRowTdStyle(!!reason)}>
                          {formatDate(createdAt, 'YYYY/MM/DD (ddd) HH:mm')}
                        </Td>
                        <Td {...twoRowTdStyle(!!reason)}>
                          {formatDate(updatedDeliveryDate, 'YYYY/MM/DD (ddd)')}
                        </Td>
                        <Td {...twoRowTdStyle(!!reason)} maxW="240px">
                          <Flex alignItems="center">
                            <ExplanationMemberLabel
                              member={member}
                              isPartner={isPartner}
                            />
                          </Flex>
                        </Td>
                        <Td {...twoRowTdStyle(!!reason)}>
                          {documentSetsHistory.map(
                            ({ numberingId, name: docSetName }) =>
                              `${numberingId}.${docSetName}`,
                          )}
                        </Td>
                      </Tr>
                      {!!reason && (
                        <Tr>
                          <Td
                            fontSize="sm"
                            colSpan={4}
                            color="gray.600"
                            pt="0"
                            pb="3"
                          >
                            変更理由：{reason}
                          </Td>
                        </Tr>
                      )}
                    </Fragment>
                  )
                },
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  )
}

type ExplanationBrowseHistorySectionProps = {
  candidateId: string
  documentSetsHistory: ExplanationDocSetHistory[]
}

const ExplanationBrowseHistorySection: React.FC<
  ExplanationBrowseHistorySectionProps
> = ({ candidateId, documentSetsHistory }) => {
  return (
    <Box as="section">
      <TextWithBar as="h2" id="explanation-histories">
        閲覧履歴
      </TextWithBar>
      <VStack paddingY="4" alignItems="start" gap="6">
        {documentSetsHistory.map(
          ({
            uid,
            numberingId,
            name: documentSetName,
            explanationDocuments,
          }) => {
            return (
              <Box width="100%" key={uid}>
                <Text
                  fontWeight="bold"
                  color="gray.600"
                >{`${numberingId}.${documentSetName}`}</Text>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th textAlign="left" pl="0">
                          閲覧時刻
                        </Th>
                        <Th>閲覧者</Th>
                        <Th>文書</Th>
                        <Th>版数</Th>
                        <Th>文書の種類</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {getExplanationDocBrowsingHistories(explanationDocuments)
                        .sort(
                          (a, b) =>
                            b.operatedAt.getTime() - a.operatedAt.getTime(),
                        )
                        .map(
                          ({
                            uid,
                            operatedAt: createdAt,
                            member,
                            isPartner,
                            numberingId,
                            name: documentName,
                            version,
                            documentType,
                            browserRole,
                          }) => (
                            <Tr key={uid}>
                              <Td pl="0">
                                {formatDate(
                                  createdAt,
                                  'YYYY/MM/DD (ddd) HH:mm',
                                )}
                              </Td>
                              <Td maxW="240px">
                                <ExplanationMemberLabel
                                  member={member}
                                  isPartner={isPartner}
                                  browserRole={browserRole}
                                  candidateId={candidateId}
                                />
                              </Td>
                              <Td>{`${numberingId}.${documentName}`}</Td>
                              <Td>{version}</Td>
                              <Td>{icfDocumentTypeToText(documentType)}</Td>
                            </Tr>
                          ),
                        )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )
          },
        )}
      </VStack>
    </Box>
  )
}
