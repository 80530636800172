import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Check, PagePrevious } from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useSessionAgreementForms } from '../../api'
import { RequestSign, useRequestSign } from '../../context/ExplanationRoomEvent'
import { useRoomMediaContext } from '../../context/MediaProvider'

type Props = {
  isPatient: boolean
  isSP?: boolean
  sessionUid: string
  requestSignRoomEventUid: string
  roomTopPath: string
  shouldShowReturnDeviceAlert?: boolean
  signPath: (requestSign: RequestSign) => string
}

type RequestSignWithDocNameAndStatus = RequestSign & {
  docName: string
  nextStatus: 'Completed' | 'Next' | 'AfterNext'
}

export const SignCompletedContent: React.FC<Props> = ({
  isPatient,
  isSP = false,
  sessionUid,
  requestSignRoomEventUid,
  roomTopPath,
  shouldShowReturnDeviceAlert = false,
  signPath,
}) => {
  const { videoExpanded, toggleVideoExpanded } = useRoomMediaContext()

  const { data: agreementForms } = useSessionAgreementForms({ sessionUid })

  const { requestSignList, setRequestSignList, cancelRequestSignBySessionUid } =
    useRequestSign()

  const currentEventRequestSignList = requestSignList.filter(
    rs => rs.explanationRoomEventUid === requestSignRoomEventUid,
  )

  const othersEventRequestSignList = requestSignList.filter(
    rs => rs.explanationRoomEventUid !== requestSignRoomEventUid,
  )

  const requestSignWithDocNameAndStatusList: RequestSignWithDocNameAndStatus[] =
    currentEventRequestSignList.map(reqSign => {
      const docRev = agreementForms?.find(af => af.uid === reqSign.docRevUid)

      if (!docRev) {
        return {
          ...reqSign,
          docName: '',
          nextStatus: 'Completed',
        }
      }

      let nextStatus: 'Completed' | 'Next' | 'AfterNext'
      if (reqSign.status === 'CompletedOnThisDevice') {
        nextStatus = 'Completed'
      } else {
        const pendingIndex = currentEventRequestSignList
          .filter(rs => rs.status === 'PendingOnThisDevice')
          .findIndex(rs => rs.docRevUid === reqSign.docRevUid)
        if (pendingIndex === 0) {
          nextStatus = 'Next'
        } else {
          nextStatus = 'AfterNext'
        }
      }
      return {
        ...reqSign,
        docName: docRev.icfDocumentRevision.name,
        nextStatus,
      }
    })

  if (!agreementForms) {
    return null
  }

  const allRequestSignCompleted = currentEventRequestSignList.every(
    reqSign => reqSign.status === 'CompletedOnThisDevice',
  )

  const title = allRequestSignCompleted
    ? 'すべての同意書に署名が完了しました'
    : '署名が完了しました'

  // propsとして受け取るdocRevisionsの順序を署名順として扱う
  const pendingRequestSignList = currentEventRequestSignList.filter(
    pendingReqSign => pendingReqSign.status === 'PendingOnThisDevice',
  )
  const nextRequestSign =
    pendingRequestSignList.length > 0 ? pendingRequestSignList[0] : undefined

  const nextPath = nextRequestSign ? signPath(nextRequestSign) : undefined

  return (
    <Flex direction="column" bg="green.50" h="full">
      {isSP ? (
        <HStack w="full" color="blue.500" spacing="1" px="2" py="4">
          <PagePrevious />
          <Link
            as={ReactRouterLink}
            to={roomTopPath}
            fontWeight="medium"
            onClick={() => {
              cancelRequestSignBySessionUid(sessionUid)
              if (!videoExpanded) toggleVideoExpanded()
            }}
          >
            説明ルームへ戻る
          </Link>
        </HStack>
      ) : (
        <Flex w="full" p="6">
          <Button
            as={ReactRouterLink}
            to={roomTopPath}
            variant="outline"
            colorScheme="gray"
            onClick={() => {
              cancelRequestSignBySessionUid(sessionUid)
              if (pendingRequestSignList.length === 0) {
                setRequestSignList(othersEventRequestSignList)
                return
              }
            }}
          >
            説明ルームへ戻る
          </Button>
        </Flex>
      )}

      <Center flex="1" flexDirection="column" gap={8} p="2">
        <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">
          {title}
        </Text>
        {!allRequestSignCompleted && (
          <Text
            fontSize={{ base: 'xs', md: 'lg' }}
            fontWeight="bold"
            wordBreak="break-word"
          >
            未署名の同意書が残っています。 引き続きご署名ください。
          </Text>
        )}
        {allRequestSignCompleted && shouldShowReturnDeviceAlert && (
          <Text
            fontSize={{ base: 'sm', md: 'lg' }}
            fontWeight="bold"
            color="green.500"
          >
            医療スタッフへ端末をお返しください。
          </Text>
        )}
        <Stack spacing="6">
          {requestSignWithDocNameAndStatusList.map(rs => (
            <HStack key={rs.docRevUid}>
              <Box minW="4">
                {rs.nextStatus === 'Next' ? (
                  <Box color="gray.600" fontWeight="bold">
                    →
                  </Box>
                ) : rs.nextStatus === 'AfterNext' ? (
                  <Box color="gray.600" fontWeight="bold">
                    ・
                  </Box>
                ) : (
                  <Check color={colors.green[500]} />
                )}
              </Box>
              <Text fontSize="lg" color="gray.600" fontWeight="bold">
                {rs.docName}
              </Text>
            </HStack>
          ))}
        </Stack>
        {isPatient && !allRequestSignCompleted && (
          <Text>署名前にSMS認証を実施します。携帯電話をご用意ください。</Text>
        )}
        {!!nextPath && (
          <Button as={ReactRouterLink} to={nextPath} colorScheme="green">
            続けて署名する
          </Button>
        )}
        {allRequestSignCompleted && (
          <Text fontSize={{ base: 'sm', md: 'lg' }}>
            署名は以上です。説明ルームへお戻りください。
          </Text>
        )}
      </Center>
    </Flex>
  )
}
