import {
  Flex,
  IconButton,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { DateTypeDef, Field } from '@micin-jp/chicken-schema'
import { Calendar } from 'src/components/__legacy__icon/monochrome'
import { CalendarComponent } from 'src/components/form-redesigned/calendar'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { zeroPadding } from 'src/utils/zeroPadding'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { toDateString, toWorksheetDate, WorksheetDate } from '../../utils/date'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  dateField: Field & {
    typeDef: DateTypeDef
  }
  index: number
}

export const WorksheetDateField: React.FC<Props> = ({ dateField, index }) => {
  const {
    findFieldValue,
    onChangeDateFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: dateField.fid,
    index,
  })

  const date = toWorksheetDate(
    value?.type === 'date' ? value.dateValue : undefined,
  )

  const onChangeDateValue = (date: WorksheetDate) => {
    onChangeDateFieldValue({
      fid: dateField.fid,
      index,
      dateValue: toDateString(date),
    })
  }

  const onChangeYear = (year: string) => {
    onChangeDateValue({ ...date, year })
  }
  const onChangeMonth = (month: string) => {
    onChangeDateValue({ ...date, month })
  }
  const onChangeDay = (day: string) => {
    onChangeDateValue({ ...date, day })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isDisabled =
    !canEdit || !isRepeatableSectionEnabled(index, dateField.fid)

  return (
    <WorksheetFieldFormControl
      field={dateField}
      index={index}
      isDisabled={isDisabled}
      errorMessage={getErrorMessage(value)}
    >
      <Stack display="inline-block" pos="relative" spacing="2">
        <Flex align="center" w="full">
          <Flex align="center" mr="4">
            <Input
              w="72px"
              mr="1"
              value={date.year}
              onChange={e => onChangeYear(e.target.value)}
            />
            <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
              年
            </Text>
            <Input
              w="56px"
              mr="1"
              value={date.month}
              onChange={e => onChangeMonth(e.target.value)}
            />
            <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
              月
            </Text>
            <Input
              w="56px"
              mr="1"
              value={date.day}
              onChange={e => onChangeDay(e.target.value)}
            />
            <Text as="span" fontSize="sm" fontWeight="bold">
              日
            </Text>
          </Flex>
          {!isDisabled && (
            <IconButton
              variant="customIconButtonGhost"
              colorScheme="blue"
              aria-label="calendar icon"
              icon={<Calendar color={colors.blue[500]} />}
              onClick={onOpen}
              pos="relative"
            />
          )}
        </Flex>
        {isOpen && (
          // アップロードファイルの画像と被るケースがあるため2を設定
          <Flex pos="absolute" right="0" zIndex="2">
            <CalendarComponent
              onChange={date => {
                onChangeDateValue({
                  year: date.getFullYear().toString(),
                  month: zeroPadding((date.getMonth() + 1).toString(), 2),
                  day: zeroPadding(date.getDate().toString(), 2),
                })
              }}
              onClose={onClose}
            />
          </Flex>
        )}
      </Stack>
    </WorksheetFieldFormControl>
  )
}
