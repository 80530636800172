import React from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { DisableModal } from './modal'

type Props = {
  lineNumber: number
  disabled?: boolean
  edcLinked: boolean
}

export const DisableLoglineButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <>
      <Button
        size="S"
        text="無効化"
        onClick={openModal}
        buttonType="normal"
        disabled={props.disabled}
      ></Button>

      {modalOpen && (
        <DisableModal
          edcLinked={props.edcLinked}
          lineNumber={props.lineNumber}
          onClose={closeModal}
        />
      )}
    </>
  )
}
