import React from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { RevisionAboutMember } from 'src/features/explanation/types'

type Props = {
  trialName: string
  candidateId: string
  hospitalName: string
  members: RevisionAboutMember[]
  onMoveToRoom: () => void
  canMoveToRoom: boolean
}

export const PatientLobbySidebar: React.FC<Props> = ({
  trialName,
  candidateId,
  hospitalName,
  members,
  onMoveToRoom,
  canMoveToRoom,
}) => {
  return (
    <Flex w="full" h="full" direction="column" justify="space-between">
      <Stack spacing="8">
        <Text fontSize="xl" fontWeight="bold">
          {trialName}
        </Text>
        {candidateId && (
          <Stack spacing="2">
            <Text fontSize="xl" fontWeight="bold">
              候補ID
            </Text>
            <Text>{candidateId}</Text>
          </Stack>
        )}
        <Box>
          <Text fontSize="xl" fontWeight="bold">
            担当者
          </Text>
          <Box mt="2">
            <Text fontWeight="bold" as="h3">
              {hospitalName}
            </Text>
            <Stack mt="2" spacing="1" ml="6">
              {members
                .sort((a, b) => a.memberIndex - b.memberIndex)
                .map(m => (
                  <HStack key={m.memberIndex} spacing="4">
                    <Text>{m.roleName}</Text>
                    <Text>{`${m.lastName} ${m.firstName}`}</Text>
                  </HStack>
                ))}
            </Stack>
          </Box>
        </Box>
      </Stack>

      <Center>
        <Button
          colorScheme="green"
          onClick={onMoveToRoom}
          isDisabled={!canMoveToRoom}
        >
          説明ルームへ
        </Button>
      </Center>
    </Flex>
  )
}
