import { UseMutationOption, useMutation } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'
import { definitions } from 'src/lib/api-client/schema.gen'

const setPinForPatient = async ({
  code,
  trialUid,
  explanationRevisionUid,
  method,
}: {
  code: string
  trialUid: string
  explanationRevisionUid: string
  method: definitions['input.ExpRevisionPinSettingMethod']
}) => {
  const res = await createApiClient({
    path: '/explanation_revision_pin/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/set_pin',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: { pin: code, method },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useSetPinForPatient = (options?: UseMutationOption<void>) => {
  return useMutation(
    ({
      code,
      trialUid,
      explanationRevisionUid,
      method,
    }: {
      code: string
      trialUid: string
      explanationRevisionUid: string
      method: definitions['input.ExpRevisionPinSettingMethod']
    }) =>
      setPinForPatient({
        method,
        code,
        trialUid,
        explanationRevisionUid,
      }),
    options,
  )
}
