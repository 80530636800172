import { useState } from 'react'

import { GetPatientReturn } from 'src/features/patient/api'

export const useSelectCreatableSchema = ({
  schemas,
}: {
  schemas: GetPatientReturn['observationVisits'][number]['creatableWorksheetSchemas']
}) => {
  const [selectedUidSet, setSelectedUidSet] = useState<Set<string>>(new Set())

  const onCheckAll = () => {
    const allChecked = selectedUidSet.size === schemas.length
    setSelectedUidSet(
      allChecked ? new Set() : new Set(schemas.map(s => s.worksheetSchemaUid)),
    )
  }

  const onCheck = (uid: string) => {
    const newSet = new Set(selectedUidSet)
    newSet.delete(uid)
      ? setSelectedUidSet(newSet)
      : setSelectedUidSet(newSet.add(uid))
  }

  const onClear = () => {
    setSelectedUidSet(new Set())
  }

  return {
    selectedUidSet,
    onCheckAll,
    onCheck,
    onClear,
  }
}
