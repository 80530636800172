import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Stack,
  Button,
  ModalFooter,
  Text,
} from '@chakra-ui/react'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type Props = {
  isOpen: boolean
  isReset?: boolean
  isFinalCheck?: boolean
  buttonColorScheme?: 'blue' | 'green'
  onClose: () => void
  onSelectSMS: () => void
  onSelectOnScreen: () => void
}

export const SelectPinSettingMethodModal: React.FC<Props> = ({
  isOpen,
  isReset = false,
  isFinalCheck = false,
  buttonColorScheme = 'blue',
  onClose,
  onSelectSMS,
  onSelectOnScreen,
}) => {
  const title = isReset
    ? '患者の暗証番号を再設定します'
    : isFinalCheck
      ? '署名実施には患者の当人認証情報設定が必要です'
      : '患者当人認証用の暗証番号を設定します'

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Stack spacing="8" mb="8">
            {isReset && (
              <WarningMessage message="再設定すると既存の暗証番号は無効になりますのでご注意ください。" />
            )}
            <Text>設定方法を選択してください</Text>
          </Stack>
          <Stack spacing="4">
            <Button
              colorScheme={buttonColorScheme}
              onClick={onSelectSMS}
              px="6"
              py="4"
              h="64px"
              fontWeight="bold"
              fontSize="md"
            >
              患者携帯電話へ設定リンクを送信
            </Button>
            <Button
              colorScheme={buttonColorScheme}
              variant="solid"
              onClick={onSelectOnScreen}
              w="full"
              h="64px"
              fontWeight="bold"
              fontSize="md"
            >
              患者本人が操作画面上で設定
            </Button>
          </Stack>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ModalCancelButton />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
