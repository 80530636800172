import { useCallback } from 'react'

import { useSearchParams } from 'react-router-dom'

const selectedFileOrderKey = 'selectedFile'

export type FileViewerSearchParam = {
  [selectedFileOrderKey]: string
}

export const useFileViewerSearchParam = () => {
  const [searchParam, setSearchParam] = useSearchParams()

  const selectedFileOrder = Number(searchParam.get(selectedFileOrderKey)) || 1

  const onChangeSelectedFileOrder = useCallback(
    ({ order }: { order: number }) => {
      setSearchParam(param => ({
        ...param,
        [selectedFileOrderKey]: order.toString(),
      }))
    },
    [setSearchParam],
  )

  return {
    selectedFileOrder,
    onChangeSelectedFileOrder,
  }
}
