import { QuestionnaireStatus } from './entity'

export const questionnaireStatusToText = (
  status: QuestionnaireStatus,
): string => {
  switch (status) {
    case QuestionnaireStatus.Invalid:
    case QuestionnaireStatus.Draft:
      return '一時保存'
    case QuestionnaireStatus.Confirmed:
      return '確定'
    case QuestionnaireStatus.Released:
      return '公開中'
    default:
      const n: never = status
      throw new Error(n)
  }
}
