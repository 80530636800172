import {
  Box,
  Flex,
  LinkBox,
  LinkOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { CriteriaListScreenDocument } from 'src/lib/gql-client'
import { useGqlQuerySWR } from 'src/lib/gql-client/request'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { AddCriteriaButton } from '../AddCriteriaButton/AddCriteriaButton'
import { CriteriaStatusBadge } from '../CriteriaStatusBadge/CriteriaStatusBadge'

export const CriteriaListScreen: React.FC = () => {
  const {
    data: { criteriaList },
  } = useGqlQuerySWR(CriteriaListScreenDocument, {})

  const { selectedTrial } = useSelectedTrial()

  return (
    <Flex as="main" h="full" p="6" direction="column">
      <Flex gap={4} align="center">
        <Text as="h1" fontSize="xl" fontWeight="bold">
          選択除外基準一覧
        </Text>
        <AddCriteriaButton />
      </Flex>
      <Box mt="6" flex="1" overflow="auto">
        <Table variant="hoverable">
          <Thead>
            <Tr>
              <Th>ステータス</Th>
              <Th>タイトル</Th>
              <Th>最終操作者</Th>
              <Th>最終更新日</Th>
              <Th>公開日</Th>
            </Tr>
          </Thead>
          <Tbody>
            {criteriaList.criteriaList.map(criteria => (
              <LinkBox as={Tr} key={criteria.criteriaUid}>
                <Td>
                  <CriteriaStatusBadge status={criteria.latestLog.status} />
                </Td>
                <Td>
                  <LinkOverlay
                    as={Link}
                    to={generatePath(Paths.CriteriaDetail, {
                      trialUid: selectedTrial.uid,
                      criteriaUid: criteria.criteriaUid,
                    })}
                  >
                    {criteria.latestDetail.title}
                  </LinkOverlay>
                </Td>
                <Td maxW="240px">
                  <MemberLabel
                    role={criteria.latestLog.actionTrialMember.role}
                    displayName={getFullName(
                      criteria.latestLog.actionTrialMember.user,
                    )}
                  />
                </Td>
                <Td minW="160px">
                  {formatDate(
                    criteria.latestLog.savedAt,
                    'YYYY/MM/DD (ddd) HH:mm',
                  )}
                </Td>
                <Td minW="160px">
                  {!!criteria.publishedAt &&
                    formatDate(criteria.publishedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                </Td>
              </LinkBox>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  )
}
