import { createSelector } from 'reselect'

import { Patient } from './entity'
import { State } from './redux'
import { RootState } from '../../reducer'
import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.patient)

export const getSingle = createSelector<
  RootState,
  string,
  string,
  State,
  Patient | null
>(
  (_state, props) => props,
  getState,
  (uid, state) => state.byId[uid] ?? null,
)
