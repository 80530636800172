import { useState, useMemo } from 'react'

import { useDisclosure } from '@chakra-ui/react'
import { Auth } from 'aws-amplify'
import { useCognitoUser } from 'src/hooks/use-cognito-user'

export const useMfa = () => {
  const { cognitoUser } = useCognitoUser()
  const [QRSecretKey, setQRSecretKey] = useState('')

  const isActiveMta = useMemo(() => {
    return cognitoUser.preferredMFA !== 'NOMFA'
  }, [cognitoUser.preferredMFA])

  const {
    isOpen: isOpenMfaModal,
    onOpen: openMfaModal,
    onClose: closeMfaModal,
  } = useDisclosure()

  const handleClickMfaMenu = async () => {
    if (!isActiveMta) {
      const secretKey = await Auth.setupTOTP(cognitoUser)
      setQRSecretKey(secretKey)
    }

    openMfaModal()
  }

  return {
    QRSecretKey,
    isActiveMta,
    isOpenMfaModal,
    closeMfaModal,
    handleClickMfaMenu,
  }
}
