import { Worksheet } from './entity'
import { createFixture as createChoiceAnswerFixture } from '../choice-answer/fixture'
import { MediumItemAnswerStatus } from '../medium-item-answer/entity'
import { createFixture as createMediumItemAnswerFixture } from '../medium-item-answer/fixture'
import { fixture as patientFixture } from '../patient/fixture'
import { ItemType, DataType } from '../small-item/entity'
import { createFixture as createSmallItemAnswerFixture } from '../small-item-answer/fixture'
import { fixtureDetail as templateFixture } from '../template/fixture'
import { fixtures as templateFolderFixtures } from '../template-folder/fixture'

export const fixture: Worksheet = {
  uid: 'fooWorksheetUid',
  title: 'foobar',
  templateFolderUid: templateFolderFixtures[1].uid,
  templateUid: 'fooTemplateUid',
  videoEnabled: true,
  hasClinicalSmallItem: false,
  patientUid: 'fooPatientUid',
  status: 1,
  index: 1,
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
  mediumItemAnswers: [],
}

export const fixtureDetail: Worksheet = {
  uid: 'fooWorksheetUid',
  title: 'foobar',
  templateFolderUid: templateFixture.templateFolderUid,
  templateUid: templateFixture.uid,
  videoEnabled: templateFixture.videoEnabled,
  hasClinicalSmallItem: true,
  patientUid: patientFixture.uid,
  status: 1,
  index: 0,
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
  // medium
  mediumItemAnswers: [
    createMediumItemAnswerFixture({
      uid: 'fooMediumItemUid',
      mediumItemUid: 'fooMediumItemUid',
      title: '通常のセクション',
      index: 0,
      hasLog: true,
      smallItemAnswerMap: {
        a: createSmallItemAnswerFixture({
          uid: 'a',
          smallItemUid: 'a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        b: createSmallItemAnswerFixture({
          uid: 'b',
          smallItemUid: 'b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'a',
              checked: true,
              smallItemAnswerUids: ['c'],
            }),
            createChoiceAnswerFixture({
              uid: 'a2',
              checked: true,
              smallItemAnswerUids: ['e'],
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        c: createSmallItemAnswerFixture({
          uid: 'c',
          smallItemUid: 'c',
          itemType: ItemType.Radio,
          parentUid: 'b',
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'b',
              description: '子持ち選択肢A',
              checked: true,
              smallItemAnswerUids: ['d'],
            }),
            createChoiceAnswerFixture({
              uid: 'c',
              description: '選択肢B',
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        d: createSmallItemAnswerFixture({
          uid: 'd',
          smallItemUid: 'd',
          parentUid: 'c',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              checked: true,
              uid: 'd',
            }),
            createChoiceAnswerFixture({
              uid: 'e',
            }),
            createChoiceAnswerFixture({
              uid: 'f',
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        e: createSmallItemAnswerFixture({
          uid: 'e',
          smallItemUid: 'e',
          parentUid: 'b',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              checked: true,
              uid: 'd',
            }),
            createChoiceAnswerFixture({
              uid: 'e',
            }),
            createChoiceAnswerFixture({
              uid: 'f',
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
      },
    }),
    createMediumItemAnswerFixture({
      uid: 'loglineMediumItemAnswerUid1',
      mediumItemUid: 'loglineMediumItemUid',
      title: 'ログラインセクション 1',
      isLogline: true,
      index: 1,
      lineNumber: 1,
      status: MediumItemAnswerStatus.Disabled,
      hasLog: true,
      smallItemAnswerMap: {
        logline1_a: createSmallItemAnswerFixture({
          uid: 'logline1_a',
          smallItemUid: 'logline1_a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
          value: '2021-01-01',
        }),
        logline1_b: createSmallItemAnswerFixture({
          uid: 'logline1_b',
          smallItemUid: 'logline1_b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline1_a',
              checked: true,
              smallItemAnswerUids: ['logline1_c'],
            }),
            createChoiceAnswerFixture({
              uid: 'logline1_a2',
              checked: true,
              smallItemAnswerUids: ['logline1_e'],
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline1_c: createSmallItemAnswerFixture({
          uid: 'logline1_c',
          smallItemUid: 'logline1_c',
          itemType: ItemType.Radio,
          parentUid: 'logline1_b',
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline1_b',
              description: '子持ち選択肢A',
              checked: true,
              smallItemAnswerUids: ['logline1_d'],
            }),
            createChoiceAnswerFixture({
              uid: 'logline1_c',
              description: '選択肢B',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline1_d: createSmallItemAnswerFixture({
          uid: 'logline1_d',
          smallItemUid: 'logline1_d',
          parentUid: 'logline1_c',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline1_d1',
              checked: true,
            }),
            createChoiceAnswerFixture({
              uid: 'logline1_e1',
            }),
            createChoiceAnswerFixture({
              uid: 'logline1_f1',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline1_e: createSmallItemAnswerFixture({
          uid: 'logline1_e',
          smallItemUid: 'logline1_e',
          parentUid: 'logline1_b',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline1_d2',
              checked: true,
            }),
            createChoiceAnswerFixture({
              uid: 'logline1_e2',
            }),
            createChoiceAnswerFixture({
              uid: 'logline1_f3',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline1_f: createSmallItemAnswerFixture({
          uid: 'logline1_f',
          smallItemUid: 'logline1_f',
          itemType: ItemType.Text,
          value: '回答1f',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline1_g: createSmallItemAnswerFixture({
          uid: 'logline1_g',
          smallItemUid: 'logline1_g',
          itemType: ItemType.Text,
          value: '回答1f',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline1_h: createSmallItemAnswerFixture({
          uid: 'logline1_h',
          smallItemUid: 'logline1_h',
          itemType: ItemType.Text,
          value: '回答1f',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
      },
    }),
    createMediumItemAnswerFixture({
      uid: 'loglineMediumItemAnswerUid2',
      mediumItemUid: 'loglineMediumItemUid',
      title: 'ログラインセクション 1',
      isLogline: true,
      index: 1,
      lineNumber: 2,
      status: MediumItemAnswerStatus.Saved,
      smallItemAnswerMap: {
        logline2_a: createSmallItemAnswerFixture({
          uid: 'logline2_a',
          smallItemUid: 'logline1_a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid2',
        }),
        logline2_b: createSmallItemAnswerFixture({
          uid: 'logline2_b',
          smallItemUid: 'logline1_b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline2_a',
              checked: true,
              smallItemAnswerUids: ['logline2_c'],
            }),
            createChoiceAnswerFixture({
              uid: 'logline2_a2',
              checked: true,
              smallItemAnswerUids: ['logline2_e'],
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid2',
        }),
        logline2_c: createSmallItemAnswerFixture({
          uid: 'logline2_c',
          smallItemUid: 'logline1_c',
          itemType: ItemType.Radio,
          parentUid: 'logline2_b',
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline2_b',
              description: '子持ち選択肢A',
              checked: true,
              smallItemAnswerUids: ['logline2_d'],
            }),
            createChoiceAnswerFixture({
              uid: 'logline2_c',
              description: '選択肢B',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid2',
        }),
        logline2_d: createSmallItemAnswerFixture({
          uid: 'logline2_d',
          smallItemUid: 'logline1_d',
          parentUid: 'logline2_c',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline2_d1',
              description: 'logline2_d1',
              checked: true,
            }),
            createChoiceAnswerFixture({
              uid: 'logline2_e1',
              description: 'logline2_e1',
            }),
            createChoiceAnswerFixture({
              uid: 'logline2_f1',
              description: 'logline2_f1',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid2',
        }),
        logline2_e: createSmallItemAnswerFixture({
          uid: 'logline2_e',
          smallItemUid: 'logline1_e',
          parentUid: 'logline2_b',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline2_d2',
              description: 'logline2_d2',
              checked: true,
            }),
            createChoiceAnswerFixture({
              uid: 'logline2_e2',
              description: 'logline2_e2',
            }),
            createChoiceAnswerFixture({
              uid: 'logline2_f2',
              description: 'logline2_f2',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid2',
        }),
        logline2_f: createSmallItemAnswerFixture({
          uid: 'logline2_f',
          smallItemUid: 'logline1_f',
          itemType: ItemType.Text,
          value: '回答2f',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline2_g: createSmallItemAnswerFixture({
          uid: 'logline2_g',
          smallItemUid: 'logline1_g',
          itemType: ItemType.Text,
          value: '回答2g',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline2_h: createSmallItemAnswerFixture({
          uid: 'logline2_h',
          smallItemUid: 'logline1_h',
          itemType: ItemType.Text,
          value: '回答2h',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
      },
    }),
    createMediumItemAnswerFixture({
      uid: 'loglineMediumItemAnswerUid3',
      mediumItemUid: 'loglineMediumItemUid',
      title: 'ログラインセクション 1',
      isLogline: true,
      index: 1,
      lineNumber: 3,
      status: MediumItemAnswerStatus.Saved,
      smallItemAnswerMap: {
        logline3_a: createSmallItemAnswerFixture({
          uid: 'logline3_a',
          smallItemUid: 'logline1_a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid3',
        }),
        logline3_b: createSmallItemAnswerFixture({
          uid: 'logline3_b',
          smallItemUid: 'logline1_b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline3_a',
              checked: true,
              smallItemAnswerUids: ['logline3_c'],
            }),
            createChoiceAnswerFixture({
              uid: 'logline3_a3',
              checked: true,
              smallItemAnswerUids: ['logline3_e'],
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid3',
        }),
        logline3_c: createSmallItemAnswerFixture({
          uid: 'logline3_c',
          smallItemUid: 'logline1_c',
          itemType: ItemType.Radio,
          parentUid: 'logline3_b',
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline3_b',
              description: '子持ち選択肢A',
              checked: true,
              smallItemAnswerUids: ['logline3_d'],
            }),
            createChoiceAnswerFixture({
              uid: 'logline3_c',
              description: '選択肢B',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid3',
        }),
        logline3_d: createSmallItemAnswerFixture({
          uid: 'logline3_d',
          smallItemUid: 'logline1_d',
          parentUid: 'logline3_c',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline3_d1',
              description: 'logline3_d1',
              checked: true,
            }),
            createChoiceAnswerFixture({
              uid: 'logline3_e1',
              description: 'logline3_e1',
            }),
            createChoiceAnswerFixture({
              uid: 'logline3_f1',
              description: 'logline3_f1',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid3',
        }),
        logline3_e: createSmallItemAnswerFixture({
          uid: 'logline3_e',
          smallItemUid: 'logline1_e',
          parentUid: 'logline3_b',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'logline3_d3',
              description: 'logline3_d3',
              checked: true,
            }),
            createChoiceAnswerFixture({
              uid: 'logline3_e3',
              description: 'logline3_e3',
            }),
            createChoiceAnswerFixture({
              uid: 'logline3_f3',
              description: 'logline3_f3',
            }),
          ],
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid3',
        }),
        logline3_f: createSmallItemAnswerFixture({
          uid: 'logline3_f',
          smallItemUid: 'logline1_f',
          itemType: ItemType.TextArea,
          value: '回答3f\n複数行',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline3_g: createSmallItemAnswerFixture({
          uid: 'logline3_g',
          smallItemUid: 'logline1_g',
          itemType: ItemType.TextArea,
          value: '回答3g\n複数行',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
        logline3_h: createSmallItemAnswerFixture({
          uid: 'logline3_h',
          smallItemUid: 'logline1_h',
          itemType: ItemType.TextArea,
          value: '回答3h\n複数行',
          mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
        }),
      },
    }),
  ],
}

export const fixtureDetail2: Worksheet = {
  uid: 'fooWorksheetUid2',
  title: 'foobar2',
  templateFolderUid: templateFolderFixtures[1].uid,
  templateUid: 'fooTemplateUid',
  videoEnabled: true,
  hasClinicalSmallItem: true,
  patientUid: 'fooPatientUid',
  status: 1,
  index: 0,
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
  // medium
  mediumItemAnswers: [
    createMediumItemAnswerFixture({
      uid: 'fooMediumItemUid',
      smallItemAnswerMap: {
        a: createSmallItemAnswerFixture({
          uid: 'a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        b: createSmallItemAnswerFixture({
          uid: 'b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'a',
              checked: true,
              smallItemAnswerUids: ['c'],
            }),
            createChoiceAnswerFixture({
              uid: 'a2',
              checked: true,
              smallItemAnswerUids: ['e'],
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        c: createSmallItemAnswerFixture({
          uid: 'c',
          itemType: ItemType.Radio,
          parentUid: 'b',
          choiceAnswers: [
            createChoiceAnswerFixture({
              uid: 'b',
              description: '子持ち選択肢A',
              checked: true,
              smallItemAnswerUids: ['d'],
            }),
            createChoiceAnswerFixture({
              uid: 'c',
              description: '選択肢B',
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        d: createSmallItemAnswerFixture({
          uid: 'd',
          parentUid: 'c',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              checked: true,
              uid: 'd',
            }),
            createChoiceAnswerFixture({
              uid: 'e',
            }),
            createChoiceAnswerFixture({
              uid: 'f',
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
        e: createSmallItemAnswerFixture({
          uid: 'e',
          parentUid: 'b',
          itemType: ItemType.CheckBox,
          choiceAnswers: [
            createChoiceAnswerFixture({
              checked: true,
              uid: 'd',
            }),
            createChoiceAnswerFixture({
              uid: 'e',
            }),
            createChoiceAnswerFixture({
              uid: 'f',
            }),
          ],
          mediumItemAnswerUid: 'fooMediumItemUid',
        }),
      },
    }),
  ],
}
