import { QuestionnaireFieldAnswer } from 'src/modules/entities/questionnaire-field-answer/entity'

import { ChoiceAnswers, FormValues } from './detail'
import {
  UpdateChoiceAnswerParam,
  UpdateQuestionnaireFieldAnswerParam,
  UpdateQuestionnaireFieldAnswersParam,
} from '../request'

export const formToParams = (
  data: FormValues,
  originalFieldAnswers: QuestionnaireFieldAnswer[],
): UpdateQuestionnaireFieldAnswersParam => {
  const fieldAnswersIncludeUid = Object.entries(data.fieldAnswers).map(
    ([uid, value]) => ({
      uid,
      ...value,
    }),
  )

  const param: UpdateQuestionnaireFieldAnswerParam[] =
    fieldAnswersIncludeUid.map(fa => {
      return {
        uid: fa.uid,
        value: fa?.value ?? null,
        choiceAnswers: fa?.choiceAnswers
          ? choiceAnswerFormToParams(
              fa?.choiceAnswers,
              originalFieldAnswers,
              fa.uid,
            )
          : [],
      }
    })

  return {
    fieldAnswers: param,
  }
}

const choiceAnswerFormToParams = (
  choiceAnswers: ChoiceAnswers,
  originalFieldAnswers: QuestionnaireFieldAnswer[],
  fieldAnswerUid: string,
): UpdateChoiceAnswerParam[] => {
  if (choiceAnswers?.allUids) {
    return Object.entries(choiceAnswers?.allUids).map(([uid, checked]) => ({
      uid,
      checked,
    }))
  } else if (choiceAnswers?.selectedUid) {
    // FIXME: ラジオボタンの場合は、該当するchoiceAnswerのuidをtrue、それ以外をfalseにする処理が必要。
    //        ラジオボタンはname属性を一意のため、他のchoiceAnswerを取得するために引数でoriginalFieldAnswersを渡している。要リファクタ。
    const originalFieldAnswer = originalFieldAnswers.find(
      fa => fa.uid === fieldAnswerUid,
    )

    const updatedChoiceAnswerParam = originalFieldAnswer?.choiceAnswers.map(
      ca => {
        return {
          uid: ca.uid,
          checked: ca.uid === choiceAnswers?.selectedUid ? true : false,
        }
      },
    )

    if (!updatedChoiceAnswerParam) return []
    return updatedChoiceAnswerParam
  }

  return []
}
