import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions as worksheetActions } from 'src/modules/entities/worksheet/redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { create } from './request'
import { getDetailRoute } from '../../routes'

export const useSubmitCreate = () => {
  const {
    requestStarted,
    requestDone,
    requestFailed,
    requesting,
    errorMessage,
  } = useRequestState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showSuccess } = useFlash()

  const request = useCallback(
    async (params: {
      trialUid: string
      patientUid: string
      templateUids: string[]
    }) => {
      const { trialUid, patientUid, templateUids } = params

      try {
        requestStarted()
        const created = await create({
          trialUid,
          patientUid,
          templateUids,
        })
        await dispatch(worksheetActions.fetchList({ trialUid, patientUid }))

        showSuccess('ワークシートを作成しました。')

        //作成されたワークシートのうち、indexが最小（0）のものの詳細ビューに遷移する。
        const topWorksheet = created.sort((a, b) => a.index - b.index)[0]

        navigate(
          getDetailRoute({
            trialUid,
            patientUid,
            worksheetUid: topWorksheet.uid,
          }),
        )

        requestDone()
      } catch (error) {
        requestFailed(error.message)

        throw error
      }
    },
    [
      requestStarted,
      dispatch,
      showSuccess,
      navigate,
      requestDone,
      requestFailed,
    ],
  )

  return {
    request,
    requesting,
    errorMessage,
  }
}
