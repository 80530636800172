import React from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { AddLoglineModal } from './modal'

type Props = {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemUid: string
  disabled?: boolean
  edcLinked: boolean
}

export const AddLoglineButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <div>
      <Button
        size="S"
        text="行の追加"
        onClick={openModal}
        buttonType="normal"
        //TODO: Add iconName
        disabled={props.disabled}
      ></Button>

      {modalOpen && <AddLoglineModal {...props} onClose={closeModal} />}
    </div>
  )
}
