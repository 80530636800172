import { Box, Flex } from '@chakra-ui/react'

import { useThisSession } from '../../hooks/useThisSession'
import { ExplanationRoomHeader } from '../ExplanationRoomHeader/ExplanationRoomHeader'
import { PatientSessionChecker } from '../PatientSessionChecker/PatientSessionChecker'
import { PatientSidebar } from '../PatientSidebar/PatientSidebar'

const HEADER_HEIGHT = 64

type Props = {
  headerTitle?: string
  trialUid: string
  sessionUid: string
  showSidebar?: boolean
  children: React.ReactNode
}
export const PatientLayout: React.FC<Props> = ({
  headerTitle,
  trialUid,
  sessionUid,
  children,
  showSidebar = true,
}) => {
  const thisSession = useThisSession({
    sessionUid: sessionUid,
    forPatient: true,
  })
  return (
    <PatientSessionChecker sessionUid={sessionUid}>
      <Flex direction="column" w="full" h="full">
        <ExplanationRoomHeader
          featureChannel={
            thisSession.fetched ? thisSession.featureChannel : undefined
          }
          headerTitle={headerTitle}
          hospitalName={
            thisSession.fetched
              ? thisSession.trialHospital.hospital.name
              : undefined
          }
          finishButton={null}
        />
        <Flex h={`calc(100svh - ${HEADER_HEIGHT}px)`}>
          <Box as="main" bg="green.50" flex="1" h="full" overflowY="auto">
            {children}
          </Box>
          {showSidebar && (
            <Box
              as="section"
              aria-label="sidebar"
              w={{ base: '240px', lg: '360px' }}
              p="6"
              bg="white"
              h="full"
              overflow="auto"
            >
              <PatientSidebar
                trialUid={trialUid}
                explanationSessionUid={sessionUid}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </PatientSessionChecker>
  )
}
