const UPLOAD_TASK_NAMES = ['template', 'section', 'field', 'choice'] as const
const REST_TASK_NAMES = ['preview', 'confirm'] as const

export const TASK_NAMES = [...UPLOAD_TASK_NAMES, ...REST_TASK_NAMES] as const

export type Task = TaskDefault | UploadTask

type TaskDefault = {
  order: number
  name: (typeof TASK_NAMES)[number]
}
export type UploadTask = TaskDefault & {
  name: (typeof UPLOAD_TASK_NAMES)[number]
}

export const isUploadTask = (task: Task): task is UploadTask => {
  switch (task.name) {
    case 'template':
    case 'section':
    case 'field':
    case 'choice':
      return true
    case 'preview':
    case 'confirm':
      return false
    default:
      const n: never = task
      console.error(n)
      return false
  }
}
