import { Schema } from '@micin-jp/chicken-schema'
import { GetWorksheetSchemaDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { worksheetCacheKey } from '../cacheKey'

const getWorksheetSchema = async ({
  worksheetUid,
}: {
  worksheetUid: string
}) => {
  try {
    const res = await graphqlRequest(GetWorksheetSchemaDocument, {
      worksheetUid,
    })
    return JSON.parse(res.worksheet.worksheetSchema.schemaFile) as Schema
  } catch (error) {
    throw error
  }
}

export const useWorksheetSchema = ({
  worksheetUid,
  ...option
}: {
  worksheetUid: string | null
} & SWRConfiguration<Schema, GqlError>) => {
  return useSWR(
    worksheetUid === null
      ? null
      : worksheetCacheKey.getWorksheetSchema(worksheetUid),
    () => {
      if (!worksheetUid) {
        throw new Error('worksheetUid is required')
      }
      return getWorksheetSchema({ worksheetUid })
    },
    option,
  )
}
