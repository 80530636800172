import {
  Badge,
  Box,
  HStack,
  Link,
  MenuItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Important } from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { NotificationPriority } from 'src/lib/gql-client'
import { getTimeDifferenceStr } from 'src/utils/getTimeDifferenceStr'
import { omitDomain } from 'src/utils/omitDomain'

import { useReadNotification } from '../../api/readNotification'
import { useSwitchTrialHospitalWithNotificationUrl } from '../../hooks/useSwitchTrialHospitalWithNotificationUrl'

type Props = {
  notificationId: string
  message: string
  link: string
  linkText: string
  savedAt: string
  hasBeenRead: boolean
  priority: NotificationPriority
  onCloseMenu: () => void
} & (
  | {
      isChildComponent: true
      isFirst: boolean
      isLast: boolean
    }
  | { isChildComponent: false }
)

export const NotificationTile: React.FC<Props> = ({
  notificationId,
  message,
  link,
  linkText,
  hasBeenRead,
  priority,
  savedAt,
  onCloseMenu,
  ...rest
}) => {
  const { request: readNotification } = useReadNotification({
    onError: console.error,
  })

  const { switchTrialHospital, shouldSwitchTrialHospital } =
    useSwitchTrialHospitalWithNotificationUrl({
      stickyUrl: link,
    })

  return (
    <MenuItem
      width="304px"
      bg={hasBeenRead ? 'white' : 'red.50'}
      _hover={{
        bg: `${hasBeenRead ? 'white' : 'red.50'}`,
        cursor: 'default',
      }}
      px="2"
      py="3"
      position="relative"
    >
      <HStack display="flex" alignItems="start" spacing="0" gap="0">
        {rest.isChildComponent && <Box w="20px" />}
        <Box w="4">
          {priority === 'High' && (
            <Important color={colors.red[300]} style={{ marginTop: '3px' }} />
          )}
        </Box>
        <Box w="4px" />
        <VStack display="flex" alignItems="start" spacing="3" width="248px">
          <Text fontSize="sm" as="span" fontWeight="bold">
            {message}
          </Text>
          {rest.isChildComponent && (
            <Box
              display="flex"
              position="absolute"
              top={rest.isFirst ? '0px' : '-12px'}
              left="14px"
              w="3px"
              h={`calc(100% - ${rest.isFirst ? 12 : 0}px - ${
                rest.isLast ? 12 : 0
              }px)`}
              bg="gray.300"
            />
          )}
          <Box
            onClick={async () => {
              onCloseMenu()
              if (!hasBeenRead) {
                await readNotification({ notificationId })
              }
            }}
          >
            {shouldSwitchTrialHospital ? (
              <Link
                as="a"
                color="blue.500"
                fontSize="xs"
                fontWeight="bold"
                cursor="pointer"
                href={link}
                onClick={async () => {
                  await switchTrialHospital()
                }}
              >
                {linkText}
              </Link>
            ) : (
              <Link
                as={ReactRouterLink}
                color="blue.500"
                fontSize="xs"
                to={omitDomain(link)}
                cursor="pointer"
                fontWeight="bold"
              >
                {linkText}
              </Link>
            )}
          </Box>
          <HStack>
            <Badge
              color="white"
              bg={hasBeenRead ? 'gray.300' : 'yellow.500'}
              borderRadius="full"
              px="2"
              fontSize="xs"
            >
              {hasBeenRead ? '既読' : '未読'}
            </Badge>
            <Text fontSize="xs">{getTimeDifferenceStr(savedAt)}</Text>
          </HStack>
        </VStack>
      </HStack>
    </MenuItem>
  )
}
