import React from 'react'

import { colors } from 'src/assets/colors'
import styled from 'styled-components'
import { height, HeightProps, width, WidthProps } from 'styled-system'

type Props = {
  className?: string
  text: string
}

const Component: React.FC<Props> = props => (
  <div className={props.className}>{props.text}</div>
)

export const CommonDisableLabel = styled(Component)<WidthProps & HeightProps>`
  background: ${colors.grayLighten1};
  border: 1px solid ${colors.grayLighten1};
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 50px;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;

  ${width}
  ${height}

  &.vertical {
    width: 25px;
    height: 54px;
    margin: 0 8px 0 -18px;
    padding: 10px 0;
    white-space: initial;
    line-height: 17px;
  }
`
