import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { ExplanationDetail } from './entity'

/** @deprecated */
export const fetchExplanation = async ({
  trialUid,
  explanationUid,
}: {
  trialUid: string
  explanationUid: string
}): Promise<ExplanationDetail> => {
  if (IS_MOCK_MODE) {
    throw new Error('fetchExplanation is not implemented in mock mode')
  }

  const url = `${SERVER_URL}/trials/${trialUid}/explanations/${explanationUid}`
  const res = await requestGet<ExplanationDetail>({ url })

  return res.data
}
