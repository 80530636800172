import { useCallback, useMemo } from 'react'

import { useSelectTrialHospital } from 'src/features/auth/api'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { usePatient } from 'src/features/patient/api'
import { rotateLoginId } from 'src/modules/util/rotate-login-id'

const extractPatientUidFromStickyUrl = (stickyUrl: string) => {
  const match = stickyUrl.match(/\/patients\/(?<patientUid>[^/]+)/)
  if (!match) {
    return undefined
  }
  return match.groups?.patientUid
}

export const useSwitchTrialHospitalWithNotificationUrl = ({
  stickyUrl,
}: {
  stickyUrl: string
}) => {
  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { request: selectTrialHospital } = useSelectTrialHospital({
    onSuccess: () => {
      rotateLoginId()
      window.location.href = stickyUrl
    },
  })
  const patientUid = extractPatientUidFromStickyUrl(stickyUrl)

  const { data: patient } = usePatient({
    patientUid: patientUid ?? null,
    revalidateIfStale: false,
  })

  const shouldSwitchTrialHospital = useMemo(() => {
    if (!patient) return false
    return (
      patient.trialHospital.uid !== selectedTrialHospitalUid &&
      patient.partnerTrialHospital?.uid !== selectedTrialHospitalUid
    )
  }, [patient, selectedTrialHospitalUid])

  const switchTrialHospital = useCallback(async () => {
    if (!patient) return
    await selectTrialHospital({
      trialUid: selectedTrial.uid,
      trialHospitalUid: patient.trialHospital.uid,
    })
  }, [patient, selectTrialHospital, selectedTrial.uid])

  return {
    shouldSwitchTrialHospital,
    switchTrialHospital,
  }
}
