import { HttpResponse } from 'msw'
import {
  createSendAgreementFormEnvelopeEmailPayloadMock,
  createSendCheckUnderstandingEnvelopeEmailPayloadMock,
  SendAgreementFormEnvelopeEmailDocument,
  SendAgreementFormEnvelopeEmailMutation,
  SendAgreementFormEnvelopeEmailMutationVariables,
  SendCheckUnderstandingEnvelopeEmailDocument,
  SendCheckUnderstandingEnvelopeEmailMutation,
  SendCheckUnderstandingEnvelopeEmailMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const sendAgreementFormEnvelopeEmailHandler = graphQLHandlerFactory<
  SendAgreementFormEnvelopeEmailMutation,
  SendAgreementFormEnvelopeEmailMutationVariables
>('mutation', SendAgreementFormEnvelopeEmailDocument, () => {
  return HttpResponse.json({
    data: {
      sendAgreementFormEnvelopeEmail:
        createSendAgreementFormEnvelopeEmailPayloadMock(),
    },
  })
})

export const sendCheckUnderstandingEnvelopeEmailHandler = graphQLHandlerFactory<
  SendCheckUnderstandingEnvelopeEmailMutation,
  SendCheckUnderstandingEnvelopeEmailMutationVariables
>('mutation', SendCheckUnderstandingEnvelopeEmailDocument, () => {
  return HttpResponse.json({
    data: {
      sendCheckUnderstandingEnvelopeEmail:
        createSendCheckUnderstandingEnvelopeEmailPayloadMock(),
    },
  })
})
