import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { EditModal } from './edit-modal'
import { getSelectedTrial } from '../../../../../../../entities/account/selector'
import { actions } from '../../../../../../../entities/template-folder/redux'
import { update } from '../../../../../../../entities/template-folder/request'
import { getSingle } from '../../../../../../../entities/template-folder/selector'
import { RootState } from '../../../../../../../reducer'
import { useRequestState } from '../../../../../../../server/use-request-state'

const useSubmit = (templateFolderUid: string) => {
  const trialUid = useSelector(getSelectedTrial)!.uid

  const { requesting, errorMessage, requestDone, requestStarted } =
    useRequestState()
  const dispatch = useDispatch()
  const toast = useMirohaToast()
  const request = useCallback(
    async (name: string) => {
      try {
        requestStarted()
        const res = await update({ trialUid, templateFolderUid, name })
        dispatch(actions.upsert(res))
        toast({
          status: 'success',
          title: 'フォルダ名を編集しました',
        })
        requestDone()
      } catch (error) {
        toast({
          status: 'error',
          title: error.message,
        })
      }
    },
    [toast, dispatch, requestDone, requestStarted, templateFolderUid, trialUid],
  )

  return { request, requesting, errorMessage }
}

type Props = {
  v2: boolean
  templateFolderUid: string
  onClose: () => void
}

export const EditModalContainer: React.FC<Props> = props => {
  const templateFolder = useSelector((state: RootState) =>
    getSingle(state, props.templateFolderUid),
  )
  const { request, requesting } = useSubmit(templateFolder.uid)
  const onSubmit = useCallback(
    async (name: string) => {
      await request(name)
      props.onClose()
    },
    [props, request],
  )

  return (
    <EditModal
      v2={props.v2}
      isFollowup={templateFolder.observationType === 'FollowUp'}
      initialName={templateFolder.name}
      onClose={props.onClose}
      onSubmit={onSubmit}
      requesting={requesting}
    />
  )
}
