import { useCallback, useState } from 'react'

import {
  ApiBodyFromPathAndMethod,
  ApiErrorFromPathAndMethod,
  ApiPathParamFromPathAndMethod,
  ApiResponseFromPathAndMethod,
  ExactHttpMethodByPath,
  ExactPathByHttpMethod,
  HttpMethod,
  createApiClient,
} from 'src/lib/api-client'

type SubmitHttpMethod = Exclude<HttpMethod, 'get'>
type SubmitPath = ExactPathByHttpMethod<SubmitHttpMethod>

type ExactSubmitMethod<Path extends SubmitPath> = SubmitHttpMethod &
  ExactHttpMethodByPath<Path>

type UseSubmitOption<
  Path extends SubmitPath,
  Method extends ExactSubmitMethod<Path>,
> = {
  path: Path
  httpMethod: Method
  params?: {
    paths?: ApiPathParamFromPathAndMethod<Path, Method>
  }
  onSuccess?: (data: ApiResponseFromPathAndMethod<Path, Method>) => void
  onError?: (error: ApiErrorFromPathAndMethod<Path, Method>) => void
  onRequestStarted?: () => void
  onRequestDone?: () => void
}

export const useApiClientSubmit = <
  Path extends SubmitPath,
  Method extends ExactSubmitMethod<Path>,
>(
  option: UseSubmitOption<Path, Method>,
) => {
  const {
    path,
    httpMethod,
    params,
    onSuccess,
    onError,
    onRequestStarted,
    onRequestDone,
  } = option

  const [requesting, setRequesting] = useState(false)
  const [data, setData] = useState<ApiResponseFromPathAndMethod<Path, Method>>()
  const [error, setError] = useState<ApiErrorFromPathAndMethod<Path, Method>>()

  const requestStart = useCallback(() => {
    setRequesting(true)
    if (!!onRequestStarted) {
      onRequestStarted()
    }
  }, [onRequestStarted])

  const requestDone = useCallback(() => {
    setRequesting(false)
    if (!!onRequestDone) {
      onRequestDone()
    }
  }, [onRequestDone])

  const request = useCallback(
    async (body?: ApiBodyFromPathAndMethod<Path, Method>) => {
      const api = createApiClient({
        path,
        httpMethod,
        params: { paths: params?.paths, body },
      })
      try {
        requestStart()

        const res = await api.request()

        requestDone()

        if (res.result === 'success') {
          setData(res.data)
          if (!!onSuccess) {
            onSuccess(res.data)
          }
          return
        }

        if (res.result === 'error') {
          setError(res.error)
          if (!!onError) {
            onError(res.error)
          }
        }
      } catch (e) {
        // apiエラー以外のエラーを捕捉
        requestDone()
        throw e
      }
    },
    [
      path,
      httpMethod,
      params?.paths,
      requestStart,
      requestDone,
      onSuccess,
      onError,
    ],
  )

  return { request, data, error, requesting }
}
