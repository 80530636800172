export const colors = {
  primary: '#1a85a7',
  primaryTint: 'rgba(26,133,167, 0.05)',
  primaryHover: '#489DB9',
  dark: '#20273D',
  light: '#AFB3C0',
  red: '#DD2D38',
  redLight: '#FFEAEA',
  green: '#0AA419',
  greenLight: '#E0F5E2',

  bgGray: '#ECEFF3',
  borderGray: '#DAE0E5',
  hoverGray: '#F5F7F9',

  bgModal: 'rgba(32, 39, 61, 0.9)',

  bgWhite: '#FFFFFF',

  grayLighten7: '#ccd0d7',
  grayLighten6: '#838899',
  grayLighten5: '#fafafa',
  grayLighten3: '#eeeeee',
  grayLighten2: '#e0e0e0',
  grayLighten1: '#bdbdbd',
  gray: '#9e9e9e',

  redLighten5: '#ffebee',
  redLighten3: '#ef9a9a',
  redLighten2: '#e57373',
  // red: '#f44336',
  redDarken4: '#b71c1c',
  redDarken5: '#db3e47',

  yellowLighten6: '#fffdf2',
  yellowLighten5: '#fffde7',
}
