import React from 'react'

import { Box, Flex, HStack } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'
import {
  RejectAgreementForRoom,
  SendEnvelopeEmail,
  StartCheckUnderstanding,
  StartInPersonSign,
  StartMembersSign,
  StartRemotePartnerSign,
  SyncEnvelope,
} from 'src/features/explanation/components'
import {
  ExplanationDocRevision,
  ExplanationSession,
} from 'src/features/explanation/types'
import { PdfViewer } from 'src/features/explanationRoom/components'

type Props =
  | {
      forPatient: true
      session: ExplanationSession
      docRevision: ExplanationDocRevision
      locationPath: string
      trialHospitalUid: string
      explanationPatientUid: string
      fileUrl: string
      mutateDocRevision?: () => void
      navigateToRoomTop: () => void
    }
  | {
      forPatient?: false
      session: ExplanationSession
      docRevision: ExplanationDocRevision
      locationPath: string
      trialHospitalUid: string
      trialMemberUid: string
      fileUrl: string
      mutateDocRevision: () => void
      navigateToRoomTop: () => void
      isPartner: boolean
    }

// ボタンの高さ + y方向のpadding
const NAV_CONTAINER_HEIGHT = 36 + 24 * 2

export const ExplanationRoomDocumentRenewal: React.FC<Props> = props => {
  const {
    forPatient,
    docRevision,
    session,
    locationPath,
    fileUrl,
    navigateToRoomTop,
    mutateDocRevision,
    trialHospitalUid,
  } = props
  return (
    <Box h="full">
      <Flex align="center" justify="space-between" p="6">
        <Button
          size="S"
          buttonType="cancel"
          text="説明ルームに戻る"
          onClick={navigateToRoomTop}
        />

        {!forPatient && (
          <HStack spacing="6" align="center">
            {/* 患者が署名したら拒否できない */}
            {docRevision.type === 'AgreementForm' &&
              !docRevision.signHistories.some(
                h => h.signerRole === 'Patient',
              ) && (
                <RejectAgreementForRoom
                  docRevision={docRevision}
                  sessionUid={session.uid}
                  mutateDocRevision={mutateDocRevision}
                />
              )}
            {(docRevision.type === 'AgreementForm' ||
              docRevision.type === 'CheckUnderstanding') &&
              session.type === 'Remote' && (
                <SendEnvelopeEmail
                  docRevision={docRevision}
                  session={session}
                  mutateDocRevision={mutateDocRevision}
                />
              )}

            {!docRevision.isCompleted && (
              <SyncEnvelope
                docRevision={docRevision}
                session={session}
                mutateDocRevision={mutateDocRevision}
              />
            )}

            {docRevision.type === 'AgreementForm' &&
              session.type === 'InPerson' && (
                <StartInPersonSign
                  session={session}
                  docRevision={docRevision}
                  startSignText="署名を開始する"
                />
              )}
            {docRevision.type === 'AgreementForm' && (
              <StartRemotePartnerSign
                session={session}
                docRevision={docRevision}
              />
            )}
            {docRevision.type === 'AgreementForm' && (
              <StartMembersSign session={session} docRevision={docRevision} />
            )}

            {session.type !== 'Remote' &&
              docRevision.type === 'CheckUnderstanding' && (
                <StartCheckUnderstanding
                  session={session}
                  docRevision={docRevision}
                />
              )}
          </HStack>
        )}
      </Flex>

      <Box h={`calc(100% - ${NAV_CONTAINER_HEIGHT}px)`}>
        {docRevision.type === 'Video' ? (
          <Box h="full" w="full" px="20" pb="10">
            <HlsVideoPlayer url={fileUrl} />
          </Box>
        ) : (
          <Flex h="full" align="center" justify="center">
            <PdfViewer
              url={fileUrl}
              sessionUid={session.uid}
              locationPath={locationPath}
              trialHospitalUid={trialHospitalUid}
              {...(forPatient
                ? {
                    actorType: 'Patient',
                    explanationPatientUid: props.explanationPatientUid,
                  }
                : {
                    actorType: 'Member',
                    trialMemberUid: props.trialMemberUid,
                    isPartner: props.isPartner,
                  })}
            />
          </Flex>
        )}
      </Box>
    </Box>
  )
}
