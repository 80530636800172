import React, { useMemo, useState } from 'react'

import { Stack, Text } from '@chakra-ui/react'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import styled from 'styled-components'

import { blue, gray } from '../base/color/palette'

type Id = string
type Value = string

type Item = {
  id: Id
  value: Value
}

type Props = {
  items: Item[]
  selectedItemIds: Id[]
  onChange: (ids: Id[]) => void
}

const useSelectingItems = (
  props: Pick<Props, 'selectedItemIds' | 'onChange'>,
) => {
  const [selectingItemIds, setSelectingItemIds] = useState<Id[]>([])
  const handlers = useMemo(
    () => ({
      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectingItemIds(
          Array.from(e.currentTarget.selectedOptions).map(option => {
            return option.value
          }),
        )
      },
      onClickButton: () => {
        props.onChange(
          Array.from(new Set([...props.selectedItemIds, ...selectingItemIds])),
        )
      },
    }),
    [props, selectingItemIds],
  )
  return handlers
}

const useDeselectingItems = (
  props: Pick<Props, 'selectedItemIds' | 'onChange'>,
) => {
  const [deselectingItemIds, setDeselectingItemIds] = useState<Id[]>([])
  const handlers = useMemo(
    () => ({
      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDeselectingItemIds(
          Array.from(e.currentTarget.selectedOptions).map(option => {
            return option.value
          }),
        )
      },
      onClickButton: () => {
        const newItem = props.selectedItemIds.filter(item => {
          return !deselectingItemIds.includes(item)
        })
        props.onChange(Array.from(new Set(newItem)))
      },
    }),
    [deselectingItemIds, props],
  )
  return handlers
}

export const MultiselectFilter: React.FC<
  Omit<
    // HTMLSelectElement["onChange"] の型を Props["onChange"] で override
    React.HTMLAttributes<HTMLSelectElement>,
    'onChange'
  > &
    Props
> = props => {
  const {
    items: _items,
    selectedItemIds: _selectedItemIds,
    onChange: _onChange,
    ...attributes
  } = props
  const selectingItemHandlers = useSelectingItems({
    selectedItemIds: props.selectedItemIds,
    onChange: props.onChange,
  })
  const deselectingItemHandlers = useDeselectingItems({
    selectedItemIds: props.selectedItemIds,
    onChange: props.onChange,
  })

  /**
   * 右側に表示される要素
   * props.items から selectedItemIds を選んだもの
   */
  const selectedItems = useMemo(() => {
    return props.items.filter(item => {
      return props.selectedItemIds.includes(item.id)
    })
  }, [props.items, props.selectedItemIds])

  /**
   * 左側に表示される要素
   * props.items から selectedItemIds を除いたもの
   */
  const restItems = useMemo(() => {
    return props.items.filter(item => {
      return !props.selectedItemIds.includes(item.id)
    })
  }, [props.items, props.selectedItemIds])

  return (
    <Wrapper>
      <div>
        <MultipleSelectWrapper>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            選択可能項目
          </Text>
          <MultipleSelect onChange={selectingItemHandlers.onChange}>
            {restItems.map(item => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              )
            })}
          </MultipleSelect>
        </MultipleSelectWrapper>
      </div>

      <Stack spacing="16px">
        <IconButton
          type="button"
          iconName="moveRight"
          onClick={selectingItemHandlers.onClickButton}
        />

        <IconButton
          type="button"
          iconName="moveLeft"
          onClick={deselectingItemHandlers.onClickButton}
        />
      </Stack>

      <div>
        <MultipleSelectWrapper>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            選択済み項目(解除可能)
          </Text>
          <MultipleSelect
            {...attributes}
            onChange={deselectingItemHandlers.onChange}
          >
            {selectedItems.map(item => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              )
            })}
          </MultipleSelect>
        </MultipleSelectWrapper>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  > div::nth-child(2) {
  }
`

const MultipleSelect = styled.select.attrs({ multiple: true })`
  min-width: 240px;
  height: 160px;
  border-radius: 5px;
  border: 2px solid ${gray[40]};
`

const MultipleSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Option = styled.option`
  font-size: 14px;
  line-height: 20px;
  height: 20px;

  &:checked {
    background-color: ${blue[10]};
  }
`
