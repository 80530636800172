import { useCallback, useState } from 'react'

import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useRoomSession } from '@micin-jp/call-lib-react'
import { useNavigate } from 'react-router-dom'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { useFinishExplanationSession } from '../../api'
import { ExplanationSession } from '../../types'
import { getRoomFinishedRoute } from '../../utils/getRoomFinishedRoute'
import { isAgreementDoneOrRejected } from '../../utils/isAgreementDoneOrRejected'

type Props = {
  session: ExplanationSession
}

export const FinishSession: React.FC<Props> = ({ session }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isCompleted = isAgreementDoneOrRejected(session.explanationRevision)

  const canFinish =
    session.explanationRevision.type !== 'RemotePartner' || isCompleted

  const [deliveryChecked, setDeliveryChecked] = useState<boolean>()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const isPartnerMember =
    session.explanationRevision.partnerTrialHospital?.uid ===
    selectedTrialHospitalUid

  const navigate = useNavigate()

  const { leaveRoom: leaveVideoCallRoom } = useRoomSession()

  const { request: finishSession } = useFinishExplanationSession({
    onSuccess: async () => {
      leaveVideoCallRoom().then()
      navigate(
        getRoomFinishedRoute({
          type: session.explanationRevision.type,
          isPartnerMember,
        }),
      )
    },
  })

  // 同意完了かつ未交付の場合はチェックを要求する
  const isAgreementDoneButNotDelivered =
    session.explanationRevision.status === 'AgreementDone' &&
    !session.explanationRevision.deliveredAt

  const handleOpen = () => {
    // モーダルを開く時点のsessionの状態に基づく
    setDeliveryChecked(!isAgreementDoneButNotDelivered)
    onOpen()
  }

  const leaveRoom = useCallback(async () => {
    leaveVideoCallRoom().then()
    navigate(
      getRoomFinishedRoute({
        type: session.explanationRevision.type,
        isLeft: true,
        isPartnerMember,
      }),
    )
  }, [
    leaveVideoCallRoom,
    navigate,
    session.explanationRevision.type,
    isPartnerMember,
  ])

  const finishActionLabel = isCompleted ? '終了' : '中断'

  return (
    <>
      <Button onClick={handleOpen} variant="outline" colorScheme="red">
        {canFinish
          ? `説明を${finishActionLabel}する`
          : `説明ルームから退出する`}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        {canFinish ? (
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {isCompleted ? `説明を終了しますか？` : '説明を中断する'}
            </ModalHeader>
            <ModalBody>
              <Stack spacing="8">
                {session.explanationRevision.type !== 'InPerson' &&
                  isCompleted && (
                    <Text>
                      説明を終了すると、現在説明ルームを開いている全参加者の説明画面が自動的に終了します。
                    </Text>
                  )}
                {isAgreementDoneButNotDelivered && (
                  <Stack spacing="4">
                    <WarningMessage message="署名済文書の交付が完了していません。終了しますか？" />
                    <Checkbox
                      isChecked={deliveryChecked}
                      onChange={e => setDeliveryChecked(e.target.checked)}
                      colorScheme="green"
                    >
                      このまま説明を終了する
                    </Checkbox>
                  </Stack>
                )}
                {!isCompleted && (
                  <Text>同意は完了していません。説明を中断しますか？</Text>
                )}
              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing="4">
                <Button colorScheme="gray" variant="ghost" onClick={onClose}>
                  キャンセル
                </Button>
                <Button
                  colorScheme="red"
                  onClick={async () => {
                    await finishSession({ explanationSessionUid: session.uid })
                  }}
                  isDisabled={!deliveryChecked}
                >{`${finishActionLabel}する`}</Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>{`説明ルームから退出する`}</ModalHeader>
            <ModalBody>
              <Text>同意は完了していません。説明ルームから退出しますか？</Text>
            </ModalBody>

            <ModalFooter>
              <ModalCancelButton />
              <Button
                colorScheme="red"
                onClick={async () => {
                  await leaveRoom()
                }}
              >{`退出する`}</Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
