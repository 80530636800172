import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { Select, SelectItem } from 'src/components/Select/Select'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { useSpinner } from 'src/hooks/use-spinner'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import {
  ExplanationRevisionStatusV2,
  ExplanationType,
  TrialMemberRole,
  DocRevisionInSignFlowFragment,
  ExpDocRevAgreementFormForSessionInSignFlowFragment,
  SessionMemberFragment,
} from 'src/lib/gql-client'
import { getFullName } from 'src/utils/getFullName'

import { Check, Reject, Sign } from '../../../../components/icon'
import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import { assertNever } from '../../../../utils/assertNever'
import {
  useAuthenticatedAccount,
  useSelectedTrial,
} from '../../../auth/context'
import { useCreateAgreementFormEnvelopes } from '../../api/createAgreementFormEnvelopes'
import { useSendAgreementFormEnvelopeEmail } from '../../api/sendAgreementFormEnvelopeEmail'
import {
  useActiveDevice,
  useRequestSign,
} from '../../context/ExplanationRoomEvent'
import { isAllSignCompleted } from '../../utils/implementSignBannerUtil'
import { ExplanationRoomPinSettingModals } from '../ExplanationRoomPinSettingModals/ExplanationRoomPinSettingModals'
import { SignFlowBannerButton } from '../SignFlowBannerButton/SignFlowBannerButton'
import { SignFlowBannerStatic } from '../SignFlowBannerStatic/SignFlowBannerStatic'

type Props = {
  hasPinSet: boolean
  hasAgreementForms: boolean
  phoneNumber: string | undefined
  partnerTrialHospitalUid: string | undefined
  sessionUid: string
  explanationRevisionUid: string
  explanationRevisionStatus: ExplanationRevisionStatusV2
  explanationType: ExplanationType
  agreementForms: ExpDocRevAgreementFormForSessionInSignFlowFragment[]
  isSignerSet: boolean
  sessionMembers: SessionMemberFragment[]
  drSignerTrialMemberUid: string | undefined
  crcSignerTrialMemberUid: string | undefined
  patientUid: string
  candidateId: string
  ownDeviceActorUid: string
  notCompletedDocRevisions: DocRevisionInSignFlowFragment[]
  mutateSession: () => void
}

type ActorElement = {
  uid: string
  node: React.ReactNode
  sendRequestText: string
  isPartner: boolean
  signerRole: 'Dr' | 'CRC' | 'Patient' | 'Invalid'
}

type SignMethod = 'OnScreen' | 'SendLink'

const trialMemberRoleToSignerRole = (
  role: TrialMemberRole,
): 'Dr' | 'CRC' | 'Invalid' => {
  switch (role) {
    case 'Dr':
      return 'Dr'
    case 'CRC':
      return 'CRC'
    default:
      return 'Invalid'
  }
}

const isRequired = (
  docRev: ExpDocRevAgreementFormForSessionInSignFlowFragment,
) => {
  return docRev.icfDocumentRevision.__typename ===
    'IcfDocumentRevisionAgreementForm'
    ? docRev.icfDocumentRevision.isConsentRequired
    : false
}

export const ImplementSignBanner: React.FC<Props> = ({
  hasPinSet,
  hasAgreementForms,
  phoneNumber,
  sessionUid,
  partnerTrialHospitalUid,
  explanationRevisionUid,
  explanationType,
  explanationRevisionStatus,
  agreementForms,
  isSignerSet,
  sessionMembers,
  drSignerTrialMemberUid,
  crcSignerTrialMemberUid,
  patientUid,
  candidateId,
  ownDeviceActorUid,
  notCompletedDocRevisions,
  mutateSession,
}) => {
  const {
    isOpen: isPinSettingModalsOpen,
    onOpen: onOpenPinSettingModals,
    onClose: onClosePinSettingModals,
  } = useDisclosure()

  const {
    isOpen: isSelectSignerModalOpen,
    onOpen: onOpenSelectSignerModal,
    onClose: onCloseSelectSignerModal,
  } = useDisclosure()

  const {
    isOpen: isSelectAgreementFormModalOpen,
    onOpen: onOpenSelectAgreementFormModal,
    onClose: onCloseSelectAgreementFormModal,
  } = useDisclosure()

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure()

  const { selectedTrial } = useSelectedTrial()

  const { send: sendRequestSign } = useRequestSign()

  const { activeDevices } = useActiveDevice()

  const actors: ActorElement[] = [
    {
      uid: patientUid,
      node: <Text as="span">{`[患者] 候補ID: ${candidateId}`}</Text>,
      sendRequestText: `候補ID: ${candidateId}`,
      isPartner: false,
      signerRole: 'Patient',
    },
    // 署名者設定の欄には Dr->CRC の順で表示する
    ...sessionMembers
      .sort((a, _) => (a.trialMember.role === 'Dr' ? -1 : 1))
      .map(member => {
        return {
          uid: member.trialMember.uid,
          node: (
            <MemberLabel
              role={member.trialMember.role}
              displayName={getFullName(member.trialMember.user)}
              isPartner={member.isPartner}
            />
          ),
          sendRequestText: `${member.trialMember.role}: ${getFullName(
            member.trialMember.user,
          )}`,
          isPartner: member.isPartner,
          signerRole: trialMemberRoleToSignerRole(member.trialMember.role),
        }
      }),
  ]

  const activeDeviceActors = actors.filter(actor =>
    activeDevices.some(device => device.actorUid === actor.uid),
  )

  const notCompletedSignerActors = getNotCompletedSignerActors(
    agreementForms,
    actors.filter(
      actor =>
        actor.uid === patientUid ||
        actor.uid === drSignerTrialMemberUid ||
        actor.uid === crcSignerTrialMemberUid,
    ),
  )

  // デフォルトの署名者: 署名がまだ可能な署名者のうち、最初の署名者
  const defaultSignerActorUid =
    notCompletedSignerActors.length > 0
      ? notCompletedSignerActors[0].uid
      : undefined

  // 署名者
  const [signerActorUid, setSignerActorUid] = useState<string | undefined>(
    defaultSignerActorUid,
  )

  // デフォルトの署名端末: パートナー医療機関でアクティブな署名端末のうち、最初の署名端末
  const defaultDeviceActorUid = actors
    .filter(actor =>
      activeDevices.some(device => device.actorUid === actor.uid),
    )
    .find(actor => actor.isPartner)?.uid

  // 署名端末
  const [deviceActorUid, setDeviceActorUid] = useState<string | undefined>(
    defaultDeviceActorUid,
  )

  // 署名対象の同意書: 最初は空、署名者が選択されたら setDefaultDocRevUids で更新
  const [docRevUids, setDocRevUids] = useState<string[]>([])

  const signerActorElement = actors.find(a => a.uid === signerActorUid)

  const signerType: 'Patient' | 'Member' =
    signerActorElement?.signerRole === 'Patient' ? 'Patient' : 'Member'

  // 選択可能な署名方法
  const signMethods: SignMethod[] = useMemo(() => {
    if (
      signerType === 'Patient' &&
      (explanationType === 'InPerson' || explanationType === 'RemotePartner')
    ) {
      return ['OnScreen']
    }
    return activeDeviceActors.some(a => a.uid === signerActorUid)
      ? ['OnScreen', 'SendLink']
      : ['SendLink']
  }, [activeDeviceActors, explanationType, signerActorUid, signerType])

  // デフォルトの署名方法: 選択可能な署名方法のうち、最初の署名方法
  const defaultSignMethod = signMethods.length > 0 ? signMethods[0] : undefined

  // 署名方法
  const [signMethod, setSignMethod] = useState<SignMethod | undefined>(
    defaultSignMethod,
  )

  const reset = () => {
    setSignerActorUid(defaultSignerActorUid)
    setDeviceActorUid(defaultDeviceActorUid)
    setDocRevUids([])
    setSignMethod(defaultSignMethod)
  }

  // 署名方法は署名者により可能/不可能が異なる。選択されたものが不可能なパターンにならないよう更新する
  useEffect(() => {
    if (!!signMethod && !signMethods.includes(signMethod)) {
      setSignMethod(defaultSignMethod)
    }
  }, [defaultSignMethod, signMethod, signMethods])

  const deviceActorElement = actors.find(a => a.uid === deviceActorUid)

  // 選択可能な署名者のリスト
  const selectAgreementFormElements = useMemo(
    () => getSelectAgreementFormElements(agreementForms, signerActorElement),
    [agreementForms, signerActorElement],
  )

  // 署名対象文書のデフォルト値を更新
  const setDefaultDocRevUids = useCallback(() => {
    setDocRevUids(
      selectAgreementFormElements
        .filter(
          e => e.status === 'PendingRequired' || e.status === 'PendingOptional',
        )
        .map(e => e.docRevUid),
    )
  }, [selectAgreementFormElements])

  const toast = useMirohaToast()

  const title = '署名実施'

  const hasNotCompletedAgreement =
    notCompletedDocRevisions.filter(
      d => d.__typename === 'ExplanationDocRevisionAgreementForm',
    ).length !== 0

  const completedAll = isAllSignCompleted({
    agreementForms,
    explanationRevisionStatus,
    hasNotCompletedAgreement,
  })

  // 同意完了に進めるが、署名可能な同意書が残っている
  const completedButNotSignedAgreementFormsExist =
    new Set<ExplanationRevisionStatusV2>([
      'SignCompleted',
      'AgreementCompleted',
      'DeliveryCompleted',
    ]).has(explanationRevisionStatus) && agreementForms.length > 0

  const signReadyStatuses: ExplanationRevisionStatusV2[] = [
    'SignReady',
    'SignStarted',
    'SignCompleted', // 必須と非必須両方の同意書がある場合で、先に必須同意書だけ署名完了した場合ステータスは、SignCompletedになる
  ]
  const ready =
    isSignerSet &&
    hasAgreementForms &&
    signReadyStatuses.includes(explanationRevisionStatus) &&
    hasNotCompletedAgreement

  const selectedDocRevisions = agreementForms.filter(af =>
    docRevUids?.includes(af.uid),
  )

  const noEnvelopeDocRevUids = selectedDocRevisions
    .filter(af => !af.docusignEnvelopeId)
    .map(af => af.uid)

  const { showSpinner, hideSpinner } = useSpinner()

  const {
    request: createAgreementFormEnvelopes,
    requesting: creatingAgreementFormEnvelopes,
  } = useCreateAgreementFormEnvelopes({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
  })

  const {
    request: sendAgreementFormEnvelopeEmail,
    requesting: sendingAgreementFormEnvelopeEmail,
  } = useSendAgreementFormEnvelopeEmail()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  // 署名端末を選択するかどうか
  // 遠隔同意(パートナー) かつ 画面上で署名 かつ 署名者がパートナー or 患者
  const shouldSelectDevice =
    explanationType === 'RemotePartner' &&
    signMethod === 'OnScreen' &&
    (!!signerActorElement?.isPartner ||
      signerActorElement?.signerRole === 'Patient')

  // 対面同意の場合、端末はownDeviceActorUidに固定
  // 署名端末が選択不要の場合、署名者と署名端末を同じにする
  const updateDeviceActorUid = () => {
    if (explanationType === 'InPerson') {
      setDeviceActorUid(ownDeviceActorUid)
      return
    }
    if (shouldSelectDevice) {
      return
    }
    setDeviceActorUid(signerActorUid)
  }

  const isRejected = explanationRevisionStatus === 'AgreementRejected'

  return (
    <Fragment>
      {completedAll || isRejected ? (
        <SignFlowBannerStatic
          title={title}
          isGrayedOut={isRejected}
          icon={isRejected ? 'Reject' : 'Check'}
        />
      ) : (
        <SignFlowBannerButton
          title={title}
          onClick={() => {
            if (selectedTrial.featureFlags.eConsentNewSignFlow && !hasPinSet) {
              onOpenPinSettingModals()
              return
            }
            onOpenSelectSignerModal()
          }}
          isDisabled={!ready}
          checked={completedButNotSignedAgreementFormsExist}
          isGrayedOut={isRejected || agreementForms.length === 0}
        />
      )}

      {isPinSettingModalsOpen && (
        <ExplanationRoomPinSettingModals
          isFinialCheck
          phoneNumber={phoneNumber}
          explanationRevisionUid={explanationRevisionUid}
          patientUid={patientUid}
          explanationType={explanationType}
          partnerTrialHospitalUid={partnerTrialHospitalUid}
          activePartnerDeviceActorUids={activeDeviceActors
            .filter(actor => actor.isPartner)
            .map(actor => actor.uid)}
          onCloseModals={onClosePinSettingModals}
          mutateSession={mutateSession}
        />
      )}

      {/* Drは設定されていることが必須 */}
      {!!drSignerTrialMemberUid && (
        <SelectSingerModal
          isOpen={isSelectSignerModalOpen}
          activeDeviceActors={activeDeviceActors}
          notCompletedSignerActors={notCompletedSignerActors}
          signMethods={signMethods}
          signerActorUid={signerActorUid}
          deviceActorUid={deviceActorUid}
          signMethod={signMethod}
          shouldSelectDevice={shouldSelectDevice}
          explanationType={explanationType}
          onChangeSignerActorUid={setSignerActorUid}
          onChangeDeviceActorUid={setDeviceActorUid}
          onChangeSignMethod={setSignMethod}
          onClose={() => {
            onCloseSelectSignerModal()
            reset()
          }}
          onSubmit={() => {
            onCloseSelectSignerModal()
            onOpenSelectAgreementFormModal()
            updateDeviceActorUid()
            setDefaultDocRevUids()
          }}
        />
      )}
      {!!signerActorElement && (
        <SelectAgreementFormModal
          isOpen={isSelectAgreementFormModalOpen}
          agreementFormElements={agreementForms}
          signerActorRole={signerActorElement.signerRole}
          docRevUids={docRevUids}
          onChangeDocRevUids={setDocRevUids}
          onClose={() => {
            onCloseSelectAgreementFormModal()
            onOpenSelectSignerModal()
          }}
          onSubmit={() => {
            onCloseSelectAgreementFormModal()
            onOpenConfirmationModal()
          }}
        />
      )}
      {!!signMethod && !!signerActorElement && !!deviceActorElement && (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          signerActorNode={signerActorElement.node}
          deviceActorNode={deviceActorElement.node}
          shouldSelectDevice={shouldSelectDevice}
          signMethod={signMethod}
          selectedDocRevisions={selectedDocRevisions}
          onClose={() => {
            onCloseConfirmationModal()
            onOpenSelectAgreementFormModal()
          }}
          onSubmit={async () => {
            if (
              !signerActorUid ||
              !deviceActorUid ||
              !selectedTrialHospitalUid
            ) {
              return
            }
            // エンベロープが作られていない同意書のエンベロープを作成する
            if (noEnvelopeDocRevUids.length > 0) {
              await createAgreementFormEnvelopes({
                explanationSessionUid: sessionUid,
                explanationDocRevisionUids: noEnvelopeDocRevUids,
              })
            }
            const signActor = actors.find(a => a.uid === signerActorUid)
            if (!signActor) return
            // 署名依頼イベントを送信する
            await sendRequestSign({
              docRevisionUids: selectedDocRevisions.map(af => af.uid),
              deviceActorUid: deviceActorUid,
              signerActorUid: signerActorUid,
              signType: signMethod,
            })
            switch (signMethod) {
              case 'OnScreen':
                if (deviceActorUid !== ownDeviceActorUid) {
                  toast({
                    status: 'success',
                    title: `${signActor.sendRequestText} へ署名依頼を送信しました`,
                  })
                }
                break
              case 'SendLink':
                // 署名依頼イベントと別途、メール依頼を送信
                await Promise.all(
                  selectedDocRevisions.map(af =>
                    sendAgreementFormEnvelopeEmail({
                      explanationSessionUid: sessionUid,
                      explanationDocRevisionUid: af.uid,
                      signerActorUids: [signerActorUid],
                    }),
                  ),
                )
                toast({
                  status: 'success',
                  title: `${signActor.sendRequestText} へ署名リンクを送信しました`,
                })
                break
              default:
                assertNever(signMethod)
            }
            onCloseConfirmationModal()
            reset()
          }}
          creatingAgreementFormEnvelopes={creatingAgreementFormEnvelopes}
          sendingAgreementFormEnvelopeEmail={sendingAgreementFormEnvelopeEmail}
        />
      )}
    </Fragment>
  )
}

type SelectSingerModalProps = {
  isOpen: boolean
  activeDeviceActors: ActorElement[]
  notCompletedSignerActors: ActorElement[]
  signMethods: SignMethod[]
  signerActorUid: string | undefined
  deviceActorUid: string | undefined
  signMethod: 'OnScreen' | 'SendLink' | undefined
  shouldSelectDevice: boolean
  explanationType: ExplanationType
  onChangeSignerActorUid: (actorUid: string) => void
  onChangeDeviceActorUid: (actorUid: string) => void
  onChangeSignMethod: (signMethod: SignMethod) => void
  onClose: () => void
  onSubmit: () => void
}
const SelectSingerModal: React.FC<SelectSingerModalProps> = ({
  isOpen,
  activeDeviceActors,
  notCompletedSignerActors,
  signMethods,
  signerActorUid,
  deviceActorUid,
  signMethod,
  shouldSelectDevice,
  explanationType,
  onChangeSignerActorUid,
  onChangeDeviceActorUid,
  onChangeSignMethod,
  onClose,
  onSubmit,
}) => {
  const signerActorSelectItems = notCompletedSignerActors.map(actor => ({
    label: actor.node,
    value: actor.uid,
  }))

  const deviceActorSelectItems = activeDeviceActors
    .filter(actor => actor.isPartner)
    .map(actor => ({
      label: actor.node,
      value: actor.uid,
    }))

  const signMethodSelectItems: SelectItem<SignMethod, string>[] =
    signMethods.map(sm => {
      return {
        label:
          sm === 'OnScreen' ? '画面上で署名する' : '署名用リンクを送信する',
        value: sm,
      }
    })

  const onlySelectSendLink =
    signMethodSelectItems.length === 1 &&
    signMethodSelectItems[0].value === 'SendLink'

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>署名実施 / 署名者の選択</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">
            署名を実施される方と署名方法を選択してください。
          </Text>
          <Stack spacing="4" mt="8">
            <Select
              width="100%"
              value={signerActorUid}
              items={signerActorSelectItems}
              onChange={onChangeSignerActorUid}
              isDisabled={signerActorSelectItems.length === 1}
            />
            <Select
              width="100%"
              value={signMethod}
              items={signMethodSelectItems}
              onChange={onChangeSignMethod}
              isDisabled={signMethodSelectItems.length === 1}
            />
          </Stack>
          {onlySelectSendLink && (
            <Box mt="2">
              <WarningMessage
                message={
                  explanationType === 'InPerson'
                    ? '端末操作ユーザーではないため、署名用リンクからご署名ください。'
                    : '対象ユーザーは説明ルームに参加していません。署名用リンクからご署名ください。'
                }
              />
            </Box>
          )}
          {shouldSelectDevice && !onlySelectSendLink && (
            <>
              <Stack spacing="1.5" mt="6">
                <HStack align="center" spacing="1.5">
                  <Sign />
                  <Text fontSize="sm" color="gray.600">
                    署名端末
                  </Text>
                </HStack>
                <Select
                  width="100%"
                  value={deviceActorUid}
                  items={deviceActorSelectItems}
                  onChange={onChangeDeviceActorUid}
                  isDisabled={deviceActorSelectItems.length < 1}
                />
              </Stack>
              {deviceActorSelectItems.length === 0 && (
                <Box mt="1">
                  <WarningMessage message="パートナー施設側に署名実施可能な署名端末が存在しません。" />
                </Box>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            variant="outline"
            colorScheme="green"
            onClick={onSubmit}
            isDisabled={
              !signerActorUid ||
              !signMethod ||
              (shouldSelectDevice && !deviceActorUid)
            }
          >
            次へ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type SelectAgreementFormModalProps = {
  isOpen: boolean
  agreementFormElements: ExpDocRevAgreementFormForSessionInSignFlowFragment[]
  signerActorRole: 'Invalid' | 'Dr' | 'CRC' | 'Patient'
  docRevUids: string[]
  onChangeDocRevUids: (docRevUids: string[]) => void
  onClose: () => void
  onSubmit: () => void
}
const SelectAgreementFormModal: React.FC<SelectAgreementFormModalProps> = ({
  isOpen,
  agreementFormElements,
  signerActorRole,
  docRevUids,
  onChangeDocRevUids,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>署名実施 / 署名する同意書の選択</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">署名を実施される同意書を選択してください。</Text>
          <Stack spacing="4" mt="8">
            {/* TODO: 同意書のリスト */}
            <CheckboxGroup colorScheme="green">
              {agreementFormElements.map(afe => {
                const status = getSelectAgreementFormElementStatus(
                  afe,
                  signerActorRole,
                )
                if (status === 'NoField') {
                  return null
                }
                // 型を絞るために第一条件を記述
                const someAreRejected = status === 'Rejected'
                return (
                  <Box key={afe.icfDocumentRevision.uid}>
                    {someAreRejected ? (
                      <HStack color="red.500">
                        <Reject />
                        <Text fontSize="sm">
                          {afe.icfDocumentRevision.name}
                        </Text>
                      </HStack>
                    ) : status === 'Completed' ? (
                      <HStack color="green.600">
                        <Check />
                        <Text fontSize="sm">
                          {afe.icfDocumentRevision.name}
                        </Text>
                      </HStack>
                    ) : (
                      <Checkbox
                        isChecked={docRevUids?.includes(afe.uid)}
                        onChange={e => {
                          if (e.target.checked) {
                            onChangeDocRevUids([...(docRevUids ?? []), afe.uid])
                          } else {
                            onChangeDocRevUids(
                              (docRevUids ?? []).filter(uid => uid !== afe.uid),
                            )
                          }
                        }}
                        disabled={
                          status === 'PendingRequired' ||
                          status === 'PatientNotBrowsed'
                        }
                      >
                        <HStack>
                          <Box as="span">{afe.icfDocumentRevision.name}</Box>
                          {status === 'PendingRequired' && (
                            <SignRequiredBadge />
                          )}
                        </HStack>
                      </Checkbox>
                    )}
                  </Box>
                )
              })}
            </CheckboxGroup>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="戻る" />
          <Button
            variant="outline"
            colorScheme="green"
            onClick={onSubmit}
            isDisabled={docRevUids.length === 0}
          >
            最終確認へ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type ConfirmationModalProps = {
  isOpen: boolean
  signMethod: SignMethod
  signerActorNode: React.ReactNode
  deviceActorNode: React.ReactNode
  shouldSelectDevice: boolean
  selectedDocRevisions: ExpDocRevAgreementFormForSessionInSignFlowFragment[]
  onClose: () => void
  onSubmit: () => void
  creatingAgreementFormEnvelopes: boolean
  sendingAgreementFormEnvelopeEmail: boolean
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  signerActorNode,
  deviceActorNode,
  shouldSelectDevice,
  signMethod,
  selectedDocRevisions,
  onClose,
  onSubmit,
  creatingAgreementFormEnvelopes,
  sendingAgreementFormEnvelopeEmail,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>署名実施 / 最終確認</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">
            以下の情報で署名を開始します。よろしいですか？
          </Text>
          <Stack spacing="4" mt="8">
            <HStack>
              {signerActorNode}
              <Box>→</Box>
              <Text>
                {signMethod === 'SendLink'
                  ? '署名用リンクを送信する'
                  : '画面上で署名する'}
              </Text>
            </HStack>
            {shouldSelectDevice && (
              <HStack fontSize="xs">
                <Text fontSize="xs">署名端末:</Text> {deviceActorNode}
              </HStack>
            )}
            <UnorderedList spacing="2" stylePosition="inside">
              {selectedDocRevisions.map(af => (
                <ListItem key={af.uid}>
                  <span>{af.icfDocumentRevision.name}</span>
                  {isRequired(af) && (
                    <Box as="span" pl="2">
                      <SignRequiredBadge />
                    </Box>
                  )}
                </ListItem>
              ))}
            </UnorderedList>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack>
            <HStack>
              <ModalCancelButton text="戻る" />
              <Button
                colorScheme="green"
                onClick={onSubmit}
                disabled={
                  creatingAgreementFormEnvelopes ||
                  sendingAgreementFormEnvelopeEmail
                }
              >
                確定
              </Button>
            </HStack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const SignRequiredBadge: React.FC = () => {
  return (
    <Badge
      colorScheme="green"
      borderRadius="full"
      fontSize="xx-small"
      px="2"
      py="0.5"
      bg="green.100"
      color="green.600"
    >
      同意必須
    </Badge>
  )
}

type CompletedSigners = {
  patient: boolean
  dr: boolean
  crc: boolean
}

// ある同意書の、ロールごとに署名が完了しているかどうかを取得する
const getCompletedSigners = (
  agreementForm: ExpDocRevAgreementFormForSessionInSignFlowFragment,
): CompletedSigners => {
  const hasCrcField =
    agreementForm.icfDocumentRevision.__typename ===
      'IcfDocumentRevisionAgreementForm' &&
    agreementForm.icfDocumentRevision.hasCrcField
  const isRejected = agreementForm.signHistories.some(sh => sh.isRejected)
  const completedSigners = agreementForm.signHistories.reduce(
    (acc, sh) => {
      switch (sh.signerRole) {
        case 'Dr':
          return { ...acc, dr: true }
        case 'CRC':
          return { ...acc, crc: true }
        case 'Patient':
          return { ...acc, patient: true }
        case 'Representative':
        case 'None':
          return acc
        default:
          return acc
      }
    },
    { patient: isRejected, dr: isRejected, crc: !hasCrcField || isRejected },
  )
  return completedSigners
}

// 署名がまだ可能なActorElementのリストを取得する
const getNotCompletedSignerActors = (
  agreementForms: ExpDocRevAgreementFormForSessionInSignFlowFragment[],
  signerActors: ActorElement[],
): ActorElement[] => {
  // 1件でも署名できる同意書があればそのSignerがtrueとなる
  const allCompletedSigners = agreementForms
    .map(af => getCompletedSigners(af))
    .reduce(
      (acc, completedSigners) => ({
        patient: acc.patient && completedSigners.patient,
        dr: acc.dr && completedSigners.dr,
        crc: acc.crc && completedSigners.crc,
      }),
      {
        patient: true,
        dr: true,
        crc: true,
      },
    )
  const res = signerActors.filter(
    actor =>
      (actor.signerRole === 'Patient' && !allCompletedSigners.patient) ||
      (actor.signerRole === 'Dr' && !allCompletedSigners.dr) ||
      (actor.signerRole === 'CRC' && !allCompletedSigners.crc),
  )
  return res
}

type SelectAgreementFormElement = {
  docRevUid: string
  icfDocRevName: string
  status:
    | 'PendingRequired'
    | 'PendingOptional'
    | 'PatientNotBrowsed'
    | 'Completed'
    | 'Rejected'
}

// あるActorの、同意書への署名状況を取得する
const getSelectAgreementFormElementStatus = (
  agreementForm: ExpDocRevAgreementFormForSessionInSignFlowFragment,
  signerActorRole: 'Invalid' | 'Dr' | 'CRC' | 'Patient',
):
  | 'PendingRequired'
  | 'PendingOptional'
  | 'PatientNotBrowsed'
  | 'Completed'
  | 'Rejected'
  | 'NoField' => {
  if (
    signerActorRole === 'CRC' &&
    agreementForm.icfDocumentRevision.__typename ===
      'IcfDocumentRevisionAgreementForm' &&
    !agreementForm.icfDocumentRevision.hasCrcField
  ) {
    return 'NoField'
  }
  if (agreementForm.signHistories.some(sh => sh.isRejected)) {
    return 'Rejected'
  }
  const signHistory = agreementForm.signHistories.find(
    sh => sh.signerRole === signerActorRole,
  )
  if (!!signHistory) {
    return 'Completed'
  }
  if (!agreementForm.latestPatientSideBrowsingHistory) {
    return 'PatientNotBrowsed'
  }
  if (
    agreementForm.icfDocumentRevision.__typename ===
    'IcfDocumentRevisionAgreementForm'
  ) {
    if (agreementForm.icfDocumentRevision.isConsentRequired) {
      return 'PendingRequired'
    }
    return 'PendingOptional'
  }
  return 'Completed'
}

// あるActorの、同意書へ署名できるか、どういう状態化を取得する
const getSelectAgreementFormElements = (
  agreementForms: ExpDocRevAgreementFormForSessionInSignFlowFragment[],
  signerActor?: ActorElement,
): SelectAgreementFormElement[] => {
  if (!signerActor) {
    return []
  }

  return agreementForms.flatMap(af => {
    const status = getSelectAgreementFormElementStatus(
      af,
      signerActor.signerRole,
    )
    if (status === 'NoField' || status === 'Rejected') {
      return []
    }
    return [
      {
        docRevUid: af.uid,
        icfDocRevName: af.icfDocumentRevision.name,
        status,
      },
    ]
  })
}
