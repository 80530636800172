import React, { memo, useCallback } from 'react'

import { Box, Stack, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { Close, Down, Up } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { blue, gray } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { QuestionnaireSection } from 'src/modules/entities/questionnaire-section/entity'
import styled from 'styled-components'

import { ErrorMessage } from './error-message'
import { EditableField } from './field'
import { MAX_INPUT_LENGTH } from '../../detail'
import { actions } from '../../redux'
import { useEditQuestionnaireSection } from '../../use-edit-questionnaire-section'
import { useValidationError } from '../../use-validation-error'

type Props = {
  section: QuestionnaireSection
  index: number
}

export const EditableSection: React.FC<Props> = memo(({ section, index }) => {
  const dispatch = useDispatch()
  const { title, fieldMap, uid } = section

  const { onDeleteSection, onUpdateSection, onUpSection, onDownSection } =
    useEditQuestionnaireSection({ index })

  const { getSectionError, resetValidationError } = useValidationError()
  const validationError = getSectionError(section.uid)

  const onAddField = useCallback(() => {
    dispatch(
      actions.addField({
        sectionIndex: index,
      }),
    )
    resetValidationError({
      target: 'fieldSize',
      sectionUid: uid,
    })
  }, [dispatch, index, uid, resetValidationError])

  return (
    <Container>
      <Stack m="8px" spacing="0px">
        <Box cursor="pointer" _hover={{ opacity: 0.6 }} onClick={onUpSection}>
          <Up />
        </Box>
        <Box cursor="pointer" _hover={{ opacity: 0.6 }} onClick={onDownSection}>
          <Down />
        </Box>
      </Stack>

      <Spacer size={20} horizontal />

      <Content>
        <Item>
          <Label>
            <Text>ページ名</Text>
          </Label>
          <Input
            width={'480px'}
            placeholder={'ページ名を入力'}
            value={title}
            onChange={e => {
              onUpdateSection({
                values: { title: e.target.value },
              })
              resetValidationError({ target: 'title', sectionUid: uid })
            }}
            className={validationError?.title ? 'error' : ''}
            maxLength={MAX_INPUT_LENGTH}
          />
          {validationError?.title && (
            <>
              <Spacer size={10} horizontal />
              <ErrorMessage>{validationError.title}</ErrorMessage>
            </>
          )}
        </Item>

        <Spacer size={30} />

        <List>
          {Object.values(fieldMap)
            .sort((a, b) => a.index - b.index)
            .map(qf => (
              <EditableField
                key={qf.uid}
                field={qf}
                sectionIndex={index}
                sectionUid={section.uid}
              />
            ))}
        </List>

        <Spacer size={20} />

        <Button
          iconName="add"
          text={'質問を追加'}
          size="S"
          buttonType="normal"
          onClick={onAddField}
        />

        {validationError?.fieldSize && (
          <>
            <Spacer size={10} />
            <ErrorMessage>{validationError.fieldSize}</ErrorMessage>
          </>
        )}

        <Box pos="absolute" top="10px" right="10px">
          <Close cursor="pointer" onClick={onDeleteSection} />
        </Box>
      </Content>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  padding-right: 70px;
`

const Content = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  background-color: ${blue[10]};
  border-radius: 10px;
  border: 1px solid ${gray[40]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 32px 40px;
  position: relative;
`

const List = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Label = styled.div`
  width: 80px;
`
