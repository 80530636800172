import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  SetExpDocRevisionSignerMemberDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const setExpDocRevisionSignerMember = async ({
  explanationDocRevisionUid,
  drSignerMemberUid,
  crcSignerMemberUid,
}: {
  explanationDocRevisionUid: string
  drSignerMemberUid: string
  crcSignerMemberUid?: string
}): Promise<void> => {
  try {
    await graphqlRequest(SetExpDocRevisionSignerMemberDocument, {
      input: {
        explanationDocRevisionUid,
        drSignerTrialMemberUid: drSignerMemberUid,
        crcSignerTrialMemberUid: crcSignerMemberUid,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useSetExpDocRevisionSignerMember = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationDocRevisionUid,
      drSignerMemberUid,
      crcSignerMemberUid,
    }: {
      explanationDocRevisionUid: string
      drSignerMemberUid: string
      crcSignerMemberUid?: string
    }) =>
      setExpDocRevisionSignerMember({
        explanationDocRevisionUid,
        drSignerMemberUid,
        crcSignerMemberUid,
      }),
    option,
  )
}
