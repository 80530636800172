export const hasSameElements = <T>(a: Array<T>, b: Array<T>) => {
  if (a.length !== b.length) {
    return false
  }

  const copyA = Array.from(a).sort()
  const copyB = Array.from(b).sort()

  for (let i = 0; i < copyA.length; i++) {
    if (copyA[i] !== copyB[i]) {
      return false
    }
  }

  return true
}
