import React from 'react'

import styled, { keyframes } from 'styled-components'

import { blue } from '../base/color/palette'

const duration = '1.4s'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Svg = styled.svg`
  animation: ${rotate} ${duration} linear infinite;
  width: 50px;
  height: 50px;
`

const Circle = styled.circle`
  stroke: ${blue[70]};
  stroke-linecap: round;
  animation: ${dash} ${duration} ease-in-out infinite;
`

export const Spinner = () => {
  return (
    <Container>
      <Svg viewBox="0 0 50 50">
        <Circle fill="none" strokeWidth="5" cx="25" cy="25" r="20" />
      </Svg>
    </Container>
  )
}
