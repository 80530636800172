import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtomValue, useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { InformationMessage } from 'src/components/InformationMessage/InformationMessage'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ReEditCriteriaValueDocument,
  SaveReferredPatientCriteriaFileValueFileInput,
} from 'src/lib/gql-client'
import { useGqlMutation } from 'src/lib/gql-client/request'

import {
  criteriaValueErrorsAtom,
  editingCriteriaValuesAtom,
  fetchedCriteriaValuesAtom,
  isCriteriaValuesChangedAtom,
  isReEditModeAtom,
  validateAtom,
} from '../../atom'

type Props = {
  referredPatientCriteriaUid: string
  mainHospitalName: string
  referredPatientsPath: string
  mutateReferredPatient: () => void
}

export const ReEditCriteriaValueButton: React.FC<Props> = ({
  referredPatientCriteriaUid,
  mainHospitalName,
  referredPatientsPath,
  mutateReferredPatient,
}) => {
  const editingValues = useAtomValue(editingCriteriaValuesAtom)
  const isChanged = useAtomValue(isCriteriaValuesChangedAtom)

  const setFetchedCriteriaValues = useSetAtom(fetchedCriteriaValuesAtom)

  const setIsReEditMode = useSetAtom(isReEditModeAtom)

  const toast = useMirohaToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const allErrors = useAtomValue(criteriaValueErrorsAtom)

  const validateValues = useSetAtom(validateAtom)

  const navigate = useNavigate()

  const { request: save } = useGqlMutation(ReEditCriteriaValueDocument, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: `紹介患者情報を更新しました。${mainHospitalName}に内容が送信されます。`,
      })
      onClose()
      // 一時保存が成功したら fetchedCriteriaValues を更新する
      setFetchedCriteriaValues(editingValues)
      setIsReEditMode(false)
      mutateReferredPatient()
      navigate(referredPatientsPath)
    },
  })
  return (
    <>
      <Button
        onClick={async () => {
          const validationRes = await validateValues()
          if (!validationRes.allOk) {
            toast({
              status: 'error',
              title: '入力に問題があるため更新ができません。',
              description:
                '設定項目に表示されたエラーメッセージをご確認のうえ、修正してください。',
            })
            return
          }
          onOpen()
        }}
        isDisabled={!isChanged || (allErrors.validated && !!allErrors.errors)}
      >
        更新
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>更新を実行しますか?</ModalHeader>
          <ModalBody>
            <Stack spacing="3">
              <Text>{`更新情報が${mainHospitalName}へ送信されます。`}</Text>
              <InformationMessage message="患者の氏名・カルテIDは実施医療機関には表示されません。" />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              onClick={async () => {
                await save({
                  input: {
                    referredPatientCriteriaUid,
                    values: editingValues.map(v => ({
                      fid: v.fid,
                      fieldIndex: v.fieldIndex,
                      value: v.isFile
                        ? (v.value.map(file => ({
                            uploadedFileUid: file.uploadedFile.uid,
                            memo: file.memo,
                            order: file.order,
                          })) satisfies SaveReferredPatientCriteriaFileValueFileInput[])
                        : !!v.value
                          ? v.value
                          : undefined,
                    })),
                  },
                })
              }}
            >
              更新する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
