import React from 'react'

import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Spacer } from 'src/components/spacer/spacer'

type Props = {
  trialName: string
  candidateId: string
  drName: string
  crcName?: string
  sessionStartedAt?: string
}

/** @deprecated import from sidebar-renewal */
export const SidebarContent: React.FC<Props> = ({
  trialName,
  candidateId,
  drName,
  crcName,
  sessionStartedAt,
}) => {
  return (
    <div>
      <Text fontSize="xl" fontWeight="bold">
        {trialName}
      </Text>
      {!!sessionStartedAt && (
        <>
          <Spacer size={32} />
          <Text fontSize="xl" fontWeight="bold">
            説明開始時刻
          </Text>
          <Spacer size={8} />
          <Text>{dayjs(sessionStartedAt).format('YYYY/MM/DD HH:mm:ss')}</Text>
        </>
      )}
      <Spacer size={32} />
      <Text fontSize="xl" fontWeight="bold">
        候補ID
      </Text>
      <Spacer size={8} />
      <Text>{candidateId}</Text>
      <Spacer size={32} />
      <Text fontSize="xl" fontWeight="bold">
        担当医師
      </Text>
      <Spacer size={8} />
      <Text>{drName}</Text>
      {crcName && (
        <>
          <Spacer size={32} />
          <Text fontSize="xl" fontWeight="bold">
            治験コーディネーター
          </Text>
          <Spacer size={8} />
          <Text>{crcName}</Text>
        </>
      )}
    </div>
  )
}
