import { useState } from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Select } from 'src/components/Select/Select'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { Member, MemberRole } from 'src/features/member/types'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { getFullName } from 'src/utils/getFullName'

import { useSendEnvelopeEmail } from '../../api/sendEnvelopeEmail'
import {
  ExplanationDocRevisionAgreementForm,
  ExplanationSession,
} from '../../types'
import { isSignedBy } from '../../utils/isSignedBy'
import { memberRoleToSignerRole } from '../../utils/memberRoleToSignerRole'

type Props = {
  session: ExplanationSession
  docRevision: ExplanationDocRevisionAgreementForm
}

const defaultMemberUid = (members: Member[]) => {
  return members.length > 0 ? members[0].uid : undefined
}

export const StartMembersSign: React.FC<Props> = ({ session, docRevision }) => {
  const {
    account: { selectedTrialHospitalUid, selectedTrial },
  } = useAuthenticatedAccount()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const myHospitalSessionMemberUids = session.members
    .filter(m => m.trialHospital.uid === selectedTrialHospitalUid)
    .map(m => m.trialMember.uid)

  const signedMembers = docRevision.signHistories.map(h => h.member?.uid)

  // Dr, CRC の順に並べる
  const byRoleOrder = (a: Member, b: Member) => {
    const roleOrder: MemberRole[] = ['Dr', 'CRC']
    return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role)
  }
  const signerMembers = docRevision.signerMembers
    .sort(byRoleOrder)
    .filter(m => {
      const isMyHospitalMember = myHospitalSessionMemberUids.includes(m.uid)
      const isNotSigned = !signedMembers.includes(m.uid)
      return isMyHospitalMember && isNotSigned
    })

  const [selectedMemberUid, setSelectedMemberUid] = useState<Member['uid']>()
  const [signMethod, setSignMethod] = useState<'SendLinkEmail' | 'OnScreen'>(
    'OnScreen',
  )

  const handleOpen = () => {
    onOpen()
    setSelectedMemberUid(defaultMemberUid(signerMembers))
  }

  const handleClose = () => {
    setSelectedMemberUid(defaultMemberUid(signerMembers))
    setSignMethod('OnScreen')
    onClose()
  }

  const toast = useMirohaToast()

  const { request: sendEmail } = useSendEnvelopeEmail({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '署名リンクを送信しました',
      })
      handleClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const navigate = useNavigate()

  const handleSubmit = async (
    memberUid: typeof selectedMemberUid,
    method: typeof signMethod,
  ) => {
    const member = signerMembers.find(m => m.uid === memberUid)
    if (!member) return
    if (!method) return

    const signerRole = memberRoleToSignerRole(member.role)

    if (method === 'SendLinkEmail') {
      await sendEmail({
        type: 'AgreementForm',
        explanationDocRevisionUid: docRevision.uid,
        explanationSessionUid: session.uid,
        signerRoles: [signerRole],
      })
      return
    }

    const searchParam = new URLSearchParams({ role: signerRole })
    navigate(
      [
        generatePath(Paths.ExplanationRoomDocuSign, {
          trialUid: selectedTrial.uid,
          sessionUid: session.uid,
          explanationDocRevisionUid: docRevision.uid,
        }),
        searchParam.toString(),
      ].join('?'),
    )
  }

  if (session.type !== 'RemotePartner') {
    return null
  }

  if (!selectedTrialHospitalUid) {
    return null
  }

  if (signerMembers.length === 0) {
    return null
  }

  if (!isSignedBy(docRevision, 'Patient')) {
    return null
  }

  const isRejected = docRevision.signHistories.some(h => h.isRejected)
  if (isRejected) {
    return null
  }

  return (
    <>
      <Button colorScheme="green" onClick={handleOpen}>
        署名を開始
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <form
            onSubmit={async e => {
              e.preventDefault()
              await handleSubmit(selectedMemberUid, signMethod)
            }}
          >
            <ModalHeader>署名者を選択してください</ModalHeader>
            <ModalBody>
              <Stack spacing="4">
                <HStack justify="space-between">
                  <Text>担当者</Text>
                  <Select
                    width={320}
                    value={selectedMemberUid}
                    items={signerMembers.map(m => ({
                      label: `${m.role} ${getFullName(m)}`,
                      value: m.uid,
                    }))}
                    onChange={setSelectedMemberUid}
                    isDisabled={signerMembers.length === 1}
                  />
                </HStack>
                <HStack justify="space-between">
                  <Text>署名方法</Text>
                  <Select
                    width={320}
                    value={signMethod}
                    items={[
                      { label: '画面上で署名する', value: 'OnScreen' },
                      {
                        label: '署名用リンクを送信する',
                        value: 'SendLinkEmail',
                      },
                    ]}
                    onChange={setSignMethod}
                  />
                </HStack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ModalCancelButton />
              <Button
                colorScheme="green"
                isDisabled={!selectedMemberUid || !signMethod}
                type="submit"
              >
                署名を開始する
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
