import { Badge, Text } from '@chakra-ui/react'
import { blue } from 'src/components/base/color/palette'

export const RequiredPatientSignBadge: React.FC = () => {
  return (
    <Badge
      sx={{
        height: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: blue[20],
        fontWeight: 'normal',
        color: blue[70],
        borderRadius: '8px',
        px: '8px',
        wordBreak: 'keep-all',
      }}
    >
      <Text
        fontSize="x-small"
        as="span"
        sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
      >
        同意必須
      </Text>
    </Badge>
  )
}
