import React, { createContext, useCallback, useState } from 'react'

import { WorksheetFieldWithStickyFragment } from 'src/lib/gql-client'

import { WorksheetDetail } from '../types'

type FindFieldStickyFunc = (arg: { fid: string; fieldIndex: number }) => {
  stickyUid: string | undefined
  hasSticky: boolean
}

type Context = {
  find: FindFieldStickyFunc
  isEditing: boolean
  isPopoverOpenForNew: (fid: string, index: number) => boolean
  onChangePopoverOpenForNew: (
    param: { fid: string; index: number } | null,
  ) => void
  toggleEditing: (isEditing: boolean) => void
  findField: (stickyUid: string) => WorksheetFieldWithStickyFragment | undefined
  mutateCache: () => void
}

const context = createContext<Context | undefined>(undefined)

type ProviderProps = {
  fetchedWorksheet: WorksheetDetail
  mutateWorksheet: () => void
  children: React.ReactNode
}

export const WorksheetFieldStickiesProvider: React.FC<ProviderProps> = ({
  fetchedWorksheet,
  mutateWorksheet,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  // 新規作成のためにpopoverを開いているfieldを保持
  // popoverはワークシート全体で一つだけ開けるのでこの値をcontextで管理する
  const [popoverFieldForNew, setPopoverFieldNew] = useState<{
    fid: string
    index: number
  } | null>(null)

  const isPopoverOpenForNew = useCallback(
    (fid: string, index: number) => {
      return (
        popoverFieldForNew?.fid === fid && popoverFieldForNew?.index === index
      )
    },
    [popoverFieldForNew],
  )

  const onChangePopoverOpenForNew = useCallback(
    (param: { fid: string; index: number } | null) => {
      if (param === null) {
        setPopoverFieldNew(null)
        return
      }
      setPopoverFieldNew({ fid: param.fid, index: param.index })
    },
    [],
  )

  const toggleEditing = useCallback((isEditing: boolean) => {
    setIsEditing(isEditing)
  }, [])

  const find: FindFieldStickyFunc = useCallback(
    ({ fid, fieldIndex }) => {
      const defaultResult = { stickyUid: undefined, hasSticky: false }
      if (!fetchedWorksheet.fields) return defaultResult

      const field = fetchedWorksheet.fields.find(
        sticky => sticky.fid === fid && sticky.fieldIndex === fieldIndex,
      )
      return {
        stickyUid: field?.stickyUid || undefined,
        hasSticky: !!field?.stickyUid,
      }
    },
    [fetchedWorksheet.fields],
  )

  const findField = useCallback(
    (stickyUid: string) => {
      return fetchedWorksheet.fields?.find(
        field => field.stickyUid === stickyUid,
      )
    },
    [fetchedWorksheet.fields],
  )

  const mutateCache = useCallback(() => {
    mutateWorksheet()
  }, [mutateWorksheet])

  return (
    <context.Provider
      value={{
        isEditing,
        toggleEditing,
        isPopoverOpenForNew,
        onChangePopoverOpenForNew,
        find,
        findField,
        mutateCache,
      }}
    >
      {children}
    </context.Provider>
  )
}

export const useWorksheetFieldStickiesContext = () => {
  const ctx = React.useContext(context)
  if (!ctx) {
    throw new Error(
      'useWorksheetFieldStickiesContext must be used within WorksheetFieldStickiesProvider',
    )
  }
  return ctx
}
