import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import Modal from 'src/Modal'
import styled from 'styled-components'

type Props = {
  onClose: () => void
  onClickIssue: () => Promise<void>
}

export const PasswordIssueModal: React.FC<Props> = props => {
  return (
    <Modal onClose={props.onClose} closable={false}>
      <ModalContent>
        <div>
          <Text fontSize="lg" fontWeight="bold">
            パスワード発行確認
          </Text>
        </div>
        <div>
          <Text>患者のログインパスワードを生成します。</Text>
        </div>
        <div>
          <Text color={red[50]}>
            ※既に患者のパスワードが生成されている場合、
            <br />
            過去のパスワードではログインできなくなります。
          </Text>
        </div>
        <ButtonWrapper>
          <div>
            <Button
              text="キャンセル"
              size="S"
              buttonType="normal"
              onClick={props.onClose}
            />
          </div>
          <div>
            <Button
              text="パスワードを発行する"
              size="S"
              buttonType="important"
              onClick={props.onClickIssue}
            />
          </div>
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:nth-child(1) {
    margin-top: 40px;
  }
  & > div:nth-child(2) {
    margin-top: 30px;
  }
  & > div:nth-child(3) {
    margin-top: 4px;
  }
  & > div:nth-child(4) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;

  & > div:nth-child(2) {
    margin-left: 16px;
  }
`
