import React, { useState } from 'react'

import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { useExplanationDocRevision } from 'src/features/explanation/api/getDocRevision'
import { useExplanationDocRevisionFileUrl } from 'src/features/explanation/api/getDocRevisionFileUrl'
import {
  ExplanationDocRevision,
  ExplanationSession,
} from 'src/features/explanation/types'
import { browseDocRevision } from 'src/features/explanationRoom/api'
import {
  locationPaths,
  ExplanationRoomEventProvider,
} from 'src/features/explanationRoom/context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from 'src/features/explanationRoom/hooks/useFloatingVideoCallForMember'

import { ExplanationRoomDocumentRenewal } from './document-renewal'
import { UID } from '../../../../../../../utils/brandedUid'
import { useHospitalSession } from '../hospital-session.hooks'
import { ExplanationRoomLayoutRenewal } from '../layout-renewal'
import { getExplanationRoomTopRoute } from '../routes'

type Param = {
  sessionUid: ExplanationSession['uid']
  docRevisionUid: ExplanationDocRevision['uid']
}

export const HospitalDocumentContainer: React.FC = () => {
  const { selectedTrial } = useSelectedTrial()
  const {
    sessionUid = '' as UID<'ExplanationSession'>,
    docRevisionUid = '' as UID<'ExplanationDocRevision'>,
  } = useParams<Param>()
  const [browsingRecorded, setBrowsingRecorded] = useState(false)
  const [previousSignHistoryCount, setPreviousSignHistoryCount] = useState(0)
  const location = useLocation()

  const { session, mutateSession } = useHospitalSession({
    explanationSessionUid: sessionUid,
  })

  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()

  const isPartner =
    session?.explanationType === 'RemotePartner' &&
    selectedTrialHospitalUid ===
      session?.explanationRevision.partnerTrialHospital?.uid

  const { data: fileUrl, mutate: mutateFileUrl } =
    useExplanationDocRevisionFileUrl({
      explanationDocRevisionUid: docRevisionUid,
      revalidateOnFocus: false,
    })

  const { data: docRevision, mutate: mutateDocRevision } =
    useExplanationDocRevision({
      explanationDocRevisionUid: docRevisionUid,
      refreshInterval: previous => {
        if (
          previous?.type !== 'AgreementForm' &&
          previous?.type !== 'CheckUnderstanding'
        ) {
          return 0
        }
        if (previous.isCompleted) {
          return 0
        }
        // 同意書or理解度確認かつ未完了の場合はpolling
        return 3000
      },
      onSuccess: async docRevision => {
        if (
          docRevision.type === 'AgreementForm' &&
          docRevision.signHistories.length !== previousSignHistoryCount
        ) {
          mutateFileUrl()
          setPreviousSignHistoryCount(docRevision.signHistories.length)
        }
        if (
          docRevision.type === 'CheckUnderstanding' &&
          docRevision.isCompleted
        ) {
          mutateFileUrl()
        }
        // 正常にデータ取得できたタイミングで（一回のmountingにつき）一度だけ閲覧履歴の記録を行う
        // ロギングの方法は見直して汎用的にできるようにしたい
        if (browsingRecorded) return
        const createLogState = location.state
        if (!!createLogState && createLogState.notCreateLog) return

        await browseDocRevision({
          explanationDocRevisionUid: docRevision.uid,
        })
        mutateSession()
        setBrowsingRecorded(true)
      },
    })

  const navigate = useNavigate()

  const navigateToRoomTop = () => {
    navigate(
      getExplanationRoomTopRoute({
        type: 'hospital',
        trialUid: selectedTrial.uid,
        sessionUid,
      }),
    )
  }

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (!docRevision || !session || !fileUrl || !selectedTrialHospitalUid) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={session.explanationRevision.uid}
      sessionUid={sessionUid}
      explanationPatientUid={
        session.explanationRevision.explanation.patient.uid
      }
      patientPhoneNumber={undefined} // 旧署名フローでは不要
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <ExplanationRoomLayoutRenewal
        headerTitle={docRevision.icfDocumentRevisionName}
        session={session}
        sidebarContent={null}
      >
        <ExplanationRoomDocumentRenewal
          navigateToRoomTop={navigateToRoomTop}
          docRevision={docRevision}
          session={session}
          locationPath={locationPaths.document(docRevisionUid)}
          trialHospitalUid={selectedTrialHospitalUid}
          trialMemberUid={currentMember.uid}
          fileUrl={fileUrl}
          mutateDocRevision={() => {
            mutateDocRevision()
            mutateFileUrl()
          }}
          isPartner={isPartner}
        />
        {renderFloatingVideoCall()}
      </ExplanationRoomLayoutRenewal>
    </ExplanationRoomEventProvider>
  )
}
