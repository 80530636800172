import React from 'react'

import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Label } from 'src/components/form-redesigned/label'
import {
  SingleCheckboxWithoutValue,
  SingleCheckboxWithValue,
} from 'src/components/form-redesigned/single-checkbox'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { Template } from 'src/modules/entities/template/entity'
import { TemplateFolder } from 'src/modules/entities/template-folder/entity'
import styled from 'styled-components'

import { useSelectTemplate } from './use-select-template'
import { useSubmitCreate } from './use-submit-create'
import { Spacer } from '../../../../../../../../../components/spacer/spacer'
import { patientIdParamName } from '../../../patient-detail'

type Props = {
  templateFolder: TemplateFolder
  templates: Template[]

  onClose: () => void
}

const TableContainer = styled.div`
  width: 100%;
  height: 300px;
`

export const TemplateSelectModal: React.FC<Props> = props => {
  const { trialUid = '', patientUid = '' } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
  }>()
  const { templateFolder, templates } = props
  const {
    selectedTemplateUids,
    handlers: { checked, onCheck, onCheckAll },
  } = useSelectTemplate(templates)

  const { request, requesting, errorMessage } = useSubmitCreate()

  const onSubmit = async () => {
    await request({ trialUid, patientUid, templateUids: selectedTemplateUids })
    props.onClose()
  }

  const onClose = () => {
    if (requesting) return

    props.onClose()
  }

  const allChecked =
    templates.length > 0 && templates.every(t => checked(t.uid))

  return (
    <Modal onClose={onClose}>
      <ModalTitle title="新規ワークシート作成" />

      <ModalContent>
        <Label>テンプレートの選択</Label>
        <Spacer size={10} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell width={48} align="center">
                  <SingleCheckboxWithoutValue
                    onChange={onCheckAll}
                    checked={allChecked}
                    disabled={templates.length === 0}
                  />
                </TableHeadCell>
                <TableHeadCell>{templateFolder.name}</TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {templates.map(t => (
                <label>
                  <TableRow key={t.uid} borderBottom hover>
                    <TableBodyCell width={48} align="center">
                      <SingleCheckboxWithValue
                        value={t.uid}
                        onChange={onCheck}
                        checked={checked(t.uid)}
                      />
                    </TableBodyCell>
                    <TableBodyCell>{t.title}</TableBodyCell>
                  </TableRow>
                </label>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="作成"
          onClick={onSubmit}
          buttonType="important"
          disabled={selectedTemplateUids.length === 0 || requesting}
        ></Button>
      </ModalActions>
      {errorMessage && <Message type="error" message={errorMessage} centered />}
    </Modal>
  )
}
