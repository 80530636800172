import { Button } from 'src/components/base/button/button'
import styled from 'styled-components'

import { PasswordDisplayModal } from './password-display-modal'
import { PasswordIssueModal } from './password-issue-modal'

export type State =
  | {
      openingModal: 'none' | 'password-issue-modal'
      isLoading: boolean
      password: string | undefined
    }
  | {
      openingModal: 'password-display-modal'
      isLoading: boolean
      password: string
    }

type Props = {
  isPasswordIssued: boolean
  state: State
  onClickButton: () => void
  onClose: () => void
  onClickIssue: () => Promise<void>
}

export const PasswordIssueButton: React.FC<Props> = props => {
  const { state } = props
  return (
    <Wrapper>
      <Button
        text={
          props.isPasswordIssued
            ? 'パスワードを再発行する'
            : 'パスワードを発行する'
        }
        size="S"
        buttonType="important"
        onClick={props.onClickButton}
      />
      {state.openingModal === 'password-issue-modal' && (
        <PasswordIssueModal
          onClose={props.onClose}
          onClickIssue={props.onClickIssue}
        />
      )}
      {state.openingModal === 'password-display-modal' &&
        state.isLoading === false && (
          <PasswordDisplayModal
            onClose={props.onClose}
            password={state.password}
          />
        )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
