import { useState, useMemo } from 'react'

export const useOpenModal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const handlers = useMemo(
    () => ({
      openModal: () => {
        setModalOpen(true)
      },
      closeModal: () => {
        setModalOpen(false)
      },
    }),
    [],
  )

  return { modalOpen, handlers }
}
