import React from 'react'

import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import equal from 'fast-deep-equal'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { InformationMessage } from 'src/components/InformationMessage/InformationMessage'
import { NumberOnlyInput } from 'src/components/NumberOnlyInput/NumberOnlyInput'
import { Select } from 'src/components/Select/Select'
import { errorMessages } from 'src/constants/errorMessages'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import {
  EditReferredPatient_ActiveTrialMembersFragment,
  EditReferredPatient_ReferredPatientFragment,
  Gender,
  TrialMemberRole,
} from 'src/lib/gql-client'
import { getFullName } from 'src/utils/getFullName'
import { phoneNumberRegExp } from 'src/utils/regExp'
import * as yup from 'yup'

import { RequiredBadge } from '../../../../components/RequiredBadge/RequiredBadge'
import { RoleBadge } from '../../../../components/RoleBadge/RoleBadge'

const referredPatientSchema = yup.object({
  name: yup.string().max(100, errorMessages.maxLength(100)).optional(),
  chartId: yup.string().max(20, errorMessages.maxLength(20)).optional(),
  gender: yup
    .mixed<Extract<Gender, 'Male' | 'Female'>>()
    .required(errorMessages.required()),
  age: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === '') return undefined
      return value
    })
    .typeError(errorMessages.onlyNumber())
    .moreThan(-1, errorMessages.numberRange(0, 150))
    .lessThan(151, errorMessages.numberRange(0, 150))
    .required(errorMessages.required()),
  assigneeName: yup
    .string()
    .max(100, errorMessages.maxLength(100))
    .required(errorMessages.required()),
  assigneeRole: yup.mixed<TrialMemberRole>().optional(),
  assigneeEmail: yup
    .string()
    .email(errorMessages.email())
    .max(255, errorMessages.maxLength(255))
    .optional(),
  assigneePhoneNumber: yup
    .string()
    .matches(phoneNumberRegExp, {
      excludeEmptyString: true,
      message: errorMessages.phoneNumber(),
    })
    .max(15, errorMessages.maxDigits(15))
    .optional(),
  isFreeInputMode: yup.boolean().optional(),
})

export type ReferredPatientSchema = yup.InferType<typeof referredPatientSchema>

type Props = {
  isOpen: boolean
  onClose: () => void
  mutate: (res: ReferredPatientSchema) => Promise<void>
  patient?: EditReferredPatient_ReferredPatientFragment
  trialMembers: EditReferredPatient_ActiveTrialMembersFragment[]
} & (
  | {
      modalType: 'Create'
      onBack: () => void
    }
  | {
      modalType: 'Modify'
    }
)

export const EditReferredPatient: React.FC<Props> = props => {
  const { modalType, isOpen, onClose, mutate, patient, trialMembers } = props

  const defaultValues: Partial<ReferredPatientSchema> = {
    name: patient?.latestDetail.name ?? undefined,
    chartId: patient?.latestDetail.chartId ?? undefined,
    gender:
      patient?.latestDetail.gender === 'Male' ||
      patient?.latestDetail.gender === 'Female'
        ? patient.latestDetail.gender
        : undefined,
    age: patient?.latestDetail.age ?? undefined,
    assigneeName: patient?.latestContact.assigneeName ?? undefined,
    assigneeRole: patient?.latestContact.assigneeRole ?? undefined,
    assigneeEmail: patient?.latestContact.assigneeEmail ?? undefined,
    assigneePhoneNumber:
      patient?.latestContact.assigneePhoneNumber ?? undefined,
    isFreeInputMode:
      !trialMembers.some(
        member =>
          getFullName(member.user) === patient?.latestContact.assigneeName,
      ) && modalType === 'Modify',
  }

  const methods = useForm<ReferredPatientSchema>({
    mode: 'onBlur',
    resolver: yupResolver(referredPatientSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    clearErrors,
    getValues,
  } = methods

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
        clearErrors()
      }}
      size="2xl"
      scrollBehavior="inside"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(mutate)}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {modalType === 'Create' ? '新規紹介患者登録' : '紹介患者情報変更'}
            </ModalHeader>
            <ModalBody>
              <Stack spacing="8" pb="4">
                <Text>1. 紹介患者の情報を入力してください。</Text>
                <VStack spacing="2" align="start">
                  <Flex w="full" gap={10}>
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel alignItems="center" display="flex">
                        氏名
                      </FormLabel>
                      <Input aria-label="氏名" {...register('name')} />
                      <FormErrorMessage>
                        {errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.chartId}>
                      <FormLabel alignItems="center" display="flex">
                        カルテID
                      </FormLabel>
                      <Input aria-label="カルテID" {...register('chartId')} />
                      <FormErrorMessage>
                        {errors.chartId?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <InformationMessage message="氏名・カルテIDは実施医療機関へは送信されません。" />
                </VStack>
                <Flex w="full" gap={10}>
                  <FormControl isRequired isInvalid={!!errors.age} w="auto">
                    <FormLabel
                      requiredIndicator={<RequiredBadge ml="1" />}
                      alignItems="center"
                      display="flex"
                    >
                      年齢
                    </FormLabel>
                    <HStack>
                      <Controller
                        name="age"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <NumberOnlyInput
                            aria-label="年齢"
                            w="80px"
                            value={String(value ?? '')}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
                        歳
                      </Text>
                    </HStack>
                    <FormErrorMessage>{errors.age?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired display="flex" flexDirection="column">
                    <FormLabel
                      requiredIndicator={<RequiredBadge ml="1" />}
                      alignItems="center"
                      display="flex"
                    >
                      性別
                    </FormLabel>
                    <RadioGroup
                      defaultValue={getValues('gender') || defaultValues.gender}
                      flex="1"
                    >
                      <HStack spacing="8" h="full" align="center">
                        <Radio key="Male" value="Male" {...register('gender')}>
                          男性
                        </Radio>
                        <Radio
                          key="Female"
                          value="Female"
                          {...register('gender')}
                        >
                          女性
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </Flex>
                <Divider />
                <Text>2. 紹介患者のご担当者の連絡先を入力してください。</Text>
                <Flex w="full">
                  <HStack>
                    <FormControl isRequired isInvalid={!!errors.assigneeName}>
                      <FormLabel
                        as="span"
                        alignItems="center"
                        display="flex"
                        requiredIndicator={<RequiredBadge ml="1" />}
                      >
                        担当者
                      </FormLabel>
                      <HStack spacing="3">
                        <Controller
                          name="assigneeRole"
                          control={control}
                          defaultValue={
                            modalType === 'Create' ? 'Dr' : undefined
                          }
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Select
                              width="120px"
                              value={value}
                              isClearable
                              placeholder="未選択"
                              unselectText="未選択"
                              items={[
                                {
                                  value: 'Dr',
                                  label: <RoleBadge role="Dr" />,
                                },
                                {
                                  value: 'CRC',
                                  label: <RoleBadge role="CRC" />,
                                },
                              ]}
                              onChange={v => {
                                // undefinedで更新するとdefaultValueが設定されてしまうので空文字に変換
                                onChange(v ?? '')
                              }}
                              onBlur={onBlur}
                            />
                          )}
                        />
                        <Controller
                          name="assigneeName"
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <MemberNameForm
                              value={value ?? ''}
                              trialMembers={trialMembers}
                              onChange={onChange}
                              onBlur={onBlur}
                              isInvalid={!!errors.assigneeName}
                              modalType={modalType}
                            />
                          )}
                        />
                      </HStack>
                      <FormErrorMessage>
                        {errors.assigneeName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Flex>
                <Flex w="full">
                  <HStack w="full">
                    <FormControl isInvalid={!!errors.assigneeEmail}>
                      <FormLabel alignItems="center" display="flex">
                        メールアドレス
                      </FormLabel>
                      <Input
                        aria-label="メールアドレス"
                        w="full"
                        {...register('assigneeEmail')}
                      />
                      <FormErrorMessage>
                        {errors.assigneeEmail?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Flex>
                <Flex w="full">
                  <HStack>
                    <FormControl
                      isInvalid={!!errors.assigneePhoneNumber}
                      as="div"
                    >
                      <FormLabel alignItems="center" display="flex">
                        電話番号
                      </FormLabel>
                      <Controller
                        name="assigneePhoneNumber"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <NumberOnlyInput
                            aria-label="電話番号"
                            w="240px"
                            placeholder="例) 08011223344"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.assigneePhoneNumber?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Flex>
              </Stack>
            </ModalBody>
            <ModalFooter>
              {modalType === 'Create' ? (
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={() => {
                    props.onBack()
                  }}
                >
                  戻る
                </Button>
              ) : (
                <ModalCancelButton />
              )}
              <SubmitButton
                defaultValues={defaultValues}
                modalType={modalType}
              />
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

type MemberNameFormProps = {
  trialMembers: EditReferredPatient_ActiveTrialMembersFragment[]
  value: string
  isInvalid: boolean
  onChange: (value: string) => void
  onBlur: () => void
  modalType: 'Create' | 'Modify'
}

const MemberNameForm: React.FC<MemberNameFormProps> = ({
  trialMembers,
  value,
  isInvalid,
  onChange,
  onBlur,
  modalType,
}) => {
  const { setValue, watch, register } = useFormContext<ReferredPatientSchema>()
  const isFreeInputMode = watch('isFreeInputMode')
  return (
    <HStack spacing="2">
      {isFreeInputMode ? (
        <Input
          width="240px"
          value={value}
          onChange={e => {
            onChange(e.target.value)
          }}
          isInvalid={isInvalid}
          onBlur={onBlur}
        />
      ) : (
        <Select
          isSearchable
          width="240px"
          value={value}
          items={trialMembers.map(member => ({
            label: getFullName(member.user),
            value: getFullName(member.user),
          }))}
          onChange={name => {
            onChange(name)
            const targetMember = trialMembers.find(
              member => getFullName(member.user) === name,
            )
            if (targetMember) {
              // メンバーの選択肢から入力した場合はroleとメールアドレスを自動入力
              setValue('assigneeRole', targetMember.role)
              setValue('assigneeEmail', targetMember.user.email)
            }
          }}
          onBlur={onBlur}
          isInvalid={isInvalid}
        />
      )}
      <Checkbox
        {...register('isFreeInputMode')}
        isInvalid={false}
        isRequired={false}
      >
        自由入力
      </Checkbox>
    </HStack>
  )
}

// 値をwatchする範囲を限定して再レンダリングを抑制する
const SubmitButton: React.FC<{
  defaultValues: Partial<ReferredPatientSchema>
  modalType: 'Create' | 'Modify'
}> = ({ defaultValues, modalType }) => {
  const {
    formState: { isValid },
  } = useFormContext<ReferredPatientSchema>()
  const currentValue = useWatch<ReferredPatientSchema>()

  const emptyOmittedDefaultValues = Object.entries(defaultValues).reduce(
    (acc, [key, value]) => {
      if (!value) return acc
      return { ...acc, [key]: value }
    },
    {},
  )

  const emptyOmittedCurrentValues = Object.entries(currentValue).reduce(
    (acc, [key, value]) => {
      if (!value) return acc
      return { ...acc, [key]: value }
    },
    {},
  )

  const isChanged = !equal(emptyOmittedCurrentValues, emptyOmittedDefaultValues)

  return (
    <Button type="submit" isDisabled={!isValid || !isChanged}>
      {modalType === 'Create' ? '登録' : '更新'}
    </Button>
  )
}
