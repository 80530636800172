import { generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { ExplanationType } from 'src/lib/gql-client'

export const getRoomFinishedRoute = (param?: {
  type?: ExplanationType
  isPartnerMember?: boolean
  isLobby?: boolean
  isLeft?: boolean
}) => {
  const searchParam = new URLSearchParams()
  if (param?.type) {
    searchParam.append('type', param.type)
  }
  if (param?.isLeft) {
    searchParam.append('isLeft', String(param.isLeft))
  }
  if (param?.isPartnerMember) {
    searchParam.append('isPartnerMember', String(param.isPartnerMember))
  }
  if (param?.isLobby) {
    searchParam.append('isLobby', String(param.isLobby))
  }
  return [
    generatePath(Paths.ExplanationRoomFinished),
    searchParam.toString(),
  ].join('?')
}
