import React from 'react'

import { ChoiceAnswer } from 'src/modules/entities/choice-answer/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import styled from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'

import { NestedSmallItemAnswerComponent } from './nest-small-item-answer'
import { hasValue } from '../../modules/util/item-type-classification'

type Props = {
  smallItemAnswer: SmallItemAnswer
  smallItemAnswerMap: Record<string, SmallItemAnswer>
}

export const SmallItemAnswerComponent: React.FC<Props> = props => {
  const { smallItemAnswer, smallItemAnswerMap } = props

  const checkedChoices = smallItemAnswer.choiceAnswers.filter(ca => ca.checked)

  const showValue = hasValue(smallItemAnswer) && smallItemAnswer.hasLog

  return (
    <Container>
      <Title>{smallItemAnswer.title}</Title>

      {showValue && (
        <FlexWrap column>
          <FlexItem>
            <Value>{smallItemAnswer.value}</Value>
          </FlexItem>
        </FlexWrap>
      )}

      {checkedChoices.length !== 0 && (
        <FlexWrap column>
          {checkedChoices.map((choice, i) =>
            choice.smallItemAnswerUids.length !== 0 ? (
              <FlexItem key={choice.uid}>
                <Border
                  className={checkedChoices.length === i + 1 ? 'last' : ''}
                >
                  <NestedSmallItemAnswerItem
                    choice={choice}
                    smallItemAnswerMap={smallItemAnswerMap}
                  />
                </Border>
              </FlexItem>
            ) : (
              <FlexItem key={choice.uid}>
                <Border
                  className={checkedChoices.length === i + 1 ? 'last' : ''}
                >
                  <Values>
                    <Value key={choice.uid}>{choice.description}</Value>
                  </Values>
                </Border>
              </FlexItem>
            ),
          )}
        </FlexWrap>
      )}
    </Container>
  )
}

type NestedSmallItemAnswerItemProps = {
  choice: ChoiceAnswer
  smallItemAnswerMap: Record<string, SmallItemAnswer>
}

const NestedSmallItemAnswerItem: React.FC<
  NestedSmallItemAnswerItemProps
> = props => {
  const { choice, smallItemAnswerMap } = props

  const smallItemAnswers = choice.smallItemAnswerUids.map(
    uid => smallItemAnswerMap[uid],
  )

  return (
    <FlexWrap column>
      <FlexItem>
        <Value className="with-question">{choice.description}</Value>
      </FlexItem>
      <FlexItem>
        {smallItemAnswers.map((sia, i) => (
          <NestedSmallItemContainer key={sia.uid}>
            <Border className={smallItemAnswers.length === i + 1 ? 'last' : ''}>
              <NestedSmallItemAnswerComponent
                key={sia.uid}
                smallItemAnswer={sia}
                smallItemAnswerMap={smallItemAnswerMap}
              />
            </Border>
          </NestedSmallItemContainer>
        ))}
      </FlexItem>
    </FlexWrap>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`

const Title = styled.p`
  width: 200px;
  font-weight: bold;
  font-size: 9px;
  padding: 9px 0;
  margin: 0;
  word-break: break-all;
`

const Values = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Value = styled.p`
  font-size: 9px;
  padding: 9px 0;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;

  &:after {
    content: '/';
  }

  &:last-child:after {
    content: '';
  }

  &.with-question {
    border-bottom: 0.5px dashed #000000;
  }
`

const NestedSmallItemContainer = styled.div`
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
`

const FlexWrap = styled(Flex)`
  width: 100%;
`

const Border = styled.div`
  border-bottom: 0.5px dashed #000000;

  &.last {
    border: none;
  }
`
