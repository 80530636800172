import { HStack, Text } from '@chakra-ui/react'

import { RoleBadge } from '../RoleBadge/RoleBadge'

type Props = {
  role: 'Dr' | 'CRC' | 'DM' | 'CRA'
  displayName: string
  isPartner?: boolean
}

export const MemberLabel: React.FC<Props> = ({
  role,
  displayName,
  isPartner = false,
}) => {
  return (
    // 最低限1文字+省略記号で表示するため、minWを設定
    <HStack spacing="1.5" align="center" minW="64px" maxW="full">
      <RoleBadge role={role} isPartner={isPartner} />
      <Text
        as="span"
        flex="1"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {displayName}
      </Text>
    </HStack>
  )
}
