import React, { useMemo, useRef } from 'react'

import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Link,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import equal from 'fast-deep-equal'
import AutosizeInput from 'react-input-autosize'
import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { colors } from 'src/assets/colors'
import { white } from 'src/components/base/color/palette'
import { Edit, Followup } from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import { useBlockTransition } from 'src/hooks/useBlockTransition'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import { getSingle } from 'src/modules/entities/template/selector'
import { getSingle as getTemplateFolder } from 'src/modules/entities/template-folder/selector'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'
import { FlexItem } from 'styled-flex-component'

import { MediumItemGroup } from './medium-item-group'
import { PreviewButton } from './preview/button'
import { PulldownContainer } from './pulldown/pulldown-container'
import { ReleaseButton } from './release/button'
import { SortOnlyMediumItemGroup } from './sort-only-medium-item-group'
import { UpdateTitleButton } from './update-title/button/update-title-button'
import { useEditTemplate } from './use-edit-template'
import { useFetch } from './use-fetch'
import { AddMediumItem } from '../../../common/add-medium-item'
import { usePermission } from '../../../common/permission'
import { trialIdParamName } from '../../../trial-detail'
import { TemplateStatusBadge } from '../status-badge'

export const templateIdParamName = 'templateUid'

type Props = {}

export const TemplateDetail: React.FC<Props> = props => {
  const { trialUid = '', templateUid = '' } = useParams<{
    [templateIdParamName]: string
    [trialIdParamName]: string
  }>()
  const template = useSelector((state: RootState) =>
    getSingle(state, templateUid),
  )
  const templateFolder = useSelector((state: RootState) =>
    getTemplateFolder(state, template?.templateFolderUid),
  )
  const selectedTrial = useSelector(getSelectedTrial)!
  const v2 = selectedTrial.featureFlags.eSourceV2

  const { originalTemplate, title, videoEnabled, mediumItems } = useSelector(
    (state: RootState) => state.templateDetail,
  )

  // 使用する？？
  // const { errorUidMap } = useHandleErrors({
  //   template,
  //   mediumItems,
  //   v2,
  // })
  const {
    releaseTried,
    handlers: {
      onValidate,
      onAdd,
      onSubmit,
      onSubmitOrder,
      onChangeTitle,
      onChangeVideoEnabled,
    },
  } = useEditTemplate({ template, mediumItems })

  const isEdited = useMemo(() => {
    if (!originalTemplate) return false
    if (originalTemplate.title !== title) return true
    if (originalTemplate.videoEnabled !== videoEnabled) return true

    return !equal(originalTemplate.mediumItems, mediumItems)
  }, [mediumItems, originalTemplate, title, videoEnabled])

  const titleRef = useRef<AutosizeInput>(null)
  const focusToTitle = () => {
    ;(titleRef as any).current.focus()
  }

  const { fetched } = useFetch({
    trialUid,
    templateUid,
    pathForNotFound: '../',
  })

  const { hasPermission } = usePermission()
  const isReleased = template?.status === TemplateStatus.Released
  const isDisabled = template?.status === TemplateStatus.Disabled

  // 編集可能であればtrue,編集不可(公開・無効化)であればfalse
  const isEditMode = !isReleased && !isDisabled

  const canSwitchVideoSelect = hasPermission(PERMISSIONS.Template_Edit)
  const showVideoSelect = useMemo(() => {
    if (!template) return false
    if (!selectedTrial.videoEnabled) return false

    return isEditMode && canSwitchVideoSelect && !v2
  }, [
    template,
    selectedTrial.videoEnabled,
    isEditMode,
    canSwitchVideoSelect,
    v2,
  ])
  const showVideoTag = !showVideoSelect && !!template?.videoEnabled
  const canEdit = isEditMode && hasPermission(PERMISSIONS.Template_Edit)
  const canEditOrder = canEdit || isReleased // 公開後はDr,CRCも順番を変更できる
  const canSave = isEditMode && hasPermission(PERMISSIONS.Template_Save)
  const canRelease = isEditMode && hasPermission(PERMISSIONS.Template_Release)
  const canRenameTitle =
    isEditMode && hasPermission(PERMISSIONS.Template_Rename)

  const canRenameTitleInDisabled =
    !isEditMode && hasPermission(PERMISSIONS.Template_Rename)

  const GroupComponent = canEdit ? MediumItemGroup : SortOnlyMediumItemGroup

  useBlockTransition({
    showAlert: isEdited,
  })

  if (!fetched && !template) {
    return null
  }
  if (!template || !templateFolder) {
    return <Navigate replace to={'./'} />
  }

  const releaseButtonEnabled =
    template.status === TemplateStatus.Draft && !isEdited
  const saveButtonEnabled = !releaseButtonEnabled

  return (
    <Box>
      <TopContainer>
        <Wrap spacingX="3" alignItems="center" align="center">
          <WrapItem alignItems="center" gap="3" w="full" overflowX="auto">
            <Flex gap="1.5" align="center">
              {templateFolder.observationType === 'FollowUp' && (
                <Box color="gray.500">
                  <Followup />
                </Box>
              )}
              <Text fontWeight="bold" fontSize="lg" wordBreak="keep-all">
                {templateFolder.name}
              </Text>
            </Flex>
            <Box color="gray.400" fontWeight="bold" fontSize="lg">
              /
            </Box>
            <Flex gap="3" align="center">
              {canRenameTitle && (
                <HStack spacing="2.5">
                  <TitleInput
                    ref={titleRef}
                    value={title}
                    inputStyle={{ fontSize: 18, fontWeight: 'bold' }}
                    onChange={event => onChangeTitle(event.target.value)}
                    className={
                      releaseTried &&
                      (titleRef.current && titleRef.current.props.value) === ''
                        ? 'error'
                        : ''
                    }
                  />
                  <IconButton
                    icon={<Edit />}
                    aria-label="edit template title"
                    onClick={e => focusToTitle()}
                    variant="customIconButtonGhost"
                  />
                </HStack>
              )}

              {!canRenameTitle && (
                <Text fontWeight="bold" fontSize="lg" wordBreak="keep-all">
                  {template.title}
                </Text>
              )}

              {/* ↓v2の場合一時的に非表示にする: https://bubbys.slack.com/archives/C01EBGH4TFT/p1687498074403549 */}
              {!v2 && canRenameTitleInDisabled && (
                <UpdateTitleButton templateUid={template.uid} />
              )}
            </Flex>
          </WrapItem>

          <WrapItem>
            <TemplateStatusBadge status={template.status} />
          </WrapItem>

          {showVideoSelect && (
            <WrapItem>
              <VideoPossibilitySelect>
                <Checkbox
                  isChecked={videoEnabled}
                  onChange={e => onChangeVideoEnabled(e.target.checked)}
                  isDisabled={template.presetUsed}
                >
                  ビデオ通話を使用する
                </Checkbox>
              </VideoPossibilitySelect>
            </WrapItem>
          )}

          {showVideoTag && (
            <WrapItem>
              <Badge colorScheme="green">ビデオ通話あり</Badge>
            </WrapItem>
          )}
        </Wrap>

        <Spacer size={10} />

        <ButtonsContainer>
          <FlexItem>
            <PreviewButton
              template={template}
              templateFolderName={templateFolder.name}
            />
          </FlexItem>

          {canSave && (
            <FlexItem>
              <SaveButton
                onClick={onSubmit}
                disabled={!saveButtonEnabled}
              ></SaveButton>
            </FlexItem>
          )}

          {canRelease && (
            <FlexItem>
              <ReleaseButton
                onValidate={onValidate}
                disabled={!releaseButtonEnabled}
              />
            </FlexItem>
          )}

          {template.status === TemplateStatus.Released && !v2 && (
            <FlexItem>
              <Button variant="outline" onClick={onSubmitOrder}>
                順番を保存
              </Button>
            </FlexItem>
          )}

          <FlexItem>
            <PulldownContainer
              status={template.status}
              templateUid={templateUid}
              v2={v2}
            />
          </FlexItem>
        </ButtonsContainer>
      </TopContainer>

      <Spacer size={10} />

      {fetched && (
        <>
          <Wrap
            mt="8"
            bg="blue.50"
            px="4"
            py="2"
            spacing="6"
            as="nav"
            aria-label="section navigation"
          >
            {mediumItems.map(mi => (
              <WrapItem key={mi.uid}>
                <HStack pos="relative">
                  <Box
                    as="span"
                    h="20px"
                    w="4px"
                    borderRadius="full"
                    bg="blue.300"
                  />
                  <Link as={HashLink} smooth to={`#${mi.uid}`} color="blue.500">
                    {mi.title}
                  </Link>
                </HStack>
              </WrapItem>
            ))}
          </Wrap>

          <Spacer size={16} />

          <MediumItemsContainer>
            <Stack spacing="5">
              {mediumItems.map((mi, i) => (
                <GroupComponent
                  key={mi.uid}
                  index={i}
                  mediumItem={mi}
                  releaseTried={releaseTried}
                  orderEditable={canEditOrder}
                  templateStatus={template.status}
                />
              ))}
              {canEdit && <AddMediumItem onAdd={onAdd} />}
            </Stack>
          </MediumItemsContainer>
        </>
      )}
    </Box>
  )
}

type SaveButtonProps = {
  onClick: () => void
  disabled: boolean
}
const SaveButton: React.FC<SaveButtonProps> = ({ onClick, disabled }) => {
  return (
    <Button onClick={onClick} variant="outline" isDisabled={disabled}>
      一時保存
    </Button>
  )
}

const TitleInput = styled(AutosizeInput)`
  resize: horizontal;
  max-width: calc(100% - 32px); // 32px = The width of pen-icon

  input {
    max-width: 100%;
    font-family: inherit;
    color: ${colors.dark};
    padding: 2px;
    border: 1px solid;
    outline: none;
    border-color: transparent;
    font-weight: bold;
    background-color: ${white};

    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &:focus {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  border: none;
  outline: none;

  &.error {
    input {
      background: ${colors.redLighten5};
      border-color: ${colors.redLighten3};
      box-shadow: 0 0 2px ${colors.redDarken4};
      min-width: 35px;
    }
  }
`

const VideoPossibilitySelect = styled.div``

const SCROLL_BAR_WIDTH = 6
const MediumItemsContainer = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 ${16 - SCROLL_BAR_WIDTH}px 0 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > div:not(:last-child) {
    margin-right: 12px;
  }
`

const TopContainer = styled.div`
  padding: 0 16px;
`
