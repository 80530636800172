import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetch as fetchQuestionnaireScheduleList } from 'src/modules/entities/questionnaire-schedule/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { routes } from 'src/modules/routes'

import { actions } from './redux'

export const useFetch = ({ trialUid }: { trialUid: string }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetched, setFetched] = useState(false)

  const { showError } = useFlash()

  useEffect(() => {
    const fetch = async () => {
      setFetched(false)
      try {
        const { questionnaires, schedules } =
          await fetchQuestionnaireScheduleList(trialUid)

        dispatch(
          actions.initQuestionnaireSchedule({ questionnaires, schedules }),
        )

        setFetched(true)
      } catch (error) {
        if (error?.original?.response?.status === 403) {
          navigate(routes.httpError403)
          return
        }
        showError(error.message)
        setFetched(true)
      }
    }

    fetch()
  }, [dispatch, navigate, showError, trialUid])

  return {
    fetched,
  }
}
