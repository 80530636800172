// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgSortAscend = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeWidth={2}
      d="M11.5 1.918v7.179"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M8.676 6.148a1 1 0 1 0-1.352 1.473l1.352-1.473Zm2.824 3.95-.676.736a1 1 0 0 0 1.352 0l-.676-.737Zm4.176-2.477a1 1 0 1 0-1.352-1.473l1.352 1.473Zm-8.352 0 3.5 3.213 1.352-1.473-3.5-3.213-1.352 1.473Zm4.852 3.213 3.5-3.213-1.352-1.473-3.5 3.213 1.352 1.473Z"
    />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 2.213h2M1 5.884h4M1 9.556h6M1 13.227h8"
    />
  </svg>
)
export default SvgSortAscend
