import { Gender } from 'src/modules/entities/patient/entity'
import {
  TemplateResponse,
  parse as parseTemplate,
} from 'src/modules/entities/template/request'
import {
  parse as worksheetParse,
  WorksheetResponse,
} from 'src/modules/entities/worksheet/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import {
  Patient,
  SmallItemAnswerLog,
  VideoCall,
  VideoCallCapture,
  WorksheetPDFData,
} from './entity'
import { fixture } from './fixture'
import {
  MediumItemAnswerLogResponse,
  parse as parseMediumItemAnswerLog,
} from '../../../medium-item-answer-log/request'

export type WorksheetPDFDataResponse = {
  uid: string
  trial_name: string
  worksheet: WorksheetResponse
  worksheetLog: WorksheetLogResponse
  patient: PatientResponse
  small_item_answer_logs: SmallItemAnswerLogResponse[]
  video_calls: VideoCallResponse[]
  logline_disable_logs: MediumItemAnswerLogResponse[]
  template: TemplateResponse
  folder_name: string
}

type PatientResponse = {
  uid: string
  disease_uid: string
  first_name: string
  first_name_ja: string
  last_name: string
  last_name_ja: string
  gender: Gender
  birthday: string
}

type WorksheetLogResponse = {
  action: number
  created_at: string
  reason: string
  uid: string
  user_name: string
}

type SmallItemAnswerLogResponse = {
  uid: string
  small_item_answer_uid: string
  user_name: string
  old_value: string
  new_value: string
  reason: string
  title: string
  section_name: string
  line_number?: number
  created_at: string
}

type VideoCallResponse = {
  uid: string
  caller_name: string
  finished_at: string
  created_at: string
  images: VideoCallCaptureResponse[]
}

type VideoCallCaptureResponse = {
  uid: string
  name: string
  url: string
}

const parse = (resp: WorksheetPDFDataResponse): WorksheetPDFData => {
  return {
    uid: resp.uid,
    trialName: resp.trial_name,
    worksheet: worksheetParse(resp.worksheet),
    worksheetLog: resp.worksheetLog,
    patient: patientParse(resp.patient),
    smallItemAnswerLogs: resp.small_item_answer_logs.map(sial =>
      smallItemAnswerLogParse(sial),
    ),
    videoCalls: resp.video_calls.map(parseVideoCall),
    loglineDisableLogs: resp.logline_disable_logs.map(parseMediumItemAnswerLog),
    template: parseTemplate(resp.template),
    templateFolderName: resp.folder_name,
  }
}

export const patientParse = (resp: PatientResponse): Patient => {
  return {
    uid: resp.uid,
    diseaseUid: resp.disease_uid,
    firstName: resp.first_name,
    firstNameJa: resp.first_name_ja,
    lastName: resp.last_name,
    lastNameJa: resp.last_name_ja,
    gender: resp.gender,
    birthday: resp.birthday,
  }
}

export const smallItemAnswerLogParse = (
  resp: SmallItemAnswerLogResponse,
): SmallItemAnswerLog => {
  return {
    uid: resp.uid,
    smallItemAnswerUid: resp.small_item_answer_uid,
    userName: resp.user_name,
    oldValue: resp.old_value,
    newValue: resp.new_value,
    reason: resp.reason,
    title: resp.title,
    sectionName: resp.section_name,
    lineNumber: resp.line_number,
    createdAt: resp.created_at,
  }
}

export const parseVideoCall = (resp: VideoCallResponse): VideoCall => {
  return {
    uid: resp.uid,
    callerName: resp.caller_name,
    finishedAt: resp.finished_at,
    createdAt: resp.created_at,
    images: resp.images.map(parseVideoCallCapture),
  }
}

const parseVideoCallCapture = (
  resp: VideoCallCaptureResponse,
): VideoCallCapture => {
  return {
    uid: resp.uid,
    name: resp.name,
    url: resp.url,
  }
}

export const fetch = async ({
  trialUid,
  patientUid,
  worksheetUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}): Promise<WorksheetPDFData> => {
  if (IS_MOCK_MODE) {
    return fixture
  }
  const res = await requestGet<WorksheetPDFDataResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/pdf_data`,
    params: {},
  })

  return parse(res.data)
}
