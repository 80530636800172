import React from 'react'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { actions as flashActions } from 'src/modules/flash/redux'

import { submitReInvite } from './request'
import { trialIdParamName } from '../../trial-detail'

type Props = {
  memberUid: string
}

export const ReInvitationButton: React.FC<Props> = props => {
  const { memberUid } = props

  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()

  const dispatch = useDispatch()

  const onSubmit = async () => {
    await submitReInvite({ memberUid, trialUid })

    const message = '招待メールを再送信しました'
    dispatch(flashActions.showSuccess({ message }))
  }

  return (
    <Button
      text="再送信"
      size="S"
      buttonType="normal"
      iconName="email"
      onClick={onSubmit}
    />
  )
}
