import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { fetchQuestionnaireList } from 'src/modules/entities/questionnaire/redux'
import { QuestionnaireListRequest } from 'src/modules/entities/questionnaire/request'
import { getList as getQuestionnaireList } from 'src/modules/entities/questionnaire/selector'
import { routes } from 'src/modules/routes'

import { List } from './list'

type Props = {}

// TODO: refactor into hooks
export const useFetchList = ({ trialUid }: { trialUid: string }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      setFetched(false)
      try {
        const params: QuestionnaireListRequest = {}
        await dispatch(fetchQuestionnaireList({ trialUid, params }))
        setFetched(true)
      } catch (error) {
        //TODO: login key?
        console.error('QuestionnaireActions.fetch Error: ', error)
        error.original.response.status === 403
          ? navigate(routes.httpError403)
          : navigate(`${routes.requiredLogin}`)
      }
    }

    fetch()
  }, [dispatch, navigate, setFetched, trialUid])

  return {
    fetched,
  }
}

export const ListContainer: React.FC<Props> = props => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const trialUid = selectedTrial.uid
  const { fetched } = useFetchList({ trialUid })
  const questionnaires = useSelector(getQuestionnaireList)
  const { hasPermission } = usePermission()

  if (!fetched) {
    return null
  }

  return (
    <List
      questionnaires={questionnaires}
      showAddButton={
        hasPermission(PERMISSIONS.Questionnaire_Create) &&
        !selectedTrial.questionnaireScheduleReleased
      }
      trialUid={trialUid}
    />
  )
}
