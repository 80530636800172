import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import {
  create,
  ScheduleCreateRequest,
} from 'src/modules/entities/schedule/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { actions } from '../../redux'

export const useSubmitRegister = () => {
  const dispatch = useDispatch()
  const trialUid = useSelector(getSelectedTrial)!.uid
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const { showSuccess } = useFlash()
  const request = useCallback(
    async (params: ScheduleCreateRequest) => {
      try {
        requestStarted()

        const newSchedule = await create(trialUid, params)
        dispatch(actions.updateSchedules(newSchedule))

        requestDone()
        showSuccess(`回答日を追加しました。`)
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [
      dispatch,
      requestDone,
      requestFailed,
      requestStarted,
      showSuccess,
      trialUid,
    ],
  )
  return { request, requesting, errorMessage }
}
