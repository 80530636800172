import React, { useState } from 'react'

import { MfaConfirmationModal } from 'src/modules/auth/mfa/mfa-confirmation-modal'
import { MfaDisableModal } from 'src/modules/auth/mfa/mfa-disable-modal'
import { MfaModal } from 'src/modules/auth/mfa/mfa-modal'

interface IProps {
  onClose: () => void
  QRSecretKey: string
  withMfa: boolean
}

export const MfaModalContainer: React.FC<IProps> = ({
  onClose,
  QRSecretKey,
  withMfa,
}) => {
  const [confirm, setConfirm] = useState(false)

  if (withMfa && !confirm) return <MfaDisableModal onClose={onClose} />

  if (confirm) return <MfaConfirmationModal onClose={onClose} />

  return (
    <MfaModal
      onClose={onClose}
      QRSecretKey={QRSecretKey}
      onConfirm={() => setConfirm(true)}
    />
  )
}
