export const getDetailRoute = (args: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}) => {
  const { trialUid, patientUid, worksheetUid } = args

  return `/dashboard/trials/${trialUid}/patients/${patientUid}/worksheet/${worksheetUid}`
}

export const getLoglineRoute = (args: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  loglineUid: string
}) => {
  const { trialUid, patientUid, worksheetUid, loglineUid } = args

  return `/dashboard/trials/${trialUid}/patients/${patientUid}/worksheet/${worksheetUid}/loglines/${loglineUid}`
}

export const emptyPath = 'no-template'
export const getEmptyRoute = (args: {
  trialUid: string
  patientUid: string
}) => {
  const { trialUid, patientUid } = args

  return `/dashboard/trials/${trialUid}/patients/${patientUid}/worksheet/${emptyPath}`
}
