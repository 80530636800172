import { useEffect, useRef } from 'react'

import Div100vh from 'react-div-100vh'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { patientUidParamName } from './doctor-container'
import { SERVER_URL } from '../server/const'

export const peerIdParamName = 'peerId'
export const trialUidParamName = 'trialUid'

// FOR DEBUG
// const baseUrl = 'http://localhost:9101/'
// const patientUid = 'bMJ-tZ5KmK3fgANAtoa3KA7N0GQGJ_UmFAsiFWBCanc'
// const accountUid = 'mQng216ORGFf_-qHcLpnDWiYJIx8OAAUw-05j09hLag'

const baseUrl = SERVER_URL + '/'
const accountUid = 'account_uid'

export const PatientVideoCallContainer = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/call-elements-standalone-0.0.13.js'
    script.type = 'module'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const {
    trialUid = '',
    peerId = '',
    patientUid = '',
  } = useParams<{
    [trialUidParamName]: string
    [peerIdParamName]: string
    [patientUidParamName]: string
  }>()

  const el = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (!el.current) {
      return
    }

    setTimeout(() => {
      ;(el.current as any).accept()
    }, 2000)
  }, [el])

  return (
    <Container>
      <curon-patient-call
        ref={el}
        account-uid={accountUid}
        patient-uid={patientUid}
        video-call-uid={peerId}
        api-base-url={baseUrl}
        trial-uid={trialUid}
      ></curon-patient-call>
    </Container>
  )
}

const Container = styled(Div100vh)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`
