import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { MultipleSelectboxItem } from 'src/components/form-redesigned/multiple-selectbox'
import { MultiselectFilter } from 'src/components/form-redesigned/multiselect-filter'
import { Selectbox } from 'src/components/form-redesigned/selectbox'
import { Spacer } from 'src/components/spacer/spacer'
import { shouldSelectHospital } from 'src/modules/dashboard/trial/detail/common/permission'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import { Member, Role } from 'src/modules/entities/member/entity'
import { actions as memberActions } from 'src/modules/entities/member/redux'
import { roleOptions } from 'src/modules/entities/member/util'
import { actions as flashActions } from 'src/modules/flash/redux'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'
import * as yup from 'yup'

import { useReactHookFormDevTools } from '../../../../../../../hooks/use-react-hook-form-dev-tools'
import { submitUpdate } from '../request'

type Props = {
  member: Member
  onSubmit: (values: FormValues) => void
  onClose: () => void
  selectedTrial: SelectedTrial
}

const validationSchema = yup.object().shape({
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  email: yup
    .string()
    .max(128, '128字以内で入力してください')
    .email('正しい形式のメールアドレスを入力してください')
    .required('メールアドレスは必須です'),
  role: yup
    .mixed<Role>()
    .oneOf(Object.values(Role) as Role[])
    .required(),
  hospitals: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    )
    .required(),
})

export type FormValues = yup.InferType<typeof validationSchema>

export const EditModal: React.FC<Props> = props => {
  const { selectedTrial, member } = props
  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()
  const memberUid: string = member ? member.uid : ''
  const selectedHospitals = member.trialHospitals || {}
  const initialSelectedItems = Object.keys(selectedHospitals).map(key => {
    return { value: key, label: selectedHospitals[key] }
  })
  const [hasHospitalError, setHasHospitalError] = useState<boolean>(false)

  const { request, requesting, errorMessage } = useSubmitUpdate()

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      lastName: member.lastName,
      firstName: member.firstName,
      email: member.email,
      hospitals: initialSelectedItems,
      role: member.role,
    },
  })
  const {
    register,
    setValue,
    setError,
    trigger,
    getValues,
    watch,
    control,
    formState: { errors },
  } = methods
  const devToolElement = useReactHookFormDevTools(control)

  const convertedItems: MultipleSelectboxItem[] =
    selectedTrial.trialHospitals.map(hospital => {
      return {
        value: hospital.uid,
        label: hospital.name,
      }
    })

  useEffect(() => {
    register('role', { required: true })
    register('hospitals', { required: true })
  })

  const onChange = (key: keyof FormValues, value: number) => {
    if (!value) {
      setError(key, { type: 'required' })
    }
    setValue(key, value, { shouldValidate: true, shouldDirty: true })
    trigger()
  }

  const onSubmit = async (value: FormValues) => {
    if (
      shouldSelectHospital(getValues().role) &&
      getValues().hospitals === null
    ) {
      setHasHospitalError(true)
      return
    }

    const hospitals = !getValues().hospitals ? [] : getValues().hospitals

    let values: FormValues = {
      ...value,
      hospitals,
    }

    // emailの改竄防止
    if (!!member.registeredAt) values = { ...values, email: member.email }

    try {
      await request({ trialUid, memberUid, values })
      onClose()
    } catch (error) {
      console.warn(error)
    }
  }

  const onClose = () => {
    if (requesting) return

    props.onClose()
  }

  const shouldShowError = (...fields: Array<keyof FormValues>) =>
    fields.some(f => errors[f])

  return (
    <>
      {devToolElement}
      <Modal onClose={onClose} size="L">
        <ModalTitle title="アカウント情報を編集" />
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <ModalContent>
            <FormContainer>
              <FormItem>
                <Spacer size={20} />
                <Label>氏名</Label>
                <Spacer size={10} />
                <Input
                  placeholder="姓"
                  width={'150px'}
                  mr={'20px'}
                  {...register('lastName')}
                ></Input>
                <Input
                  placeholder="名"
                  width={'150px'}
                  {...register('firstName')}
                ></Input>
                {shouldShowError('firstName', 'lastName') && (
                  <div>
                    <Spacer size={4} />
                    <Message type="error" message="氏名の入力が必要です" />
                  </div>
                )}
              </FormItem>

              <FormItem>
                <Spacer size={20} />
                <Label htmlFor="settings__member__edit-modal__email">
                  メールアドレス
                </Label>
                <Spacer size={10} />
                <Input
                  type="email"
                  placeholder="example@miroha.co"
                  width={'320px'}
                  readOnly={!!member.registeredAt}
                  id="settings__member__edit-modal__email"
                  {...register('email')}
                />
                {shouldShowError('email') && (
                  <div>
                    <Spacer size={4} />
                    <Message
                      type="error"
                      message={errors.email?.message ?? ''}
                    />
                  </div>
                )}
              </FormItem>
            </FormContainer>

            <FormContainer>
              <FormItem>
                <Spacer size={20} />
                <Label>ロール</Label>
                <Spacer size={10} />
                <Selectbox<Role>
                  items={roleOptions}
                  name="role"
                  selectedValue={watch('role')}
                  onChange={value => onChange('role', Number(value))}
                  width={320}
                />

                {shouldShowError('role') && (
                  <div>
                    <Spacer size={4} />
                    <Message type="error" message="ロールの選択が必要です" />
                  </div>
                )}
              </FormItem>
            </FormContainer>

            <FormContainer>
              <FormItem>
                <Spacer size={20} />
                <Label htmlFor="settings__member__edit-modal__hostitals">
                  医療機関
                </Label>
                <Spacer size={10} />
                <Controller
                  name="hospitals"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <MultiselectFilter
                        id="settings__member__edit-modal__hostitals"
                        // MultipleSelectboxItem から MultiselectFilter の内部のデータ形式に変換
                        items={selectedTrial.trialHospitals.map(hospital => {
                          return {
                            id: hospital.uid,
                            value: hospital.name,
                          }
                        })}
                        selectedItemIds={watch('hospitals').map(hospital => {
                          return hospital.value
                        })}
                        onChange={(ids: string[]) => {
                          onChange(
                            // MultiselectFilter の内部のデータ形式 から MultipleSelectboxItem に変換
                            convertedItems.filter(item => {
                              return ids.includes(item.value)
                            }),
                          )

                          if (!shouldSelectHospital(member.role)) {
                            setHasHospitalError(false)
                          } else if (
                            /* 病院が１つ以上選択されているとき */
                            ids &&
                            Array.isArray(ids) &&
                            ids.length !== 0
                          ) {
                            setHasHospitalError(false)
                          } else {
                            setHasHospitalError(true)
                          }
                        }}
                      ></MultiselectFilter>
                    )
                  }}
                ></Controller>
                {
                  // TODO(nekootoko3): use shouldShowError
                  hasHospitalError && (
                    <div>
                      <Spacer size={4} />
                      <Message
                        type="error"
                        message="医療機関の選択が必要です"
                      />
                    </div>
                  )
                }
              </FormItem>
            </FormContainer>
          </ModalContent>

          <ModalActions>
            <Button
              size="S"
              text="キャンセル"
              buttonType="cancel"
              onClick={onClose}
            ></Button>
            <Spacer size={40} horizontal />
            <Button
              size="S"
              text="編集内容を確定"
              onClick={() => methods.handleSubmit(onSubmit)}
              buttonType="important"
              disabled={requesting}
            ></Button>
          </ModalActions>
          {errorMessage && (
            <Message type="error" message={errorMessage} centered />
          )}
        </form>
      </Modal>
    </>
  )
}

const useSubmitUpdate = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const dispatch = useDispatch()

  const request = async ({
    trialUid,
    memberUid,
    values,
  }: {
    trialUid: string
    memberUid: string
    values: FormValues
  }) => {
    try {
      requestStarted()
      const updated = await submitUpdate({ trialUid, memberUid, values })

      dispatch(memberActions.upsert(updated))

      const message = 'アカウント情報を変更しました'
      dispatch(flashActions.showSuccess({ message }))
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const FormItem = styled.div`
  position: relative;
`
