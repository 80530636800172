import { useCallback, useMemo } from 'react'

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { sortByDate } from 'src/modules/util/sort'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useRepeatableSectionHistories } from '../../api/getRepeatableSectionHistories'
import { Worksheet } from '../../types'

type Props = {
  isOpen: boolean
  onClose: () => void
  fid: string
  worksheetUid: Worksheet['uid']
  fieldName: string
}

export const RepeatableSectionHistoryDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  fid,
  worksheetUid,
  fieldName,
}) => {
  const { data: histories } = useRepeatableSectionHistories({
    shouldCancel: !isOpen,
    worksheetUid: worksheetUid,
    fid,
  })

  const sortedHistories = useMemo(
    () =>
      sortByDate(
        histories ?? [],
        history => history.worksheetLog.savedAt,
        'desc',
      ),
    [histories],
  )

  // 操作の項目に「追加」を表示するべき行のworksheetFieldValueUidのSet
  const firstFieldValueUids = useMemo(() => {
    const firstFieldValueUidMap = new Map(
      sortedHistories.map(item => [
        item.fieldIndex,
        item.worksheetFieldValueUid,
      ]),
    )
    return new Set(firstFieldValueUidMap.values())
  }, [sortedHistories])

  const getActionText = useCallback(
    (isEnabled: boolean, worksheetFieldValueUid: string) => {
      if (firstFieldValueUids.has(worksheetFieldValueUid)) return '追加'
      return isEnabled ? '有効化' : '無効化'
    },
    [firstFieldValueUids],
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>ログライン操作履歴</DrawerHeader>
        <DrawerBody>
          <Box as="section">
            <TextWithBar>{fieldName}</TextWithBar>
            <TableContainer mt="3">
              <Table>
                <Thead>
                  <Tr>
                    <Th textAlign="left">日時</Th>
                    <Th>操作者</Th>
                    <Th>操作</Th>
                    <Th>備考</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedHistories.map(history => {
                    const { worksheetLog } = history
                    return (
                      <Tr key={history.worksheetFieldValueUid}>
                        <Td>
                          {formatDate(
                            worksheetLog.savedAt,
                            'YYYY/MM/DD (ddd) HH:mm',
                          )}
                        </Td>
                        <Td maxW="240px">
                          <MemberLabel
                            role={worksheetLog.trialMember.role}
                            displayName={getFullName(
                              worksheetLog.trialMember.user,
                            )}
                          />
                        </Td>
                        <Td>{`#${history.fieldIndex}${' '}行の${getActionText(
                          history.isEnabled,
                          history.worksheetFieldValueUid,
                        )}`}</Td>
                        <Td
                          whiteSpace="pre-line"
                          overflowWrap="break-word"
                          minW="240px"
                        >
                          {history.reason}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
