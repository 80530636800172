import produce from 'immer'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { Member } from './entity'
import { fetch } from './request'

const create = actionCreatorFactory('entities/user')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetchList: createAsync<
    {
      trialUid: string
      type: 'all'
    },
    Member[],
    { message: string }
  >('FETCH_LIST', async (params, dispatch, getState) => {
    return await fetch(params)
  }),

  upsert: create<Member>('upsert'),
}

export type State = {
  byId: {
    [uid: string]: Member
  }
}

const initialState: State = {
  byId: {},
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.fetchList.async.done, (state, payload) =>
    produce(state, draft => {
      payload.result.forEach(p => {
        draft.byId[p.uid] = p
      })
    }),
  )
  .case(actions.upsert, (state, payload) =>
    produce(state, draft => {
      const member = payload
      draft.byId[member.uid] = member
    }),
  )
  .build()
