import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { actions as patientActions } from 'src/modules/entities/patient/redux'
import { getSingle } from 'src/modules/entities/patient/selector'

import { RootState } from './../../../../../reducer'

export const useFetch = ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}) => {
  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()
  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )

  useEffect(() => {
    const fetch = async () => {
      if (!patient) {
        await dispatch(patientActions.fetch({ trialUid, patientUid }))
      }

      setFetched(true)
    }

    fetch()
  }, [dispatch, patient, patientUid, trialUid])

  return {
    fetched,
  }
}
