import React from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSingle } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { QuestionnaireAnswerPage } from './answer'
import { QuestionnaireAnswerResponse, fetchAnswers } from './request'
import { trialIdParamName } from '../../../../../../../epro/trial/epro-page'
import { patientIdParamName } from '../../router'

type Props = {}

export const QuestionnaireAnswerContainer: React.FC<Props> = props => {
  const { trialUid = '', patientUid = '' } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
  }>()
  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )!

  const { hasPermission } = usePermission()

  const { data, error } = useSWR<QuestionnaireAnswerResponse, Error>(
    swrKeys.fetchQuestionnaireAnswers({ trialUid, patientUid }),
    () => fetchAnswers(trialUid, patientUid),
  )

  if (error) return <>{error.message}</>
  if (!data) return null

  return (
    <QuestionnaireAnswerPage
      patient={patient}
      schedules={data.schedules}
      questionnaires={data.questionnaires}
      questionnaireAnswers={data.questionnaireAnswers}
      showQuestionnaireAnswers={hasPermission(
        PERMISSIONS.Patient_ReadQuestionnaireAnswers,
      )}
    />
  )
}
