import { definitions } from 'src/lib/api-client/schema.gen'

import { SmallItem } from './entity'
import { parse as parseChoice } from '../choice/request'

export const parse = (
  resp: definitions['output.SmallItemResponse'],
): SmallItem => {
  return {
    uid: resp.uid,
    title: resp.title,
    value: resp.value,
    description: resp.description,
    index: resp.index,
    itemType: resp.item_type,
    dataType: resp.data_type,
    edcDataItem: resp.edc_data_item,
    unit: resp.unit,
    viewableHospitalUids: resp.viewable_hospital_uids,
    unsaved: false,

    inputRoles: resp.input_roles,
    mediumItemUid: resp.medium_item_uid,
    parentUid: resp.parent_uid,
    isCertifiedCopy: resp.is_certified_copy,

    choices: resp.choices.map(parseChoice),
    presetUsed: resp.preset_used,
  }
}
