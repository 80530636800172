import { ComponentStyleConfig } from '@chakra-ui/react'

export const Text: ComponentStyleConfig = {
  baseStyle: {
    textAlign: 'start',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
    lineHeight: '1.5',
  },
}
