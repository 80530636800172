import {
  FieldInput,
  GqlError,
  GetFileFieldDownloadLogsQuery,
  GetFileFieldDownloadLogsDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { worksheetCacheKey } from '../cacheKey'

type DownloadLogs = GetFileFieldDownloadLogsQuery['field']['fileDownloadLogs']

const getFileFieldDownloadLogs = async (
  input: FieldInput,
): Promise<DownloadLogs> => {
  try {
    const res = await graphqlRequest(GetFileFieldDownloadLogsDocument, {
      input,
    })
    return res.field.fileDownloadLogs
  } catch (error) {
    throw error
  }
}

export const useFileFieldDownloadLogs = ({
  input,
  ...option
}: {
  input: FieldInput | null
} & SWRConfiguration<DownloadLogs, GqlError>) => {
  return useSWR(
    input === null
      ? null
      : worksheetCacheKey.getFileFieldDownloadLogs(
          input.worksheetUid,
          input.fid,
          input.fieldIndex,
        ),
    () => {
      if (input === null) {
        throw new Error('input is null')
      }
      return getFileFieldDownloadLogs(input)
    },
    option,
  )
}
