import { useCallback, useMemo, useState } from 'react'

import { GetPatientReturn } from 'src/features/patient/api'
import { generateUUID } from 'src/utils/generateUUID'
import { groupBy } from 'src/utils/groupBy'

import { validateWorksheetNameLength } from '../utils/validateNameLength'

type AddFollowUpWorksheetParam = {
  id: string
  index: number
  name: string
  touched: boolean
}

const byIndexAsc = (
  a: AddFollowUpWorksheetParam,
  b: AddFollowUpWorksheetParam,
) => a.index - b.index

export const useAddFollowUpWorksheetParam = ({
  fetchedFollowUps,
}: {
  fetchedFollowUps: GetPatientReturn['observationFollowUps'] | undefined
}) => {
  const [paramsBySchemaUid, setParamsBySchemaUid] = useState<
    Map<string, AddFollowUpWorksheetParam[]>
  >(new Map())

  // schemaUidごとに存在しているワークシートの数を保持する
  const existingWorksheetCountBySchemaUid = useMemo(() => {
    const worksheets =
      fetchedFollowUps?.flatMap(followUp => followUp.worksheets) ?? []
    const worksheetsBySchemaUid = groupBy(worksheets, w => w.worksheetSchemaUid)
    return new Map(
      Object.entries(worksheetsBySchemaUid).map(([k, v]) => [k, v.length]),
    )
  }, [fetchedFollowUps])

  const getIndex = useCallback(
    (schemaUid: string, paramSize: number) => {
      const existingCount =
        existingWorksheetCountBySchemaUid.get(schemaUid) ?? 0
      return existingCount + paramSize + 1
    },
    [existingWorksheetCountBySchemaUid],
  )

  const onAddParam = useCallback(
    (schemaUid: string) => {
      setParamsBySchemaUid(prev => {
        const newMap = new Map(prev)
        const params = newMap.get(schemaUid) ?? []
        const index = getIndex(schemaUid, params.length)
        const newParams = [
          ...params,
          { id: generateUUID(), index, name: '', touched: false },
        ].sort(byIndexAsc)
        return newMap.set(schemaUid, newParams)
      })
    },
    [getIndex],
  )

  const onChangeName = useCallback(
    ({
      schemaUid,
      id,
      name,
    }: {
      schemaUid: string
      id: string
      name: string
    }) => {
      setParamsBySchemaUid(prev => {
        const params = prev.get(schemaUid) ?? []
        const target = params.find(param => param.id === id)
        if (!target) return prev
        const newMap = new Map(prev)
        newMap.set(
          schemaUid,
          [
            ...params.filter(p => p.id !== id),
            { ...target, touched: true, name },
          ].sort(byIndexAsc),
        )
        return newMap
      })
    },
    [],
  )

  const onDeleteParam = useCallback(
    ({ schemaUid, id }: { schemaUid: string; id: string }) => {
      setParamsBySchemaUid(prev => {
        const params = prev.get(schemaUid) ?? []
        const target = params.find(p => p.id === id)
        if (!target) return prev
        const newMap = new Map(prev)
        // 抜けた分のindexを詰めて連番になるように採番し直す
        const newParams = params
          .filter(p => p.id !== target.id)
          .sort(byIndexAsc)
          .map((p, i) => ({ ...p, index: getIndex(schemaUid, i) }))
        return newMap.set(schemaUid, newParams)
      })
    },
    [getIndex],
  )

  const getParams = useCallback(
    (schemaUid: string) => {
      const params = paramsBySchemaUid.get(schemaUid) ?? []
      return params
    },
    [paramsBySchemaUid],
  )

  const reset = useCallback(() => {
    setParamsBySchemaUid(new Map())
  }, [])

  const nameLengthErrors: Record<AddFollowUpWorksheetParam['id'], string> =
    useMemo(() => {
      const params = Array.from(paramsBySchemaUid.values()).flat()
      const errors: Record<AddFollowUpWorksheetParam['id'], string> = {}
      params.forEach(param => {
        if (!param.touched) return
        const error = validateWorksheetNameLength(param.name)
        if (error.isValid) {
          delete errors[param.id]
        } else {
          errors[param.id] = error.errorMessage
        }
      })
      return errors
    }, [paramsBySchemaUid])

  const isValid = useMemo(() => {
    const params = Array.from(paramsBySchemaUid.values()).flat()
    return (
      params.length > 0 &&
      params.every(param => param.name !== '') &&
      Object.keys(nameLengthErrors).length === 0
    )
  }, [paramsBySchemaUid, nameLengthErrors])

  return {
    onAddParam,
    onChangeName,
    onDeleteParam,
    reset,
    getParams,
    isValid,
    nameLengthErrors,
  }
}
