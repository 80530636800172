import React from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { CancelCrfDownloadModal } from './modal'

type Props = {
  crfDownloadJobUid: string
}

export const CancelCrfDownloadButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <>
      <Button
        size="S"
        text="処理を中止"
        onClick={openModal}
        buttonType="normal"
      />

      {modalOpen && (
        <CancelCrfDownloadModal
          onClose={closeModal}
          crfDownloadJobUid={props.crfDownloadJobUid}
        />
      )}
    </>
  )
}
