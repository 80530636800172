import produce from 'immer'
import { Patient, PatientEProStatus } from 'src/modules/entities/patient/entity'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { fetch, eproUpdate, PatientEproUpdateRequest } from './request'
import { actions as patientListActions } from '../../dashboard/trial/detail/patient/list/redux'

const create = actionCreatorFactory('entities/patient')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetch: createAsync<
    {
      trialUid: string
      patientUid: string
    },
    Patient,
    { message: string }
  >('FETCH', async (params, dispatch, getState) => {
    return await fetch(params)
  }),
  eproUpdate: createAsync<
    {
      trialUid: string
      patientUid: string
      params: PatientEproUpdateRequest
    },
    Patient,
    { message: string }
  >('EPRO_UPDATE', async (request, dispatch, getState) => {
    return await eproUpdate({ ...request })
  }),
  updateEProStatus: create<{ uid: string; eproStatus: PatientEProStatus }>(
    'CHANGE_EPRO_STATUS',
  ),
  updateEProPasswordIssued: create<{
    uid: string
    isPasswordIssued: boolean
    eproStatus?: PatientEProStatus
  }>('UPDATE_EPRO_PASSWORD_ISSUED'),
  delete: create<{ uid: string }>('DELETE'),
  flush: create('FLUSH'),
}

export type State = {
  byId: Record<string, Patient>
}

const initialState: State = {
  byId: {},
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.fetch.async.done, (state, payload) =>
    produce(state, draft => {
      const patient = payload.result
      draft.byId[patient.uid] = patient
    }),
  )
  .case(patientListActions.fetch.async.done, (state, payload) =>
    produce(state, draft => {
      payload.result.patients.forEach(p => {
        draft.byId[p.uid] = p
      })
    }),
  )
  .case(actions.updateEProStatus, (state, payload) =>
    produce(state, draft => {
      const patient = draft.byId[payload.uid]
      if (!patient) return

      patient.eproStatus = payload.eproStatus
    }),
  )
  .case(actions.updateEProPasswordIssued, (state, payload) =>
    produce(state, draft => {
      const patient = draft.byId[payload.uid]
      if (!patient) return

      patient.isPasswordIssued = payload.isPasswordIssued
      if (payload.eproStatus != null) {
        patient.eproStatus = payload.eproStatus
      }
    }),
  )
  .case(actions.delete, (state, payload) =>
    produce(state, draft => {
      delete draft.byId[payload.uid]
    }),
  )
  .case(actions.flush, (state, payload) =>
    produce(state, draft => {
      draft.byId = {}
    }),
  )
  .case(actions.eproUpdate.async.done, (state, payload) =>
    produce(state, draft => {
      const patient = payload.result
      draft.byId[patient.uid] = patient
    }),
  )
  .build()
