import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost, requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import {
  CreateIcfDocumentSetItem,
  IcfDocumentSet,
  IcfDocumentSetForList,
  UpdateIcfDocumentSetItem,
} from './entity'
import {
  icfDocumentSetFixture1,
  icfDocumentSetFixture2,
  icfDocumentSetListFixture,
} from './fixture'

export const fetchIcfDocumentSetList = async ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: string
  trialHospitalUid: string
}): Promise<IcfDocumentSetForList[]> => {
  if (IS_MOCK_MODE) {
    return icfDocumentSetListFixture
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets`
  const res = await requestGet<IcfDocumentSetForList[]>({ url })

  return res.data
}

export const fetchIcfDocumentSet = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentSetUid,
  excludeDisabledDocs,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentSetUid: string
  excludeDisabledDocs?: boolean
}): Promise<IcfDocumentSet> => {
  if (IS_MOCK_MODE) {
    return (
      [icfDocumentSetFixture1, icfDocumentSetFixture2].find(
        set => set.uid === icfDocumentSetUid,
      ) ?? icfDocumentSetFixture1
    )
  }

  const qs =
    excludeDisabledDocs != null
      ? `?exclude_disabled_docs=${excludeDisabledDocs}`
      : ''
  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets/${icfDocumentSetUid}${qs}`
  const res = await requestGet<IcfDocumentSet>({ url })

  return res.data
}

export const createIcfDocumentSet = async ({
  trialUid,
  trialHospitalUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  params: CreateIcfDocumentSetItem
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets`
  await requestPost({ url, params })
}

export const updateIcfDocumentSet = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentSetUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentSetUid: string
  params: UpdateIcfDocumentSetItem
}): Promise<IcfDocumentSet> => {
  if (IS_MOCK_MODE) {
    return icfDocumentSetFixture1
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets/${icfDocumentSetUid}`
  const res = await requestPut<IcfDocumentSet>({ url, params })

  return res.data
}

export const disableIcfDocumentSet = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentSetUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentSetUid: string
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets/${icfDocumentSetUid}/disable`
  await requestPut({ url })
}

export const enableIcfDocumentSet = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentSetUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentSetUid: string
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_sets/${icfDocumentSetUid}/enable`
  await requestPut({ url })
}
