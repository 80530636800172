import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getListRoute } from 'src/modules/dashboard/trial/detail/epro/questionnaire/routes'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { actions } from 'src/modules/entities/questionnaire/redux'
import { deleteQuestionnaire } from 'src/modules/entities/questionnaire/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { questionnaireIdParamName } from '../../../router'

export const useSubmitDelete = () => {
  const trialUid = useSelector(getSelectedTrial)!.uid
  const { questionnaireUid = '' } = useParams<{
    [questionnaireIdParamName]: string
  }>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showSuccess } = useFlash()
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()

  const request = async () => {
    try {
      requestStarted()
      await deleteQuestionnaire(trialUid, questionnaireUid)
      dispatch(actions.delete(questionnaireUid))
      navigate(getListRoute(trialUid))
      requestDone()
      showSuccess('質問票を削除しました。')
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}
