import produce from 'immer'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { Account, SelectedTrial } from './entity'
import { fetchMe, selectTrial } from './request'
import { rotateLoginId } from '../../util/rotate-login-id'

const create = actionCreatorFactory('entities/account')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetchMe: createAsync<null, Account, { message: string }>(
    'FETCH_ME',
    async (_params, _dispatch, _getState) => {
      return await fetchMe()
    },
  ),

  selectTrialHospital: createAsync<
    {
      trialUid: string
      trialHospitalUid?: string
      path?: string
    },
    {},
    { message: string }
  >('SELECT_TRIAL_HOSPITAL', async (params, dispatch, getState) => {
    try {
      await selectTrial({
        trialUid: params.trialUid,
        trialHospitalUid: params.trialHospitalUid,
      })
      rotateLoginId()
      // NOTE(nekootoko3): 治験切替時、現状の state を全て削除したいのでページをリロードしている
      window.location.href = params.path ?? '/dashboard'
    } catch (error) {
      console.error('src/modules/entities/account/redux.ts Error: ', error)
    }
  }),

  enableSelectTrialQuestionnaireScheduleReleased: create(
    'enableSelectTrialQuestionnaireScheduleReleased',
  ),
}

export type State = {
  uid: string
  email: string
  firstName: string
  lastName: string
  hasMultipleMemberships: boolean
  selectedTrial: SelectedTrial | null
}

const initialState: State = {
  uid: '',
  email: '',
  firstName: '',
  lastName: '',
  hasMultipleMemberships: false,
  selectedTrial: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.fetchMe.async.done, (state, payload) =>
    produce(state, draft => {
      const account = payload.result

      draft.uid = account.uid
      draft.email = account.email
      draft.firstName = account.firstName
      draft.lastName = account.lastName
      draft.hasMultipleMemberships = account.hasMultipleMemberships
      draft.selectedTrial = account.selectedTrial
    }),
  )
  .case(actions.selectTrialHospital.async.failed, (state, payload) => {
    return {
      ...state,
      errorMessage: payload.error.message,
    }
  })
  .case(actions.enableSelectTrialQuestionnaireScheduleReleased, state =>
    produce(state, draft => {
      if (!draft.selectedTrial) return
      draft.selectedTrial.questionnaireScheduleReleased = true
    }),
  )
  .build()
