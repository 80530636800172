import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RedirectLogin } from 'src/components/RedirectLogin/RedirectLogin'
import { Spinner } from 'src/components/spinner/spinner'
import { AuthenticatedAccountProvider } from 'src/features/auth/context'
import { useAuth } from 'src/features/auth/hooks'
import { SelectTrialContainer } from 'src/modules/dashboard/select-trial/select-trial-container'

import { routes } from './routes'
import { CompleteDocuSignEditContainer } from './trial/detail/e-consent/icf-document/icf-document/complete-docusign-edit/complete-docusign-edit-container'
import { VideoViewerContainer } from './trial/detail/e-consent/icf-document/icf-document/video-viewer/video-viewer-container'
import { TrialContent } from './trial/trial'
import { useInit } from './use-init'
import { getSelectedTrial } from '../entities/account/selector'

export const DashboardScreen: React.FC = () => {
  const authState = useAuth()

  // Router配下にaccountのRedux storeに依存している箇所が多数あるのでReduxの初期化も行っておく
  // Reduxを剥がすタイミングでこの処理は消す
  const { initialized } = useInit({ cancelRedirect: true })

  const selectedTrial = useSelector(getSelectedTrial)

  if (authState.state === 'Challenging' || !initialized) {
    return <Spinner />
  }

  if (authState.state === 'Failed') {
    return <RedirectLogin />
  }

  if (!authState.account.hasSelectedTrial || !selectedTrial) {
    return <SelectTrialContainer redirectWithPathSelection />
  }

  return (
    <AuthenticatedAccountProvider account={authState.account}>
      <Router />
    </AuthenticatedAccountProvider>
  )
}

const Router = () => {
  return (
    <Routes>
      {/* 動画ビューア */}
      <Route
        path={`${routes.icfDocumentRevisionVideoStream}`}
        element={<VideoViewerContainer />}
      />
      {/* eConsentでDocuSign編集が完了したリダイレクト先 */}{' '}
      <Route
        path={`${routes.completeDocuSign}`}
        element={<CompleteDocuSignEditContainer />}
      />
      <Route path={`${routes.trials}/*`} element={<TrialContent />} />
      <Route path="*" element={<Navigate replace to={`${routes.trials}`} />} />
    </Routes>
  )
}
