import { Fragment } from 'react'

import { Center, Spinner } from '@chakra-ui/react'

import { useUpdateRevisionStatus } from '../../context/ExplanationRoomEvent'
import { useThisSession } from '../../hooks/useThisSession'
import { FinishedScreen } from '../FinishedScreen/FinishedScreen'

type Props = {
  sessionUid: string
  children: React.ReactNode
}

export const PatientSessionChecker: React.FC<Props> = ({
  sessionUid,
  children,
}) => {
  const thisSession = useThisSession({ sessionUid, forPatient: true })

  useUpdateRevisionStatus({
    listener: () => {
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  if (!thisSession.fetched) {
    return (
      <Center w="100vw" h="100vh">
        <Spinner />
      </Center>
    )
  }

  if (thisSession.isFinished) {
    return <FinishedScreen shouldLogout={false} />
  }

  return <Fragment>{children}</Fragment>
}
