import { Questionnaire } from 'src/modules/entities/questionnaire/entity'

export const getListRoute = (trialUid: string) => {
  return `/dashboard/trials/${trialUid}/epro/questionnaires`
}

export const getDetailRoute = (
  trialUid: string,
  questionnaire: Questionnaire,
) => {
  return `/dashboard/trials/${trialUid}/epro/questionnaires/${questionnaire.uid}`
}

export const getScheduleRoute = (trialUid: string) => {
  return `/dashboard/trials/${trialUid}/epro/questionnaires/schedules`
}
