import { useParams, generatePath, useLocation } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'

import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useThisSession } from '../../hooks/useThisSession'
import { SignCompletedContent } from '../SignCompletedContent/SignCompletedContent'
import { SPExplanationRoomLayout } from '../SPExplanationRoomLayout/SPExplanationRoomLayout'
import { SPExplanationVideoCall } from '../SPExplanationVideoCall/SPExplanationVideoCall'

type Param = {
  trialUid: string
  sessionUid: string
  signerActorUid: string
}

export const SPPatientSignCompletedScreen: React.FC = () => {
  const { trialUid = '', sessionUid = '' } = useParams<Param>()
  const thisSession = useThisSession({ sessionUid, forPatient: true })

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  if (!thisSession.fetched) return null

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      isSP
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      sessionUid={sessionUid}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.patient.trialHospitalUid}
    >
      <SPExplanationRoomLayout trialUid={trialUid} sessionUid={sessionUid}>
        <SPExplanationVideoCall
          roomUid={thisSession.videoCallRoomUid}
          candidateId={thisSession.patient.candidateId}
          canShrinkVideoCall
        />
        <SignCompletedContent
          isPatient
          isSP
          sessionUid={sessionUid}
          requestSignRoomEventUid={searchParams.get(RoomEventUidQueryKey) ?? ''}
          roomTopPath={generatePath(Paths.PatientExplanationRoomTop, {
            trialUid,
            sessionUid,
          })}
          signPath={requestSign => {
            return (
              generatePath(Paths.PatientExplanationRoomDocumentSign, {
                trialUid,
                sessionUid: requestSign.sessionUid,
                docRevisionUid: requestSign.docRevUid,
                signerActorUid: requestSign.signerActorUid,
              }) +
              `?${RoomEventUidQueryKey}=${requestSign.explanationRoomEventUid}`
            )
          }}
        />
      </SPExplanationRoomLayout>
    </ExplanationRoomEventProvider>
  )
}
