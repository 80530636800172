// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgNavigateArrow = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#navigate_arrow_svg__a)">
      <path
        fill={props.color ?? 'currentColor'}
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z"
      />
      <path
        fill="#fff"
        d="m12.71 7.302-.71-.71-2.83-2.83a.996.996 0 1 0-1.41 1.41l1.83 1.83H4c-.55 0-1 .45-1 1s.45 1 1 1h5.59l-1.83 1.83a.996.996 0 1 0 1.41 1.41L12 9.412l.71-.71a.996.996 0 0 0 0-1.41v.01Z"
      />
    </g>
    <defs>
      <clipPath id="navigate_arrow_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgNavigateArrow
