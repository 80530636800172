import { useCallback, useMemo, useState } from 'react'

import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { DeeplyPartial } from 'src/utils/deeplyPartial'
import { generateUUID } from 'src/utils/generateUUID'
import { ValidationError } from 'yup'

import {
  ExplanationMembersSchema,
  MemberSchema,
  explanationMembersSchema,
} from '../schema/explanationFormSchema'
import { ExplanationType } from '../types'
import { getIndexFromObjectString } from '../utils/getIndexFromObjectString'

type ExplanationMemberSettingErrors = {
  members?: {
    message?: string
    [index: number]: { message: string }
  }
}

type Props = {
  selectedTrialHospitalUid: string
  explanationType: ExplanationType
  defaultValue: DeeplyPartial<ExplanationMembersSchema>
}

export const useExplanationMemberSetting = ({
  selectedTrialHospitalUid,
  defaultValue,
  explanationType,
}: Props) => {
  const [members, setMembers] =
    useState<DeeplyPartial<ExplanationMembersSchema>>(defaultValue)

  const toast = useMirohaToast()

  const [errors, setErrors] = useState<ExplanationMemberSettingErrors>({})

  const schema = useMemo(
    () =>
      explanationMembersSchema(explanationType).test(
        'member required',
        '担当者は必須です',
        value => !!value && value.length !== 0,
      ),
    [explanationType],
  )

  const memberToAppend: Partial<MemberSchema> = useMemo(
    () => ({
      id: generateUUID(),
      trialHospitalUid: selectedTrialHospitalUid,
      member: undefined,
    }),
    [selectedTrialHospitalUid],
  )

  const appendMember = useCallback(() => {
    setMembers(members => [...members, memberToAppend])
  }, [memberToAppend])

  const removeMember = useCallback((index: number) => {
    setMembers(members => members.filter((_, i) => i !== index))
  }, [])

  const onChange = useCallback(
    (index: number, param: DeeplyPartial<MemberSchema>) => {
      setMembers(members =>
        members.map((member, i) => (i === index ? param : member)),
      )
    },
    [],
  )

  const handleSubmit = useCallback(
    async (onSubmit: (members: ExplanationMembersSchema) => void) => {
      const gotErrors: typeof errors = {}
      try {
        const validated = await schema.validate(members, {
          abortEarly: false,
        })
        await onSubmit(validated)
      } catch (error) {
        if (error instanceof ValidationError) {
          error.inner.forEach(e => {
            if (e.path === undefined) return
            // 複数バリデーションがある場合は最初のエラーのみを表示する
            if (!!gotErrors['members']) return
            if (!e.path.includes('.')) {
              gotErrors['members'] = {
                message: e.message,
              }
              return
            }
            const index = getIndexFromObjectString(e.path)
            if (index === undefined) return

            if (gotErrors['members'] === undefined) gotErrors['members'] = {}
            gotErrors['members'][index] = { message: e.message }
          })
          toast({
            status: 'error',
            title:
              '入力値に誤りがあります。フィールドのエラーを確認してください。',
          })
        }
      } finally {
        setErrors(gotErrors)
      }
    },
    [members, setErrors, schema, toast],
  )

  return {
    members: members,
    appendMember,
    removeMember,
    errors,
    onChange,
    handleSubmit,
  }
}
