import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react'
import { Field, Schema } from '@micin-jp/chicken-schema'
import { isSectionTypeDef } from 'src/lib/chicken-schema/utils'
import { CriteriaStatus } from 'src/lib/gql-client'

import { CriteriaStatusBadge } from '../CriteriaStatusBadge/CriteriaStatusBadge'
import { FieldViewer } from '../FieldViewer/FieldViewer'
import { SectionNavigation } from '../SectionNavigation/SectionNavigation'
import { SectionViewer } from '../SectionViewer/SectionViewer'

type Props = {
  status: CriteriaStatus
  schema: Schema
}

export const ReadOnlySchema: React.FC<Props> = ({ status, schema }) => {
  return (
    <Box>
      <HStack>
        <Text fontWeight="bold">{schema.name}</Text>
        <CriteriaStatusBadge status={status} />
      </HStack>
      <Box mt="6">
        <SectionNavigation />
      </Box>
      <Stack spacing="2" mt="4">
        {schema.fields.map(field => (
          <FieldComponent key={field.fid} field={field} />
        ))}
      </Stack>
    </Box>
  )
}

const FieldComponent: React.FC<{ field: Field }> = ({ field }) => {
  return isSectionTypeDef(field.typeDef) ? (
    <Stack spacing="2" id={field.fid}>
      <SectionViewer field={field} />
      <Flex direction="column" pl="8" gap={4}>
        {field.typeDef.fields.map(f => (
          <FieldComponent key={f.fid} field={f} />
        ))}
      </Flex>
    </Stack>
  ) : (
    <FieldViewer field={field} />
  )
}
