import produce from 'immer'
import { UpdateMediumItemOrderResponse } from 'src/modules/dashboard/trial/detail/template/common/detail/request-update-order'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { Template, TemplateStatus } from './entity'
import { fetchList, fetchSingle } from './request'

const create = actionCreatorFactory('entities/template')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetchList: createAsync<
    {
      trialUid: string
    },
    Template[],
    { message: string }
  >('FETCH_LIST', async (params, dispatch, getState) => {
    return await fetchList(params)
  }),
  fetchSingle: createAsync<
    {
      trialUid: string
      templateUid: string
    },
    Template,
    { message: string }
  >('FETCH_SINGLE', async (params, dispatch, getState) => {
    return await fetchSingle(params)
  }),
  upsert: create<Template>('upsert'),

  updateOrder: create<{ uid: string; res: UpdateMediumItemOrderResponse[] }>(
    'updateOrder',
  ),
  release: create<{ uid: string }>('release'),

  delete: create<{ uid: string }>('delete'),

  disable: create<{ uid: string }>('disable'),

  enable: create<{ uid: string }>('enable'),

  updateTitle: create<{ uid: string }>('updateTitle'),
}

export type State = {
  byId: Record<string, Template>
}

const initialState: State = {
  byId: {},
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.fetchList.async.done, (state, payload) =>
    produce(state, draft => {
      const newTemplates: Record<string, Template> = {}
      payload.result.forEach(t => {
        const ms = state.byId[t.uid] ? state.byId[t.uid].mediumItems : []
        newTemplates[t.uid] = {
          ...t,
          mediumItems: ms,
        }
      })

      draft.byId = newTemplates
    }),
  )
  .case(actions.fetchSingle.async.done, (state, payload) =>
    produce(state, draft => {
      const template = payload.result
      draft.byId[template.uid] = template
    }),
  )
  .case(actions.upsert, (state, payload) =>
    produce(state, draft => {
      const template = payload
      draft.byId[template.uid] = template
    }),
  )
  .case(actions.updateOrder, (state, payload) =>
    produce(state, draft => {
      const template = draft.byId[payload.uid]

      payload.res.forEach(mediumItemOrderResponse => {
        const mediumItem = template.mediumItems!.find(
          mi => mi.uid === mediumItemOrderResponse.medium_item_uid,
        )!
        mediumItem.index = mediumItemOrderResponse.index

        mediumItemOrderResponse.small_items.forEach(smallItemResponse => {
          mediumItem.smallItemMap[smallItemResponse.small_item_uid].index =
            smallItemResponse.index
        })
      })

      template.mediumItems = template.mediumItems!.sort(
        (a, b) => a.index - b.index,
      )
    }),
  )
  .case(actions.release, (state, payload) =>
    produce(state, draft => {
      const template = draft.byId[payload.uid]

      template.status = TemplateStatus.Released
    }),
  )
  .case(actions.delete, (state, payload) =>
    produce(state, draft => {
      delete draft.byId[payload.uid]
    }),
  )
  .case(actions.updateTitle, (state, payload) =>
    produce(state, draft => {
      const template = draft.byId[payload.uid]

      template.status = TemplateStatus.Released
    }),
  )

  .build()
