import { useSelector } from 'react-redux'
import { Permission, PERMISSIONS } from 'src/lib/permission'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { Role } from 'src/modules/entities/member/entity'

export const usePermission = () => {
  const trial = useSelector(getSelectedTrial)
  return {
    hasPermission: (permission: Permission) => {
      return hasTrialPermissionWithTrialConfig(trial, permission)
    },
  }
}

/**
 * TrialConfigも加味した権限チェックを行う
 *
 * ビデオキャプチャ参照についてはTrialConfigの設定値も確認する必要があるため、それも含めてここで確認する
 *
 * @param trial 治験情報
 * @param permission チェックしたい権限
 * @returns 権限あり or なし
 */
const hasTrialPermissionWithTrialConfig = (
  trial: SelectedTrial | null,
  permission: Permission,
) => {
  if (!trial) {
    return false
  }
  if (
    permission === PERMISSIONS.Worksheet_ReadVideoCapture ||
    permission === PERMISSIONS.Patient_DownloadVideoCallCapture
  ) {
    if (!trial.videoCaptureAccessRestricted) {
      return true
    }
  }
  return trial.permissions.includes(permission)
}

export const shouldSelectHospital = (role: Role) => {
  return [Role.Dr, Role.CRC, Role.CRA].includes(role)
}

export const exportedForTesting = {
  hasTrialPermissionWithTrialConfig,
}
