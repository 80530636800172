import { Auth } from 'aws-amplify'
import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { Account, CognitoUser } from '../../types'
import { accountCacheKey } from '../cacheKey'
import { parseAccountResponse } from '../parser'

export const getAccount = async () => {
  try {
    const res = await createApiClient({
      path: '/accounts/user',
      httpMethod: 'get',
    }).request()

    if (res.result === 'error') {
      throw new Error()
    }

    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()

    return parseAccountResponse(res.data, cognitoUser)
  } catch (e) {
    throw new Error('user is not authenticated')
  }
}

export const useAccount = (
  args?: { shouldCancel?: boolean } & SWRConfiguration<Account, Error>,
) => {
  const { shouldCancel, ...options } = args ?? {}
  return useSWR<Account, Error>(
    shouldCancel ? null : accountCacheKey.getAccount,
    () => getAccount(),
    options,
  )
}
