import React from 'react'

import { Link, Text, TextProps } from '@chakra-ui/react'

type Props = {
  children: string
} & TextProps

const urlPattern = /(https?:\/\/[^\s]+)/g

export const Linkify: React.FC<Props> = ({ children: text, ...textProps }) => {
  const parts = text.split(urlPattern).map(part => {
    if (part.match(urlPattern)) {
      return (
        <Link
          href={part}
          key={part}
          fontWeight={textProps.fontWeight}
          isExternal
          color="blue.500"
        >
          {part}
        </Link>
      )
    } else {
      return part
    }
  })
  return (
    <Text as="span" {...textProps}>
      {parts}
    </Text>
  )
}
