import { useEffect, useMemo, useState } from 'react'

import { GetNotificationsQuery } from 'src/lib/gql-client'

import { useGetNotifications } from '../api/getNotifications'

const DEFAULT_NOTIFICATION_PER_NUMBER = 5
const LOAD_NEXT_NOTIFICATION_PER_NUMBER = 10

type NotificationsType = GetNotificationsQuery['notifications']['edges']

export const useNotification = () => {
  const [notifications, setNotifications] = useState<NotificationsType>([])

  const [unreadCount, setUnreadCount] = useState<number>(0)

  const currentCursor = useMemo(() => {
    if (notifications.length === 0) return undefined
    return notifications[notifications.length - 1].cursor
  }, [notifications])

  const { data } = useGetNotifications({
    refreshInterval: 10000,
    after: currentCursor,
    limit:
      currentCursor === undefined
        ? DEFAULT_NOTIFICATION_PER_NUMBER
        : LOAD_NEXT_NOTIFICATION_PER_NUMBER,
  })

  useEffect(() => {
    if (!data) return

    if (data.unreadCount !== unreadCount) {
      setNotifications([])
    }

    setUnreadCount(data.unreadCount)
  }, [data, unreadCount, setUnreadCount, setNotifications])

  const hasNextPage = useMemo(() => {
    if (data === undefined) return false
    return data.pageInfo.hasNextPage
  }, [data])

  const loadNext = () => {
    if (!hasNextPage || !data) return
    setNotifications(prev => [...prev, ...data.edges])
  }

  const allNotifications = useMemo(() => {
    if (data === undefined) return notifications
    return [...notifications, ...data.edges]
  }, [data, notifications])

  return {
    hasNextPage,
    loadNext,
    unreadCount,
    notifications: allNotifications,
  }
}
