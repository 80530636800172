import { Schema } from '@micin-jp/chicken-schema'
import { ActualVisit } from 'src/features/actualVisit/types'
import { parseGqlMember } from 'src/features/member/api/parser'
import { Member } from 'src/features/member/types'
import * as GqlTypes from 'src/lib/gql-client'
import {
  WorksheetDetailFragment,
  WorksheetLogBaseFragment,
  GetWorksheetFieldQuery,
} from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'
import { castUid } from 'src/utils/brandedUid'

import {
  WorksheetDetail,
  Worksheet,
  WorksheetLogWithFieldValue,
  WorksheetStatus,
  FieldValue,
  FieldValueBase,
  FieldValueInput,
  WorksheetLog,
  WorksheetAction,
  WorksheetField,
} from '../types'

/** GraphQLのレスポンスからフロント用の型に変換 */
export const parseGqlWorksheetResponse = (
  res: WorksheetDetailFragment,
): WorksheetDetail => {
  return {
    uid: castUid<Worksheet>(res.worksheetUid),
    name: res.name,
    index: res.index,
    patientObservationUid: castUid<ActualVisit>(res.patientObservationUid),
    schema: JSON.parse(res.worksheetSchema.schemaFile) as Schema,
    status: parseGqlWorksheetStatus(res.latestWorksheetLog.status),
    worksheetLogs: res.worksheetLogs.map(parseGqlWorksheetLog),
    latestWorksheetLog: parseGqlWorksheetLogWithFieldValue(
      res.latestWorksheetLog,
    ),
    fields: res.fields,
  }
}

const parseGqlWorksheetLog = (res: WorksheetLogBaseFragment): WorksheetLog => {
  return {
    uid: castUid<WorksheetLog>(res.worksheetLogUid),
    worksheetUid: castUid<Worksheet>(res.worksheetUid),
    trialMemberUid: castUid<Member>(res.trialMemberUid),
    trialMember: parseGqlMember(res.trialMember),
    savedAt: res.savedAt,
    reason: res.reason ?? undefined,
    status: parseGqlWorksheetStatus(res.status),
    action: parseGqlWorksheetAction(res.action),
  }
}

const parseGqlWorksheetLogWithFieldValue = (
  res: WorksheetDetailFragment['latestWorksheetLog'],
): WorksheetLogWithFieldValue => {
  return {
    ...parseGqlWorksheetLog(res),
    fieldValues: res.fieldValues.map(parseGqlWorksheetFieldValue),
  }
}

export const parseGqlWorksheetStatus = (
  res: GqlTypes.WorksheetStatus,
): WorksheetStatus => {
  switch (res) {
    case 'Created':
      return 'created'
    case 'Saved':
      return 'saved'
    case 'Confirmed':
      return 'confirmed'
    case 'Disabled':
      return 'disabled'
    default:
      return assertNever(res)
  }
}

const parseGqlWorksheetAction = (
  res: GqlTypes.WorksheetAction,
): WorksheetAction => {
  switch (res) {
    case 'Create':
      return 'create'
    case 'Save':
      return 'save'
    case 'Confirm':
      return 'confirm'
    case 'ReEdit':
      return 'reEdit'
    case 'Disable':
      return 'disable'
    case 'Enable':
      return 'enable'
    default:
      return assertNever(res)
  }
}

type FieldValueRes =
  WorksheetDetailFragment['latestWorksheetLog']['fieldValues'][number]

const parseGqlWorksheetFieldValue = (res: FieldValueRes): FieldValue => {
  if ('textValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'text',
      textValue: res.textValue,
    }
  }
  if ('textareaValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'textarea',
      textareaValue: res.textareaValue,
    }
  }
  if ('numberValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'number',
      numberValue: res.numberValue,
    }
  }
  if ('dateValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'date',
      dateValue: res.dateValue,
    }
  }
  if ('nullableDateValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'nullableDate',
      nullableDateValue: res.nullableDateValue,
    }
  }
  if ('selectMenuValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'selectMenu',
      selectMenuValue: res.selectMenuValue,
    }
  }
  if ('checkboxValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'checkbox',
      checkboxValue: res.checkboxValue,
    }
  }
  if ('radioValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'radio',
      radioValue: res.radioValue,
    }
  }
  if ('timeValue' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'time',
      timeValue: res.timeValue,
    }
  }
  if ('isEnabled' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'repeatableSection',
      isEnabled: res.isEnabled,
      isNew: false, // fetchした値は必ずfalse
    }
  }
  if ('files' in res) {
    return {
      ...parseGqlWorksheetFieldBase(res),
      type: 'file',
      files: res.files.map(file => ({
        uploadedFileUID: file.uploadedFile.uid,
        worksheetFieldValueUid: castUid<FieldValue>(
          file.worksheetFieldValueUid,
        ),
        memo: file.memo,
        order: file.order,
        uploadedFile: {
          uid: file.uploadedFile.uid,
          name: file.uploadedFile.name,
          url: file.uploadedFile.url,
          extension: file.uploadedFile.extension,
          size: file.uploadedFile.size,
          savedAt: file.uploadedFile.savedAt,
        },
      })),
    }
  }
  // TODO: __typenameでチェック、assertNeverを利用
  // return assertNever(res)
  return {
    ...parseGqlWorksheetFieldBase(res),
    type: 'cleared',
  }
}

const parseGqlWorksheetFieldBase = (res: FieldValueRes): FieldValueBase => {
  return {
    uid: castUid<FieldValue>(res.worksheetFieldValueUid),
    fid: res.fid,
    index: res.fieldIndex,
  }
}

export const parseGqlWorksheetField = (
  res: GetWorksheetFieldQuery['field'],
): WorksheetField => {
  return {
    fid: res.fid,
    index: res.fieldIndex,
    fieldValues: res.fieldValues.map(value => ({
      ...parseGqlWorksheetFieldValue(value),
      worksheetLog: parseGqlWorksheetLog(value.worksheetLog),
      reason: value.reason,
    })),
  }
}

/** GraphQL用のパラメータの型に変換 */
export const toGqlFieldValueInput = (
  input: FieldValueInput,
): GqlTypes.FieldValueInput => {
  return {
    fid: input.fid,
    fieldIndex: input.index,
    worksheetFieldValueUid: input.uid,
    reason: input.reason,
    ...toSpecifiedGqlFieldValueInput(input),
  }
}

type NotCleared = {
  isCleared: false
}

type SpecifiedFieldValueInput =
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'textValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'textareaValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'numberValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'dateValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'nullableDateValue'> &
      NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'timeValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'checkboxValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'radioValue'> & NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'selectMenuValue'> &
      NotCleared)
  | (Pick<GqlTypes.FieldValueInput, 'fieldType' | 'fileValue'> & NotCleared)
  | (Pick<
      GqlTypes.FieldValueInput,
      'fieldType' | 'repeatableSectionIsEnabled' | 'repeatableSectionIsNew'
    > &
      NotCleared)
  | { isCleared: true } // クリアされた場合

const toSpecifiedGqlFieldValueInput = (
  input: FieldValueInput,
): SpecifiedFieldValueInput => {
  switch (input.type) {
    case 'text':
      return {
        fieldType: 'Text',
        textValue: input.textValue,
        isCleared: false,
      }
    case 'textarea':
      return {
        fieldType: 'Textarea',
        textareaValue: input.textareaValue,
        isCleared: false,
      }
    case 'number':
      return {
        fieldType: 'Number',
        numberValue: input.numberValue,
        isCleared: false,
      }
    case 'date':
      return {
        fieldType: 'Date',
        dateValue: input.dateValue,
        isCleared: false,
      }
    case 'nullableDate':
      return {
        fieldType: 'NullableDate',
        nullableDateValue: input.nullableDateValue,
        isCleared: false,
      }
    case 'time':
      return {
        fieldType: 'Time',
        timeValue: input.timeValue,
        isCleared: false,
      }
    case 'checkbox':
      return {
        fieldType: 'Checkbox',
        checkboxValue: input.checkboxValue,
        isCleared: false,
      }
    case 'radio':
      return {
        fieldType: 'Radio',
        radioValue: input.radioValue,
        isCleared: false,
      }
    case 'selectMenu':
      return {
        fieldType: 'SelectMenu',
        selectMenuValue: input.selectMenuValue,
        isCleared: false,
      }
    case 'file':
      return {
        fieldType: 'File',
        isCleared: false,
        fileValue: input.files.map(file => ({
          memo: file.memo,
          order: file.order,
          uploadFileUid: file.uploadedFile.uid,
        })),
      }
    case 'repeatableSection':
      return {
        fieldType: 'RepeatableSection',
        repeatableSectionIsEnabled: input.isEnabled,
        repeatableSectionIsNew: input.isNew,
        isCleared: false,
      }
    case 'cleared':
      return {
        isCleared: true,
      }

    default:
      return assertNever(input)
  }
}
