import React from 'react'

import { Text } from '@chakra-ui/react'
import OutsideClickHandler from 'react-outside-click-handler'
import { zIndex } from 'src/modules/util/z-index'
import styled from 'styled-components'

import { blue, gray, Palette, white } from '../base/color/palette'

export type PullDownItem = {
  name: string
  onClick: () => void | Promise<void>
  fontColor?: Palette
}

type Props = {
  items: PullDownItem[]
  pulldownOpen: boolean
  closePulldown: () => void
  children?: React.ReactNode
}

const Wrapper = styled.div`
  position: relative;
`

const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  width: 500px; //十分に大きい数字を設定しておく。Dropdownが文字数に応じて可変サイズになるようにするための処理。
  display: flex;
  justify-content: flex-end;
  z-index: ${zIndex.dropDown};
`

const DropDown = styled.div`
  background: ${white};
  margin-left: auto;
  border: 1px solid ${gray[40]};
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`
const DropDownItem = styled.div`
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px dashed ${gray[40]};

  &:hover {
    background-color: ${blue[5]};
  }
  &:active {
    background-color: ${blue[10]};
  }

  &:last-child {
    border-bottom: none;
  }
`

/**
 * @deprecated Use chakra-ui Menu instead.
 */
export const PullDown: React.FC<Props> = props => {
  const { items, closePulldown, pulldownOpen } = props

  const onClick = (item: PullDownItem) => {
    closePulldown()
    item.onClick()
  }

  return (
    <Wrapper>
      {props.children}
      {pulldownOpen && (
        <DropdownContainer>
          <OutsideClickHandler onOutsideClick={closePulldown}>
            <DropDown>
              {items.map(item => (
                <DropDownItem key={item.name} onClick={() => onClick(item)}>
                  <Text fontSize="sm" color={item.fontColor}>
                    {item.name}
                  </Text>
                </DropDownItem>
              ))}
            </DropDown>
          </OutsideClickHandler>
        </DropdownContainer>
      )}
    </Wrapper>
  )
}
