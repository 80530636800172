// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgMovieAreaShrink = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M6.4.4c-.6 0-1 .4-1 1v2.5L1.8.3C1.4-.1.8-.1.4.3c-.4.4-.4 1 0 1.4L4 5.3H1.5c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.1 0 .3 0 .4-.1.2-.1.4-.3.5-.5.1-.1.1-.3.1-.4V1.4c0-.5-.5-1-1-1ZM15.7 14.3l-3.6-3.6h2.5c.6 0 1-.4 1-1s-.4-1-1-1H9.7c-.1 0-.3 0-.4.1-.2.1-.4.3-.5.5-.1.1-.1.3-.1.4v4.9c0 .6.4 1 1 1s1-.4 1-1v-2.5l3.6 3.6c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4Z"
    />
  </svg>
)
export default SvgMovieAreaShrink
