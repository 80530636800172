import React, { useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import { usePane } from 'src/hooks/use-pane'
import { Container as DisableModalContainer } from 'src/modules/dashboard/trial/detail/patient/disable/container'
import { Patient } from 'src/modules/entities/patient/entity'
import { RootState } from 'src/modules/reducer'

import { EditForm, RequestValues } from './form'
import { actions } from '../redux'

type Props = {
  trialUid: string
  patient: Patient
  edcLinked?: boolean
  worksheetUid: string
}

export const EditButtonContainer: React.FC<Props> = props => {
  const { trialUid, patient, edcLinked, worksheetUid } = props
  const { errorMessage, requesting } = useSelector(
    (state: RootState) => state.patient.edit,
  )
  const dispatch = useDispatch()
  const {
    handlers: { openPane, closePane },
    renderPane,
  } = usePane({
    paneType: 'modal',
    closeOnOutsideClick: true,
  })
  const [disableModalOpen, setDisableModalOpen] = useState(false)

  const openDisableModal = () => {
    closePane()
    setDisableModalOpen(true)
  }
  const closeDisableModal = () => {
    setDisableModalOpen(false)
  }

  const onSubmit = async (values: RequestValues) => {
    if (requesting) return

    try {
      await dispatch(
        actions.submit({
          trialUid,
          patientUid: patient.uid,
          patient: values,
        }),
      )
      closePane()
    } catch (error) {
      /* empty */
    }
  }

  return (
    <div>
      <IconButton iconName="edit" onClick={openPane} />

      {renderPane(
        <EditForm
          patient={patient}
          errorMessage={errorMessage || ''}
          requesting={requesting}
          onSubmit={onSubmit}
          closeModal={closePane}
          showModal={openDisableModal}
        />,
      )}
      {disableModalOpen && (
        <DisableModalContainer
          trialUid={trialUid}
          patientUid={patient.uid}
          diseaseUid={patient.diseaseUid}
          onClose={closeDisableModal}
          edcLinked={edcLinked}
          worksheetUid={worksheetUid}
        />
      )}
    </div>
  )
}
