import {
  CheckboxTypeDef,
  currentVersion,
  ErrSchema,
  ErrSchemaDetail,
  Field,
  LabelTypeDef,
  RadioTypeDef,
  RepeatableSectionTypeDef,
  Schema,
  SectionTypeDef,
  SelectMenuTypeDef,
  TypeDef,
} from '@micin-jp/chicken-schema'
import equal from 'fast-deep-equal'
import { assertNever } from 'src/utils/assertNever'
import { generateUUID } from 'src/utils/generateUUID'

export type FlattenEditingFieldMap = Map<string, Field>

export type SchemaStructure = {
  fields: FieldStructure[]
}
export type FieldStructure = {
  fid: string
  fields?: FieldStructure[]
}

export type FieldErrorGroup = Record<string, Record<string, ErrSchemaDetail[]>>

export const emptyField = (override?: Partial<Field>): Field => {
  return {
    fid: generateUUID(),
    name: '',
    typeDef: { type: 'Text' },
    ...override,
  }
}
export const emptySection = (): Field => {
  return emptyField({ typeDef: { type: 'Section', fields: [] } })
}
/**
 * 引数に指定したFieldの配列をフラット化して返す
 *
 * @example
 * const fields = [
 *   { fid: '1', name: 'field1', typeDef: { type: 'Text' } },
 *   {
 *     fid: '2',
 *     name: 'field2',
 *     typeDef: {
 *       type: 'Section',
 *       fields: [
 *         { fid: '3', name: 'field3', typeDef: { type: 'Text' } },
 *         { fid: '4', name: 'field4', typeDef: { type: 'Text' } },
 *       ],
 *     },
 *   },
 * ];
 * const flattenFields = getFlattenFields(fields);
 * // flattenFields => [
 * //   { fid: '1', name: 'field1', typeDef: { type: 'Text' } },
 * //   {
 * //     fid: '2',
 * //     name: 'field2',
 * //     typeDef: {
 * //       type: 'Section',
 * //       fields: [
 * //         { fid: '3', name: 'field3', typeDef: { type: 'Text' } },
 * //         { fid: '4', name: 'field4', typeDef: { type: 'Text' } },
 * //       ],
 * //     },
 * //   },
 * //   { fid: '3', name: 'field3', typeDef: { type: 'Text' } },
 * //   { fid: '4', name: 'field4', typeDef: { type: 'Text' } },
 * // ];
 */
export const getFlattenFields = (fields: Field[]): Field[] => {
  return fields.reduce<Field[]>((acc, f) => {
    acc.push(f)
    if (
      f.typeDef?.type === 'Section' ||
      f.typeDef?.type === 'RepeatableSection'
    ) {
      acc.push(...getFlattenFields(f.typeDef.fields ?? []))
    }
    return acc
  }, [])
}

export const fieldToStructure = (field: Field): FieldStructure => {
  return {
    fid: field.fid,
    fields:
      field.typeDef?.type === 'Section' ||
      field.typeDef?.type === 'RepeatableSection'
        ? (field.typeDef?.fields?.map(f => fieldToStructure(f)) ?? [])
        : undefined,
  }
}

export const buildSchema = (
  name: string,
  structure: SchemaStructure,
  fieldMap: FlattenEditingFieldMap,
): Schema => {
  const buildField = (fieldStructure: FieldStructure): Field => {
    const field = fieldMap.get(fieldStructure.fid)
    if (!field) throw new Error('field not found')
    return {
      ...field,
      typeDef:
        field.typeDef?.type === 'Section' ||
        field.typeDef?.type === 'RepeatableSection'
          ? {
              ...field.typeDef,
              fields: fieldStructure.fields?.map(buildField) ?? [],
            }
          : field.typeDef,
    }
  }
  return {
    schemaVersion: currentVersion,
    name,
    fields: structure.fields.map(buildField),
  }
}

export const parseSchema = (schemaStr: string): Schema => {
  return JSON.parse(schemaStr) as Schema
}

export type FieldOf<T extends TypeDef['type']> = Field & {
  typeDef: Extract<TypeDef, { type: T }>
}

export const typeString = (type: TypeDef['type']) => {
  switch (type) {
    case 'Section':
      return 'セクション'
    case 'RepeatableSection':
      return '繰り返しセクション'
    case 'Radio':
      return '単一選択（ボタン選択）'
    case 'SelectMenu':
      return '単一選択（リスト選択）'
    case 'Checkbox':
      return '複数選択'
    case 'Date':
      return '日時入力（YYYY/MM/DD）'
    case 'NullableDate':
      return '日時入力（YYYY/MM~/DD~）'
    case 'Time':
      return '日時入力（HH:MI）'
    case 'Number':
      return '数値入力'
    case 'Text':
      return 'テキスト入力（単一行）'
    case 'Textarea':
      return 'テキスト入力（複数行）'
    case 'File':
      return 'ファイルアップロード'
    case 'Label':
      return 'テキスト表示'
    default:
      assertNever(type)
  }
}

export type WithChoiceTypeDef =
  | CheckboxTypeDef
  | RadioTypeDef
  | SelectMenuTypeDef

export const isChoiceTypeDef = (
  typeDef: TypeDef,
): typeDef is WithChoiceTypeDef => {
  return (
    typeDef.type === 'Checkbox' ||
    typeDef.type === 'Radio' ||
    typeDef.type === 'SelectMenu'
  )
}
export const isSectionTypeDef = (
  typeDef: TypeDef,
): typeDef is SectionTypeDef | RepeatableSectionTypeDef => {
  return typeDef.type === 'Section' || typeDef.type === 'RepeatableSection'
}

export type HasValidationTypeDef = Exclude<
  TypeDef,
  SectionTypeDef | RepeatableSectionTypeDef | LabelTypeDef
>

export const hasValidationTypeDef = (
  typeDef: TypeDef,
): typeDef is HasValidationTypeDef => {
  const { type } = typeDef
  return type !== 'Section' && type !== 'RepeatableSection' && type !== 'Label'
}

export type NotSectionField = FieldOf<
  Exclude<TypeDef['type'], 'Section' | 'RepeatableSection'>
>

const isValidTypeField = <T extends TypeDef['type']>(
  field: Field,
  type: T,
): field is FieldOf<T> => {
  return field.typeDef.type === type
}
export const withValidateFieldType = <T extends TypeDef['type']>(
  field: Field,
  type: T,
): FieldOf<T> => {
  if (!isValidTypeField(field, type)) {
    throw new Error(`Invalid field type: ${field.typeDef.type}`)
  }
  return field
}
/**
 * エラースキーマをフィールド、パスごとにグルーピングする
 * @param schema
 */
export const groupErrSchema = (schema: ErrSchema): FieldErrorGroup => {
  const result: FieldErrorGroup = {}
  for (const detail of schema.details) {
    if (detail.fid && detail.pathFromField) {
      // "fid"が存在する場合
      const key = detail.fid
      if (!result[key]) result[key] = {}
      const path = detail.pathFromField.join('.')
      if (!result[key][path]) result[key][path] = []
      result[key][path].push(detail)
    } else if (detail.cid && detail.pathFromChoice) {
      // "cid"が存在する場合
      const key = detail.cid
      if (!result[key]) result[key] = {}
      const path = detail.pathFromChoice.join('.')
      if (!result[key][path]) result[key][path] = []
      result[key][path].push(detail)
    } else if (detail.path) {
      // いずれも存在せず、"path"が指定されている場合
      const key = 'root'
      if (!result[key]) result[key] = {}
      const path = detail.path.join('.')
      if (!result[key][path]) result[key][path] = []
      result[key][path].push(detail)
    }
  }
  return result
}

const omitFalsyValidationFromField = (field: Field): Field => {
  if (isSectionTypeDef(field.typeDef)) {
    return {
      ...field,
      typeDef: {
        ...field.typeDef,
        fields: field.typeDef.fields.map(omitFalsyValidationFromField),
      },
    }
  }
  if (!hasValidationTypeDef(field.typeDef)) {
    return field
  }
  if (!field.typeDef.validation) {
    return {
      ...field,
      typeDef: {
        ...field.typeDef,
        validation: undefined,
      },
    }
  }
  // 0や空文字が設定されている場合などは削除しないようundefinedとfalseの場合のみチェック
  if (
    Object.values(field.typeDef.validation).some(v => {
      return v !== undefined && v !== false
    })
  ) {
    return field
  }
  // すべてundefined or falseの場合はvalidationを削除（undefinedにする）
  return {
    ...field,
    typeDef: {
      ...field.typeDef,
      validation: undefined,
    },
  }
}

/**
 * validationの値がすべてfalsyな場合、validationがundefinedになるようにする
 */
export const omitFalsyValidation = (schema: Schema): Schema => {
  return {
    ...schema,
    fields: schema.fields.map(omitFalsyValidationFromField),
  }
}

/**
 * スキーマのバージョンを無視して比較する
 */
export const equalIgnoreVersion = (a: Schema, b: Schema): boolean => {
  return equal(
    { ...a, schemaVersion: 'IGNORED' },
    { ...b, schemaVersion: 'IGNORED' },
  )
}
