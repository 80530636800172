import React, { Suspense } from 'react'

import { Grid, GridItem } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { LoadingDots } from 'src/components/LoadingDots/LoadingDots'
import { Header } from 'src/features/misc/components/Header/Header'

import { NavigationRouteItems } from '../../hooks/useNavigationRoutes'
import { Navigation } from '../Navigation/Navigation'

type Props = {
  navigationRouteItems: NavigationRouteItems
}

export const MainLayout: React.FC<Props> = ({ navigationRouteItems }) => {
  return (
    <Grid
      h="100svh"
      w="100svw"
      templateRows="auto 1fr"
      templateColumns="auto 1fr"
    >
      <GridItem gridColumn="1 / -1">
        <Header />
      </GridItem>
      <GridItem gridRow="2">
        <Navigation routeItems={navigationRouteItems} />
      </GridItem>
      <GridItem overflow="auto" gridRow="2" gridColumn="2">
        <Suspense fallback={<LoadingDots />}>
          <Outlet />
        </Suspense>
      </GridItem>
    </Grid>
  )
}
