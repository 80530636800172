import dayjs from 'dayjs'
import { CreatePatientInput, UpdatePatientInput } from 'src/lib/gql-client'

import { PatientSchema } from '../schema'

export const schemaToCreatePatientInput = (
  schema: PatientSchema,
): CreatePatientInput => {
  return {
    trialHospitalUid: schema.trialHospitalUid,
    birthday: schema.birthday
      ? dayjs(schema.birthday).format('YYYY-MM-DD')
      : undefined,
    chartUid: schema.chartId,
    diseaseUid: schema.diseaseId,
    email: schema.email,
    firstName: schema.firstName,
    firstNameJa: schema.firstNameJa,
    gender: schema.gender ?? 'Unspecified',
    lastName: schema.lastName,
    lastNameJa: schema.lastNameJa,
    partnerTrialHospitalUid: !!schema.partnerTrialHospitalUid
      ? schema.partnerTrialHospitalUid
      : undefined,
    phoneNumber: schema.phoneNumber,
  }
}

export const schemaToUpdatePatientInput = (
  patientUid: string,
  schema: PatientSchema,
): UpdatePatientInput => {
  return {
    trialHospitalUid: schema.trialHospitalUid,
    patientUid: patientUid,
    birthday: schema.birthday
      ? dayjs(schema.birthday).format('YYYY-MM-DD')
      : undefined,
    chartUid: schema.chartId,
    diseaseUid: schema.diseaseId,
    email: schema.email,
    firstName: schema.firstName,
    firstNameJa: schema.firstNameJa,
    gender: schema.gender ?? 'Unspecified',
    lastName: schema.lastName,
    lastNameJa: schema.lastNameJa,
    partnerTrialHospitalUid: !!schema.partnerTrialHospitalUid
      ? schema.partnerTrialHospitalUid
      : undefined,
    phoneNumber: schema.phoneNumber,
  }
}
