import { HttpResponse } from 'msw'
import {
  createReplyStickyPayloadMock,
  ReplyStickyDocument,
  ReplyStickyMutation,
  ReplyStickyMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const replyStickyHandler = graphQLHandlerFactory<
  ReplyStickyMutation,
  ReplyStickyMutationVariables
>('mutation', ReplyStickyDocument, () => {
  return HttpResponse.json({
    data: { replySticky: createReplyStickyPayloadMock() },
  })
})
