import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { FileUploader } from './components/file-uploader'
import { Progress } from './components/progress'
import { useUploadCsv } from './use-upload-csv'
import { uploadTaskToJaText } from './util'
import { getState } from '../selector'
import { Task, UploadTask } from '../task'

type Props = {
  trialUid: string
  currentTask: UploadTask
  uploadTasks: UploadTask[]
  allUploadCompleted: boolean
  onComplete: () => void
  completed: (task: Task) => boolean

  onClickNext: () => void
  onClickPrev: () => void
}

export const UploadComponent: React.FC<Props> = props => {
  const {
    trialUid,
    currentTask,
    uploadTasks,
    onComplete,
    completed,
    onClickNext,
    onClickPrev,
    allUploadCompleted,
  } = props
  const {
    fileInputRef,
    currentFile,
    onChangeFile,
    uploadCsv,
    errorMessage,
    requesting,
  } = useUploadCsv({
    trialUid,
    task: currentTask,
  })
  const { files } = useSelector(getState)

  const onUpload = async () => {
    if (!currentFile) return

    try {
      await uploadCsv()

      onComplete()
    } catch {
      /* empty */
    }
  }

  const showPrevButton =
    currentTask !== uploadTasks[0] || completed(currentTask)

  return (
    <Container>
      <Text fontSize="lg" fontWeight="bold">
        CRFファイルアップロード
      </Text>

      <ProgressContainer>
        {uploadTasks.map(t => (
          <Progress
            key={t.name}
            task={t}
            completed={completed(t)}
            isCurrentTask={t.order === currentTask.order}
            isLast={t.order === uploadTasks.length - 1}
          />
        ))}
      </ProgressContainer>

      <Spacer size={50} />

      <ItemContainer>
        {completed(currentTask) ? (
          <div>
            <Text fontWeight="bold">
              {uploadTaskToJaText(currentTask)}
              のCSVファイルのアップロードが成功しました。
            </Text>

            <Spacer size={16} />

            <Text fontWeight="bold">{files[currentTask.name]}</Text>
          </div>
        ) : (
          <div>
            <Text>
              {uploadTaskToJaText(currentTask)}
              のCSVファイルをアップロードしてください
            </Text>
          </div>
        )}
      </ItemContainer>

      {allUploadCompleted && (
        <ItemContainer>
          <div>
            <Text fontWeight="bold">
              すべてのCSVファイルのアップロードが成功しました。
            </Text>
          </div>
        </ItemContainer>
      )}

      <ItemContainer>
        <FileUploader
          currentFile={currentFile}
          onChangeFile={onChangeFile}
          completed={completed(currentTask)}
          fileInputRef={fileInputRef}
          onUpload={onUpload}
        />
      </ItemContainer>

      {errorMessage && (
        <ErrorMessagesContainer>
          {errorMessage.split('\n').map(message => (
            <div key={message}>
              <Message type="error" message={message} />
              <Spacer size={8} />
            </div>
          ))}
        </ErrorMessagesContainer>
      )}

      <ButtonWrapper>
        {showPrevButton && (
          <ButtonItem>
            <Button
              size="S"
              text="戻る"
              buttonType="cancel"
              onClick={onClickPrev}
            ></Button>
          </ButtonItem>
        )}

        {completed(currentTask) ? (
          <ButtonItem>
            <Button
              size="S"
              text="次へ"
              buttonType="important"
              onClick={onClickNext}
            ></Button>
          </ButtonItem>
        ) : (
          <ButtonItem>
            <Button
              size="S"
              text="アップロード"
              buttonType="important"
              onClick={onUpload}
              disabled={!currentFile || requesting}
            ></Button>
          </ButtonItem>
        )}
      </ButtonWrapper>
      <Spacer size={100} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: auto;
`

const ItemContainer = styled(Flex)`
  margin: 20px;
  flex-wrap: wrap;
`

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
`

const ErrorMessagesContainer = styled.div`
  min-height: 50px;
  overflow-y: auto;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const ButtonItem = styled.div`
  margin: 0 20px;
`
