import OperationManual from 'src/assets/operation-manual/operation-manual.md'
import { Markdown } from 'src/components/Markdown/Markdown'
import { Authenticator } from 'src/features/auth/components'
import { castMarkdownContent } from 'src/utils/markdown'

import { SimpleScreenLayout } from '../SimpleScreenLayout/SimpleScreenLayout'

export const OperationManualScreen: React.FC = () => {
  return (
    <Authenticator shouldSelectTrial={false}>
      <SimpleScreenLayout>
        <Markdown content={castMarkdownContent(OperationManual)} />
      </SimpleScreenLayout>
    </Authenticator>
  )
}
