import { useMemo, useState } from 'react'

import {
  Box,
  Flex,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import {
  PaginationBack,
  PaginationEnd,
  PaginationNext,
  PaginationTop,
} from 'src/components/__legacy__icon/monochrome'
import { LoadingDots } from 'src/components/LoadingDots/LoadingDots'
import { Select } from 'src/components/Select/Select'
import { Paths } from 'src/constants/paths'
import {
  useCurrentMember,
  usePermission,
  useSelectedTrial,
} from 'src/features/auth/context'
import { useTrialHospitals } from 'src/features/trial/api'
import { useQuery } from 'src/hooks/use-query'

import { RoleBadge } from '../../../../components/RoleBadge/RoleBadge'
import { ReferredPatientListScreenDocument } from '../../../../lib/gql-client'
import { useGqlQuerySWRNoSuspense } from '../../../../lib/gql-client/request'
import { PERMISSIONS } from '../../../../lib/permission'
import { formatDate } from '../../../../utils/formatDate'
import {
  generatePathWithTrialHospitalParam,
  generateTrialHospitalParam,
  TRIAL_HOSPITAL_PARAM_KEY,
} from '../../utils/trialHospitalParam'
import { AddReferredPatientButton } from '../AddReferredPatientButton/AddReferredPatientButton'
import { ReferredPatientStatusBadge } from '../ReferredPatientStatusBadge/ReferredPatientStatusBadge'

const PER_PAGE = 50

export const ReferredPatientListScreen: React.FC = () => {
  const navigate = useNavigate()

  const query = useQuery()
  const trialHospitalParam = query.get(TRIAL_HOSPITAL_PARAM_KEY) ?? undefined

  const { selectedTrialHospitalRole } = useCurrentMember()

  const [currentPage, setCurrentPage] = useState(1)

  const [totalCount, setTotalCount] = useState(0)

  const { hasPermission } = usePermission()

  const { data: trialHospitals } = useTrialHospitals({
    revalidateIfStale: false,
    revalidateOnMount: true,
  })

  const selectedMainTrialHospitalUid = useMemo(() => {
    if (!!trialHospitalParam) {
      return trialHospitalParam
    }
    // クエリパラメータがない場合、main医療機関のうち、最初にfindでヒットした医療機関を選択状態にする
    return trialHospitals?.find(h => h.role === 'Main')?.uid ?? undefined
  }, [trialHospitalParam, trialHospitals])

  const { data: referredPatientListData } = useGqlQuerySWRNoSuspense(
    ReferredPatientListScreenDocument,
    selectedTrialHospitalRole
      ? {
          page: currentPage,
          per: PER_PAGE,
          mainTrialHospitalUid: null,
        }
      : !!selectedMainTrialHospitalUid
        ? {
            page: currentPage,
            per: PER_PAGE,
            mainTrialHospitalUid: selectedMainTrialHospitalUid,
          }
        : null,
    {
      onSuccess: data => {
        setTotalCount(data.referredPatients.totalCount)
      },
    },
  )

  const { selectedTrial } = useSelectedTrial()

  return (
    <Flex as="main" h="full" p="6" direction="column">
      <Flex gap={4} minWidth="max-content" align="center">
        <Text as="h1" fontSize="xl" fontWeight="bold">
          紹介患者一覧
        </Text>
        {hasPermission(PERMISSIONS.Referredpatient_Edit) &&
          selectedTrialHospitalRole === 'Partner' && (
            <AddReferredPatientButton />
          )}
        <Spacer />
        {!selectedTrialHospitalRole && (
          <HStack spacing="4">
            <Text fontWeight="bold">実施医療機関</Text>
            <Select
              value={selectedMainTrialHospitalUid}
              items={(trialHospitals ?? [])
                ?.filter(h => h.role === 'Main')
                .map(hospital => ({
                  label: hospital.name,
                  value: hospital.uid,
                }))}
              onChange={uid => {
                navigate({
                  search: generateTrialHospitalParam(uid),
                })
              }}
              width="320px"
            />
          </HStack>
        )}
      </Flex>

      <Box mt="6" flex="1" overflow="auto">
        <Flex justifyContent="flex-end">
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            totalCount={totalCount}
          />
        </Flex>
        {!!referredPatientListData ? (
          <Table variant="hoverable">
            <Thead>
              <Tr>
                <Th>ステータス</Th>
                <Th>No</Th>
                {selectedTrialHospitalRole === 'Partner' && <Th>氏名</Th>}
                <Th>年齢</Th>
                <Th>性別</Th>
                {(selectedTrialHospitalRole === 'Main' ||
                  !selectedTrialHospitalRole) && <Th>パートナー施設</Th>}
                <Th>紹介日</Th>
                <Th>担当者</Th>
                <Th>最終更新日</Th>
              </Tr>
            </Thead>
            <Tbody>
              {referredPatientListData.referredPatients.edges
                .map(edge => edge.node)
                .map(node => (
                  <LinkBox as={Tr} key={node.referredPatientUid}>
                    <Td>
                      {selectedTrialHospitalRole === 'Partner' ? (
                        <ReferredPatientStatusBadge
                          status={node.latestLog.referrerStatus}
                        />
                      ) : (
                        <ReferredPatientStatusBadge
                          status={node.latestLog.refereeStatus ?? 'Deleted'}
                        />
                      )}
                    </Td>
                    <Td>
                      <LinkOverlay
                        as={Link}
                        to={generatePathWithTrialHospitalParam(
                          generatePath(Paths.ReferredPatient, {
                            trialUid: selectedTrial.uid,
                            referredPatientUid: node.referredPatientUid,
                          }),
                          !!selectedTrialHospitalRole
                            ? undefined
                            : trialHospitalParam,
                        )}
                      >
                        {node.numberingId}
                      </LinkOverlay>
                    </Td>
                    {selectedTrialHospitalRole === 'Partner' && (
                      <Td maxW="180px">
                        <Flex>
                          <Text
                            as="span"
                            flex="1"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {node.latestDetail.name}
                          </Text>
                        </Flex>
                      </Td>
                    )}
                    <Td>{node.latestDetail.age}歳</Td>
                    <Td>
                      {node.latestDetail.gender === 'Male'
                        ? '男性'
                        : node.latestDetail.gender === 'Female'
                          ? '女性'
                          : '不明'}
                    </Td>
                    {(selectedTrialHospitalRole === 'Main' ||
                      !selectedTrialHospitalRole) && (
                      <Td>{node.patientTrialHospital.hospital.name}</Td>
                    )}
                    <Td>
                      {!!node.latestRefer && !node.latestRefer.cancel
                        ? formatDate(
                            node.latestRefer.referredAt,
                            'YYYY/MM/DD (ddd) HH:mm',
                          )
                        : undefined}
                    </Td>
                    <Td maxW="180px">
                      <HStack spacing="1.5" align="center" minW="64px">
                        {node.latestContact.assigneeRole && (
                          <RoleBadge role={node.latestContact.assigneeRole} />
                        )}
                        <Text
                          as="span"
                          flex="1"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {node.latestContact.assigneeName}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>
                      {formatDate(
                        node.latestUpdatedAt,
                        'YYYY/MM/DD (ddd) HH:mm',
                      )}
                    </Td>
                  </LinkBox>
                ))}
            </Tbody>
          </Table>
        ) : (
          <LoadingDots />
        )}
      </Box>
    </Flex>
  )
}

const Pagination: React.FC<{
  page: number
  setPage: (page: number) => void
  totalCount: number
}> = ({ page, setPage, totalCount }) => {
  return (
    <HStack spacing="4px">
      <IconButton
        variant="customIconButtonGhost"
        height="24px"
        width="24px"
        aria-label="pagination first button"
        // 他のページネーションUIと合わせるため古いアイコンを使う
        icon={<PaginationTop />}
        onClick={() => {
          setPage(1)
        }}
        isDisabled={page === 1}
      />
      <IconButton
        variant="customIconButtonGhost"
        height="24px"
        width="24px"
        aria-label="pagination back button"
        icon={<PaginationBack />}
        onClick={() => {
          setPage(Math.max(page - 1, 1))
        }}
        isDisabled={page === 1}
      />
      <Text fontSize="sm">
        {totalCount === 0
          ? '-'
          : `${PER_PAGE * (page - 1) + 1} - ${Math.min(PER_PAGE * page, totalCount)}`}
      </Text>
      <Text fontSize="sm">/</Text>
      <Text fontSize="sm">{`${totalCount} 件`}</Text>
      <IconButton
        variant="customIconButtonGhost"
        height="24px"
        width="24px"
        aria-label="pagination next button"
        icon={<PaginationNext />}
        onClick={() => {
          setPage(Math.min(page + 1, Math.ceil(totalCount / PER_PAGE)))
        }}
        isDisabled={page * PER_PAGE >= totalCount}
      />
      <IconButton
        variant="customIconButtonGhost"
        height="24px"
        width="24px"
        aria-label="pagination last button"
        icon={<PaginationEnd />}
        onClick={() => {
          setPage(Math.ceil(totalCount / PER_PAGE))
        }}
        isDisabled={page * PER_PAGE >= totalCount}
      />
    </HStack>
  )
}
