import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import { Add } from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { useSpinner } from 'src/hooks/use-spinner'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'

import { useCreatePatient } from '../../api/createPatient'
import { PatientSchema, patientSchema } from '../../schema'
import { schemaToCreatePatientInput } from '../../utils/schemaToInput'
import { PatientFormContent } from '../PatientFormContent/PatientFormContent'

export const CreatePatientButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    account: { selectedTrialHospitalUid, selectedTrial },
  } = useAuthenticatedAccount()

  const defaultValues: Partial<PatientSchema> = {
    trialHospitalUid: selectedTrialHospitalUid ?? undefined,
  }

  const methods = useForm<PatientSchema>({
    mode: 'onBlur',
    resolver: yupResolver(patientSchema(selectedTrialHospitalUid)),
    defaultValues,
  })

  const {
    reset,
    clearErrors,
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods

  const requiredFieldFilled =
    !!watch('diseaseId') && !!watch('trialHospitalUid')

  const myHospitalSelected =
    watch('trialHospitalUid') === selectedTrialHospitalUid ||
    watch('partnerTrialHospitalUid') === selectedTrialHospitalUid

  const handleClose = () => {
    onClose()
    reset()
    clearErrors()
  }

  const { hasPermission } = usePermission()

  const toast = useMirohaToast()

  const navigate = useNavigate()

  const { showSpinner, hideSpinner } = useSpinner()

  const { request } = useCreatePatient({
    onRequestStarted: () => {
      showSpinner()
    },
    onSuccess: ({ patientUid }) => {
      toast({
        status: 'success',
        title: '患者を新規作成しました',
      })
      navigate(
        generatePath(Paths.Patient, {
          patientUid,
          trialUid: selectedTrial.uid,
        }),
      )
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  if (!hasPermission(PERMISSIONS.Patient_Add) || !selectedTrialHospitalUid)
    return null

  return (
    <>
      <Button
        aria-label="新規作成"
        variant="outline"
        leftIcon={<Add />}
        onClick={onOpen}
      >
        新規作成
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(async data => {
              await request(schemaToCreatePatientInput(data))
            })}
          >
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize="lg" fontWeight="bold">
                  新規作成
                </Text>
              </ModalHeader>
              <ModalBody py="8">
                <PatientFormContent
                  myHospitalSelected={myHospitalSelected}
                  defaultValues={defaultValues}
                />
              </ModalBody>

              <ModalFooter>
                <ModalCancelButton />
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={
                    !requiredFieldFilled || !myHospitalSelected || !isValid
                  }
                >
                  確定
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}
