// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgFilter = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M7.5 16c-1 0-2-.8-2-1.7V8.4l-4-4.2c-.2-.3-.5-.6-.5-1.1V1.7C1 .8 1.9 0 3 0h10c1 0 2 .8 2 1.7v1.4c0 .5-.2.8-.5 1.2l-4 4.1v5.4c0 .7-.4 1.3-1.1 1.5l-1 .4c-.2.2-.6.3-.9.3ZM3 1.6c-.1 0-.1 0 0 0L2.9 3l4 4.2c.2.3.5.7.5 1.1v5.9l1.1-.4.1-5.5c0-.5.2-.8.5-1.2l4-4.1V1.6H3Z"
    />
    <path fill={props.color ?? 'currentColor'} d="M2.5 3h11v1.6h-11V3Z" />
  </svg>
)
export default SvgFilter
