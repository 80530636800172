import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { isRequestError } from 'src/modules/server/request'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { getFilePreviewRequestUrl } from './../../icf-document/request'
import { usePermission } from '../../../../common/permission'
import {
  icfDocumentSetUidParamName,
  getIcfDocumentSetListRoute,
  getEditIcfDocumentSetRoute,
} from '../../routes'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import { enableIcfDocumentSet, fetchIcfDocumentSet } from '../request'

export const useIcfDocumentSetDetail = () => {
  const { uid: trialUid, trialHospitals } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid: trialHospitalUid } =
    useIcfDocumentHospital()
  const { icfDocumentSetUid = '' } = useParams<{
    [icfDocumentSetUidParamName]: string
  }>()
  const navigate = useNavigate()
  const { hasPermission } = usePermission()
  const { showSuccess, showError } = useFlash()

  const { data: icfDocumentSet, mutate: mutateIcfDocumentSet } = useSWR(
    trialHospitalUid
      ? swrKeys.fetchIcfDocumentSet({
          trialUid,
          trialHospitalUid,
          icfDocumentSetUid,
        })
      : null,
    () =>
      fetchIcfDocumentSet({
        trialUid,
        trialHospitalUid: trialHospitalUid ?? '',
        icfDocumentSetUid,
      }),
  )

  const listPath = useMemo(() => {
    return getIcfDocumentSetListRoute({
      trialUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? trialHospitalUid
        : undefined,
    })
  }, [trialUid, trialHospitalUid, hasPermission])

  const canEdit = useMemo(() => {
    if (!icfDocumentSet) {
      return false
    }
    if (!icfDocumentSet.enabled) {
      return false
    }

    return hasPermission(PERMISSIONS.Icfdocumentset_Edit)
  }, [icfDocumentSet, hasPermission])

  const canEnable = useMemo(() => {
    if (!icfDocumentSet) {
      return false
    }
    if (icfDocumentSet.enabled) {
      return false
    }

    return hasPermission(PERMISSIONS.Icfdocumentset_Enable)
  }, [icfDocumentSet, hasPermission])

  const navigateToEdit = useCallback(() => {
    const editPath = getEditIcfDocumentSetRoute({
      trialUid,
      icfDocumentSetUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? trialHospitalUid
        : undefined,
    })
    navigate(editPath)
  }, [trialUid, icfDocumentSetUid, hasPermission, trialHospitalUid, navigate])

  const getFilePreviewUrl = useCallback(
    (icfDocumentRevisionUid: string): string => {
      if (!trialHospitalUid) {
        return ''
      }
      return getFilePreviewRequestUrl({
        trialUid,
        trialHospitalUid,
        icfDocumentRevisionUid,
      })
    },
    [trialUid, trialHospitalUid],
  )

  const onEnable = useCallback(async () => {
    if (!icfDocumentSet || !trialHospitalUid) {
      return
    }

    try {
      await enableIcfDocumentSet({
        trialUid,
        trialHospitalUid,
        icfDocumentSetUid,
      })
      showSuccess('文書セットを有効化しました')
      mutateIcfDocumentSet({ ...icfDocumentSet, enabled: true })
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [
    icfDocumentSet,
    trialHospitalUid,
    trialUid,
    icfDocumentSetUid,
    showSuccess,
    mutateIcfDocumentSet,
    showError,
  ])

  const shouldSelectHospital = useMemo(() => {
    return hasPermission(PERMISSIONS.Icfdocument_SelectTrialHospital)
  }, [hasPermission])

  const hospitalName = useMemo(() => {
    const hospital = trialHospitals.find(
      hospital => hospital.uid === trialHospitalUid,
    )

    return hospital?.name ?? ''
  }, [trialHospitalUid, trialHospitals])

  return {
    icfDocumentSet,
    listPath,
    canEdit,
    canEnable,
    navigateToEdit,
    getFilePreviewUrl,
    onEnable,
    shouldSelectHospital,
    hospitalName,
  }
}
