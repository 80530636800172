// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgAccountBlue = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 33 33"
    {...props}
  >
    <circle cx={16.367} cy={16.498} r={16} fill="#fff" />
    <mask id="account-blue_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M2.897 25.155a1.752 1.752 0 0 1-.27-1.145c.766-6.824 6.556-12.129 13.585-12.129 7.17 0 13.05 5.519 13.626 12.54.033.402-.076.804-.305 1.136-2.884 4.19-7.713 6.935-13.184 6.935-5.644 0-10.605-2.922-13.452-7.337Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#699DD8"
      fillRule="evenodd"
      d="M2.897 25.155a1.752 1.752 0 0 1-.27-1.145c.766-6.824 6.556-12.129 13.585-12.129 7.17 0 13.05 5.519 13.626 12.54.033.402-.076.804-.305 1.136-2.884 4.19-7.713 6.935-13.184 6.935-5.644 0-10.605-2.922-13.452-7.337Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="m29.838 24.42-1.993.164 1.993-.163Zm-.305 1.137-1.647-1.134 1.647 1.134Zm-26.636-.402-1.681 1.084 1.68-1.084Zm1.717-.921c.654-5.824 5.599-10.353 11.598-10.353v-4C8.153 9.881 1.518 15.962.64 23.787l3.975.447ZM16.212 13.88c6.12 0 11.141 4.711 11.633 10.703l3.987-.327c-.66-8.05-7.4-14.376-15.62-14.376v4Zm11.674 10.542c-2.527 3.67-6.752 6.07-11.537 6.07v4c6.156 0 11.59-3.094 14.832-7.801l-3.295-2.269Zm-11.537 6.07c-4.936 0-9.277-2.554-11.772-6.422l-3.361 2.168c3.2 4.961 8.782 8.253 15.133 8.253v-4Zm11.496-5.909a.247.247 0 0 1 .041-.161l3.295 2.269a3.753 3.753 0 0 0 .65-2.435l-3.986.327ZM.639 23.787c-.098.866.112 1.73.577 2.452l3.361-2.168c.028.042.044.1.037.163l-3.975-.447Z"
      mask="url(#account-blue_svg__a)"
    />
    <circle
      cx={16.212}
      cy={11.334}
      r={5.836}
      fill="#699DD8"
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
)
export default SvgAccountBlue
