// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgDownArrow = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#down_arrow_svg__a)">
      <g clipPath="url(#down_arrow_svg__b)">
        <path
          fill={props.color ?? 'currentColor'}
          d="M14 9a.996.996 0 0 0-1.41 0L9 12.59V1c0-.55-.45-1-1-1S7 .45 7 1v11.59L3.41 9A.996.996 0 1 0 2 10.41l5.29 5.29c.39.39 1.02.39 1.41 0l5.29-5.29a.996.996 0 0 0 0-1.41H14Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="down_arrow_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
      <clipPath id="down_arrow_svg__b">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgDownArrow
