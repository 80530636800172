import { HttpResponse } from 'msw'
import {
  createUpdateExpPreSessionIdentificationPayloadMock,
  UpdatePreSessionIdentificationDocument,
  UpdatePreSessionIdentificationMutation,
  UpdatePreSessionIdentificationMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const updatePreSessionIdentificationHandler = graphQLHandlerFactory<
  UpdatePreSessionIdentificationMutation,
  UpdatePreSessionIdentificationMutationVariables
>('mutation', UpdatePreSessionIdentificationDocument, () => {
  return HttpResponse.json({
    data: {
      updateExpPreSessionIdentification:
        createUpdateExpPreSessionIdentificationPayloadMock(),
    },
  })
})
