import { HttpResponse } from 'msw'
import {
  createHospitalMock,
  createTrialHospitalMock,
  GetTrialHospitalsDocument,
  GetTrialHospitalsQuery,
  GetTrialHospitalsQueryVariables,
} from 'src/lib/gql-client'
import {
  hospitalName1,
  hospitalName2,
  trialHospitalUid1,
} from 'src/tests/factory'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const getTrialHospitalsHandler = graphQLHandlerFactory<
  GetTrialHospitalsQuery,
  GetTrialHospitalsQueryVariables
>('query', GetTrialHospitalsDocument, () => {
  return HttpResponse.json({
    data: {
      enabledTrialHospitals: [
        createTrialHospitalMock({
          uid: trialHospitalUid1,
          hospital: createHospitalMock({
            name: hospitalName1,
          }),
        }),
        createTrialHospitalMock({
          uid: generateUUID(),
          hospital: createHospitalMock({
            name: hospitalName2,
          }),
        }),
      ],
    },
  })
})
