import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { UseMutationOption, useMutation } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'
import { fileToBase64 } from 'src/utils/fileToBase64'
import { formatDate } from 'src/utils/formatDate'

import { ExplanationWithdrawalField } from '../../hooks/useExplanationWithdrawForm'
import { ExplanationRevision } from '../../types'

type Param = {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
  comment: string
  fileBase64: string | undefined
  fileName: string | undefined
  withdrawnAt: string
}

const withdrawExplanation = async ({
  trialUid,
  explanationRevisionUid,
  comment,
  fileBase64,
  fileName,
  withdrawnAt,
}: Param): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/withdraw',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: {
        comment,
        fileBase64,
        fileName,
        withdrawnAt,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useWithdrawExplanation = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    async ({
      explanationRevisionUid,
      data,
    }: {
      explanationRevisionUid: ExplanationRevision['uid']
      data: ExplanationWithdrawalField
    }) =>
      withdrawExplanation({
        trialUid: selectedTrial.uid,
        explanationRevisionUid,
        comment: data.comment,
        fileName: data?.file?.name,
        fileBase64:
          data.file !== undefined ? await fileToBase64(data.file) : undefined,
        withdrawnAt: formatDate(data.withdrawAt, 'YYYY-MM-DD'),
      }),
    option,
  )
}
