import { Button, Box, Flex } from '@chakra-ui/react'
import { generatePath, Link, useParams } from 'react-router-dom'
import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'

import { browseDocRevision, useDocRevisionForBrowse } from '../../api'
import {
  locationPaths,
  ExplanationRoomEventProvider,
} from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { useThisSession } from '../../hooks/useThisSession'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { PdfViewer } from '../PDFViewer/PDFViewer'

type Param = {
  sessionUid: string
  docRevisionUid: string
}

// ボタンの高さ + y方向のpadding
const NAV_CONTAINER_HEIGHT = 36 + 24 * 2

type Props = {
  isPartner: boolean
}

export const DocumentViewerScreen: React.FC<Props> = ({ isPartner }) => {
  const { docRevisionUid = '', sessionUid = '' } = useParams<Param>()

  const { data: docRevision } = useDocRevisionForBrowse({
    explanationDocRevisionUid: docRevisionUid,
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onSuccess: async ({ uid: explanationDocRevisionUid }) => {
      // mount時のみfetchするようにしているのでそのfetchが成功したタイミングで閲覧履歴を記録する
      await browseDocRevision({
        explanationDocRevisionUid,
      })
    },
  })
  const thisSession = useThisSession({ sessionUid, forPatient: false })
  const {
    account: { selectedTrial, selectedTrialHospitalUid, member },
  } = useAuthenticatedAccount()

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (!docRevision || !thisSession.fetched || !selectedTrialHospitalUid) {
    return null
  }
  if (
    docRevision.icfDocumentRevision.__typename ===
    'IcfDocumentRevisionCheckUnderstanding'
  ) {
    throw new Error('CheckUnderstanding is not supported')
  }

  const topPath = isPartner
    ? Paths.PartnerExplanationRoomTop
    : Paths.HospitalExplanationRoomTop

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={member.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      sessionUid={sessionUid}
      deviceActorType={isPartner ? 'Partner' : 'Member'}
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout
        headerTitle={docRevision.icfDocumentRevision.name}
        sessionUid={sessionUid}
        isPartner={isPartner}
        showSidebar={false}
      >
        <Box h="full">
          <Flex align="center" justify="space-between" p="6">
            <Button
              as={Link}
              to={generatePath(topPath, {
                trialUid: selectedTrial.uid,
                sessionUid,
              })}
              variant="outline"
              colorScheme="gray"
            >
              説明ルームへ戻る
            </Button>
          </Flex>

          <Box h={`calc(100% - ${NAV_CONTAINER_HEIGHT}px)`}>
            {docRevision.icfDocumentRevision.__typename ===
            'IcfDocumentRevisionVideo' ? (
              <Box h="full" w="full" px="20" pb="10">
                <HlsVideoPlayer
                  url={docRevision.icfDocumentRevision.videoURL ?? ''}
                />
              </Box>
            ) : (
              <Flex h="full" align="center" justify="center">
                <PdfViewer
                  url={docRevision.icfDocumentRevision.fileURL}
                  sessionUid={sessionUid}
                  locationPath={locationPaths.document(docRevisionUid)}
                  trialHospitalUid={selectedTrialHospitalUid}
                  actorType="Member"
                  trialMemberUid={member.uid}
                  isPartner={isPartner}
                />
              </Flex>
            )}
          </Box>
        </Box>
        {renderFloatingVideoCall()}
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}
