import { HStack, Radio, RadioGroup } from '@chakra-ui/react'

import { ExplanationType } from '../../types'
import { explanationTypeToText } from '../../utils/typeToText'

type Props = {
  value: ExplanationType | undefined
  onChange: (type: ExplanationType) => void
}

const types: ExplanationType[] = ['InPerson', 'Remote', 'RemotePartner']

export const ExplanationTypeRadio: React.FC<Props> = ({ value, onChange }) => {
  return (
    <RadioGroup
      value={value}
      onChange={value => onChange(value as ExplanationType)}
      name="type"
    >
      <HStack spacing="8">
        {types.map(type => (
          <Radio key={type} value={type}>
            {explanationTypeToText(type)}
          </Radio>
        ))}
      </HStack>
    </RadioGroup>
  )
}
