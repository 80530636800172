import { Choice, Field, SelectMenuTypeDef } from '@micin-jp/chicken-schema'
import { Select } from 'src/components/Select/Select'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  selectMenuField: Field & {
    typeDef: SelectMenuTypeDef
  }
  index: number
}

export const WorksheetSelectMenuField: React.FC<Props> = ({
  selectMenuField,
  index,
}) => {
  const {
    findFieldValue,
    onChangeSelectMenuFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: selectMenuField.fid,
    index,
  })

  const onChange = (selectMenuValue: Choice['cid'] | undefined) => {
    onChangeSelectMenuFieldValue({
      fid: selectMenuField.fid,
      index,
      selectMenuValue,
    })
  }

  return (
    <WorksheetFieldFormControl
      field={selectMenuField}
      index={index}
      isDisabled={
        !canEdit || !isRepeatableSectionEnabled(index, selectMenuField.fid)
      }
      errorMessage={getErrorMessage(value)}
    >
      <Select
        width={320}
        isClearable
        value={value?.type === 'selectMenu' ? value.selectMenuValue : undefined}
        items={selectMenuField.typeDef.choices.map(choice => ({
          value: choice.cid,
          label: choice.name,
        }))}
        onChange={onChange}
        isDisabled={
          !canEdit || !isRepeatableSectionEnabled(index, selectMenuField.fid)
        }
      />
    </WorksheetFieldFormControl>
  )
}
