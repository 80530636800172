import React from 'react'

import { Text } from '@chakra-ui/react'
import { gray } from 'src/components/base/color/palette'
import { AnswerType } from 'src/modules/entities/questionnaire-field/entity'
import styled from 'styled-components'

type Item = {
  value: AnswerType
  name: string
  desc?: string
}

const items: Item[] = [
  {
    value: AnswerType.Radio,
    name: 'ラジオボタン',
    desc: '複数の選択肢の中から1つだけ選択できる。',
  },
  {
    value: AnswerType.CheckBox,
    name: 'チェックボックス',
    desc: '複数の選択肢の中からいくつも選択可能。',
  },
  {
    value: AnswerType.Number,
    name: '数値入力',
    desc: '数値の単位を設定して数字入力できる。',
  },
  {
    value: AnswerType.Text,
    name: 'テキスト',
    desc: '文字列を入力できる。',
  },
]

const DROP_DOWN_ITEM_HEIGHT = 50

type Props = {
  selectedValue: AnswerType
}

export const FixedEditableAnswerTypeSelect: React.FC<Props> = ({
  selectedValue,
}) => {
  return <Component items={items} selectedValue={selectedValue} />
}

type ComponentProps = {
  selectedValue: AnswerType
  items: Item[]
}

const Component: React.FC<ComponentProps> = ({ selectedValue, items }) => {
  const selectedItem = items.find(item => item.value === selectedValue)

  const getText = (item: Item) => {
    return item.desc ? `${item.name}（${item.desc}）` : `${item.name}`
  }

  if (!selectedItem) {
    return null
  }

  return (
    <Wrapper>
      <Selected>
        <Text fontSize="sm">{getText(selectedItem)}</Text>
      </Selected>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Selected = styled.div`
  background-color: ${gray[10]};
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  text-decoration: none;
  box-sizing: border-box;
  align-items: center;
  border-radius: 3px;
  user-select: none;
  border: 1px solid ${gray[40]};
  border-radius: 5px;
  width: 450px;
  height: ${DROP_DOWN_ITEM_HEIGHT}px;
`
