import { GqlError, SyncAllEnvelopesDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const syncAllEnvelopes = async ({
  explanationSessionUid,
  skipUpdateRevStatusEvent = false,
}: {
  explanationSessionUid: string
  skipUpdateRevStatusEvent?: boolean
}): Promise<void> => {
  try {
    await graphqlRequest(SyncAllEnvelopesDocument, {
      input: {
        explanationSessionUid,
        skipUpdateRevStatusEvent,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useSyncAllEnvelopes = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationSessionUid,
      skipUpdateRevStatusEvent = false,
    }: {
      explanationSessionUid: string
      skipUpdateRevStatusEvent?: boolean
    }) =>
      syncAllEnvelopes({
        explanationSessionUid,
        skipUpdateRevStatusEvent,
      }),
    option,
  )
}
