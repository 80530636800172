import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { GqlError, SyncAllEnvelopesDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { ExplanationSession } from '../../types'

const syncAllEnvelopes = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: ExplanationSession['uid']
}): Promise<void> => {
  try {
    await graphqlRequest(SyncAllEnvelopesDocument, {
      input: { explanationSessionUid },
    })
  } catch (error) {
    throw error
  }
}

export const useSyncAllEnvelopes = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationSessionUid,
    }: {
      explanationSessionUid: ExplanationSession['uid']
    }) => syncAllEnvelopes({ explanationSessionUid }),
    option,
  )
}
