import { useEffect } from 'react'

import { useRoomMediaContext } from '../context/MediaProvider'

export const useInitializePatientVideoCall = (param: {
  roomUid?: string
  candidateId?: string
}) => {
  const { initializePatientVideoCall } = useRoomMediaContext()
  useEffect(() => {
    if (!param.roomUid || !param.candidateId) return
    initializePatientVideoCall({
      roomUid: param.roomUid,
      candidateId: param.candidateId,
    })
  }, [param.candidateId, param.roomUid, initializePatientVideoCall])
}
