import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtomValue } from 'jotai'
import { editingSchemaAtom } from 'src/lib/chicken-schema/atom'

import { PreviewCriteria } from '../PreviewCriteria/PreviewCriteria'

type Props = {
  mainHospitalName: string | undefined
}

export const PreviewCriteriaButton: React.FC<Props> = ({
  mainHospitalName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const schema = useAtomValue(editingSchemaAtom)

  return (
    <>
      <Button variant="outline" onClick={onOpen}>
        プレビュー
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Box>
              <Text as="span">{schema.name}</Text>
              {mainHospitalName && (
                <Text
                  as="span"
                  display="block"
                  fontSize="sm"
                  fontWeight="normal"
                >{`実施医療機関: ${mainHospitalName}`}</Text>
              )}
            </Box>
          </ModalHeader>
          <ModalBody>
            <PreviewCriteria schema={schema} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
