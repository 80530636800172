import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { WSDataExportJob } from '../../types'
import { parseWSExportJobResponse } from '../parser'

export const createWSDataExportJob = async ({
  trialUid,
}: {
  trialUid: Trial['uid']
}) => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/ws_data_export',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return parseWSExportJobResponse(res.data)
}

export const useCreateWSDataExportJob = (
  mutateOptions?: UseMutationOption<WSDataExportJob>,
) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    () => createWSDataExportJob({ trialUid: selectedTrial.uid }),
    mutateOptions,
  )
}
