import React from 'react'

import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { AddIcfDocumentSet } from './add'
import { useAddIcfDocumentSet } from './add.hooks'

export const AddIcfDocumentSetContainer: React.FC = () => {
  const {
    createSetItem,
    approvedIcfDocuments,
    editing,
    listPath,
    onChangeName,
    onChangeSelectedIcfDocuments,
    canSubmit,
    onSubmit,
    onCancel,
    errors,
  } = useAddIcfDocumentSet()

  const pullDownState = useOpenPullDown()

  useBlockTransition({
    showAlert: editing,
  })

  return (
    <>
      <AddIcfDocumentSet
        createIcfDocumentSetItem={createSetItem}
        approvedIcfDocuments={approvedIcfDocuments}
        listPath={listPath}
        onChangeName={onChangeName}
        onChangeSelectedDocuments={onChangeSelectedIcfDocuments}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        pullDownState={pullDownState}
        onCancel={onCancel}
        errors={errors}
      />
    </>
  )
}
