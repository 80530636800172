import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient, RequestResponse } from 'src/lib/api-client'

export type VerifyOTPRes = Extract<
  RequestResponse<
    '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/verify_otp',
    'post'
  >,
  { result: 'success' }
>['data']

const verifyOTP = async ({
  trialUid,
  explanationSignAuthUid,
  otpCode,
}: {
  trialUid: string
  explanationSignAuthUid: string
  otpCode: string
}): Promise<VerifyOTPRes> => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/verify_otp',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthUid,
      },
      body: {
        otpCode: otpCode,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
  return res.data
}

export const useVerifyOTP = (options?: UseMutationOption<VerifyOTPRes>) => {
  return useMutation(
    ({
      trialUid,
      explanationSignAuthUid,
      otpCode,
    }: {
      trialUid: string
      explanationSignAuthUid: string
      otpCode: string
    }) =>
      verifyOTP({
        trialUid,
        explanationSignAuthUid,
        otpCode,
      }),
    options,
  )
}
