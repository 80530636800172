import { Role } from '../entities/member/entity'

export const roleToText = (role: Role) => {
  switch (role) {
    case Role.Admin: // Adminロールは現在利用なし
      return 'Admin'
    case Role.Dr:
      return 'Dr'
    case Role.CRC:
      return 'CRC'
    case Role.DM:
      return 'DM'
    case Role.CRA:
      return 'CRA'
  }
}

export const rolesToText = (roles: Role[]) => {
  return roles.map(roleToText).join(', ')
}
