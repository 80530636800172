import { useCallback, useMemo } from 'react'

import { PatientsSortKey, SortOrder } from 'src/lib/gql-client'

import { usePatientQueryParams } from './usePatientsQueryParams'

const DEFAULT_PATIENT_SORT_KEY = 'DiseaseUID' as const
const DEFAULT_PATIENT_SORT_ORDER = 'Desc' as const

export const useSortPatients = () => {
  const { queryParamObject, setQuery } = usePatientQueryParams()

  const currentOrderKey = useMemo(
    () => queryParamObject.sortKey ?? DEFAULT_PATIENT_SORT_KEY,
    [queryParamObject],
  )

  const currentSortOrder = useMemo(
    () => queryParamObject.sortOrder ?? DEFAULT_PATIENT_SORT_ORDER,
    [queryParamObject],
  )

  const handleChangeSortKey = useCallback(
    (field: PatientsSortKey | undefined) => {
      setQuery({
        type: 'sortKey',
        value: field ?? DEFAULT_PATIENT_SORT_KEY,
      })
    },
    [setQuery],
  )

  const handleChangeSortOrder = useCallback(
    (sortOrder: SortOrder) => {
      setQuery({
        type: 'sortOrder',
        value: sortOrder ?? DEFAULT_PATIENT_SORT_ORDER,
      })
    },
    [setQuery],
  )

  return {
    handleChangeSortKey,
    handleChangeSortOrder,
    sortKey: currentOrderKey,
    sortOrder: currentSortOrder,
  }
}
