import produce from 'immer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { TemplateFolder } from './entity'
import { fetchList } from './request'
import { RootState } from '../../reducer'

const create = actionCreatorFactory('entities/template-folder')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetchList: createAsync<
    {
      trialUid: string
    },
    TemplateFolder[],
    { message: string }
  >('FETCH_LIST', async (params, dispatch, getState) => {
    return await fetchList(params)
  }),
  upsert: create<TemplateFolder>('upsert'),
  delete: create<{ uid: string }>('delete'),
  sort: create<TemplateFolder[]>('sort'),
}

export type State = {
  byId: Record<string, TemplateFolder>
}

const initialState: State = {
  byId: {},
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.fetchList.async.done, (state, payload) =>
    produce(state, draft => {
      const newFolders: Record<string, TemplateFolder> = {}
      payload.result.forEach(tf => {
        newFolders[tf.uid] = tf
      })

      draft.byId = newFolders
    }),
  )
  .case(actions.upsert, (state, payload) =>
    produce(state, draft => {
      const templateFolder = payload
      draft.byId[templateFolder.uid] = templateFolder
    }),
  )
  .case(actions.delete, (state, payload) =>
    produce(state, draft => {
      delete draft.byId[payload.uid]
    }),
  )
  .case(actions.sort, (state, payload) =>
    produce(state, draft => {
      payload.forEach(tf => {
        draft.byId[tf.uid].index = tf.index
      })
    }),
  )

  .build()
