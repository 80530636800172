import { ExplanationRevision } from '../types'

export const isAgreementDoneOrRejected = (
  revision: ExplanationRevision,
): boolean => {
  return (
    revision.status === 'AgreementDone' ||
    revision.status === 'AgreementRejected'
  )
}
