import React from 'react'

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  HStack,
  FormErrorMessage,
  VStack,
} from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'
import { Linkify } from 'src/components/Linkify/Linkify'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'

import { WarningMessage } from '../../../../components/WarningMessage/WarningMessage'

type Props = {
  field: Field
  /** CheckboxGroup, RadioGroupの場合はtrueを設定する */
  asFieldset?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  errorMessage?: string
  warnMessage?: string
  showRequiredBadge?: boolean
  children?: React.ReactNode
  supplementaryElement?: React.ReactNode
}

/** ChakraUIのFormControlを拡張 ChakraUIのフォームコンポーネントを使う場合はこちらを使用 */
export const ChakraFieldFormControl: React.FC<Props> = ({
  field,
  asFieldset,
  isDisabled,
  isRequired,
  errorMessage,
  warnMessage,
  showRequiredBadge = true,
  children,
  supplementaryElement,
}) => {
  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={!!errorMessage}
      w="full"
      as={asFieldset ? 'fieldset' : 'div'}
    >
      <FormLabel
        display="flex"
        alignItems="center"
        gap={1}
        mb="2"
        requiredIndicator={showRequiredBadge ? <RequiredBadge /> : null}
        as={asFieldset ? 'legend' : 'label'}
      >
        <Linkify fontWeight="bold">{field.name}</Linkify>
        {supplementaryElement}
      </FormLabel>
      {!!field.description && (
        <FormHelperText my="2">
          <Linkify>{field.description}</Linkify>
        </FormHelperText>
      )}
      {children}
      <VStack align="start" spacing="1" mt="2">
        {!!errorMessage && (
          <FormErrorMessage m="0">{errorMessage}</FormErrorMessage>
        )}
        {!!warnMessage && (
          <WarningMessage message={warnMessage} fontSize="sm" iconSize="12px" />
        )}
      </VStack>
    </FormControl>
  )
}

/** フォーム要素が複数ある場合、フォーム要素以外をレンダリングする場合はa11y上の理由からこちらを使うこと（見た目はChakraFieldFormControlと同様） */
export const FormWrapperWithoutChakra: React.FC<Props> = ({
  field,
  isRequired,
  children,
  supplementaryElement,
  errorMessage,
  warnMessage,
  showRequiredBadge = true,
  isDisabled,
}) => {
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      isDisabled={isDisabled}
      w="full"
    >
      <Stack spacing="2" role="group" display="inline-flex" w="full">
        <HStack spacing="1" align="center">
          <Linkify fontSize="sm" fontWeight="bold" color="gray.600">
            {field.name}
          </Linkify>
          {isRequired && showRequiredBadge && <RequiredBadge />}
          {supplementaryElement}
        </HStack>
        {!!field.description && (
          <Linkify fontSize="sm" color="gray.500">
            {field.description}
          </Linkify>
        )}
        {children}
      </Stack>
      <VStack align="start" spacing="1" mt="2">
        {!!errorMessage && (
          <FormErrorMessage m="0">{errorMessage}</FormErrorMessage>
        )}
        {!!warnMessage && (
          <WarningMessage message={warnMessage} fontSize="sm" iconSize="12px" />
        )}
      </VStack>
    </FormControl>
  )
}
