import { useRef } from 'react'

import { Button, Radio, RadioGroup, VStack } from '@chakra-ui/react'

import { FieldOf } from '../../utils'
import { ChakraFieldFormControl } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Radio'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

// 選択肢なしの場合に選択クリアを有効にするためのダミーのラジオボタンのvalue
const CLEARED = 'CLEARED'

export const RadioFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  const dummyRadioRef = useRef<HTMLInputElement>(null)
  return (
    <ChakraFieldFormControl
      field={field}
      asFieldset
      isRequired={field.typeDef.validation?.required}
      isDisabled={isDisabled || isPreview}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <RadioGroup
        value={value ?? CLEARED}
        onChange={c => onChange?.(c === CLEARED ? undefined : c)}
      >
        <VStack
          align="start"
          spacing="3"
          sx={{
            // 無効な場合も常に通常色を表示する 本来はisReadOnlyなどを使って制御したい
            '.chakra-radio__label': {
              opacity: '1 !important',
            },
          }}
        >
          {field.typeDef.choices.map(choice => (
            <Radio key={choice.cid} value={choice.cid}>
              {choice.name}
            </Radio>
          ))}
          {/* 選択クリアを有効にするために無理やりdummyのラジオボタンを作っておく */}
          <Radio
            display="none"
            ref={dummyRadioRef}
            key={CLEARED}
            value={CLEARED}
          />
        </VStack>
      </RadioGroup>
      {!isDisabled && (
        <Button
          mt="4"
          variant="text"
          onClick={() => {
            dummyRadioRef.current?.click()
          }}
          isDisabled={isDisabled || isPreview}
        >
          選択クリア
        </Button>
      )}
    </ChakraFieldFormControl>
  )
}
