import { Dictionary } from 'lodash'
import { Choice } from 'src/modules/entities/choice/entity'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import {
  SmallItem,
  DataType,
  ItemType,
} from 'src/modules/entities/small-item/entity'
import { isEmptyLetter } from 'src/modules/util/is-empty-letter'

import {
  hasChoice,
  isLabelTypeWithEdc,
} from '../../../../../../util/item-type-classification'

type ErrorMap = Dictionary<Dictionary<boolean>>

// { [mediumItemUid: string]: { [mediumItemUid or smallItemUid or choiceUid]: boolean }}
export const validateMediumItems = (mediumItems: MediumItem[], v2: boolean) => {
  const mediumItemErrorMap: ErrorMap = {}

  mediumItems.forEach(mediumItem => {
    mediumItemErrorMap[mediumItem.uid] = {}

    const validatedKeys: Array<keyof MediumItem> = ['title']
    const smallItems = Object.values(mediumItem.smallItemMap)

    if (!v2) {
      const hasClinicalSmallItem = smallItems.some(
        si => si.dataType === DataType.Clinical,
      )
      if (hasClinicalSmallItem) {
        validatedKeys.push('folderName')
        validatedKeys.push('formName')
      }
    }

    if (validatedKeys.some(vk => !mediumItem[vk])) {
      mediumItemErrorMap[mediumItem.uid][mediumItem.uid] = true
    }

    validateSmallItems(mediumItemErrorMap, mediumItem, v2)
  })

  return mediumItemErrorMap
}

const validateSmallItems = (
  mediumItemErrorMap: ErrorMap,
  mediumItem: MediumItem,
  v2: boolean,
) => {
  const smallItems = Object.values(mediumItem.smallItemMap)

  if (smallItems.length === 0) {
    mediumItemErrorMap[mediumItem.uid][mediumItem.uid] = true
  }

  smallItems.forEach(smallItem => {
    validateSmallItem(
      mediumItemErrorMap,
      mediumItem.uid,
      smallItem,
      mediumItem.smallItemMap,
      v2,
    )
  })
}

const validateSmallItem = (
  mediumItemErrorMap: ErrorMap,
  mediumItemUid: string,
  smallItem: SmallItem,
  smallItemMap: Dictionary<SmallItem>,
  v2: boolean,
) => {
  const validatedKeys: Array<keyof SmallItem> = [
    'title',
    'itemType',
    'dataType',
  ]

  if (!v2) {
    validatedKeys.push('inputRoles')

    if (smallItem.dataType === DataType.Clinical) {
      validatedKeys.push('edcDataItem')
    }

    if (isLabelTypeWithEdc(smallItem)) {
      validatedKeys.push('value')
    }
  }

  if (smallItem.parentUid === null) {
    validatedKeys.push('index')
  }

  validatedKeys.some(vk => {
    if (vk === 'index') {
      return (
        smallItem[vk] < 0 &&
        (mediumItemErrorMap[mediumItemUid][smallItem.uid] = true)
      )
    }

    if (vk === 'inputRoles') {
      //ラベルタイプの場合はロールが指定されていないことを確認する
      if (smallItem.itemType === ItemType.Label) {
        return (
          smallItem[vk].length !== 0 &&
          (mediumItemErrorMap[mediumItemUid][smallItem.uid] = true)
        )
      }

      return (
        smallItem[vk].length === 0 &&
        (mediumItemErrorMap[mediumItemUid][smallItem.uid] = true)
      )
    }

    if (vk === 'value') {
      return (
        isEmptyLetter(smallItem[vk]) &&
        (mediumItemErrorMap[mediumItemUid][smallItem.uid] = true)
      )
    }

    return (
      !smallItem[vk] &&
      (mediumItemErrorMap[mediumItemUid][smallItem.uid] = true)
    )
  })

  if (hasChoice(smallItem)) {
    smallItem.choices.length === 0 &&
      (mediumItemErrorMap[mediumItemUid][smallItem.uid] = true)

    validateChoices(
      mediumItemErrorMap,
      mediumItemUid,
      smallItem,
      smallItem.dataType,
      smallItemMap,
      v2,
    )
  }
}

const validateChoices = (
  mediumItemErrorMap: ErrorMap,
  mediumItemUid: string,
  smallItem: SmallItem,
  dataType: DataType,
  smallItemMap: Dictionary<SmallItem>,
  v2: boolean,
) => {
  smallItem.choices &&
    smallItem.choices.forEach(choice => {
      validateChoice(
        mediumItemErrorMap,
        mediumItemUid,
        choice,
        dataType,
        smallItemMap,
        v2,
      )
    })
}

const validateChoice = (
  mediumItemErrorMap: ErrorMap,
  mediumItemUid: string,
  choice: Choice,
  dataType: DataType,
  smallItemMap: Dictionary<SmallItem>,
  v2: boolean,
) => {
  const validatedKeys: Array<keyof Choice> = ['description']

  if (dataType === DataType.Clinical) {
    validatedKeys.push('edcValue')
  }

  validatedKeys.some(vk => {
    if (vk === 'edcValue') {
      const value = choice[vk]!
      if (value.includes('|')) {
        mediumItemErrorMap[mediumItemUid][choice.uid] = true
      }
    }

    return !choice[vk] && (mediumItemErrorMap[mediumItemUid][choice.uid] = true)
  })

  choice.smallItemUids.forEach(smallItemUid => {
    const smallItem = smallItemMap[smallItemUid]

    validateSmallItem(
      mediumItemErrorMap,
      mediumItemUid,
      smallItem,
      smallItemMap,
      v2,
    )
  })
}
