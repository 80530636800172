import axios, { AxiosError } from 'axios'

type RequestOption = {
  url: string
  params?: any
  idToken?: string
}

type Response<T> = {
  data: T
  status: number
}

type RequestError = {
  message: string
  original: AxiosError
}

export const isRequestError = (e: unknown): e is RequestError => {
  if (typeof e !== 'object' || !e) {
    return false
  }

  return 'message' in e
}

const handleError = (error: any): RequestError => {
  if (!error.response || !error.response.status) {
    return { message: 'インターネットに接続されていません。', original: error }
  }

  const isUserFriendlyMessage = !error.response.data.message.match(
    /^[a-zA-Z0-9!-/:-@¥[-`{-~\s]+$/,
  )
  const message = isUserFriendlyMessage
    ? error.response.data.message
    : error.response.status === 403
      ? `アクセスが制限されています。アクセス可能な環境から接続してください`
      : `問題が発生しました。時間を置いて再度お試しください。`

  return {
    message,
    original: error as AxiosError,
  }
}

export const requestGet = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params } = option

  try {
    const res = await axios.get<Data>(url, {
      params,
      url,
      headers: {},
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestPost = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params, idToken } = option

  try {
    const res = await axios.post<Data>(url, params, {
      headers: { Authorization: `Bearer ${idToken}` },
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestPut = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params } = option

  try {
    const res = await axios.put<Data>(url, params, {
      headers: {},
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestDelete = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url } = option

  try {
    const res = await axios.delete<Data>(url, {
      headers: {},
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}
