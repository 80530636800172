import * as Sentry from '@sentry/react'

export const initialize = () => {
  if (import.meta.env.PROD) {
    Sentry.init({
      environment: import.meta.env.VITE_APPLICATION_ENV,
      dsn: import.meta.env.VITE_SENTRY_DSN,
    })
  }
}
