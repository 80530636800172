export const miscCacheKey = {
  getNotifications: ({ after, limit }: { after: string; limit: number }) =>
    ['notification', after, limit].join(),
  getNotificationsByGroupKey: ({
    after,
    groupKey,
  }: {
    after: string
    groupKey: string
  }) => ['notificationByGroupKey', after, groupKey].join(),
}
