import { Flex, IconButton, Input, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Time } from 'src/components/icon'

import { FieldOf } from '../../utils'
import { FormWrapperWithoutChakra } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Time'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

type FormTime = {
  hour?: string
  minute?: string
}

const strToFormTime = (value?: string): FormTime => {
  const [hour, minute] = value?.split(':') ?? []
  return {
    hour: hour === 'HH' ? undefined : hour,
    minute: minute === 'MM' ? undefined : minute,
  }
}

const formTimeToStr = (time: FormTime): string | undefined => {
  if (time.hour && time.minute) {
    return `${time.hour}:${time.minute}`
  }
  if (!time.hour && !time.minute) {
    return undefined
  }
  // 不正な時間。HH:MMで置き換える。後のバリデーションでエラーになる。
  return `${time.hour ?? 'HH'}:${time.minute ?? 'MM'}`
}

export const TimeFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  const time = strToFormTime(value)

  return (
    <FormWrapperWithoutChakra
      field={field}
      isRequired={field.typeDef.validation?.required}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <Flex align="center">
        <Input
          w="56px"
          mr="2"
          value={time.hour ?? ''}
          onChange={e =>
            onChange?.(formTimeToStr({ ...time, hour: e.target.value }))
          }
          isDisabled={isDisabled || isPreview}
        />
        <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
          時
        </Text>
        <Input
          w="56px"
          mr="2"
          value={time.minute ?? ''}
          onChange={e =>
            onChange?.(formTimeToStr({ ...time, minute: e.target.value }))
          }
          isDisabled={isDisabled || isPreview}
        />
        <Text as="span" fontSize="sm" fontWeight="bold" mr="4">
          分
        </Text>
        {(isPreview || !isDisabled) && (
          <IconButton
            isDisabled={isDisabled || isPreview}
            icon={<Time />}
            aria-label="現在時刻を入力"
            variant="customIconButtonGhost"
            onClick={() => {
              onChange?.(dayjs().format('HH:mm'))
            }}
          />
        )}
      </Flex>
    </FormWrapperWithoutChakra>
  )
}
