import React, { useRef, useState } from 'react'

import { Text } from '@chakra-ui/react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Down } from 'src/components/__legacy__icon/monochrome'
import { gray, white } from 'src/components/base/color/palette'
import { AnswerType } from 'src/modules/entities/questionnaire-field/entity'
import { zIndex } from 'src/modules/util/z-index'
import styled from 'styled-components'

type Item = {
  value: AnswerType
  name: string
  desc?: string
}

const items: Item[] = [
  {
    value: AnswerType.Radio,
    name: 'ラジオボタン',
    desc: '複数の選択肢の中から1つだけ選択できる。',
  },
  {
    value: AnswerType.CheckBox,
    name: 'チェックボックス',
    desc: '複数の選択肢の中からいくつも選択可能。',
  },
  {
    value: AnswerType.Number,
    name: '数値入力',
    desc: '数値の単位を設定して数字入力できる。',
  },
  {
    value: AnswerType.Text,
    name: 'テキスト',
    desc: '文字列を入力できる。',
  },
]

type Props = {
  selectedValue: AnswerType
  onSelect: (value: AnswerType) => void
}

export const EditableAnswerTypeSelect: React.FC<Props> = ({
  selectedValue,
  onSelect,
}) => {
  return (
    <Component
      items={items}
      selectedValue={selectedValue}
      onSelect={onSelect}
      width={450}
    />
  )
}

const DROP_DOWN_ITEM_HEIGHT = 50
const DROP_DOWN_MAX_HEIGHT = 350

type ComponentProps = {
  selectedValue: AnswerType
  onSelect: (value: AnswerType) => void
  items: Item[]
  width: number
}

const Component: React.FC<ComponentProps> = ({
  selectedValue,
  onSelect,
  items,
  width,
}) => {
  const selectedAreaRef = useRef<HTMLDivElement>(null)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const toggleDropDown = () => setDropDownOpen(!dropDownOpen)
  const selectedItem = items.find(item => item.value === selectedValue)

  const calculatePosition = (): { top: number; left: number } => {
    if (!selectedAreaRef.current) {
      return {
        top: 0,
        left: 0,
      }
    }

    const windowHeight = window.innerHeight
    const selectedArea = selectedAreaRef.current.getBoundingClientRect()
    const dropDownHeight =
      DROP_DOWN_MAX_HEIGHT < DROP_DOWN_ITEM_HEIGHT * items.length
        ? DROP_DOWN_MAX_HEIGHT
        : DROP_DOWN_ITEM_HEIGHT * items.length

    //selectedAreaの下にdropDownを表示するスペースがあれば真下に表示する
    if (windowHeight - selectedArea.bottom > dropDownHeight) {
      return {
        top: selectedArea.bottom,
        left: selectedArea.left,
      }
    }

    return {
      top: selectedArea.top - dropDownHeight - 2, //selectedAreaの真上より少し上
      left: selectedArea.left,
    }
  }

  const onClickDropDownItem = (answerType: AnswerType) => {
    setDropDownOpen(false)
    onSelect(answerType)
  }

  const getText = (item: Item) => {
    return item.desc ? `${item.name}（${item.desc}）` : `${item.name}`
  }

  if (!selectedItem) {
    return null
  }

  return (
    <Wrapper>
      <Selected onClick={toggleDropDown} ref={selectedAreaRef} width={width}>
        <Text fontSize="sm">{getText(selectedItem)}</Text>
        <Down color={gray[80]} cursor="pointer" />
      </Selected>

      {dropDownOpen && (
        <Background>
          <OutsideClickHandler onOutsideClick={toggleDropDown}>
            <DropDownWrapper
              top={calculatePosition().top}
              left={calculatePosition().left}
              width={width}
            >
              {items.map(item => (
                <DropDownItem
                  key={item.value}
                  active={item.value === selectedValue}
                  onClick={() => onClickDropDownItem(item.value)}
                >
                  <Text fontSize="sm">{getText(item)}</Text>
                </DropDownItem>
              ))}
            </DropDownWrapper>
          </OutsideClickHandler>
        </Background>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Selected = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  text-decoration: none;
  box-sizing: border-box;
  align-items: center;
  border-radius: 3px;
  user-select: none;
  border: 1px solid ${gray[40]};
  border-radius: 5px;
  width: ${p => p.width}px;
  height: ${DROP_DOWN_ITEM_HEIGHT}px;
`

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${zIndex.answerTypeSelect};
`

const DropDownWrapper = styled.div<{
  top: number
  left: number
  width: number
}>`
  position: fixed;
  top: ${props => props.top}px;
  left: ${props => props.left}px;

  max-height: ${DROP_DOWN_MAX_HEIGHT}px;
  width: ${p => p.width}px;
  padding: 4px;
  box-sizing: border-box;
  overflow: auto;
  background: ${white};
  border: 1px solid ${gray[40]};
  border-radius: 5px;
`

const DropDownItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 100%;
  height: ${DROP_DOWN_ITEM_HEIGHT}px;
  box-sizing: border-box;
  word-break: break-all;
  background-color: ${props => props.active && gray[10]};

  &:hover {
    background-color: ${props => !props.active && gray[5]};
  }
`
