// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgProgressComplete = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 30 24"
    {...props}
  >
    <path
      fill="#44B3A2"
      d="m29.775 11.46-7.5-6.96C19.38 1.59 15.795 0 12 0 5.37 0 0 5.265 0 12s5.37 12 12 12c3.795 0 7.38-1.62 10.275-4.515l7.5-6.96a.761.761 0 0 0 0-1.08v.015Z"
    />
    <path
      fill="#fff"
      d="M10.44 17.547c-.445 0-.742-.148-1.039-.444l-4.455-4.436c-.595-.592-.595-1.48 0-2.07a1.44 1.44 0 0 1 2.079 0l3.416 3.4 6.534-6.506a1.44 1.44 0 0 1 2.08 0c.593.591.593 1.478 0 2.07l-7.575 7.542a1.607 1.607 0 0 1-1.04.444Z"
    />
  </svg>
)
export default SvgProgressComplete
