import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

type GetTrialsWithTrialHospitalsReturn = {
  name: string
  role: 0 | 5 | 4 | 3 | 2 | 1
  trial_hospitals: {
    name: string
    uid: string
  }[]
  uid: string
}[]

const getTrialsWithTrialHospitals = async () => {
  const res = await createApiClient({
    path: '/trials_with_trial_hospitals',
    httpMethod: 'get',
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useTrialsWithTrialHospitals = ({
  ...option
}: SWRConfiguration<GetTrialsWithTrialHospitalsReturn>) => {
  return useSWR<GetTrialsWithTrialHospitalsReturn>(
    'trials_with_trial_hospitals',
    () => getTrialsWithTrialHospitals(),
    option,
  )
}
