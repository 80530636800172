import React, { memo } from 'react'

import { Text } from '@chakra-ui/react'
import { blue, gray } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { QuestionnaireSection } from 'src/modules/entities/questionnaire-section/entity'
import styled from 'styled-components'

import { FixedQuestionnaireField } from './field'

type Props = {
  section: QuestionnaireSection
}

export const FixedQuestionnaireSection: React.FC<Props> = memo(
  ({ section }) => {
    const { title, fieldMap } = section

    return (
      <Container>
        <Content>
          <Item>
            <Label>
              <Text>ページ名</Text>
            </Label>
            <Input width={'480px'} value={title} disabled={true} />
          </Item>

          <Spacer size={30} />

          <List>
            {Object.values(fieldMap)
              .sort((a, b) => a.index - b.index)
              .map(qf => (
                <FixedQuestionnaireField key={qf.uid} field={qf} />
              ))}
          </List>
        </Content>
      </Container>
    )
  },
)

const Container = styled.div`
  display: flex;
  padding-right: 70px;
`

const Content = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  background-color: ${blue[10]};
  border-radius: 10px;
  border: 1px solid ${gray[40]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 32px 40px;
  position: relative;
`

const List = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Label = styled.div`
  width: 80px;
`
