import {
  CreateOrGetPreSessionDocument,
  CreateOrGetPreSessionMutation,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

export type CreateOrGetPreSessionRes =
  CreateOrGetPreSessionMutation['createOrGetExpPreSession']['explanationPreSession']

export const createOrGetPreSession = async ({
  explanationRevisionUid,
  isPatient,
}: {
  explanationRevisionUid: string
  isPatient: boolean
}): Promise<CreateOrGetPreSessionRes> => {
  try {
    const res = await graphqlRequest(CreateOrGetPreSessionDocument, {
      input: {
        explanationRevisionUid,
      },
      isPatient,
    })
    return res.createOrGetExpPreSession.explanationPreSession
  } catch (error) {
    throw error
  }
}
