import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react'
import { History } from 'src/components/__legacy__icon/monochrome'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { RepeatableSectionHistoryDrawer } from '../RepeatableSectionHistoryDrawer/RepeatableSectionHistoryDrawer'

type Props = {
  fid: string
  fieldName: string
}

export const RepeatableSectionHistoryButton: React.FC<Props> = ({
  fid,
  fieldName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { fetchedWorksheet } = useWorksheetValueContext()

  if (
    fetchedWorksheet.latestWorksheetLog.fieldValues.filter(
      ({ fid: fieldId }) => fieldId === fid,
    ).length === 0
  ) {
    return null
  }

  return (
    <>
      <Tooltip label="変更履歴">
        <IconButton
          aria-label="変更履歴を表示"
          variant="customIconButtonGhost"
          colorScheme="blue"
          icon={<History />}
          onClick={onOpen}
        />
      </Tooltip>
      <RepeatableSectionHistoryDrawer
        fid={fid}
        worksheetUid={fetchedWorksheet.uid}
        isOpen={isOpen}
        onClose={onClose}
        fieldName={fieldName}
      />
    </>
  )
}
