import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'
import { createApiClient } from '../../../../lib/api-client'
import { useSelectedTrial } from '../../../auth/context'
import { Trial } from '../../../trial/types'

const sendTestEmail = async ({
  trialUid,
  email,
}: {
  trialUid: Trial['uid']
  email: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/test_message_by_email',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
      },
      body: {
        email: email,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return
}

export const useSendTestEmail = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()
  return useMutation(
    ({ email }: { email: string }) =>
      sendTestEmail({ email, trialUid: selectedTrial.uid }),
    option,
  )
}
