import dayjs from 'dayjs'
import { parseIcfDocumentRevision } from 'src/features/icfDocument/api/parser'
import {
  IcfDocumentRevision,
  IcfDocumentSet,
} from 'src/features/icfDocument/types'
import { parseGqlMember } from 'src/features/member/api/parser'
import { parseGqlTrailHospital } from 'src/features/trial/api/parser'
import { TrialHospital } from 'src/features/trial/types'
import { ApiResponse } from 'src/lib/api-client'
import * as GqlTypes from 'src/lib/gql-client'
import {
  ExplanationDocRevisionFragment,
  ExplanationDocRevisionBaseFragment,
  ExplanationRevisionFragment,
  ExplanationFragment,
  ExplanationRevisionMemberFragment,
  BrowsingHistoryFragment,
  ChangeDeliveryDateHistoryFragment,
  DeliveryHistoryFragment,
  ExplanationDocSetHistoryFragment,
  ExplanationHistoryFragment,
  SignHistoryFragment,
  GetDocRevisionForPatientFragment,
  DisplayingDocSetFragment,
  GetExplanationDetailQuery,
  ExplanationDocRevisionForPatientSessionFragment,
  GetSessionForPatientLegacyQuery as GetSessionForPatientQuery,
  GetSessionQuery,
} from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'
import { castUid } from 'src/utils/brandedUid'
import { parseDate, parseDateWithException } from 'src/utils/dateParser'

import {
  Explanation,
  ExplanationDetail,
  ExplanationDocSet,
  DisplayingExplanationDocSet,
  ExplanationPatient,
  ExplanationPreSession,
  ExplanationRevision,
  ExplanationRevisionDetail,
  ExplanationRevisionMember,
  ExplanationSession,
  ScheduleHistory,
  SignAuthResult,
  ExplanationDocRevision,
  DocRevisionBase,
  ExplanationDocRevisionSignHistory,
  ExplanationRevisionHistory,
  ExplanationSessionBase,
  ExplanationDocSignHistory,
  DeliveryHistory,
  ChangeDeliveryDateHistory,
  ExplanationDocRevisionBrowsingHistory,
  ExplanationHistory,
  ExplanationDocSetHistory,
  DeliveryHistoryDocument,
  WithdrawHistory,
  ExplanationDocRevisionCreateEnvelopeViewUrlHistory,
} from '../types'

export const parseSignAuthResult = (
  res: ApiResponse<'SignAuthResult'>,
): SignAuthResult => {
  // そのままだが、型を変換する
  //他のAPI同様APIの型に依存させずフロント用の型を用意しておく
  return res
}

export const parseGqlExplanation = ({
  uid,
  isRequiredReAgreement,
  trialHospital,
  latestRevision,
  patient,
}: ExplanationFragment): Explanation => {
  const isAgreementComplete =
    latestRevision.latestHistory.status === 'AgreementDone'
  return {
    uid: castUid<Explanation>(uid),
    isRequiredReAgreement: isRequiredReAgreement,
    agreementCompletedAt: isAgreementComplete
      ? parseDate(latestRevision.latestHistory.createdAt)
      : undefined,
    trialHospital: parseGqlTrailHospital(trialHospital),
    patient: {
      uid: castUid<ExplanationPatient>(patient.uid),
      trialHospitalUid: castUid<TrialHospital>(patient.trialHospitalUid),
      candidateId: patient.candidateId,
      expDiseaseId: patient.expDiseaseId,
      email: patient.email ?? undefined,
      phoneNumber: patient.mobileNumber ?? undefined,
      referredPatientUid: patient.referredPatientUid ?? undefined,
    },
    latestRevision: parseGqlExplanationRevision(latestRevision),
  }
}

const parseGqlExplanationRevision = ({
  uid,
  explanationUid,
  explanationType,
  latestHistory,
  latestChangeDeliveryDateHistory,
  members,
  partnerTrialHospital,
  latestPinSettingHistory,
}: ExplanationRevisionFragment): ExplanationRevision => {
  if (latestHistory.status === 'Invalid') {
    throw new Error('invalid latestHistory status')
  }
  return {
    uid: castUid<ExplanationRevision>(uid),
    explanationUid: castUid<Explanation>(explanationUid),
    type: omitInvalid(explanationType),
    status: latestHistory.status,
    statusV2: latestHistory.statusV2,
    deliveredAt:
      latestChangeDeliveryDateHistory?.updatedDeliveryDate &&
      dayjs(latestChangeDeliveryDateHistory.updatedDeliveryDate).isValid()
        ? dayjs(latestChangeDeliveryDateHistory.updatedDeliveryDate).toDate()
        : undefined,
    members: members.map(parseGqlExplanationRevisionMember),
    partnerTrialHospital: partnerTrialHospital
      ? parseGqlTrailHospital(partnerTrialHospital)
      : undefined,
    pinSettingStatus: latestPinSettingHistory?.status ?? undefined,
  }
}
const parseGqlExplanationRevisionMember = ({
  index,
  isPartner,
  trialMember,
  trialHospital,
}: ExplanationRevisionMemberFragment): ExplanationRevisionMember => {
  return {
    index,
    isPartner,
    trialMember: parseGqlMember(trialMember),
    trialHospital: parseGqlTrailHospital(trialHospital),
  }
}

// Parse GetExplanationDetail response
export const parseGqlExplanationDetail = (
  res: GetExplanationDetailQuery['explanation'],
): ExplanationDetail => {
  return {
    ...parseGqlExplanation(res),
    latestRevision: parseGqlExplanationRevisionDetail(res.latestRevision),
    revisionUids: res.revisions
      .sort((a, b) => {
        return (
          parseDateWithException(b.createdAt).getTime() -
          parseDateWithException(a.createdAt).getTime()
        )
      })
      .map(({ uid }) => {
        return castUid<ExplanationRevision>(uid)
      }),
  }
}
const parseGqlExplanationRevisionDetail = (
  res: GetExplanationDetailQuery['explanation']['latestRevision'],
): ExplanationRevisionDetail => {
  return {
    ...parseGqlExplanationRevision(res),
    docSets: res.docSets.map(parseGqlExplanationDocSet),
    scheduledAt: res.latestScheduleHistory?.scheduledAt || undefined,
    scheduleHistories: res.scheduleHistories?.map(history => ({
      uid: castUid<ScheduleHistory>(history.uid),
      scheduledAt: history.scheduledAt,
      isPartner: history.isPartner,
      member: parseGqlMember(history.trialMember),
      createdAt: history.createdAt,
    })),
    latestSessionStartedAt: res.latestExplanationSession?.startedAt
      ? parseDate(res.latestExplanationSession.startedAt)
      : undefined,
    latestSessionUid: res.latestExplanationSession?.uid
      ? castUid<ExplanationSession>(res.latestExplanationSession.uid)
      : undefined,
    isLatestSessionFinished: res.latestExplanationSession?.isFinished,
    isPreSessionExpired: (() => {
      if (
        res.latestExplanationSession &&
        'explanationPreSession' in res.latestExplanationSession
      ) {
        const resExpiredAt =
          res.latestExplanationSession?.explanationPreSession.expiredAt
        const expiredAt = resExpiredAt ? parseDate(resExpiredAt) : undefined
        return expiredAt ? dayjs(expiredAt).isBefore(dayjs()) : undefined
      }
      return undefined
    })(),
  }
}

const parseGqlExplanationDocSet = ({
  index,
  icfDocumentSet,
  name,
  currentDocuments,
  currentName,
  updatedAfterChecked,
  documentRevisions,
}: DisplayingDocSetFragment): DisplayingExplanationDocSet => {
  return {
    index,
    icfDocSetUid: castUid<IcfDocumentSet>(icfDocumentSet.uid),
    icfDocSetNumberingId: icfDocumentSet.numberingId,
    name,
    currentName,
    updatedAfterChecked,
    currentDocs: currentDocuments.map(doc => ({
      index: doc.index,
      numberingId: doc.numberingId,
      icfDocumentRevision: parseIcfDocumentRevision(doc.icfDocumentRevision),
    })),
    explanationDocRevisions: documentRevisions
      ? documentRevisions.map(parseGqlDocRevision)
      : undefined,
  }
}

export const parseGqlSession = (
  res: GetSessionQuery['explanationSession'],
): ExplanationSession => {
  // NOTE: __typenameで絞り込みたい（明示的に__typenameをクエリに定義しても取得できない場合あり APIとの調整が必要）
  if (res.explanationType === 'Remote' && 'videoCallRoomUid' in res) {
    return {
      ...parseSessionBase(res),
      type: 'Remote',
      videoCallRoomUid: res.videoCallRoomUid,
      explanationPreSession: {
        uid: castUid<ExplanationPreSession>(res.explanationPreSession.uid),
        identifiedAt: res.explanationPreSession.identifiedAt ?? undefined,
        identifierTrialMember: res.explanationPreSession.identifierTrialMember
          ? parseGqlMember(res.explanationPreSession.identifierTrialMember)
          : undefined,
      },
    }
  }
  if (
    res.explanationType === 'RemotePartner' &&
    'videoCallRoomUid' in res &&
    'partnerTrialHospital' in res
  ) {
    return {
      ...parseSessionBase(res),
      type: 'RemotePartner',
      videoCallRoomUid: res.videoCallRoomUid,
      partnerTrialHospital: parseGqlTrailHospital(res.partnerTrialHospital),
      explanationPreSession: {
        uid: castUid<ExplanationPreSession>(res.explanationPreSession.uid),
        identifiedAt: res.explanationPreSession.identifiedAt ?? undefined,
        identifierTrialMember: res.explanationPreSession.identifierTrialMember
          ? parseGqlMember(res.explanationPreSession.identifierTrialMember)
          : undefined,
      },
    }
  }
  if (res.explanationType === 'InPerson') {
    return {
      ...parseSessionBase(res),
      type: 'InPerson',
    }
  }
  throw new Error('invalid session')
}

export const parseGqlSessionForPatient = (
  res: GetSessionForPatientQuery['explanationSession'],
): ExplanationSession => {
  if (res.explanationType === 'Remote' && 'videoCallRoomUid' in res) {
    return {
      ...parseSessionBase(res),
      type: 'Remote',
      videoCallRoomUid: res.videoCallRoomUid,
    }
  }
  throw new Error('invalid session')
}

const parseSessionBase = (
  res:
    | GetSessionQuery['explanationSession']
    | GetSessionForPatientQuery['explanationSession'],
): ExplanationSessionBase => {
  return {
    uid: castUid<ExplanationSession>(res.uid),
    explanationType: omitInvalid(res.explanationType),
    explanationRevision: {
      ...parseGqlExplanationRevision(res.explanationRevision),
      explanation: {
        patient: {
          uid: castUid<ExplanationPatient>(
            res.explanationRevision.explanation.patient.uid,
          ),
          candidateId: res.explanationRevision.explanation.patient.candidateId,
          trialHospitalUid: castUid<TrialHospital>(
            res.explanationRevision.explanation.patient.trialHospitalUid,
          ),
        },
      },
      docSets: res.explanationRevision.docSets.map(docSet => ({
        uid: castUid<ExplanationDocSet>(docSet.uid),
        name: docSet.name,
        index: docSet.index,
        icfDocSetUid: castUid<IcfDocumentSet>(docSet.icfDocumentSet.uid),
        icfDocSetNumberingId: docSet.icfDocumentSet.numberingId,
        docRevisions: docSet.documentRevisions
          ? docSet.documentRevisions.map(parseGqlDocRevision)
          : [],
      })),
    },
    withRepresentative: res.withRepresentative,
    members: res.members.map(member => ({
      index: member.index,
      isPartner: member.isPartner,
      trialMember: parseGqlMember(member.trialMember),
      trialHospital: parseGqlTrailHospital(member.trialHospital),
    })),
    startedAt: res.startedAt,
    finishedAt: res.finishedAt ?? undefined,
  }
}

export const parseGqlExplanationHistory = (
  res: ExplanationHistoryFragment,
): ExplanationHistory => {
  return {
    explanationRevisionUid: castUid<ExplanationRevision>(res.uid),
    createdAt: parseDateWithException(res.createdAt),
    explanationRevisionHistory: parseGqlExplanationRevisionHistory(res),
    explanationDocSetHistories: res.docSets.map(
      parseGqlExplanationDocSetHistory,
    ),
    deliveryHistories: res.deliveryHistories.map(parseGqlDeliveryHistory),
    changeDeliveryHistories: res.changeDeliveryDateHistories.map(
      parseGqlChangeDeliveryHistory,
    ),
    explanationSessions: res.explanationSessions
      .map(x => {
        return {
          uid: castUid<ExplanationSession>(x.uid),
          startedAt: x.startedAt,
        }
      })
      .sort((a, b) => {
        return (
          parseDateWithException(b.startedAt).getTime() -
          parseDateWithException(a.startedAt).getTime()
        )
      }),
  }
}

const parseGqlDeliveryHistory = (
  res: DeliveryHistoryFragment,
): DeliveryHistory => {
  return {
    uid: castUid<DeliveryHistory>(res.uid),
    deliveryType: omitInvalid(res.deliveryType),
    createdAt: parseDateWithException(res.createdAt),
    // 患者の場合は recipientPatientEmail 、治験メンバーの場合は recipientTrialMemberEmail が入る
    email:
      res.recipientPatientEmail ?? res.recipientTrialMemberEmail ?? undefined,
    isPartner: res.isPartner,
    member: !!res.trialMember ? parseGqlMember(res.trialMember) : undefined,
    documents: res.deliveredDocuments
      .map(({ explanationDocRevision }) => {
        return parseDeliveryHistoryDocument(explanationDocRevision)
      })
      .sort((a, b) => a.numberingId - b.numberingId),
  }
}

const parseDeliveryHistoryDocument = (
  res: ExplanationDocRevisionBaseFragment,
): DeliveryHistoryDocument => {
  return {
    uid: castUid<DeliveryHistoryDocument>(res.uid),
    numberingId: res.icfDocumentRevision.icfDocument.numberingId,
    documentName: res.icfDocumentRevision.name,
    documentType: res.documentType,
    version: res.icfDocumentRevision.version,
    documentSetUid: castUid<ExplanationDocSetHistory>(res.explanationDocSetUid),
  }
}

const parseGqlChangeDeliveryHistory = (
  res: ChangeDeliveryDateHistoryFragment,
): ChangeDeliveryDateHistory => {
  return {
    uid: castUid<ChangeDeliveryDateHistory>(res.uid),
    updatedDeliveryDate: parseDateWithException(res.updatedDeliveryDate),
    createdAt: parseDateWithException(res.createdAt),
    reason: res.reason ?? undefined,
    member: !!res.trialMember ? parseGqlMember(res.trialMember) : undefined,
    isPartner: res.isPartner,
  }
}

const parseGqlExplanationDocSetHistory = (
  res: ExplanationDocSetHistoryFragment,
): ExplanationDocSetHistory => {
  const { icfDocumentSet, documentRevisions } = res
  return {
    uid: castUid<ExplanationDocSetHistory>(res.uid),
    numberingId: icfDocumentSet.numberingId,
    name: icfDocumentSet.name,
    explanationDocuments: documentRevisions?.map(parseGqlDocRevision) ?? [],
    signHistories:
      documentRevisions
        ?.filter(
          (doc): doc is GqlTypes.ExplanationDocRevisionAgreementForm =>
            doc.documentType === 'AgreementForm',
        )
        .flatMap(parseSignHistories) ?? [],
  }
}

const parseGqlBrowsingHistory = (
  res: BrowsingHistoryFragment,
): ExplanationDocRevisionBrowsingHistory => {
  return {
    uid: castUid<ExplanationDocRevisionBrowsingHistory>(res.uid),
    browserRole: omitInvalid(res.browserRole),
    operatedAt: parseDateWithException(res.operatedAt),
    member: res.trialMember ? parseGqlMember(res.trialMember) : undefined,
    isPartner: res.isPartner,
  }
}

const parseSignHistories = (
  res: GqlTypes.ExplanationDocRevisionAgreementForm,
): ExplanationDocSignHistory[] => {
  const { file, isPatientConsentRequired, signHistories, icfDocumentRevision } =
    res
  return signHistories.map(
    ({
      uid,
      isRejected,
      isPartner,
      trialMember,
      operatedAt,
      signerRole,
      rejectedReason,
      latestCreateEnvelopeViewUrlHistory: viewUrlHist,
    }) => {
      return {
        uid: castUid<ExplanationDocSignHistory>(uid),
        isRejected,
        operatedAt: parseDateWithException(operatedAt),
        numberingId: icfDocumentRevision.icfDocument.numberingId,
        name: icfDocumentRevision.name,
        signedFileUrl: file?.fileUrl ?? undefined,
        isPatientConsentRequired,
        member: !!trialMember ? parseGqlMember(trialMember) : undefined,
        isPartner,
        signerRole,
        fileUrl: (() => {
          if ('fileURL' in icfDocumentRevision) {
            return icfDocumentRevision.fileURL
          }
          return ''
        })(),
        rejectedReason: rejectedReason ?? undefined,
        latestCreateEnvelopeViewUrlHistory: !!viewUrlHist
          ? {
              uid: castUid<ExplanationDocRevisionCreateEnvelopeViewUrlHistory>(
                viewUrlHist.uid,
              ),
              createType: viewUrlHist.createType,
              savedAt: parseDateWithException(viewUrlHist.savedAt),
              creator: !!viewUrlHist.creator
                ? parseGqlMember(viewUrlHist.creator)
                : undefined,
            }
          : undefined,
      }
    },
  )
}

const parseGqlExplanationRevisionHistory = (
  res: ExplanationHistoryFragment,
): ExplanationRevisionHistory => {
  return {
    explanationType: omitInvalid(res.explanationType),
    partnerTrialHospital: !!res.partnerTrialHospital
      ? parseGqlTrailHospital(res.partnerTrialHospital)
      : undefined,
    withRepresentative: res.withRepresentative,
    createdAt: parseDateWithException(res.createdAt),
    members: res.members
      .map(member => ({
        index: member.index,
        isPartner: member.isPartner,
        trialMember: parseGqlMember(member.trialMember),
        trialHospital: parseGqlTrailHospital(member.trialHospital),
      }))
      .sort((a, b) => a.index - b.index),
  }
}

// TODO: typenameで絞り込み
export const parseGqlDocRevision = (
  docRev:
    | ExplanationDocRevisionFragment
    | ExplanationDocRevisionForPatientSessionFragment
    | GetDocRevisionForPatientFragment,
): ExplanationDocRevision => {
  if (docRev.documentType === 'Undefined') {
    throw new Error('documentType is undefined')
  }

  const base: DocRevisionBase = {
    uid: castUid<ExplanationDocRevision>(docRev.uid),
    index: docRev.index,
    icfDocumentNumberingId: (() => {
      if ('icfDocument' in docRev.icfDocumentRevision) {
        return docRev.icfDocumentRevision.icfDocument.numberingId
      }
      return undefined
    })(),
    icfDocumentRevisionUid: castUid<IcfDocumentRevision>(
      docRev.icfDocumentRevision.uid,
    ),
    icfDocumentRevisionName: docRev.icfDocumentRevision.name,
    icfDocumentRevisionType: docRev.icfDocumentRevision.icfDocumentType,
    icfDocumentRevisionVersion: docRev.icfDocumentRevision.version,
    icfDocumentRevisionIsRequiredAgreement: (() => {
      if ('isConsentRequired' in docRev.icfDocumentRevision) {
        return docRev.icfDocumentRevision.isConsentRequired
      }
      return false
    })(),
    browsingHistories: docRev.browsingHistories.map(parseGqlBrowsingHistory),
    isCompleted: docRev.isCompleted,
  }

  // NOTE: __typenameで絞り込みたい（明示的に__typenameをクエリに定義しても取得できない場合あり APIとの調整が必要）
  if (docRev.documentType === 'AgreementForm') {
    if (
      'docusignEnvelopeId' in docRev &&
      'signerMembers' in docRev &&
      'signHistories' in docRev
    ) {
      return {
        ...base,
        type: 'AgreementForm',
        dsEnvelopeId: docRev.docusignEnvelopeId ?? '',
        signerMembers: docRev.signerMembers
          .map(m => m.trialMember)
          .map(parseGqlMember),
        isPatientConsentRequired: docRev.isPatientConsentRequired,
        signHistories: docRev.signHistories.map(parseGqlSignHistory),
        icfDocumentRevisionHasCrcField: (() => {
          if ('hasCrcField' in docRev.icfDocumentRevision) {
            return docRev.icfDocumentRevision.hasCrcField
          }
          return false
        })(),
      }
    }
    throw new Error('invalid AgreementForm')
  }

  if (docRev.documentType === 'CheckUnderstanding') {
    if ('docusignEnvelopeId' in docRev) {
      return {
        ...base,
        type: 'CheckUnderstanding',
        dsEnvelopeId: docRev.docusignEnvelopeId ?? '',
      }
    }

    throw new Error('invalid CheckUnderstanding')
  }

  if (docRev.documentType === 'Description') {
    return {
      ...base,
      type: 'Description',
    }
  }

  if (docRev.documentType === 'Video') {
    return {
      ...base,
      type: 'Video',
    }
  }

  return assertNever(docRev.documentType)
}

export const parseGqlWithdrawHistory = (res: {
  uid: string
  withdrawnDate: string
  comment: string
  fileName?: string | null
  createdAt: string
  fileURL: string
  isPartner: boolean
  trialMember: {
    uid: string
    role: GqlTypes.TrialMemberRole
    user: {
      uid: string
      firstName: string
      lastName: string
    }
  }
}): WithdrawHistory => {
  return {
    uid: castUid<WithdrawHistory>(res.uid),
    withdrawAt: parseDateWithException(res.withdrawnDate),
    comment: res.comment,
    fileName: res.fileName ?? undefined,
    fileUrl: res.fileURL ?? undefined,
    member: parseGqlMember(res.trialMember),
    isPartner: res.isPartner,
    createdAt: parseDateWithException(res.createdAt),
  }
}

const parseGqlSignHistory = (
  res: SignHistoryFragment,
): ExplanationDocRevisionSignHistory => {
  return {
    uid: castUid<ExplanationDocRevisionSignHistory>(res.uid),
    rejectedReason: res.rejectedReason ?? undefined,
    isRejected: res.isRejected,
    signerRole: omitInvalid(res.signerRole),
    isPartner: res.isPartner,
    member: !!res.trialMember ? parseGqlMember(res.trialMember) : undefined,
    operatedAt: res.operatedAt,
  }
}

const omitInvalid = <T extends string>(type: T): Exclude<T, 'Invalid'> => {
  if (type === 'Invalid') {
    throw new Error('Invalid explanationType')
  }
  return type as Exclude<T, 'Invalid'>
}
