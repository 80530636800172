import React, { useCallback, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import { Followup } from 'src/components/icon'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

const useChangeTemplateFolderName = (initialName: string) => {
  const [templateFolderName, setTemplateFolderName] =
    useState<string>(initialName)
  const onChangeTemplateFolderName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTemplateFolderName(e.target.value)
    },
    [],
  )

  return { templateFolderName, onChangeTemplateFolderName }
}

type Props = {
  v2: boolean
  isFollowup: boolean
  initialName: string
  requesting: boolean

  onClose: () => void
  onSubmit: (name: string) => void
}

export const EditModal: React.FC<Props> = props => {
  const { templateFolderName, onChangeTemplateFolderName } =
    useChangeTemplateFolderName(props.initialName)

  const onSubmit = useCallback(() => {
    props.onSubmit(templateFolderName)
  }, [props, templateFolderName])

  const canSubmit = !props.requesting && !!templateFolderName

  return (
    <Modal onClose={props.onClose} isOpen>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>フォルダ名を編集</ModalHeader>

        <ModalBody>
          <Stack spacing="3">
            <Input
              value={templateFolderName}
              onChange={onChangeTemplateFolderName}
            />
            {props.v2 && (
              <Checkbox disabled isChecked={props.isFollowup}>
                <Flex gap="1.5" align="center">
                  <Box color="gray.500">
                    <Followup />
                  </Box>
                  随時観察
                </Flex>
              </Checkbox>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button onClick={onSubmit} isDisabled={!canSubmit}>
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
