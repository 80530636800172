import { assertNever } from 'src/utils/assertNever'

import { DataType, ItemType, SmallItem } from '../entities/small-item/entity'
import { SmallItemAnswer } from '../entities/small-item-answer/entity'

export const hasValue = (smallItem: SmallItem | SmallItemAnswer) => {
  switch (smallItem.itemType) {
    case ItemType.Text:
    case ItemType.Date:
    case ItemType.Number:
    case ItemType.NullableDate:
    case ItemType.HourMinute:
    case ItemType.TextArea:
      return true
    case ItemType.Radio:
    case ItemType.SelectMenu:
    case ItemType.CheckBox:
    case ItemType.File:
      return false
    case ItemType.Label:
      return smallItem.dataType === DataType.Clinical
    default:
      return assertNever(smallItem.itemType)
  }
}

export const hasChoice = (smallItem: SmallItem | SmallItemAnswer) => {
  switch (smallItem.itemType) {
    case ItemType.Radio:
    case ItemType.SelectMenu:
    case ItemType.CheckBox:
      return true
    case ItemType.Text:
    case ItemType.Date:
    case ItemType.Number:
    case ItemType.NullableDate:
    case ItemType.HourMinute:
    case ItemType.Label:
    case ItemType.TextArea:
    case ItemType.File:
      return false
    default:
      return assertNever(smallItem.itemType)
  }
}

export const isDateAndTimeType = (smallItem: SmallItem | SmallItemAnswer) => {
  return [ItemType.Date, ItemType.NullableDate, ItemType.HourMinute].includes(
    smallItem.itemType,
  )
}

export const isTextType = (smallItem: SmallItem | SmallItemAnswer) => {
  return [ItemType.Text, ItemType.TextArea].includes(smallItem.itemType)
}

/**
 * Text/Date系のSmallItemから、より一般化したItemType(Text/Date)を取得する
 * @param smallItem SmallItem
 * @return ItemType(Text/Date)
 */
export const getGeneralItemType: (
  smallItem: SmallItem | SmallItemAnswer,
) => ItemType = smallItem => {
  return isDateAndTimeType(smallItem)
    ? ItemType.Date
    : isTextType(smallItem)
      ? ItemType.Text
      : smallItem.itemType
}

export const isLabelTypeWithEdc = (smallItem: SmallItem | SmallItemAnswer) => {
  return (
    smallItem.itemType === ItemType.Label &&
    smallItem.dataType === DataType.Clinical
  )
}

export const isClinical = (smallItem: SmallItem | SmallItemAnswer) =>
  smallItem.dataType === DataType.Clinical
