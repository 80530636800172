import dayjs from 'dayjs'

import { Schedule } from './entity'
import { Patient } from '../patient/entity'

export const answerStartDate = ({
  schedule,
  answerStartDate,
}: {
  schedule: Schedule
  answerStartDate: Patient['answerStartDate']
}) => {
  if (!answerStartDate || schedule.shouldDeliverAfterTrial) return ''

  return dayjs(answerStartDate)
    .add(schedule.startAfterDays, 'day')
    .format('MM/DD')
}
