import { HttpResponse } from 'msw'
import {
  createWorksheetLogMock,
  DisableWorksheetDocument,
  DisableWorksheetMutation,
  DisableWorksheetMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

import { worksheetWithSchemaResponseFactory } from '../../testUtils/factory'

export const disableWorksheetHandler = graphQLHandlerFactory<
  DisableWorksheetMutation,
  DisableWorksheetMutationVariables
>('mutation', DisableWorksheetDocument, () => {
  return HttpResponse.json({
    data: {
      disableWorksheet: {
        worksheet: worksheetWithSchemaResponseFactory({
          latestWorksheetLog: createWorksheetLogMock({
            status: 'Disabled',
          }),
        }),
      },
    },
  })
})
