import { ExplanationType } from '../types'

const typeTextMap: Record<ExplanationType, string> = {
  InPerson: '対面同意',
  Remote: '遠隔同意',
  RemotePartner: '遠隔同意（パートナー施設）',
}

export const explanationTypeToText = (type: ExplanationType): string =>
  typeTextMap[type]
