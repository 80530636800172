import React from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { ArrowDown, ArrowUp, Close } from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { DataType } from 'src/modules/entities/small-item/entity'
import { FlexItem } from 'styled-flex-component'

import { Value } from './components/value'

type Props = {
  mediumItem: MediumItem
  releaseTried: boolean
  canUp: boolean
  canDown: boolean
  v2: boolean

  onUp: () => void
  onDown: () => void
  onChange: (values: Partial<MediumItem>) => void
  onDelete: () => void
}

const shouldShowError = (value: string) => {
  return !value
}

export const Group: React.FC<Props> = props => {
  const {
    mediumItem,
    releaseTried,
    canUp,
    canDown,
    v2,
    onChange,
    onDelete,
    onUp,
    onDown,
  } = props
  const { title, folderName, formName, isLogline } = mediumItem
  const canControlMediumItem = !mediumItem.presetUsed
  const hasClinicalSmallItem = Object.values(mediumItem.smallItemMap).some(
    si => si.dataType === DataType.Clinical,
  )

  return (
    <HStack spacing="1" w="full">
      <Stack>
        <IconButton
          icon={<ArrowUp />}
          variant="customIconButtonGhost"
          aria-label="フィールドを上に移動する"
          isDisabled={!canUp}
          onClick={onUp}
        />

        <IconButton
          icon={<ArrowDown />}
          variant="customIconButtonGhost"
          aria-label="フィールドを下に移動する"
          isDisabled={!canDown}
          onClick={onDown}
        />
      </Stack>
      <Box
        flex="1"
        bg="blue.50"
        p="5"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="base"
        pos="relative"
      >
        <Flex>
          <Box>
            <Box>
              <Flex direction="row" align="center">
                <FlexItem grow={1}>
                  {!canControlMediumItem && <Value>{mediumItem.title}</Value>}
                  {!canControlMediumItem && (
                    <Text fontSize="xs" color="gray.100">
                      このセクションは削除できません
                    </Text>
                  )}
                  <HStack spacing="10">
                    {canControlMediumItem && (
                      <FormControl
                        isInvalid={releaseTried && shouldShowError(title)}
                      >
                        <FormLabel>セクション名</FormLabel>
                        <Input
                          w="490px"
                          placeholder="セクション名を入力"
                          value={title}
                          onChange={e => onChange({ title: e.target.value })}
                        />
                      </FormControl>
                    )}

                    <FormControl>
                      <FormLabel>ログライン</FormLabel>
                      <Switch
                        size="lg"
                        colorScheme="green"
                        isChecked={isLogline}
                        onChange={() => {
                          onChange({ isLogline: !isLogline })
                        }}
                        isDisabled={!canControlMediumItem}
                      />
                    </FormControl>
                  </HStack>
                </FlexItem>
              </Flex>
            </Box>

            <Spacer size={20} />

            {!v2 && (
              <HStack>
                <FormControl
                  isInvalid={
                    releaseTried &&
                    hasClinicalSmallItem &&
                    shouldShowError(folderName)
                  }
                >
                  <FormLabel>EDCフォルダ名</FormLabel>
                  <Input
                    onChange={e => onChange({ folderName: e.target.value })}
                    value={folderName}
                  />
                </FormControl>

                <FormControl
                  isInvalid={
                    releaseTried &&
                    hasClinicalSmallItem &&
                    shouldShowError(formName)
                  }
                >
                  <FormLabel>EDCフォーム名</FormLabel>
                  <Input
                    onChange={e => onChange({ formName: e.target.value })}
                    value={formName}
                  />
                </FormControl>
              </HStack>
            )}
          </Box>

          {canControlMediumItem && (
            <IconButton
              pos="absolute"
              top="3"
              right="3"
              variant="customIconButtonGhost"
              aria-label="Delete Section"
              icon={<Close />}
              onClick={onDelete}
            />
          )}
        </Flex>
      </Box>
    </HStack>
  )
}
