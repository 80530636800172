import React from 'react'

import { Text } from '@chakra-ui/react'
import { red, gray } from 'src/components/base/color/palette'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

type Props = {
  requesting: boolean
  errorMessage: string
  answerStateDate: string
  onSubmit: () => void
  onClose: () => void
}

export const ConfirmAnswerStateDateModal: React.FC<Props> = props => {
  return (
    <SubmitModal
      title="回答の開始"
      submitText="確定する"
      requesting={props.requesting}
      errorMessage={props.errorMessage}
      onSubmit={props.onSubmit}
      onClose={props.onClose}
    >
      <Modal>
        <Text textAlign="center">
          試験開始日を確定すると、患者に質問票が配信されます。
        </Text>
        <Spacer horizontal size={30} />
        <Text color={gray[80]} textAlign="center">
          試験開始日
        </Text>
        <Spacer horizontal size={16} />
        <Text fontSize="2xl" fontWeight="bold" textAlign="center">
          {props.answerStateDate}
        </Text>
        <Spacer horizontal size={4} />
        <Text color={red[50]} textAlign="center">
          ※確定後は開始日の変更はできません
        </Text>
      </Modal>
    </SubmitModal>
  )
}

const Modal = styled.div``
