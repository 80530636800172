import { generatePath, Navigate, useParams } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { VideoCallAlreadyFinishedScreen } from 'src/features/videoCall/components/VideoCallAlreadyFinishedScreen/VideoCallAlreadyFinishedScreen'

import { assertNever } from '../../../../utils/assertNever'
import { useChallengePatientVideoCall } from '../../api/challengePatientVideoCall'

type Params = {
  trialUid: string
  patientVideoCallUid: string
}

type Props = {
  children: ({
    diseaseUid,
    hospitalName,
  }: {
    diseaseUid?: string
    hospitalName?: string
  }) => React.ReactNode
}

export const PatientVideoCallGuard: React.FC<Props> = ({ children }) => {
  const { patientVideoCallUid = '', trialUid = '' } = useParams<Params>()

  const signinScreenPath = generatePath(Paths.PatientVideoCallSignIn, {
    patientVideoCallUid,
    trialUid,
  })

  const isSigninScreen = window.location.pathname === signinScreenPath

  const { data } = useChallengePatientVideoCall({
    trialUid,
    patientVideoCallUid,
    refreshInterval: previous => {
      // 通話可能の状態なら30秒おきに再challengeをして状態を確認しておく。
      if (previous?.result === 'Authorized') return 30000
      return 0
    },
  })

  if (!data) return null

  switch (data.result) {
    case 'VideoCallHasBeenFinished':
      return <VideoCallAlreadyFinishedScreen />
    case 'NewVideoCallExists':
      if (data.newPatientVideoCallUid === undefined) return null
      return (
        <VideoCallAlreadyFinishedScreen
          nextUrl={generatePath(Paths.PatientVideoCallForPatient, {
            patientVideoCallUid: data.newPatientVideoCallUid,
            trialUid: trialUid,
          })}
        />
      )
    case 'UserHasNoSession':
      if (isSigninScreen) {
        return (
          <>
            {children({
              diseaseUid: data.diseaseUid,
              hospitalName: data.hospitalName,
            })}
          </>
        )
      }
      return <Navigate replace to={signinScreenPath} />
    case 'Authorized':
      if (isSigninScreen) {
        return (
          <Navigate
            replace
            to={generatePath(Paths.PatientVideoCallForPatient, {
              trialUid,
              patientVideoCallUid,
            })}
          />
        )
      }
      return (
        <>
          {children({
            diseaseUid: data.diseaseUid,
            hospitalName: data.hospitalName,
          })}
        </>
      )
    default:
      assertNever(data.result)
      return null
  }
}
