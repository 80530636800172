import { useCallback, useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Spinner } from 'src/components/spinner/spinner'
import { getCurrentUser } from 'src/modules/entities/account/selector'
import styled from 'styled-components'

import { submitCapture } from './submit-capture'
import { worksheetIdParamName } from '../dashboard/trial/detail/patient/detail/worksheet/common/detail/worksheet-detail'
import { trialIdParamName } from '../dashboard/trial/detail/trial-detail'
import { useInit } from '../dashboard/use-init'
import { SERVER_URL } from '../server/const'
import { useRequestState } from '../server/use-request-state'

export const patientUidParamName = 'patientUid'

export const DoctorVideoCallContainer = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/call-elements-standalone-0.0.13.js'
    script.type = 'module'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientUidParamName]: string
    [worksheetIdParamName]: string
  }>()

  const [videoCallUid, setVideoCallUid] = useState<string | null>(null)

  const { request } = useUploadImage()

  const uploadImage = useCallback(
    (base64Image: string) => {
      ;(async () => {
        try {
          await request({
            trialUid,
            patientUid,
            worksheetUid,
            videoCallUid: videoCallUid!,
            base64Image,
          })
        } catch (e) {
          console.error(e)
        }
      })()
    },
    [patientUid, request, trialUid, videoCallUid, worksheetUid],
  )

  const el = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (el.current) {
      const elc = el.current

      const saveHandler = (event: any) => {
        const base64: string = (event as any).detail
        uploadImage(base64)
      }
      const generateVideoCallHandler = (event: any) => {
        const videoCallUid: string = (event as any).detail
        if (videoCallUid) {
          setVideoCallUid(videoCallUid)
        }
      }

      elc.addEventListener('saveCapturedImage', saveHandler)
      elc.addEventListener('generateVideoCall', generateVideoCallHandler)

      return () => {
        elc.removeEventListener('saveCapturedImage', saveHandler)
        elc.removeEventListener('generateVideoCall', generateVideoCallHandler)
      }
    }
  }, [el, uploadImage])

  const { initialized } = useInit()

  const account = useSelector(getCurrentUser)
  const trial = account && account.selectedTrial
  const videoCaptureEnabled = !!trial ? trial.videoCaptureEnabled : false

  if (!initialized) return <Spinner />

  // FOR DEBUG
  // const baseUrl = 'http://localhost:3000/api/'
  // const episodeUid = '0CAVQg5-sALFpYssDnwRMqIH9TUb0uucPr-NEGB37Gc'
  // const userUid = '4jZ_cVsdGkkCtZMGAjuBm0W3XsoE55RwEg-Yjk6qZY0'
  // const clinicUid = 'vs8BmTQZn-BesDol6YUpykuGkPOLs2iVOOgfNNyhlu8'

  const baseUrl = SERVER_URL + '/'
  const episodeUid = worksheetUid
  const userUid = !!account ? account.uid : ''
  const clinicUid = !!trial ? trial.selectedTrialHospitalUid : ''

  return (
    <Container>
      <curon-call
        ref={el}
        episode-uid={episodeUid}
        user-uid={userUid} // user uid は log のために使われているのみ
        clinic-uid={clinicUid}
        api-base-url={baseUrl}
        autocall={true}
        capture-enabled={videoCaptureEnabled}
      ></curon-call>
    </Container>
  )
}

const useUploadImage = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()

  const request = async ({
    trialUid,
    patientUid,
    worksheetUid,
    videoCallUid,
    base64Image,
  }: {
    trialUid: string
    patientUid: string
    worksheetUid: string
    videoCallUid: string
    base64Image: string
  }) => {
    try {
      requestStarted()

      await submitCapture({
        trialUid,
        patientUid,
        worksheetUid,
        videoCallUid,
        base64Image,
      })

      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`
