import { Button, IconButton, Tooltip } from '@chakra-ui/react'
import { Reload } from 'src/components/__legacy__icon/monochrome'
import { useSpinner } from 'src/hooks/use-spinner'
import { assertNever } from 'src/utils/assertNever'

import { useSyncAllEnvelopes } from '../../api'
import { ExplanationSession } from '../../types'

type Props = {
  buttonType: 'text' | 'icon'
  sessionUid: ExplanationSession['uid']
  onSyncDone?: () => void
}

export const SyncAllEnvelopes: React.FC<Props> = ({
  buttonType,
  sessionUid,
  onSyncDone,
}) => {
  const { showSpinner, hideSpinner } = useSpinner()

  const { request: syncAllEnvelopes } = useSyncAllEnvelopes({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      onSyncDone?.()
    },
  })

  const onClick = async () => {
    await syncAllEnvelopes({ explanationSessionUid: sessionUid })
  }

  switch (buttonType) {
    case 'text':
      return (
        <Button variant="outline" onClick={onClick}>
          同意書の最新状態を取得
        </Button>
      )
    case 'icon':
      return (
        <Tooltip label="最新の情報に更新">
          <IconButton
            onClick={onClick}
            aria-label="最新の署名状況を取得"
            variant="customIconButtonGhost"
            icon={<Reload />}
          />
        </Tooltip>
      )
    default:
      return assertNever(buttonType)
  }
}
