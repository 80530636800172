import React from 'react'

import { IconButton } from '@chakra-ui/react'
import { Edit } from 'src/components/icon'
import styled from 'styled-components'

import { useOpenModal } from '../../../../../../../../hooks/use-open-modal'
import { EditModalContainer } from '../modal/edit-modal-container'

type Props = {
  templateFolderUid: string
  v2: boolean
}

export const EditButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <Wrapper>
      <IconButton
        icon={<Edit />}
        aria-label="テンプレートフォルダ名編集モーダルを開く"
        onClick={e => {
          e.stopPropagation()
          openModal()
        }}
        variant="customIconButtonGhost"
      />

      {modalOpen && (
        <EditModalContainer
          v2={props.v2}
          onClose={closeModal}
          templateFolderUid={props.templateFolderUid}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
