import React from 'react'

import { useParams } from 'react-router-dom'

import { DownloadComponent } from './download'
import { trialIdParamName } from '../../../routes'

export const CrfDownload: React.FC = () => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()
  return <DownloadComponent trialUid={trialUid} />
}
