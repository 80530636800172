import { TrialHospitalFragment } from 'src/lib/gql-client'
import { castUid } from 'src/utils/brandedUid'

import { TrialHospital } from '../types'

export const parseGqlTrailHospital = (
  res: TrialHospitalFragment,
): TrialHospital => {
  return {
    uid: castUid<TrialHospital>(res.uid),
    name: res.hospital.name,
    role: res.role,
    mainTrialHospitalUid: res.mainTrialHospital?.uid
      ? castUid<TrialHospital>(res.mainTrialHospital.uid)
      : undefined,
  }
}
