import React from 'react'

import { Text } from '@chakra-ui/react'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import { IconButton } from '../icon-button/icon-button'

type Props = {
  totalCount: number
  perPage: number
  currentPage: number
  hasNextPage: boolean
  hasPrevPage: boolean
  onClickNext: () => void
  onClickBack: () => void
  onClickEnd: () => void
  onClickTop: () => void
}

export const Pagination: React.FC<Props> = ({
  totalCount,
  perPage,
  currentPage,
  hasNextPage,
  hasPrevPage,
  onClickNext,
  onClickBack,
  onClickTop,
  onClickEnd,
}) => {
  const currentOffset = perPage * (currentPage - 1)
  const currentLimit = perPage * currentPage

  const start = totalCount === 0 ? 0 : currentOffset + 1
  const end = currentLimit < totalCount ? currentLimit : totalCount
  const currentRange = `${start} - ${end}`

  return (
    <Container>
      <IconButton
        iconName="paginationTop"
        onClick={onClickTop}
        disabled={!hasPrevPage}
      />
      <Spacer size={8} horizontal />
      <IconButton
        iconName="paginationBack"
        onClick={onClickBack}
        disabled={!hasPrevPage}
      />
      <Spacer size={8} horizontal />
      <Text fontSize="sm">{currentRange}</Text>
      <Spacer size={8} horizontal />
      <Text fontSize="sm">/</Text>
      <Spacer size={8} horizontal />
      <Text fontSize="sm">{`${totalCount} 件`}</Text>
      <Spacer size={8} horizontal />
      <IconButton
        iconName="paginationNext"
        onClick={onClickNext}
        disabled={!hasNextPage}
      />
      <Spacer size={8} horizontal />
      <IconButton
        iconName="paginationEnd"
        onClick={onClickEnd}
        disabled={!hasNextPage}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`
