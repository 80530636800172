import { Box, Center, Flex, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo-full.svg'

type Props = {
  children: React.ReactNode
}

export const SimpleScreenLayout: React.FC<Props> = ({ children }) => {
  return (
    <Flex direction="column" h="100vh" w="100vw">
      <Flex
        as="header"
        position="sticky"
        top="0"
        w="full"
        p="4"
        align="center"
        h="64px"
        borderBottom="1px"
        borderColor="gray.100"
      >
        <img src={MirohaLogo} alt="miroha logo" width={126.55} />
      </Flex>
      <Flex
        direction="column"
        as="main"
        flex="1"
        justify="space-between"
        overflow="auto"
      >
        <Box maxW="1024px" mx="auto" pb="124px" px="6" w="full">
          {children}
        </Box>
        <Center as="footer" w="full" p="4" h="76px">
          <Text color="gray.400">© All Right Reserved by MICIN.INC</Text>
        </Center>
      </Flex>
    </Flex>
  )
}
