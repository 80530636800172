// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgTelemedicine = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeWidth={2}
      d="M4 30h24M16 30v-6"
    />
    <rect
      width={30}
      height={22}
      x={1}
      y={1}
      stroke={props.color ?? 'currentColor'}
      strokeWidth={2}
      rx={5}
    />
    <g
      fill={props.color ?? 'currentColor'}
      clipPath="url(#telemedicine_svg__a)"
    >
      <path
        fillRule="evenodd"
        d="M5.125 20a11.536 11.536 0 0 1 5.405-6.369A7.479 7.479 0 0 0 16 16a7.48 7.48 0 0 0 5.47-2.369A11.537 11.537 0 0 1 26.875 20H5.125Z"
        clipRule="evenodd"
      />
      <circle cx={16} cy={8.5} r={5.5} />
    </g>
    <defs>
      <clipPath id="telemedicine_svg__a">
        <path fill="#fff" d="M5 3h22v17H5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgTelemedicine
