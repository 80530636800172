import { Button, useDisclosure } from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { SelectItem } from 'src/components/Select/Select'
import { getFullName } from 'src/utils/getFullName'

import { DeliveryDocsModal } from './DeliveryDocsModal'
import { ExplanationRevision, ExplanationRevisionMember } from '../../types'

type Props = {
  explanationRevision: ExplanationRevision
  mutateSession: () => void
}

export const DeliveryDocs: React.FC<Props> = ({
  explanationRevision,
  mutateSession,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toMailMemberSelectItem = (
    revMember: ExplanationRevisionMember,
  ): SelectItem<string, React.ReactNode> => ({
    value: revMember.trialMember.uid,
    label: (
      <MemberLabel
        role={revMember.trialMember.role}
        displayName={getFullName(revMember.trialMember)}
        isPartner={revMember.isPartner}
      />
    ),
  })

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="green"
        isDisabled={explanationRevision.status !== 'AgreementDone'}
      >
        署名済文書交付
      </Button>

      <DeliveryDocsModal
        explanationRevisionUID={explanationRevision.uid}
        isRemote={explanationRevision.type === 'Remote'}
        explanationRevisionMemberItem={explanationRevision.members.map(
          toMailMemberSelectItem,
        )}
        mutateSession={mutateSession}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
