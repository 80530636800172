import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  QuestionnaireStatus,
  Questionnaire,
} from 'src/modules/entities/questionnaire/entity'
import {
  createEmptyQuestionnaireChoice,
  QuestionnaireChoice,
} from 'src/modules/entities/questionnaire-choice/entity'
import {
  createEmptyQuestionnaireField,
  QuestionnaireField,
} from 'src/modules/entities/questionnaire-field/entity'
import {
  createEmptyQuestionnaireSection,
  QuestionnaireSection,
} from 'src/modules/entities/questionnaire-section/entity'

export type ChoiceValidationError = {
  id: string
  description?: string
  value?: string
}

export type FieldValidationError = {
  id: string
  title?: string
  choiceSize?: string
  choices?: ChoiceValidationError[]
}

export type SectionValidationError = {
  id: string
  title?: string
  fieldSize?: string
  fields?: FieldValidationError[]
}

export type ValidationError = {
  title?: string
  displayName?: string
  sectionSize?: string
  sections?: SectionValidationError[]
}

export type State = {
  originalQuestionnaire: Questionnaire | null
  uid: string
  status: QuestionnaireStatus
  title: string
  displayName: string
  sections: QuestionnaireSection[]
  validationError: ValidationError
}

const initialState: State = {
  originalQuestionnaire: null,
  uid: '',
  status: QuestionnaireStatus.Draft,
  title: '',
  displayName: '',
  sections: [],
  validationError: {},
}

const slice = createSlice({
  name: 'questionnaireDetail',
  initialState,
  reducers: {
    // Questionnaire
    initWithQuestionnaire: (
      state: State,
      action: PayloadAction<{ questionnaire: Questionnaire }>,
    ) => {
      const { questionnaire } = action.payload
      if (!questionnaire) return

      state.originalQuestionnaire = questionnaire
      state.uid = questionnaire.uid
      state.status = questionnaire.status
      state.title = questionnaire.title
      state.displayName = questionnaire.displayName
      state.sections = questionnaire.sections
      state.validationError = {}
    },
    updateQuestionnaire: (
      state: State,
      action: PayloadAction<{
        values: Partial<Pick<Questionnaire, 'title' | 'displayName'>>
      }>,
    ) => {
      const { values } = action.payload
      for (const key in values) {
        const k = key as keyof Pick<State, 'title' | 'displayName'>
        ;(state[k] as any) = values[k] as any
      }
    },

    // Section
    addSection: (state: State) => {
      const nextIndex = state.sections.length
      const empty = createEmptyQuestionnaireSection(nextIndex)
      state.sections.push(empty)
    },
    deleteSection: (state: State, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload
      state.sections.splice(index, 1)

      // 残ったセクションのindexを昇順で抜けがないようにする
      Object.values(state.sections)
        .sort((a, b) => a.index - b.index)
        .forEach((s, idx) => {
          s.index = idx
        })
    },
    updateSection: (
      state: State,
      action: PayloadAction<{
        index: number
        values: Partial<Pick<QuestionnaireSection, 'title'>>
      }>,
    ) => {
      const { index, values } = action.payload
      const section = state.sections[index]
      for (const key in values) {
        const k = key as keyof Partial<Pick<QuestionnaireSection, 'title'>>
        ;(section[k] as any) = values[k] as any
      }
    },
    changeIndexSection: (
      state: State,
      action: PayloadAction<{ index: number; direction: 'up' | 'down' }>,
    ) => {
      const { index, direction } = action.payload
      const swappedIndex = direction === 'up' ? index - 1 : index + 1

      if (swappedIndex < 0 || swappedIndex >= state.sections.length) return
      ;[state.sections[index].index, state.sections[swappedIndex].index] = [
        state.sections[swappedIndex].index,
        state.sections[index].index,
      ]
    },

    // Field
    addField: (
      state: State,
      action: PayloadAction<{ sectionIndex: number }>,
    ) => {
      const { sectionIndex } = action.payload
      const fieldMap = state.sections[sectionIndex].fieldMap

      const nextIndex = Object.keys(fieldMap).length
      const empty = createEmptyQuestionnaireField(nextIndex)

      state.sections[sectionIndex].fieldMap = {
        ...fieldMap,
        [empty.uid]: empty,
      }
    },
    deleteField: (
      state: State,
      action: PayloadAction<{ sectionIndex: number; uid: string }>,
    ) => {
      const { sectionIndex, uid } = action.payload

      const fieldMap = state.sections[sectionIndex].fieldMap
      delete fieldMap[uid]

      // 残ったフィールドのindexを昇順で抜けがないようにする
      Object.values(fieldMap)
        .sort((a, b) => a.index - b.index)
        .forEach((f, idx) => {
          f.index = idx
        })
    },
    updateField: (
      state: State,
      action: PayloadAction<{
        sectionIndex: number
        uid: string
        values: Partial<
          Pick<QuestionnaireField, 'title' | 'answerType' | 'unit'>
        >
      }>,
    ) => {
      const { sectionIndex, uid, values } = action.payload
      const field = state.sections[sectionIndex].fieldMap[uid]
      for (const key in values) {
        const k = key as keyof Partial<
          Pick<QuestionnaireField, 'title' | 'answerType'>
        >
        ;(field[k] as any) = values[k] as any
      }
    },
    changeIndexField: (
      state: State,
      action: PayloadAction<{
        sectionIndex: number
        uid: string
        direction: 'up' | 'down'
      }>,
    ) => {
      const { sectionIndex, uid, direction } = action.payload
      const fieldMap = state.sections[sectionIndex].fieldMap
      const rootFields = Object.values(fieldMap).sort(
        (a, b) => a.index - b.index,
      )

      const targetField = fieldMap[uid]
      const targetFieldIndex = rootFields.findIndex(
        f => f.uid === targetField.uid,
      )
      const swappedIndex =
        direction === 'up' ? targetFieldIndex - 1 : targetFieldIndex + 1
      const swappedField = rootFields[swappedIndex]
      if (!swappedField) return
      ;[targetField.index, swappedField.index] = [
        swappedField.index,
        targetField.index,
      ]
    },

    // Choice
    addChoice: (
      state: State,
      action: PayloadAction<{ sectionIndex: number; fieldUid: string }>,
    ) => {
      const { sectionIndex, fieldUid } = action.payload
      const field = state.sections[sectionIndex].fieldMap[fieldUid]

      const nextIndex = field.choices.length
      const empty = createEmptyQuestionnaireChoice(nextIndex)

      field.choices.push(empty)
    },
    deleteChoice: (
      state: State,
      action: PayloadAction<{
        sectionIndex: number
        fieldUid: string
        index: number
      }>,
    ) => {
      const { sectionIndex, fieldUid, index } = action.payload
      const field = state.sections[sectionIndex].fieldMap[fieldUid]

      field.choices.splice(index, 1)

      // 残ったchoiceのindexを昇順で抜けがないようにする
      field.choices
        .sort((a, b) => a.index - b.index)
        .forEach((f, idx) => {
          f.index = idx
        })
    },
    updateChoice: (
      state: State,
      action: PayloadAction<{
        sectionIndex: number
        fieldUid: string
        index: number
        values: Partial<Pick<QuestionnaireChoice, 'description' | 'value'>>
      }>,
    ) => {
      const { sectionIndex, fieldUid, index, values } = action.payload
      const choice =
        state.sections[sectionIndex].fieldMap[fieldUid].choices[index]

      for (const key in values) {
        const k = key as keyof Partial<
          Pick<QuestionnaireChoice, 'description' | 'value'>
        >
        ;(choice[k] as any) = values[k] as any
      }
    },

    updateValidationError: (
      state: State,
      action: PayloadAction<{
        err: ValidationError
      }>,
    ) => {
      const { err } = action.payload
      state.validationError = { ...err }
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer
