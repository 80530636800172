import { useCallback, useEffect } from 'react'

import { Box } from '@chakra-ui/react'

import { useThisSession } from './useThisSession'
import { FloatingVideoPlayer } from '../components/FloatingVideoPlayer/FloatingVideoPlayer'
import { useRoomMediaContext } from '../context/MediaProvider'

export const useFloatingVideoCallForPatient = ({
  sessionUid,
  isOldFlow,
}: {
  sessionUid: string
  /** @deprecated */
  isOldFlow?: boolean
}) => {
  const { initializePatientVideoCall } = useRoomMediaContext()

  const thisSession = useThisSession({ sessionUid, forPatient: true })

  useEffect(() => {
    if (!thisSession.fetched) return
    if (!thisSession.videoCallRoomUid) return

    initializePatientVideoCall({
      roomUid: thisSession.videoCallRoomUid,
      candidateId: thisSession.patient.candidateId,
    })
  }, [thisSession, initializePatientVideoCall])

  const renderFloatingVideoCall = useCallback(
    (position?: { right: number; bottom: number }) => {
      const right = position?.right ?? 24
      const bottom = position?.bottom ?? 24
      return (
        <Box
          position="absolute"
          right={`${right}px`}
          bottom={`${bottom}px`}
          zIndex={1}
        >
          <FloatingVideoPlayer isOldFlow={isOldFlow} />
        </Box>
      )
    },
    [isOldFlow],
  )

  return {
    renderFloatingVideoCall,
  }
}
