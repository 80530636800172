import React from 'react'

import { Text } from '@chakra-ui/react'
import styled from 'styled-components'

import {
  blue,
  gray,
  red,
  yellow,
} from '../../../../components/base/color/palette'
import { Patient, PatientEProStatus, PatientStatus } from '../entity'

type Props = {
  patient: Patient
}

export const EProStatus: React.FC<Props> = ({ patient }) => {
  switch (patient.eproStatus) {
    case PatientEProStatus.Invalid:
    case PatientEProStatus.Unspecified:
      return null
    case PatientEProStatus.BeforeTrial:
      return (
        <BeforeTrialWrapper highlight={patient.status === PatientStatus.Enable}>
          <Text color={red[50]}>試験開始前</Text>
        </BeforeTrialWrapper>
      )
    case PatientEProStatus.InTrial:
      return <Text color={blue[70]}>試験中</Text>
    case PatientEProStatus.TrialCompleted:
      return <Text color={gray[55]}>試験終了</Text>
    case PatientEProStatus.TrialDiscontinued:
      return <Text color={yellow[70]}>中止</Text>
    default:
      const n: never = patient.eproStatus
      throw n
  }
}

const BeforeTrialWrapper = styled.div<{ highlight: boolean }>`
  background-color: ${props => (props.highlight ? red[10] : '')};
`
