import { Worksheet } from 'src/modules/entities/worksheet/entity'
import { fixture as worksheetFixture } from 'src/modules/entities/worksheet/fixture'
import {
  parse as parseWorksheet,
  WorksheetResponse,
} from 'src/modules/entities/worksheet/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'
import { generateUUID } from 'src/utils/generateUUID'

type TemplateParams = {
  template_uids: string[]
}

export const create = async ({
  trialUid,
  patientUid,
  templateUids,
}: {
  trialUid: string
  patientUid: string
  templateUids: string[]
}): Promise<Worksheet[]> => {
  if (IS_MOCK_MODE) {
    return [{ ...worksheetFixture, uid: generateUUID() }]
  }

  const params: TemplateParams = {
    template_uids: templateUids,
  }

  const res = await requestPost<WorksheetResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets`,
    params: params,
  })

  return res.data.map(parseWorksheet)
}
