import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  OrderedList,
  ListItem,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Menu as MenuIcon } from 'src/components/icon'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { FileViewer } from 'src/features/misc/components/FileViewer/FileViewer'
import { useSpinner } from 'src/hooks/use-spinner'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { getFlattenFields, parseSchema } from 'src/lib/chicken-schema/utils'
import {
  ReferredPatientFileViewerScreenDocument,
  ReferredPatientFileViewerScreen_DownloadFilesDocument,
  ReferredPatientFileViewerScreen_DownloadLogsDocument,
} from 'src/lib/gql-client'
import { useGqlMutation, useGqlQuerySWR } from 'src/lib/gql-client/request'
import { downloadObject } from 'src/utils/downloadObject'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

type Param = {
  referredPatientUid: string
  referredPatientCriteriaValueFileUid: string
}

export const ReferredPatientFileViewerScreen: React.FC = () => {
  const { referredPatientUid, referredPatientCriteriaValueFileUid } =
    useParams<Param>()

  if (!referredPatientUid || !referredPatientCriteriaValueFileUid) {
    throw new Error(
      'referredPatientUid or referredPatientCriteriaValueFileUid is not provided',
    )
  }

  const {
    data: { referredPatientCriteriaValueFile, referredPatient },
  } = useGqlQuerySWR(ReferredPatientFileViewerScreenDocument, {
    referredPatientUid,
    referredPatientCriteriaValueFileUid,
  })

  const files = referredPatientCriteriaValueFile.files

  const schema = parseSchema(
    referredPatient.criteriaList[0].criteria.latestDetail.criteriaSchema.schema,
  )

  const field = getFlattenFields(schema.fields).find(
    field => field.fid === referredPatientCriteriaValueFile.fid,
  )

  const fileDownloadModal = useDisclosure()
  const downloadLogsDrawer = useDisclosure()

  const canDownloadFiles = true // TODO: control by permission

  if (!field) {
    return null
  }

  return (
    <>
      <FileViewer
        field={field}
        files={files.map(file => ({
          order: file.fileOrder,
          memo: file.memo,
          uploadedFile: {
            uid: file.uploadedFileUid,
            extension: file.uploadedFile.extension,
            name: file.uploadedFile.name,
            url: file.uploadedFile.url,
            savedAt: file.uploadedFile.savedAt,
          },
        }))}
        menuComponent={
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<MenuIcon />}
              variant="customIconButtonGhost"
              aria-label="menu"
            />
            <MenuList>
              {canDownloadFiles && (
                <MenuItem onClick={fileDownloadModal.onOpen}>
                  ダウンロード
                </MenuItem>
              )}
              <MenuItem onClick={downloadLogsDrawer.onOpen}>
                ダウンロード履歴
              </MenuItem>
            </MenuList>
          </Menu>
        }
      />

      {canDownloadFiles && (
        <FileDownloadModal
          isOpen={fileDownloadModal.isOpen}
          referredPatientCriteriaValueFileUid={
            referredPatientCriteriaValueFileUid
          }
          onClose={fileDownloadModal.onClose}
        />
      )}
      {downloadLogsDrawer.isOpen && (
        <DownloadLogsDrawer
          fieldName={field.name}
          referredPatientCriteriaUid={
            referredPatient.criteriaList[0].referredPatientCriteriaUid
          }
          fid={referredPatientCriteriaValueFile.fid}
          fieldIndex={referredPatientCriteriaValueFile.fieldIndex}
          onClose={downloadLogsDrawer.onClose}
        />
      )}
    </>
  )
}

type FileDownloadModalProps = {
  isOpen: boolean
  referredPatientCriteriaValueFileUid: string
  onClose: () => void
}
const FileDownloadModal: React.FC<FileDownloadModalProps> = ({
  isOpen,
  referredPatientCriteriaValueFileUid,
  onClose,
}) => {
  const { showSpinner, hideSpinner } = useSpinner()

  const { request } = useGqlMutation(
    ReferredPatientFileViewerScreen_DownloadFilesDocument,
    {
      onRequestStarted: showSpinner,
      onRequestDone: hideSpinner,
      onSuccess: ({
        downloadReferredPatientCriteriaFieldFile: { downloadUrl },
      }) => {
        downloadObject({ url: downloadUrl })
        onClose()
      },
    },
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>ファイルダウンロード</ModalHeader>
        <ModalBody>
          <Text>
            ページ内すべてのファイルをダウンロードします。処理を開始しますか？
          </Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            onClick={async () => {
              await request({
                input: {
                  referredPatientCriteriaValueFileUid,
                },
              })
            }}
          >
            ダウンロードする
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type DownloadLogsDrawerProps = {
  fieldName: string
  referredPatientCriteriaUid: string
  fid: string
  fieldIndex: number
  onClose: () => void
}

const DownloadLogsDrawer: React.FC<DownloadLogsDrawerProps> = ({
  fieldName,
  referredPatientCriteriaUid,
  fid,
  fieldIndex,
  onClose,
}) => {
  const {
    data: { referredPatientCriteriaFileDownloadLogs: logs },
  } = useGqlQuerySWR(ReferredPatientFileViewerScreen_DownloadLogsDocument, {
    input: {
      referredPatientCriteriaUid,
      fid,
      fieldIndex,
    },
  })

  return (
    <Drawer isOpen onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>ダウンロード履歴</DrawerHeader>
        <DrawerBody>
          <Stack spacing="3">
            <TextWithBar as="h2">{fieldName}</TextWithBar>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>日時</Th>
                    <Th>操作者</Th>
                    <Th>対象ファイル</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {logs.map(log => (
                    <Tr key={log.criteriaDownloadLogUid}>
                      <Td verticalAlign="top" w="128px">
                        {formatDate(log.downloadedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                      </Td>
                      <Td verticalAlign="top" minW="160px">
                        <MemberLabel
                          role={log.trialMember.role}
                          displayName={getFullName(log.trialMember.user)}
                        />
                      </Td>
                      <Td verticalAlign="top">
                        <OrderedList spacing={4}>
                          {log.valueFile.files
                            .sort((a, b) => a.fileOrder - b.fileOrder)
                            .map(file => (
                              <ListItem key={file.uploadedFileUid}>
                                <Text wordBreak="break-all">
                                  {file.uploadedFile.name}
                                </Text>
                              </ListItem>
                            ))}
                        </OrderedList>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
