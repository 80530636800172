import { Center, Text } from '@chakra-ui/react'

export const VideoCallRoomEnded: React.FC<{ endedText: string }> = ({
  endedText,
}) => {
  return (
    <Center w="full" h="full">
      <Text
        fontSize="2xl"
        fontWeight="bold"
        color="white"
        textAlign="center"
        lineHeight="normal"
      >
        {endedText}
      </Text>
    </Center>
  )
}
