import React from 'react'

import { Box, IconButton } from '@chakra-ui/react'
import { Arrange } from 'src/components/icon'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { TemplateFolder } from 'src/modules/entities/template-folder/entity'

import { SortTemplateFolderModal } from '../modal/modal'

type Props = {
  trialUid: string
  templateFolders: TemplateFolder[]
}

export const SortTemplateFolderButton: React.FC<Props> = ({
  trialUid,
  templateFolders,
}) => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <Box>
      <IconButton
        icon={<Arrange />}
        aria-label="並べ替え"
        onClick={openModal}
        variant="customIconButtonGhost"
      />
      {modalOpen && (
        <SortTemplateFolderModal
          trialUid={trialUid}
          templateFolders={templateFolders}
          onClose={closeModal}
        />
      )}
    </Box>
  )
}
