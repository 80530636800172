import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  CreatePatientInput,
  GqlError,
  CreatePatientMutation,
  CreatePatientDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type CreatePatientReturn = CreatePatientMutation['createPatient']

const createPatient = async (
  input: CreatePatientInput,
): Promise<CreatePatientReturn> => {
  try {
    const res = await graphqlRequest(CreatePatientDocument, {
      input,
    })
    return res.createPatient
  } catch (error) {
    throw error
  }
}

export const useCreatePatient = (
  option?: UseMutationOption<CreatePatientReturn, GqlError>,
) => {
  return useMutation(
    (input: CreatePatientInput) => createPatient(input),
    option,
  )
}
