import { Account, SelectedTrial } from './entity'
import {
  DMPermissionsForFixture,
  DRPermissionsForFixture,
} from '../../../lib/permission'
import { Role } from '../member/entity'

const selectedTrial: SelectedTrial = {
  uid: 'ch001',
  name: '治験A',
  role: Role.Dr,
  videoEnabled: true,
  pdfOutputEnabled: true,
  videoCaptureEnabled: true,
  videoCaptureAccessRestricted: true,
  edcIntegrationEnabled: true,
  videoProvider: 2,
  selectedTrialHospitalUid: 'h001',
  trialHospitals: [
    {
      uid: 'h001',
      name: '病院A',
    },
    {
      uid: 'h002',
      name: '病院B',
    },
  ],
  newEconsentEnabled: true,
  questionnaireScheduleReleased: false,
  hasWorksheet: true,
  eProEnabled: false,
  permissions: DRPermissionsForFixture,
  multiSiteVideoCall: false,
  featureChannel: 'Stable',
  featureFlags: {
    eSourceV1: false,
    eSourceV2: true,
    eConsentNewSignFlow: false,
    eRecruit: true,
    preScreening: true,
  },
}

export const fixture: Account = {
  uid: 'fooAccountUid',
  email: 'demo@micin.jp',
  firstName: '太郎',
  lastName: 'クロン',
  hasMultipleMemberships: true,
  selectedTrial: selectedTrial,
}

export const oneMedicalTrial: SelectedTrial = {
  uid: '09bcd1b7-6601-495e-978f-32ef031a8b60',
  name: '1医療機関治験',
  role: 2,
  videoEnabled: false,
  pdfOutputEnabled: true,
  videoCaptureEnabled: true,
  videoCaptureAccessRestricted: false,
  videoProvider: 2,
  edcIntegrationEnabled: true,
  selectedTrialHospitalUid: '',
  trialHospitals: [
    {
      uid: '203028c5-8b20-4e81-8109-d75581f19e6e',
      name: 'micin clinic',
    },
  ],
  newEconsentEnabled: false,
  hasWorksheet: true,
  eProEnabled: false,
  questionnaireScheduleReleased: false,
  permissions: DMPermissionsForFixture,
  multiSiteVideoCall: false,
  featureChannel: 'Stable',
  featureFlags: {
    eSourceV1: false,
    eSourceV2: true,
    eConsentNewSignFlow: false,
    eRecruit: true,
    preScreening: true,
  },
}
