import React from 'react'

import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spacer } from 'src/components/spacer/spacer'
import { MediumItemAnswerLogActionToText } from 'src/modules/text'
import styled from 'styled-components'

import { MediumItemAnswerLog, MediumItemAnswerLogAction } from '../entity'

type Props = {
  onClose: () => void
  logs: MediumItemAnswerLog[]
  edcLinked: boolean
}

export const MediumItemAnswerLogModal: React.FC<Props> = props => {
  const { onClose, logs, edcLinked } = props
  const isConfirm = (log: MediumItemAnswerLog) =>
    log.action === MediumItemAnswerLogAction.Confirm

  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="変更履歴" />

      <ModalContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell width="30%">日時</TableHeadCell>
                <TableHeadCell width="15%">操作</TableHeadCell>
                <TableHeadCell width="20%">アカウント</TableHeadCell>
                {edcLinked && (
                  <TableHeadCell width="35%">EDC連携結果</TableHeadCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {logs.map(log => (
                <TableRow key={log.uid} borderBottom>
                  <TableBodyCell width="30%">
                    {dayjs(log.createdAt).format('YYYY/MM/DD HH:mm:ss')}
                  </TableBodyCell>

                  <TableBodyCell width="15%">
                    {MediumItemAnswerLogActionToText(log.action)}
                  </TableBodyCell>

                  <TableBodyCell width="20%">{log.userName}</TableBodyCell>

                  <TableBodyCell>
                    {log.message ? (
                      <div>
                        <Text fontSize="xs">Error</Text>
                        <Spacer size={4} />
                        <Message type="error" message={log.message} />
                      </div>
                    ) : isConfirm(log) ? (
                      <Message type="success" message="OK" />
                    ) : null}
                  </TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>
    </Modal>
  )
}

const TableContainer = styled.div`
  width: 100%;
  height: 300px;
`
