import React, { useMemo } from 'react'

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { ExplanationSignerRole } from 'src/features/explanation/types'
import { explanationSignerRoleToText } from 'src/features/explanation/utils/explanationSignerRoleToText'

type Props = {
  isOpen: boolean
  role: ExplanationSignerRole
  accountName: string
  onClose: () => void
}

export const ConfirmRoleModal: React.FC<Props> = ({
  isOpen,
  role,
  accountName,
  onClose,
}) => {
  const isPatient = role === 'Patient'
  const roleText = explanationSignerRoleToText(role)

  const displayTexts = useMemo((): { bold: string; normal: string } => {
    if (isPatient) {
      return {
        bold: '患者',
        normal: 'に端末を渡してください。',
      }
    }
    return {
      bold: `${roleText}:${accountName}`,
      normal: 'で署名を開始します。',
    }
  }, [isPatient, roleText, accountName])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Text fontSize="md">
            <strong>{displayTexts.bold}</strong>
            <Text as="span" fontWeight="normal">
              {displayTexts.normal}
            </Text>
          </Text>
        </ModalHeader>
        <ModalFooter>
          <Button colorScheme="green" onClick={onClose}>
            確認
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
