import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationCacheKey } from '../../../explanation/api/cacheKey'
import { ExplanationRevisionAbout } from '../../../explanation/types'

const getExplanationRevisionAbout = async ({
  trialUid,
  explanationRevisionUid,
}: {
  trialUid: string
  explanationRevisionUid: string
}): Promise<ExplanationRevisionAbout> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/about',
    httpMethod: 'get',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useExplanationRevisionAbout = ({
  shouldCancel,
  trialUid,
  explanationRevisionUid,
  ...option
}: {
  shouldCancel?: boolean
  trialUid: string
  explanationRevisionUid: string
} & SWRConfiguration<ExplanationRevisionAbout, Error>) => {
  return useSWR(
    shouldCancel
      ? null
      : explanationCacheKey.getExplanationRevisionAbout(explanationRevisionUid),
    () => getExplanationRevisionAbout({ trialUid, explanationRevisionUid }),
    option,
  )
}
