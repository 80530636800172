import React from 'react'

import { Text } from '@chakra-ui/react'
import { SubmitModal } from 'src/components/modal/submit'

type Props = {
  errorMessage: string
  requesting: boolean
  onSubmit: () => void
  closeModal: () => void
}

export const BackToDraftModal: React.FC<Props> = props => {
  const { errorMessage, requesting, onSubmit, closeModal } = props

  return (
    <SubmitModal
      title={`質問票を一時保存に戻しますか？`}
      submitText={`一時保存に戻す`}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      requesting={requesting}
      onClose={closeModal}
    >
      <Text textAlign="center">質問票を一時保存に戻しますか？</Text>
    </SubmitModal>
  )
}
