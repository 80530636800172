import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDetailRoute } from 'src/modules/dashboard/trial/detail/epro/questionnaire/routes'
import { actions } from 'src/modules/entities/questionnaire/redux'
import { confirm } from 'src/modules/entities/questionnaire/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

export const useSubmitConfirm = ({
  trialUid,
  questionnaireUid,
}: {
  trialUid: string
  questionnaireUid: string
}) => {
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const navigate = useNavigate()

  const request = useCallback(async () => {
    try {
      requestStarted()

      const confirmedQuestionnaire = await confirm(trialUid, questionnaireUid)

      dispatch(actions.upsert(confirmedQuestionnaire))
      navigate(getDetailRoute(trialUid, confirmedQuestionnaire))

      requestDone()
      showSuccess(`質問票を確定しました。`)
    } catch (e) {
      requestFailed(e.message)
      throw e
    }
  }, [
    dispatch,
    navigate,
    questionnaireUid,
    requestDone,
    requestFailed,
    requestStarted,
    showSuccess,
    trialUid,
  ])
  return { request, requesting, errorMessage }
}
