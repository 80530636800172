import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'

import { IS_MOCK_MODE } from '../util/feature-flag'

export const submitCapture = async ({
  trialUid,
  patientUid,
  worksheetUid,
  videoCallUid,
  base64Image,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  videoCallUid: string
  base64Image: string
}) => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPost<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/video_calls/${videoCallUid}/images`,
    params: {
      base_url: base64Image,
    },
  })

  return {}
}
