import { SmallItemAnswer } from './entity'
import {
  ChoiceAnswerResponse,
  parse as parseChoiceAnswerResponse,
} from '../choice-answer/request'
import { Role } from '../member/entity'
import { ItemType, DataType } from '../small-item/entity'

export type SmallItemAnswerResponse = {
  uid: string
  medium_item_answer_uid: string
  small_item_uid: string

  title: string
  description: string
  index: number
  item_type: ItemType
  data_type: DataType
  value: string | null // choices を持つ場合は null
  unit: string
  has_log: boolean
  fixed: boolean

  input_roles: Role[]
  choice_answers: ChoiceAnswerResponse[]

  parent_uid: string | null // ネストしていない（親の small item が無い）場合は null
}

export const parse = (resp: SmallItemAnswerResponse): SmallItemAnswer => {
  return {
    type: 'worksheet',
    uid: resp.uid,
    title: resp.title,
    description: resp.description,
    index: resp.index,
    itemType: resp.item_type,
    dataType: resp.data_type,
    value: resp.value,
    unit: resp.unit,
    hasLog: resp.has_log,
    fixed: resp.fixed,

    inputRoles: resp.input_roles,
    choiceAnswers: resp.choice_answers.map(parseChoiceAnswerResponse),

    parentUid: resp.parent_uid,
    smallItemUid: resp.small_item_uid,
    mediumItemAnswerUid: resp.medium_item_answer_uid,
  }
}
