import { Badge, CSSObject } from '@chakra-ui/react'
import { WorksheetStatus as GqlWorksheetStatus } from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'

import { WorksheetStatus } from '../../types'

type Props = {
  status: WorksheetStatus | GqlWorksheetStatus
  isDisabled: boolean
  detailed?: boolean
}

type ColorVariation = 'blue' | 'green' | 'yellow' | 'gray'

const colorMap: Record<ColorVariation, { bg: number; text: number }> = {
  blue: { bg: 200, text: 700 },
  green: { bg: 200, text: 700 },
  yellow: { bg: 200, text: 700 },
  gray: { bg: 100, text: 400 },
}

const style = (c: ColorVariation, isDisabled: boolean): CSSObject => {
  const { bg: bgNumber, text: textNumber } = colorMap[c]
  return {
    opacity: isDisabled ? 0.3 : 1,
    minW: '44px',
    h: '18px',
    display: 'grid',
    alignContent: 'center',
    fontSize: '10px',
    lineHeight: '18px',
    textAlign: 'center',
    py: '2px',
    color: `${c}.${textNumber}`,
    backgroundColor: `${c}.${bgNumber}`,
  }
}

export const WorksheetStatusBadge: React.FC<Props> = ({
  status,
  isDisabled,
  detailed = false,
}) => {
  switch (status) {
    case 'created':
    case 'Created':
      return <Badge sx={style('blue', isDisabled)}>新規</Badge>
    case 'saved':
    case 'Saved':
      return detailed ? (
        <Badge sx={style('yellow', isDisabled)}>一時保存</Badge>
      ) : (
        <Badge sx={style('yellow', isDisabled)}>要対応</Badge>
      )
    case 'confirmed':
    case 'Confirmed':
      return detailed ? (
        <Badge sx={style('green', isDisabled)}>確定</Badge>
      ) : (
        <Badge sx={style('green', isDisabled)}>完了</Badge>
      )
    case 'disabled':
    case 'Disabled':
      return <Badge sx={style('gray', isDisabled)}>無効</Badge>
    default:
      return assertNever(status)
  }
}
