import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  UpdateWorksheetNameDocument,
  UpdateWorksheetNameInput,
  UpdateWorksheetNameMutation,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type UpdateWorksheetNameReturn =
  UpdateWorksheetNameMutation['updateWorksheetName']

const updateWorksheetName = async (
  input: UpdateWorksheetNameInput,
): Promise<UpdateWorksheetNameReturn> => {
  try {
    const res = await graphqlRequest(UpdateWorksheetNameDocument, { input })
    return res.updateWorksheetName
  } catch (error) {
    throw error
  }
}

export const useUpdateWorksheetName = (
  option?: UseMutationOption<UpdateWorksheetNameReturn, GqlError>,
) => {
  return useMutation(
    (input: UpdateWorksheetNameInput) => updateWorksheetName(input),
    option,
  )
}
