import { useState } from 'react'

export const useToggle = () => {
  const [on, setOn] = useState<boolean>(false)
  const toggle = () => {
    setOn(!on)
  }

  return { on, toggle }
}
