import React, { useState, useCallback } from 'react'

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { SortableDragAndDropItem } from 'src/components/layout/drag-and-drop'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { TemplateFolder } from 'src/modules/entities/template-folder/entity'
import { useRequestState } from 'src/modules/server/use-request-state'

import { actions } from '../../../../../../../entities/template-folder/redux'
import { submitSortTemplateFolder, toParams } from '../request'

type Props = {
  trialUid: string
  templateFolders: TemplateFolder[]

  onClose: () => void
}

// TODO: 選択除外基準の並べ替え同様のstyleにする
export const SortTemplateFolderModal: React.FC<Props> = ({
  trialUid,
  onClose,
  templateFolders: originalFolders,
}) => {
  const [templateFolders, setTemplateFolders] =
    useState<TemplateFolder[]>(originalFolders)
  const { request, requesting } = useSortTemplateFolder()

  const onSubmit = async () => {
    await request({ trialUid, templateFolders })
    onClose()
  }

  const findItem = useCallback(
    (uid: string) => {
      const templateFolder = templateFolders.find(tf => tf.uid === uid)!
      return {
        templateFolder,
        index: templateFolders.indexOf(templateFolder),
      }
    },
    [templateFolders],
  )
  const moveItem = useCallback(
    (uid: string, atIndex: number) => {
      const { templateFolder, index } = findItem(uid)
      const newArray = templateFolders.slice()
      newArray.splice(index, 1)
      newArray.splice(atIndex, 0, templateFolder)

      setTemplateFolders(newArray)
    },
    [templateFolders, findItem],
  )

  const canSubmit =
    !requesting && !templateFolders.every((tf, i) => tf.index === i)

  return (
    <Modal isOpen onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>フォルダ並べ替え</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">
            ドラッグ＆ドロップでリストの並び順を変更できます
          </Text>

          <Box overflowY="scroll" mt="5">
            {templateFolders.map((tf, i) => (
              <SortableDragAndDropItem
                key={tf.uid}
                itemKey="template-folder"
                id={tf.uid}
                moveItem={moveItem}
                findItem={findItem}
                title={tf.name}
                top={i === 0}
              />
            ))}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button onClick={onSubmit} isDisabled={!canSubmit}>
            順番を保存
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const useSortTemplateFolder = () => {
  const { requestStarted, requestDone, requesting } = useRequestState()
  const dispatch = useDispatch()

  const toast = useMirohaToast()

  const request = useCallback(
    async ({
      trialUid,
      templateFolders,
    }: {
      trialUid: string
      templateFolders: TemplateFolder[]
    }) => {
      try {
        requestStarted()
        const resp = await submitSortTemplateFolder({
          trialUid,
          params: toParams(templateFolders),
        })
        dispatch(actions.sort(resp))
        toast({
          status: 'success',
          title: 'フォルダの並び順を変更しました',
        })
        requestDone()
      } catch (err) {
        toast({
          status: 'error',
          title: err.message,
        })
        throw err
      }
    },
    [requestStarted, requestDone, dispatch, toast],
  )

  return {
    request,
    requesting,
  }
}
