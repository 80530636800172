import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const rejectAgreementHandler = restHandlerFactory(
  '/trials/{trial_uid}/explanation_document_revisions/{explanation_document_revision_uid}/reject',
  'put',
  () => {
    return HttpResponse.json(undefined, { status: 204 })
  },
)
