import React, { InputHTMLAttributes } from 'react'

import styled from 'styled-components'

import { gray, green, white } from '../base/color/palette'

type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>
type Props = {
  text: string
  offText: string
  toggleAriaLabel?: string
} & CheckboxProps

/** @deprecated 旧デザインにつき極力使用しないこと。使用する際はレイアウト崩れに細心の注意を払うこと */
export const ToggleButton: React.FC<Props> = ({
  text,
  offText,
  toggleAriaLabel,
  ...rest
}) => {
  return (
    <Button>
      <HiddenInput type="checkbox" {...rest} text={text} offText={offText} />
      <span aria-label={toggleAriaLabel} />
    </Button>
  )
}

const Button = styled.label`
  position: relative;
  display: inline-block;
  width: 96px;
  height: 34px;
`

type TextProps = Pick<Props, 'text' | 'offText'>
const HiddenInput = styled.input<TextProps>`
  opacity: 0;
  width: 0;
  height: 0;

  & + span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${gray[55]};

    display: flex;
    align-items: center;
    justify-content: flex-end;

    border-radius: 34px;

    // circle
    ::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: ${white};

      border-radius: 50%;
    }
    // text
    ::after {
      color: ${white};
      font-size: 14px;
      font-weight: normal;
      width: calc(100% - 30px);
      padding: 0 16px 0 8px;
      text-align: center;
      box-sizing: border-box;
      right: 30px;
      content: '${p => p.offText}';
    }
  }

  &:checked + span {
    background-color: ${green[40]};
    justify-content: flex-start;

    // text
    ::before {
      position: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${white};
      font-size: 14px;
      font-weight: normal;
      width: calc(100% - 30px);
      padding: 0 8px 0 16px;
      text-align: center;
      box-sizing: border-box;
      right: 0;
      background-color: unset;
      content: '${p => p.text}';
    }
    // circle
    ::after {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      right: 4px;
      bottom: 4px;
      background-color: ${white};
      border-radius: 50%;
    }
  }

  &:disabled + span {
    cursor: not-allowed;
  }

  //FIXME: :focus のstyleがあったほうが良さそう（tabキーによる操作性が向上する）
`
