import { useCallback, useEffect } from 'react'

import {
  AspectRatio,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
} from '@chakra-ui/react'
import { RemoteUser } from '@micin-jp/call-lib-core'
import {
  useLocalPlayer,
  useRemotePlayer,
  useRoomUsers,
} from '@micin-jp/call-lib-react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { CameraOff, MicOff, CameraOn } from 'src/components/icon'
import { useModal } from 'src/hooks/use-modal'

import { useVolumeMeter } from '../../hooks/useVolumeMeter'

export const SPVideoCallRoomInCall: React.FC = () => {
  const { remoteUsers } = useRoomUsers()
  const { showModal } = useModal()

  useEffect(() => {
    // iOS系の場合、Agoraの音声の自動再生時にユーザビリティ設定のために失敗することがある。
    // 何かしらユーザーの画面操作を挟めば音声が再生されるため、モーダルを表示・ボタンクリックを促すようにする。
    // See: https://docportal.shengwang.cn/cn/voice-legacy/autoplay_policy_web_ng?platform=iOS
    AgoraRTC.onAudioAutoplayFailed = () => {
      showModal({
        title: '通話が開始されています',
        content: 'マイクをONにしてください',
        submitText: '確認',
        submitButtonColor: 'blue',
        size: 'sm',
        hideCancel: true,
        onSubmit: () => {},
      })
    }
  }, [showModal])

  if (remoteUsers.length === 0) {
    return (
      <Flex w="full" h="full" gap={2}>
        <Center h="full" w="full" color="white">
          {/* TODO: 文言は仮なのでfixする */}
          <Text fontWeight="bold" color="white">
            あなた以外に参加者がいません
          </Text>
        </Center>
        <AspectRatio
          minW="124px"
          ratio={16 / 9}
          w={{ base: '192px', lg: '320px' }}
        >
          <SPVideoCallRoomLocalPlayer />
        </AspectRatio>
      </Flex>
    )
  }

  return (
    <Flex w="full" h="full" gap={2}>
      <Grid
        w="full"
        h="full"
        gap={4}
        templateColumns={
          remoteUsers.length < 2 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
        }
        templateRows={
          remoteUsers.length < 3 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
        }
      >
        {remoteUsers.slice(0, 3).map(user => (
          <GridItem key={user.id}>
            <SPVideoCallRoomRemotePlayer remoteUser={user} />
          </GridItem>
        ))}
        {/* 3名超過分はaudioのみ */}
        {remoteUsers.slice(3).map(user => (
          <AudioOnlyRemotePlayer key={user.id} remoteUser={user} />
        ))}
        {remoteUsers.length > 3 && (
          <RestUsersPlaceHolder restNumber={remoteUsers.length - 3} />
        )}
      </Grid>
      <AspectRatio
        minW="124px"
        ratio={16 / 9}
        w={{ base: '192px', lg: '320px' }}
      >
        <SPVideoCallRoomLocalPlayer />
      </AspectRatio>
    </Flex>
  )
}

const RestUsersPlaceHolder = ({ restNumber }: { restNumber: number }) => {
  return (
    <Box
      bg="gray.900"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        backgroundColor="gray.600"
        rounded="full"
        textColor="white"
        width="50px"
        height="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        他+{restNumber}
      </Box>
    </Box>
  )
}

const SPVideoCallRoomRemotePlayer: React.FC<{ remoteUser: RemoteUser }> = ({
  remoteUser,
}) => {
  const { attachVideo, playAudio, cameraEnabled, micEnabled, audioTrack } =
    useRemotePlayer(remoteUser)

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachVideo(elm, { fit: 'contain' }),
    [attachVideo],
  )

  useEffect(() => playAudio(), [playAudio])

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  return (
    <Box w="full" h="full" borderRadius="sm" pos="relative">
      <Box
        pos="absolute"
        zIndex="1"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      {cameraEnabled ? (
        <Flex
          ref={videoRef}
          direction="column"
          justify="flex-end"
          w="full"
          h="full"
        />
      ) : (
        <Center flexDirection="column" color="white" w="full" h="full">
          <CameraOff size="md" />
        </Center>
      )}
      <Box
        pos="absolute"
        maxW="80%"
        p="0.5"
        left="8px"
        bottom="8px"
        bg="gray.500"
        color="white"
        borderRadius="sm"
        as="span"
      >
        <HStack spacing="2">
          <Text
            fontSize="x-small"
            as="span"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {remoteUser.name}
          </Text>
          {!micEnabled && (
            <Box minW="16px">
              <MicOff size="sm" />
            </Box>
          )}
        </HStack>
      </Box>
    </Box>
  )
}

// 3名より多い分のRemoteUserについては映像のレンダリングはせずAudioの再生のみを行う
const AudioOnlyRemotePlayer: React.FC<{
  remoteUser: RemoteUser
}> = ({ remoteUser }) => {
  const { playAudio } = useRemotePlayer(remoteUser)
  useEffect(() => playAudio(), [playAudio])
  return null
}

const SPVideoCallRoomLocalPlayer = () => {
  const {
    attachLocalVideo,
    localCameraEnabled,
    localMicEnabled,
    localAudioTrack: audioTrack,
  } = useLocalPlayer()

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachLocalVideo(elm, { fit: 'contain' }),
    [attachLocalVideo],
  )

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  return (
    <Box h="full" w="full" bg="gray.700" pos="relative" borderRadius="sm">
      <Box
        pos="absolute"
        zIndex="1"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      {localCameraEnabled ? (
        <Flex
          ref={videoRef}
          direction="column"
          justify="flex-end"
          w="full"
          h="full"
          transform="scaleX(-1)"
        />
      ) : (
        <Center flexDirection="column" color="white" w="full" h="full">
          {localCameraEnabled ? <CameraOn /> : <CameraOff />}
        </Center>
      )}
      <Box
        pos="absolute"
        p="0.5"
        left="8px"
        bottom="8px"
        bg="gray.500"
        color="white"
        borderRadius="sm"
        as="span"
      >
        <HStack spacing="2">
          <Text fontSize="x-small" as="span">
            あなた
          </Text>
          {!localMicEnabled && <MicOff size="sm" />}
        </HStack>
      </Box>
    </Box>
  )
}
