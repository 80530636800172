import { useCallback, useMemo } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
} from '@chakra-ui/react'
import { Trash } from 'src/components/__legacy__icon/monochrome'
import { CalendarDateInput } from 'src/components/CalendarDateInput/CalendarDateInput'
import { FileSelectButton } from 'src/components/FileSelectButton/FileSelectButton'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { usePermission } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useExplanationWithdrawnHistories } from '../../api/getExplanationWithdrawHistories'
import { useUpdateWithdrawExplanation } from '../../api/updateWithdrawExplanation'
import { useExplanationWithdrawForm } from '../../hooks/useExplanationWithdrawForm'
import { ExplanationRevision, WithdrawHistory } from '../../types'

type Props = {
  explanationRevisionUid: ExplanationRevision['uid']
  isOpen: boolean
  handleClose: () => void
  withdrawHistories: WithdrawHistory[]
}

export const ExplanationWithdrawHistoryModal: React.FC<Props> = ({
  explanationRevisionUid,
  isOpen,
  handleClose,
  withdrawHistories,
}) => {
  const toast = useMirohaToast()

  const { mutate: mutateExplanationWithdrawHistories } =
    useExplanationWithdrawnHistories(explanationRevisionUid)

  const { hasPermission } = usePermission()

  const canEditWithdrawal = useMemo(() => {
    return hasPermission(PERMISSIONS.Explanation_Edit)
  }, [hasPermission])

  const latestWithdrawHistory = withdrawHistories[0]

  const defaultValue = useMemo(() => {
    return {
      comment: '',
      file: undefined,
      withdrawAt: latestWithdrawHistory.withdrawAt,
      isFileChange: false,
    }
  }, [latestWithdrawHistory])

  const {
    field,
    onChangeWithdrawnAt,
    onCommentChange,
    onFileChange,
    onFileRemove,
    isValid,
  } = useExplanationWithdrawForm({ defaultValue })

  const { request: updateWithdrawRequest, requesting } =
    useUpdateWithdrawExplanation({
      onSuccess: () => {
        mutateExplanationWithdrawHistories()
        handleClose()
        toast({ status: 'success', title: '同意撤回情報を更新しました' })
      },
    })

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault() // ページのリロードを防止する
      await updateWithdrawRequest({ explanationRevisionUid, data: field })
    },
    [updateWithdrawRequest, explanationRevisionUid, field],
  )

  const isFileExists = useMemo(() => {
    if (
      !field.isFileChange &&
      !!latestWithdrawHistory.fileName &&
      !!latestWithdrawHistory.fileUrl
    )
      return true
    return field.file !== undefined
  }, [field, latestWithdrawHistory])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="4xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader pb="2">同意撤回情報</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel fontSize="md" fontWeight="bold">
                同意撤回日
              </FormLabel>
              <Box h="2" />
              <CalendarDateInput
                value={field.withdrawAt}
                onChange={onChangeWithdrawnAt}
                isDisabled={!canEditWithdrawal}
              />
            </FormControl>
            <Box h="6" />
            <FormControl>
              <FormLabel fontSize="md" fontWeight="bold">
                同意撤回書
              </FormLabel>
            </FormControl>
            <HStack gap={4}>
              {field.file !== undefined && (
                <Link
                  href={URL.createObjectURL(field.file)}
                  isExternal
                  color="blue.500"
                >
                  {field.file?.name}
                </Link>
              )}
              {!!latestWithdrawHistory.fileName && !field.isFileChange && (
                <ExternalLinkComponent
                  fileUrl={latestWithdrawHistory.fileUrl}
                  fileName={latestWithdrawHistory.fileName}
                />
              )}
              {canEditWithdrawal && (
                <>
                  <FileSelectButton
                    onChange={onFileChange}
                    text="ファイルを選択"
                    accept=".pdf"
                    buttonProps={{ variant: 'text', px: '0', mx: '0' }}
                  />
                  {isFileExists && (
                    <IconButton
                      aria-label="delete-file"
                      icon={<Trash />}
                      variant="text"
                      onClick={onFileRemove}
                    />
                  )}
                </>
              )}
            </HStack>
            <Box h="6" />
            <FormControl>
              <FormLabel fontSize="md" fontWeight="bold">
                変更理由
              </FormLabel>
              <Input
                value={field.comment}
                onChange={e => {
                  onCommentChange(e.target.value)
                }}
                bg="white"
                disabled={!canEditWithdrawal}
                placeholder="内容を更新する場合は入力してください"
              />
            </FormControl>
            <Box height="8" />
            <Text fontSize="lg" fontWeight="bold">
              変更履歴
            </Text>
            <ExplanationWithdrawHistoryTable
              withdrawHistories={withdrawHistories}
            />
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            {canEditWithdrawal && (
              <Button type="submit" isDisabled={requesting || !isValid}>
                内容更新
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

const ExplanationWithdrawHistoryTable = ({
  withdrawHistories,
}: {
  withdrawHistories: WithdrawHistory[]
}) => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>同意撤回日</Th>
            <Th>コメント</Th>
            <Th>同意撤回書</Th>
            <Th>更新日時/操作者</Th>
          </Tr>
        </Thead>
        <Tbody>
          {withdrawHistories.map(
            ({
              uid,
              createdAt,
              withdrawAt,
              comment,
              fileName,
              fileUrl,
              member,
              isPartner,
            }) => {
              return (
                <Tr key={uid}>
                  <Td>{formatDate(withdrawAt, 'YYYY/MM/DD (ddd)')}</Td>
                  <Td>
                    <Wrap maxWidth="320px">
                      <Text>{comment}</Text>
                    </Wrap>
                  </Td>
                  <Td>
                    {!!fileName && (
                      <ExternalLinkComponent
                        fileUrl={fileUrl}
                        fileName={fileName}
                      />
                    )}
                  </Td>
                  <Td maxW="240px">
                    <Stack spacing="1">
                      <Text fontSize="sm" color="grey" width="144px">
                        {formatDate(createdAt, 'YYYY/MM/DD (ddd) HH:mm')}
                      </Text>
                      <MemberLabel
                        role={member.role}
                        displayName={getFullName(member)}
                        isPartner={isPartner}
                      />
                    </Stack>
                  </Td>
                </Tr>
              )
            },
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const ExternalLinkComponent = ({
  fileName,
  fileUrl,
}: {
  fileName: string
  fileUrl?: string
}) => {
  if (fileUrl === '' || fileUrl === undefined) {
    return <Text>{fileName}</Text>
  }
  return (
    <Link href={fileUrl} color="blue.500" isExternal>
      {fileName}
    </Link>
  )
}
