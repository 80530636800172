import { useCallback, useEffect, useRef, useState } from 'react'

type TRequestOption<T> = {
  immediate?: boolean
  onComplete?: (data: T) => void
  onFailure?: (error: string) => void
}

export const useRequest = <T, P>(
  request: (params: P) => Promise<T>,
  options?: TRequestOption<T>,
) => {
  const [requested, setRequested] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T | null>(null)
  const onComplete = options?.onComplete
  const onFailure = options?.onFailure
  const unmounted = useRef<boolean>()

  const doRequest = useCallback(
    async (params: P) => {
      setRequesting(true)
      setError(null)
      setData(null)

      try {
        const data = await request(params)
        if (unmounted.current) return

        setData(data)
        setRequested(true)
        if (onComplete) onComplete(data)
      } catch (error) {
        if (unmounted.current) return

        setError(error.message)
        if (onFailure) onFailure(error.message)
      } finally {
        setRequesting(false)
      }
    },
    [onComplete, onFailure, request],
  )

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  return {
    requested,
    requesting,
    error,
    data,
    doRequest,
  }
}
