import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { useCognitoUser } from 'src/hooks/use-cognito-user'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'
import * as yup from 'yup'

interface IProps {
  onClose: () => void
}

const validationSchema = yup.object().shape({
  authCode: yup.string().required(),
})

type IFormValues = yup.InferType<typeof validationSchema>

export const MfaDisableModal: React.FC<IProps> = ({ onClose }) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { cognitoUser, initCognitoUser } = useCognitoUser()
  const { showSuccess } = useFlash()
  const { register, handleSubmit, formState } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = async (value: IFormValues) => {
    try {
      requestStarted()
      await Auth.verifyTotpToken(cognitoUser, value.authCode)
      await Auth.setPreferredMFA(cognitoUser, 'NOMFA')
      await initCognitoUser()
      onClose()
      showSuccess('2段階認証を解除しました。')
    } catch (error) {
      switch (error.code) {
        case 'EnableSoftwareTokenMFAException':
          if (
            error.message === 'Your software token has already been used once.'
          ) {
            requestFailed(
              '既に使用済みの認証コードです。新しい認証コードをご入力下さい。',
            )
            break
          }

          requestFailed('正しい認証コードを入力下さい。')
          break
        case 'InvalidParameterException':
          requestFailed('適切な形式の認証コードを入力下さい。')
          break
        case 'LimitExceededException':
          requestFailed(
            'アカウントがロックされています。時間を置いて再度お試し下さい。',
          )
          break
        default:
          requestFailed('問題が発生しました。時間を置いて再度お試し下さい。')
      }
    } finally {
      requestDone()
    }
  }

  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="2段階認証設定" />

      <ModalContent>
        <Message
          type="success"
          message="2段階認証が設定されています。"
          centered
        />
        <Spacer size={10} />
        <Text textAlign="center">
          解除する場合は認証用モバイルアプリに表示されている認証コードを入力してください。
        </Text>
        <Spacer size={20} />
        <Label bold>認証コード</Label>
        <Spacer size={10} />
        <Input type="text" {...register('authCode')} />
        {errors.authCode && touchedFields.authCode && (
          <div>
            <Spacer size={4} />
            <Message type="error" message="認証コードを入力してください" />
          </div>
        )}

        {errorMessage && (
          <div>
            <Spacer size={8} />
            <Message type="error" message={errorMessage} centered />
          </div>
        )}
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="解除する"
          onClick={handleSubmit(onSubmit)}
          buttonType="important"
          disabled={!isValid || requesting}
        ></Button>
      </ModalActions>
    </Modal>
  )
}
