import { useMemo } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { usePatientQueryParams } from './usePatientsQueryParams'

export const useSearchPatients = () => {
  const { queryParamObject, setQuery } = usePatientQueryParams()

  const currentSearchQuery = useMemo(
    () => queryParamObject.searchQuery,
    [queryParamObject.searchQuery],
  )

  const handleChangeSearchQuery = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery({ type: 'search', value: e.target.value })
    },
    500,
  )

  return {
    handleChangeSearchQuery,
    searchQuery: currentSearchQuery,
  }
}
