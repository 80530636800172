import { HttpResponse } from 'msw'
import {
  createDeliverDocumentsPayloadMock,
  DeliveryDocumentsDocument,
  DeliveryDocumentsMutation,
  DeliveryDocumentsMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const deliveryDocumentsHandler = graphQLHandlerFactory<
  DeliveryDocumentsMutation,
  DeliveryDocumentsMutationVariables
>('mutation', DeliveryDocumentsDocument, () => {
  return HttpResponse.json({
    data: {
      deliverDocuments: createDeliverDocumentsPayloadMock(),
    },
  })
})
