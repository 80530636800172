import { Input, InputProps } from '@chakra-ui/react'

// コンポーネントの利用側で指定させたくないprops
type OmittedInputProps = Pick<
  InputProps,
  'type' | 'value' | 'onChange' | 'inputMode'
>

type Props = {
  value: string | undefined
  onChange: (value: string) => void
} & Omit<InputProps, keyof OmittedInputProps>

/** 半角数字のみ入力可能なinput */
export const NumberOnlyInput: React.FC<Props> = ({
  value,
  onChange,
  ...styleProps
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 全角数字は半角数字に変換 それ以外は削除
    const newValue = e.target.value
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      .replace(/[^0-9]/g, '')
    onChange(newValue)
  }

  return (
    <Input
      type="text"
      value={value}
      onChange={handleChange}
      inputMode="numeric"
      {...styleProps}
    />
  )
}
