import React from 'react'

import { Box, HStack, Tooltip, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Info } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { green } from 'src/components/base/color/palette'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { ButtonLink } from 'src/components/base/text-link'
import { Spacer } from 'src/components/spacer/spacer'
import { ApiResponse } from 'src/lib/api-client'
import styled from 'styled-components'

import { IcfDocumentRevisionStatusBadge } from '../components/icf-document-status-badge'
import { RequiredPatientSignBadge } from '../components/required-patient-sign-badge'
import {
  icfDocumentRevisionStatus,
  icfDocumentTypes,
  icfDocumentTypeText,
} from '../entity'

type Props = {
  icfDocumentList: ApiResponse<'ListICFDocumentsItem'>[] | undefined
  selectedTrialHospitalUid: string | undefined
  shouldShowReAgreementConfirmButton: boolean
  navigateToAdd: () => void
  navigateToEdit: ({
    icfDocumentUid,
    latestRevisionUid,
  }: {
    icfDocumentUid: string
    latestRevisionUid: string
  }) => void
  onClickDocumentSetLink: (documentUid: string) => void
  onClickReAgreementConfirmButton: () => void
}

type TableKey = keyof Pick<
  ApiResponse<'ListICFDocumentsItem'>,
  | 'isRequiredReAgreementConfirmation'
  | 'status'
  | 'numberingId'
  | 'name'
  | 'version'
  | 'type'
  | 'approvedAt'
  | 'isLinkedICFDocumentSet'
>

const tableCellWidth: Record<TableKey, string | undefined> = {
  isRequiredReAgreementConfirmation: '15px',
  status: '120px',
  numberingId: '80px',
  name: '20%',
  version: '120px',
  type: '160px',
  approvedAt: '200px',
  isLinkedICFDocumentSet: '100px',
}

export const IcfDocumentList: React.FC<Props> = ({
  icfDocumentList,
  selectedTrialHospitalUid,
  shouldShowReAgreementConfirmButton,
  navigateToAdd,
  navigateToEdit,
  onClickDocumentSetLink,
  onClickReAgreementConfirmButton,
}) => {
  return (
    <Wrapper>
      <HStack w="full" justify="right">
        <HStack spacing="16px">
          {shouldShowReAgreementConfirmButton && (
            <Button
              onClick={onClickReAgreementConfirmButton}
              text="再同意確認"
              buttonType="notice"
              size="S"
            />
          )}
          <Button
            onClick={navigateToAdd}
            iconName="add"
            text="新規文書登録"
            buttonType="normal"
            size="S"
            disabled={selectedTrialHospitalUid === undefined}
          />
        </HStack>
      </HStack>
      <Spacer size={8} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell
                width={tableCellWidth['isRequiredReAgreementConfirmation']}
              />
              <TableHeadCell width={tableCellWidth['status']}>
                ステータス
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['numberingId']}>
                文書ID
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['name']}>
                文書名
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['version']}>
                版数
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['type']}>
                文書の種類
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['approvedAt']}>
                承認日時
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['isLinkedICFDocumentSet']}>
                文書セット
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!icfDocumentList
              ? null
              : icfDocumentList.map(document => {
                  const status = Object.values(icfDocumentRevisionStatus).find(
                    s => s === document.status,
                  )!
                  const type = Object.values(icfDocumentTypes).find(
                    s => s === document.type,
                  )!

                  return (
                    <HStack align="center" key={document.uid}>
                      <Box
                        w={tableCellWidth['isRequiredReAgreementConfirmation']}
                        h="full"
                      >
                        {document.isRequiredReAgreementConfirmation && (
                          <Tooltip label="再同意確認要">
                            <Box>
                              <Info size="S" color={green[40]} />
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                      <TableRow
                        onClick={() =>
                          navigateToEdit({
                            icfDocumentUid: document.uid,
                            latestRevisionUid: document.icfDocumentRevisionUid,
                          })
                        }
                        disabled={status === icfDocumentRevisionStatus.Disabled}
                        hover
                        borderBottom
                      >
                        <TableBodyCell width={tableCellWidth['status']}>
                          <IcfDocumentRevisionStatusBadge status={status} />
                        </TableBodyCell>
                        <TableBodyCell width={tableCellWidth['numberingId']}>
                          {document.numberingId}
                        </TableBodyCell>
                        <TableBodyCell width={tableCellWidth['name']}>
                          {document.name}
                        </TableBodyCell>
                        <TableBodyCell width={tableCellWidth['version']}>
                          {document.version}
                          {document.isRevising && (
                            <Text fontSize="xs" color={green[40]}>
                              （改版中）
                            </Text>
                          )}
                        </TableBodyCell>
                        <TableBodyCell width={tableCellWidth['type']}>
                          {icfDocumentTypeText[type]}
                          {document.isRequiredPatientSign && (
                            <>
                              <Spacer size={8} horizontal />
                              <RequiredPatientSignBadge />
                            </>
                          )}
                        </TableBodyCell>
                        <TableBodyCell width={tableCellWidth['approvedAt']}>
                          {document.approvedAt &&
                            dayjs(document.approvedAt).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )}
                        </TableBodyCell>
                        <TableBodyCell
                          width={tableCellWidth['isLinkedICFDocumentSet']}
                        >
                          {document.isLinkedICFDocumentSet && (
                            <ButtonLink
                              onClick={e => {
                                e.stopPropagation()
                                onClickDocumentSetLink(document.uid)
                              }}
                            >
                              {'設定済'}
                            </ButtonLink>
                          )}
                        </TableBodyCell>
                      </TableRow>
                    </HStack>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  flex: 1;
`
