// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgTemplate = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 33"
    {...props}
  >
    <path
      fill="#EEE"
      d="M3.867 16.255a1.5 1.5 0 0 1 0-2.122L16.246 1.755a1.5 1.5 0 0 1 2.121 0l6.364 6.364a1.5 1.5 0 0 1 0 2.12L12.352 22.62a1.5 1.5 0 0 1-2.121 0l-6.364-6.364Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      stroke={props.color ?? 'currentColor'}
      strokeWidth={0.5}
      d="M3.513 16.608a2 2 0 0 1 0-2.828L15.893 1.4a2 2 0 0 1 2.828 0l6.363 6.364a2 2 0 0 1 0 2.828l-12.378 12.38a2 2 0 0 1-2.829 0l-6.364-6.365Zm.707-2.121a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.415 0L24.377 9.886a1 1 0 0 0 0-1.414l-6.363-6.364a1 1 0 0 0-1.415 0L4.22 14.487Z"
      clipRule="evenodd"
    />
    <path
      fill="#EEE"
      d="M5.807 14.862a1.5 1.5 0 0 1 .477-2.067L22.397 2.727a1.5 1.5 0 0 1 2.067.477l4.769 7.632a1.5 1.5 0 0 1-.477 2.067L12.643 22.972a1.5 1.5 0 0 1-2.067-.477l-4.77-7.633Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      stroke={props.color ?? 'currentColor'}
      strokeWidth={0.5}
      d="M5.383 15.127a2 2 0 0 1 .636-2.756L22.132 2.303a2 2 0 0 1 2.756.636l4.769 7.633a2 2 0 0 1-.636 2.756L12.908 23.395a2 2 0 0 1-2.756-.636l-4.77-7.633Zm1.166-1.908a1 1 0 0 0-.318 1.378L11 22.23a1 1 0 0 0 1.378.318L28.49 12.479a1 1 0 0 0 .318-1.377l-4.77-7.633a1 1 0 0 0-1.377-.318L6.549 13.219Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      stroke={props.color ?? 'currentColor'}
      strokeWidth={0.5}
      d="M4.155 13.829a2 2 0 0 1 1.414-2.45l18.353-4.917a2 2 0 0 1 2.45 1.414l2.07 7.727a2 2 0 0 1-1.414 2.45L8.675 22.97a2 2 0 0 1-2.45-1.414l-2.07-7.727Zm1.673-1.484a1 1 0 0 0-.707 1.225l2.07 7.727a1 1 0 0 0 1.225.707l18.353-4.917a1 1 0 0 0 .707-1.225l-2.07-7.727a1 1 0 0 0-1.225-.708L5.828 12.345Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      d="M3.78 9.004a1 1 0 0 0-.97.758L2 13.004v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H12l-.81-3.242a1 1 0 0 0-.97-.758H3.78Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgTemplate
