import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { CommonDisableLabel } from 'src/components/labels/disabled'
import { Spacer } from 'src/components/spacer/spacer'
import { useBlockTransition } from 'src/hooks/useBlockTransition'
import { PERMISSIONS } from 'src/lib/permission'
import { Anchors } from 'src/modules/dashboard/trial/detail/common/anchors'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import {
  MediumItemAnswer,
  MediumItemAnswerStatus,
} from 'src/modules/entities/medium-item-answer/entity'
import { PatientStatus } from 'src/modules/entities/patient/entity'
import { getSingle as getPatient } from 'src/modules/entities/patient/selector'
import { callable } from 'src/modules/entities/patient/util'
import { DataType } from 'src/modules/entities/small-item/entity'
import { getSingle as getTemplateFolder } from 'src/modules/entities/template-folder/selector'
import { WorksheetStatus } from 'src/modules/entities/worksheet/entity'
import { getSingle } from 'src/modules/entities/worksheet/selector'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'
import { space, SpaceProps } from 'styled-system'

import { DisableButton } from './disable/button'
import { EnableButton } from './enable/button'
import { LoglineSection } from './logline-section'
import { OutputButton } from './output/button'
import { PulldownContainer } from './pulldown/pulldown-container'
import { actions } from './redux'
import { getState } from './selector'
import { SingleSection } from './single-section'
import { useVideoCall } from './use-video-call'
import { getSingle as getSingleTemplate } from '../../../../../../../../entities/template/selector'
import { patientIdParamName } from '../../../patient-detail'

export const worksheetIdParamName = 'worksheetUid'

type Props = {}

export const WorksheetDetail: React.FC<Props> = props => {
  const { worksheetUid = '', patientUid = '' } = useParams<{
    [worksheetIdParamName]: string
    [patientIdParamName]: string
  }>()
  const originalWorksheet = useSelector((state: RootState) =>
    getSingle(state, worksheetUid),
  )
  const trial = useSelector(getSelectedTrial)!
  const template = useSelector((state: RootState) =>
    getSingleTemplate(state, originalWorksheet?.templateUid),
  )
  const templateFolder = useSelector((state: RootState) =>
    getTemplateFolder(state, template?.templateFolderUid),
  )

  const hasClinicalSmallItemAnswer = originalWorksheet.mediumItemAnswers
    .flatMap(mia => {
      return Object.values(mia.smallItemAnswerMap)
    })
    .some(sia => {
      return sia.dataType === DataType.Clinical
    })

  const { videoEnabled, pdfOutputEnabled, videoCaptureEnabled } = trial
  const { hasPermission } = usePermission()
  const dispatch = useDispatch()
  const { worksheet, editingSmallItemAnswerUidSet } = useSelector(getState)
  const currentPatient = useSelector((state: RootState) =>
    getPatient(state, patientUid),
  )

  useEffect(() => {
    if (!originalWorksheet) return

    dispatch(
      actions.initWithWorksheet({
        worksheet: originalWorksheet,
      }),
    )
  }, [dispatch, originalWorksheet])

  useEffect(() => {
    return () => {
      dispatch(actions.reset({}))
    }
  }, [dispatch])

  const { canUseVideoCall, onClick: onClickVideoCall } = useVideoCall()

  useBlockTransition({
    showAlert: editingSmallItemAnswerUidSet.size > 0,
  })

  if (!originalWorksheet) {
    return null
  }

  if (!originalWorksheet) {
    return <Navigate replace to={'./'} />
  }

  if (!worksheet) {
    return null
  }

  if (!currentPatient) {
    return null
  }

  const edcLinked = hasClinicalSmallItemAnswer && trial.edcIntegrationEnabled
  const isWorksheetDisabled = worksheet.status === WorksheetStatus.Disabled

  const isEnablePatient = currentPatient.status === PatientStatus.Enable

  const worksheetEditable =
    currentPatient.status === PatientStatus.Enable &&
    worksheet.status !== WorksheetStatus.Disabled

  const showVideoCallButton =
    videoEnabled && template.videoEnabled && canUseVideoCall

  const isNotEditing = (mia: MediumItemAnswer) => {
    return [
      MediumItemAnswerStatus.Confirmed,
      MediumItemAnswerStatus.Disabled,
    ].includes(mia.status)
  }
  const isConfirmedSection = (mi: MediumItem) => {
    const mediumItemAnswers = worksheet.mediumItemAnswers.filter(
      mia => mia.mediumItemUid === mi.uid,
    )

    if (mediumItemAnswers.length === 0) {
      return false
    }

    return mediumItemAnswers.every(isNotEditing)
  }

  const breadCrumbParams: BreadcrumbParam[] = [
    { label: templateFolder.name, isLink: false },
    { label: worksheet.title, isLink: false },
  ]

  return (
    <Container>
      <Box padding={24} paddingBottom={0}>
        <Flex justifyBetween alignCenter wrap>
          <BasicInfo>
            <Breadcrumb breadcrumbParams={breadCrumbParams} />

            <Spacer size={20} horizontal />

            {isWorksheetDisabled && (
              <TitleSideDisableBtn>
                <CommonDisableLabel text={'無効'} />
              </TitleSideDisableBtn>
            )}

            {showVideoCallButton && (
              <CallButtonWrapper>
                <Button
                  size="S"
                  text="ビデオ通話"
                  onClick={onClickVideoCall}
                  buttonType="notice"
                  //TODO: Add iconName
                  disabled={
                    !callable(currentPatient) || trial.videoProvider !== 0 // not skyway
                  }
                />
              </CallButtonWrapper>
            )}
          </BasicInfo>

          <Buttons>
            <FlexItem>
              {hasPermission(PERMISSIONS.Worksheet_Enable) &&
                isWorksheetDisabled &&
                isEnablePatient && <EnableButton edcLinked={edcLinked} />}
            </FlexItem>
            <FlexItem>
              {hasPermission(PERMISSIONS.Worksheet_Disable) &&
                !isWorksheetDisabled &&
                isEnablePatient && <DisableButton edcLinked={edcLinked} />}
            </FlexItem>
            <FlexItem>
              {pdfOutputEnabled &&
                hasPermission(PERMISSIONS.Patient_Download) &&
                !isWorksheetDisabled && <OutputButton />}
            </FlexItem>
            <FlexItem>
              <PulldownContainer
                videoEnabled={videoEnabled && worksheet.videoEnabled}
                videoCaptureEnabled={videoCaptureEnabled}
                canViewVideoCapture={hasPermission(
                  PERMISSIONS.Worksheet_ReadVideoCapture,
                )}
                canViewVideoHistory={hasPermission(
                  PERMISSIONS.Worksheet_ReadVideoCallHistory,
                )}
                deletable={
                  worksheet.status === WorksheetStatus.New &&
                  hasPermission(PERMISSIONS.Worksheet_Delete)
                }
              />
            </FlexItem>
          </Buttons>
        </Flex>
      </Box>

      <Box px={24} mt={3}>
        <Anchors
          items={template.mediumItems.map(mi => {
            return {
              name: mi.title,
              path: `#${mi.uid}`,
              confirmed: isConfirmedSection(mi),
            }
          })}
        />
      </Box>

      <MediumItemAnswersContainer marginTop={3}>
        {template.mediumItems.map(mi => {
          const mediumItemAnswers = worksheet.mediumItemAnswers.filter(
            mia => mia.mediumItemUid === mi.uid,
          )

          if (!mi.isLogline && mediumItemAnswers.length !== 1) {
            return null
          }

          return mi.isLogline ? (
            <LoglineSection
              key={mi.uid}
              mediumItem={mi}
              mediumItemAnswers={mediumItemAnswers}
              worksheetEditable={worksheetEditable}
            />
          ) : (
            <SingleSection
              key={mediumItemAnswers[0].uid}
              mediumItemAnswer={mediumItemAnswers[0]}
              worksheetStatus={worksheet.status}
              isEnablePatient={isEnablePatient}
            />
          )
        })}
      </MediumItemAnswersContainer>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Box = styled.div<SpaceProps>`
  ${space}
`

const SCROLL_BAR_WIDTH = 6
const MediumItemAnswersContainer = styled.div<SpaceProps>`
  height: 100%;
  overflow-y: scroll;
  padding: 0 ${24 - SCROLL_BAR_WIDTH}px 24px 24px;
  ${space}
`

const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;

  > div:not(:first-child) {
    margin-left: 12px;
  }
`

const TitleSideDisableBtn = styled.div`
  display: inline-block;
  margin: 6px 0;
`

const CallButtonWrapper = styled.div`
  padding-left: 18px;
`
