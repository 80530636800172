import dayjs from 'dayjs'

type DateFormat =
  | 'YYYY/MM/DD (ddd) HH:mm:ss'
  | 'YYYY/MM/DD (ddd) HH:mm'
  | 'YYYY/MM/DD (ddd)'
  | 'YYYY/MM/DD'
  | 'YYYY-MM-DD'
  | 'HH:mm'

export const formatDate = (date: string | Date, format: DateFormat) => {
  return dayjs(date).format(format)
}
