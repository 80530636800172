import { useCallback, useEffect, useState } from 'react'

import { generatePath, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { httpStatus } from 'src/constants/statusCodes'
import { usePreSession } from 'src/features/explanation/hooks/usePreSession'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { GqlError } from 'src/lib/gql-client'
import { getNotFoundRoute } from 'src/modules/dashboard/trial/detail/e-consent/explanation-room/routes'

import { useExplanationRevisionAbout } from '../api'
import { useRoomMediaContext } from '../context/MediaProvider'

type UsePatientLobbyProps = {
  trialUid: string
  explanationRevisionUid: string
}

export const usePatientLobby = (props: UsePatientLobbyProps) => {
  const { trialUid, explanationRevisionUid } = props

  const { initializePatientVideoCall } = useRoomMediaContext()

  const [isUnauthorized, setIsUnauthorized] = useState(false)
  const toast = useMirohaToast()

  const [roomUid, setRoomUid] = useState<string>()

  const toastError = (error: Error | GqlError) => {
    toast({
      status: 'error',
      title: error.message,
    })
  }

  const { data: revisionAbout } = useExplanationRevisionAbout({
    trialUid,
    explanationRevisionUid,
    onError: toastError,
  })

  const navigate = useNavigate()

  const moveToRoom = useCallback(
    (sessionUid: string) => {
      navigate(
        generatePath(Paths.PatientExplanationRoomTop, { trialUid, sessionUid }),
      )
    },
    [navigate, trialUid],
  )

  const [isWaitingSession, setIsWaitingSession] = useState(false)

  const { preSession } = usePreSession({
    isPatient: true,
    explanationRevisionUid: explanationRevisionUid,
    onSetup: preSession => {
      if (preSession.explanationRevision.latestHistory.status === 'Disabled') {
        navigate(getNotFoundRoute({ type: 'patient' }))
        return
      }

      if (!!preSession.videoCallRoomUid) {
        setRoomUid(preSession.videoCallRoomUid)
      }

      if (!preSession.explanationSessionUid) {
        setIsWaitingSession(true)
      }
    },
    onAdditionalFetch: preSession => {
      // セッションが開始したら自動でトップ画面へ（最初からセッションが開始している場合は手動で遷移）
      if (!!preSession.explanationSessionUid && isWaitingSession) {
        moveToRoom(preSession.explanationSessionUid)
      }
    },
    onError: error => {
      if (
        error.status === httpStatus.Unauthorized ||
        error.status === httpStatus.Forbidden
      ) {
        setIsUnauthorized(true)
        return
      }
      toastError(error)
    },
  })

  useEffect(() => {
    if (!roomUid || !revisionAbout?.candidateID) return
    initializePatientVideoCall({
      roomUid,
      candidateId: revisionAbout.candidateID,
    })
  }, [roomUid, revisionAbout?.candidateID, initializePatientVideoCall])

  return {
    preSession,
    revisionAbout,
    isUnauthorized,
    moveToRoom,
  }
}
