import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFlash } from 'src/modules/flash/use-flash'
import { routes } from 'src/modules/routes'

import { actions as patientListActions } from './redux'
import { usePatientQuery } from './use-patient-query'

export const useFetch = ({
  trialUid,
  per,
}: {
  trialUid: string
  per: number
}) => {
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const { page, searchQuery, sortKey, sortOrder, statusFilter } =
    usePatientQuery()
  const navigate = useNavigate()

  const { showError } = useFlash()

  useEffect(() => {
    const fetch = async () => {
      setFetched(false)
      try {
        await dispatch(
          patientListActions.fetch({
            trialUid,
            page,
            per,
            searchQuery,
            statusFilter,
            sortKey,
            sortOrder,
          }),
        )
        setFetched(true)
      } catch (error) {
        //TODO: login key?
        console.error('patientActions.fetch Error: ', error)
        if (error?.original?.response?.status === 403) {
          navigate(routes.httpError403)
          return
        }
        showError(error.message)
      }
    }

    fetch()
  }, [
    dispatch,
    page,
    per,
    searchQuery,
    statusFilter,
    showError,
    sortKey,
    sortOrder,
    trialUid,
    navigate,
  ])

  return {
    fetched,
  }
}
