import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { DeleteModal } from './delete-modal'
import { getSelectedTrial } from '../../../../../../../entities/account/selector'
import { actions } from '../../../../../../../entities/template-folder/redux'
import { submitDelete } from '../../../../../../../entities/template-folder/request'
import { getSingle } from '../../../../../../../entities/template-folder/selector'
import { RootState } from '../../../../../../../reducer'
import { useRequestState } from '../../../../../../../server/use-request-state'

type Props = {
  onClose: () => void
  templateFolderUid: string
}

export const DeleteModalContainer: React.FC<Props> = props => {
  const templateFolder = useSelector((state: RootState) =>
    getSingle(state, props.templateFolderUid),
  )
  const { request, requesting } = useDeleteTemplateFolder(templateFolder.uid)
  const onSubmit = useCallback(async () => {
    await request()
    props.onClose()
  }, [props, request])

  return (
    <DeleteModal
      templateFolderName={templateFolder.name}
      onClose={props.onClose}
      onSubmit={onSubmit}
      requesting={requesting}
    />
  )
}

const useDeleteTemplateFolder = (templateFolderUid: string) => {
  const trialUid = useSelector(getSelectedTrial)!.uid
  const { requesting, errorMessage, requestDone, requestStarted } =
    useRequestState()
  const dispatch = useDispatch()

  const toast = useMirohaToast()

  const request = useCallback(async () => {
    try {
      requestStarted()
      await submitDelete({ trialUid, templateFolderUid })
      dispatch(actions.delete({ uid: templateFolderUid }))
      toast({
        status: 'success',
        title: 'フォルダを削除しました',
      })
      requestDone()
    } catch (error) {
      toast({
        status: 'error',
        title: error.message,
      })
      requestDone()
    }
  }, [
    dispatch,
    requestDone,
    requestStarted,
    trialUid,
    templateFolderUid,
    toast,
  ])

  return { request, requesting, errorMessage }
}
