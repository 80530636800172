// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgEConsent = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill={props.color ?? 'currentColor'} clipPath="url(#e-consent_svg__a)">
      <path
        fillRule="evenodd"
        d="M21.967 13.382a.5.5 0 0 0 .283.258l4.11 1.5a.5.5 0 0 0 .64-.3l4.34-11.91a1.001 1.001 0 0 0-.6-1.29L27.57.49a1 1 0 0 0-1.28.6L21.95 13a.5.5 0 0 0 .017.382Zm-1.002 2.932a1 1 0 0 0 .565.516L24.66 18a1 1 0 0 0 1.32-.61l.44-1.22a.51.51 0 0 0-.3-.65l-4.1-1.49a.5.5 0 0 0-.64.3l-.45 1.22a1 1 0 0 0 .035.764Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M23.258 15.997a1.5 1.5 0 0 0-1.898.46l-3.07 4.27a1.5 1.5 0 0 0-.24 1.23l.677 2.793a1.5 1.5 0 0 0 2.15.977l2.244-1.168a1.5 1.5 0 0 0 .785-1.074l.955-5.484a1.5 1.5 0 0 0-.798-1.594l-.805-.41Zm-1.086 1.045a.5.5 0 0 1 .633-.154l.805.41a.5.5 0 0 1 .266.531l-.955 5.485a.5.5 0 0 1-.262.358l-2.244 1.168a.503.503 0 0 1-.131.047l1.146-2.866a.85.85 0 1 0-.937-.35l-.924 2.31-.548-2.26a.5.5 0 0 1 .08-.41l3.07-4.27Z"
        clipRule="evenodd"
      />
      <path d="M3.66 25.024c-1.68 1-4.34 3.48-2.86 5.64 1.25 1.82 4.43.56 5.92-.27 4.38-2.42 5.78-8.13 4.56-12.72a1.01 1.01 0 0 0-2 .27 15.45 15.45 0 0 0 1.63 8.18c.5 1 1.43 2.19 2.61 1.24 1-.8 1.46-2.57 1.79-3.73h-1.93a4.25 4.25 0 0 0 2.19 2.69 4.75 4.75 0 0 0 3.91-.3c1.17-.48.66-2.41-.53-1.93a3.71 3.71 0 0 1-2.4.48 2.12 2.12 0 0 1-1.24-1.47 1 1 0 0 0-1.93 0c-.12.444-.263.882-.43 1.31-.23.392-.424.804-.58 1.23.55.24.73.23.54 0 0-.08-.1-.16-.14-.24-.04-.08-.23-.42-.33-.63-.188-.41-.352-.83-.49-1.26a14.202 14.202 0 0 1-.67-5.53l-2 .26c.84 3.14.27 7-2.11 9.33a7.39 7.39 0 0 1-4.08 2c-.17 0-.58.14-.72 0s.14-.78.28-1a6.63 6.63 0 0 1 1.95-1.78c1.1-.67.09-2.4-1-1.72l.06-.05Z" />
    </g>
    <defs>
      <clipPath id="e-consent_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgEConsent
