import React from 'react'

import { usePane } from 'src/hooks/use-pane'
import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { EditIcfDocument } from './edit'
import { useEditIcfDocument } from './edit.hooks'
import { RevisionHistoryContainer } from '../revision-history/revision-history-container'

export const EditIcfDocumentContainer: React.FC = () => {
  const {
    icfDocument,
    latestRevision,
    errors,
    listPath,
    shouldSelectHospital,
    hospitalName,
    latestFilePreviewUrl,
    canUpdate,
    canChangeDocumentType,
    canSetDocuSignRole,
    hasSetDocuSignRole,
    approvalHandlers,
    pullDownState,
    deleteModalState,
    disableModalState,
    enableModalState,
    latestRevisionStatus,
    hasMultipleRevisionHistories,
    hasTempFile,
    onRedirectDocuSignEdit,
    onRedirectDocuSignAgreementFormPreview,
    onChangeName,
    onChangeVersion,
    onChangeDocumentType,
    onChangeRequiredPatientSign,
    onSelectFile,
    onValidate,
    onSubmitUpdate,
    onSubmitDelete,
    onDisable,
    onEnable,
    onCancelRevision,
    navigateToAddRevision,
  } = useEditIcfDocument()

  const {
    handlers: { openPane: openRevisionHistoryPane },
    renderPane,
  } = usePane({ paneType: 'modal', closeOnOutsideClick: true })

  useBlockTransition({
    showAlert: canUpdate,
  })

  return (
    <>
      {renderPane(<RevisionHistoryContainer />)}

      <EditIcfDocument
        icfDocument={icfDocument}
        latestRevision={latestRevision}
        errors={errors}
        listPath={listPath}
        shouldSelectHospital={shouldSelectHospital}
        hospitalName={hospitalName}
        latestFilePreviewUrl={latestFilePreviewUrl}
        canChangeDocumentType={canChangeDocumentType}
        canUpdate={canUpdate}
        canEdit={latestRevisionStatus.canEdit}
        canRequestApproval={latestRevisionStatus.canRequestApproval}
        canWithdraw={latestRevisionStatus.canWithdraw}
        canApprove={latestRevisionStatus.canApprove}
        canReject={latestRevisionStatus.canReject}
        canDisable={latestRevisionStatus.canDisable}
        canAddRevision={latestRevisionStatus.canAddRevision}
        canSetDocuSignRole={canSetDocuSignRole}
        hasSetDocuSignRole={hasSetDocuSignRole}
        pullDownState={pullDownState}
        deleteModalState={deleteModalState}
        requestApprovalModalState={approvalHandlers.requestApprovalModalState}
        withdrawModalState={approvalHandlers.withdrawModalState}
        approveModalState={approvalHandlers.approveModalState}
        rejectModalState={approvalHandlers.rejectModalState}
        disableModalState={disableModalState}
        enableModalState={enableModalState}
        hasMultipleRevisionHistories={hasMultipleRevisionHistories}
        hasTempFile={hasTempFile}
        onRedirectDocuSignEdit={onRedirectDocuSignEdit}
        onRedirectDocuSignPreview={onRedirectDocuSignAgreementFormPreview}
        onChangeName={onChangeName}
        onChangeVersion={onChangeVersion}
        onChangeDocumentType={onChangeDocumentType}
        onChangeRequiredPatientSign={onChangeRequiredPatientSign}
        onSelectFile={onSelectFile}
        onValidate={onValidate}
        onSubmitUpdate={onSubmitUpdate}
        onSubmitDelete={onSubmitDelete}
        onRequestApproval={approvalHandlers.onRequestApproval}
        onWithdraw={approvalHandlers.onWithdrawApprovalRequest}
        onApprove={approvalHandlers.onApprove}
        onReject={approvalHandlers.onReject}
        onDisable={onDisable}
        onEnable={onEnable}
        onCancelRevision={onCancelRevision}
        navigateToAddRevision={navigateToAddRevision}
        openRevisionHistoryPane={openRevisionHistoryPane}
      />
    </>
  )
}
