import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { blue, gray, white } from 'src/components/base/color/palette'
import styled from 'styled-components'

type Props = {
  selectedTab: 'basic' | 'answer'
  onClickBasic: () => void
  onClickAnswer: () => void
}

export const useTabs = () => {
  const [selectedTab, setSelectedTab] = useState<Props['selectedTab']>('basic')
  const onClickBasic = useCallback(() => {
    setSelectedTab('basic')
  }, [])
  const onClickAnswer = useCallback(() => {
    setSelectedTab('answer')
  }, [])
  const renderTabs = () => {
    return (
      <Tabs
        selectedTab={selectedTab}
        onClickBasic={onClickBasic}
        onClickAnswer={onClickAnswer}
      ></Tabs>
    )
  }
  return [selectedTab, renderTabs] as const
}

const Tabs: React.FC<Props> = props => {
  return (
    <TabPanel>
      <Tab
        onClick={props.onClickBasic}
        selected={props.selectedTab === 'basic'}
      >
        <Text fontWeight="bold">基本情報</Text>
      </Tab>
      <Tab
        onClick={props.onClickAnswer}
        selected={props.selectedTab === 'answer'}
      >
        <Text fontWeight="bold">回答実績</Text>
      </Tab>
    </TabPanel>
  )
}

const TabPanel = styled.ul`
  background-color: ${gray[10]};
  margin: 0;
  list-style-type: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 4px;
  width: fit-content;
`

const Tab = styled.li<{ selected?: boolean }>`
  cursor: pointer;
  height: 32px;
  width: 140px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => (p.selected ? white : gray[100])};
  background-color: ${p => (p.selected ? blue[70] : gray[10])};
`
