import { useCallback, useMemo } from 'react'

import {
  useAuthenticatedAccount,
  useSelectedTrial,
} from 'src/features/auth/context'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { GqlError } from 'src/lib/gql-client'
import { openNewWindow } from 'src/utils/openNewWindow'

import { useAvailablePatientVideoCalls, usePatient } from '../api'
import { createPatientVideoCall } from '../api/createPatientVideoCall'
import { getPatientVideoCallUrl } from '../utils/getPatientVideoCallUrl'

export const useStartPatientVideoCall = ({
  patientUid,
  onCallStart,
}: {
  patientUid: string
  onCallStart: () => void
}) => {
  const { data: patient } = usePatient({ patientUid, revalidateIfStale: false })

  const { data: videoCalls } = useAvailablePatientVideoCalls({
    patientUid,
    order: 'CreatedAtAsc', // NOTE: want desc
    refreshInterval: previous => {
      if (!previous) return 0
      // availableなビデオ通話がある場合、バッジ表示などを更新するために3秒おきに再取得する
      return previous.length > 0 ? 3000 : 0
    },
  })

  const { selectedTrial } = useSelectedTrial()

  const {
    account: { selectedTrialHospitalUid, member },
  } = useAuthenticatedAccount()

  const toast = useMirohaToast()

  const activeVideoCall = useMemo(() => {
    if (!videoCalls) return undefined
    if (videoCalls.length === 0) return undefined
    return videoCalls[videoCalls.length - 1]
  }, [videoCalls])

  const joinedMemberCountWithPartner =
    activeVideoCall?.__typename === 'PatientVideoCallPartnerTrialHospital'
      ? (activeVideoCall.videoCallRoom.joinedMemberCount ?? 0)
      : 0

  const joinedMemberCountWithPatient =
    activeVideoCall?.__typename === 'PatientVideoCallPatient'
      ? (activeVideoCall.videoCallRoom.joinedMemberCount ?? 0)
      : 0

  const totalJoinedMemberCount =
    joinedMemberCountWithPartner + joinedMemberCountWithPatient

  const canCallWithPartner = !!patient?.partnerTrialHospital

  const canCallWithPatient = !!patient?.email || !!patient?.phoneNumber

  const hasPermission = member.role === 'Dr' || member.role === 'CRC'

  const existingPatientVideoCallUid =
    !!activeVideoCall &&
    activeVideoCall.__typename === 'PatientVideoCallPatient'
      ? activeVideoCall.patientVideoCallUid
      : undefined

  const handleStartVideoCalWithPartner = useCallback(async () => {
    const isPartner =
      selectedTrialHospitalUid === patient?.partnerTrialHospital?.uid
    const windowType: Parameters<typeof openNewWindow>[1] = isPartner
      ? 'tab'
      : 'newWindow'

    if (!!activeVideoCall) {
      openNewWindow(
        getPatientVideoCallUrl(
          selectedTrial.uid,
          activeVideoCall.patientVideoCallUid,
        ),
        windowType,
      )
      onCallStart()
      return
    }

    // 非同期処理を行うため、新しいウィンドウを開いておく。こうしないとpopup blockに引っかかる場合がある
    const newWindow = openNewWindow('', windowType)
    if (!newWindow) return

    try {
      const res = await createPatientVideoCall({
        patientUid,
        patientVideoCallType: 'PartnerTrialHospital',
      })
      newWindow.location.href = getPatientVideoCallUrl(
        selectedTrial.uid,
        res.patientVideoCallUid,
      )
      onCallStart()
    } catch (error) {
      if (error instanceof GqlError) {
        toast({
          status: 'error',
          title: error.message,
        })
        return
      }
      console.error(error)
    }
  }, [
    selectedTrial,
    activeVideoCall,
    patientUid,
    selectedTrialHospitalUid,
    patient,
    onCallStart,
    toast,
  ])

  const handleStartVideoCalWithPatient = useCallback(async () => {
    const windowType = 'newWindow'
    if (
      !!activeVideoCall &&
      activeVideoCall.__typename === 'PatientVideoCallPatient'
    ) {
      openNewWindow(
        getPatientVideoCallUrl(
          selectedTrial.uid,
          activeVideoCall.patientVideoCallUid,
        ),
        windowType,
      )
      onCallStart()
      return
    }

    const newWindow = openNewWindow('', windowType)
    if (!newWindow) return

    try {
      const res = await createPatientVideoCall({
        patientUid,
        patientVideoCallType: 'Patient',
      })
      newWindow.location.href = getPatientVideoCallUrl(
        selectedTrial.uid,
        res.patientVideoCallUid,
      )
      onCallStart()
    } catch (error) {
      if (error instanceof GqlError) {
        toast({
          status: 'error',
          title: error.message,
        })
        return
      }
      console.error(error)
    }
  }, [selectedTrial.uid, activeVideoCall, patientUid, onCallStart, toast])

  return {
    existingPatientVideoCallUid,
    joinedMemberCountWithPartner,
    joinedMemberCountWithPatient,
    totalJoinedMemberCount,
    canCallWithPartner,
    canCallWithPatient,
    hasPermission,
    handleStartVideoCalWithPartner,
    handleStartVideoCalWithPatient,
  }
}
