import { useMemo } from 'react'

import { generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import {
  useCurrentMember,
  usePermission,
  useSelectedTrial,
} from 'src/features/auth/context'
import { PERMISSIONS } from 'src/lib/permission'

const navigationRouteKeys = [
  'home',
  'patients',
  'explanations',
  'referredPatients',
  'templates',
  'icfDocuments',
  'criteria',
  'trialInfo',
  'account',
  'crf',
  'csvExport',
] as const

export type NavigationRouteKey = (typeof navigationRouteKeys)[number]

export type NavigationRouteItem<
  Key extends NavigationRouteKey = NavigationRouteKey,
> = {
  key: Key
  linkPath: string
  isEnabled: boolean
}

export type NavigationRouteItems = {
  [Key in NavigationRouteKey]: NavigationRouteItem<Key>
}

export const useNavigationRoutes = (): {
  routeItems: NavigationRouteItems
} => {
  const { selectedTrialHospitalRole } = useCurrentMember()
  const {
    selectedTrial: {
      uid: trialUid,
      featureFlags,
      newEconsentEnabled: eConsentEnabled,
      eRecruitEnabled,
    },
  } = useSelectedTrial()

  const { hasPermission } = usePermission()

  return {
    routeItems: useMemo(
      () => ({
        // Homeは未実装
        home: {
          key: 'home',
          linkPath: '',
          isEnabled: false,
        },
        patients: {
          key: 'patients',
          linkPath: generatePath(Paths.Patients, { trialUid }),
          isEnabled: true,
        },
        explanations: {
          key: 'explanations',
          linkPath: generatePath(Paths.Explanations, { trialUid }),
          isEnabled: eConsentEnabled,
        },
        referredPatients: {
          key: 'referredPatients',
          linkPath: generatePath(Paths.ReferredPatients, { trialUid }),
          isEnabled: eRecruitEnabled,
        },
        templates: {
          key: 'templates',
          linkPath: generatePath(Paths.Templates, { trialUid }),
          isEnabled: true,
        },
        icfDocuments: {
          key: 'icfDocuments',
          linkPath: generatePath(Paths.IcfDocuments, { trialUid }),
          isEnabled: eConsentEnabled,
        },
        criteria: {
          key: 'criteria',
          linkPath: generatePath(Paths.Criteria, { trialUid }),
          isEnabled: eRecruitEnabled && selectedTrialHospitalRole !== 'Partner',
        },
        trialInfo: {
          key: 'trialInfo',
          linkPath: generatePath(Paths.SettingsTrial, { trialUid }),
          isEnabled: hasPermission(PERMISSIONS.Menu_ShowSettings),
        },
        account: {
          key: 'account',
          linkPath: generatePath(Paths.SettingsMember, { trialUid }),
          isEnabled: hasPermission(PERMISSIONS.Menu_ShowSettings),
        },
        crf: {
          key: 'crf',
          linkPath: generatePath(Paths.SettingsCrf, { trialUid }),
          isEnabled:
            featureFlags.eSourceV1 &&
            hasPermission(PERMISSIONS.Menu_ShowSettings),
        },
        csvExport: {
          key: 'csvExport',
          linkPath: generatePath(Paths.SettingsWSDataExport, { trialUid }),
          isEnabled: hasPermission(PERMISSIONS.Dataexport_DataExport),
        },
      }),
      [
        trialUid,
        eConsentEnabled,
        featureFlags.eSourceV1,
        selectedTrialHospitalRole,
        eRecruitEnabled,
        hasPermission,
      ],
    ),
  }
}
