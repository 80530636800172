import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { CompleteAgreementDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const completeAgreement = async ({
  explanationRevisionUid,
}: {
  explanationRevisionUid: string
}): Promise<void> => {
  try {
    await graphqlRequest(CompleteAgreementDocument, {
      input: {
        explanationRevisionUid,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useCompleteAgreement = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({ explanationRevisionUid }: { explanationRevisionUid: string }) =>
      completeAgreement({ explanationRevisionUid }),
    option,
  )
}
