import { useCallback } from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { ConfirmAnswerStateDateModalContainer } from './confirm-answer-state-date-modal-container'
import { useValidate } from './use-validate'

type Props = {
  disabled: boolean
}

export const ConfirmAnswerStateDateButton: React.FC<Props> = props => {
  const { disabled } = props

  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  const { validate } = useValidate()

  const onClick = useCallback(() => {
    validate(openModal)
  }, [openModal, validate])

  return (
    <>
      <Button
        text="試験開始日の確定"
        buttonType="important"
        size="S"
        onClick={onClick}
        disabled={disabled}
      />
      {modalOpen && (
        <ConfirmAnswerStateDateModalContainer onClose={closeModal} />
      )}
    </>
  )
}
