export const getListRoute = (trialUid: string) => {
  return `/dashboard/trials/${trialUid}/epro/patient_answers`
}

export const getDetailRoute = ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}) => {
  return `/dashboard/trials/${trialUid}/epro/patient_answers/${patientUid}`
}
