// inspired by https://zenn.dev/okunokentaro/articles/01gmpkp9gzfyr1za5wvrxt0vy6#branded-types

/**
 * UIDをコンパイラ上で区別するたのUtility。
 * Entityの型を定義する際に任意の文字列を型引数に含めることで型を定義する
 */
export type UID<T extends string> = string & { _brand: T }

/** uidというフィールドを持つ型を型引数に指定することでその型のuidの型にcastする */
export const castUid = <T extends { uid: string }>(uid: string) => {
  return uid as T['uid']
}
