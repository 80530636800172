import { useState } from 'react'

import {
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { CalendarDateInput } from 'src/components/CalendarDateInput/CalendarDateInput'
import { Select, SelectItem } from 'src/components/Select/Select'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { downloadObject } from 'src/utils/downloadObject'

import {
  useDeliveryDocumentByDownload,
  useDeliveryDocumentByEmail,
  useDeliveryDocumentByEmailAndDownload,
} from '../../api'

type Props = {
  explanationRevisionUID: string
  isRemote: boolean
  explanationRevisionMemberItem: SelectItem<string, React.ReactNode>[]
  mutateSession: () => void
  isOpen: boolean
  onClose: () => void
}

export const DeliveryDocsModal: React.FC<Props> = ({
  explanationRevisionUID,
  isRemote,
  explanationRevisionMemberItem,
  mutateSession,
  isOpen,
  onClose,
}) => {
  const [deliveryMethod, setDeliveryMethod] = useState<
    'Download' | 'EmailAndDownload' | 'Email' | undefined
  >(isRemote ? 'EmailAndDownload' : undefined)

  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date())
  const [memberUid, setMemberUid] = useState<string>()

  const toast = useMirohaToast()

  const onSuccess = ({ downloadUrl }: { downloadUrl: string }) => {
    downloadObject({ url: downloadUrl })
    mutateSession()
    toast({
      title: '文書を交付しました',
      status: 'success',
    })
    onClose()
  }

  const {
    request: deliveryByEmailAndDownload,
    requesting: emailAndDownloadRequesting,
  } = useDeliveryDocumentByEmailAndDownload({
    onSuccess,
  })
  const { request: deliveryByDownload, requesting: downloadRequesting } =
    useDeliveryDocumentByDownload({
      onSuccess,
    })
  const { request: deliveryByEmail, requesting: emailRequesting } =
    useDeliveryDocumentByEmail({
      onSuccess: () => {
        mutateSession()
        toast({
          title: '文書を交付しました',
          status: 'success',
        })
        onClose()
      },
    })

  const requesting =
    emailAndDownloadRequesting || downloadRequesting || emailRequesting

  const handleSubmit = async () => {
    if (!deliveryMethod || !deliveryDate) return

    if (deliveryMethod === 'Email') {
      if (!memberUid) return
      await deliveryByEmail({
        explanationRevisionUid: explanationRevisionUID,
        memberUid,
        deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD'),
      })
      return
    }

    if (deliveryMethod === 'EmailAndDownload' && isRemote) {
      await deliveryByEmailAndDownload({
        explanationRevisionUid: explanationRevisionUID,
        deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD'),
      })
      return
    }

    if (deliveryMethod === 'EmailAndDownload' && !isRemote) {
      if (!memberUid) return
      await deliveryByEmailAndDownload({
        explanationRevisionUid: explanationRevisionUID,
        memberUid,
        deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD'),
      })
      return
    }
    await deliveryByDownload({
      explanationRevisionUid: explanationRevisionUID,
      deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD'),
    })
  }

  const canSubmit = (() => {
    if (isRemote) return true
    if (deliveryMethod === 'Email') {
      return !!memberUid && !!deliveryDate
    }
    return !!deliveryDate
  })()

  const deliveryText = isRemote ? '交付' : '発行'

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {`同意が完了しました。署名済文書を${deliveryText}しますか？`}
        </ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Grid templateColumns="96px 1fr" alignContent="center" rowGap="8">
              <GridItem alignContent="center">
                <Flex h="full" align="center">
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                    交付日
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <CalendarDateInput
                  value={deliveryDate}
                  onChange={setDeliveryDate}
                />
              </GridItem>
            </Grid>

            {/* 遠隔 or [遠隔同意(パートナー施設)・対面]で表示が変わる */}
            {isRemote ? (
              <Text color="green.500" fontSize="sm">
                交付するボタンを押下すると患者・代諾者へ署名済資料が送付されます。
                <br />
                また、送付資料と同様の資料が本端末にダウンロードされます。
              </Text>
            ) : (
              <Grid templateColumns="96px 1fr" alignContent="center" rowGap="8">
                <GridItem>
                  <Flex h="full" align="center">
                    <Text fontSize="sm" fontWeight="bold" color="gray.600">
                      交付形式
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Flex align="center">
                    <RadioGroup
                      colorScheme="green"
                      value={deliveryMethod}
                      onChange={value => {
                        setDeliveryMethod(value as 'Download' | 'Email')
                      }}
                    >
                      <HStack spacing="8">
                        <Radio value="Download">ダウンロード</Radio>
                        <Radio value="Email">Dr/CRCへメール送信</Radio>
                      </HStack>
                    </RadioGroup>
                  </Flex>
                </GridItem>
                {deliveryMethod === 'Email' && (
                  <>
                    <GridItem></GridItem>
                    <GridItem>
                      <Select
                        value={memberUid}
                        onChange={setMemberUid}
                        placeholder="送信先を選択してください"
                        items={explanationRevisionMemberItem}
                      />
                    </GridItem>
                  </>
                )}
              </Grid>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            colorScheme="green"
            onClick={async () => {
              await handleSubmit()
            }}
            isDisabled={!canSubmit || requesting}
          >
            {`${deliveryText}する`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
