import React from 'react'

import { Text } from '@chakra-ui/react'

import { WithCommentModal } from './with-comment-modal'
import { IcfDocument, IcfDocumentRevision } from '../entity'

type Props = {
  onClose: () => void
  numberingId: IcfDocument['numberingId']
  documentName: IcfDocumentRevision['name']
  onSubmit: (comment: string) => void
}

export const EnableModal: React.FC<Props> = ({
  onClose,
  numberingId,
  documentName,
  onSubmit,
}) => {
  return (
    <WithCommentModal
      title="文書を有効化"
      onClose={onClose}
      onSubmit={onSubmit}
      required={false}
      submitText="有効化する"
      submitButtonType="important"
      descriptionComponent={
        <Text textAlign="center">
          <strong>{`文書ID:${numberingId} ${documentName}`}</strong>
          を有効化します
        </Text>
      }
    />
  )
}
