import { Member } from 'src/features/member/types'
import { TrialHospital } from 'src/features/trial/types'
import { castUid } from 'src/utils/brandedUid'

import { ExplanationFormSchema } from '../schema/explanationFormSchema'
import { CreateExplanationInput } from '../types'

export const expFormSchemaToCreateInput = (
  trialHospitalUid: TrialHospital['uid'],
  formValue: ExplanationFormSchema,
  patientMasterUid?: string,
): CreateExplanationInput => {
  return {
    trialHospitalUid,
    partnerTrialHospitalUid: formValue.partnerTrialHospitalUid
      ? castUid<TrialHospital>(formValue.partnerTrialHospitalUid)
      : undefined,
    patientMasterUid: patientMasterUid,
    candidateId: formValue.candidateId,
    expDiseaseId: formValue.diseaseId,
    email: formValue.email,
    phoneNumber: formValue.phoneNumber,
    scheduledAt:
      formValue.type !== 'InPerson' ? formValue.scheduledAt : undefined,
    notifyToPatient:
      formValue.type === 'Remote' && !!formValue.scheduledAt
        ? formValue.notifyToPatient
        : undefined,
    type: formValue.type,
    withRepresentative: formValue.representative === 'with',
    members: formValue.members.map(member => ({
      trialHospitalUid: castUid<TrialHospital>(member.trialHospitalUid),
      memberUid: castUid<Member>(member.member.uid),
    })),
    docSets: formValue.docSetUids.map((uid, index) => ({
      uid,
      index,
    })),
  }
}
