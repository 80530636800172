import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { useQuery } from '../../../../../../../router'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import { completeEditDocuSignTemplate } from '../request'

const eventQuery = 'event'
const envelopeIdQuery = 'envelopeId'

export const useCompleteDocuSignEdit = () => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const query = useQuery()
  const onShown = useCallback(async () => {
    if (!selectedTrialHospitalUid) {
      return
    }

    // DocuSignでテンプレート編集後、以下のクエリが返却される
    // ?envelopeId=d48109ac-ee9c-470f-b523-5158fc0ff441&event=Save
    // envelopeIdの値として、templateIdが入る

    // イベントがSaveでなければ弾く
    const event = query.get(eventQuery)
    if (event !== 'Save') {
      return
    }

    const dsTemplateId = query.get(envelopeIdQuery)
    if (!dsTemplateId) {
      return
    }

    await completeEditDocuSignTemplate({
      trialUid,
      trialHospitalUid: selectedTrialHospitalUid,
      dsTemplateId,
    })
  }, [query, selectedTrialHospitalUid, trialUid])

  return {
    onShown,
  }
}
