import {
  Badge,
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Down, Up } from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { PERMISSIONS } from 'src/lib/permission'
import { getFullName } from 'src/utils/getFullName'

import { usePatients } from '../../api/searchPatients'
import { useSortPatients } from '../../hooks/useSortPatients'
import { getRelatedHospitalName } from '../../utils/getReleatedHospital'

export const PatientsTable: React.FC = () => {
  const { data } = usePatients({})
  const navigate = useNavigate()
  const { sortKey, sortOrder } = useSortPatients()

  const { hasPermission } = usePermission()
  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const patients = data?.patients ?? []

  return (
    <TableContainer>
      <Table variant="hoverable" alignContent="flex-start">
        <Thead>
          <Tr borderBottomWidth="0.5">
            <Th minW="80px" />
            <Th minW="160px">
              <Flex justifyContent="flex-start">
                <Text fontWeight="bold" textAlign="left">
                  症例番号
                </Text>
                <Box px="1" />
                {sortKey === 'DiseaseUID' &&
                  (sortOrder === 'Desc' ? <Down /> : <Up />)}
              </Flex>
            </Th>
            <Th minW="160px">
              <Flex justifyContent="flex-start">
                <Text fontWeight="bold" textAlign="left">
                  氏名
                </Text>
                <Box px="1" />
                {sortKey === 'Name' &&
                  (sortOrder === 'Desc' ? <Down /> : <Up />)}
              </Flex>
            </Th>
            <Th minW="160px" w="auto">
              <Text fontWeight="bold" textAlign="left">
                ふりがな
              </Text>
            </Th>
            {hasPermission(PERMISSIONS.Hospital_BelongAll) && (
              <Th minW="160px" w={undefined}>
                <Flex justifyContent="flex_start">
                  <Text fontWeight="bold" textAlign="left">
                    医療機関
                  </Text>
                  {sortKey === 'HospitalName' &&
                    (sortOrder === 'Desc' ? <Down /> : <Up />)}
                </Flex>
              </Th>
            )}
            <Th w="full">関連施設</Th>
          </Tr>
        </Thead>
        <Tbody>
          {patients.map(patient => {
            const disabled = patient.status === 'Disable'
            const textColor = disabled ? 'gray.100' : undefined
            return (
              <Tr
                onClick={() => {
                  navigate(
                    generatePath(Paths.Patient, {
                      trialUid: selectedTrial.uid,
                      patientUid: patient.uid,
                    }),
                  )
                }}
                key={patient.uid}
              >
                <Td>
                  {disabled ? (
                    <Badge bg="gray.100" color="gray.400" px="2" py="0.5">
                      無効
                    </Badge>
                  ) : (
                    <Badge bg="green.200" color="green.700" px="2" py="0.5">
                      有効
                    </Badge>
                  )}
                </Td>
                <Td>
                  <Text overflowWrap="break-word" color={textColor}>
                    {patient.diseaseUid}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} overflowWrap="break-word">
                    {getFullName(patient)}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} overflowWrap="break-word">
                    {`${patient.lastNameJa} ${patient.firstNameJa}`}
                  </Text>
                </Td>
                {hasPermission(PERMISSIONS.Hospital_BelongAll) && (
                  <Td color={textColor}>
                    {patient.trialHospital.hospital.name}
                  </Td>
                )}
                <Td color={textColor}>
                  {getRelatedHospitalName(patient, selectedTrialHospitalUid)}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
