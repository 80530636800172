/** Array.prototype.sort() に与えるcallback関数のutil
 *
 * indexプロパティを持つオブジェクトの配列を、indexの昇順でソートします
 *
 * @example
 * ```
 * const arr = [
 *  { index: 2, name: 'foo' },
 *  { index: 1, name: 'bar' },
 * ]
 * arr.sort(byIndexAsc)
 * // => [
 * //  { index: 1, name: 'bar' },
 * //  { index: 2, name: 'foo' },
 * // ]
 * ```
 *
 */
export const byIndexAsc = <T extends { index: number }>(a: T, b: T) => {
  return a.index - b.index
}

// byCreatedAtAscなどもここに定義したい
