import React from 'react'

import { Button } from 'src/components/base/button/button'
import { usePane } from 'src/hooks/use-pane'

import { AddForm, RequestValues } from './form'

type Props = {
  errorMessage: string | null
  requesting: boolean
  onSubmit: (values: RequestValues) => void
}

export const AddButton: React.FC<Props> = props => {
  const { errorMessage, requesting, onSubmit } = props
  const {
    handlers: { openPane, closePane },
    renderPane,
  } = usePane({ paneType: 'modal', closeOnOutsideClick: true })

  return (
    <>
      <Button
        size="S"
        text="新規作成"
        iconName="add"
        buttonType="normal"
        onClick={openPane}
      ></Button>
      {renderPane(
        <AddForm
          errorMessage={errorMessage || ''}
          requesting={requesting}
          onSubmit={onSubmit}
          closePane={closePane}
        />,
      )}
    </>
  )
}
