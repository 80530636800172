// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgSortDescend = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      d="M3.9 11.5a1 1 0 0 1-1 1H1a1 1 0 1 1 0-2h1.9a1 1 0 0 1 1 1ZM2.6 15a1 1 0 0 1-1 1H1a1 1 0 1 1 0-2h.6a1 1 0 0 1 1 1ZM5.85 8a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h3.85a1 1 0 0 1 1 1ZM7.8 4.5a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h5.8a1 1 0 0 1 1 1ZM9.75 1a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h7.75a1 1 0 0 1 1 1ZM11.5 6a1 1 0 0 1 1 1v7a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      d="M7.257 11.181a1 1 0 0 1 1.412-.074l2.831 2.548 2.831-2.548a1 1 0 0 1 1.338 1.486l-3.5 3.15a1 1 0 0 1-1.338 0l-3.5-3.15a1 1 0 0 1-.074-1.412Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSortDescend
