import styled, { css } from 'styled-components'

import { blue, gray } from '../color/palette'

type Props = {
  noUnderline?: boolean
}

const baseStyle = css<Props>`
  color: ${blue[70]};
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: ${p => (p.noUnderline ? 'none' : 'underline')};
  cursor: pointer;
  :hover {
    color: ${blue[80]};
  }
  :active {
    color: ${blue[90]};
  }
  :disabled {
    text-decoration: none;
    color: ${gray[40]};
    cursor: not-allowed;
  }
`

export const AnchorLink = styled.a`
  all: unset;
  ${baseStyle}
`

export const ButtonLink = styled.button.attrs(props => ({
  type: props.type ?? 'button',
}))`
  all: unset;
  ${baseStyle}
`
