import {
  useDisclosure,
  Stack,
  Input,
  IconButton,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react'
import { Calendar } from 'src/components/__legacy__icon/monochrome'
import { CalendarComponent } from 'src/components/form-redesigned/calendar'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

type Birthday = {
  year: string
  month: string
  day: string
}

type Props = {
  value: string
  onChange: (value: string) => void
  onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined
}

// TODO: 汎用日時入力用のコンポーネントで吸収したい
export const PatientBirthdayForm: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [year, month, day] = value.split('-') ?? []
  const birthday: Birthday = {
    year: year ?? '',
    month: month ?? '',
    day: day ?? '',
  }
  const onChangeBirthday = (value: Birthday) => {
    // 全ての項目が空の場合は空文字で更新する
    if (!value.year && !value.month && !value.day) {
      onChange('')
      return
    }
    onChange(`${value.year}-${value.month}-${value.day}`)
  }
  const onChangeYear = (value: string) => {
    onChangeBirthday({ ...birthday, year: value })
  }
  const onChangeMonth = (value: string) => {
    onChangeBirthday({ ...birthday, month: value })
  }
  const onChangeDay = (value: string) => {
    onChangeBirthday({ ...birthday, day: value })
  }
  return (
    <Stack display="inline-block" pos="relative" spacing="2" onBlur={onBlur}>
      <Flex align="center" w="full">
        <Flex align="center" mr="4">
          <Input
            w="72px"
            mr="1"
            placeholder="1900"
            value={birthday.year}
            onChange={e => onChangeYear(e.target.value)}
          />
          <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
            年
          </Text>
          <Input
            w="52px"
            mr="1"
            placeholder="00"
            value={birthday.month}
            onChange={e => onChangeMonth(e.target.value)}
          />
          <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
            月
          </Text>
          <Input
            w="52px"
            mr="1"
            placeholder="00"
            value={birthday.day}
            onChange={e => onChangeDay(e.target.value)}
          />
          <Text as="span" fontSize="sm" fontWeight="bold">
            日
          </Text>
        </Flex>
        <IconButton
          variant="customIconButtonGhost"
          colorScheme="blue"
          aria-label="calendar icon"
          icon={<Calendar color={colors.blue[500]} />}
          onClick={onOpen}
          pos="relative"
        />
      </Flex>
      {isOpen && (
        <Box pos="absolute" right="0" zIndex="1">
          <CalendarComponent
            onChange={date => {
              onChangeBirthday({
                year: date.getFullYear().toString(),
                month: (date.getMonth() + 1).toString().padStart(2, '0'),
                day: date.getDate().toString().padStart(2, '0'),
              })
            }}
            onClose={onClose}
          />
        </Box>
      )}
    </Stack>
  )
}
