import { QuestionnaireFieldAnswer } from '../questionnaire-field-answer/entity'

export enum QuestionnaireSectionAnswerStatus {
  Invalid = 0,
  Unanswered,
  Answered,
}

export type QuestionnaireSectionAnswer = {
  uid: string
  questionnaireAnswerUid: string
  questionnaireSectionUid: string
  title: string
  status: QuestionnaireSectionAnswerStatus
  index: number
  answeredAt: string
  nextQuestionnaireSectionAnswerUid: string
  previousQuestionnaireSectionAnswerUid: string

  fieldAnswers: QuestionnaireFieldAnswer[]
}
