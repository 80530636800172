import React, { useCallback } from 'react'

import styled from 'styled-components'
import Flex from 'styled-flex-component'
import { width, WidthProps } from 'styled-system'

import { Input } from './input'

type Props = {
  timeValue: string
  onChange: (value: string) => void
  disabled?: boolean
  showWarning?: boolean
}

const isEmptyTime = ({ hour, minute }: { hour: string; minute: string }) =>
  hour === '' && minute === ''

export const TimeInput: React.FC<Props> = props => {
  const { timeValue, disabled, showWarning } = props

  //アプリケーション内の日付入力は「○○:○○」の形式で統一されていおり、未入力の場合は空文字となる
  const hour = timeValue.split(':')[0] ?? ''
  const minute = timeValue.split(':')[1] ?? ''

  const onChangeHour = useCallback(
    (value: string) => {
      if (!value.match(new RegExp('^[0-9]*$'))) return

      props.onChange(
        isEmptyTime({ hour: value, minute }) ? '' : `${value}:${minute}`,
      )
    },
    [minute, props],
  )

  const onChangeMinute = useCallback(
    (value: string) => {
      if (!value.match(new RegExp('^[0-9]*$'))) return

      props.onChange(
        isEmptyTime({ hour, minute: value }) ? '' : `${hour}:${value}`,
      )
    },
    [hour, props],
  )

  return (
    <Flex alignEnd>
      <InputContainer width={48}>
        <Input
          type="text"
          placeholder="00"
          value={hour}
          onChange={e => onChangeHour(e.target.value)}
          showWarning={showWarning}
          disabled={disabled}
          warning={!hour}
        />
      </InputContainer>

      <Unit>時</Unit>

      <InputContainer width={48}>
        <Input
          type="text"
          placeholder="00"
          value={minute}
          onChange={e => onChangeMinute(e.target.value)}
          showWarning={showWarning}
          disabled={disabled}
          warning={!minute}
        />
      </InputContainer>

      <Unit>分</Unit>
    </Flex>
  )
}

const Unit = styled.span`
  font-size: 14px;
  margin: 0 16px 0 10px;
`

const InputContainer = styled.div<WidthProps>`
  ${width}
`
