import {
  QuestionnaireSectionAnswer,
  QuestionnaireSectionAnswerStatus,
} from './entity'
import { fixtures as QuestionnaireFieldFixtures } from '../questionnaire-field-answer/fixture'

export const fixtures: QuestionnaireSectionAnswer[] = [
  {
    uid: 'qsa-uid-1',
    questionnaireAnswerUid: 'qa-uid-1',
    questionnaireSectionUid: 'qs-uid-1',
    title: '質問1',
    status: QuestionnaireSectionAnswerStatus.Answered,
    index: 0,
    answeredAt: '',
    nextQuestionnaireSectionAnswerUid: 'qsa-uid-2',
    previousQuestionnaireSectionAnswerUid: '',

    fieldAnswers: QuestionnaireFieldFixtures,
  },
  {
    uid: 'qsa-uid-2',
    questionnaireAnswerUid: 'qa-uid-1',
    questionnaireSectionUid: 'qs-uid-2',
    title: '質問2',
    status: QuestionnaireSectionAnswerStatus.Unanswered,
    index: 1,
    answeredAt: '',
    nextQuestionnaireSectionAnswerUid: 'qsa-uid-3',
    previousQuestionnaireSectionAnswerUid: 'qsa-uid-1',

    fieldAnswers: QuestionnaireFieldFixtures,
  },
  {
    uid: 'qsa-uid-3',
    questionnaireAnswerUid: 'qa-uid-1',
    questionnaireSectionUid: 'qs-uid-3',
    title: '質問3',
    status: QuestionnaireSectionAnswerStatus.Invalid,
    index: 2,
    answeredAt: '',
    nextQuestionnaireSectionAnswerUid: '',
    previousQuestionnaireSectionAnswerUid: 'qsa-uid-2',

    fieldAnswers: QuestionnaireFieldFixtures,
  },
]
