import { generatePath, useParams, useLocation } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  useSelectedTrial,
} from 'src/features/auth/context'

import { useCreateSignAuthentication } from '../../api/createSignAuthentication'
import {
  getSignerDisplayName,
  useDocRevisionAndSessionForSign,
} from '../../api/getDocRevisionAndSessionForSign'
import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { useThisSession } from '../../hooks/useThisSession'
import { DocuSignIframeContent } from '../DocuSignIframeContent/DocuSignIframeContent'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { SignAuthChecker } from '../SignAuthChecker/SignAuthChecker'

type Param = {
  sessionUid: string
  docRevisionUid: string
  signerActorUid: string
}

export const PartnerDocumentSignScreen: React.FC = () => {
  const {
    sessionUid = '',
    docRevisionUid = '',
    signerActorUid = '',
  } = useParams<Param>()

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const { selectedTrial } = useSelectedTrial()

  const {
    account: { member, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { data } = useDocRevisionAndSessionForSign({
    explanationDocRevisionUid: docRevisionUid,
    explanationSessionUid: sessionUid,
  })
  const { data: signAuth, isValidating } = useCreateSignAuthentication({
    explanationSessionUid: sessionUid,
    explanationDocRevisionUid: docRevisionUid,
    signerActorUid,
    revalidateOnFocus: false,
  })

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (
    !data ||
    !selectedTrialHospitalUid ||
    !thisSession.fetched ||
    !signAuth ||
    isValidating
  ) {
    return null
  }

  const roomTopPath = generatePath(Paths.PartnerExplanationRoomTop, {
    trialUid: selectedTrial.uid,
    sessionUid,
  })

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={member.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      sessionUid={sessionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Partner"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout
        headerTitle={data.explanationDocRevision.icfDocumentRevision.name}
        sessionUid={sessionUid}
        showSidebar={false}
        isPartner
      >
        <SignAuthChecker
          isSP={false}
          trialUid={selectedTrial.uid}
          explanationSignAuthUid={signAuth.signAuthenticationUid}
          roomTopPath={roomTopPath}
        >
          <DocuSignIframeContent
            roomTopPath={roomTopPath}
            sessionUid={sessionUid}
            docRevisionUid={docRevisionUid}
            signerDisplayName={getSignerDisplayName(data, signerActorUid)}
            signerActorUid={signerActorUid}
            completePath={
              generatePath(Paths.PartnerExplanationRoomSignCompleted, {
                trialUid: selectedTrial.uid,
                sessionUid,
                signerActorUid,
              }) +
              `?${RoomEventUidQueryKey}=${searchParams.get(RoomEventUidQueryKey)}`
            }
            roomEventUid={searchParams.get(RoomEventUidQueryKey) ?? ''}
          />
        </SignAuthChecker>
        {renderFloatingVideoCall({ right: 24, bottom: 96 })}
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}
