import React, { useMemo } from 'react'

import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { CheckFill, Rejection } from 'src/components/__legacy__icon/monochrome'
import { Message } from 'src/components/base/message/message'
import { Spacer } from 'src/components/spacer/spacer'
import {
  ExplanationType,
  ExplanationSignerRole,
} from 'src/features/explanation/types'
import { explanationSignerRoleToText } from 'src/features/explanation/utils/explanationSignerRoleToText'
import { IcfDocumentType } from 'src/features/icfDocument/types'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { ExplanationRoomUserAttributeType } from '../user-attribute-type'

type Props = {
  userAttributeType: ExplanationRoomUserAttributeType
  order: number
  docType: IcfDocumentType
  docName: string
  docVersion: string
  explanationType?: ExplanationType
  latestPatientBrowsedAt?: string
  isCompleted: boolean
  isRejected: boolean
  isSkipped: boolean
  hasDSEnvelope: boolean
  allPlacedSignerRoles: ExplanationSignerRole[]
  completedSignerRoles: ExplanationSignerRole[]
  documentPagePath: string
}

export const DocumentListCardRenewal: React.FC<Props> = ({
  userAttributeType,
  order,
  docType,
  docName,
  docVersion,
  explanationType,
  latestPatientBrowsedAt,
  isCompleted,
  isRejected,
  isSkipped,
  hasDSEnvelope,
  allPlacedSignerRoles,
  completedSignerRoles,
  documentPagePath,
}) => {
  const disabled = isSkipped || isRejected
  return (
    <LinkBox
      as={Flex}
      align="center"
      justify="space-between"
      border="2px solid"
      borderColor={disabled ? 'gray.500' : 'green.500'}
      borderRadius="lg"
      bg="white"
      w="full"
      p="4"
      pointerEvents={disabled ? 'none' : 'auto'}
      _hover={{ bg: disabled ? 'unset' : 'green.50' }}
      _active={{ bg: disabled ? 'unset' : 'green.100' }}
    >
      <Flex align="center" flex={1}>
        {isCompleted ? (
          <CheckFill size="XL" color={colors.green[500]} />
        ) : isRejected ? (
          <Rejection size="XL" color={colors.gray[500]} />
        ) : (
          <Box
            as="span"
            h="40px"
            w="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="white"
            borderRadius="50%"
            border="1px solid"
            fontWeight="bold"
            borderColor={disabled ? 'gray.500' : 'green.500'}
            color={disabled ? 'gray.500' : 'green.500'}
          >
            <span>{order}</span>
          </Box>
        )}
        <Spacer size={24} horizontal />
        <Text
          fontWeight="bold"
          fontSize="2xl"
          color={disabled ? 'gray.600' : 'green.600'}
        >
          <LinkOverlay
            as={Link}
            to={documentPagePath}
            tabIndex={disabled ? -1 : 0}
          >
            {docName}
          </LinkOverlay>
        </Text>
      </Flex>
      {userAttributeType === 'hospital' && (
        <ForHospitalInformation
          {...{
            docType,
            explanationType,
            latestPatientBrowsedAt,
            docVersion,
            isRejected,
            isCompleted,
            hasDSEnvelope,
            allPlacedSignerRoles,
            completedSignerRoles,
          }}
        />
      )}
      {userAttributeType === 'patient' && (
        <ForPatientInformation
          {...{
            latestPatientBrowsedAt,
          }}
        />
      )}
    </LinkBox>
  )
}

const ForHospitalInformation: React.FC<
  Pick<
    Props,
    | 'docType'
    | 'explanationType'
    | 'latestPatientBrowsedAt'
    | 'docVersion'
    | 'isRejected'
    | 'isCompleted'
    | 'hasDSEnvelope'
    | 'allPlacedSignerRoles'
    | 'completedSignerRoles'
  >
> = ({
  docType,
  explanationType,
  latestPatientBrowsedAt,
  docVersion,
  isRejected,
  isCompleted,
  hasDSEnvelope,
  allPlacedSignerRoles,
  completedSignerRoles,
}) => {
  // 同意済みロールの並び順を定義
  const roleOrder: ExplanationSignerRole[] = [
    'Patient',
    'Representative',
    'Dr',
    'CRC',
    'None',
  ]

  const warningMessage = useMemo(() => {
    if (!hasDSEnvelope) return undefined
    if (isRejected) return undefined

    if (docType === 'AgreementForm') {
      return allPlacedSignerRoles.length > completedSignerRoles.length
        ? '署名実施中'
        : undefined
    }

    return undefined
  }, [
    hasDSEnvelope,
    isRejected,
    docType,
    allPlacedSignerRoles.length,
    completedSignerRoles.length,
  ])

  const isRemoteType =
    explanationType === 'Remote' || explanationType === 'RemotePartner'

  const shouldShowAgreementStatus =
    (isRemoteType && (completedSignerRoles.length > 0 || isRejected)) ||
    (explanationType === 'InPerson' && (hasDSEnvelope || isRejected))

  return (
    <Flex align="center" flex={1} justify="end">
      {!!warningMessage && (
        <Box ml={6} mr={12}>
          <Message type="warning" message={warningMessage} noWrap />
        </Box>
      )}
      <Flex align="center" w="300px">
        <Box flex="1">
          {(docType === 'Description' || docType === 'Video') &&
            !!latestPatientBrowsedAt && (
              <>
                <Flex>
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    閲覧時刻
                  </Text>
                  <Spacer size={16} horizontal />
                  <Text fontSize="sm">
                    {dayjs(latestPatientBrowsedAt).format(
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                  </Text>
                </Flex>
                <Spacer size={4} />
                <Flex>
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    閲覧状況
                  </Text>
                  <Spacer size={16} horizontal />
                  <Flex align="center">
                    <Text fontSize="sm" wordBreak="keep-all">
                      患者
                    </Text>
                    <Spacer size={4} horizontal />
                    <CheckFill size="S" color={colors.green[500]} />
                  </Flex>
                </Flex>
              </>
            )}
          {docType === 'CheckUnderstanding' && (
            <>
              <VStack spacing="4px" align="start">
                {!!latestPatientBrowsedAt && (
                  <HStack spacing="16px">
                    <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                      閲覧時刻
                    </Text>
                    <Text fontSize="sm">
                      {dayjs(latestPatientBrowsedAt).format(
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </Text>
                  </HStack>
                )}
                {isRemoteType && isCompleted && (
                  <HStack spacing="16px">
                    <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                      回答状況
                    </Text>
                    <HStack align="center" spacing="4px">
                      {/* NOTE: 現状は患者のみ */}
                      <Text fontSize="sm" wordBreak="keep-all">
                        患者
                      </Text>
                      <CheckFill size="S" color={colors.green[500]} />
                    </HStack>
                  </HStack>
                )}
                {explanationType === 'InPerson' && hasDSEnvelope && (
                  <HStack spacing="16px">
                    <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                      回答状況
                    </Text>
                    <HStack align="center" spacing="4px">
                      {/* NOTE: 現状は患者のみ */}
                      <Text fontSize="sm" wordBreak="keep-all">
                        患者
                      </Text>
                      {isCompleted ? (
                        <CheckFill size="S" color={colors.green[500]} />
                      ) : (
                        <Spacer size={16} horizontal />
                      )}
                    </HStack>
                  </HStack>
                )}
              </VStack>
            </>
          )}

          {docType === 'AgreementForm' && (
            <VStack spacing="4px" align="start">
              {!!latestPatientBrowsedAt && (
                <HStack spacing="16px">
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    閲覧時刻
                  </Text>
                  <Text fontSize="sm">
                    {dayjs(latestPatientBrowsedAt).format(
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                  </Text>
                </HStack>
              )}
              {shouldShowAgreementStatus && (
                <HStack spacing="16px">
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    署名状況
                  </Text>
                  <Flex align="center">
                    {isRejected ? (
                      <Text fontSize="sm" color={colors.red[500]}>
                        同意拒否
                      </Text>
                    ) : (
                      allPlacedSignerRoles
                        .sort(
                          (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b),
                        )
                        .map(signerRole => (
                          <React.Fragment key={signerRole}>
                            <Text fontSize="sm" wordBreak="keep-all">
                              {explanationSignerRoleToText(signerRole)}
                            </Text>
                            <Spacer size={4} horizontal />
                            {completedSignerRoles.includes(signerRole) ? (
                              <CheckFill size="S" color={colors.green[500]} />
                            ) : (
                              <Spacer size={16} horizontal />
                            )}
                            <Spacer size={16} horizontal />
                          </React.Fragment>
                        ))
                    )}
                  </Flex>
                </HStack>
              )}
            </VStack>
          )}
        </Box>

        <Box whiteSpace="nowrap">
          <Text>{`${docVersion}版`}</Text>
        </Box>
      </Flex>
    </Flex>
  )
}

const ForPatientInformation: React.FC<
  Pick<Props, 'latestPatientBrowsedAt'>
> = ({ latestPatientBrowsedAt }) => {
  return (
    <Flex>
      {!!latestPatientBrowsedAt && (
        <Box flex="1">
          <Flex flexDirection="column">
            <Flex>
              <Text fontSize="sm" fontWeight="bold">
                閲覧時刻
              </Text>
              <Spacer size={16} horizontal />
              <Text fontSize="sm">
                {dayjs(latestPatientBrowsedAt).format('YYYY/MM/DD HH:mm:ss')}
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  )
}
