import { generateUUID } from 'src/utils/generateUUID'

import { QuestionnaireField } from '../questionnaire-field/entity'

export type QuestionnaireSection = {
  uid: string
  title: string
  index: number

  fieldMap: Record<string, QuestionnaireField>
}

export const createEmptyQuestionnaireSection = (
  index: number,
): QuestionnaireSection => ({
  uid: generateUUID(),
  title: '',
  index: index,
  fieldMap: {},
})
