import { Button, useDisclosure } from '@chakra-ui/react'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useCompleteAgreement, useExplanationSession } from '../../api'
import {
  ExplanationDocRevision,
  ExplanationRevision,
  ExplanationSession,
} from '../../types'
import { parseDocRevsToCompleteAgreement } from '../../utils/parseDocRevsToCompleteAgreement'
import { CompleteAgreementModal } from '../CompleteAgreementModal/CompleteAgreementModal'

type Props = {
  explanationRevisionUid: ExplanationRevision['uid']
  explanationSessionUid: ExplanationSession['uid']
  docRevisions: ExplanationDocRevision[]
}

export const CompleteAgreementForRoom: React.FC<Props> = ({
  explanationRevisionUid,
  explanationSessionUid,
  docRevisions,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useMirohaToast()

  const { mutate: mutateSession } = useExplanationSession({
    explanationSessionUid,
  })

  const { request: completeAgreement } = useCompleteAgreement({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '同意が完了しました',
      })
      mutateSession()
      onClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const {
    isOptionalAgreementSigning,
    allRequiredAgreementCompleted,
    uncompletedDocRevs,
  } = parseDocRevsToCompleteAgreement(docRevisions)

  return (
    <>
      <Button
        onClick={() => {
          if (isOptionalAgreementSigning) {
            toast({
              status: 'warning',
              title: '署名中の同意書があります',
              description:
                '同意完了するには署名を完了してから再度お試しください。',
            })
            return
          }
          onOpen()
        }}
        colorScheme="green"
        isDisabled={!allRequiredAgreementCompleted}
      >
        同意完了
      </Button>

      <CompleteAgreementModal
        isForRoom={true}
        isOpen={isOpen}
        uncompletedDocRevs={uncompletedDocRevs}
        onClose={onClose}
        onSubmit={async () => {
          await completeAgreement({
            explanationRevisionUid,
          })
        }}
      />
    </>
  )
}
