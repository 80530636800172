import React from 'react'

import { Close } from 'src/components/__legacy__icon/monochrome'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { QuestionnaireChoice } from 'src/modules/entities/questionnaire-choice/entity'
import styled from 'styled-components'

import { ErrorMessage } from './error-message'
import { MAX_INPUT_LENGTH } from '../../detail'
import { useEditQuestionnaireChoice } from '../../use-edit-questionnaire-choice'
import { useValidationError } from '../../use-validation-error'

type Props = {
  choice: QuestionnaireChoice
  sectionIndex: number
  sectionUid: string
  fieldUid: string
  index: number
}

export const EditableChoice: React.FC<Props> = props => {
  const { choice, sectionIndex, sectionUid, fieldUid, index } = props
  const { description, value, uid } = choice
  const { onDeleteChoice, onUpdateChoice } = useEditQuestionnaireChoice({
    sectionIndex,
    fieldUid,
    index,
  })

  const { getChoiceError, resetValidationError } = useValidationError()
  const validationError = getChoiceError(sectionUid, fieldUid, choice.uid)

  return (
    <Wrapper>
      <Container>
        <Input
          width={'280px'}
          value={description}
          placeholder="選択肢"
          onChange={e => {
            onUpdateChoice({
              values: { description: e.target.value },
            })
            resetValidationError({
              target: 'description',
              sectionUid,
              fieldUid,
              choiceUid: uid,
            })
          }}
          className={validationError?.description ? 'error' : ''}
          maxLength={MAX_INPUT_LENGTH}
        />
        <Spacer size={16} horizontal />
        <Input
          width={'120px'}
          value={value}
          placeholder="値"
          onChange={e => {
            onUpdateChoice({ values: { value: e.target.value } })
            resetValidationError({
              target: 'value',
              sectionUid,
              fieldUid,
              choiceUid: uid,
            })
          }}
          className={validationError?.value ? 'error' : ''}
          maxLength={MAX_INPUT_LENGTH}
        />
        <Spacer size={16} horizontal />
        <Close cursor="pointer" onClick={onDeleteChoice} />
      </Container>
      {validationError?.description && (
        <ErrorMessage>{validationError.description}</ErrorMessage>
      )}
      {validationError?.value && (
        <ErrorMessage>{validationError.value}</ErrorMessage>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
