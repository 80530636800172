import { HttpResponse } from 'msw'
import {
  createTrialMemberMock,
  GetActiveMembersByTrialHospitalUidsDocument,
  GetActiveMembersByTrialHospitalUidsQuery,
  GetActiveMembersByTrialHospitalUidsQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getActiveMembersByTrialHospitalUidsHandler = graphQLHandlerFactory<
  GetActiveMembersByTrialHospitalUidsQuery,
  GetActiveMembersByTrialHospitalUidsQueryVariables
>('query', GetActiveMembersByTrialHospitalUidsDocument, () => {
  return HttpResponse.json({
    data: {
      activeTrialMembersByTrialHospitalUids: [
        createTrialMemberMock(),
        createTrialMemberMock(),
      ],
    },
  })
})
