import { GetSessionBasicInfoQuery } from 'src/lib/gql-client'
import { isNotNullish } from 'src/utils/isNotNullish'

import { useSessionBasicInfo } from '../api'

type GetSessionBasicInfoQueryExpSession =
  GetSessionBasicInfoQuery['explanationSession']

type UseThisSessionForHospitalReturn =
  | { fetched: false }
  | {
      fetched: true
      isFinished: boolean
      explanationType: GetSessionBasicInfoQueryExpSession['explanationType']
      explanationRevisionUid: string
      trialHospital: GetSessionBasicInfoQueryExpSession['explanationRevision']['explanation']['trialHospital']
      partnerTrialHospital: GetSessionBasicInfoQueryExpSession['explanationRevision']['partnerTrialHospital']
      patient: GetSessionBasicInfoQueryExpSession['explanationRevision']['explanation']['patient']
      videoCallRoomUid: string | undefined
      hasConsentRequiredAgreementForm: boolean
      isSignerMemberSet: boolean
      featureChannel: GetSessionBasicInfoQuery['trial']['featureChannel']
      mutate: () => void
    }

/** 参加中のセッションに関する基本的な情報を取得できる（医療機関・患者兼用） */
export const useThisSession = ({
  sessionUid,
  forPatient,
}: {
  sessionUid: string
  forPatient: boolean
}): UseThisSessionForHospitalReturn => {
  const { data, mutate: mutateSession } = useSessionBasicInfo({
    explanationSessionUid: sessionUid,
    forPatient,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  if (!data) {
    return { fetched: false }
  }

  const session = data.explanationSession

  const explanationRevisionUid = session.explanationRevision.uid

  const explanationType = session.explanationType

  const trialHospital = session.explanationRevision.explanation.trialHospital
  const partnerTrialHospital = session.explanationRevision.partnerTrialHospital

  const patient = session.explanationRevision.explanation.patient

  const videoCallRoomUid: string | undefined =
    session.__typename === 'ExplanationRemoteSession' ||
    session.__typename === 'ExplanationRemotePartnerSession'
      ? session.videoCallRoomUid
      : undefined

  const docRevs = session.explanationRevision.docSets
    .flatMap(docSet => docSet.documentRevisions)
    .filter(isNotNullish)

  const hasConsentRequiredAgreementForm = docRevs.some(docRev => {
    if (docRev.__typename !== 'ExplanationDocRevisionAgreementForm') {
      return false
    }
    return docRev.isPatientConsentRequired
  })

  const isSignerMemberSet = docRevs.some(docRev => {
    if (docRev.__typename !== 'ExplanationDocRevisionAgreementForm') {
      return false
    }
    return docRev.signerMembers.length > 0
  })

  const isFinished = session.isFinished

  const featureChannel = data.trial.featureChannel

  const mutate = () => {
    mutateSession()
  }

  return {
    fetched: true,
    isFinished,
    explanationRevisionUid,
    explanationType,
    trialHospital,
    partnerTrialHospital,
    patient,
    videoCallRoomUid,
    hasConsentRequiredAgreementForm,
    isSignerMemberSet,
    featureChannel,
    mutate,
  }
}
