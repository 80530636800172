import React, { useState } from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { MessageWarning } from 'src/components/icon'
import { PartnerBadge } from 'src/components/PartnerBadge/PartnerBadge'
import { Select, SelectItem } from 'src/components/Select/Select'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { Member } from 'src/features/member/types'
import { useSpinner } from 'src/hooks/use-spinner'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { getFullName } from 'src/utils/getFullName'

import {
  useCreateEnvelope,
  useExplanation,
  useSetExpDocRevisionSignerMember,
} from '../../api'
import {
  ExplanationDocRevisionAgreementForm,
  ExplanationPatient,
  ExplanationSession,
  ExplanationSessionMember,
  ExplanationSignerRole,
} from '../../types'
import { isSignedBy } from '../../utils/isSignedBy'

type Props = {
  session: ExplanationSession
  docRevision: ExplanationDocRevisionAgreementForm
}

type MemberState = {
  dr: ExplanationSessionMember
  crc?: ExplanationSessionMember
}

export const StartRemotePartnerSign: React.FC<Props> = ({
  session,
  docRevision,
}) => {
  const { showSpinner, hideSpinner } = useSpinner()

  const [modalToOpen, setModalToOpen] = useState<
    'SelectMembers' | 'SubmitMembers' | 'None'
  >('None')

  const [selectedMembers, setSelectedMembers] = useState<MemberState>()

  const {
    account: { selectedTrialHospitalUid, selectedTrial },
  } = useAuthenticatedAccount()

  const { data: explanation } = useExplanation({
    explanationUid: session.explanationRevision.explanationUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const { request: createEnvelope } = useCreateEnvelope({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      navigateToDocuSignPage()
    },
  })

  const toast = useMirohaToast()

  const { request: setExpDocRevisionSignerMember } =
    useSetExpDocRevisionSignerMember({
      onSuccess: async () => handleCreateEnvelope(),
      onError: error => {
        toast({
          status: 'error',
          title: error.message,
        })
      },
    })

  const navigate = useNavigate()

  const navigateToDocuSignPage = () => {
    const searchParam = new URLSearchParams()
    const role: ExplanationSignerRole = 'Patient'
    searchParam.append('role', role)
    navigate(
      [
        generatePath(Paths.ExplanationRoomDocuSign, {
          trialUid: selectedTrial.uid,
          sessionUid: session.uid,
          explanationDocRevisionUid: docRevision.uid,
        }),
        searchParam.toString(),
      ].join('?'),
    )
  }

  const handleCreateEnvelope = async () => {
    // envelope作成済みの場合はDocuSignページへ
    if (!!docRevision.dsEnvelopeId) {
      navigateToDocuSignPage()
      return
    }
    await createEnvelope({
      type: 'AgreementForm',
      explanationDocRevisionUid: docRevision.uid,
      explanationSessionUid: session.uid,
    })
  }

  if (session.explanationRevision.type !== 'RemotePartner') {
    return null
  }
  // 説明タイプ=パートナー施設 で説明を開始できるのはパートナー施設側のメンバーのみ
  if (
    session.explanationRevision.partnerTrialHospital?.uid !==
    selectedTrialHospitalUid
  ) {
    return null
  }

  if (isSignedBy(docRevision, 'Patient')) {
    return null
  }

  return (
    <>
      <Button
        colorScheme="green"
        onClick={async () => {
          // メンバー選択済みの場合はEnvelopeを作成処理へ。
          if (docRevision.signerMembers.length > 0) {
            await handleCreateEnvelope()
            return
          }
          setModalToOpen('SelectMembers')
        }}
      >
        署名を開始
      </Button>

      <SelectMemberModal
        isOpen={modalToOpen === 'SelectMembers'}
        onCloseModal={() => setModalToOpen('None')}
        members={session.members}
        onSubmit={members => {
          setSelectedMembers(members)
          setModalToOpen('SubmitMembers')
        }}
      />

      {selectedMembers && !!explanation && (
        <SubmitMembersModal
          isOpen={modalToOpen === 'SubmitMembers'}
          onCancel={() => {
            setModalToOpen('SelectMembers')
          }}
          members={selectedMembers}
          patient={explanation.patient}
          onSubmit={async ({ dr, crc }) => {
            await setExpDocRevisionSignerMember({
              explanationDocRevisionUid: docRevision.uid,
              drSignerMemberUid: dr.trialMember.uid,
              crcSignerMemberUid: crc?.trialMember.uid,
            })
          }}
          hasCrcField={docRevision.icfDocumentRevisionHasCrcField ?? false}
        />
      )}
    </>
  )
}

const SelectMemberModal: React.FC<{
  isOpen: boolean
  onCloseModal: () => void
  members: ExplanationSessionMember[]
  onSubmit: (members: MemberState) => void
}> = ({ isOpen, onCloseModal, members, onSubmit }) => {
  const drMembers = members.filter(m => m.trialMember.role === 'Dr')
  const crcMembers = members.filter(m => m.trialMember.role === 'CRC')

  const initialState: Partial<MemberState> = {
    dr: drMembers.length === 1 ? drMembers[0] : undefined, // Drが1人の場合はデフォルトで選択
    crc: undefined,
  }

  const [selectedMembers, setSelectedMembers] =
    useState<Partial<MemberState>>(initialState)

  const toSelectItem = (
    member: ExplanationSessionMember,
  ): SelectItem<Member['uid'], React.ReactNode> => ({
    label: (
      <HStack spacing="1">
        <Text>{member.trialMember.role}</Text>
        <Text>{getFullName(member.trialMember)}</Text>
        {member.isPartner && <PartnerBadge />}
      </HStack>
    ),
    value: member.trialMember.uid,
  })
  const onClose = () => {
    onCloseModal()
    setSelectedMembers(initialState)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>これより同意書への署名を開始します</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Text>署名を開始する前に署名担当者を選択してください。</Text>
            <Stack spacing="6">
              <Stack spacing="1">
                <Text fontSize="sm" color="gray.600" fontWeight="bold">
                  Dr
                </Text>
                <Select
                  width="100%"
                  isClearable
                  value={selectedMembers?.dr?.trialMember.uid}
                  items={drMembers.map(toSelectItem)}
                  onChange={uid => {
                    setSelectedMembers(current => ({
                      ...current,
                      dr: drMembers.find(m => m.trialMember.uid === uid),
                    }))
                  }}
                />
              </Stack>
              <Stack spacing="1">
                <Text fontSize="sm" color="gray.600" fontWeight="bold">
                  CRC
                </Text>
                <Select
                  width="100%"
                  isClearable
                  items={crcMembers.map(toSelectItem)}
                  value={selectedMembers?.crc?.trialMember.uid}
                  onChange={uid => {
                    setSelectedMembers(current => ({
                      ...current,
                      crc: crcMembers.find(m => m.trialMember.uid === uid),
                    }))
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            colorScheme="green"
            onClick={() => {
              if (!selectedMembers.dr) return
              onSubmit({ dr: selectedMembers.dr, crc: selectedMembers.crc })
            }}
            isDisabled={!selectedMembers.dr}
          >
            設定する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const SubmitMembersModal: React.FC<{
  isOpen: boolean
  onCancel: () => void
  patient: ExplanationPatient
  members: MemberState
  onSubmit: (members: MemberState) => void
  hasCrcField: boolean
}> = ({ isOpen, onCancel, patient, members, onSubmit, hasCrcField }) => {
  const cellStyle = (isTitle?: boolean): TableCellProps => ({
    fontWeight: isTitle ? 'bold' : 'normal',
    w: isTitle ? undefined : 'full',
    p: isTitle ? 0 : '4',
    borderBottom: 'none',
  })
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      motionPreset="scale"
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>署名を開始します</ModalHeader>
        <ModalBody>
          <Stack>
            <Stack>
              <Text fontSize="xs">署名を患者より開始します。</Text>
              <Text fontSize="xs">
                「患者の署名を開始する」ボタンを押下した後、患者に端末をお渡しください
              </Text>
            </Stack>
            <TableContainer>
              <Table variant="unstyled">
                <Tbody>
                  <Tr>
                    <Td {...cellStyle(true)}>患者</Td>
                    <Td
                      {...cellStyle(false)}
                    >{`候補ID: ${patient.candidateId}`}</Td>
                  </Tr>
                  <Tr>
                    <Td {...cellStyle(true)}>Dr</Td>
                    <Td {...cellStyle(false)}>
                      <HStack>
                        <Text>{getFullName(members.dr.trialMember)}</Text>
                        {members.dr.isPartner && <PartnerBadge />}
                      </HStack>
                    </Td>
                  </Tr>
                  {!!members.crc && (
                    <Tr>
                      <Td {...cellStyle(true)}>CRC</Td>
                      <Td {...cellStyle(false)}>
                        <HStack>
                          <Text>{getFullName(members.crc.trialMember)}</Text>
                          {members.crc.isPartner && <PartnerBadge />}
                        </HStack>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Stack>
              {hasCrcField && !members.crc && (
                <WarningMessage
                  message={`署名担当にCRCが設定されていません。\nこのまま署名を開始してもよろしいですか？`}
                />
              )}
              <HStack color="red.500">
                <MessageWarning />
                <Text fontSize="sm">
                  ボタン押下後は担当者を変更できません。
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button colorScheme="green" onClick={() => onSubmit(members)}>
            患者の署名を開始する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
