import React from 'react'

import { Badge } from '@chakra-ui/react'
import { Warning } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { gray, green, yellow } from 'src/components/base/color/palette'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { IcfDocumentSetForList } from '../entity'

type Props = {
  icfDocumentSetList: IcfDocumentSetForList[] | undefined
  canAddDocumentSet: boolean
  navigateToAdd: () => void
  navigateToDetail: (icfDocumentSetUid: string) => void
}

type TableKey = keyof Pick<
  IcfDocumentSetForList,
  | 'uid'
  | 'numberingId'
  | 'name'
  | 'enabled'
  | 'hasDocuments'
  | 'hasRequiredAgreementForm'
>

const tableCellWidth: Record<TableKey, string | undefined> = {
  enabled: '120px',
  uid: undefined,
  numberingId: '160px',
  name: '240px',
  hasRequiredAgreementForm: '160px',
  hasDocuments: '120px',
}

export const IcfDocumentSetList: React.FC<Props> = ({
  icfDocumentSetList,
  canAddDocumentSet,
  navigateToAdd,
  navigateToDetail,
}) => {
  return (
    <Wrapper>
      <Flex alignEnd column>
        {canAddDocumentSet && (
          <Button
            iconName="add"
            text="新規文書セット作成"
            buttonType="normal"
            size="S"
            onClick={navigateToAdd}
          />
        )}
      </Flex>
      <Spacer size={8} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell width={tableCellWidth['enabled']}>
                ステータス
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['numberingId']}>
                文書セットID
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['name']}>
                文書セット名
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['hasRequiredAgreementForm']}>
                同意必須文書
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['hasDocuments']}>
                文書設定
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!icfDocumentSetList
              ? null
              : icfDocumentSetList.map(set => (
                  <TableRow
                    key={set.uid}
                    onClick={() => navigateToDetail(set.uid)}
                    disabled={!set.enabled}
                    hover
                    borderBottom
                  >
                    <TableBodyCell width={tableCellWidth['enabled']}>
                      <Badge
                        sx={{
                          width: '80px',
                          height: '24px',
                          display: 'flex',
                          justifyContent: 'center',
                          color: set.enabled ? green[40] : gray[55],
                          backgroundColor: set.enabled ? green[10] : gray[5],
                          fontWeight: 'normal',
                        }}
                      >
                        {set.enabled ? '有効' : '無効'}
                      </Badge>
                    </TableBodyCell>
                    <TableBodyCell width={tableCellWidth['numberingId']}>
                      {set.numberingId}
                    </TableBodyCell>
                    <TableBodyCell width={tableCellWidth['name']}>
                      {set.name}
                    </TableBodyCell>
                    <TableBodyCell
                      width={tableCellWidth['hasRequiredAgreementForm']}
                    >
                      {set.hasRequiredAgreementForm ? 'あり' : 'なし'}
                    </TableBodyCell>
                    <TableBodyCell width={tableCellWidth['hasDocuments']}>
                      {set.hasDocuments ? (
                        <span>設定済</span>
                      ) : (
                        <>
                          <span>未設定</span>
                          <Spacer size={8} horizontal />
                          <Warning size="S" color={yellow[70]} />
                        </>
                      )}
                    </TableBodyCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  flex: 1;
`
