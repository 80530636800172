export const readAsDataURL = (file: File) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return new Promise<string>((resolve, reject) => {
    reader.addEventListener('load', () => {
      const result = reader.result
      if (typeof result !== 'string') {
        reject('failed to load file')
        return
      }
      // readAsDataURLでは先頭にdata:{content-type};base64,という文字を含むため、これを削除して返却する
      const base64Text = result.replace(/^data:/, '').replace(/^.+,/, '')
      resolve(base64Text)
    })
    reader.addEventListener('error', () => {
      reject('failed to load file')
    })
  })
}
