import React from 'react'

import { Button, useDisclosure } from '@chakra-ui/react'
import { ExplanationType } from 'src/lib/gql-client'

import { ExplanationRoomPinSettingModals } from '../ExplanationRoomPinSettingModals/ExplanationRoomPinSettingModals'

type Props = {
  isReset?: boolean
  phoneNumber: string | undefined
  explanationRevisionUid: string
  activePartnerDeviceActorUids: string[]
  patientUid: string
  explanationType: ExplanationType
  partnerTrialHospitalUid: string | undefined
  mutateSession: () => void
}

export const RequestPinSettingButton: React.FC<Props> = ({
  isReset = false,
  phoneNumber,
  explanationRevisionUid,
  patientUid,
  explanationType,
  activePartnerDeviceActorUids,
  partnerTrialHospitalUid,
  mutateSession,
}) => {
  const {
    isOpen: isModalsOpen,
    onOpen: onOpenModals,
    onClose: onCloseModals,
  } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpenModals}
        colorScheme={isReset ? 'blue' : 'green'}
        variant={isReset ? 'text' : 'solid'}
      >
        {isReset ? '暗証番号再設定' : '当人認証設定'}
      </Button>

      {isModalsOpen && (
        <ExplanationRoomPinSettingModals
          isReset={isReset}
          phoneNumber={phoneNumber}
          explanationRevisionUid={explanationRevisionUid}
          activePartnerDeviceActorUids={activePartnerDeviceActorUids}
          patientUid={patientUid}
          explanationType={explanationType}
          partnerTrialHospitalUid={partnerTrialHospitalUid}
          onCloseModals={onCloseModals}
          mutateSession={mutateSession}
        />
      )}
    </>
  )
}
