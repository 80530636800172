// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgSetting = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke={props.color ?? 'currentColor'}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M29.12 18.72a.958.958 0 0 0 .88-.951v-2.243a.944.944 0 0 0-.88-.951l-3-.233a.51.51 0 0 1-.413-.323l-.987-2.387a.5.5 0 0 1 .09-.52l1.957-2.279a.944.944 0 0 0-.054-1.291l-1.598-1.597a.947.947 0 0 0-1.293-.054l-2.3 1.974a.502.502 0 0 1-.52.09l-2.37-.988a.465.465 0 0 1-.306-.43l-.252-2.996a.94.94 0 0 0-.934-.88h-2.263a.945.945 0 0 0-.951.88l-.234 2.996a.581.581 0 0 1-.305.43l-2.389.97a.463.463 0 0 1-.52-.072L8.195 5.909a.946.946 0 0 0-1.293.054L5.305 7.56A.944.944 0 0 0 5.25 8.85l1.957 2.279c.126.144.162.34.072.52l-.97 2.369a.535.535 0 0 1-.43.323l-3 .233a.944.944 0 0 0-.88.95v2.261c0 .503.377.915.88.951l3 .234c.197.018.359.143.43.305l.988 2.386a.5.5 0 0 1-.09.52l-1.957 2.279a.944.944 0 0 0 .054 1.292l1.598 1.597a.947.947 0 0 0 1.293.053l2.281-1.955a.502.502 0 0 1 .521-.09l2.389.987c.18.072.305.233.305.43l.234 2.997c.035.484.449.879.951.879h2.263a.945.945 0 0 0 .952-.88l.234-2.995a.524.524 0 0 1 .305-.431l2.389-.987a.502.502 0 0 1 .52.09l2.282 1.955a.947.947 0 0 0 1.293-.053l1.598-1.597a.944.944 0 0 0 .054-1.292l-1.957-2.279a.5.5 0 0 1-.09-.52l.988-2.386a.465.465 0 0 1 .43-.305l2.982-.252Zm-13.111 2.655a4.659 4.659 0 0 1-3.34-1.382 4.635 4.635 0 0 1-1.402-3.355 4.65 4.65 0 0 1 1.383-3.337 4.69 4.69 0 0 1 3.341-1.382c1.257 0 2.46.485 3.34 1.382a4.68 4.68 0 0 1 1.384 3.337 4.65 4.65 0 0 1-1.383 3.337 4.563 4.563 0 0 1-3.323 1.4Z"
    />
  </svg>
)
export default SvgSetting
