import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const sendTestSMSHandler = restHandlerFactory(
  '/trials/{trial_uid}/test_message',
  'post',
  () => {
    return HttpResponse.json(undefined, { status: 204 })
  },
)
