// APIのレスポンスで受け取る"@[*](uuid)"形式に一致する正規表現
export const mentionRegexOfApiResponse =
  /(@\[\*\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\))/g

// 表示名を含む"@[{任意の表示名}](uuid) "形式に一致する正規表現
const mentionRegexWithDisplayName =
  /(@\[.*?\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\))/g

// APIのレスポンスで受け取る"@[*](uuid)"形式の文字列を"@[displayName](uuid)"形式に変換する
export const parseMentionTextWithDisplayName = (
  message: string,
  getDisplayName: (id: string) => string,
) => {
  return message.replace(mentionRegexOfApiResponse, (match, mention) => {
    const id = mention.match(
      /\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/,
    )?.[1]
    if (!id) return match
    const displayName = getDisplayName(id)
    if (!displayName) return '' // 元メッセージのメンション先ユーザーが現在のメンション先候補に存在しない場合は空文字を返す
    return `@[${displayName}](${id})`
  })
}

export const getMentionTextCount = (message: string) => {
  return message.replace(mentionRegexWithDisplayName, '-').length
}
