import { useCallback, useEffect, useMemo } from 'react'

import { Box } from '@chakra-ui/react'
import { useCurrentMember } from 'src/features/auth/context'

import { useThisSession } from './useThisSession'
import { FloatingVideoPlayer } from '../components/FloatingVideoPlayer/FloatingVideoPlayer'
import { useRoomMediaContext } from '../context/MediaProvider'

export const useFloatingVideoCallForMember = ({
  sessionUid,
  isOldFlow = false,
}: {
  sessionUid: string
  /** @deprecated */
  isOldFlow?: boolean
}) => {
  const { currentMember } = useCurrentMember()
  const { initializeMemberVideoCall } = useRoomMediaContext()

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  const isInPerson = useMemo(() => {
    if (!thisSession.fetched) return false
    return thisSession.explanationType === 'InPerson'
  }, [thisSession])

  useEffect(() => {
    if (!thisSession.fetched) return
    if (isInPerson) return
    if (!thisSession.videoCallRoomUid) return

    initializeMemberVideoCall({
      roomUid: thisSession.videoCallRoomUid,
      member: currentMember,
    })
  }, [thisSession, isInPerson, initializeMemberVideoCall, currentMember])

  const renderFloatingVideoCall = useCallback(
    (position?: { right: number; bottom: number }) => {
      if (isInPerson) {
        return null
      }
      const right = position?.right ?? 24
      const bottom = position?.bottom ?? 24
      return (
        <Box
          position="absolute"
          right={`${right}px`}
          bottom={`${bottom}px`}
          zIndex={1}
        >
          <FloatingVideoPlayer isOldFlow={isOldFlow} />
        </Box>
      )
    },
    [isInPerson, isOldFlow],
  )

  return {
    renderFloatingVideoCall,
  }
}
