import { Box, SkeletonText, Stack, Text } from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { useTrial } from 'src/features/explanation/api/getTrial'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useSessionForPatientSidebar } from '../../api'
import { useUpdateRevisionStatus } from '../../context/ExplanationRoomEvent'

type Props = {
  trialUid: string
  explanationSessionUid: string
}

export const PatientSidebar: React.FC<Props> = ({
  trialUid,
  explanationSessionUid,
}) => {
  const { data: trial } = useTrial({
    trialUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const { data: session, mutate: mutateSession } = useSessionForPatientSidebar({
    explanationSessionUid,
    revalidateIfStale: false,
  })

  useUpdateRevisionStatus({
    listener: async () => {
      await mutateSession()
    },
  })

  const hospitalName =
    session?.explanationRevision.explanation.trialHospital.hospital.name

  if (!session)
    return (
      <Stack h="full" spacing="4">
        <SkeletonText skeletonHeight="4" noOfLines={8} spacing="4" />
      </Stack>
    )

  return (
    <Stack spacing="8">
      <Text fontSize="xl" fontWeight="bold" as="span">
        {trial?.name}
      </Text>
      <Stack spacing="1">
        <Text fontSize="xl" fontWeight="bold" as="span">
          説明開始時刻
        </Text>
        <Text as="time" dateTime={session.startedAt}>
          {formatDate(session.startedAt, 'YYYY/MM/DD (ddd) HH:mm:ss')}
        </Text>
      </Stack>

      <Stack spacing="1">
        <Text fontSize="xl" fontWeight="bold">
          候補ID
        </Text>
        <Stack>
          <Text fontSize="lg">
            {session.explanationRevision.explanation.patient.candidateId}
          </Text>
        </Stack>
      </Stack>

      <Box>
        <Text fontSize="xl" fontWeight="bold">
          説明担当者
        </Text>
        <Stack spacing="4" maxHeight="192px" overflowY="scroll" mt="2">
          {/* メンバーが指定されていない場合は医療機関名も表示しない */}
          {session.members !== undefined && session.members.length > 0 && (
            <Box>
              <Text fontWeight="bold" as="h3" color="gray.600">
                {hospitalName}
              </Text>
              <Stack mt="2" spacing="1" ml="6">
                {session.members.map(member => (
                  <MemberLabel
                    key={member.trialMember.uid}
                    role={member.trialMember.role}
                    displayName={getFullName(member.trialMember.user)}
                    isPartner={member.isPartner}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
        <Box height={8} />
      </Box>
    </Stack>
  )
}
