import { GetFileFieldValueDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR from 'swr'

import { worksheetCacheKey } from '../cacheKey'

const getFileFieldValue = async ({
  worksheetFieldValueUid,
}: {
  worksheetFieldValueUid: string
}) => {
  try {
    const res = await graphqlRequest(GetFileFieldValueDocument, {
      worksheetFieldValueUid,
    })
    return res.fileFieldValue
  } catch (error) {
    throw error
  }
}

export const useFileFieldValue = ({
  worksheetFieldValueUid,
}: {
  worksheetFieldValueUid: string | null
}) => {
  return useSWR(
    worksheetFieldValueUid === null
      ? null
      : worksheetCacheKey.getFileFieldValue(worksheetFieldValueUid),
    () => {
      if (!worksheetFieldValueUid) {
        throw new Error('worksheetFieldValueUid is required')
      }
      return getFileFieldValue({ worksheetFieldValueUid })
    },
  )
}
