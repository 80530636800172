import dayjs from 'dayjs'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { SmallItemLog, LogType } from './entity'
import { fixtures } from './fixture'

type SmallItemLogResponse = {
  uid: string
  user_name: string
  old_value: string
  new_value: string
  reason: string
  title: string
  log_type: LogType
  depth: number
  created_at: string
}

const parse = (resp: SmallItemLogResponse): SmallItemLog => {
  return {
    uid: resp.uid,
    userName: resp.user_name,
    oldValue: resp.old_value,
    newValue: resp.new_value,
    reason: resp.reason,
    title: resp.title,
    logType: resp.log_type !== undefined ? resp.log_type : LogType.Change,
    depth: resp.depth,
    createdAt: resp.created_at,
  }
}

const compare = (a: SmallItemLog, b: SmallItemLog) => {
  //複数階層を同時に更新した場合は1階層目→2階層目の順で上から表示する
  if (
    dayjs(a.createdAt).format('YYYY-MM-DD HH:mm:ss') ===
    dayjs(b.createdAt).format('YYYY-MM-DD HH:mm:ss')
  ) {
    return a.depth - b.depth
  }

  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

export const fetchList = async ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemAnswerUid,
  smallItemAnswerUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemAnswerUid: string
  smallItemAnswerUid: string
}): Promise<SmallItemLog[]> => {
  if (IS_MOCK_MODE) {
    return fixtures.sort(compare)
  }

  const resp = await requestGet<SmallItemLogResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/medium_item_answers/${mediumItemAnswerUid}/small_item_answers/${smallItemAnswerUid}/logs`,
    params: {},
  })

  return resp.data.map(parse).sort(compare)
}
