import React from 'react'

import css from '@styled-system/css'
import styled from 'styled-components'
import {
  background,
  BackgroundProps,
  borders,
  BordersProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps &
  FlexboxProps &
  BordersProps &
  BackgroundProps

const styledSystemProps = [
  typography,
  space,
  layout,
  color,
  position,
  flexbox,
  borders,
  background,
]

type AnchorProps = StyledSystemProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

export const Anchor: React.FC<AnchorProps> = styled('a')<StyledSystemProps>(
  css({}),
  styledSystemProps,
)
