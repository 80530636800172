import { generatePath, useLocation, useParams } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'

import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { useThisSession } from '../../hooks/useThisSession'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { SignCompletedContent } from '../SignCompletedContent/SignCompletedContent'

type Param = {
  sessionUid: string
  signerActorUid: string
}

export const HospitalSignCompletedScreen: React.FC = () => {
  const { sessionUid = '', signerActorUid = '' } = useParams<Param>()

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()
  const { selectedTrial } = useSelectedTrial()

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  if (!selectedTrialHospitalUid || !thisSession.fetched) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      sessionUid={sessionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout sessionUid={sessionUid} showSidebar={false}>
        <SignCompletedContent
          isPatient={signerActorUid === thisSession.patient.uid}
          sessionUid={sessionUid}
          requestSignRoomEventUid={searchParams.get(RoomEventUidQueryKey) ?? ''}
          roomTopPath={generatePath(Paths.HospitalExplanationRoomSigning, {
            trialUid: selectedTrial.uid,
            sessionUid,
          })}
          signPath={requestSign => {
            return (
              generatePath(Paths.HospitalExplanationRoomDocumentSign, {
                trialUid: selectedTrial.uid,
                sessionUid: requestSign.sessionUid,
                docRevisionUid: requestSign.docRevUid,
                signerActorUid: requestSign.signerActorUid,
              }) +
              `?${RoomEventUidQueryKey}=${requestSign.explanationRoomEventUid}`
            )
          }}
          shouldShowReturnDeviceAlert={signerActorUid !== currentMember.uid}
        />
        {renderFloatingVideoCall()}
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}
