import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'
import { createApiClient } from '../../../../lib/api-client'
import { useSelectedTrial } from '../../../auth/context'
import { Trial } from '../../../trial/types'

const sendTestSMS = async ({
  trialUid,
  phoneNumber,
}: {
  trialUid: Trial['uid']
  phoneNumber: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/test_message',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
      },
      body: {
        phone_number: phoneNumber,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return
}

export const useSendTestSMS = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()
  return useMutation(
    ({ phoneNumber }: { phoneNumber: string }) =>
      sendTestSMS({ phoneNumber, trialUid: selectedTrial.uid }),
    option,
  )
}
