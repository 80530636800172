import matter from 'gray-matter'
import { castMarkdownContent, MarkdownContent } from 'src/utils/markdown'
import useSWR from 'swr'

import { Release } from '../../types'

type ReleaseNote = {
  metadata: Release
  content: MarkdownContent | null
}

const getReleaseNote = async (path: string): Promise<ReleaseNote> => {
  try {
    const fullPath = [import.meta.env.VITE_WEB_DOMAIN, path].join('')
    const res = await fetch(fullPath)
    const text = await res.text()
    const { content, data } = matter(text)
    const metadata = data as Release
    if (content === '') {
      return {
        metadata,
        content: null,
      }
    }
    return {
      metadata,
      content: castMarkdownContent(content),
    }
  } catch (error) {
    console.error(error)
    throw new Error('fetch error')
  }
}

export const useReleaseNote = ({ version }: { version: string }) => {
  const path = `/release-note/${version}.md`
  return useSWR(path, getReleaseNote)
}
