import React, { useState, useEffect } from 'react'

import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { Pdf } from 'src/components/pdf/pdf'
import { Spinner } from 'src/components/spinner/spinner'
import { useInit } from 'src/modules/dashboard/use-init'
import { getCurrentUser } from 'src/modules/entities/account/selector'
import { useQuery, stringToBool, QueryParamKeys } from 'src/modules/router'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { fetch } from './request'
import { trialIdParamName } from '../../../trial-detail'
import { PatientPDFData } from '../../detail/output/pdf/entity'

export const PdfContainer = () => {
  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()

  const query = useQuery()

  const datetime = dayjs(Date.now()).format('YYYY年MM月DD日HH時mm分ss秒')

  const { requesting, patientPDFDataArr } = useFetch({
    trialUid,
  })

  const { initialized } = useInit()

  const account = useSelector(getCurrentUser)!

  if (requesting || !initialized) {
    return <Spinner />
  }

  return (
    <Container>
      {patientPDFDataArr.map((patientPDFData, i) =>
        patientPDFData.worksheetPDFDataArr.map((worksheetPDFData, index) => (
          <Pdf
            key={worksheetPDFData.uid}
            isHistory={stringToBool(query.get(QueryParamKeys.History))}
            isWorksheet={stringToBool(query.get(QueryParamKeys.Worksheet))}
            isCallHistory={stringToBool(query.get(QueryParamKeys.CallHistory))}
            worksheetPDFData={worksheetPDFData}
            datetime={datetime}
            currentRole={account.selectedTrial!.role}
            accountName={`${account.lastName} ${account.firstName}`}
            accountEmail={account.email}
          />
        )),
      )}
    </Container>
  )
}

const useFetch = ({ trialUid }: { trialUid: string }) => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()
  const [patientPDFDataArr, setPatientPDFDataArr] = useState<PatientPDFData[]>(
    [],
  )

  useEffect(() => {
    const request = async () => {
      try {
        requestStarted()
        const res = await fetch({ trialUid })
        setPatientPDFDataArr(res)

        requestDone()
      } catch (error) {
        requestFailed(error.message)
      }
    }

    request()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trialUid])

  return {
    requesting,
    errorMessage,
    patientPDFDataArr,
  }
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${colors.bgGray};
`
