import { useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  DateTypeDef,
  ErrSchemaDetail,
  NumberTypeDef,
  TextareaTypeDef,
  TextTypeDef,
} from '@micin-jp/chicken-schema'
import { useAtom } from 'jotai'
import { Add, Close } from 'src/components/icon'
import { fieldFamily } from 'src/lib/chicken-schema/atom'
import {
  hasValidationTypeDef,
  HasValidationTypeDef,
} from 'src/lib/chicken-schema/utils'
import { assertNever } from 'src/utils/assertNever'

type Props = {
  isDisabled: boolean
  fid: string
  fieldErrors: Record<string, ErrSchemaDetail[]>
}

export const FieldValidationForm: React.FC<Props> = ({
  isDisabled,
  fid,
  fieldErrors,
}) => {
  const atom = fieldFamily(fid)
  const [field, setField] = useAtom(atom)

  const typeDef = field.typeDef

  if (!hasValidationTypeDef(typeDef)) return null

  return (
    <Flex
      align="center"
      pl="4"
      pr="10"
      py="2"
      wrap="wrap"
      borderRadius="sm"
      bg="gray.50"
      pos="relative"
      minH="44px"
    >
      {!!typeDef.validation && (
        <Box
          pos="absolute"
          aria-label="入力チェックを削除"
          as="button"
          right={4}
          top={2}
          zIndex="1"
          color="blue.500"
          onClick={() => {
            setField({ typeDef: { ...typeDef, validation: undefined } })
          }}
        >
          <Close />
        </Box>
      )}

      {!typeDef.validation ? (
        <Button
          variant="text"
          leftIcon={<Add />}
          isDisabled={isDisabled}
          onClick={() => {
            // デフォルト（空）のバリデーションを追加する
            setField({
              typeDef: { ...typeDef, validation: { required: false } },
            })
          }}
        >
          入力チェック追加
        </Button>
      ) : (
        <ValidationComponent
          typeDef={typeDef}
          onChangeTypeDef={typeDef => {
            setField({ typeDef })
          }}
          fieldErrors={fieldErrors}
        />
      )}
    </Flex>
  )
}

const ValidationComponent: React.FC<{
  typeDef: HasValidationTypeDef
  onChangeTypeDef: (typeDef: HasValidationTypeDef) => void
  fieldErrors: Record<string, ErrSchemaDetail[]>
}> = ({ typeDef, onChangeTypeDef, fieldErrors }) => {
  switch (typeDef.type) {
    case 'Text':
    case 'Textarea':
      return (
        <TextValidation
          validation={typeDef.validation}
          onChange={validation => {
            onChangeTypeDef({ ...typeDef, validation })
          }}
          fieldErrors={fieldErrors}
        />
      )
    case 'Number':
      return (
        <NumberValidation
          validation={typeDef.validation}
          onChange={validation => {
            onChangeTypeDef({ ...typeDef, validation })
          }}
          fieldErrors={fieldErrors}
        />
      )
    case 'Date':
    case 'NullableDate':
      return (
        <DateValidation
          validation={typeDef.validation}
          onChange={validation => {
            onChangeTypeDef({ ...typeDef, validation })
          }}
          fieldErrors={fieldErrors}
        />
      )
    case 'Time':
      return (
        <TimeValidation
          validation={typeDef.validation}
          onChange={validation => {
            onChangeTypeDef({ ...typeDef, validation })
          }}
          fieldErrors={fieldErrors}
        />
      )
    // 必須チェックのみ
    case 'Checkbox':
    case 'Radio':
    case 'SelectMenu':
    case 'File':
      return (
        <RequiredCheckbox
          isChecked={typeDef.validation?.required}
          onChange={required => {
            onChangeTypeDef({ ...typeDef, validation: { required } })
          }}
        />
      )
    default:
      return assertNever(typeDef)
  }
}

const RequiredCheckbox: React.FC<{
  isChecked: boolean | undefined
  onChange: (isChecked: boolean) => void
}> = ({ isChecked, onChange }) => {
  return (
    <FormControl display="flex" gap={2} w="auto">
      <FormLabel m="0" display="block" wordBreak="keep-all" cursor="pointer">
        必須
      </FormLabel>
      <Checkbox
        color="gray.600"
        isChecked={isChecked ?? false}
        onChange={e => {
          onChange(e.target.checked)
        }}
      />
    </FormControl>
  )
}

type TextTypeValidation =
  | TextTypeDef['validation']
  | TextareaTypeDef['validation']
type TextValidationProps = {
  validation: TextTypeValidation
  onChange: (validation: TextTypeValidation) => void
  fieldErrors: Record<string, ErrSchemaDetail[]>
}
const TextValidation: React.FC<TextValidationProps> = ({
  validation,
  onChange,
  fieldErrors,
}) => {
  return (
    <Flex gap={8} align="center" w="full">
      <RequiredCheckbox
        isChecked={validation?.required}
        onChange={required => {
          onChange({ ...validation, required })
        }}
      />
      <FormControl
        display="flex"
        alignItems="center"
        gap={2}
        isInvalid={!!fieldErrors['typeDef.validation.max']}
      >
        <FormLabel
          m="0"
          color="gray.600"
          fontWeight="medium"
          w="max-content"
          wordBreak="keep-all"
        >
          上限文字数
        </FormLabel>
        <Stack w="full" spacing="0.5">
          <Input
            maxW="80px"
            value={validation?.max?.toString() ?? ''}
            onChange={e => {
              const value = e.target.value
              if (value === '') {
                onChange({ ...validation, max: undefined })
                return
              }
              const num = parseInt(value)
              if (isNaN(num)) return
              onChange({ ...validation, max: num })
            }}
          />
          <FormErrorMessage m="0">
            {fieldErrors['typeDef.validation.max']
              ?.map(e => e.message)
              .join('')}
          </FormErrorMessage>
        </Stack>
      </FormControl>
    </Flex>
  )
}

type NumberTypeValidation = NumberTypeDef['validation']
type NumberValidationProps = {
  validation: NumberTypeValidation
  onChange: (validation: NumberTypeValidation) => void
  fieldErrors: Record<string, ErrSchemaDetail[]>
}
const NumberValidation: React.FC<NumberValidationProps> = ({
  validation,
  onChange,
  fieldErrors,
}) => {
  return (
    <Flex gap={8} align="center">
      <RequiredCheckbox
        isChecked={validation?.required}
        onChange={required => {
          onChange({ ...validation, required })
        }}
      />
      <FormControl
        display="flex"
        alignItems="center"
        gap={2}
        isInvalid={
          !!fieldErrors['typeDef.validation.min'] ||
          !!fieldErrors['typeDef.validation.max']
        }
      >
        <FormLabel
          m="0"
          color="gray.600"
          fontWeight="medium"
          w="max-content"
          wordBreak="keep-all"
        >
          入力可能範囲（最小〜最大）
        </FormLabel>

        <Stack spacing="0.5">
          <Flex align="center" gap="2">
            <Input
              maxW="124px"
              value={validation?.min ?? ''}
              onChange={e => {
                const value = e.target.value
                if (value === '') {
                  onChange({ ...validation, min: undefined })
                  return
                }
                onChange({ ...validation, min: value })
              }}
              placeholder="-99999.9999"
            />

            <Text as="span">~</Text>

            <Input
              maxW="124px"
              value={validation?.max ?? ''}
              onChange={e => {
                const value = e.target.value
                if (value === '') {
                  onChange({ ...validation, max: undefined })
                  return
                }
                onChange({ ...validation, max: value })
              }}
              placeholder="99999.9999"
            />
          </Flex>
          {fieldErrors['typeDef.validation.min']?.length > 0 && (
            <FormErrorMessage m="0">
              {fieldErrors['typeDef.validation.min']
                ?.map(e => `最小値: ${e.message}`)
                .join('')}
            </FormErrorMessage>
          )}
          {fieldErrors['typeDef.validation.max']?.length > 0 && (
            <FormErrorMessage m="0">
              {fieldErrors['typeDef.validation.max']
                ?.map(e => `最大値: ${e.message}`)
                .join('')}
            </FormErrorMessage>
          )}
        </Stack>
      </FormControl>
    </Flex>
  )
}

type DateTypeValidation =
  | DateTypeDef['validation']
  | NumberTypeDef['validation']
type DateValidationProps = {
  validation: DateTypeValidation
  onChange: (validation: DateTypeValidation) => void
  fieldErrors: Record<string, ErrSchemaDetail[]>
}
type FormDate = {
  year?: string
  month?: string
  day?: string
}

const strToFormDate = (
  value: string | undefined,
  separator: '-' | '/',
): FormDate => {
  const [year, month, day] = value?.split(separator) ?? []
  return {
    year: year === 'YYYY' ? undefined : year,
    month: month === 'MM' ? undefined : month,
    day: day === 'DD' ? undefined : day,
  }
}
// 一時保存できるよう0埋めなどの処理を行う & schemaが求める-区切りの文字列に変換
const formDateToStrWithHyphen = (date: FormDate): string | undefined => {
  if (date.year && date.month && date.day) {
    return `${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`
  }
  if (date.year && date.month && !date.day) {
    return `${date.year}-${date.month.padStart(2, '0')}`
  }
  if (date.year && !date.month && !date.day) {
    return date.year
  }
  if (!date.year && !date.month && !date.day) {
    return undefined
  }
  // 不正な日付。YYYY-MM-DDで置き換える。後のバリデーションでエラーになる。
  return `${date.year ?? 'YYYY'}-${date.month ?? 'MM'}-${date.day ?? 'DD'}`
}

type DateValidationValue = {
  inputtingText: string
  formDate: FormDate
}
const DateValidation: React.FC<DateValidationProps> = ({
  validation,
  onChange,
  fieldErrors,
}) => {
  const [minDate, setMinDate] = useState<DateValidationValue>({
    inputtingText: validation?.min?.replace(/-/g, '/') ?? '',
    formDate: strToFormDate(validation?.min, '-'),
  })
  const [maxDate, setMaxDate] = useState<DateValidationValue>({
    inputtingText: validation?.max?.replace(/-/g, '/') ?? '',
    formDate: strToFormDate(validation?.max, '-'),
  })
  return (
    <Flex gap={8} align="center">
      <RequiredCheckbox
        isChecked={validation?.required}
        onChange={required => {
          onChange({ ...validation, required })
        }}
      />
      <FormControl
        display="flex"
        alignItems="center"
        gap={2}
        isInvalid={
          !!fieldErrors['typeDef.validation.min'] ||
          !!fieldErrors['typeDef.validation.max']
        }
      >
        <FormLabel m="0" color="gray.600" fontWeight="medium">
          入力可能範囲（最小〜最大）
        </FormLabel>
        <VStack spacing="0.5" align="start">
          <Flex gap={2} align="center">
            <Input
              maxW="120px"
              placeholder="1000/01/01"
              value={minDate.inputtingText}
              onChange={e => {
                const value = e.target.value
                if (value === '') {
                  setMinDate({
                    inputtingText: value,
                    formDate: strToFormDate(value, '/'),
                  })
                  onChange({ ...validation, min: undefined })
                  return
                }
                const formDate = strToFormDate(value, '/')
                setMinDate({
                  inputtingText: value,
                  formDate,
                })
                onChange({
                  ...validation,
                  min: formDateToStrWithHyphen(formDate),
                })
              }}
            />

            <Text as="span">~</Text>

            <Input
              maxW="120px"
              placeholder="9999/12/31"
              value={maxDate.inputtingText}
              onChange={e => {
                const value = e.target.value
                if (value === '') {
                  setMaxDate({
                    inputtingText: value,
                    formDate: strToFormDate(value, '/'),
                  })
                  onChange({ ...validation, max: undefined })
                  return
                }
                const formDate = strToFormDate(value, '/')
                setMaxDate({
                  inputtingText: value,
                  formDate,
                })
                onChange({
                  ...validation,
                  max: formDateToStrWithHyphen(formDate),
                })
              }}
            />
          </Flex>
          <FormErrorMessage m="0">
            {fieldErrors['typeDef.validation.min']
              ?.map(e => `最小値: ${e.message}`)
              .join('')}
          </FormErrorMessage>
          <FormErrorMessage m="0">
            {fieldErrors['typeDef.validation.max']
              ?.map(e => `最大値: ${e.message}`)
              .join('')}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    </Flex>
  )
}

type TimeTypeValidation = DateTypeDef['validation']
type TimeValidationProps = {
  validation: TimeTypeValidation
  onChange: (validation: TimeTypeValidation) => void
  fieldErrors: Record<string, ErrSchemaDetail[]>
}
const TimeValidation: React.FC<TimeValidationProps> = ({
  validation,
  onChange,
  fieldErrors,
}) => {
  return (
    <Flex gap={8} align="center">
      <RequiredCheckbox
        isChecked={validation?.required}
        onChange={isChecked => {
          onChange({ ...validation, required: isChecked })
        }}
      />
      <FormControl
        display="flex"
        alignItems="center"
        gap={2}
        isInvalid={
          !!fieldErrors['typeDef.validation.min'] ||
          !!fieldErrors['typeDef.validation.max']
        }
      >
        <FormLabel m="0" color="gray.600" fontWeight="medium">
          入力可能範囲（最小〜最大）
        </FormLabel>
        <VStack align="start" spacing="0.5">
          <Flex gap={2} align="center">
            <Input
              maxW="80px"
              placeholder="00:00"
              value={validation?.min?.toString() ?? ''}
              onChange={e => {
                onChange({
                  ...validation,
                  min: !!e.target.value ? e.target.value : undefined,
                })
              }}
            />

            <Text as="span">~</Text>

            <Input
              maxW="80px"
              placeholder="23:59"
              value={validation?.max?.toString() ?? ''}
              onChange={e => {
                onChange({
                  ...validation,
                  max: !!e.target.value ? e.target.value : undefined,
                })
              }}
            />
          </Flex>
          <FormErrorMessage m="0">
            {fieldErrors['typeDef.validation.min']
              ?.map(e => `最小値: ${e.message}`)
              .join('')}
          </FormErrorMessage>
          <FormErrorMessage m="0">
            {fieldErrors['typeDef.validation.max']
              ?.map(e => `最大値: ${e.message}`)
              .join('')}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    </Flex>
  )
}
