import React from 'react'

import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { Edit, Warning } from 'src/components/__legacy__icon/monochrome'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { useModal } from 'src/hooks/use-modal'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'

import { GetPatientReturn } from '../../api'
import { useEditPatient } from '../../api/editPatient'
import { PatientSchema, patientSchema } from '../../schema'
import { patientToSchema } from '../../utils/patientToSchema'
import { schemaToUpdatePatientInput } from '../../utils/schemaToInput'
import { DisablePatientModal } from '../DisablePatientModal/DisablePatientModal'
import { EnablePatientModal } from '../EnablePatientModal/EnablePatientModal'
import { PatientFormContent } from '../PatientFormContent/PatientFormContent'

type Props = {
  patient: GetPatientReturn
  mutate: () => Promise<GetPatientReturn | undefined>
}

const isHospitalChanged = (
  patient: GetPatientReturn,
  schema: PatientSchema,
) => {
  if (patient.trialHospital.uid !== schema.trialHospitalUid) {
    return true
  }
  if (!patient.partnerTrialHospital) {
    return !!schema.partnerTrialHospitalUid
  }
  return patient.partnerTrialHospital.uid !== schema.partnerTrialHospitalUid
}

export const EditPatient: React.FC<Props> = ({ patient, mutate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isDisableModalOpen,
    onOpen: onOpenDisableModal,
    onClose: onCloseDisableModal,
  } = useDisclosure()

  const {
    isOpen: isEnableModalOpen,
    onOpen: onOpenEnableModal,
    onClose: onCloseEnableModal,
  } = useDisclosure()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const defaultValues: Partial<PatientSchema> = patientToSchema(patient)

  const methods = useForm<PatientSchema>({
    mode: 'onBlur',
    resolver: yupResolver(
      patientSchema(
        selectedTrialHospitalUid,
        defaultValues.partnerTrialHospitalUid,
      ),
    ),
    defaultValues,
  })

  const {
    reset,
    clearErrors,
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods

  const handleClose = () => {
    onClose()
    reset()
    clearErrors()
  }

  const isDisabled = patient.status === 'Disable'

  const requiredFieldFilled =
    !!watch('diseaseId') && !!watch('trialHospitalUid')

  const myHospitalSelected =
    watch('trialHospitalUid') === selectedTrialHospitalUid ||
    watch('partnerTrialHospitalUid') === selectedTrialHospitalUid

  const { hasPermission } = usePermission()

  const toast = useMirohaToast()

  const { request: editPatient, requesting } = useEditPatient({
    onSuccess: async () => {
      onClose()
      toast({
        title: '患者情報を更新しました',
        status: 'success',
      })
      const resetValue = await mutate()
      reset(resetValue ? patientToSchema(resetValue) : undefined)
      clearErrors()
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const { showModal, isOpen: isConfirmModalOpen } = useModal()

  const onSubmit = async (schema: PatientSchema) => {
    const input = schemaToUpdatePatientInput(patient.uid, schema)
    if (isHospitalChanged(patient, schema)) {
      showModal({
        title: ConfirmModalTitle,
        content: ConfirmModalContent,
        submitText: '確定',
        submitButtonColor: 'red',
        size: 'xl',
        onSubmit: async () => {
          await editPatient(input)
        },
      })
      return
    }

    await editPatient(input)
  }

  if (!hasPermission(PERMISSIONS.Patient_Edit) || !selectedTrialHospitalUid) {
    return null
  }

  return (
    <>
      <Tooltip label="被験者情報変更">
        <IconButton
          aria-label="被験者情報変更"
          variant="customIconButtonGhost"
          colorScheme="blue"
          onClick={onOpen}
          icon={<Edit size="S" />}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalContent
              px="6"
              // confirmModalをキャンセルした場合、stateが維持されるようcloseせずにdisplay:noneにする
              display={isConfirmModalOpen || requesting ? 'none' : undefined}
            >
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize="lg" fontWeight="bold">
                  被験者情報変更
                </Text>
              </ModalHeader>
              <ModalBody py="8">
                <PatientFormContent
                  isDisabled={isDisabled}
                  myHospitalSelected={myHospitalSelected}
                  isPartner={
                    patient.partnerTrialHospital?.uid ===
                    selectedTrialHospitalUid
                  }
                  defaultValues={defaultValues}
                />
              </ModalBody>

              <ModalFooter w="full" justifyContent="space-between">
                {isDisabled ? (
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => {
                      onClose()
                      onOpenEnableModal()
                    }}
                  >
                    有効化
                  </Button>
                ) : (
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={() => {
                      onClose()
                      onOpenDisableModal()
                    }}
                  >
                    無効化
                  </Button>
                )}
                <HStack spacing="4">
                  <ModalCancelButton />
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isDisabled={
                      !requiredFieldFilled ||
                      !myHospitalSelected ||
                      !isValid ||
                      isDisabled
                    }
                  >
                    確定
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>

      <DisablePatientModal
        patient={patient}
        mutatePatient={mutate}
        isOpen={isDisableModalOpen}
        onClose={() => {
          onCloseDisableModal()
          onOpen()
        }}
        onCloseEditPatientModal={onClose}
      />
      <EnablePatientModal
        patient={patient}
        mutatePatient={mutate}
        isOpen={isEnableModalOpen}
        onClose={() => {
          onCloseEnableModal()
          onOpen()
        }}
        onCloseEditPatientModal={onClose}
      />
    </>
  )
}

const ConfirmModalTitle: React.ReactNode = (
  <HStack color="red.500">
    <Warning />
    <Text>実施医療機関/パートナー施設が変更されています</Text>
  </HStack>
)

const ConfirmModalContent: React.ReactNode = (
  <Text>
    誤った施設を設定してしまうと、意図しないユーザーに対して被験者情報が表示されてしまうのでご注意ください。
    <br />
    このまま変更してよろしいでしょうか？
  </Text>
)
