import { Center, Flex, HStack, Text } from '@chakra-ui/react'
import { Check, Reject } from 'src/components/icon'

type Props = {
  title: string
  supplementaryElement?: React.ReactNode
  isGrayedOut?: boolean
  icon?: 'Reject' | 'Check'
}

export const SignFlowBannerStatic: React.FC<Props> = ({
  title,
  supplementaryElement,
  isGrayedOut = false,
  icon = 'Check',
}) => {
  return (
    <Flex
      bg={isGrayedOut ? 'gray.400' : 'green.100'}
      p="4"
      borderRadius="lg"
      align="center"
      h="72px"
      gap={2}
    >
      <HStack flex="1" align="center" spacing="6">
        <Center w="40px" h="40px" color={isGrayedOut ? 'white' : 'green.700'}>
          {icon === 'Reject' ? <Reject size="32px" /> : <Check size="32px" />}
        </Center>
        <Text
          fontSize="2xl"
          fontWeight="bold"
          color={isGrayedOut ? 'white' : 'green.700'}
        >
          {title}
        </Text>
      </HStack>
      {supplementaryElement !== undefined && (
        <Flex flex="1" maxW="50%">
          {supplementaryElement}
        </Flex>
      )}
    </Flex>
  )
}
