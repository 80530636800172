import React from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SubmitModal } from 'src/components/modal/submit'
import { actions as worksheetActions } from 'src/modules/entities/worksheet/redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { create } from './request'
import { getLoglineRoute } from '../../../routes'
import { actions as worksheetDetailActions } from '../../detail/redux'

type Props = {
  onClose: () => void
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemUid: string
  edcLinked: boolean
}

export const AddLoglineModal: React.FC<Props> = props => {
  const { trialUid, patientUid, worksheetUid, mediumItemUid, edcLinked } = props
  const { request, requesting } = useAddLogline({
    trialUid,
    patientUid,
    worksheetUid,
    mediumItemUid,
    edcLinked,
  })

  const onSubmit = async () => {
    await request()
    props.onClose()
  }

  return (
    <SubmitModal
      title={`行を追加しますか？`}
      submitText={`追加`}
      onSubmit={onSubmit}
      requesting={requesting}
      onClose={props.onClose}
    >
      {edcLinked && <Text textAlign="center">※EDCにも空行が追加されます</Text>}
    </SubmitModal>
  )
}

const useAddLogline = ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemUid,
  edcLinked,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemUid: string
  edcLinked: boolean
}) => {
  const { requestDone, requestStarted, requesting, errorMessage } =
    useRequestState({
      withSpinner: edcLinked,
    })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showSuccess, showError } = useFlash()

  const request = async () => {
    try {
      requestStarted()
      const logline = await create({
        trialUid,
        patientUid,
        worksheetUid,
        mediumItemUid,
      })

      dispatch(worksheetDetailActions.addLogline({ logline }))

      navigate(
        getLoglineRoute({
          trialUid,
          patientUid,
          worksheetUid,
          loglineUid: logline.uid,
        }),
      )

      dispatch(
        worksheetActions.fetchSingle({ trialUid, patientUid, worksheetUid }),
      )

      showSuccess('行を追加しました')
    } catch (error) {
      showError(error.message, { durationMsec: 30000, closable: true })
    }
    requestDone()
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}
