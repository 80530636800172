import React from 'react'

import { Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { Circle, CircleOutline } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { blue, gray, green } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { EProStatus } from 'src/modules/entities/patient/components/epro-status'
import { Patient, PatientStatus } from 'src/modules/entities/patient/entity'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import styled, { css } from 'styled-components'

import { usePROExportPane } from '../pro-export-pane'
import { getDetailRoute } from '../routes'

type Props = {
  trialUid: string
  patients: Patient[]
  questionnaires: Questionnaire[]
  canExportCSV: boolean
}

export const List: React.FC<Props> = props => {
  const { trialUid, patients, questionnaires, canExportCSV } = props

  const { paneComponent: proExportPane, open: openPROExportPane } =
    usePROExportPane(trialUid, questionnaires)

  return (
    <PageContainer>
      <ContentContainer>
        <HeadContainer>
          <Text as="h1" fontSize="lg" fontWeight="bold">
            患者回答
          </Text>

          {canExportCSV && (
            <Button
              text="全患者の回答出力"
              buttonType="normal"
              size="S"
              onClick={openPROExportPane}
            />
          )}
        </HeadContainer>
        <TableContainer>
          {patients.length === 0 && (
            <NonQuestionnaireText>
              登録している患者データはありません。
            </NonQuestionnaireText>
          )}
          {patients.length >= 1 && (
            <PatientTable trialUid={trialUid} patients={patients} />
          )}
        </TableContainer>
        {proExportPane}
      </ContentContainer>
    </PageContainer>
  )
}

type PatientTableProps = {
  trialUid: string
  patients: Patient[]
}

const PatientTable: React.FC<PatientTableProps> = props => {
  const { patients, trialUid } = props

  return (
    <Container>
      <Table>
        <Head>
          <HeadItems>
            <Spacer size={48} horizontal />

            <Grid>
              <GridItem>
                <Text fontWeight="bold">症例番号</Text>
                <Spacer size={10} horizontal />
              </GridItem>

              <GridItem>
                <Text fontWeight="bold">初回来院日</Text>
                <Spacer size={10} horizontal />
              </GridItem>

              <GridItem>
                <Text fontWeight="bold">試験開始日</Text>
              </GridItem>

              <GridItem>
                <Text fontWeight="bold">ステータス</Text>
              </GridItem>
            </Grid>
          </HeadItems>
        </Head>

        <Body>
          <Scroll>
            {patients.map((p, i) => {
              const disabled = p.status === PatientStatus.Disable
              const row = createPatientRow(p)
              const key = p.uid + i

              return disabled ? (
                <DisabledRow key={key}>{row}</DisabledRow>
              ) : (
                <Row
                  key={key}
                  to={getDetailRoute({ trialUid, patientUid: p.uid })}
                >
                  {row}
                </Row>
              )
            })}
          </Scroll>
        </Body>
      </Table>
    </Container>
  )
}

const createPatientRow = (p: Patient) => {
  const disabled = p.status === PatientStatus.Disable
  const textColor = disabled ? gray[55] : gray[100]
  return (
    <>
      <Status>
        {disabled ? (
          <CircleOutline color={gray[55]} />
        ) : (
          <Circle color={green[40]} />
        )}
      </Status>

      <Grid>
        <GridItem>
          <Text color={textColor}>{p.diseaseUid}</Text>
        </GridItem>

        <GridItem className="name">
          <Text color={textColor}>{p.firstVisitDate}</Text>
        </GridItem>

        <GridItem>
          <Text color={textColor}>{p.answerStartDate}</Text>
        </GridItem>

        <GridItem>
          <EProStatus patient={p} />
        </GridItem>
      </Grid>
    </>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 28px;
  box-sizing: border-box;
  min-height: 55px;
  background: ${colors.bgWhite};
`

const NonQuestionnaireText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 200px 0;
  color: ${gray[50]};
`

const TableContainer = styled.div`
  flex: 1;
`

const Container = styled.div`
  height: 100%;
  padding: 16px 12px 8px;
  box-sizing: border-box;
`

const Table = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border: 1px solid ${gray[40]};
  border-radius: 12px;
`

const Head = styled.div`
  width: 100%;
  border-bottom: 1px solid ${gray[40]};
`

const SCROLL_BAR_WIDTH = 6
const RIGHT_SPACE = 10 + SCROLL_BAR_WIDTH + 20 //Body部における、スクロールバーの外側（10）、本体、内側（20）のスペースを足した値
const HeadItems = styled.div`
  margin: 0 ${RIGHT_SPACE}px 0 20px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  position: relative;
  flex: 1;
  margin: 0 10px 0 0;
`

const RowBase = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  border-bottom: 1px dashed ${gray[40]};
`

const Row = styled(NavLink)`
  :hover {
    background-color: ${blue[10]};
  }
  ${RowBase}
`

const DisabledRow = styled.div`
  ${RowBase}
`

const Scroll = styled.div`
  position: absolute;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`

const Grid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 25% 20% 25% 30%;
`

const GridItem = styled.div`
  display: flex;
  align-items: center;
`

const Status = styled.div`
  width: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
