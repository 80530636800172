import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'

const useChangeTemplateTitle = (initialName: string) => {
  const [templateTitle, setTemplateTitle] = useState<string>(initialName)
  const onChangeTemplateTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTemplateTitle(e.target.value)
    },
    [],
  )

  return { templateTitle, onChangeTemplateTitle }
}

type Props = {
  initialName: string
  requesting: boolean
  errorMessage: string

  onClose: () => void
  onSubmit: (name: string) => void
}

export const UpdateTitleModal: React.FC<Props> = props => {
  const { templateTitle, onChangeTemplateTitle } = useChangeTemplateTitle(
    props.initialName,
  )

  const onSubmit = useCallback(() => {
    props.onSubmit(templateTitle)
  }, [props, templateTitle])

  const canSubmit = !props.requesting && !!templateTitle

  return (
    <Modal onClose={props.onClose}>
      <ModalTitle title="テンプレート名を編集" />
      <ModalContent>
        <Text fontSize="xs" textAlign="center">
          ※作成済みの患者ワークシートの名称も変更されます
        </Text>
        <Spacer size={20} />
        <Input value={templateTitle} onChange={onChangeTemplateTitle} />
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          buttonType="cancel"
          onClick={props.onClose}
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="確定"
          buttonType="important"
          onClick={onSubmit}
          disabled={!canSubmit}
        ></Button>
      </ModalActions>
      {props.errorMessage && (
        <Message type="error" message={props.errorMessage} centered />
      )}
    </Modal>
  )
}
