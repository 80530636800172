import { ExplanationRevisionStatus } from '../types'

export const allExplanationRevisionStatuses: ExplanationRevisionStatus[] = [
  'SessionNotStarted',
  'SessionNotDone',
  'AgreementNotDone',
  'AgreementDone',
  'AgreementRejected',
  'Withdrawn',
  'Disabled',
]
