import { ExplanationRevisionStatusV2 } from 'src/lib/gql-client'

export const getStatusInformationMessage = (param: {
  status: ExplanationRevisionStatusV2
  hasConsentRequiredAgreementForm: boolean
  isSignerSet: boolean
}) => {
  const { status, hasConsentRequiredAgreementForm, isSignerSet } = param
  if (!hasConsentRequiredAgreementForm) {
    return undefined
  }
  switch (status) {
    case 'New':
    case 'SessionStarted':
      if (isSignerSet) {
        return "'署名処理を実施しています。このままお待ちください。'"
      }
      return undefined
    case 'SignReady':
    case 'SignStarted':
    case 'SignCompleted':
      if (!isSignerSet) {
        return '署名準備を進めています。このままお待ちください。'
      }
      return '署名処理を実施しています。このままお待ちください。'
    case 'AgreementCompleted':
    case 'DeliveryCompleted':
      return '署名処理が完了しました。'
    case 'AgreementRejected':
      return '説明への同意が拒否されました。説明を終了してください。'
    default:
      return undefined
  }
}
