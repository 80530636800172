import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import { PatientAnswer } from './patient-answer/patient-answer'
import { Questionnaire } from './questionnaire/questionnaire'
import { routes } from './routes'

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={`${routes.patientAnswers}/*`} element={<PatientAnswer />} />
      <Route path={`${routes.questionnaires}/*`} element={<Questionnaire />} />
      <Route
        path="*"
        element={<Navigate replace to={`${routes.patientAnswers}`} />}
      />
    </Routes>
  )
}
