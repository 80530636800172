import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { Information } from './entity'
import { fetchList } from './request'

export const useFetch = () => {
  const [informationList, setInformationList] = useState<Information[]>([])
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    if (fetched) return

    const fetch = async () => {
      const list = await fetchList()
      setInformationList(filterList(list))
      setFetched(true)
    }

    fetch()
  }, [fetched])

  return {
    informationList,
  }
}

const filterList = (list: Information[]) => {
  const createdAtDesc = (a: Information, b: Information) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  }

  return list.filter(isValidInformation).sort(createdAtDesc)
}

const isValidInformation = (information: Information) => {
  const { title, content, startedAt, endedAt } = information
  const now = new Date()
  const start = new Date(startedAt)
  const end = new Date(endedAt)

  if (!title || !content) {
    return false
  }

  //不正な日付、開始・終了両方とも未設定、終了が未設定のケースを弾く
  if (!isValidDate(startedAt) || !isValidDate(endedAt)) {
    return false
  }
  if (!startedAt && !endedAt) {
    return false
  }

  const notStarted = !!startedAt && start.getTime() - now.getTime() > 0
  if (notStarted) {
    return false
  }

  const ended = !!endedAt && now.getTime() - end.getTime() > 0
  if (ended) {
    return false
  }

  return true
}

const isValidDate = (date: string) => {
  return !date || dayjs(date).isValid()
}
