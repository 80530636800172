export const patientCacheKey = {
  getPatient: (patientUid: string) => patientUid,
  searchPatients: (searchParams: string) =>
    ['searchPatients', searchParams].join(),
  getEnabledTrialHospitals: () => 'enabledTrialHospitals',
  getAvailablePatientVideoCalls: (patientUid: string) =>
    [patientUid, 'availablePatientVideoCalls'].join(),
  getPatientVideoCallsForHistory: (patientUid: string) =>
    [patientUid, 'getPatientVideoCallsForHistory'].join(),
  getPatientVideoCall: (patientVideoCallUid: string) => [
    'patientVideoCall',
    patientVideoCallUid,
  ],
  getPatientVideoCallAbout: (patientVideoCallUid: string) =>
    ['patientVideoCallAbout', patientVideoCallUid].join(),
  challengePatientVideoCall: (trialUid: string, patientVideoCallUid: string) =>
    ['challengePatientVideoCall', trialUid, patientVideoCallUid].join(),
}
