import { assertNever } from 'src/utils/assertNever'

import { ExplanationRevisionStatus } from '../types'

export const isSignStarted: (
  status: ExplanationRevisionStatus,
) => boolean = status => {
  switch (status) {
    case 'SessionNotStarted':
    case 'SessionNotDone':
      return false
    case 'AgreementNotDone':
    case 'AgreementDone':
    case 'AgreementRejected':
    case 'Disabled':
    case 'Withdrawn':
      return true
    default:
      return assertNever(status)
  }
}
