import { MediumItemAnswer } from '../medium-item-answer/entity'

export enum WorksheetStatus {
  Disabled = 0,
  Saved,
  New,
}

export type Worksheet = {
  uid: string
  templateUid: string
  patientUid: string
  title: string
  templateFolderUid: string
  videoEnabled: boolean
  status?: WorksheetStatus
  hasClinicalSmallItem: boolean
  index: number

  mediumItemAnswers: MediumItemAnswer[]

  createdAt: string
  updatedAt: string
}
