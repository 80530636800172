import { ReactNode } from 'react'

import { Badge, HStack, Text } from '@chakra-ui/react'
import { Check, Rejection } from 'src/components/__legacy__icon/monochrome'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { ExplanationRevisionStatus } from '../../types'

const explanationStatusPropertiesRecord: Record<
  ExplanationRevisionStatus,
  { text: string; fontColor: string; backgroundColor: string; icon?: ReactNode }
> = {
  SessionNotStarted: {
    text: '説明未実施',
    fontColor: colors.blue[500],
    backgroundColor: colors.blue[100],
  },
  SessionNotDone: {
    text: '説明未完了',
    fontColor: colors.blue[500],
    backgroundColor: colors.blue[100],
  },
  AgreementNotDone: {
    text: '同意未完了',
    fontColor: colors.yellow[700],
    backgroundColor: colors.yellow[100],
  },
  AgreementDone: {
    text: '同意完了',
    fontColor: colors.green[600],
    backgroundColor: colors.green[100],
    icon: <Check />,
  },
  AgreementRejected: {
    text: '同意拒否',
    fontColor: colors.red[600],
    backgroundColor: colors.red[100],
    icon: <Rejection />,
  },
  Disabled: {
    text: '無効',
    fontColor: colors.gray[400],
    backgroundColor: colors.gray[100],
  },
  Withdrawn: {
    text: '同意撤回',
    // TODO: colorの確認。figmaに記載なし。
    fontColor: colors.gray[400],
    backgroundColor: colors.gray[100],
  },
}

type Props = {
  status: ExplanationRevisionStatus
}

export const ExplanationStatusBadge: React.FC<Props> = ({ status }) => {
  const { fontColor, backgroundColor, icon, text } =
    explanationStatusPropertiesRecord[status]
  return (
    <Badge
      sx={{
        width: '66px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        color: fontColor,
        backgroundColor: backgroundColor,
        fontWeight: 'normal',
      }}
    >
      <HStack spacing="2px">
        {!!icon && icon}
        <Text fontWeight="700" fontSize="10px">
          {text}
        </Text>
      </HStack>
    </Badge>
  )
}
