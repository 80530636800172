import { ExplanationRevisionDeliveryType } from '../types'

const typeToTextMap: Record<ExplanationRevisionDeliveryType, string> = {
  Download: 'ダウンロード交付',
  Email: 'メール交付',
  EmailAndDownload: 'メール交付',
}

export const explanationRevisionDeliveryTypeToText = (
  type: ExplanationRevisionDeliveryType,
) => {
  return typeToTextMap[type]
}
