import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { ExplanationRoomDocument } from './document'
import { getExplanationDetailRoute } from '../../explanation/routes'
import { ExplanationRoomLayout } from '../layout'
import {
  usePreviewDocRevision,
  usePreviewExplanation,
} from '../preview-context'
import {
  docRevisionUidParamName,
  getExplanationRoomTopPreviewRoute,
} from '../routes'

export const PreviewDocumentContainer: React.FC = () => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!

  const { explanation } = usePreviewExplanation()
  const { docRevisionUid = '' } = useParams<{
    [docRevisionUidParamName]: string
  }>()
  const { docRevision } = usePreviewDocRevision(docRevisionUid)

  const navigate = useNavigate()

  const navigateToTop = useCallback(() => {
    if (!explanation) return
    navigate(getExplanationRoomTopPreviewRoute(trialUid, explanation.uid))
  }, [explanation, navigate, trialUid])

  const navigateToExplanationDetail = useCallback(() => {
    if (!explanation) return
    navigate(
      getExplanationDetailRoute({ trialUid, explanationUid: explanation.uid }),
    )
  }, [explanation, navigate, trialUid])

  if (!docRevision) {
    return null
  }

  return (
    <ExplanationRoomLayout
      headerTitle={docRevision.docName}
      onFinish={navigateToExplanationDetail}
      isPreview
      sidebarContent={null}
    >
      <ExplanationRoomDocument
        docRevision={docRevision}
        navigateToRoomTop={navigateToTop}
        shouldShowSendLinkButton={false}
        shouldShowResendLinkButton={false}
        shouldShowFetchResultButton={false}
        shouldShowRejectButton={false}
        shouldShowStartDocuSignButton={false}
        fetchingResult={false}
      />
    </ExplanationRoomLayout>
  )
}
