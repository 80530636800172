import { IcfDocumentType, icfDocumentTypes } from './entity'

type Arg =
  | { key: 'version'; value: string | undefined }
  | { key: 'name'; value: string | undefined }
  | { key: 'documentType'; value: IcfDocumentType | undefined }
  | { key: 'fileSize'; value: File }
  | { key: 'fileName'; value: string | undefined }

export type IcfDocumentErrors = Partial<Record<Arg['key'], string | undefined>>

const megaByte = (byte: number) => byte / 1024 ** 2

const fileExtension = (fileName: string): 'pdf' | 'mp4' | undefined => {
  const extension = fileName.split('.').pop()
  if (extension === 'pdf') {
    return 'pdf'
  }
  if (extension === 'mp4') {
    return 'mp4'
  }

  return undefined
}

const onlyHalfSizeNumberAndPeriodRegex = /^[\d|.]+$/
const versionRegex = /^(?!0.0|0$)[1-9]?[0-9](\.[0-9])?$/

const assertNotNever = (arg: never) => {
  throw new Error(`${arg} is not never`)
}

const getErrorMessage = (arg: Arg): string | null => {
  switch (arg.key) {
    case 'version':
      const version = arg.value
      if (!version) {
        return '版数は必須です'
      }
      // 文字の種類
      if (!version.match(onlyHalfSizeNumberAndPeriodRegex)) {
        return '半角数字とピリオド(.)のみ入力可能です'
      }
      // 小数点の桁数
      const decimalPointLength = !!version.split('.')[1]
        ? version.split('.')[1].length
        : 0
      if (decimalPointLength >= 2) {
        return '小数点第一位まで入力可能です'
      }
      // 数値の範囲
      if (!(Number(version) >= 0.1 && Number(version) <= 99.9)) {
        return '0.1から99.9の範囲のみ入力可能です'
      }
      if (!version.match(versionRegex)) {
        return '版数のフォーマットが不正です'
      }
      break

    case 'name':
      const name = arg.value
      if (!name) {
        return '文書名は必須です'
      }
      if (name.length > 100) {
        return '文書名は100文字以内で入力してください'
      }
      break

    case 'documentType':
      const type = arg.value
      if (type === undefined || type === icfDocumentTypes.Undefined) {
        return '文書の種類を選択してください'
      }
      break

    case 'fileSize':
      const file = arg.value
      if (fileExtension(file.name) === 'pdf' && megaByte(file.size) > 10) {
        return 'PDFファイルの大きさの上限は10MBです'
      }
      if (fileExtension(file.name) === 'mp4' && megaByte(file.size) > 100) {
        return 'mp4ファイルの大きさの上限は100MBです'
      }
      break

    case 'fileName':
      const fileName = arg.value
      if (!fileName) {
        return 'ファイルを選択してください'
      }
      if (fileExtension(fileName) === undefined) {
        return 'ファイルのフォーマットが不正です'
      }
      break

    default:
      return assertNotNever(arg)
  }

  return null
}

export const validateIcfDocument = (
  arg: Arg,
  ...restArgs: Arg[]
): IcfDocumentErrors | null => {
  const errors: Partial<IcfDocumentErrors> = {}

  const args = [arg, ...restArgs]

  args.forEach(arg => {
    const message = getErrorMessage(arg)
    if (message === null) {
      return
    }

    errors[arg.key] = message
  })

  return Object.keys(errors).length > 0 ? errors : null
}
