export type WorksheetDate = {
  year: string | undefined
  month: string | undefined
  day: string | undefined
}

/** 0000-00-00 の形式で渡されることが期待される */
export const toWorksheetDate = (dateStr: string | undefined): WorksheetDate => {
  const [year, month, day] = dateStr?.split('-') ?? []
  return { year, month, day }
}

export const toDateString = (date: WorksheetDate): string => {
  const { year, month, day } = date
  if (!year && !month && !day) {
    return ''
  }
  return `${year ?? ''}-${month ?? ''}-${day ?? ''}`
}
