import {
  GetDocRevisionDocument,
  GetDocRevisionForPatientDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { ExplanationDocRevision } from '../../types'
import { explanationCacheKey } from '../cacheKey'
import { parseGqlDocRevision } from '../parser'

const getExplanationDocRevision = async ({
  explanationDocRevisionUid,
  forPatient,
}: {
  explanationDocRevisionUid: ExplanationDocRevision['uid']
  forPatient?: boolean
}): Promise<ExplanationDocRevision> => {
  try {
    const res = forPatient
      ? await graphqlRequest(GetDocRevisionForPatientDocument, {
          explanationDocRevisionUid,
        })
      : await graphqlRequest(GetDocRevisionDocument, {
          explanationDocRevisionUid,
        })
    return parseGqlDocRevision(res.explanationDocRevision)
  } catch (error) {
    throw error
  }
}

export const useExplanationDocRevision = ({
  explanationDocRevisionUid,
  forPatient,
  ...option
}: {
  explanationDocRevisionUid: ExplanationDocRevision['uid']
  forPatient?: boolean
} & SWRConfiguration<ExplanationDocRevision, GqlError>) => {
  return useSWR(
    explanationCacheKey.getExplanationDocRevision(explanationDocRevisionUid),
    () => getExplanationDocRevision({ explanationDocRevisionUid, forPatient }),
    option,
  )
}
