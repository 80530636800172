import React from 'react'

import { Badge, Box, HStack, IconButton, Stack, Text } from '@chakra-ui/react'
import { ArrowUp, ArrowDown } from 'src/components/icon'
import { MediumItem } from 'src/modules/entities/medium-item/entity'

import { useEditCard } from '../use-edit-card'

type Props = {
  index: number
  mediumItem: MediumItem
  orderEditable: boolean
  v2: boolean

  onUp: () => void
  onDown: () => void
  onChange: (values: Partial<MediumItem>) => void
  onDelete: () => void
}

export const SortOnlyGroup: React.FC<Props> = props => {
  const { index, mediumItem, orderEditable, onUp, onDown, v2 } = props

  const { canUpMediumItem, canDownMediumItem } = useEditCard(index)

  return (
    <HStack spacing="1" w="full">
      {!v2 && (
        <Stack>
          <IconButton
            icon={<ArrowUp />}
            variant="customIconButtonGhost"
            aria-label="セクションを上に移動する"
            isDisabled={!canUpMediumItem || !orderEditable}
            onClick={onUp}
          />

          <IconButton
            icon={<ArrowDown />}
            variant="customIconButtonGhost"
            aria-label="セクションを下に移動する"
            isDisabled={!canDownMediumItem || !orderEditable}
            onClick={onDown}
          />
        </Stack>
      )}
      <Stack
        flex="1"
        spacing="5"
        border="1px"
        borderColor="gray.100"
        borderRadius="base"
        bg="blue.50"
        p="5"
      >
        <LabelAndValue label="セクション名" value={mediumItem.title} />

        {!v2 && (
          <HStack spacing="12">
            <LabelAndValue
              label="EDCフォルダ名"
              value={mediumItem.folderName}
            />
            <LabelAndValue label="EDCフォーム名" value={mediumItem.formName} />
          </HStack>
        )}

        {mediumItem.isLogline && (
          <Box>
            <Badge>ログライン</Badge>
          </Box>
        )}
      </Stack>
    </HStack>
  )
}

const LabelAndValue: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <Stack spacing="1">
    <Text fontWeight="bold" fontSize="sm" color="gray.600">
      {label}
    </Text>
    <Text minH="6">{value}</Text>
  </Stack>
)
