// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgEPro = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect
      width={26}
      height={30}
      x={3}
      y={1}
      stroke={props.color ?? 'currentColor'}
      strokeWidth={2}
      rx={4}
    />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeWidth={3}
      d="M15.5 7.167h9M15.5 16.5h9M15.5 25.833h9"
    />
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      d="M8 3.333a2 2 0 0 0-2 2V8a2 2 0 0 0 2 2h2.667a2 2 0 0 0 2-2V5.333a2 2 0 0 0-2-2H8Zm4.02 2.354a.5.5 0 1 0-.707-.707L8.667 7.626 7.02 5.98a.5.5 0 0 0-.707.707l2 2a.5.5 0 0 0 .707 0l3-3Z"
      clipRule="evenodd"
    />
    <rect
      width={5.667}
      height={5.667}
      x={6.5}
      y={13.167}
      fill={props.color ?? 'currentColor'}
      stroke={props.color ?? 'currentColor'}
      rx={1.5}
    />
    <rect
      width={5.667}
      height={5.667}
      x={6.5}
      y={22.5}
      fill={props.color ?? 'currentColor'}
      stroke={props.color ?? 'currentColor'}
      rx={1.5}
    />
  </svg>
)
export default SvgEPro
