import { generateUUID } from 'src/utils/generateUUID'

import { QuestionnaireChoice } from '../questionnaire-choice/entity'

export enum AnswerType {
  Radio = 1,
  CheckBox,
  Number,
  Text,
}

export type QuestionnaireField = {
  uid: string
  title: string
  answerType: AnswerType
  index: number
  unit: string
  choices: QuestionnaireChoice[]
}

export const createEmptyQuestionnaireField = (
  index: number,
): QuestionnaireField => ({
  uid: generateUUID(),
  title: '',
  answerType: AnswerType.Radio,
  index: index,
  unit: '',
  choices: [],
})
