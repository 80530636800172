import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost, requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { fixture } from './fixture'
import { Questionnaire } from '../questionnaire/entity'
import { QuestionnaireListResponse } from '../questionnaire/request'
import { Schedule } from '../schedule/entity'

export type QuestionnaireScheduleListResponse = {
  questionnaires: Pick<
    Questionnaire,
    'uid' | 'title' | 'status' | 'scheduleUids'
  >[]
  schedules: Schedule[]
}

export const fetch = async (
  trialUid: string,
): Promise<QuestionnaireScheduleListResponse> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestGet<QuestionnaireScheduleListResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaire_schedules`,
  })

  return res.data
}

export type QuestionnaireScheduleSaveRequest = {
  questionnaires: Pick<Questionnaire, 'uid' | 'scheduleUids'>[]
}

export const save = async ({
  trialUid,
  params,
}: {
  trialUid: string
  params: QuestionnaireScheduleSaveRequest
}): Promise<any> => {
  const res = await requestPut<any>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaire_schedules`,
    params,
  })

  return res.data
}

export const release = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<void> => {
  await requestPost<QuestionnaireListResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaire_schedules/release`,
  })
}
