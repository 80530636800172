import { createSelector } from 'reselect'
import { RootState } from 'src/modules/reducer'

import { Worksheet } from './entity'
import { State } from './redux'
import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.worksheet)

export const getList = createSelector(getState, state =>
  Object.values(state.byId),
)

export const getSingle = createSelector<
  RootState,
  string,
  string,
  State,
  Worksheet
>(
  (_state, props) => props,
  getState,
  (uid, state) => state.byId[uid],
)
