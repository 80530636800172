import { browseStickyHandler } from '../browseSticky/mock'
import { confirmWorksheetHandler } from '../confirmWorksheet/mock'
import { createFollowUpWorksheetsHandler } from '../createFollowUpWorksheets/mock'
import { createWorksheetFieldStickyHandler } from '../createWorksheetFieldSticky/mock'
import { deleteStickyHandler } from '../deleteSticky/mock'
import { deleteStickyMessageHandler } from '../deleteStickyMessage/mock'
import { disableWorksheetHandler } from '../disableWorksheet/mock'
import { editStickyMessageHandler } from '../editStickyMessage/mock'
import { getStickyHandler } from '../getSticky/mock'
import { getWorksheetHandler } from '../getWorksheet/mock'
import { replyStickyHandler } from '../replySticky/mock'
import { saveWorksheetHandler } from '../saveWorksheet/mock'
import { updateWorksheetNameHandler } from '../updateWorkhseetName/mock'

export const worksheetHandlers = [
  browseStickyHandler(),
  confirmWorksheetHandler(),
  createFollowUpWorksheetsHandler(),
  createWorksheetFieldStickyHandler(),
  deleteStickyHandler(),
  deleteStickyMessageHandler(),
  disableWorksheetHandler(),
  editStickyMessageHandler(),
  getStickyHandler(),
  getWorksheetHandler(),
  replyStickyHandler(),
  saveWorksheetHandler(),
  updateWorksheetNameHandler(),
]
