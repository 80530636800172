import { HttpResponse } from 'msw'
import {
  createExplanationRevisionWithdrawalHistoryMock,
  GetExplanationWithdrawHistoriesDocument,
  GetExplanationWithdrawHistoriesQuery,
  GetExplanationWithdrawHistoriesQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationWithdrawHistoriesHandler = graphQLHandlerFactory<
  GetExplanationWithdrawHistoriesQuery,
  GetExplanationWithdrawHistoriesQueryVariables
>('query', GetExplanationWithdrawHistoriesDocument, () => {
  return HttpResponse.json({
    data: {
      explanationRevision: {
        withdrawalHistories: [
          createExplanationRevisionWithdrawalHistoryMock(),
          createExplanationRevisionWithdrawalHistoryMock(),
          createExplanationRevisionWithdrawalHistoryMock(),
        ],
      },
    },
  })
})
