import React from 'react'

import { Box, HStack, Text } from '@chakra-ui/react'
import {
  Success,
  Info,
  Warning,
  Error,
} from 'src/components/__legacy__icon/monochrome'

import { blue, green, red, yellow } from '../color/palette'

type Props = {
  type: 'error' | 'success' | 'warning' | 'information'
  message?: string
  centered?: boolean
  noIcon?: boolean
  children?: React.ReactNode
  noWrap?: boolean
}

const assertUnreachable = (type: never) => {
  throw type
}
const statusIcon = (type: Props['type']) => {
  switch (type) {
    case 'error':
      return <Error size="S" color={red[50]} />
    case 'success':
      return <Success size="S" color={blue[70]} />
    case 'warning':
      return <Warning size="S" color={yellow[70]} />
    case 'information':
      return <Info size="S" color={green[40]} />
    default:
      return assertUnreachable(type)
  }
}
const getTextColor = (type: Props['type']) => {
  switch (type) {
    case 'error':
      return red[50]
    case 'success':
      return blue[70]
    case 'warning':
      return yellow[70]
    case 'information':
      return green[40]
    default:
      return assertUnreachable(type)
  }
}

export const Message: React.FC<Props> = props => {
  const { type, message, centered, noIcon, children, noWrap } = props

  return (
    <HStack
      align="center"
      justify={centered ? 'center' : 'flex-start'}
      wordBreak={noWrap ? 'keep-all' : 'break-word'}
      whiteSpace="pre-wrap"
    >
      <Box>{noIcon ? null : statusIcon(type)}</Box>

      <Text fontSize="sm" color={getTextColor(type)}>
        {children ?? message}
      </Text>
    </HStack>
  )
}
