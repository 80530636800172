import { useRef } from 'react'

import {
  HStack,
  PinInput as ChakraPinInput,
  PinInputField as ChakraPinInputField,
} from '@chakra-ui/react'
import { isWindows } from 'react-device-detect'

type Props = {
  /** sm(40px x 54px),  md(52px x 72px),  lg(60px x 84px) @default md */
  size?: 'sm' | 'md' | 'lg'
  length?: 4 | 6
  value: string | undefined
  isDisabled?: boolean
  otp?: boolean
  onChange: (value: string) => void
}

const fieldWidth: Record<Exclude<Props['size'], undefined>, string> = {
  sm: '40px',
  md: '52px',
  lg: '60px',
}
const fieldHeight: Record<Exclude<Props['size'], undefined>, string> = {
  sm: '54px',
  md: '72px',
  lg: '84px',
}
const fontSize: Record<Exclude<Props['size'], undefined>, string> = {
  sm: 'xl',
  md: '2xl',
  lg: '2xl',
}

const getIsComposing = (
  nativeEvent: React.ChangeEvent<HTMLInputElement>['nativeEvent'],
) => {
  return (
    'isComposing' in nativeEvent &&
    typeof nativeEvent.isComposing === 'boolean' &&
    nativeEvent.isComposing
  )
}

/** ChakraUIのPinInputをベースに全角入力対応などを行ったコンポーネント
 *
 * PIN設定やOTP認証などではこのコンポーネントを使用すること
 */
export const PinInput: React.FC<Props> = ({
  size = 'md',
  length = 4,
  value,
  isDisabled,
  otp,
  onChange,
}) => {
  const filedRefs = useRef<Record<number, HTMLInputElement>>({})

  const isConsecutive = useRef(false)
  const isComposing = useRef(false)
  // mobileの自動補完などで連続的にonChangeがcallされれstateを更新されると値が正しく反映されないため、最新の値はrefで保持する
  const latestValue = useRef<string>()

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    // windowsの全角モードでは一度の入力で2度onChangeが呼ばれてしまう問題がある。これにより「1」と入力しただけで「11」となってしまう問題が発生。
    // ユーザー操作（isTrusted=true）から100ms以内の連続入力を防止することで問題の発生を防ぐ。
    // mobile自動補完などでisTrusted=falseの場合は連続でcallされることを許容する。
    if (isConsecutive.current) return
    isConsecutive.current = e.nativeEvent.isTrusted && isWindows
    if (isConsecutive.current) {
      setTimeout(() => {
        isConsecutive.current = false
      }, 100)
    }

    // windowsにて、IME入力で文字を確定する前(isComposing=trueの状態)にvalueが空の状態でonChangeが呼ばれることがある。
    // これにより入力した値が意図せず消えてしまう問題が発生する。
    // この場合（composition確定前かつvalueがない場合）は値の更新を行わないようにする。
    if (isComposing.current && !e.target.value) {
      isComposing.current = getIsComposing(e.nativeEvent)
      return
    }
    isComposing.current = getIsComposing(e.nativeEvent)

    // 全角数字を半角数字に変換する
    // これにより全角モードで入力しても半角の値を正しく入力できる
    const fullWidthNumberRegex = /^[０-９]$/
    if (fullWidthNumberRegex.test(e.target.value)) {
      e.target.value = e.target.value.replace(
        fullWidthNumberRegex,
        String.fromCharCode(e.target.value.charCodeAt(0) - 0xfee0),
      )
    }

    const newValue = latestValue.current
      ? latestValue.current.slice(0, index) +
        e.target.value +
        latestValue.current.slice(index + 1)
      : e.target.value

    // 全角の変換などを経て、半角数字以外の場合は値の更新を行わない
    if (!/^\d*$/.test(newValue)) return

    // 最終桁を入力している場合最後に入力した値で最終桁を更新する
    const fixedValue =
      newValue.length > length
        ? newValue.slice(0, length - 1) + newValue[newValue.length - 1]
        : newValue
    latestValue.current = fixedValue
    onChange(fixedValue)
    const nextField = filedRefs.current[fixedValue.length]
    nextField?.focus()
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (!value) return
    if (e.key === 'Backspace' && (e.target as HTMLInputElement).value === '') {
      // indexの前のinputの値を削除し、focusを移す
      const newValue = value.slice(0, index - 1) + value.slice(index)
      latestValue.current = newValue
      onChange(newValue)
      const prevInput = filedRefs.current[index - 1]
      prevInput?.focus()
    }
  }

  const gap = length === 6 ? '12px' : '18px'

  return (
    <HStack justify="center" spacing={gap}>
      <ChakraPinInput
        // デフォルトのfocus管理を有効にすると、全角入力時にfocusがうまく動作しないため無効化。ほぼ同様のロジックを自前で定義。
        manageFocus={false}
        type="number"
        value={value}
        placeholder=""
        isDisabled={isDisabled}
        otp={otp}
      >
        {[...Array(length)].map((_, i) => (
          <ChakraPinInputField
            key={i}
            data-testid={`pin-input-${i}`}
            ref={el => el && (filedRefs.current[i] = el)}
            w={fieldWidth[size]}
            h={fieldHeight[size]}
            fontSize={fontSize[size]}
            inputMode="numeric"
            onKeyDown={e => handleKeyDown(e, i)}
            onChange={e => {
              handleInputChange(e, i)
            }}
          />
        ))}
      </ChakraPinInput>
    </HStack>
  )
}
