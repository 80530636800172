import {
  GeneratePatientVideoCallJwtTokenInput,
  GeneratePatientVideoCallTokenMutation,
  GeneratePatientVideoCallTokenDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

export type GeneratePatientVideoCallTokenReturn =
  GeneratePatientVideoCallTokenMutation['generatePatientVideoCallJwtToken']

export const generatePatientVideoCallToken = async (
  input: GeneratePatientVideoCallJwtTokenInput,
): Promise<GeneratePatientVideoCallTokenReturn> => {
  try {
    const res = await graphqlRequest(GeneratePatientVideoCallTokenDocument, {
      input,
    })
    return res.generatePatientVideoCallJwtToken
  } catch (error) {
    throw error
  }
}
