import React, { useState } from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import { getList } from 'src/modules/entities/member/selector'
import * as yup from 'yup'

type FormValues = {
  email: string
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .max(128, '128文字以内で入力してください')
    .email('正しい形式のメールアドレスを入力してください')
    .required('メールアドレスは必須です'),
})

type Props = {
  onSubmit: ({ email }: { email: string }) => void
  onClose: () => void
  selectedTrial: SelectedTrial
  requesting: boolean
  errorMessage: string
}

export const LookupUserForm: React.FC<Props> = props => {
  const { selectedTrial, requesting, errorMessage } = props
  const [existsMember, setExistsMember] = useState<boolean>(false)

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  })
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = methods

  const existingsMembers = useSelector(getList)

  const onClose = () => {
    if (requesting) return

    props.onClose()
  }

  const onSubmit = async (values: FormValues) => {
    if (requesting) {
      return
    }

    const { email } = getValues()

    const exists = existingsMembers.some(member => {
      return member.email === email
    })
    if (exists) {
      setExistsMember(true)
      return
    }

    try {
      props.onSubmit({ email: getValues().email })
    } catch (error) {
      console.warn(error)
    }
  }

  const emailField = register('email', { required: true })

  return (
    <div>
      <ModalTitle title={selectedTrial.name} />
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <ModalContent>
          <Text textAlign="center">
            追加するアカウントのメールアドレスを入力
          </Text>
          <Spacer size={20} />
          <Input
            type="email"
            {...emailField}
            onChange={e => {
              emailField.onChange(e)
              setExistsMember(false)
            }}
          ></Input>
          {!!errors.email?.message && (
            <div>
              <Spacer size={4} />
              <Message type="error" message={errors.email.message} />
            </div>
          )}
          {!errors.email && existsMember && (
            <div>
              <Spacer size={4} />
              <Message
                type="error"
                message="既にこの試験にアカウントが登録されています"
              />
            </div>
          )}
        </ModalContent>

        <ModalActions>
          <Button
            size="S"
            text="キャンセル"
            buttonType="cancel"
            onClick={onClose}
          ></Button>
          <Spacer size={40} horizontal />
          <Button
            size="S"
            text="次へ"
            onClick={() => handleSubmit(onSubmit)}
            buttonType="important"
            disabled={!watch('email') || requesting}
          ></Button>
        </ModalActions>

        {errorMessage && (
          <Message type="error" message={errorMessage} centered />
        )}
      </form>
    </div>
  )
}
