import React, { useCallback } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type Props = {
  requesting: boolean
  templateFolderName: string
  onClose: () => void
  onSubmit: () => void
}

export const DeleteModal: React.FC<Props> = props => {
  const onSubmit = useCallback(() => {
    props.onSubmit()
  }, [props])

  return (
    <Modal onClose={props.onClose} isOpen>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>フォルダを削除</ModalHeader>

        <ModalBody>
          <Text>
            <Text as="span" fontWeight="bold">
              {props.templateFolderName}
            </Text>{' '}
            を削除します。
          </Text>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            colorScheme="red"
            onClick={onSubmit}
            isDisabled={props.requesting}
          >
            削除する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
