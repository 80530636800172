import { RadioItem } from '../../../../../../../../components/form-redesigned/radio'
import { DataType } from '../../../../../../../entities/small-item/entity'

const dataTypeOptionLabel: (dataType: DataType) => string = dataType => {
  switch (dataType) {
    case DataType.Clinical:
      return '有り'
    case DataType.Operational:
      return '無し'
  }
}

export const dataTypeRadioItems: RadioItem[] = [
  {
    value: DataType.Clinical,
    name: dataTypeOptionLabel(DataType.Clinical),
  },
  {
    value: DataType.Operational,
    name: dataTypeOptionLabel(DataType.Operational),
  },
]
