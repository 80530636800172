import { useMemo } from 'react'

import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import {
  Progress,
  ProgressComplete,
  ProgressNotStarted,
  Reject,
} from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { ExplanationRevisionStatusV2 } from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'

type Props = {
  status: ExplanationRevisionStatusV2
  // 署名者が設定完了しているか、という情報はステータスから取得できない
  isSignerSet: boolean
}

const getStep = (
  status: ExplanationRevisionStatusV2,
  isSignerSet: boolean,
): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  switch (status) {
    case 'Invalid':
    case 'Disabled':
      return 0
    case 'New':
    case 'SessionStarted':
      return isSignerSet ? 3 : 1
    case 'SignReady':
      return isSignerSet ? 3 : 2
    case 'SignStarted':
    case 'AgreementRejected':
      return 3
    case 'SignCompleted':
      return 4
    case 'AgreementCompleted':
      return 5
    case 'DeliveryCompleted':
    case 'Withdrawn':
      return 6
    default:
      return assertNever(status)
  }
}

export const ExplanationRoomProgressBar: React.FC<Props> = ({
  status,
  isSignerSet,
}) => {
  const step = useMemo(
    () => getStep(status, isSignerSet),
    [status, isSignerSet],
  )

  const isRejected = status === 'AgreementRejected'

  const getProgressIcon = (currentStep: number, thisStep: number) => {
    if (thisStep === 3 && isRejected)
      return (
        <Box bg="white">
          <Reject color={colors.red['400']} size="20px" />
        </Box>
      )
    if (thisStep === currentStep) return <Progress size="lg" />
    if (thisStep < currentStep) return <ProgressComplete size="lg" />
    return <ProgressNotStarted size="lg" />
  }

  // TODO: fix style
  return (
    <Box h="50px" as="section" aria-label="progress bar">
      <Box position="relative" minWidth="380px">
        <Box
          position="absolute"
          height="4px"
          bg="gray.400"
          width="320px"
          top="10px"
          left="24px"
        />
        <Box
          position="absolute"
          top="10px"
          left="24px"
          height="4px"
          bg="green.500"
          width={`${(step > 5 ? 4 : step - 1) * 80}px`}
        />
        <HStack spacing="30px" position="absolute" top="0">
          <VStack>
            {getProgressIcon(step, 1)}
            <Text fontSize="sm" fontWeight="bold" color="gray.600">
              説明実施
            </Text>
          </VStack>
          <VStack>
            {getProgressIcon(step, 2)}
            <Text fontSize="sm" fontWeight="bold" color="gray.600">
              署名準備
            </Text>
          </VStack>
          <VStack>
            {getProgressIcon(step, 3)}
            <Text fontSize="sm" fontWeight="bold" color="gray.600">
              署名
            </Text>
          </VStack>
          <VStack>
            {getProgressIcon(step, 4)}
            <Text fontSize="sm" fontWeight="bold" color="gray.600">
              同意完了
            </Text>
          </VStack>
          <VStack>
            {getProgressIcon(step, 5)}
            <Text fontSize="sm" fontWeight="bold" color="gray.600">
              文書交付
            </Text>
          </VStack>
        </HStack>
      </Box>
    </Box>
  )
}
