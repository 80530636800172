import {
  ErrValues,
  ErrValuesDetail,
  Schema,
  validateValues,
} from '@micin-jp/chicken-schema'
import equal from 'fast-deep-equal'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'

import { CriteriaValue } from '../types'

export const schemaAtom = atom<Schema>({
  fields: [],
  name: '',
  schemaVersion: '',
})

export const fetchedCriteriaValuesAtom = atom<CriteriaValue[]>([])

export const editingCriteriaValuesAtom = atom<CriteriaValue[]>([])

export const isReEditModeAtom = atom(false)

type CriteriaValueErrors =
  | {
      validated: false
    }
  | {
      validated: true
      errors?: Record<string, ErrValuesDetail[]>
    }

export const criteriaValueErrorsAtom = atom<CriteriaValueErrors>({
  validated: false,
})

const groupErrValues = (err: ErrValues): Record<string, ErrValuesDetail[]> => {
  const res: Record<string, ErrValuesDetail[]> = {}
  for (const error of err.details) {
    if (!res[error.fid]) {
      res[error.fid] = []
    }
    res[error.fid].push(error)
  }
  return res
}

type CriteriaValueFamilyParam = {
  fid: string
  fieldIndex: number
}

export const criteriaValueFamily = atomFamily(
  (param: CriteriaValueFamilyParam) =>
    atom(
      get => {
        const criteriaValue = get(editingCriteriaValuesAtom).find(
          v => v.fid === param.fid && v.fieldIndex === param.fieldIndex,
        )
        return criteriaValue?.value
      },
      async (get, set, update: any, isFile?: boolean) => {
        const editing = get(editingCriteriaValuesAtom)
        const index = editing.findIndex(
          v => v.fid === param.fid && v.fieldIndex === param.fieldIndex,
        )
        if (index === -1) {
          set(editingCriteriaValuesAtom, [
            ...editing,
            {
              isFile,
              fid: param.fid,
              fieldIndex: param.fieldIndex,
              value: update,
            },
          ])
        } else {
          set(
            editingCriteriaValuesAtom,
            editing
              .map((v, i) =>
                i === index
                  ? {
                      isFile,
                      fid: param.fid,
                      fieldIndex: param.fieldIndex,
                      value: update,
                    }
                  : v,
              )
              .filter(v => v.value !== undefined), // 値がundefinedのものは削除
          )
        }
        // 必須バリデーションのみ、errorがある（一度でもバリデーションが実行されている）場合はフィールドの変更ごとに再バリデーションを行う
        const valErrors = get(criteriaValueErrorsAtom)
        if (
          valErrors.validated &&
          Object.keys(valErrors?.errors ?? {}).length > 0
        ) {
          const res = await validateValues(
            get(schemaAtom),
            get(editingCriteriaValuesAtom),
          )
          if (!res.ok) {
            set(criteriaValueErrorsAtom, {
              validated: true,
              errors: groupErrValues(res.error),
            })
          } else {
            set(criteriaValueErrorsAtom, {
              validated: true,
            })
          }
        }
      },
    ),
  equal,
)

export const isCriteriaValuesChangedAtom = atom(get => {
  const editing = get(editingCriteriaValuesAtom)
  const fetched = get(fetchedCriteriaValuesAtom)
  if (editing.length !== fetched.length) {
    return true
  }
  const sortFunc = (a: CriteriaValue, b: CriteriaValue) => {
    if (a.fid !== b.fid) {
      return a.fid < b.fid ? -1 : 1
    }
    return a.fieldIndex - b.fieldIndex
  }
  return !equal(editing.sort(sortFunc), fetched.sort(sortFunc))
})

export const criteriaValueErrorsFamily = atomFamily((fid: string) =>
  atom(get => {
    const valErrors = get(criteriaValueErrorsAtom)
    if (valErrors.validated && !!valErrors.errors) {
      return valErrors.errors[fid] ?? []
    }
    return []
  }),
)

// write only
export const validateAtom = atom(
  null,
  async (
    get,
    set,
  ): Promise<{
    allOk: boolean
    requiredValidationOk: boolean
    userValidationOk: boolean
  }> => {
    const res = await validateValues(
      get(schemaAtom),
      get(editingCriteriaValuesAtom),
      true,
    )
    if (!res.ok) {
      set(criteriaValueErrorsAtom, {
        validated: true,
        errors: groupErrValues(res.error as ErrValues),
      })
      return {
        allOk: res.ok,
        requiredValidationOk: !res.error.details.some(
          d => d.validationType === 'Required',
        ),
        userValidationOk: !res.error.details.some(
          d => d.validationType === 'User',
        ),
      }
    } else {
      set(criteriaValueErrorsAtom, {
        validated: true,
      })
      return {
        allOk: true,
        requiredValidationOk: true,
        userValidationOk: true,
      }
    }
  },
)
