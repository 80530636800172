import React from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { ReleaseModalContainer } from './components/modal-container'

type Props = {
  disabled: boolean
}
export const ReleaseButton: React.FC<Props> = ({ disabled }) => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <>
      <Button
        text={'配信開始'}
        size="S"
        buttonType="important"
        onClick={openModal}
        disabled={disabled}
      />
      {modalOpen && <ReleaseModalContainer closeModal={closeModal} />}
    </>
  )
}
