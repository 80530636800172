import React from 'react'

import { RevisionHistory } from './revision-history'
import { useRevisionHistory } from './revision-history.hooks'
import { useDocuSignPreview } from '../../use-docusign-preview'

export const RevisionHistoryContainer: React.FC = () => {
  const {
    icfDocument,
    selectedRevision,
    selectedRevisionUid,
    onChangeSelectedRevisionUid,
    filePreviewUrl,
  } = useRevisionHistory()

  const { redirect: redirectDocuSignPreview } = useDocuSignPreview()

  return (
    <RevisionHistory
      icfDocument={icfDocument}
      selectedRevisionUid={selectedRevisionUid}
      selectedRevision={selectedRevision}
      onChangeSelectedRevisionUid={onChangeSelectedRevisionUid}
      filePreviewUrl={filePreviewUrl}
      onRedirectDocuSignPreview={redirectDocuSignPreview}
    />
  )
}
