import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  UpdatePreSessionIdentificationDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const updatePreSessionIdentification = async ({
  explanationPreSessionUid,
  isIdentified,
}: {
  explanationPreSessionUid: string
  isIdentified: boolean
}) => {
  try {
    await graphqlRequest(UpdatePreSessionIdentificationDocument, {
      input: {
        explanationPreSessionUid,
        isIdentified,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useUpdatePreSessionIdentification = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationPreSessionUid,
      isIdentified,
    }: {
      explanationPreSessionUid: string
      isIdentified: boolean
    }) =>
      updatePreSessionIdentification({
        explanationPreSessionUid,
        isIdentified,
      }),
    option,
  )
}
