import { useEffect, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { actions } from './redux'
import { validateMediumItems } from './validate'
import { MediumItem } from '../../../../../../entities/medium-item/entity'
import { Template } from '../../../../../../entities/template/entity'

export const useEditTemplate = ({
  template,
  mediumItems,
}: {
  template: Template
  mediumItems: MediumItem[]
}) => {
  const [releaseTried, setReleaseTried] = useState(false)

  const dispatch = useDispatch()

  const selectedTrial = useSelector(getSelectedTrial)!
  const v2 = selectedTrial.featureFlags.eSourceV2

  useEffect(() => {
    if (!template) return

    dispatch(actions.initWithTemplate({ template }))
    setReleaseTried(false)
  }, [dispatch, template])

  const toast = useMirohaToast()

  const handlers = useMemo(
    () => ({
      onValidate: () => {
        setReleaseTried(true)
        return validateMediumItems(mediumItems, v2)
      },
      onAdd: () => dispatch(actions.addMediumItem({})),
      onSubmit: async () => {
        setReleaseTried(false)
        try {
          dispatch(actions.submitUpdate(null))
          toast({
            status: 'success',
            title: '一時保存しました。',
          })
        } catch (error) {
          /* empty */
        }
      },
      onSubmitOrder: async () => {
        if (!v2) return
        try {
          dispatch(actions.submitUpdateOrder(null))
          toast({
            status: 'success',
            title: '順番を保存しました。',
          })
        } catch (error) {
          toast({
            status: 'error',
            title: error.message,
          })
        }
      },
      onChangeTitle: (title: string) =>
        dispatch(actions.updateTitle({ title })),
      onChangeVideoEnabled: (videoEnabled: boolean) =>
        dispatch(actions.updateVideoEnabled({ videoEnabled })),
    }),
    [dispatch, mediumItems, v2, toast],
  )

  return {
    releaseTried,
    handlers,
  }
}
