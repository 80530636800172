import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

import { parse, VideoCallRoomImageResponse } from './parse'
import { VideoCallRoomImage } from '../entity'

export const getVideoCallRoomImages = async ({
  worksheetUid,
}: {
  worksheetUid: string
}): Promise<VideoCallRoomImage[]> => {
  const res = await requestGet<VideoCallRoomImageResponse[]>({
    url: `${SERVER_URL}/worksheets/${worksheetUid}/video_call_room_images`,
  })

  return res.data.map(parse)
}
