import { Member } from 'src/features/member/types'

import { ExplanationSignerRole } from '../types'

export const memberRoleToSignerRole = (
  role: Member['role'],
): ExplanationSignerRole => {
  switch (role) {
    case 'Dr':
      return 'Dr'
    case 'CRC':
      return 'CRC'
    default:
      throw new Error(`${role} is invalid role as signer`)
  }
}
