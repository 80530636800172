import { useCallback } from 'react'

import {
  Badge,
  Box,
  Center,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useLocalPlayer } from '@micin-jp/call-lib-react'
import { CameraOff, CameraOn, MicOff, MicOn } from 'src/components/icon'

import { useVolumeMeter } from '../../hooks/useVolumeMeter'

export const VideoCallRoomLocalPlayer: React.FC = () => {
  const {
    attachLocalVideo,
    localCameraEnabled,
    localMicEnabled,
    localAudioTrack: audioTrack,
  } = useLocalPlayer()

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachLocalVideo(elm, { fit: 'contain' }),
    [attachLocalVideo],
  )

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  const movieIconSize = useBreakpointValue({ base: '32px', lg: '64px' })

  return (
    <Box w="full" h="full">
      <Box
        pos="absolute"
        zIndex="1"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      <Box h="full" w="full" bg="gray.700" pos="relative" borderRadius="sm">
        {localCameraEnabled ? (
          <Flex
            ref={videoRef}
            direction="column"
            justify="flex-end"
            w="full"
            h="full"
            transform="scaleX(-1)"
          />
        ) : (
          <Center flexDirection="column" color="white" w="full" h="full">
            {localCameraEnabled ? (
              <CameraOn size={movieIconSize} />
            ) : (
              <CameraOff size={movieIconSize} />
            )}
          </Center>
        )}
        <Badge
          pos="absolute"
          left="8px"
          bottom="8px"
          px="4"
          bg="gray.500"
          color="white"
          fontWeight="bold"
          fontSize="md"
        >
          <HStack>
            <Text>あなた</Text>
            {localMicEnabled ? <MicOn /> : <MicOff />}
          </HStack>
        </Badge>
      </Box>
    </Box>
  )
}
