import { useState } from 'react'

import { Stack, Text } from '@chakra-ui/react'
import { useRoomSession } from '@micin-jp/call-lib-react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  useAuthenticatedAccount,
  useCurrentMember,
} from 'src/features/auth/context'
import { useExplanation } from 'src/features/explanation/api'
import { usePreSession } from 'src/features/explanation/hooks/usePreSession'
import { getRoomFinishedRoute } from 'src/features/explanation/utils/getRoomFinishedRoute'
import { hasAlreadyPinSet } from 'src/features/explanation/utils/hasAlreadyPinSet'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { Information } from 'src/modules/dashboard/trial/detail/e-consent/components/information'
import { getNotFoundRoute } from 'src/modules/dashboard/trial/detail/e-consent/explanation-room/routes'

import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useRoomMediaContext } from '../../context/MediaProvider'
import { ExplanationRoomLobbyVideoCallContent } from '../ExplanationRoomLobbyVideoCallContent/ExplanationRoomLobbyVideoCallContent'
import { HospitalLobbyLayout } from '../HospitalLobbyLayout/HospitalLobbyLayout'

type Param = {
  explanationRevisionUid: string
}

export const HospitalLobbyScreen: React.FC = () => {
  const { explanationRevisionUid = '' } = useParams<Param>()

  const navigate = useNavigate()

  const { currentMember } = useCurrentMember()
  const {
    account: { selectedTrial, member },
  } = useAuthenticatedAccount()

  const { leaveRoom } = useRoomSession()

  const { initializeMemberVideoCall } = useRoomMediaContext()

  const toast = useMirohaToast()

  const [hasPinSetByDefault, setHasPinSetByDefault] = useState(false)
  const [pinSetupNoticeDone, setPinSetupNoticeDone] = useState(false)

  const {
    preSession,
    setupPreSessionError,
    mutate: mutatePreSession,
  } = usePreSession({
    isPatient: false,
    explanationRevisionUid,
    onSetup: preSession => {
      // revisionが無効な場合はNotFoundへ遷移
      if (preSession.explanationRevision.latestHistory.status === 'Disabled') {
        navigate(getNotFoundRoute({ type: 'hospital' }))
        return
      }
      if (!!preSession.videoCallRoomUid) {
        initializeMemberVideoCall({
          roomUid: preSession.videoCallRoomUid,
          member: currentMember,
        })
      }
      if (selectedTrial.featureFlags.eConsentNewSignFlow) {
        const pinSettingStatus =
          preSession.explanationRevision.latestPinSettingHistory?.status
        if (!!pinSettingStatus && hasAlreadyPinSet(pinSettingStatus)) {
          setHasPinSetByDefault(true)
          return
        }
        toast({
          status: 'info',
          title: '署名実施時には患者の当人認証設定が必要です',
          description:
            '「当人認証設定」ボタンより患者の当人認証情報の事前設定が可能です。',
        })
      }
    },
    onAdditionalFetch: preSession => {
      if (hasPinSetByDefault || pinSetupNoticeDone) return
      const pinSettingStatus =
        preSession.explanationRevision.latestPinSettingHistory?.status
      if (pinSettingStatus === 'Completed') {
        toast({
          status: 'success',
          title: '患者の暗証番号の設定が完了しました',
        })
        setPinSetupNoticeDone(true)
      }
    },
  })

  const { data: explanation } = useExplanation({
    explanationUid: preSession
      ? preSession.explanationRevision.explanationUid
      : null,
    refreshInterval: 10000,
  })

  if (!!setupPreSessionError) {
    if (setupPreSessionError.status === 403) {
      return (
        <Stack
          w="full"
          h="full"
          bgColor="#f8fbfa"
          align="center"
          justify="center"
          spacing="16px"
        >
          <Text>説明を開始できないユーザーです。</Text>
          <Text>
            説明を開始する操作ユーザーは説明情報の担当者に登録されている必要があります。
          </Text>
        </Stack>
      )
    }
    return <Information body={setupPreSessionError.message} />
  }

  if (!preSession || !explanation) return null

  return (
    <ExplanationRoomEventProvider
      roomType="PreSession"
      preSessionUid={preSession.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={preSession.explanationRevision.uid}
      explanationPatientUid={explanation.patient.uid}
      patientPhoneNumber={explanation.patient.phoneNumber}
      deviceActorType="Member"
      deviceActorUid={member.uid}
      deviceActorTrialHospitalUid={explanation.trialHospital.uid}
    >
      <HospitalLobbyLayout
        preSession={preSession}
        mutatePreSession={mutatePreSession}
        explanation={explanation}
        onFinish={async () => {
          leaveRoom().then()
          navigate(
            getRoomFinishedRoute({
              isLobby: true,
              isPartnerMember: false,
              type: preSession.explanationRevision.explanationType,
            }),
          )
        }}
      >
        <ExplanationRoomLobbyVideoCallContent
          isSessionStarted={!!preSession.explanationSessionUid}
          hospitalName={explanation.trialHospital.name}
        />
      </HospitalLobbyLayout>
    </ExplanationRoomEventProvider>
  )
}
