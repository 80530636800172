import { assertNever } from '../../../utils/assertNever'
import { CheckedFieldValue } from '../types'

export const formatFieldValue = (
  value: CheckedFieldValue,
): CheckedFieldValue => {
  const typ = value.type
  switch (typ) {
    case 'text':
      break
    case 'textarea':
      break
    case 'number':
      return {
        ...value,
        numberValue: value.numberValue
          .replace(/^-0$/, '0')
          .replace(/^(?!0$)(-?)(0+)(?!\.)/, '$1'),
      }
    case 'date':
      const dateSplit = value.dateValue.split('-')
      if (dateSplit.length !== 3) {
        break
      }
      const year = dateSplit[0].padStart(4, '0')
      const month = dateSplit[1].padStart(2, '0')
      const day = dateSplit[2].padStart(2, '0')
      return {
        ...value,
        dateValue: `${year}-${month}-${day}`,
      }
    case 'nullableDate':
      const nDateSplit = value.nullableDateValue.split('-')
      if (nDateSplit.length !== 3) {
        break
      }
      const nYear = nDateSplit[0] === '' ? '' : nDateSplit[0].padStart(4, '0')
      const nMonth = nDateSplit[1] === '' ? '' : nDateSplit[1].padStart(2, '0')
      const nDay = nDateSplit[2] === '' ? '' : nDateSplit[2].padStart(2, '0')
      if (!nMonth) {
        return {
          ...value,
          nullableDateValue: `${nYear}`,
        }
      }
      if (!nDay) {
        return {
          ...value,
          nullableDateValue: `${nYear}-${nMonth}`,
        }
      }
      return {
        ...value,
        nullableDateValue: `${nYear}-${nMonth}-${nDay}`,
      }
    case 'time':
      const timeSplit = value.timeValue.split(':')
      if (timeSplit.length !== 2) {
        break
      }
      const hh = timeSplit[0].padStart(2, '0')
      const mi = timeSplit[1].padStart(2, '0')
      return {
        ...value,
        timeValue: `${hh}:${mi}`,
      }
    case 'checkbox':
    case 'radio':
    case 'selectMenu':
    case 'repeatableSection':
    case 'cleared':
    case 'file':
      break
    default:
      assertNever(typ)
  }
  return value
}
