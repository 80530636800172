import { createContext, useCallback, useContext, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import {
  DocForExplanationDetail,
  DocSetForExplanationDetail,
  ExplanationDetail,
  ExplanationDocRevision,
  ExplanationDocSet,
  explanationSignStatus,
  ExpRevDocSetDocItem,
} from '../explanation/entity'
import { fetchExplanation } from '../explanation/request'

// NOTE:
// ここでは以下のような機能を提供する
// * 説明と文書管理用文書セットをfetchして説明ルームで求められる型を満たすオブジェクトを生成
// * 文書リビジョン詳細を取得する関数を定義（詳細用に別途fetchは行わない）
const usePreviewValueCore = (explanationUid: string) => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!

  const { data: explanation } = useSWR(
    swrKeys.fetchExplanationDetail({ trialUid, explanationUid }),
    () => fetchExplanation({ trialUid, explanationUid }),
  )

  const docSets: ExplanationDocSet[] = useMemo(() => {
    if (!explanation) {
      return []
    }

    return explanation.docSets.map(toRoomDocSet)
  }, [explanation])

  const getDocRevision = useCallback(
    (docRevUid: string): ExplanationDocRevision | undefined => {
      if (!explanation) return undefined

      const expDoc = explanation.docSets
        .flatMap(expDocSet => expDocSet.docs)
        .find(expDoc => expDoc.icfDocumentRevisionUid === docRevUid)

      if (!expDoc) {
        return undefined
      }

      return {
        uid: expDoc.icfDocumentRevisionUid,
        icfDocRevisionUid: expDoc.icfDocumentRevisionUid,
        docNumberingId: expDoc.docNumberingId,
        docName: expDoc.docName,
        docType: expDoc.docType,
        docIsRequiredPatientSign: false,
        patientSignStatus: explanationSignStatus.NotDone,
        fileUrl: expDoc.fileUrl,
        isCompleted: false,
        hasDSEnvelope: false,
        allPlacedSignerRoles: [],
        completedSignerRoles: [],
      }
    },
    [explanation],
  )

  return {
    explanation,
    docSets,
    getDocRevision,
  }
}

const toRoomDocSet = (
  expDocSet: DocSetForExplanationDetail,
): ExplanationDocSet => {
  return {
    uid: expDocSet.uid,
    icfDocumentSetUid: expDocSet.uid,
    docSetNumberingId: expDocSet.numberingId,
    docSetName: expDocSet.name,
    docs: expDocSet.docs.map(toRoomDoc),
  }
}
const toRoomDoc = (doc: DocForExplanationDetail): ExpRevDocSetDocItem => {
  return {
    //NOTE: セッション開始前は説明文書リビジョンUIDがないため、文書リビジョンUIDをUIDをとしてデータを保持する
    docRevisionUid: doc.icfDocumentRevisionUid,
    icfDocRevisionUid: doc.icfDocumentRevisionUid,
    index: doc.index,
    docNumberingId: doc.docNumberingId,
    docName: doc.docName,
    docVersion: doc.docVersion,
    docType: doc.docType,
    isCompleted: false,
    hasDSEnvelope: false,
    signerRoles: [],
    browsingHistories: [],
    signHistories: [],
    isRequiredPatientSign: doc.docIsRequiredPatientSign,
  }
}

type Context = {
  explanation: ExplanationDetail | undefined
  docSets: ExplanationDocSet[]
  getDocRevision: (docRevUid: string) => ExplanationDocRevision | undefined
}

const PreviewContext = createContext<Context>({} as Context)

type Props = {
  explanationUid: string
  children: React.ReactNode
}

export const PreviewContextProvider: React.FC<Props> = ({
  explanationUid,
  children,
}) => {
  const { explanation, docSets, getDocRevision } =
    usePreviewValueCore(explanationUid)

  return (
    <PreviewContext.Provider value={{ explanation, docSets, getDocRevision }}>
      {children}
    </PreviewContext.Provider>
  )
}

export const usePreviewExplanation = () => {
  const { explanation } = useContext(PreviewContext)

  return { explanation }
}

export const usePreviewDocSets = () => {
  const { docSets } = useContext(PreviewContext)

  return { docSets }
}

// docRevisionUidを引数にとってdocRevisionを返す
export const usePreviewDocRevision = (docRevisionUid: string) => {
  const { getDocRevision } = useContext(PreviewContext)

  const docRevision = getDocRevision(docRevisionUid)

  return { docRevision }
}
