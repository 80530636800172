import React, { useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { VisibilitySetModalComponent } from './visibility-set-modal-component'
import { getTrialHospitalsByUid } from '../../../../../../../../entities/account/selector'
import { RootState } from '../../../../../../../../reducer'
import { getState } from '../../selector'
import { useUpdateSmallItemVisibility } from '../use-update-small-item-visibility'

const useOnChangeMultiselect = (initialTrialHospitalUids: string[]) => {
  const [selectedTrialHospitalUids, setTrialHospitalUids] = useState<string[]>(
    initialTrialHospitalUids,
  )

  const handlers = useMemo(
    () => ({
      onChangeMultiselect: (ids: string[]) => {
        setTrialHospitalUids(ids)
      },
    }),
    [setTrialHospitalUids],
  )

  return { selectedTrialHospitalUids, handlers }
}

type Props = {
  smallItemUid: string
  onClose: () => void
}

export const VisibilitySetModalContainer: React.FC<Props> = props => {
  const { smallItemUid, onClose } = props

  const template = useSelector((state: RootState) => getState(state))

  const { originalTemplate } = template

  const mediumItem = template.mediumItems.find(mi => {
    return !!mi.smallItemMap[smallItemUid]
  })!

  const smallItem = mediumItem.smallItemMap[smallItemUid]

  const trialHospitalsByUid = useSelector(getTrialHospitalsByUid)
  const { request, requesting, errorMessage } = useUpdateSmallItemVisibility()

  const {
    selectedTrialHospitalUids,
    handlers: { onChangeMultiselect },
  } = useOnChangeMultiselect(smallItem.viewableHospitalUids)

  const onSubmit = async () => {
    if (!originalTemplate) return

    try {
      await request({
        trialUid: originalTemplate.trialUid,
        templateUid: originalTemplate.uid,
        smallItemUids: [smallItem.uid],
        trialHospitalUids: selectedTrialHospitalUids,
      })

      onClose()
    } catch (error) {
      console.error('update smallItemVisibility Error', error)
    }
  }

  const onDelete = async () => {
    if (!originalTemplate) return

    try {
      await request({
        trialUid: originalTemplate.trialUid,
        templateUid: originalTemplate.uid,
        smallItemUids: [smallItem.uid],
        trialHospitalUids: [], // Pass empty array
      })

      onClose()
    } catch (error) {
      console.error('delete smallItemVisibility Error', error)
    }
  }

  const canDelete = useMemo(() => {
    if (!originalTemplate) {
      return false
    }

    const originalMediumItem = originalTemplate.mediumItems.find(
      mi => mi.smallItemMap[smallItemUid],
    )
    if (!originalMediumItem) {
      return false
    }

    const originalSmallItem = originalMediumItem.smallItemMap[smallItemUid]

    return originalSmallItem.viewableHospitalUids.length > 0
  }, [originalTemplate, smallItemUid])

  return (
    <VisibilitySetModalComponent
      mediumItemTitle={mediumItem.title}
      smallItem={smallItem}
      trialHospitalsByUid={trialHospitalsByUid}
      selectedTrialHospitalUids={selectedTrialHospitalUids}
      canDelete={canDelete}
      requesting={requesting}
      errorMessage={errorMessage}
      handlers={{
        onChangeMultiselect: onChangeMultiselect,
        onClose: onClose,
        onSubmit: onSubmit,
        onDelete: onDelete,
      }}
    ></VisibilitySetModalComponent>
  )
}
