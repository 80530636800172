import styled, { css } from 'styled-components'
import { width, WidthProps } from 'styled-system'

import { gray, red, white, yellow } from '../base/color/palette'

type TextareaProps = {
  showError?: boolean
  warning?: boolean
}

const LINE_HEIGHT = 20

export const Textarea = styled.textarea<TextareaProps & WidthProps>`
  font-size: 14px;

  background-color: ${white};

  padding: 5px 16px;
  border: 1px solid ${gray[40]};
  border-radius: 5px;
  box-sizing: border-box;

  line-height: ${LINE_HEIGHT}px;
  height: ${LINE_HEIGHT * 3}px; // 3行分表示する

  width: 100%;
  ${width}

  resize: none;
  overflow-y: auto;
  &:focus {
    outline: none;
  }

  ${props =>
    props.showError &&
    css`
      background: ${red[10]};
    `}

  // FIXME: required + :invalid などで制御したい
  ${props =>
    props.warning &&
    css`
      background: ${yellow[10]};
    `}

    &[disabled] {
    opacity: 1; // for ios/iPad safari
    -webkit-text-fill-color: ${gray[100]};
    color: ${gray[100]};
    border-color: ${gray[55]};

    &:hover {
      cursor: not-allowed;
    }
    ::placeholder {
      color: ${gray[55]};
    }
  }

  &[disabled] {
    opacity: 1; // for ios/iPad safari
    -webkit-text-fill-color: ${gray[100]};
    color: ${gray[100]};
    background-color: ${gray[10]};
    border-color: ${gray[55]};
    ::placeholder {
      -webkit-text-fill-color: ${gray[55]};
      color: ${gray[55]};
    }
  }
`
