import { useCallback, useMemo, useState } from 'react'

import { Field } from '@micin-jp/chicken-schema'
import { MentionItem } from 'src/components/MentionTextarea/MentionTextarea'
import {
  GetTrialMembersReturn,
  useTrialMembers,
} from 'src/features/member/api/getTrialMembers'
import { useModal } from 'src/hooks/use-modal'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { StickyPriority } from 'src/lib/gql-client'
import { getFullName } from 'src/utils/getFullName'
import { isNotNullish } from 'src/utils/isNotNullish'

import { useStickyEditingAlert } from './useStickyEditingAlert'
import { useStickyQuery } from './useStickyQuery'
import { useBrowseSticky } from '../api/browseSticky'
import { useCreateWorksheetFieldSticky } from '../api/createWorksheetFieldSticky'
import { useDeleteSticky } from '../api/deleteSticky'
import { useDeleteStickyMessage } from '../api/deleteStickyMessage'
import { useEditStickyMessage } from '../api/editStickyMessage'
import { useSticky } from '../api/getSticky'
import { useReplySticky } from '../api/replySticky'
import { useWorksheetFieldStickiesContext } from '../context/WorksheetFieldStickies'
import { useWorksheetValueContext } from '../context/WorksheetValue'
import { findSection } from '../utils/findSection'
import { getFlattenFieldMap } from '../utils/getFlattenFields'

type MessageParam = {
  message: string
  priority: StickyPriority
}

type EditMessageParam = {
  messageUid: string
  message: string
  priority: StickyPriority
  onSuccess: () => void
}

type MemberMap = Map<string, GetTrialMembersReturn[number]>

export const useStickyControl = ({
  filed: field,
  fieldIndex,
}: {
  filed: Field
  fieldIndex: number
}) => {
  const { fetchedWorksheet, patient, findFieldValue } =
    useWorksheetValueContext()

  const {
    isEditing,
    toggleEditing,
    isPopoverOpenForNew,
    onChangePopoverOpenForNew,
    find,
    mutateCache: mutateFieldStickyCache,
  } = useWorksheetFieldStickiesContext()

  const { hasSticky, stickyUid } = find({ fid: field.fid, fieldIndex })

  const { stickyQuery, setStickyQuery, resetQuery } = useStickyQuery()

  const isPopoverOpen = useMemo(() => {
    if (!!stickyUid) {
      return stickyQuery === stickyUid
    }
    return isPopoverOpenForNew(field.fid, fieldIndex)
  }, [stickyUid, stickyQuery, isPopoverOpenForNew, field.fid, fieldIndex])

  const onOpen = useCallback(() => {
    if (!!stickyUid) {
      onChangePopoverOpenForNew(null)
      setStickyQuery(stickyUid)
      return
    }
    if (!!stickyQuery) {
      resetQuery()
    }
    onChangePopoverOpenForNew({ fid: field.fid, index: fieldIndex })
  }, [
    stickyUid,
    setStickyQuery,
    stickyQuery,
    resetQuery,
    field.fid,
    fieldIndex,
    onChangePopoverOpenForNew,
  ])

  const onClose = useCallback(() => {
    if (hasSticky) {
      resetQuery()
      return
    }
    onChangePopoverOpenForNew(null)
  }, [hasSticky, resetQuery, onChangePopoverOpenForNew])

  const toast = useMirohaToast()

  const { showModal } = useModal()

  const { showStickyEditingAlert } = useStickyEditingAlert()

  const [browsed, setBrowsed] = useState(false)

  // APIs
  const browseApi = useBrowseSticky({ onError: console.error })
  const { data: sticky, mutate: mutateSticky } = useSticky({
    stickyUid: !stickyUid || !isPopoverOpen ? null : stickyUid,
    onSuccess: ({ stickyUid }) => {
      if (!browsed) {
        browseApi.request({ stickyUid })
        setBrowsed(true)
      }
    },
  })
  const createStickyApi = useCreateWorksheetFieldSticky({
    onSuccess: ({ stickyUid }) => {
      mutateFieldStickyCache()
      onChangePopoverOpenForNew(null)
      setStickyQuery(stickyUid)
    },
  })
  const replyApi = useReplySticky({
    onSuccess: () => {
      mutateSticky()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })
  // 成功/失敗後の処理は引数の値に応じて実行できるようにする
  const editApi = useEditStickyMessage()
  const deleteMessageApi = useDeleteStickyMessage({
    onSuccess: () => {
      mutateSticky()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })
  const deleteStickyApi = useDeleteSticky({
    onSuccess: () => {
      onClose()
      toast({
        status: 'success',
        title: `${field.name}の付箋を削除しました`,
      })
      mutateFieldStickyCache()
    },
  })

  const trialHospitalUids = [
    patient.trialHospital.uid,
    patient.partnerTrialHospital?.uid,
  ].filter(isNotNullish)

  const { data: members } = useTrialMembers({
    input: {
      mainTrialHospitalUid: patient.trialHospital.uid,
      trialHospitalUids,
      roles: ['Dr', 'CRC', 'CRA', 'DM'],
    },
    sortOrder: 'ForStickyMention',
    revalidateIfStale: false,
  })

  const isNewRepeatableSection = useMemo(() => {
    const section = findSection(
      getFlattenFieldMap(fetchedWorksheet.schema.fields),
      field.fid,
    )
    if (!section) return false
    const value = findFieldValue({ fid: section.fid, index: fieldIndex })
    if (!value) return false
    return value.type === 'repeatableSection' && value.isNew
  }, [fetchedWorksheet.schema.fields, field.fid, fieldIndex, findFieldValue])

  const closePopoverWithConfirmation = useCallback(() => {
    if (isEditing) {
      showStickyEditingAlert({
        onConfirm: () => {
          onClose()
          toggleEditing(false)
        },
      })
      return
    }
    onClose()
  }, [isEditing, toggleEditing, onClose, showStickyEditingAlert])

  const onOpenPopover = useCallback(async () => {
    if (!!stickyUid && stickyUid === stickyQuery) return
    if (isNewRepeatableSection) {
      toast({
        status: 'warning',
        title: '付箋メッセージを作成できません',
        description: '追加された行を一時保存後にお試しください',
      })
      return
    }
    if (isEditing) {
      showStickyEditingAlert({
        onConfirm: () => {
          onOpen()
          toggleEditing(false)
        },
      })
      return
    }
    onOpen()
  }, [
    stickyUid,
    stickyQuery,
    onOpen,
    isNewRepeatableSection,
    isEditing,
    showStickyEditingAlert,
    toggleEditing,
    toast,
  ])

  const onClosePopover = useCallback(() => {
    closePopoverWithConfirmation()
  }, [closePopoverWithConfirmation])

  // メンション先の候補
  const membersToMention: MentionItem[] = (members ?? []).map(member => ({
    id: member.uid,
    display: getFullName(member.user),
  }))

  const createSticky = useCallback(
    async ({ message, priority }: MessageParam) => {
      await createStickyApi.request({
        worksheetUid: fetchedWorksheet.uid,
        fid: field.fid,
        fieldIndex,
        message,
        priority,
      })
    },
    [fetchedWorksheet, field, fieldIndex, createStickyApi],
  )

  const replySticky = useCallback(
    async ({ message, priority }: MessageParam) => {
      if (!stickyUid) return
      await replyApi.request({
        message,
        priority,
        stickyUid: stickyUid,
      })
    },
    [stickyUid, replyApi],
  )

  const editStickyMessage = useCallback(
    async ({ messageUid, message, priority, onSuccess }: EditMessageParam) => {
      if (!messageUid) return
      const res = await editApi.request({
        message,
        priority,
        stickyMessageUid: messageUid,
      })
      if (res.result === 'success') {
        mutateSticky()
        onSuccess()
      }
      if (res.result === 'error') {
        toast({
          status: 'error',
          title: res.error.message,
        })
      }
    },
    [editApi, mutateSticky, toast],
  )

  const deleteStickyMessage = useCallback(
    async (stickyMessageUid: string) => {
      showModal({
        title: 'このメッセージを削除しますか？',
        submitText: '削除する',
        submitButtonColor: 'red',
        content: '削除するとメッセージは参照できなくなります。',
        onCancel: onOpen, // モーダルを閉じた時にpopoverを開く
        onSubmit: async () => {
          await deleteMessageApi.request({
            stickyMessageUid,
          })
        },
      })
    },
    [showModal, onOpen, deleteMessageApi],
  )

  const deleteSticky = useCallback(async () => {
    if (!stickyUid) return
    showModal({
      title: 'この付箋を削除しますか？',
      submitText: '削除する',
      submitButtonColor: 'red',
      content: '削除すると付箋は参照できなくなります。',
      onCancel: onOpen, // モーダルを閉じた時にpopoverを開く
      onSubmit: async () => {
        await deleteStickyApi.request({
          stickyUid,
        })
      },
    })
  }, [stickyUid, showModal, onOpen, deleteStickyApi])

  const memberMap: MemberMap = useMemo(
    () => new Map(members?.map(member => [member.uid, member]) ?? []),
    [members],
  )

  const isPartnerMember = useCallback(
    (memberUid: string) => {
      const member = memberMap.get(memberUid)
      if (!member) return false
      // 実施医療機関に所属していない === パートナーのみに所属している
      return !member.trialHospitalUIDs.includes(patient.trialHospital.uid)
    },
    [memberMap, patient.trialHospital.uid],
  )

  return {
    sticky,
    hasSticky,
    resetQuery,
    isPopoverOpen,
    onOpenPopover,
    onClosePopover,
    membersToMention,
    createSticky,
    replySticky,
    editStickyMessage,
    deleteStickyMessage,
    deleteSticky,
    memberMap,
    isPartnerMember,
    toggleEditing,
  }
}
