import React from 'react'

import { WithCommentModal } from './with-comment-modal'

type Props = {
  onClose: () => void
  onSubmit: (comment: string) => void
}

export const RejectModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  return (
    <WithCommentModal
      title="承認却下"
      onClose={onClose}
      onSubmit={onSubmit}
      required={false}
      submitText="承認を却下する"
      submitButtonType="alert"
      descriptionComponent={null}
    />
  )
}
