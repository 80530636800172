import { Member } from 'src/features/member/types'
import { GetPatientReturn } from 'src/features/patient/api'

import { WorksheetFieldStickiesProvider } from './WorksheetFieldStickies'
import { WorksheetValueProvider } from './WorksheetValue'
import { WorksheetDetail } from '../types'

type ProviderProps = {
  fetchedWorksheet: WorksheetDetail
  mutateWorksheet: () => void
  children: React.ReactNode
  member: Member
  patient: GetPatientReturn
}

export const WorksheetProvider: React.FC<ProviderProps> = ({
  fetchedWorksheet,
  mutateWorksheet,
  member,
  patient,
  children,
}) => {
  return (
    <WorksheetValueProvider
      fetchedWorksheet={fetchedWorksheet}
      member={member}
      patient={patient}
    >
      <WorksheetFieldStickiesProvider
        fetchedWorksheet={fetchedWorksheet}
        mutateWorksheet={mutateWorksheet}
      >
        {children}
      </WorksheetFieldStickiesProvider>
    </WorksheetValueProvider>
  )
}
