import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import MirohaLogoFull from 'src/assets/image/miroha-logo-full.svg'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { NewPasswordInput } from 'src/modules/auth/common/new-password-input'
import styled from 'styled-components'
import * as yup from 'yup'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${blue[5]};
`

const ContentContainerForm = styled.form`
  width: 80%;
  max-width: 600px;
  padding: 60px 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${white};
  border-radius: 10px;
`

const FormItem = styled.div`
  width: 100%;
`

export interface IProps {
  errorMessage: string
  requesting: boolean
  onSubmit: (values: IFormValues) => Promise<void>
}

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup.string().required(),
})

type IFormValues = yup.InferType<typeof validationSchema>

export const RegisterPassword: React.FC<IProps> = ({
  errorMessage,
  requesting,
  onSubmit: submit,
}) => {
  const { register, handleSubmit, formState } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = (values: IFormValues) => {
    if (!isValid) return

    submit(values)
  }

  return (
    <PageContainer>
      <img src={MirohaLogoFull} alt="MiROHA icon" />
      <Spacer size={60} />
      <ContentContainerForm onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize="lg" fontWeight="bold">
          本パスワードの登録
        </Text>
        <Spacer size={40} />
        <NewPasswordInput
          register={register}
          isValid={!(errors.password && touchedFields.password)}
        />
        <Spacer size={20} />
        <FormItem>
          <Label bold>新しいパスワード(確認用)</Label>
          <Spacer size={10} />
          <Input
            type="password"
            width={1}
            placeholder="新しいパスワード確認入力"
            {...register('passwordConfirmation')}
          />
          {errors.passwordConfirmation &&
            touchedFields.passwordConfirmation && (
              <div>
                <Spacer size={4} />
                <Message type="error" message="パスワードを入力してください" />
              </div>
            )}
        </FormItem>

        {errorMessage && (
          <div>
            <Message type="error" message={errorMessage} centered />
            <Spacer size={20} />
          </div>
        )}

        <Spacer size={40} />

        <Button
          size="S"
          text="設定"
          onClick={() => handleSubmit(onSubmit)}
          buttonType="important"
          disabled={!isValid || requesting}
        >
          設定
        </Button>
      </ContentContainerForm>
    </PageContainer>
  )
}
