import { useEffect } from 'react'

import { useRequest } from 'src/hooks/use-request'

import { getVideoCallRoomImages } from '../request'

export const useGetVideoCallRoomImages = (worksheetUid: string) => {
  const {
    error,
    data: response,
    doRequest,
    requesting,
  } = useRequest(getVideoCallRoomImages)

  useEffect(() => {
    doRequest({ worksheetUid })
  }, [doRequest, worksheetUid])

  return { error, response, requesting }
}
