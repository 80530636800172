import { GetExplanationHistoryDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { ExplanationHistory, ExplanationRevision } from '../../types'
import { explanationCacheKey } from '../cacheKey'
import { parseGqlExplanationHistory } from '../parser'

const getExplanationHistories = async ({
  explanationRevisionUid,
}: {
  explanationRevisionUid: ExplanationRevision['uid']
}): Promise<ExplanationHistory> => {
  try {
    const res = await graphqlRequest(GetExplanationHistoryDocument, {
      explanationRevisionUid,
    })
    return parseGqlExplanationHistory(res.explanationRevision)
  } catch (e) {
    throw e
  }
}

export const useExplanationHistory = ({
  explanationRevisionUid,
  ...option
}: {
  explanationRevisionUid: ExplanationRevision['uid']
} & SWRConfiguration<ExplanationHistory, GqlError>) => {
  return useSWR<ExplanationHistory, GqlError>(
    explanationCacheKey.getExplanationHistories(explanationRevisionUid),
    () => getExplanationHistories({ explanationRevisionUid }),
    option,
  )
}
