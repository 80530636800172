import React from 'react'

import { Text } from '@chakra-ui/react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Circle, Warning } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { gray, green, white, yellow } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { usePane } from 'src/hooks/use-pane'
import {
  Questionnaire,
  QuestionnaireStatus,
} from 'src/modules/entities/questionnaire/entity'
import { questionnaireStatusToText } from 'src/modules/entities/questionnaire/utils'
import { formatTimestamp } from 'src/modules/util/datetime-utils'
import styled from 'styled-components'

import { AddContainer } from './add/add-container'
import { getScheduleRoute } from '../routes'

type Props = {
  questionnaires: Questionnaire[]
  showAddButton: boolean
  trialUid: string
}

export const List: React.FC<Props> = props => {
  const { questionnaires, showAddButton, trialUid } = props

  const navigate = useNavigate()

  const {
    handlers: { openPane, closePane },
    renderPane,
  } = usePane({ paneType: 'normal', closeOnOutsideClick: true })

  return (
    <PageContainer>
      {renderPane(<AddContainer onCancel={closePane} />)}
      <ContentContainer>
        <HeadContainer>
          <Text as="h1" fontSize="lg" fontWeight="bold">
            質問票
          </Text>

          <BtnContainer>
            {showAddButton && (
              <Button
                size="S"
                text="質問票を登録"
                buttonType="normal"
                onClick={openPane}
              />
            )}
            <Button
              size="S"
              text="スケジュール登録に進む"
              buttonType="normal"
              onClick={() => navigate(getScheduleRoute(trialUid))}
            />
          </BtnContainer>
        </HeadContainer>

        {questionnaires.length > 0 && (
          <>
            <TableContainer>
              <QuestionnaireTable questionnaires={questionnaires} />
            </TableContainer>
            <Spacer size={30} />
          </>
        )}
        {questionnaires.length === 0 && (
          <NonQuestionnaireText>
            登録している質問票データはありません。
          </NonQuestionnaireText>
        )}
      </ContentContainer>
    </PageContainer>
  )
}

type QuestionnaireTableProps = {
  questionnaires: Questionnaire[]
}

const QuestionnaireTable: React.FC<QuestionnaireTableProps> = props => {
  const { questionnaires } = props
  return (
    <Table>
      <Head>
        <Row>
          {['', '質問票名', '登録日時', 'ステータス'].map((headItem, i) => {
            return (
              <Item key={i}>
                <Text fontWeight="bold">{headItem}</Text>
              </Item>
            )
          })}
        </Row>
      </Head>
      <Body>
        <Scroll>
          {questionnaires.map((q, i) => {
            return (
              <BodyRowNavLink key={q.uid + i} to={`${q.uid}`}>
                <Item>
                  <Circle color={green[40]} />
                </Item>
                <Item>
                  <Text>{q.title}</Text>
                </Item>
                <Item>
                  <Text>{formatTimestamp({ timestamp: q.registeredAt })}</Text>
                </Item>
                <Item>
                  <Text>{questionnaireStatusToText(q.status)}</Text>
                  {q.status === QuestionnaireStatus.Draft && (
                    <>
                      <Spacer horizontal size={4} />
                      <Warning color={yellow[70]} />
                    </>
                  )}
                </Item>
              </BodyRowNavLink>
            )
          })}
        </Scroll>
      </Body>
    </Table>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${white};
`

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 28px;
  box-sizing: border-box;
  min-height: 55px;
  background: ${white};
`

const NonQuestionnaireText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 200px 0;

  color: ${gray[50]};
`

const TableContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
`

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    display: flex;
    align-items: center;
    margin-right: 20px;

    > img {
      margin-right: 5px;
    }
  }
`

const Head = styled.div`
  color: ${gray[100]};
  font-weight: 700;
  border-bottom: 1px solid ${gray[40]};
`

const Row = styled.div`
  margin: 0 33px 0 23px;
  display: grid;
  grid-template-columns: 5% 40% 20% 35%;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
`

const BodyRowNavLink = styled(NavLink)`
  margin: 0 33px 0 23px;
  text-decoration: none;
  display: grid;
  grid-template-columns: 5% 40% 20% 35%;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  &:not(:last-child) {
    border-bottom: 1px dashed ${gray[40]};
  }
`

const Body = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-top: none;
`

const Item = styled.div`
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 11px 8px;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${gray[40]};
  border-radius: 10px;
  margin: 0 30px;
  background: ${white};
`

const Scroll = styled.div`
  position: absolute;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`
