type Item = {
  parentUid: string | null
  index: number
}

export const getRootItems = <T extends Item>(items: T[]): T[] => {
  return Object.values(items)
    .filter(si => si.parentUid === null)
    .sort((a, b) => a.index - b.index)
}
