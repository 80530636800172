import React from 'react'

import { RoleMapModal } from './modal'

type Props = {
  onClose: () => void
}

export const RoleMapModalContainer: React.FC<Props> = props => {
  return <RoleMapModal onClose={props.onClose} />
}
