import { VideoCallRoomImage } from '../entity'

export type VideoCallRoomImageResponse = {
  uid: string
  name: string
  created_at: string
  photo_by_name: string
}

export const parse = (resp: VideoCallRoomImageResponse): VideoCallRoomImage => {
  return {
    uid: resp.uid,
    name: resp.name,
    createdAt: resp.created_at,
    photoByName: resp.photo_by_name,
  }
}
