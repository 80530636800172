import { DeleteSignSessionDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const deleteSignSession = async (): Promise<void> => {
  try {
    await graphqlRequest(DeleteSignSessionDocument, {})
  } catch (error) {
    throw error
  }
}

export const useDeleteSignSession = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(() => deleteSignSession(), option)
}
