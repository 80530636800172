import { ActualVisit } from 'src/features/actualVisit/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { CreateWorksheetsDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { castUid } from 'src/utils/brandedUid'

import { Worksheet } from '../../types'
import { parseGqlWorksheetStatus } from '../parser'

const createWorksheets = async ({
  actualVisitUid,
  worksheetSchemaUids,
}: {
  actualVisitUid: string
  worksheetSchemaUids: string[]
}): Promise<Worksheet[]> => {
  try {
    const res = await graphqlRequest(CreateWorksheetsDocument, {
      input: {
        patientObservationUid: actualVisitUid,
        worksheetSchemaUids,
      },
    })
    return res.createWorksheets.worksheets.map(w => ({
      uid: castUid<Worksheet>(w.worksheetUid),
      name: w.name,
      index: w.index,
      status: parseGqlWorksheetStatus(w.latestWorksheetLog.status),
      patientObservationUid: castUid<ActualVisit>(w.patientObservationUid),
    }))
  } catch (error) {
    throw error
  }
}

export const useCreateWorksheets = (
  options?: UseMutationOption<Worksheet[], GqlError>,
) => {
  return useMutation(
    ({
      actualVisitUid,
      worksheetSchemaUids,
    }: {
      actualVisitUid: string
      worksheetSchemaUids: string[]
    }) => createWorksheets({ actualVisitUid, worksheetSchemaUids }),
    options,
  )
}
