import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { matchPath, useLocation } from 'react-router-dom'
import {
  addExplanationRoomEvent,
  AddExplanationRoomEventParam,
  RoomUidParam,
  useSubscribeExplanationRoomEvents,
} from 'src/features/explanationRoom/api'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { assertNever } from 'src/utils/assertNever'

import { IgnoredRequestSignModalPaths } from '../../../constants/paths'
import { useLocalStorage } from '../../../hooks/use-local-storage'
import { ReceivePinSettingRequestModal } from '../components/ReceivePinSettingRequestModal/ReceivePinSettingRequestModal'
import { ReceiveRequestSignModal } from '../components/ReceiveRequestSignModal/ReceiveRequestSignModal'

export const locationPaths = {
  top: () => '/',
  document: (docRevisionUid: string) => `/documents/${docRevisionUid}`,
  docusign: (docRevisionUid: string) => `/documents/${docRevisionUid}/docusign`,
  completeDocusign: (docRevisionUid: string) =>
    `/documents/${docRevisionUid}/complete-docusign`,
} as const

type ActorDevice = {
  actorUid: string
  actorTrialHospitalUid: string
}

type CommonContext = {
  deviceActorUid: string
  deviceActorType: 'Member' | 'Partner' | 'Patient'
  deviceActorTrialHospitalUid: string
  currentLocationEvents: TempCurrentLocation[]
  pointLocation: PointLocation | null
  resetPointLocation: () => void
  setNewStatusListener: (listener: () => void) => void
  requestSignList: RequestSign[]
  setRequestSignList: (requestSignList: RequestSign[]) => void
  activeDevices: ActorDevice[]
}
type SessionContext = {
  roomType: 'Session'
  sessionUid: string
  preSessionUid?: undefined
} & CommonContext
type PreSessionContext = {
  roomType: 'PreSession'
  preSessionUid: string
  sessionUid?: undefined
} & CommonContext

type Context = SessionContext | PreSessionContext

const ExplanationRoomEventContext = createContext<Context>({} as Context)

type CommonProps = {
  children: React.ReactNode
  isSP?: boolean
  trialUid: string
  explanationRevisionUid: string
  explanationPatientUid: string
  patientPhoneNumber: string | undefined
  deviceActorUid: string
  deviceActorType: 'Member' | 'Partner' | 'Patient'
  deviceActorTrialHospitalUid: string
}

type SessionProps = {
  roomType: 'Session'
  sessionUid: string
} & CommonProps

type PreSessionProps = {
  roomType: 'PreSession'
  preSessionUid: string
} & CommonProps

type Props = SessionProps | PreSessionProps

// 'PendingOnThisDevice' このデバイスに署名依頼後、署名まち
// 'CompletedOnThisDevice' このデバイスに署名依頼後、署名済み
// 'Waiting' 他のデバイスorリンク送信で署名依頼後、署名まち
type RequestSignStatus =
  | 'PendingOnThisDevice'
  | 'CompletedOnThisDevice'
  | 'Waiting'

export type RequestSign = {
  explanationRoomEventUid: string
  sessionUid: string
  docRevUid: string
  signerActorUid: string
  deviceActorUid: string
  signerActorDisplayName?: string
  signType: 'OnScreen' | 'SendLink'
  selfRequest: boolean
  status: RequestSignStatus
}

const REQUEST_SIGN_LIST_STORAGE_KEY_PREFIX = 'requestSignList'

export const ExplanationRoomEventProvider: React.FC<Props> = ({
  children,
  isSP = false,
  trialUid,
  explanationRevisionUid,
  explanationPatientUid,
  patientPhoneNumber,
  deviceActorUid,
  deviceActorType,
  deviceActorTrialHospitalUid,
  ...changeable
}) => {
  const [currentLocations, setCurrentLocations] = useState<
    TempCurrentLocation[]
  >([])

  const [pointLocation, setPointLocation] = useState<PointLocation | null>(null)

  const newStatusListenerListRef = useRef<(() => void)[]>([])

  const [isOpenRequestSignModal, setIsOpenRequestSignModal] = useState(false)
  const [isOpenRequestPinSettingModal, setIsOpenRequestPinSettingModal] =
    useState(false)

  const [activeDevices, setActiveDevices] = useState<ActorDevice[]>([])

  const { pathname } = useLocation()

  const sessionUid =
    changeable.roomType === 'Session' ? changeable.sessionUid : undefined

  const {
    data: requestSignList,
    setData: setRequestSignList,
    setDataWithCallback: setRequestSignListWithCallback,
    removeData: removeRequestSignList,
  } = useLocalStorage<RequestSign[]>(
    REQUEST_SIGN_LIST_STORAGE_KEY_PREFIX + '_' + sessionUid,
  )

  const toast = useMirohaToast()

  useEffect(() => {
    if (!requestSignList) {
      setIsOpenRequestSignModal(false)
      return
    }
    if (
      !requestSignList.some(
        rs =>
          rs.deviceActorUid === deviceActorUid &&
          rs.status === 'PendingOnThisDevice',
      )
    ) {
      setIsOpenRequestSignModal(false)
      return
    }
    for (const ignoredPath of IgnoredRequestSignModalPaths) {
      if (matchPath(ignoredPath, pathname)) {
        setIsOpenRequestSignModal(false)
        return
      }
    }
    setIsOpenRequestSignModal(true)
  }, [deviceActorUid, pathname, requestSignList])

  useSubscribeExplanationRoomEvents({
    explanationRoomUid:
      changeable.roomType === 'Session'
        ? changeable.sessionUid
        : changeable.preSessionUid,
    roomType: 'Session',
    onNext: newEv => {
      switch (newEv.__typename) {
        case 'ExplanationRoomEvent':
          if (newEv.eventType === 'CurrentLocation') {
            // 自分自身が発行したイベントは無視する
            if (newEv.actor.actorUid === deviceActorUid) {
              return
            }
            setCurrentLocations(prev => {
              if (
                newEv.eventMessage.__typename !==
                'ExplanationRoomCurrentLocationEventMessage'
              ) {
                return prev
              }
              return [
                ...prev.filter(ev => ev.actorUid !== newEv.actor.actorUid),
                {
                  actorUid: newEv.actor.actorUid,
                  actorType:
                    newEv.actor.__typename ===
                    'ExplanationRoomEventActorPatient'
                      ? 'Patient'
                      : 'Member',
                  trialHospitalUid:
                    newEv.actor.__typename === 'ExplanationRoomEventActorSystem'
                      ? undefined
                      : newEv.actor.trialHospitalUid,
                  displayName:
                    newEv.actor.__typename === 'ExplanationRoomEventActorSystem'
                      ? undefined
                      : newEv.actor.displayName,
                  path: newEv.eventMessage.currentLocationPath ?? undefined,
                  page: newEv.eventMessage.page ?? undefined,
                  deleted: newEv.eventMessage.deleted ?? undefined,
                },
              ]
            })
          }
          if (newEv.eventType === 'PointLocation') {
            // 自分自身が発行したイベントは無視する
            if (newEv.actor.actorUid === deviceActorUid) {
              return
            }
            setPointLocation(prev => {
              if (newEv.explanationRoomEventUid === prev?.roomEventUid) {
                return prev
              }
              if (
                newEv.eventMessage.__typename !==
                'ExplanationRoomPointLocationEventMessage'
              ) {
                return prev
              }
              return {
                roomEventUid: newEv.explanationRoomEventUid,
                actorUid: newEv.actor.actorUid,
                actorType:
                  newEv.actor.__typename === 'ExplanationRoomEventActorPatient'
                    ? 'Patient'
                    : 'Member',
                trialHospitalUid:
                  newEv.actor.__typename === 'ExplanationRoomEventActorSystem'
                    ? undefined
                    : newEv.actor.trialHospitalUid,
                displayName:
                  newEv.actor.__typename === 'ExplanationRoomEventActorSystem'
                    ? undefined
                    : newEv.actor.displayName,
                path: newEv.eventMessage.pointLocationPath,
                page: newEv.eventMessage.page ?? undefined,
              }
            })
          }
          if (newEv.eventType === 'UpdateRevisionStatus') {
            // 自分自身が発行したイベントは無視する
            if (newEv.actor.actorUid === deviceActorUid) {
              return
            }
            if (
              newEv.eventMessage.__typename !==
              'ExplanationRoomUpdateRevisionStatusEventMessage'
            ) {
              return
            }
            newStatusListenerListRef.current.forEach(listener => {
              listener()
            })
          }
          if (newEv.eventType === 'RequestSign') {
            if (
              newEv.eventMessage.__typename !==
              'ExplanationRoomRequestSignEventMessage'
            ) {
              return
            }
            const {
              signerActorUid: evSignerActorUid,
              signerActorDisplayName: evSignerActorDisplayName,
              deviceActorUid: evDeviceActorUid,
              signType: evSignType,
            } = newEv.eventMessage
            // 宛先が自分のデバイス、かつ画面上署名の場合はPendingOnThisDevice
            // それ以外の場合はWaitingで署名待ちになる
            const status: RequestSignStatus =
              evDeviceActorUid === deviceActorUid &&
              newEv.eventMessage.signType === 'OnScreen'
                ? 'PendingOnThisDevice'
                : 'Waiting'
            setRequestSignListWithCallback(prev => {
              if (changeable.roomType !== 'Session') {
                return prev
              }
              if (
                newEv.eventMessage.__typename !==
                'ExplanationRoomRequestSignEventMessage'
              ) {
                return prev
              }
              const newRequestSignList =
                newEv.eventMessage.explanationDocRevisionUids.map(
                  docRevUid => ({
                    explanationRoomEventUid: newEv.explanationRoomEventUid,
                    sessionUid: changeable.sessionUid,
                    signerActorUid: evSignerActorUid,
                    deviceActorUid: evDeviceActorUid,
                    docRevUid,
                    signerActorDisplayName: evSignerActorDisplayName,
                    signType: evSignType,
                    status,
                    selfRequest: deviceActorUid === newEv.actor.actorUid,
                  }),
                )
              const existingRequestSign =
                prev?.filter(
                  rs =>
                    !newRequestSignList.some(
                      nrs =>
                        nrs.docRevUid === rs.docRevUid &&
                        nrs.signerActorUid === rs.signerActorUid,
                    ),
                ) ?? []
              return [...newRequestSignList, ...existingRequestSign]
            })
            if (status === 'PendingOnThisDevice') {
              setIsOpenRequestSignModal(true)
            }
          }
          if (newEv.eventType === 'ActiveDevice') {
            setActiveDevices(prev => {
              if (
                newEv.eventMessage.__typename !==
                'ExplanationRoomActiveDeviceEventMessage'
              ) {
                return prev
              }
              const newActiveDevice = {
                actorUid: newEv.eventMessage.deviceActorUid,
                actorTrialHospitalUid:
                  newEv.eventMessage.deviceActorTrialHospitalUid,
              }
              const isSame = (prev: ActorDevice) => {
                const { actorUid, actorTrialHospitalUid } = newActiveDevice
                return (
                  prev.actorUid === actorUid &&
                  prev.actorTrialHospitalUid === actorTrialHospitalUid
                )
              }
              if (!!newEv.eventMessage.deleted) {
                return [...prev].filter(
                  activeDevice =>
                    activeDevice.actorUid !== newActiveDevice.actorUid,
                )
              } else if (prev.some(isSame)) {
                return prev
              } else {
                return [...prev, newActiveDevice]
              }
            })
          }

          if (newEv.eventType === 'RequestPinSetting') {
            if (
              newEv.eventMessage.__typename !==
              'ExplanationRoomRequestPinSettingEventMessage'
            ) {
              return
            }

            setIsOpenRequestPinSettingModal(
              !isOpenRequestPinSettingModal &&
                newEv.eventMessage.deviceActorUid === deviceActorUid,
            )
          }

          break
        case 'ExplanationRoomEventInfo':
          break
        case 'ExplanationRoomEventError':
          toast({
            status: 'warning',
            title: newEv.message,
          })
          break
        default:
          return assertNever(newEv)
      }
    },
    onError: () => {
      toast({
        status: 'warning',
        title: '画面情報の同期に失敗しました',
        isClosable: true,
        duration: 5000,
      })
    },
  })

  const resetPointLocation = useCallback(() => {
    setPointLocation(null)
  }, [])

  const commonContext: CommonContext = {
    deviceActorUid,
    deviceActorType,
    deviceActorTrialHospitalUid,
    currentLocationEvents: currentLocations,
    pointLocation,
    resetPointLocation,
    setNewStatusListener: (listener: () => void) => {
      newStatusListenerListRef.current.push(listener)
    },
    requestSignList: requestSignList ?? [],
    setRequestSignList: rss => {
      if (rss.length === 0) {
        removeRequestSignList()
        return
      }
      setRequestSignList(rss)
    },
    activeDevices,
  }

  return (
    <ExplanationRoomEventContext.Provider
      value={
        changeable.roomType === 'Session'
          ? {
              ...commonContext,
              roomType: 'Session',
              sessionUid: changeable.sessionUid,
            }
          : {
              ...commonContext,
              roomType: 'PreSession',
              preSessionUid: changeable.preSessionUid,
            }
      }
    >
      {children}
      {changeable.roomType === 'Session' && (
        <ReceiveRequestSignModal
          isOpen={isOpenRequestSignModal}
          isSP={isSP}
          trialUid={trialUid}
          sessionUid={changeable.sessionUid}
          actorType={deviceActorType}
          onClose={() => setIsOpenRequestSignModal(false)}
          requestSign={
            requestSignList?.find(rs => rs.status === 'PendingOnThisDevice') ??
            undefined
          }
          ownDeviceActorUid={deviceActorUid}
        />
      )}
      {(deviceActorType === 'Partner' || deviceActorType === 'Patient') && (
        <ReceivePinSettingRequestModal
          isOpen={isOpenRequestPinSettingModal}
          isSP={isSP}
          trialUid={trialUid}
          explanationRevisionUid={explanationRevisionUid}
          explanationPatientUid={explanationPatientUid}
          explanationSessionUid={sessionUid}
          patientPhoneNumber={patientPhoneNumber}
          actor={deviceActorType}
          onClose={() => {
            setIsOpenRequestPinSettingModal(false)
          }}
        />
      )}
    </ExplanationRoomEventContext.Provider>
  )
}

type TempCurrentLocation = {
  actorUid: string
  actorType: 'Member' | 'Patient'
  trialHospitalUid?: string
  displayName?: string
  path?: string
  page?: number
  deleted?: boolean
}

export type CurrentLocation = TempCurrentLocation & {
  actorSide: 'Ours' | 'Theirs'
}

export const useCurrentLocations = () => {
  const { currentLocationEvents } = useContext(ExplanationRoomEventContext)

  const { deviceActorTrialHospitalUid, deviceActorType } = useContext(
    ExplanationRoomEventContext,
  )

  const getCurrentLocationsByPath = useCallback(
    ({
      path,
      inverse = false,
    }: {
      path: string
      inverse?: boolean
    }): CurrentLocation[] => {
      return currentLocationEvents
        .filter(ev => ev.deleted !== true)
        .filter(ev => (inverse ? ev.path !== path : ev.path === path))
        .map(ev => ({
          ...ev,
          actorSide:
            // 自分か相手、どちらかが患者の場合は必ず相手側
            ev.actorType === 'Patient'
              ? 'Theirs'
              : deviceActorType === 'Patient'
                ? 'Theirs'
                : ev.trialHospitalUid === deviceActorTrialHospitalUid
                  ? 'Ours'
                  : 'Theirs',
        }))
    },
    [currentLocationEvents, deviceActorTrialHospitalUid, deviceActorType],
  )

  return {
    getCurrentLocationsByPath,
  }
}

type SendCurrentLocationProps = {
  path: string
  page?: number
  sendOnLanding: boolean
}

export const useSendCurrentLocation = (props: SendCurrentLocationProps) => {
  const { path, page, sendOnLanding } = props

  const [completeOnLanding, setCompleteOnLanding] = useState(false)

  const {
    roomType,
    sessionUid,
    deviceActorUid,
    deviceActorType,
    deviceActorTrialHospitalUid,
  } = useContext(ExplanationRoomEventContext)

  const send = useCallback(
    async (args: { page?: number; deleted?: boolean }): Promise<void> => {
      if (roomType !== 'Session') {
        return
      }
      let param: AddExplanationRoomEventParam | undefined
      if (deviceActorType === 'Member' || deviceActorType === 'Partner') {
        const trialMemberUid = deviceActorUid
        if (!trialMemberUid) {
          return
        }
        param = {
          explanationSessionUid: sessionUid,
          actorType: 'Member',
          trialMemberUid,
          trialHospitalUid: deviceActorTrialHospitalUid,
          eventType: 'CurrentLocation',
          path: !args.deleted ? path : undefined,
          page: !args.deleted ? args.page : undefined,
          deleted: args.deleted,
        }
      } else if (deviceActorType === 'Patient') {
        const explanationPatientUid = deviceActorUid
        if (!explanationPatientUid) {
          return
        }
        param = {
          explanationSessionUid: sessionUid,
          actorType: 'Patient',
          explanationPatientUid,
          trialHospitalUid: deviceActorTrialHospitalUid,
          eventType: 'CurrentLocation',
          path: !args.deleted ? path : undefined,
          page: !args.deleted ? args.page : undefined,
          deleted: args.deleted,
        }
      }
      if (!param) {
        return
      }
      await addExplanationRoomEvent(param)
      return
    },
    [
      roomType,
      deviceActorTrialHospitalUid,
      deviceActorType,
      deviceActorUid,
      path,
      sessionUid,
    ],
  )

  useEffect(() => {
    if (!sendOnLanding || completeOnLanding) {
      return
    }
    // 送信に失敗しても再送しない
    setCompleteOnLanding(true)
    ;(async () => {
      await send({ page })
    })()
  }, [completeOnLanding, sendOnLanding, send, page])

  return {
    send,
  }
}

type PointLocation = {
  roomEventUid: string
  actorUid: string
  actorType: 'Member' | 'Patient'
  trialHospitalUid?: string
  displayName?: string
  path: string
  page?: number
}

export const usePointLocations = ({
  path,
  listener,
}: {
  path: string
  listener: (pointLocation: PointLocation) => void
}) => {
  const { pointLocation, resetPointLocation } = useContext(
    ExplanationRoomEventContext,
  )

  useEffect(() => {
    if (!pointLocation) {
      return
    }
    if (pointLocation.path !== path) {
      return
    }
    listener(pointLocation)
    resetPointLocation()
  }, [listener, path, pointLocation, resetPointLocation])
}

type SendPointLocationProps = {
  path: string
}

export const useSendPointLocation = (props: SendPointLocationProps) => {
  const { path } = props

  const { roomType, sessionUid } = useContext(ExplanationRoomEventContext)

  const send = useCallback(
    async (args: {
      actorType: 'Member'
      trialHospitalUid: string
      trialMemberUid: string
      page?: number
    }): Promise<void> => {
      if (roomType !== 'Session') {
        return
      }
      await addExplanationRoomEvent({
        explanationSessionUid: sessionUid,
        actorType: 'Member',
        trialMemberUid: args.trialMemberUid,
        trialHospitalUid: args.trialHospitalUid,
        eventType: 'PointLocation',
        path,
        page: args.page,
      })
      return
    },
    [roomType, path, sessionUid],
  )

  return {
    send,
  }
}

type UpdateRevisionStatusProps = {
  listener: () => void
}

export const useUpdateRevisionStatus = (props: UpdateRevisionStatusProps) => {
  const { listener } = props

  const { setNewStatusListener } = useContext(ExplanationRoomEventContext)

  const initialized = useRef(false)

  useEffect(() => {
    if (initialized.current) {
      return
    }
    initialized.current = true
    setNewStatusListener(listener)
  }, [listener, setNewStatusListener])
}

export const useRequestSign = () => {
  const {
    roomType,
    sessionUid,
    deviceActorUid,
    deviceActorType,
    deviceActorTrialHospitalUid,
    requestSignList,
    setRequestSignList,
  } = useContext(ExplanationRoomEventContext)

  const send = useCallback(
    async (args: {
      docRevisionUids: string[]
      signerActorUid: string
      deviceActorUid: string
      signType: 'OnScreen' | 'SendLink'
    }): Promise<void> => {
      if (deviceActorType !== 'Member') {
        return
      }
      if (roomType !== 'Session') {
        return
      }
      await addExplanationRoomEvent({
        explanationSessionUid: sessionUid,
        actorType: deviceActorType,
        trialMemberUid: deviceActorUid,
        trialHospitalUid: deviceActorTrialHospitalUid,
        eventType: 'RequestSign',
        explanationDocRevisionUids: args.docRevisionUids,
        signerActorUid: args.signerActorUid,
        deviceActorUid: args.deviceActorUid,
        signType: args.signType,
      })
      return
    },
    [
      roomType,
      deviceActorTrialHospitalUid,
      deviceActorType,
      deviceActorUid,
      sessionUid,
    ],
  )

  const completeRequestSign = useCallback(
    (completed: {
      sessionUid: string
      docRevUid: string
      signerActorUid: string
    }) => {
      const newRequestSignList: RequestSign[] = requestSignList.map(
        requestSign => {
          if (
            requestSign.sessionUid === completed.sessionUid &&
            requestSign.docRevUid === completed.docRevUid &&
            requestSign.signerActorUid === completed.signerActorUid
          ) {
            return {
              ...requestSign,
              status: 'CompletedOnThisDevice',
            }
          }
          return requestSign
        },
      )
      setRequestSignList(newRequestSignList)
    },
    [requestSignList, setRequestSignList],
  )

  const cancelRequestSignBySessionUid = useCallback(
    (sessionUid: string) => {
      setRequestSignList(
        requestSignList.filter(
          requestSign => requestSign.sessionUid !== sessionUid,
        ),
      )
    },
    [requestSignList, setRequestSignList],
  )

  return {
    send,
    requestSignList,
    setRequestSignList,
    completeRequestSign,
    cancelRequestSignBySessionUid,
  }
}

export const useActiveDevice = () => {
  const {
    roomType,
    sessionUid,
    preSessionUid,
    deviceActorUid,
    deviceActorType,
    deviceActorTrialHospitalUid,
    activeDevices,
  } = useContext(ExplanationRoomEventContext)

  const sendDeleted = useCallback(async (): Promise<void> => {
    let param: AddExplanationRoomEventParam | undefined
    if (deviceActorType === 'Member' || deviceActorType === 'Partner') {
      const trialMemberUid = deviceActorUid
      if (!trialMemberUid) {
        return
      }
      param = {
        explanationSessionUid:
          roomType === 'Session' ? sessionUid : preSessionUid,
        actorType: 'Member',
        trialMemberUid,
        trialHospitalUid: deviceActorTrialHospitalUid,
        eventType: 'ActiveDevice',
        deviceActorUid,
        deviceActorTrialHospitalUid,
        deleted: true,
      }
    } else if (deviceActorType === 'Patient') {
      const explanationPatientUid = deviceActorUid
      if (!explanationPatientUid) {
        return
      }
      param = {
        explanationSessionUid:
          roomType === 'Session' ? sessionUid : preSessionUid,
        actorType: 'Patient',
        explanationPatientUid,
        trialHospitalUid: deviceActorTrialHospitalUid,
        eventType: 'ActiveDevice',
        deviceActorUid,
        deviceActorTrialHospitalUid,
        deleted: true,
      }
    }
    if (!param) {
      return
    }
    await addExplanationRoomEvent(param)
    return
  }, [
    roomType,
    deviceActorTrialHospitalUid,
    deviceActorType,
    deviceActorUid,
    sessionUid,
    preSessionUid,
  ])

  return {
    sendDeleted,
    activeDevices,
  }
}

export const useRequestPinSettingEvent = () => {
  const {
    roomType,
    sessionUid,
    preSessionUid,
    deviceActorUid,
    deviceActorType,
    deviceActorTrialHospitalUid,
  } = useContext(ExplanationRoomEventContext)

  const send = useCallback(
    async (args: { targetActorUid: string }) => {
      if (deviceActorType !== 'Member') return

      const roomUidParam: RoomUidParam =
        roomType === 'Session'
          ? {
              explanationSessionUid: sessionUid,
              trialHospitalUid: deviceActorTrialHospitalUid,
            }
          : {
              explanationPreSessionUid: preSessionUid,
              trialHospitalUid: deviceActorTrialHospitalUid,
            }

      const param: AddExplanationRoomEventParam = {
        ...roomUidParam,
        eventType: 'RequestPinSetting',
        actorType: deviceActorType,
        trialMemberUid: deviceActorUid,
        deviceActorUid: args.targetActorUid,
      }
      await addExplanationRoomEvent(param)
    },
    [
      deviceActorType,
      roomType,
      sessionUid,
      preSessionUid,
      deviceActorUid,
      deviceActorTrialHospitalUid,
    ],
  )

  return {
    send,
  }
}
