import { memo, useCallback } from 'react'

import { Box, Stack, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { Close, Down, Up } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { white, gray } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { Spacer } from 'src/components/spacer/spacer'
import {
  AnswerType,
  QuestionnaireField,
} from 'src/modules/entities/questionnaire-field/entity'
import { hasChoice } from 'src/modules/util/answer-type-classification'
import styled from 'styled-components'

import { EditableAnswerTypeSelect } from './answer-type-select'
import { EditableChoice } from './choice'
import { ErrorMessage } from './error-message'
import { MAX_UNIT_LENGTH } from '../../detail'
import { actions, FieldValidationError } from '../../redux'
import { useEditQuestionnaireField } from '../../use-edit-questionnaire-field'
import { useValidationError } from '../../use-validation-error'

type Props = {
  field: QuestionnaireField
  sectionIndex: number
  sectionUid: string
}

export const EditableField: React.FC<Props> = memo(props => {
  const { field, sectionIndex, sectionUid } = props
  const { uid, title, answerType } = field

  const { onDeleteField, onUpdateField, onUpField, onDownField } =
    useEditQuestionnaireField({ sectionIndex, uid })

  const { getFieldError, resetValidationError } = useValidationError()
  const validationError = getFieldError(sectionUid, uid)

  const onChoiceAdded = useCallback(
    () =>
      resetValidationError({
        target: 'choiceSize',
        sectionUid,
        fieldUid: uid,
      }),
    [resetValidationError, sectionUid, uid],
  )

  return (
    <Container>
      <Stack m="8px" spacing="0px">
        <Box cursor="pointer" _hover={{ opacity: 0.6 }} onClick={onUpField}>
          <Up />
        </Box>
        <Box cursor="pointer" _hover={{ opacity: 0.6 }} onClick={onDownField}>
          <Down />
        </Box>
      </Stack>

      <Content>
        <Item>
          <Label>
            <Text fontWeight="bold">質問</Text>
          </Label>
          <Spacer size={40} horizontal />
          <TitleContainer>
            <Textarea
              width="480px"
              placeholder={'質問を入力'}
              value={title}
              onChange={e => {
                onUpdateField({ values: { title: e.target.value } })
                resetValidationError({
                  target: 'title',
                  sectionUid,
                  fieldUid: uid,
                })
              }}
              showError={!!validationError?.title}
            />
            {validationError?.title && (
              <ErrorMessage>{validationError.title}</ErrorMessage>
            )}
          </TitleContainer>
        </Item>

        <Spacer size={16} />

        <Item>
          <Label>
            <Text fontWeight="bold">回答タイプ</Text>
          </Label>
          <Spacer size={40} horizontal />
          <EditableAnswerTypeSelect
            selectedValue={answerType}
            onSelect={selectedValue =>
              onUpdateField({
                values: { answerType: selectedValue },
              })
            }
          />
        </Item>

        <Spacer size={16} />

        {hasChoice(field) && (
          <ChoiceList
            sectionIndex={sectionIndex}
            field={field}
            validationError={validationError}
            sectionUid={sectionUid}
            onChoiceAdded={onChoiceAdded}
          />
        )}

        {field.answerType === AnswerType.Number && (
          <Item>
            <Label>
              <Text fontWeight="bold">入力する数値の単位</Text>
            </Label>
            <Spacer size={40} horizontal />
            <Input
              width="40%"
              value={field.unit}
              placeholder="回、℃など"
              onChange={e => {
                onUpdateField({
                  values: { unit: e.target.value },
                })
              }}
              maxLength={MAX_UNIT_LENGTH}
            />
          </Item>
        )}

        <Box pos="absolute" top="10px" right="10px">
          <Close cursor="pointer" onClick={onDeleteField} />
        </Box>
      </Content>
    </Container>
  )
})

type ChoiceListProps = {
  sectionIndex: number
  field: QuestionnaireField
  validationError?: FieldValidationError
  sectionUid: string
  onChoiceAdded: () => void
}

const ChoiceList: React.FC<ChoiceListProps> = props => {
  const dispatch = useDispatch()
  const { sectionIndex, field, validationError, sectionUid, onChoiceAdded } =
    props

  const onAddChoice = useCallback(() => {
    dispatch(
      actions.addChoice({
        sectionIndex,
        fieldUid: field.uid,
      }),
    )
    onChoiceAdded()
  }, [dispatch, field.uid, sectionIndex, onChoiceAdded])

  return (
    <>
      <ChoiceItem>
        <Label>
          <Text fontWeight="bold">回答の選択肢</Text>
        </Label>
        <Spacer size={40} horizontal />
        <ChoiceContainer>
          {Object.values(field.choices)
            .sort((a, b) => a.index - b.index)
            .map(c => (
              <EditableChoice
                key={c.uid}
                sectionIndex={sectionIndex}
                sectionUid={sectionUid}
                fieldUid={field.uid}
                index={c.index}
                choice={c}
              />
            ))}
        </ChoiceContainer>
      </ChoiceItem>

      <Spacer size={16} />

      <Button
        iconName="add"
        text={'新しい選択肢を追加'}
        size="S"
        buttonType="normal"
        onClick={onAddChoice}
      />

      {validationError?.choiceSize && (
        <>
          <Spacer size={10} />
          <ErrorMessage>{validationError.choiceSize}</ErrorMessage>
        </>
      )}
    </>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Content = styled.div`
  flex-grow: 1;
  background-color: ${white};
  padding: 16px 40px 16px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${gray[40]};
  position: relative;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ChoiceItem = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
`

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Label = styled.div`
  width: 120px;
`

const TitleContainer = styled.div``
