import { useEffect } from 'react'

import { useAuth } from 'src/features/auth/hooks'

const useGaSetTag = () => {
  const { state, account } = useAuth()

  useEffect(() => {
    if (state === 'Challenging') return

    if (!!window.dataLayer) {
      window.dataLayer.push({
        logged_in: !!account,
        user_id: !!account ? account.uid : null,
      })
    }
  }, [account, state])
}

export const GaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useGaSetTag()

  return <>{children}</>
}
