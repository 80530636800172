import { SERVER_URL } from 'src/modules/server/const'
import {
  requestDelete,
  requestPost,
  requestPut,
} from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Schedule } from './entity'
import { fixture } from './fixture'

export type ScheduleCreateRequest = {
  title: string
  startAfterDays: number
  availableFromDays: number
  availableUpToDays: number
}

export const create = async (
  trialUid: string,
  params: ScheduleCreateRequest,
): Promise<Schedule> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPost<Schedule>({
    url: `${SERVER_URL}/trials/${trialUid}/schedules`,
    params,
  })

  return res.data
}

type ScheduleUpdateRequest = {
  title: string
  startAfterDays: number
  availableFromDays: number
  availableUpToDays: number
}
export const update = async (
  trialUid: string,
  scheduleUid: string,
  params: ScheduleUpdateRequest,
): Promise<Schedule> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPut<Schedule>({
    url: `${SERVER_URL}/trials/${trialUid}/schedules/${scheduleUid}`,
    params,
  })

  return res.data
}

export const destroy = async (
  trialUid: string,
  scheduleUid: string,
): Promise<Schedule> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestDelete<Schedule>({
    url: `${SERVER_URL}/trials/${trialUid}/schedules/${scheduleUid}`,
  })

  return res.data
}
