import { fixture as patientFixture } from 'src/modules/entities/patient/fixture'
import { PatientResponse, parse } from 'src/modules/entities/patient/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { RequestValues } from './components/form'
import { Params } from '../add/request'

export const editPatient = async ({
  trialUid,
  patientUid,
  patient: {
    diseaseUid,
    chartUid,
    firstName,
    firstNameJa,
    lastNameJa,
    lastName,
    gender,
    birthday,
    phoneNumber,
    email,
  },
}: {
  trialUid: string
  patientUid: string
  patient: RequestValues
}) => {
  if (IS_MOCK_MODE) {
    return patientFixture
  }

  const params: Params = {
    chart_uid: chartUid || '',
    disease_uid: diseaseUid,
    first_name: firstName || '',
    first_name_ja: firstNameJa || '',
    last_name: lastName || '',
    last_name_ja: lastNameJa || '',
    gender: gender || null,
    birthday: birthday || '',
    phone_number: phoneNumber || '',
    email: email || '',
  }

  const resp = await requestPut<PatientResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}`,
    params,
  })

  return parse(resp.data)
}
