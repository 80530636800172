import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const resendSignAuthEmail = async ({
  trialUid,
  explanationSignAuthUid,
}: {
  trialUid: string
  explanationSignAuthUid: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/resend_email',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useResendSignAuthEmail = (options?: UseMutationOption<void>) => {
  return useMutation(
    ({
      trialUid,
      explanationSignAuthUid,
    }: {
      trialUid: string
      explanationSignAuthUid: string
    }) =>
      resendSignAuthEmail({
        trialUid,
        explanationSignAuthUid,
      }),
    options,
  )
}
