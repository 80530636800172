import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions as templateActions } from 'src/modules/entities/template/redux'
import { actions as templateFolderActions } from 'src/modules/entities/template-folder/redux'
import { actions as worksheetActions } from 'src/modules/entities/worksheet/redux'
import { routes } from 'src/modules/routes'

export const useFetch = ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}) => {
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      try {
        await Promise.all([
          dispatch(worksheetActions.fetchList({ trialUid, patientUid })),
          dispatch(templateActions.fetchList({ trialUid })),
          dispatch(templateFolderActions.fetchList({ trialUid })),
        ])
        setFetched(true)
      } catch (error) {
        // TOOD: login key?
        console.error(
          '(worksheetActions.fetchList || templateActions.fetchList) Error: ',
          error,
        )
        error.original.response.status === 403
          ? navigate(routes.httpError403)
          : navigate(`${routes.requiredLogin}`)
      }
    }

    fetch()
  }, [dispatch, navigate, patientUid, setFetched, trialUid])

  return {
    fetched,
  }
}
