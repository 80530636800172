import { colors } from 'src/assets/colors'
import { zIndex } from 'src/modules/util/z-index'
import styled from 'styled-components'

import { blue, gray } from '../base/color/palette'

export const DropDown = styled.div`
  position: absolute;
  background: ${colors.bgWhite};
  border: 1px solid ${colors.borderGray};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: ${zIndex.dropDown};
  &.menu {
    min-width: 200px;
    right: 0;
  }
  > a {
    &:not(:last-child) {
      border-bottom: 1px solid ${colors.borderGray};
    }
  }
`
export const DropDownItem = styled.div`
  color: ${gray[100]};
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;

  &:hover {
    background-color: ${colors.hoverGray};
  }
  &.active {
    background-color: ${blue[10]};
  }
  &.desc {
    padding: 10px 26px;
  }
  &.menu {
    padding: 15px 12px;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    border-bottom: 1px dashed ${colors.grayLighten7};
    &:last-child {
      border-bottom: none;
    }
  }
`
export const Name = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  white-space: nowrap;
  &.desc {
    flex-grow: 0;
  }
`
