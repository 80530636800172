import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { QuestionnaireSection } from 'src/modules/entities/questionnaire-section/entity'

import { actions } from './redux'

export const useEditQuestionnaireSection = ({ index }: { index: number }) => {
  const dispatch = useDispatch()

  const onDeleteSection = useCallback(
    () =>
      dispatch(
        actions.deleteSection({
          index,
        }),
      ),
    [dispatch, index],
  )

  const onUpdateSection = useCallback(
    ({ values }: { values: Partial<Pick<QuestionnaireSection, 'title'>> }) =>
      dispatch(
        actions.updateSection({
          index,
          values,
        }),
      ),
    [dispatch, index],
  )

  const onUpSection = useCallback(
    () => dispatch(actions.changeIndexSection({ index, direction: 'up' })),
    [dispatch, index],
  )

  const onDownSection = useCallback(
    () => dispatch(actions.changeIndexSection({ index, direction: 'down' })),
    [dispatch, index],
  )

  return {
    onDeleteSection,
    onUpdateSection,
    onUpSection,
    onDownSection,
  }
}
