import React from 'react'

import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Spacer,
  HStack,
  MenuItem,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { genderList } from 'src/features/patient/utils/genderList'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ExcludeReferredPatientButton_ExcludeReferredPatientDocument,
  Gender,
} from '../../../../lib/gql-client'
import { useGqlMutation } from '../../../../lib/gql-client/request'

type Props = {
  componentType: 'Button' | 'MenuItem'
  referredPatientReferUid: string
  numberingID: number
  gender: Gender
  age: number
  patientHospitalName: string
  mutateReferredPatient: () => void
  referredPatientsPath: string
}

export const ExcludeReferredPatientButton: React.FC<Props> = ({
  componentType,
  referredPatientReferUid,
  numberingID,
  gender,
  age,
  patientHospitalName,
  mutateReferredPatient,
  referredPatientsPath,
}) => {
  const {
    isOpen: isExcludeReferredPatientModalOpen,
    onOpen: onExcludeReferredPatientModalOpen,
    onClose: onExcludeReferredPatientModalClose,
  } = useDisclosure()

  const toast = useMirohaToast()

  const navigate = useNavigate()

  const { request: excludeRefer } = useGqlMutation(
    ExcludeReferredPatientButton_ExcludeReferredPatientDocument,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: '紹介患者を除外しました',
        })
        onExcludeReferredPatientModalClose()
        mutateReferredPatient()
        navigate(referredPatientsPath)
      },
    },
  )

  return (
    <>
      {componentType === 'Button' ? (
        <Button variant="outline" onClick={onExcludeReferredPatientModalOpen}>
          除外
        </Button>
      ) : (
        <MenuItem onClick={onExcludeReferredPatientModalOpen} color="red.500">
          除外
        </MenuItem>
      )}

      <Modal
        isOpen={isExcludeReferredPatientModalOpen}
        onClose={() => {
          onExcludeReferredPatientModalClose()
        }}
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>紹介患者を除外しますか?</ModalHeader>
          <ModalBody>
            <FormControl>
              <Text>紹介患者を被験者候補への組み入れから「除外」します。</Text>
              <Spacer p={2} />
              <HStack spacing={6}>
                <Text>{`No：${numberingID}`}</Text>
                <Text>{`${age}歳`}</Text>
                <Text>{`${genderList.find(g => g.gender === gender)?.label}`}</Text>
                <Text>{`${patientHospitalName}`}</Text>
              </HStack>
              <Spacer p={2} />
              <WarningMessage message="この操作は取り消しできません。対象をご確認の上実行してください。" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="red"
              onClick={async () => {
                await excludeRefer({
                  input: {
                    referredPatientReferUid: referredPatientReferUid,
                  },
                })
              }}
            >
              除外する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
