import dayjs from 'dayjs'

import {
  QuestionnaireAnswer,
  QuestionnaireAnswerAnswerStatus,
  QuestionnaireAnswerStatus,
} from './entity'
import { fixture as patientFixture } from '../patient/fixture'
import { fixtures as questionnaireFixtures } from '../questionnaire/fixture'
import { fixtures as questionnaireSectionAnswerFixtures } from '../questionnaire-section-answer/fixture'
import { fixtures as scheduleFixtures } from '../schedule/fixture'

export const fixture: QuestionnaireAnswer = {
  uid: 'qa-uid-1',
  questionnaireUid: questionnaireFixtures[0].uid,
  scheduleUid: 'dummy-end',
  patientUid: patientFixture.uid,
  title: questionnaireFixtures[0].title,
  displayName: questionnaireFixtures[0].displayName,
  status: QuestionnaireAnswerStatus.Unanswered,
  answerStatus: QuestionnaireAnswerAnswerStatus.BeforeAnswer,
  shouldDeliverAfterTrial: scheduleFixtures[0].shouldDeliverAfterTrial,
  answeredAt: null,
  answerStartDate: dayjs(patientFixture.answerStartDate!)
    .add(
      scheduleFixtures[0].startAfterDays -
        scheduleFixtures[0].availableFromDays,
    )
    .format('2022/01/01'),
  answerEndDate: dayjs(patientFixture.answerStartDate!)
    .add(
      scheduleFixtures[0].startAfterDays +
        scheduleFixtures[0].availableUpToDays,
    )
    .format('2022/01/01'),
  sectionAnswers: questionnaireSectionAnswerFixtures,
}

export const fixtures: QuestionnaireAnswer[] = [
  {
    uid: 'qa-uid-1',
    questionnaireUid: questionnaireFixtures[0].uid,
    scheduleUid: scheduleFixtures[0].uid,
    patientUid: patientFixture.uid,
    title: questionnaireFixtures[0].title,
    displayName: questionnaireFixtures[0].displayName,
    status: QuestionnaireAnswerStatus.Unanswered,
    answerStatus: QuestionnaireAnswerAnswerStatus.BeforeAnswer,
    shouldDeliverAfterTrial: scheduleFixtures[0].shouldDeliverAfterTrial,
    answeredAt: null,
    answerStartDate: dayjs(patientFixture.answerStartDate!)
      .add(
        scheduleFixtures[0].startAfterDays -
          scheduleFixtures[0].availableFromDays,
      )
      .format('2022/01/01'),
    answerEndDate: dayjs(patientFixture.answerStartDate!)
      .add(
        scheduleFixtures[0].startAfterDays +
          scheduleFixtures[0].availableUpToDays,
      )
      .format('2022/01/01'),
    sectionAnswers: questionnaireSectionAnswerFixtures,
  },
  {
    uid: 'qa-uid-2',
    questionnaireUid: questionnaireFixtures[1].uid,
    scheduleUid: scheduleFixtures[1].uid,
    patientUid: patientFixture.uid,
    title: questionnaireFixtures[1].title,
    displayName: questionnaireFixtures[1].displayName,
    status: QuestionnaireAnswerStatus.Unanswered,
    answerStatus: QuestionnaireAnswerAnswerStatus.BeforeAnswer,
    shouldDeliverAfterTrial: scheduleFixtures[1].shouldDeliverAfterTrial,
    answeredAt: null,
    answerStartDate: dayjs(patientFixture.answerStartDate!)
      .add(
        scheduleFixtures[1].startAfterDays -
          scheduleFixtures[1].availableFromDays,
      )
      .format('2022/01/01'),
    answerEndDate: dayjs(patientFixture.answerStartDate!)
      .add(
        scheduleFixtures[1].startAfterDays +
          scheduleFixtures[1].availableUpToDays,
      )
      .format('2022/01/01'),
    sectionAnswers: questionnaireSectionAnswerFixtures,
  },
  {
    uid: 'qa-uid-3',
    questionnaireUid: questionnaireFixtures[2].uid,
    scheduleUid: scheduleFixtures[2].uid,
    patientUid: patientFixture.uid,
    title: questionnaireFixtures[2].title,
    displayName: questionnaireFixtures[2].displayName,
    status: QuestionnaireAnswerStatus.Unanswered,
    answerStatus: QuestionnaireAnswerAnswerStatus.Unanswered,
    shouldDeliverAfterTrial: scheduleFixtures[2].shouldDeliverAfterTrial,
    answeredAt: null,
    answerStartDate: dayjs(patientFixture.answerStartDate!)
      .add(
        scheduleFixtures[2].startAfterDays -
          scheduleFixtures[2].availableFromDays,
      )
      .format('2022/01/01'),
    answerEndDate: dayjs(patientFixture.answerStartDate!)
      .add(
        scheduleFixtures[2].startAfterDays +
          scheduleFixtures[2].availableUpToDays,
      )
      .format('2022/01/01'),
    sectionAnswers: questionnaireSectionAnswerFixtures,
  },
]
