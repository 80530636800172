import { useCallback } from 'react'

import { stringToNum, useQuery } from 'src/modules/router'

import { PatientStatusFilterCondition } from './search/status-filter'
import {
  PatientSortType,
  SortKey,
  sortKeys,
  SortOrder,
  sortOrders,
} from './sort'

const queryParamKeys = {
  page: 'page',
  searchQuery: 'q',
  sortKey: 'sort',
  sortOrder: 'order',
  statusFilter: 'status',
} as const

type GetQueryParamInput =
  | PaginationInput
  | SortInput
  | SearchInput
  | StatusFilterInput

type PaginationInput = { type: 'pagination'; pageNum: number }
type SortInput = { type: 'sort'; sortType: PatientSortType }
type SearchInput = { type: 'search'; searchQuery: string }
type StatusFilterInput = {
  type: 'status'
  condition: PatientStatusFilterCondition
}

export const usePatientQuery = () => {
  const query = useQuery()

  const currentPage = stringToNum(query.get(queryParamKeys.page)) ?? 1
  const currentSearchQuery = query.get(queryParamKeys.searchQuery) ?? ''
  const currentStatusFilter = toStatusFilter(
    query.get(queryParamKeys.statusFilter),
  )
  const currentSortKey = toSortKey(query.get(queryParamKeys.sortKey))
  const currentSortOrder = toSortOrder(query.get(queryParamKeys.sortOrder))

  const searchQueryExists = !!query.get(queryParamKeys.searchQuery)
  const statusFilterExists = !!query.get(queryParamKeys.statusFilter)
  const sortQueryExists =
    !!query.get(queryParamKeys.sortKey) && !!query.get(queryParamKeys.sortOrder)

  //TODO: テスト追加（URLの取得をうまくmockする）
  const getQuery = useCallback(
    (input: GetQueryParamInput) => {
      if (input.type === 'pagination') {
        return generateQuery({
          page: input.pageNum,
          searchQuery: searchQueryExists ? currentSearchQuery : undefined,
          statusFilter: statusFilterExists ? currentStatusFilter : undefined,
          sortType: sortQueryExists
            ? { key: currentSortKey, order: currentSortOrder }
            : undefined,
        })
      }

      //pagination以外ではページを指定しない(2ページ目以降で行った場合は1ページ目に戻る)。
      //ページ以外についてはqueryパラメータに含まれるものを引き継ぐ

      if (input.type === 'search') {
        return generateQuery({
          page: undefined,
          searchQuery: input.searchQuery,
          statusFilter: statusFilterExists ? currentStatusFilter : undefined,
          sortType: sortQueryExists
            ? { key: currentSortKey, order: currentSortOrder }
            : undefined,
        })
      }

      if (input.type === 'status') {
        return generateQuery({
          page: undefined,
          searchQuery: searchQueryExists ? currentSearchQuery : undefined,
          statusFilter: input.condition,
          sortType: sortQueryExists
            ? { key: currentSortKey, order: currentSortOrder }
            : undefined,
        })
      }

      if (input.type === 'sort') {
        return generateQuery({
          page: undefined,
          searchQuery: searchQueryExists ? currentSearchQuery : undefined,
          statusFilter: statusFilterExists ? currentStatusFilter : undefined,
          sortType: input.sortType,
        })
      }

      const n: never = input
      return n
    },
    [
      searchQueryExists,
      statusFilterExists,
      currentSearchQuery,
      currentStatusFilter,
      currentSortKey,
      currentSortOrder,
      sortQueryExists,
    ],
  )

  return {
    page: currentPage,
    searchQuery: currentSearchQuery,
    sortKey: currentSortKey,
    sortOrder: currentSortOrder,
    statusFilter: currentStatusFilter,

    getQuery,
  }
}

const toSortKey = (str: string | null) =>
  !!str ? (str as SortKey) : sortKeys.diseaseUid

const toSortOrder = (str: string | null) =>
  !!str ? (str as SortOrder) : sortOrders.desc

const toStatusFilter = (str: string | null): PatientStatusFilterCondition =>
  !!str ? (str as PatientStatusFilterCondition) : 'all'

const generateQuery = ({
  page,
  searchQuery,
  sortType,
  statusFilter,
}: {
  searchQuery: string | undefined
  page: number | undefined
  sortType: PatientSortType | undefined
  statusFilter: PatientStatusFilterCondition | undefined
}) => {
  const params: string[] = []

  if (page) {
    params.push(`${queryParamKeys.page}=${page}`)
  }
  if (searchQuery) {
    params.push(
      `${queryParamKeys.searchQuery}=${encodeURIComponent(searchQuery)}`,
    )
  }
  if (sortType) {
    params.push(`${queryParamKeys.sortKey}=${sortType.key}`)
    params.push(`${queryParamKeys.sortOrder}=${sortType.order}`)
  }
  if (statusFilter) {
    params.push(`${queryParamKeys.statusFilter}=${statusFilter}`)
  }

  return '?' + params.join('&')
}
