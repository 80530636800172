import { HttpResponse } from 'msw'
import {
  createCreateWorksheetFieldStickyPayloadMock,
  CreateWorksheetFieldStickyDocument,
  CreateWorksheetFieldStickyMutation,
  CreateWorksheetFieldStickyMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const createWorksheetFieldStickyHandler = graphQLHandlerFactory<
  CreateWorksheetFieldStickyMutation,
  CreateWorksheetFieldStickyMutationVariables
>('mutation', CreateWorksheetFieldStickyDocument, () => {
  return HttpResponse.json({
    data: {
      createWorksheetFieldSticky: createCreateWorksheetFieldStickyPayloadMock(),
    },
  })
})
