import { QuestionnaireSection } from '../questionnaire-section/entity'

export enum QuestionnaireStatus {
  Invalid = 0,
  Draft,
  Confirmed,
  Released,
}

export type Questionnaire = {
  uid: string
  title: string
  displayName: string
  status: QuestionnaireStatus
  registeredAt: string
  releasedAt: string | null

  sections: QuestionnaireSection[]

  scheduleUids: string[]
}
