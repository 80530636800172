import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { GqlError, ReEditWorksheetDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { Worksheet, WorksheetDetail } from '../../types'
import { parseGqlWorksheetResponse } from '../parser'

const reEditWorksheet = async ({
  worksheetUid,
  reason,
}: {
  worksheetUid: Worksheet['uid']
  reason?: string
}): Promise<WorksheetDetail> => {
  try {
    const res = await graphqlRequest(ReEditWorksheetDocument, {
      input: {
        worksheetUid,
        reason: reason ?? '',
      },
    })
    return parseGqlWorksheetResponse(res.reEditWorksheet.worksheet)
  } catch (error) {
    throw error
  }
}

export const useReEditWorksheet = (
  option?: UseMutationOption<WorksheetDetail, GqlError>,
) => {
  return useMutation(
    ({
      worksheetUid,
      reason,
    }: {
      worksheetUid: Worksheet['uid']
      reason?: string
    }) => reEditWorksheet({ worksheetUid, reason }),
    option,
  )
}
