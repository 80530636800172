import { AddExplanationRoomEventDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type SessionUidParam = {
  explanationSessionUid: string
  explanationPreSessionUid?: undefined
  trialHospitalUid: string
}
type PreSessionUidParam = {
  explanationPreSessionUid: string
  explanationSessionUid?: undefined
  trialHospitalUid: string
}

export type RoomUidParam = SessionUidParam | PreSessionUidParam

export type AddExplanationRoomEventParam = RoomUidParam &
  (
    | {
        actorType: 'Member'
        trialMemberUid: string
      }
    | {
        actorType: 'Patient'
        explanationPatientUid: string
      }
  ) &
  (
    | {
        eventType: 'CurrentLocation'
        path?: string
        page?: number
        deleted?: boolean
      }
    | {
        eventType: 'PointLocation'
        path: string
        page?: number
      }
    | {
        eventType: 'RequestSign'
        explanationDocRevisionUids: string[]
        signerActorUid: string
        deviceActorUid: string
        signType: 'OnScreen' | 'SendLink'
      }
    | {
        eventType: 'ActiveDevice'
        deviceActorUid: string
        deviceActorTrialHospitalUid: string
        deleted?: boolean
      }
    | {
        eventType: 'RequestPinSetting'
        deviceActorUid: string
        deviceActorTrialHospitalUid?: string
      }
  )

export const addExplanationRoomEvent = async (
  param: AddExplanationRoomEventParam,
): Promise<void> => {
  try {
    await graphqlRequest(AddExplanationRoomEventDocument, {
      input: {
        explanationSessionUid: param.explanationSessionUid,
        explanationPreSessionUid: param.explanationPreSessionUid,
        trialHospitalUid: param.trialHospitalUid,
        explanationPatientUid:
          param.actorType === 'Patient'
            ? param.explanationPatientUid
            : undefined,
        trialMemberUid:
          param.actorType === 'Member' ? param.trialMemberUid : undefined,
        currentLocationEvent:
          param.eventType === 'CurrentLocation'
            ? {
                path: param.path,
                page: param.page,
                deleted: param.deleted,
              }
            : undefined,
        pointLocationEvent:
          param.eventType === 'PointLocation'
            ? {
                path: param.path,
                page: param.page,
              }
            : undefined,
        requestSignEvent:
          param.eventType === 'RequestSign'
            ? {
                explanationDocRevisionUids: param.explanationDocRevisionUids,
                signerActorUid: param.signerActorUid,
                deviceActorUid: param.deviceActorUid,
                signType: param.signType,
              }
            : undefined,
        activeDeviceEvent:
          param.eventType === 'ActiveDevice'
            ? {
                deviceActorUid: param.deviceActorUid,
                deviceActorTrialHospitalUid: param.deviceActorTrialHospitalUid,
                deleted: param.deleted,
              }
            : undefined,
        requestPinSettingEvent:
          param.eventType === 'RequestPinSetting'
            ? {
                deviceActorUid: param.deviceActorUid,
              }
            : undefined,
      },
    })
  } catch (error) {
    throw error
  }
}
