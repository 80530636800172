import { Fragment } from 'react'

import { Center, Spinner } from '@chakra-ui/react'
import { RedirectLogin } from 'src/components/RedirectLogin/RedirectLogin'
import { SelectTrialContainer } from 'src/modules/dashboard/select-trial/select-trial-container'

import { AuthenticatedAccountProvider } from '../../context'
import { useAuth } from '../../hooks'

export const Authenticator: React.FC<{
  children: React.ReactNode
  shouldSelectTrial?: boolean
}> = ({ children, shouldSelectTrial = true }) => {
  const authState = useAuth()

  if (authState.state === 'Challenging') {
    return (
      <Center w="100vw" h="100vh">
        <Spinner />
      </Center>
    )
  }

  if (authState.state === 'Failed') {
    return <RedirectLogin />
  }

  if (!shouldSelectTrial) {
    return <>{children}</>
  }

  if (!authState.account.hasSelectedTrial) {
    return <SelectTrialContainer redirectWithPathSelection />
  }

  return (
    <AuthenticatedAccountProvider account={authState.account}>
      {children}
    </AuthenticatedAccountProvider>
  )
}
