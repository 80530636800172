import { useState, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { actions as spinnerActions } from '../screen-spinner/redux'

type RequestOption = {
  withSpinner: boolean
}

export const useRequestState = (options?: Partial<RequestOption>) => {
  const [requesting, setRequesting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()

  const withSpinner = !!options?.withSpinner

  const requestStarted = useCallback(() => {
    if (withSpinner) {
      dispatch(spinnerActions.show({}))
    }

    setRequesting(true)
    setErrorMessage('')
  }, [dispatch, withSpinner])

  const requestDone = useCallback(() => {
    if (withSpinner) {
      dispatch(spinnerActions.hide({}))
    }

    setRequesting(false)
  }, [dispatch, withSpinner])

  const requestFailed = useCallback(
    (message: string) => {
      if (withSpinner) {
        dispatch(spinnerActions.hide({}))
      }

      setRequesting(false)
      setErrorMessage(message)
    },
    [dispatch, withSpinner],
  )

  const clearErrorMessage = useCallback(() => setErrorMessage(''), [])

  return {
    requesting,
    errorMessage,

    requestStarted,
    requestDone,
    requestFailed,
    clearErrorMessage,
  }
}
