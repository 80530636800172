import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const challengeSignAuthHandler = restHandlerFactory(
  '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/challenge',
  'post',
  () =>
    HttpResponse.json({
      docuSignUrl: 'https://example.com',
      signAuthUid: generateUUID(),
      status: 'New',
      urlStatus: 'OK',
      trialName: 'trialName',
      documentName: 'documentName',
    }),
)
