import { createApiClient } from 'src/lib/api-client'
import { definitions } from 'src/lib/api-client/schema.gen'
import useSWR, { SWRConfiguration } from 'swr'

import { trialCacheKey } from '../cacheKey'

type GetFeatureFlagsRes = definitions['output.GetFeatureFlags']['featureFlags']

const getFeatureFlags = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<GetFeatureFlagsRes> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/feature-flags',
    httpMethod: 'get',
    params: {
      paths: {
        trial_uid: trialUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data.featureFlags
}

export const useFeatureFlags = ({
  trialUid,
  ...option
}: { trialUid: string | null } & SWRConfiguration<
  GetFeatureFlagsRes,
  Error
>) => {
  return useSWR(
    trialUid === null ? null : trialCacheKey.getFeatureFlags(trialUid),
    () => {
      if (trialUid === null) {
        throw new Error('trialUid is null')
      }
      return getFeatureFlags({ trialUid })
    },
    option,
  )
}
