import { Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import MirohaLogoFull from 'src/assets/image/miroha-logo-full.svg'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { routes } from 'src/modules/routes'
import styled from 'styled-components'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${blue[5]};
`

const ContentContainer = styled.div`
  width: 80%;
  max-width: 600px;
  padding: 60px 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${white};
  border-radius: 10px;
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RequiredLogin = () => {
  const navigate = useNavigate()

  return (
    <PageContainer>
      <img src={MirohaLogoFull} alt="MiROHA icon" />
      <Spacer size={60} />
      <ContentContainer>
        <Text fontSize="lg" fontWeight="bold">
          再ログインのお願い
        </Text>

        <Spacer size={40} />

        <Text fontSize="sm">
          再ログインが必要です。ログイン画面に戻り、再度ログインをしてください。
        </Text>

        <Spacer size={40} />

        <BtnContainer>
          <Button
            size="S"
            text="ログインページへ移動"
            onClick={() => navigate(routes.login)}
            buttonType="important"
          ></Button>
        </BtnContainer>
      </ContentContainer>
    </PageContainer>
  )
}
