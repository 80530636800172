import React, { useState } from 'react'

import { Seek } from 'src/components/__legacy__icon/monochrome'
import { blue, yellow } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import { SearchPane } from './pane'
import { usePatientQuery } from '../use-patient-query'

export const SearchPatientButton: React.FC = () => {
  const { searchQuery, statusFilter } = usePatientQuery()
  const [searchPaneOpen, setSearchPaneOpen] = useState(false)

  //ソートのみ行っているケースは無視する
  const active = !!searchQuery || statusFilter !== 'all'

  const openSearchPane = () => setSearchPaneOpen(true)
  const closeSearchPane = () => setSearchPaneOpen(false)

  return (
    <div>
      <Button onClick={openSearchPane}>
        <Seek color={active ? yellow[70] : blue[70]} />
        <Spacer size={6} horizontal />
        検索/絞り込み
      </Button>
      {searchPaneOpen && <SearchPane onClose={closeSearchPane} />}
    </div>
  )
}

const Button = styled.button`
  outline: none;
  border: unset;
  background: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: ${blue[70]};
  &:hover {
    color: ${blue[40]};
  }
  &:active {
    color: ${blue[10]};
  }
`
