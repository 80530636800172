import React from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { BackToDraftModalContainer } from './components/modal-container'

export const BackToDraftButton: React.FC = () => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <>
      <Button
        size="S"
        text="一時保存に戻す"
        buttonType="normal"
        onClick={openModal}
      />
      {modalOpen && <BackToDraftModalContainer closeModal={closeModal} />}
    </>
  )
}
