// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgMessageWarning = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M16.65 12.8 9.971 1.3c-.399-.8-1.695-.8-2.193 0l-6.68 11.5c-.199.4-.199.9 0 1.2.2.4.599.6 1.097.6h13.259c.399 0 .897-.2 1.096-.6.4-.3.4-.8.1-1.2Z"
    />
    <path
      fill="#fff"
      d="M8.974 4.3c.599 0 .997.4.997 1v3c0 .6-.398 1-.997 1-.598 0-.996-.4-.996-1v-3a1 1 0 0 1 .996-1ZM8.974 13c.66 0 1.196-.538 1.196-1.2 0-.663-.535-1.2-1.196-1.2-.66 0-1.196.537-1.196 1.2 0 .662.535 1.2 1.196 1.2Z"
    />
  </svg>
)
export default SvgMessageWarning
