import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  UpdateExpMembersDocument,
  UpdateExpMembersInput,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const updateExpMembers = async (input: UpdateExpMembersInput) => {
  try {
    await graphqlRequest(UpdateExpMembersDocument, {
      input,
    })
  } catch (error) {
    throw error
  }
}

export const useUpdateExpMembers = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    (input: UpdateExpMembersInput) => updateExpMembers(input),
    option,
  )
}
