import React, { useContext } from 'react'

import { HStack, Stack, Text } from '@chakra-ui/react'
import {
  DataType,
  ItemType,
  SmallItem,
} from 'src/modules/entities/small-item/entity'
import {
  itemTypeToDateFormat,
  itemTypeToTextLabel,
} from 'src/modules/entities/small-item/util'
import { itemTypeToText, rolesToText } from 'src/modules/text'
import styled from 'styled-components'

import { SortOnlyChoiceComponent } from './sort-only-choice'
import {
  hasChoice,
  isDateAndTimeType,
  isLabelTypeWithEdc,
  isTextType,
} from '../../../../../../../../util/item-type-classification'
import { CardContext } from '../context'

type Props = {
  smallItem: SmallItem
  v2: boolean
}

const ItemContainer = styled.div`
  width: 100%;
  margin: 0 0 30px;
`

export const SortOnlyNestedSmallItem: React.FC<Props> = props => {
  const { smallItem, v2 } = props

  const category = smallItem.dataType

  return (
    <Stack
      spacing="5"
      border="1px"
      borderColor="gray.100"
      p="5"
      borderRadius="base"
    >
      <LabelAndValue label="フィールド名" value={smallItem.title} />
      {!v2 && (
        <HStack spacing="12">
          <LabelAndValue
            label="ロール"
            value={rolesToText(smallItem.inputRoles)}
          />

          <LabelAndValue
            label="EDC連携"
            value={smallItem.dataType === DataType.Clinical ? '有り' : '無し'}
          />
        </HStack>
      )}
      <HStack>
        {category === DataType.Clinical && <EDCItems smallItem={smallItem} />}
        <LabelAndValue label="フィールドの説明" value={smallItem.description} />
      </HStack>

      <HStack spacing="12">
        <LabelAndValue
          label="回答タイプ"
          value={
            smallItem.itemType === ItemType.File && smallItem.isCertifiedCopy
              ? `${itemTypeToText(smallItem.itemType)}（Certified Copyとして扱う）`
              : itemTypeToText(smallItem.itemType)
          }
        />

        {isDateAndTimeType(smallItem) && (
          <LabelAndValue
            label="フォーマット"
            value={itemTypeToDateFormat(smallItem.itemType)}
          />
        )}

        {isTextType(smallItem) && (
          <LabelAndValue
            label="フォーマット"
            value={itemTypeToTextLabel(smallItem.itemType)}
          />
        )}
      </HStack>

      {hasChoice(smallItem) && <OptionsItem smallItem={smallItem} v2={v2} />}

      {smallItem.itemType === ItemType.Number && (
        <LabelAndValue label="入力する数値の単位" value={smallItem.unit} />
      )}

      {isLabelTypeWithEdc(smallItem) && (
        <LabelAndValue label="EDC連携テキスト" value={smallItem.value ?? ''} />
      )}
    </Stack>
  )
}

type EDCItemsProps = {
  smallItem: SmallItem
}

const EDCItems: React.FC<EDCItemsProps> = EDCItemsProps => {
  const { smallItem } = EDCItemsProps

  return <LabelAndValue label="EDCフィールド名" value={smallItem.edcDataItem} />
}

type OptionsItemProps = {
  smallItem: SmallItem
  v2: boolean
}

const OptionsItem: React.FC<OptionsItemProps> = optionsItemProps => {
  const { smallItem, v2 } = optionsItemProps

  const {
    smallItemMap,
    onChangeChoice,
    onRemoveChoice,
    onAddSmallItemToChoice,
  } = useContext(CardContext)

  return (
    <ItemContainer>
      <Text fontWeight="bold" fontSize="sm" color="gray.600">
        回答の選択肢
      </Text>
      {smallItem.choices &&
        smallItem.choices.map((choice, choiceIndex) => (
          <SortOnlyChoiceComponent
            key={choice.uid}
            isClinical={smallItem.dataType === DataType.Clinical}
            smallItems={
              choice.smallItemUids
                ? choice.smallItemUids.map(uid => smallItemMap[uid])
                : []
            }
            choice={choice}
            itemType={smallItem.itemType}
            v2={v2}
            onChange={({ values }) =>
              onChangeChoice({
                smallItemUid: smallItem.uid,
                choiceIndex,
                values,
              })
            }
            onRemove={() =>
              onRemoveChoice({ smallItemUid: smallItem.uid, choiceIndex })
            }
            onAddSmallItem={() =>
              onAddSmallItemToChoice({
                smallItemUid: smallItem.uid,
                choiceIndex,
              })
            }
          />
        ))}
    </ItemContainer>
  )
}

const LabelAndValue: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <Stack>
    <Text fontWeight="bold" fontSize="sm" color="gray.600">
      {label}
    </Text>
    <Text minH="6">{value}</Text>
  </Stack>
)
