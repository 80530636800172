import { selectedTrial } from 'src/modules/util/fixtureUtils'
import { generateUUID } from 'src/utils/generateUUID'

import { Role, Member } from './entity'

export const fixture: Member = {
  uid: 'fooUserUid',
  firstName: '太郎',
  lastName: 'クロン',
  email: 'demo@curon.co',
  role: Role.DM,
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
  deletedAt: null,
  registeredAt: null,
  trialHospitals: { foo001: 'foo病院' },
  trialUid: selectedTrial().uid,
}

export const createFixture = (): Member => {
  return {
    ...fixture,
    uid: generateUUID(),
  }
}
