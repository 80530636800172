import { useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { actions } from 'src/modules/entities/patient/redux'
import { PatientEproUpdateRequest } from 'src/modules/entities/patient/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { getDetailRoute } from '../../routes'

export const useSubmitUpdate = () => {
  const trialUid = useSelector(getSelectedTrial)!.uid
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const navigate = useNavigate()

  const request = useCallback(
    async (patientUid: string, params: PatientEproUpdateRequest) => {
      try {
        requestStarted()

        await dispatch(
          actions.eproUpdate({
            trialUid,
            patientUid,
            params,
          }),
        )
        navigate(getDetailRoute({ trialUid, patientUid }))

        requestDone()
        showSuccess(`患者情報を更新しました。`)
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [
      dispatch,
      navigate,
      requestDone,
      requestFailed,
      requestStarted,
      showSuccess,
      trialUid,
    ],
  )
  return { request, requesting, errorMessage }
}
