import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useQuery } from 'src/modules/router'

import { ICF_DOCUMENT_HOSPITAL_QUERY_PARAM_KEY } from './routes'

export const useIcfDocumentHospital = () => {
  const query = useQuery()
  const selectedTrial = useSelector(getSelectedTrial)!
  const navigate = useNavigate()

  const selectedTrialHospitalUid = useMemo(() => {
    const fromQueryParam = query.get(ICF_DOCUMENT_HOSPITAL_QUERY_PARAM_KEY)
    if (fromQueryParam !== null) {
      return fromQueryParam
    }
    //複数医療機関に紐づく場合（DMなど）は空文字になる
    if (selectedTrial.selectedTrialHospitalUid !== '') {
      return selectedTrial.selectedTrialHospitalUid
    }

    return selectedTrial.trialHospitals[0].uid
  }, [query, selectedTrial])

  const onChangeTrialHospital = useCallback(
    (trialHospitalUid: string) => {
      navigate({
        search: `?${ICF_DOCUMENT_HOSPITAL_QUERY_PARAM_KEY}=${trialHospitalUid}`,
      })
    },
    [navigate],
  )

  return {
    selectedTrialHospitalUid,
    onChangeTrialHospital,
  }
}
