import { useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useIcfDocumentSets } from 'src/features/icfDocument/api'
import { IcfDocumentSet } from 'src/features/icfDocument/types'
import { TrialHospital } from 'src/features/trial/types'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type Props = {
  trialHospitalUid: TrialHospital['uid']
  selectedUids: IcfDocumentSet['uid'][]
  onSubmit: (uids: IcfDocumentSet['uid'][]) => void
  onMissingRequiredDoc: () => void
}

export const SelectDocSets: React.FC<Props> = ({
  trialHospitalUid,
  selectedUids,
  onSubmit,
  onMissingRequiredDoc,
}) => {
  const [currentSelectedUids, setCurrentSelectedUids] = useState(selectedUids)

  const isValidSelectedUidsCount = currentSelectedUids.length <= 10

  const { data: icfDocumentSets } = useIcfDocumentSets({ trialHospitalUid })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onCheck = (uid: IcfDocumentSet['uid'], checked: boolean) => {
    setCurrentSelectedUids(prev => {
      if (checked) {
        return [...prev, uid]
      } else {
        return prev.filter(v => v !== uid)
      }
    })
  }

  if (!icfDocumentSets) return null

  return (
    <>
      <Button colorScheme="blue" variant="outline" onClick={onOpen}>
        文書セットを選択
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>文書セットを選択</ModalHeader>

          <ModalBody>
            {icfDocumentSets
              .filter(set => set.status === 'Enabled' && set.hasDocuments)
              .map(set => (
                <Box key={set.uid}>
                  <Checkbox
                    isChecked={currentSelectedUids.includes(set.uid)}
                    onChange={e => {
                      onCheck(set.uid, e.target.checked)
                    }}
                    w="full"
                    py="3"
                    sx={{
                      '> .chakra-checkbox__label': {
                        fontSize: 'md',
                        ml: 5,
                      },
                    }}
                  >
                    <Text as="span">{set.name}</Text>
                  </Checkbox>
                </Box>
              ))}
            {!isValidSelectedUidsCount && (
              <Text fontSize="sm" color="red.500" mt="2" as="div">
                対象文書セットが最大登録件数（10件）を超えています。
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="blue"
              onClick={() => {
                if (
                  icfDocumentSets
                    .filter(set => currentSelectedUids.includes(set.uid))
                    .every(set => !set.hasRequiredAgreementForm)
                ) {
                  onMissingRequiredDoc()
                }
                onSubmit(currentSelectedUids)
                onClose()
              }}
              isDisabled={!isValidSelectedUidsCount}
            >
              確定
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
