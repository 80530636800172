import { ChoiceAnswer } from 'src/modules/entities/choice-answer/entity'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import {
  MediumItemAnswer,
  MediumItemAnswerStatus,
  SectionConfirmationStatus,
} from 'src/modules/entities/medium-item-answer/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import { Template } from 'src/modules/entities/template/entity'
import { Worksheet } from 'src/modules/entities/worksheet/entity'
import { generateUUID } from 'src/utils/generateUUID'

import {
  DataType,
  ItemType,
  SmallItem,
} from '../../../../../../../entities/small-item/entity'

export const createWorksheetWithLogline = ({
  template,
  patientUid,
  visibilityIconVisible,
}: {
  template: Template
  patientUid: string
  visibilityIconVisible: boolean
}): Worksheet => {
  const worksheetUid = generateUUID()
  return {
    uid: worksheetUid,
    templateUid: template.uid,
    patientUid,
    title: template.title,
    templateFolderUid: template.templateFolderUid,
    videoEnabled: true,
    hasClinicalSmallItem: template.mediumItems
      .flatMap(mi => Object.values(mi.smallItemMap))
      .some(si => si.dataType === DataType.Clinical),
    index: 0,

    mediumItemAnswers: createMediumItemAnswers(
      template,
      worksheetUid,
      visibilityIconVisible,
    ),

    createdAt: template.createdAt,
    updatedAt: template.updatedAt,
  }
}

const createMediumItemAnswers = (
  template: Template,
  worksheetUid: string,
  visibilityIconVisible: boolean,
): MediumItemAnswer[] => {
  return template.mediumItems!!.map(mi =>
    createMediumItemAnswer(mi, visibilityIconVisible, worksheetUid),
  )
}

const createMediumItemAnswer = (
  mediumItem: MediumItem,
  visibilityIconVisible: boolean,
  worksheetUid: string,
  lineNumber?: number,
) => {
  const mediumItemAnswerUid = generateUUID()
  return {
    uid: mediumItemAnswerUid,
    title: mediumItem.title,
    folderName: mediumItem.folderName,
    formName: mediumItem.formName,
    index: mediumItem.index,
    isLogline: mediumItem.isLogline,
    lineNumber: lineNumber ?? 1,
    smallItemAnswerMap: createSmallItemAnswerMap(
      mediumItemAnswerUid,
      mediumItem,
      visibilityIconVisible,
    ),
    mediumItemUid: mediumItem.uid,
    worksheetUid: worksheetUid,
    status: MediumItemAnswerStatus.New,
    hasLog: false,
    confirmationStatus: SectionConfirmationStatus.Pending,
  }
}

const createSmallItemAnswerMap = (
  mediumItemAnswerUid: string,
  mediumItem: MediumItem,
  visibilityIconVisible: boolean,
): Record<string, SmallItemAnswer> => {
  const res: Record<string, SmallItemAnswer> = {}
  const smallItemAnswerUidBySmallItemUid =
    createSmallItemAnswerUidBySmallItemUid(mediumItem.smallItemMap)

  Object.values(mediumItem.smallItemMap).forEach(smallItem => {
    res[smallItemAnswerUidBySmallItemUid[smallItem.uid]] = {
      type: 'preview',
      uid: smallItemAnswerUidBySmallItemUid[smallItem.uid],
      title: smallItem.title,
      description: smallItem.description,
      index: smallItem.index,
      itemType: smallItem.itemType,
      dataType: smallItem.dataType,
      value: '',
      unit: smallItem.unit,
      hasLog:
        smallItem.itemType !== ItemType.Label ||
        smallItem.dataType === DataType.Clinical,
      fixed: false,
      visibilityIconVisible: visibilityIconVisible,
      viewableHospitalUids: smallItem.viewableHospitalUids,
      inputRoles: smallItem.inputRoles,
      choiceAnswers: createChoiceAnswers(
        smallItem,
        smallItemAnswerUidBySmallItemUid,
      ),
      parentUid: smallItem.parentUid,
      smallItemUid: smallItem.uid,
      mediumItemAnswerUid,
    }
  })

  return res
}

const createChoiceAnswers = (
  smallItem: SmallItem,
  smallItemAnswerUidBySmallItemUid: Record<string, string>,
): ChoiceAnswer[] => {
  return smallItem.choices.map(choice => {
    return {
      uid: generateUUID(),
      description: choice.description,
      checked: false,

      choiceUid: choice.uid,
      smallItemAnswerUid: smallItemAnswerUidBySmallItemUid[smallItem.uid],
      smallItemAnswerUids: choice.smallItemUids.map(
        suid => smallItemAnswerUidBySmallItemUid[suid],
      ),
    }
  })
}

const createSmallItemAnswerUidBySmallItemUid = (
  smallItemMap: Record<string, SmallItem>,
) => {
  const res: Record<string, string> = {}

  Object.values(smallItemMap).forEach(smallItem => {
    res[smallItem.uid] = generateUUID()
  })

  return res
}
