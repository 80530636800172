import content from 'src/assets/terms/privacyPolicy.md'
import { Markdown } from 'src/components/Markdown/Markdown'
import { castMarkdownContent } from 'src/utils/markdown'

import { SimpleScreenLayout } from '../SimpleScreenLayout/SimpleScreenLayout'

export const PrivacyPolicyScreen: React.FC = () => {
  return (
    <SimpleScreenLayout>
      <Markdown content={castMarkdownContent(content)} />
    </SimpleScreenLayout>
  )
}
