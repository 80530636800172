import { Box, Text } from '@chakra-ui/react'
import { Field, LabelTypeDef } from '@micin-jp/chicken-schema'

type Props = {
  labelField: Field & {
    typeDef: LabelTypeDef
  }
}

export const WorksheetLabelField: React.FC<Props> = ({ labelField }) => {
  return (
    <Box w="full" as="section">
      <Text fontSize="sm" color="gray.600" fontWeight="medium">
        {labelField.name}
      </Text>
      {/* TODO: show value */}
    </Box>
  )
}
