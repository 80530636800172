import { GraphQLError } from 'graphql'
import { ClientError } from 'graphql-request'

// chicken-apiで返却するGraphQL用エラーの型
// see: https://github.com/micin-jp/chicken-api/blob/main/cmd/api/controllers/00_error.go

const UNKNOWN_ERROR_MESSAGE =
  '問題が発生しました。時間を置いて再度お試しください。'

export class GqlError extends Error {
  public readonly name: 'GqlError'
  public readonly status?: number
  constructor(
    message: string = UNKNOWN_ERROR_MESSAGE,
    public readonly path?: GraphQLError['path'],
    public readonly extensions?: GqlErrorExtensions,
  ) {
    super(message)
    this.name = 'GqlError'
    this.status = extensions?.status
  }
}

export type GqlErrorExtensions = {
  status: number
  validationDetails?: GqlErrorValidationDetail[]
}

export type GqlErrorValidationDetail = {
  key: string
  message: string
}

const gqlUnknownError = new GqlError(UNKNOWN_ERROR_MESSAGE)

// 予期せぬエラーをパースする。HTTPのステータスコードをマッピングする
const parseUnexpectedError = (err: ClientError): GqlError => {
  const message =
    'message' in err.response && typeof err.response.message === 'string'
      ? err.response.message
      : UNKNOWN_ERROR_MESSAGE
  const extensions: GqlErrorExtensions = {
    status: err.response.status,
  }
  return new GqlError(message, undefined, extensions)
}

const parseGraphQLError = (err: GraphQLError): GqlError => {
  const message = err.message
  const path = err.path
  const extensions = err.extensions as GqlErrorExtensions

  return new GqlError(message, path, extensions)
}

/** graphql-requestのエラーから、定義済みエラーにパースする
 *
 * @remarks
 * GraphQLのエラーは複数ある場合があるが、この関数では最初のエラーのみをパースして返す
 */
export const parseGqlError = (err: unknown): GqlError => {
  if (import.meta.env.NODE_ENV !== 'test') {
    console.error(err)
  }
  if (!(err instanceof ClientError)) {
    return gqlUnknownError
  }
  if (!err.response.errors || err.response.errors.length === 0) {
    return parseUnexpectedError(err)
  }
  const firstError = err.response.errors[0]
  return parseGraphQLError(firstError)
}

/** graphql-requestのエラーから、定義済みエラーにパースする
 *
 * @remarks
 * エラーが複数ある場合は複数のエラーを返す
 *
 */
export const parseMultipleGqlErrors = (err: unknown): GqlError[] => {
  if (import.meta.env.NODE_ENV !== 'test') {
    console.error(err)
  }
  if (!(err instanceof ClientError)) {
    return [gqlUnknownError]
  }
  if (!err.response.errors || err.response.errors.length === 0) {
    return [parseUnexpectedError(err)]
  }
  return err.response.errors.map(parseGraphQLError)
}
