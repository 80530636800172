import React from 'react'

import { usePane } from 'src/hooks/use-pane'
import { Schedule } from 'src/modules/entities/schedule/entity'

import { EditContainer } from './components/edit-container'

type Props = {
  schedule: Schedule
  renderTitle: (props: { onClick: () => void }) => JSX.Element
}
export const WithEditPane: React.FC<Props> = props => {
  const {
    handlers: { openPane, closePane },
    renderPane,
  } = usePane({ paneType: 'normal', closeOnOutsideClick: true })

  return (
    <>
      {props.renderTitle({ onClick: openPane })}
      {renderPane(
        <EditContainer schedule={props.schedule} closePane={closePane} />,
      )}
    </>
  )
}
