import { useCallback, useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { icfDocumentUidParamName } from '../../routes'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import {
  fetchIcfDocument,
  fetchIcfDocumentRevision,
  getFilePreviewRequestUrl,
} from '../request'

export const useRevisionHistory = () => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { icfDocumentUid = '' } = useParams<{
    [icfDocumentUidParamName]: string
  }>()

  const [selectedRevisionUid, setSelectedRevisionUid] = useState<string>()

  const { data: icfDocument } = useSWR(
    selectedTrialHospitalUid !== undefined
      ? swrKeys.fetchIcfDocument({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentUid,
        })
      : null,
    () =>
      fetchIcfDocument({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentUid: icfDocumentUid,
      }),
  )

  const { data: selectedRevision } = useSWR(
    selectedTrialHospitalUid && selectedRevisionUid
      ? swrKeys.fetchIcfDocumentRevision({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: selectedRevisionUid,
        })
      : null,
    () =>
      fetchIcfDocumentRevision({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentRevisionUid: selectedRevisionUid ?? '',
      }),
  )

  useEffect(() => {
    if (!icfDocument) {
      return
    }
    if (icfDocument.revisions.length === 0) {
      return
    }

    //revisionsは承認日降順で返ってくる
    setSelectedRevisionUid(icfDocument.revisions[0].uid)
  }, [icfDocument])

  const onChangeSelectedRevisionUid = useCallback((uid: string) => {
    setSelectedRevisionUid(uid)
  }, [])

  const filePreviewUrl = useMemo(() => {
    if (!selectedRevisionUid || !selectedTrialHospitalUid) {
      return ''
    }

    return getFilePreviewRequestUrl({
      trialUid,
      trialHospitalUid: selectedTrialHospitalUid,
      icfDocumentRevisionUid: selectedRevisionUid,
    })
  }, [selectedRevisionUid, selectedTrialHospitalUid, trialUid])

  return {
    icfDocument,
    selectedRevision,
    selectedRevisionUid,
    onChangeSelectedRevisionUid,
    filePreviewUrl,
  }
}
