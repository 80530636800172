import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { MediumItemAnswer } from 'src/modules/entities/medium-item-answer/entity'
import { WorksheetStatus } from 'src/modules/entities/worksheet/entity'
import { RootState } from 'src/modules/reducer'
import { hasClinicalSmallItem } from 'src/modules/util/has-clinical'
import styled from 'styled-components'

import { ConfirmButton } from './confirm/button'
import { SaveSectionButton } from './save-section/save-section-button/save-section-button'
import { useSaveSection } from './save-section/use-save-section'
import { getRootSmallItemAnswers } from './selector'
import { SmallItemAnswerComponent } from './small-item/small-item-answer'
import { MediumItemAnswerLogButton } from '../medium-item-answer-log/button/button'

type Props = {
  mediumItemAnswer: MediumItemAnswer
  worksheetStatus?: WorksheetStatus
  isEnablePatient?: boolean
}

const Container = styled.div``

const TitleContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SingleSection: React.FC<Props> = props => {
  const { mediumItemAnswer, worksheetStatus, isEnablePatient } = props

  const trial = useSelector(getSelectedTrial)!

  const { hasPermission } = usePermission()
  const { canSave, canConfirm } = useSaveSection(mediumItemAnswer.uid)

  const smallItemAnswers = useSelector((state: RootState) =>
    getRootSmallItemAnswers(state, {
      mediumItemAnswerUid: mediumItemAnswer.uid,
    }),
  )

  const edcLinked =
    trial.edcIntegrationEnabled && hasClinicalSmallItem(smallItemAnswers)

  return (
    <Container id={mediumItemAnswer.mediumItemUid}>
      <TitleContainer>
        <Text fontWeight="bold">{mediumItemAnswer.title}</Text>
        <ButtonWrapper>
          {hasPermission(PERMISSIONS.Worksheet_Save) &&
            worksheetStatus !== WorksheetStatus.Disabled && (
              <SaveSectionButton
                mediumItemAnswerUid={mediumItemAnswer.uid}
                disabled={!canSave}
              />
            )}

          <Spacer size={12} horizontal />

          {hasPermission(PERMISSIONS.Worksheet_Submit) &&
            worksheetStatus !== WorksheetStatus.Disabled && (
              <ConfirmButton
                mediumItemAnswerUid={mediumItemAnswer.uid}
                edcLinked={edcLinked}
                disabled={!canConfirm}
                confirmationStatus={mediumItemAnswer.confirmationStatus}
              />
            )}

          <Spacer size={12} horizontal />

          {mediumItemAnswer.hasLog && (
            <MediumItemAnswerLogButton
              mediumItemAnswerUid={mediumItemAnswer.uid}
              edcLinked={edcLinked}
            />
          )}
        </ButtonWrapper>
      </TitleContainer>

      {smallItemAnswers.map(smallItemAnswer => (
        <SmallItemAnswerComponent
          key={smallItemAnswer.uid}
          smallItemAnswer={smallItemAnswer}
          smallItemAnswerMap={mediumItemAnswer.smallItemAnswerMap}
          currentRole={trial.role}
          worksheetStatus={worksheetStatus}
          isEnablePatient={isEnablePatient}
        />
      ))}
    </Container>
  )
}
