import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

import { gray, red, white, yellow } from '../base/color/palette'

// FiXME: どちらかに統一
type InputProps = {
  showWarning?: boolean
  warning?: boolean
}

/**
 * @deprecated Use chakra-ui Input instead.
 */
export const Input = styled.input<SpaceProps & LayoutProps & InputProps>`
  font-size: 14px;
  color: ${gray[100]};
  width: 100%;
  background-color: ${p => (p.warning ? yellow[10] : white)};
  padding: 5px 15px;
  border: 1px solid ${gray[40]};
  border-radius: 5px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${gray[55]};
  }

  &:placeholder-shown {
    background-color: ${p => (p.showWarning ? yellow[10] : white)};
  }

  //FiXME: classNameで制御せずに、エラー状態を表すpropsを受け取るようにする。
  &.error {
    background: ${red[10]};
  }

  &[disabled] {
    opacity: 1; // for ios/iPad safari
    -webkit-text-fill-color: ${gray[100]};
    color: ${gray[100]};
    background-color: ${gray[10]};
    border-color: ${gray[55]};
    &:hover {
      cursor: not-allowed;
    }
    ::placeholder {
      -webkit-text-fill-color: ${gray[55]};
      color: ${gray[55]};
    }
  }

  ${space}
  ${layout}
`
