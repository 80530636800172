import React from 'react'

import { Button } from 'src/components/base/button/button'
import styled from 'styled-components'

import { useOpenModal } from '../../../../../../../../../../../hooks/use-open-modal'
import { SaveSectionModalContainer } from '../save-section-modal/save-section-modal-container'

type Props = {
  mediumItemAnswerUid: string
  disabled: boolean
}

export const SaveSectionButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <Wrapper>
      <Button
        size="S"
        text="一時保存"
        onClick={openModal}
        buttonType="important"
        disabled={props.disabled}
      ></Button>
      {modalOpen && (
        <SaveSectionModalContainer
          mediumItemAnswerUid={props.mediumItemAnswerUid}
          onClose={closeModal}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
