import { useSelectedTrial } from 'src/features/auth/context'
import { Trial, TrialHospital } from 'src/features/trial/types'
import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { IcfDocumentSet } from '../../types'
import { icfDocumentCacheKey } from '../cacheKey'
import { parseIcfDocumentSetListItem } from '../parser'

const getIcfDocumentSets = async ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: Trial['uid']
  trialHospitalUid: TrialHospital['uid']
}): Promise<IcfDocumentSet[]> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/trial_hospitals/{trial_hospital_uid}/icf_document_sets',
    httpMethod: 'get',
    params: {
      paths: {
        trial_uid: trialUid,
        trial_hospital_uid: trialHospitalUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data.map(parseIcfDocumentSetListItem)
}

export const useIcfDocumentSets = ({
  trialHospitalUid,
  ...option
}: {
  trialHospitalUid: TrialHospital['uid']
} & SWRConfiguration<IcfDocumentSet[], Error>) => {
  const { selectedTrial } = useSelectedTrial()
  return useSWR<IcfDocumentSet[], Error>(
    icfDocumentCacheKey.getIcfDocumentSets(trialHospitalUid),
    () =>
      getIcfDocumentSets({
        trialUid: selectedTrial.uid,
        trialHospitalUid: trialHospitalUid,
      }),
    option,
  )
}
