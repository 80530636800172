import { useMemo } from 'react'

import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { getRoute } from './routes'
import { usePatientQuery } from './use-patient-query'
import { trialIdParamName } from '../../trial-detail'

export const usePaginatePatient = (args: {
  totalCount: number
  perPage: number
}) => {
  const { totalCount, perPage } = args
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()
  const { page: currentPage, getQuery } = usePatientQuery()
  const totalPageCount = Math.ceil(totalCount / perPage)

  const hasNextPage = currentPage < totalPageCount
  const hasPrevPage = currentPage > 1

  const navigate = useNavigate()

  const handlers = useMemo(() => {
    return {
      onClickNext: () => {
        if (!hasNextPage) return

        navigate(
          getRoute({
            trialUid,
            queryParam: getQuery({
              type: 'pagination',
              pageNum: currentPage + 1,
            }),
          }),
        )
      },
      onClickBack: () => {
        if (!hasPrevPage) return

        navigate(
          getRoute({
            trialUid,
            queryParam: getQuery({
              type: 'pagination',
              pageNum: currentPage - 1,
            }),
          }),
        )
      },
      onClickTop: () => {
        if (currentPage === 1) return

        navigate(
          getRoute({
            trialUid,
            queryParam: getQuery({ type: 'pagination', pageNum: 1 }),
          }),
        )
      },
      onClickEnd: () => {
        if (currentPage === totalPageCount) return

        navigate(
          getRoute({
            trialUid,
            queryParam: getQuery({
              type: 'pagination',
              pageNum: totalPageCount,
            }),
          }),
        )
      },
    }
  }, [
    hasNextPage,
    navigate,
    trialUid,
    getQuery,
    currentPage,
    hasPrevPage,
    totalPageCount,
  ])

  return {
    currentPage,
    hasNextPage,
    hasPrevPage,
    handlers,
  }
}
