import { useEffect, useMemo, useState } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, RepeatableSectionTypeDef } from '@micin-jp/chicken-schema'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { usePermission } from 'src/features/auth/context'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'

import { useWorksheetFieldStickiesContext } from '../../context/WorksheetFieldStickies'
import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { useStickyQuery } from '../../hooks/useStickyQuery'
import { RepeatableSectionFieldValue } from '../../types'
import { getFlattenFieldMap } from '../../utils/getFlattenFields'
import { getFieldValueText } from '../../utils/getValueText'
import { moveForwardIndex } from '../../utils/moveForwardIndex'
import { RepeatableSectionHistoryButton } from '../RepeatableSectionHistoryButton/RepeatableSectionHistoryButton'
import { WorksheetFieldComponent } from '../WorksheetField/WorksheetField'

type RepeatableSectionField = Field & {
  typeDef: RepeatableSectionTypeDef
}

type Props = {
  repeatableSectionField: RepeatableSectionField
}

const HEADER_ITEM_COUNT = 4

const getShowableFields = (fields: Field[]) => {
  // 4つまで表示。条件付きで表示される項目は除外する。
  return fields
    .filter(f => f.visibleIf === undefined)
    .slice(0, HEADER_ITEM_COUNT)
}

export const WorksheetRepeatableSectionField: React.FC<Props> = ({
  repeatableSectionField,
}) => {
  const { findFieldValues, onAddRepeatableSectionRow, canEdit } =
    useWorksheetValueContext()

  const repeatableSectionRows = findFieldValues({
    fid: repeatableSectionField.fid,
  })

  const { findField } = useWorksheetFieldStickiesContext()

  const { stickyQuery: stickyUid } = useStickyQuery()

  // 付箋を含む行がある場合は、その行を開いた状態にする
  const defaultIndex = repeatableSectionRows?.findIndex(row => {
    if (!stickyUid) return false
    const field = findField(stickyUid)
    if (!field) return false
    return row.index === field.fieldIndex
  })

  const [accordionIndex, setAccordionIndex] = useState<number[]>(
    !!defaultIndex ? [defaultIndex] : [],
  )

  const handleToggle = (index: number) => {
    setAccordionIndex(prevIndex => {
      if (prevIndex.includes(index)) {
        return prevIndex.filter(i => i !== index)
      }
      return [...prevIndex, index]
    })
  }

  // 開きたい付箋がある場合はその行を開く
  useEffect(() => {
    if (!stickyUid) return
    const index = repeatableSectionRows?.findIndex(row => {
      const field = findField(stickyUid)
      if (!field) return false
      if (
        !repeatableSectionField.typeDef.fields.some(f => f.fid === field.fid)
      ) {
        return false
      }
      return row.index === field.fieldIndex
    })
    if (index === undefined || index === -1) return
    setAccordionIndex(prev => {
      if (prev.includes(index)) return prev
      return [...prev, index]
    })
  }, [
    findField,
    repeatableSectionRows,
    repeatableSectionField.typeDef.fields,
    stickyUid,
  ])

  const { hasPermission } = usePermission()

  const toast = useMirohaToast()

  const showableFields = getShowableFields(
    repeatableSectionField.typeDef.fields,
  )

  const showAddRepeatableSectionRowButton =
    canEdit && hasPermission(PERMISSIONS.Worksheet_Edit)

  return (
    <Box as="section">
      <HStack spacing="1.5" align="center">
        <TextWithBar as="h2" fontWeight="bold">
          {repeatableSectionField.name}
        </TextWithBar>
        <RepeatableSectionHistoryButton
          fieldName={repeatableSectionField.name}
          fid={repeatableSectionField.fid}
        />
      </HStack>

      <Flex pl="5" py="2" align="center" justify="space-between">
        <Box w="128px">
          <Text fontWeight="bold">#</Text>
        </Box>
        <Grid
          templateColumns={`repeat(${showableFields.length}, 1fr)`}
          alignItems="center"
          w="full"
        >
          {showableFields.map(field => (
            <GridItem key={field.fid} pr="10">
              {field.typeDef.type !== 'Label' && (
                <Text fontWeight="bold">{field.name}</Text>
              )}
            </GridItem>
          ))}
        </Grid>
        <Flex minW="148px" justify="flex-start">
          {repeatableSectionField.typeDef.fields.length > HEADER_ITEM_COUNT ? (
            <Box fontWeight="bold" w="16px" mr="32px">
              ...
            </Box>
          ) : (
            <Spacer />
          )}
          <Box>
            {showAddRepeatableSectionRowButton && (
              <Button
                variant="outline"
                onClick={() => {
                  onAddRepeatableSectionRow({
                    fid: repeatableSectionField.fid,
                    onSuccess: () => {
                      const nextIndex = repeatableSectionRows?.length ?? 0
                      setAccordionIndex(prevIndex => [...prevIndex, nextIndex])

                      toast({
                        status: 'success',
                        title: '行を追加しました',
                      })
                    },
                  })
                }}
              >
                行の追加
              </Button>
            )}
          </Box>
        </Flex>
      </Flex>

      <Divider />

      <Accordion allowMultiple index={accordionIndex} mt="2">
        <Stack spacing="2">
          {repeatableSectionRows?.map((row, index) => {
            if (row.type !== 'repeatableSection') {
              throw new Error('invalid type')
            }
            return (
              <RepeatableSectionAccordionItem
                key={row.uid}
                field={repeatableSectionField}
                row={row}
                onToggle={() => handleToggle(index)}
              />
            )
          })}
        </Stack>
      </Accordion>
    </Box>
  )
}

type AccordionItemProps = {
  field: RepeatableSectionField
  row: RepeatableSectionFieldValue
  onToggle: () => void
}

const RepeatableSectionAccordionItem: React.FC<AccordionItemProps> = ({
  field,
  row,
  onToggle,
}) => {
  const { findFieldValue, droppedIndex } = useWorksheetValueContext()

  const fieldMap = getFlattenFieldMap(field.typeDef.fields)

  const showableFields = getShowableFields(field.typeDef.fields)

  return (
    <AccordionItem
      borderWidth="1px"
      borderColor={row.isEnabled ? 'gray.300' : 'gray.100'}
      borderRadius="base"
    >
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton
              px="5"
              borderRadius="base"
              py="2.5"
              bg={isExpanded ? 'blue.50' : undefined}
              _hover={{
                bg: isExpanded ? 'blue.50' : 'blackAlpha.50', // blackAlpha is default
                cursor: 'pointer',
              }}
              onClick={onToggle}
            >
              {/* 行が削除されている場合があるので連番で表示されるようにする */}
              <Text
                w="118px"
                color={row.isEnabled ? undefined : 'gray.400'}
                display="flex"
                alignItems="center"
                justifyContent="start"
              >
                {moveForwardIndex(row.index, droppedIndex)}
                {!row.isEnabled && (
                  <Badge px="2" ml="4" color="gray.300" bg="gray.50">
                    無効
                  </Badge>
                )}
              </Text>

              {isExpanded ? (
                <Spacer />
              ) : (
                <Flex w="full">
                  <Grid
                    w="full"
                    templateColumns={`repeat(${showableFields.length}, 1fr)`}
                  >
                    {showableFields.map(field => {
                      const value = findFieldValue({
                        fid: field.fid,
                        index: row.index,
                      })
                      if (field.typeDef.type === 'Label')
                        return (
                          <GridItem
                            key={`$label_${row.index}_${field.fid}`}
                            pr="10"
                          >
                            <Text
                              color={row.isEnabled ? undefined : 'gray.400'}
                            >
                              {field.name}
                            </Text>
                          </GridItem>
                        )
                      if (!value) return <Spacer key={`$spacer_${field.fid}`} />

                      return (
                        <GridItem key={value.uid} pr="10">
                          <Text color={row.isEnabled ? undefined : 'gray.400'}>
                            {getFieldValueText(value, fieldMap)}
                          </Text>
                        </GridItem>
                      )
                    })}
                  </Grid>
                </Flex>
              )}

              <Flex w="168px" gap="36px" justify="flex-end" align="center">
                {isExpanded && (
                  <RepeatableFieldActionButton row={row} field={field} />
                )}
                <AccordionIcon color={row.isEnabled ? undefined : 'gray.400'} />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel
            borderRadius="base"
            bg={isExpanded ? 'blue.50' : undefined}
          >
            <Stack spacing="6">
              {field.typeDef.fields.map(field => (
                <WorksheetFieldComponent
                  key={`field_value_${field.fid}_${row.index}`}
                  field={field}
                  index={row.index}
                />
              ))}
            </Stack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

const RepeatableFieldActionButton = ({
  row,
  field,
}: {
  row: RepeatableSectionFieldValue
  field: RepeatableSectionField
}) => {
  const {
    fieldValueDiffList,
    onDeleteRepeatableSectionRow,
    onDisableRepeatableSectionRow,
    onEnabledRepeatableSectionRow,
    canEdit,
  } = useWorksheetValueContext()

  const toast = useMirohaToast()

  // indexでrepeatableSection内のfieldかを判断 & fieldにおいて一つでも変更されているものがあればdisableは無効
  const cannotClickDisableButton = useMemo(() => {
    const childFids = field.typeDef.fields.map(field => field.fid)
    return fieldValueDiffList.some(({ newValue, isChanged }) => {
      return (
        row.index === newValue.index &&
        childFids.includes(newValue.fid) &&
        isChanged
      )
    })
  }, [row.index, fieldValueDiffList, field.typeDef.fields])

  // 一度も一時保存されていない状態
  if (row.isNew) {
    return (
      <Button
        colorScheme="gray"
        variant="outline"
        onClick={() => onDeleteRepeatableSectionRow(row)}
      >
        行の削除
      </Button>
    )
  }

  if (!canEdit) return <></>

  if (row.isEnabled)
    return (
      <Button
        colorScheme="gray"
        variant="outline"
        onClick={() =>
          onDisableRepeatableSectionRow(row, shouldSave =>
            toast({
              status: 'success',
              title: shouldSave
                ? '行データを無効化しました。一時保存ボタンより状態を保存してください。'
                : '行データを無効化しました。',
            }),
          )
        }
        isDisabled={cannotClickDisableButton}
      >
        行の無効化
      </Button>
    )
  return (
    <Button
      variant="outline"
      onClick={() => {
        onEnabledRepeatableSectionRow(row, shouldSave =>
          toast({
            status: 'success',
            title: shouldSave
              ? '行データを有効化しました。一時保存ボタンより状態を保存してください。'
              : '行データを有効化しました。',
          }),
        )
      }}
    >
      行の有効化
    </Button>
  )
}
