import React from 'react'

import { IcfDocumentSetDetail } from './detail'
import { useIcfDocumentSetDetail } from './detail.hooks'

export const IcfDocumentSetDetailContainer: React.FC = () => {
  const {
    icfDocumentSet,
    listPath,
    canEdit,
    canEnable,
    navigateToEdit,
    getFilePreviewUrl,
    onEnable,
    shouldSelectHospital,
    hospitalName,
  } = useIcfDocumentSetDetail()

  return (
    <IcfDocumentSetDetail
      icfDocumentSet={icfDocumentSet}
      listPath={listPath}
      canEdit={canEdit}
      canEnable={canEnable}
      navigateToEdit={navigateToEdit}
      getFilePreviewUrl={getFilePreviewUrl}
      onEnable={onEnable}
      shouldShowHospitalName={shouldSelectHospital}
      hospitalName={hospitalName}
    />
  )
}
