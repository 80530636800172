import { ComponentStyleConfig } from '@chakra-ui/react'

export const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      borderBottom: '1px',
      borderColor: 'gray.600',
      color: 'gray.600',
      textTransform: 'unset',
      '&:last-of-type': {
        pr: 0,
      },
    },
    td: {
      borderBottom: '1px',
      borderColor: 'gray.100',
      '&:last-of-type': {
        pr: 0,
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  },
  sizes: {
    md: {
      th: {
        fontSize: 'sm',
        py: 3,
        pl: 0,
        pr: 10,
      },
      td: {
        py: 3,
        pl: 0,
        pr: 10,
      },
    },
  },
  variants: {
    normal: {},
    hoverable: {
      tbody: {
        tr: {
          cursor: 'pointer',
          _hover: {
            bg: 'blue.50',
          },
          _active: {
            bg: 'blue.100',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'normal',
  },
}
