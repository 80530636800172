import React, { useState } from 'react'

import { Button } from '@chakra-ui/react'
import styled from 'styled-components'

import { colors } from '../../../../../../../../../assets/colors'
import {
  DataType,
  SmallItem,
} from '../../../../../../../../entities/small-item/entity'
import { VisibilitySetModalContainer } from '../../pulldown/visibility-set/visibility-set-modal-container'

type Props = {
  smallItem: SmallItem
  size: 'large' | 'small'
}

export const VisibilityIcon: React.FC<Props> = props => {
  const { smallItem } = props
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  if (smallItem.unsaved) {
    return <Unsaved>一時保存後に設定可能です。</Unsaved>
  }

  if (smallItem.dataType === DataType.Clinical) {
    return (
      <Button variant="outline" isDisabled={true}>
        未設定
      </Button>
    )
  }

  return (
    <>
      {smallItem.viewableHospitalUids.length === 0 ? (
        <Button variant="outline" onClick={() => setModalOpen(true)}>
          未設定
        </Button>
      ) : (
        <Button variant="outline" onClick={() => setModalOpen(true)}>
          設定済み
        </Button>
      )}
      {modalOpen && (
        <VisibilitySetModalContainer
          smallItemUid={smallItem.uid}
          onClose={() => setModalOpen(false)}
        ></VisibilitySetModalContainer>
      )}
    </>
  )
}

const Unsaved = styled.p`
  color: ${colors.gray};
  margin-top: 6px;
`
