// source: micin-jp/chicken-api/scripts/generate_role_permissions/main.go

// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

export const PERMISSIONS = {
  Hospital_BelongAll: 'hospital:BelongAll', // 医療機関 - 全医療機関所属
  Menu_ShowSettings: 'menu:ShowSettings', // メニュー表示 - 試験設定
  Menu_ShowPatients: 'menu:ShowPatients', // メニュー表示 - 患者一覧
  Menu_ShowTemplate: 'menu:ShowTemplate', // メニュー表示 - テンプレート
  Trial_Read: 'trial:Read', // 試験設定/試験情報 - 試験情報参照
  Account_ReadMembers: 'account:ReadMembers', // 試験設定/アカウント管理 - アカウント管理一覧参照
  Account_Add: 'account:Add', // 試験設定/アカウント管理 - アカウント登録/招待メール再送信
  Account_Edit: 'account:Edit', // 試験設定/アカウント管理 - アカウント情報編集
  Account_Delete: 'account:Delete', // 試験設定/アカウント管理 - アカウント削除
  Account_Disable: 'account:Disable', // 試験設定/アカウント管理 - アカウント無効化
  Account_Enable: 'account:Enable', // 試験設定/アカウント管理 - アカウント有効化
  Crf_Upload: 'crf:Upload', // 試験設定/CRF管理 - CRFアップロード
  Crf_Download: 'crf:Download', // 試験設定/CRF管理 - CRFダウンロード
  Dataexport_DataExport: 'dataexport:DataExport', // 試験設定/症例データ管理 - 症例データ管理
  Patient_ReadDetail: 'patient:ReadDetail', // 患者管理 - 患者個人情報参照
  Patient_ReadHospitals: 'patient:ReadHospitals', // 患者管理 - 医療機関参照
  Patient_Add: 'patient:Add', // 患者管理 - 患者追加
  Patient_Download: 'patient:Download', // 患者管理 - データ出力
  Patient_DownloadVideoCallCapture: 'patient:DownloadVideoCallCapture', // 患者管理 - ビデオ通話キャプチャのダウンロード
  Patient_Edit: 'patient:Edit', // 患者管理 - 患者情報編集
  Patient_Disable: 'patient:Disable', // 患者管理 - 患者無効化
  Patient_Enable: 'patient:Enable', // 患者管理 - 患者有効化
  Patient_IssuePassword: 'patient:IssuePassword', // 患者管理 - 患者PW発行
  Patient_StartTrial: 'patient:StartTrial', // 患者管理 - 試験開始日の確定
  Patient_FinishTrial: 'patient:FinishTrial', // 患者管理 - 試験の終了
  Patient_ReadQuestionnaireAnswers: 'patient:ReadQuestionnaireAnswers', // 患者管理 - 回答一覧参照
  Patient_CreateVideoCall: 'patient:CreateVideoCall', // 患者管理 - ビデオ通話作成
  Patient_GenerateVideoCallJWT: 'patient:GenerateVideoCallJWT', // 患者管理 - ビデオ通話JWT生成
  Worksheet_Create: 'worksheet:Create', // ワークシート - 新しいワークシートの作成
  Worksheet_Save: 'worksheet:Save', // ワークシート - セクション一時保存ボタン
  Worksheet_Submit: 'worksheet:Submit', // ワークシート - セクション確定ボタン
  Worksheet_Delete: 'worksheet:Delete', // ワークシート - ワークシートを削除
  Worksheet_AddLogline: 'worksheet:AddLogline', // ワークシート - ログライン行追加
  Worksheet_SaveLogline: 'worksheet:SaveLogline', // ワークシート - ログライン一時保存
  Worksheet_SubmitLogline: 'worksheet:SubmitLogline', // ワークシート - ログライン確定
  Worksheet_DisableLogline: 'worksheet:DisableLogline', // ワークシート - ログラインの無効化
  Worksheet_EnableLogline: 'worksheet:EnableLogline', // ワークシート - ログラインの有効化
  Worksheet_Edit: 'worksheet:Edit', // ワークシート - ワークシート編集
  Worksheet_DownloadFileField: 'worksheet:DownloadFileField', // ワークシート - ファイルフィールドのダウンロード
  Worksheet_Disable: 'worksheet:Disable', // ワークシート - ワークシートの無効化
  Worksheet_Enable: 'worksheet:Enable', // ワークシート - ワークシートの有効化
  Worksheet_UseVideoCall: 'worksheet:UseVideoCall', // ワークシート - ビデオ通話
  Worksheet_ReadVideoCallHistory: 'worksheet:ReadVideoCallHistory', // ワークシート - ビデオ通話履歴参照
  Worksheet_ReadVideoCapture: 'worksheet:ReadVideoCapture', // ワークシート - ビデオ通話キャプチャ参照
  Template_CreateFolder: 'template:CreateFolder', // テンプレート - フォルダ作成
  Template_EditFolderName: 'template:EditFolderName', // テンプレート - フォルダ名編集
  Template_SortFolders: 'template:SortFolders', // テンプレート - フォルダ並び替え
  Template_DeleteFolder: 'template:DeleteFolder', // テンプレート - フォルダ削除
  Template_Create: 'template:Create', // テンプレート - 新しいテンプレート作成
  Template_Export: 'template:Export', // テンプレート - エクスポート
  Template_Rename: 'template:Rename', // テンプレート - テンプレート名編集
  Template_Edit: 'template:Edit', // テンプレート - テンプレート編集
  Template_Delete: 'template:Delete', // テンプレート - テンプレートの削除
  Template_Save: 'template:Save', // テンプレート - テンプレートの一時保存
  Template_Release: 'template:Release', // テンプレート - テンプレートの公開
  Template_Disable: 'template:Disable', // テンプレート - テンプレートの無効化
  Template_Enable: 'template:Enable', // テンプレート - テンプレートの有効化
  Template_SortFieldsOfMaster: 'template:SortFieldsOfMaster', // テンプレート - セクション・フィールドの並び替え(マスタ)
  Template_SetHospitals: 'template:SetHospitals', // テンプレート - 医療機関設定
  Template_SetHospitalsWithFields: 'template:SetHospitalsWithFields', // テンプレート - フィールド別医療機関設定
  Questionnaire_Create: 'questionnaire:Create', // 質問票管理 - 新しい質問票の作成
  Questionnaire_Edit: 'questionnaire:Edit', // 質問票管理 - 質問票の編集
  Questionnaire_Save: 'questionnaire:Save', // 質問票管理 - 質問票の一時保存
  Questionnaire_Delete: 'questionnaire:Delete', // 質問票管理 - 質問票の削除
  Questionnaire_Confirm: 'questionnaire:Confirm', // 質問票管理 - 質問票の確定
  Questionnaire_BackToDraft: 'questionnaire:BackToDraft', // 質問票管理 - 質問票を下書きに戻す
  Questionnaire_Export: 'questionnaire:Export', // 質問票管理 - 質問票のエクスポート
  Questionnaire_Import: 'questionnaire:Import', // 質問票管理 - 質問票のインポート
  Questionnaire_CreateSchedule: 'questionnaire:CreateSchedule', // 質問票管理 - 質問票のスケジュール登録
  Questionnaire_SaveSchedule: 'questionnaire:SaveSchedule', // 質問票管理 - 質問票のスケジュール一時保存
  Questionnaire_ReleaseSchedule: 'questionnaire:ReleaseSchedule', // 質問票管理 - 質問票のスケジュール配信開始
  Questionnaire_ExportAnswerCsv: 'questionnaire:ExportAnswerCsv', // 質問票管理 - 質問票の回答CSV出力
  Schedule_AddSchedule: 'schedule:AddSchedule', // スケジュール管理 - スケジュールの追加
  Schedule_DeleteSchedule: 'schedule:DeleteSchedule', // スケジュール管理 - スケジュールの編集
  Schedule_EditSchedule: 'schedule:EditSchedule', // スケジュール管理 - スケジュールの削除
  Icfdocument_SelectTrialHospital: 'icfdocument:SelectTrialHospital', // 文書管理 - 医療機関選択
  Icfdocument_CreateDocument: 'icfdocument:CreateDocument', // 文書管理 - 文書の新規作成
  Icfdocument_Edit: 'icfdocument:Edit', // 文書管理 - 文書の編集
  Icfdocument_ReadDocumentList: 'icfdocument:ReadDocumentList', // 文書管理 - 文書の一覧取得
  Icfdocument_ReadDocumentDetail: 'icfdocument:ReadDocumentDetail', // 文書管理 - 文書の詳細取得
  Icfdocument_Enable: 'icfdocument:Enable', // 文書管理 - 文書の有効化
  Icfdocument_Disable: 'icfdocument:Disable', // 文書管理 - 文書の無効化
  Icfdocument_DeleteDocument: 'icfdocument:DeleteDocument', // 文書管理 - 文書の削除
  Icfdocument_CreateRevision: 'icfdocument:CreateRevision', // 文書管理 - 文書の改版
  Icfdocument_RequestApprovalRevision: 'icfdocument:RequestApprovalRevision', // 文書管理 - 文書の承認依頼
  Icfdocument_WithdrawRevision: 'icfdocument:WithdrawRevision', // 文書管理 - 文書の承認依頼取り消し
  Icfdocument_ApproveRevision: 'icfdocument:ApproveRevision', // 文書管理 - 文書の承認
  Icfdocument_RejectRevision: 'icfdocument:RejectRevision', // 文書管理 - 文書の承認却下
  Icfdocumentset_Create: 'icfdocumentset:Create', // 文書セット管理 - 文書セットの新規作成
  Icfdocumentset_ReadDocumentSetList: 'icfdocumentset:ReadDocumentSetList', // 文書セット管理 - 文書セットの一覧取得
  Icfdocumentset_ReadDocumentSetDetail: 'icfdocumentset:ReadDocumentSetDetail', // 文書セット管理 - 文書セットの詳細取得
  Icfdocumentset_Edit: 'icfdocumentset:Edit', // 文書セット管理 - 文書セットの編集
  Icfdocumentset_Enable: 'icfdocumentset:Enable', // 文書セット管理 - 文書セットの有効化
  Icfdocumentset_Disable: 'icfdocumentset:Disable', // 文書セット管理 - 文書セットの無効化
  Explanation_Read: 'explanation:Read', // 説明同意 - 参照
  Explanation_Edit: 'explanation:Edit', // 説明同意 - 編集
  Criteria_Read: 'criteria:Read', // 選択除外基準 - 参照
  Criteria_Edit: 'criteria:Edit', // 選択除外基準 - 編集
  Referredpatient_Read: 'referredpatient:Read', // 紹介患者 - 参照
  Referredpatient_ReadConfidential: 'referredpatient:ReadConfidential', // 紹介患者 - 機密情報の参照
  Referredpatient_Edit: 'referredpatient:Edit', // 紹介患者 - 編集

} as const

export type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS]

export const DMPermissionsForFixture: Permission[] = ["hospital:BelongAll","menu:ShowSettings","menu:ShowPatients","menu:ShowTemplate","trial:Read","account:ReadMembers","account:Add","account:Edit","account:Delete","account:Disable","account:Enable","crf:Upload","crf:Download","dataexport:DataExport","patient:ReadHospitals","patient:Download","patient:ReadQuestionnaireAnswers","worksheet:ReadVideoCallHistory","template:CreateFolder","template:EditFolderName","template:SortFolders","template:DeleteFolder","template:Create","template:Export","template:Rename","template:Edit","template:Delete","template:Save","template:Release","template:Disable","template:Enable","template:SortFieldsOfMaster","template:SetHospitals","template:SetHospitalsWithFields","questionnaire:Create","questionnaire:Edit","questionnaire:Save","questionnaire:Delete","questionnaire:Confirm","questionnaire:BackToDraft","questionnaire:Export","questionnaire:Import","questionnaire:CreateSchedule","questionnaire:SaveSchedule","questionnaire:ReleaseSchedule","questionnaire:ExportAnswerCsv","schedule:AddSchedule","schedule:DeleteSchedule","schedule:EditSchedule","icfdocument:SelectTrialHospital","icfdocument:CreateDocument","icfdocument:Edit","icfdocument:ReadDocumentList","icfdocument:ReadDocumentDetail","icfdocument:Enable","icfdocument:Disable","icfdocument:DeleteDocument","icfdocument:CreateRevision","icfdocument:RequestApprovalRevision","icfdocument:WithdrawRevision","icfdocumentset:ReadDocumentSetList","icfdocumentset:ReadDocumentSetDetail","explanation:Read","criteria:Read","criteria:Edit","referredpatient:Read",]
export const CRAPermissionsForFixture: Permission[] = ["menu:ShowPatients","menu:ShowTemplate","account:ReadMembers","patient:Download","patient:ReadQuestionnaireAnswers","worksheet:ReadVideoCallHistory","questionnaire:ExportAnswerCsv","icfdocument:CreateDocument","icfdocument:Edit","icfdocument:ReadDocumentList","icfdocument:ReadDocumentDetail","icfdocument:Enable","icfdocument:Disable","icfdocument:DeleteDocument","icfdocument:CreateRevision","icfdocument:RequestApprovalRevision","icfdocument:WithdrawRevision","icfdocumentset:ReadDocumentSetList","icfdocumentset:ReadDocumentSetDetail","explanation:Read","criteria:Read","criteria:Edit","referredpatient:Read",]
export const DRPermissionsForFixture: Permission[] = ["menu:ShowPatients","menu:ShowTemplate","account:ReadMembers","patient:ReadDetail","patient:Add","patient:Download","patient:DownloadVideoCallCapture","patient:Edit","patient:Disable","patient:Enable","patient:IssuePassword","patient:StartTrial","patient:FinishTrial","patient:ReadQuestionnaireAnswers","patient:CreateVideoCall","patient:GenerateVideoCallJWT","worksheet:Create","worksheet:Save","worksheet:Submit","worksheet:Delete","worksheet:AddLogline","worksheet:SaveLogline","worksheet:SubmitLogline","worksheet:DisableLogline","worksheet:EnableLogline","worksheet:Edit","worksheet:DownloadFileField","worksheet:Disable","worksheet:Enable","worksheet:UseVideoCall","worksheet:ReadVideoCallHistory","worksheet:ReadVideoCapture","questionnaire:ExportAnswerCsv","icfdocument:CreateDocument","icfdocument:Edit","icfdocument:ReadDocumentList","icfdocument:ReadDocumentDetail","icfdocument:Enable","icfdocument:Disable","icfdocument:DeleteDocument","icfdocument:CreateRevision","icfdocument:RequestApprovalRevision","icfdocument:ApproveRevision","icfdocument:RejectRevision","icfdocumentset:Create","icfdocumentset:ReadDocumentSetList","icfdocumentset:ReadDocumentSetDetail","icfdocumentset:Edit","icfdocumentset:Enable","icfdocumentset:Disable","explanation:Read","explanation:Edit","criteria:Read","criteria:Edit","referredpatient:Read","referredpatient:ReadConfidential","referredpatient:Edit",]
export const CRCPermissionsForFixture: Permission[] = ["menu:ShowPatients","menu:ShowTemplate","account:ReadMembers","patient:ReadDetail","patient:Add","patient:Download","patient:DownloadVideoCallCapture","patient:Edit","patient:Disable","patient:Enable","patient:IssuePassword","patient:StartTrial","patient:FinishTrial","patient:ReadQuestionnaireAnswers","patient:CreateVideoCall","patient:GenerateVideoCallJWT","worksheet:Create","worksheet:Save","worksheet:Delete","worksheet:AddLogline","worksheet:SaveLogline","worksheet:DisableLogline","worksheet:EnableLogline","worksheet:Edit","worksheet:DownloadFileField","worksheet:Disable","worksheet:Enable","worksheet:UseVideoCall","worksheet:ReadVideoCallHistory","worksheet:ReadVideoCapture","questionnaire:ExportAnswerCsv","icfdocument:CreateDocument","icfdocument:Edit","icfdocument:ReadDocumentList","icfdocument:ReadDocumentDetail","icfdocument:Enable","icfdocument:Disable","icfdocument:DeleteDocument","icfdocument:CreateRevision","icfdocument:RequestApprovalRevision","icfdocument:WithdrawRevision","icfdocumentset:Create","icfdocumentset:ReadDocumentSetList","icfdocumentset:ReadDocumentSetDetail","icfdocumentset:Edit","icfdocumentset:Enable","icfdocumentset:Disable","explanation:Read","explanation:Edit","criteria:Read","criteria:Edit","referredpatient:Read","referredpatient:ReadConfidential","referredpatient:Edit",]


export const isPermission = (str: string): str is Permission => {
  return Object.values(PERMISSIONS).includes(str as Permission)
}

export const toPermission = (str: string): Permission | null => {
  return isPermission(str) ? str : null
}
