import { Box, Flex } from '@chakra-ui/react'
import { RemoteUser } from '@micin-jp/call-lib-core'

import { VideoCallRoomRemotePlayer } from '../VideoCallRoomRemotePlayer/VideoCallRoomRemotePlayer'

type Props = {
  remoteUsers: RemoteUser[]
}

export const VideoCallRoomRemotePlayers: React.FC<Props> = ({
  remoteUsers,
}) => {
  return (
    <Flex w="full" h="full" gap="8px">
      {/* TODO: remoteUserが2名以上いる場合のデザイン */}
      {remoteUsers.map(user => (
        <Box key={user.id} w="full" h="full">
          <VideoCallRoomRemotePlayer remoteUser={user} />
        </Box>
      ))}
    </Flex>
  )
}
