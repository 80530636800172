import React from 'react'

import { Box, Collapse, Fade, HStack, Stack, Text } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { MenuClose, MenuOpen } from 'src/components/__legacy__icon/monochrome'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useCurrentVersion } from '../../hooks/useCurrentVersion'
import { useLegacyNavigation } from '../../hooks/useLegacyNavigation'

export const LegacyNavigation: React.FC<{}> = () => {
  const {
    navOpen,
    navItems,
    generateIcon,
    checkIsOpenMainMenu,
    checkIsOpenSubMenu,
    handleToggleNavOpen,
  } = useLegacyNavigation()

  const { currentVersion } = useCurrentVersion()

  return (
    <Stack
      as="nav"
      h="full"
      minW={navOpen ? '216px' : '74px'}
      bgColor="gray.50"
    >
      <Stack flex={1} pt={4} spacing={0}>
        {navItems.map(navItem => (
          <Box key={navItem.name}>
            <HStack
              align="center"
              spacing={navOpen ? 3 : 0}
              py={4}
              px={navOpen ? 6 : 4}
              as={ReactRouterLink}
              to={navItem.hasSubmenu ? navItem.basePath : navItem.path}
              // サブメニューが開いている場合、ベースのリンクは機能させない
              pointerEvents={
                checkIsOpenMainMenu(navItem) && navItem.hasSubmenu
                  ? 'none'
                  : 'inherit'
              }
              bgColor={checkIsOpenMainMenu(navItem) ? 'white' : ''}
              cursor={
                checkIsOpenMainMenu(navItem) && navItem.hasSubmenu
                  ? 'default'
                  : 'pointer'
              }
              _hover={{
                bgColor: checkIsOpenMainMenu(navItem) ? 'white' : 'gray.100',
              }}
              sx={{
                '&:focus-visible': {
                  outline: `${colors.blue[500]} auto 2px`,
                  outlineOffset: '-4px',
                },
              }}
            >
              {generateIcon(navItem.iconName)}
              {navOpen && (
                <Fade in>
                  <Text fontWeight="bold" color="gray.800">
                    {navItem.name}
                  </Text>
                </Fade>
              )}
            </HStack>
            {navOpen && (
              <Fade in>
                {navItem.hasSubmenu && (
                  <Collapse in={checkIsOpenMainMenu(navItem)} animateOpacity>
                    <Stack spacing={1} bgColor="white" pb={4}>
                      {navItem.submenus.map(submenu => (
                        <HStack key={submenu.name} spacing={1} px="4">
                          <Box
                            w="4px"
                            h="20px"
                            bgColor={
                              checkIsOpenSubMenu(submenu) ? 'gray.600' : 'white'
                            }
                            borderRadius="2px"
                            ml="48px"
                          />

                          <Text
                            fontWeight={
                              checkIsOpenSubMenu(submenu) ? 'bold' : 'normal'
                            }
                            w="full"
                            cursor="pointer"
                            as={ReactRouterLink}
                            to={submenu.path}
                            sx={{
                              '&:focus-visible': {
                                outline: `${colors.blue[500]} auto 2px`,
                                outlineOffset: '-2px',
                              },
                            }}
                          >
                            {submenu.name}
                          </Text>
                        </HStack>
                      ))}
                    </Stack>
                  </Collapse>
                )}
              </Fade>
            )}
          </Box>
        ))}
      </Stack>

      <HStack w="full" justify={navOpen ? 'space-between' : 'center'} p={4}>
        {navOpen && <Text>{`ver.${currentVersion}`}</Text>}
        <Box onClick={handleToggleNavOpen} cursor="pointer">
          {navOpen ? (
            <MenuClose size="M" color={colors.gray[600]} />
          ) : (
            <MenuOpen size="M" color={colors.gray[600]} />
          )}
        </Box>
      </HStack>
    </Stack>
  )
}
