export const sortKeys = {
  diseaseUid: 'disease-uid',
  name: 'name',
  hospitalName: 'hospital',
} as const

export const sortOrders = {
  asc: 'asc',
  desc: 'desc',
} as const

export type SortKey = (typeof sortKeys)[keyof typeof sortKeys]
export type SortOrder = (typeof sortOrders)[keyof typeof sortOrders]

export type PatientSortType = {
  key: SortKey
  order: SortOrder
}

export const canSortByPermission = ({
  sortKey,
  canReadPatientDetail,
  canReadHospital,
}: {
  sortKey: SortKey
  canReadPatientDetail: boolean
  canReadHospital: boolean
}) => {
  switch (sortKey) {
    case 'disease-uid':
      return true
    case 'name':
      return canReadPatientDetail
    case 'hospital':
      return canReadHospital
    default:
      const n: never = sortKey
      throw n
  }
}
export const sortKeyStrings: Record<SortKey, string> = {
  [sortKeys.diseaseUid]: '症例番号',
  [sortKeys.name]: '氏名',
  [sortKeys.hospitalName]: '医療機関名',
}
