import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useResendNotification } from '../../api'
import { ExplanationRevision } from '../../types'

type Props = {
  isOpen: boolean
  explanationRevisionUid: ExplanationRevision['uid']
  patientEmail: string
  onClose: () => void
}

export const ResendNotificationModal: React.FC<Props> = ({
  isOpen,
  explanationRevisionUid,
  patientEmail,
  onClose,
}) => {
  const toast = useMirohaToast()

  const { request: resendNotification } = useResendNotification({
    onSuccess: () => {
      onClose()
      toast({
        status: 'success',
        title: '説明情報を送信しました',
      })
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>患者へ説明URLを再送</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Text>
              以下の患者メールアドレスに説明情報（URLを含む）を送信します。
            </Text>
            <Text>{patientEmail}</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            onClick={async () => {
              await resendNotification({
                explanationRevisionUid,
              })
            }}
          >
            送信する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
