import React, { useState } from 'react'

import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Calendar } from 'src/components/__legacy__icon/monochrome'
import { CalendarComponent } from 'src/components/form-redesigned/calendar'
import { Select, SelectItem } from 'src/components/Select/Select'
import { Spacer } from 'src/components/spacer/spacer'
import { zeroPadding } from 'src/utils/zeroPadding'

type Props = {
  value: string
  onChange: (value: string) => void
  visibleClear: boolean
  'aria-labelledby'?: string
  setUnderEditSchedule?: (value: boolean) => void
}

type ScheduleValue = {
  date: string
  hour: string
  minute: string
}

// 0 ~ 23
const hours = [...Array(24)].map((_, i) => zeroPadding(String(i), 2))
// 00 ~ 55
const minutes = [...Array(60 / 5)].map((_, i) => zeroPadding(String(i * 5), 2))

const hoursSelectItems: SelectItem[] = hours.map(hour => ({
  label: hour,
  value: hour,
}))
const minutesSelectItems: SelectItem[] = minutes.map(minute => ({
  label: minute,
  value: minute,
}))

const formatScheduleValue = (
  date: string,
  hour: string,
  minute: string,
): string => {
  const joined = `${date} ${hour}:${minute}`
  return dayjs(joined).isValid() ? dayjs(joined).format() : ''
}

const hasCompleteScheduleValue = (scheduleValue: ScheduleValue): boolean => {
  return (
    scheduleValue.date !== '' &&
    scheduleValue.hour !== '' &&
    scheduleValue.minute !== ''
  )
}

export const ScheduleInput: React.FC<Props> = ({
  value,
  onChange,
  visibleClear,
  setUnderEditSchedule,
  'aria-labelledby': ariaLabelledBy,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [date, setDate] = useState<string>(
    dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '',
  )
  const hourValue = value ? dayjs(value).format('HH') : ''
  const minuteValue = value ? dayjs(value).format('mm') : ''
  const [hour, setHour] = useState<string>(hourValue)
  const [minute, setMinute] = useState<string>(minuteValue)

  const onChangeDate = (dateValue: string) => {
    setDate(dateValue)
    setUnderEditSchedule?.(true)
    if (
      hasCompleteScheduleValue({
        date: dateValue,
        hour: hour,
        minute: minute,
      })
    ) {
      onChange(formatScheduleValue(dateValue, hour, minute))
    }
  }
  const onChangeDateValue = (date: Date) => {
    onChangeDate(dayjs(date).format('YYYY-MM-DD'))
  }

  const onChangeHour = (hourValue: string) => {
    setHour(hourValue)
    setUnderEditSchedule?.(true)
    if (
      hasCompleteScheduleValue({
        date: date,
        hour: hourValue,
        minute: minute,
      })
    ) {
      onChange(formatScheduleValue(date, hourValue, minute))
    }
  }
  const onChangeMinute = (minuteValue: string) => {
    setMinute(minuteValue)
    setUnderEditSchedule?.(true)
    if (
      hasCompleteScheduleValue({
        date: date,
        hour: hour,
        minute: minuteValue,
      })
    ) {
      onChange(formatScheduleValue(date, hour, minuteValue))
    }
  }

  return (
    <Box aria-labelledby={ariaLabelledBy}>
      <Flex align="center">
        <Stack display="inline-block" pos="relative" spacing="2">
          <Flex align="center" w="full">
            <Flex align="center" mr="4">
              <Input
                w="72px"
                mr="1"
                value={dayjs(date).isValid() ? dayjs(date).format('YYYY') : ''}
                readOnly
              />
              <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
                年
              </Text>
              <Input
                w="52px"
                mr="1"
                value={dayjs(date).isValid() ? dayjs(date).format('MM') : ''}
                readOnly
              />
              <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
                月
              </Text>
              <Input
                w="52px"
                mr="1"
                value={dayjs(date).isValid() ? dayjs(date).format('DD') : ''}
                readOnly
              />
              <Text as="span" fontSize="sm" fontWeight="bold">
                日
              </Text>
            </Flex>
            <IconButton
              variant="ghost"
              colorScheme="gray"
              aria-label="calendar icon"
              h="32px"
              w="32px"
              minW="unset"
              borderRadius="base"
              color="blue.500"
              icon={<Calendar />}
              onClick={onOpen}
              pos="relative"
            />
          </Flex>
          {isOpen && (
            <Flex pos="absolute" right="0" zIndex="1">
              <CalendarComponent
                onChange={onChangeDateValue}
                onClose={onClose}
              />
            </Flex>
          )}
        </Stack>
        <Spacer size={24} horizontal />
        <Flex align="end">
          <Select
            items={hoursSelectItems}
            value={hour}
            onChange={onChangeHour}
            width={80}
            placeholder="--"
          />
          <Spacer size={8} horizontal />
          <Text fontSize="sm">時</Text>
          <Spacer size={8} horizontal />
          <Select
            items={minutesSelectItems}
            value={minute}
            onChange={onChangeMinute}
            width={80}
            placeholder="--"
          />
          <Spacer size={8} horizontal />
          <Text fontSize="sm">分</Text>
        </Flex>
        {visibleClear && (
          <Button
            onClick={() => {
              onChange('')
              setDate('')
              setHour('')
              setMinute('')
              setUnderEditSchedule?.(false)
            }}
            ml="4"
            variant="ghost"
          >
            日時クリア
          </Button>
        )}
      </Flex>
    </Box>
  )
}
