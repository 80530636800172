import { Box, Center, Flex, Stack, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'

type Props = {
  children: React.ReactNode
  colorScheme?: 'blue' | 'green'
}

/** 静的な画面用の共通コンポーネント */
export const StaticScreen: React.FC<Props> = ({
  children,
  colorScheme: c = 'blue',
}) => {
  return (
    <Flex direction="column" bg={`${c}.50`} w="full" h="100svh">
      <Box as="header" h="64px" pos="sticky" p="4">
        <img src={MirohaLogo} alt="MiROHA Logo" />
      </Box>
      <Center flex="1" p="4" h="calc(100svh - 64px)" overflow="auto">
        <Flex
          justify="center"
          align="start"
          boxShadow="card"
          bg="white"
          borderRadius="base"
          p={{ base: '4', sm: '8', md: '16' }}
          h="auto"
          w="full"
          maxW="720px"
          maxH="full"
          overflow="auto"
        >
          <Stack spacing="6">{children}</Stack>
        </Flex>
      </Center>
    </Flex>
  )
}

type TitleProps = {
  children: React.ReactNode
}
export const StaticScreenTitle: React.FC<TitleProps> = ({ children }) => {
  return (
    <Center>
      <Text fontWeight="bold" as="h2" textAlign="center">
        {children}
      </Text>
    </Center>
  )
}

type ContentProps = {
  children: React.ReactNode
}
export const StaticScreenContent: React.FC<ContentProps> = ({ children }) => (
  <Box textAlign="center">{children}</Box>
)

type FooterProps = {
  children: React.ReactNode
}
export const StaticScreenFooter: React.FC<FooterProps> = ({ children }) => (
  <Center>{children}</Center>
)
