import React from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'
import { actions as memberActions } from 'src/modules/entities/member/redux'
import { actions as flashActions } from 'src/modules/flash/redux'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { submitDelete } from './request'

type Props = {
  userName: string
  trialUid: string
  memberUid: string
  closeModal: () => void
}

const Bold = styled.span`
  font-weight: bold;
`

export const DisableModal: React.FC<Props> = props => {
  const { userName, trialUid, memberUid, closeModal } = props

  const { request, requesting, errorMessage } = useSubmitDisable()

  const onSubmit = async () => {
    await request({ memberUid, trialUid })
    closeModal()
  }

  return (
    <Modal onClose={closeModal}>
      <ModalTitle title="アカウントを無効にする" />
      <ModalContent>
        <Text textAlign="center">
          <Bold>{userName}</Bold>
          さんのアカウントを無効にします。よろしいですか？
        </Text>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          buttonType="cancel"
          onClick={closeModal}
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="無効化"
          buttonType="important"
          onClick={onSubmit}
          disabled={requesting}
        ></Button>
      </ModalActions>
      {errorMessage && <Message type="error" message={errorMessage} centered />}
    </Modal>
  )
}

const useSubmitDisable = () => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()
  const dispatch = useDispatch()

  const request = async ({
    trialUid,
    memberUid,
  }: {
    trialUid: string
    memberUid: string
  }) => {
    try {
      requestStarted()

      await submitDelete({ trialUid, memberUid })
      dispatch(memberActions.fetchList({ trialUid, type: 'all' }))

      const message = 'アカウントを無効化しました'
      dispatch(flashActions.showSuccess({ message }))

      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}
