import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions } from 'src/modules/entities/account/redux'
import { getCurrentUser } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { routes } from 'src/modules/routes'

export const useSelectHospital = () => {
  const account = useSelector(getCurrentUser)!
  const selectedTrial = account.selectedTrial!

  const dispatch = useDispatch()
  const { showError } = useFlash()

  const navigate = useNavigate()

  const handleSelectHospital = (hospitalUid: string) => {
    try {
      dispatch(
        actions.selectTrialHospital({
          trialUid: selectedTrial.uid,
          trialHospitalUid: hospitalUid,
        }),
      )
      navigate('/switch')
    } catch (error) {
      showError('医療機関選択中にエラーが発生しました。')
      if (error.original.response.status === 403) {
        navigate(routes.httpError403)
      }
    }
  }

  return {
    handleSelectHospital,
  }
}
