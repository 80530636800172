import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export const getVideoCallRoomImage = async ({
  videoCallRoomImageUid,
}: {
  videoCallRoomImageUid: string
}): Promise<string> => {
  const res = await requestGet<string>({
    url: `${SERVER_URL}/participants/video_call_room_images/${videoCallRoomImageUid}`,
  })

  return res.data
}
