import { SmallItem, ItemType, DataType } from './entity'
import { fixture as choiceFixture } from '../choice/fixture'
import { Role } from '../member/entity'

export const createFixture = (values?: Partial<SmallItem>): SmallItem => {
  const res: SmallItem = {
    uid: '1',
    title: 'タイトル',
    value: null,
    itemType: ItemType.Text,
    description:
      '説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明',
    index: 0,
    dataType: DataType.Operational,
    inputRoles: [Role.Dr, Role.CRC],
    choices: [],
    parentUid: null,
    presetUsed: false,
    viewableHospitalUids: [],
    edcDataItem: 'fooEdcDataItem',
    unsaved: false,
    unit: '',
    mediumItemUid: '',
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof SmallItem
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}

export const fixture: SmallItem = {
  uid: 'fooSmallItemUid',
  title: '質問タイトル',
  value: null,
  description: '質問説明',
  index: 0,
  itemType: ItemType.CheckBox,
  dataType: DataType.Clinical,
  edcDataItem: 'fooEdcDataItem',
  unit: '',
  viewableHospitalUids: [],
  unsaved: false,
  inputRoles: [Role.Dr, Role.CRC],
  choices: [choiceFixture],

  mediumItemUid: 'fooMediumItemUid',
  parentUid: null,
  presetUsed: false,
}

export const fixtureNested: SmallItem = {
  uid: 'fooSmallItemUidNested',
  title: '質問タイトル',
  value: null,
  description: '質問説明',
  index: 0,
  itemType: ItemType.Number,
  dataType: DataType.Clinical,
  edcDataItem: 'fooEdcDataItemNested',
  unit: '',
  viewableHospitalUids: [],
  unsaved: false,
  inputRoles: [Role.Dr, Role.CRC],
  choices: [],

  mediumItemUid: 'fooMediumItemUid',
  parentUid: 'fooSmallItemUid',
  presetUsed: false,
}
