import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { generatePath, useNavigate } from 'react-router-dom'
import { ToggleButton } from 'src/components/form-redesigned/toggle-button'
import { HasSetBadge } from 'src/components/HasSetBadge/HasSetBadge'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { NotSetBadge } from 'src/components/NotSetBadge/NotSetBadge'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  useSelectedTrial,
} from 'src/features/auth/context'
import {
  CreateOrGetPreSessionRes,
  useCreateExplanationSession,
  useUpdatePreSessionIdentification,
} from 'src/features/explanation/api'
import { MembersSettingButton } from 'src/features/explanation/components/MembersSettingButton/MembersSettingButton'
import { ExplanationDetail } from 'src/features/explanation/types'
import { hasAlreadyPinSet } from 'src/features/explanation/utils/hasAlreadyPinSet'
import { getFullName } from 'src/utils/getFullName'

import { useActiveDevice } from '../../context/ExplanationRoomEvent'
import { ExplanationRoomHeader } from '../ExplanationRoomHeader/ExplanationRoomHeader'
import { LeaveLobbyModal } from '../LeaveLobbyModal/LeaveLobbyModal'
import { RequestPinSettingButton } from '../RequestPinSettingButton/RequestPinSettingButton'

const HEADER_HEIGHT = 64

type Props = {
  explanation: ExplanationDetail
  preSession: CreateOrGetPreSessionRes
  mutatePreSession: () => void
  onFinish: () => void
  children: React.ReactNode
}

export const HospitalLobbyLayout: React.FC<Props> = ({
  explanation,
  preSession,
  mutatePreSession,
  onFinish,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { selectedTrial } = useSelectedTrial()
  return (
    <Box h="full">
      <Flex width="100%" height="100%" direction="column">
        <LeaveLobbyModal
          isOpen={isOpen}
          onClose={onClose}
          onFinish={onFinish}
        />
        <ExplanationRoomHeader
          featureChannel={selectedTrial.featureChannel}
          headerTitle="説明ロビー"
          finishButton={
            <Button variant="outline" colorScheme="red" onClick={onOpen}>
              説明ロビーから退出する
            </Button>
          }
          height={HEADER_HEIGHT}
        />
        <Flex height={`calc(100% - ${HEADER_HEIGHT}px)`}>
          <Box as="main" flex="1" overflowY="auto" h="full" bg="green.50">
            {children}
          </Box>
          <Box
            w={{ base: '240px', lg: '360px' }}
            p="6"
            bg="white"
            height="full"
            overflow="auto"
          >
            <HospitalLobbySidebar
              explanation={explanation}
              preSession={preSession}
              mutatePreSession={() => mutatePreSession()}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

const HospitalLobbySidebar = ({
  explanation,
  mutatePreSession,
  preSession,
}: {
  explanation: ExplanationDetail
  mutatePreSession: () => void
  preSession: CreateOrGetPreSessionRes
}) => {
  const {
    patient,
    uid: explanationUid,
    latestRevision,
    trialHospital,
  } = explanation

  const {
    isIdentified,
    identifiedAt,
    explanationSessionUid,
    explanationRevision,
  } = preSession

  const navigate = useNavigate()

  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const disabledStartSession =
    !isIdentified || latestRevision.members.length === 0

  const { request: createSession } = useCreateExplanationSession({
    onSuccess: ({ explanationSessionUid }) => {
      navigate(
        generatePath(Paths.HospitalExplanationRoomTop, {
          trialUid: selectedTrial.uid,
          sessionUid: explanationSessionUid,
        }),
      )
    },
  })

  const { request: updateIdentification } = useUpdatePreSessionIdentification({
    onSuccess: mutatePreSession,
  })

  const mainHospitalMembers = useMemo(
    () =>
      latestRevision.members.filter(
        m => m.trialHospital.uid === trialHospital?.uid,
      ),
    [latestRevision, trialHospital],
  )

  const partnerHospitalMembers = useMemo(
    () =>
      latestRevision.members.filter(
        m => m.trialHospital.uid === latestRevision.partnerTrialHospital?.uid,
      ),
    [latestRevision],
  )

  const isSignerSet = useMemo(() => {
    return explanation.latestRevision.docSets.some(ds =>
      ds.explanationDocRevisions?.some(
        d => d.type === 'AgreementForm' && d.signerMembers.length > 0,
      ),
    )
  }, [explanation.latestRevision.docSets])

  const { activeDevices } = useActiveDevice()

  const iamPartnerMember = selectedTrialHospitalUid !== trialHospital?.uid

  const partnerTrialHospitalUid =
    explanation.latestRevision.partnerTrialHospital?.uid

  const pinSettingStatus = explanationRevision.latestPinSettingHistory?.status

  const hasPinSet = pinSettingStatus
    ? hasAlreadyPinSet(pinSettingStatus)
    : false

  const hasPhoneNumberSet = !!patient.phoneNumber

  return (
    <Flex height="full" direction="column" justifyContent="space-between">
      <Stack spacing="8">
        <Text fontSize="xl" fontWeight="bold">
          {selectedTrial.name}
        </Text>
        <Stack spacing="3">
          <Stack spacing="1">
            <Text fontSize="xl" fontWeight="bold">
              候補ID
            </Text>
            <Text>{patient.candidateId}</Text>
          </Stack>
          {selectedTrial.featureFlags.eConsentNewSignFlow && (
            <Stack>
              <HStack spacing="4">
                {hasPinSet ? (
                  <HasSetBadge text="暗証番号" />
                ) : (
                  <NotSetBadge text="暗証番号" />
                )}
                {hasPhoneNumberSet ? (
                  <HasSetBadge text="携帯電話番号" />
                ) : (
                  <NotSetBadge text="携帯電話番号" />
                )}
              </HStack>
              {!iamPartnerMember && (
                <Box>
                  <RequestPinSettingButton
                    isReset={hasPinSet}
                    explanationRevisionUid={explanation.latestRevision.uid}
                    phoneNumber={explanation.patient.phoneNumber}
                    patientUid={explanation.patient.uid}
                    explanationType={explanation.latestRevision.type}
                    activePartnerDeviceActorUids={activeDevices
                      .filter(
                        d =>
                          d.actorTrialHospitalUid === partnerTrialHospitalUid,
                      )
                      .map(d => d.actorUid)}
                    partnerTrialHospitalUid={partnerTrialHospitalUid}
                    mutateSession={mutatePreSession}
                  />
                </Box>
              )}
            </Stack>
          )}
        </Stack>
        {patient.expDiseaseId && (
          <Stack spacing="1">
            <Text fontSize="xl" fontWeight="bold">
              症例番号
            </Text>
            <Text>{patient.expDiseaseId}</Text>
          </Stack>
        )}
        <Stack spacing="1" alignItems="start">
          <Text fontSize="xl" fontWeight="bold">
            本人確認
          </Text>
          <Wrap alignItems="start" spacing="4" spacingY="2">
            <WrapItem>
              <Text>患者</Text>
            </WrapItem>
            <WrapItem>
              <Flex direction="column">
                <ToggleButton
                  toggleAriaLabel="identify-patient"
                  checked={isIdentified}
                  text="確認済"
                  offText="未確認"
                  onChange={async () => {
                    await updateIdentification({
                      explanationPreSessionUid: preSession.uid,
                      isIdentified: !isIdentified,
                    })
                  }}
                />
                {identifiedAt && (
                  <Text>
                    {dayjs(identifiedAt).format('YYYY/MM/DD HH:mm:ss')}
                  </Text>
                )}
              </Flex>
            </WrapItem>
          </Wrap>
        </Stack>
        <VStack alignItems="start" w="full">
          <Text fontSize="xl" fontWeight="bold">
            説明担当者
          </Text>
          <Box maxHeight="192px" w="full">
            {latestRevision.members.length === 0 && (
              <Box my={1}>
                <WarningMessage
                  message="説明担当者が設定されていません。"
                  subMessage="署名を実施するには説明ルームで説明担当者の設定が必要です。"
                />
              </Box>
            )}
            {mainHospitalMembers.length > 0 && (
              <Box>
                <Text fontWeight="bold" as="h3">
                  {trialHospital.name}
                </Text>
                <Stack mt="2" spacing="1" pl="6">
                  {mainHospitalMembers.map(m => (
                    <MemberLabel
                      key={m.trialMember.uid}
                      role={m.trialMember.role}
                      displayName={getFullName(m.trialMember)}
                      isPartner={false}
                    />
                  ))}
                </Stack>
              </Box>
            )}
            {partnerHospitalMembers.length > 0 && (
              <Box mt="4">
                <Text fontWeight="bold" as="h3">
                  {latestRevision.partnerTrialHospital?.name}
                </Text>
                <Stack mt="2" spacing="1" pl="6">
                  {partnerHospitalMembers.map(m => (
                    <MemberLabel
                      key={m.trialMember.uid}
                      role={m.trialMember.role}
                      displayName={getFullName(m.trialMember)}
                      isPartner
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Box>
          <MembersSettingButton
            explanationUid={explanationUid}
            isSignerSet={isSignerSet}
            explanationSessionUid={
              preSession.explanationSessionUid ?? undefined
            }
            mutateSession={mutatePreSession}
            defaultMembers={latestRevision.members.map(m => ({
              trialHospitalUid: m.trialHospital.uid,
              trialMemberUid: m.trialMember.uid,
              role: m.trialMember.role,
            }))}
          />
        </VStack>
      </Stack>
      <Flex alignItems="center" justifyContent="center">
        {!explanationSessionUid && (
          <Button
            colorScheme="green"
            onClick={async () => {
              await createSession({
                type: 'fromPreSession',
                explanationPreSessionUid: preSession.uid,
              })
            }}
            isDisabled={disabledStartSession}
          >
            説明開始
          </Button>
        )}
        {!!explanationSessionUid && (
          <Button
            colorScheme="green"
            onClick={() => {
              navigate(
                generatePath(Paths.HospitalExplanationRoomTop, {
                  trialUid: selectedTrial.uid,
                  sessionUid: explanationSessionUid,
                }),
              )
            }}
          >
            説明ルームへ
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
