import { useState } from 'react'

import { useChallengeSignAuthV2 } from 'src/features/explanation/api/challengeSignAuthV2'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { assertNever } from 'src/utils/assertNever'

import {
  CompleteAuthModal,
  SendOTPByVoiceModal,
  OTPVerificationModal,
} from '../OTPModal/OTPModal'
import { PinVerificationModal } from '../PinVerificationModal/PinVerificationModal'

type Props = {
  isSP: boolean
  trialUid: string
  explanationSignAuthUid: string
  roomTopPath: string
  children: React.ReactNode
}

export const SignAuthChecker: React.FC<Props> = ({
  children,
  isSP,
  trialUid,
  explanationSignAuthUid,
  roomTopPath,
}) => {
  const toast = useMirohaToast()
  const { mutate: challengeAuth, data: challengeRes } = useChallengeSignAuthV2({
    trialUid: trialUid,
    explanationSignAuthenticationUid: explanationSignAuthUid,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    onError: () => {
      toast({
        title: '問題が発生しました',
        status: 'error',
      })
    },
  })

  const [isOpenVoiceModal, setOpenOPTVoiceModal] = useState<boolean>(false)
  const [isOpenAuthCompleteModal, setOpenAuthCompleteModal] =
    useState<boolean>(true)

  if (!challengeRes) {
    return null
  }

  if (challengeRes.message) {
    toast({
      title: challengeRes.message,
      status: 'info',
    })
  }

  switch (challengeRes.result) {
    case 'MemberSignAvailable':
      return <>{children}</>
    case 'PatientSignAvailable':
      if (isOpenAuthCompleteModal) {
        return (
          <CompleteAuthModal
            isSP={isSP}
            isOpen={isOpenAuthCompleteModal}
            onConfirm={() => setOpenAuthCompleteModal(false)}
          />
        )
      }
      return <>{children}</>
    case 'RequiredPatientPinAuthentication':
      return (
        <PinVerificationModal
          trialUid={trialUid}
          explanationSessionUid={challengeRes.explanationSessionUid}
          isSP={isSP}
          roomTooPath={roomTopPath}
          challengeSignAuth={challengeAuth}
        />
      )
    case 'RequiredPatientOTPAuthentication':
      if (isOpenVoiceModal) {
        return (
          <SendOTPByVoiceModal
            trialUid={trialUid}
            explanationSignAuthUid={explanationSignAuthUid}
            isSP={isSP}
            isOpen={true}
            onClose={() => {
              setOpenOPTVoiceModal(false)
            }}
          />
        )
      }
      return (
        <OTPVerificationModal
          trialUid={trialUid}
          explanationSignAuthUid={explanationSignAuthUid}
          isSP={isSP}
          challengeSignAuth={challengeAuth}
          openVoiceOTPModal={() => {
            setOpenOPTVoiceModal(true)
          }}
        />
      )
    case 'ExpiredAndReSent':
    case 'RequiredMemberLogin':
      return null // 説明ルーム内での処理のため、ここには到達しない想定
    case 'AlreadySignCompleted':
      return <>{children}</>
    default:
      return assertNever(challengeRes.result)
  }
}
