import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Schema } from '@micin-jp/chicken-schema'
import { useAtomValue } from 'jotai'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { editingSchemaAtom } from 'src/lib/chicken-schema/atom'
import {
  equalIgnoreVersion,
  omitFalsyValidation,
} from 'src/lib/chicken-schema/utils'
import { CriteriaStatus, PublishCriteriaDocument } from 'src/lib/gql-client'
import { useGqlMutation } from 'src/lib/gql-client/request'

import { useMutateDetailScreen } from '../../hooks/useMutateDetailScreen'

type Props = {
  fetchedSchema: Schema
  criteriaUid: string
  status: CriteriaStatus
}

export const PublishCriteriaButton: React.FC<Props> = ({
  fetchedSchema,
  criteriaUid,
  status,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const editingSchema = useAtomValue(editingSchemaAtom)

  const isEditing = !equalIgnoreVersion(
    omitFalsyValidation(editingSchema),
    omitFalsyValidation(fetchedSchema),
  )

  const toast = useMirohaToast()

  const mutate = useMutateDetailScreen({ criteriaUid })

  const { request: publish } = useGqlMutation(PublishCriteriaDocument, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: '選択除外基準を公開しました。',
      })
      mutate('Published')
    },
  })

  const isDisabled = status !== 'Saved' || isEditing

  return (
    <>
      <Button
        variant="solid"
        onClick={() => {
          onOpen()
        }}
        isDisabled={isDisabled}
      >
        公開
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>選択除外基準を公開しますか？</ModalHeader>
          <ModalBody>
            <Text>
              公開すると所定のパートナー施設で選択除外
              基準が参照できるようになります。
            </Text>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              onClick={async () => {
                await publish({
                  input: {
                    criteriaUid,
                  },
                })
              }}
            >
              公開する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
