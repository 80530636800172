import { generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'

export const getPatientVideoCallUrl = (
  trialUid: string,
  patientVideoCallUid: string,
) => {
  const domain = import.meta.env.VITE_WEB_DOMAIN
  const path = generatePath(Paths.PatientVideoCall, {
    trialUid,
    patientVideoCallUid,
  })
  return domain + path
}
