import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { ExplanationRevision } from '../../types'

const updateDeliveryDate = async ({
  trialUid,
  explanationRevisionUid,
  deliveryDate,
  reason,
}: {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
  deliveryDate: string
  reason: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/delivery_date',
    httpMethod: 'put',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: {
        deliveryDate,
        reason,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useUpdateDeliveryDate = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    ({
      explanationRevisionUid,
      deliveryDate,
      reason,
    }: {
      explanationRevisionUid: ExplanationRevision['uid']
      deliveryDate: string
      reason: string
    }) =>
      updateDeliveryDate({
        trialUid: selectedTrial.uid,
        explanationRevisionUid,
        deliveryDate,
        reason,
      }),
    option,
  )
}
