import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { CallHistory } from './entity'
import { fixture } from './fixture'

type CallHsitoryResponse = {
  uid: string
  caller_name: string
  finished_at: string
  created_at: string
}

const parse = (resp: CallHsitoryResponse): CallHistory => {
  return {
    uid: resp.uid,
    callerName: resp.caller_name,
    finishedAt: resp.finished_at,
    createdAt: resp.created_at,
  }
}

export const fetch = async ({
  trialUid,
  patientUid,
  worksheetUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}): Promise<CallHistory[]> => {
  if (IS_MOCK_MODE) {
    return [fixture]
  }

  // TODO: その worksheet だけでなく患者に紐づくものを全て表示すべき？
  const res = await requestGet<CallHsitoryResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/video_calls`,
    params: {},
  })

  return res.data.map(parse)
}
