import { QuestionnaireFieldAnswer } from './entity'
import { fixtures as QuestionnaireChoiceAnswerFixtures } from '../questionnaire-choice-answer/fixture'
import { AnswerType } from '../questionnaire-field/entity'

export const fixtures: QuestionnaireFieldAnswer[] = [
  {
    uid: 'qfa-uid-1',
    questionnaireSectionAnswerUid: 'qsa-uid-1',
    questionnaireFieldUid: 'qf-uid-1',
    title: '体調はよいですか？(ラジオ形式)',
    answerType: AnswerType.Radio,
    index: 0,
    value: '',
    unit: '',
    choiceAnswers: QuestionnaireChoiceAnswerFixtures,
  },
  {
    uid: 'qfa-uid-2',
    questionnaireSectionAnswerUid: 'qsa-uid-1',
    questionnaireFieldUid: 'qf-uid-2',
    title: '体調はよいですか？(チェックボックス形式)',
    answerType: AnswerType.CheckBox,
    index: 1,
    value: '',
    unit: '',
    choiceAnswers: QuestionnaireChoiceAnswerFixtures,
  },
  {
    uid: 'qfa-uid-3',
    questionnaireSectionAnswerUid: 'qsa-uid-1',
    questionnaireFieldUid: 'qf-uid-3',
    title: '体調はよいですか？(テキスト形式)',
    answerType: AnswerType.Text,
    index: 2,
    value: '回答の値',
    unit: '',
    choiceAnswers: [],
  },
  {
    uid: 'qfa-uid-4',
    questionnaireSectionAnswerUid: 'qsa-uid-1',
    questionnaireFieldUid: 'qf-uid-4',
    title: '体調はよいですか？(数字形式)',
    answerType: AnswerType.Number,
    index: 3,
    value: '回答の値',
    unit: '回',
    choiceAnswers: [],
  },
]
