import { useCallback, useMemo, useState } from 'react'

import { Button, Stack, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import {
  StaticScreen,
  StaticScreenContent,
  StaticScreenFooter,
  StaticScreenTitle,
} from 'src/components/StaticScreen/StaticScreen'
import { useResendDeliveryDocs } from 'src/features/explanation/api/resendDeliveryDocs'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

export const DeliveryDocUrlIsExpired: React.FC = () => {
  const toast = useMirohaToast()

  const location = useLocation()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  )

  const [requestSucceeded, setRequestSucceeded] = useState(false)

  const { request, requesting } = useResendDeliveryDocs({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '再送メールを送信しました',
      })
      setRequestSucceeded(true)
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const onSubmit = useCallback(async () => {
    const trialUid = searchParams.get('trial_uid')
    const explanationRevisionUid = searchParams.get('explanation_revision_uid')
    const target = searchParams.get('target')
    const trialMemberUid = searchParams.get('trial_member_uid')
    if (
      !trialUid ||
      !explanationRevisionUid ||
      (target !== 'Patient' && target !== 'Member')
    ) {
      toast({
        status: 'error',
        title: '再送メールの送信先を特定できませんでした',
      })
      return
    }
    await request({
      trialUid,
      explanationRevisionUid,
      target,
      trialMemberUid: trialMemberUid ?? undefined,
    })
  }, [request, searchParams, toast])

  return (
    <StaticScreen>
      <StaticScreenTitle>
        対象の交付文書のURLが期限切れとなっています
      </StaticScreenTitle>
      <StaticScreenContent>
        <Text>以下のリンクより、交付文書の再送メールを送信してください。</Text>
      </StaticScreenContent>
      <StaticScreenFooter>
        <Stack textAlign="center" spacing="4">
          <Button
            variant="text"
            onClick={onSubmit}
            disabled={requesting || requestSucceeded}
          >
            再送メールを送信
          </Button>
        </Stack>
      </StaticScreenFooter>
    </StaticScreen>
  )
}
