import React from 'react'

import { Box, Button } from '@chakra-ui/react'
import { Add } from 'src/components/icon'

import { useOpenModal } from '../../../../../../../../hooks/use-open-modal'
import { ModalContainer } from '../modal/create-modal-container'

type Props = {
  v2: boolean
}

export const CreateButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <Box>
      <Button variant="outline" onClick={openModal} leftIcon={<Add />}>
        新規フォルダ追加
      </Button>
      {modalOpen && <ModalContainer v2={props.v2} onClose={closeModal} />}
    </Box>
  )
}
