/**
 * 配列内のアイテムを指定したキーでグループ化する
 *
 * @example
 * const people = [
 *   { name: 'Alice', age: 30 },
 *   { name: 'Bob', age: 25 },
 *   { name: 'Charlie', age: 30 },
 *   { name: 'Dave', age: 25 },
 * ];
 * const groupedByAge = groupBy(people, person => person.age);
 * // groupedByAge の結果は { 25: [{ name: 'Bob', age: 25 }, { name: 'Dave', age: 25 }], 30: [{ name: 'Alice', age: 30 }, { name: 'Charlie', age: 30 }] }
 *
 */
export const groupBy = <T, K extends keyof any>(array: T[], key: (i: T) => K) =>
  array.reduce(
    (groups, item) => {
      if (!groups[key(item)]) {
        groups[key(item)] = []
      }
      groups[key(item)].push(item)
      return groups
    },
    {} as Record<K, T[]>,
  )
