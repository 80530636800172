import React, { memo } from 'react'

import { useSelector } from 'react-redux'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Spacer } from 'src/components/spacer/spacer'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { getSelectedTrial } from '../../../../../../../entities/account/selector'
import { BackToDraftButton } from '../back-to-draft/back-to-draft-button'
import { ConfirmButton } from '../confirm/confirm-button'
import { PulldownContainer } from '../pulldown/pulldown-container'
import { SaveButton } from '../save/save-button'
import { useQuestionnaireDetailPermission } from '../use-permission'

export const Head: React.FC = memo(() => {
  const selectedTrial = useSelector(getSelectedTrial)
  const trialUid = selectedTrial?.uid

  const questionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail,
  )
  const { uid: questionnaireUid } = questionnaire

  const breadcrumbParams: BreadcrumbParam[] = [
    { label: '質問票', isLink: true, path: '../' },
    { label: '詳細', isLink: false },
  ]

  const { canSave, canConfirm, canBackToDraft } =
    useQuestionnaireDetailPermission()

  if (!trialUid) return null
  return (
    <Container>
      <Breadcrumb breadcrumbParams={breadcrumbParams} />
      <ButtonContainer>
        {canSave && (
          <>
            <SaveButton />
            <Spacer size={10} horizontal />
          </>
        )}

        {canConfirm && (
          <>
            <ConfirmButton />
            <Spacer size={10} horizontal />
          </>
        )}

        {canBackToDraft && (
          <>
            <BackToDraftButton />
            <Spacer size={10} horizontal />
          </>
        )}
        <PulldownContainer
          trialUid={trialUid}
          questionnaireUid={questionnaireUid}
        />
      </ButtonContainer>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
