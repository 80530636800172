import { generateUUID } from 'src/utils/generateUUID';
import dayjs from 'dayjs'
import * as Types from './client-preset/graphql';
export function createAcceptReferredPatientInputMock(props: Partial<Types.AcceptReferredPatientInput> = {}): Types.AcceptReferredPatientInput {
  return {
    note: null,
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createAcceptReferredPatientPayloadMock(props: Partial<Types.AcceptReferredPatientPayload> = {}): Types.AcceptReferredPatientPayload {
  return {
    __typename: "AcceptReferredPatientPayload",
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createAddExpRoomEventInputMock(props: Partial<Types.AddExpRoomEventInput> = {}): Types.AddExpRoomEventInput {
  return {
    activeDeviceEvent: null,
    currentLocationEvent: null,
    explanationPatientUid: null,
    explanationPreSessionUid: null,
    explanationSessionUid: null,
    pointLocationEvent: null,
    requestPinSettingEvent: null,
    requestSignEvent: null,
    trialHospitalUid: generateUUID(),
    trialMemberUid: null,
    ...props,
  };
}

export function createAddExpRoomEventPayloadMock(props: Partial<Types.AddExpRoomEventPayload> = {}): Types.AddExpRoomEventPayload {
  return {
    __typename: "AddExpRoomEventPayload",
    explanationRoomEventUid: generateUUID(),
    ...props,
  };
}

export function createBrowseExpDocRevisionInputMock(props: Partial<Types.BrowseExpDocRevisionInput> = {}): Types.BrowseExpDocRevisionInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createBrowseExpDocRevisionPayloadMock(props: Partial<Types.BrowseExpDocRevisionPayload> = {}): Types.BrowseExpDocRevisionPayload {
  return {
    __typename: "BrowseExpDocRevisionPayload",
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createBrowseReferredPatientInputMock(props: Partial<Types.BrowseReferredPatientInput> = {}): Types.BrowseReferredPatientInput {
  return {
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createBrowseReferredPatientPayloadMock(props: Partial<Types.BrowseReferredPatientPayload> = {}): Types.BrowseReferredPatientPayload {
  return {
    __typename: "BrowseReferredPatientPayload",
    isLogRecorded: false,
    referredPatientLogUid: null,
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createBrowseStickyPayloadMock(props: Partial<Types.BrowseStickyPayload> = {}): Types.BrowseStickyPayload {
  return {
    __typename: "BrowseStickyPayload",
    stickyUid: generateUUID(),
    ...props,
  };
}

export function createCancelReferredPatientInputMock(props: Partial<Types.CancelReferredPatientInput> = {}): Types.CancelReferredPatientInput {
  return {
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createCancelReferredPatientPayloadMock(props: Partial<Types.CancelReferredPatientPayload> = {}): Types.CancelReferredPatientPayload {
  return {
    __typename: "CancelReferredPatientPayload",
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createCancelReservationForExplanationRevisionInputMock(props: Partial<Types.CancelReservationForExplanationRevisionInput> = {}): Types.CancelReservationForExplanationRevisionInput {
  return {
    explanationReservationUid: generateUUID(),
    ...props,
  };
}

export function createCancelReservationForExplanationRevisionPayloadMock(props: Partial<Types.CancelReservationForExplanationRevisionPayload> = {}): Types.CancelReservationForExplanationRevisionPayload {
  return {
    __typename: "CancelReservationForExplanationRevisionPayload",
    explanationReservationUid: generateUUID(),
    ...props,
  };
}

export function createCancelWorksheetExportCsvJobInputMock(props: Partial<Types.CancelWorksheetExportCsvJobInput> = {}): Types.CancelWorksheetExportCsvJobInput {
  return {
    jobUid: generateUUID(),
    ...props,
  };
}

export function createCancelWorksheetExportCsvJobPayloadMock(props: Partial<Types.CancelWorksheetExportCsvJobPayload> = {}): Types.CancelWorksheetExportCsvJobPayload {
  return {
    __typename: "CancelWorksheetExportCSVJobPayload",
    job: createWorksheetCsvExportJobMock({}),
    jobUid: generateUUID(),
    ...props,
  };
}

export function createCheckboxFieldValueMock(props: Partial<Types.CheckboxFieldValue> = {}): Types.CheckboxFieldValue {
  return {
    __typename: "CheckboxFieldValue",
    checkboxValue: [],
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createClearedFieldValueMock(props: Partial<Types.ClearedFieldValue> = {}): Types.ClearedFieldValue {
  return {
    __typename: "ClearedFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createCompleteAgreementInputMock(props: Partial<Types.CompleteAgreementInput> = {}): Types.CompleteAgreementInput {
  return {
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createCompleteAgreementPayloadMock(props: Partial<Types.CompleteAgreementPayload> = {}): Types.CompleteAgreementPayload {
  return {
    __typename: "CompleteAgreementPayload",
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createConfirmWorksheetInputMock(props: Partial<Types.ConfirmWorksheetInput> = {}): Types.ConfirmWorksheetInput {
  return {
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createConfirmWorksheetPayloadMock(props: Partial<Types.ConfirmWorksheetPayload> = {}): Types.ConfirmWorksheetPayload {
  return {
    __typename: "ConfirmWorksheetPayload",
    worksheet: createWorksheetMock({}),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createCreateAgreementFormEnvelopeInputMock(props: Partial<Types.CreateAgreementFormEnvelopeInput> = {}): Types.CreateAgreementFormEnvelopeInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    skipEmail: null,
    ...props,
  };
}

export function createCreateAgreementFormEnvelopePayloadMock(props: Partial<Types.CreateAgreementFormEnvelopePayload> = {}): Types.CreateAgreementFormEnvelopePayload {
  return {
    __typename: "CreateAgreementFormEnvelopePayload",
    crcSignAuthUid: null,
    drSignAuthUid: null,
    dsEnvelopeId: generateUUID(),
    patientSignAuthUid: null,
    ...props,
  };
}

export function createCreateAgreementFormEnvelopesInputMock(props: Partial<Types.CreateAgreementFormEnvelopesInput> = {}): Types.CreateAgreementFormEnvelopesInput {
  return {
    explanationDocRevisionUids: [],
    explanationSessionUid: generateUUID(),
    ...props,
  };
}

export function createCreateAgreementFormEnvelopesPayloadMock(props: Partial<Types.CreateAgreementFormEnvelopesPayload> = {}): Types.CreateAgreementFormEnvelopesPayload {
  return {
    __typename: "CreateAgreementFormEnvelopesPayload",
    createdEnvelopes: [],
    ...props,
  };
}

export function createCreateAgreementFormEnvelopesPayloadEnvelopeMock(props: Partial<Types.CreateAgreementFormEnvelopesPayloadEnvelope> = {}): Types.CreateAgreementFormEnvelopesPayloadEnvelope {
  return {
    __typename: "CreateAgreementFormEnvelopesPayloadEnvelope",
    dsEnvelopeId: generateUUID(),
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createCreateCheckUnderstandingEnvelopeInputMock(props: Partial<Types.CreateCheckUnderstandingEnvelopeInput> = {}): Types.CreateCheckUnderstandingEnvelopeInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    ...props,
  };
}

export function createCreateCheckUnderstandingEnvelopePayloadMock(props: Partial<Types.CreateCheckUnderstandingEnvelopePayload> = {}): Types.CreateCheckUnderstandingEnvelopePayload {
  return {
    __typename: "CreateCheckUnderstandingEnvelopePayload",
    dsEnvelopeId: generateUUID(),
    ...props,
  };
}

export function createCreateCriteriaInputMock(props: Partial<Types.CreateCriteriaInput> = {}): Types.CreateCriteriaInput {
  return {
    title: "",
    ...props,
  };
}

export function createCreateCriteriaPayloadMock(props: Partial<Types.CreateCriteriaPayload> = {}): Types.CreateCriteriaPayload {
  return {
    __typename: "CreateCriteriaPayload",
    criteriaDetailUid: generateUUID(),
    criteriaLogUid: generateUUID(),
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createCreateEnvelopeViewUrlInputMock(props: Partial<Types.CreateEnvelopeViewUrlInput> = {}): Types.CreateEnvelopeViewUrlInput {
  return {
    createType: "FromEmailLink",
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    returnUrl: "",
    signerActorUid: null,
    signerRole: null,
    ...props,
  };
}

export function createCreateEnvelopeViewUrlPayloadMock(props: Partial<Types.CreateEnvelopeViewUrlPayload> = {}): Types.CreateEnvelopeViewUrlPayload {
  return {
    __typename: "CreateEnvelopeViewUrlPayload",
    alreadyFinished: false,
    explanationDocRevisionUid: generateUUID(),
    viewUrl: null,
    ...props,
  };
}

export function createCreateExpSessionInputMock(props: Partial<Types.CreateExpSessionInput> = {}): Types.CreateExpSessionInput {
  return {
    explanationPreSessionUid: null,
    explanationRevisionUid: null,
    ...props,
  };
}

export function createCreateExpSessionPayloadMock(props: Partial<Types.CreateExpSessionPayload> = {}): Types.CreateExpSessionPayload {
  return {
    __typename: "CreateExpSessionPayload",
    explanationDocRevUids: [],
    explanationSessionUid: generateUUID(),
    ...props,
  };
}

export function createCreateFollowUpWorksheetsInputMock(props: Partial<Types.CreateFollowUpWorksheetsInput> = {}): Types.CreateFollowUpWorksheetsInput {
  return {
    followUpWorksheetInputs: [],
    patientObservationUid: generateUUID(),
    ...props,
  };
}

export function createCreateFollowUpWorksheetsPayloadMock(props: Partial<Types.CreateFollowUpWorksheetsPayload> = {}): Types.CreateFollowUpWorksheetsPayload {
  return {
    __typename: "CreateFollowUpWorksheetsPayload",
    worksheetUids: [],
    worksheets: [],
    ...props,
  };
}

export function createCreateOrGetExpPreSessionInputMock(props: Partial<Types.CreateOrGetExpPreSessionInput> = {}): Types.CreateOrGetExpPreSessionInput {
  return {
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createCreateOrGetExpPreSessionPayloadMock(props: Partial<Types.CreateOrGetExpPreSessionPayload> = {}): Types.CreateOrGetExpPreSessionPayload {
  return {
    __typename: "CreateOrGetExpPreSessionPayload",
    explanationPreSession: createExplanationPreSessionMock({}),
    explanationPreSessionUid: generateUUID(),
    videoCallRoomUid: generateUUID(),
    ...props,
  };
}

export function createCreatePatientInputMock(props: Partial<Types.CreatePatientInput> = {}): Types.CreatePatientInput {
  return {
    birthday: null,
    chartUid: null,
    diseaseUid: "",
    email: null,
    firstName: null,
    firstNameJa: null,
    gender: "Unspecified",
    lastName: null,
    lastNameJa: null,
    partnerTrialHospitalUid: null,
    phoneNumber: null,
    trialHospitalUid: generateUUID(),
    ...props,
  };
}

export function createCreatePatientPayloadMock(props: Partial<Types.CreatePatientPayload> = {}): Types.CreatePatientPayload {
  return {
    __typename: "CreatePatientPayload",
    patientUid: generateUUID(),
    ...props,
  };
}

export function createCreatePatientVideoCallInputMock(props: Partial<Types.CreatePatientVideoCallInput> = {}): Types.CreatePatientVideoCallInput {
  return {
    patientUid: generateUUID(),
    patientVideoCallType: "PartnerTrialHospital",
    ...props,
  };
}

export function createCreatePatientVideoCallPayloadMock(props: Partial<Types.CreatePatientVideoCallPayload> = {}): Types.CreatePatientVideoCallPayload {
  return {
    __typename: "CreatePatientVideoCallPayload",
    patientVideoCallUid: generateUUID(),
    tempUrl: null,
    videoCallRoomUid: generateUUID(),
    ...props,
  };
}

export function createCreateReferredPatientInputMock(props: Partial<Types.CreateReferredPatientInput> = {}): Types.CreateReferredPatientInput {
  return {
    age: 0,
    assigneeEmail: null,
    assigneeName: "",
    assigneePhoneNumber: null,
    assigneeRole: null,
    chartId: null,
    criteriaUid: generateUUID(),
    gender: "Unspecified",
    name: null,
    ...props,
  };
}

export function createCreateReferredPatientPayloadMock(props: Partial<Types.CreateReferredPatientPayload> = {}): Types.CreateReferredPatientPayload {
  return {
    __typename: "CreateReferredPatientPayload",
    patientMasterUid: generateUUID(),
    referredPatientContactUid: generateUUID(),
    referredPatientCriteriaUid: generateUUID(),
    referredPatientDetailUid: generateUUID(),
    referredPatientLogUid: generateUUID(),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createCreateReservationForExplanationRevisionInputMock(props: Partial<Types.CreateReservationForExplanationRevisionInput> = {}): Types.CreateReservationForExplanationRevisionInput {
  return {
    explanationRevisionUid: generateUUID(),
    reservationInviteeType: "PartnerMembers",
    reservationType: "AfterConsent",
    reservedAt: dayjs().format(),
    ...props,
  };
}

export function createCreateReservationForExplanationRevisionPayloadMock(props: Partial<Types.CreateReservationForExplanationRevisionPayload> = {}): Types.CreateReservationForExplanationRevisionPayload {
  return {
    __typename: "CreateReservationForExplanationRevisionPayload",
    explanationReservationUid: generateUUID(),
    ...props,
  };
}

export function createCreateSignAuthenticationInputMock(props: Partial<Types.CreateSignAuthenticationInput> = {}): Types.CreateSignAuthenticationInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    signerActorUid: generateUUID(),
    ...props,
  };
}

export function createCreateSignAuthenticationPayloadMock(props: Partial<Types.CreateSignAuthenticationPayload> = {}): Types.CreateSignAuthenticationPayload {
  return {
    __typename: "CreateSignAuthenticationPayload",
    signAuthenticationUid: generateUUID(),
    ...props,
  };
}

export function createCreateVideoCallRoomForAfterConsentInputMock(props: Partial<Types.CreateVideoCallRoomForAfterConsentInput> = {}): Types.CreateVideoCallRoomForAfterConsentInput {
  return {
    patientMasterUid: generateUUID(),
    reservationUid: null,
    ...props,
  };
}

export function createCreateVideoCallRoomForAfterConsentPayloadMock(props: Partial<Types.CreateVideoCallRoomForAfterConsentPayload> = {}): Types.CreateVideoCallRoomForAfterConsentPayload {
  return {
    __typename: "CreateVideoCallRoomForAfterConsentPayload",
    videoCallRoomUid: generateUUID(),
    visitUid: generateUUID(),
    visitVideoCallUid: generateUUID(),
    ...props,
  };
}

export function createCreateWorksheetFieldStickyInputMock(props: Partial<Types.CreateWorksheetFieldStickyInput> = {}): Types.CreateWorksheetFieldStickyInput {
  return {
    fid: generateUUID(),
    fieldIndex: 0,
    message: "",
    priority: "High",
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createCreateWorksheetFieldStickyPayloadMock(props: Partial<Types.CreateWorksheetFieldStickyPayload> = {}): Types.CreateWorksheetFieldStickyPayload {
  return {
    __typename: "CreateWorksheetFieldStickyPayload",
    sticky: createStickyMock({}),
    stickyUid: generateUUID(),
    ...props,
  };
}

export function createCreateWorksheetsInputMock(props: Partial<Types.CreateWorksheetsInput> = {}): Types.CreateWorksheetsInput {
  return {
    patientObservationUid: generateUUID(),
    worksheetSchemaUids: [],
    ...props,
  };
}

export function createCreateWorksheetsPayloadMock(props: Partial<Types.CreateWorksheetsPayload> = {}): Types.CreateWorksheetsPayload {
  return {
    __typename: "CreateWorksheetsPayload",
    worksheetUids: [],
    worksheets: [],
    ...props,
  };
}

export function createCriteriaMock(props: Partial<Types.Criteria> = {}): Types.Criteria {
  return {
    __typename: "Criteria",
    belongAllTrialHospitals: false,
    criteriaUid: generateUUID(),
    latestDetail: createCriteriaDetailMock({}),
    latestLog: createCriteriaLogMock({}),
    numberingId: 0,
    publishedAt: null,
    trialHospital: null,
    trialHospitalUid: null,
    ...props,
  };
}

export function createCriteriaDetailMock(props: Partial<Types.CriteriaDetail> = {}): Types.CriteriaDetail {
  return {
    __typename: "CriteriaDetail",
    criteriaDetailUid: generateUUID(),
    criteriaLogUid: generateUUID(),
    criteriaSchema: createCriteriaSchemaMock({}),
    criteriaSchemaUid: null,
    criteriaUid: generateUUID(),
    savedAt: dayjs().format(),
    title: "",
    ...props,
  };
}

export function createCriteriaFileMock(props: Partial<Types.CriteriaFile> = {}): Types.CriteriaFile {
  return {
    __typename: "CriteriaFile",
    fileOrder: 0,
    memo: "",
    referredPatientCriteriaValueFileUid: generateUUID(),
    uploadedFile: createUploadedFileMock({}),
    uploadedFileUid: generateUUID(),
    ...props,
  };
}

export function createCriteriaFileDownloadLogMock(props: Partial<Types.CriteriaFileDownloadLog> = {}): Types.CriteriaFileDownloadLog {
  return {
    __typename: "CriteriaFileDownloadLog",
    criteriaDownloadLogUid: generateUUID(),
    downloadedAt: dayjs().format(),
    referredPatientCriteriaValueFileUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    valueFile: createReferredPatientCriteriaValueFileMock({}),
    ...props,
  };
}

export function createCriteriaListInputMock(props: Partial<Types.CriteriaListInput> = {}): Types.CriteriaListInput {
  return {
    order: "NumberingIdDesc",
    ...props,
  };
}

export function createCriteriaListPayloadMock(props: Partial<Types.CriteriaListPayload> = {}): Types.CriteriaListPayload {
  return {
    __typename: "CriteriaListPayload",
    criteriaList: [],
    total: 0,
    ...props,
  };
}

export function createCriteriaLogMock(props: Partial<Types.CriteriaLog> = {}): Types.CriteriaLog {
  return {
    __typename: "CriteriaLog",
    action: "Create",
    actionTrialMember: createTrialMemberMock({}),
    actionTrialMemberUID: generateUUID(),
    criteriaLogUid: generateUUID(),
    criteriaUid: generateUUID(),
    savedAt: dayjs().format(),
    status: "Created",
    ...props,
  };
}

export function createCriteriaSchemaMock(props: Partial<Types.CriteriaSchema> = {}): Types.CriteriaSchema {
  return {
    __typename: "CriteriaSchema",
    criteriaSchemaUid: generateUUID(),
    schema: "",
    ...props,
  };
}

export function createDateFieldValueMock(props: Partial<Types.DateFieldValue> = {}): Types.DateFieldValue {
  return {
    __typename: "DateFieldValue",
    dateValue: "",
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createDeleteCriteriaInputMock(props: Partial<Types.DeleteCriteriaInput> = {}): Types.DeleteCriteriaInput {
  return {
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createDeleteCriteriaPayloadMock(props: Partial<Types.DeleteCriteriaPayload> = {}): Types.DeleteCriteriaPayload {
  return {
    __typename: "DeleteCriteriaPayload",
    criteriaLogUid: generateUUID(),
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createDeleteReferredPatientInputMock(props: Partial<Types.DeleteReferredPatientInput> = {}): Types.DeleteReferredPatientInput {
  return {
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createDeleteReferredPatientPayloadMock(props: Partial<Types.DeleteReferredPatientPayload> = {}): Types.DeleteReferredPatientPayload {
  return {
    __typename: "DeleteReferredPatientPayload",
    referredPatientLogUid: generateUUID(),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createDeleteSignSessionPayloadMock(props: Partial<Types.DeleteSignSessionPayload> = {}): Types.DeleteSignSessionPayload {
  return {
    __typename: "DeleteSignSessionPayload",
    deleted: false,
    ...props,
  };
}

export function createDeleteStickyInputMock(props: Partial<Types.DeleteStickyInput> = {}): Types.DeleteStickyInput {
  return {
    stickyUid: "",
    ...props,
  };
}

export function createDeleteStickyMessageInputMock(props: Partial<Types.DeleteStickyMessageInput> = {}): Types.DeleteStickyMessageInput {
  return {
    stickyMessageUid: "",
    ...props,
  };
}

export function createDeleteStickyMessagePayloadMock(props: Partial<Types.DeleteStickyMessagePayload> = {}): Types.DeleteStickyMessagePayload {
  return {
    __typename: "DeleteStickyMessagePayload",
    sticky: createStickyMock({}),
    stickyMessageUid: generateUUID(),
    stickyUid: generateUUID(),
    ...props,
  };
}

export function createDeleteStickyPayloadMock(props: Partial<Types.DeleteStickyPayload> = {}): Types.DeleteStickyPayload {
  return {
    __typename: "DeleteStickyPayload",
    stickyUid: generateUUID(),
    ...props,
  };
}

export function createDeliverDocumentsInputMock(props: Partial<Types.DeliverDocumentsInput> = {}): Types.DeliverDocumentsInput {
  return {
    deliveryDate: null,
    deliveryType: "Download",
    emailTargetTrialMemberUid: null,
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createDeliverDocumentsPayloadMock(props: Partial<Types.DeliverDocumentsPayload> = {}): Types.DeliverDocumentsPayload {
  return {
    __typename: "DeliverDocumentsPayload",
    downloadURL: null,
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createDisableCriteriaInputMock(props: Partial<Types.DisableCriteriaInput> = {}): Types.DisableCriteriaInput {
  return {
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createDisableCriteriaPayloadMock(props: Partial<Types.DisableCriteriaPayload> = {}): Types.DisableCriteriaPayload {
  return {
    __typename: "DisableCriteriaPayload",
    criteriaLogUid: generateUUID(),
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createDisablePatientInputMock(props: Partial<Types.DisablePatientInput> = {}): Types.DisablePatientInput {
  return {
    patientUid: generateUUID(),
    reason: "",
    ...props,
  };
}

export function createDisablePatientPayloadMock(props: Partial<Types.DisablePatientPayload> = {}): Types.DisablePatientPayload {
  return {
    __typename: "DisablePatientPayload",
    patientUid: generateUUID(),
    ...props,
  };
}

export function createDisableWorksheetInputMock(props: Partial<Types.DisableWorksheetInput> = {}): Types.DisableWorksheetInput {
  return {
    reason: "",
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createDisableWorksheetPayloadMock(props: Partial<Types.DisableWorksheetPayload> = {}): Types.DisableWorksheetPayload {
  return {
    __typename: "DisableWorksheetPayload",
    worksheet: createWorksheetMock({}),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createDownloadReferredPatientCriteriaFieldFileInputMock(props: Partial<Types.DownloadReferredPatientCriteriaFieldFileInput> = {}): Types.DownloadReferredPatientCriteriaFieldFileInput {
  return {
    referredPatientCriteriaValueFileUid: generateUUID(),
    ...props,
  };
}

export function createDownloadReferredPatientCriteriaFieldFilePayloadMock(props: Partial<Types.DownloadReferredPatientCriteriaFieldFilePayload> = {}): Types.DownloadReferredPatientCriteriaFieldFilePayload {
  return {
    __typename: "DownloadReferredPatientCriteriaFieldFilePayload",
    downloadUrl: "",
    ...props,
  };
}

export function createDownloadWorksheetFieldFileInputMock(props: Partial<Types.DownloadWorksheetFieldFileInput> = {}): Types.DownloadWorksheetFieldFileInput {
  return {
    fieldValueUid: generateUUID(),
    ...props,
  };
}

export function createDownloadWorksheetFieldFilePayloadMock(props: Partial<Types.DownloadWorksheetFieldFilePayload> = {}): Types.DownloadWorksheetFieldFilePayload {
  return {
    __typename: "DownloadWorksheetFieldFilePayload",
    downloadUrl: "",
    ...props,
  };
}

export function createEditStickyMessageInputMock(props: Partial<Types.EditStickyMessageInput> = {}): Types.EditStickyMessageInput {
  return {
    message: "",
    priority: "High",
    stickyMessageUid: "",
    ...props,
  };
}

export function createEditStickyMessagePayloadMock(props: Partial<Types.EditStickyMessagePayload> = {}): Types.EditStickyMessagePayload {
  return {
    __typename: "EditStickyMessagePayload",
    sticky: createStickyMock({}),
    stickyMessageUid: generateUUID(),
    stickyUid: generateUUID(),
    ...props,
  };
}

export function createEnableCriteriaInputMock(props: Partial<Types.EnableCriteriaInput> = {}): Types.EnableCriteriaInput {
  return {
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createEnableCriteriaPayloadMock(props: Partial<Types.EnableCriteriaPayload> = {}): Types.EnableCriteriaPayload {
  return {
    __typename: "EnableCriteriaPayload",
    criteriaLogUid: generateUUID(),
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createEnablePatientInputMock(props: Partial<Types.EnablePatientInput> = {}): Types.EnablePatientInput {
  return {
    patientUid: generateUUID(),
    reason: "",
    ...props,
  };
}

export function createEnablePatientPayloadMock(props: Partial<Types.EnablePatientPayload> = {}): Types.EnablePatientPayload {
  return {
    __typename: "EnablePatientPayload",
    patientUid: generateUUID(),
    ...props,
  };
}

export function createEnableWorksheetInputMock(props: Partial<Types.EnableWorksheetInput> = {}): Types.EnableWorksheetInput {
  return {
    reason: "",
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createEnableWorksheetPayloadMock(props: Partial<Types.EnableWorksheetPayload> = {}): Types.EnableWorksheetPayload {
  return {
    __typename: "EnableWorksheetPayload",
    worksheet: createWorksheetMock({}),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createExcludeReferredPatientInputMock(props: Partial<Types.ExcludeReferredPatientInput> = {}): Types.ExcludeReferredPatientInput {
  return {
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createExcludeReferredPatientPayloadMock(props: Partial<Types.ExcludeReferredPatientPayload> = {}): Types.ExcludeReferredPatientPayload {
  return {
    __typename: "ExcludeReferredPatientPayload",
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createExpCurrentIcfDocumentMock(props: Partial<Types.ExpCurrentIcfDocument> = {}): Types.ExpCurrentIcfDocument {
  return {
    __typename: "ExpCurrentICFDocument",
    icfDocumentRevision: createIcfDocumentRevisionAgreementFormMock({}),
    icfDocumentRevisionUid: generateUUID(),
    index: 0,
    numberingId: 0,
    ...props,
  };
}

export function createExpRoomActiveDeviceEventInputMock(props: Partial<Types.ExpRoomActiveDeviceEventInput> = {}): Types.ExpRoomActiveDeviceEventInput {
  return {
    deleted: null,
    deviceActorTrialHospitalUid: generateUUID(),
    deviceActorUid: generateUUID(),
    ...props,
  };
}

export function createExpRoomCurrentLocationEventInputMock(props: Partial<Types.ExpRoomCurrentLocationEventInput> = {}): Types.ExpRoomCurrentLocationEventInput {
  return {
    deleted: null,
    page: null,
    path: null,
    ...props,
  };
}

export function createExpRoomPointLocationEventInputMock(props: Partial<Types.ExpRoomPointLocationEventInput> = {}): Types.ExpRoomPointLocationEventInput {
  return {
    page: null,
    path: "",
    ...props,
  };
}

export function createExpRoomRequestPinSettingEventInputMock(props: Partial<Types.ExpRoomRequestPinSettingEventInput> = {}): Types.ExpRoomRequestPinSettingEventInput {
  return {
    deviceActorTrialHospitalUid: null,
    deviceActorUid: generateUUID(),
    ...props,
  };
}

export function createExpRoomRequestSignEventInputMock(props: Partial<Types.ExpRoomRequestSignEventInput> = {}): Types.ExpRoomRequestSignEventInput {
  return {
    deviceActorUid: generateUUID(),
    explanationDocRevisionUids: [],
    signType: "OnScreen",
    signerActorUid: generateUUID(),
    ...props,
  };
}

export function createExplanationMock(props: Partial<Types.Explanation> = {}): Types.Explanation {
  return {
    __typename: "Explanation",
    explanationPatientUid: generateUUID(),
    isRequiredReAgreement: false,
    latestRevision: createExplanationRevisionMock({}),
    patient: createExplanationPatientMock({}),
    reservations: [],
    revisions: [],
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    uid: generateUUID(),
    videoCallRooms: [],
    ...props,
  };
}

export function createExplanationDocRevisionMock(props: Partial<Types.ExplanationDocRevision> = {}): Types.ExplanationDocRevision {
  switch(props.__typename) {
    case "ExplanationDocRevisionAgreementForm":
      return createExplanationDocRevisionAgreementFormMock(props);
    case "ExplanationDocRevisionCheckUnderstanding":
      return createExplanationDocRevisionCheckUnderstandingMock(props);
    case "ExplanationDocRevisionDescription":
      return createExplanationDocRevisionDescriptionMock(props);
    case "ExplanationDocRevisionVideo":
      return createExplanationDocRevisionVideoMock(props);
    case undefined:
    default:
      return createExplanationDocRevisionMock({ __typename: "ExplanationDocRevisionAgreementForm", ...props });
  }
}

export function createExplanationDocRevisionAgreementFormMock(props: Partial<Types.ExplanationDocRevisionAgreementForm> = {}): Types.ExplanationDocRevisionAgreementForm {
  return {
    __typename: "ExplanationDocRevisionAgreementForm",
    browsingHistories: [],
    completedSignerRoles: [],
    documentType: "AgreementForm",
    docusignEnvelopeId: null,
    explanationDocSetUid: generateUUID(),
    file: createExplanationDocRevisionFileMock({}),
    icfDocumentRevision: createIcfDocumentRevisionAgreementFormMock({}),
    icfDocumentRevisionUid: generateUUID(),
    index: 0,
    isCompleted: false,
    isPatientConsentRequired: false,
    latestPatientSideBrowsingHistory: null,
    signHistories: [],
    signerMembers: [],
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionBrowsingHistoryMock(props: Partial<Types.ExplanationDocRevisionBrowsingHistory> = {}): Types.ExplanationDocRevisionBrowsingHistory {
  return {
    __typename: "ExplanationDocRevisionBrowsingHistory",
    browserRole: "CRC",
    explanationDocRevisionUid: generateUUID(),
    isPartner: false,
    operatedAt: dayjs().format(),
    trialHospital: null,
    trialHospitalUid: null,
    trialMember: null,
    trialMemberUid: null,
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionCheckUnderstandingMock(props: Partial<Types.ExplanationDocRevisionCheckUnderstanding> = {}): Types.ExplanationDocRevisionCheckUnderstanding {
  return {
    __typename: "ExplanationDocRevisionCheckUnderstanding",
    browsingHistories: [],
    documentType: "AgreementForm",
    docusignEnvelopeId: null,
    explanationDocSetUid: generateUUID(),
    file: createExplanationDocRevisionFileMock({}),
    icfDocumentRevision: createIcfDocumentRevisionAgreementFormMock({}),
    icfDocumentRevisionUid: generateUUID(),
    index: 0,
    isCompleted: false,
    latestPatientSideBrowsingHistory: null,
    numberingId: 0,
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionCreateEnvelopeViewUrlHistoryMock(props: Partial<Types.ExplanationDocRevisionCreateEnvelopeViewUrlHistory> = {}): Types.ExplanationDocRevisionCreateEnvelopeViewUrlHistory {
  return {
    __typename: "ExplanationDocRevisionCreateEnvelopeViewUrlHistory",
    createType: "FromEmailLink",
    creator: null,
    creatorTrialMemberUid: null,
    savedAt: dayjs().format(),
    signerRole: "CRC",
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionDescriptionMock(props: Partial<Types.ExplanationDocRevisionDescription> = {}): Types.ExplanationDocRevisionDescription {
  return {
    __typename: "ExplanationDocRevisionDescription",
    browsingHistories: [],
    documentType: "AgreementForm",
    explanationDocSetUid: generateUUID(),
    icfDocumentRevision: createIcfDocumentRevisionAgreementFormMock({}),
    icfDocumentRevisionUid: generateUUID(),
    index: 0,
    isCompleted: false,
    latestPatientSideBrowsingHistory: null,
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionFileMock(props: Partial<Types.ExplanationDocRevisionFile> = {}): Types.ExplanationDocRevisionFile {
  return {
    __typename: "ExplanationDocRevisionFile",
    fileUrl: null,
    hasFile: false,
    ...props,
  };
}

export function createExplanationDocRevisionSignHistoryMock(props: Partial<Types.ExplanationDocRevisionSignHistory> = {}): Types.ExplanationDocRevisionSignHistory {
  return {
    __typename: "ExplanationDocRevisionSignHistory",
    actorUid: generateUUID(),
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: null,
    isPartner: false,
    isRejected: false,
    latestCreateEnvelopeViewUrlHistory: null,
    operatedAt: dayjs().format(),
    rejectedReason: null,
    signerRole: "CRC",
    trialHospital: null,
    trialHospitalUid: null,
    trialMember: null,
    trialMemberUid: null,
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionSignerMemberMock(props: Partial<Types.ExplanationDocRevisionSignerMember> = {}): Types.ExplanationDocRevisionSignerMember {
  return {
    __typename: "ExplanationDocRevisionSignerMember",
    explanationDocRevisionUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocRevisionVideoMock(props: Partial<Types.ExplanationDocRevisionVideo> = {}): Types.ExplanationDocRevisionVideo {
  return {
    __typename: "ExplanationDocRevisionVideo",
    browsingHistories: [],
    documentType: "AgreementForm",
    explanationDocSetUid: generateUUID(),
    icfDocumentRevision: createIcfDocumentRevisionAgreementFormMock({}),
    icfDocumentRevisionUid: generateUUID(),
    index: 0,
    isCompleted: false,
    latestPatientSideBrowsingHistory: null,
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationDocSetMock(props: Partial<Types.ExplanationDocSet> = {}): Types.ExplanationDocSet {
  return {
    __typename: "ExplanationDocSet",
    currentDocuments: [],
    currentName: "",
    documentRevisions: null,
    icfDocumentSet: createIcfDocumentSetMock({}),
    icfDocumentSetUid: generateUUID(),
    index: 0,
    lastCheckedAt: dayjs().format(),
    name: "",
    uid: generateUUID(),
    updatedAfterChecked: false,
    ...props,
  };
}

export function createExplanationInPersonSessionMock(props: Partial<Types.ExplanationInPersonSession> = {}): Types.ExplanationInPersonSession {
  return {
    __typename: "ExplanationInPersonSession",
    explanationRevision: createExplanationRevisionMock({}),
    explanationRevisionUid: generateUUID(),
    explanationType: "InPerson",
    finishedAt: null,
    isFinished: false,
    members: [],
    startedAt: dayjs().format(),
    uid: generateUUID(),
    visit: null,
    visitUid: null,
    withRepresentative: false,
    ...props,
  };
}

export function createExplanationPatientMock(props: Partial<Types.ExplanationPatient> = {}): Types.ExplanationPatient {
  return {
    __typename: "ExplanationPatient",
    candidateId: "",
    email: null,
    expDiseaseId: "",
    mobileNumber: null,
    patientMasterUid: generateUUID(),
    referredPatientUid: null,
    trialHospitalUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationPreSessionMock(props: Partial<Types.ExplanationPreSession> = {}): Types.ExplanationPreSession {
  return {
    __typename: "ExplanationPreSession",
    expiredAt: null,
    explanationRevision: createExplanationRevisionMock({}),
    explanationRevisionUid: generateUUID(),
    explanationSession: null,
    explanationSessionUid: null,
    finishedAt: null,
    identifiedAt: null,
    identifierTrialMember: null,
    identifierTrialMemberUid: null,
    isFinished: false,
    isIdentified: false,
    latestRoomEvents: [],
    uid: generateUUID(),
    videoCallRoom: createVideoCallRoomMock({}),
    videoCallRoomUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRemotePartnerSessionMock(props: Partial<Types.ExplanationRemotePartnerSession> = {}): Types.ExplanationRemotePartnerSession {
  return {
    __typename: "ExplanationRemotePartnerSession",
    explanationPreSession: createExplanationPreSessionMock({}),
    explanationRevision: createExplanationRevisionMock({}),
    explanationRevisionUid: generateUUID(),
    explanationType: "InPerson",
    finishedAt: null,
    isFinished: false,
    latestRoomEvents: [],
    members: [],
    partnerTrialHospital: createTrialHospitalMock({}),
    partnerTrialHospitalUid: generateUUID(),
    startedAt: dayjs().format(),
    uid: generateUUID(),
    videoCallRoom: createVideoCallRoomMock({}),
    videoCallRoomUid: generateUUID(),
    visit: null,
    visitUid: null,
    withRepresentative: false,
    ...props,
  };
}

export function createExplanationRemoteSessionMock(props: Partial<Types.ExplanationRemoteSession> = {}): Types.ExplanationRemoteSession {
  return {
    __typename: "ExplanationRemoteSession",
    explanationPreSession: createExplanationPreSessionMock({}),
    explanationRevision: createExplanationRevisionMock({}),
    explanationRevisionUid: generateUUID(),
    explanationType: "InPerson",
    finishedAt: null,
    isFinished: false,
    latestRoomEvents: [],
    members: [],
    startedAt: dayjs().format(),
    uid: generateUUID(),
    videoCallRoom: createVideoCallRoomMock({}),
    videoCallRoomUid: generateUUID(),
    visit: null,
    visitUid: null,
    withRepresentative: false,
    ...props,
  };
}

export function createExplanationReservationMock(props: Partial<Types.ExplanationReservation> = {}): Types.ExplanationReservation {
  return {
    __typename: "ExplanationReservation",
    explanationReservationUid: generateUUID(),
    explanationRevisionUid: generateUUID(),
    reservation: createReservationMock({}),
    reservationInviteeType: "PartnerMembers",
    reservationType: "AfterConsent",
    reservationUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionMock(props: Partial<Types.ExplanationRevision> = {}): Types.ExplanationRevision {
  return {
    __typename: "ExplanationRevision",
    changeDeliveryDateHistories: [],
    createdAt: dayjs().format(),
    deliveryHistories: [],
    docSets: [],
    explanation: createExplanationMock({}),
    explanationSessions: [],
    explanationType: "InPerson",
    explanationUid: generateUUID(),
    histories: [],
    latestChangeDeliveryDateHistory: null,
    latestExplanationSession: null,
    latestHistory: createExplanationRevisionHistoryMock({}),
    latestPinSettingHistory: null,
    latestScheduleHistory: null,
    members: [],
    partnerTrialHospital: null,
    partnerTrialHospitalUid: null,
    scheduleHistories: [],
    uid: generateUUID(),
    withRepresentative: false,
    withdrawalHistories: [],
    ...props,
  };
}

export function createExplanationRevisionChangeDeliveryDateHistoryMock(props: Partial<Types.ExplanationRevisionChangeDeliveryDateHistory> = {}): Types.ExplanationRevisionChangeDeliveryDateHistory {
  return {
    __typename: "ExplanationRevisionChangeDeliveryDateHistory",
    createdAt: dayjs().format(),
    explanationRevisionUid: generateUUID(),
    isPartner: false,
    reason: null,
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    uid: generateUUID(),
    updatedDeliveryDate: "",
    ...props,
  };
}

export function createExplanationRevisionDeliveryHistoryMock(props: Partial<Types.ExplanationRevisionDeliveryHistory> = {}): Types.ExplanationRevisionDeliveryHistory {
  return {
    __typename: "ExplanationRevisionDeliveryHistory",
    createdAt: dayjs().format(),
    deliveredDocuments: [],
    deliveryType: "Download",
    explanationRevisionUid: generateUUID(),
    isPartner: false,
    recipientPatientEmail: null,
    recipientTrialMember: null,
    recipientTrialMemberEmail: null,
    recipientTrialMemberUid: null,
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionDeliveryHistoryDocumentMock(props: Partial<Types.ExplanationRevisionDeliveryHistoryDocument> = {}): Types.ExplanationRevisionDeliveryHistoryDocument {
  return {
    __typename: "ExplanationRevisionDeliveryHistoryDocument",
    explanationDocRevision: createExplanationDocRevisionAgreementFormMock({}),
    explanationDocRevisionUid: generateUUID(),
    explanationRevisionDeliveryHistoryUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionHistoryMock(props: Partial<Types.ExplanationRevisionHistory> = {}): Types.ExplanationRevisionHistory {
  return {
    __typename: "ExplanationRevisionHistory",
    comment: null,
    createdAt: dayjs().format(),
    explanationRevisionUid: generateUUID(),
    status: "AgreementDone",
    statusV2: "AgreementCompleted",
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionMemberMock(props: Partial<Types.ExplanationRevisionMember> = {}): Types.ExplanationRevisionMember {
  return {
    __typename: "ExplanationRevisionMember",
    explanationRevisionUid: generateUUID(),
    index: 0,
    isPartner: false,
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionPinSettingHistoryMock(props: Partial<Types.ExplanationRevisionPinSettingHistory> = {}): Types.ExplanationRevisionPinSettingHistory {
  return {
    __typename: "ExplanationRevisionPinSettingHistory",
    explanationRevision: createExplanationRevisionMock({}),
    explanationRevisionUid: generateUUID(),
    method: "OnScreen",
    savedAt: dayjs().format(),
    status: "Completed",
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionScheduleHistoryMock(props: Partial<Types.ExplanationRevisionScheduleHistory> = {}): Types.ExplanationRevisionScheduleHistory {
  return {
    __typename: "ExplanationRevisionScheduleHistory",
    createdAt: dayjs().format(),
    explanationRevisionUid: generateUUID(),
    isPartner: false,
    scheduledAt: dayjs().format(),
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createExplanationRevisionWithdrawalHistoryMock(props: Partial<Types.ExplanationRevisionWithdrawalHistory> = {}): Types.ExplanationRevisionWithdrawalHistory {
  return {
    __typename: "ExplanationRevisionWithdrawalHistory",
    comment: "",
    createdAt: dayjs().format(),
    explanationRevisionUid: generateUUID(),
    fileName: null,
    fileURL: "",
    fileUid: null,
    isPartner: false,
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberRole: "CRA",
    trialMemberUid: generateUUID(),
    uid: generateUUID(),
    withdrawnDate: "",
    ...props,
  };
}

export function createExplanationRoomActiveDeviceEventMessageMock(props: Partial<Types.ExplanationRoomActiveDeviceEventMessage> = {}): Types.ExplanationRoomActiveDeviceEventMessage {
  return {
    __typename: "ExplanationRoomActiveDeviceEventMessage",
    deleted: null,
    deviceActorTrialHospitalUid: generateUUID(),
    deviceActorUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRoomCurrentLocationEventMessageMock(props: Partial<Types.ExplanationRoomCurrentLocationEventMessage> = {}): Types.ExplanationRoomCurrentLocationEventMessage {
  return {
    __typename: "ExplanationRoomCurrentLocationEventMessage",
    deleted: null,
    page: null,
    path: null,
    ...props,
  };
}

export function createExplanationRoomEventMock(props: Partial<Types.ExplanationRoomEvent> = {}): Types.ExplanationRoomEvent {
  return {
    __typename: "ExplanationRoomEvent",
    actor: createExplanationRoomEventActorMemberMock({}),
    eventMessage: createExplanationRoomActiveDeviceEventMessageMock({}),
    eventType: "ActiveDevice",
    explanationPreSessionUid: null,
    explanationRoomEventUid: generateUUID(),
    explanationSessionUid: null,
    ...props,
  };
}

export function createExplanationRoomEventActorMock(props: Partial<Types.ExplanationRoomEventActor> = {}): Types.ExplanationRoomEventActor {
  switch(props.__typename) {
    case "ExplanationRoomEventActorMember":
      return createExplanationRoomEventActorMemberMock(props);
    case "ExplanationRoomEventActorPatient":
      return createExplanationRoomEventActorPatientMock(props);
    case "ExplanationRoomEventActorSystem":
      return createExplanationRoomEventActorSystemMock(props);
    case undefined:
    default:
      return createExplanationRoomEventActorMock({ __typename: "ExplanationRoomEventActorMember", ...props });
  }
}

export function createExplanationRoomEventActorMemberMock(props: Partial<Types.ExplanationRoomEventActorMember> = {}): Types.ExplanationRoomEventActorMember {
  return {
    __typename: "ExplanationRoomEventActorMember",
    actorUid: generateUUID(),
    displayName: "",
    trialHospitalUid: generateUUID(),
    trialMemberUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRoomEventActorPatientMock(props: Partial<Types.ExplanationRoomEventActorPatient> = {}): Types.ExplanationRoomEventActorPatient {
  return {
    __typename: "ExplanationRoomEventActorPatient",
    actorUid: generateUUID(),
    displayName: "",
    explanationPatientUid: generateUUID(),
    trialHospitalUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRoomEventActorSystemMock(props: Partial<Types.ExplanationRoomEventActorSystem> = {}): Types.ExplanationRoomEventActorSystem {
  return {
    __typename: "ExplanationRoomEventActorSystem",
    actorUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRoomEventErrorMock(props: Partial<Types.ExplanationRoomEventError> = {}): Types.ExplanationRoomEventError {
  return {
    __typename: "ExplanationRoomEventError",
    message: "",
    ...props,
  };
}

export function createExplanationRoomEventInfoMock(props: Partial<Types.ExplanationRoomEventInfo> = {}): Types.ExplanationRoomEventInfo {
  return {
    __typename: "ExplanationRoomEventInfo",
    message: "",
    ...props,
  };
}

export function createExplanationRoomEventMessageMock(props: Partial<Types.ExplanationRoomEventMessage> = {}): Types.ExplanationRoomEventMessage {
  switch(props.__typename) {
    case "ExplanationRoomActiveDeviceEventMessage":
      return createExplanationRoomActiveDeviceEventMessageMock(props);
    case "ExplanationRoomCurrentLocationEventMessage":
      return createExplanationRoomCurrentLocationEventMessageMock(props);
    case "ExplanationRoomPointLocationEventMessage":
      return createExplanationRoomPointLocationEventMessageMock(props);
    case "ExplanationRoomRequestPinSettingEventMessage":
      return createExplanationRoomRequestPinSettingEventMessageMock(props);
    case "ExplanationRoomRequestSignEventMessage":
      return createExplanationRoomRequestSignEventMessageMock(props);
    case "ExplanationRoomUpdateRevisionStatusEventMessage":
      return createExplanationRoomUpdateRevisionStatusEventMessageMock(props);
    case undefined:
    default:
      return createExplanationRoomEventMessageMock({ __typename: "ExplanationRoomActiveDeviceEventMessage", ...props });
  }
}

export function createExplanationRoomEventPayloadMock(props: Partial<Types.ExplanationRoomEventPayload> = {}): Types.ExplanationRoomEventPayload {
  switch(props.__typename) {
    case "ExplanationRoomEvent":
      return createExplanationRoomEventMock(props);
    case "ExplanationRoomEventError":
      return createExplanationRoomEventErrorMock(props);
    case "ExplanationRoomEventInfo":
      return createExplanationRoomEventInfoMock(props);
    case undefined:
    default:
      return createExplanationRoomEventPayloadMock({ __typename: "ExplanationRoomEvent", ...props });
  }
}

export function createExplanationRoomEventsInputMock(props: Partial<Types.ExplanationRoomEventsInput> = {}): Types.ExplanationRoomEventsInput {
  return {
    explanationRoomUid: generateUUID(),
    roomType: "PreSession",
    ...props,
  };
}

export function createExplanationRoomPointLocationEventMessageMock(props: Partial<Types.ExplanationRoomPointLocationEventMessage> = {}): Types.ExplanationRoomPointLocationEventMessage {
  return {
    __typename: "ExplanationRoomPointLocationEventMessage",
    page: null,
    path: "",
    ...props,
  };
}

export function createExplanationRoomRequestPinSettingEventMessageMock(props: Partial<Types.ExplanationRoomRequestPinSettingEventMessage> = {}): Types.ExplanationRoomRequestPinSettingEventMessage {
  return {
    __typename: "ExplanationRoomRequestPinSettingEventMessage",
    deviceActorTrialHospitalUid: generateUUID(),
    deviceActorUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRoomRequestSignEventMessageMock(props: Partial<Types.ExplanationRoomRequestSignEventMessage> = {}): Types.ExplanationRoomRequestSignEventMessage {
  return {
    __typename: "ExplanationRoomRequestSignEventMessage",
    deviceActorUid: generateUUID(),
    explanationDocRevisionUids: [],
    signType: "OnScreen",
    signerActorDisplayName: "",
    signerActorUid: generateUUID(),
    ...props,
  };
}

export function createExplanationRoomUpdateRevisionStatusEventMessageMock(props: Partial<Types.ExplanationRoomUpdateRevisionStatusEventMessage> = {}): Types.ExplanationRoomUpdateRevisionStatusEventMessage {
  return {
    __typename: "ExplanationRoomUpdateRevisionStatusEventMessage",
    explanationRevisionHistoryUid: generateUUID(),
    explanationRevisionUid: generateUUID(),
    status: "AgreementCompleted",
    ...props,
  };
}

export function createExplanationSessionMock(props: Partial<Types.ExplanationSession> = {}): Types.ExplanationSession {
  switch(props.__typename) {
    case "ExplanationInPersonSession":
      return createExplanationInPersonSessionMock(props);
    case "ExplanationRemotePartnerSession":
      return createExplanationRemotePartnerSessionMock(props);
    case "ExplanationRemoteSession":
      return createExplanationRemoteSessionMock(props);
    case undefined:
    default:
      return createExplanationSessionMock({ __typename: "ExplanationInPersonSession", ...props });
  }
}

export function createExplanationSessionMemberMock(props: Partial<Types.ExplanationSessionMember> = {}): Types.ExplanationSessionMember {
  return {
    __typename: "ExplanationSessionMember",
    explanationSessionUid: generateUUID(),
    index: 0,
    isPartner: false,
    isSetMember: false,
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    ...props,
  };
}

export function createExplanationSessionParticipantTrialMemberInputMock(props: Partial<Types.ExplanationSessionParticipantTrialMemberInput> = {}): Types.ExplanationSessionParticipantTrialMemberInput {
  return {
    trialHospitalUid: generateUUID(),
    trialMemberUid: generateUUID(),
    ...props,
  };
}

export function createFieldMock(props: Partial<Types.Field> = {}): Types.Field {
  return {
    __typename: "Field",
    fid: generateUUID(),
    fieldIndex: 0,
    fieldType: "Checkbox",
    fieldValues: [],
    fileDownloadLogs: [],
    stickyUid: null,
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createFieldInputMock(props: Partial<Types.FieldInput> = {}): Types.FieldInput {
  return {
    fid: generateUUID(),
    fieldIndex: 0,
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createFieldValueMock(props: Partial<Types.FieldValue> = {}): Types.FieldValue {
  switch(props.__typename) {
    case "CheckboxFieldValue":
      return createCheckboxFieldValueMock(props);
    case "ClearedFieldValue":
      return createClearedFieldValueMock(props);
    case "DateFieldValue":
      return createDateFieldValueMock(props);
    case "FileFieldValue":
      return createFileFieldValueMock(props);
    case "NullableDateFieldValue":
      return createNullableDateFieldValueMock(props);
    case "NumberFieldValue":
      return createNumberFieldValueMock(props);
    case "RadioFieldValue":
      return createRadioFieldValueMock(props);
    case "RepeatableSectionFieldValue":
      return createRepeatableSectionFieldValueMock(props);
    case "SelectMenuFieldValue":
      return createSelectMenuFieldValueMock(props);
    case "TextFieldValue":
      return createTextFieldValueMock(props);
    case "TextareaFieldValue":
      return createTextareaFieldValueMock(props);
    case "TimeFieldValue":
      return createTimeFieldValueMock(props);
    case undefined:
    default:
      return createFieldValueMock({ __typename: "CheckboxFieldValue", ...props });
  }
}

export function createFieldValueInputMock(props: Partial<Types.FieldValueInput> = {}): Types.FieldValueInput {
  return {
    checkboxValue: null,
    dateValue: null,
    fid: generateUUID(),
    fieldIndex: 0,
    fieldType: null,
    fileValue: null,
    isCleared: false,
    nullableDateValue: null,
    numberValue: null,
    radioValue: null,
    reason: "",
    repeatableSectionIsEnabled: null,
    repeatableSectionIsNew: null,
    selectMenuValue: null,
    textValue: null,
    textareaValue: null,
    timeValue: null,
    worksheetFieldValueUid: generateUUID(),
    ...props,
  };
}

export function createFileDownloadLogMock(props: Partial<Types.FileDownloadLog> = {}): Types.FileDownloadLog {
  return {
    __typename: "FileDownloadLog",
    downloadedAt: dayjs().format(),
    fileDownloadLogUid: generateUUID(),
    fileFieldValue: createFileFieldValueMock({}),
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    worksheetFieldValueUid: generateUUID(),
    ...props,
  };
}

export function createFileFieldValueMock(props: Partial<Types.FileFieldValue> = {}): Types.FileFieldValue {
  return {
    __typename: "FileFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    files: [],
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createFileValueInputMock(props: Partial<Types.FileValueInput> = {}): Types.FileValueInput {
  return {
    memo: "",
    order: 0,
    uploadFileUid: generateUUID(),
    ...props,
  };
}

export function createFinishExpSessionInputMock(props: Partial<Types.FinishExpSessionInput> = {}): Types.FinishExpSessionInput {
  return {
    explanationSessionUid: generateUUID(),
    ...props,
  };
}

export function createFinishExpSessionPayloadMock(props: Partial<Types.FinishExpSessionPayload> = {}): Types.FinishExpSessionPayload {
  return {
    __typename: "FinishExpSessionPayload",
    explanationSessionUid: generateUUID(),
    ...props,
  };
}

export function createFollowUpWorksheetInputMock(props: Partial<Types.FollowUpWorksheetInput> = {}): Types.FollowUpWorksheetInput {
  return {
    index: 0,
    name: "",
    worksheetSchemaUid: generateUUID(),
    ...props,
  };
}

export function createGeneratePatientVideoCallJwtTokenInputMock(props: Partial<Types.GeneratePatientVideoCallJwtTokenInput> = {}): Types.GeneratePatientVideoCallJwtTokenInput {
  return {
    patientVideoCallUid: generateUUID(),
    ...props,
  };
}

export function createGeneratePatientVideoCallJwtTokenPayloadMock(props: Partial<Types.GeneratePatientVideoCallJwtTokenPayload> = {}): Types.GeneratePatientVideoCallJwtTokenPayload {
  return {
    __typename: "GeneratePatientVideoCallJwtTokenPayload",
    jwtToken: "",
    participantUid: generateUUID(),
    patientVideoCallUid: generateUUID(),
    ...props,
  };
}

export function createHospitalMock(props: Partial<Types.Hospital> = {}): Types.Hospital {
  return {
    __typename: "Hospital",
    name: "",
    uid: generateUUID(),
    ...props,
  };
}

export function createIcfDocumentMock(props: Partial<Types.IcfDocument> = {}): Types.IcfDocument {
  return {
    __typename: "IcfDocument",
    isRequiredReAgreementConfirmation: false,
    numberingId: 0,
    trialHospitalUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createIcfDocumentRevisionMock(props: Partial<Types.IcfDocumentRevision> = {}): Types.IcfDocumentRevision {
  switch(props.__typename) {
    case "IcfDocumentRevisionAgreementForm":
      return createIcfDocumentRevisionAgreementFormMock(props);
    case "IcfDocumentRevisionCheckUnderstanding":
      return createIcfDocumentRevisionCheckUnderstandingMock(props);
    case "IcfDocumentRevisionDescription":
      return createIcfDocumentRevisionDescriptionMock(props);
    case "IcfDocumentRevisionVideo":
      return createIcfDocumentRevisionVideoMock(props);
    case undefined:
    default:
      return createIcfDocumentRevisionMock({ __typename: "IcfDocumentRevisionAgreementForm", ...props });
  }
}

export function createIcfDocumentRevisionAgreementFormMock(props: Partial<Types.IcfDocumentRevisionAgreementForm> = {}): Types.IcfDocumentRevisionAgreementForm {
  return {
    __typename: "IcfDocumentRevisionAgreementForm",
    docusignTemplateId: null,
    docusignTemplateModifedAt: null,
    fileName: "",
    fileURL: "",
    hasCrcField: false,
    hasDrField: false,
    hasPatientField: false,
    hasRepresentativeField: false,
    icfDocument: createIcfDocumentMock({}),
    icfDocumentType: "AgreementForm",
    icfDocumentUid: generateUUID(),
    isConsentRequired: false,
    latestHistory: createIcfDocumentRevisionHistoryMock({}),
    name: "",
    uid: generateUUID(),
    version: "",
    ...props,
  };
}

export function createIcfDocumentRevisionCheckUnderstandingMock(props: Partial<Types.IcfDocumentRevisionCheckUnderstanding> = {}): Types.IcfDocumentRevisionCheckUnderstanding {
  return {
    __typename: "IcfDocumentRevisionCheckUnderstanding",
    docusignTemplateId: null,
    docusignTemplateModifedAt: null,
    fileName: "",
    fileURL: "",
    hasPatientField: false,
    hasRepresentativeField: false,
    icfDocument: createIcfDocumentMock({}),
    icfDocumentType: "AgreementForm",
    icfDocumentUid: generateUUID(),
    latestHistory: createIcfDocumentRevisionHistoryMock({}),
    name: "",
    uid: generateUUID(),
    version: "",
    ...props,
  };
}

export function createIcfDocumentRevisionDescriptionMock(props: Partial<Types.IcfDocumentRevisionDescription> = {}): Types.IcfDocumentRevisionDescription {
  return {
    __typename: "IcfDocumentRevisionDescription",
    fileName: "",
    fileURL: "",
    icfDocument: createIcfDocumentMock({}),
    icfDocumentType: "AgreementForm",
    icfDocumentUid: generateUUID(),
    latestHistory: createIcfDocumentRevisionHistoryMock({}),
    name: "",
    uid: generateUUID(),
    version: "",
    ...props,
  };
}

export function createIcfDocumentRevisionHistoryMock(props: Partial<Types.IcfDocumentRevisionHistory> = {}): Types.IcfDocumentRevisionHistory {
  return {
    __typename: "IcfDocumentRevisionHistory",
    comment: "",
    createdAt: dayjs().format(),
    icfDocumentRevisionUid: generateUUID(),
    status: "Approved",
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createIcfDocumentRevisionVideoMock(props: Partial<Types.IcfDocumentRevisionVideo> = {}): Types.IcfDocumentRevisionVideo {
  return {
    __typename: "IcfDocumentRevisionVideo",
    fileName: "",
    icfDocument: createIcfDocumentMock({}),
    icfDocumentType: "AgreementForm",
    icfDocumentUid: generateUUID(),
    latestHistory: createIcfDocumentRevisionHistoryMock({}),
    name: "",
    uid: generateUUID(),
    version: "",
    videoURL: null,
    ...props,
  };
}

export function createIcfDocumentSetMock(props: Partial<Types.IcfDocumentSet> = {}): Types.IcfDocumentSet {
  return {
    __typename: "IcfDocumentSet",
    icfDocuments: [],
    name: "",
    numberingId: 0,
    status: "Disabled",
    uid: generateUUID(),
    ...props,
  };
}

export function createLatestExplanationRevisionsTrialMembersInputMock(props: Partial<Types.LatestExplanationRevisionsTrialMembersInput> = {}): Types.LatestExplanationRevisionsTrialMembersInput {
  return {
    Role: "CRC",
    trialHospitalUid: null,
    ...props,
  };
}

export function createMutationMock(props: Partial<Types.Mutation> = {}): Types.Mutation {
  return {
    __typename: "Mutation",
    acceptReferredPatient: createAcceptReferredPatientPayloadMock({}),
    addExpRoomEvent: createAddExpRoomEventPayloadMock({}),
    browseExpDocRevision: createBrowseExpDocRevisionPayloadMock({}),
    browseReferredPatient: createBrowseReferredPatientPayloadMock({}),
    browseSticky: createBrowseStickyPayloadMock({}),
    cancelReferredPatient: createCancelReferredPatientPayloadMock({}),
    cancelReservationForExplanationRevision: createCancelReservationForExplanationRevisionPayloadMock({}),
    cancelWorksheetExportCSVJob: createCancelWorksheetExportCsvJobPayloadMock({}),
    completeAgreement: createCompleteAgreementPayloadMock({}),
    confirmWorksheet: createConfirmWorksheetPayloadMock({}),
    createAgreementFormEnvelope: createCreateAgreementFormEnvelopePayloadMock({}),
    createAgreementFormEnvelopes: createCreateAgreementFormEnvelopesPayloadMock({}),
    createCheckUnderstandingEnvelope: createCreateCheckUnderstandingEnvelopePayloadMock({}),
    createCriteria: createCreateCriteriaPayloadMock({}),
    createEnvelopeViewUrl: createCreateEnvelopeViewUrlPayloadMock({}),
    createExpSession: createCreateExpSessionPayloadMock({}),
    createFollowUpWorksheets: createCreateFollowUpWorksheetsPayloadMock({}),
    createOrGetExpPreSession: createCreateOrGetExpPreSessionPayloadMock({}),
    createPatient: createCreatePatientPayloadMock({}),
    createPatientVideoCall: createCreatePatientVideoCallPayloadMock({}),
    createReferredPatient: createCreateReferredPatientPayloadMock({}),
    createReservationForExplanationRevision: createCreateReservationForExplanationRevisionPayloadMock({}),
    createSignAuthentication: createCreateSignAuthenticationPayloadMock({}),
    createVideoCallRoomForAfterConsent: createCreateVideoCallRoomForAfterConsentPayloadMock({}),
    createWorksheetFieldSticky: createCreateWorksheetFieldStickyPayloadMock({}),
    createWorksheets: createCreateWorksheetsPayloadMock({}),
    deleteCriteria: createDeleteCriteriaPayloadMock({}),
    deleteReferredPatient: createDeleteReferredPatientPayloadMock({}),
    deleteSignSession: createDeleteSignSessionPayloadMock({}),
    deleteSticky: createDeleteStickyPayloadMock({}),
    deleteStickyMessage: createDeleteStickyMessagePayloadMock({}),
    deliverDocuments: createDeliverDocumentsPayloadMock({}),
    disableCriteria: createDisableCriteriaPayloadMock({}),
    disablePatient: createDisablePatientPayloadMock({}),
    disableWorksheet: createDisableWorksheetPayloadMock({}),
    downloadReferredPatientCriteriaFieldFile: createDownloadReferredPatientCriteriaFieldFilePayloadMock({}),
    downloadWorksheetFieldFile: createDownloadWorksheetFieldFilePayloadMock({}),
    editStickyMessage: createEditStickyMessagePayloadMock({}),
    enableCriteria: createEnableCriteriaPayloadMock({}),
    enablePatient: createEnablePatientPayloadMock({}),
    enableWorksheet: createEnableWorksheetPayloadMock({}),
    excludeReferredPatient: createExcludeReferredPatientPayloadMock({}),
    finishExpSession: createFinishExpSessionPayloadMock({}),
    generatePatientVideoCallJwtToken: createGeneratePatientVideoCallJwtTokenPayloadMock({}),
    publishCriteria: createPublishCriteriaPayloadMock({}),
    reEditReferredPatientCriteria: createReEditReferredPatientCriteriaPayloadMock({}),
    reEditWorksheet: createReEditWorksheetPayloadMock({}),
    readNotification: createReadNotificationPayloadMock({}),
    referReferredPatient: createReferReferredPatientPayloadMock({}),
    registerWorksheetExportCSVJob: createRegisterWorksheetExportCsvJobPayloadMock({}),
    replySticky: createReplyStickyPayloadMock({}),
    requestExpRevisionPinSetUp: createRequestRevisionPinSetUpPayloadMock({}),
    resendPatientVideoCallNotification: createResendPatientVideoCallNotificationPayloadMock({}),
    saveCriteria: createSaveCriteriaPayloadMock({}),
    saveReferredPatient: createSaveReferredPatientPayloadMock({}),
    saveReferredPatientCriteria: createSaveReferredPatientCriteriaPayloadMock({}),
    saveWorksheet: createSaveWorksheetPayloadMock({}),
    sendAgreementFormEnvelopeEmail: createSendAgreementFormEnvelopeEmailPayloadMock({}),
    sendCheckUnderstandingEnvelopeEmail: createSendCheckUnderstandingEnvelopeEmailPayloadMock({}),
    setExpDocRevisionSignerMember: createSetExpDocRevisionSignerMemberPayloadMock({}),
    setExpRevisionPin: createSetExpRevisionPinPayloadMock({}),
    syncAllEnvelopes: createSyncAllEnvelopesPayloadMock({}),
    syncEnvelope: createSyncEnvelopePayloadMock({}),
    updateExpMembers: createUpdateExpSessionMembersPayloadMock({}),
    updateExpPatientMobileNumber: createUpdateExpPatientMobileNumberPayloadMock({}),
    updateExpPreSessionIdentification: createUpdateExpPreSessionIdentificationPayloadMock({}),
    updateExpRevisionStatus: createUpdateExpRevisionStatusPayloadMock({}),
    updatePatient: createUpdatePatientPayloadMock({}),
    updateReservationForExplanationRevision: createUpdateReservationForExplanationRevisionPayloadMock({}),
    updateWorksheetName: createUpdateWorksheetNamePayloadMock({}),
    uploadFiles: createUploadFilesPayloadMock({}),
    ...props,
  };
}

export function createNotificationMock(props: Partial<Types.Notification> = {}): Types.Notification {
  return {
    __typename: "Notification",
    group: "",
    hasBeenRead: false,
    link: "",
    linkText: "",
    message: "",
    notificationId: "",
    priority: "High",
    savedAt: dayjs().format(),
    ...props,
  };
}

export function createNotificationByGroupKeyConnectionMock(props: Partial<Types.NotificationByGroupKeyConnection> = {}): Types.NotificationByGroupKeyConnection {
  return {
    __typename: "NotificationByGroupKeyConnection",
    edges: [],
    ...props,
  };
}

export function createNotificationByGroupKeyEdgeMock(props: Partial<Types.NotificationByGroupKeyEdge> = {}): Types.NotificationByGroupKeyEdge {
  return {
    __typename: "NotificationByGroupKeyEdge",
    cursor: "",
    node: createNotificationMock({}),
    ...props,
  };
}

export function createNotificationConnectionMock(props: Partial<Types.NotificationConnection> = {}): Types.NotificationConnection {
  return {
    __typename: "NotificationConnection",
    edges: [],
    pageInfo: createPageInfoMock({}),
    unreadCount: 0,
    ...props,
  };
}

export function createNotificationEdgeMock(props: Partial<Types.NotificationEdge> = {}): Types.NotificationEdge {
  return {
    __typename: "NotificationEdge",
    countGroup: 0,
    cursor: "",
    node: createNotificationMock({}),
    ...props,
  };
}

export function createNotificationsByGroupKeyInputMock(props: Partial<Types.NotificationsByGroupKeyInput> = {}): Types.NotificationsByGroupKeyInput {
  return {
    after: "",
    groupKey: "",
    ...props,
  };
}

export function createNotificationsInputMock(props: Partial<Types.NotificationsInput> = {}): Types.NotificationsInput {
  return {
    after: null,
    limit: 0,
    ...props,
  };
}

export function createNullableDateFieldValueMock(props: Partial<Types.NullableDateFieldValue> = {}): Types.NullableDateFieldValue {
  return {
    __typename: "NullableDateFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    nullableDateValue: "",
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createNumberFieldValueMock(props: Partial<Types.NumberFieldValue> = {}): Types.NumberFieldValue {
  return {
    __typename: "NumberFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    numberValue: "",
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createPageInfoMock(props: Partial<Types.PageInfo> = {}): Types.PageInfo {
  return {
    __typename: "PageInfo",
    endCursor: null,
    hasNextPage: false,
    ...props,
  };
}

export function createPatientMock(props: Partial<Types.Patient> = {}): Types.Patient {
  return {
    __typename: "Patient",
    birthday: null,
    chartUid: "",
    diseaseUid: "",
    email: "",
    firstName: "",
    firstNameJa: "",
    gender: "Unspecified",
    lastName: "",
    lastNameJa: "",
    observationFollowUps: [],
    observationVisits: [],
    partnerTrialHospital: null,
    partnerTrialHospitalUid: null,
    phoneNumber: "",
    status: "Enable",
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    uid: generateUUID(),
    videoCalls: [],
    ...props,
  };
}

export function createPatientObservationFollowUpMock(props: Partial<Types.PatientObservationFollowUp> = {}): Types.PatientObservationFollowUp {
  return {
    __typename: "PatientObservationFollowUp",
    creatableWorksheetSchemas: [],
    name: "",
    patientObservationUid: generateUUID(),
    patientUid: generateUUID(),
    worksheets: [],
    ...props,
  };
}

export function createPatientObservationVisitMock(props: Partial<Types.PatientObservationVisit> = {}): Types.PatientObservationVisit {
  return {
    __typename: "PatientObservationVisit",
    creatableWorksheetSchemas: [],
    name: "",
    patientObservationUid: generateUUID(),
    patientUid: generateUUID(),
    worksheets: [],
    ...props,
  };
}

export function createPatientVideoCallMock(props: Partial<Types.PatientVideoCall> = {}): Types.PatientVideoCall {
  switch(props.__typename) {
    case "PatientVideoCallPartnerTrialHospital":
      return createPatientVideoCallPartnerTrialHospitalMock(props);
    case "PatientVideoCallPatient":
      return createPatientVideoCallPatientMock(props);
    case undefined:
    default:
      return createPatientVideoCallMock({ __typename: "PatientVideoCallPartnerTrialHospital", ...props });
  }
}

export function createPatientVideoCallPartnerTrialHospitalMock(props: Partial<Types.PatientVideoCallPartnerTrialHospital> = {}): Types.PatientVideoCallPartnerTrialHospital {
  return {
    __typename: "PatientVideoCallPartnerTrialHospital",
    patientUid: generateUUID(),
    patientVideoCallType: "PartnerTrialHospital",
    patientVideoCallUid: generateUUID(),
    temporaryUrlToken: null,
    trialHospital: createTrialHospitalMock({}),
    trialHospitalUid: generateUUID(),
    videoCallRoom: createVideoCallRoomMock({}),
    videoCallRoomUid: generateUUID(),
    ...props,
  };
}

export function createPatientVideoCallPatientMock(props: Partial<Types.PatientVideoCallPatient> = {}): Types.PatientVideoCallPatient {
  return {
    __typename: "PatientVideoCallPatient",
    patientUid: generateUUID(),
    patientVideoCallType: "PartnerTrialHospital",
    patientVideoCallUid: generateUUID(),
    temporaryUrlToken: null,
    videoCallRoom: createVideoCallRoomMock({}),
    videoCallRoomUid: generateUUID(),
    ...props,
  };
}

export function createPublishCriteriaInputMock(props: Partial<Types.PublishCriteriaInput> = {}): Types.PublishCriteriaInput {
  return {
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createPublishCriteriaPayloadMock(props: Partial<Types.PublishCriteriaPayload> = {}): Types.PublishCriteriaPayload {
  return {
    __typename: "PublishCriteriaPayload",
    criteriaLogUid: generateUUID(),
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createQueryMock(props: Partial<Types.Query> = {}): Types.Query {
  return {
    __typename: "Query",
    activeTrialMembersByTrialHospitalUids: [],
    criteria: createCriteriaMock({}),
    criteriaList: createCriteriaListPayloadMock({}),
    enabledTrialHospitals: [],
    error400: "",
    error500: "",
    explanation: createExplanationMock({}),
    explanationDocRevision: createExplanationDocRevisionAgreementFormMock({}),
    explanationPreSession: createExplanationPreSessionMock({}),
    explanationRevision: createExplanationRevisionMock({}),
    explanationSession: createExplanationInPersonSessionMock({}),
    field: createFieldMock({}),
    fileFieldValue: createFileFieldValueMock({}),
    icfDocument: createIcfDocumentMock({}),
    latestExplanationRevisionsTrialMembers: [],
    notifications: createNotificationConnectionMock({}),
    notificationsByGroupKey: createNotificationByGroupKeyConnectionMock({}),
    panicError: "",
    panicString: "",
    patient: createPatientMock({}),
    patientVideoCall: createPatientVideoCallPartnerTrialHospitalMock({}),
    referredPatient: createReferredPatientMock({}),
    referredPatientCriteriaFileDownloadLogs: [],
    referredPatientCriteriaValueFile: createReferredPatientCriteriaValueFileMock({}),
    referredPatients: createReferredPatientConnectionMock({}),
    repeatableSectionValues: [],
    searchExplanations: createSearchExplanationsOutputMock({}),
    searchPatients: createSearchPatientsPayloadMock({}),
    searchableTrialHospitalsForPatientSearch: [],
    sticky: createStickyMock({}),
    trial: createTrialMock({}),
    trialHospital: createTrialHospitalMock({}),
    trialMembers: [],
    videoCallRoom: createVideoCallRoomMock({}),
    worksheet: createWorksheetMock({}),
    worksheetCSVExportJobs: [],
    ...props,
  };
}

export function createRadioFieldValueMock(props: Partial<Types.RadioFieldValue> = {}): Types.RadioFieldValue {
  return {
    __typename: "RadioFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    radioValue: generateUUID(),
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createReEditReferredPatientCriteriaInputMock(props: Partial<Types.ReEditReferredPatientCriteriaInput> = {}): Types.ReEditReferredPatientCriteriaInput {
  return {
    referredPatientCriteriaUid: generateUUID(),
    values: [],
    ...props,
  };
}

export function createReEditReferredPatientCriteriaPayloadMock(props: Partial<Types.ReEditReferredPatientCriteriaPayload> = {}): Types.ReEditReferredPatientCriteriaPayload {
  return {
    __typename: "ReEditReferredPatientCriteriaPayload",
    referredPatientCriteriaUid: generateUUID(),
    referredPatientCriteriaValuesUid: generateUUID(),
    ...props,
  };
}

export function createReEditReferredPatientCriteriaValueInputMock(props: Partial<Types.ReEditReferredPatientCriteriaValueInput> = {}): Types.ReEditReferredPatientCriteriaValueInput {
  return {
    fid: generateUUID(),
    fieldIndex: 0,
    value: {},
    ...props,
  };
}

export function createReEditWorksheetInputMock(props: Partial<Types.ReEditWorksheetInput> = {}): Types.ReEditWorksheetInput {
  return {
    reason: "",
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createReEditWorksheetPayloadMock(props: Partial<Types.ReEditWorksheetPayload> = {}): Types.ReEditWorksheetPayload {
  return {
    __typename: "ReEditWorksheetPayload",
    worksheet: createWorksheetMock({}),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createReadNotificationPayloadMock(props: Partial<Types.ReadNotificationPayload> = {}): Types.ReadNotificationPayload {
  return {
    __typename: "ReadNotificationPayload",
    notificationId: "",
    ...props,
  };
}

export function createReferReferredPatientInputMock(props: Partial<Types.ReferReferredPatientInput> = {}): Types.ReferReferredPatientInput {
  return {
    refereeTrialHospitalUid: generateUUID(),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createReferReferredPatientPayloadMock(props: Partial<Types.ReferReferredPatientPayload> = {}): Types.ReferReferredPatientPayload {
  return {
    __typename: "ReferReferredPatientPayload",
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientMock(props: Partial<Types.ReferredPatient> = {}): Types.ReferredPatient {
  return {
    __typename: "ReferredPatient",
    criteriaList: [],
    explanationPatientUid: null,
    latestContact: createReferredPatientContactMock({}),
    latestDetail: createReferredPatientDetailMock({}),
    latestLog: createReferredPatientLogMock({}),
    latestRefer: null,
    latestUpdatedAt: dayjs().format(),
    logs: [],
    numberingId: 0,
    patientMasterUid: generateUUID(),
    patientTrialHospital: createTrialHospitalMock({}),
    patientTrialHospitalUid: generateUUID(),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientConnectionMock(props: Partial<Types.ReferredPatientConnection> = {}): Types.ReferredPatientConnection {
  return {
    __typename: "ReferredPatientConnection",
    edges: [],
    totalCount: 0,
    ...props,
  };
}

export function createReferredPatientContactMock(props: Partial<Types.ReferredPatientContact> = {}): Types.ReferredPatientContact {
  return {
    __typename: "ReferredPatientContact",
    assigneeEmail: null,
    assigneeName: "",
    assigneePhoneNumber: null,
    assigneeRole: null,
    referredPatientContactUid: generateUUID(),
    referredPatientLogUid: generateUUID(),
    referredPatientUid: generateUUID(),
    savedAt: dayjs().format(),
    ...props,
  };
}

export function createReferredPatientCriteriaMock(props: Partial<Types.ReferredPatientCriteria> = {}): Types.ReferredPatientCriteria {
  return {
    __typename: "ReferredPatientCriteria",
    criteria: createCriteriaMock({}),
    criteriaUid: generateUUID(),
    fileDownloadLogs: [],
    latestValues: null,
    referredPatientCriteriaUid: generateUUID(),
    referredPatientLogUid: generateUUID(),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientCriteriaFileDownloadLogsInputMock(props: Partial<Types.ReferredPatientCriteriaFileDownloadLogsInput> = {}): Types.ReferredPatientCriteriaFileDownloadLogsInput {
  return {
    fid: generateUUID(),
    fieldIndex: 0,
    referredPatientCriteriaUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientCriteriaValueMock(props: Partial<Types.ReferredPatientCriteriaValue> = {}): Types.ReferredPatientCriteriaValue {
  return {
    __typename: "ReferredPatientCriteriaValue",
    fid: generateUUID(),
    fieldIndex: 0,
    value: {},
    ...props,
  };
}

export function createReferredPatientCriteriaValueFileMock(props: Partial<Types.ReferredPatientCriteriaValueFile> = {}): Types.ReferredPatientCriteriaValueFile {
  return {
    __typename: "ReferredPatientCriteriaValueFile",
    fid: generateUUID(),
    fieldIndex: 0,
    files: [],
    referredPatientCriteriaValueFileUid: generateUUID(),
    referredPatientCriteriaValueUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientCriteriaValuesMock(props: Partial<Types.ReferredPatientCriteriaValues> = {}): Types.ReferredPatientCriteriaValues {
  return {
    __typename: "ReferredPatientCriteriaValues",
    files: [],
    referredPatientCriteriaUid: generateUUID(),
    referredPatientCriteriaValueUid: generateUUID(),
    savedAt: dayjs().format(),
    values: [],
    ...props,
  };
}

export function createReferredPatientDetailMock(props: Partial<Types.ReferredPatientDetail> = {}): Types.ReferredPatientDetail {
  return {
    __typename: "ReferredPatientDetail",
    age: 0,
    chartId: null,
    gender: "Unspecified",
    name: null,
    referredPatientDetailUid: generateUUID(),
    referredPatientLogUid: generateUUID(),
    referredPatientUid: generateUUID(),
    savedAt: dayjs().format(),
    ...props,
  };
}

export function createReferredPatientEdgeMock(props: Partial<Types.ReferredPatientEdge> = {}): Types.ReferredPatientEdge {
  return {
    __typename: "ReferredPatientEdge",
    node: createReferredPatientMock({}),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientLogMock(props: Partial<Types.ReferredPatientLog> = {}): Types.ReferredPatientLog {
  return {
    __typename: "ReferredPatientLog",
    action: "AcceptByReferee",
    actionTrialMemberUid: generateUUID(),
    refereeStatus: null,
    referredPatientLogUid: generateUUID(),
    referredPatientUid: generateUUID(),
    referrerStatus: "Awaiting",
    savedAt: dayjs().format(),
    ...props,
  };
}

export function createReferredPatientReferMock(props: Partial<Types.ReferredPatientRefer> = {}): Types.ReferredPatientRefer {
  return {
    __typename: "ReferredPatientRefer",
    acceptance: null,
    cancel: null,
    exclude: null,
    log: createReferredPatientLogMock({}),
    refereeTrialHospital: createTrialHospitalMock({}),
    refereeTrialHospitalUid: generateUUID(),
    referredAt: dayjs().format(),
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientReferAcceptanceMock(props: Partial<Types.ReferredPatientReferAcceptance> = {}): Types.ReferredPatientReferAcceptance {
  return {
    __typename: "ReferredPatientReferAcceptance",
    log: createReferredPatientLogMock({}),
    note: null,
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    savedAt: dayjs().format(),
    ...props,
  };
}

export function createReferredPatientReferCancelMock(props: Partial<Types.ReferredPatientReferCancel> = {}): Types.ReferredPatientReferCancel {
  return {
    __typename: "ReferredPatientReferCancel",
    cancelledAt: dayjs().format(),
    log: createReferredPatientLogMock({}),
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientReferExcludeMock(props: Partial<Types.ReferredPatientReferExclude> = {}): Types.ReferredPatientReferExclude {
  return {
    __typename: "ReferredPatientReferExclude",
    excludedAt: dayjs().format(),
    log: createReferredPatientLogMock({}),
    referredPatientLogUid: generateUUID(),
    referredPatientReferUid: generateUUID(),
    ...props,
  };
}

export function createReferredPatientsInputMock(props: Partial<Types.ReferredPatientsInput> = {}): Types.ReferredPatientsInput {
  return {
    mainTrialHospitalUid: null,
    page: 0,
    per: 0,
    ...props,
  };
}

export function createRegisterWorksheetExportCsvJobPayloadMock(props: Partial<Types.RegisterWorksheetExportCsvJobPayload> = {}): Types.RegisterWorksheetExportCsvJobPayload {
  return {
    __typename: "RegisterWorksheetExportCSVJobPayload",
    job: createWorksheetCsvExportJobMock({}),
    jobUid: generateUUID(),
    ...props,
  };
}

export function createRepeatableSectionFieldValueMock(props: Partial<Types.RepeatableSectionFieldValue> = {}): Types.RepeatableSectionFieldValue {
  return {
    __typename: "RepeatableSectionFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    isEnabled: false,
    reason: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createRepeatableSectionValuesInputMock(props: Partial<Types.RepeatableSectionValuesInput> = {}): Types.RepeatableSectionValuesInput {
  return {
    fid: generateUUID(),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createReplyStickyInputMock(props: Partial<Types.ReplyStickyInput> = {}): Types.ReplyStickyInput {
  return {
    message: "",
    priority: "High",
    stickyUid: "",
    ...props,
  };
}

export function createReplyStickyPayloadMock(props: Partial<Types.ReplyStickyPayload> = {}): Types.ReplyStickyPayload {
  return {
    __typename: "ReplyStickyPayload",
    sticky: createStickyMock({}),
    stickyMessageUid: generateUUID(),
    stickyUid: generateUUID(),
    ...props,
  };
}

export function createRequestRevisionPinSetUpInputMock(props: Partial<Types.RequestRevisionPinSetUpInput> = {}): Types.RequestRevisionPinSetUpInput {
  return {
    deviceActorUid: null,
    explanationRevisionUid: generateUUID(),
    method: "OnScreen",
    ...props,
  };
}

export function createRequestRevisionPinSetUpPayloadMock(props: Partial<Types.RequestRevisionPinSetUpPayload> = {}): Types.RequestRevisionPinSetUpPayload {
  return {
    __typename: "RequestRevisionPinSetUpPayload",
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createResendPatientVideoCallNotificationInputMock(props: Partial<Types.ResendPatientVideoCallNotificationInput> = {}): Types.ResendPatientVideoCallNotificationInput {
  return {
    patientVideoCallUid: generateUUID(),
    ...props,
  };
}

export function createResendPatientVideoCallNotificationPayloadMock(props: Partial<Types.ResendPatientVideoCallNotificationPayload> = {}): Types.ResendPatientVideoCallNotificationPayload {
  return {
    __typename: "ResendPatientVideoCallNotificationPayload",
    patientVideoCallUid: generateUUID(),
    ...props,
  };
}

export function createReservationMock(props: Partial<Types.Reservation> = {}): Types.Reservation {
  return {
    __typename: "Reservation",
    latestLog: createReservationLogMock({}),
    reservationUid: generateUUID(),
    reservedAt: dayjs().format(),
    ...props,
  };
}

export function createReservationLogMock(props: Partial<Types.ReservationLog> = {}): Types.ReservationLog {
  return {
    __typename: "ReservationLog",
    actorTrialMember: createTrialMemberMock({}),
    actorTrialMemberUid: generateUUID(),
    reservationLogUid: generateUUID(),
    reservationUid: generateUUID(),
    savedAt: dayjs().format(),
    status: "Cancelled",
    ...props,
  };
}

export function createSaveCriteriaInputMock(props: Partial<Types.SaveCriteriaInput> = {}): Types.SaveCriteriaInput {
  return {
    criteriaUid: generateUUID(),
    schema: "",
    title: "",
    ...props,
  };
}

export function createSaveCriteriaPayloadMock(props: Partial<Types.SaveCriteriaPayload> = {}): Types.SaveCriteriaPayload {
  return {
    __typename: "SaveCriteriaPayload",
    criteriaDetailUid: generateUUID(),
    criteriaLogUid: generateUUID(),
    criteriaSchemaUid: generateUUID(),
    criteriaUid: generateUUID(),
    ...props,
  };
}

export function createSaveReferredPatientCriteriaFileValueFileInputMock(props: Partial<Types.SaveReferredPatientCriteriaFileValueFileInput> = {}): Types.SaveReferredPatientCriteriaFileValueFileInput {
  return {
    memo: "",
    order: 0,
    uploadedFileUid: generateUUID(),
    ...props,
  };
}

export function createSaveReferredPatientCriteriaInputMock(props: Partial<Types.SaveReferredPatientCriteriaInput> = {}): Types.SaveReferredPatientCriteriaInput {
  return {
    referredPatientCriteriaUid: generateUUID(),
    values: [],
    ...props,
  };
}

export function createSaveReferredPatientCriteriaPayloadMock(props: Partial<Types.SaveReferredPatientCriteriaPayload> = {}): Types.SaveReferredPatientCriteriaPayload {
  return {
    __typename: "SaveReferredPatientCriteriaPayload",
    referredPatientCriteriaUid: generateUUID(),
    referredPatientCriteriaValuesUid: generateUUID(),
    ...props,
  };
}

export function createSaveReferredPatientCriteriaValueInputMock(props: Partial<Types.SaveReferredPatientCriteriaValueInput> = {}): Types.SaveReferredPatientCriteriaValueInput {
  return {
    fid: generateUUID(),
    fieldIndex: 0,
    value: {},
    ...props,
  };
}

export function createSaveReferredPatientInputMock(props: Partial<Types.SaveReferredPatientInput> = {}): Types.SaveReferredPatientInput {
  return {
    age: 0,
    assigneeEmail: null,
    assigneeName: "",
    assigneePhoneNumber: null,
    assigneeRole: null,
    chartId: null,
    gender: "Unspecified",
    name: null,
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createSaveReferredPatientPayloadMock(props: Partial<Types.SaveReferredPatientPayload> = {}): Types.SaveReferredPatientPayload {
  return {
    __typename: "SaveReferredPatientPayload",
    referredPatientContactUid: null,
    referredPatientDetailUid: null,
    referredPatientLogUids: [],
    referredPatientUid: generateUUID(),
    ...props,
  };
}

export function createSaveWorksheetInputMock(props: Partial<Types.SaveWorksheetInput> = {}): Types.SaveWorksheetInput {
  return {
    fieldValues: [],
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createSaveWorksheetPayloadMock(props: Partial<Types.SaveWorksheetPayload> = {}): Types.SaveWorksheetPayload {
  return {
    __typename: "SaveWorksheetPayload",
    worksheet: createWorksheetMock({}),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createSearchExplanationsInputMock(props: Partial<Types.SearchExplanationsInput> = {}): Types.SearchExplanationsInput {
  return {
    crcTrialMemberUid: null,
    drTrialMemberUid: null,
    orderKey: null,
    page: 0,
    per: 0,
    reAgreementRequired: [],
    searchQuery: null,
    statuses: null,
    statusesV2: null,
    trialHospitalUid: null,
    ...props,
  };
}

export function createSearchExplanationsOutputMock(props: Partial<Types.SearchExplanationsOutput> = {}): Types.SearchExplanationsOutput {
  return {
    __typename: "SearchExplanationsOutput",
    explanations: [],
    totalCount: 0,
    ...props,
  };
}

export function createSearchPatientsInputMock(props: Partial<Types.SearchPatientsInput> = {}): Types.SearchPatientsInput {
  return {
    page: 0,
    per: 0,
    relatedTrialHospitalType: "Any",
    relatedTrialHospitalUid: null,
    searchQuery: null,
    sortKey: "DiseaseUID",
    sortOrder: "Asc",
    statuses: [],
    trialHospitalUid: null,
    ...props,
  };
}

export function createSearchPatientsPayloadMock(props: Partial<Types.SearchPatientsPayload> = {}): Types.SearchPatientsPayload {
  return {
    __typename: "SearchPatientsPayload",
    patients: [],
    totalCount: 0,
    ...props,
  };
}

export function createSelectMenuFieldValueMock(props: Partial<Types.SelectMenuFieldValue> = {}): Types.SelectMenuFieldValue {
  return {
    __typename: "SelectMenuFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    selectMenuValue: generateUUID(),
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createSendAgreementFormEnvelopeEmailInputMock(props: Partial<Types.SendAgreementFormEnvelopeEmailInput> = {}): Types.SendAgreementFormEnvelopeEmailInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    signerActorUids: null,
    signerRoles: null,
    ...props,
  };
}

export function createSendAgreementFormEnvelopeEmailPayloadMock(props: Partial<Types.SendAgreementFormEnvelopeEmailPayload> = {}): Types.SendAgreementFormEnvelopeEmailPayload {
  return {
    __typename: "SendAgreementFormEnvelopeEmailPayload",
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createSendCheckUnderstandingEnvelopeEmailInputMock(props: Partial<Types.SendCheckUnderstandingEnvelopeEmailInput> = {}): Types.SendCheckUnderstandingEnvelopeEmailInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    signerRoles: [],
    ...props,
  };
}

export function createSendCheckUnderstandingEnvelopeEmailPayloadMock(props: Partial<Types.SendCheckUnderstandingEnvelopeEmailPayload> = {}): Types.SendCheckUnderstandingEnvelopeEmailPayload {
  return {
    __typename: "SendCheckUnderstandingEnvelopeEmailPayload",
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createSetExpDocRevisionSignerMemberInputMock(props: Partial<Types.SetExpDocRevisionSignerMemberInput> = {}): Types.SetExpDocRevisionSignerMemberInput {
  return {
    crcSignerTrialMemberUid: null,
    drSignerTrialMemberUid: generateUUID(),
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createSetExpDocRevisionSignerMemberPayloadMock(props: Partial<Types.SetExpDocRevisionSignerMemberPayload> = {}): Types.SetExpDocRevisionSignerMemberPayload {
  return {
    __typename: "SetExpDocRevisionSignerMemberPayload",
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createSetExpRevisionPinInputMock(props: Partial<Types.SetExpRevisionPinInput> = {}): Types.SetExpRevisionPinInput {
  return {
    explanationRevisionUid: generateUUID(),
    pin: "",
    ...props,
  };
}

export function createSetExpRevisionPinPayloadMock(props: Partial<Types.SetExpRevisionPinPayload> = {}): Types.SetExpRevisionPinPayload {
  return {
    __typename: "SetExpRevisionPinPayload",
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createStickyMock(props: Partial<Types.Sticky> = {}): Types.Sticky {
  return {
    __typename: "Sticky",
    savedAt: dayjs().format(),
    status: "Deleted",
    stickyMessages: [],
    stickyUid: "",
    trialMember: createTrialMemberMock({}),
    trialMemberUid: "",
    ...props,
  };
}

export function createStickyMessageMock(props: Partial<Types.StickyMessage> = {}): Types.StickyMessage {
  return {
    __typename: "StickyMessage",
    hasBeenRead: false,
    isRoot: false,
    message: "",
    priority: "High",
    savedAt: dayjs().format(),
    status: "Deleted",
    stickyMessageUid: "",
    stickyUid: "",
    trialMember: createTrialMemberMock({}),
    trialMemberUid: "",
    ...props,
  };
}

export function createSubscriptionMock(props: Partial<Types.Subscription> = {}): Types.Subscription {
  return {
    __typename: "Subscription",
    explanationRoomEvents: createExplanationRoomEventMock({}),
    ...props,
  };
}

export function createSyncAllEnvelopesInputMock(props: Partial<Types.SyncAllEnvelopesInput> = {}): Types.SyncAllEnvelopesInput {
  return {
    explanationSessionUid: generateUUID(),
    skipUpdateRevStatusEvent: false,
    ...props,
  };
}

export function createSyncAllEnvelopesPayloadMock(props: Partial<Types.SyncAllEnvelopesPayload> = {}): Types.SyncAllEnvelopesPayload {
  return {
    __typename: "SyncAllEnvelopesPayload",
    explanationDocRevisionUids: [],
    explanationSessionUid: generateUUID(),
    ...props,
  };
}

export function createSyncEnvelopeInputMock(props: Partial<Types.SyncEnvelopeInput> = {}): Types.SyncEnvelopeInput {
  return {
    explanationDocRevisionUid: generateUUID(),
    explanationSessionUid: generateUUID(),
    skipUpdateRevStatusEvent: false,
    ...props,
  };
}

export function createSyncEnvelopePayloadMock(props: Partial<Types.SyncEnvelopePayload> = {}): Types.SyncEnvelopePayload {
  return {
    __typename: "SyncEnvelopePayload",
    explanationDocRevisionUid: generateUUID(),
    ...props,
  };
}

export function createTextFieldValueMock(props: Partial<Types.TextFieldValue> = {}): Types.TextFieldValue {
  return {
    __typename: "TextFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    textValue: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createTextareaFieldValueMock(props: Partial<Types.TextareaFieldValue> = {}): Types.TextareaFieldValue {
  return {
    __typename: "TextareaFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    textareaValue: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createTimeFieldValueMock(props: Partial<Types.TimeFieldValue> = {}): Types.TimeFieldValue {
  return {
    __typename: "TimeFieldValue",
    fid: generateUUID(),
    fieldIndex: 0,
    reason: "",
    timeValue: "",
    worksheetFieldValueUid: generateUUID(),
    worksheetLog: createWorksheetLogMock({}),
    worksheetLogUid: generateUUID(),
    ...props,
  };
}

export function createTrialMock(props: Partial<Types.Trial> = {}): Types.Trial {
  return {
    __typename: "Trial",
    featureChannel: "Beta",
    name: "",
    uid: generateUUID(),
    ...props,
  };
}

export function createTrialHospitalMock(props: Partial<Types.TrialHospital> = {}): Types.TrialHospital {
  return {
    __typename: "TrialHospital",
    hospital: createHospitalMock({}),
    hospitalUid: generateUUID(),
    mainTrialHospital: null,
    role: "Main",
    trialUid: generateUUID(),
    uid: generateUUID(),
    ...props,
  };
}

export function createTrialMemberMock(props: Partial<Types.TrialMember> = {}): Types.TrialMember {
  return {
    __typename: "TrialMember",
    role: "CRA",
    trialHospitalUIDs: [],
    uid: generateUUID(),
    user: createUserMock({}),
    userUid: generateUUID(),
    ...props,
  };
}

export function createTrialMembersParamMock(props: Partial<Types.TrialMembersParam> = {}): Types.TrialMembersParam {
  return {
    mainTrialHospitalUid: generateUUID(),
    roles: [],
    trialHospitalUids: [],
    ...props,
  };
}

export function createUpdateExpMembersInputMock(props: Partial<Types.UpdateExpMembersInput> = {}): Types.UpdateExpMembersInput {
  return {
    explanationRevisionUid: generateUUID(),
    explanationSessionUid: null,
    members: [],
    ...props,
  };
}

export function createUpdateExpMembersMemberInputMock(props: Partial<Types.UpdateExpMembersMemberInput> = {}): Types.UpdateExpMembersMemberInput {
  return {
    trialHospitalUid: generateUUID(),
    trialMemberUid: generateUUID(),
    ...props,
  };
}

export function createUpdateExpPatientMobileNumberInputMock(props: Partial<Types.UpdateExpPatientMobileNumberInput> = {}): Types.UpdateExpPatientMobileNumberInput {
  return {
    explanationPatientUid: generateUUID(),
    mobileNumber: "",
    ...props,
  };
}

export function createUpdateExpPatientMobileNumberPayloadMock(props: Partial<Types.UpdateExpPatientMobileNumberPayload> = {}): Types.UpdateExpPatientMobileNumberPayload {
  return {
    __typename: "UpdateExpPatientMobileNumberPayload",
    explanationPatientUid: generateUUID(),
    ...props,
  };
}

export function createUpdateExpPreSessionIdentificationInputMock(props: Partial<Types.UpdateExpPreSessionIdentificationInput> = {}): Types.UpdateExpPreSessionIdentificationInput {
  return {
    explanationPreSessionUid: generateUUID(),
    isIdentified: false,
    ...props,
  };
}

export function createUpdateExpPreSessionIdentificationPayloadMock(props: Partial<Types.UpdateExpPreSessionIdentificationPayload> = {}): Types.UpdateExpPreSessionIdentificationPayload {
  return {
    __typename: "UpdateExpPreSessionIdentificationPayload",
    explanationPreSessionUid: generateUUID(),
    ...props,
  };
}

export function createUpdateExpRevisionStatusInputMock(props: Partial<Types.UpdateExpRevisionStatusInput> = {}): Types.UpdateExpRevisionStatusInput {
  return {
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createUpdateExpRevisionStatusPayloadMock(props: Partial<Types.UpdateExpRevisionStatusPayload> = {}): Types.UpdateExpRevisionStatusPayload {
  return {
    __typename: "UpdateExpRevisionStatusPayload",
    explanationRevisionUid: generateUUID(),
    ...props,
  };
}

export function createUpdateExpSessionMembersPayloadMock(props: Partial<Types.UpdateExpSessionMembersPayload> = {}): Types.UpdateExpSessionMembersPayload {
  return {
    __typename: "UpdateExpSessionMembersPayload",
    explanationRevisionUid: generateUUID(),
    explanationSessionUid: null,
    ...props,
  };
}

export function createUpdatePatientInputMock(props: Partial<Types.UpdatePatientInput> = {}): Types.UpdatePatientInput {
  return {
    birthday: null,
    chartUid: null,
    diseaseUid: "",
    email: null,
    firstName: null,
    firstNameJa: null,
    gender: "Unspecified",
    lastName: null,
    lastNameJa: null,
    partnerTrialHospitalUid: null,
    patientUid: generateUUID(),
    phoneNumber: null,
    trialHospitalUid: generateUUID(),
    ...props,
  };
}

export function createUpdatePatientPayloadMock(props: Partial<Types.UpdatePatientPayload> = {}): Types.UpdatePatientPayload {
  return {
    __typename: "UpdatePatientPayload",
    patientUid: generateUUID(),
    ...props,
  };
}

export function createUpdateReservationForExplanationRevisionInputMock(props: Partial<Types.UpdateReservationForExplanationRevisionInput> = {}): Types.UpdateReservationForExplanationRevisionInput {
  return {
    explanationReservationUid: generateUUID(),
    reservationInviteeType: "PartnerMembers",
    reservedAt: dayjs().format(),
    ...props,
  };
}

export function createUpdateReservationForExplanationRevisionPayloadMock(props: Partial<Types.UpdateReservationForExplanationRevisionPayload> = {}): Types.UpdateReservationForExplanationRevisionPayload {
  return {
    __typename: "UpdateReservationForExplanationRevisionPayload",
    explanationReservationUid: generateUUID(),
    ...props,
  };
}

export function createUpdateWorksheetNameInputMock(props: Partial<Types.UpdateWorksheetNameInput> = {}): Types.UpdateWorksheetNameInput {
  return {
    name: "",
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createUpdateWorksheetNamePayloadMock(props: Partial<Types.UpdateWorksheetNamePayload> = {}): Types.UpdateWorksheetNamePayload {
  return {
    __typename: "UpdateWorksheetNamePayload",
    worksheet: createWorksheetMock({}),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createUploadFilesInputMock(props: Partial<Types.UploadFilesInput> = {}): Types.UploadFilesInput {
  return {
    files: [],
    ...props,
  };
}

export function createUploadFilesPayloadMock(props: Partial<Types.UploadFilesPayload> = {}): Types.UploadFilesPayload {
  return {
    __typename: "UploadFilesPayload",
    uploadedFileUids: [],
    uploadedFiles: [],
    ...props,
  };
}

export function createUploadedFileMock(props: Partial<Types.UploadedFile> = {}): Types.UploadedFile {
  return {
    __typename: "UploadedFile",
    extension: "",
    name: "",
    savedAt: dayjs().format(),
    size: 0,
    uid: generateUUID(),
    url: "",
    ...props,
  };
}

export function createUserMock(props: Partial<Types.User> = {}): Types.User {
  return {
    __typename: "User",
    email: "",
    firstName: "",
    lastName: "",
    uid: generateUUID(),
    ...props,
  };
}

export function createVideoCallRoomMock(props: Partial<Types.VideoCallRoom> = {}): Types.VideoCallRoom {
  return {
    __typename: "VideoCallRoom",
    callEndedAt: null,
    callStartedAt: null,
    createdAt: dayjs().format(),
    expiredAt: null,
    joinedMemberCount: null,
    logs: [],
    uid: generateUUID(),
    videoCallRoomType: "Agora",
    ...props,
  };
}

export function createVideoCallRoomLogMock(props: Partial<Types.VideoCallRoomLog> = {}): Types.VideoCallRoomLog {
  return {
    __typename: "VideoCallRoomLog",
    savedAt: dayjs().format(),
    videoCallRoomLogType: "Create",
    videoCallRoomLogUid: generateUUID(),
    videoCallRoomUid: generateUUID(),
    ...props,
  };
}

export function createVisitMock(props: Partial<Types.Visit> = {}): Types.Visit {
  return {
    __typename: "Visit",
    firstVisitedAt: dayjs().format(),
    patientMasterUid: generateUUID(),
    reservation: null,
    reservationUid: null,
    videoCalls: [],
    visitDate: "",
    visitUid: generateUUID(),
    ...props,
  };
}

export function createVisitVideoCallMock(props: Partial<Types.VisitVideoCall> = {}): Types.VisitVideoCall {
  return {
    __typename: "VisitVideoCall",
    videoCallRoom: createVideoCallRoomMock({}),
    videoCallRoomUid: generateUUID(),
    visit: createVisitMock({}),
    visitUid: generateUUID(),
    visitVideoCallUid: generateUUID(),
    ...props,
  };
}

export function createWorksheetMock(props: Partial<Types.Worksheet> = {}): Types.Worksheet {
  return {
    __typename: "Worksheet",
    fields: [],
    index: 0,
    latestWorksheetLog: createWorksheetLogMock({}),
    name: "",
    patientObservationUid: generateUUID(),
    worksheetLogs: [],
    worksheetSchema: createWorksheetSchemaMock({}),
    worksheetSchemaUid: generateUUID(),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createWorksheetCsvExportJobMock(props: Partial<Types.WorksheetCsvExportJob> = {}): Types.WorksheetCsvExportJob {
  return {
    __typename: "WorksheetCSVExportJob",
    domainEventUid: null,
    jobType: "WorksheetCSVExport",
    jobUid: generateUUID(),
    logs: [],
    ...props,
  };
}

export function createWorksheetCsvExportJobLogMock(props: Partial<Types.WorksheetCsvExportJobLog> = {}): Types.WorksheetCsvExportJobLog {
  return {
    __typename: "WorksheetCSVExportJobLog",
    actorTrialMember: null,
    actorTrialMemberUid: null,
    actorType: "System",
    isExpired: null,
    jobLogUid: generateUUID(),
    jobUid: generateUUID(),
    savedAt: dayjs().format(),
    status: "Cancelled",
    uploadFileUid: null,
    uploadedFile: null,
    ...props,
  };
}

export function createWorksheetCsvExportJobsInputMock(props: Partial<Types.WorksheetCsvExportJobsInput> = {}): Types.WorksheetCsvExportJobsInput {
  return {
    limit: 0,
    order: "SavedAtDesc",
    ...props,
  };
}

export function createWorksheetFileMock(props: Partial<Types.WorksheetFile> = {}): Types.WorksheetFile {
  return {
    __typename: "WorksheetFile",
    memo: "",
    order: 0,
    uploadedFile: createUploadedFileMock({}),
    uploadedFileUID: generateUUID(),
    worksheetFieldValueUid: generateUUID(),
    ...props,
  };
}

export function createWorksheetLogMock(props: Partial<Types.WorksheetLog> = {}): Types.WorksheetLog {
  return {
    __typename: "WorksheetLog",
    action: "Confirm",
    fieldValues: [],
    reason: null,
    savedAt: dayjs().format(),
    status: "Confirmed",
    trialMember: createTrialMemberMock({}),
    trialMemberUid: generateUUID(),
    worksheetLogUid: generateUUID(),
    worksheetUid: generateUUID(),
    ...props,
  };
}

export function createWorksheetSchemaMock(props: Partial<Types.WorksheetSchema> = {}): Types.WorksheetSchema {
  return {
    __typename: "WorksheetSchema",
    legacyTemplateIndex: null,
    name: "",
    schemaFile: "",
    worksheetSchemaUid: generateUUID(),
    ...props,
  };
}
