import { CrfDownloadJob, CrfDownloadStatus } from './entity'

export const crfDownloadJobFixtures: CrfDownloadJob[] = [
  {
    uid: 'test1',
    registeredAt: new Date('2021-11-02T08:42:00.000Z'),
    completedAt: new Date('2021-11-02T08:45:00.000Z'),
    lastUpdatedBy: 'mukai',
    status: CrfDownloadStatus.Done,
  },
  {
    uid: 'test2',
    registeredAt: new Date('2021-11-02T08:41:00.000Z'),
    completedAt: new Date('2021-11-02T08:42:00.000Z'),
    lastUpdatedBy: 'mukai',
    status: CrfDownloadStatus.Canceled,
  },
]
