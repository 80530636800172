import {
  Box,
  Stack,
  TextProps,
  Wrap,
  Text,
  BoxProps,
  WrapItem,
} from '@chakra-ui/react'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'

import { ExplanationPatient } from '../../types'

type Props = {
  patient: ExplanationPatient
} & BoxProps

const labelStyle: TextProps = {
  fontSize: 'sm',
  color: 'gray.600',
  fontWeight: 'bold',
}

export const ExplanationPatientInformationSectionForHistory: React.FC<
  Props
> = ({ patient, ...rest }) => {
  return (
    <Box as="section" aria-labelledby="patient_info_heading" {...rest}>
      <Box px="4" py="2" bg="blue.50">
        <TextWithBar as="h2" id="patient_info_heading">
          患者情報
        </TextWithBar>
      </Box>
      <Wrap p="6" spacing="16">
        <WrapItem>
          <Stack spacing="2">
            <Text {...labelStyle}>候補ID</Text>
            <Text>{patient.candidateId}</Text>
          </Stack>
        </WrapItem>
        <WrapItem textAlign="left">
          <Stack spacing="2">
            <Text {...labelStyle}>症例番号</Text>
            <Text>{patient?.expDiseaseId ?? ''}</Text>
          </Stack>
        </WrapItem>
        <WrapItem>
          <Stack spacing="2">
            <Text {...labelStyle}>患者メールアドレス</Text>
            <Text mt="2">{patient.email}</Text>
          </Stack>
        </WrapItem>
        <WrapItem>
          <Stack spacing="2">
            <Text {...labelStyle}>患者携帯電話番号</Text>
            <Text>{patient.phoneNumber}</Text>
          </Stack>
        </WrapItem>
      </Wrap>
    </Box>
  )
}
