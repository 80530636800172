import { useCallback, useEffect, useState } from 'react'

import { SwiperClass, useSwiper } from 'swiper/react'

type SwiperState = {
  prevSlideEnabled: boolean
  nextSlideEnabled: boolean
}

type UseMirohaSwiperReturn = SwiperState & {
  slideNext: () => void
  slidePrev: () => void
}

const getState = (swiper: SwiperClass): SwiperState => ({
  prevSlideEnabled: swiper.allowSlidePrev && !swiper.isBeginning,
  nextSlideEnabled: swiper.allowSlideNext && !swiper.isEnd,
})

/** swiperから提供されるuseSwiperの拡張
 *
 * リアルタイムでスライドの状態を取得できる（オリジナルのuseSwiperだとできない）
 * see) https://github.com/nolimits4web/swiper/issues/5577
 *  */
export const useMirohaSwiper = (): UseMirohaSwiperReturn => {
  const swiper = useSwiper()

  const [state, setState] = useState<SwiperState>(getState(swiper))

  const updateState = useCallback(() => {
    setState(getState(swiper))
  }, [swiper])

  // 初期値が正しく取得できるようにレンダリング後にstateを更新する
  useEffect(() => {
    updateState()
  }, [updateState])

  useEffect(() => {
    swiper.on('slideChange', updateState)
    swiper.on('resize', updateState)
    // 稀にslideChangeイベントで正しく制御できないケースがあるのでreachEndとreachBeginningも監視する
    swiper.on('reachEnd', updateState)
    swiper.on('reachBeginning', updateState)
  }, [swiper, updateState])

  const slideNext = useCallback(() => {
    swiper.slideNext()
  }, [swiper])

  const slidePrev = useCallback(() => {
    swiper.slidePrev()
  }, [swiper])

  return {
    ...state,
    slideNext,
    slidePrev,
  }
}
