import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'

import { ExportModalComponent } from './export-component'
import { submitExport } from './request'
import { templateIdParamName } from '../../template-detail'

type Props = {
  onClose: () => void
}

export const ExportContainer: React.FC<Props> = props => {
  const { trialUid = '', templateUid = '' } = useParams<{
    [templateIdParamName]: string
    [trialIdParamName]: string
  }>()

  const { url, requesting } = useExportTemplate({
    trialUid,
    templateUid,
  })

  if (requesting) return null

  return <ExportModalComponent url={url} onClose={props.onClose} />
}

const useExportTemplate = ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}) => {
  const [requesting, setRequesting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    const request = async () => {
      try {
        setRequesting(true)
        setErrorMessage('')
        const res = await submitExport({ trialUid, templateUid })
        setUrl(res.url)
        setRequesting(false)
      } catch (error) {
        setRequesting(false)
        setErrorMessage(error.message)
        throw error
      }
    }

    request()
  }, [trialUid, templateUid])

  return {
    requesting,
    errorMessage,
    url,
  }
}
