import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import {
  getAddIcfDocumentSetRoute,
  getIcfDocumentSetDetailRoute,
} from './../../routes'
import { usePermission } from '../../../../common/permission'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import { fetchIcfDocumentSetList } from '../request'

export const useIcfDocumentSetList = () => {
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const navigate = useNavigate()
  const { hasPermission } = usePermission()

  const { data: icfDocumentSetList } = useSWR(
    selectedTrialHospitalUid
      ? swrKeys.fetchIcfDocumentSetList({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
        })
      : null,
    () =>
      fetchIcfDocumentSetList({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
      }),
  )

  const canAddDocumentSet = useMemo(
    () => hasPermission(PERMISSIONS.Icfdocumentset_Create),
    [hasPermission],
  )

  const navigateToAdd = useCallback(() => {
    navigate(
      getAddIcfDocumentSetRoute({
        trialUid,
        trialHospitalUid: hasPermission(
          PERMISSIONS.Icfdocument_SelectTrialHospital,
        )
          ? selectedTrialHospitalUid
          : undefined,
      }),
    )
  }, [navigate, trialUid, hasPermission, selectedTrialHospitalUid])

  const navigateToDetail = useCallback(
    (icfDocumentSetUid: string) => {
      navigate(
        getIcfDocumentSetDetailRoute({
          trialUid,
          icfDocumentSetUid,
          trialHospitalUid: hasPermission(
            PERMISSIONS.Icfdocument_SelectTrialHospital,
          )
            ? selectedTrialHospitalUid
            : undefined,
        }),
      )
    },
    [navigate, trialUid, hasPermission, selectedTrialHospitalUid],
  )

  return {
    icfDocumentSetList,
    canAddDocumentSet,
    navigateToAdd,
    navigateToDetail,
  }
}
