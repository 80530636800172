import { useAtomValue } from 'jotai'
import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { isCriteriaValuesChangedAtom } from '../../atom'

export const ReferredPatientTransitionBlocker: React.FC = () => {
  const isEditing = useAtomValue(isCriteriaValuesChangedAtom)

  useBlockTransition({
    showAlert: isEditing,
  })

  return null
}
