import { MediumItem } from '../medium-item/entity'

export enum TemplateStatus {
  Draft = 1,
  Released,
  New,
  Disabled,
}

export type Template = {
  uid: string
  trialUid: string
  title: string
  templateFolderUid: string
  presetUsed: boolean
  status: TemplateStatus
  createdAt: string
  updatedAt: string
  releasedAt: string | null
  videoEnabled: boolean
  index: number
  mediumItems: MediumItem[]
}
