import { Flex, IconButton, Input, Text } from '@chakra-ui/react'
import { Field, TimeTypeDef } from '@micin-jp/chicken-schema'
import { Time } from 'src/components/__legacy__icon/monochrome'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { toTimeString, toWorksheetTime, WorksheetTime } from '../../utils/time'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  timeField: Field & {
    typeDef: TimeTypeDef
  }
  index: number
}

export const WorksheetTimeField: React.FC<Props> = ({ timeField, index }) => {
  const {
    findFieldValue,
    onChangeTimeFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: timeField.fid,
    index,
  })

  const time = toWorksheetTime(
    value?.type === 'time' ? value.timeValue : undefined,
  )

  const onChangeTime = (time: WorksheetTime) => {
    onChangeTimeFieldValue({
      fid: timeField.fid,
      index,
      timeValue: toTimeString(time),
    })
  }

  const onChangeHour = (hour: string) => {
    onChangeTime({ ...time, hour })
  }
  const onChangeMinute = (minute: string) => {
    onChangeTime({ ...time, minute })
  }

  const onClickTimerIcon = () => {
    const now = new Date()
    onChangeTime({
      hour: now.getHours().toString().padStart(2, '0'),
      minute: now.getMinutes().toString().padStart(2, '0'),
    })
  }

  const isDisabled =
    !canEdit || !isRepeatableSectionEnabled(index, timeField.fid)

  return (
    <WorksheetFieldFormControl
      field={timeField}
      index={index}
      isDisabled={isDisabled}
      errorMessage={getErrorMessage(value)}
    >
      <Flex align="center">
        <Flex align="center" mr="4">
          <Input
            w="56px"
            mr="1"
            value={time.hour}
            onChange={e => onChangeHour(e.target.value)}
          />
          <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
            時
          </Text>
          <Input
            w="56px"
            mr="1"
            value={time.minute}
            onChange={e => onChangeMinute(e.target.value)}
          />
          <Text as="span" fontSize="sm" fontWeight="bold">
            分
          </Text>
        </Flex>
        {!isDisabled && (
          <IconButton
            variant="customIconButtonGhost"
            colorScheme="blue"
            aria-label="timer icon"
            icon={<Time color={colors.blue[500]} />}
            onClick={onClickTimerIcon}
          />
        )}
      </Flex>
    </WorksheetFieldFormControl>
  )
}
