import {
  Box,
  HStack,
  Link,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ExternalLink } from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { WorksheetFile } from 'src/lib/gql-client'
import { openNewWindow } from 'src/utils/openNewWindow'

import { FileFieldValue } from '../../types'

type Props = {
  isNewValue: boolean
  oldFileFieldValue: FileFieldValue | undefined
  newFileFieldValue: FileFieldValue | undefined
}

const findFileByUploadedFileUID = (
  uploadedFileUID: string,
  fileFieldValue: FileFieldValue | undefined,
): WorksheetFile | undefined => {
  return fileFieldValue?.files.find(f => f.uploadedFile.uid === uploadedFileUID)
}

const comparedFile = (
  file: WorksheetFile,
  from: WorksheetFile | undefined,
): {
  orderChanged: boolean
  fileChanged: boolean
  memoChanged: boolean
} => {
  const orderChanged = from?.order !== file.order
  const fileChanged = from === undefined
  const memoChanged = from?.memo !== file.memo
  return { orderChanged, fileChanged, memoChanged }
}

/** FileFieldValueの表示ビュー
 *
 * 一時保存、履歴での使用を想定
 * 新規の場合、古い値の場合いずれの場合も差分を表示する
 * **/
export const FileFieldValueView: React.FC<Props> = ({
  isNewValue,
  newFileFieldValue,
  oldFileFieldValue,
}) => {
  const value = isNewValue ? newFileFieldValue : oldFileFieldValue

  const changedColor = isNewValue ? 'green.500' : 'red.500'
  const changedDecoration = isNewValue ? 'none' : 'line-through'

  if (!value) return null

  return (
    <List spacing={4}>
      {value.files.map(file => {
        const { orderChanged, fileChanged, memoChanged } = comparedFile(
          file,
          findFileByUploadedFileUID(
            file.uploadedFile.uid,
            isNewValue ? oldFileFieldValue : newFileFieldValue,
          ),
        )

        const isDroppedOrder =
          !isNewValue &&
          file.order >
            Math.max(...(newFileFieldValue?.files.map(f => f.order) ?? [0]))

        return (
          <ListItem key={file.uploadedFile.uid}>
            <HStack alignItems="top">
              <Text
                whiteSpace="nowrap"
                color={
                  isDroppedOrder
                    ? 'red.500'
                    : orderChanged && isNewValue
                      ? 'green.500'
                      : 'gray.800'
                }
                textDecoration={isDroppedOrder ? 'line-through' : 'none'}
              >
                {file.order}.
              </Text>
              <Stack spacing="1.5">
                <Link
                  as="button"
                  type="button"
                  onClick={() =>
                    openNewWindow(file.uploadedFile.url, 'newWindow')
                  }
                  color={fileChanged ? changedColor : 'blue.500'}
                >
                  <HStack as="span">
                    <Text
                      textDecoration={fileChanged ? changedDecoration : 'none'}
                      as="span"
                      wordBreak="break-all"
                    >
                      {file.uploadedFile.name}
                    </Text>
                    <Box as="span" w="16px">
                      <ExternalLink color={colors.blue[500]} />
                    </Box>
                  </HStack>
                </Link>

                <Text
                  fontSize="xs"
                  textDecoration={memoChanged ? changedDecoration : 'none'}
                  textColor={memoChanged ? changedColor : 'gray.800'}
                  wordBreak="break-all"
                >
                  {file.memo}
                </Text>
              </Stack>
            </HStack>
          </ListItem>
        )
      })}
    </List>
  )
}
