import { Center, Text } from '@chakra-ui/react'
import { PatientSimpleScreenContainer } from 'src/components/PatientSimpleScreenContainer/PatientSimpleScreenContainer'

export const CompletedSignAuth: React.FC = () => {
  return (
    <PatientSimpleScreenContainer>
      <Center>
        <Text fontSize="lg" fontWeight="bold">
          すでに完了しています
        </Text>
      </Center>
    </PatientSimpleScreenContainer>
  )
}
