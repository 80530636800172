import {
  GqlError,
  GetSessionForSidebarQuery,
  GetSessionForSidebarDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type GetSessionForSidebarRes = GetSessionForSidebarQuery['explanationSession']

const getSessionForSidebar = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<GetSessionForSidebarRes> => {
  try {
    const res = await graphqlRequest(GetSessionForSidebarDocument, {
      explanationSessionUid,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionForSidebar = ({
  explanationSessionUid,
  ...option
}: {
  explanationSessionUid: string
} & SWRConfiguration<GetSessionForSidebarRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionForSidebar(explanationSessionUid),
    () => getSessionForSidebar({ explanationSessionUid }),
    option,
  )
}
