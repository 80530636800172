import {
  GqlError,
  PatientVideoCallsOrder,
  GetPatientVideoCallsForHistoryQuery,
  GetPatientVideoCallsForHistoryDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { patientCacheKey } from '../cacheKey'

export type GetPatientVideoCallsForHistoryReturn =
  GetPatientVideoCallsForHistoryQuery['patient']['videoCalls']

const GetPatientVideoCallsForHistory = async ({
  patientUid,
  order,
}: {
  patientUid: string
  order: PatientVideoCallsOrder
}) => {
  try {
    const res = await graphqlRequest(GetPatientVideoCallsForHistoryDocument, {
      patientUid,
      order,
    })
    return res.patient.videoCalls
  } catch (error) {
    throw error
  }
}

export const usePatientVideoCallsForHistory = ({
  patientUid,
  order,
  ...option
}: {
  patientUid: string
  order: PatientVideoCallsOrder
} & SWRConfiguration<GetPatientVideoCallsForHistoryReturn, GqlError>) => {
  return useSWR<GetPatientVideoCallsForHistoryReturn, GqlError>(
    patientCacheKey.getPatientVideoCallsForHistory(patientUid),
    () => GetPatientVideoCallsForHistory({ patientUid, order }),
    option,
  )
}
