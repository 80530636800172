import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

export const FixedGeneral: React.FC = () => {
  const questionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail,
  )

  const { title, displayName } = questionnaire

  return (
    <ItemContainer>
      <Item>
        <Label>
          <Text fontWeight="bold">質問票名</Text>
        </Label>
        <Spacer size={40} horizontal />
        <Input width={'480px'} value={title} disabled={true} />
      </Item>

      <Spacer size={20} />

      <Item>
        <Label>
          <Text fontWeight="bold">患者表示名</Text>
        </Label>
        <Spacer size={40} horizontal />
        <Input width={'480px'} value={displayName} disabled={true} />
      </Item>
    </ItemContainer>
  )
}

const ItemContainer = styled.div``

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Label = styled.div`
  width: 80px;
`
