import { TemplateFolder } from './entity'

export const fixture: TemplateFolder = {
  uid: 'folder1',
  name: 'folder1',
  isPreset: false,
  index: 0,
  observationType: 'Visit',
}

export const fixtures: TemplateFolder[] = [
  {
    uid: 'preset',
    name: '',
    isPreset: true,
    index: 0,
    observationType: 'Visit',
  },
  fixture,
  {
    uid: 'folder2',
    name: 'folder2',
    isPreset: false,
    index: 1,
    observationType: 'Visit',
  },
  {
    uid: 'folder3',
    name: 'FolderFolderFolderFolderFolderFolder',
    isPreset: false,
    index: 2,
    observationType: 'FollowUp',
  },
]
