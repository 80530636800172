import React from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { actions as templateActions } from 'src/modules/entities/template/redux'
import { getSingle } from 'src/modules/entities/template/selector'
import { RootState } from 'src/modules/reducer'
import { useRequestState } from 'src/modules/server/use-request-state'

import { submitTemplateEnable } from './request'
import { trialIdParamName } from '../../../../../trial-detail'
import { templateIdParamName } from '../../template-detail'

type Props = {
  onClose: () => void
}

export const EnableContainer: React.FC<Props> = props => {
  const { trialUid = '', templateUid = '' } = useParams<{
    [templateIdParamName]: string
    [trialIdParamName]: string
  }>()

  const template = useSelector((state: RootState) =>
    getSingle(state, templateUid),
  )

  const { request, requesting } = useEnableTemplate({
    trialUid,
    templateUid,
  })

  const dispatch = useDispatch()
  const toast = useMirohaToast()

  const onSubmit = () => {
    const submit = async () => {
      try {
        await request()
        await dispatch(templateActions.fetchSingle({ trialUid, templateUid }))
        toast({
          status: 'success',
          title: 'テンプレートを有効化しました。',
        })
        props.onClose()
      } catch (error) {
        toast({
          status: 'error',
          title: error.message,
        })
      }
    }

    submit()
  }

  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>テンプレートを有効化</ModalHeader>
        <ModalBody>
          <Text>
            <Text fontWeight="bold" as="span">
              {template.title}
            </Text>
            を有効化します。
          </Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button onClick={onSubmit} isDisabled={requesting}>
            有効化する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const useEnableTemplate = ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()

  const request = async () => {
    try {
      requestStarted()
      await submitTemplateEnable({ trialUid, templateUid })
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}
