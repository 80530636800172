import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { PullDown } from 'src/components/layout/pulldown'
import { Spacer } from 'src/components/spacer/spacer'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import styled from 'styled-components'

import { AddRevisionModal } from '../components/add-revision-modal'
import { IcfDocumentCard } from '../components/document-card'
import { CreateIcfDocumentRevisionItem, IcfDocumentDetail } from '../entity'
import { IcfDocumentErrors } from '../validate'

type Props = {
  icfDocument: IcfDocumentDetail | undefined
  nextRevision: CreateIcfDocumentRevisionItem | undefined
  filePreviewUrl: string
  listPath: string
  editPath: string
  errors: IcfDocumentErrors
  shouldSelectHospital: boolean
  hospitalName: string
  hasMultipleRevisionHistories: boolean
  hasTempFile: boolean
  pullDownState: ReturnType<typeof useOpenPullDown>
  submitModalState: ReturnType<typeof useOpenModal>
  onValidate: () => boolean
  onChangeName: (value: string) => void
  onChangeVersion: (value: string) => void
  onChangeRequiredPatientSign: () => void
  onSelectFile: (file: File) => Promise<void>
  onSubmit: () => Promise<void>
  onCancel: () => void
  openRevisionHistoryPane: () => void
}

export const AddIcfDocumentRevision: React.FC<Props> = ({
  icfDocument,
  nextRevision,
  filePreviewUrl,
  listPath,
  editPath,
  errors,
  shouldSelectHospital,
  hospitalName,
  hasMultipleRevisionHistories,
  hasTempFile,
  pullDownState,
  submitModalState,
  onValidate,
  onChangeName,
  onChangeVersion,
  onChangeRequiredPatientSign,
  onSelectFile,
  onSubmit,
  onCancel,
  openRevisionHistoryPane,
}) => {
  if (!icfDocument || !nextRevision) {
    return null
  }

  const breadcrumbParams: BreadcrumbParam[] = [
    { label: '文書一覧', isLink: true, path: listPath },
    { label: '文書編集', isLink: true, path: editPath },
    { label: '改版登録', isLink: false },
  ]

  return (
    <Wrapper>
      {submitModalState.modalOpen && (
        <AddRevisionModal
          onClose={submitModalState.handlers.closeModal}
          onSubmit={onSubmit}
        />
      )}
      <Breadcrumb breadcrumbParams={breadcrumbParams} />
      <Spacer size={32} />
      <ContentMenu>
        {shouldSelectHospital && (
          <HospitalNameContainer>
            <Text fontSize="sm" fontWeight="bold">
              医療機関 : {hospitalName}
            </Text>
          </HospitalNameContainer>
        )}
        <FlexSpacer />
        <ButtonsContainer>
          <Button
            size="S"
            buttonType="important"
            text="改版登録"
            onClick={() => {
              if (!onValidate()) {
                return
              }
              submitModalState.handlers.openModal()
            }}
          />
          <Spacer size={16} horizontal />
          <IconButton
            iconName="expand3"
            onClick={pullDownState.handlers.openPullDown}
          />
        </ButtonsContainer>
      </ContentMenu>
      {pullDownState.pullDownOpen && (
        <PullDown
          pulldownOpen={pullDownState.pullDownOpen}
          closePulldown={pullDownState.handlers.closePullDown}
          items={[
            { name: '改版を取り消し', onClick: onCancel, fontColor: red[40] },
          ]}
        />
      )}
      <Spacer size={32} />
      <CardContainer>
        <IcfDocumentCard
          cardType="addRevision"
          numberingId={icfDocument.numberingId}
          name={nextRevision.name}
          documentType={nextRevision.type}
          version={nextRevision.version}
          fileName={nextRevision.fileName}
          filePreviewUrl={filePreviewUrl}
          isRequiredPatientSign={nextRevision.isRequiredPatientSign}
          onChangeName={onChangeName}
          onChangeVersion={onChangeVersion}
          onChangeRequiredPatientSign={onChangeRequiredPatientSign}
          onSelectFile={onSelectFile}
          hasTempFile={hasTempFile}
          errors={errors}
          hasMultipleRevisionHistories={hasMultipleRevisionHistories}
          openRevisionHistory={openRevisionHistoryPane}
        />
      </CardContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ContentMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HospitalNameContainer = styled.div`
  display: flex;
  align-items: center;
`

const FlexSpacer = styled.div`
  flex: 1;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const CardContainer = styled.div`
  width: 100%;
  max-width: 800px;
`
