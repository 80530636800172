import React from 'react'

import styled from 'styled-components'

type Props = {
  children: React.ReactNode
}

/**
 * @deprecated Use chakra-ui ModalFooter instead.
 */
export const ModalActions: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
