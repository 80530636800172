import { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  Collapse,
  Flex,
  FlexProps,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { atom, useAtom, useAtomValue } from 'jotai'
import { useDrag, useDrop } from 'react-dnd'
import { Arrange, ArrowDown, ArrowUp, Hamburger } from 'src/components/icon'
import { InformationMessage } from 'src/components/InformationMessage/InformationMessage'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { fieldFamily, schemaStructureAtom } from 'src/lib/chicken-schema/atom'
import { FieldStructure, SchemaStructure } from 'src/lib/chicken-schema/utils'

export const SortFieldButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="text" leftIcon={<Arrange />} onClick={onOpen}>
        並べ替え
      </Button>

      {isOpen && <SortModal onClose={onClose} />}
    </>
  )
}

const SortModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [schemaStructure, setSchemaStructure] = useAtom(schemaStructureAtom)

  const [editingStructure, setEditingStructure] = useState(schemaStructure)

  const isChanged =
    JSON.stringify(schemaStructure) !== JSON.stringify(editingStructure)

  const toast = useMirohaToast()

  const handleSubmit = () => {
    setSchemaStructure(editingStructure)
    onClose()
    toast({
      status: 'success',
      title: '並び順を保存しました。',
    })
  }

  return (
    <Modal isOpen onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent h="full">
        <ModalCloseButton />
        <ModalHeader>
          <VStack spacing="2" align="start">
            <Text as="span">セクション・フィールド並べ替え</Text>
            <InformationMessage message="ドラッグ＆ドロップでフィールドの並び順を変更できます。" />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Box w="full" borderTop="1px" borderColor="gray.500">
            {editingStructure.fields.map((fieldStructure, i) => (
              <NestedDraggable
                key={fieldStructure.fid}
                currentStructure={editingStructure}
                fieldStructure={fieldStructure}
                onChangeSchemaStructure={setEditingStructure}
                parentFid={null}
                index={i}
              />
            ))}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button isDisabled={!isChanged} onClick={handleSubmit}>
            保存する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const NestedDraggable: React.FC<{
  showBorder?: boolean
  currentStructure: SchemaStructure
  fieldStructure: FieldStructure
  parentFid: string | null
  index: number
  onChangeSchemaStructure: (newStructure: SchemaStructure) => void
}> = ({
  showBorder = true,
  currentStructure,
  fieldStructure,
  parentFid,
  index,
  onChangeSchemaStructure,
}) => {
  return (
    <Draggable
      fid={fieldStructure.fid}
      onChangeStructure={onChangeSchemaStructure}
      currentStructure={currentStructure}
      parentFid={parentFid}
      noFields={!fieldStructure.fields || fieldStructure.fields.length === 0}
      showBorder={showBorder}
      index={index}
    >
      {!!fieldStructure.fields && fieldStructure.fields.length > 0 && (
        <Box pl="8">
          {fieldStructure.fields.map((childFieldStructure, i) => (
            <NestedDraggable
              key={childFieldStructure.fid}
              currentStructure={currentStructure}
              fieldStructure={childFieldStructure}
              parentFid={fieldStructure.fid}
              onChangeSchemaStructure={onChangeSchemaStructure}
              showBorder={i !== fieldStructure.fields!.length - 1}
              index={i}
            />
          ))}
        </Box>
      )}
    </Draggable>
  )
}

// 新たなschemaStructureを返す
const updateOrder = (
  schemaStructure: SchemaStructure,
  parentFid: string | null,
  myFid: string,
  targetFid: string,
  position: 'top' | 'bottom',
): SchemaStructure => {
  const getFieldStructure = (
    fields: FieldStructure[],
    myFid: string,
    targetFid: string,
  ): FieldStructure[] => {
    const me = fields.find(field => field.fid === myFid)
    const target = fields.find(field => field.fid === targetFid)
    if (!me || !target) return fields

    // positionがtopの場合、targetの前にmeを挿入する
    // positionがbottomの場合、targetの後ろにmeを挿入する
    // それ以外の順番は変更しない
    return fields.reduce<FieldStructure[]>((acc, field) => {
      if (field.fid === targetFid && position === 'top') {
        acc.push(me)
      }
      if (field.fid !== myFid) {
        acc.push(field)
      }
      if (field.fid === targetFid && position === 'bottom') {
        acc.push(me)
      }
      return acc
    }, [])
  }

  const isRoot = parentFid === null
  if (isRoot) {
    return {
      ...schemaStructure,
      fields: getFieldStructure(schemaStructure.fields, myFid, targetFid),
    }
  }

  // 3層目を考慮する場合は再帰的に呼び出す必要があるが現時点では2階層目までのみ考慮する
  return {
    ...schemaStructure,
    fields: schemaStructure.fields.map(field => {
      if (field.fid === parentFid && field.fields) {
        return {
          ...field,
          fields: getFieldStructure(field.fields, myFid, targetFid),
        }
      }
      return field
    }),
  }
}
// 子フィールドを別のセクションに移動する
// 2層目のフィールドを別のセクションに移動することのみを想定している
const insertFieldToOtherField = (
  schemaStructure: SchemaStructure,
  currentParentFid: string,
  nextParentFid: string,
  myFid: string,
  targetFid: string | null,
  position: 'top' | 'bottom',
): SchemaStructure => {
  const me = schemaStructure.fields
    .find(field => field.fid === currentParentFid)
    ?.fields?.find(field => field.fid === myFid)
  if (!me) return schemaStructure

  const newStructure: SchemaStructure = {
    ...schemaStructure,
    fields: schemaStructure.fields.map(field => {
      if (field.fid === currentParentFid && field.fields) {
        return {
          fid: field.fid,
          fields: field.fields.filter(f => f.fid !== myFid),
        }
      }
      if (field.fid === nextParentFid) {
        const target = field.fields?.find(f => f.fid === targetFid)
        let newFields: FieldStructure[]
        if (!field.fields) {
          newFields = [me]
        } else if (!target) {
          newFields = [...field.fields, me]
        } else if (position === 'top') {
          // topの場合、targetの前にmeをpush
          newFields = field.fields.reduce<FieldStructure[]>((acc, f) => {
            if (f.fid === targetFid) {
              acc.push(me)
            }
            acc.push(f)
            return acc
          }, [])
        } else {
          // bottomの場合、targetの後ろにmeをpush
          newFields = field.fields.reduce<FieldStructure[]>((acc, f) => {
            acc.push(f)
            if (f.fid === targetFid) {
              acc.push(me)
            }
            return acc
          }, [])
        }
        return {
          fid: field.fid,
          fields: newFields,
        }
      }
      return field
    }),
  }

  return newStructure
}

type DragItem = {
  parentFid: string | null
  fid: string
  index: number
}

// 全てのDraggableコンポーネントでいずれかの要素がドラッグ中かどうかを管理する
const someIsDraggingAtom = atom(false)

const Draggable: React.FC<{
  children?: React.ReactNode
  showBorder?: boolean
  parentFid: string | null
  fid: string
  index: number
  currentStructure: SchemaStructure
  noFields: boolean
  onChangeStructure: (newStructure: SchemaStructure) => void
}> = ({
  showBorder,
  fid,
  parentFid,
  index,
  currentStructure,
  onChangeStructure,
  noFields,
  children,
}) => {
  const field = useAtomValue(fieldFamily(fid))

  const [someIsDragging, setSomeIsDragging] = useAtom(someIsDraggingAtom)

  const [shouldInsertTop, setShouldInsertTop] = useState(false)
  const [indicatorBorderProps, setIndicatorBorderProps] = useState<FlexProps>(
    {},
  )

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })

  const nodeRef = useRef<HTMLDivElement | null>(null)

  const [{ isDragging }, drag] = useDrag({
    type: 'field',
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    item: {
      parentFid,
      fid,
      index,
    },
  })

  useEffect(() => {
    setSomeIsDragging(isDragging)
    return () => {
      setSomeIsDragging(false)
    }
  }, [isDragging, setSomeIsDragging])

  const [{ isOver, isOverCurrent }, drop] = useDrop<
    DragItem,
    unknown,
    {
      isOver: boolean
      isOverCurrent: boolean
    }
  >({
    accept: 'field',
    collect: monitor => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (item.parentFid !== null && parentFid === null && !isOpen) {
        onToggle()
      }

      // 自身の上に入れるべきか、下に入れるべきかを判定する
      // 基本的にはhoverしたアイテムの下にborderを表示して、その下に入れるようにする
      // ただし、最初のアイテム（index=0）だけはその上にborderを表示して、その上に入れられるようにする
      // これで全ての場所に移動できるようになる

      const clientY = monitor.getClientOffset()?.y
      if (!clientY) return
      const hoverBoundingRect = nodeRef.current?.getBoundingClientRect()
      if (!hoverBoundingRect) return
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverClientY = clientY - hoverBoundingRect.top
      const shouldInsertTop = hoverClientY < hoverMiddleY && index === 0
      setShouldInsertTop(shouldInsertTop)

      // 同じ階層かつ一つ上に隣接している場合はindicatorを表示しない
      const isOneHigher =
        item.parentFid === parentFid && item.index === index + 1

      // フィールドを空のセクションに移動する場合
      const isEmptySection =
        item.fid !== fid &&
        item.parentFid !== fid &&
        item.parentFid !== null &&
        parentFid === null &&
        noFields
      if (isEmptySection) {
        setIndicatorBorderProps({
          border: '2px',
          borderColor: 'blue.500',
          top: '0',
          left: '0',
          h: 'full',
        })
        return
      }
      const isSameTypeField =
        (item.parentFid === null && parentFid === null) ||
        (item.parentFid !== null && parentFid !== null)
      if (
        !isSameTypeField ||
        item.fid === fid ||
        (!shouldInsertTop && isOneHigher)
      ) {
        setIndicatorBorderProps({})
        return
      }

      if (shouldInsertTop) {
        setIndicatorBorderProps({
          borderTop: '4px',
          borderColor: 'blue.500',
          top: '0',
        })
      } else {
        setIndicatorBorderProps({
          borderBottom: '4px',
          borderColor: 'blue.500',
          bottom: '0',
        })
      }
    },
    drop: item => {
      if (item.fid === fid) return

      // 空のセクションにフィールドを移動する場合
      if (item.parentFid !== null && parentFid === null) {
        if (!noFields) return
        onChangeStructure(
          insertFieldToOtherField(
            currentStructure,
            item.parentFid,
            fid,
            item.fid,
            null,
            'bottom',
          ),
        )
        return
      }
      // フィールドをすでにフィールドが存在する別のセクションに移動する場合
      if (
        item.parentFid !== parentFid &&
        item.parentFid !== null &&
        parentFid !== null
      ) {
        onChangeStructure(
          insertFieldToOtherField(
            currentStructure,
            item.parentFid,
            parentFid,
            item.fid,
            fid,
            shouldInsertTop ? 'top' : 'bottom',
          ),
        )
        return
      }
      // 同一セクション内ではフィールドの順番を入れ替える
      onChangeStructure(
        updateOrder(
          currentStructure,
          parentFid,
          item.fid,
          fid,
          shouldInsertTop ? 'top' : 'bottom',
        ),
      )
    },
  })

  // セクションの場合はエリア全体にoverされているかどうかを判定する
  const showIndicator = parentFid === null ? isOver : isOverCurrent

  return (
    <Box
      ref={node => {
        nodeRef.current = node
        drag(drop(node))
      }}
      w="full"
      cursor="pointer"
      borderBottom={parentFid === null ? '1px' : 'none'}
      borderColor="gray.100"
      pos="relative"
    >
      <Box
        pos="absolute"
        w="full"
        zIndex="1"
        {...(showIndicator && indicatorBorderProps)}
      />
      <Box borderBottom={showBorder ? '1px' : 'unset'} borderColor="gray.50">
        <Flex
          as={!isOpen && parentFid === null ? 'button' : 'div'}
          onClick={() => {
            if (parentFid !== null) return
            onToggle()
          }}
          w="full"
          p="3"
          justify="space-between"
          align="center"
          bg={parentFid === null ? 'blue.50' : 'white'}
          _hover={{
            bg: parentFid === null ? 'blue.100' : 'gray.50',
          }}
          _active={{
            bg: someIsDragging
              ? parentFid === null
                ? 'blue.50'
                : 'white'
              : parentFid === null
                ? 'blue.100'
                : 'gray.50',
            filter: someIsDragging
              ? 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.20))'
              : 'unset',
          }}
        >
          <HStack spacing="6">
            <Box w="16px" color="gray.500">
              <Hamburger />
            </Box>
            {parentFid === null ? (
              <TextWithBar as="span">{field.name}</TextWithBar>
            ) : (
              <span>{field.name}</span>
            )}
          </HStack>
          {parentFid === null && (
            <Box w="16px" color="gray.300">
              {isOpen ? <ArrowUp /> : <ArrowDown />}
            </Box>
          )}
        </Flex>
      </Box>
      {!!children && (
        <Collapse in={isOpen}>
          <Box>{children}</Box>
        </Collapse>
      )}
    </Box>
  )
}
