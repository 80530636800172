import { css } from 'styled-components'

import {
  Palette,
  white,
  blue,
  green,
  red,
  gray,
  yellow,
} from '../color/palette'

//TODO: type名は要再検討。デザインシステムと統一した名前にしたい。
export type ButtonType =
  | 'important'
  | 'normal'
  | 'cancel'
  | 'alert'
  | 'notice'
  | 'warning'

export const getButtonColor = (props: { buttonType: ButtonType }) => {
  switch (props.buttonType) {
    case 'important': {
      return getColorStyle({
        normal: {
          color: white,
          backgroundColor: blue[70],
          borderColor: blue[70],
        },
        hover: {
          color: white,
          backgroundColor: blue[80],
          borderColor: blue[80],
        },
        click: {
          color: white,
          backgroundColor: blue[90],
          borderColor: blue[90],
        },
      })
    }
    case 'normal': {
      return getColorStyle({
        normal: {
          color: blue[70],
          backgroundColor: white,
          borderColor: blue[70],
        },
        hover: {
          color: blue[70],
          backgroundColor: blue[10],
          borderColor: blue[70],
        },
        click: {
          color: blue[70],
          backgroundColor: blue[20],
          borderColor: blue[70],
        },
      })
    }
    case 'cancel': {
      return getColorStyle({
        normal: {
          color: gray[80],
          backgroundColor: white,
          borderColor: gray[80],
        },
        hover: {
          color: gray[80],
          backgroundColor: gray[5],
          borderColor: gray[80],
        },
        click: {
          color: gray[80],
          backgroundColor: gray[10],
          borderColor: gray[80],
        },
      })
    }
    case 'alert': {
      return getColorStyle({
        normal: {
          color: red[30],
          backgroundColor: white,
          borderColor: red[30],
        },
        hover: {
          color: red[30],
          backgroundColor: red[5],
          borderColor: red[30],
        },
        click: {
          color: red[30],
          backgroundColor: red[10],
          borderColor: red[30],
        },
      })
    }
    case 'notice': {
      return getColorStyle({
        normal: {
          color: white,
          backgroundColor: green[40],
          borderColor: green[40],
        },
        hover: {
          color: white,
          backgroundColor: green[50],
          borderColor: green[50],
        },
        click: {
          color: white,
          backgroundColor: green[60],
          borderColor: green[60],
        },
      })
    }
    //figmaにstyleが定義されていない
    case 'warning': {
      return getColorStyle({
        normal: {
          color: white,
          backgroundColor: yellow[70],
          borderColor: yellow[70],
        },
        hover: {
          color: white,
          backgroundColor: yellow[80],
          borderColor: yellow[80],
        },
        click: {
          color: white,
          backgroundColor: yellow[90],
          borderColor: yellow[90],
        },
      })
    }
  }
}

const disabledButtonStyle = css`
  color: ${gray[10]};
  background-color: ${gray[40]};
  border-color: ${gray[40]};
`

type ButtonColors = {
  color: Palette
  backgroundColor: Palette
  borderColor: Palette
}

const getColorStyle = (props: {
  normal: ButtonColors
  hover: ButtonColors
  click: ButtonColors
}) => {
  // モバイルブラウザ等タッチデバイスの場合は :hover にスタイルを付けない
  // (タップ後に別の場所をタップするまでずっと hover 状態が続くため)
  const isTouchDevice = 'ontouchend' in document
  const hover = isTouchDevice
    ? ''
    : css`
        &:hover {
          color: ${props.hover.color};
          background-color: ${props.hover.backgroundColor};
          border-color: ${props.hover.borderColor};
        }
      `
  return css`
    color: ${props.normal.color};
    background-color: ${props.normal.backgroundColor};
    border-color: ${props.normal.borderColor};
    ${hover}
    &:active {
      color: ${props.click.color};
      background-color: ${props.click.backgroundColor};
      border-color: ${props.click.borderColor};
    }
    &:disabled {
      ${disabledButtonStyle};
    }
  `
}
