import React, { useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Box,
  FormLabel,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { Select } from 'src/components/Select/Select'
import { useSelectedTrial } from 'src/features/auth/context'
import { useRequestPinSetting } from 'src/features/explanation/api/requestPinSetting'
import { AfterPinSettingPhoneNumberSettingModal } from 'src/features/explanation/components/AfterPinSettingPhoneNumberSettingModal/AfterPinSettingPhoneNumberSettingModal'
import { ConfirmOnScreenPinSettingModal } from 'src/features/explanation/components/ConfirmOnScreenPinSettingModal/ConfirmOnScreenPinSettingModal'
import { PassToPatientModal } from 'src/features/explanation/components/PassToPatientModal/PassToPatientModal'
import { PinSettingModal } from 'src/features/explanation/components/PinSettingModal/PinSettingModal'
import { SelectPinSettingMethodModal } from 'src/features/explanation/components/SelectPinSettingMethodModal/SelectPinSettingMethodModal'
import { SubmitSMSPinSettingModal } from 'src/features/explanation/components/SubmitSMSPinSettingModal/SubmitSMSPinSettingModal'
import { useActiveMembersByTrialHospitalUids } from 'src/features/member/api/getActiveTrialMembersByTrialHospitalUids'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { ExplanationType, parseGqlError } from 'src/lib/gql-client'
import { getFullName } from 'src/utils/getFullName'

import { useRequestPinSettingEvent } from '../../context/ExplanationRoomEvent'

type Props = {
  isReset?: boolean
  isFinialCheck?: boolean
  phoneNumber: string | undefined
  explanationRevisionUid: string
  activePartnerDeviceActorUids: string[]
  patientUid: string
  explanationType: ExplanationType
  partnerTrialHospitalUid: string | undefined
  onCloseModals: () => void
  mutateSession: () => void
}

type Step =
  | 'SelectSettingPinMethod'
  | 'SubmitSMSPinSetting'
  | 'ConfirmOnScreenPinSetting'
  | 'PassToPatient'
  | 'PinSettingOnScreen'
  | 'AfterPinSettingPhoneNumberSetting'
  | 'SubmitOnScreenRequestToPartnerDevice'
  | 'SubmitOnScreenRequestToPatient'

/** 説明ルームにおけるPIN設定の一連のモーダルをレンダリングする */
export const ExplanationRoomPinSettingModals: React.FC<Props> = ({
  isReset = false,
  isFinialCheck = false,
  phoneNumber,
  explanationRevisionUid,
  patientUid,
  explanationType,
  activePartnerDeviceActorUids,
  partnerTrialHospitalUid,
  onCloseModals,
  mutateSession,
}) => {
  const [step, setStep] = useState<Step>('SelectSettingPinMethod')

  const { request: requestPinSettingOnScreen } = useRequestPinSetting({
    onSuccess: () => {
      setStep('PassToPatient')
    },
  })

  const { selectedTrial } = useSelectedTrial()

  return (
    <>
      <SelectPinSettingMethodModal
        isOpen={step === 'SelectSettingPinMethod'}
        buttonColorScheme="green"
        isReset={isReset}
        isFinalCheck={isFinialCheck}
        onClose={onCloseModals}
        onSelectSMS={async () => {
          setStep('SubmitSMSPinSetting')
        }}
        onSelectOnScreen={() => {
          const nextModalKey =
            explanationType === 'RemotePartner'
              ? 'SubmitOnScreenRequestToPartnerDevice'
              : explanationType === 'InPerson'
                ? 'ConfirmOnScreenPinSetting'
                : 'SubmitOnScreenRequestToPatient'
          setStep(nextModalKey)
        }}
      />
      <SubmitSMSPinSettingModal
        isOpen={step === 'SubmitSMSPinSetting'}
        onCancel={() => setStep('SelectSettingPinMethod')}
        onSubmit={() => {
          onCloseModals()
          mutateSession()
        }}
        explanationRevisionUid={explanationRevisionUid}
        patientUid={patientUid}
        fetchedPhoneNumber={phoneNumber}
        buttonColorScheme="green"
      />

      {/* 対面OnScreenの場合はその場で設定可能 */}
      {explanationType === 'InPerson' && (
        <>
          <ConfirmOnScreenPinSettingModal
            isOpen={step === 'ConfirmOnScreenPinSetting'}
            buttonColorScheme="green"
            onClose={() => setStep('SelectSettingPinMethod')}
            onSubmit={async () => {
              await requestPinSettingOnScreen({
                explanationRevisionUid,
                method: 'OnScreen',
              })
            }}
          />
          <PassToPatientModal
            buttonColorScheme="green"
            isOpen={step === 'PassToPatient'}
            onConfirm={() => setStep('PinSettingOnScreen')}
          />
          <PinSettingModal
            isOpen={step === 'PinSettingOnScreen'}
            explanationRevisionUid={explanationRevisionUid}
            trialUid={selectedTrial.uid}
            onClose={onCloseModals}
            onConfirm={() => {
              if (!phoneNumber) {
                setStep('AfterPinSettingPhoneNumberSetting')
                return
              }
              mutateSession()
              onCloseModals()
            }}
          />

          <AfterPinSettingPhoneNumberSettingModal
            isOpen={step === 'AfterPinSettingPhoneNumberSetting'}
            onClose={() => {
              mutateSession()
              onCloseModals()
            }}
            patientUid={patientUid}
            buttonColorScheme="green"
          />
        </>
      )}

      {/* 遠隔/遠隔(パートナー)でOnScreenの場合は設定リクエストを送信 */}
      {!!partnerTrialHospitalUid && (
        <SubmitOnScreenRequestToPartnerDeviceModal
          isOpen={step === 'SubmitOnScreenRequestToPartnerDevice'}
          explanationRevisionUid={explanationRevisionUid}
          partnerTrialHospitalUid={partnerTrialHospitalUid}
          activePartnerDeviceActorUids={activePartnerDeviceActorUids}
          onClose={() => setStep('SelectSettingPinMethod')}
          onCompleted={onCloseModals}
        />
      )}
      <SubmitOnScreenRequestToPatientModal
        isOpen={step === 'SubmitOnScreenRequestToPatient'}
        explanationRevisionUid={explanationRevisionUid}
        patientUid={patientUid}
        onClose={() => setStep('SelectSettingPinMethod')}
        onCompleted={onCloseModals}
      />
    </>
  )
}

type SubmitOnScreenRequestToPartnerDeviceModalProps = {
  isOpen: boolean
  explanationRevisionUid: string
  activePartnerDeviceActorUids: string[]
  partnerTrialHospitalUid: string
  onClose: () => void
  onCompleted: () => void
}

const SubmitOnScreenRequestToPartnerDeviceModal: React.FC<
  SubmitOnScreenRequestToPartnerDeviceModalProps
> = ({
  isOpen,
  explanationRevisionUid,
  partnerTrialHospitalUid,
  activePartnerDeviceActorUids,
  onClose,
  onCompleted,
}) => {
  const [deviceActorUid, setDeviceActorUid] = useState<string | undefined>(
    activePartnerDeviceActorUids.length === 1
      ? activePartnerDeviceActorUids[0]
      : undefined,
  )

  const toast = useMirohaToast()

  const { request } = useRequestPinSetting({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '暗証番号設定依頼を送信しました',
      })
      onCompleted()
    },
  })

  const { send: sendPinSettingRequest } = useRequestPinSettingEvent()

  const handleSubmit = async () => {
    if (!deviceActorUid) return
    try {
      await sendPinSettingRequest({ targetActorUid: deviceActorUid })
      await request({
        method: 'OnScreen',
        explanationRevisionUid,
        deviceActorUid,
      })
    } catch (error) {
      toast({
        status: 'error',
        title: parseGqlError(error).message,
      })
    }
  }

  // 余分なメンバーを取得してしまうが、セッション開始前に取る口がないので仕方なし
  const { data: members } = useActiveMembersByTrialHospitalUids({
    trialHospitalUids: [partnerTrialHospitalUid],
  })

  if (!members) return null

  const activeMembers = members.filter(m =>
    activePartnerDeviceActorUids.includes(m.uid),
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>患者本人が操作画面上で設定</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Text>操作端末へ暗証番号の設定依頼を送信します。</Text>
            <Box>
              <FormLabel>操作端末</FormLabel>
              <Select
                isDisabled={activePartnerDeviceActorUids.length === 0}
                items={activeMembers.map(m => ({
                  label: (
                    <MemberLabel
                      role={m.role}
                      displayName={getFullName(m)}
                      isPartner
                    />
                  ),
                  value: m.uid,
                  filterValue: `${m.role} ${getFullName(m)}`,
                }))}
                value={deviceActorUid}
                onChange={setDeviceActorUid}
              />
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="戻る" />
          <Button
            colorScheme="green"
            isDisabled={!deviceActorUid}
            onClick={handleSubmit}
          >
            送信する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type SubmitOnScreenRequestToPatientModalProps = {
  isOpen: boolean
  explanationRevisionUid: string
  patientUid: string
  onClose: () => void
  onCompleted: () => void
}

const SubmitOnScreenRequestToPatientModal: React.FC<
  SubmitOnScreenRequestToPatientModalProps
> = ({ isOpen, explanationRevisionUid, patientUid, onClose, onCompleted }) => {
  const toast = useMirohaToast()

  const { request } = useRequestPinSetting({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '暗証番号設定依頼を送信しました',
      })
      onCompleted()
    },
  })

  const { send: sendPinSettingRequest } = useRequestPinSettingEvent()

  const handleSubmit = async () => {
    try {
      await sendPinSettingRequest({ targetActorUid: patientUid })
      await request({
        method: 'OnScreen',
        explanationRevisionUid,
        deviceActorUid: patientUid,
      })
    } catch (error) {
      toast({
        status: 'error',
        title: parseGqlError(error).message,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>患者本人が操作画面上で設定</ModalHeader>
        <ModalBody>
          <Text>操作端末へ暗証番号の設定依頼を送信します。</Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="戻る" />
          <Button
            colorScheme="green"
            onClick={async () => {
              await handleSubmit()
            }}
          >
            送信する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
