// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgArrowLeft = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M11.9.1c-.1-.1-.3-.1-.4-.1-.3 0-.5.1-.7.3l-7 7c-.4.4-.4 1 0 1.4l7 7c.2.2.4.3.7.3.1 0 .3 0 .4-.1.4-.2.6-.5.6-.9V1c0-.4-.2-.8-.6-.9Z"
    />
  </svg>
)
export default SvgArrowLeft
