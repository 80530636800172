import React, { useCallback, useEffect, useMemo, useState } from 'react'

import produce from 'immer'
import { useNavigate } from 'react-router-dom'

import { Agreement } from './agreement'
import { AgreementType } from './entity'
import { useAgreement } from './hooks/use-agreement'
import { routes } from '../../routes'

export type AgreementItem = {
  agreementType: AgreementType
  checked: boolean
  onChange: () => void
  enabled: boolean
  onEnable: () => void
  path: string
}

const pathByAgreementType: Record<AgreementType, string> = {
  privacyPolicy: routes.privacyPolicy,
  termsOfService: routes.termsOfService,
  externalTransmissionPolicy: routes.externalTransmissionPolicy,
}

export const AgreementContainer: React.FC = () => {
  const navigate = useNavigate()
  const { fetchWithCache, submit, requesting, errorMessage } = useAgreement()

  const [requiredAgreementTypes, setRequiredAgreementTypes] = useState<
    AgreementType[]
  >([])
  const [checked, setChecked] = useState(new Map<AgreementType, boolean>())
  const [enabled, setEnabled] = useState(new Map<AgreementType, boolean>())
  const [enabledSubmit, setEnabledSubmit] = useState(false)

  useEffect(() => {
    // すべてチェックされているか確認
    const allChecked = Array.from(checked.values()).every(e => e)
    setEnabledSubmit(allChecked && !requesting)
  }, [checked, requesting])

  useEffect(() => {
    if (requesting) return
    const get = async () => {
      const agreements = await fetchWithCache({ forceFetch: false })

      const agreementTypes = [
        agreements.agreeTermsOfService ? null : 'termsOfService',
        agreements.agreePrivacyPolicy ? null : 'privacyPolicy',
        agreements.agreeExternalTransmissionPolicy
          ? null
          : 'externalTransmissionPolicy',
      ].filter(e => e !== null) as AgreementType[]

      // すべて同意済みであれば、ログイン画面に遷移
      if (agreementTypes.length === 0) {
        navigate(routes.login)
      }

      // 必要なenabled, checkedを初期化
      agreementTypes.forEach(agreementType => {
        setChecked(prev =>
          produce(prev, draft => {
            draft.set(agreementType, false)
          }),
        )
        setEnabled(prev =>
          produce(prev, draft => {
            draft.set(agreementType, false)
          }),
        )
      })

      setRequiredAgreementTypes(agreementTypes)
    }
    get()
  }, [requesting, fetchWithCache, navigate])

  const agreementItems = useMemo<AgreementItem[]>(
    () =>
      requiredAgreementTypes.map(t => ({
        agreementType: t,
        checked: checked.get(t) || false,
        onChange: () => {
          setChecked(prev =>
            produce(prev, draft => {
              draft.set(t, !prev.get(t))
            }),
          )
        },
        enabled: enabled.get(t) || false,
        onEnable: () => {
          setEnabled(prev =>
            produce(prev, draft => {
              draft.set(t, true)
            }),
          )
        },
        path: pathByAgreementType[t],
      })),
    [checked, enabled, requiredAgreementTypes],
  )

  const onSubmit = useCallback(async () => {
    await submit({
      // チェックしたもののみ同意として送信
      agreeTermsOfService: checked.get('termsOfService'),
      agreePrivacyPolicy: checked.get('privacyPolicy'),
      agreeExternalTransmissionPolicy: checked.get(
        'externalTransmissionPolicy',
      ),
    })
  }, [checked, submit])

  return (
    <Agreement
      agreementItems={agreementItems}
      onSubmit={onSubmit}
      enabledSubmit={enabledSubmit}
      errorMessage={errorMessage || undefined}
    />
  )
}
