import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '@chakra-ui/react'

type Props = {
  isOpen: boolean
  buttonColorScheme?: 'blue' | 'green'
  onConfirm: () => void
}
export const PassToPatientModal: React.FC<Props> = ({
  isOpen,
  buttonColorScheme = 'blue',
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onConfirm} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>患者に端末を渡してください。</ModalHeader>
        <ModalFooter>
          <Button colorScheme={buttonColorScheme} onClick={onConfirm}>
            確認
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
