import { AspectRatio, Box, Flex } from '@chakra-ui/react'
import { MediaProvider, MediaProviderProps } from '@micin-jp/call-lib-react'

import { VideoCallRoomHeader } from '../VideoCallRoomHeader/VideoCallRoomHeader'
import { VideoCallRoomLocalPlayer } from '../VideoCallRoomLocalPlayer/VideoCallRoomLocalPlayer'
import { VideoCallRoomMainContent } from '../VideoCallRoomMainContent/VideoCallRoomMainContent'

type Props = {
  /** MediaProviderに渡すjwtToken */
  jwtToken: string | null
  /** ルームに参加するユーザーの名前・指定することで通話中のユーザーの名前を表示できる（optional） */
  participantName?: string
  /** room退出時に画面に表示するメッセージ（optional）
   *
   * @default "ビデオ通話を終了しました。"
   */
  endedText?: string
}

/**
 * ビデオ通話用の機能およびstyleを提供するコンポーネント
 * call-lib-reactが提供するインターフェイスに依存している
 */
export const VideoCallRoom: React.FC<Props> = ({
  jwtToken,
  participantName,
  endedText = 'ビデオ通話を終了しました。',
}) => {
  const isDCT = import.meta.env.VITE_APPLICATION_ENV === 'dct'
  const callregEnv: MediaProviderProps['env'] = isDCT ? 'prod' : 'stg'

  return (
    <MediaProvider token={jwtToken} env={callregEnv}>
      <Flex direction="column" h="100svh" pos="relative">
        <Box h="80px">
          <VideoCallRoomHeader />
        </Box>
        <Box flex="1" bg="gray.800">
          <VideoCallRoomMainContent
            participantName={participantName}
            endedText={endedText}
          />
        </Box>
        <AspectRatio
          ratio={16 / 9}
          pos="absolute"
          w={{ base: '192px', lg: '320px' }}
          right="8px"
          bottom="8px"
        >
          <VideoCallRoomLocalPlayer />
        </AspectRatio>
      </Flex>
    </MediaProvider>
  )
}
