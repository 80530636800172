import { readAsDataURL } from 'src/modules/util/read-as-data-url'

export const fileToBase64 = async (file: File) => {
  if (!file) return undefined
  try {
    return readAsDataURL(file)
  } catch {
    throw new Error('ファイルのエンコードに失敗しました')
  }
}
