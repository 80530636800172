import { HStack, IconButton, Spacer, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'
import { List } from 'src/components/icon'

type Props = {
  title?: String
  onClickMenu: () => void
}

export const SPExplanationRoomHeader: React.FC<Props> = ({
  title,
  onClickMenu,
}) => {
  return (
    <HStack
      w="full"
      bg="white"
      align="center"
      p="2"
      borderBottom="1px solid"
      bgColor="green.50"
      borderBottomColor="gray.100"
    >
      <img src={MirohaLogo} alt="miroha-logo" width="23px" height="16px" />
      {!!title && (
        <Text fontSize="14px" fontWeight="500" color="green.500">
          {title}
        </Text>
      )}
      <Spacer />
      <IconButton
        variant="customIconButtonGhost"
        colorScheme="gray"
        aria-label="説明ルーム情報"
        icon={<List />}
        onClick={onClickMenu}
      />
    </HStack>
  )
}
