import { createSelector } from 'reselect'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import { RootState } from 'src/modules/reducer'
import { getState as getRootState } from 'src/modules/selector'

import { State } from './redux'

export const getState = createSelector(
  getRootState,
  state => state.worksheetDetail,
)

export const getMediumItemAnswer = createSelector(
  (_state: RootState, props: string) => props,
  getState,
  (mediumItemAnswerUid: string, state) => {
    const { worksheet } = state
    if (!worksheet) return undefined

    return worksheet.mediumItemAnswers.find(
      mia => mia.uid === mediumItemAnswerUid,
    )
  },
)

export const getRootSmallItemAnswers = createSelector<
  RootState,
  { mediumItemAnswerUid: string },
  { mediumItemAnswerUid: string },
  State,
  SmallItemAnswer[]
>(
  (_state, props) => props,
  getState,
  ({ mediumItemAnswerUid }, state) => {
    // TODO: medium item answer も map で持つ？
    const { worksheet } = state
    if (!worksheet) return []

    const mediumItemAnswer = worksheet.mediumItemAnswers.find(
      mia => mia.uid === mediumItemAnswerUid,
    )
    if (!mediumItemAnswer) return []

    const smallItemAnswers = Object.values(mediumItemAnswer.smallItemAnswerMap)
      .filter(si => si.parentUid === null)
      .sort((a, b) => a.index - b.index)

    return smallItemAnswers
  },
)
