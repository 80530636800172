import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { white } from 'src/components/base/color/palette'
import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
} from 'src/components/base/table/table'
import { AnchorLink, ButtonLink } from 'src/components/base/text-link'
import { Select } from 'src/components/Select/Select'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import {
  IcfDocumentCheckRole,
  icfDocumentCheckRoles,
  IcfDocumentDetail,
  IcfDocumentRevision,
  icfDocumentTypeText,
  icfDocumentRevisionStatusText,
  icfDocumentRoleText,
  IcfDocumentSignerRole,
  icfDocumentSignerRoles,
  icfDocumentTypes,
} from '../entity'

type Props = {
  icfDocument: IcfDocumentDetail | undefined
  selectedRevision: IcfDocumentRevision | undefined
  selectedRevisionUid: string | undefined
  onChangeSelectedRevisionUid: (uid: string) => void
  filePreviewUrl: string
  onRedirectDocuSignPreview: ({
    dsTemplateId,
    role,
  }: {
    dsTemplateId: string
    role: IcfDocumentSignerRole | IcfDocumentCheckRole
  }) => void
}

type HistoryTableKey = keyof Pick<
  IcfDocumentRevision['histories'][number],
  'status' | 'operatedAt' | 'operatedMemberName' | 'comment'
>
const historyTableCellWidth: Record<HistoryTableKey, string> = {
  status: '20%',
  operatedAt: '30%',
  operatedMemberName: '20%',
  comment: '30%',
}

export const RevisionHistory: React.FC<Props> = ({
  icfDocument,
  selectedRevision,
  selectedRevisionUid,
  onChangeSelectedRevisionUid,
  filePreviewUrl,
  onRedirectDocuSignPreview,
}) => {
  if (!icfDocument || !selectedRevisionUid) {
    return null
  }

  return (
    <Wrapper>
      <DocumentInfo>
        <Row>
          <LabelContainer>
            <Text fontWeight="bold">文書ID</Text>
          </LabelContainer>
          <ValueContainer>
            <Text>{icfDocument.numberingId}</Text>
          </ValueContainer>
          <Spacer size={64} horizontal />
          <LabelContainer>
            <Text fontWeight="bold">版数</Text>
          </LabelContainer>
          <ValueContainer>
            <Select
              width={100}
              value={selectedRevisionUid}
              items={icfDocument.revisions.map(revision => ({
                label: revision.version,
                value: revision.uid,
              }))}
              onChange={onChangeSelectedRevisionUid}
            />
          </ValueContainer>
        </Row>
      </DocumentInfo>

      <Spacer size={32} />

      {selectedRevision !== undefined && (
        <RevisionInfo>
          <Row>
            <LabelContainer>
              <Text fontWeight="bold">文書名</Text>
            </LabelContainer>
            <ValueContainer>
              <Text>{selectedRevision.name}</Text>
            </ValueContainer>
          </Row>
          <Spacer size={24} />
          <Row>
            <LabelContainer>
              <Text fontWeight="bold">文書の種類</Text>
            </LabelContainer>
            <ValueContainer>
              <Text>{icfDocumentTypeText[selectedRevision.type]}</Text>
            </ValueContainer>
          </Row>
          {selectedRevision.type === icfDocumentTypes.AgreementForm && (
            <>
              <Spacer size={24} />
              <Row>
                <LabelContainer>
                  <Text fontWeight="bold">患者の同意</Text>
                </LabelContainer>
                <ValueContainer>
                  <Text>
                    {selectedRevision.isRequiredPatientSign
                      ? '必須'
                      : '必須ではない'}
                  </Text>
                </ValueContainer>
              </Row>
            </>
          )}
          <Spacer size={24} />
          <Row>
            <LabelContainer>
              <Text fontWeight="bold">ファイル名</Text>
            </LabelContainer>
            <ValueContainer>
              <AnchorLink
                href={filePreviewUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {selectedRevision.fileName}
              </AnchorLink>
            </ValueContainer>
          </Row>
          {selectedRevision.type === icfDocumentTypes.AgreementForm && (
            <>
              <Spacer size={24} />
              <Row>
                <LabelContainer>
                  <Text fontWeight="bold">署名ロール</Text>
                </LabelContainer>
                <ValueContainer>
                  <HStack>
                    {Object.values(icfDocumentSignerRoles).map(role => (
                      <RoleTextContainer key={role}>
                        <ButtonLink
                          disabled={
                            !selectedRevision.signerRoles.includes(role)
                          }
                          onClick={() =>
                            onRedirectDocuSignPreview({
                              dsTemplateId: selectedRevision.dsTemplateId,
                              role,
                            })
                          }
                        >
                          {icfDocumentRoleText[role]}
                        </ButtonLink>
                        <Spacer horizontal size={10} />
                      </RoleTextContainer>
                    ))}
                  </HStack>
                </ValueContainer>
              </Row>
            </>
          )}
          {selectedRevision.type === icfDocumentTypes.CheckUnderstanding && (
            <>
              <Spacer size={24} />
              <Row>
                <LabelContainer>
                  <Text fontWeight="bold">確認項目</Text>
                </LabelContainer>
                <ValueContainer>
                  <HStack>
                    {Object.values(icfDocumentCheckRoles).map(role => (
                      <RoleTextContainer key={role}>
                        <ButtonLink
                          disabled={!selectedRevision.checkRoles.includes(role)}
                          onClick={() =>
                            onRedirectDocuSignPreview({
                              dsTemplateId: selectedRevision.dsTemplateId,
                              role,
                            })
                          }
                        >
                          {icfDocumentRoleText[role]}
                        </ButtonLink>
                        <Spacer horizontal size={10} />
                      </RoleTextContainer>
                    ))}
                  </HStack>
                </ValueContainer>
              </Row>
            </>
          )}

          <Spacer size={32} />

          <Text fontWeight="bold">変更履歴</Text>
          <Spacer size={8} />
          <HistoryTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell width={historyTableCellWidth['status']}>
                    操作
                  </TableHeadCell>
                  <TableHeadCell width={historyTableCellWidth['operatedAt']}>
                    操作日時
                  </TableHeadCell>
                  <TableHeadCell
                    width={historyTableCellWidth['operatedMemberName']}
                  >
                    操作者
                  </TableHeadCell>
                  <TableHeadCell width={historyTableCellWidth['comment']}>
                    コメント
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRevision.histories
                  .sort(
                    (a, b) =>
                      new Date(b.operatedAt).getTime() -
                      new Date(a.operatedAt).getTime(),
                  )
                  .map(history => (
                    <TableRow key={history.uid} borderBottom>
                      <TableBodyCell width={historyTableCellWidth['status']}>
                        {icfDocumentRevisionStatusText[history.status]}
                      </TableBodyCell>
                      <TableBodyCell
                        width={historyTableCellWidth['operatedAt']}
                      >
                        {dayjs(history.operatedAt).format(
                          'YYYY/MM/DD HH:mm:ss',
                        )}
                      </TableBodyCell>
                      <TableBodyCell
                        width={historyTableCellWidth['operatedMemberName']}
                      >
                        {history.operatedMemberName}
                      </TableBodyCell>
                      <TableBodyCell width={historyTableCellWidth['comment']}>
                        {history.comment}
                      </TableBodyCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </HistoryTableContainer>
        </RevisionInfo>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const DocumentInfo = styled.section``

const RevisionInfo = styled.section``

const Row = styled.div`
  display: flex;
  align-items: center;
`

const LabelContainer = styled.div`
  width: 120px;
`

const ValueContainer = styled.div`
  flex: 1;
  word-break: break-all;
`

const HistoryTableContainer = styled.div`
  background-color: ${white};
  border-radius: 10px;
  height: 300px;
`

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const RoleTextContainer = styled.div`
  display: flex;
  justify-content: center;
`
