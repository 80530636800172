import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Questionnaire,
  QuestionnaireStatus,
} from 'src/modules/entities/questionnaire/entity'
import { Schedule } from 'src/modules/entities/schedule/entity'

export type State = {
  questionnaires: Pick<
    Questionnaire,
    'uid' | 'title' | 'status' | 'scheduleUids'
  >[]
  schedules: Schedule[]
}

const initialState: State = {
  questionnaires: [],
  schedules: [],
}

const slice = createSlice({
  name: 'questionnaireSchedule',
  initialState,
  reducers: {
    initQuestionnaireSchedule: (
      state: State,
      action: PayloadAction<{
        questionnaires: Pick<
          Questionnaire,
          'uid' | 'title' | 'status' | 'scheduleUids'
        >[]
        schedules: Schedule[]
      }>,
    ) => {
      const { questionnaires, schedules } = action.payload
      state.questionnaires = questionnaires
      state.schedules = schedules
    },
    updateSchedule: (state: State, action: PayloadAction<Schedule>) => {
      state.schedules = state.schedules.map(schedule => {
        if (schedule.uid === action.payload.uid) {
          return action.payload
        } else {
          return schedule
        }
      })
      state.schedules.sort((a, b) => a.startAfterDays - b.startAfterDays)
    },
    updateSchedules: (state: State, action: PayloadAction<Schedule>) => {
      const isExist = state.schedules.some(s => s.uid === action.payload.uid)
      if (isExist) {
        return
      } else {
        state.schedules.push(action.payload)
        state.schedules.sort((a, b) => a.startAfterDays - b.startAfterDays)
      }
    },
    deleteSchedule: (state: State, action: PayloadAction<string>) => {
      const scheduleUid = action.payload
      state.schedules = state.schedules.filter(schedule => {
        return schedule.uid !== scheduleUid
      })
    },
    releaseQuestionnaireSchedule: (state: State) => {
      state.questionnaires.map(q => (q.status = QuestionnaireStatus.Released))
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer
