import { useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Text,
  useDisclosure,
  Spacer,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import { AcceptReferredPatientButton_AcceptReferredPatientDocument } from '../../../../lib/gql-client'
import { useGqlMutation } from '../../../../lib/gql-client/request'

type Props = {
  referredPatientReferUid: string
  mutateReferredPatient: () => void
  referredPatientsPath: string
}

export const AcceptReferredPatientButton: React.FC<Props> = ({
  referredPatientReferUid,
  mutateReferredPatient,
  referredPatientsPath,
}) => {
  const {
    isOpen: isAcceptReferReferredPatientModalOpen,
    onOpen: onAcceptReferReferredPatientModalOpen,
    onClose: onAcceptReferReferredPatientModalClose,
  } = useDisclosure()

  const toast = useMirohaToast()

  const navigate = useNavigate()
  const [note, setNote] = useState<string | undefined>(undefined)

  const [errorMessage, setErrorMessage] = useState('')

  const { request: acceptRefer } = useGqlMutation(
    AcceptReferredPatientButton_AcceptReferredPatientDocument,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: '紹介患者を候補化しました',
        })
        onAcceptReferReferredPatientModalClose()
        mutateReferredPatient()
        navigate(referredPatientsPath)
      },
    },
  )

  return (
    <>
      <Button onClick={onAcceptReferReferredPatientModalOpen}>候補化</Button>

      <Modal
        isOpen={isAcceptReferReferredPatientModalOpen}
        onClose={() => {
          onAcceptReferReferredPatientModalClose()
          setNote(undefined)
        }}
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>紹介患者を候補化します。</ModalHeader>
          <ModalBody>
            <FormControl isInvalid={!!errorMessage}>
              <Text>
                以降の被験者候補との日程調整はパートナー施設の担当者へご連絡の上、実施してください。
              </Text>
              <Spacer p={2} />
              <Text>
                本操作による、候補化はパートナー施設にメールで通知されます。
              </Text>
              <Text>
                下記の追記事項をメール本文へ任意で添付することができます。
              </Text>
              <Spacer p={2} />
              <FormLabel>追記事項</FormLabel>
              <Textarea
                value={note}
                onChange={e => {
                  if (e.target.value.length > 500) {
                    setErrorMessage('500文字以内で入力してください。')
                  } else {
                    setErrorMessage('')
                  }
                  setNote(e.target.value)
                }}
                placeholder="パートナー施設に連絡事項等がある場合に記入してください。"
                minH="150px"
              />
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              isDisabled={!!errorMessage}
              onClick={async () => {
                await acceptRefer({
                  input: {
                    referredPatientReferUid: referredPatientReferUid,
                    note: !!note ? note : undefined,
                  },
                })
              }}
            >
              候補化する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
