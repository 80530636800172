import { useCallback, useEffect, useState } from 'react'

import { Blocker, useBlocker, useNavigate } from 'react-router-dom'

// ログアウト、治験変更は遷移確認なしで可能
const ignorePaths = ['/login', '/switch']

// ブラウザバック、react-routerによる遷移（navigate, Linkなど）-> useBlockerで制御
// リロード -> beforeunloadイベントで制御
export const useBlockTransition = ({
  message = '編集内容が保存されていない可能性があります。移動しますか？',
  showAlert,
}: {
  message?: string
  showAlert: boolean
}) => {
  // NOTE:
  // このフラグがないとブラウザバックをした場合にproceedしても遷移しない
  // proceedした場合に明示的にnavigateすることで遷移できる（ブラウザバック以外のLinkなどによる遷移の場合は指定せずに画面遷移する）が、
  // その際に再度blockerが発火してしまう
  // 2度アラートが出ることを防ぐためproceedした段階でcanceledをtrueにしてアラートを出さずに遷移できるようにする
  const [blockerCanceled, setBlockerCanceled] = useState(false)

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (ignorePaths.includes(nextLocation.pathname)) {
      return false
    }
    if (currentLocation.pathname === nextLocation.pathname) {
      return false
    }
    return showAlert && !blockerCanceled
  })

  const navigate = useNavigate()

  const handleBlocker = useCallback(
    (blocker: Blocker) => {
      if (blocker.state === 'proceeding') {
        navigate(blocker.location.pathname)
        return
      }

      if (blocker.state !== 'blocked') {
        return
      }
      if (!showAlert) {
        blocker.proceed()
        return
      }

      const accepted = window.confirm(message)
      if (!accepted) {
        blocker.reset()
        return
      }

      blocker.proceed()
      setBlockerCanceled(true)
    },
    [message, showAlert, navigate],
  )

  useEffect(() => {
    handleBlocker(blocker)
    return () => {
      blocker.reset?.()
    }
  }, [blocker, handleBlocker, showAlert])

  // リロード時の制御
  const handleBeforeunload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (!showAlert) return
      e.preventDefault()
      e.returnValue = message
    },
    [showAlert, message],
  )
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [handleBeforeunload])
}
