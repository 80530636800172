import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { UseMutationOption, useMutation } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const sendTestSMS = async ({
  trialUid,
  phoneNumber,
}: {
  trialUid: Trial['uid']
  phoneNumber: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_patients/test_sms',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
      },
      body: {
        mobileNumber: phoneNumber,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useSendTestSMS = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()
  return useMutation(
    ({ phoneNumber }: { phoneNumber: string }) =>
      sendTestSMS({
        trialUid: selectedTrial.uid,
        phoneNumber,
      }),
    option,
  )
}
