import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost, requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { CrfDownloadStatus, CrfDownloadJob } from './entity'
import { crfDownloadJobFixtures } from './fixture'

type CrfDownLoadJobListResponse = {
  uid: string
  registered_at: Date
  completed_at?: Date
  last_updated_by: string
  status: CrfDownloadStatus
}

const parse = (resp: CrfDownLoadJobListResponse): CrfDownloadJob => {
  return {
    uid: resp.uid,
    registeredAt: resp.registered_at,
    completedAt: resp.completed_at,
    lastUpdatedBy: resp.last_updated_by,
    status: resp.status,
  }
}

export const fetchList = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<CrfDownloadJob[]> => {
  if (IS_MOCK_MODE) {
    return crfDownloadJobFixtures
  }

  const resp = await requestGet<{ Jobs: CrfDownLoadJobListResponse[] }>({
    url: `${SERVER_URL}/trials/${trialUid}/crf/download_jobs`,
    params: {},
  })

  return resp.data.Jobs.map(parse)
}

export const createDownloadJob = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<CrfDownloadJob> => {
  if (IS_MOCK_MODE) {
    return crfDownloadJobFixtures[1]
  }

  const res = await requestPost<{ Job: CrfDownLoadJobListResponse }>({
    url: `${SERVER_URL}/trials/${trialUid}/crf/download_jobs`,
    params: {},
  })

  return parse(res.data.Job)
}

export const cancelDownloadJob = async ({
  trialUid,
  crfDownloadJobUid,
}: {
  trialUid: string
  crfDownloadJobUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPut<CrfDownLoadJobListResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/crf/download_jobs/${crfDownloadJobUid}/cancel`,
    params: {},
  })

  return {}
}
