import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  CreateFollowUpWorksheetsInput,
  GqlError,
  CreateFollowUpWorksheetsMutation,
  CreateFollowUpWorksheetsDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type CreateFollowUpWorksheetsReturn =
  CreateFollowUpWorksheetsMutation['createFollowUpWorksheets']

const createFollowUpWorksheets = async (
  input: CreateFollowUpWorksheetsInput,
): Promise<CreateFollowUpWorksheetsReturn> => {
  try {
    const res = await graphqlRequest(CreateFollowUpWorksheetsDocument, {
      input,
    })

    return res.createFollowUpWorksheets
  } catch (error) {
    throw error
  }
}

export const useCreateFollowUpWorksheets = (
  option?: UseMutationOption<CreateFollowUpWorksheetsReturn, GqlError>,
) => {
  return useMutation(
    (input: CreateFollowUpWorksheetsInput) => createFollowUpWorksheets(input),
    option,
  )
}
