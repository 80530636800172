import { Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  StaticScreen,
  StaticScreenContent,
  StaticScreenFooter,
  StaticScreenTitle,
} from 'src/components/StaticScreen/StaticScreen'

type Props = {
  nextUrl?: string
}
export const VideoCallAlreadyFinishedScreen: React.FC<Props> = ({
  nextUrl,
}) => {
  const navigate = useNavigate()
  return (
    <StaticScreen>
      <StaticScreenTitle>
        こちらのビデオ通話は既に終了しています
      </StaticScreenTitle>
      {nextUrl && (
        <>
          <StaticScreenContent>
            以下のボタンより新しいビデオ通話が開始できます。
          </StaticScreenContent>
          <StaticScreenFooter>
            <Button onClick={() => navigate(nextUrl)}>
              ビデオ通話を開始する
            </Button>
          </StaticScreenFooter>
        </>
      )}
    </StaticScreen>
  )
}
