import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { UseMutationOption, useMutation } from 'src/hooks/use-mutation'

import { CreateReAgreementExplanationInput, Explanation } from '../../types'
import { createExplanationRevision } from '../createExplanationRevision'

const createReAgreementExplanation = async ({
  trialUid,
  explanationUid,
  input,
}: {
  trialUid: Trial['uid']
  explanationUid: Explanation['uid']
  input: CreateReAgreementExplanationInput
}): Promise<void> => {
  try {
    const requests = [
      createExplanationRevision({
        trialUid,
        explanationUid,
        input: input,
      }),
    ]
    await Promise.all(requests)
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
    throw new Error('再同意の作成に失敗しました')
  }
}

export const useCreateReAgreementExplanation = (
  option?: UseMutationOption<void>,
) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    ({
      explanationUid,
      input,
    }: {
      explanationUid: Explanation['uid']
      input: CreateReAgreementExplanationInput
    }) =>
      createReAgreementExplanation({
        trialUid: selectedTrial.uid,
        explanationUid,
        input,
      }),
    option,
  )
}
