import { ExplanationPatientPinSettingStatus } from 'src/lib/gql-client'

export const hasAlreadyPinSet = (
  pinSettingStatus: ExplanationPatientPinSettingStatus,
) => {
  return (
    pinSettingStatus === 'Completed' ||
    pinSettingStatus === 'ReSetupRequested' ||
    pinSettingStatus === 'Locked'
  )
}
