import React, { useState } from 'react'

import { Button } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import { Template } from 'src/modules/entities/template/entity'

import { PreviewModal } from './modal'
import { templateIdParamName } from '../template-detail'

type Props = {
  template: Template
  templateFolderName: string
}

export const PreviewButton: React.FC<Props> = props => {
  const [modalOpen, setModalOpen] = useState(false)
  const { template, templateFolderName } = props
  const { templateUid = '' } = useParams<{ [templateIdParamName]: string }>()

  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: () => setModalOpen(false),
    deps: [templateUid],
  })

  return (
    <>
      <Button variant="outline" onClick={() => setModalOpen(true)}>
        プレビュー
      </Button>

      {modalOpen && (
        <PreviewModal
          onClose={() => setModalOpen(false)}
          template={template}
          templateFolderName={templateFolderName}
        />
      )}
    </>
  )
}
