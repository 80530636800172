import { HttpResponse } from 'msw'
import {
  createDisablePatientPayloadMock,
  DisablePatientDocument,
  DisablePatientMutation,
  DisablePatientMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const disablePatientHandler = graphQLHandlerFactory<
  DisablePatientMutation,
  DisablePatientMutationVariables
>('mutation', DisablePatientDocument, () => {
  return HttpResponse.json({
    data: { disablePatient: createDisablePatientPayloadMock() },
  })
})
