import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/modules/reducer'

import { Questionnaire } from './entity'
import { State } from './redux'
import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.questionnaire)

export const getList = createSelector(getState, state =>
  Object.values(state.byId),
)

export const getDetail = createSelector<
  RootState,
  string,
  string,
  State,
  Questionnaire
>(
  (_state, props) => props,
  getState,
  (uid, state) => state.byId[uid],
)
