import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

type Props = {
  onJoinRoom: () => void
}

export const ExplanationRoomDisconnect: React.FC<Props> = ({ onJoinRoom }) => {
  return (
    <Wrapper>
      <Text fontSize="2xl" fontWeight="bold" color="red.500">
        通信エラーのため、回線が切れました。 <br />
        再度通話開始ボタンを押してください
      </Text>
      <Spacer size={48} />
      <Button
        size="S"
        buttonType="notice"
        text="通話開始"
        onClick={onJoinRoom}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
