import { HttpResponse } from 'msw'
import {
  GetExplanationTrialHospitalsDocument,
  GetExplanationTrialHospitalsQuery,
  GetExplanationTrialHospitalsQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationTrialHospitalsHandler = graphQLHandlerFactory<
  GetExplanationTrialHospitalsQuery,
  GetExplanationTrialHospitalsQueryVariables
>('query', GetExplanationTrialHospitalsDocument, () => {
  return HttpResponse.json({
    data: {
      enabledTrialHospitals: [],
    },
  })
})
