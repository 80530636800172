import { QuestionnaireChoiceAnswer } from './entity'

export const fixtures: QuestionnaireChoiceAnswer[] = [
  {
    uid: 'qca-uid-1',
    questionnaireFieldAnswerUid: 'qfa-uid-1',
    questionnaireChoiceUid: 'qc-uid-1',
    description: '選択肢1',
    index: 0,
    checked: true,
  },
  {
    uid: 'qca-uid-2',
    questionnaireFieldAnswerUid: 'qfa-uid-1',
    questionnaireChoiceUid: 'qc-uid-2',
    description: '選択肢2',
    index: 0,
    checked: false,
  },
  {
    uid: 'qca-uid-3',
    questionnaireFieldAnswerUid: 'qfa-uid-1',
    questionnaireChoiceUid: 'qc-uid-3',
    description: '選択肢3',
    index: 0,
    checked: false,
  },
]
