import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const enableLogline = async ({
  trialUid,
  patientUid,
  worksheetUid,
  loglineUid,
  reason,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  loglineUid: string
  reason: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/loglines/${loglineUid}/enable`,
    params: {
      reason,
    },
  })

  return {}
}
