import { createSelector } from 'reselect'

import { TemplateFolder } from './entity'
import { State } from './redux'
import { RootState } from '../../reducer'
import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.templateFolder)

// Ordered by index asc
export const nonPresetSelector = createSelector(getState, state =>
  Object.values(state.byId)
    .filter(tf => !tf.isPreset)
    .sort((a, b) => {
      return a.index - b.index
    }),
)

export const presetSelector = createSelector(
  getState,
  state => Object.values(state.byId).filter(tf => tf.isPreset)[0],
)

export const getSingle = createSelector<
  RootState,
  string,
  string,
  State,
  TemplateFolder
>(
  (_state, props) => props,
  getState,
  (uid, state) => state.byId[uid],
)
