import { Auth } from 'aws-amplify'

export const initialize = () => {
  Auth.configure({
    region: import.meta.env.VITE_REGION,
    authenticationFlowType: import.meta.env.VITE_AUTHENTICATION_FLOW_TYPE,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      options: {
        AdvancedSecurityDataCollectionFlag: true,
      },
    },
  })
}
