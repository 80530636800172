import { MutableRefObject, useMemo } from 'react'

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Button,
  DrawerBody,
  VStack,
  DrawerCloseButton,
  Text,
  Input,
  Flex,
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Radio,
  RadioGroup,
  Box,
} from '@chakra-ui/react'
import {
  Filter,
  Arrange,
  Ascending,
  Descending,
  Search,
} from 'src/components/icon'
import { Select } from 'src/components/Select/Select'
import { usePermission } from 'src/features/auth/context'
import { useTrialHospitals } from 'src/features/trial/api'
import { useRelatedTrialHospitals } from 'src/features/trial/api/getRelatedTrialHospitals'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PatientsSortKey, PatientStatus, SortOrder } from 'src/lib/gql-client'
import { PERMISSIONS } from 'src/lib/permission'

import { useFilterPatients } from '../../hooks/useFilterPatients'
import { usePatientQueryParams } from '../../hooks/usePatientsQueryParams'
import { useSearchPatients } from '../../hooks/useSearchPatients'
import { useSortPatients } from '../../hooks/useSortPatients'

type Props = {
  isOpen: boolean
  onClose: () => void
}
export const PatientFilterDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { queryInputRef, resetQuery } = usePatientQueryParams()

  return (
    <Drawer size="sm" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader px="10" py="8">
          <Flex justify="space-between">
            <Text>検索/絞り込み</Text>
            <Button variant="text" onClick={resetQuery}>
              検索条件クリア
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody px="10" pb="8" pt="0">
          <VStack alignItems="start" spacing="2rem">
            <SearchQuerySection queryInputRef={queryInputRef} />
            <FilterSection />
            <SortSection />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const SearchQuerySection = ({
  queryInputRef,
}: {
  queryInputRef: MutableRefObject<HTMLInputElement | null>
}) => {
  const { searchQuery, handleChangeSearchQuery } = useSearchPatients()
  return (
    <Box w="full">
      <Flex alignItems="center">
        <Search />
        <Box width="0.5rem" />
        <Text fontWeight="bold" fontSize="1rem">
          フリーワード検索
        </Text>
      </Flex>
      <Input
        ref={queryInputRef}
        mt="2"
        placeholder="症例番号または氏名"
        bgColor="white"
        padding="0.5rem"
        defaultValue={searchQuery}
        onChange={handleChangeSearchQuery}
      />
    </Box>
  )
}

const statusCheckboxItems: {
  label: string
  value: PatientStatus
}[] = [
  { label: '有効', value: 'Enable' },
  { label: '無効', value: 'Disable' },
]

const UNSELECTED_KEY = 'OnlyNotSet'

const FilterSection = () => {
  const {
    handleCheckStatus,
    statuses,
    trialHospitalUid,
    relatedHospitalUid,
    relatedHospitalType,
    handleChangeHospital,
    handleChangeRelatedHospital,
  } = useFilterPatients()

  const { hasPermission } = usePermission()

  const canFilterWithHospital = hasPermission(PERMISSIONS.Hospital_BelongAll)

  const { data: enabledTrialHospitals } = useTrialHospitals({
    shouldCancel: !canFilterWithHospital,
  })

  const { data: relatedTrialHospitals } = useRelatedTrialHospitals({})

  const relatedTrialHospitalOptions = useMemo(() => {
    if (!relatedTrialHospitals) return []

    const options = [
      ...relatedTrialHospitals.map(h => ({
        label: h.name,
        value: h.uid,
      })),
    ]

    return [
      ...options,
      {
        label: '(未指定)',
        value: UNSELECTED_KEY,
      },
    ]
  }, [relatedTrialHospitals])

  return (
    <Box w="full">
      <Flex alignItems="center">
        <Filter />
        <Box width="0.5rem" />
        <Text fontWeight="bold" fontSize="1rem">
          絞り込み
        </Text>
      </Flex>
      <VStack p="0.5rem 1.5rem" alignItems="start" spacing="2rem">
        <Box>
          <Text fontSize="1rem">ステータス</Text>
          <Grid
            pt="1rem"
            rowGap="1rem"
            columnGap="2.5rem"
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(2, 1fr)"
          >
            {statusCheckboxItems.map(({ label, value }) => (
              <GridItem key={value}>
                <Flex>
                  <Checkbox
                    bgColor="white"
                    onChange={() => handleCheckStatus(value)}
                    isChecked={statuses.includes(value)}
                  >
                    {label}
                  </Checkbox>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Box>
        {canFilterWithHospital && (
          <Box w="full">
            <Text fontSize="1rem">医療機関</Text>
            <Box height="0.5rem" />
            <Select
              isClearable
              items={(enabledTrialHospitals ?? []).map(h => ({
                label: h.name,
                value: h.uid,
              }))}
              value={trialHospitalUid}
              onChange={handleChangeHospital}
            />
          </Box>
        )}
        <Box w="full">
          <Text fontSize="1rem">関連施設</Text>
          <Box height="0.5rem" />
          <Select
            isClearable
            items={relatedTrialHospitalOptions}
            value={
              relatedHospitalType === UNSELECTED_KEY
                ? relatedHospitalType
                : relatedHospitalUid
            }
            onChange={value => {
              handleChangeRelatedHospital(value)
            }}
          />
        </Box>
      </VStack>
    </Box>
  )
}

const SortSection = () => {
  const { sortKey, sortOrder, handleChangeSortKey, handleChangeSortOrder } =
    useSortPatients()

  const { hasPermission } = usePermission()

  const patientSortFields: {
    label: string
    value: PatientsSortKey
    visible: boolean
  }[] = [
    { label: '症例番号', value: 'DiseaseUID', visible: true },
    {
      label: '医療機関',
      value: 'HospitalName',
      visible: hasPermission(PERMISSIONS.Hospital_BelongAll),
    },
    {
      label: '氏名',
      value: 'Name',
      visible: hasPermission(PERMISSIONS.Patient_ReadDetail),
    },
  ]

  return (
    <Box width="100%">
      <Flex alignItems="center">
        <Arrange />
        <Box width="2" />
        <Text fontWeight="bold" fontSize="1rem">
          並べ替え
        </Text>
      </Flex>
      <Box height="2" />
      <Flex alignItems="center">
        <Select
          width="100%"
          items={patientSortFields
            .filter(field => field.visible)
            .map(field => ({
              label: field.label,
              value: field.value,
            }))}
          value={sortKey}
          onChange={handleChangeSortKey}
        />
        <RadioGroup
          pl={8}
          color={colors.blue[600]}
          value={sortOrder}
          onChange={val => handleChangeSortOrder(val as SortOrder)}
        >
          <Box display="flex" alignItems="center">
            <Radio value="Asc">
              <HStack width="52px">
                <Text color="gray.800">昇順</Text>
                <Ascending color={colors.gray[800]} />
              </HStack>
            </Radio>
            <Radio pl="6" value="Desc">
              <HStack width="52px">
                <Text color="gray.800">降順</Text>
                <Descending color={colors.gray[800]} />
              </HStack>
            </Radio>
          </Box>
        </RadioGroup>
      </Flex>
    </Box>
  )
}
