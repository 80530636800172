import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

import { ChallengeSignAuthV2Res } from '.'

export const challengeSignAuthV2Handler = restHandlerFactory(
  '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/challenge_v2',
  'post',
  () => HttpResponse.json(challengeSignAuthV2ResMock, { status: 200 }),
)

export const challengeSignAuthV2ResMock: ChallengeSignAuthV2Res = {
  trialName: 'trialName',
  documentName: 'documentName',
  explanationDocRevisionUid: 'expDocRevisionUid',
  explanationSessionUid: 'expSessionUid',
  explanationRevisionUid: 'expRevisionUid',
  result: 'RequiredPatientPinAuthentication',
  signActorUid: 'patientUid',
}
