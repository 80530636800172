import { useState } from 'react'

import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import useSWR from 'swr'

type Param = {
  roomUid: string
  participantUid: string
}

const fetcher = async (param: Param) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/video_call_rooms/jwt_token`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      roomUid: param.roomUid,
      participantUid: param.participantUid,
    }),
  })
  const body: { token: string } = await response.json()
  return body
}

export const useVideoCallJwtToken = (param: Param | null) => {
  const [jwtToken, setJwtToken] = useState<string | null>(null)

  const toast = useMirohaToast()

  useSWR(param, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onSuccess: data => {
      setJwtToken(data.token)
    },
    onError: () => {
      toast({
        status: 'error',
        title: '問題が発生しました',
      })
    },
  })

  return { jwtToken }
}
