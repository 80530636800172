import React from 'react'

import { Box, Button, Center, Flex, Stack, Text } from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { ExplanationRevisionAbout } from 'src/features/explanation/types'
import { HEADER_HEIGHT } from 'src/features/misc/components'
import { getFullName } from 'src/utils/getFullName'

import { ExplanationRoomHeader } from '../ExplanationRoomHeader/ExplanationRoomHeader'

type Props = {
  children: React.ReactNode
  about: ExplanationRevisionAbout
  canMoveToRoom: boolean
  onMoveToRoom: () => void
}

export const PatientLobbyLayout: React.FC<Props> = ({
  children,
  about,
  canMoveToRoom,
  onMoveToRoom,
}) => {
  return (
    <Box h="full">
      <Flex width="100%" height="100%" direction="column">
        <ExplanationRoomHeader
          featureChannel={about.featureChannel}
          headerTitle="説明ロビー"
          height={HEADER_HEIGHT}
        />
        <Flex height={`calc(100% - ${HEADER_HEIGHT}px)`}>
          <Box as="main" flex="1" overflowY="auto" h="full" bg="green.50">
            {children}
          </Box>
          <Box
            w={{ base: '240px', lg: '360px' }}
            p="6"
            bg="white"
            height="full"
            overflow="auto"
          >
            <Sidebar
              about={about}
              canMoveToRoom={canMoveToRoom}
              onMoveToRoom={onMoveToRoom}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

type SidebarProps = {
  about: ExplanationRevisionAbout
  canMoveToRoom: boolean
  onMoveToRoom: () => void
}

const Sidebar: React.FC<SidebarProps> = ({
  about,
  onMoveToRoom,
  canMoveToRoom,
}) => {
  const { trialName, candidateID, hospitalName, members } = about
  return (
    <Flex w="full" h="full" direction="column" justify="space-between">
      <Stack spacing="8">
        <Text fontSize="xl" fontWeight="bold">
          {trialName}
        </Text>
        {candidateID && (
          <Stack spacing="2">
            <Text fontSize="xl" fontWeight="bold">
              候補ID
            </Text>
            <Text>{candidateID}</Text>
          </Stack>
        )}
        <Box>
          <Text fontSize="xl" fontWeight="bold">
            担当者
          </Text>
          <Box mt="2">
            <Text fontWeight="bold" as="h3">
              {hospitalName}
            </Text>
            <Stack mt="2" spacing="1" ml="6">
              {members
                .sort((a, b) => a.memberIndex - b.memberIndex)
                .map(m => (
                  <MemberLabel
                    key={m.memberIndex}
                    role={m.roleName as 'Dr' | 'CRC'}
                    displayName={getFullName(m)}
                  />
                ))}
            </Stack>
          </Box>
        </Box>
      </Stack>

      <Center>
        <Button
          colorScheme="green"
          onClick={onMoveToRoom}
          isDisabled={!canMoveToRoom}
        >
          説明ルームへ
        </Button>
      </Center>
    </Flex>
  )
}
