import React from 'react'

import { Text } from '@chakra-ui/react'
import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'
import styled from 'styled-components'

type Props = {
  videoStreamUrl?: string
  errorMessage?: string
}

export const VideoViewer: React.FC<Props> = ({
  videoStreamUrl,
  errorMessage,
}) => {
  if (errorMessage) {
    return (
      <ErrorMessageContainer>
        <Text fontSize="sm">{errorMessage}</Text>
      </ErrorMessageContainer>
    )
  }
  if (!videoStreamUrl) {
    return null
  }

  return <HlsVideoPlayer url={videoStreamUrl} />
}

const ErrorMessageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
