import { challengePatientVideoCallHandler } from '../challengePatientVideoCall/mock'
import { disablePatientHandler } from '../disablePatient/mock'
import { enablePatientHandler } from '../enablePatient/mock'
import { getPatientHandler } from '../getPatient/mock'
import { getPatientVideoCallsForHistoryHandler } from '../getPatientVideoCallsForHistory/mock'
import { patientVideoCallSignInHandler } from '../patientVideoCallSignIn/mock'
import { sendTestEmailHandler } from '../sendTestEmail/mock'
import { sendTestSMSHandler } from '../sendTestSMS/mock'

export const patientHandlers = [
  disablePatientHandler(),
  enablePatientHandler(),
  getPatientHandler(),
  challengePatientVideoCallHandler(),
  getPatientVideoCallsForHistoryHandler(),
  patientVideoCallSignInHandler(),
  sendTestEmailHandler(),
  sendTestSMSHandler(),
]
