import { useCallback, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { RootState } from 'src/modules/reducer'

import { actions } from './redux'
import { Choice } from '../../../../../../entities/choice/entity'
import { MediumItem } from '../../../../../../entities/medium-item/entity'
import { SmallItem } from '../../../../../../entities/small-item/entity'

export const useEditCard = (index: number) => {
  const dispatch = useDispatch()
  const mediumItems = useSelector(
    (state: RootState) => state.templateDetail.mediumItems,
  )

  const selectedTrial = useSelector(getSelectedTrial)!
  const v2 = selectedTrial.featureFlags.eSourceV2

  const onAdd = useCallback(
    () =>
      dispatch(
        actions.addSmallItem({
          mediumItemIndex: index,
          v2,
        }),
      ),
    [dispatch, index, v2],
  )

  const canUpMediumItem = useMemo(() => {
    return !!mediumItems.find(mi => mediumItems.indexOf(mi) < index)
  }, [index, mediumItems])

  const canDownMediumItem = useMemo(() => {
    return !!mediumItems.find(mi => mediumItems.indexOf(mi) > index)
  }, [index, mediumItems])

  const onUpMediumItem = useCallback(() => {
    dispatch(
      actions.changeIndexMediumItem({
        index,
        direction: 'up',
      }),
    )
  }, [dispatch, index])

  const onDownMediumItem = useCallback(() => {
    dispatch(
      actions.changeIndexMediumItem({
        index,
        direction: 'down',
      }),
    )
  }, [dispatch, index])

  const onChangeMediumItem = useCallback(
    (values: Partial<MediumItem>) => {
      dispatch(
        actions.updateMediumItem({
          index,
          values,
        }),
      )
    },
    [dispatch, index],
  )

  const onDeleteMediumItem = useCallback(() => {
    dispatch(
      actions.deleteMediumItem({
        index,
      }),
    )
  }, [dispatch, index])

  const onChangeSmallItem = useCallback(
    ({
      smallItemUid,
      values,
    }: {
      smallItemUid: string
      values: Partial<SmallItem>
    }) => {
      dispatch(
        actions.updateSmallItem({
          mediumItemIndex: index,
          uid: smallItemUid,
          values,
        }),
      )
    },
    [dispatch, index],
  )

  const canChangeSmallItemIndex = useCallback(
    ({ smallItemUid }: { smallItemUid: string }) => {
      const mediumItem = mediumItems[index]
      const rootSmallItems = Object.values(mediumItem.smallItemMap).filter(
        si => si.parentUid === null,
      )
      const smallItem = mediumItem.smallItemMap[smallItemUid]

      return {
        canUp: rootSmallItems.some(si => si.index < smallItem.index),
        canDown: rootSmallItems.some(si => si.index > smallItem.index),
      }
    },
    [index, mediumItems],
  )

  const onChangeSmallItemIndex = useCallback(
    ({
      smallItemUid,
      direction,
    }: {
      smallItemUid: string
      direction: 'up' | 'down'
    }) => {
      dispatch(
        actions.changeIndexSmallItem({
          mediumItemIndex: index,
          uid: smallItemUid,
          direction,
        }),
      )
    },
    [dispatch, index],
  )

  const onRemoveSmallItem = useCallback(
    ({ smallItemUid }: { smallItemUid: string }) => {
      dispatch(
        actions.deleteSmallItem({ mediumItemIndex: index, uid: smallItemUid }),
      )
    },
    [dispatch, index],
  )

  const onAddChoice = useCallback(
    ({ smallItemUid }: { smallItemUid: string }) => {
      dispatch(actions.addChoice({ mediumItemIndex: index, smallItemUid }))
    },
    [dispatch, index],
  )

  const onChangeChoice = useCallback(
    ({
      smallItemUid,
      choiceIndex,
      values,
    }: {
      smallItemUid: string
      choiceIndex: number
      values: Partial<Choice>
    }) => {
      dispatch(
        actions.updateChoice({
          mediumItemIndex: index,
          smallItemUid,
          choiceIndex,
          values,
        }),
      )
    },
    [dispatch, index],
  )

  const onRemoveChoice = useCallback(
    ({
      smallItemUid,
      choiceIndex,
    }: {
      smallItemUid: string
      choiceIndex: number
    }) => {
      dispatch(
        actions.deleteChoice({
          mediumItemIndex: index,
          smallItemUid,
          choiceIndex,
        }),
      )
    },
    [dispatch, index],
  )

  const onAddSmallItemToChoice = useCallback(
    ({
      smallItemUid,
      choiceIndex,
    }: {
      smallItemUid: string
      choiceIndex: number
    }) => {
      dispatch(
        actions.addSmallItemToChoice({
          mediumItemIndex: index,
          smallItemUid,
          choiceIndex,
          v2,
        }),
      )
    },
    [dispatch, index, v2],
  )

  return {
    onAdd,
    canUpMediumItem,
    canDownMediumItem,
    onUpMediumItem,
    onDownMediumItem,
    onChangeMediumItem,
    onDeleteMediumItem,
    onChangeSmallItem,
    canChangeSmallItemIndex,
    onChangeSmallItemIndex,
    onRemoveSmallItem,
    onAddChoice,
    onChangeChoice,
    onRemoveChoice,
    onAddSmallItemToChoice,
  }
}
