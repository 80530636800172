import {
  Input,
  Stack,
  Button,
  Box,
  Flex,
  FormLabel,
  FormControl,
  HStack,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react'
import { useAtomValue, useSetAtom, useAtom } from 'jotai'
import { Add } from 'src/components/icon'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import {
  schemaStructureAtom,
  appendFieldAtom,
  schemaNameAtom,
  fieldErrorFamily,
} from 'src/lib/chicken-schema/atom'
import { emptySection } from 'src/lib/chicken-schema/utils'
import { CriteriaStatus } from 'src/lib/gql-client'

import { CriteriaStatusBadge } from '../CriteriaStatusBadge/CriteriaStatusBadge'
import { FieldForm } from '../FieldForm/FieldForm'
import { SectionForm } from '../SectionForm/SectionForm'
import { SectionNavigation } from '../SectionNavigation/SectionNavigation'

type Props = {
  status: CriteriaStatus
}

export const SchemaForm: React.FC<Props> = ({ status }) => {
  const schemaStructure = useAtomValue(schemaStructureAtom)
  const appendField = useSetAtom(appendFieldAtom)
  const [schemaName, setSchemaName] = useAtom(schemaNameAtom)

  const rootErrors = useAtomValue(fieldErrorFamily('root'))
  const fieldsError = rootErrors['fields']?.map(e => e.message).join('')

  const isDisabled = status === 'Disabled'

  return (
    <Box>
      <Flex gap={2} align="center">
        <FormControl
          isRequired
          isDisabled={isDisabled}
          isInvalid={!!rootErrors['name']}
        >
          <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
            タイトル
          </FormLabel>
          <HStack spacing="2">
            <Input
              aria-label="title"
              maxW="480px"
              value={schemaName}
              onChange={e => {
                setSchemaName(e.target.value)
              }}
              isDisabled={isDisabled}
            />
            <CriteriaStatusBadge status={status} />
          </HStack>
          <FormErrorMessage>
            {rootErrors['name']?.map(e => e.message).join('')}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Box mt="6">
        <SectionNavigation sortable />
      </Box>
      {schemaStructure.fields.map(section => (
        <Stack key={section.fid} spacing="2" id={section.fid}>
          <SectionForm fid={section.fid} isDisabled={isDisabled} />
          <Box pl="8">
            <Stack spacing="2">
              {section.fields?.map(field => (
                <FieldForm
                  key={field.fid}
                  fid={field.fid}
                  isDisabled={isDisabled}
                />
              ))}
            </Stack>
            <Box
              mt="2"
              w="full"
              border="1px solid"
              borderColor="gray.100"
              borderRadius="sm"
              px="5"
              py="2"
            >
              <Button
                variant="text"
                leftIcon={<Add />}
                onClick={() => {
                  appendField({ parentSectionFid: section.fid })
                }}
                isDisabled={isDisabled}
              >
                フィールド追加
              </Button>
            </Box>
          </Box>
        </Stack>
      ))}
      <Box
        mt="2"
        bg="blue.50"
        w="full"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="sm"
        px="5"
        py="2"
      >
        <Button
          variant="text"
          leftIcon={<Add />}
          onClick={() => {
            appendField({
              parentSectionFid: null,
              defaultValue: emptySection(),
            })
          }}
          isDisabled={isDisabled}
        >
          セクション追加
        </Button>
      </Box>
      {fieldsError && (
        <Text fontSize="sm" mt="1" color="red.500">
          {fieldsError}
        </Text>
      )}
    </Box>
  )
}
