import { useCallback, useMemo, useState } from 'react'

export type ExplanationWithdrawalField = {
  comment: string
  withdrawAt: Date
  file?: File
  isFileChange: boolean
}

export const useExplanationWithdrawForm = ({
  defaultValue,
}: {
  defaultValue?: ExplanationWithdrawalField
}) => {
  const now = new Date()

  const [field, setField] = useState<ExplanationWithdrawalField>({
    comment: '',
    withdrawAt: defaultValue !== undefined ? defaultValue.withdrawAt : now,
    file: defaultValue?.file,
    isFileChange: defaultValue?.isFileChange ?? false,
  })

  const onChangeWithdrawnAt = useCallback(
    (date: Date) => {
      setField(item => ({
        ...item,
        withdrawAt: date,
      }))
    },
    [setField],
  )

  const onCommentChange = useCallback(
    (comment: string) => {
      setField(item => ({ ...item, comment }))
    },
    [setField],
  )

  const onFileChange = useCallback(
    (file: File) => {
      setField(item => ({ ...item, file, isFileChange: true }))
    },
    [setField],
  )

  const onFileRemove = useCallback(() => {
    setField(item => ({ ...item, file: undefined, isFileChange: true }))
  }, [setField])

  const isValid = useMemo(() => {
    return field.comment !== ''
  }, [field])

  return {
    field,
    onChangeWithdrawnAt,
    onCommentChange,
    onFileChange,
    onFileRemove,
    isValid,
  }
}
