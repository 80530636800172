import React from 'react'

import { Checkbox, HStack } from '@chakra-ui/react'
import { Role } from 'src/modules/entities/member/entity'
import { roleToText } from 'src/modules/text'

type Props = {
  checkedItems: Role[]
  onChange: (role: Role) => void
  disabled: boolean
}

const items: Role[] = [Role.Dr, Role.CRC]

export const RoleCheckbox: React.FC<Props> = props => {
  const { onChange, checkedItems, disabled } = props

  return (
    <HStack spacing="4">
      {items.map(item => (
        <CheckboxItem
          key={item}
          item={item}
          checked={checkedItems.includes(item)}
          onChange={() => onChange(item)}
          disabled={disabled}
        />
      ))}
    </HStack>
  )
}

type CheckboxItemProps = {
  item: Role
  checked: boolean
  onChange: () => void
  disabled: boolean
}

const CheckboxItem: React.FC<CheckboxItemProps> = checkboxItemProps => {
  const { item, onChange, checked, disabled } = checkboxItemProps

  return (
    <Checkbox onChange={onChange} isChecked={checked} isDisabled={disabled}>
      {roleToText(item)}
    </Checkbox>
  )
}
