import { TrialHospital } from 'src/features/trial/types'

import { IcfDocumentSet } from '../types'

export const icfDocumentCacheKey = {
  getIcfDocumentSets: (trialHospitalUid: TrialHospital['uid']) =>
    [trialHospitalUid, 'icfDocumentSets'].join(),
  getIcfDocumentSet: (
    trialHospitalUid: TrialHospital['uid'],
    icfDocumentSetUid: IcfDocumentSet['uid'],
  ) => [trialHospitalUid, icfDocumentSetUid, 'icfDocumentSet'].join(),
}
