// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgSubject = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill={props.color ?? 'currentColor'} clipPath="url(#subject_svg__a)">
      <path d="M10.312 5.816V5.72c0-1.432-.52-2.312-1.944-2.528-.784-.12-1.592.44-1.592.44S5.544 3.8 5.688 5.72v.096c-.2.152-.32.368-.288.728.04.432.28.872.56.904.32.944.952 1.744 2.04 1.744s1.712-.792 2.04-1.736c.28-.024.52-.472.56-.904.032-.36-.088-.568-.288-.728v-.008ZM12.528 11.368c-.152-1.176-1.184-2.064-2.408-2.064l-1.592 1.4a.803.803 0 0 1-1.056 0l-1.584-1.4c-1.232 0-2.264.888-2.416 2.072l-.272 2.08c-.024.16.04.304.168.408.44.336 1.864 1.04 4.624 1.04s4.08-.72 4.6-1.048a.443.443 0 0 0 .2-.424l-.264-2.064ZM4.648 5.288c.024-1.576.848-2.344 1.552-2.648-.264-.552-.752-.896-1.552-1.024-.728-.112-1.48.408-1.48.408s-1.144.16-1.008 1.936v.088c-.184.144-.296.336-.264.672.032.4.256.808.512.84.296.872.88 1.608 1.888 1.608.088 0 .176-.016.256-.024a2.51 2.51 0 0 1-.16-.664c-.04-.448.048-.848.248-1.192h.008ZM4.496 7.864c-.088 0-.856.024-1.232-.224h-.76C1.608 7.832.952 8.32.48 8.976c-.128.184-.344 1.56-.48 2.376 0 0 .336.768 2.12 1.144l.16-1.256c.192-1.472 1.288-2.624 2.688-2.984a1.893 1.893 0 0 1-.328-.384h-.136l-.008-.008ZM15.52 8.968a3.228 3.228 0 0 0-2.024-1.336h-.76c-.376.248-1.144.232-1.232.224h-.136c-.096.144-.2.272-.328.376 1.4.36 2.496 1.512 2.688 2.976l.16 1.264C15.664 12.096 16 11.328 16 11.328c-.136-.816-.352-2.192-.48-2.376v.016ZM11.352 5.288c-.024-1.576-.848-2.344-1.552-2.648.264-.552.752-.896 1.552-1.024.728-.112 1.48.408 1.48.408s1.144.16 1.008 1.936v.088c.184.144.296.336.264.672-.032.4-.256.808-.512.84-.296.872-.88 1.608-1.888 1.608-.088 0-.176-.016-.256-.024a2.51 2.51 0 0 0 .16-.664 1.975 1.975 0 0 0-.248-1.192h-.008Z" />
    </g>
    <defs>
      <clipPath id="subject_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgSubject
