import { ComponentStyleConfig } from '@chakra-ui/react'

export const Alert: ComponentStyleConfig = {
  baseStyle: ({ colorScheme: c }) => ({
    container: {
      alignItems: 'flex-start',
      borderRadius: '8px',
      border: '2px solid',
      borderColor: `${c}.500`,
      p: 4,
    },
    icon: {
      marginEnd: 2,
    },
  }),
  variants: {
    subtle: ({ colorScheme: c }) => ({
      container: {
        bg:
          // NOTE:
          // warning（colorsScheme=orange）の場合は背景色をyellowにする。
          // インフォメッセージは100だとコントラストが低く文字が見えづらいので50を適用する。
          c === 'orange' ? 'yellow.100' : c === 'blue' ? 'blue.50' : `${c}.100`,
      },
    }),
  },
}
