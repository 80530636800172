import React, { useMemo, useState } from 'react'

import _ from 'lodash'
import styled from 'styled-components'

import { SingleCheckboxWithValue } from './single-checkbox'

type Props = {
  items: CheckboxItem[]
  className?: string
  disabled?: boolean
  onChange: (value: string) => void
  warning?: boolean
}

export type CheckboxItem = {
  value: string | number
  name: string
  checked: boolean
}

const Component: React.FC<Props> = props => {
  const [name] = useState(_.uniqueId('name-'))
  const allUnchecked = props.items.every(item => !item.checked)

  const className = useMemo(() => {
    if (props.disabled) {
      return props.className + ' disabled'
    }

    return props.className
  }, [props.className, props.disabled])

  return (
    <div className={className}>
      {props.items.map(item => {
        const id = _.uniqueId('checkbox-')
        return (
          <div className="item" key={id}>
            <SingleCheckboxWithValue
              name={name}
              label={item.name}
              value={item.value}
              onChange={() => props.onChange(item.value as string)}
              checked={item.checked}
              disabled={props.disabled}
              warning={props.warning && allUnchecked}
            ></SingleCheckboxWithValue>
          </div>
        )
      })}
    </div>
  )
}

/**
 * @deprecated Use chakra-ui Checkbox instead.
 */
export const Checkbox = styled(Component)`
  margin: 0 0 6px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  word-break: break-word;
`
