// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgMenuOpen = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8ZM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M9 12c-.3 0-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4l3-3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L7.4 8l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3Z"
    />
  </svg>
)
export default SvgMenuOpen
