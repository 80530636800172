import React, { useState, useEffect, useCallback } from 'react'

import dayjs from 'dayjs'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spacer } from 'src/components/spacer/spacer'
import { Spinner } from 'src/components/spinner/spinner'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'

import { VideoCallImage } from './entity'
import { fetchList, fetchSingle } from './request'
import { patientIdParamName } from '../../../../../patient-detail'
import { worksheetIdParamName } from '../../worksheet-detail'

type Props = {
  onClose: () => void
}

export const CaptureModalContainer: React.FC<Props> = props => {
  const [selectedUid, setSelectedUid] = useState('')

  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  const { requesting, errorMessage, videoCallImages } = useFetchList({
    trialUid,
    patientUid,
    worksheetUid,
  })

  useEffect(() => {
    if (!videoCallImages.length) {
      return
    }
    setSelectedUid(videoCallImages[0].uid)
  }, [videoCallImages])

  const { request: fetchSingle, fetched: selectedImageWithUrl } =
    useFetchSingle()

  useEffect(() => {
    const request = async () => {
      if (!selectedUid) {
        return
      }

      const videoCallImage = videoCallImages.find(i => i.uid === selectedUid)

      if (!videoCallImage) {
        console.warn('selected video call image does not exist: ', selectedUid)
        return
      }

      fetchSingle({
        trialUid,
        patientUid,
        worksheetUid,
        videoCallUid: videoCallImage.videoCallUid!,
        videoCallImageUid: videoCallImage.uid,
      })
    }

    request()
  }, [
    fetchSingle,
    patientUid,
    selectedUid,
    trialUid,
    videoCallImages,
    worksheetUid,
  ])

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title="ビデオ通話のキャプチャ一覧" />

      <ModalContent>
        <Flex>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell width="20%">ファイル名</TableHeadCell>
                  <TableHeadCell>撮影日時</TableHeadCell>
                  <TableHeadCell>撮影者</TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {requesting ? (
                  <Spinner />
                ) : (
                  videoCallImages.map(videoCallImage => (
                    <TableRow
                      key={videoCallImage.uid}
                      onClick={() => setSelectedUid(videoCallImage.uid)}
                      hover
                      borderBottom
                    >
                      <TableBodyCell width="20%">
                        {videoCallImage.name}
                      </TableBodyCell>
                      <TableBodyCell>
                        {dayjs(videoCallImage.capturedAt).format(
                          'YYYY/MM/DD HH:mm',
                        )}
                      </TableBodyCell>
                      <TableBodyCell>{videoCallImage.callerName}</TableBodyCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Spacer size={10} horizontal />

          <ImageContainer>
            {selectedImageWithUrl && <Image src={selectedImageWithUrl.url!} />}
          </ImageContainer>
        </Flex>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="閉じる"
          onClick={props.onClose}
          buttonType="normal"
        />
      </ModalActions>
      {errorMessage && (
        <div>
          <Spacer size={8} />
          <Message type="error" message={errorMessage} centered />
        </div>
      )}
    </Modal>
  )
}

const useFetchList = ({
  trialUid,
  patientUid,
  worksheetUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}) => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()

  const [videoCallImages, setVideoCallImages] = useState<VideoCallImage[]>([])

  useEffect(() => {
    const request = async () => {
      try {
        requestStarted()
        const res = await fetchList({ trialUid, patientUid, worksheetUid })
        setVideoCallImages(res)
        requestDone()
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    }

    request()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientUid, trialUid])

  return {
    requesting,
    errorMessage,
    videoCallImages,
  }
}

const useFetchSingle = () => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()

  const [fetched, setFetched] = useState<VideoCallImage | null>(null)

  const request = useCallback(
    ({
      trialUid,
      patientUid,
      worksheetUid,
      videoCallUid,
      videoCallImageUid,
    }: {
      trialUid: string
      patientUid: string
      worksheetUid: string
      videoCallUid: string
      videoCallImageUid: string
    }) => {
      ;(async () => {
        try {
          setFetched(null)
          requestStarted()
          const res = await fetchSingle({
            trialUid,
            patientUid,
            worksheetUid,
            videoCallUid,
            videoCallImageUid,
          })
          requestDone()
          setFetched(res)
        } catch (error) {
          requestFailed(error.message)
          throw error
        }
      })()
    },
    [requestDone, requestFailed, requestStarted],
  )

  return {
    request,
    fetched,
    requesting,
    errorMessage,
  }
}

const TableContainer = styled.div`
  flex: 1;
  height: 367px;
`

const ImageContainer = styled(FlexItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 367px;
  background-color: #000;
  border-radius: 8px;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`
