import { Button, useDisclosure } from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
  useSelectedTrial,
} from 'src/features/auth/context'
import { PERMISSIONS } from 'src/lib/permission'

import { Explanation } from '../../types'
import { isPartner } from '../../utils/isPartner'
import { ExplanationStartReAgreementConfirmModal } from '../ExplanationStartReAgreementConfirmModal/ExplanationStartReAgreementConfirmModal'

type Props = {
  explanation: Explanation
}

export const ExplanationStartReAgreementButton: React.FC<Props> = ({
  explanation,
}) => {
  const { selectedTrial } = useSelectedTrial()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { hasPermission } = usePermission()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  if (!hasPermission(PERMISSIONS.Explanation_Edit)) return null
  if (!explanation.isRequiredReAgreement) return null
  if (
    selectedTrialHospitalUid &&
    isPartner(explanation, selectedTrialHospitalUid)
  )
    return null

  return (
    <>
      <Button onClick={onOpen}>再同意実施</Button>
      <ExplanationStartReAgreementConfirmModal
        candidateId={explanation.patient.candidateId}
        status={explanation.latestRevision.status}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          navigate(
            generatePath(Paths.ExplanationAddReAgreement, {
              trialUid: selectedTrial.uid,
              explanationUid: explanation.uid,
            }),
          )
        }}
      />
    </>
  )
}
