import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { PatientEProStatus } from 'src/modules/entities/patient/entity'
import { PatientEproUpdateRequest } from 'src/modules/entities/patient/request'
import { getSingle } from 'src/modules/entities/patient/selector'
import { getList as getQuestionnaireList } from 'src/modules/entities/questionnaire/selector'
import { RootState } from 'src/modules/reducer'
import { truncateTime } from 'src/modules/util/datetime-utils'

import { EditPatient } from './edit'
import { useSubmitUpdate } from './use-submit-update'
import { useFetchList as useFetchQuestionnaireList } from '../../../questionnaire/list/list-container'
import { patientIdParamName } from '../../router'

type Props = {}

export const EditPatientContainer: React.FC<Props> = props => {
  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()

  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )!

  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { fetched: isQuestionnairesFetched } = useFetchQuestionnaireList({
    trialUid,
  })
  const questionnaires = useSelector(getQuestionnaireList)

  const { hasPermission } = usePermission()

  // 'eproStatus = Invalid' は ePRO 実装以前に登録された患者に該当
  const canEdit =
    hasPermission(PERMISSIONS.Patient_Edit) &&
    [
      PatientEProStatus.Invalid,
      PatientEProStatus.Unspecified,
      PatientEProStatus.BeforeTrial,
    ].includes(patient.eproStatus)
  const showUpdate =
    hasPermission(PERMISSIONS.Patient_Edit) &&
    [
      PatientEProStatus.Invalid,
      PatientEProStatus.Unspecified,
      PatientEProStatus.BeforeTrial,
    ].includes(patient.eproStatus)
  const showIssuePassword =
    hasPermission(PERMISSIONS.Patient_IssuePassword) &&
    patient.eproStatus !== PatientEProStatus.TrialCompleted
  const showConfirmAnswerStartDate =
    hasPermission(PERMISSIONS.Patient_StartTrial) &&
    [
      PatientEProStatus.Invalid,
      PatientEProStatus.Unspecified,
      PatientEProStatus.BeforeTrial,
    ].includes(patient.eproStatus)
  const showFinishTrial =
    hasPermission(PERMISSIONS.Patient_FinishTrial) &&
    patient.eproStatus === PatientEProStatus.InTrial
  const canExportCSV = hasPermission(PERMISSIONS.Questionnaire_ExportAnswerCsv)

  // 更新ボタン押下時
  const { request, requesting, errorMessage } = useSubmitUpdate()
  const onSubmitUpdate = useCallback(
    async (params: PatientEproUpdateRequest) => {
      await request(patientUid, params)
    },
    [request, patientUid],
  )

  if (!isQuestionnairesFetched) {
    return null
  }

  const isValidAnswerStartDate =
    !!patient.answerStartDate &&
    truncateTime(new Date()).getTime() <=
      truncateTime(new Date(patient.answerStartDate)).getTime()

  return (
    <EditPatient
      trialUid={trialUid}
      patient={patient}
      questionnaires={questionnaires}
      canEdit={canEdit}
      showUpdate={showUpdate}
      showIssuePassword={showIssuePassword}
      showConfirmAnswerStartDate={showConfirmAnswerStartDate}
      showFinishTrial={showFinishTrial}
      canExportCSV={canExportCSV}
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmitUpdate={onSubmitUpdate}
      isValidAnswerStartDate={isValidAnswerStartDate}
    />
  )
}
