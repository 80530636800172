import React from 'react'

import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { ButtonLink } from 'src/components/base/text-link'
import styled from 'styled-components'

import {
  icfDocumentRoleText,
  IcfDocumentCheckRole,
  icfDocumentCheckRoles,
} from '../entity'

type Props = {
  onClose: () => void
  onRedirect: (role: IcfDocumentCheckRole) => void
  checkRoles: IcfDocumentCheckRole[]
}

export const DocuSignCheckUnderstandingPreviewModal: React.FC<Props> = ({
  onClose,
  onRedirect,
  checkRoles,
}) => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle title="確認するロールを選択" />
      <ModalContent>
        <RoleTextContainer>
          {Object.values(icfDocumentCheckRoles).map(role => (
            <ButtonLink
              key={role}
              onClick={() => onRedirect(role)}
              disabled={!checkRoles.includes(role)}
            >
              {icfDocumentRoleText[role]}
            </ButtonLink>
          ))}
        </RoleTextContainer>
      </ModalContent>
      <ModalActions>
        <Button size="S" buttonType="cancel" text="閉じる" onClick={onClose} />
      </ModalActions>
    </Modal>
  )
}

const RoleTextContainer = styled.div`
  display: flex;
  justify-content: center;

  & > button:not(:last-child) {
    margin-right: 24px;
  }
`
