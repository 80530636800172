import { Choice } from '@micin-jp/chicken-schema'
import { assertNever } from 'src/utils/assertNever'

import { ChoiceTypeFieldValue } from './isChoiceTypeFieldValue'

export const isChosen = (value: ChoiceTypeFieldValue, cid: Choice['cid']) => {
  switch (value.type) {
    case 'checkbox':
      return value.checkboxValue.includes(cid)
    case 'radio':
      return value.radioValue === cid
    case 'selectMenu':
      return value.selectMenuValue === cid
    default:
      return assertNever(value)
  }
}
