import { createSelector } from '@reduxjs/toolkit'

import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.flash)

export const getMessage = createSelector(
  getState,
  state => state.messages[0] || null,
)
