import { useCallback } from 'react'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  ToastId,
  useMediaQuery,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react'

// ChakraのuseToast同様に、関数にproperty（close関数）を追加する
type UseMirohaToastReturn = {
  (options?: UseToastOptions): ToastId | undefined
  close: (id: ToastId) => void
}

export const useMirohaToast = () => {
  const toast = useToast()
  const [isLargerThan720] = useMediaQuery('(min-width: 720px)')

  const f = useCallback(
    (options?: UseToastOptions) =>
      toast({
        render: () => (
          <Alert status={options?.status ?? 'info'}>
            <AlertIcon />
            <Box>
              <AlertTitle wordBreak="break-word">{options?.title}</AlertTitle>
              <AlertDescription>{options?.description}</AlertDescription>
            </Box>
          </Alert>
        ),
        containerStyle: {
          width: isLargerThan720 ? '720px' : '90%',
        },
        position: options?.position ?? 'top',
      }),
    [isLargerThan720, toast],
  ) as UseMirohaToastReturn // 関数のpropertyを追加するためにキャスト

  f.close = toast.close

  return f
}
