import dayjs from 'dayjs'

const isValidDateValue = (value: string) => {
  if (value === '') return true

  const matched = value.match(/^([12]\d{3})-([0-2]?\d)-([0123]?\d)$/)
  if (!matched) return false

  const year = Number(matched[1])
  const month = Number(matched[2])
  const day = Number(matched[3])

  const valueDayJs = dayjs(value, 'YYYY-MM-DD')

  // dayjs は存在しない日付でも valid な日付として parse するので、parse 後の年月日と元の年月日それぞれを比較する
  return (
    year === valueDayJs.year() &&
    // dayjs が返す month は 0~11 なので +1 している
    month === valueDayJs.month() + 1 &&
    day === valueDayJs.date()
  )
}

export const shouldShowDateFormatError = (value: string | null) => {
  if (!value) return false

  const year = value.split('-')[0]
  const month = value.split('-')[1]
  const day = value.split('-')[2]

  //埋まっていない項目がある場合はエラーを表示しない
  if (!year || !month || !day) return false

  return !isValidDateValue(value)
}

export const formatTimestamp = ({
  timestamp,
  onlyMonth,
}: {
  timestamp: string | null
  onlyMonth?: boolean
}): string => {
  if (!timestamp) return ''
  const d = dayjs(timestamp)

  if (onlyMonth) return d.format('MM/DD')
  return d.format('YYYY/MM/DD HH:mm')
}

/**
 * Date の時刻部分を 00:00:00 にして返す
 */
export const truncateTime = (d: Date): Date =>
  new Date(d.getFullYear(), d.getMonth(), d.getDate())
