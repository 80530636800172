import { useState } from 'react'

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Stack,
  HStack,
} from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'
import { Certification } from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { StickyPopover } from '../StickyPopover/StickyPopover'
import { WorksheetFieldHistory } from '../WorksheetFieldHistory/WorksheetFieldHistory'

type Props = {
  field: Field
  index: number
  isDisabled: boolean
  showCertifiedCopyIcon?: boolean
  errorMessage?: string
  children: React.ReactNode
}

/** ワークシートの各フィールドで呼び出すHelperコンポーネント
 *
 * ChakraUIのFormControlを使用している。
 * フィールドの履歴表示なども担う。
 */
export const WorksheetFieldFormControl: React.FC<Props> = ({
  field,
  index,
  isDisabled,
  showCertifiedCopyIcon,
  errorMessage,
  children,
}) => {
  const [inputting, setInputting] = useState(false)

  return (
    <FormControl
      onFocus={() => setInputting(true)}
      onBlur={() => setInputting(false)}
      isDisabled={isDisabled}
      isInvalid={!inputting && !!errorMessage}
    >
      <Stack mb="6px" spacing="3px">
        <HStack align="center" spacing="3" w="full">
          <HStack spacing="0.5">
            <FormLabel m="0">{field.name}</FormLabel>
            {showCertifiedCopyIcon && (
              <Certification color={colors.green[500]} size="12px" />
            )}
          </HStack>
          <WorksheetFieldHistory field={field} index={index} />
          <Box flex="1">
            <StickyPopover field={field} index={index} />
          </Box>
        </HStack>
        {!!field.description && (
          <FormHelperText color="gray.600" fontSize="xs" mt="0" mb="0">
            {field.description}
          </FormHelperText>
        )}
      </Stack>
      <Box>{children}</Box>
      {!!errorMessage && (
        <FormErrorMessage color="red.500" fontSize="xs" mt="1.5" mb="0">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
