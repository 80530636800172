import { HttpResponse } from 'msw'
import {
  createEditStickyMessagePayloadMock,
  EditStickyMessageDocument,
  EditStickyMessageMutation,
  EditStickyMessageMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const editStickyMessageHandler = graphQLHandlerFactory<
  EditStickyMessageMutation,
  EditStickyMessageMutationVariables
>('mutation', EditStickyMessageDocument, () => {
  return HttpResponse.json({
    data: { editStickyMessage: createEditStickyMessagePayloadMock() },
  })
})
