import {
  Add,
  Check,
  Edit,
  Email,
  Trash,
} from 'src/components/__legacy__icon/monochrome'

//ボタンに付与できるアイコンのリスト
export const buttonIcons = {
  add: Add,
  check: Check,
  edit: Edit,
  email: Email,
  trash: Trash,
} as const

export type ButtonIconName = keyof typeof buttonIcons

export const getIcon = ({
  iconName,
}: {
  iconName: ButtonIconName | undefined
}) => {
  if (!iconName) {
    return undefined
  }

  return buttonIcons[iconName]
}
