import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { swrKeys } from '../../../../../../../swr/key'
import { trialUidParamName } from '../../../explanation-room/routes'
import { icfDocumentRevisionUidParamName } from '../../routes'
import { getVideoStreamUrl } from '../request'

export const useVideoViewer = () => {
  const { trialUid = '', icfDocumentRevisionUid = '' } = useParams<{
    [trialUidParamName]: string
    [icfDocumentRevisionUidParamName]: string
  }>()

  const { data: videoStreamUrl, error } = useSWR<string, Error>(
    icfDocumentRevisionUid !== undefined
      ? swrKeys.getIcfDocumentVideoStreamUrl({
          trialUid,
          icfDocumentRevisionUid,
        })
      : null,
    () =>
      getVideoStreamUrl({
        trialUid,
        icfDocumentRevisionUid,
      }),
  )

  return {
    videoStreamUrl,
    error,
  }
}
