import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  UploadFilesMutation,
  UploadFilesMutationVariables,
} from 'src/lib/gql-client'
import { SERVER_URL } from 'src/modules/server/const'
import { isNotNullish } from 'src/utils/isNotNullish'

import query from './mutation.gql?raw' // graphqlファイルを文字列として読み込み

export type UploadFilesRes = UploadFilesMutation['uploadFiles']

type UploadFilesInput = {
  files: File[]
}

type Operations = {
  query: string
  variables: UploadFilesMutationVariables
  operationName: string
}

type RequestResponse = {
  data: UploadFilesMutation | null
  errors: GqlError[] | undefined | null
}

// https://github.com/jaydenseric/graphql-multipart-request-spec?tab=readme-ov-file#multipart-form-field-structure で示されているようにFormDataを構築する
// JSON形式ででoperations, map, マッピング対象のファイルの指定を行いFormDataに設定する
const uploadFiles = async (
  input: UploadFilesInput,
): Promise<UploadFilesRes> => {
  const files = input.files

  const operations: Operations = {
    query,
    variables: { input: { files: files.map(_ => null) } }, // アップロードするファイル分のサイズを持った配列を作成
    operationName: 'UploadFiles',
  }
  const map: { [key: number]: string[] } = {}
  files.forEach((_, i) => {
    map[i] = [`variables.input.files.${i}`]
  })

  // FormDataの構築
  const formData = new FormData()
  formData.append('operations', JSON.stringify(operations))
  formData.append('map', JSON.stringify(map))
  // fileをappend(keyはmapで指定している通りindex)
  files.forEach((file, i) => {
    formData.append(String(i), file)
  })

  const fetchRes = await fetch(`${SERVER_URL}/query`, {
    method: 'post',
    body: formData,
    credentials: 'include',
  })
  // request自体が失敗している場合はnullを指定してデフォルトのエラーメッセージ（問題が発生しました...）が表示されるようにする。
  if (!fetchRes.ok) {
    throw new GqlError()
  }
  const res: RequestResponse = await fetchRes.json()
  // GraphQLのエラーはレスポンスに配列で含まれるので最初のエラーを返す
  if (isNotNullish(res.errors) && res.errors.length > 0) {
    const firstError = res.errors[0]
    throw firstError
  }
  if (res.data !== null) {
    return res.data.uploadFiles
  }

  // 最終的に値をreturnできなかった場合 こちらもデフォルトのエラーを返す
  throw new GqlError()
}

export const useUploadFiles = (
  options?: UseMutationOption<UploadFilesRes, GqlError>,
) => {
  return useMutation(uploadFiles, options)
}
