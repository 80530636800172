import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { isTablet } from 'react-device-detect'
import { Message, MessageTab } from 'src/components/layout/message-tab'
import { generateUUID } from 'src/utils/generateUUID'

type Context = {
  showing: boolean
  show: () => void
  hide: () => void
}

const context = createContext({} as Context)

/** @deprecated use useMobileOrientationAlert without this provider */
export const OrientationAlertProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [showing, setShowing] = useState(false)

  const show = useCallback(() => setShowing(true), [])
  const hide = useCallback(() => setShowing(false), [])

  const message: Message = {
    uid: generateUUID(),
    body: '画面を横向きにしてのご利用を推奨しています',
    type: 'Info',
    option: { durationMsec: 60000, closable: true },
  }

  return (
    <context.Provider value={{ showing, show, hide }}>
      {showing && <MessageTab message={message} onComplete={hide} />}
      {children}
    </context.Provider>
  )
}

/** @deprecated use useMobileOrientationAlert */
export const useScreenOrientationAlert = () => {
  const showed = useRef(false)
  const { showing, show, hide } = useContext(context)

  // この機能はタブレット（iPad）を想定したものであるが、
  // Screen Orientation API はsafariで未対応のため 幅よりも高さの値が大きい場合を縦向きとして扱うこととする
  // https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
  const isPortrait = () => window.innerHeight > window.innerWidth

  const detectResizing = useCallback(() => {
    window.addEventListener('resize', () => {
      if (!isPortrait() && showing) {
        hide()
        showed.current = false
        return
      }
      if (isPortrait() && !showed.current) {
        show()
        showed.current = true
      }
    })
  }, [showing, show, hide])

  useEffect(() => {
    if (!isTablet) return
    // 初期状態で縦だったらアラートを出す
    if (isPortrait() && !showed.current) {
      show()
      showed.current = true
    }
    detectResizing()

    return () => window.removeEventListener('resize', detectResizing)
  }, [show, detectResizing])
}
