import { useEffect, useMemo, useRef, useState } from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  AcceptableFileType,
  FileSelectButton,
} from 'src/components/FileSelectButton/FileSelectButton'
import {
  Add,
  Arrange,
  ArrowLeft,
  ArrowRight,
  Close,
  Certification,
  Document,
} from 'src/components/icon'
import { RightClickBlocker } from 'src/components/RightClickBlocker/RightClickBlocker'
import {
  UploadFilesRes,
  useUploadFiles,
} from 'src/features/misc/api/uploadFiles'
import { usePrevious } from 'src/hooks/use-previous'
import { useSpinner } from 'src/hooks/use-spinner'
import { useMirohaSwiper } from 'src/hooks/useMirohaSwiper'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { openNewWindow } from 'src/utils/openNewWindow'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import {
  checkFileNameCharacters,
  checkFileNameLength,
  checkFileSize,
  FileItem,
  fileSizeWithUnit,
  getFileType,
  isNewFileItem,
  isSavedFileItem,
  NewFileItem,
  renameFileNameToUnique,
  SavedFileItem,
  truncateFileName,
  validateFileItems,
} from '../../utils/fileUploadUtils'

import 'swiper/css'

const ACCEPT_FILE_TYPES: AcceptableFileType[] = [
  '.jpg',
  '.jpeg',
  '.png',
  '.pdf',
]

const replaceFileItemsWithUploaded = (
  fileItems: FileItem[],
  uploadedFiles: UploadFilesRes['uploadedFiles'],
): SavedFileItem[] => {
  const uploadedByFileName = new Map(
    uploadedFiles.map(file => [file.name, file]),
  )
  const newFileItems: SavedFileItem[] = []
  fileItems.forEach(item => {
    if (isNewFileItem(item)) {
      const uploadedFile = uploadedByFileName.get(item.fileName)
      if (uploadedFile) {
        newFileItems.push({
          isNew: false,
          uid: uploadedFile.uid,
          fileName: uploadedFile.name,
          fileUrl: uploadedFile.url,
          fileSize: uploadedFile.size,
          fileExtension: uploadedFile.extension,
          savedAt: uploadedFile.savedAt,
        })
      }
    } else {
      newFileItems.push(item)
    }
  })
  return newFileItems
}

type Props = {
  defaultFileItems: SavedFileItem[]
  isCertifiedCopy: boolean
  onUploaded: (fileItems: SavedFileItem[]) => void
}

export const FileUploadComponent: React.FC<Props> = ({
  defaultFileItems,
  isCertifiedCopy,
  onUploaded,
}) => {
  const uploadModal = useDisclosure()
  const sortModal = useDisclosure()

  const [fileItems, setFileItems] = useState<FileItem[]>(defaultFileItems)
  // 内容を確定したファイルのリスト
  const [uploadedFileItems, setUploadedFileItems] =
    useState<SavedFileItem[]>(defaultFileItems)

  const isChanged =
    JSON.stringify(fileItems) !== JSON.stringify(uploadedFileItems)

  const handleFileUpload = (files: File[]) => {
    const existingFileNames = fileItems.map(item => item.fileName)
    const newFileItems: NewFileItem[] = files.map(uploadedFile => {
      const newFileName = renameFileNameToUnique(
        uploadedFile.name,
        existingFileNames,
      )
      const newFile = new File([uploadedFile], newFileName, {
        type: uploadedFile.type,
      })
      // 同時にアップロードするファイルも含めて重複チェックするために追加
      existingFileNames.push(newFileName)
      return {
        isNew: true,
        fileName: newFileName,
        fileUrl: URL.createObjectURL(newFile),
        fileSize: newFile.size,
        file: newFile,
      }
    })
    setFileItems(prev => [...prev, ...newFileItems])
  }

  const handleDropFile = (index: number) => {
    setFileItems(prev => prev.filter((_, i) => i !== index))
    URL.revokeObjectURL(fileItems[index].fileUrl)
  }

  const newFileItems = fileItems.filter(isNewFileItem)
  const savedFileItems = fileItems.filter(isSavedFileItem)

  const { showSpinner, hideSpinner } = useSpinner()

  const { request: uploadFiles, requesting: isUploading } = useUploadFiles({
    onRequestStarted: showSpinner,
    onRequestDone: hideSpinner,
    onSuccess: ({ uploadedFiles }) => {
      uploadModal.onClose()
      newFileItems.forEach(item => URL.revokeObjectURL(item.fileUrl))
      const updatedFileItems = replaceFileItemsWithUploaded(
        fileItems,
        uploadedFiles,
      )
      onUploaded(updatedFileItems)
      setUploadedFileItems(updatedFileItems)
      setFileItems(updatedFileItems)
    },
  })

  const hasContent = fileItems.length > 0

  return (
    <>
      {hasContent ? (
        <Box>
          <Button
            variant="text"
            leftIcon={<Add />}
            onClick={uploadModal.onOpen}
          >
            アップロードファイル編集
          </Button>
        </Box>
      ) : (
        <Stack spacing="2">
          <FileSelectButton
            allowMultiple
            text="ファイルアップロード"
            accept={ACCEPT_FILE_TYPES}
            onChange={files => {
              handleFileUpload(files)
              uploadModal.onOpen()
            }}
            buttonProps={{
              variant: 'text',
              leftIcon: <Add />,
            }}
          />
          <Text fontSize="sm">
            ※ ファイルはPNG・JPEG・PDF形式の拡張子から選択可能です。
          </Text>
        </Stack>
      )}
      {uploadModal.isOpen && (
        <UploadModal
          fileItems={fileItems}
          isCertifiedCopy={isCertifiedCopy}
          isChanged={isChanged}
          isUploading={isUploading}
          onClose={() => {
            uploadModal.onClose()
            setFileItems(defaultFileItems)
          }}
          onFileUpload={handleFileUpload}
          onDropFile={handleDropFile}
          onClickSortButton={() => {
            uploadModal.onClose()
            sortModal.onOpen()
          }}
          onSubmit={async () => {
            if (newFileItems.length === 0) {
              uploadModal.onClose()
              onUploaded(savedFileItems)
              setUploadedFileItems(savedFileItems)
              return
            }
            await uploadFiles({
              files: newFileItems.map(item => item.file),
            })
          }}
        />
      )}
      {sortModal.isOpen && (
        <SortModal
          defaultFileItems={fileItems}
          onClose={() => {
            sortModal.onClose()
            uploadModal.onOpen()
          }}
          onSubmit={sortedFileItems => {
            setFileItems(sortedFileItems)
            sortModal.onClose()
            uploadModal.onOpen()
          }}
        />
      )}
    </>
  )
}

type UploadModalProps = {
  fileItems: FileItem[]
  isCertifiedCopy: boolean
  isChanged: boolean
  isUploading: boolean
  onClose: () => void
  onFileUpload: (files: File[]) => void
  onDropFile: (index: number) => void
  onClickSortButton: () => void
  onSubmit: () => void
}
const UploadModal: React.FC<UploadModalProps> = ({
  fileItems,
  isCertifiedCopy,
  isChanged,
  isUploading,
  onClose,
  onFileUpload,
  onDropFile,
  onClickSortButton,
  onSubmit,
}) => {
  // certifiedCopyとして扱わない場合はチェック済みとする
  const [certifiedCopyCheckDone, setCertifiedCopyCheckDone] = useState(
    !isCertifiedCopy || !isChanged,
  )

  const { isValid: isValidFiles, errorMessage } = validateFileItems(fileItems)

  const swiperRef = useRef<SwiperClass | null>(null)
  const previousFileItemCount = usePrevious(fileItems.length)

  useEffect(() => {
    if (!swiperRef || !previousFileItemCount) return
    // slideが追加された場合は最後のスライドに移動する
    if (fileItems.length > previousFileItemCount) {
      swiperRef.current?.slideTo(fileItems.length - 1)
    }
  }, [fileItems.length, previousFileItemCount])

  const canConfirm = useMemo(() => {
    if (fileItems.length === 0) {
      return true
    }
    if (!isValidFiles) {
      return false
    }
    if (isUploading) {
      return false
    }
    if (isChanged) {
      return isCertifiedCopy ? certifiedCopyCheckDone : true
    }
    return false
  }, [
    fileItems.length,
    isChanged,
    isCertifiedCopy,
    certifiedCopyCheckDone,
    isValidFiles,
    isUploading,
  ])

  return (
    <Modal isOpen onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>ファイルアップロード</ModalHeader>
        <ModalBody>
          <Flex justify="flex-end" gap={2} pb="2">
            <FileSelectButton
              allowMultiple
              text="ファイル追加"
              accept={ACCEPT_FILE_TYPES}
              onChange={files => {
                onFileUpload(files)
                setCertifiedCopyCheckDone(!isCertifiedCopy)
              }}
              buttonProps={{ variant: 'text', leftIcon: <Add /> }}
            />
            <Button
              variant="text"
              leftIcon={<Arrange />}
              onClick={onClickSortButton}
              isDisabled={fileItems.length < 2}
            >
              並べ替え
            </Button>
          </Flex>
          <Divider />
          <Flex w="full" h="300px" align="center" pos="relative">
            <Swiper
              spaceBetween={30}
              slidesPerView="auto"
              // NOTE: keyboard操作時のbuttonの位置を保つためstaticにして親をrelativeにする（Swiperのdefaultはrelative）
              style={{ padding: '0 54px', position: 'static', margin: 0 }}
              // @ts-ignore Swiperがanyで型定義されているため
              onBeforeInit={swiper => {
                swiperRef.current = swiper
              }}
            >
              <Flex
                pos="absolute"
                w="54px"
                left="0"
                top="0"
                bg="white"
                h="full"
                align="center"
                justify="start"
                zIndex="1"
              >
                <SwiperButton direction="prev" />
              </Flex>
              {fileItems.length === 0 && (
                <Center>
                  <Text fontWeight="bold">
                    アップロード対象のファイルがありません。
                  </Text>
                </Center>
              )}
              {fileItems.map((fileItem, i) => {
                const isValid =
                  checkFileNameCharacters(fileItem.fileName) &&
                  checkFileSize(fileItem) &&
                  checkFileNameLength(fileItem.fileName)
                return (
                  <SwiperSlide key={fileItem.fileUrl} style={{ width: 160 }}>
                    <Center>
                      <Text>{i + 1}</Text>
                    </Center>
                    <Box pos="relative">
                      <FileThumbnail
                        key={fileItem.fileUrl}
                        isPDF={getFileType(fileItem) === 'PDF'}
                        fileUrl={fileItem.fileUrl}
                        fileName={fileItem.fileName}
                        isValid={isValid}
                      />
                      <Center
                        pos="absolute"
                        borderRadius="full"
                        bg="gray.500"
                        h="20px"
                        w="20px"
                        color="white"
                        right="1.5"
                        top="1.5"
                        as="button"
                        onClick={() => {
                          onDropFile(i)
                          setCertifiedCopyCheckDone(false)
                        }}
                      >
                        <Close size="14px" />
                      </Center>
                    </Box>
                    <Center>
                      <Text
                        fontSize="xs"
                        color={checkFileSize(fileItem) ? 'gray.400' : 'red.500'}
                      >
                        {fileSizeWithUnit(fileItem.fileSize)}
                      </Text>
                    </Center>
                    <Text
                      fontSize="sm"
                      wordBreak="break-all"
                      color={isValid ? undefined : 'red.500'}
                    >
                      {truncateFileName(fileItem.fileName)}
                    </Text>
                  </SwiperSlide>
                )
              })}
              <Flex
                pos="absolute"
                w="54px"
                justify="end"
                right="0"
                top="0"
                zIndex="1"
                h="full"
                bg="white"
                align="center"
              >
                <SwiperButton direction="next" />
              </Flex>
            </Swiper>
          </Flex>
          <Divider />

          <Stack mt="4" spacing="1">
            {errorMessage && (
              <Stack color="red.500">
                <Text fontSize="sm">{errorMessage}</Text>
              </Stack>
            )}
            {isCertifiedCopy && fileItems.length > 0 && (
              <HStack pt={4}>
                <Checkbox
                  isChecked={certifiedCopyCheckDone || !isChanged}
                  onChange={e => setCertifiedCopyCheckDone(e.target.checked)}
                  isDisabled={!isChanged}
                >
                  原資料とアップロードしたものとの差異がないことを確認しました。
                </Checkbox>
                <Box
                  color={certifiedCopyCheckDone ? 'green.500' : 'gray.200'}
                  opacity={isChanged ? 1 : 0.4}
                >
                  <Certification />
                </Box>
              </HStack>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button onClick={onSubmit} isDisabled={!canConfirm}>
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type SortModalProps = {
  defaultFileItems: FileItem[]
  onClose: () => void
  onSubmit: (sorted: FileItem[]) => void
}
const SortModal: React.FC<SortModalProps> = ({
  defaultFileItems,
  onClose,
  onSubmit,
}) => {
  const [sortedFileItems, setSortedFileItems] =
    useState<FileItem[]>(defaultFileItems)

  const handleSort = (index: number, direction: 'up' | 'down') => {
    setSortedFileItems(prev => {
      const targetIndex = direction === 'up' ? index + 1 : index - 1
      const me = prev[index]
      const target = prev[targetIndex]
      const newItems = [...prev]
      newItems[index] = target
      newItems[targetIndex] = me
      return newItems
    })
  }

  const changed =
    JSON.stringify(sortedFileItems) !== JSON.stringify(defaultFileItems)

  return (
    <Modal isOpen onClose={onClose} size="5xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>ファイル並べ替え</ModalHeader>
        <ModalBody>
          <Divider />
          <Box py="6" pos="relative">
            <Swiper
              spaceBetween={30}
              slidesPerView="auto"
              // NOTE: keyboard操作時のbuttonの位置を保つためstaticにして親をrelativeにする（Swiperのdefaultはrelative）
              style={{ padding: '0 54px', position: 'static' }}
            >
              <Flex
                pos="absolute"
                w="54px"
                left="0"
                top="0"
                bg="white"
                h="full"
                align="center"
                justify="start"
                zIndex="1"
              >
                <SwiperButton direction="prev" />
              </Flex>
              {sortedFileItems.map((fileItem, i) => (
                <SwiperSlide key={fileItem.fileUrl} style={{ width: 160 }}>
                  <Stack w="160px" spacing="2">
                    <Center gap={4}>
                      <Button
                        variant="unstyled"
                        aria-label="sort-left"
                        minW="unset"
                        w="32px"
                        display="flex"
                        justifyContent="center"
                        color="blue.500"
                        opacity={i === 0 ? 0.3 : 1}
                        isDisabled={i === 0}
                        onClick={() => handleSort(i, 'down')}
                      >
                        <ArrowLeft />
                      </Button>
                      <Text>{i + 1}</Text>
                      <Button
                        variant="unstyled"
                        aria-label="sort-right"
                        minW="unset"
                        w="32px"
                        display="flex"
                        justifyContent="center"
                        color="blue.500"
                        opacity={i === sortedFileItems.length - 1 ? 0.3 : 1}
                        isDisabled={i === sortedFileItems.length - 1}
                        onClick={() => handleSort(i, 'up')}
                      >
                        <ArrowRight />
                      </Button>
                    </Center>
                    <FileThumbnail
                      isPDF={fileItem.fileName.endsWith('.pdf')}
                      fileUrl={fileItem.fileUrl}
                      fileName={fileItem.fileName}
                      isValid={true} // 並べ替え時はサイズチェックしない
                    />
                    <Center>
                      <Text fontSize="xs" color="gray.400">
                        {fileSizeWithUnit(fileItem.fileSize)}
                      </Text>
                    </Center>
                    <Text fontSize="sm" wordBreak="break-all">
                      {truncateFileName(fileItem.fileName)}
                    </Text>
                  </Stack>
                </SwiperSlide>
              ))}
              <Flex
                pos="absolute"
                w="54px"
                right="0"
                top="0"
                bg="white"
                h="full"
                align="center"
                justify="end"
                zIndex="1"
              >
                <SwiperButton direction="next" />
              </Flex>
            </Swiper>
          </Box>
          <Divider />
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            isDisabled={!changed}
            onClick={() => {
              onSubmit(sortedFileItems)
            }}
          >
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const SwiperButton: React.FC<{
  direction: 'prev' | 'next'
}> = ({ direction }) => {
  const swiper = useMirohaSwiper()

  return (
    <IconButton
      variant="customIconButtonGhost"
      icon={direction === 'prev' ? <ArrowLeft /> : <ArrowRight />}
      aria-label={direction === 'prev' ? '手前に移動する' : '奥に移動する'}
      isDisabled={
        direction === 'prev'
          ? !swiper.prevSlideEnabled
          : !swiper.nextSlideEnabled
      }
      onClick={() => {
        if (direction === 'prev') {
          swiper.slidePrev()
        } else {
          swiper.slideNext()
        }
      }}
    />
  )
}

const FileThumbnail: React.FC<{
  isPDF: boolean
  fileUrl: string
  fileName: string
  isValid: boolean
}> = ({ isPDF, fileUrl, fileName, isValid }) => {
  return (
    <RightClickBlocker>
      <AspectRatio
        ratio={1}
        border={isValid ? '1px solid' : '2px solid'}
        w="full"
        borderColor={isValid ? 'gray.200' : 'red.500'}
        pos="relative"
        as="button"
        onClick={() => {
          openNewWindow(fileUrl, 'newWindow')
        }}
      >
        {isPDF ? (
          <Center w="full" bg="gray.50">
            <Document size="48" />
          </Center>
        ) : (
          <Image src={fileUrl} alt={fileName} objectFit="cover" />
        )}
      </AspectRatio>
    </RightClickBlocker>
  )
}
