import React from 'react'

import { IconButton } from '@chakra-ui/react'
import { Delete } from 'src/components/icon'
import styled from 'styled-components'

import { useOpenModal } from '../../../../../../../../hooks/use-open-modal'
import { DeleteModalContainer } from '../modal/delete-modal-container'

type Props = {
  templateFolderUid: string
}

export const DeleteButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <Wrapper>
      <IconButton
        icon={<Delete />}
        aria-label="テンプレートフォルダ削除モーダルを開く"
        variant="customIconButtonGhost"
        onClick={e => {
          e.stopPropagation()
          openModal()
        }}
      />
      {modalOpen && (
        <DeleteModalContainer
          onClose={closeModal}
          templateFolderUid={props.templateFolderUid}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
