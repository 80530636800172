import { Button } from '@chakra-ui/react'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSendTestSMS } from '../../api'

type Props = {
  phoneNumber?: string
}
export const SendTestSmsButton: React.FC<Props> = ({ phoneNumber }) => {
  const toast = useMirohaToast()

  const { request: sendTestSMS } = useSendTestSMS({
    onSuccess: () => {
      toast({
        title: '番号確認のメッセージを送信しました。',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  return (
    <Button
      variant="text"
      isDisabled={!phoneNumber}
      onClick={async () => {
        if (!phoneNumber) return
        await sendTestSMS({
          phoneNumber,
        })
        return
      }}
    >
      テスト送信
    </Button>
  )
}
