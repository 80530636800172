import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import {
  Selectbox,
  SelectboxItem,
} from 'src/components/form-redesigned/selectbox'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'
import { TrialFinishReason } from 'src/modules/entities/patient/request'
import styled from 'styled-components'

type Props = {
  requesting: boolean
  errorMessage: string
  selected: TrialFinishReason
  onSubmit: () => void
  onClose: () => void
  onChange: (e: TrialFinishReason) => void
}

const items: SelectboxItem<TrialFinishReason>[] = [
  {
    name: '試験終了',
    value: TrialFinishReason.End,
  },
  {
    name: '中止',
    value: TrialFinishReason.Discontinue,
  },
]

export const FinishTrialModal: React.FC<Props> = props => {
  const { onChange } = props

  const [isReasonSelected, setReasonSelected] = useState(false)

  const onReasonChange = useCallback(
    (reason: TrialFinishReason) => {
      setReasonSelected(true)
      onChange(reason)
    },
    [onChange, setReasonSelected],
  )

  return (
    <SubmitModal
      title="試験終了"
      submitText="試験を終了"
      requesting={props.requesting}
      errorMessage={props.errorMessage}
      onSubmit={props.onSubmit}
      onClose={props.onClose}
      disabled={!isReasonSelected}
    >
      <Modal>
        <Text textAlign="center">
          患者の質問票回答を停止します。終了理由を入力してください。
        </Text>
        <Spacer size={12} />
        <Selectbox<TrialFinishReason>
          items={items}
          selectedValue={props.selected}
          placeholder="理由を選択してください"
          onChange={onReasonChange}
        />
        <Spacer size={30} />
      </Modal>
    </SubmitModal>
  )
}

const Modal = styled.div``
