import React, { useCallback } from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'src/components/form-redesigned/input'
import { Spacer } from 'src/components/spacer/spacer'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { ErrorMessage } from './error-message'
import { MAX_INPUT_LENGTH } from '../../detail'
import { actions } from '../../redux'
import { useValidationError } from '../../use-validation-error'

export const EditableGeneral: React.FC = () => {
  const dispatch = useDispatch()
  const questionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail,
  )

  const { title, displayName } = questionnaire

  const onUpdate = useCallback(
    ({
      values,
    }: {
      values: Partial<Pick<Questionnaire, 'title' | 'displayName'>>
    }) => {
      dispatch(actions.updateQuestionnaire({ values: values }))
    },
    [dispatch],
  )

  const { validationError, resetValidationError } = useValidationError()

  return (
    <ItemContainer>
      <Item>
        <Label>
          <Text fontWeight="bold">質問票名</Text>
        </Label>
        <Spacer size={40} horizontal />
        <Input
          width={'480px'}
          placeholder={'質問票名を入力'}
          value={title}
          onChange={e => {
            onUpdate({
              values: { title: e.target.value },
            })
            resetValidationError({ target: 'title' })
          }}
          className={validationError.title ? 'error' : ''}
          maxLength={MAX_INPUT_LENGTH}
        />
        {title.length === 0 && (
          <>
            <Spacer size={10} horizontal />
            <ErrorMessage>質問票名は必須です</ErrorMessage>
          </>
        )}
        {validationError.title && (
          <>
            <Spacer size={10} horizontal />
            <ErrorMessage>{validationError.title}</ErrorMessage>
          </>
        )}
      </Item>

      <Spacer size={20} />

      <Item>
        <Label>
          <Text fontWeight="bold">患者表示名</Text>
        </Label>
        <Spacer size={40} horizontal />
        <Input
          width={'480px'}
          placeholder={'患者の回答画面で表示する名前を入力'}
          value={displayName}
          onChange={e => {
            onUpdate({
              values: { displayName: e.target.value },
            })
            resetValidationError({ target: 'displayName' })
          }}
          className={validationError.displayName ? 'error' : ''}
          maxLength={MAX_INPUT_LENGTH}
        />
        {validationError.displayName && (
          <>
            <Spacer size={10} horizontal />
            <ErrorMessage>{validationError.displayName}</ErrorMessage>
          </>
        )}
      </Item>
    </ItemContainer>
  )
}

const ItemContainer = styled.div``

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Label = styled.div`
  width: 80px;
`
