import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelectedTrial } from 'src/features/auth/context'

import { TrialDetailContent } from './detail/trial-detail'
import { getRelativePath } from '../../../constants/paths'

export const TrialContent = () => {
  const { selectedTrial } = useSelectedTrial()

  return (
    <Routes>
      <Route
        path={`${getRelativePath('Trials', 'Trial')}/*`}
        element={<TrialDetailContent />}
      />
      <Route
        path="*"
        element={<Navigate replace to={`${selectedTrial.uid}`} />}
      />
    </Routes>
  )
}
