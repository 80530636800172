import {
  QuestionnaireStatus,
  Questionnaire,
} from 'src/modules/entities/questionnaire/entity'

import { QuestionnaireScheduleListResponse } from './request'
import { getFixtures as getScheduleFixtures } from '../schedule/fixture'

const questionnaireFixtures: Pick<
  Questionnaire,
  'uid' | 'title' | 'status' | 'scheduleUids'
>[] = [
  {
    uid: 'dummy-1',
    title: '質問票１',
    status: QuestionnaireStatus.Released,
    scheduleUids: [],
  },
  {
    uid: 'dummy-2',
    title: '質問票２',
    status: QuestionnaireStatus.Released,
    scheduleUids: [],
  },
  {
    uid: 'dummy-3',
    title: '質問票３',
    status: QuestionnaireStatus.Released,
    scheduleUids: [],
  },
]

const scheduleFixtures = getScheduleFixtures()

export const fixture: QuestionnaireScheduleListResponse = {
  questionnaires: questionnaireFixtures,
  schedules: scheduleFixtures,
}
