import { Box } from '@chakra-ui/react'
import { useParams, useNavigate, generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { useModal } from 'src/hooks/use-modal'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useExplanation } from '../../api'
import { useCreateReAgreementExplanation } from '../../api/createReAgreement'
import { Explanation } from '../../types'
import { schemaToCreateReAgreementInput } from '../../utils/schemaToCreateReAgreementInput'
import { ExplanationForm } from '../ExplanationForm/ExplanationForm'

type Param = {
  explanationUid: Explanation['uid']
}

export const AddReAgreementExplanationScreen: React.FC = () => {
  const { explanationUid = '' } = useParams<Param>()

  const { data: explanation, mutate } = useExplanation({
    explanationUid,
  })

  const toast = useMirohaToast()
  const navigate = useNavigate()
  const {
    account: { selectedTrial },
  } = useAuthenticatedAccount()

  const { request: createReAgreementExplanation } =
    useCreateReAgreementExplanation({
      onSuccess: async () => {
        toast({
          status: 'success',
          title: '説明の再同意登録が完了しました',
        })
        // 古いリビジョンのデータを表示しないようここでcacheをクリア
        await mutate(undefined, false)
        navigate(
          generatePath(Paths.Explanation, {
            trialUid: selectedTrial.uid,
            explanationUid,
          }),
        )
      },
      onError: error => {
        toast({
          status: 'error',
          title: error.message,
        })
      },
    })

  const { showModal } = useModal()

  if (!explanation) return null

  return (
    <Box as="main">
      <ExplanationForm
        formType="reAgreement"
        explanation={explanation}
        onSubmit={async schema => {
          showModal({
            title: '再同意登録',
            content: '説明の再同意情報を登録します。',
            submitText: '登録する',
            onSubmit: async () => {
              await createReAgreementExplanation({
                explanationUid: explanation.uid,
                input: schemaToCreateReAgreementInput(schema),
              })
            },
          })
        }}
      />
    </Box>
  )
}
