import React, { useState, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { SelectTrialModal } from 'src/components/modal/select-trial'
import { useStickyQuery } from 'src/features/worksheet/hooks/useStickyQuery'
import { TrialSelection } from 'src/modules/entities/select-trial/entity'
import { fetchList } from 'src/modules/entities/select-trial/request'
import { routes } from 'src/modules/routes'
import styled from 'styled-components'

type Props = {
  redirectWithPathSelection: boolean
}
export const SelectTrialContainer: React.FC<Props> = ({
  redirectWithPathSelection,
}) => {
  const [fetched, setFetched] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [trialSelections, setTrialSelections] = useState<TrialSelection[]>([])
  const { stickyQuery, resetQuery } = useStickyQuery()

  const navigate = useNavigate()

  const location = useLocation()

  const trialUidRegExp = /trials\/([a-zA-Z0-9-]+)\/?/
  const pathEmbeddedTrialUid = location.pathname.match(trialUidRegExp)?.[1]

  useEffect(() => {
    const fetch = async () => {
      try {
        // TODO: useTrialsWithTrialHospitalsに置き換える
        const res = await fetchList()
        // URLにtrials/uidが含まれている場合、当該の治験を選択済みとする
        if (
          redirectWithPathSelection &&
          !!pathEmbeddedTrialUid &&
          res.map(t => t.uid).includes(pathEmbeddedTrialUid)
        ) {
          setTrialSelections(res.filter(t => t.uid === pathEmbeddedTrialUid))
        } else {
          // 付箋選択解除の位置確認
          if (!!stickyQuery) resetQuery()
          setTrialSelections(res)
        }
        setFetched(true)
      } catch (error) {
        //治験・病院が取得できなかった際のエラー処理
        console.error('SelectTrialContainer fetch Error: ', error)
        if (error.original.response.status === 403) {
          navigate(routes.httpError403)
        }
        setErrorMessage(error.message)
      }
    }
    fetch()
  }, [
    redirectWithPathSelection,
    pathEmbeddedTrialUid,
    resetQuery,
    stickyQuery,
    navigate,
  ])

  if (!fetched) {
    return null
  }

  return (
    <>
      {!errorMessage ? (
        <SelectTrialModal trialSelections={trialSelections} />
      ) : (
        <Message>{errorMessage}</Message>
      )}
    </>
  )
}

const Message = styled.h2`
  width: 100%;
  margin: 100px 0 30px;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: ${colors.red};
`
