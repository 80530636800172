// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgCallStart = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M4.7 9.7c-1.4-2.5-1.9-4.9-2-6.5-.1-.8.2-1.5 1-1.9 0 0 .1-.1.2-.1l.9-.5.2-.1c.2-.1.5-.1.7.2l1.7 2.9c.1.2.1.5-.2.7l-.2.1-1 .6-.1.1c-.3.2-.4.3-.3.6.3 1 .7 1.9 1.2 2.8 0 .1.2.3.2.4.5.9 1.1 1.7 1.8 2.4.2.2.5.2.7 0l.1-.1 1-.6.2-.1c.2-.1.5-.1.7.2l1.7 2.9c.1.2.1.5-.2.7l-.2.1-.9.5c-.1.1-.2.1-.2.1-.7.4-1.5.3-2.2-.1-1.3-.9-3.1-2.5-4.6-5 0 .1-.1-.2-.2-.3Z"
    />
  </svg>
)
export default SvgCallStart
