import { generateUUID } from 'src/utils/generateUUID'

import { Choice } from '../choice/entity'
import { Role } from '../member/entity'

export enum ItemType {
  Radio = 1,
  CheckBox,
  SelectMenu,
  Date,
  Number,
  Text,
  NullableDate,
  HourMinute,
  Label,
  TextArea,
  File,
}

export enum DataType {
  Operational = 1,
  Clinical,
}

export type SmallItem = {
  uid: string
  title: string
  value: string | null //回答タイプがラベルかつEDC連携ありの場合のみ設定
  description: string
  index: number
  itemType: ItemType
  dataType: DataType
  edcDataItem: string
  unit: string
  viewableHospitalUids: string[]
  unsaved: boolean

  inputRoles: Role[]
  choices: Choice[]

  parentUid: string | null
  mediumItemUid?: string
  presetUsed: boolean

  isCertifiedCopy?: boolean
}

export const createEmpty = (v2: boolean = false): SmallItem => ({
  uid: generateUUID(),
  title: '',
  value: null,
  description: '',
  index: -1,
  itemType: ItemType.Radio,
  dataType: v2 ? DataType.Operational : DataType.Clinical,
  edcDataItem: '',
  unit: '',
  viewableHospitalUids: [],
  unsaved: true,
  inputRoles: [],
  choices: [],
  parentUid: null,
  presetUsed: false,
})
