import React, { useCallback, useMemo, useState } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import {
  generatePath,
  useLocation,
  Link as ReactRouterLink,
} from 'react-router-dom'
import { Add, Followup } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { usePermission } from 'src/features/auth/context'
import { PERMISSIONS } from 'src/lib/permission'
import { Template, TemplateStatus } from 'src/modules/entities/template/entity'
import { getList as getTemplates } from 'src/modules/entities/template/selector'
import { TemplateFolder } from 'src/modules/entities/template-folder/entity'
import { nonPresetSelector as getTemplateFolders } from 'src/modules/entities/template-folder/selector'

import { TemplateStatusBadge } from './common/status-badge'
import { CreateTemplateModalContainer } from './create-template-modal/create-template-modal-container'
import { CreateButton } from './template-folder/create/button/create-button'
import { DeleteButton } from './template-folder/delete/button/delete-button'
import { EditButton } from './template-folder/edit/button/edit-button'
import { SortTemplateFolderButton } from './template-folder/sort/button/button'
import { getSelectedTrial } from '../../../../entities/account/selector'

const useOpenCreateTemplateModal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [templateFolderUid, setTemplateFolderUid] = useState('')

  const handlers = useMemo(
    () => ({
      openModal: (uid: string) => {
        setTemplateFolderUid(uid)
        setModalOpen(true)
      },
      closeModal: () => {
        setModalOpen(false)
      },
    }),
    [],
  )

  return { modalOpen, templateFolderUid, handlers }
}

const getTemplatesByFolderUid = (
  templates: Template[],
  templateFolderUid: string,
) => {
  return templates
    .filter(t => t.templateFolderUid === templateFolderUid)
    .sort((a, b) => a.index - b.index)
}

export const Head = () => {
  const trial = useSelector(getSelectedTrial)
  const templates = useSelector(getTemplates)
  const templateFolders = useSelector(getTemplateFolders)
  const { pathname } = useLocation()
  const { hasPermission } = usePermission()
  const selectedTemplateUid =
    pathname.split('/')[
      pathname.split('/').findIndex(p => p === 'template') + 1
    ]
  const selectedTemplate = templates.find(t => t.uid === selectedTemplateUid)
  const activeTemplateFolderUid = selectedTemplate?.templateFolderUid ?? null

  const {
    modalOpen,
    templateFolderUid,
    handlers: { openModal, closeModal },
  } = useOpenCreateTemplateModal()

  const expandedFolder = templateFolders.find(
    tf => tf.uid === activeTemplateFolderUid,
  )

  const v2 = trial?.featureFlags.eSourceV2 ?? false

  const canEditFolderName = useCallback(
    (isActiveFolder: boolean) => {
      if (!isActiveFolder) return false
      return hasPermission(PERMISSIONS.Template_EditFolderName)
    },
    [hasPermission],
  )

  const canDeleteFolder = useCallback(
    (tf: TemplateFolder, isActiveFolder: boolean) => {
      if (
        !hasPermission(PERMISSIONS.Template_DeleteFolder) ||
        !isActiveFolder
      ) {
        return false
      }
      const folderTemplates = getTemplatesByFolderUid(templates, tf.uid)
      return folderTemplates.every(t => t.status === TemplateStatus.New)
    },
    [hasPermission, templates],
  )

  if (!trial) return null

  return (
    <Flex direction="column" w="full" h="full">
      <Flex justify="space-between" px="4" pt="4" pb="6" pos="sticky">
        {hasPermission(PERMISSIONS.Template_CreateFolder) && (
          <Box>
            <CreateButton v2={v2} />
          </Box>
        )}

        {hasPermission(PERMISSIONS.Template_SortFolders) && (
          <Box>
            <SortTemplateFolderButton
              trialUid={trial.uid}
              templateFolders={templateFolders}
            />
          </Box>
        )}
      </Flex>

      <Box flex="1" overflowY="scroll">
        <Accordion
          allowToggle
          allowMultiple
          defaultIndex={
            expandedFolder ? [templateFolders.indexOf(expandedFolder)] : []
          }
          overflowY="scroll"
        >
          {templateFolders.map(tf => (
            <AccordionItem border="none" key={tf.uid}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <Box
                      p="2"
                      _hover={{ bgColor: 'blue.50' }}
                      bgColor={isExpanded ? 'blue.50' : 'unset'}
                    >
                      <AccordionButton
                        p="2"
                        display="flex"
                        justifyContent="space-between"
                        _hover={{ bgColor: 'blue.50' }}
                      >
                        <HStack spacing="1">
                          <Flex gap="1.5" align="center">
                            {tf.observationType === 'FollowUp' && (
                              <Box color="gray.500">
                                <Followup />
                              </Box>
                            )}
                            <Text
                              fontSize={isExpanded ? 'md' : 'sm'}
                              fontWeight={isExpanded ? 'bold' : 'normal'}
                              lineHeight="5"
                              as="span"
                            >
                              {tf.name}
                            </Text>
                          </Flex>
                          {canEditFolderName(isExpanded) && (
                            <EditButton v2={v2} templateFolderUid={tf.uid} />
                          )}
                          {canDeleteFolder(tf, isExpanded) && (
                            <DeleteButton templateFolderUid={tf.uid} />
                          )}
                        </HStack>
                        <AccordionIcon color="blue.500" />
                      </AccordionButton>
                    </Box>
                  </h2>
                  <AccordionPanel
                    px="2"
                    bgColor={isExpanded ? 'blue.50' : 'unset'}
                    borderBottomRadius="base"
                  >
                    <Stack overflow="auto" spacing="4">
                      {getTemplatesByFolderUid(templates, tf.uid).map(t => (
                        <Box
                          key={t.uid}
                          as={ReactRouterLink}
                          to={generatePath(Paths.Template, {
                            trialUid: trial.uid,
                            templateUid: t.uid,
                          })}
                          p="2"
                          textAlign="left"
                          fontWeight="normal"
                          borderRadius="base"
                          cursor="pointer"
                          _hover={{
                            bgColor:
                              t.uid === selectedTemplateUid
                                ? 'blue.300'
                                : 'blue.100',
                          }}
                          _active={{
                            bgColor:
                              t.uid === selectedTemplateUid
                                ? 'blue.300'
                                : 'blue.200',
                          }}
                          bgColor={
                            t.uid === selectedTemplateUid ? 'blue.300' : 'unset'
                          }
                        >
                          <HStack spacing="1">
                            <TemplateStatusBadge status={t.status} />
                            <Text
                              as="span"
                              opacity={
                                t.status === TemplateStatus.Disabled ? 0.3 : 1
                              }
                            >
                              {t.title}
                            </Text>
                          </HStack>
                        </Box>
                      ))}
                      {hasPermission(PERMISSIONS.Template_Create) && (
                        <Box>
                          <Button
                            onClick={() => openModal(tf.uid)}
                            leftIcon={<Add />}
                            variant="outline"
                          >
                            テンプレート追加
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Box>

      {modalOpen && (
        <CreateTemplateModalContainer
          templateFolderUid={templateFolderUid}
          onClose={closeModal}
        />
      )}
    </Flex>
  )
}
