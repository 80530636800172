import dayjs from 'dayjs'

export const parseDateWithException = (date: string): Date => {
  if (!dayjs(date).isValid())
    throw new Error(`String: ${date} is invalid format to parse date type.`)
  return dayjs(date).toDate()
}

export const parseDate = (date: string): Date | undefined => {
  return dayjs(date).isValid() ? dayjs(date).toDate() : undefined
}
