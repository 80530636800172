import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { FinishTrialModalContainer } from './finish-trial-modal-container'

type Props = {}

export const FinishTrialButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <>
      <Button
        text="試験を終了"
        buttonType="important"
        size="S"
        onClick={openModal}
      />
      {modalOpen && <FinishTrialModalContainer onClose={closeModal} />}
    </>
  )
}
