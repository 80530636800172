import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { DocumentNode, GraphQLError } from 'graphql'
import {
  graphql,
  http,
  HttpResponseResolver,
  HttpResponse,
  GraphQLQuery,
  GraphQLVariables,
  GraphQLResponseResolver,
} from 'msw'
import {
  ApiPath,
  HttpMethod,
  ApiPathParamFromPathAndMethod,
  ApiResponseFromPathAndMethod,
  ExactHttpMethodByPath,
  ApiErrorFromPathAndMethod,
} from 'src/lib/api-client'

import { ApiBodyFromPathAndMethod } from './../../lib/api-client/schema-util'

type WithoutUnknown<T> = T extends unknown ? {} : T

type WithoutNever<T> = [T] extends [never] ? {} : T

type Res<Path extends ApiPath, Method extends HttpMethod> = WithoutNever<
  ApiResponseFromPathAndMethod<Path, Method>
>

type PathParams<
  Path extends ApiPath,
  Method extends ExactHttpMethodByPath<ApiPath>,
> = ApiPathParamFromPathAndMethod<Path, Method>

type BodyType<
  Path extends ApiPath,
  Method extends ExactHttpMethodByPath<ApiPath>,
> = WithoutUnknown<ApiBodyFromPathAndMethod<Path, Method>>

type Err<
  Path extends ApiPath,
  Method extends HttpMethod,
> = ApiErrorFromPathAndMethod<Path, Method>

export const restHandlerFactory =
  <Path extends ApiPath, Method extends ExactHttpMethodByPath<Path>>(
    path: Path,
    httpMethod: Method,
    defaultResolver: HttpResponseResolver<
      PathParams<Path, Method>,
      BodyType<Path, Method>,
      Res<Path, Method>
    >,
  ) =>
  (override?: {
    status?: number
    data?: Res<Path, Method>
    error?: Err<Path, Method>
  }) => {
    // test環境ではundefinedになるので空文字を代入
    const serverURL = import.meta.env.VITE_SERVER_URL || ''
    // pathをmswが求める形式に変換 ex) /trials/{trial_uid} -> trials/:trial_uid
    const mswPath = serverURL + path.replace(/\{/g, ':').replace(/\}/g, '')

    return http[httpMethod]<PathParams<Path, Method>, BodyType<Path, Method>>(
      mswPath,
      resolverOptions => {
        if (override?.data) {
          return HttpResponse.json(override.data, {
            status: override.status ?? 200,
          })
        }
        if (override?.error) {
          return HttpResponse.json(override.error.data, {
            status: override.status ?? 500,
          })
        }
        return defaultResolver(resolverOptions)
      },
    )
  }

export const graphQLHandlerFactory =
  <Query extends GraphQLQuery, Variable extends GraphQLVariables>(
    operationType: 'query' | 'mutation',
    operation: DocumentNode | TypedDocumentNode<Query, Variable>,
    defaultResolver: GraphQLResponseResolver<Query, Variable>,
  ) =>
  (override?: { data?: Query; errors?: GraphQLError[] }) => {
    return graphql[operationType]<Query, Variable>(
      operation,
      resolverOptions => {
        if (override?.data) {
          return HttpResponse.json({ data: override.data })
        }
        if (override?.errors) {
          return HttpResponse.json({ errors: override.errors })
        }
        return defaultResolver(resolverOptions)
      },
    )
  }
