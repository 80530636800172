import { useCallback } from 'react'

import { useExplanationsQueryParams } from './useExplanationsQueryParams'
import { ExplanationRevisionStatus } from '../types'
import { allExplanationRevisionStatuses } from '../utils/allExplanationRevisionStatuses'

export const useFilterExplanations = () => {
  const { queryParamObject, setQuery } = useExplanationsQueryParams()

  // BEGIN: ステータス
  const handleCheckStatus = useCallback(
    (value: ExplanationRevisionStatus | 'all') => {
      let nextStatuses = [...queryParamObject.statuses]
      if (value === 'all') {
        nextStatuses =
          nextStatuses.length === allExplanationRevisionStatuses.length
            ? []
            : allExplanationRevisionStatuses
      } else {
        const idx = nextStatuses.indexOf(value)
        if (idx !== -1) {
          nextStatuses.splice(idx, 1)
        } else {
          nextStatuses.push(value)
        }
      }
      setQuery({
        type: 'statuses',
        value: nextStatuses,
      })
    },
    [setQuery, queryParamObject.statuses],
  )
  // END: ステータス

  // BEGIN: 再同意
  const handleCheckReAgreement = useCallback(
    (value: boolean) => {
      const nextReAgreementArray = [...queryParamObject.reAgreement]
      const idx = nextReAgreementArray.indexOf(value)
      if (idx !== -1) nextReAgreementArray.splice(idx, 1)
      else nextReAgreementArray.push(value)

      setQuery({
        type: 'reAgreement',
        value: nextReAgreementArray,
      })
    },
    [setQuery, queryParamObject.reAgreement],
  )
  // END: 再同意

  // BEGIN: 医療機関
  const handleChangeHospital = useCallback(
    (hospitalUid: string | undefined) => {
      setQuery({ type: 'trialHospital', value: hospitalUid })
    },
    [setQuery],
  )
  // END: 医療機関

  // BEGIN: 担当Dr
  const handleChangeDoctor = useCallback(
    (doctorUid: string | undefined) => {
      setQuery({ type: 'dr', value: doctorUid })
    },
    [setQuery],
  )
  // BEGIN: 担当Dr

  // BEGIN:担当CRC
  const handleChangeCrc = useCallback(
    (crcUid: string | undefined) => {
      setQuery({ type: 'crc', value: crcUid })
    },
    [setQuery],
  )
  // BEGIN: 担当CRC

  return {
    isCheckAllStatus:
      queryParamObject.statuses.length ===
      allExplanationRevisionStatuses.length,
    handleCheckStatus,
    statuses: queryParamObject.statuses,
    handleCheckReAgreement,
    reAgreement: queryParamObject.reAgreement,
    handleChangeHospital,
    hospitalUid: queryParamObject.trialHospitalUid,
    handleChangeDoctor,
    doctorUid: queryParamObject.dr,
    handleChangeCrc,
    crcUid: queryParamObject.crc,
  }
}
