import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
// 正確な日付バリデーションにはpluginが必要
// https://day.js.org/docs/en/parse/is-valid

export const isValidDateStr = ({
  year,
  month,
  day,
}: {
  year: string
  month: string
  day: string
}) => {
  const yyyy = year.padStart(4, '0')
  const mm = month.padStart(2, '0')
  const dd = day.padStart(2, '0')
  const d = dayjs(`${yyyy}-${mm}-${dd}`, 'YYYY-MM-DD', true)
  return d.isValid()
}
