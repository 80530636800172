import React from 'react'

import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react'
import { generatePath, useParams } from 'react-router-dom'
import { MessageInformation } from 'src/components/icon'
import { Paths } from 'src/constants/paths'

import { useSessionForPatient } from '../../api'
import {
  ExplanationRoomEventProvider,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useRoomMediaContext } from '../../context/MediaProvider'
import { useThisSession } from '../../hooks/useThisSession'
import { getStatusInformationMessage } from '../../utils/getStatusInformationMessage'
import { DocumentList } from '../DocumentList/DocumentList'
import { SPExplanationRoomLayout } from '../SPExplanationRoomLayout/SPExplanationRoomLayout'
import { SPExplanationVideoCall } from '../SPExplanationVideoCall/SPExplanationVideoCall'

type Param = {
  trialUid: string
  sessionUid: string
}

export const SPExplanationPatientTop: React.FC = () => {
  const { trialUid = '', sessionUid = '' } = useParams<Param>()

  const thisSession = useThisSession({ sessionUid, forPatient: true })

  if (!thisSession.fetched) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      isSP
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      sessionUid={sessionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.trialHospital.uid}
    >
      <SPExplanationRoomLayout
        headerTitle="説明ルーム"
        trialUid={trialUid}
        sessionUid={sessionUid}
      >
        <Box zIndex="1">
          <SPExplanationVideoCall
            canShrinkVideoCall
            roomUid={thisSession.videoCallRoomUid}
            candidateId={thisSession.patient.candidateId}
          />
        </Box>
        <Content trialUid={trialUid} sessionUid={sessionUid} />
      </SPExplanationRoomLayout>
    </ExplanationRoomEventProvider>
  )
}

const Content: React.FC<{ trialUid: string; sessionUid: string }> = ({
  trialUid,
  sessionUid,
}) => {
  const { data: session, mutate: mutateSession } = useSessionForPatient({
    explanationSessionUid: sessionUid,
  })

  const thisSession = useThisSession({ sessionUid, forPatient: true })

  useUpdateRevisionStatus({
    listener: () => {
      mutateSession()
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  const { videoExpanded, toggleVideoExpanded } = useRoomMediaContext()

  if (!session || !thisSession.fetched) {
    return null
  }

  const status = session.explanationRevision.latestHistory.statusV2

  const informationMessage = getStatusInformationMessage({
    status,
    hasConsentRequiredAgreementForm:
      thisSession.hasConsentRequiredAgreementForm,
    isSignerSet: thisSession.isSignerMemberSet,
  })

  return (
    <Stack
      spacing="6"
      bg="green.50"
      h="full"
      px="2"
      py="4"
      overflow="auto"
      pt={videoExpanded ? '2' : '16'}
    >
      <DocumentList
        docSets={session.explanationRevision.docSets}
        toggleVideoCall={() => {
          // 文書を開くタイミングでビデオを縮小する
          if (!videoExpanded) return
          toggleVideoExpanded()
        }}
        docPagePath={docRevisionUid =>
          generatePath(Paths.PatientExplanationRoomDocument, {
            trialUid,
            sessionUid,
            docRevisionUid,
          })
        }
      />
      {!!informationMessage && (
        <Center>
          <HStack spacing="2" color="blue.500" align="center">
            <MessageInformation />
            <Text fontWeight="bold" fontSize="xs">
              {informationMessage}
            </Text>
          </HStack>
        </Center>
      )}
    </Stack>
  )
}
