import { useCallback, useEffect, useRef } from 'react'

import { ToastId } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { assertNever } from 'src/utils/assertNever'

type UseMobileOrientationAlertProps = {
  isDisabled?: boolean
  shouldBe: 'portrait' | 'landscape'
  message: string
}

/** スマホ、タブレットなどのデバイスで画面の向きが指定したものと異なる場合にアラートを表示する */
export const useMobileOrientationAlert = ({
  isDisabled,
  shouldBe,
  message,
}: UseMobileOrientationAlertProps) => {
  // 表示したかどうかのフラグ
  const showed = useRef(false)

  const toastIdRef = useRef<ToastId>()
  const toast = useMirohaToast()

  const show = useCallback(() => {
    toastIdRef.current = toast({
      status: 'warning',
      title: message,
    })
  }, [toast, message])

  const close = useCallback(
    (id: ToastId) => {
      toast.close(id)
    },
    [toast],
  )

  // Screen Orientation API はsafariで未対応のため 幅よりも高さの値が大きい場合を縦向きとして扱うこととする
  // https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
  const isPortrait = useCallback(() => {
    return window.innerHeight > window.innerWidth
  }, [])
  const isLandscape = useCallback(() => {
    return window.innerHeight < window.innerWidth
  }, [])

  const shouldShowAlert = useCallback(() => {
    if (shouldBe === 'portrait') return isLandscape()
    if (shouldBe === 'landscape') return isPortrait()
    return assertNever(shouldBe)
  }, [shouldBe, isPortrait, isLandscape])

  const currentWidthRef = useRef(window.innerWidth)

  const handleResizing = useCallback(() => {
    if (currentWidthRef.current === window.innerWidth) return
    currentWidthRef.current = window.innerWidth
    if (!shouldShowAlert() && !!toastIdRef.current) {
      close(toastIdRef.current)
      showed.current = false
      return
    }
    if (shouldShowAlert() && !showed.current) {
      show()
      showed.current = true
    }
  }, [shouldShowAlert, show, close])

  useEffect(() => {
    if (!isMobile || isDisabled) return
    // 初期状態で縦だったらアラートを出す
    if (shouldShowAlert() && !showed.current) {
      show()
      showed.current = true
    }
    window.addEventListener('resize', handleResizing)
    return () => window.removeEventListener('resize', handleResizing)
  }, [isDisabled, shouldShowAlert, show, handleResizing])
}
