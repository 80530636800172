import React from 'react'

import { Text } from '@chakra-ui/react'
import { SubmitModal } from 'src/components/modal/submit'

type Props = {
  errorMessage: string
  requesting: boolean
  onSubmit: () => void
  closeModal: () => void
}

export const ReleaseModal: React.FC<Props> = props => {
  const { errorMessage, requesting, onSubmit, closeModal } = props

  return (
    <SubmitModal
      title={`配信開始`}
      submitText={`確定`}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      requesting={requesting}
      onClose={() => closeModal()}
    >
      <Text textAlign="center">
        配信を開始後、質問票とスケジュールは、
        <br />
        追加、更新、削除はできません。
      </Text>
    </SubmitModal>
  )
}
