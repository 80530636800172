import { Button } from '@chakra-ui/react'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useResendPatientVideoCallNotification } from '../../api/resendPatientVideoCallNotification'

type Props = {
  patientVideoCallUid: string
}
export const ResendPatientVideoCallNotificationButton: React.FC<Props> = ({
  patientVideoCallUid,
}) => {
  const toast = useMirohaToast()
  const { request } = useResendPatientVideoCallNotification({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '通話リンクメールを再送しました。',
      })
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  return (
    <Button variant="link" onClick={() => request({ patientVideoCallUid })}>
      ビデオ通話リンクを再送する
    </Button>
  )
}
