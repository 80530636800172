import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { VideoCallImage } from './entity'
import { fixture, fixture2 } from './fixture'

type VideoCallImageResponse = {
  uid: string
  name: string
  caller_name?: string
  url?: string
  captured_at: string
  created_at: string
  video_call_uid?: string
}

const parse = (resp: VideoCallImageResponse): VideoCallImage => {
  return {
    uid: resp.uid,
    name: resp.name,
    callerName: resp.caller_name,
    capturedAt: resp.captured_at,
    createdAt: resp.created_at,
    url: resp.url,
    videoCallUid: resp.video_call_uid,
  }
}

export const fetchList = async ({
  trialUid,
  patientUid,
  worksheetUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}): Promise<VideoCallImage[]> => {
  if (IS_MOCK_MODE) {
    return [fixture, fixture2]
  }

  // TODO: その worksheet だけでなく患者に紐づくものを全て表示すべき？
  const res = await requestGet<VideoCallImageResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/video_calls/images`,
    params: {},
  })

  return res.data.map(parse)
}

export const fetchSingle = async ({
  trialUid,
  patientUid,
  worksheetUid,
  videoCallUid,
  videoCallImageUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  videoCallUid: string
  videoCallImageUid: string
}): Promise<VideoCallImage> => {
  if (IS_MOCK_MODE) {
    return (
      [fixture, fixture2].find(f => f.uid === videoCallImageUid) ?? fixture2
    )
  }

  // TODO: その worksheet だけでなく患者に紐づくものを全て表示すべき？
  const res = await requestGet<VideoCallImageResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/video_calls/${videoCallUid}/images/${videoCallImageUid}`,
    params: {},
  })

  return parse(res.data)
}
