import React, { ReactNode } from 'react'

import { Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { Button } from 'src/components/base/button/button'
import { white } from 'src/components/base/color/palette'
import { AnchorLink } from 'src/components/base/text-link'
import { SingleCheckboxWithoutValue } from 'src/components/form-redesigned/single-checkbox'
import { Spacer } from 'src/components/spacer/spacer'
import { agreementTypeStrings } from 'src/modules/auth/agreement/entity'
import { routes } from 'src/modules/routes'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { AgreementItem } from './agreement-container'
import { Message } from '../../../components/base/message/message'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 3%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.bgGray};
`

const ContentContainer = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 40px;
  max-width: 768px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${white};
  box-shadow: 0px 10px 30px rgba(133, 141, 168, 0.06);
  border-radius: 10px;
`

const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ConfirmContainer = styled.div`
  display: flex;
`

const LinkWithTargetBlank: React.FC<{
  to: string
  onEnable: () => void
  children: ReactNode
}> = ({ to, onEnable, children }) => (
  <AnchorLink
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onEnable}
  >
    {children}
  </AnchorLink>
)

type Props = {
  agreementItems: AgreementItem[]
  onSubmit: () => void
  enabledSubmit: boolean
  errorMessage?: string
}

export const Agreement: React.FC<Props> = ({
  agreementItems,
  onSubmit,
  enabledSubmit,
  errorMessage,
}) => {
  const navigate = useNavigate()

  return (
    <PageContainer>
      <ContentContainer>
        <Text fontSize="2xl" fontWeight="bold">
          同意事項
        </Text>
        <Spacer size={30} />
        <Text fontSize="sm">
          MiROHAサービスのご利用には、下記の規約等をお読みの上同意していただく必要があります。
        </Text>

        <Spacer size={24} />

        <CheckContainer>
          {agreementItems.map((item, i) => (
            <div key={`item-${i}`}>
              {i > 0 && <Spacer size={10} />}
              <Flex alignCenter>
                <SingleCheckboxWithoutValue
                  key={item.agreementType}
                  checked={item.checked}
                  onChange={item.onChange}
                  disabled={!item.enabled}
                />
                <Spacer size={10} horizontal />
                <Text fontSize="sm">
                  <LinkWithTargetBlank to={item.path} onEnable={item.onEnable}>
                    {agreementTypeStrings[item.agreementType]}
                  </LinkWithTargetBlank>
                  に同意します。
                </Text>
              </Flex>
            </div>
          ))}
        </CheckContainer>

        <Spacer size={24} />

        <Text fontSize="xs">
          ※リンク先をご確認いただくことで同意のチェックボックスが有効になります。
        </Text>

        <Spacer size={30} />

        <ConfirmContainer>
          <Button
            size="S"
            text="キャンセル"
            buttonType="cancel"
            onClick={() => {
              navigate(routes.login)
            }}
          />
          <Spacer size={12} horizontal />
          <Button
            size="S"
            text="次へ"
            type={'submit'}
            buttonType="important"
            onClick={onSubmit}
            disabled={!enabledSubmit}
          />
        </ConfirmContainer>
        {errorMessage && (
          <div>
            <Spacer size={10} />
            <Message type="error" message={errorMessage} />
          </div>
        )}
      </ContentContainer>
    </PageContainer>
  )
}
