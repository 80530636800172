import { HttpResponse } from 'msw'
import {
  createDeleteStickyPayloadMock,
  DeleteStickyDocument,
  DeleteStickyMutation,
  DeleteStickyMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const deleteStickyHandler = graphQLHandlerFactory<
  DeleteStickyMutation,
  DeleteStickyMutationVariables
>('mutation', DeleteStickyDocument, () => {
  return HttpResponse.json({
    data: { deleteSticky: createDeleteStickyPayloadMock() },
  })
})
