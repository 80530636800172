import { useEffect } from 'react'

import { initGoLib, Schema } from '@micin-jp/chicken-schema'
import { Provider, useSetAtom, WritableAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'

import {
  editingCriteriaValuesAtom,
  fetchedCriteriaValuesAtom,
  schemaAtom,
  validateAtom,
} from './criteriaValue'
import { ReferredPatientStatus } from '../../../lib/gql-client'
import { CriteriaValue } from '../types'

type Props = {
  schema: Schema
  fetchedCriteriaValues: CriteriaValue[]
  referrerStatus: ReferredPatientStatus
  children: React.ReactNode
}

const wasmUrl = import.meta.env.VITE_WEB_DOMAIN + '/schema.wasm'
export const CriteriaValueProvider: React.FC<Props> = ({
  schema,
  fetchedCriteriaValues,
  referrerStatus,
  children,
}) => {
  useEffect(() => {
    ;(async () => {
      await initGoLib({ wasmUrl })
    })()
  }, [])
  return (
    <Provider>
      <AtomsHydrator
        schema={schema}
        values={fetchedCriteriaValues}
        referrerStatus={referrerStatus}
      >
        {children}
      </AtomsHydrator>
    </Provider>
  )
}

// 初期値を注入する
// cf) https://jotai.org/docs/guides/initialize-atom-on-render
const AtomsHydrator: React.FC<{
  schema: Schema
  values: CriteriaValue[]
  referrerStatus: ReferredPatientStatus
  children: React.ReactNode
}> = ({ schema, values, referrerStatus, children }) => {
  const atomValues: Iterable<
    readonly [WritableAtom<unknown, [any], unknown>, unknown]
  > = [
    [schemaAtom, schema],
    [fetchedCriteriaValuesAtom, values],
    [editingCriteriaValuesAtom, values],
  ]

  useHydrateAtoms(new Map(atomValues))

  // 一時保存状態の場合は即時バリデーションを行う
  const validateValues = useSetAtom(validateAtom)
  if (referrerStatus === 'Saved') {
    validateValues().then()
  }

  return <>{children}</>
}
