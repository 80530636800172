import { Box } from '@chakra-ui/react'
import {
  CheckboxTypeDef,
  DateTypeDef,
  Field,
  FileTypeDef,
  LabelTypeDef,
  NullableDateTypeDef,
  NumberTypeDef,
  RadioTypeDef,
  RepeatableSectionTypeDef,
  SectionTypeDef,
  SelectMenuTypeDef,
  TextareaTypeDef,
  TextTypeDef,
  TimeTypeDef,
} from '@micin-jp/chicken-schema'
import { isSectionTypeDef } from 'src/lib/chicken-schema/utils'
import { assertNever } from 'src/utils/assertNever'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { generateFieldComponentId } from '../../utils/generateFieldComponentId'
import { WorksheetCheckboxField } from '../WorksheetCheckboxField/WorksheetCheckboxField'
import { WorksheetDateField } from '../WorksheetDateField/WorksheetDateField'
import { WorksheetFileField } from '../WorksheetFileField/WorksheetFileField'
import { WorksheetLabelField } from '../WorksheetLabelField/WorksheetLabelField'
import { WorksheetNullableDateField } from '../WorksheetNullableDateField/WorksheetNullableDateField'
import { WorksheetNumberField } from '../WorksheetNumberField/WorksheetNumberField'
import { WorksheetRadioField } from '../WorksheetRadioField/WorksheetRadioField'
import { WorksheetRepeatableSectionField } from '../WorksheetRepeatableSectionField/WorksheetRepeatableSectionField'
import { WorksheetSectionField } from '../WorksheetSectionField/WorksheetSectionField'
import { WorksheetSelectMenuField } from '../WorksheetSelectMenuField/WorksheetSelectMenuField'
import { WorksheetTextareaField } from '../WorksheetTextareaField/WorksheetTextareaField'
import { WorksheetTextField } from '../WorksheetTextField/WorksheetTextField'
import { WorksheetTimeField } from '../WorksheetTimeField/WorksheetTimeField'

type Props = {
  field: Field
  index?: number
}

/** fieldのtypeに応じて表示するコンポーネントを切り替える */
export const WorksheetFieldComponent: React.FC<Props> = ({
  field,
  index = 1,
}) => {
  const { isVisibleField } = useWorksheetValueContext()

  if (!isVisibleField(field.fid, index)) {
    return null
  }

  return (
    <Box
      className={
        isSectionTypeDef(field.typeDef)
          ? ''
          : `worksheet-field ${field.typeDef.type}`
      }
      id={generateFieldComponentId(field.fid, index)}
      w="full"
    >
      <Child field={field} index={index} />
    </Box>
  )
}

const Child: React.FC<{ field: Field; index: number }> = ({ field, index }) => {
  const type = field.typeDef.type

  switch (type) {
    case 'Section':
      const sectionField = field as Field & { typeDef: SectionTypeDef }
      return <WorksheetSectionField sectionField={sectionField} />

    case 'RepeatableSection':
      const repeatableSectionField = field as Field & {
        typeDef: RepeatableSectionTypeDef
      }
      return (
        <WorksheetRepeatableSectionField
          repeatableSectionField={repeatableSectionField}
        />
      )

    case 'Text':
      const textFiled = field as Field & { typeDef: TextTypeDef }
      return <WorksheetTextField textField={textFiled} index={index} />

    case 'Textarea':
      const textareaField = field as Field & { typeDef: TextareaTypeDef }
      return (
        <WorksheetTextareaField textareaField={textareaField} index={index} />
      )

    case 'Number':
      const numberFiled = field as Field & { typeDef: NumberTypeDef }
      return <WorksheetNumberField numberField={numberFiled} index={index} />

    case 'Checkbox':
      const checkboxField = field as Field & { typeDef: CheckboxTypeDef }
      return (
        <WorksheetCheckboxField checkboxField={checkboxField} index={index} />
      )

    case 'Radio':
      const radioField = field as Field & { typeDef: RadioTypeDef }
      return <WorksheetRadioField radioField={radioField} index={index} />

    case 'SelectMenu':
      const selectMenuField = field as Field & { typeDef: SelectMenuTypeDef }
      return (
        <WorksheetSelectMenuField
          selectMenuField={selectMenuField}
          index={index}
        />
      )

    case 'Date':
      const dateField = field as Field & { typeDef: DateTypeDef }
      return <WorksheetDateField dateField={dateField} index={index} />

    case 'NullableDate':
      const nullableDateField = field as Field & {
        typeDef: NullableDateTypeDef
      }
      return (
        <WorksheetNullableDateField
          nullableDateField={nullableDateField}
          index={index}
        />
      )

    case 'Time':
      const timeField = field as Field & { typeDef: TimeTypeDef }
      return <WorksheetTimeField timeField={timeField} index={index} />

    case 'Label':
      const labelField = field as Field & { typeDef: LabelTypeDef }
      return <WorksheetLabelField labelField={labelField} />

    case 'File':
      const fileField = field as Field & { typeDef: FileTypeDef }
      return <WorksheetFileField fileField={fileField} index={index} />

    default:
      return assertNever(type)
  }
}
