import { Box, Stack, Text } from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'

type Props = {
  field: Field
}

export const SectionViewer: React.FC<Props> = ({ field }) => {
  return (
    <Box
      as="section"
      aria-label={field.name}
      w="full"
      p="5"
      bg="blue.50"
      borderRadius="base"
      border="1px solid"
      borderColor="gray.100"
    >
      <Stack spacing="1.5" as="dl">
        <Text as="dt" fontSize="sm" fontWeight="bold" color="gray.600">
          セクション名
        </Text>
        <Text as="dd">{field.name}</Text>
      </Stack>
    </Box>
  )
}
