import React, { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useLogin } from 'src/modules/auth/login/hooks/use-login'
import { routes } from 'src/modules/routes'

import { TemporaryPasswordLogin } from './temporary-password-login'

export const TemporaryPasswordLoginContainer: React.FC<{}> = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // 正規ルート(AuthenticateContainerからの移動)以外でアクセスした場合、ログインページへ
  useEffect(() => {
    if (!location.state?.email) navigate(routes.login)
  }, [location.state?.email, navigate])

  const { errorMessage, requesting, onSubmit } = useLogin({
    onlyPassword: true,
  })

  if (!location.state?.email) return <></>

  return (
    <TemporaryPasswordLogin
      email={location.state.email}
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
    />
  )
}
