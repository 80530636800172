import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { patientCacheKey } from '../cacheKey'

type ChallengePatientVideoCallResponseType = {
  diseaseUid?: string | undefined
  hospitalName?: string | undefined
  newPatientVideoCallUid?: string | undefined
  result:
    | 'Authorized'
    | 'VideoCallHasBeenFinished'
    | 'UserHasNoSession'
    | 'NewVideoCallExists'
}

const ChallengePatientVideoCall = async ({
  trialUid,
  patientVideoCallUid,
}: {
  trialUid: string
  patientVideoCallUid: string
}): Promise<ChallengePatientVideoCallResponseType> => {
  const res = await createApiClient({
    path: '/trials/{trialUid}/patient_video_calls/{patientVideoCallUid}/challenge',
    httpMethod: 'get',
    params: {
      paths: {
        trialUid,
        patientVideoCallUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useChallengePatientVideoCall = ({
  trialUid,
  patientVideoCallUid,
  ...option
}: {
  trialUid: string
  patientVideoCallUid: string
} & SWRConfiguration<ChallengePatientVideoCallResponseType, Error>) => {
  return useSWR<ChallengePatientVideoCallResponseType, Error>(
    patientCacheKey.challengePatientVideoCall(trialUid, patientVideoCallUid),
    () => ChallengePatientVideoCall({ trialUid, patientVideoCallUid }),
    option,
  )
}
