import { useCallback, useEffect, useMemo, useState } from 'react'

import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { isRequestError } from 'src/modules/server/request'
import { swrKeys } from 'src/modules/swr/key'
import { generateUUID } from 'src/utils/generateUUID'
import useSWR from 'swr'

import { useIcfDocumentHospital } from './../../use-icf-document-hospital'
import {
  getEditIcfDocumentRoute,
  getIcfDocumentListRoute,
  icfDocumentUidParamName,
} from '../../routes'
import {
  CreateIcfDocumentRevisionItem,
  IcfDocumentType,
  isDocuSignType,
} from '../entity'
import {
  createIcfDocumentRevision,
  fetchIcfDocument,
  fetchNextRevisionTemplate,
  uploadDocuSignFile,
  uploadTempFile,
} from '../request'
import { IcfDocumentErrors, validateIcfDocument } from '../validate'

export const useAddIcfDocumentRevision = () => {
  const { uid: trialUid, trialHospitals } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { icfDocumentUid = '' } = useParams<{
    [icfDocumentUidParamName]: string
  }>()
  const { showSuccess, showError } = useFlash()
  const navigate = useNavigate()
  const { hasPermission } = usePermission()

  const [nextRevision, setNextRevision] = useState<
    CreateIcfDocumentRevisionItem | undefined
  >(undefined)
  const [errors, setErrors] = useState<IcfDocumentErrors>({})
  const [tempFileUrl, setTempFileUrl] = useState<string | undefined>(undefined)
  const [submitTried, setSubmitTried] = useState(false)

  const { data: icfDocument, mutate: mutateIcfDocument } = useSWR(
    selectedTrialHospitalUid !== undefined
      ? swrKeys.fetchIcfDocument({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentUid,
        })
      : null,
    () =>
      fetchIcfDocument({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentUid: icfDocumentUid,
      }),
  )

  const { data: nextRevisionTemplate } = useSWR(
    selectedTrialHospitalUid !== undefined
      ? swrKeys.fetchNextIcfDocumentRevisionTemplate({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentUid,
        })
      : null,
    () =>
      fetchNextRevisionTemplate({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentUid,
      }),
  )

  const editing = useMemo(() => {
    if (!nextRevision) {
      return false
    }
    if (submitTried) {
      return false
    }

    return !equal(nextRevision, {
      ...nextRevisionTemplate,
      uid: nextRevision.uid,
      tempFileUid: '',
    })
  }, [nextRevision, submitTried, nextRevisionTemplate])

  const listPath = useMemo(() => {
    return getIcfDocumentListRoute({
      trialUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? selectedTrialHospitalUid
        : undefined,
    })
  }, [trialUid, selectedTrialHospitalUid, hasPermission])

  const editPath = useMemo(() => {
    return getEditIcfDocumentRoute({
      trialUid,
      trialHospitalUid: selectedTrialHospitalUid,
      icfDocumentUid,
    })
  }, [trialUid, selectedTrialHospitalUid, icfDocumentUid])

  const shouldSelectHospital = hasPermission(
    PERMISSIONS.Icfdocument_SelectTrialHospital,
  )

  const hospitalName = useMemo(() => {
    const hospital = trialHospitals.find(
      hospital => hospital.uid === selectedTrialHospitalUid,
    )

    return hospital?.name ?? ''
  }, [selectedTrialHospitalUid, trialHospitals])

  const hasMultipleRevisionHistories = useMemo(() => {
    if (!icfDocument) {
      return false
    }

    return icfDocument.revisions.length >= 2
  }, [icfDocument])

  const pullDownState = useOpenPullDown()
  const submitModalState = useOpenModal()

  const onChangeName = useCallback(
    (value: string) => {
      if (!nextRevision) {
        return
      }

      setNextRevision({ ...nextRevision, name: value })
    },
    [nextRevision],
  )

  const onChangeVersion = useCallback(
    (value: string) => {
      if (!nextRevision) {
        return
      }

      setNextRevision({ ...nextRevision, version: value })
    },
    [nextRevision],
  )

  const onChangeRequiredPatientSign = useCallback(() => {
    if (!nextRevision) {
      return
    }

    setNextRevision({
      ...nextRevision,
      isRequiredPatientSign: !nextRevision.isRequiredPatientSign,
    })
  }, [nextRevision])

  const uploadDocuSign = useCallback(
    async ({
      fileName,
      trialUid,
      trialHospitalUid,
      formData,
      type,
    }: {
      fileName: string
      trialUid: string
      trialHospitalUid: string
      formData: FormData
      type: IcfDocumentType
    }) => {
      if (!nextRevision) {
        return
      }
      const docusignResp = await uploadDocuSignFile({
        trialUid,
        trialHospitalUid,
        params: {
          formData,
          type,
        },
      })
      setNextRevision({
        ...nextRevision,
        fileName,
        dsTemplateId: docusignResp.dsTemplateId,
        tempFileUid: docusignResp.tempFileUid,
      })
      setTempFileUrl(docusignResp.fileUrl)
    },
    [nextRevision],
  )

  const uploadTemp = useCallback(
    async ({
      fileName,
      trialUid,
      trialHospitalUid,
      params,
    }: {
      fileName: string
      trialUid: string
      trialHospitalUid: string
      params: FormData
    }) => {
      if (!nextRevision) {
        return
      }
      const tempFileResp = await uploadTempFile({
        trialUid,
        trialHospitalUid,
        params,
      })

      setNextRevision({
        ...nextRevision,
        fileName,
        tempFileUid: tempFileResp.tempFileUid,
      })
      setTempFileUrl(tempFileResp.fileUrl)
    },
    [nextRevision],
  )

  const onSelectFile = useCallback(
    async (file: File) => {
      if (!nextRevision) {
        return
      }
      if (!selectedTrialHospitalUid) {
        throw new Error('trialHospitalUid does not set')
      }

      const fileSizeError = validateIcfDocument({
        key: 'fileSize',
        value: file,
      })
      if (fileSizeError !== null) {
        setErrors({ ...errors, fileSize: fileSizeError.fileSize })
        return
      }

      setErrors({ ...errors, fileSize: undefined })

      try {
        const formData = new FormData()
        formData.append('file', file)

        isDocuSignType(nextRevision.type)
          ? await uploadDocuSign({
              fileName: file.name,
              trialUid,
              trialHospitalUid: selectedTrialHospitalUid,
              formData: formData,
              type: nextRevision.type,
            })
          : await uploadTemp({
              fileName: file.name,
              trialUid,
              trialHospitalUid: selectedTrialHospitalUid,
              params: formData,
            })
      } catch (e) {
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }

      setErrors({})
    },
    [
      nextRevision,
      trialUid,
      selectedTrialHospitalUid,
      uploadDocuSign,
      uploadTemp,
      errors,
      showError,
    ],
  )

  const onValidate = useCallback((): boolean => {
    if (!nextRevision) {
      return false
    }

    //validationがsubmit直前に呼び出されるという前提の元、ここでsubmitTriedの値を更新する
    setSubmitTried(true)

    const errors = validateIcfDocument(
      { key: 'name', value: nextRevision.name },
      { key: 'version', value: nextRevision.version },
      { key: 'fileName', value: nextRevision.fileName },
    )

    if (errors !== null) {
      setErrors(errors)
      return false
    }

    return true
  }, [nextRevision])

  const onSubmit = useCallback(async () => {
    if (!nextRevision || !icfDocument) {
      return
    }
    if (!selectedTrialHospitalUid) {
      throw new Error('trialHospitalUid does not set')
    }

    //バージョンが1~99の整数値で設定された場合は"n.0"の形式に変換して更新する
    const validIntegerRegex = /^[1-9][0-9]?$/

    try {
      await createIcfDocumentRevision({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid,
        params: {
          ...nextRevision,
          version: !!nextRevision.version.match(validIntegerRegex)
            ? `${nextRevision.version}.0`
            : nextRevision.version,
        },
      })
      //改版後、編集ページにて前の版が一瞬表示されるのを防ぐため、latestRevisionUidを作成したものに更新しておく。
      //編集ページで再検証はされるのでここではキャッシュの更新のみを行っておく
      mutateIcfDocument(
        { ...icfDocument, latestRevisionUid: nextRevision.uid },
        false,
      )
      showSuccess('改版を登録しました')
      navigate(editPath)
    } catch (e) {
      submitModalState.handlers.closeModal()
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [
    nextRevision,
    icfDocument,
    selectedTrialHospitalUid,
    trialUid,
    mutateIcfDocument,
    showSuccess,
    navigate,
    editPath,
    submitModalState.handlers,
    showError,
  ])

  const onCancel = useCallback(() => {
    navigate(editPath)
  }, [navigate, editPath])

  useEffect(() => {
    if (!nextRevisionTemplate) {
      return
    }

    setNextRevision({
      ...nextRevisionTemplate,
      uid: generateUUID(),
      fileName: '',
      tempFileUid: '',
    })
  }, [nextRevisionTemplate])

  useEffect(() => {
    setSubmitTried(false)
  }, [nextRevision])

  const filePreviewUrl = tempFileUrl ?? ''
  const hasTempFile = !!tempFileUrl

  return {
    nextRevision,
    icfDocument,
    editing,
    filePreviewUrl,
    listPath,
    editPath,
    errors,
    shouldSelectHospital,
    hospitalName,
    hasMultipleRevisionHistories,
    hasTempFile,
    pullDownState,
    submitModalState,
    onChangeName,
    onChangeVersion,
    onChangeRequiredPatientSign,
    onSelectFile,
    onValidate,
    onSubmit,
    onCancel,
  }
}
