import {
  CreateAgreementFormEnvelopesDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const createAgreementFormEnvelopes = async ({
  explanationSessionUid,
  explanationDocRevisionUids,
}: {
  explanationSessionUid: string
  explanationDocRevisionUids: string[]
}): Promise<void> => {
  try {
    await graphqlRequest(CreateAgreementFormEnvelopesDocument, {
      input: {
        explanationSessionUid,
        explanationDocRevisionUids,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useCreateAgreementFormEnvelopes = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationSessionUid,
      explanationDocRevisionUids,
    }: {
      explanationSessionUid: string
      explanationDocRevisionUids: string[]
    }) =>
      createAgreementFormEnvelopes({
        explanationSessionUid,
        explanationDocRevisionUids,
      }),
    option,
  )
}
