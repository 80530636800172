import { useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import PinInputImage from 'src/assets/image/pin-input.svg'
import { PatientSimpleScreenContainer } from 'src/components/PatientSimpleScreenContainer/PatientSimpleScreenContainer'
import { PinInput } from 'src/components/PinInput/PinInput'
import { SendOTPByVoiceModal } from 'src/features/explanationRoom/components/OTPModal/OTPModal'
import { definitions } from 'src/lib/api-client/schema.gen'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useResendAuthenticationCode } from '../../api/resendAuthentiationCode'
import { useVerifyOTP } from '../../api/verifyOTP'
import { PATIENT_OTP_TOKEN_LENGTH } from '../../constants/oPTSetting'
import { otpVerificationMessage } from '../../utils/signAuthMessage'

type Props = {
  trialUid: string
  explanationSignAuthUid: string
  mutateChallenge: () => void
  isSP: boolean
}

export const OTPForm: React.FC<Props> = ({
  trialUid,
  explanationSignAuthUid,
  mutateChallenge,
  isSP,
}) => {
  const [code, setCode] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [verificationResult, setVerificationResult] =
    useState<definitions['output.SignAuthVerificationResult']>()

  const { request: verify, requesting } = useVerifyOTP({
    onSuccess: data => {
      setVerificationResult(data.result)
      if (data.result === 'Verified') {
        mutateChallenge()
      }
      setCode('')
    },
    onError: () => {
      setCode('')
    },
  })

  const toast = useMirohaToast()

  const { request: resend } = useResendAuthenticationCode({
    onSuccess: () => {
      toast({
        title: '携帯電話番号宛に認証コードを再送しました',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const onSubmit = async () => {
    if (code?.length !== PATIENT_OTP_TOKEN_LENGTH) {
      return
    }

    await verify({
      trialUid,
      explanationSignAuthUid,
      otpCode: code,
    })
  }

  const errorMessage = otpVerificationMessage(verificationResult)

  return (
    <PatientSimpleScreenContainer>
      <Stack spacing="8">
        <Center>
          <img src={PinInputImage} alt="暗証番号の入力" />
        </Center>
        <Text fontSize="lg" textAlign="center" fontWeight="bold">
          次に携帯電話番号宛てに届いた6ケタの認証コードを入力してください
        </Text>

        <Flex justify="center">
          <Stack align="start">
            <Text>SMS認証コードはSMSで携帯電話番号に送信しました</Text>
            <Button variant="text" onClick={onOpen} p="0">
              SMSが届かない場合
            </Button>
          </Stack>
        </Flex>
        <HStack justify="center">
          <PinInput
            otp
            length={PATIENT_OTP_TOKEN_LENGTH}
            size="sm"
            value={code}
            onChange={setCode}
          />
        </HStack>
        {!!errorMessage && (
          <Box>
            {errorMessage.split('\\n').map(m => (
              <Text key={m} color="red.500">
                {m}
              </Text>
            ))}
          </Box>
        )}
        <Center textAlign="center" w="full" flexDirection="column">
          <Button
            colorScheme="green"
            onClick={onSubmit}
            isDisabled={code?.length !== PATIENT_OTP_TOKEN_LENGTH || requesting}
            size="sp"
          >
            認証
          </Button>
          <Button
            mt="8"
            variant="text"
            onClick={async () => {
              await resend({ trialUid, explanationSignAuthUid })
            }}
            size="sp"
          >
            認証コードを再送
          </Button>
        </Center>
      </Stack>

      <SendOTPByVoiceModal
        trialUid={trialUid}
        explanationSignAuthUid={explanationSignAuthUid}
        isSP={isSP}
        isOpen={isOpen}
        onClose={onClose}
      />
    </PatientSimpleScreenContainer>
  )
}
