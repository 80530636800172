import {
  ExplanationRevisionStatusV2,
  ExpDocRevAgreementFormForSessionInSignFlowFragment,
} from 'src/lib/gql-client'

export const isAllSignCompleted = (props: {
  explanationRevisionStatus: ExplanationRevisionStatusV2
  hasNotCompletedAgreement: boolean
  agreementForms: ExpDocRevAgreementFormForSessionInSignFlowFragment[]
}) => {
  const {
    explanationRevisionStatus,
    hasNotCompletedAgreement,
    agreementForms,
  } = props

  const agreementCompletedStatuses: ExplanationRevisionStatusV2[] = [
    'AgreementCompleted',
    'DeliveryCompleted',
  ]

  if (agreementForms.length === 0) return false

  return (
    agreementCompletedStatuses.includes(explanationRevisionStatus) ||
    (!hasNotCompletedAgreement &&
      explanationRevisionStatus === 'SignCompleted') ||
    (agreementForms.filter(af => af.isPatientConsentRequired).length === 0 &&
      !hasNotCompletedAgreement &&
      explanationRevisionStatus === 'SignReady')
  )
}
