import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { SignAuthResult } from '../../types'
import { parseSignAuthResult } from '../parser'

const challengeSignAuth = async ({
  trialUid,
  explanationSignAuthUid,
}: {
  trialUid: Trial['uid']
  explanationSignAuthUid: string
}): Promise<SignAuthResult> => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/challenge',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return parseSignAuthResult(res.data)
}

export const useChallengeSignAuth = (
  options?: UseMutationOption<SignAuthResult>,
) => {
  return useMutation(
    ({
      trialUid,
      explanationSignAuthUid,
    }: {
      trialUid: Trial['uid']
      explanationSignAuthUid: string
    }) => challengeSignAuth({ trialUid, explanationSignAuthUid }),
    options,
  )
}
