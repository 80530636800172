import React from 'react'

import { Box, Button } from '@chakra-ui/react'
import { Add } from 'src/components/icon'

type Props = {
  onAdd: () => void
}

export const AddMediumItem: React.FC<Props> = props => {
  const { onAdd } = props

  return (
    <Box bg="blue.50" p="5">
      <Button variant="outline" leftIcon={<Add />} onClick={onAdd}>
        新規セクション作成
      </Button>
    </Box>
  )
}
