import React from 'react'

import { useSelector } from 'react-redux'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { PatientListScreen } from 'src/features/patient/components/PatientListScreen/PatientListScreen'
import { WorksheetDetailScreen } from 'src/features/worksheet/components'
import { PatientDetailLayout } from 'src/layouts/PatientDetailLayout/PatientDetailLayout'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { patientIdParamName, PatientDetail } from './detail/patient-detail'
import { Home } from './home'
import { getRelativePath } from '../../../../../constants/paths'
import { usePermission } from '../common/permission'

export const Patient = () => {
  const selectedTrial = useSelector(getSelectedTrial)!

  const { hasPermission } = usePermission()

  return (
    <Routes>
      <Route
        path={`:${patientIdParamName}/*`}
        element={
          selectedTrial.featureFlags.eSourceV2 ? (
            <NewPatientDetailRouter />
          ) : (
            <PatientDetail />
          )
        }
      />
      <Route
        path={`/`}
        element={
          selectedTrial.featureFlags.eSourceV2 ? (
            <PatientListScreen />
          ) : (
            <Home
              trialUid={selectedTrial.uid}
              canAddPatient={hasPermission(PERMISSIONS.Patient_Add)}
              pdfOutputEnabled={
                selectedTrial.featureFlags.eSourceV1 &&
                selectedTrial.pdfOutputEnabled &&
                hasPermission(PERMISSIONS.Patient_Download)
              }
            />
          )
        }
      />
      <Route path="*" element={<Navigate replace to={`/`} />} />
    </Routes>
  )
}

const NewPatientDetailRouter: React.FC = () => {
  const { patientUid } = useParams<{
    [patientIdParamName]: string
  }>()

  return (
    <PatientDetailLayout patientUid={patientUid!}>
      <Routes>
        <Route path={`/`} element={<Empty />} />
        <Route
          path={getRelativePath('Patient', 'PatientWorksheet')}
          element={<WorksheetDetailScreen />}
        />
        <Route path="*" element={<Navigate replace to={`/`} />} />
      </Routes>
    </PatientDetailLayout>
  )
}

// TODO: 別途画面を用意（空白 or <公開templateがなければその旨を示す & 権限があればtemplateがない場合作成を促す>）
const Empty = () => null
