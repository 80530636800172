import { QuestionnaireSection } from './entity'

export const createFixture = (
  values?: Partial<QuestionnaireSection>,
): QuestionnaireSection => {
  const res: QuestionnaireSection = {
    uid: 'QuestionnaireSectionUid',
    title: 'セクションタイトル',
    index: 0,
    fieldMap: {},
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof QuestionnaireSection
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}

export const fixture: QuestionnaireSection = {
  uid: 'QuestionnaireSectionUid',
  title: 'セクションタイトル',
  index: 0,
  fieldMap: {},
}
