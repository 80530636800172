import React from 'react'

import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import OutsideClickHandler from 'react-outside-click-handler'
import 'dayjs/locale/ja'
import {
  PaginationBackBlue,
  PaginationNextBlue,
} from 'src/assets/icon/multicolor'
import { sleep } from 'src/utils/sleep'
import styled from 'styled-components'

import { gray, green, red, white } from '../base/color/palette'

dayjs.locale('ja')

// FIXME: valueを受け取るようにする。-> 選択状態の日付をactiveにするため。
type Props = {
  onChange: (date: Date) => void
  onClose: () => void
  disabled?: boolean
}

export const CalendarComponent: React.FC<Props> = props => {
  const onChange = (date: Date | Date[], e: React.SyntheticEvent) => {
    if (!Array.isArray(date)) {
      props.onChange(date)
    }

    props.onClose()
  }

  const onClickOutside = () => {
    //即座にonCloseを実行してしまうとカレンダーを表示するためのボタンを再度押しても
    //カレンダーが表示されたままになってしまうため、遅延させている
    const animate = async () => {
      await sleep(10)
      props.onClose()
    }

    animate()
  }

  //type FormatterCallback = (locale: string, date: Date) => string;
  //cf. node_modules/@types/react-calendar/index.d.ts
  const formatDay = (_: string, date: Date) => dayjs(date).format('D')
  const formatMonthYear = (_: string, date: Date) =>
    dayjs(date).format('YYYY/MM')
  const formatShortWeekDay = (_: string, date: Date) =>
    dayjs(date).format('ddd')

  return (
    <OutsideClickHandler
      onOutsideClick={onClickOutside}
      disabled={!!props.disabled}
    >
      <CalendarContainer>
        <Calendar
          onChange={onChange}
          defaultActiveStartDate={new Date()}
          calendarType={'US'}
          minDetail={'month'}
          prev2Label={null}
          next2Label={null}
          formatDay={formatDay}
          formatMonthYear={formatMonthYear}
          formatShortWeekday={formatShortWeekDay}
        />
      </CalendarContainer>
    </OutsideClickHandler>
  )
}

// font-familyが上書きされていくのでより狭いスコープでもfont-familyの指定を行う
const CalendarContainer = styled.div`
  .react-calendar {
    width: 240px;
    color: ${gray[100]};
    border: 2px solid ${gray[40]};
    border-radius: 2px;
    padding: 8px;
    box-sizing: border-box;
    user-select: none;
  }

  // ナビゲーション部分
  .react-calendar__navigation {
    height: 16px;
    margin-bottom: 16px;
  }
  .react-calendar__navigation__label {
    font-family: 'Noto Sans JP';
    font-size: 14px;
    color: ${gray[100]};
    background: none;
    flex-grow: unset;
    padding: 0 16px;
  }
  .react-calendar__navigation__label[disabled] {
    background-color: rgba(0, 0, 0, 0);
  }
  .react-calendar__navigation button:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  .react-calendar__navigation button:focus {
    background-color: rgba(0, 0, 0, 0);
  }
  .react-calendar__navigation__prev-button {
    flex-grow: 1;
    background-image: url(${PaginationBackBlue});
    background-repeat: no-repeat;
    background-position: right;
    color: rgba(0, 0, 0, 0);
  }
  .react-calendar__navigation__next-button {
    flex-grow: 1;
    background-image: url(${PaginationNextBlue});
    background-repeat: no-repeat;
    background-position: left;
    color: rgba(0, 0, 0, 0);
  }

  // 曜日表示部分
  .react-calendar__month-view__weekdays__weekday {
    font-family: 'Noto Sans JP';
    font-size: 14px;
    font-weight: normal;
    padding-top: 0;
    abbr[title] {
      text-decoration: none;
    }
    abbr[title='土曜日'] {
      color: ${red[70]};
    }
    abbr[title='日曜日'] {
      color: ${red[70]};
    }
  }

  // 日付表示部分
  .react-calendar__month-view__days__day {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    box-sizing: border-box;
  }
  .react-calendar__tile {
    font-family: 'Noto Sans JP';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-calendar__tile--now {
    background: ${green[5]};
  }
  .react-calendar__tile:enabled:focus {
    background: ${green[40]};
  }
  .react-calendar__tile:hover {
    background: ${green[10]};
  }
  .react-calendar__month-view__days__day {
    color: ${gray[100]};
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${red[70]};
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${gray[55]};
  }
  .react-calendar__tile--active {
    background: ${green[40]};
    color: ${white};
  }
  .react-calendar__tile--active:enabled:hover {
    color: ${gray[100]};
    background: ${green[5]};
    border: 1px solid ${green[40]};
    box-sizing: border-box;
  }
`
