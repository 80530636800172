import React, { useRef, useState } from 'react'

import { Text, IconButton } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { blue, gray, Palette, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { AnchorLink, ButtonLink } from 'src/components/base/text-link'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Radio, RadioItem } from 'src/components/form-redesigned/radio'
import { SingleCheckboxWithoutValue } from 'src/components/form-redesigned/single-checkbox'
import { Check, Delete, Edit } from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import { Spinner } from 'src/components/spinner/spinner'
import { useSelectedTrial } from 'src/features/auth/context'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { zIndex } from 'src/modules/util/z-index'
import styled, { css } from 'styled-components'

import { DocuSignAgreementFormPreviewModal } from './docusign-agreement-form-preview-modal'
import { DocuSignCheckUnderstandingPreviewModal } from './docusign-check-understanding-preview-modal'
import {
  IcfDocumentType,
  icfDocumentTypes,
  icfDocumentTypeText,
  IcfDocument,
  IcfDocumentSignerRole,
  icfDocumentSignerRoles,
  icfDocumentRoleText,
  isDocuSignType,
  IcfDocumentCheckRole,
  icfDocumentCheckRoles,
} from '../entity'
import { IcfDocumentErrors } from '../validate'

// 各項目の中身を見えやすくするための helper-type
type Identity<T> = T extends {}
  ? {
      [P in keyof T]: T[P]
    }
  : never

type Props =
  | Identity<NewProps>
  | Identity<EditProps>
  | Identity<AddRevisionProps>
  | Identity<ReadOnlyProps>

type PropsBase = {
  name: string
  documentType: IcfDocumentType
  fileName: string
  version: string
  isRequiredPatientSign: boolean
  filePreviewUrl?: string
  signerRoles?: IcfDocumentSignerRole[]
  checkRoles?: IcfDocumentCheckRole[]
  errors?: IcfDocumentErrors
  hasTempFile: boolean
}

type OnChangeProps = {
  onChangeVersion: (value: string) => void
  onChangeName: (value: string) => void
  onChangeDocumentType: (type: IcfDocumentType) => void
  onSelectFile: (file: File) => Promise<void>
  onChangeRequiredPatientSign: () => void
}

type NewProps = {
  cardType: 'new'
  numberingId?: undefined
  onResetFile: () => void
} & OnChangeProps &
  Partial<PropsBase>

type EditProps = {
  cardType: 'edit'
  numberingId: IcfDocument['numberingId']
  canChangeDocumentType: boolean
  hasMultipleRevisionHistories: boolean
  canSetDocuSignRole: boolean
  hasSetDocuSignRole: boolean
  onRedirectDocuSignEdit: () => void
  openRevisionHistory: () => void
} & OnChangeProps &
  PropsBase

// 文書タイプ以外変更可能
type AddRevisionProps = {
  cardType: 'addRevision'
  numberingId: IcfDocument['numberingId']
  hasMultipleRevisionHistories: boolean
  openRevisionHistory: () => void
} & Omit<OnChangeProps, 'onChangeDocumentType'> &
  PropsBase

type ReadOnlyProps = {
  cardType: 'readOnly'
  numberingId: IcfDocument['numberingId']
  filePreviewUrl: string //readOnlyでは必須
  onRedirectDocuSignPreview: (
    role: IcfDocumentSignerRole | IcfDocumentCheckRole,
  ) => void
  disabled?: boolean
  hasMultipleRevisionHistories: boolean
  openRevisionHistory: () => void
} & PropsBase

const isDocumentType = (num: number): num is IcfDocumentType => {
  return (
    Object.values(icfDocumentTypes).find(
      documentType => documentType === num,
    ) !== undefined
  )
}

const documentTypeDescriptionMap: Record<IcfDocumentType, string> = {
  [icfDocumentTypes.Undefined]: '',
  [icfDocumentTypes.AgreementForm]: 'DocuSignで署名ありの文書を登録します。',
  [icfDocumentTypes.CheckUnderstanding]:
    'DocuSignで理解度確認文書を登録します。',
  [icfDocumentTypes.Description]: '説明文書を登録します。',
  [icfDocumentTypes.Video]: '説明動画ファイルを登録します。',
}

export const IcfDocumentCard: React.FC<Props> = props => {
  const [canSelectFile, setCanSelectFile] = useState(
    props.cardType === 'new' || props.fileName === '',
  )
  const [showSpinner, setShowSpinner] = useState(false)

  const { selectedTrial } = useSelectedTrial()
  const radioItems: RadioItem[] = (() => {
    const allItems = Object.values(icfDocumentTypes)
      .filter(documentType => documentType !== icfDocumentTypes.Undefined)
      .map(documentType => ({
        name: icfDocumentTypeText[documentType],
        value: documentType,
      }))
    if (selectedTrial?.featureFlags.eConsentNewSignFlow) {
      return allItems.filter(
        item => item.value !== icfDocumentTypes.CheckUnderstanding,
      )
    }
    return allItems
  })()

  const {
    modalOpen: agreementPreviewModalOpen,
    handlers: {
      openModal: openAgreementPreviewModal,
      closeModal: closeAgreementPreviewModal,
    },
  } = useOpenModal()

  const {
    modalOpen: checkUnderStandingPreviewModalOpen,
    handlers: {
      openModal: openCheckUnderstandingPreviewModal,
      closeModal: closeCheckUnderstandingPreviewModal,
    },
  } = useOpenModal()

  const canEditDocumentType =
    (props.cardType === 'new' && !props.fileName) ||
    (props.cardType === 'edit' && props.canChangeDocumentType)

  const isDocumentTypeUndefined =
    props.documentType === undefined ||
    props.documentType === icfDocumentTypes.Undefined

  const onChangeDocumentTypeRadioValue = (value: string) => {
    if (
      !(
        props.cardType === 'new' ||
        (props.cardType === 'edit' && props.canChangeDocumentType)
      )
    ) {
      return
    }

    const num = Number(value)
    if (isDocumentType(num)) {
      props.onChangeDocumentType(num)
    }
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  const onClickFileSelectButton = () => {
    fileInputRef.current?.click()
  }

  const startFileEdit = () => {
    setCanSelectFile(true)
  }

  const completeFileEdit = () => {
    setCanSelectFile(false)
  }

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.cardType === 'readOnly') {
      return
    }
    if (!e.target.files || !e.target.files[0]) {
      return
    }

    try {
      setShowSpinner(true)
      await props.onSelectFile(e.target.files[0])
      setShowSpinner(false)
      setCanSelectFile(false)
    } catch {
      setShowSpinner(false)
    }
  }

  const resetFile = () => {
    if (props.cardType !== 'new') {
      return
    }

    props.onResetFile()
    setCanSelectFile(true)
  }

  const isInitialCardState =
    props.cardType === 'new' &&
    !props.name &&
    !props.documentType &&
    props.version === '1.0'
  const backgroundColor: Palette =
    props.cardType === 'readOnly' ? white : isInitialCardState ? white : blue[5]

  return (
    <CardContainer backgroundColor={backgroundColor}>
      {showSpinner && (
        <SpinnerBackground>
          <Spinner />
        </SpinnerBackground>
      )}
      {agreementPreviewModalOpen &&
        props.cardType === 'readOnly' &&
        !!props.signerRoles && (
          <DocuSignAgreementFormPreviewModal
            onClose={closeAgreementPreviewModal}
            onRedirect={props.onRedirectDocuSignPreview}
            signerRoles={props.signerRoles}
          />
        )}
      {checkUnderStandingPreviewModalOpen &&
        props.cardType === 'readOnly' &&
        !!props.checkRoles && (
          <DocuSignCheckUnderstandingPreviewModal
            onClose={closeCheckUnderstandingPreviewModal}
            onRedirect={props.onRedirectDocuSignPreview}
            checkRoles={props.checkRoles}
          />
        )}

      {props.numberingId !== undefined && (
        <ItemsContainer>
          <ItemContainer>
            <Item>
              <Label bold>文書ID</Label>
            </Item>
            <Item>
              <Text
                color={
                  props.cardType === 'readOnly' && props.disabled
                    ? gray[55]
                    : undefined
                }
              >
                {props.numberingId}
              </Text>
            </Item>
          </ItemContainer>
        </ItemsContainer>
      )}

      <ItemsContainer>
        <ItemContainer>
          <Item>
            <Label bold>版数</Label>
          </Item>
          <Item flex>
            <div>
              {props.cardType === 'readOnly' ? (
                <Text color={props.disabled ? gray[55] : undefined}>
                  {props.version}
                </Text>
              ) : (
                <Input
                  width={100}
                  value={props.version ?? ''}
                  onChange={e => props.onChangeVersion(e.target.value)}
                  showWarning
                  placeholder="1.0"
                />
              )}
            </div>
            {props.cardType !== 'new' && props.hasMultipleRevisionHistories && (
              <>
                <Spacer size={16} horizontal />
                <ButtonLink onClick={props.openRevisionHistory}>
                  改版履歴
                </ButtonLink>
              </>
            )}
          </Item>
        </ItemContainer>
        {props.errors?.version && (
          <ErrorMessage errorMessage={props.errors.version} />
        )}
      </ItemsContainer>

      <ItemsContainer>
        <ItemContainer>
          <Item>
            <Label bold>文書名</Label>
          </Item>
          <Item>
            {props.cardType === 'readOnly' ? (
              <Text color={props.disabled ? gray[55] : undefined}>
                {props.name}
              </Text>
            ) : (
              <Input
                maxWidth={400}
                value={props.name ?? ''}
                onChange={e => props.onChangeName(e.target.value)}
                warning={!props.name}
              />
            )}
          </Item>
        </ItemContainer>
        {props.errors?.name && (
          <ErrorMessage errorMessage={props.errors.name} />
        )}
      </ItemsContainer>

      <ItemsContainer>
        <ItemContainer>
          <Item>
            <Label bold>文書の種類</Label>
          </Item>
          <Item>
            <Radio
              disabled={!canEditDocumentType}
              items={radioItems}
              onChange={onChangeDocumentTypeRadioValue}
              selectedValue={props.documentType}
              warning
            />
          </Item>
        </ItemContainer>
        {props.documentType !== undefined && (
          <ItemContainer>
            <FlexSpacer />
            <Item>
              <Text fontSize="sm">
                {documentTypeDescriptionMap[props.documentType]}
              </Text>
            </Item>
          </ItemContainer>
        )}
        {props.documentType === icfDocumentTypes.AgreementForm && (
          <ItemContainer>
            <FlexSpacer />
            <Item>
              <SingleCheckboxWithoutValue
                onChange={
                  props.cardType === 'readOnly'
                    ? undefined
                    : props.onChangeRequiredPatientSign
                }
                checked={!!props.isRequiredPatientSign}
                label="患者の同意必須"
                disabled={props.cardType === 'readOnly'}
              />
            </Item>
          </ItemContainer>
        )}
        {props.errors?.documentType && (
          <ErrorMessage errorMessage={props.errors.documentType} />
        )}
      </ItemsContainer>

      {!isDocumentTypeUndefined && (
        <ItemsContainer>
          <ItemContainer>
            <Item>
              <Label bold>ファイル名</Label>
            </Item>
            <Item flex>
              {canSelectFile || props.errors?.fileSize ? (
                <Input
                  maxWidth={300}
                  placeholder={
                    props.documentType === icfDocumentTypes.Video
                      ? 'mp4ファイルを選択してください'
                      : 'PDFファイルを選択してください'
                  }
                  value={props.fileName ?? ''}
                  readOnly
                />
              ) : (
                // NOTE:動画のコンバートに時間がかかるため、アップロード直後にリンクをクリックされるとエラーになる。
                // それを防ぐため、動画の場合はリンクを付けない。
                <Item flex>
                  {props.hasTempFile &&
                  props.documentType === icfDocumentTypes.Video ? (
                    <Text fontSize="sm">{props.fileName}</Text>
                  ) : (
                    <AnchorLink
                      href={props.filePreviewUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.fileName}
                    </AnchorLink>
                  )}

                  {props.cardType !== 'readOnly' && props.fileName && (
                    <>
                      <Spacer size={16} horizontal />
                      <IconButton
                        aria-label="edit"
                        variant="customIconButtonGhost"
                        icon={<Edit />}
                        onClick={startFileEdit}
                      />
                    </>
                  )}
                  {props.cardType === 'new' && props.fileName && (
                    <>
                      <Spacer size={16} horizontal />
                      <IconButton
                        aria-label="trash"
                        variant="customIconButtonGhost"
                        icon={<Delete />}
                        onClick={resetFile}
                      />
                    </>
                  )}
                </Item>
              )}
              {props.cardType !== 'readOnly' &&
                (canSelectFile || !!props.errors?.fileSize) && (
                  <>
                    <Spacer size={16} horizontal />
                    <Button
                      size="S"
                      buttonType="normal"
                      text="ファイル選択"
                      onClick={onClickFileSelectButton}
                    />
                    <HiddenFileInput
                      id="file"
                      type="file"
                      accept={
                        props.documentType === icfDocumentTypes.Video
                          ? '.mp4'
                          : '.pdf'
                      }
                      ref={fileInputRef}
                      onChange={e => onChangeFile(e)}
                    />
                    <Spacer size={16} horizontal />
                    {props.fileName && (
                      <IconButton
                        aria-label="check"
                        variant="customIconButtonGhost"
                        icon={<Check />}
                        onClick={completeFileEdit}
                      />
                    )}
                  </>
                )}
            </Item>
          </ItemContainer>
          {props.errors?.fileName && (
            <ErrorMessage errorMessage={props.errors.fileName} />
          )}
          {props.errors?.fileSize && (
            <ErrorMessage errorMessage={props.errors.fileSize} />
          )}
        </ItemsContainer>
      )}

      {!!props.documentType &&
        isDocuSignType(props.documentType) &&
        props.fileName && (
          <ItemsContainer>
            {props.documentType === icfDocumentTypes.AgreementForm &&
              !!props.signerRoles &&
              !canSelectFile && (
                <ItemContainer>
                  <Item>
                    <Label bold>署名ロール</Label>
                  </Item>
                  <Item flex>
                    {Object.values(icfDocumentSignerRoles).map(role => (
                      <RoleTextContainer key={role}>
                        <Text
                          fontSize="sm"
                          color={
                            props.signerRoles?.includes(role)
                              ? blue[70]
                              : gray[55]
                          }
                          fontWeight="bold"
                        >
                          {icfDocumentRoleText[role]}
                        </Text>
                      </RoleTextContainer>
                    ))}
                  </Item>
                </ItemContainer>
              )}

            {props.documentType === icfDocumentTypes.CheckUnderstanding &&
              !!props.checkRoles &&
              !canSelectFile && (
                <ItemContainer>
                  <Item>
                    <Label bold>確認項目</Label>
                  </Item>
                  <Item flex>
                    {Object.values(icfDocumentCheckRoles).map(role => (
                      <RoleTextContainer key={role}>
                        <Text
                          fontSize="sm"
                          color={
                            props.checkRoles?.includes(role)
                              ? blue[70]
                              : gray[55]
                          }
                          fontWeight="bold"
                        >
                          {icfDocumentRoleText[role]}
                        </Text>
                      </RoleTextContainer>
                    ))}
                  </Item>
                </ItemContainer>
              )}

            {props.cardType === 'edit' && !props.canSetDocuSignRole && (
              <ItemContainer>
                <FlexSpacer />
                <Item>
                  {props.documentType === icfDocumentTypes.AgreementForm ? (
                    <Text fontSize="sm" fontWeight="bold">
                      *署名欄は一時保存後、設定することができます。
                    </Text>
                  ) : props.documentType ===
                    icfDocumentTypes.CheckUnderstanding ? (
                    <Text fontSize="sm" fontWeight="bold">
                      *確認項目は一時保存後、設定することができます。
                    </Text>
                  ) : null}
                </Item>
              </ItemContainer>
            )}

            {props.cardType === 'edit' &&
              props.canSetDocuSignRole &&
              props.onRedirectDocuSignEdit && (
                <ItemContainer>
                  <FlexSpacer />
                  <Item>
                    <Button
                      size="S"
                      text={props.hasSetDocuSignRole ? '設定更新' : '項目設定'}
                      buttonType="normal"
                      onClick={props.onRedirectDocuSignEdit}
                      disabled={canSelectFile}
                    />
                  </Item>
                </ItemContainer>
              )}

            {!canSelectFile &&
              props.hasTempFile &&
              (props.cardType === 'new' ||
                props.cardType === 'addRevision') && (
                <ItemContainer>
                  <FlexSpacer />
                  <Item>
                    {props.documentType === icfDocumentTypes.AgreementForm ? (
                      <Text fontSize="sm" fontWeight="bold">
                        *署名欄は登録処理後、文書編集ページで設定が必要です。
                      </Text>
                    ) : props.documentType ===
                      icfDocumentTypes.CheckUnderstanding ? (
                      <Text fontSize="sm" fontWeight="bold">
                        *回答項目は登録処理後、文書編集ページで設定が必要です。
                      </Text>
                    ) : null}
                  </Item>
                </ItemContainer>
              )}

            {props.cardType === 'readOnly' && (
              <ItemContainer>
                <FlexSpacer />
                <Item>
                  <Button
                    size="S"
                    text="DocuSign設定確認"
                    buttonType="normal"
                    onClick={
                      props.documentType === icfDocumentTypes.AgreementForm
                        ? openAgreementPreviewModal
                        : props.documentType ===
                            icfDocumentTypes.CheckUnderstanding
                          ? openCheckUnderstandingPreviewModal
                          : undefined
                    }
                  />
                </Item>
              </ItemContainer>
            )}
          </ItemsContainer>
        )}
    </CardContainer>
  )
}

const ErrorMessage: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <ItemContainer>
      <FlexSpacer />
      <Item>
        <Message type="error" message={errorMessage} />
      </Item>
    </ItemContainer>
  )
}

const CardContainer = styled.div<{ backgroundColor: Palette }>`
  width: 100%;
  background-color: ${p => p.backgroundColor};
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid ${gray[40]};
  border-radius: 10px;

  position: relative;
`

const SpinnerBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.spinnerBackground};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
`

const ItemsContainer = styled.div`
  margin-bottom: 24px;
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > div:nth-child(1) {
    flex: 1;
  }

  & > div:nth-child(2) {
    flex: 4;
  }
`

const Item = styled.div<{ flex?: boolean }>`
  ${p => p.flex && flexItemStyle}
`

const flexItemStyle = css`
  display: flex;
  align-items: center;
  word-break: break-all;
`

const FlexSpacer = styled.div`
  flex: 1;
`

const HiddenFileInput = styled.input`
  display: none;
`

const RoleTextContainer = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 8px;
  }
`
