import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { blue, gray, red } from 'src/components/base/color/palette'
import Modal from 'src/Modal'
import styled from 'styled-components'

type Props = {
  onClose: () => void
  password: string
}

export const PasswordDisplayModal: React.FC<Props> = props => {
  return (
    <Modal onClose={props.onClose} closable={false}>
      <ModalContent>
        <div>
          <Text fontSize="lg" fontWeight="bold">
            パスワードを発行しました
          </Text>
        </div>
        <div>
          <DisplayPassword>
            <Text color={gray[80]}>ログインパスワード</Text>

            <Text
              fontSize="2xl"
              fontWeight="bold"
              id="autify-epro-login-password"
            >
              {props.password}
            </Text>
          </DisplayPassword>
        </div>
        <div>
          <Text color={red[50]}>
            ※画面を閉じるとパスワードを再表示することはできません。
          </Text>
        </div>
        <div>
          <Button
            text="閉じる"
            size="S"
            buttonType="important"
            onClick={props.onClose}
          />
        </div>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:nth-child(1) {
    margin-top: 40px;
  }
  & > div:nth-child(2) {
    margin-top: 30px;
  }
  & > div:nth-child(3) {
    margin-top: 16px;
  }
  & > div:nth-child(4) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`

const DisplayPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${blue[10]};
  border-radius: 4px;
  padding: 16px;
  width: 304px;

  & > div:nth-child(2) {
    margin-top: 4px;
  }
`
