import React from 'react'

import { Box } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { useSelectedTrial } from 'src/features/auth/context'
import { CriteriaDetailScreen } from 'src/features/criteria/components/CriteriaDetailScreen/CriteriaDetailScreen'
import { CriteriaListScreen } from 'src/features/criteria/components/CriteriaListScreen/CriteriaListScreen'
import { MainLayout } from 'src/features/misc/components/MainLayout/MainLayout'
import { NotFound } from 'src/features/misc/components/NotFound/NotFound'
import { useNavigationRoutes } from 'src/features/misc/hooks/useNavigationRoutes'
import { ReferredPatientDetailScreen } from 'src/features/referredPatient/components/ReferredPatientDetialScreen/ReferredPatientDetailScreen'
import { ReferredPatientListScreen } from 'src/features/referredPatient/components/ReferredPatientListScreen/ReferredPatientListScreen'
import { useTrialsWithTrialHospitals } from 'src/features/trial/api/getTrialsWithTrialHospitals'
import { useStickyQuery } from 'src/features/worksheet/hooks/useStickyQuery'
import { WSDataExportV1Screen } from 'src/features/wsdataexport/components'
import { WSDataExportScreen } from 'src/features/wsdataexport/components/WSDataExportScreen/WSDataExportScreen'
import { LegacyMainLayout } from 'src/layouts/LegacyMainLayout/LegacyMainLayout'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { routes as rootRoutes } from 'src/modules/routes'

import { usePermission } from './common/permission'
import { EConsent } from './e-consent/e-consent'
import { Explanation } from './e-consent/explanation/explanation'
import { IcfDocument } from './e-consent/icf-document/icf-document'
import { Epro } from './epro/epro'
import { Patient } from './patient/patient'
import { Crf } from './settings/crf/crf'
import { Member } from './settings/member/member'
import { Settings } from './settings/settings'
import { Trial } from './settings/trial/trial'
import { useInit } from './settings/use-init'
import { Template } from './template/template'
import { getRelativePath, PathKey } from '../../../../constants/paths'
import { SelectTrialContainer } from '../../select-trial/select-trial-container'

export const trialIdParamName = 'trialUid'

export const TrialDetailContent = () => {
  const { trialUid } = useParams<{ [trialIdParamName]: string }>()
  const { stickyQuery } = useStickyQuery()
  const selectedTrial = useSelector(getSelectedTrial)!
  const { hasPermission } = usePermission()
  const { data: trials } = useTrialsWithTrialHospitals({})

  const { routeItems } = useNavigationRoutes()

  if (!trials) return null

  if (selectedTrial.uid !== trialUid) {
    // 選択後のpathにstickyが含まれる場合は自動的にtrialを選択する。
    if (trials.some(trial => trial.uid === trialUid) && !!stickyQuery)
      return <SelectTrialContainer redirectWithPathSelection />

    return <Navigate replace to={rootRoutes.dashboard} />
  }

  const getPath = (path: PathKey) => {
    return getRelativePath('Trial', path)
  }

  // ePROが有効な試験は旧レイアウト（ナビゲーション）を利用する
  // eRecruitとePROを同時に有効にすることはできないので注意
  const showNewNavigation = !selectedTrial.eProEnabled

  // 患者一覧は必ず表示するため現状はリダイレクト先として無条件で設定
  const redirectPath = getPath('Patients')

  // 新しいナビゲーションの場合
  // ナビゲーションが有効なページのみレンダリングする
  if (showNewNavigation) {
    return (
      <Routes>
        <Route
          path="/"
          element={<MainLayout navigationRouteItems={routeItems} />}
        >
          <Route index element={<Navigate to={redirectPath} />} />
          {routeItems.patients.isEnabled && (
            <Route
              path={`${getPath('Patients')}/*`}
              element={<PatientRoutes />}
            />
          )}
          {routeItems.explanations.isEnabled && (
            <Route
              path={`${getPath('Explanations')}/*`}
              element={<ExplanationRoutes />}
            />
          )}
          {routeItems.referredPatients.isEnabled && (
            <Route path="referred-patients">
              <Route index element={<ReferredPatientListScreen />} />
              <Route
                path=":referredPatientUid"
                element={<ReferredPatientDetailScreen />}
              />
            </Route>
          )}
          {routeItems.templates.isEnabled && (
            <Route path={`${getPath('Templates')}/*`} element={<Template />} />
          )}
          {routeItems.icfDocuments.isEnabled && (
            <Route
              path={`${getPath('IcfDocuments')}/*`}
              element={<IcfDocumentRoutes />}
            />
          )}
          {routeItems.criteria.isEnabled && (
            <Route path="/criteria">
              <Route index element={<CriteriaListScreen />} />
              <Route path=":criteriaUid" element={<CriteriaDetailScreen />} />
            </Route>
          )}
          {routeItems.trialInfo.isEnabled && (
            <Route
              path={getPath('SettingsTrial')}
              element={<TrialInfoScreen />}
            />
          )}
          {routeItems.account.isEnabled && (
            <Route
              path={getPath('SettingsMember')}
              element={<MembersScreen />}
            />
          )}
          {routeItems.crf.isEnabled && (
            <Route
              path={`${getPath('SettingsCrf')}/*`}
              element={<CrfRoutes />}
            />
          )}
          {routeItems.csvExport.isEnabled && (
            <Route
              path={getPath('SettingsWSDataExport')}
              element={<CSVExportScreen />}
            />
          )}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    )
  }

  return (
    <LegacyMainLayout>
      <Routes>
        {hasPermission(PERMISSIONS.Menu_ShowSettings) && (
          <Route path={`${getPath('Settings')}/*`} element={<Settings />} />
        )}
        <Route path={`${getPath('Patients')}/*`} element={<Patient />} />
        <Route path={`${getPath('Templates')}/*`} element={<Template />} />
        <Route path={`${getPath('EConsent')}/*`} element={<EConsent />} />
        <Route path={`${getPath('EPRO')}/*`} element={<Epro />} />

        <Route
          path="*"
          element={<Navigate replace to={`${getPath('Patients')}`} />}
        />
      </Routes>
    </LegacyMainLayout>
  )
}

const PatientRoutes: React.FC = () => {
  return <Patient />
}
const ExplanationRoutes: React.FC = () => {
  return (
    <Box h="full" p="6">
      <Explanation />
    </Box>
  )
}
const IcfDocumentRoutes: React.FC = () => {
  return (
    <Box h="full" p="6">
      <IcfDocument />
    </Box>
  )
}

const TrialInfoScreen: React.FC = () => {
  return (
    <Box h="full" p="6">
      <Trial />
    </Box>
  )
}
const MembersScreen: React.FC = () => {
  const { selectedTrial } = useSelectedTrial()

  // Reduxの初期化をする必要がある
  // 元はsettingsで行なっていたが新しいナビゲーションの場合settingsが呼ばれないためここで行う
  const { initialized } = useInit(selectedTrial.uid)
  if (!initialized) {
    return null
  }
  return (
    <Box h="full" p="6">
      <Member />
    </Box>
  )
}
const CrfRoutes: React.FC = () => {
  return (
    <Box h="full" p="6">
      <Crf />
    </Box>
  )
}
const CSVExportScreen: React.FC = () => {
  const {
    selectedTrial: { featureFlags },
  } = useSelectedTrial()
  return (
    <Box h="full" p="6">
      {featureFlags.eSourceV2 ? (
        <WSDataExportScreen />
      ) : (
        <WSDataExportV1Screen />
      )}
    </Box>
  )
}
