import React, { useCallback, useMemo } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import { QuestionnaireSectionAnswerStatus } from 'src/modules/entities/questionnaire-section-answer/entity'
import { useFlash } from 'src/modules/flash/use-flash'
import { eproPatientUserKeys } from 'src/modules/swr/key'
import useSWR, { useSWRConfig } from 'swr'

import { Detail, FormValues } from './detail'
import { formToParams } from './util'
import { trialIdParamName } from '../../epro-page'
import { fetchSingle, save } from '../request'
import {
  questionnaireAnswerIdParamName,
  questionnaireSectionAnswerIdParamName,
} from '../router'
import { getConfirmRoute, getDetailRoute, getListRoute } from '../routes'

export const DetailContainer = () => {
  const {
    trialUid = '',
    questionnaireAnswerUid = '',
    questionnaireSectionAnswerUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [questionnaireAnswerIdParamName]: string
    [questionnaireSectionAnswerIdParamName]: string
  }>()

  const { mutate } = useSWRConfig()

  const { data: questionnaireAnswer, error } = useSWR<
    QuestionnaireAnswer,
    Error
  >(
    eproPatientUserKeys.fetchQuestionnaireAnswerSingle(
      trialUid,
      questionnaireAnswerUid,
    ),
    fetchSingle,
  )

  const sectionAnswer = useMemo(() => {
    if (!questionnaireAnswer) return
    return questionnaireAnswer.sectionAnswers.find(
      sa => sa.uid === questionnaireSectionAnswerUid,
    )
  }, [questionnaireAnswer, questionnaireSectionAnswerUid])

  const nextSectionAnswerUid = useMemo(() => {
    if (!sectionAnswer) return
    const nextSectionAnswer = questionnaireAnswer?.sectionAnswers.find(
      sa => sa.index === sectionAnswer?.index + 1,
    )

    return nextSectionAnswer ? nextSectionAnswer.uid : null
  }, [questionnaireAnswer?.sectionAnswers, sectionAnswer])

  const previousSectionAnswerUid = useMemo(() => {
    if (!sectionAnswer) return
    const previousSectionAnswer = questionnaireAnswer?.sectionAnswers.find(
      sa => sa.index === sectionAnswer?.index - 1,
    )

    return previousSectionAnswer ? previousSectionAnswer.uid : null
  }, [questionnaireAnswer?.sectionAnswers, sectionAnswer])

  const navigate = useNavigate()
  const { showError } = useFlash()

  const onAnswer = useCallback(
    async (data: FormValues) => {
      const originalFieldAnswers = sectionAnswer?.fieldAnswers
      if (!originalFieldAnswers) return
      const params = formToParams(data, originalFieldAnswers)

      try {
        await save({
          trialUid,
          questionnaireAnswerUid,
          questionnaireSectionAnswerUid,
          params,
        })

        mutate(
          eproPatientUserKeys.fetchQuestionnaireAnswerSingle(
            trialUid,
            questionnaireAnswerUid,
          ),
        )

        if (!nextSectionAnswerUid) {
          navigate(
            getConfirmRoute({
              trialUid,
              questionnaireAnswerUid,
            }),
          )
        } else {
          navigate(
            getDetailRoute({
              trialUid,
              questionnaireAnswerUid,
              questionnaireSectionAnswerUid: nextSectionAnswerUid,
            }),
          )
        }
      } catch (e) {
        showError(e.message)
      }
    },
    [
      mutate,
      navigate,
      nextSectionAnswerUid,
      questionnaireAnswerUid,
      questionnaireSectionAnswerUid,
      sectionAnswer?.fieldAnswers,
      showError,
      trialUid,
    ],
  )

  const onBack = useCallback(() => {
    if (!previousSectionAnswerUid) {
      navigate(
        getListRoute({
          trialUid,
        }),
      )
    } else {
      navigate(
        getDetailRoute({
          trialUid,
          questionnaireAnswerUid,
          questionnaireSectionAnswerUid: previousSectionAnswerUid,
        }),
      )
    }
  }, [navigate, previousSectionAnswerUid, questionnaireAnswerUid, trialUid])

  const onStop = useCallback(() => {
    navigate(
      getListRoute({
        trialUid,
      }),
    )
  }, [navigate, trialUid])

  const displayAnsweredCount = useMemo(() => {
    if (!questionnaireAnswer) return ''
    const totalSectionAnswers = questionnaireAnswer.sectionAnswers.length
    const answeredCount = questionnaireAnswer.sectionAnswers.filter(
      sa => sa.status === QuestionnaireSectionAnswerStatus.Answered,
    ).length
    return `${answeredCount} / ${totalSectionAnswers}`
  }, [questionnaireAnswer])

  if (error) return <>{error.message}</>
  if (!questionnaireAnswer || !sectionAnswer) return null

  return (
    <Detail
      displayName={questionnaireAnswer.displayName}
      answerStartDate={questionnaireAnswer.answerStartDate}
      answerEndDate={questionnaireAnswer.answerEndDate}
      shouldDeliverAfterTrial={questionnaireAnswer.shouldDeliverAfterTrial}
      sectionAnswer={sectionAnswer}
      displayAnsweredCount={displayAnsweredCount}
      onAnswer={onAnswer}
      onBack={onBack}
      onStop={onStop}
      hasPrev={!!previousSectionAnswerUid}
    />
  )
}
