import { useCallback, useMemo } from 'react'

import { useExplanationsQueryParams } from './useExplanationsQueryParams'
import { useExplanations } from '../api'

export const EXPLANATIONS_PER_PAGE = 30

export const useExplanationPagination = () => {
  const { queryParamObject, setQuery } = useExplanationsQueryParams()
  const currentPage = queryParamObject.page

  const { data } = useExplanations({})

  const totalCount = data?.totalCount ?? 0
  const totalPageCount = Math.ceil(totalCount / EXPLANATIONS_PER_PAGE)

  const hasPrevPage = currentPage > 1
  const hasNextPage = currentPage < totalPageCount

  const currentRange = useMemo(() => {
    const currentOffset = EXPLANATIONS_PER_PAGE * (currentPage - 1)
    const currentLimit = EXPLANATIONS_PER_PAGE * currentPage

    const start = totalCount === 0 ? 0 : currentOffset + 1
    const end = currentLimit < totalCount ? currentLimit : totalCount
    return `${start} - ${end}`
  }, [currentPage, totalCount])

  const handleClickFirstPage = useCallback(() => {
    if (currentPage === 1) return
    setQuery({ type: 'pagination', value: 1 })
  }, [setQuery, currentPage])

  const handleClickPrevPage = useCallback(() => {
    if (!hasPrevPage) return
    setQuery({ type: 'pagination', value: currentPage - 1 })
  }, [setQuery, currentPage, hasPrevPage])

  const handleClickNextPage = useCallback(() => {
    if (!hasNextPage) return
    setQuery({ type: 'pagination', value: currentPage + 1 })
  }, [setQuery, currentPage, hasNextPage])

  const handleClickLastPage = useCallback(() => {
    if (currentPage === totalPageCount) return
    setQuery({ type: 'pagination', value: totalPageCount })
  }, [setQuery, currentPage, totalPageCount])

  // ページング時のちらつきをなくすため予めページング後のデータをfetchしておく
  const shouldFetchFirst = hasPrevPage && currentPage - 1 !== 1
  useExplanations({
    page: shouldFetchFirst ? 1 : undefined,
  })
  const shouldFetchPrev = hasPrevPage
  useExplanations({
    page: shouldFetchPrev ? currentPage - 1 : undefined,
  })
  const shouldFetchNext = hasNextPage
  useExplanations({
    page: shouldFetchNext ? currentPage + 1 : undefined,
  })

  const shouldFetchLast = hasNextPage && currentPage + 1 < totalPageCount
  useExplanations({
    page: shouldFetchLast ? totalPageCount : undefined,
  })

  return {
    totalCount,
    rangeString: currentRange,
    hasFirstPage: hasPrevPage,
    hasPrevPage,
    hasNextPage,
    hasLastPage: hasNextPage,
    handleClickFirstPage,
    handleClickPrevPage,
    handleClickNextPage,
    handleClickLastPage,
  }
}
