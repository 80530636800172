import React, { useCallback, useState } from 'react'

import { readAsDataURL } from 'src/modules/util/read-as-data-url'

import { Add } from './add'
import { useSubmitRegister } from './use-submit-register'

type Props = {
  onCancel: () => void
}

export const AddContainer: React.FC<Props> = props => {
  const { onCancel } = props
  const { request, requesting, errorMessage, clearErrorMessage } =
    useSubmitRegister()
  const [importingJsonFile, setImportingJsonFile] = useState<{
    name: string
    base64JsonText: string
  }>()
  const [mode, setMode] = useState('edit-new')
  const onSubmit = useCallback(
    async (title: string) => {
      const success = await request(mode, {
        title,
        content:
          mode === 'import' ? importingJsonFile?.base64JsonText : undefined,
      })
      if (success) {
        onCancel()
      }
    },
    [onCancel, request, mode, importingJsonFile],
  )

  const onImportingJsonFileChanged = useCallback(
    async (file: File) => {
      const base64JsonText = await readAsDataURL(file)
      setImportingJsonFile({ name: file.name, base64JsonText })
    },
    [setImportingJsonFile],
  )

  const onChangeMode = useCallback(
    (mode: string) => {
      setMode(mode)
      clearErrorMessage()
    },
    [clearErrorMessage],
  )

  return (
    <Add
      errorMessage={mode === 'import' ? '' : errorMessage}
      importErrorMessage={mode === 'import' ? errorMessage : ''}
      requesting={requesting}
      onSubmit={onSubmit}
      onCancel={onCancel}
      mode={mode}
      onChangeMode={onChangeMode}
      importingJsonFileName={importingJsonFile?.name}
      onImportingJsonFileChanged={onImportingJsonFileChanged}
    />
  )
}
