import { HttpResponse } from 'msw'
import {
  SaveWorksheetDocument,
  SaveWorksheetMutation,
  SaveWorksheetMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

import { worksheetWithSchemaResponseFactory } from '../../testUtils/factory'

export const saveWorksheetHandler = graphQLHandlerFactory<
  SaveWorksheetMutation,
  SaveWorksheetMutationVariables
>('mutation', SaveWorksheetDocument, () => {
  return HttpResponse.json({
    data: {
      saveWorksheet: {
        worksheet: worksheetWithSchemaResponseFactory(),
      },
    },
  })
})
