import { Patient, PatientEProStatus } from './entity'

export const callable = (patient: Patient) => {
  return !!patient.phoneNumber || !!patient.email
}

export const isTrialStarted = (patient: Patient) => {
  return [
    PatientEProStatus.InTrial,
    PatientEProStatus.TrialCompleted,
    PatientEProStatus.TrialDiscontinued,
  ].includes(patient.eproStatus)
}
