import { Member, Role } from 'src/modules/entities/member/entity'
import { createFixture } from 'src/modules/entities/member/fixture'
import {
  MemberResponse,
  parse as parseMember,
} from 'src/modules/entities/member/request'
import { SERVER_URL } from 'src/modules/server/const'
import {
  requestPost,
  requestPut,
  requestDelete,
  requestGet,
} from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { FormValues } from './edit-modal/edit-modal'

type UpdateParams = {
  first_name: string
  last_name: string
  trial_hospital_uids: string[]
  email: string
  role: Role
}

const toParams = (values: FormValues): UpdateParams => {
  const uids: string[] = values.hospitals.map(item => {
    return item.value
  })
  return {
    first_name: values.firstName,
    last_name: values.lastName,
    trial_hospital_uids: uids,
    email: values.email,
    role: values.role,
  }
}

export const submitUpdate = async ({
  trialUid,
  memberUid,
  values,
}: {
  trialUid: string
  memberUid: string
  values: FormValues
}): Promise<Member> => {
  if (IS_MOCK_MODE) {
    const res = createFixture()
    res.uid = memberUid

    return res
  }

  const res = await requestPut<MemberResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/members/${memberUid}`,
    params: toParams(values),
  })

  return parseMember(res.data)
}

export const submitCreate = async ({
  trialUid,
  values,
}: {
  trialUid: string
  values: FormValues
}): Promise<Member> => {
  if (IS_MOCK_MODE) {
    return createFixture()
  }

  const res = await requestPost<MemberResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/members`,
    params: toParams(values),
  })

  return parseMember(res.data)
}

export const submitDelete = async ({
  trialUid,
  memberUid,
}: {
  trialUid: string
  memberUid: string
}): Promise<Member> => {
  if (IS_MOCK_MODE) {
    const res = createFixture()
    res.uid = memberUid

    return res
  }

  const res = await requestDelete<MemberResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/members/${memberUid}`,
    params: {},
  })

  return parseMember(res.data)
}

export const submitEnable = async ({
  trialUid,
  memberUid,
}: {
  trialUid: string
  memberUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  const res = await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/members/${memberUid}/enable`,
    params: {},
  })

  return res
}

export const submitReInvite = async ({
  trialUid,
  memberUid,
}: {
  trialUid: string
  memberUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  const res = await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/members/${memberUid}/invitation`,
    params: {},
  })

  return res
}

export type LookupUser = {
  uid: string
  firstName: string
  lastName: string
  email: string
}

type LookupUserResponse = {
  uid: string
  email: string
  first_name: string
  last_name: string
}[]

export const submitLookupUser = async ({
  email,
}: {
  email: string
}): Promise<LookupUser | null> => {
  if (IS_MOCK_MODE) {
    return null
  }

  const res = await requestGet<LookupUserResponse>({
    url: `${SERVER_URL}/accounts/lookup`,
    params: { email },
  })

  const user = res.data[0]
  if (!user) return null

  const lookupUser = {
    uid: user.uid,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
  }

  return lookupUser
}
