import React from 'react'

import { Center, Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { white } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import styled from 'styled-components'

import { Header } from '../components/header'

type Props = {
  questionnaireAnswer: QuestionnaireAnswer
  onSendAnswer: () => void
  onBack: () => void
}

export const Confirm: React.FC<Props> = ({
  onBack,
  onSendAnswer,
  questionnaireAnswer,
}) => {
  const {
    displayName,
    answerStartDate,
    answerEndDate,
    shouldDeliverAfterTrial,
  } = questionnaireAnswer
  return (
    <Container>
      <Header
        displayName={displayName}
        answerStartDate={answerStartDate}
        answerEndDate={answerEndDate}
        shouldDeliverAfterTrial={shouldDeliverAfterTrial}
        showStopButton={false}
      />

      <Center w="full" h="full">
        {/* TODO: 患者側デザイントークン定義時にサイズ置き換え */}
        <Text fontSize="3xl" textAlign="center">
          回答を確定しますがよろしいですか？
        </Text>
      </Center>

      <Footer>
        <Button
          text={'回答に戻る'}
          size="M"
          buttonType="normal"
          onClick={onBack}
        />
        <Spacer horizontal size={40} />
        <Button
          text={'回答を送信する'}
          size="M"
          buttonType="important"
          onClick={onSendAnswer}
        />
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  border-top: 1px solid #9ab8c1;
`
