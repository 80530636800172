import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spacer } from 'src/components/spacer/spacer'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import styled from 'styled-components'

import { TrialHospitalsByUid } from '../../../../../../../../entities/account/util'
import { MediumItem } from '../../../../../../../../entities/medium-item/entity'
import { DataType } from '../../../../../../../../entities/small-item/entity'
import { HospitalNames } from '../../components/common/hospital-names'

type Props = {
  templateTitle: string
  mediumItems?: MediumItem[]
  trialHospitalsByUid: TrialHospitalsByUid
  templateStatus: TemplateStatus | undefined

  onClose: () => void
  onOpenVisibilitySetModalFactory: (smallItemUid: string) => () => void
  onOpenVisibilityBulkSetModal: () => void
}

export const VisibilityModalComponent: React.FC<Props> = props => {
  const {
    templateTitle,
    mediumItems,
    trialHospitalsByUid,
    templateStatus,
    onClose,
    onOpenVisibilitySetModalFactory,
    onOpenVisibilityBulkSetModal,
  } = props

  const modalContentHeight = `${window.innerHeight - 320}px`

  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="フィールド別医療機関設定" />
      <ModalContent>
        <Text textAlign="center">
          EDC連携なしのフィールドが表示される医療機関を設定します。
        </Text>

        <Spacer size={20} />

        <BulkSettingsBtnContainer>
          {templateStatus !== TemplateStatus.Disabled && (
            <Button
              size="S"
              text="一括設定"
              onClick={onOpenVisibilityBulkSetModal}
              buttonType="important"
            ></Button>
          )}
        </BulkSettingsBtnContainer>

        <Spacer size={20} />

        <Text fontWeight="bold">{templateTitle}</Text>

        <Spacer size={20} />

        <TableContainer style={{ maxHeight: modalContentHeight }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell width="20%">セクション</TableHeadCell>
                <TableHeadCell width="20%">フィールド</TableHeadCell>
                <TableHeadCell width="45%">表示される医療機関</TableHeadCell>
                <TableHeadCell width="15%" />
              </TableRow>
            </TableHead>

            <TableBody>
              {!!mediumItems &&
                mediumItems.flatMap(mi => {
                  const topLevelSavedOperationalSmallItem = Object.values(
                    mi.smallItemMap,
                  )
                    .filter(
                      si =>
                        !si.parentUid &&
                        !si.unsaved &&
                        si.dataType === DataType.Operational,
                    )
                    .sort((a, b) => a.index - b.index)
                  return topLevelSavedOperationalSmallItem.map(si => {
                    return (
                      <TableRow key={si.uid} borderBottom>
                        <TableBodyCell width="20%">{mi.title}</TableBodyCell>
                        <TableBodyCell width="20%">{si.title}</TableBodyCell>
                        <TableBodyCell width="45%">
                          <HospitalNames
                            trialHospitalsByUid={trialHospitalsByUid}
                            hospitalUids={si.viewableHospitalUids}
                          ></HospitalNames>
                        </TableBodyCell>
                        <TableBodyCell align="center" width="15%">
                          {templateStatus !== TemplateStatus.Disabled && (
                            <Button
                              size="S"
                              text="設定"
                              onClick={onOpenVisibilitySetModalFactory(si.uid)}
                              buttonType="normal"
                            ></Button>
                          )}
                        </TableBodyCell>
                      </TableRow>
                    )
                  })
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>
    </Modal>
  )
}

const BulkSettingsBtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TableContainer = styled.div`
  width: 100%;
  height: 300px;
`
