import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  UnorderedList,
  ListItem,
  ModalFooter,
  Button,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { ExplanationDocRevision } from '../../types'

type Props = {
  isForRoom: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
  uncompletedDocRevs: ExplanationDocRevision[]
}

export const CompleteAgreementModal: React.FC<Props> = ({
  isForRoom,
  isOpen,
  onClose,
  onSubmit,
  uncompletedDocRevs,
}) => {
  const allCompleted = uncompletedDocRevs.length === 0

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {allCompleted
            ? 'すべての文書の説明・署名が完了しました'
            : '未完了の文書があります'}
        </ModalHeader>
        <ModalBody>
          {allCompleted ? (
            <Text>
              「同意完了する」ボタンより
              <br />
              本説明への同意を完了してください。
            </Text>
          ) : (
            <Stack spacing="8">
              <Text>
                以下の文書の説明・署名/回答が未完了です。このまま同意を完了しますか？
              </Text>
              <UnorderedList stylePosition="inside">
                {uncompletedDocRevs.map(docRev => (
                  <ListItem key={docRev.uid} wordBreak="break-word">
                    {docRev.icfDocumentRevisionName}
                  </ListItem>
                ))}
              </UnorderedList>
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            colorScheme={isForRoom ? 'green' : 'blue'}
            onClick={async () => {
              await onSubmit()
            }}
          >
            同意完了する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
