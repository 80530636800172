import { HttpResponse } from 'msw'
import {
  createUpdateExpSessionMembersPayloadMock,
  UpdateExpMembersDocument,
  UpdateExpMembersMutation,
  UpdateExpMembersMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const updateExpMembersHandler = graphQLHandlerFactory<
  UpdateExpMembersMutation,
  UpdateExpMembersMutationVariables
>('mutation', UpdateExpMembersDocument, () => {
  return HttpResponse.json({
    data: {
      updateExpMembers: createUpdateExpSessionMembersPayloadMock(),
    },
  })
})
