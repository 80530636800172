import {
  GqlError,
  CreateSignAuthenticationMutation,
  CreateSignAuthenticationDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type CreateSignAuthenticationRes =
  CreateSignAuthenticationMutation['createSignAuthentication']

export const createSignAuthentication = async ({
  explanationSessionUid,
  explanationDocRevisionUid,
  signerActorUid,
}: {
  explanationSessionUid: string
  explanationDocRevisionUid: string
  signerActorUid: string
}): Promise<CreateSignAuthenticationRes> => {
  try {
    const data = await graphqlRequest(CreateSignAuthenticationDocument, {
      input: {
        explanationSessionUid,
        explanationDocRevisionUid,
        signerActorUid,
      },
    })
    return data['createSignAuthentication']
  } catch (error) {
    throw error
  }
}

export const useCreateSignAuthentication = ({
  explanationSessionUid,
  explanationDocRevisionUid,
  signerActorUid,
  ...option
}: {
  explanationSessionUid: string
  explanationDocRevisionUid: string
  signerActorUid: string
} & SWRConfiguration<CreateSignAuthenticationRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.createSignAuthentication(
      explanationDocRevisionUid,
      explanationSessionUid,
      signerActorUid,
    ),
    () =>
      createSignAuthentication({
        explanationSessionUid,
        explanationDocRevisionUid,
        signerActorUid,
      }),
    option,
  )
}
