type ExtractColor<M extends Record<number, string>> = keyof M extends number
  ? M[keyof M]
  : never

/**
 * @deprecated Use chakra-ui color instead.
 */
export const blue = {
  90: '#363BB5',
  80: '#3758D1',
  70: '#3770E8',
  60: '#4E80EB',
  50: '#88AAF2',
  40: '#9BB8F4',
  30: '#A9C1F6',
  20: '#C6D6F9',
  10: '#E0E8FC',
  5: '#F0F4FE',
} as const

/**
 * @deprecated Use chakra-ui color instead.
 */
export const green = {
  100: '#004E3F',
  90: '#005A4B',
  80: '#006E60',
  70: '#008C7E',
  60: '#007E70',
  50: '#009B8D',
  40: '#00A79A',
  30: '#40BDB3',
  20: '#79D1CA',
  10: '#B5E6E2',
  5: '#E1F5F3',
} as const

/**
 * @deprecated Use chakra-ui color instead.
 */
export const red = {
  100: '#640037',
  90: '#89004C',
  80: '#9F0050',
  70: '#BA0055',
  60: '#CF0059',
  50: '#E9005E',
  40: '#ED2978',
  30: '#F15996',
  20: '#F690B9',
  10: '#FAC2D9',
  5: '#FCDAE8',
} as const

/**
 * @deprecated Use chakra-ui color instead.
 */
export const yellow = {
  100: '#D25D00',
  90: '#FF7100',
  80: '#FF8A00',
  70: '#FFA300',
  60: '#FFAE1D',
  50: '#FFC04E',
  40: '#FFCD71',
  30: '#FFDB98',
  20: '#FFE8BE',
  10: '#FFF1D8',
  5: '#FFF7E8',
} as const

/**
 * @deprecated Use chakra-ui color instead.
 */
export const gray = {
  100: '#1E1E1E',
  90: '#373737',
  80: '#535353',
  70: '#707070',
  60: '#878787',
  55: '#939393',
  50: '#9F9F9F',
  40: '#B6B6B6',
  30: '#C6C6C6',
  20: '#D7D7D7',
  10: '#E9E9E9',
  5: '#F2F2F2',
} as const

/**
 * @deprecated Use chakra-ui color instead.
 */
export const white = '#FFFFFF' as const

/**
 * @deprecated Use chakra-ui color instead.
 */
export type Palette =
  | typeof white
  | ExtractColor<typeof blue>
  | ExtractColor<typeof green>
  | ExtractColor<typeof red>
  | ExtractColor<typeof yellow>
  | ExtractColor<typeof gray>

/**
 * @deprecated Use chakra-ui color instead.
 */
export const micinBlack = '#1E1C1C' as const
