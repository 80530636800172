import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { DeliveryDocumentsDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const deliveryDocuments = async ({
  type,
  explanationRevisionUid,
  memberUid,
  deliveryDate,
}: {
  type: 'EmailToMember' | 'EmailToPatientAndDownload' | 'Download'
  explanationRevisionUid: string
  memberUid?: string
  deliveryDate?: string
}): Promise<{ downloadUrl?: string }> => {
  try {
    const res = await graphqlRequest(DeliveryDocumentsDocument, {
      input: {
        deliveryType: type,
        explanationRevisionUid,
        emailTargetTrialMemberUid: memberUid,
        deliveryDate,
      },
    })
    return {
      downloadUrl: res.deliverDocuments.downloadURL ?? undefined,
    }
  } catch (error) {
    throw error
  }
}

const deliveryDocumentsByEmail = async ({
  explanationRevisionUid,
  memberUid,
  deliveryDate,
}: {
  explanationRevisionUid: string
  memberUid: string
  deliveryDate: string
}): Promise<void> => {
  await deliveryDocuments({
    type: 'EmailToMember',
    explanationRevisionUid,
    memberUid,
    deliveryDate,
  })
}

const deliveryDocumentByDownload = async ({
  explanationRevisionUid,
  deliveryDate,
}: {
  explanationRevisionUid: string
  deliveryDate?: string
}): Promise<{ downloadUrl: string }> => {
  const res = await deliveryDocuments({
    type: 'Download',
    explanationRevisionUid,
    deliveryDate,
  })
  if (!res.downloadUrl) {
    throw new Error('downloadUrl is empty')
  }
  return {
    downloadUrl: res.downloadUrl,
  }
}

const deliveryDocumentByEmailAndDownload = async ({
  explanationRevisionUid,
  memberUid,
  deliveryDate,
}: {
  explanationRevisionUid: string
  memberUid?: string
  deliveryDate: string
}): Promise<{ downloadUrl: string }> => {
  const res = await deliveryDocuments({
    type: 'EmailToPatientAndDownload',
    explanationRevisionUid,
    memberUid,
    deliveryDate,
  })
  if (!res.downloadUrl) {
    throw new Error('downloadUrl is empty')
  }
  return {
    downloadUrl: res.downloadUrl,
  }
}

export const useDeliveryDocumentByEmail = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationRevisionUid,
      memberUid,
      deliveryDate,
    }: {
      explanationRevisionUid: string
      memberUid: string
      deliveryDate: string
    }) =>
      deliveryDocumentsByEmail({
        explanationRevisionUid,
        memberUid,
        deliveryDate,
      }),
    option,
  )
}

export const useDeliveryDocumentByDownload = (
  option?: UseMutationOption<{ downloadUrl: string }, GqlError>,
) => {
  return useMutation(
    ({
      explanationRevisionUid,
      deliveryDate,
    }: {
      explanationRevisionUid: string
      deliveryDate?: string
    }) =>
      deliveryDocumentByDownload({
        explanationRevisionUid,
        deliveryDate,
      }),
    option,
  )
}

export const useDeliveryDocumentByEmailAndDownload = (
  option?: UseMutationOption<{ downloadUrl: string }, GqlError>,
) => {
  return useMutation(
    ({
      explanationRevisionUid,
      memberUid,
      deliveryDate,
    }: {
      explanationRevisionUid: string
      memberUid?: string
      deliveryDate: string
    }) =>
      deliveryDocumentByEmailAndDownload({
        explanationRevisionUid,
        memberUid,
        deliveryDate,
      }),
    option,
  )
}
