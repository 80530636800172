import { Tag } from '@chakra-ui/react'

type Props = {
  featureChannel?: 'Stable' | 'Beta' | 'Canary'
}

export const FeatureChannelLabel: React.FC<Props> = ({ featureChannel }) => {
  if (!featureChannel) return null
  if (featureChannel === 'Stable') return null

  return (
    <Tag
      size="lg"
      bg="black"
      color="white"
      fontWeight="700"
      borderRadius="2"
      whiteSpace="nowrap"
    >
      {featureChannel}
    </Tag>
  )
}
