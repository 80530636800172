import { SERVER_URL } from 'src/modules/server/const'
import {
  requestGet,
  requestPost,
  requestDelete,
  requestPut,
} from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Questionnaire } from './entity'
import { fixture, fixtures } from './fixture'
import { toUpdateParams } from './params'
import { QuestionnaireSection } from '../questionnaire-section/entity'
import {
  QuestionnaireSectionResponse,
  parse as parseQuestionnaireSection,
} from '../questionnaire-section/request'

export type QuestionnaireListRequest = {}
export type QuestionnaireListResponse = {
  data: Questionnaire[]
}

export const fetchList = async ({
  trialUid,
  params,
}: {
  trialUid: string
  params: QuestionnaireListRequest
}): Promise<Questionnaire[]> => {
  if (IS_MOCK_MODE) {
    return fixtures
  }

  const resp = await requestGet<QuestionnaireListResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires`,
    params,
  })

  return resp.data.data
}

type QuestionnaireResponse = {
  uid: string
  title: string
  displayName: string
  status: number
  registeredAt: string
  releasedAt: string | null
  sections: QuestionnaireSectionResponse[]
}

export const fetch = async ({
  trialUid,
  questionnaireUid,
}: {
  trialUid: string
  questionnaireUid: string
}): Promise<Questionnaire> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const resp = await requestGet<QuestionnaireResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires/${questionnaireUid}`,
    params: [],
  })

  return parse(resp.data)
}

export type QuestionnaireUpdateRequest = {
  title: string
  displayName: string
  sections: QuestionnaireSection[]
}

export const update = async ({
  trialUid,
  questionnaireUid,
  params,
}: {
  trialUid: string
  questionnaireUid: string
  params: QuestionnaireUpdateRequest
}): Promise<Questionnaire> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const updateParams = toUpdateParams({ ...params })

  const resp = await requestPut<QuestionnaireResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires/${questionnaireUid}`,
    params: updateParams,
  })

  return parse(resp.data)
}

export type QuestionnaireRegisterRequest = {
  title: string
  content?: string
}

export const register = async (
  trialUid: string,
  params: QuestionnaireRegisterRequest,
): Promise<Questionnaire> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPost<QuestionnaireResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires${
      params.content ? '/import' : ''
    }`,
    params,
  })

  return parse(res.data)
}

export const confirm = async (
  trialUid: string,
  questionnaireUid: string,
): Promise<Questionnaire> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPut<QuestionnaireResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires/${questionnaireUid}/confirm`,
  })

  return parse(res.data)
}

export const backToDraft = async (
  trialUid: string,
  questionnaireUid: string,
) => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPut<QuestionnaireResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires/${questionnaireUid}/back_to_draft`,
  })

  return parse(res.data)
}

export const deleteQuestionnaire = async (
  trialUid: string,
  questionnaireUid: string,
): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  await requestDelete<void>({
    url: `${SERVER_URL}/trials/${trialUid}/questionnaires/${questionnaireUid}`,
  })
}

const parse = (res: QuestionnaireResponse): Questionnaire => {
  return {
    uid: res.uid,
    title: res.title,
    displayName: res.displayName,
    status: res.status,
    registeredAt: res.registeredAt,
    releasedAt: res.releasedAt,
    sections: res.sections
      ? res.sections
          .map(parseQuestionnaireSection)
          .sort((a, b) => a.index - b.index)
      : [],
    scheduleUids: [],
  }
}
