import React from 'react'

import { colors } from 'src/assets/colors'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { Worksheet } from 'src/modules/entities/worksheet/entity'
import styled from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'

import { SmallItemAnswerComponent } from './small-item-answer'

type Props = {
  worksheet: Worksheet
  mediumItem: MediumItem
}

export const SingleSectionComponent: React.FC<Props> = props => {
  const { worksheet, mediumItem } = props

  const mediumItemAnswer = worksheet.mediumItemAnswers.find(
    mia => mia.mediumItemUid === mediumItem.uid,
  )

  if (!mediumItemAnswer) return null

  const smallItemAnswers = Object.values(mediumItemAnswer.smallItemAnswerMap)
    .filter(si => si.parentUid === null)
    .sort((a, b) => a.index - b.index)

  return (
    <Container>
      <Title>{mediumItemAnswer.title}</Title>
      <SmallItemContainer>
        <Flex column>
          {smallItemAnswers.map((smallItemAnswer, i) => (
            <FlexItem key={smallItemAnswer.uid}>
              <Border
                className={smallItemAnswers.length === i + 1 ? 'last' : ''}
              >
                <SmallItemAnswerComponent
                  smallItemAnswer={smallItemAnswer}
                  smallItemAnswerMap={mediumItemAnswer.smallItemAnswerMap}
                />
              </Border>
            </FlexItem>
          ))}
        </Flex>
      </SmallItemContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  padding: 6px 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  background: ${colors.bgGray};
  border-top: 0.5px solid #000000;
  border-bottom: 0.5px dashed #000000;
  word-break: break-word;
`

const SmallItemContainer = styled.div`
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
`

const Border = styled.div`
  border-bottom: 0.5px dashed #000000;

  &.last {
    border: none;
  }
`
