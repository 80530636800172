import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import {
  ForPartnerDocSetFragment,
  BrowsingFlowDocSetFragment,
} from 'src/lib/gql-client'

import { DocumentBanner } from '../DocumentBanner/DocumentBanner'

type Props = {
  docSets:
    | BrowsingFlowDocSetFragment[]
    | ForPartnerDocSetFragment[]
    | ForPartnerDocSetFragment[]
  docPagePath: (docRevisionUid: string) => string
  toggleVideoCall?: () => void
}

export const DocumentList: React.FC<Props> = ({
  docSets,
  docPagePath,
  toggleVideoCall,
}) => {
  let sequentialDocOrder = 0
  return (
    <Stack spacing="6" as="section" aria-label="documents">
      {docSets.length >= 1 &&
        docSets.map(set => {
          if (!set.documentRevisions || set.documentRevisions.length === 0) {
            return null
          }
          return (
            <Box key={set.uid} as="section" aria-labelledby={set.uid}>
              <Text as="h2" fontSize="xl" fontWeight="bold" id={set.uid}>
                {`${set.icfDocumentSet.numberingId}. ${set.name}`}
              </Text>
              <Flex direction="column" gap="4" mt="4">
                {set.documentRevisions
                  .sort((a, b) => a.index - b.index)
                  .map(docRev => {
                    sequentialDocOrder += 1
                    return (
                      <Box key={docRev.uid} onClick={() => toggleVideoCall?.()}>
                        <DocumentBanner
                          key={docRev.uid}
                          order={sequentialDocOrder}
                          docName={docRev.icfDocumentRevision.name}
                          docPath={docPagePath(docRev.uid)}
                          docVersion={docRev.icfDocumentRevision.version}
                          patientBrowsedAt={
                            docRev.latestPatientSideBrowsingHistory?.operatedAt
                          }
                        />
                      </Box>
                    )
                  })}
              </Flex>
            </Box>
          )
        })}
    </Stack>
  )
}
