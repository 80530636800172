import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import { QuestionnaireAnswerContainer } from './answer/answer-container'
import { EditPatientContainer } from './edit/edit-container'

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={``} element={<EditPatientContainer />} />
      <Route path={`answers`} element={<QuestionnaireAnswerContainer />} />

      <Route path="*" element={<Navigate replace to={``} />} />
    </Routes>
  )
}
