import React from 'react'

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type Props = {
  isOpen: boolean
  onClose: () => void
  onFinish: () => void
}

export const LeaveLobbyModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onFinish,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>説明ロビーから退出しますか？</ModalHeader>
      <ModalFooter>
        <ModalCancelButton />
        <Button
          colorScheme="red"
          onClick={() => {
            onFinish()
            onClose()
          }}
        >
          退出する
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
