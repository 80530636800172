import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Menu as MenuIcon } from 'src/components/icon'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { usePermission } from 'src/features/auth/context'
import { FileViewer } from 'src/features/misc/components/FileViewer/FileViewer'
import { useSpinner } from 'src/hooks/use-spinner'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { FieldInput } from 'src/lib/gql-client'
import { PERMISSIONS } from 'src/lib/permission'
import { sortByDate } from 'src/modules/util/sort'
import { downloadObject } from 'src/utils/downloadObject'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useFileFieldValue, useWorksheetSchema } from '../../api'
import { useDownloadWorksheetFieldFiles } from '../../api/downloadWorksheetFieldFiles'
import { useFileFieldDownloadLogs } from '../../api/getFileFieldDownloadLogs'
import { getFlattenFields } from '../../utils/getFlattenFields'

type Param = {
  worksheetUid: string
  fileFieldValueUid: string
}

export const WorksheetFileViewerScreen: React.FC = () => {
  const { worksheetUid, fileFieldValueUid } = useParams<Param>()

  const { data: schema } = useWorksheetSchema({
    worksheetUid: worksheetUid ?? null,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const { data: fileFieldValue } = useFileFieldValue({
    worksheetFieldValueUid: fileFieldValueUid ?? null,
  })

  const { hasPermission } = usePermission()

  const canDownloadFiles = hasPermission(
    PERMISSIONS.Worksheet_DownloadFileField,
  )

  const fileDownloadModal = useDisclosure()
  const downloadLogsDrawer = useDisclosure()

  if (!worksheetUid || !schema || !fileFieldValue) {
    return null
  }

  const field = getFlattenFields(schema.fields).find(
    f => f.fid === fileFieldValue.fid,
  )

  if (!field) return null

  return (
    <>
      <FileViewer
        field={field}
        files={fileFieldValue.files.map(file => ({
          order: file.order,
          memo: file.memo,
          uploadedFile: {
            uid: file.uploadedFile.uid,
            extension: file.uploadedFile.extension,
            name: file.uploadedFile.name,
            savedAt: file.uploadedFile.savedAt,
            url: file.uploadedFile.url,
          },
        }))}
        menuComponent={
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<MenuIcon />}
              variant="customIconButtonGhost"
              aria-label="menu"
            />
            <MenuList>
              {canDownloadFiles && (
                <MenuItem onClick={fileDownloadModal.onOpen}>
                  ダウンロード
                </MenuItem>
              )}
              <MenuItem onClick={downloadLogsDrawer.onOpen}>
                ダウンロード履歴
              </MenuItem>
            </MenuList>
          </Menu>
        }
      />
      {canDownloadFiles && (
        <FileDownloadModal
          isOpen={fileDownloadModal.isOpen}
          fieldValueUid={fileFieldValue.worksheetFieldValueUid}
          onClose={fileDownloadModal.onClose}
        />
      )}
      <DownloadLogsDrawer
        isOpen={downloadLogsDrawer.isOpen}
        fieldName={field.name}
        worksheetUid={worksheetUid}
        fid={fileFieldValue.fid}
        fieldIndex={fileFieldValue.fieldIndex}
        onClose={downloadLogsDrawer.onClose}
      />
    </>
  )
}

type FileDownloadModalProps = {
  isOpen: boolean
  fieldValueUid: string
  onClose: () => void
}

const FileDownloadModal: React.FC<FileDownloadModalProps> = ({
  isOpen,
  fieldValueUid,
  onClose,
}) => {
  const { showSpinner, hideSpinner } = useSpinner()

  const { request } = useDownloadWorksheetFieldFiles({
    onRequestStarted: showSpinner,
    onRequestDone: hideSpinner,
    onSuccess: ({ downloadUrl }) => {
      downloadObject({ url: downloadUrl })
      onClose()
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>ファイルダウンロード</ModalHeader>
        <ModalBody>
          <Text>
            ページ内すべてのファイルをダウンロードします。処理を開始しますか？
          </Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            onClick={async () => {
              await request({ fieldValueUid })
            }}
          >
            ダウンロードする
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type DownloadLogsDrawerProps = {
  isOpen: boolean
  fieldName: string
  worksheetUid: string
  fid: string
  fieldIndex: number
  onClose: () => void
}

const DownloadLogsDrawer: React.FC<DownloadLogsDrawerProps> = ({
  isOpen,
  fieldName,
  worksheetUid,
  fid,
  fieldIndex,
  onClose,
}) => {
  const input: FieldInput = {
    worksheetUid,
    fid,
    fieldIndex,
  }
  const { data: logs } = useFileFieldDownloadLogs({
    input: isOpen ? input : null,
  })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>ダウンロード履歴</DrawerHeader>
        <DrawerBody>
          <Stack spacing="3">
            <TextWithBar as="h2">{fieldName}</TextWithBar>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>日時</Th>
                    <Th>操作者</Th>
                    <Th>対象ファイル</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortByDate(logs ?? [], 'downloadedAt', 'desc').map(log => (
                    <Tr key={log.fileDownloadLogUid}>
                      <Td verticalAlign="top" w="128px">
                        {formatDate(log.downloadedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                      </Td>
                      <Td verticalAlign="top" minW="160px">
                        <MemberLabel
                          role={log.trialMember.role}
                          displayName={getFullName(log.trialMember.user)}
                        />
                      </Td>
                      <Td verticalAlign="top">
                        <OrderedList spacing={4}>
                          {log.fileFieldValue.files
                            .sort((a, b) => a.order - b.order)
                            .map(file => (
                              <ListItem
                                key={`${file.uploadedFile.uid}_${log.fileDownloadLogUid}`}
                              >
                                <Text wordBreak="break-all">
                                  {file.uploadedFile.name}
                                </Text>
                              </ListItem>
                            ))}
                        </OrderedList>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
