import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { Menu as MenuIcon } from 'src/components/icon'
import { CriteriaDetailMenu_CriteriaFragment } from 'src/lib/gql-client'

import { useCriteriaDetailMenuItems } from './useCriteriaMenuItems'

type Props = {
  criteria: CriteriaDetailMenu_CriteriaFragment
}

export const CriteriaDetailMenu: React.FC<Props> = ({ criteria }) => {
  const menuItems = useCriteriaDetailMenuItems({ criteria })

  if (menuItems.length === 0) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="open menu"
        icon={<MenuIcon />}
        variant="customIconButtonGhost"
      />
      <MenuList>
        {menuItems.map(item => (
          <MenuItem key={item.label} onClick={item.onClick} color={item.color}>
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
