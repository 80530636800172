import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { layouts } from 'src/assets/layouts'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import { Role, Member } from 'src/modules/entities/member/entity'
import { getList } from 'src/modules/entities/member/selector'
import { roleToText } from 'src/modules/text'
import styled from 'styled-components'

import { MemberDisableButton } from './disable-button'
import { MemberEditButton } from './edit-button'
import { MemberEnableButton } from './enable-button'
import { ReInvitationButton } from './reInvitation-button'

type Props = {
  selectedTrial: SelectedTrial
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
  border: 1px solid #b6b6b6;
  border-radius: 10px;
`

const Head = styled.div`
  color: #000;
  border-bottom: 1px solid #b6b6b6;
`

const Body = styled.div`
  flex: 1;
  position: relative;
`

const ScrollItem = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 20% 35% 11% 34%;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: ${layouts.tabletLandscapeWithMax}) {
    grid-template-columns: 17% 25% 11% 47%;
  }
`

const SCROLL_BAR_WIDTH = 6
const HeadRow = styled(Row)`
  width: calc(100% - ${SCROLL_BAR_WIDTH + 10}px);
`
const BodyRow = styled(Row)`
  border-bottom: 1px dashed #b6b6b6;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;

  @media (max-width: ${layouts.tabletLandscapeWithMax}) {
    padding: 8px 8px;
  }
`

const ButtonContainer = styled.div`
  padding: 2px 8px;
`

export const MemberList: React.FC<Props> = props => {
  const { selectedTrial } = props

  const members = useSelector(getList).filter(
    u => u.trialUid === selectedTrial.uid,
  )

  return (
    <Container>
      <Head>
        <HeadRow>
          <Item>
            <Text fontWeight="bold">氏名</Text>
          </Item>
          <Item>
            <Text fontWeight="bold">メールアドレス</Text>
          </Item>
          <Item>
            <Text fontWeight="bold">ロール</Text>
          </Item>
        </HeadRow>
      </Head>

      <Body>
        <ScrollItem>
          {members.map(member => {
            const inactive = !!(member.deletedAt || !member.registeredAt)
            const textColor = inactive ? '#939393' : undefined

            return (
              <BodyRow key={member.uid}>
                <Item>
                  <Text color={textColor}>
                    {member.lastName}
                    {member.firstName}
                  </Text>
                </Item>

                <Item>
                  <Text color={textColor}>{member.email}</Text>
                </Item>

                <Item>
                  <Text color={textColor}>{roleToText(member.role)}</Text>
                </Item>

                <BtnItem selectedTrial={selectedTrial} member={member} />
              </BodyRow>
            )
          })}
        </ScrollItem>
      </Body>
    </Container>
  )
}

type BtnProps = {
  selectedTrial: SelectedTrial
  member: Member
}

const BtnItem: React.FC<BtnProps> = props => {
  const { selectedTrial, member } = props
  const inactive = !!(member.deletedAt || !member.registeredAt)
  const textColor = inactive ? '#939393' : undefined

  if (member.deletedAt) {
    return (
      <Item>
        <ButtonContainer>
          <MemberEnableButton memberUid={member.uid} />
        </ButtonContainer>
        <Text color={textColor}>無効なアカウント</Text>
      </Item>
    )
  }

  return (
    <Item>
      {![Role.DM].includes(member.role) && (
        <ButtonContainer>
          <MemberDisableButton memberUid={member.uid} />
        </ButtonContainer>
      )}
      <ButtonContainer>
        <MemberEditButton
          memberUid={member.uid}
          selectedTrial={selectedTrial}
        />
      </ButtonContainer>
      {!member.registeredAt && (
        <ButtonContainer>
          <ReInvitationButton memberUid={member.uid} />
        </ButtonContainer>
      )}
    </Item>
  )
}
