import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'
import SorryDr from 'src/assets/image/sorry-dr.svg'

export const NotFound: React.FC = () => {
  return (
    <Center w="full" h="100vh" color="gray.400" p="0">
      <Box position="absolute" left="4" top="4">
        <img src={MirohaLogo} alt="MiROHA Logo" />
      </Box>
      <Stack spacing="6">
        <HStack>
          <Text fontSize="96px" fontWeight="bold">
            Sorry...
          </Text>
          <img src={SorryDr} alt="Sorry Dr." />
        </HStack>
        <Stack spacing="4">
          <Text fontSize="32px" fontWeight="bold">
            お探しのページが見つかりませんでした。
          </Text>
          <Text fontSize="20px">
            ページは削除、変更されたか現在利用できない可能性があります。
          </Text>
        </Stack>
      </Stack>
    </Center>
  )
}
