import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import { EProPatientDetail } from './detail/detail'
import { ListContainer } from './list/list-container'

export const patientIdParamName = 'patientUid'

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={``} element={<ListContainer />} />
      <Route path={`:${patientIdParamName}`} element={<EProPatientDetail />} />
      <Route path="*" element={<Navigate replace to={``} />} />
    </Routes>
  )
}
