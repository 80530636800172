import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import styled from 'styled-components'

import { MemberAddButton } from './add-button'
import { MemberList } from './list'

export const Member: React.FC = () => {
  const trial = useSelector(getSelectedTrial)!

  return (
    <Container>
      <TopContainer>
        <Text as="h1" fontSize="lg" fontWeight="bold">
          アカウント情報
        </Text>

        <ButtonContainer>
          <MemberAddButton selectedTrial={trial} />
        </ButtonContainer>
      </TopContainer>

      <ListContainer>
        <MemberList selectedTrial={trial} />
      </ListContainer>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 0 50px;
`

const ListContainer = styled.div`
  margin: 36px 0 0;
  flex: 1;
`
