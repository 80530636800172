import React, { useState } from 'react'

import { Text } from '@chakra-ui/react'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import styled from 'styled-components'

import { Button } from '../base/button/button'
import { Message } from '../base/message/message'
import { Modal } from '../base/modal/modal'
import { ModalActions } from '../base/modal/modal-actions'
import { ModalContent } from '../base/modal/modal-content'
import { ModalTitle } from '../base/modal/modal-title'
import { ButtonLink } from '../base/text-link'
import { Checkbox, CheckboxItem } from '../form-redesigned/checkbox'
import { Spacer } from '../spacer/spacer'

type Props = {
  title: string
  submitText: string
  errorMessage: string
  requesting: boolean
  videoEnabled: boolean
  onSubmit: ({
    isHistory,
    isWorksheet,
    isCallHistory,
  }: {
    isHistory: boolean
    isWorksheet: boolean
    isCallHistory: boolean
  }) => void
  onDownloadCapture: () => void
  onClose: () => void
  children: React.ReactNode
}

enum OutputFormat {
  Worksheet = 'worksheet',
  CallHistory = 'callHistory',
  downloadFile = 'downlooadFile',
}

// children: description
export const OutputModal: React.FC<Props> = props => {
  const [isWorksheet, setIsWorksheet] = useState(false)
  const [isCallHistory, setIsCallHistory] = useState(false)
  const [isHistory, setIsHistory] = useState(false)

  const isHistoryItems: CheckboxItem[] = [
    {
      value: 'history',
      name: `変更履歴も含める`,
      checked: isHistory,
    },
  ]

  const formatItems: CheckboxItem[] = [
    {
      value: OutputFormat.Worksheet,
      name: `ワークシート履歴`,
      checked: isWorksheet,
    },
    ...(props.videoEnabled
      ? [
          {
            value: OutputFormat.CallHistory,
            name: `ビデオ通話履歴`,
            checked: isCallHistory,
          },
        ]
      : []),
  ]

  const onChangeFormat = (value: string) => {
    switch (value) {
      case OutputFormat.Worksheet:
        setIsWorksheet(!isWorksheet)
        break
      case OutputFormat.CallHistory:
        setIsCallHistory(!isCallHistory)
        break
    }
  }

  const onSubmit = () => {
    if (props.requesting) return

    props.onSubmit({ isHistory, isWorksheet, isCallHistory })
  }

  const canSubmit =
    !props.requesting && (isWorksheet || (isCallHistory && !isHistory))

  const { hasPermission } = usePermission()

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title={props.title} />

      <ModalContent>
        {props.children}

        <Spacer size={20} />

        <Text textAlign="center">出力するデータを選択してください。</Text>

        <CheckboxContainer>
          <FormatContainer>
            <Checkbox items={formatItems} onChange={onChangeFormat} />
          </FormatContainer>
          <IsHistoryText>
            <span className="front">(</span>
            <Checkbox
              items={isHistoryItems}
              onChange={() => setIsHistory(!isHistory)}
            />
            <span className="back">)</span>
          </IsHistoryText>
        </CheckboxContainer>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={props.onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text={props.submitText}
          onClick={onSubmit}
          buttonType="important"
          disabled={!canSubmit}
        >
          {props.submitText}
        </Button>
      </ModalActions>

      {props.videoEnabled &&
        hasPermission(PERMISSIONS.Patient_DownloadVideoCallCapture) && (
          <div>
            <Spacer size={8} />
            <DownloadContainer>
              <ButtonLink onClick={props.onDownloadCapture}>
                ビデオ通話キャプチャのダウンロード
              </ButtonLink>
            </DownloadContainer>
          </div>
        )}

      {props.errorMessage && (
        <div>
          <Spacer size={8} />
          <Message type="error" message={props.errorMessage} centered />
        </div>
      )}
    </Modal>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const FormatContainer = styled.div`
  > div {
    flex-direction: column;
    align-items: flex-start;
  }
`

//FIXME: もう少しシンプルにスタイリングする
const IsHistoryText = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > .front {
    position: absolute;
    left: -10px;
  }

  > .back {
    position: absolute;
    right: 20px;
  }

  > div {
    justify-content: center;
    margin: 0;
  }
`

const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
`
