import { useCallback, useMemo, useRef } from 'react'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  useDisclosure,
  useId,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Email } from 'src/components/__legacy__icon/monochrome'
import { CallStart, Edit } from 'src/components/icon'
import { RoleBadge } from 'src/components/RoleBadge/RoleBadge'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { useQuery } from 'src/hooks/use-query'
import { useBreadcrumb } from 'src/hooks/useBreadcrumb'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { parseSchema } from 'src/lib/chicken-schema/utils'
import {
  ReferredPatientDetailScreen_BrowseReferredPatientDocument,
  ReferredPatientDetailScreen_SaveReferredPatientDocument,
  ReferredPatientDetailScreenDocument,
  SaveReferredPatientInput,
  ReferredPatientDetailScreen_EditReferredPatientDocument,
} from 'src/lib/gql-client'
import { useGqlMutation, useGqlQuerySWR } from 'src/lib/gql-client/request'
import { PERMISSIONS } from 'src/lib/permission'

import { formatDate } from '../../../../utils/formatDate'
import { CriteriaValueProvider, isReEditModeAtom } from '../../atom'
import { CriteriaValue } from '../../types'
import {
  generatePathWithTrialHospitalParam,
  TRIAL_HOSPITAL_PARAM_KEY,
} from '../../utils/trialHospitalParam'
import { AcceptReferredPatientButton } from '../AcceptReferredPatientButton/AcceptReferredPatientButton'
import { CancelReEditButton } from '../CancelReEditButton/CancelReEditButton'
import { CancelReferredPatientButton } from '../CancelReferredPatientButton/CancelReferredPatientButton'
import { CreateExplanationButton } from '../CreateExplanationButton/CreateExplanationButton'
import { EditCriteriaForm } from '../EditCriteriaForm/EditCriteriaForm'
import {
  EditReferredPatient,
  ReferredPatientSchema,
} from '../EditReferredPatient/EditReferredPatient'
import { ExcludeReferredPatientButton } from '../ExcludeReferredPatientButton/ExcludeReferredPatientButton'
import { ReEditCriteriaValueButton } from '../ReEditCriteriaValueButton/ReEditedReferredPatientButton'
import { ReferredPatientDetailMenu } from '../ReferredPatientDetailMenu/ReferredPatientDetailMenu'
import { ReferredPatientStatusBadge } from '../ReferredPatientStatusBadge/ReferredPatientStatusBadge'
import { ReferredPatientTransitionBlocker } from '../ReferredPatientTransitionBlocker/ReferredPatientTransitionBlocker'
import { ReferReferredPatientButton } from '../ReferReferredPatientButton/ReferReferredPatientButton'
import { SaveCriteriaValueButton } from '../SaveCriteriaValueButton/SaveCriteriaValueButton'

type Param = {
  referredPatientUid: string
}

const schemaToInput = (
  referredPatientUid: string,
  schema: ReferredPatientSchema,
): SaveReferredPatientInput => {
  return {
    referredPatientUid,
    name: schema.name || undefined,
    age: schema.age ?? undefined,
    assigneeEmail: schema.assigneeEmail || undefined,
    assigneeName: schema.assigneeName ?? undefined,
    gender: schema.gender || undefined,
    assigneePhoneNumber: schema.assigneePhoneNumber || undefined,
    assigneeRole: schema.assigneeRole || undefined,
    chartId: schema.chartId || undefined,
  }
}

export const ReferredPatientDetailScreen: React.FC = () => {
  const { referredPatientUid } = useParams<Param>()

  if (!referredPatientUid) {
    throw new Error('referredPatientUid is required')
  }

  const query = useQuery()
  const trialHospitalParam = query.get(TRIAL_HOSPITAL_PARAM_KEY) ?? undefined

  const {
    account: { selectedTrialHospitalUid, selectedTrialHospitalRole, member },
  } = useAuthenticatedAccount()

  const mutateReferredPatientRef = useRef<() => void>(() => {})

  const { request: browseReferredPatient } = useGqlMutation(
    ReferredPatientDetailScreen_BrowseReferredPatientDocument,
    {
      onSuccess: data => {
        if (data.browseReferredPatient.isLogRecorded) {
          // 循環参照になるため、refを利用
          mutateReferredPatientRef.current()
        }
      },
    },
  )

  const {
    data: { referredPatient },
    mutate: mutateReferredPatient,
  } = useGqlQuerySWR(
    ReferredPatientDetailScreenDocument,
    {
      referredPatientUid,
      selectedTrialHospitalUid: selectedTrialHospitalRole
        ? (selectedTrialHospitalUid ?? '')
        : (trialHospitalParam ?? ''),
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onSuccess: () => {
        mutateReferredPatientRef.current = mutateReferredPatient
        if (
          ['Dr', 'CRC'].includes(member.role) &&
          selectedTrialHospitalRole === 'Main'
        ) {
          browseReferredPatient({ input: { referredPatientUid } })
        }
      },
    },
  )

  const criteria = referredPatient.criteriaList[0]
  const criteriaValues: CriteriaValue[] = useMemo(() => {
    if (!criteria.latestValues) return []
    const fileValues = criteria.latestValues.files
    return criteria.latestValues.values.map(criteriaValue => {
      const fileValue = fileValues.find(
        fileValue =>
          fileValue.fid === criteriaValue.fid &&
          fileValue.fieldIndex === criteriaValue.fieldIndex,
      )
      const value: CriteriaValue = !!fileValue
        ? {
            isFile: true,
            fid: fileValue.fid,
            fieldIndex: fileValue.fieldIndex,
            value: fileValue.files.map(f => ({
              order: f.fileOrder,
              memo: f.memo,
              uploadedFile: {
                uid: f.uploadedFile.uid,
                name: f.uploadedFile.name,
                size: f.uploadedFile.size,
                url: f.uploadedFile.url,
                extension: f.uploadedFile.extension,
                savedAt: f.uploadedFile.savedAt,
              },
            })),
          }
        : {
            fid: criteriaValue.fid,
            fieldIndex: criteriaValue.fieldIndex,
            value: criteriaValue.value,
          }
      return value
    })
  }, [criteria.latestValues])

  const schema = parseSchema(
    criteria.criteria.latestDetail.criteriaSchema.schema,
  )

  return (
    <CriteriaValueProvider
      schema={schema}
      fetchedCriteriaValues={criteriaValues}
      referrerStatus={referredPatient.latestLog.referrerStatus}
    >
      <ReferredPatientTransitionBlocker />
      {/* jotaiで定義したstateを参照できる */}
      <Component referredPatientUid={referredPatientUid} />
    </CriteriaValueProvider>
  )
}

type ComponentProps = {
  referredPatientUid: string
}

export const Component: React.FC<ComponentProps> = ({ referredPatientUid }) => {
  const { renderBreadcrumb } = useBreadcrumb()
  const query = useQuery()
  const trialHospitalParam = query.get(TRIAL_HOSPITAL_PARAM_KEY) ?? undefined

  const [isReEditMode, setIsReEditMode] = useAtom(isReEditModeAtom)

  const navigate = useNavigate()

  const {
    account: {
      selectedTrial,
      selectedTrialHospitalUid,
      selectedTrialHospitalRole,
    },
  } = useAuthenticatedAccount()

  const {
    data: {
      referredPatient,
      trialHospital: { hospital, mainTrialHospital },
    },
    mutate: mutateReferredPatient,
  } = useGqlQuerySWR(
    ReferredPatientDetailScreenDocument,
    {
      referredPatientUid,
      selectedTrialHospitalUid: selectedTrialHospitalRole
        ? (selectedTrialHospitalUid ?? '')
        : (trialHospitalParam ?? ''),
    },
    {
      revalidateIfStale: false,
      revalidateOnMount: false,
    },
  )

  const { data: activeTrialMembersData } = useGqlQuerySWR(
    ReferredPatientDetailScreen_EditReferredPatientDocument,
    selectedTrialHospitalRole === 'Partner' && selectedTrialHospitalUid
      ? {
          selectedTrialHospitalUid: selectedTrialHospitalUid,
        }
      : null,

    {
      revalidateIfStale: false,
      revalidateOnMount: false,
    },
  )

  const toast = useMirohaToast()
  const editReferredPatientModal = useDisclosure()

  const { request: saveReferredPatient } = useGqlMutation(
    ReferredPatientDetailScreen_SaveReferredPatientDocument,
    {
      onSuccess: () => {
        mutateReferredPatient()
        editReferredPatientModal.onClose()
        toast({
          status: 'success',
          title: '紹介患者情報を更新しました',
        })
      },
    },
  )

  const { hasPermission } = usePermission()

  const patientInfoHeaderId = useId()
  const patientHeaderId = useId()
  const contactHeaderId = useId()

  const patientDetail = referredPatient.latestDetail
  const contact = referredPatient.latestContact

  const criteria = referredPatient.criteriaList[0]

  const getFileViewerPath = useCallback(
    (param: { fid: string; fieldIndex: number; order: number }) => {
      const file = criteria.latestValues?.files.find(file => {
        return file.fid === param.fid && file.fieldIndex === param.fieldIndex
      })
      if (!file) return ''
      return `${generatePath(Paths.ReferredPatientFileViewer, {
        referredPatientUid,
        referredPatientCriteriaValueFileUid:
          file.referredPatientCriteriaValueFileUid,
      })}?selectedFile=${param.order}`
    },
    [criteria.latestValues?.files, referredPatientUid],
  )

  // 将来的に複数の選択除外基準がある可能性があるが現状は必ず1つなのでエラーにしてしまう
  if (referredPatient.criteriaList.length !== 1) {
    throw new Error('criteriaList length must be 1')
  }

  const schema = parseSchema(
    criteria.criteria.latestDetail.criteriaSchema.schema,
  )

  const referredPatientsPath = generatePathWithTrialHospitalParam(
    generatePath(Paths.ReferredPatients, {
      trialUid: selectedTrial.uid,
    }),
    trialHospitalParam,
  )

  const referrerStatus = referredPatient.latestLog.referrerStatus
  const refereeStatus = referredPatient.latestLog.refereeStatus

  const isCriteriaEditing = useMemo(() => {
    if (!hasPermission(PERMISSIONS.Referredpatient_Edit)) return false

    if (isReEditMode) return true

    switch (referrerStatus) {
      case 'Created':
      case 'Saved':
        return true
      default:
        return false
    }
  }, [hasPermission, isReEditMode, referrerStatus])

  const canCreateExplanation =
    hasPermission(PERMISSIONS.Explanation_Edit) &&
    selectedTrialHospitalRole === 'Main' &&
    referrerStatus === 'Candidate' &&
    !referredPatient.explanationPatientUid

  return (
    <Flex as="main" h="full" direction="column">
      <Flex justify="space-between" align="center" p="6">
        {renderBreadcrumb([
          {
            link: referredPatientsPath,
            text: '紹介患者一覧',
          },
          {
            isCurrentPage: true,
            text: isReEditMode ? '紹介患者情報編集' : '紹介患者情報詳細',
          },
        ])}
        <Flex gap={6} justify="end" flex="1">
          {/* 紹介前: 一時保存 or 紹介実行 */}
          {(referrerStatus === 'Created' || referrerStatus === 'Saved') &&
            hasPermission(PERMISSIONS.Referredpatient_Edit) &&
            selectedTrialHospitalRole === 'Partner' && (
              <>
                <SaveCriteriaValueButton
                  referredPatientCriteriaUid={
                    criteria.referredPatientCriteriaUid
                  }
                  mutateReferredPatient={mutateReferredPatient}
                />
                <ReferReferredPatientButton
                  referredPatientUid={referredPatientUid}
                  onComplete={async () => {
                    await mutateReferredPatient()
                    navigate(referredPatientsPath)
                  }}
                />
              </>
            )}

          {/* 紹介後: 紹介取消 or 再編集 */}
          {(referrerStatus === 'Referred' || referrerStatus === 'Awaiting') &&
            !isReEditMode &&
            !!referredPatient.latestRefer &&
            hasPermission(PERMISSIONS.Referredpatient_Edit) &&
            selectedTrialHospitalRole === 'Partner' && (
              <>
                <CancelReferredPatientButton
                  referredPatientReferUid={
                    referredPatient.latestRefer.referredPatientReferUid
                  }
                  mutateReferredPatient={mutateReferredPatient}
                  mainHospitalName={mainTrialHospital?.hospital.name ?? ''}
                />
                <Button
                  onClick={() => {
                    setIsReEditMode(true)
                  }}
                >
                  再編集
                </Button>
              </>
            )}

          {isReEditMode &&
            hasPermission(PERMISSIONS.Referredpatient_Edit) &&
            selectedTrialHospitalRole === 'Partner' && (
              <>
                <CancelReEditButton />
                <ReEditCriteriaValueButton
                  referredPatientCriteriaUid={
                    criteria.referredPatientCriteriaUid
                  }
                  mainHospitalName={mainTrialHospital?.hospital.name ?? ''}
                  referredPatientsPath={referredPatientsPath}
                  mutateReferredPatient={mutateReferredPatient}
                />
              </>
            )}

          {/* 紹介後-実施医療機関: 候補化 or 除外 */}
          {!!referredPatient.latestRefer &&
            selectedTrialHospitalRole === 'Main' &&
            hasPermission(PERMISSIONS.Referredpatient_Edit) && (
              <>
                {(refereeStatus === 'Browsed' ||
                  refereeStatus === 'Awaiting') && (
                  <ExcludeReferredPatientButton
                    componentType="Button"
                    referredPatientReferUid={
                      referredPatient.latestRefer.referredPatientReferUid
                    }
                    numberingID={referredPatient.numberingId}
                    age={referredPatient.latestDetail.age}
                    gender={referredPatient.latestDetail.gender}
                    patientHospitalName={
                      referredPatient.patientTrialHospital.hospital.name
                    }
                    mutateReferredPatient={mutateReferredPatient}
                    referredPatientsPath={referredPatientsPath}
                  />
                )}
                {(refereeStatus === 'NotBrowsed' ||
                  refereeStatus === 'Browsed') && (
                  <AcceptReferredPatientButton
                    referredPatientReferUid={
                      referredPatient.latestRefer.referredPatientReferUid
                    }
                    mutateReferredPatient={mutateReferredPatient}
                    referredPatientsPath={referredPatientsPath}
                  />
                )}
              </>
            )}
          {canCreateExplanation && (
            <CreateExplanationButton referredPatientUid={referredPatientUid} />
          )}
          <ReferredPatientDetailMenu
            referredPatient={referredPatient}
            mutateReferredPatient={mutateReferredPatient}
            referredPatientsPath={referredPatientsPath}
            showDelete={
              (referrerStatus === 'Created' || referrerStatus === 'Saved') &&
              hasPermission(PERMISSIONS.Referredpatient_Edit) &&
              selectedTrialHospitalRole === 'Partner'
            }
            showExclude={
              !!referredPatient.latestRefer &&
              selectedTrialHospitalRole === 'Main' &&
              hasPermission(PERMISSIONS.Referredpatient_Edit) &&
              refereeStatus === 'Candidate' &&
              canCreateExplanation
            }
          />
        </Flex>
      </Flex>
      {canCreateExplanation && (
        <Box px="6" pb="6">
          <Alert status="info" flexDirection="column">
            <Flex gap="6px">
              <AlertIcon boxSize="24px" m="0" />
              <AlertTitle>
                「説明同意登録」ボタンより説明同意の登録が可能です。
              </AlertTitle>
            </Flex>
            <AlertDescription pl="30px">
              説明同意では候補患者との面談日程の入力欄があるため、あらかじめ日程調整をされることをお勧めします。
            </AlertDescription>
          </Alert>
        </Box>
      )}
      <Box overflow="auto" px="6" pb="8">
        <Box as="section" aria-labelledby={patientInfoHeaderId}>
          <HStack spacing="2">
            <TextWithBar id={patientInfoHeaderId} as="h2" fontWeight="bold">
              紹介患者情報
            </TextWithBar>
            {hasPermission(PERMISSIONS.Referredpatient_Edit) &&
              selectedTrialHospitalRole === 'Partner' &&
              (['Created', 'Saved'].includes(referrerStatus) ||
                isReEditMode) && (
                <IconButton
                  icon={<Edit />}
                  aria-label="紹介患者情報を編集"
                  variant="customIconButtonGhost"
                  onClick={editReferredPatientModal.onOpen}
                />
              )}
            {editReferredPatientModal.isOpen && (
              <EditReferredPatient
                modalType="Modify"
                isOpen
                onClose={editReferredPatientModal.onClose}
                mutate={async schema => {
                  await saveReferredPatient({
                    input: schemaToInput(referredPatientUid, schema),
                  })
                }}
                patient={referredPatient}
                trialMembers={
                  activeTrialMembersData.activeTrialMembersByTrialHospitalUids ??
                  []
                }
              />
            )}
          </HStack>
          <Stack spacing="8">
            <Box as="section" mt="3" aria-labelledby={patientHeaderId}>
              <Text
                id={patientHeaderId}
                fontSize="sm"
                as="h3"
                color="gray.600"
                fontWeight="bold"
              >
                紹介患者
              </Text>
              <Flex columnGap={10} rowGap={5} mt="2" wrap="wrap" align="center">
                <HStack>
                  {selectedTrialHospitalRole === 'Partner' ? (
                    <ReferredPatientStatusBadge status={referrerStatus} />
                  ) : (
                    refereeStatus && (
                      <ReferredPatientStatusBadge status={refereeStatus} />
                    )
                  )}
                  <Text as="span">
                    {`No：`}
                    <span aria-label="紹介患者ID">
                      {referredPatient.numberingId}
                    </span>
                  </Text>
                </HStack>

                {selectedTrialHospitalRole === 'Partner' &&
                  !!patientDetail.name && (
                    <Text as="span" aria-label="紹介患者氏名">
                      {patientDetail.name}
                    </Text>
                  )}
                {!!patientDetail.age && (
                  <Text
                    as="span"
                    aria-label="紹介患者年齢"
                  >{`${patientDetail.age}歳`}</Text>
                )}
                {!!patientDetail.gender && (
                  <Text as="span" aria-label="紹介患者性別">
                    {patientDetail.gender === 'Male'
                      ? '男性'
                      : patientDetail.gender === 'Female'
                        ? '女性'
                        : null}
                  </Text>
                )}
                {selectedTrialHospitalRole === 'Partner' &&
                  !!patientDetail.chartId && (
                    <Text as="span">
                      {`カルテID：`}
                      <span aria-label="カルテID">{patientDetail.chartId}</span>
                    </Text>
                  )}
                {selectedTrialHospitalRole !== 'Partner' && (
                  <Text as="span">
                    <span aria-label="パートナー施設">
                      {referredPatient.patientTrialHospital.hospital.name}
                    </span>
                  </Text>
                )}
                <Text as="span">
                  <span aria-label="紹介日">
                    {`紹介日：`}
                    {referredPatient.latestRefer?.referredAt &&
                    (referrerStatus === 'Candidate' ||
                      referrerStatus === 'Referred' ||
                      referrerStatus === 'Awaiting')
                      ? formatDate(
                          referredPatient.latestRefer.referredAt,
                          'YYYY/MM/DD (ddd) HH:mm',
                        )
                      : undefined}
                  </span>
                </Text>
              </Flex>
            </Box>

            <Box as="section" aria-labelledby={contactHeaderId}>
              <Text
                id={contactHeaderId}
                fontSize="sm"
                as="h3"
                color="gray.600"
                fontWeight="bold"
              >
                担当者連絡先
              </Text>
              <Flex columnGap={10} rowGap={5} align="center" mt="2" wrap="wrap">
                <HStack spacing="1.5" align="center">
                  {!!contact.assigneeRole && (
                    <RoleBadge role={contact.assigneeRole} />
                  )}
                  <Text as="span" aria-label="担当者名">
                    {contact.assigneeName}
                  </Text>
                </HStack>
                <Flex
                  gap={1.5}
                  align="center"
                  aria-label="担当者メールアドレス"
                >
                  <Box as="span" w="16px">
                    {/* TODO: replace icon */}
                    <Email color={colors.gray[500]} />
                  </Box>
                  <Text>
                    {!!contact.assigneeEmail ? contact.assigneeEmail : '未設定'}
                  </Text>
                </Flex>
                <Flex gap={1.5} align="center" aria-label="担当者電話番号">
                  <Box as="span" w="16px">
                    <CallStart color={colors.gray[500]} />
                  </Box>
                  <Text>
                    {!!contact.assigneePhoneNumber
                      ? contact.assigneePhoneNumber
                      : '未設定'}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Stack>
        </Box>
        <Divider my="10" />
        <Stack as="section" spacing="6" aria-label="選択除外基準入力フォーム">
          <Stack>
            <Text as="h2" fontSize="lg" fontWeight="bold">
              {schema.name}
            </Text>
            <Text fontSize="sm">
              {`実施医療機関: ${mainTrialHospital ? mainTrialHospital.hospital.name : hospital.name}`}
            </Text>
          </Stack>
          <EditCriteriaForm
            schema={schema}
            isDisabled={!isCriteriaEditing}
            fileViewerPath={getFileViewerPath}
          />
        </Stack>
      </Box>
    </Flex>
  )
}
