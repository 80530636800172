// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgSettings = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#settings_svg__a)">
      <path
        fill={props.color ?? 'currentColor'}
        d="m18.79 8.75-3.1-.44c-.12-.4-.27-.78-.47-1.14l1.88-2.51c.09-.12.08-.3-.03-.41l-1.33-1.33a.318.318 0 0 0-.41-.03l-2.51 1.88c-.36-.2-.74-.35-1.14-.47l-.44-3.1a.313.313 0 0 0-.31-.27H9.05c-.16 0-.29.11-.31.27L8.3 4.3c-.4.12-.78.27-1.14.47L4.65 2.89a.319.319 0 0 0-.41.03L2.91 4.25c-.11.11-.12.28-.03.41l1.88 2.51c-.2.36-.35.74-.47 1.14l-3.1.44c-.15.02-.27.15-.27.31v1.88c0 .16.11.29.27.31l3.1.44c.12.4.27.78.47 1.14l-1.88 2.51c-.09.12-.08.3.03.41l1.33 1.33c.06.06.14.09.22.09.07 0 .13-.02.19-.06l2.51-1.88c.36.2.74.35 1.14.47l.44 3.1c.02.15.15.27.31.27h1.88c.16 0 .29-.11.31-.27l.44-3.1c.4-.12.78-.27 1.14-.47l2.51 1.88c.06.04.12.06.19.06.08 0 .16-.03.22-.09l1.33-1.33c.11-.11.12-.28.03-.41l-1.88-2.51c.2-.36.35-.74.47-1.14l3.1-.44c.15-.02.27-.15.27-.31V9.06c0-.16-.11-.29-.27-.31ZM10 12.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z"
      />
    </g>
    <defs>
      <clipPath id="settings_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgSettings
