import { useSelectedTrial } from 'src/features/auth/context'
import { Trial, TrialHospital } from 'src/features/trial/types'
import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { IcfDocumentSet, IcfDocumentSetDetail } from '../../types'
import { icfDocumentCacheKey } from '../cacheKey'
import { parseIcfDocumentSetDetail } from '../parser'

const getIcfDocumentSet = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentSetUid,
  excludeDisabledDocs,
}: {
  trialUid: Trial['uid']
  trialHospitalUid: TrialHospital['uid']
  icfDocumentSetUid: IcfDocumentSet['uid']
  excludeDisabledDocs?: boolean
}): Promise<IcfDocumentSetDetail> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/trial_hospitals/{trial_hospital_uid}/icf_document_sets/{icf_document_set_uid}',
    httpMethod: 'get',
    params: {
      paths: {
        trial_uid: trialUid,
        trial_hospital_uid: trialHospitalUid,
        icf_document_set_uid: icfDocumentSetUid,
      },
      query: {
        exclude_disabled_docs: excludeDisabledDocs,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return parseIcfDocumentSetDetail(res.data)
}

export const useIcfDocumentSet = ({
  icfDocumentSetUid,
  trialHospitalUid,
  excludeDisabledDocs,
  ...option
}: {
  icfDocumentSetUid: IcfDocumentSet['uid']
  trialHospitalUid: TrialHospital['uid']
  excludeDisabledDocs?: boolean
} & SWRConfiguration<IcfDocumentSetDetail, Error>) => {
  const { selectedTrial } = useSelectedTrial()

  return useSWR<IcfDocumentSetDetail, Error>(
    icfDocumentCacheKey.getIcfDocumentSet(trialHospitalUid, icfDocumentSetUid),
    () =>
      getIcfDocumentSet({
        trialUid: selectedTrial.uid,
        trialHospitalUid,
        icfDocumentSetUid,
        excludeDisabledDocs,
      }),
    option,
  )
}
