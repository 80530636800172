import {
  GqlError,
  GetPatientVideoCallQuery,
  GetPatientVideoCallQueryVariables,
  GetPatientVideoCallDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { patientCacheKey } from '../cacheKey'

export type GetPatientVideoCallReturn =
  GetPatientVideoCallQuery['patientVideoCall']

const getPatientVideoCall = async ({
  patientVideoCallUid,
}: GetPatientVideoCallQueryVariables): Promise<GetPatientVideoCallReturn> => {
  try {
    const res = await graphqlRequest(GetPatientVideoCallDocument, {
      patientVideoCallUid,
    })
    return res.patientVideoCall
  } catch (error) {
    throw error
  }
}

export const usePatientVideoCall = ({
  patientVideoCallUid,
  ...option
}: GetPatientVideoCallQueryVariables &
  SWRConfiguration<GetPatientVideoCallReturn, GqlError>) => {
  return useSWR(
    patientCacheKey.getPatientVideoCall(patientVideoCallUid),
    () => getPatientVideoCall({ patientVideoCallUid }),
    option,
  )
}
