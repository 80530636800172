import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { gray } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'
import { flex, FlexProps, space, SpaceProps } from 'styled-system'

import { getPreviewData } from './preview-data'
import { getState } from '../selector'

type Props = {
  onClickNext: () => void
  onClickPrev: () => void
}

export const PreviewComponent: React.FC<Props> = props => {
  const { onClickNext, onClickPrev } = props
  const { folderTemplates, sections } = useSelector(getState)
  const previewData = getPreviewData({
    folderTemplates,
    sections,
  })

  if (!previewData) {
    return null
  }

  return (
    <Container>
      <Text fontSize="lg" fontWeight="bold">
        プレビュー
      </Text>

      <Spacer size={30} />

      {/* FIXME: テーブルコンポーネントを使いたい */}
      <Table>
        <Head>
          <HeadRowContainer>
            <HeadRow>
              <HeadItem flex={3}>
                <Text fontWeight="bold">フォルダ</Text>
              </HeadItem>
              <HeadItem flex={5}>
                <Text fontWeight="bold">テンプレート</Text>
              </HeadItem>
              <HeadItem flex={5}>
                <Text fontWeight="bold">セクション</Text>
              </HeadItem>
              <HeadItem flex={5}>
                <Text fontWeight="bold">EDC Folder / Form</Text>
              </HeadItem>
            </HeadRow>
          </HeadRowContainer>
        </Head>

        <Body>
          <ScrollItem>
            {previewData.map((data, i) => (
              <BodyRow key={data.templateFolderName}>
                <Block flex={1}>
                  <Space p={2}>
                    <Text>{data.templateFolderName}</Text>
                  </Space>
                </Block>

                <TemplateArea flex={5}>
                  {data.templateData.map((templateData, i) => {
                    const templateName = templateData.templateName
                    const sections = templateData.sections

                    return (
                      <TemplateRow
                        key={templateName}
                        isLast={i === data.templateData.length - 1}
                      >
                        <Block flex={1}>
                          <Space p={2}>
                            <Text>{templateName}</Text>
                          </Space>
                        </Block>

                        <SectionArea flex={2}>
                          {sections.map(s => (
                            <SectionRow key={s.name}>
                              <Block flex={1}>
                                <Space p={2}>
                                  <Text>{s.name}</Text>
                                </Space>
                              </Block>
                              <Block flex={1}>
                                <Space p={2}>
                                  <Text>
                                    {s.edcFolderOid} / {s.edcFormOid}
                                  </Text>
                                </Space>
                              </Block>
                            </SectionRow>
                          ))}
                        </SectionArea>
                      </TemplateRow>
                    )
                  })}
                </TemplateArea>
              </BodyRow>
            ))}
          </ScrollItem>
        </Body>
      </Table>

      <Spacer size={30} />

      <ButtonWrapper>
        <ButtonItem>
          <Button
            size="S"
            text="戻る"
            buttonType="cancel"
            onClick={onClickPrev}
          ></Button>
        </ButtonItem>

        <ButtonItem>
          <Button
            size="S"
            text="次へ"
            buttonType="important"
            onClick={onClickNext}
          ></Button>
        </ButtonItem>
      </ButtonWrapper>
      <Spacer size={100} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${gray[40]};
  border-radius: 10px;
`
const Head = styled.div`
  border-bottom: 1px solid ${gray[40]};
`

const SCROLL_BAR_WIDTH = 6
const HeadRowContainer = styled.div`
  display: flex;
  width: calc(100% - ${SCROLL_BAR_WIDTH}px - 10px);
  padding: 0 20px;
  box-sizing: border-box;
`
const HeadRow = styled.div`
  display: flex;
  width: 100%;
`

const HeadItem = styled.div<FlexProps>`
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;

  ${flex};
`

const Body = styled.div`
  position: relative;
  margin-right: 10px;
  flex: 1;
`
const ScrollItem = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  border-top: none;

  overflow-y: auto;
`
const BodyRow = styled.div`
  display: flex;
  border-bottom: 1px dashed ${gray[40]};
`

const Block = styled.div<FlexProps>`
  display: flex;
  align-items: center;

  ${flex}
`
const Space = styled.div<SpaceProps>`
  ${space}
`

const TemplateArea = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${flex}
`

const TemplateRow = styled.div<{ isLast: boolean }>`
  display: flex;
  border-bottom: 1px dashed ${gray[40]};
  border: ${props => props.isLast && 'none'};
`

const SectionArea = styled.div<FlexProps>`
  height: 100%;

  ${flex}
`

const SectionRow = styled.div`
  display: flex;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const ButtonItem = styled.div`
  margin: 0 20px;
`
