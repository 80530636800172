import { useSelectedTrial } from 'src/features/auth/context'
import { GetTrialHospitalsDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { TrialHospital } from '../../types'
import { trialCacheKey } from '../cacheKey'
import { parseGqlTrailHospital } from '../parser'

const getTrialHospitals = async (): Promise<TrialHospital[]> => {
  try {
    const res = await graphqlRequest(GetTrialHospitalsDocument, {})
    return res.enabledTrialHospitals.map(parseGqlTrailHospital)
  } catch (e) {
    throw e
  }
}

export const useTrialHospitals = ({
  shouldCancel = false,
  ...options
}: {
  shouldCancel?: boolean
} & SWRConfiguration<TrialHospital[], GqlError>) => {
  const { selectedTrial } = useSelectedTrial()
  return useSWR<TrialHospital[], GqlError>(
    shouldCancel ? null : trialCacheKey.getTrialHospitals(selectedTrial.uid),
    () => getTrialHospitals(),
    options,
  )
}
