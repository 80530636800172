import { Dictionary } from 'lodash'

export enum Role {
  Admin = 1, // Adminロールは現在利用なし
  DM,
  CRA,
  Dr,
  CRC,
}

export const toggleRoles: (roles: Role[], toggledRole: Role) => Role[] = (
  roles,
  toggledRole,
) => {
  return roles.includes(toggledRole)
    ? roles.filter(r => r !== toggledRole)
    : [...roles, toggledRole]
}

export type Member = {
  uid: string // API 側の、 User でなく TrialMember の uid に対応する
  firstName: string
  lastName: string
  email: string
  role: Role
  trialHospitals: Dictionary<string>
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  registeredAt: string | null

  trialUid: string
}
