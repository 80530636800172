import { IcfDocumentType } from '../types'

const typeTextMap: Record<IcfDocumentType, string> = {
  Undefined: '',
  AgreementForm: '同意書',
  CheckUnderstanding: '理解度確認',
  Description: '説明文書',
  Video: '動画',
}

export const icfDocumentTypeToText = (type: IcfDocumentType): string =>
  typeTextMap[type]
