import { SERVER_URL } from 'src/modules/server/const'
import { requestPost, requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { CrfSection, CrfTemplate, CrfFolderTemplates } from './entity'
import { sectionsFixture, folderTemplateFixture } from './fixture'

type CrfTemplateResponse = {
  template_folder_name: string
  template_name: string
}
type CrfFolderTemplatesResponse = {
  crf_folder_template_uid: string
  template_folders: string[]
  templates: CrfTemplateResponse[]
}

type CrfSectionResponse = {
  section_name: string
  edc_folder_oid: string
  edc_form_oid: string
  template_name: string
}
type CrfSectionsResponse = {
  sections: CrfSectionResponse[]
}

export const uploadFolderTemplates = async ({
  trialUid,
  params,
}: {
  trialUid: string
  params: FormData
}): Promise<CrfFolderTemplates> => {
  if (IS_MOCK_MODE) {
    return folderTemplateFixture
  }

  const resp = await requestPost<CrfFolderTemplatesResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/crf/folder_templates`,
    params,
  })

  return parseCrfFolderTemplates(resp.data)
}

export const uploadSection = async ({
  trialUid,
  crfFolderTemplateUid,
  params,
}: {
  trialUid: string
  crfFolderTemplateUid: string
  params: FormData
}): Promise<CrfSection[]> => {
  if (IS_MOCK_MODE) {
    return sectionsFixture
  }

  const resp = await requestPut<CrfSectionsResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/crf/folder_templates/${crfFolderTemplateUid}/sections`,
    params,
  })

  return resp.data.sections.map(parseCrfSection)
}

export const uploadField = async ({
  trialUid,
  crfFolderTemplateUid,
  params,
}: {
  trialUid: string
  crfFolderTemplateUid: string
  params: FormData
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPut({
    url: `${SERVER_URL}/trials/${trialUid}/crf/folder_templates/${crfFolderTemplateUid}/fields`,
    params,
  })

  return {}
}

export const uploadChoice = async ({
  trialUid,
  crfFolderTemplateUid,
  params,
}: {
  trialUid: string
  crfFolderTemplateUid: string
  params: FormData
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }
  await requestPut({
    url: `${SERVER_URL}/trials/${trialUid}/crf/folder_templates/${crfFolderTemplateUid}/choices`,
    params,
  })

  return {}
}

export const confirm = async ({
  trialUid,
  crfFolderTemplateUid,
}: {
  trialUid: string
  crfFolderTemplateUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPost({
    url: `${SERVER_URL}/trials/${trialUid}/crf`,
    params: {
      crf_folder_template_uid: crfFolderTemplateUid,
    },
  })

  return {}
}

const parseCrfFolderTemplates = (
  resp: CrfFolderTemplatesResponse,
): CrfFolderTemplates => {
  return {
    crfFolderTemplateUid: resp.crf_folder_template_uid,
    templateFolders: resp.template_folders,
    templates: resp.templates.map(parseCrfTemplate),
  }
}
const parseCrfTemplate = (resp: CrfTemplateResponse): CrfTemplate => {
  return {
    templateFolderName: resp.template_folder_name,
    templateName: resp.template_name,
  }
}

const parseCrfSection = (resp: CrfSectionResponse): CrfSection => {
  return {
    name: resp.section_name,
    edcFolderOid: resp.edc_folder_oid,
    edcFormOid: resp.edc_form_oid,
    templateName: resp.template_name,
  }
}
