import { ChoiceAnswer } from './entity'

export const createFixture = (values?: Partial<ChoiceAnswer>): ChoiceAnswer => {
  const res = {
    uid: 'fooChoiceAnswerUid',
    description: '選択肢A',
    checked: false,
    choiceUid: 'fooChoiceUid',
    smallItemAnswerUid: 'fooSmallItemAnswerUid',
    smallItemAnswerUids: [],
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof ChoiceAnswer
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}
