import React, { useCallback } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { Patient } from 'src/modules/entities/patient/entity'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import { QuestionnaireSectionAnswer } from 'src/modules/entities/questionnaire-section-answer/entity'
import { eproPatientUserKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { List } from './list'
import { trialIdParamName } from '../../epro-page'
import { fetchList, fetchMe } from '../request'
import { getDetailRoute } from '../routes'

export const ListContainer = () => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()

  const { data: patientInfo, error: fetchPatientInfoError } = useSWR<
    Pick<Patient, 'uid' | 'diseaseUid' | 'eproStatus'>,
    Error
  >(eproPatientUserKeys.fetchMe(trialUid), fetchMe)

  const { data: questionnaireAnswers, error: fetchQuestionnarieAnswersError } =
    useSWR<QuestionnaireAnswer[], Error>(
      eproPatientUserKeys.fetchQuestionnaireAnswerList(trialUid),
      fetchList,
    )

  const navigate = useNavigate()

  const onClick = useCallback(
    (questionnaireAnswer: QuestionnaireAnswer) => {
      const indexAsc = (
        a: QuestionnaireSectionAnswer,
        b: QuestionnaireSectionAnswer,
      ) => a.index - b.index
      const firstQuestionnaireSectionAnswerUid =
        questionnaireAnswer.sectionAnswers.sort(indexAsc)[0].uid

      navigate(
        getDetailRoute({
          trialUid: trialUid,
          questionnaireAnswerUid: questionnaireAnswer.uid,
          questionnaireSectionAnswerUid: firstQuestionnaireSectionAnswerUid,
        }),
      )
    },
    [navigate, trialUid],
  )

  if (fetchPatientInfoError) return <>{fetchPatientInfoError.message}</>
  if (fetchQuestionnarieAnswersError)
    return <>{fetchQuestionnarieAnswersError.message}</>
  if (!patientInfo || !questionnaireAnswers) return null

  return (
    <List
      patientInfo={patientInfo}
      questionnaireAnswers={questionnaireAnswers}
      onClick={onClick}
    />
  )
}
