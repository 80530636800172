import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost, requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Patient, Gender } from './entity'
import { fixture } from './fixture'

export type PatientResponse = {
  uid: string
  chart_uid: string
  hospital_uid: string
  hospital_name: string
  disease_uid: string
  first_name: string
  first_name_ja: string
  last_name: string
  last_name_ja: string
  gender: Gender
  birthday: string
  start_record_on: string
  phone_number: string
  email: string
  status: number
  epro_status: number
  first_visit_date: string
  answer_start_date: string
  is_password_issued: boolean
}

export const parse = (resp: PatientResponse): Patient => {
  return {
    uid: resp.uid,
    diseaseUid: resp.disease_uid,
    chartUid: resp.chart_uid,
    hospitalUid: resp.hospital_uid,
    hospitalName: resp.hospital_name,
    firstName: resp.first_name,
    firstNameJa: resp.first_name_ja,
    lastName: resp.last_name,
    lastNameJa: resp.last_name_ja,
    gender: resp.gender,
    birthday: resp.birthday,
    phoneNumber: resp.phone_number,
    email: resp.email,
    status: resp.status,
    eproStatus: resp.epro_status,
    isPasswordIssued: resp.is_password_issued,
    firstVisitDate: resp.first_visit_date,
    answerStartDate: resp.answer_start_date,

    ratio: fixture.ratio,
    createdAt: fixture.createdAt,
    updatedAt: fixture.updatedAt,
  }
}

export const fetch = async ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}): Promise<Patient> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const resp = await requestGet<PatientResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}`,
    params: [],
  })

  return parse(resp.data)
}

export type PatientEproUpdateRequest = {
  firstVisitDate: string | null
  answerStartDate: string | null
}

export const eproUpdate = async ({
  trialUid,
  patientUid,
  params,
}: {
  trialUid: string
  patientUid: string
  params: PatientEproUpdateRequest
}): Promise<Patient> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const resp = await requestPut<PatientResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/epro`,
    params,
  })

  return parse(resp.data)
}

export const confirmAnswerStateDate = async ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}): Promise<void> => {
  if (IS_MOCK_MODE) return

  await requestPost<void>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/start_trial`,
  })

  return
}

export enum TrialFinishReason {
  Unspecified = 0,
  End,
  Discontinue,
}

type FinalTrialResponse = {
  epro_status: number
}

export const finishTrial = async ({
  trialUid,
  patientUid,
  reason,
}: {
  trialUid: string
  patientUid: string
  reason: TrialFinishReason
}): Promise<FinalTrialResponse> => {
  if (IS_MOCK_MODE) {
    return {
      epro_status: 1, // NOTE: `PatientEPROStatusTrialCompleted`
    }
  }

  const resp = await requestPost<FinalTrialResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/finish_trial`,
    params: {
      reason: reason,
    },
  })

  return resp.data
}
