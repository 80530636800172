import dayjs from 'dayjs'

export function getTimeDifferenceStr(dateInput: string): string {
  const now = dayjs()
  const date = dayjs(dateInput)

  const diffInYears = now.diff(date, 'year')
  date.add(diffInYears, 'year')

  const diffInMonths = now.diff(date, 'month')
  date.add(diffInMonths, 'month')

  const diffInWeeks = now.diff(date, 'week')
  date.add(diffInWeeks, 'week')

  const diffInDays = now.diff(date, 'day')
  date.add(diffInDays, 'day')

  const diffInHours = now.diff(date, 'hour')
  date.add(diffInHours, 'hour')

  const diffInMinutes = now.diff(date, 'minute')

  if (diffInYears > 0) {
    return `${diffInYears}年前`
  } else if (diffInMonths > 0) {
    return `${diffInMonths}ヶ月前`
  } else if (diffInWeeks > 0) {
    return `${diffInWeeks}週間前`
  } else if (diffInDays > 0) {
    return `${diffInDays}日前`
  } else if (diffInHours > 0) {
    return `${diffInHours}時間前`
  } else {
    return `${diffInMinutes}分前`
  }
}
