import { Account } from 'src/features/auth/types'
import { Member } from 'src/features/member/types'
import { Trial, TrialHospital } from 'src/features/trial/types'
import { DRPermissionsForFixture } from 'src/lib/permission'
import { castUid } from 'src/utils/brandedUid'
import { generateUUID } from 'src/utils/generateUUID'

export const uuidFactory = <T extends { uid: string }>() => {
  return castUid<T>(generateUUID())
}

type AuthenticatedAccount = Account & { hasSelectedTrial: true }

export const trialHospitalUid1 = castUid<TrialHospital>(
  '25aeb110-6c07-47d8-99a6-6ae8b50af3a7',
)
export const trialHospitalUid2 = castUid<TrialHospital>(
  '25aeb110-6c07-47d8-99a6-6ae8b50af3a8',
)

export const hospitalName1 = 'test hospital1'
export const hospitalName2 = 'test hospital2'

export const authenticatedAccountFactory = (
  override?: Partial<AuthenticatedAccount>,
): AuthenticatedAccount => {
  return {
    uid: uuidFactory<Account>(),
    firstName: '太郎',
    lastName: 'micin',
    email: 'taro_micin@example.com',
    mfaEnabled: false,
    hasSelectedTrial: true,
    hasMultipleMemberships: true,
    selectedTrial: {
      uid: uuidFactory<Trial>(),
      name: 'micin治験',
      videoEnabled: false,
      pdfOutputEnabled: true,
      videoCaptureEnabled: false,
      videoCaptureAccessRestricted: false,
      edcIntegrationEnabled: false,
      hasWorksheet: true,
      eProEnabled: true,
      questionnaireScheduleReleased: true,
      multiSiteVideoCallEnabled: false,
      newEconsentEnabled: true,
      eRecruitEnabled: true,
      videoProvider: 2,
      trialHospitals: [
        {
          uid: trialHospitalUid1,
          name: hospitalName1,
          role: 'Main',
        },
        {
          uid: trialHospitalUid2,
          name: hospitalName2,
          role: 'Main',
        },
      ],
      featureChannel: 'Stable',
      featureFlags: {
        eConsentNewSignFlow: false,
        eSourceV1: false,
        eSourceV2: true,
        eRecruit: true,
        preScreening: true,
      },
    },
    member: {
      uid: uuidFactory<Member>(),
      firstName: '太郎',
      lastName: 'micin',
      role: 'Dr',
    },
    selectedTrialHospitalUid: trialHospitalUid1,
    selectedTrialHospitalRole: 'Main',
    permissions: DRPermissionsForFixture,
    ...override,
  }
}
