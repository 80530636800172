import axios from 'axios'
import { httpStatus } from 'src/constants/statusCodes'

import {
  ApiBodyFromPathAndMethod,
  ApiErrorFromPathAndMethod,
  ApiPath,
  ApiPathParamFromPathAndMethod,
  ApiQueryParam,
  ApiResponseFromPathAndMethod,
  HttpMethod,
} from './schema-util'

type CreateApiClientOption<Path extends ApiPath, Method extends HttpMethod> = {
  path: Path
  httpMethod: Method
  params?: {
    paths?: ApiPathParamFromPathAndMethod<Path, Method>
    query?: ApiQueryParam<Path, Method>
    body?: ApiBodyFromPathAndMethod<Path, Method>
  }
}

export type RequestResponse<Path extends ApiPath, Method extends HttpMethod> =
  | { result: 'success'; data: ApiResponseFromPathAndMethod<Path, Method> }
  | { result: 'error'; error: ApiErrorFromPathAndMethod<Path, Method> }

export const createApiClient = <
  Path extends ApiPath,
  Method extends HttpMethod,
>(
  option: CreateApiClientOption<Path, Method>,
) => {
  const path = () => {
    // {trial_uid} などとなっているpathを実際の値に変換する
    const fullPath = Object.entries(option.params?.paths ?? {}).reduce(
      (prev, [key, value]) =>
        prev.replace(new RegExp(`\\{${key}\\}`), String(value)),
      option.path as string,
    )

    const searchParam = new URLSearchParams()
    Object.entries(option.params?.query ?? {}).forEach(([key, value]) => {
      if (typeof value === 'string') {
        searchParam.set(key, value)
      }
    })

    if (searchParam.toString().length > 0) {
      return fullPath + '?' + searchParam.toString()
    }

    return fullPath
  }

  const request = async (): Promise<RequestResponse<Path, Method>> => {
    try {
      const res = await axios.request<
        ApiResponseFromPathAndMethod<Path, Method>
      >({
        baseURL: import.meta.env.VITE_SERVER_URL,
        method: option.httpMethod,
        url: path(),
        data: option.params?.body,
        withCredentials: true,
      })
      return {
        result: 'success',
        data: res.data,
      }
    } catch (e) {
      if (axios.isAxiosError(e) && !!e.response) {
        const errorData = { status: e.response.status, data: e.response.data }
        if (isExpectedError<Path, Method>(errorData)) {
          return {
            result: 'error',
            error: errorData,
          }
        }
      }
      throw new Error('unexpected error')
    }
  }

  return { path, request }
}

// schema-utilで定義しているエラーコードのリストにレスポンスのステータスコードが含まれるかチェック
const isExpectedError = <Path extends ApiPath, Method extends HttpMethod>(res: {
  status: number
  data: any
}): res is ApiErrorFromPathAndMethod<Path, Method> => {
  // 400番台と500番台をfiltering
  const errorCodes = Object.values(httpStatus).filter(
    status => 400 <= status && status < 600,
  )
  return errorCodes.map(Number).includes(res.status)
}
