import { Input } from '@chakra-ui/react'
import { Field, TextTypeDef } from '@micin-jp/chicken-schema'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  textField: Field & {
    typeDef: TextTypeDef
  }
  index: number
}

export const WorksheetTextField: React.FC<Props> = ({ textField, index }) => {
  const {
    findFieldValue,
    onChangeTextFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: textField.fid,
    index,
  })

  const onChange = (textValue: string) => {
    onChangeTextFieldValue({
      fid: textField.fid,
      index,
      textValue,
    })
  }

  return (
    <WorksheetFieldFormControl
      field={textField}
      index={index}
      isDisabled={!canEdit || !isRepeatableSectionEnabled(index, textField.fid)}
      errorMessage={getErrorMessage(value)}
    >
      <Input
        type="text"
        value={value?.type === 'text' ? value.textValue : undefined}
        onChange={e => onChange(e.target.value)}
      />
    </WorksheetFieldFormControl>
  )
}
