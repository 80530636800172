import React, { useCallback, useState } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { TrialFinishReason } from 'src/modules/entities/patient/request'
import { getSingle } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'

import { FinishTrialModal } from './finish-trial-modal'
import { useFinishTrial } from './use-finish-trial'
import { patientIdParamName } from '../../../router'

type Props = {
  onClose: () => void
}

export const FinishTrialModalContainer: React.FC<Props> = props => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()
  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )!

  const [selectedReason, setSelectedReason] = useState(
    TrialFinishReason.Unspecified,
  )

  const { request, requesting, errorMessage } = useFinishTrial()
  const onSubmit = useCallback(async () => {
    await request({
      trialUid: selectedTrial.uid,
      patientUid: patient.uid,
      reason: selectedReason,
    })
    props.onClose()
  }, [patient.uid, props, request, selectedTrial.uid, selectedReason])

  return (
    <FinishTrialModal
      requesting={requesting}
      errorMessage={errorMessage}
      selected={selectedReason}
      onSubmit={onSubmit}
      onClose={props.onClose}
      onChange={setSelectedReason}
    />
  )
}
