import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { getSingle as getPatient } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'
import Flex from 'styled-flex-component'

import { List } from './common/list/list'

export const patientIdParamName = 'patientUid'

export const WorksheetComponent = () => {
  const { patientUid = '' } = useParams<{ [patientIdParamName]: string }>()

  const patient = useSelector((state: RootState) =>
    getPatient(state, patientUid),
  )

  if (!patient) {
    return <Navigate replace to={'./'} />
  }

  return (
    <Flex column full>
      <List />
    </Flex>
  )
}
