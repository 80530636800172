import { Button, Text, VStack } from '@chakra-ui/react'
import { Add, Certification } from 'src/components/icon'
import {
  FileListComponent,
  FileUploadComponent,
} from 'src/features/misc/components'
import { SavedFileItem } from 'src/features/misc/utils/fileUploadUtils'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { FileValue } from '../../types'
import { FieldOf } from '../../utils'
import { FormWrapperWithoutChakra } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'File'>
  value?: FileValue[]
  isDisabled?: boolean
  isPreview?: boolean
  isEditing?: boolean
  onChange?: (value: FileValue[] | undefined) => void
  fileViewerPath?: (order: number) => string
  errorMessage?: string
  warnMessage?: string
}

export const FileFieldForm: React.FC<Props> = ({
  field,
  value: files,
  isDisabled,
  isPreview,
  isEditing,
  onChange,
  fileViewerPath,
  errorMessage,
  warnMessage,
}) => {
  const defaultFileItems: SavedFileItem[] =
    files?.map(f => ({
      isNew: false,
      uid: f.uploadedFile.uid,
      fileName: f.uploadedFile.name,
      fileSize: f.uploadedFile.size,
      fileUrl: f.uploadedFile.url,
      fileExtension: f.uploadedFile.extension,
      savedAt: f.uploadedFile.savedAt,
    })) ?? []

  const currentValueByFileUid = new Map(
    files?.map(f => [f.uploadedFile.uid, f]) ?? [],
  )

  const hasFiles = !!files && files.length > 0

  return (
    <FormWrapperWithoutChakra
      field={field}
      isRequired={field.typeDef.validation?.required}
      supplementaryElement={
        field.typeDef.isCertifiedCopy &&
        hasFiles && <Certification color={colors.green[500]} />
      }
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <VStack spacing="2" align="start" w="full">
        {isPreview && (
          <VStack align="start">
            <Button variant="text" leftIcon={<Add />} isDisabled>
              ファイルアップロード
            </Button>
            <Text fontSize="sm">
              ※ ファイルはPNG・JPEG・PDF形式の拡張子から選択可能です。
            </Text>
          </VStack>
        )}
        {!isDisabled && !isPreview && (
          <FileUploadComponent
            defaultFileItems={defaultFileItems}
            isCertifiedCopy={field.typeDef.isCertifiedCopy}
            onUploaded={items => {
              if (!onChange) return
              const value: FileValue[] = items.map((item, i) => {
                const current = currentValueByFileUid.get(item.uid)
                if (!!current) {
                  return {
                    ...current,
                    order: i + 1,
                  }
                }
                return {
                  memo: '',
                  order: i + 1,
                  uploadedFile: {
                    uid: item.uid,
                    name: item.fileName,
                    url: item.fileUrl,
                    extension: item.fileExtension,
                    size: item.fileSize,
                    savedAt: item.savedAt,
                  },
                }
              })
              onChange(value.length > 0 ? value : undefined)
            }}
          />
        )}
        {files && files.length > 0 && (
          <FileListComponent
            isEditing={isEditing ?? false}
            canEdit={!isDisabled}
            fileItems={files.map(v => ({
              fileUid: v.uploadedFile.uid,
              fileName: v.uploadedFile.name,
              fileUrl: v.uploadedFile.url,
              fileExtension: v.uploadedFile.extension,
              order: v.order,
              memo: v.memo,
            }))}
            onChangeMemo={(fileUid, memo) => {
              if (!onChange) return
              const value: FileValue[] = files.map(f => {
                if (f.uploadedFile.uid === fileUid) {
                  return {
                    ...f,
                    memo,
                  }
                }
                return f
              })
              onChange(value.length > 0 ? value : undefined)
            }}
            fileViewerPath={order => {
              return fileViewerPath?.(order) ?? ''
            }}
          />
        )}
      </VStack>
    </FormWrapperWithoutChakra>
  )
}
