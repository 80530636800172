import { Trial } from 'src/features/trial/types'

import { WSDataExportJob } from '../types'

export const getWSDataExportZipDownloadLink = ({
  trialUid,
  wsDataExportJobUid,
}: {
  trialUid: Trial['uid']
  wsDataExportJobUid: WSDataExportJob['uid']
}) => {
  return `${
    import.meta.env.VITE_SERVER_URL
  }/trials/${trialUid}/ws_data_export/${wsDataExportJobUid}/zip`
}
