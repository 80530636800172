import { Fragment } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  ModalFooter,
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Center,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Add, Delete } from 'src/components/icon'
import { Select } from 'src/components/Select/Select'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { Member, MemberRole } from 'src/features/member/types'
import { useTrialHospitals } from 'src/features/trial/api'
import { TrialHospital } from 'src/features/trial/types'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { castUid } from 'src/utils/brandedUid'
import { generateUUID } from 'src/utils/generateUUID'

import { useUpdateExpMembers } from '../../api'
import { useExplanationMemberSetting } from '../../hooks/useExplanationMemberSetting'
import { ExplanationMembersSchema } from '../../schema/explanationFormSchema'
import { Explanation } from '../../types'
import { MemberSelect } from '../MemberSelect/MemberSelect'

type DefaultMember = {
  trialHospitalUid: string
  trialMemberUid: string
  role: MemberRole
}

type Props = {
  isOpen: boolean
  onClose: () => void
  explanation: Explanation
  explanationSessionUid: string | undefined
  defaultMembers: DefaultMember[]
  mutate: () => void
  submitButtonColor?: 'blue' | 'green'
}
export const MembersSettingModal: React.FC<Props> = ({
  isOpen,
  onClose,
  explanation,
  explanationSessionUid,
  defaultMembers,
  mutate,
  submitButtonColor = 'blue',
}) => {
  const {
    account: { selectedTrialHospitalUid, member: currentMember },
  } = useAuthenticatedAccount()

  const { data: trialHospitals } = useTrialHospitals({})

  if (!selectedTrialHospitalUid) {
    throw new Error('selectedTrialHospitalUid is not defined')
  }

  const toast = useMirohaToast()

  const { request: updateMembers } = useUpdateExpMembers({
    onSuccess: async () => {
      toast({
        status: 'success',
        title: '説明担当者を設定しました',
      })
      mutate()
      onClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const {
    members,
    appendMember,
    removeMember,
    onChange,
    errors,
    handleSubmit,
  } = useExplanationMemberSetting({
    selectedTrialHospitalUid,
    explanationType: explanation.latestRevision.type,
    defaultValue:
      defaultMembers.length === 0
        ? [
            {
              id: generateUUID(),
              trialHospitalUid: selectedTrialHospitalUid,
              member: {
                uid: currentMember.uid,
                role: currentMember.role,
              },
            },
          ]
        : defaultMembers.map(m => ({
            id: generateUUID(),
            trialHospitalUid: m.trialHospitalUid,
            member: {
              uid: m.trialMemberUid,
              role: m.role,
            },
          })),
  })

  const onSubmit = async (params: ExplanationMembersSchema) => {
    await updateMembers({
      explanationRevisionUid: explanation.latestRevision.uid,
      explanationSessionUid,
      members: params.map(param => ({
        trialMemberUid: param.member.uid,
        trialHospitalUid: param.trialHospitalUid,
      })),
    })
  }

  const MAX_MEMBER_COUNT =
    explanation.latestRevision.type === 'RemotePartner' ? 10 : 2

  const showableTrialHospitals = trialHospitals?.filter(
    th =>
      th.uid === explanation.trialHospital.uid ||
      th.uid === explanation.latestRevision.partnerTrialHospital?.uid,
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <form
        onSubmit={async e => {
          e.preventDefault()
          await handleSubmit(onSubmit)
        }}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>説明担当者を設定します</ModalHeader>
          <ModalBody>
            {/* 遠隔同意(パートナー施設)の場合は5名分のフォームが表示できる高さ（400px）はデフォルトで保持しておく */}
            <Stack
              spacing="2"
              minH={
                explanation.latestRevision.type === 'RemotePartner'
                  ? '400px'
                  : undefined
              }
            >
              <Text>説明を実施する担当者を設定してください</Text>
              <Stack spacing="2">
                <TableContainer overflowY="unset" overflowX="unset">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th>医療機関</Th>
                        <Th>担当者</Th>
                        <Th>
                          <Button
                            colorScheme="blue"
                            variant="text"
                            leftIcon={<Add />}
                            isDisabled={members.length >= MAX_MEMBER_COUNT}
                            onClick={appendMember}
                          >
                            追加
                          </Button>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {members.map((member, index) => {
                        if (!member)
                          return <Fragment key={`${index}`}></Fragment>
                        return (
                          <Tr key={`${member.id}-${index}`}>
                            <Td>{`${index + 1}`}</Td>
                            <Td verticalAlign="top">
                              <Select
                                width={280}
                                value={members[index]?.trialHospitalUid}
                                items={(showableTrialHospitals ?? []).map(
                                  h => ({
                                    value: h.uid,
                                    label: h.name,
                                  }),
                                )}
                                onChange={value => {
                                  onChange(index, {
                                    id: generateUUID(),
                                    trialHospitalUid: value,
                                  })
                                }}
                              />
                            </Td>
                            <Td verticalAlign="top">
                              <Stack>
                                <MemberSelect
                                  width={280}
                                  trialHospitalUid={
                                    !!member.trialHospitalUid
                                      ? castUid<TrialHospital>(
                                          member.trialHospitalUid,
                                        )
                                      : undefined
                                  }
                                  memberUid={
                                    !!member?.member?.uid
                                      ? castUid<Member>(member?.member?.uid)
                                      : undefined
                                  }
                                  onChange={value => {
                                    onChange(index, {
                                      ...member,
                                      id: value.uid,
                                      member: {
                                        uid: value.uid,
                                        role: value.role,
                                      },
                                    })
                                  }}
                                  isInvalid={
                                    (errors &&
                                      errors.members?.hasOwnProperty(index)) ??
                                    false
                                  }
                                  isPartner={
                                    member.trialHospitalUid ===
                                    explanation.latestRevision
                                      .partnerTrialHospital?.uid
                                  }
                                />
                                {errors.members &&
                                  errors.members?.hasOwnProperty(index) && (
                                    <Text
                                      color="red.500"
                                      fontSize="xs"
                                      mt="1.5"
                                      mb="0"
                                    >
                                      {errors.members[index].message}
                                    </Text>
                                  )}
                              </Stack>
                            </Td>
                            <Td textAlign="right">
                              <Center>
                                <IconButton
                                  aria-label="担当者を削除"
                                  colorScheme="gray"
                                  minW="unset"
                                  w="32px"
                                  h="32px"
                                  borderRadius="base"
                                  variant="ghost"
                                  color="blue.500"
                                  icon={<Delete />}
                                  onClick={() => {
                                    removeMember(index)
                                  }}
                                />
                              </Center>
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
                {errors?.members?.message !== undefined && (
                  <Text fontSize="sm" color="red.500" mt="2" as="div">
                    {errors.members.message}
                  </Text>
                )}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button type="submit" colorScheme={submitButtonColor}>
              設定する
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
