import { createApiClient, RequestResponse } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationCacheKey } from '../../../explanation/api/cacheKey'

export type ExplanationRevPinSettingAbout = Extract<
  RequestResponse<
    '/explanation_revision_pin/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/about',
    'get'
  >,
  { result: 'success' }
>['data']

const getExplanationRevPinSettingAbout = async ({
  trialUid,
  explanationRevisionUid,
}: {
  trialUid: string
  explanationRevisionUid: string
}): Promise<ExplanationRevPinSettingAbout> => {
  const res = await createApiClient({
    path: '/explanation_revision_pin/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/about',
    httpMethod: 'get',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useExplanationRevPinSettingAbout = ({
  trialUid,
  explanationRevisionUid,
  ...option
}: {
  trialUid: string
  explanationRevisionUid: string
} & SWRConfiguration<ExplanationRevPinSettingAbout, Error>) => {
  return useSWR(
    explanationCacheKey.getExplanationRevPinSettingAbout(
      trialUid,
      explanationRevisionUid,
    ),
    () =>
      getExplanationRevPinSettingAbout({ trialUid, explanationRevisionUid }),
    option,
  )
}
