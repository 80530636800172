import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { SERVER_URL } from 'src/modules/server/const'

import { COMPLETE_DOCUSIGN_EDIT_URL } from './icf-document/complete-docusign-edit/complete-docusign-edit'
import {
  IcfDocumentSignerRole,
  IcfDocumentCheckRole,
  icfDocumentSignerRoles,
} from './icf-document/entity'
import { useIcfDocumentHospital } from './use-icf-document-hospital'

const roleQueryParamValue: Record<
  IcfDocumentSignerRole | IcfDocumentCheckRole,
  string
> = {
  [icfDocumentSignerRoles.Dr]: 'dr',
  [icfDocumentSignerRoles.CRC]: 'crc',
  [icfDocumentSignerRoles.Patient]: 'patient',
  [icfDocumentSignerRoles.Representative]: 'representative',
}

export const useDocuSignPreview = () => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const redirect = useCallback(
    ({
      dsTemplateId,
      role,
    }: {
      dsTemplateId: string
      role: IcfDocumentSignerRole | IcfDocumentCheckRole
    }) => {
      const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${selectedTrialHospitalUid}/docusign_templates/${dsTemplateId}/preview/${roleQueryParamValue[role]}?return_url=${COMPLETE_DOCUSIGN_EDIT_URL}`
      window.open(url, '_blank', 'noreferrer')
    },
    [trialUid, selectedTrialHospitalUid],
  )
  return { redirect }
}
