import React, { useContext, useMemo, useState } from 'react'

import { Button, HStack, Tooltip } from '@chakra-ui/react'
import { colors } from 'src/assets/colors'
import { Hint } from 'src/components/__legacy__icon/monochrome'
import { blue } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import { Message } from 'src/components/base/message/message'
import { ButtonLink } from 'src/components/base/text-link'
import { CalendarComponent } from 'src/components/form-redesigned/calendar'
import { Checkbox } from 'src/components/form-redesigned/checkbox'
import { ControlledDateInput } from 'src/components/form-redesigned/date-input'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Radio } from 'src/components/form-redesigned/radio'
import { Selectbox } from 'src/components/form-redesigned/selectbox'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { TimeInput } from 'src/components/form-redesigned/time-input'
import { Add } from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import { ChoiceAnswer } from 'src/modules/entities/choice-answer/entity'
import { ItemType } from 'src/modules/entities/small-item/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import {
  isValidDateValue,
  isValidNullableDate,
  isValidTimeValue,
} from 'src/modules/entities/small-item-answer/util'
import { zIndex } from 'src/modules/util/z-index'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { SmallItemAnswerContext } from './context'
import {
  choiceAnswerToCheckBoxItem,
  choiceAnswerToOptionItem,
} from './small-item-answer'
import { useSmallItemAnswerForm } from './use-small-item-answer-form'

type Props = {
  smallItemAnswer: SmallItemAnswer
  placeholder?: string
  canEditInParent: boolean
  isPreview?: boolean
}

const Container = styled.div`
  width: 100%;
  padding: 0 0 20px 28px;
  margin: 0 0;
  box-sizing: border-box;
  border-left: 5px solid ${colors.borderGray};

  &:last-child {
    padding: 0 0 0 28px;
  }
`

const LabelContainer = styled.div`
  width: 100%;
  padding: 8px 0;
`

const InputFieldContainer = styled.div`
  width: 100%;
`

const DateContainer = styled.div`
  position: relative;
  display: inline-block; // 中身の幅を子（date-input + アイコン）の幅にとどめて右端が揃うようにする。
`

const CalendarContainer = styled.div`
  position: absolute;
  right: 0; // カレンダーアイコンと右端を揃える
  z-index: ${zIndex.calendarComponent};
`

const HourMinuteContainer = styled.div``

const NumberContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
`

const Unit = styled.span`
  margin-left: 22px;
  color: ${colors.dark};
`

const NestedSmallItemAnswerContainer = styled.div`
  width: 100%;
  margin: 12px 0 12px;
`

export const NestedSmallItemAnswer: React.FC<Props> = props => {
  const { smallItemAnswer, placeholder, canEditInParent, isPreview } = props

  const { currentRole } = useContext(SmallItemAnswerContext)

  const canEdit = useMemo(() => {
    if (!canEditInParent) return false
    if (smallItemAnswer.fixed) return false
    if (isPreview) return true
    return currentRole
      ? smallItemAnswer.inputRoles.includes(currentRole)
      : false
  }, [
    canEditInParent,
    currentRole,
    smallItemAnswer.fixed,
    smallItemAnswer.inputRoles,
    isPreview,
  ])

  const {
    value,
    setValue,
    selectedChoiceAnswerUids,
    onToggleChoiceAnswer,
    onRemoveSelected,
    onUnselect,
  } = useSmallItemAnswerForm(smallItemAnswer)

  const [calendarOpen, setCalendarOpen] = useState(false)

  const choicesWithQuestion = smallItemAnswer.choiceAnswers.filter(
    ca =>
      ca.smallItemAnswerUids.length > 0 &&
      selectedChoiceAnswerUids.includes(ca.uid),
  )

  const onChangeNumber = (value: any) => {
    //TODO: 000.000 や 01234 などを弾く条件を追加
    const regex = new RegExp('^(\\-)?[0-9]*(\\.)?([0-9]+)?$', 'g')
    const result = value.match(regex)
    if (!result) {
      return
    }

    setValue(value)
  }

  const isNullableDate = smallItemAnswer.itemType === ItemType.NullableDate

  const fill0 = (v: string) => {
    if (isNullableDate) {
      return v
    }
    return v.length === 1 ? `0${v}` : v
  }
  const setDateValueByDateObject = (date: Date) => {
    const year = String(date.getFullYear())
    const month = fill0(String(date.getMonth() + 1))
    const day = fill0(String(date.getDate()))

    setValue(`${year}-${month}-${day}`)
  }

  const onClickCalendarIcon = () => {
    const [year, month, day] = value.split('-')

    if (!year || !month || !day) {
      setDateValueByDateObject(new Date())
      return
    }

    setCalendarOpen(!calendarOpen)
  }
  const closeCalendar = () => setCalendarOpen(false)

  const onClickTimerIcon = () => {
    const date = new Date()
    const hour = fill0(String(date.getHours()))
    const minute = fill0(String(date.getMinutes()))

    setValue(`${hour}:${minute}`)
  }

  const selectedSingleValue = selectedChoiceAnswerUids[0]

  return (
    <Container>
      <LabelContainer>
        <HStack align="center" spacing="8px">
          <Label bold category={smallItemAnswer.dataType}>
            {smallItemAnswer.title}
          </Label>

          {smallItemAnswer.description && (
            <Tooltip label={smallItemAnswer.description} hasArrow>
              <span>
                <Hint size="S" color={blue[70]} />
              </span>
            </Tooltip>
          )}
          {smallItemAnswer.itemType === ItemType.SelectMenu &&
            !!selectedChoiceAnswerUids.length &&
            canEdit && (
              <Flex>
                <Spacer size={10} horizontal />
                <ButtonLink onClick={onUnselect}>未入力にする</ButtonLink>
              </Flex>
            )}
        </HStack>
      </LabelContainer>

      <Spacer size={10} />

      <InputFieldContainer>
        {smallItemAnswer.itemType === ItemType.Text && (
          <>
            <Input
              placeholder={placeholder}
              width={400}
              value={value}
              onChange={e => setValue(e.target.value)}
              disabled={!canEdit}
              warning={!value}
            />

            <Spacer size={8} />

            {shouldShowEdcValuePipeError(value) && (
              <Message type="error" message="「|」は使用できません。" />
            )}
          </>
        )}

        {smallItemAnswer.itemType === ItemType.TextArea && (
          <>
            <Textarea
              placeholder={placeholder}
              width={400}
              value={value}
              onChange={e => setValue(e.target.value)}
              disabled={!canEdit}
              warning={!value}
            />

            <Spacer size={8} />

            {shouldShowEdcValuePipeError(value) && (
              <Message type="error" message="「|」は使用できません。" />
            )}
          </>
        )}

        {smallItemAnswer.itemType === ItemType.Date && (
          <DateContainer>
            <Flex alignCenter>
              <ControlledDateInput
                date={value}
                onChange={setValue}
                disabled={!canEdit}
                showWarningYear={true}
                showWarningMonth={true}
                showWarningDay={true}
                allowAlphabet={false}
              />
              {canEdit && (
                <IconButton iconName="calendar" onClick={onClickCalendarIcon} />
              )}
            </Flex>

            {calendarOpen && (
              <CalendarContainer>
                <Spacer size={8} />
                <CalendarComponent
                  onClose={closeCalendar}
                  onChange={setDateValueByDateObject}
                />
              </CalendarContainer>
            )}

            <Spacer size={8} />

            {shouldShowDateFormatError(value) && (
              <Message type="error" message="日付の入力が不正です" />
            )}
          </DateContainer>
        )}

        {smallItemAnswer.itemType === ItemType.NullableDate && (
          <DateContainer>
            <Flex alignCenter>
              <ControlledDateInput
                date={value}
                onChange={setValue}
                disabled={!canEdit}
                showWarningYear={true}
                showWarningMonth={false}
                showWarningDay={false}
                allowAlphabet={true}
              />
              {canEdit && (
                <IconButton iconName="calendar" onClick={onClickCalendarIcon} />
              )}
            </Flex>

            {calendarOpen && (
              <CalendarContainer>
                <Spacer size={8} />
                <CalendarComponent
                  onClose={closeCalendar}
                  onChange={setDateValueByDateObject}
                />
              </CalendarContainer>
            )}

            <Spacer size={8} />

            {!isValidNullableDate(value) && (
              <Message type="error" message="日付の入力が不正です" />
            )}
          </DateContainer>
        )}

        {smallItemAnswer.itemType === ItemType.HourMinute && (
          <HourMinuteContainer>
            <Flex alignCenter>
              <TimeInput
                timeValue={value}
                onChange={setValue}
                disabled={!canEdit}
                showWarning
              />
              {canEdit && (
                <IconButton iconName="time" onClick={onClickTimerIcon} />
              )}
            </Flex>

            <Spacer size={8} />

            {shouldShowTimeFormatError(value) && (
              <Message type="error" message="日時の入力が不正です" />
            )}
          </HourMinuteContainer>
        )}

        {smallItemAnswer.itemType === ItemType.Number && (
          <NumberContainer>
            <Input
              placeholder={placeholder}
              width={150}
              value={value}
              onChange={e => onChangeNumber(e.target.value)}
              disabled={!canEdit}
              warning={!value}
            />
            <Unit>{smallItemAnswer.unit}</Unit>
          </NumberContainer>
        )}

        {smallItemAnswer.itemType === ItemType.Radio && (
          <Radio
            items={smallItemAnswer.choiceAnswers.map(choiceAnswerToOptionItem)}
            selectedValue={selectedSingleValue}
            onChange={onToggleChoiceAnswer}
            onClick={onRemoveSelected}
            disabled={!canEdit}
            warning
          />
        )}

        {smallItemAnswer.itemType === ItemType.CheckBox && (
          <Checkbox
            items={smallItemAnswer.choiceAnswers.map(a =>
              choiceAnswerToCheckBoxItem(a, selectedChoiceAnswerUids),
            )}
            onChange={onToggleChoiceAnswer}
            disabled={!canEdit}
            warning
          />
        )}

        {smallItemAnswer.itemType === ItemType.SelectMenu && (
          <Selectbox<string>
            width={400}
            items={smallItemAnswer.choiceAnswers.map(choiceAnswerToOptionItem)}
            selectedValue={selectedSingleValue}
            placeholder={placeholder}
            onChange={onToggleChoiceAnswer}
            disabled={!canEdit}
            warning={!selectedSingleValue}
          />
        )}
        {smallItemAnswer.itemType === ItemType.File && (
          <Button onClick={() => {}} variant="text" leftIcon={<Add />}>
            ファイルアップロード
          </Button>
        )}
      </InputFieldContainer>

      {choicesWithQuestion.length !== 0 && (
        <NestedSmallItemAnswerContainer>
          {choicesWithQuestion.map(choice => (
            <NestedSmallItemAnswerItem
              key={choice.uid}
              choice={choice}
              canEdit={canEdit}
              isPreview={isPreview}
            />
          ))}
        </NestedSmallItemAnswerContainer>
      )}
    </Container>
  )
}

type ChoiceQuestionItemProps = {
  choice: ChoiceAnswer
  canEdit: boolean
  isPreview?: boolean
}

const ChoiceDescription = styled.div`
  margin: 10px 0 10px;
  color: ${colors.dark};
  font-size: 12px;
`

const NestedSmallItemAnswerItem: React.FC<ChoiceQuestionItemProps> = props => {
  const { choice, canEdit, isPreview } = props

  const { smallItemAnswerMap } = useContext(SmallItemAnswerContext)

  const smallItemAnswers = choice.smallItemAnswerUids.map(
    uid => smallItemAnswerMap[uid],
  )

  return (
    <>
      <ChoiceDescription>
        「{choice.description}」を選択した場合は次の設問を入力してください。
      </ChoiceDescription>

      {smallItemAnswers.map(sia => (
        <NestedSmallItemAnswer
          key={sia.uid}
          smallItemAnswer={sia}
          canEditInParent={canEdit}
          isPreview={isPreview}
        />
      ))}
    </>
  )
}

const shouldShowEdcValuePipeError = (value: string) => {
  return value.includes('|')
}
const shouldShowDateFormatError = (value: string) => {
  if (!value) return false

  const year = value.split('-')[0]
  const month = value.split('-')[1]
  const day = value.split('-')[2]

  //埋まっていない項目がある場合はエラーを表示しない
  if (!year || !month || !day) return false

  return !isValidDateValue(value)
}
const shouldShowTimeFormatError = (value: string) => {
  const hourStr = value.split(':')[0] ?? ''
  const minuteStr = value.split(':')[1] ?? ''

  // 時間・分いずれも埋まっている時のみエラーを表示する
  if (!hourStr || !minuteStr) return false

  return !isValidTimeValue(value)
}
