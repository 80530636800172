import { useCallback } from 'react'

import {
  CriteriaDetailScreen_CriteriaDocument,
  CriteriaStatus,
} from 'src/lib/gql-client'
import { useGqlQuerySWR } from 'src/lib/gql-client/request'

export const useMutateDetailScreen = ({
  criteriaUid,
}: {
  criteriaUid: string
}) => {
  const { mutate } = useGqlQuerySWR(
    CriteriaDetailScreen_CriteriaDocument,
    { criteriaUid },
    {
      revalidateIfStale: false,
      revalidateOnMount: false,
      revalidateOnFocus: false,
    },
  )

  /** statusを元に選択除外基準詳細画面のcacheを即座に（楽観的に）更新する */
  const mutateWithStatus = useCallback(
    (status?: CriteriaStatus) => {
      if (!status) {
        mutate()
        return
      }
      mutate(current => {
        if (!current) return current
        return {
          ...current,
          criteria: {
            ...current.criteria,
            latestLog: {
              ...current.criteria.latestLog,
              status,
            },
          },
        }
      })
    },
    [mutate],
  )

  return mutateWithStatus
}
