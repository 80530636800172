import { Textarea } from '@chakra-ui/react'
import { Field, TextareaTypeDef } from '@micin-jp/chicken-schema'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  textareaField: Field & {
    typeDef: TextareaTypeDef
  }
  index: number
}

export const WorksheetTextareaField: React.FC<Props> = ({
  textareaField,
  index,
}) => {
  const {
    findFieldValue,
    onChangeTextareaFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: textareaField.fid,
    index,
  })

  const onChange = (textareaValue: string) => {
    onChangeTextareaFieldValue({
      fid: textareaField.fid,
      index,
      textareaValue,
    })
  }

  return (
    <WorksheetFieldFormControl
      field={textareaField}
      index={index}
      isDisabled={
        !canEdit || !isRepeatableSectionEnabled(index, textareaField.fid)
      }
      errorMessage={getErrorMessage(value)}
    >
      <Textarea
        value={value?.type === 'textarea' ? value.textareaValue : undefined}
        onChange={e => onChange(e.target.value)}
      />
    </WorksheetFieldFormControl>
  )
}
