import { useState } from 'react'

import { useRequestState } from 'src/modules/server/use-request-state'

import { sendEmail } from './request'

export const useSendEmail = () => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()

  const [doneMessage, setDoneMessage] = useState('')

  const request = async ({
    trialUid,
    email,
  }: {
    trialUid: string
    email: string
  }) => {
    try {
      requestStarted()
      await sendEmail({ trialUid, email })
      setDoneMessage('メールアドレス確認のメッセージを送信しました。')
      setTimeout(() => {
        setDoneMessage('')
      }, 3000)
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    doneMessage,
    errorMessage,
  }
}
