import { assertNever } from 'src/utils/assertNever'
import { formatDate } from 'src/utils/formatDate'
import { zeroPadding } from 'src/utils/zeroPadding'

import { FlattenFieldMap } from './getFlattenFields'
import { FieldValue } from '../types'

export const getFieldValueText = (
  fieldValue: FieldValue,
  fieldMap: FlattenFieldMap,
): string => {
  switch (fieldValue.type) {
    case 'text':
      return fieldValue.textValue

    case 'textarea':
      return fieldValue.textareaValue

    case 'number':
      const numberField = fieldMap.get(fieldValue.fid)
      if (!numberField) {
        throw new Error(`field not found: ${fieldValue.fid}`)
      }
      if (numberField.typeDef.type !== 'Number') {
        throw new Error(`field type is not number: ${fieldValue.fid}`)
      }
      if (numberField.typeDef.unit) {
        return `${fieldValue.numberValue} ${numberField.typeDef.unit}`
      }
      return fieldValue.numberValue

    case 'date':
      return !!fieldValue.dateValue
        ? formatDate(fieldValue.dateValue, 'YYYY/MM/DD')
        : ''

    case 'nullableDate':
      if (!fieldValue.nullableDateValue) {
        return ''
      }
      // から文字はomit
      const splitDate = fieldValue.nullableDateValue.split('-').filter(Boolean)
      if (splitDate.length === 1) {
        return splitDate[0].padStart(4, '0')
      }
      if (splitDate.length === 2) {
        return `${splitDate[0].padStart(4, '0')}/${splitDate[1].padStart(
          2,
          '0',
        )}`
      }
      return formatDate(fieldValue.nullableDateValue, 'YYYY/MM/DD')

    case 'time':
      const splitTime = fieldValue.timeValue.split(':')
      if (splitTime.length !== 2) {
        return ''
      }
      return `${zeroPadding(splitTime[0], 2)}:${zeroPadding(splitTime[1], 2)}`

    case 'checkbox':
      const checkboxField = fieldMap.get(fieldValue.fid)
      if (!checkboxField) {
        throw new Error(`field not found: ${fieldValue.fid}`)
      }
      if (checkboxField.typeDef.type !== 'Checkbox') {
        throw new Error(`field type is not checkbox: ${fieldValue.fid}`)
      }
      return checkboxField.typeDef.choices
        .filter(choice => fieldValue.checkboxValue.includes(choice.cid))
        .map(choice => choice.name)
        .join(', ')

    case 'radio':
      const radioField = fieldMap.get(fieldValue.fid)
      if (!radioField) {
        throw new Error(`field not found: ${fieldValue.fid}`)
      }
      if (radioField.typeDef.type !== 'Radio') {
        throw new Error(`field type is not radio: ${fieldValue.fid}`)
      }
      const radioChoice = radioField.typeDef.choices.find(
        choice => choice.cid === fieldValue.radioValue,
      )
      return radioChoice?.name ?? ''

    case 'selectMenu':
      const selectMenuField = fieldMap.get(fieldValue.fid)
      if (!selectMenuField) {
        throw new Error(`field not found: ${fieldValue.fid}`)
      }
      if (selectMenuField.typeDef.type !== 'SelectMenu') {
        throw new Error(`field type is not selectMenu: ${fieldValue.fid}`)
      }
      const selectMenuChoice = selectMenuField.typeDef.choices.find(
        choice => choice.cid === fieldValue.selectMenuValue,
      )
      return selectMenuChoice?.name ?? ''

    case 'file':
      return ''

    case 'repeatableSection':
      return ''

    case 'cleared':
      return ''

    default:
      assertNever(fieldValue)
      return ''
  }
}
