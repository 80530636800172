import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'

import { useLogout } from './use-logout'

interface IProps {
  onClose: () => void
}

export const LogoutModal: React.FC<IProps> = ({ onClose }) => {
  const { requesting, handleLogout } = useLogout()

  return (
    <Modal onClose={onClose}>
      <ModalTitle title="ログアウト" />
      <ModalContent>
        <Text textAlign="center">ログアウトしてもよろしいですか？</Text>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="ログアウト"
          onClick={handleLogout}
          buttonType="important"
          disabled={requesting}
        ></Button>
      </ModalActions>
    </Modal>
  )
}
