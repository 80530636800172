import { delay, HttpResponse } from 'msw'
import {
  createSyncAllEnvelopesPayloadMock,
  SyncAllEnvelopesDocument,
  SyncAllEnvelopesMutation,
  SyncAllEnvelopesMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const syncAllEnvelopesHandler = graphQLHandlerFactory<
  SyncAllEnvelopesMutation,
  SyncAllEnvelopesMutationVariables
>('mutation', SyncAllEnvelopesDocument, async () => {
  await delay(3000)
  return HttpResponse.json({
    data: {
      syncAllEnvelopes: createSyncAllEnvelopesPayloadMock(),
    },
  })
})
