import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { useQuery } from 'src/hooks/use-query'

import {
  generateStickySearchParam,
  STICKY_SEARCH_PARAM_KEY,
} from '../utils/stickySearchParam'

/** クエリパラメータで指定されているstickyUidを取得・操作 */
export const useStickyQuery = () => {
  const query = useQuery()
  const navigate = useNavigate()

  const stickyQuery = query.get(STICKY_SEARCH_PARAM_KEY) ?? undefined

  const setStickyQuery = useCallback(
    (stickyUid: string) => {
      // ブラウザバックなどで他の付箋が開かないようreplaceする
      navigate(
        { search: generateStickySearchParam(stickyUid) },
        { replace: true },
      )
    },
    [navigate],
  )

  const resetQuery = useCallback(() => {
    navigate({ search: undefined }, { replace: true })
  }, [navigate])

  return {
    stickyQuery,
    setStickyQuery,
    resetQuery,
  }
}
