import { useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Stack,
  HStack,
  Button,
  ModalFooter,
  Text,
} from '@chakra-ui/react'
import { NumberOnlyInput } from 'src/components/NumberOnlyInput/NumberOnlyInput'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSendTestSMS } from '../../api'
import { useUpdateExpPatientMobileNumber } from '../../api/updatePatientMobileNumber'

type Props = {
  isOpen: boolean
  onClose: () => void
  patientUid: string
  buttonColorScheme?: 'blue' | 'green'
}

export const AfterPinSettingPhoneNumberSettingModal: React.FC<Props> = ({
  isOpen,
  onClose,
  patientUid,
  buttonColorScheme = 'blue',
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>()

  const toast = useMirohaToast()

  const { request: updateMobileNumber } = useUpdateExpPatientMobileNumber({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '患者携帯電話番号を設定しました',
      })
      onClose()
    },
  })

  const onSubmit = async () => {
    if (!phoneNumber) return
    await updateMobileNumber({
      explanationPatientUid: patientUid,
      mobileNumber: phoneNumber,
    })
  }

  const { request: sendTestSMS } = useSendTestSMS({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'テストSMSを送信しました',
      })
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>患者携帯電話を設定しますか？</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Text>
              署名時の当人認証に暗証番号と患者の携帯電話番号が必要です。
            </Text>
            <Text>
              設定可能な場合は以下のフォームに患者の携帯電話番号を入力して
              「設定する」ボタンを押してください
            </Text>
            <HStack spacing="2" marginBottom={2}>
              <NumberOnlyInput
                w="160px"
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder="例) 08011223344"
              />
              <Button
                variant="text"
                isDisabled={!phoneNumber}
                onClick={async () => {
                  if (!phoneNumber) return
                  await sendTestSMS({
                    phoneNumber,
                  })
                }}
              >
                テスト送信
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="後で設定する" />
          <Button
            onClick={onSubmit}
            isDisabled={!phoneNumber}
            colorScheme={buttonColorScheme}
          >
            設定する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
