import React from 'react'

import { Center, Spinner, SpinnerProps } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { getState } from './selector'

type Props = {
  color?: 'blue.500' | 'green.500' | 'gray.800'
  size?: SpinnerProps['size']
}

export const ScreenSpinner: React.FC<Props> = ({
  color = 'blue.500',
  size = 'lg',
}) => {
  return (
    <Center
      position="fixed"
      zIndex="popover" // higher than modal
      w="100vw"
      h="100vh"
      bg="rgba(0, 0, 0, 0.1)"
    >
      <Spinner size={size} color={color ?? 'blue.500'} />
    </Center>
  )
}

/** @deprecated useSpinner経由で使うようにしてください */
export const ScreenSpinnerWithRedux: React.FC<Props> = () => {
  const { showable } = useSelector(getState)

  if (!showable) return null

  return <ScreenSpinner />
}
