const basePath = (trialUid: string) =>
  `/dashboard/trials/${trialUid}/e-consent/explanations`

export const getExplanationDetailRoute = ({
  trialUid,
  explanationUid,
}: {
  trialUid: string
  explanationUid: string
}) => {
  return `${basePath(trialUid)}/${explanationUid}`
}
