import { Input, Textarea } from '@chakra-ui/react'
import ReactTextareaAutosize from 'react-textarea-autosize'

import { FieldOf } from '../../utils'
import { ChakraFieldFormControl } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Text'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

export const TextFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  return (
    <ChakraFieldFormControl
      field={field}
      isRequired={field.typeDef.validation?.required}
      isDisabled={isDisabled || isPreview}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      {isDisabled ? (
        // テキストが長い場合に折り返して表示するように参照時はテキストエリアとして表示
        <Textarea as={ReactTextareaAutosize} minH="40px" value={value ?? ''} />
      ) : (
        <Input
          value={value ?? ''}
          onChange={e => {
            if (e.target.value === '') {
              onChange?.(undefined)
              return
            }
            onChange?.(e.target.value)
          }}
        />
      )}
    </ChakraFieldFormControl>
  )
}
