import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { actions } from 'src/modules/auth/login/redux'
import { actions as cognitoActions } from 'src/modules/entities/cognito/redux'
import { routes } from 'src/modules/routes'
import { useRequestState } from 'src/modules/server/use-request-state'

import { useAgreement } from '../../agreement/hooks/use-agreement'

export type OnSubmit = ({
  email,
  password,
}: {
  email: string
  password: string
}) => Promise<void>

export const useLogin = ({ onlyPassword = false } = {}) => {
  const dispatch = useDispatch()
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { jumpAfterLogin } = useAgreement()

  const navigate = useNavigate()

  const location = useLocation()

  const onSubmit: OnSubmit = async ({ email, password }) => {
    try {
      requestStarted()
      const currentUser = await Auth.signIn(email, password)

      // 本パスワード登録済みでない場合
      if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(cognitoActions.setCognitoUser(currentUser))
        navigate(routes.registerPassword)
        return
      }

      // MFAを設定している場合
      if (currentUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
        dispatch(cognitoActions.setCognitoUser(currentUser))
        navigate(routes.twoFactor, { state: location.state })
        return
      }

      const currentSession = await Auth.currentSession()
      const idToken = currentSession.getIdToken().getJwtToken()

      await dispatch(actions.submit({ idToken: idToken, firstLogin: false }))
      await jumpAfterLogin()
    } catch (error) {
      const message = onlyPassword
        ? 'パスワードが誤っています'
        : 'メールアドレスまたは、パスワードが誤っています'
      requestFailed(message)
    } finally {
      requestDone()
    }
  }

  return {
    errorMessage,
    requesting,
    onSubmit,
  }
}
