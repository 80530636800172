import React from 'react'

import { Box, Stack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { Card } from './components/card'
import { Group } from './components/group'
import { getSmallItems } from './selector'
import { useEditCard } from './use-edit-card'
import { AddSmallItem } from '../../../common/add-small-item'

type Props = {
  index: number
  mediumItem: MediumItem
  releaseTried: boolean
  orderEditable: boolean
}

export const MediumItemGroup: React.FC<Props> = props => {
  const { index, mediumItem, releaseTried } = props

  const selectedTrial = useSelector(getSelectedTrial)!
  const v2 = selectedTrial.featureFlags.eSourceV2

  const smallItems = useSelector((state: RootState) =>
    getSmallItems(state, { mediumItemUid: mediumItem.uid }),
  )

  const {
    onAdd,
    canUpMediumItem,
    canDownMediumItem,
    onUpMediumItem,
    onDownMediumItem,
    onChangeMediumItem,
    onDeleteMediumItem,
    onChangeSmallItem,
    canChangeSmallItemIndex,
    onChangeSmallItemIndex,
    onRemoveSmallItem,
    onAddChoice,
    onChangeChoice,
    onRemoveChoice,
    onAddSmallItemToChoice,
  } = useEditCard(index)

  return (
    <Box id={mediumItem.uid}>
      <Box>
        <Group
          mediumItem={mediumItem}
          canUp={canUpMediumItem}
          canDown={canDownMediumItem}
          onUp={onUpMediumItem}
          onDown={onDownMediumItem}
          onChange={onChangeMediumItem}
          onDelete={onDeleteMediumItem}
          releaseTried={releaseTried}
          v2={v2}
        />
      </Box>

      <Stack spacing="4" ml="10" mt="5">
        <Stack spacing="2.5">
          {smallItems.map(smallItem => (
            <Card
              key={smallItem.uid}
              smallItem={smallItem}
              smallItemMap={mediumItem.smallItemMap}
              onAddChoice={onAddChoice}
              onChangeSmallItem={onChangeSmallItem}
              canChangeSmallItemIndex={canChangeSmallItemIndex}
              onChangeSmallItemIndex={onChangeSmallItemIndex}
              onChangeChoice={onChangeChoice}
              onRemoveSmallItem={onRemoveSmallItem}
              onRemoveChoice={onRemoveChoice}
              onAddSmallItemToChoice={onAddSmallItemToChoice}
              releaseTried={releaseTried}
              v2={v2}
            />
          ))}
        </Stack>

        <AddSmallItem onAdd={onAdd} />
      </Stack>

      {releaseTried && smallItems.length === 0 && (
        <MessageContainer>
          <Text fontSize="sm" color="red.500">
            フィールドを追加してください
          </Text>
        </MessageContainer>
      )}
    </Box>
  )
}

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
`
