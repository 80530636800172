import { HttpResponse } from 'msw'
import {
  createExplanationSessionMock,
  GetSessionDocument,
  GetSessionQuery,
  GetSessionQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationSessionHandler = graphQLHandlerFactory<
  GetSessionQuery,
  GetSessionQueryVariables
>('query', GetSessionDocument, () => {
  return HttpResponse.json({
    data: {
      explanationSession: createExplanationSessionMock(),
    },
  })
})
