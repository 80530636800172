import { Box, BoxProps } from '@chakra-ui/react'

type Props = {
  children: React.ReactNode
} & BoxProps

/** PCでの右クリック、タブレット端末等での長押しによる画像保存などをblockする */
export const RightClickBlocker: React.FC<Props> = ({
  children,
  ...boxProps
}) => {
  return (
    <Box
      onContextMenu={e => {
        e.preventDefault()
        return false
      }}
      sx={{
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        userSelect: 'none',
      }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}
