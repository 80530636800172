import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { RHFSingleRadio } from 'src/components/form-redesigned/radio'
import { RHFSingleCheckbox } from 'src/components/form-redesigned/single-checkbox'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { Spacer } from 'src/components/spacer/spacer'
import Modal from 'src/Modal'
import { getDetail } from 'src/modules/entities/questionnaire/selector'
import { AnswerType } from 'src/modules/entities/questionnaire-field/entity'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { questionnaireIdParamName } from '../../../router'

type Props = {
  onClose: () => void
}

export const PreviewContainer: React.FC<Props> = ({ onClose }) => {
  const { questionnaireUid = '' } = useParams<{
    [questionnaireIdParamName]: string
  }>()

  const questionnaire = useSelector((state: RootState) =>
    getDetail(state, questionnaireUid),
  )
  const { displayName, sections } = questionnaire

  const [page, setPage] = useState(0)
  const totalPages = sections.length
  const sectionsForSort = [...sections]
  const sortedSections = sectionsForSort.sort((a, b) => a.index - b.index)
  const s = sortedSections[page]

  const onClickPrevPage = useCallback(() => {
    if (page > 0) setPage(page - 1)
  }, [page, setPage])

  const onClickNextPage = useCallback(() => {
    if (page < totalPages - 1) setPage(page + 1)
  }, [page, setPage, totalPages])

  if (!s) return null

  return (
    <Modal onClose={onClose} closable={true}>
      <ModalContent>
        <Text fontSize="lg" fontWeight="bold">
          {displayName}
        </Text>
        <Spacer size={16} />
        <Page>
          <Text fontSize="lg" fontWeight="bold">
            {s.title}
          </Text>
          <Spacer size={8} />
          {Object.values(s.fieldMap)
            .sort((a, b) => a.index - b.index)
            .map(f => {
              return (
                <FieldContainer key={f.uid}>
                  <Field>
                    <Text whiteSpace="pre-wrap">{f.title}</Text>
                    <Spacer size={10} />
                    {f.answerType === AnswerType.Radio && (
                      <Options>
                        {[...f.choices]
                          .sort((a, b) => a.index - b.index)
                          .map(c => (
                            <RHFSingleRadio
                              key={c.uid}
                              name={c.uid}
                              value={c.uid}
                              defaultChecked={false}
                              label={c.description}
                              disabled={true}
                            />
                          ))}
                      </Options>
                    )}
                    {f.answerType === AnswerType.CheckBox && (
                      <Options>
                        {[...f.choices]
                          .sort((a, b) => a.index - b.index)
                          .map(c => (
                            <RHFSingleCheckbox
                              key={c.uid}
                              name={c.uid}
                              defaultChecked={false}
                              label={c.description}
                              disabled={true}
                            />
                          ))}
                        <Spacer size={10} />
                      </Options>
                    )}

                    {f.answerType === AnswerType.Text && (
                      <Textarea defaultValue={''} disabled={true} />
                    )}

                    {f.answerType === AnswerType.Number && (
                      <Number>
                        <Input type="text" defaultValue={''} disabled={true} />
                        <Text fontSize="sm" wordBreak="keep-all">
                          {f.unit}
                        </Text>
                      </Number>
                    )}
                  </Field>
                  <Spacer size={10} />
                </FieldContainer>
              )
            })}
          <Spacer size={20} />
        </Page>
        <Pager>
          <Flex alignCenter>
            <Button
              text="< <"
              size="S"
              buttonType="normal"
              disabled={page === 0}
              onClick={onClickPrevPage}
            />
            <PagerLabels>
              <Spacer size={30} horizontal />
              <PagerNumberLabel>{page + 1}</PagerNumberLabel>
              <Spacer size={20} horizontal />
              <PagerLabelDivider />
              <Spacer size={20} horizontal />
              <PagerNumberLabel>{totalPages}</PagerNumberLabel>
              <Spacer size={30} horizontal />
            </PagerLabels>
            <Button
              text="> >"
              size="S"
              buttonType="normal"
              disabled={page >= totalPages - 1}
              onClick={onClickNextPage}
            />
          </Flex>
        </Pager>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  width: 581px;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Page = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${blue[10]};
  border-radius: 10px;
  overflow-y: auto;
`

const FieldContainer = styled.div``

const Field = styled.div`
  background-color: ${white};
  border-radius: 5px;
  padding: 16px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
`

const Number = styled.div`
  display: flex;
  align-items: center;
`

const Pager = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`

const PagerLabels = styled.div`
  display: inline-block;
`

const PagerNumberLabel = styled.span``

const PagerLabelDivider = styled.span`
  &:after {
    content: '/';
  }
`
