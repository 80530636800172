import { findSection } from './findSection'
import { FlattenFieldMap } from './getFlattenFields'
import { FieldValueParamToSave } from '../hooks/useWorksheetValue'

/** schema定義に従って並べ替える
 *
 * 並び順は --- sectionの並び順 -> 行の並び順(Repeatable) -> Section内の各フィールドのschema定義における並び順 ---
 */
export const sortFieldValueParam = (
  fieldMap: FlattenFieldMap,
  params: FieldValueParamToSave[],
): FieldValueParamToSave[] => {
  const fields = Array.from(fieldMap.values())

  return [...params].sort((a, b) => {
    const aSectionIndex = fields.findIndex(
      f => f.fid === findSection(fieldMap, a.fieldValue.fid)?.fid,
    )
    const bSectionIndex = fields.findIndex(
      f => f.fid === findSection(fieldMap, b.fieldValue.fid)?.fid,
    )
    if (aSectionIndex !== bSectionIndex) {
      return aSectionIndex - bSectionIndex
    }
    if (a.fieldValue.index !== b.fieldValue.index) {
      return a.fieldValue.index - b.fieldValue.index
    }
    const aIndex = fields.findIndex(f => f.fid === a.fieldValue.fid)
    const bIndex = fields.findIndex(f => f.fid === b.fieldValue.fid)
    return aIndex - bIndex
  })
}
