import { MediumItem } from './entity'
import {
  fixture as smallItemFixture,
  fixtureNested as nestedSmallItemFixture,
} from '../small-item/fixture'

export const createFixture = (values?: Partial<MediumItem>): MediumItem => {
  const res: MediumItem = {
    uid: 'fooMediumItemUid',
    title: '中項目タイトル',
    folderName: '',
    formName: '',
    index: 0,
    isLogline: false,
    presetUsed: false,

    smallItemMap: {},

    templateUid: 'fooTemplateUid',
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof MediumItem
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}

export const fixture: MediumItem = {
  uid: 'fooMediumItemUid',
  title: '中項目タイトル',
  folderName: 'folderName',
  formName: 'formName',
  index: 0,
  isLogline: false,
  presetUsed: false,

  smallItemMap: {},

  templateUid: 'fooTemplateUid',
}

export const fixtureDetail: MediumItem = {
  uid: 'fooMediumItemUid',
  title: '中項目タイトル',
  folderName: 'folderName',
  formName: 'formName',
  index: 0,
  isLogline: false,
  presetUsed: false,

  smallItemMap: {
    [smallItemFixture.uid]: smallItemFixture,
    [nestedSmallItemFixture.uid]: nestedSmallItemFixture,
  },

  templateUid: 'fooTemplateUid',
}
