/** 配列の特定のindexの値を更新する
 *
 * @returns 配列のコピー
 */
export const updateElementAtIndex = <T>(
  array: T[],
  index: number,
  newValue: T,
): T[] => {
  if (index < 0 || index >= array.length) {
    throw new Error('Invalid index')
  }

  const newArray = [...array]
  newArray[index] = newValue

  return newArray
}
