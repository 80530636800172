import { GqlError, UpdateExpRevisionStatusDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const updateExpRevisionStatus = async ({
  explanationRevisionUid,
}: {
  explanationRevisionUid: string
}): Promise<void> => {
  try {
    await graphqlRequest(UpdateExpRevisionStatusDocument, {
      input: {
        explanationRevisionUid,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useUpdateExpRevisionStatus = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({ explanationRevisionUid }: { explanationRevisionUid: string }) =>
      updateExpRevisionStatus({
        explanationRevisionUid,
      }),
    option,
  )
}
