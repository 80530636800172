import { useMemo, useState } from 'react'

import {
  Box,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  Stack,
  RadioGroup,
  Radio,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormErrorMessage,
  Text,
  Checkbox,
  Circle,
} from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { generatePath, Link as ReactRouterLink } from 'react-router-dom'
import { PaginationNext } from 'src/components/__legacy__icon/monochrome'
import { Check } from 'src/components/icon'
import { NumberOnlyInput } from 'src/components/NumberOnlyInput/NumberOnlyInput'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import { Select } from 'src/components/Select/Select'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { IcfDocumentSet } from 'src/features/icfDocument/types'
import { useTrialHospitals } from 'src/features/trial/api'
import { TrialHospital } from 'src/features/trial/types'
import { useModal } from 'src/hooks/use-modal'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { ExplanationFormReferredPatientFragment } from 'src/lib/gql-client'
import { castUid } from 'src/utils/brandedUid'
import { DeeplyPartial } from 'src/utils/deeplyPartial'
import { formatDate } from 'src/utils/formatDate'

import { useSendTestEmail, useSendTestSMS } from '../../api'
import { useExplanationForm } from '../../hooks/useExplanationForm'
import { ExplanationFormSchema } from '../../schema/explanationFormSchema'
import { ExplanationDetail } from '../../types'
import { explanationFormDefaultValues } from '../../utils/explanationFormDefaultValues'
import { explanationToSchema } from '../../utils/explanationToSchema'
import { isPartner } from '../../utils/isPartner'
import { isSignStarted } from '../../utils/isSignStarted'
import { explanationTypeToText } from '../../utils/typeToText'
import { DocSetAccordion } from '../DocSetAccordion/DocSetAccordion'
import { ExplanationTypeRadio } from '../ExplanationTypeRadio/ExplanationTypeRadio'
import { MemberSelectForm } from '../MemberSelectForm/MemberSelectForm'
import { MembersTable } from '../MembersTable/MembersTable'
import { ScheduleInput } from '../ScheduleInput/ScheduleInput'
import { ScheduleTooltip } from '../ScheduleTooltip/ScheduleTooltip'
import { SelectDocSets } from '../SelectDocSets/SelectDocSets'

type Props =
  | {
      formType: 'add'
      referredPatient: ExplanationFormReferredPatientFragment | undefined
      onSubmit: (data: ExplanationFormSchema) => void
    }
  | {
      formType: 'edit' | 'reAgreement'
      explanation: ExplanationDetail
      onSubmit: (data: ExplanationFormSchema) => void
    }

const submitText: Record<Props['formType'], string> = {
  add: '登録',
  edit: '内容更新',
  reAgreement: '登録',
}

const currentPageName: Record<Props['formType'], string> = {
  add: '説明同意登録',
  edit: '説明同意編集',
  reAgreement: '再同意登録',
}

/** 説明同意の作成、編集、再同意作成で共通して使用するフォームコンポーネント */
// NOTE: 見通しが悪くなってきたら適宜関数を切り出したりコンポーネントを分割したりすること
export const ExplanationForm: React.FC<Props> = props => {
  const { formType, onSubmit } = props

  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  // 説明同意を作成できるのは担当医療機関が選択されている場合のみ
  if (!selectedTrialHospitalUid) {
    throw new Error('selectedTrialHospitalUid is not defined')
  }

  const mainTrialHospitalUid =
    formType === 'add'
      ? selectedTrialHospitalUid
      : props.explanation.trialHospital.uid

  const status =
    formType !== 'add' ? props.explanation.latestRevision.status : undefined

  const signNotStarted = status ? !isSignStarted(status) : false

  // 以下のように条件を定義しておくことでcanEdit...の分岐によってpropsの型が絞り込まれる
  // 例) canEditCandidateId===false のスコープではformTypeは'add'以外に絞り込まれてexplanationの値を取得できる
  // とはいえ、オブジェクトなどに切り出した方が見通しはよくなるかも
  const canEditCandidateId =
    formType === 'add' ||
    (formType === 'edit' &&
      status === 'SessionNotStarted' &&
      props.explanation.revisionUids.length === 1)
  const canEditDocSet =
    formType !== 'edit' ||
    (!isPartner(props.explanation, selectedTrialHospitalUid) &&
      status === 'SessionNotStarted')
  const canEditType =
    formType !== 'edit' ||
    (!isPartner(props.explanation, selectedTrialHospitalUid) && signNotStarted)
  const canEditPartnerTrialHospital =
    formType !== 'edit' ||
    (!isPartner(props.explanation, selectedTrialHospitalUid) && signNotStarted)
  const canEditSchedule = formType !== 'edit' || status !== 'AgreementDone'
  const canEditMembers = formType !== 'edit' || status !== 'AgreementDone'

  const defaultValue: DeeplyPartial<ExplanationFormSchema> = {
    ...explanationFormDefaultValues,
    ...(formType === 'add'
      ? {
          type: !!props.referredPatient ? 'RemotePartner' : undefined,
          partnerTrialHospitalUid: !!props.referredPatient
            ? props.referredPatient.patientTrialHospitalUid
            : undefined,
        }
      : explanationToSchema({
          explanation: props.explanation,
          onlyPatient: formType === 'reAgreement',
        })),
  }

  const [underEditSchedule, setUnderEditSchedule] = useState(false)

  const {
    formValue,
    handleSubmit,
    errors,
    onChangeCandidateId,
    onChangeDiseaseId,
    onChangeEmail,
    onChangePhoneNumber,
    onChangeDocSetUids,
    sortDocSet,
    onChangeType,
    onChangePartnerTrialHospitalUid,
    onChangeScheduledAt,
    onChangeNotifyToPatient,
    onChangeMember,
    appendMember,
    removeMember,
    resetMembers,
  } = useExplanationForm({
    selectedTrialHospitalUid,
    defaultValue,
    status,
    eConsentNewSignFlow: selectedTrial.featureFlags.eConsentNewSignFlow,
    underEditSchedule,
  })

  const isRemoteType =
    formValue.type === 'Remote' || formValue.type === 'RemotePartner'

  const currentMembers = formValue.members

  const shouldShowResetPartnerAlert = useMemo(() => {
    if (formValue.type !== 'RemotePartner') return false

    return (
      (!!currentMembers &&
        currentMembers.some(m => !!m.member && !!m.member?.uid)) ||
      !!formValue.partnerTrialHospitalUid
    )
  }, [formValue, currentMembers])

  const { showModal } = useModal()

  const { data: trialHospitals } = useTrialHospitals({})

  const mainTrialHospital = trialHospitals?.find(
    th => th.uid === mainTrialHospitalUid,
  )

  const partnerTrialHospital = trialHospitals?.find(
    th => th.uid === formValue.partnerTrialHospitalUid,
  )

  const toast = useMirohaToast()

  const { request: sendTestEmail } = useSendTestEmail({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'テストメールを送信しました',
      })
    },
  })
  const { request: sendTestSMS } = useSendTestSMS({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'テストSMSを送信しました',
      })
    },
  })

  const selectableTrialHospitals =
    trialHospitals?.filter(th => {
      if (!selectedTrial.featureFlags.preScreening) {
        return th.uid !== selectedTrialHospitalUid
      }
      return (
        th.role === 'Partner' &&
        th.mainTrialHospitalUid === mainTrialHospitalUid
      )
    }) ?? []

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        await handleSubmit(onSubmit)
      }}
      aria-label={`Explanation Form for ${formType}`}
      // HTMLデフォルトのバリデーションを無効化
      noValidate
    >
      <Stack spacing="6">
        <Flex justify="space-between" as="header">
          <Breadcrumb
            display="flex"
            alignItems="center"
            separator={<PaginationNext size="M" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                as={ReactRouterLink}
                to={generatePath(Paths.Explanations, {
                  trialUid: selectedTrial.uid,
                })}
                color="blue.500"
                fontSize="lg"
                fontWeight="bold"
              >
                説明同意一覧
              </BreadcrumbLink>
            </BreadcrumbItem>
            {(formType === 'edit' || formType === 'reAgreement') && (
              <BreadcrumbItem>
                <BreadcrumbLink
                  as={ReactRouterLink}
                  to={generatePath(Paths.Explanation, {
                    trialUid: selectedTrial.uid,
                    explanationUid: props.explanation.uid,
                  })}
                  color="blue.500"
                  fontSize="lg"
                  fontWeight="bold"
                >
                  説明同意詳細
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem isCurrentPage fontSize="lg">
              <BreadcrumbLink
                fontWeight="bold"
                cursor="text"
                _hover={{ textDecoration: 'none' }}
              >
                {currentPageName[formType]}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <HStack spacing="4">
            <Button
              variant="outline"
              as={ReactRouterLink}
              to={
                formType === 'add'
                  ? !!props.referredPatient
                    ? generatePath(Paths.ReferredPatient, {
                        trialUid: selectedTrial.uid,
                        referredPatientUid:
                          props.referredPatient.referredPatientUid,
                      })
                    : generatePath(Paths.Explanations, {
                        trialUid: selectedTrial.uid,
                      })
                  : generatePath(Paths.Explanation, {
                      trialUid: selectedTrial.uid,
                      explanationUid: props.explanation.uid,
                    })
              }
            >
              キャンセル
            </Button>
            <Button type="submit">{submitText[formType]}</Button>
          </HStack>
        </Flex>

        <Box>
          <Box as="section" aria-labelledby="patient_info_heading">
            <HStack spacing="4" px="4" py="2" bg="blue.50">
              <TextWithBar as="h2" id="patient_info_heading">
                患者情報
              </TextWithBar>
              {props.formType === 'add' && !!props.referredPatient && (
                <HStack spacing="1.5">
                  <Circle size="20px" bg="green.500" color="white">
                    <Check size="12px" />
                  </Circle>
                  <Text as="span" fontSize="sm">
                    紹介患者
                  </Text>
                </HStack>
              )}
            </HStack>
            <Wrap p="6" spacing="8">
              <WrapItem>
                {canEditCandidateId ? (
                  <FormControl
                    w="auto"
                    isInvalid={!!errors.candidateId}
                    isRequired
                  >
                    <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
                      候補ID
                    </FormLabel>
                    <Input
                      value={formValue.candidateId ?? ''}
                      onChange={e => onChangeCandidateId(e.target.value)}
                      w="124px"
                    />
                    {errors.candidateId && (
                      <FormErrorMessage>
                        {errors.candidateId.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                ) : (
                  <Stack>
                    <FormLabel as="span">候補ID</FormLabel>
                    <Text>{props.explanation.patient.candidateId}</Text>
                  </Stack>
                )}
              </WrapItem>
              <WrapItem>
                <FormControl w="auto" isInvalid={!!errors.diseaseId}>
                  <FormLabel>症例番号</FormLabel>
                  <Input
                    w="124px"
                    value={formValue.diseaseId}
                    onChange={e => onChangeDiseaseId(e.target.value)}
                  />
                  {errors.diseaseId && (
                    <FormErrorMessage>
                      {errors.diseaseId.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </WrapItem>
              {selectedTrial.featureFlags.eConsentNewSignFlow && (
                <WrapItem>
                  <FormControl isInvalid={!!errors.phoneNumber}>
                    <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
                      携帯電話番号
                    </FormLabel>
                    <HStack spacing="2">
                      <NumberOnlyInput
                        w="160px"
                        value={formValue.phoneNumber}
                        onChange={onChangePhoneNumber}
                        isDisabled={
                          !selectedTrial.featureFlags.eConsentNewSignFlow &&
                          formValue.type !== 'Remote'
                        }
                        placeholder="例) 08011223344"
                      />
                      <Button
                        variant="text"
                        isDisabled={!formValue.phoneNumber}
                        onClick={async () => {
                          const phoneNumber = formValue.phoneNumber
                          if (!phoneNumber) return
                          await sendTestSMS({
                            phoneNumber,
                          })
                        }}
                      >
                        テスト送信
                      </Button>
                    </HStack>
                    {errors.phoneNumber && (
                      <FormErrorMessage>
                        {errors.phoneNumber.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </WrapItem>
              )}
            </Wrap>
          </Box>

          <Box as="section" aria-labelledby="explanation_content_heading">
            <Box px="4" py="2" bg="blue.50">
              <TextWithBar as="h2" id="explanation_content_heading">
                説明内容
              </TextWithBar>
            </Box>
            <Stack p="6" spacing="6">
              <Box>
                <FormLabel as="span">
                  対象文書
                  <RequiredBadge ml="1" />
                </FormLabel>
                {canEditDocSet ? (
                  <Stack>
                    <Box mt="2">
                      <DocSetAccordion
                        type="edit"
                        trialUid={selectedTrial.uid}
                        trialHospitalUid={selectedTrialHospitalUid}
                        docSetsUids={
                          (formValue.docSetUids as IcfDocumentSet['uid'][]) ??
                          []
                        }
                        onUp={(uid: IcfDocumentSet['uid']) => {
                          sortDocSet(uid, 'up')
                        }}
                        onDown={(uid: IcfDocumentSet['uid']) => {
                          sortDocSet(uid, 'down')
                        }}
                      />
                    </Box>
                    <Box>
                      <SelectDocSets
                        trialHospitalUid={selectedTrialHospitalUid}
                        selectedUids={
                          (formValue.docSetUids as IcfDocumentSet['uid'][]) ??
                          []
                        }
                        onSubmit={uids => {
                          onChangeDocSetUids(uids)
                        }}
                        onMissingRequiredDoc={() => {
                          toast({
                            status: 'warning',
                            title:
                              '設定された文書セットには説明必須の同意文書が含まれていません',
                          })
                        }}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <Stack>
                    <Box mt="2">
                      <DocSetAccordion
                        type="fixed"
                        docSets={props.explanation.latestRevision.docSets}
                        shouldShowUpdateAlert={false}
                      />
                    </Box>
                  </Stack>
                )}
                {errors.docSetUids && 'message' in errors.docSetUids && (
                  <Text fontSize="sm" color="red.500" mt="2" as="div">
                    {errors.docSetUids.message}
                  </Text>
                )}
              </Box>

              {canEditType ? (
                <FormControl isInvalid={!!errors.type} isRequired>
                  <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
                    説明方式
                  </FormLabel>

                  <ExplanationTypeRadio
                    value={formValue.type}
                    onChange={type => {
                      if (shouldShowResetPartnerAlert) {
                        showModal({
                          title: '説明方式を変更します',
                          content:
                            '説明方式を変更すると設定中のパートナー施設・担当者がリセットされます。このまま変更してもよろしいでしょうか？',
                          submitText: '変更する',
                          submitButtonColor: 'red',
                          onSubmit: () => {
                            resetMembers()
                            onChangePartnerTrialHospitalUid(undefined)
                            onChangeType(type)
                          },
                        })
                        return
                      }
                      if (!!formValue.partnerTrialHospitalUid) {
                        onChangePartnerTrialHospitalUid(undefined)
                      }
                      onChangeType(type)
                    }}
                  />
                  {errors.type && (
                    <FormErrorMessage>{errors.type.message}</FormErrorMessage>
                  )}
                </FormControl>
              ) : (
                <Stack>
                  <FormLabel as="span">説明方式</FormLabel>
                  <Text>
                    {explanationTypeToText(
                      props.explanation.latestRevision.type,
                    )}
                  </Text>
                </Stack>
              )}

              {formValue.type === 'Remote' && (
                <Wrap spacing="8">
                  <WrapItem>
                    <FormControl isInvalid={!!errors.email} isRequired>
                      <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
                        患者メールアドレス
                      </FormLabel>
                      <HStack spacing="2">
                        <Input
                          w="300px"
                          type="email"
                          value={formValue.email}
                          onChange={e => onChangeEmail(e.target.value)}
                        />
                        <Button
                          variant="text"
                          isDisabled={
                            !formValue.email || formValue.type !== 'Remote'
                          }
                          onClick={async () => {
                            const email = formValue.email
                            if (!email) return
                            await sendTestEmail({ email })
                          }}
                        >
                          テスト送信
                        </Button>
                      </HStack>
                      {errors.email && (
                        <FormErrorMessage>
                          {errors.email.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </WrapItem>
                  {!selectedTrial.featureFlags.eConsentNewSignFlow && (
                    <WrapItem>
                      <FormControl isInvalid={!!errors.phoneNumber} isRequired>
                        <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
                          患者携帯電話番号
                        </FormLabel>
                        <HStack spacing="2">
                          <Input
                            w="160px"
                            value={formValue.phoneNumber}
                            onChange={e => onChangePhoneNumber(e.target.value)}
                          />
                          <Button
                            variant="text"
                            isDisabled={
                              !formValue.phoneNumber ||
                              formValue.type !== 'Remote'
                            }
                            onClick={async () => {
                              const phoneNumber = formValue.phoneNumber
                              if (!phoneNumber) return
                              await sendTestSMS({
                                phoneNumber,
                              })
                            }}
                          >
                            テスト送信
                          </Button>
                        </HStack>
                        {errors.phoneNumber && (
                          <FormErrorMessage>
                            {errors.phoneNumber.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </WrapItem>
                  )}
                </Wrap>
              )}

              {formValue.type === 'RemotePartner' &&
                !!trialHospitals &&
                (canEditPartnerTrialHospital ? (
                  <FormControl
                    isInvalid={!!errors.partnerTrialHospitalUid}
                    isRequired
                    aria-label="パートナー施設"
                  >
                    <FormLabel
                      as="span"
                      requiredIndicator={<RequiredBadge ml="1" />}
                    >
                      パートナー施設
                    </FormLabel>

                    <Select<TrialHospital['uid'], string>
                      width={360}
                      isSearchable={!isMobile}
                      items={selectableTrialHospitals.map(th => ({
                        label: th.name,
                        value: th.uid,
                      }))}
                      onChange={newValue => {
                        if (
                          shouldShowResetPartnerAlert &&
                          newValue !== formValue.partnerTrialHospitalUid
                        ) {
                          showModal({
                            title: 'パートナー施設を変更します',
                            content:
                              'パートナー施設を変更すると設定中の担当者がリセットされます。このまま変更してもよろしいでしょうか？',
                            submitText: '変更する',
                            submitButtonColor: 'red',
                            onSubmit: () => {
                              resetMembers()
                              onChangePartnerTrialHospitalUid(newValue)
                            },
                          })
                          return
                        }
                        onChangePartnerTrialHospitalUid(newValue)
                      }}
                      value={
                        formValue.partnerTrialHospitalUid
                          ? castUid<TrialHospital>(
                              formValue.partnerTrialHospitalUid,
                            )
                          : undefined
                      }
                    />
                    {errors.partnerTrialHospitalUid && (
                      <FormErrorMessage>
                        {errors.partnerTrialHospitalUid.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                ) : (
                  <Stack>
                    <FormLabel as="span">パートナー施設</FormLabel>
                    <Text>
                      {
                        props.explanation.latestRevision.partnerTrialHospital
                          ?.name
                      }
                    </Text>
                  </Stack>
                ))}

              {isRemoteType &&
                !selectedTrial.featureFlags.preScreening &&
                (canEditSchedule ? (
                  <Stack spacing="4">
                    <FormControl isInvalid={!!errors.scheduledAt}>
                      <FormLabel display="flex" alignItems="center">
                        予約日時
                        {formValue.type === 'Remote' && (
                          <Box ml="2">
                            <ScheduleTooltip />
                          </Box>
                        )}
                      </FormLabel>
                      <ScheduleInput
                        value={formValue.scheduledAt ?? ''}
                        onChange={value => {
                          onChangeScheduledAt(value)
                        }}
                        setUnderEditSchedule={setUnderEditSchedule}
                        visibleClear={formType !== 'edit'}
                      />

                      {errors.scheduledAt && (
                        <FormErrorMessage>
                          {errors.scheduledAt.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    {formValue.type === 'Remote' && (
                      <Box>
                        <Checkbox
                          isChecked={
                            !!formValue.scheduledAt && formValue.notifyToPatient
                          }
                          onChange={e =>
                            onChangeNotifyToPatient(e.target.checked)
                          }
                          disabled={!formValue.scheduledAt}
                          mt="4"
                        >
                          患者に予約情報を即時通知する
                        </Checkbox>
                      </Box>
                    )}
                  </Stack>
                ) : (
                  <Stack>
                    <FormLabel as="span">予約日時</FormLabel>
                    {!!props.explanation.latestRevision.scheduledAt && (
                      <Text>
                        {formatDate(
                          props.explanation.latestRevision.scheduledAt,
                          'YYYY/MM/DD (ddd) HH:mm',
                        )}
                      </Text>
                    )}
                  </Stack>
                ))}

              {/* 代諾者機能は現状は未実装 */}
              <FormControl isDisabled>
                <FormLabel>患者代諾者</FormLabel>
                <RadioGroup value="without" isDisabled>
                  <HStack spacing="8">
                    <Radio value="without">無し</Radio>
                    <Radio value="with">有り</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </Box>

          <Box as="section" aria-labelledby="explanation_members_heading">
            <Box px="4" py="2" bg="blue.50">
              <TextWithBar as="h2" id="explanation_members_heading">
                説明担当者
              </TextWithBar>
            </Box>
            {canEditMembers ? (
              !!mainTrialHospital &&
              !!formValue.members && (
                <MemberSelectForm
                  currentType={formValue.type}
                  mainTrialHospital={mainTrialHospital}
                  isPartner={
                    selectedTrialHospitalUid === partnerTrialHospital?.uid
                  }
                  members={formValue.members}
                  onChangeMember={onChangeMember}
                  partnerTrialHospital={partnerTrialHospital}
                  removeMember={removeMember}
                  appendMember={appendMember}
                  errors={errors}
                />
              )
            ) : (
              <MembersTable
                members={props.explanation.latestRevision.members}
              />
            )}
          </Box>
        </Box>
      </Stack>
    </form>
  )
}
