import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'
import { generateUUID } from 'src/utils/generateUUID'

import { TemplateFolder } from './entity'
import { fixture, fixtures } from './fixture'
import { SERVER_URL } from '../../server/const'
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from '../../server/request'

export type TemplateFolderResponse = {
  uid: string
  name: string
  is_preset: boolean
  index: number
  observation_type: string
}

export const parse = (resp: TemplateFolderResponse): TemplateFolder => {
  return {
    uid: resp.uid,
    name: resp.name,
    isPreset: resp.is_preset,
    index: resp.index,
    observationType:
      resp.observation_type === 'FollowUp' ? 'FollowUp' : 'Visit',
  }
}

export const fetchList = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<TemplateFolder[]> => {
  if (IS_MOCK_MODE) {
    return fixtures
  }

  const resp = await requestGet<TemplateFolderResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/template_folders`,
    params: {},
  })

  return resp.data.map(parse)
}

export const create = async ({
  trialUid,
  name,
  isFollowup,
}: {
  trialUid: string
  name: string
  isFollowup: boolean
}): Promise<TemplateFolder> => {
  if (IS_MOCK_MODE) {
    return {
      ...fixture,
      uid: generateUUID(),
      name: name,
      index: 3,
    }
  }

  // TODO: 本来はしっかり型定義したい -> src/feature配下にschemaベースで定義したい
  const observation_type = isFollowup ? 'FollowUp' : 'Visit'

  const res = await requestPost<TemplateFolderResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/template_folders`,
    params: { name, observation_type },
  })

  return parse(res.data)
}

export const update = async ({
  trialUid,
  templateFolderUid,
  name,
}: {
  trialUid: string
  templateFolderUid: string
  name: string
}): Promise<TemplateFolder> => {
  if (IS_MOCK_MODE) {
    return {
      ...fixture,
      uid: templateFolderUid,
      name: name,
    }
  }

  const res = await requestPut<TemplateFolderResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/template_folders/${templateFolderUid}`,
    params: { name },
  })

  return parse(res.data)
}

export const submitDelete = async ({
  trialUid,
  templateFolderUid,
}: {
  trialUid: string
  templateFolderUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestDelete<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/template_folders/${templateFolderUid}`,
    params: {},
  })

  return {}
}
