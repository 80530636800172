import { useState, useCallback } from 'react'

import { CreateType, createTypes } from './create-template-modal'

export const useBuildTemplate = () => {
  const [title, setTitle] = useState('')
  const [presetTemplateUid, setPresetTemplateUid] = useState<string>()
  const [selectedType, setSelectedType] = useState<CreateType>('New')
  const [fileName, setFileName] = useState('')
  const [content, setContent] = useState('')

  const onChangeTemplateTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value)
    },
    [],
  )

  const onChangeCreateType = useCallback(
    (value: string) => {
      if (!isCreateType(value)) return
      if (value === selectedType) return

      switch (value) {
        case 'New':
          setPresetTemplateUid(undefined)
          setFileName('')
          setContent('')
          break
        case 'Import':
          setPresetTemplateUid(undefined)
          break
        case 'Preset':
          setFileName('')
          setContent('')
          break
      }

      setSelectedType(value)
    },
    [selectedType],
  )

  const onSelectPresetTemplate = useCallback(
    (value: string) => setPresetTemplateUid(value),
    [],
  )

  const onChangeFile = (file: File) => {
    setFileName(file.name)

    const reader = new FileReader()
    // TODO: replace implementation
    reader.readAsText(file)
    reader.addEventListener('load', () => {
      setContent(btoa(unescape(encodeURIComponent(reader.result as string))))
    })
  }

  return {
    title,
    presetTemplateUid,
    fileName,
    selectedType,
    content,
    handlers: {
      onChangeTemplateTitle,
      onChangeCreateType,
      onSelectPresetTemplate,
      onChangeFile,
    },
  }
}

function isCreateType(value: string): value is CreateType {
  return createTypes.includes(value)
}
