import produce from 'immer'
import {
  Choice,
  createEmpty as createEmptyChoice,
} from 'src/modules/entities/choice/entity'
import {
  MediumItem,
  createEmpty as createEmptyMediumItem,
} from 'src/modules/entities/medium-item/entity'
import { Role } from 'src/modules/entities/member/entity'
import {
  ItemType,
  SmallItem,
  createEmpty as createEmptySmallItem,
} from 'src/modules/entities/small-item/entity'
import { Template } from 'src/modules/entities/template/entity'
import { actions as templateActions } from 'src/modules/entities/template/redux'
import { actions as flashActions } from 'src/modules/flash/redux'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { update } from './request-update'
import { updateOrder } from './request-update-order'
import { hasChoice } from '../../../../../../util/item-type-classification'

const create = actionCreatorFactory('templateDetail')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  // Template
  initWithTemplate: create<{ template: Template }>('initWithTemplate'),
  updateTitle: create<{ title: string }>('updateTitle'),
  updateVideoEnabled: create<{ videoEnabled: boolean }>('updateVideoEnabled'),

  // MediumItem
  addMediumItem: create<{}>('addMediumItem'),
  deleteMediumItem: create<{ index: number }>('deleteMediumItem'),
  changeIndexMediumItem: create<{ index: number; direction: 'up' | 'down' }>(
    'changeIndexMediumItem',
  ),
  updateMediumItem: create<{
    index: number
    values: Partial<MediumItem>
  }>('updateMediumItem'),

  // SmallItem
  addSmallItem: create<{ mediumItemIndex: number; v2: boolean }>(
    'addSmallItem',
  ),
  deleteSmallItem: create<{ mediumItemIndex: number; uid: string }>(
    'deleteSmallItem',
  ),
  changeIndexSmallItem: create<{
    mediumItemIndex: number
    uid: string
    direction: 'up' | 'down'
  }>('changeIndexSmallItem'),
  updateSmallItem: create<{
    mediumItemIndex: number
    uid: string
    values: Partial<SmallItem>
  }>('updateSmallItem'),
  updateSmallItemViewableHospitalUids: create<{
    smallItemUids: string[]
    trialHospitalUids: string[]
  }>('updateSmallItemViewableHospitalUids'),

  // Choice
  addChoice: create<{ mediumItemIndex: number; smallItemUid: string }>(
    'addChoice',
  ),
  updateChoice: create<{
    mediumItemIndex: number
    smallItemUid: string
    choiceIndex: number
    values: Partial<Choice>
  }>('updateChoice'),
  deleteChoice: create<{
    mediumItemIndex: number
    smallItemUid: string
    choiceIndex: number
  }>('deleteChoice'),

  addSmallItemToChoice: create<{
    mediumItemIndex: number
    smallItemUid: string
    choiceIndex: number
    v2: boolean
  }>('addSmallItemToChoice'),

  // Request
  submitUpdate: createAsync<null, {}, { message: string }>(
    'submitUpdate',
    async (_params, dispatch, getState) => {
      const { originalTemplate, title, videoEnabled, mediumItems } =
        getState().templateDetail

      const selectedTrial = getState().entities.account.selectedTrial!
      const v2 = selectedTrial.featureFlags.eSourceV2
      let newMediumItem = mediumItems
      // v2で回答タイプがラベル以外であればinputRolesをDr/CRCに設定する(API側のバリデーションエラーになるため)
      if (v2) {
        newMediumItem = mediumItems.map(mi => {
          const newSmallItemMap: Record<string, SmallItem> = {}
          Object.values(mi.smallItemMap).forEach(si => {
            newSmallItemMap[si.uid] = {
              ...si,
              inputRoles:
                si.itemType !== ItemType.Label
                  ? [(Role.Dr, Role.CRC)]
                  : si.inputRoles,
            }
          })

          return {
            ...mi,
            smallItemMap: newSmallItemMap,
          }
        })
      }

      try {
        const updated = await update({
          uid: originalTemplate!.uid,
          trialUid: originalTemplate!.trialUid,
          title,
          videoEnabled,
          mediumItems: v2 ? newMediumItem : mediumItems,
        })

        dispatch(templateActions.upsert(updated))
      } catch (error) {
        dispatch(flashActions.showError({ message: error.message }))
        throw error
      }
    },
  ),

  submitUpdateOrder: createAsync<null, {}, { message: string }>(
    'submitUpdateOrder',
    async (_params, dispatch, getState) => {
      const { originalTemplate, mediumItems } = getState().templateDetail

      try {
        const response = await updateOrder({
          uid: originalTemplate!.uid,
          trialUid: originalTemplate!.trialUid,
          mediumItems,
        })

        dispatch(
          templateActions.updateOrder({
            uid: originalTemplate!.uid,
            res: response,
          }),
        )
      } catch (error) {
        throw error
      }
    },
  ),
}

export type State = {
  originalTemplate: Template | null
  title: string
  videoEnabled: boolean
  mediumItems: MediumItem[]

  submitting: boolean
}

const initialState: State = {
  originalTemplate: null,
  title: '',
  videoEnabled: false,
  mediumItems: [],

  submitting: false,
}

export const reducer = reducerWithInitialState(initialState)
  // Template
  .case(actions.initWithTemplate, (state, payload) =>
    produce(state, draft => {
      const { mediumItems } = payload.template
      if (!mediumItems) return

      draft.originalTemplate = payload.template
      draft.title = payload.template.title
      draft.videoEnabled = payload.template.videoEnabled
      draft.mediumItems = mediumItems
    }),
  )
  .case(actions.updateTitle, (state, payload) =>
    produce(state, draft => {
      const { title } = payload

      draft.title = title
    }),
  )
  .case(actions.updateVideoEnabled, (state, payload) =>
    produce(state, draft => {
      const { videoEnabled } = payload

      draft.videoEnabled = videoEnabled
    }),
  )
  .case(templateActions.delete, (state, payload) =>
    produce(state, draft => {
      draft.originalTemplate = null
    }),
  )

  // MediumItem
  .case(actions.addMediumItem, (state, payload) =>
    produce(state, draft => {
      const empty = createEmptyMediumItem()
      draft.mediumItems.push(empty)
    }),
  )
  .case(actions.deleteMediumItem, (state, payload) =>
    produce(state, draft => {
      draft.mediumItems.splice(payload.index, 1)
    }),
  )
  .case(actions.changeIndexMediumItem, (state, payload) =>
    produce(state, draft => {
      const { index, direction } = payload

      const swappedIndex = direction === 'up' ? index - 1 : index + 1
      if (swappedIndex < 0 || swappedIndex >= state.mediumItems.length) return
      ;[draft.mediumItems[index], draft.mediumItems[swappedIndex]] = [
        draft.mediumItems[swappedIndex],
        draft.mediumItems[index],
      ]
    }),
  )
  .case(actions.updateMediumItem, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.index]
      if (!mediumItem) return

      for (const key in payload.values) {
        // TODO: any workaround?
        const k = key as keyof MediumItem
        ;(mediumItem[k] as any) = payload.values[k] as any
      }
    }),
  )

  // SmallItem
  .case(actions.addSmallItem, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const empty = createEmptySmallItem(payload.v2)
      empty.index = Object.values(mediumItem.smallItemMap).filter(
        si => si.parentUid === null,
      ).length
      mediumItem.smallItemMap[empty.uid] = empty
    }),
  )
  .case(actions.deleteSmallItem, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const smallItem = mediumItem.smallItemMap[payload.uid]

      // 親の削除
      if (smallItem.parentUid) {
        const parent = mediumItem.smallItemMap[smallItem.parentUid]
        const choice = parent.choices.find(c =>
          c.smallItemUids.includes(smallItem.uid),
        )!
        choice.smallItemUids.splice(
          choice.smallItemUids.findIndex(uid => uid === smallItem.uid),
          1,
        )
      }

      // 子の削除
      deleteSmallItem(mediumItem, smallItem)

      delete mediumItem.smallItemMap[payload.uid]

      // 残ったフィールドの index に抜けが無いようにする
      Object.values(mediumItem.smallItemMap)
        .filter(si => si.parentUid === null)
        .sort((a, b) => a.index - b.index)
        .forEach((si, idx) => {
          si.index = idx
        })
    }),
  )
  .case(actions.changeIndexSmallItem, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const { uid, direction } = payload
      const targetSmallItem = mediumItem.smallItemMap[uid]

      const rootSmallItems = Object.values(mediumItem.smallItemMap)
        .filter(si => si.parentUid === null)
        .sort((a, b) => a.index - b.index)
      const targetSmallItemIndex = rootSmallItems.findIndex(
        si => si.uid === targetSmallItem.uid,
      )

      const swappedIndex =
        direction === 'up' ? targetSmallItemIndex - 1 : targetSmallItemIndex + 1

      const swappedItem = rootSmallItems[swappedIndex]
      if (!swappedItem) return
      ;[targetSmallItem.index, swappedItem.index] = [
        swappedItem.index,
        targetSmallItem.index,
      ]
    }),
  )
  .case(actions.updateSmallItem, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const { uid } = payload
      const smallItem = mediumItem.smallItemMap[uid]

      for (const key in payload.values) {
        // TODO: any workaround?
        const k = key as keyof SmallItem
        ;(smallItem[k] as any) = payload.values[k] as any
      }
    }),
  )
  .case(actions.updateSmallItemViewableHospitalUids, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false

      payload.smallItemUids.forEach(uid => {
        const origMediumItem = draft.originalTemplate?.mediumItems?.find(
          mi => !!mi.smallItemMap[uid],
        )
        if (!origMediumItem) return

        const origSmallItem = origMediumItem.smallItemMap[uid]
        if (!origSmallItem) return

        const mediumItem = draft.mediumItems.find(mi => !!mi.smallItemMap[uid])
        if (!mediumItem) return

        const smallItem = mediumItem.smallItemMap[uid]
        if (!smallItem) return

        origSmallItem.viewableHospitalUids = payload.trialHospitalUids
        smallItem.viewableHospitalUids = payload.trialHospitalUids
      })
    }),
  )

  // Choice
  .case(actions.addChoice, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const smallItem = mediumItem.smallItemMap[payload.smallItemUid]

      if (!hasChoice(smallItem)) return

      const empty = createEmptyChoice()
      smallItem.choices.push(empty)
    }),
  )
  .case(actions.deleteChoice, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const smallItem = mediumItem.smallItemMap[payload.smallItemUid]

      if (!hasChoice(smallItem)) return

      const choice = smallItem.choices[payload.choiceIndex]
      choice.smallItemUids
        .map(uid => mediumItem.smallItemMap[uid])
        .forEach(smallItem => deleteSmallItem(mediumItem, smallItem))

      smallItem.choices.splice(payload.choiceIndex, 1)
    }),
  )
  .case(actions.addSmallItemToChoice, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const smallItem = mediumItem.smallItemMap[payload.smallItemUid]

      if (!hasChoice(smallItem)) return

      const newSmallItem = createEmptySmallItem(payload.v2)
      newSmallItem.parentUid = smallItem.uid
      mediumItem.smallItemMap[newSmallItem.uid] = newSmallItem
      smallItem.choices[payload.choiceIndex].smallItemUids.push(
        newSmallItem.uid,
      )
    }),
  )
  .case(actions.updateChoice, (state, payload) =>
    produce(state, draft => {
      const mediumItem = draft.mediumItems[payload.mediumItemIndex]
      if (!mediumItem) return

      const { smallItemUid } = payload
      const smallItem = mediumItem.smallItemMap[smallItemUid]

      const choice = smallItem.choices[payload.choiceIndex]

      for (const key in payload.values) {
        // TODO: any workaround?
        const k = key as keyof Choice
        ;(choice[k] as any) = payload.values[k] as any
      }
    }),
  )

  // Request
  .case(actions.submitUpdate.async.started, (state, payload) =>
    produce(state, draft => {
      draft.submitting = true
    }),
  )
  .case(actions.submitUpdate.async.done, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false
    }),
  )
  .case(actions.submitUpdate.async.failed, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false
    }),
  )
  .case(actions.submitUpdateOrder.async.started, (state, payload) =>
    produce(state, draft => {
      draft.submitting = true
    }),
  )
  .case(actions.submitUpdateOrder.async.done, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false
    }),
  )
  .case(actions.submitUpdateOrder.async.failed, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false
    }),
  )
  .build()

// SmallItem と、その choices 以下の SmallItem の削除
const deleteSmallItem = (mediumItem: MediumItem, smallItem: SmallItem) => {
  const deleteChildren = (si: SmallItem) => {
    const childSmallItemUids = si.choices.map(c => c.smallItemUids).flat()

    childSmallItemUids.forEach(childSmallItemUid => {
      const childSmallitem = mediumItem.smallItemMap[childSmallItemUid]
      deleteChildren(childSmallitem)
      delete mediumItem.smallItemMap[childSmallItemUid]
    })
  }

  deleteChildren(smallItem)
  delete mediumItem.smallItemMap[smallItem.uid]
}
