import { HttpResponse } from 'msw'
import {
  createCreateExpSessionPayloadMock,
  CreateSessionDocument,
  CreateSessionMutation,
  CreateSessionMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const createSessionHandler = graphQLHandlerFactory<
  CreateSessionMutation,
  CreateSessionMutationVariables
>('mutation', CreateSessionDocument, () => {
  return HttpResponse.json({
    data: {
      createExpSession: createCreateExpSessionPayloadMock(),
    },
  })
})
