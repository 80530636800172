import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { Message } from 'src/components/base/message/message'
import { Pdf } from 'src/components/pdf/pdf'
import { Spacer } from 'src/components/spacer/spacer'
import { Spinner } from 'src/components/spinner/spinner'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { useInit } from 'src/modules/dashboard/use-init'
import { getCurrentUser } from 'src/modules/entities/account/selector'
import { QueryParamKeys, stringToBool, useQuery } from 'src/modules/router'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { WorksheetPDFData } from './entity'
import { fetch } from './request'
import { patientIdParamName } from '../../../../worksheet'
import { worksheetIdParamName } from '../../worksheet-detail'

export const PdfContainer = () => {
  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  const query = useQuery()

  const {
    requesting,
    worksheetPDFData,
    errorMessage: PDFErrorMessage,
  } = useFetch({
    trialUid,
    patientUid,
    worksheetUid,
  })

  const { initialized } = useInit()

  const datetime = dayjs(Date.now()).format('YYYY年MM月DD日HH時mm分ss秒')
  const account = useSelector(getCurrentUser)!

  if (!worksheetPDFData || requesting || !initialized) {
    return <Spinner />
  }

  return (
    <Container>
      <Pdf
        isHistory={stringToBool(query.get(QueryParamKeys.History))}
        isWorksheet={stringToBool(query.get(QueryParamKeys.Worksheet))}
        isCallHistory={stringToBool(query.get(QueryParamKeys.CallHistory))}
        worksheetPDFData={worksheetPDFData}
        // callHistories={callHistories}
        datetime={datetime}
        currentRole={account.selectedTrial!.role}
        accountName={`${account.lastName} ${account.firstName}`}
        accountEmail={account.email}
      />
      {PDFErrorMessage && (
        <div>
          <Spacer size={4} />
          <Message type="error" message={PDFErrorMessage} centered />
        </div>
      )}
    </Container>
  )
}

const useFetch = ({
  trialUid,
  patientUid,
  worksheetUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}) => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()
  const [worksheetPDFData, setWorksheetPDFData] =
    useState<WorksheetPDFData | null>(null)

  // const [callHistories, setCallHistories] = useState<CallHistory[]>([])

  useEffect(() => {
    const request = async () => {
      try {
        requestStarted()
        const res = await fetch({ trialUid, patientUid, worksheetUid })
        setWorksheetPDFData(res)

        requestDone()
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    }

    request()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientUid, trialUid, worksheetUid])

  return {
    requesting,
    errorMessage,
    worksheetPDFData,
  }
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${colors.bgGray};
`
