import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { UpdateTitleModal } from './update-title-modal'
import { getSelectedTrial } from '../../../../../../../../entities/account/selector'
import { actions } from '../../../../../../../../entities/template/redux'
import { updateTitle } from '../../../../../../../../entities/template/request'
import { getSingle } from '../../../../../../../../entities/template/selector'
import { useFlash } from '../../../../../../../../flash/use-flash'
import { RootState } from '../../../../../../../../reducer'
import { useRequestState } from '../../../../../../../../server/use-request-state'

const useSubmit = (templateUid: string) => {
  const trialUid = useSelector(getSelectedTrial)!.uid

  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const request = useCallback(
    async (title: string) => {
      try {
        requestStarted()
        await updateTitle({ trialUid, templateUid, title })
        await dispatch(actions.fetchSingle({ trialUid, templateUid }))
        showSuccess('テンプレート名を変更しました')
        requestDone()
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [
      dispatch,
      requestDone,
      requestFailed,
      requestStarted,
      showSuccess,
      templateUid,
      trialUid,
    ],
  )

  return { request, requesting, errorMessage }
}

type Props = {
  templateUid: string
  onClose: () => void
}

export const UpdateTitleModalContainer: React.FC<Props> = props => {
  const template = useSelector((state: RootState) =>
    getSingle(state, props.templateUid),
  )
  const { request, requesting, errorMessage } = useSubmit(template.uid)
  const onSubmit = useCallback(
    async (title: string) => {
      await request(title)
      props.onClose()
    },
    [props, request],
  )

  return (
    <UpdateTitleModal
      initialName={template.title}
      onClose={props.onClose}
      onSubmit={onSubmit}
      requesting={requesting}
      errorMessage={errorMessage}
    />
  )
}
