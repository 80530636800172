import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'
import { Spinner } from 'src/components/spinner/spinner'
import { getRelativePath, PathKey, Paths } from 'src/constants/paths'
import { useScreenOrientationAlert } from 'src/hooks/use-orientation-alert'
import { useInit } from 'src/modules/dashboard/use-init'
import styled from 'styled-components'

import { ExplanationRoomCompleteDocuSignContainer } from './complete-docusign/complete-docusign-container'
import { HospitalDocumentContainer } from './document/hospital-container'
import { ExplanationRoomDocuSignContainer } from './docusign/docusign-container'
import { NotFoundError, SessionHasBeenExpired } from './error/error'
import { HospitalLobbyContainer } from './lobby/hospital-container'
import { HospitalTopContainer } from './top/hospital-container'

/** @deprecated legacy */
export const HospitalExplanationRoom: React.FC = () => {
  const { initialized } = useInit()

  useScreenOrientationAlert()

  if (!initialized) {
    return <Spinner />
  }

  return <HospitalExplanationRoomRoutes />
}

const HospitalExplanationRoomRoutes: React.FC = () => {
  const getPath = (path: PathKey): string => {
    return getRelativePath('HospitalExplanationRoom', path)
  }

  return (
    <Wrapper>
      <Routes>
        <Route
          path={getPath('HospitalExplanationRoomLobby')}
          element={<HospitalLobbyContainer />}
        />
        <Route
          path={getPath('HospitalExplanationRoomTop')}
          element={<HospitalTopContainer />}
        />
        <Route
          path={getPath('HospitalExplanationRoomDocument')}
          element={<HospitalDocumentContainer />}
        />
        <Route
          path={getPath('HospitalExplanationRoomDocumentSignLegacy')}
          element={<ExplanationRoomDocuSignContainer />}
        />
        <Route
          path={getPath('HospitalExplanationRoomCompleteDocuSign')}
          element={<ExplanationRoomCompleteDocuSignContainer />}
        />
        <Route
          path={getPath('HospitalExplanationRoomExpired')}
          element={<SessionHasBeenExpired />}
        />
        <Route
          path={getPath('HospitalExplanationRoomNotFound')}
          element={<NotFoundError />}
        />
        <Route
          path="*"
          element={
            <Navigate replace to={Paths.HospitalExplanationRoomNotFound} />
          }
        />
      </Routes>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`
