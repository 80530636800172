import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/modules/reducer'

import {
  actions,
  ChoiceValidationError,
  FieldValidationError,
  SectionValidationError,
  ValidationError,
} from './redux'

type ResetTarget =
  | keyof ValidationError
  | keyof SectionValidationError
  | keyof FieldValidationError
  | keyof ChoiceValidationError

/**
 * err.sections 内の sect を更新した新しい ValidationError を返す
 */
const updateSect = (
  err: ValidationError,
  sectionUid: string,
  sect?: SectionValidationError,
): ValidationError => {
  const newErr = { ...err }
  newErr.sections = newErr.sections?.filter(x => x.id !== sectionUid)
  if (sect) {
    newErr.sections?.push(sect)
  }
  return newErr
}

/**
 * sect.fields 内の field を更新した新しい SectionValidationError を返す
 */
const updateField = (
  sect: SectionValidationError,
  fieldUid: string,
  field?: FieldValidationError,
): SectionValidationError => {
  const newSect = { ...sect }
  newSect.fields = newSect.fields?.filter(x => x.id !== fieldUid)
  if (field) {
    newSect.fields?.push(field)
  }
  return newSect
}

/**
 * field.choices 内の choice を更新した新しい FieldValidationError を返す
 */
const updateChoice = (
  field: FieldValidationError,
  choiceUid: string,
  choice?: ChoiceValidationError,
): FieldValidationError => {
  const newField = { ...field }
  newField.choices = newField.choices?.filter(x => x.id !== choiceUid)
  if (choice) {
    newField.choices?.push(choice)
  }
  return newField
}

/**
 * err から target のエラーを削除した新しい ValidationError を返す
 */
const resetBase = (err: ValidationError, target: string): ValidationError => {
  if (!(target in err)) {
    return err
  }
  const newErr = { ...err }
  delete newErr[target as keyof ValidationError]
  return newErr
}

/**
 * err 内の sections から target のエラーを削除した新しい ValidationError を返す
 */
const resetSect = (
  err: ValidationError,
  target: string,
  sectionUid: string,
): ValidationError => {
  const sect = err.sections?.find(x => x.id === sectionUid)
  if (!sect || !(target in sect)) {
    return err
  }
  const newSect = { ...sect }
  delete newSect[target as keyof SectionValidationError]
  return updateSect(err, sectionUid, newSect)
}

/**
 * err 内の fields から target のエラーを削除した新しい ValidationError を返す
 */
const resetField = (
  err: ValidationError,
  target: string,
  sectionUid: string,
  fieldUid: string,
): ValidationError => {
  const sect = err.sections?.find(x => x.id === sectionUid)
  if (!sect) {
    return err
  }
  const field = sect.fields?.find(x => x.id === fieldUid)
  if (!field || !(target in field)) {
    return err
  }
  const newField = { ...field }
  delete newField[target as keyof FieldValidationError]
  return updateSect(err, sectionUid, updateField(sect, fieldUid, newField))
}

/**
 * err 内の choices から target のエラーを削除した新しい ValidationError を返す
 */
const resetChoice = (
  err: ValidationError,
  target: string,
  sectionUid: string,
  fieldUid: string,
  choiceUid: string,
): ValidationError => {
  const sect = err.sections?.find(x => x.id === sectionUid)
  if (!sect) {
    return err
  }
  const field = sect.fields?.find(x => x.id === fieldUid)
  if (!field) {
    return err
  }
  const choice = field.choices?.find(x => x.id === choiceUid)
  if (!choice || !(target in choice)) {
    return err
  }
  const newChoice = { ...choice }
  delete newChoice[target as keyof ChoiceValidationError]
  return updateSect(
    err,
    sectionUid,
    updateField(sect, fieldUid, updateChoice(field, choiceUid, newChoice)),
  )
}

/**
 * err から特定のエラーを削除した新しい ValidationError を返す
 */
const reset = (data: {
  err: ValidationError
  target: ResetTarget
  sectionUid?: string
  fieldUid?: string
  choiceUid?: string
}): ValidationError => {
  if (data.sectionUid && data.fieldUid && data.choiceUid) {
    return resetChoice(
      data.err,
      data.target,
      data.sectionUid,
      data.fieldUid,
      data.choiceUid,
    )
  }
  if (data.sectionUid && data.fieldUid) {
    return resetField(data.err, data.target, data.sectionUid, data.fieldUid)
  }
  if (data.sectionUid) {
    return resetSect(data.err, data.target, data.sectionUid)
  }
  return resetBase(data.err, data.target)
}

export const useValidationError = () => {
  const dispatch = useDispatch()

  const validationError = useSelector(
    (state: RootState) => state.questionnaireDetail.validationError,
  )

  const getSectionError = useCallback(
    (sectionUid: string): SectionValidationError | undefined =>
      validationError.sections?.find(x => x.id === sectionUid),
    [validationError.sections],
  )

  const getFieldError = useCallback(
    (sectionUid: string, fieldUid: string): FieldValidationError | undefined =>
      getSectionError(sectionUid)?.fields?.find(x => x.id === fieldUid),
    [getSectionError],
  )

  const getChoiceError = useCallback(
    (
      sectionUid: string,
      fieldUid: string,
      choiceUid: string,
    ): ChoiceValidationError | undefined =>
      getFieldError(sectionUid, fieldUid)?.choices?.find(
        x => x.id === choiceUid,
      ),
    [getFieldError],
  )

  // 特定のバリデーションエラーのみリセットする
  const resetValidationError = useCallback(
    (data: {
      target: ResetTarget
      sectionUid?: string
      fieldUid?: string
      choiceUid?: string
    }) => {
      const newErr = reset({
        ...data,
        err: validationError,
      })
      dispatch(actions.updateValidationError({ err: newErr }))
    },
    [dispatch, validationError],
  )

  return {
    validationError,
    getSectionError,
    getFieldError,
    getChoiceError,
    resetValidationError,
  }
}
