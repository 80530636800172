import { useMemo } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { useExplanationsQueryParams } from './useExplanationsQueryParams'

export const useSearchExplanations = () => {
  const { queryParamObject, setQuery } = useExplanationsQueryParams()

  const currentSearchQuery = useMemo(
    () => queryParamObject.searchQuery,
    [queryParamObject.searchQuery],
  )

  const handleChangeSearchQuery = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery({ type: 'search', value: e.target.value })
    },
    500,
  )

  return {
    handleChangeSearchQuery,
    searchQuery: currentSearchQuery,
  }
}
