// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgHospitals = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      fillRule="evenodd"
      d="M8.649 0H0v16h8.649V0Zm-.865.889H.864V15.11h1.73v-3.556h3.46v3.556h1.73V.889Zm-3.877 3.52v1.313h.875V4.41h1.312v-.875H4.782V2.222h-.875v1.313H2.594v.875h1.313Zm2.147 5.369h-3.46v.888h3.46v-.888Zm-.865 2.666h-1.73v2.666h1.73v-2.666Zm3.892-7.11H16V16H9.081V5.333Zm.865.888h5.189v8.889h-5.19V6.222Zm2.162 3.556v-.89h-.865V8h.865V7.11h.865V8h.865v.889h-.865v.889h-.865Zm-1.298.888h.865v.89h-.865v-.89Zm1.298 0h.865v.89h-.865v-.89Zm2.162 0h-.865v.89h.865v-.89ZM11.675 12h-.865v.89h.865v-.89Zm1.298 0h-.865v.89h.865v-.89Zm.432 0h.865v.89h-.865v-.89Zm.865 1.334h-3.46v1.777h3.46v-1.777Zm-.865.888h-1.73v.89h1.73v-.89Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHospitals
