import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Add } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { useCurrentMember } from 'src/features/auth/context'
import { GetPatientReturn, usePatient } from 'src/features/patient/api'
import { Trial } from 'src/features/trial/types'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useCreateWorksheets } from '../../api/createWorksheets'
import { useSelectCreatableSchema } from '../../hooks/useSelectCreatableSchema'

type Props = {
  trialUid: Trial['uid']
  patientUid: string
  actualVisit: GetPatientReturn['observationVisits'][number]
}

export const AddVisitWorksheet: React.FC<Props> = ({
  trialUid,
  patientUid,
  actualVisit,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentMember } = useCurrentMember()

  const { selectedUidSet, onCheckAll, onCheck, onClear } =
    useSelectCreatableSchema({
      schemas: actualVisit.creatableWorksheetSchemas,
    })

  const { data: patient, mutate: mutatePatient } = usePatient({
    patientUid,
    revalidateIfStale: false,
  })

  const navigate = useNavigate()
  const toast = useMirohaToast()

  const { request: createWorksheets } = useCreateWorksheets({
    onSuccess: createdWorksheets => {
      // ワークシート一覧が更新されるように、患者情報を再取得する
      mutatePatient()
      handleClose()
      navigate(
        generatePath(Paths.PatientWorksheet, {
          trialUid,
          patientUid,
          worksheetUid: createdWorksheets[0].uid,
        }),
      )
      toast({
        title: 'ワークシートを追加しました',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const handleClose = () => {
    onClear()
    onClose()
  }

  if (currentMember.role === 'DM' || currentMember.role === 'CRA') return null

  if (!patient || patient.status === 'Disable') {
    return null
  }

  return (
    <>
      <Button
        colorScheme="blue"
        variant="outline"
        leftIcon={<Add />}
        onClick={onOpen}
      >
        ワークシート追加
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize="lg" fontWeight="bold">
              ワークシート追加
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box borderBottom="1px" borderBottomColor="gray.500">
              <Checkbox
                isChecked={
                  selectedUidSet.size ===
                  actualVisit.creatableWorksheetSchemas.length
                }
                onChange={() => onCheckAll()}
                w="full"
                pb="2.5"
                sx={{
                  '> .chakra-checkbox__label': {
                    fontSize: 'md',
                    fontWeight: 'bold',
                    ml: 5,
                  },
                }}
              >
                {actualVisit.name}
              </Checkbox>
            </Box>

            {actualVisit.creatableWorksheetSchemas.map(schema => (
              <Box
                key={schema.worksheetSchemaUid}
                borderBottom="1px"
                borderBottomColor="gray.100"
              >
                <Checkbox
                  isChecked={selectedUidSet.has(schema.worksheetSchemaUid)}
                  onChange={() => onCheck(schema.worksheetSchemaUid)}
                  w="full"
                  py="3"
                  sx={{
                    '> .chakra-checkbox__label': {
                      fontSize: 'md',
                      ml: 5,
                    },
                  }}
                >
                  <Text as="span">{schema.name}</Text>
                </Checkbox>
              </Box>
            ))}
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="blue"
              isDisabled={selectedUidSet.size === 0}
              onClick={async () => {
                await createWorksheets({
                  actualVisitUid: actualVisit.patientObservationUid,
                  worksheetSchemaUids: Array.from(selectedUidSet),
                })
              }}
            >
              追加する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
