import React, { useState, useEffect, useCallback, useMemo } from 'react'

import { Text } from '@chakra-ui/react'
import { NavLink as OriginalNavLink } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { layouts } from 'src/assets/layouts'
import {
  Circle,
  CircleOutline,
  CircleOutline2,
  CloseArrow,
  OpenArrow,
} from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { blue, gray, green } from 'src/components/base/color/palette'
import { CommonDisableLabel } from 'src/components/labels/disabled'
import { Spacer } from 'src/components/spacer/spacer'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import styled from 'styled-components'
import Flex from 'styled-flex-component'
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from 'styled-system'

import { DeleteButton } from './template/template-folder/delete/button/delete-button'
import { EditButton } from './template/template-folder/edit/button/edit-button'

type TemplateNavItem = {
  type: 'template'
  path: string
  name: string
  status: TemplateStatus
}
type WorksheetNavItem = {
  type: 'worksheet'
  path: string
  name: string
  disabled?: boolean
}

export type NavItem = TemplateNavItem | WorksheetNavItem

export type CardNavItem = {
  templateFolderUid: string
  title: string
  deletable: boolean
  navItems: NavItem[]
  onAdd: () => void
  addText: string
}

type NavStyleProps = {
  topIndex: number
  bottomIndex: number
}

type Props = SpaceProps &
  LayoutProps & {
    v2: boolean
    items: CardNavItem[]
    canAdd: boolean
    activeTemplateFolderUid: string | null
    templateFolderEditable: boolean
  }

const useSelectTemplateFolder = (initialTemplateFolderUid: string | null) => {
  const [selectedTemplateFolderUid, setSelectedTemplateFolderUid] = useState<
    string | null
  >(initialTemplateFolderUid)

  useEffect(() => {
    setSelectedTemplateFolderUid(initialTemplateFolderUid)
  }, [initialTemplateFolderUid])

  const clickNav = useCallback(
    (templateFolderUid: string) => {
      setSelectedTemplateFolderUid(
        selectedTemplateFolderUid === templateFolderUid
          ? null
          : templateFolderUid,
      )
    },
    [selectedTemplateFolderUid],
  )

  return { selectedTemplateFolderUid, clickNav }
}

const useHoverTemplateFolderEditButton = () => {
  const [buttonHovered, setButtonHovered] = useState(false)

  const handlers = useMemo(
    () => ({
      hoverButton: () => setButtonHovered(true),
      unhoverButton: () => setButtonHovered(false),
    }),
    [],
  )

  return { buttonHovered, handlers }
}

const IconButtonWrapper: React.FC<
  {
    children: React.ReactNode
    onMouseEnter: () => void
    onMouseLeave: () => void
  } & PositionProps
> = ({ children, onMouseEnter, onMouseLeave, ...styleProps }) => {
  return (
    <ButtonWrapper
      onClick={e => {
        e.stopPropagation()
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...styleProps}
    >
      {children}
    </ButtonWrapper>
  )
}

const getTemplateStatusIcon = (status: TemplateStatus) => {
  switch (status) {
    case TemplateStatus.New:
      return <CircleOutline color={green[40]} />
    case TemplateStatus.Draft:
      return <CircleOutline2 color={green[40]} />
    case TemplateStatus.Released:
      return <Circle color={green[40]} />
    case TemplateStatus.Disabled:
      return <CircleOutline color={gray[55]} />
    default:
      return ''
  }
}

export const CardNav: React.FC<Props> = props => {
  const {
    items,
    canAdd: canEdit,
    activeTemplateFolderUid,
    templateFolderEditable,
    ...styleProps
  } = props
  const { selectedTemplateFolderUid, clickNav } = useSelectTemplateFolder(
    activeTemplateFolderUid,
  )
  const {
    handlers: { hoverButton, unhoverButton },
  } = useHoverTemplateFolderEditButton()

  return (
    <NavWrap {...styleProps}>
      {items.map((item, i) => (
        <React.Fragment key={item.templateFolderUid}>
          <Nav
            topIndex={i}
            bottomIndex={items.length - 1 - i}
            className={
              item.templateFolderUid === selectedTemplateFolderUid
                ? 'active'
                : ''
            }
            onClick={() => clickNav(item.templateFolderUid)}
          >
            {templateFolderEditable &&
            item.templateFolderUid === selectedTemplateFolderUid ? (
              <Flex alignCenter>
                <Text fontWeight="bold">{item.title}</Text>
                <Spacer size={10} horizontal />
                <IconButtonWrapper
                  onMouseEnter={hoverButton}
                  onMouseLeave={unhoverButton}
                >
                  <EditButton
                    v2={props.v2}
                    templateFolderUid={item.templateFolderUid}
                  />
                </IconButtonWrapper>

                {item.deletable && (
                  <IconButtonWrapper
                    onMouseEnter={hoverButton}
                    onMouseLeave={unhoverButton}
                  >
                    <DeleteButton templateFolderUid={item.templateFolderUid} />
                  </IconButtonWrapper>
                )}
              </Flex>
            ) : item.templateFolderUid === selectedTemplateFolderUid ? (
              <Text fontWeight="bold">{item.title}</Text>
            ) : (
              <Text>{item.title}</Text>
            )}

            {item.templateFolderUid === selectedTemplateFolderUid ? (
              <CloseArrow color={blue[70]} />
            ) : (
              <OpenArrow color={blue[70]} />
            )}
          </Nav>

          {item.templateFolderUid === selectedTemplateFolderUid && (
            <NavItems>
              {item.navItems.map(navItem => (
                <NavLink
                  key={`${navItem.path}`}
                  to={`${navItem.path}`}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  <Flex justifyBetween alignCenter>
                    {navItem.type === 'template' && (
                      <Flex alignCenter>
                        {getTemplateStatusIcon(navItem.status)}
                        <Spacer size={8} horizontal />
                        <Text
                          color={
                            navItem.status === TemplateStatus.Disabled
                              ? gray[55]
                              : undefined
                          }
                        >
                          {navItem.name}
                        </Text>
                      </Flex>
                    )}

                    {navItem.type === 'worksheet' && (
                      <>
                        <Text>{navItem.name}</Text>
                        {navItem.disabled && (
                          <CommonDisableLabel text={'無効'} />
                        )}
                      </>
                    )}
                  </Flex>
                </NavLink>
              ))}

              <Spacer size={8} />

              {canEdit && (
                <Button
                  size="S"
                  text={item.addText}
                  iconName="add"
                  buttonType="normal"
                  onClick={item.onAdd}
                ></Button>
              )}
            </NavItems>
          )}
        </React.Fragment>
      ))}
    </NavWrap>
  )
}

const NavWrap = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  user-select: none;

  ${space}
  ${layout}
`

const Nav = styled.div<NavStyleProps>`
  top: ${props => props.topIndex * 53}px;
  bottom: ${props => props.bottomIndex * 53}px;
  width: 100%;
  padding: 12px 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.bgWhite};
  cursor: pointer;

  > .text {
    display: flex;
    margin: 0;
    box-sizing: border-box;
    font-size: 16px;
    color: ${colors.dark};
    word-wrap: break-word;

    @media (max-width: ${layouts.tabletLandscapeWithMax}) {
      font-size: 14px;
    }
  }
`

const NavItems = styled.div`
  width: 100%;
  padding: 6px 28px 6px 18px;
  box-sizing: border-box;
`

const NavLink = styled(OriginalNavLink)`
  display: block;
  text-decoration: none;
  margin: 1px 0;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.dark};
  cursor: pointer;
  word-break: break-all;
  .disable_color {
    color: ${colors.grayLighten1};
  }
  &:hover {
    background: ${blue[5]};
  }
  &.active {
    background: ${blue[10]};
    cursor: default;

    &:hover {
      background: ${blue[10]};
    }
  }
`

const ButtonWrapper = styled.div<PositionProps>`
  display: inline-block;
  ${position}
`
