import React, { useId, useState } from 'react'

import { HStack, Text } from '@chakra-ui/react'
import _ from 'lodash'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { colors } from 'src/assets/colors'
import {
  RadioCheckedDisabled,
  RadioCheckedEconsent,
  RadioChecked,
  RadioDisabled,
  RadioUnchecked,
  RadioWarning,
} from 'src/assets/icon/multicolor'
import { Gender } from 'src/modules/entities/patient/entity'
import styled, { css } from 'styled-components'

import { Check } from '../__legacy__icon/monochrome'
import { blue, green } from '../base/color/palette'

type Props = {
  items: RadioItem[]
  selectedValue: string | number | undefined
  name?: string
  className?: string
  disabled?: boolean
  onChange: (value: string) => void
  onClick?: (clickedValue: string) => void
  warning?: boolean
  eConsent?: boolean // eConsent用の緑カラーを定義・ラジオボタン自体の改修は後ほど行う
  vertical?: boolean
}

export type RadioItem = {
  value: string | number
  name: string
  disabled?: boolean
  completed?: boolean
}

const Component: React.FC<Props> = props => {
  const [name] = useState(_.uniqueId('name-'))

  const getClassName = (
    item: RadioItem,
    label: string,
    allDisabled?: boolean,
  ): string => {
    if (allDisabled || item.disabled) {
      return label + ' disabled'
    }
    if (item.value === Gender.Unspecified) {
      return label + ' unspecified'
    }
    return label
  }

  return (
    <div className={props.className}>
      {props.items.map(item => {
        const id = _.uniqueId('radio-')
        if (item.completed) {
          return (
            <HStack className="item" key={id} px="8px" mr="16px">
              <Check color={props.eConsent ? green[50] : blue[50]} size="M" />
              <Text fontSize="sm">{item.name}</Text>
            </HStack>
          )
        }

        const radioClassName = getClassName(item, 'radio', props.disabled)
        const labelClassName = getClassName(item, 'label', props.disabled)
        return (
          <div className="item" key={id}>
            <input
              className={radioClassName}
              type="radio"
              name={props.name ? props.name : name}
              id={id}
              onChange={() => props.onChange(item.value as string)}
              onClick={() =>
                props.onClick && props.onClick(item.value as string)
              }
              checked={props.selectedValue === item.value}
              disabled={props.disabled || item.disabled}
              value={item.value}
            />
            <label className={labelClassName} htmlFor={id}>
              {item.name}
            </label>
          </div>
        )
      })}
    </div>
  )
}

/**
 * @deprecated Use chakra-ui Radio instead.
 */
export const Radio = styled(Component)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${({ vertical }) =>
    !vertical
      ? css`
          align-items: center;
        `
      : css`
          align-items: flex-start;
          flex-direction: column;
        `}

  > .item {
    position: relative;
    display: flex;
    align-items: center;

    > .radio {
      appearance: button;
      display: none;
      margin: 0;

      &:checked + label:after {
        content: ${p =>
          p.eConsent
            ? css`url(${RadioCheckedEconsent})`
            : css`url(${RadioChecked})`};
        width: 20px;
        height: 20px;
        border: none;
      }

      &.disabled + label:after {
        content: url(${RadioDisabled});
      }

      &.disabled {
        &:checked + label:after {
          content: url(${RadioCheckedDisabled});
        }
      }
    }

    > .label {
      word-break: break-word;
      padding: 10px 28px;

      border: none;
      box-sizing: border-box;
      transition: background-color 0.14s linear;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      user-select: none;
      color: ${colors.dark};
      transition: all 0.2s linear;
      &:after {
        content: url(${RadioUnchecked});
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        display: block;
        border-radius: 100%;
        transition: all 0.2s linear;
      }

      &.disabled {
        background-color: unset;
        &:hover {
          cursor: not-allowed;
        }
      }

      ${p =>
        p.warning &&
        !p.selectedValue &&
        css`
          &::after {
            content: url(${RadioWarning});
          }
        `}
    }
  }
`

export const GenderRadio = styled(Component)`
  display: flex;
  align-items: center;

  > .item {
    position: relative;
    display: flex;
    align-items: center;

    > .radio {
      appearance: button;
      visibility: visible;
      margin: 0;
      opacity: 0;

      &:focus + label:after {
        border: 1px solid ${colors.primary};
      }

      &:checked + label:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 12px;
        left: 15px;
        border-radius: 100%;
        background: ${colors.dark};
      }

      &.unspecified + label:before {
        display: none;
      }
    }

    > .label {
      height: 36px;
      width: 55px;
      padding: 10px 0 10px 21px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      user-select: none;
      color: ${colors.dark};

      &:after {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        top: 8px;
        left: 11px;
        display: block;
        border-radius: 100%;
        border: 1px solid ${colors.borderGray};
        transition: border-color 0.2s linear;
      }

      &.unspecified {
        border-radius: 5%;
        border: 1px solid ${colors.borderGray};
        padding: 0;
        display: flex;
        justify-content: center;
        width: 70px;
        font-size: 0.8rem;
        border-radius: 50px;
        height: 22px;

        &:hover {
          opacity: 0.8;
        }

        &:after {
          display: none;
        }
      }
    }
  }
`

// FIXME: react-hook-form用のラジオボタン。一旦はここに作成するが適切な箇所へ移動・リファクタする。
type RHFProps<T extends FieldValues> = {
  name: string
  defaultChecked: boolean
  value: string
  register?: UseFormRegister<T>
  disabled?: boolean
  error?: boolean
  label?: string
}

export const RHFSingleRadio = <T extends FieldValues>(props: RHFProps<T>) => {
  const id = useId()
  const rhfProps = props.register ? props.register(props.name as Path<T>) : {}

  return (
    <Wrapper>
      <RadioInput
        id={id}
        type="radio"
        defaultChecked={props.defaultChecked}
        disabled={props.disabled ?? false}
        value={props.value}
        {...rhfProps}
      />
      <Label htmlFor={id} disabled={props.disabled ?? false}>
        {props.label}
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

const RadioInput = styled.input<{ disabled: boolean }>`
  appearance: button;
  display: none;
  margin: 0;

  &:checked + label:after {
    content: url(${RadioChecked});
    width: 20px;
    height: 20px;
    border: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      & + label:after {
        content: url(${RadioDisabled});
      }

      &:checked + label:after {
        content: url(${RadioCheckedDisabled});
      }
    `}
`

const Label = styled.label<{ disabled: boolean }>`
  word-break: break-word;
  padding: 10px 28px;

  border: none;
  box-sizing: border-box;
  transition: background-color 0.14s linear;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
  color: ${colors.dark};
  transition: all 0.2s linear;

  &:after {
    content: url(${RadioUnchecked});
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    display: block;
    border-radius: 100%;
    transition: all 0.2s linear;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: unset;

      &:hover {
        cursor: not-allowed;
      }
    `}
`
