import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { TemplateExport } from './extity'
import { fixture } from './fixture'

type TemplateExportResponse = {
  url: string
}

export const submitExport = async ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}): Promise<TemplateExport> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestPost<TemplateExportResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${templateUid}/export`,
    params: {},
  })

  return res.data
}
