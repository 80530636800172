import { Flex, Center, Box } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'

type Props = {
  children: React.ReactNode
}

/** 患者向けのシンプルな画面用 スマホ対応 */
export const PatientSimpleScreenContainer: React.FC<Props> = ({ children }) => {
  return (
    <Flex bg="green.50" h="100svh" overflow="auto" direction="column">
      <Box h="64px" px="4">
        <Flex align="center" h="64px">
          <img src={MirohaLogo} alt="MiROHA Logo" />
        </Flex>
      </Box>
      <Flex flex="1" mb="64px" align="center" px="4">
        <Center
          bg="white"
          p="4"
          w="full"
          maxW="container.md"
          mx="auto"
          borderRadius="lg"
          flexDirection="column"
        >
          {children}
        </Center>
      </Flex>
    </Flex>
  )
}
