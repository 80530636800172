import React, { useState, useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { Spinner } from 'src/components/spinner/spinner'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { routes } from 'src/modules/routes'

import { Authenticate } from './authenticate'
import { submitAuthenticate } from './request'

export const membersParamName = 'members'
export const memberIdParamName = 'memberUid'
export const tokenParamName = 'token'

export const AuthenticateContainer = () => {
  const navigate = useNavigate()
  const {
    trialUid = '',
    members = '',
    memberUid = '',
    token = '',
  } = useParams<{
    [trialIdParamName]: string
    [membersParamName]: string
    [memberIdParamName]: string
    [tokenParamName]: string
  }>()
  const [requesting, setRequesting] = useState(true)
  const [message, setMessage] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        const { email, requiredPasswordChange } = await submitAuthenticate({
          trialUid,
          members,
          memberUid,
          token,
        })

        // 本パスワード登録済みの場合、「ログインページ」へ
        if (!requiredPasswordChange) {
          navigate(routes.login)
          return
        }

        // 本パスワード未登録場合、「仮パスワードによるログインページ」へ
        navigate(routes.temporaryPasswordLogin, { state: { email } })
      } catch (error) {
        setMessage(error.message || '問題が発生しました')
      } finally {
        setRequesting(false)
      }
    })()
  }, [memberUid, members, navigate, token, trialUid])

  if (requesting) return <Spinner />

  return <Authenticate message={message} />
}
