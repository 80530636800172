import { Worksheet } from '../types'

export const worksheetCacheKey = {
  getWorksheet: (worksheetUid: Worksheet['uid']) => worksheetUid,
  getWorksheetSchema: (worksheetUid: string) =>
    ['worksheet', worksheetUid, 'schema'].join(),
  getWorksheetLogs: (worksheetUid: Worksheet['uid']) =>
    [worksheetUid, 'logs'].join(),
  getWorksheetField: (
    worksheetUid: Worksheet['uid'],
    fid: string,
    index: number,
  ) => [worksheetUid, fid, index].join(),
  getFileFieldValue: (worksheetFieldValueUid: string) =>
    ['worksheetFileFieldValue', worksheetFieldValueUid].join(),
  getRepeatableSectionHistories: (
    worksheetUid: Worksheet['uid'],
    fid: string,
  ) => [worksheetUid, fid, 'RepeatableSectionHistories'].join(),

  getWorksheetFieldStickies: (worksheetUid: string) =>
    ['worksheet', worksheetUid, 'fieldStickies'].join(),
  getSticky: (stickyUid: string) => ['sticky', stickyUid].join(),
  getFileFieldDownloadLogs: (
    worksheetUid: string,
    fid: string,
    fieldIndex: number,
  ) =>
    [
      'worksheet',
      worksheetUid,
      'fileField',
      fid,
      fieldIndex,
      'downloadLogs',
    ].join(),
}
