import { combineReducers, Action, Reducer } from '@reduxjs/toolkit'
import actionCreatorFactory, { isType } from 'typescript-fsa'

import {
  reducer as AccountReducer,
  State as AccountState,
} from './account/redux'
import {
  reducer as CognitoReducer,
  IState as CognitoState,
} from './cognito/redux'
import { reducer as userReducer, State as UserState } from './member/redux'
import {
  reducer as patientReducer,
  State as PatientState,
} from './patient/redux'
import {
  reducer as QuestionnaireReducer,
  State as QuestionnaireState,
} from './questionnaire/redux'
import {
  reducer as templateReducer,
  State as TemplateState,
} from './template/redux'
import {
  reducer as templateFolderReducer,
  State as TemplateFolderState,
} from './template-folder/redux'
import { reducer as TrialReducer, State as TrialState } from './trial/redux'
import {
  reducer as worksheetReducer,
  State as WorksheetState,
} from './worksheet/redux'

export type State = {
  account: AccountState
  patient: PatientState
  templateFolder: TemplateFolderState
  template: TemplateState
  worksheet: WorksheetState
  trial: TrialState
  user: UserState
  cognito: CognitoState
  questionnaire: QuestionnaireState
}

const pureCombinedEntitiesReducer = combineReducers<State>({
  account: AccountReducer,
  patient: patientReducer,
  templateFolder: templateFolderReducer,
  template: templateReducer,
  worksheet: worksheetReducer,
  trial: TrialReducer,
  user: userReducer,
  cognito: CognitoReducer,
  questionnaire: QuestionnaireReducer,
})

const create = actionCreatorFactory('Entities')

export const actions = {
  reset: create<{}>('RESET'),
}

export const reducer: Reducer<State, any> = (
  state: State | undefined,
  action: Action,
) => {
  if (isType(action, actions.reset)) {
    state = undefined
  }

  return pureCombinedEntitiesReducer(state, action)
}
