import { SmallItemLog, LogType } from './entity'

export const fixtures: SmallItemLog[] = [
  {
    uid: 'foo',
    userName: '山川 みどり',
    oldValue: 'あり',
    newValue: 'なし',
    reason: '患者様ご本人から口頭にて報告を受けたため',
    title: '反応の有無',
    logType: LogType.Change,
    depth: 0,
    createdAt: '2019-01-01T00:00:00',
  },
  {
    uid: 'bar',
    userName: '山田 太郎',
    oldValue: 'あり',
    newValue: 'あり',
    reason: '患者様ご本人から口頭にて報告を受けたため',
    title: '反応の有無',
    logType: LogType.Confirm,
    depth: 0,
    createdAt: '2020-01-01T00:00:00',
  },
]
