import { memo } from 'react'

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Close } from 'src/components/icon'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import {
  deleteFieldAtom,
  fieldErrorFamily,
  fieldFamily,
} from 'src/lib/chicken-schema/atom'

type Props = {
  fid: string
  isDisabled: boolean
}

export const SectionForm: React.FC<Props> = memo(({ fid, isDisabled }) => {
  const atom = fieldFamily(fid)
  const [field, setField] = useAtom(atom)

  const deleteField = useSetAtom(deleteFieldAtom)

  const errors = useAtomValue(fieldErrorFamily(fid))

  const handleDelete = () => {
    deleteField(fid)
  }

  return (
    <Stack>
      <Box
        mt="4"
        bg="blue.50"
        w="full"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="sm"
        p="5"
        pos="relative"
      >
        <Box
          pos="absolute"
          right="3"
          top="3"
          as="button"
          aria-label="セクションを削除"
          color="blue.500"
          onClick={handleDelete}
        >
          <Close />
        </Box>
        <FormControl
          isRequired
          isDisabled={isDisabled}
          isInvalid={!!errors['name']}
        >
          <FormLabel requiredIndicator={<RequiredBadge ml="1" />}>
            セクション名
          </FormLabel>
          <Input
            maxW="480px"
            value={field.name}
            onChange={e => {
              setField({ name: e.target.value })
            }}
          />
          <FormErrorMessage>
            {errors['name']?.map(e => e.message).join(', ')}
          </FormErrorMessage>
        </FormControl>
      </Box>
      {errors['typeDef.fields']?.length > 0 && (
        <Box>
          {errors['typeDef.fields'].map((error, i) => (
            <Text
              key={`typeDef.fields error ${i}`}
              color="red.500"
              fontSize="sm"
            >
              {error.message}
            </Text>
          ))}
        </Box>
      )}
    </Stack>
  )
})
