import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const createExplanationHandler = restHandlerFactory(
  '/trials/{trial_uid}/explanations',
  'post',
  () =>
    HttpResponse.json(
      {
        explanationUid: generateUUID(),
        patientUid: generateUUID(),
        explanationRevisionUid: generateUUID(),
      },
      { status: 201 },
    ),
)
