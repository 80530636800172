import { Fragment } from 'react'

import { Center, Spinner } from '@chakra-ui/react'
import { useAuthenticatedAccount } from 'src/features/auth/context'

import { useUpdateRevisionStatus } from '../../context/ExplanationRoomEvent'
import { useThisSession } from '../../hooks/useThisSession'
import { ExplanationRoomNotFound } from '../ExplanationRoomNotFound/ExplanationRoomNotFound'
import { FinishedScreen } from '../FinishedScreen/FinishedScreen'

type Props = {
  sessionUid: string
  isPartner: boolean
  children: React.ReactNode
}

export const HospitalSessionChecker: React.FC<Props> = ({
  sessionUid,
  isPartner,
  children,
}) => {
  const thisSession = useThisSession({ sessionUid, forPatient: false })

  useUpdateRevisionStatus({
    listener: () => {
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  if (!thisSession.fetched) {
    return (
      <Center w="100vw" h="100vh">
        <Spinner />
      </Center>
    )
  }

  if (thisSession.isFinished) {
    return (
      <FinishedScreen
        shouldLogout={isPartner || thisSession.explanationType === 'InPerson'}
      />
    )
  }

  const ok = isPartner
    ? selectedTrialHospitalUid === thisSession.partnerTrialHospital?.uid
    : selectedTrialHospitalUid === thisSession.trialHospital.uid

  if (!ok) {
    return <ExplanationRoomNotFound />
  }

  return <Fragment>{children}</Fragment>
}
