import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const resendSignAuthEmailHandler = restHandlerFactory(
  '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/resend_email',
  'post',
  () => {
    return HttpResponse.json(undefined, { status: 204 })
  },
)
