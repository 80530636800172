// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgReload = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M5.836 3.78a1 1 0 0 0-.97-1.75l.97 1.75Zm6.298-1.75a1 1 0 1 0-.97 1.75l.97-1.75ZM14 8.592a5.5 5.5 0 0 1-5.5 5.5v2a7.5 7.5 0 0 0 7.5-7.5h-2Zm-5.5 5.5a5.5 5.5 0 0 1-5.5-5.5H1a7.5 7.5 0 0 0 7.5 7.5v-2ZM3 8.592A5.498 5.498 0 0 1 5.836 3.78l-.97-1.749A7.498 7.498 0 0 0 1 8.592h2Zm8.164-4.813A5.498 5.498 0 0 1 14 8.592h2a7.498 7.498 0 0 0-3.866-6.562l-.97 1.75Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M4.95 7.425a1 1 0 1 0 2 0h-2Zm1-4.95h1a1 1 0 0 0-1-1v1Zm-4.95-1a1 1 0 1 0 0 2v-2Zm5.95 5.95v-4.95h-2v4.95h2Zm-1-5.95H1v2h4.95v-2Z"
    />
  </svg>
)
export default SvgReload
