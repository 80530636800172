import React from 'react'

import { Text } from '@chakra-ui/react'
import { white } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { Container as AddButtonContainer } from 'src/modules/dashboard/trial/detail/patient/add/container'
import styled from 'styled-components'

import { List } from './list/list'
import { SearchPatientButton } from './list/search/button'
import { OutputButton } from './output/button'

type Props = {
  trialUid: string
  canAddPatient: boolean
  pdfOutputEnabled: boolean
}

const PATIENT_COUNT_PER_PAGE = 50

export const Home: React.FC<Props> = props => {
  const { trialUid, canAddPatient, pdfOutputEnabled } = props

  return (
    <PageContainer>
      <ContentContainer>
        <HeadContainer>
          <Left>
            <Text as="h1" fontSize="lg" fontWeight="bold">
              患者一覧
            </Text>
            <Spacer size={20} horizontal />
            {pdfOutputEnabled && <OutputButton />}
            <Spacer size={20} horizontal />
            {canAddPatient && <AddButtonContainer />}
          </Left>

          <Right>
            <SearchPatientButton />
          </Right>
        </HeadContainer>

        <Spacer size={16} />

        <List trialUid={trialUid} perPage={PATIENT_COUNT_PER_PAGE} />
      </ContentContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
`

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${white};
`

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Right = styled.div``
