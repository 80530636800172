import dayjs from 'dayjs'

import { SmallItemAnswer } from './entity'
import { hasValue } from '../../util/item-type-classification'
import { ItemType } from '../small-item/entity'

export const equal = (a: SmallItemAnswer, b: SmallItemAnswer) => {
  return toValueString(a) === toValueString(b)
}

export const toValueString = (smallItemAnswer: SmallItemAnswer) => {
  if (hasValue(smallItemAnswer)) {
    return smallItemAnswer.value ?? ''
  }

  return smallItemAnswer.choiceAnswers
    .filter(ca => ca.checked)
    .map(ca => ca.description)
    .join(', ')
}

export const isValidNullableDate = (value: string) => {
  if (!value) return true

  const year = value.split('-')[0] ?? ''

  if (!year) return false

  const yearOnlyPattern = /^[1|2]\d{3}--$/
  const yearAndMonthPattern = /^[1|2]\d{3}-[a-zA-Z0-9]*-$/
  const filledPattern = /^[1|2]\d{3}-[a-zA-Z0-9]+-[a-zA-Z0-9]*$/

  return (
    !!value.match(yearOnlyPattern) ||
    !!value.match(yearAndMonthPattern) ||
    !!value.match(filledPattern)
  )
}

export const isCorrectDataFormat = (
  smallItemAnswer: SmallItemAnswer,
): boolean => {
  const newValue = smallItemAnswer.value ?? ''

  if (smallItemAnswer.itemType === ItemType.Date) {
    return isValidDateValue(newValue)
  }

  if (smallItemAnswer.itemType === ItemType.NullableDate) {
    return isValidNullableDate(newValue)
  }

  if (smallItemAnswer.itemType === ItemType.HourMinute) {
    return isValidTimeValue(newValue)
  }

  if (smallItemAnswer.itemType === ItemType.Number) {
    return !!newValue.match(new RegExp('^(\\-[0-9])?[0-9]*?(.[0-9]+)?$', 'g'))
  }

  if (smallItemAnswer.itemType === ItemType.Text) {
    return !newValue.includes('|')
  }

  return true
}

export const isValidTimeValue = (value: string) => {
  if (value === '') return true

  const matched = value.match(/^([012]?\d):([0-5]?\d$)/)
  if (!matched) return false

  const hourStr = matched[1]
  const minuteStr = matched[2]
  const hour = Number(hourStr)
  const minute = Number(minuteStr)

  return 0 <= hour && hour <= 23 && 0 <= minute && minute <= 59
}

export const isValidDateValue = (value: string) => {
  if (value === '') return true

  const matched = value.match(/^([12]\d{3})-([0-2]?\d)-([0123]?\d)$/)
  if (!matched) return false

  const year = Number(matched[1])
  const month = Number(matched[2])
  const day = Number(matched[3])

  const valueDayJs = dayjs(value, 'YYYY-MM-DD')

  // dayjs は存在しない日付でも valid な日付として parse するので、parse 後の年月日と元の年月日それぞれを比較する
  return (
    year === valueDayJs.year() &&
    // dayjs が返す month は 0~11 なので +1 している
    month === valueDayJs.month() + 1 &&
    day === valueDayJs.date()
  )
}
