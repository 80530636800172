import {
  GqlError,
  GetSessionForPatientQuery,
  GetSessionForPatientDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

export type GetSessionForPatientRes =
  GetSessionForPatientQuery['explanationSession']

const getSessionForPatient = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<GetSessionForPatientRes> => {
  try {
    const res = await graphqlRequest(GetSessionForPatientDocument, {
      explanationSessionUid,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionForPatient = ({
  explanationSessionUid,
  ...option
}: {
  explanationSessionUid: string
} & SWRConfiguration<GetSessionForPatientRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionForPatient(explanationSessionUid),
    () => getSessionForPatient({ explanationSessionUid }),
    option,
  )
}
