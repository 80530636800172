import { useMemo } from 'react'

import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { QuestionnaireStatus } from 'src/modules/entities/questionnaire/entity'
import { RootState } from 'src/modules/reducer'

export const useQuestionnaireDetailPermission = () => {
  const originalQuestionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail.originalQuestionnaire,
  )
  const questionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail,
  )
  const { hasPermission } = usePermission()
  const isDraft = questionnaire?.status === QuestionnaireStatus.Draft
  const isConfirmed = questionnaire?.status === QuestionnaireStatus.Confirmed
  const isReleased = questionnaire?.status === QuestionnaireStatus.Released

  const isEdited = useMemo(() => {
    if (!originalQuestionnaire) return false
    if (originalQuestionnaire.title !== questionnaire.title) return true
    if (originalQuestionnaire.displayName !== questionnaire.displayName)
      return true

    return !equal(originalQuestionnaire.sections, questionnaire.sections)
  }, [originalQuestionnaire, questionnaire])

  const canEdit: boolean = useMemo(() => {
    return isDraft && hasPermission(PERMISSIONS.Questionnaire_Edit)
  }, [hasPermission, isDraft])

  const canSave: boolean = useMemo(() => {
    return isDraft && hasPermission(PERMISSIONS.Questionnaire_Save)
  }, [hasPermission, isDraft])

  const canConfirm: boolean = useMemo(() => {
    return isDraft && hasPermission(PERMISSIONS.Questionnaire_Confirm)
  }, [hasPermission, isDraft])

  const canBackToDraft: boolean = useMemo(() => {
    return isConfirmed && hasPermission(PERMISSIONS.Questionnaire_BackToDraft)
  }, [hasPermission, isConfirmed])

  const canDelete: boolean = useMemo(() => {
    return isDraft && hasPermission(PERMISSIONS.Questionnaire_Delete)
  }, [hasPermission, isDraft])

  const canExport: boolean = useMemo(() => {
    return (
      (isConfirmed || isReleased) &&
      hasPermission(PERMISSIONS.Questionnaire_Export)
    )
  }, [hasPermission, isConfirmed, isReleased])

  return {
    isEdited,
    canEdit,
    canSave,
    canConfirm,
    canBackToDraft,
    canDelete,
    canExport,
  }
}
