import { icfDocumentRevisionStatus, IcfDocumentRevisionStatus } from './entity'

type RevisionStatusTransitionMap = Record<
  IcfDocumentRevisionStatus,
  Record<IcfDocumentRevisionStatus, boolean>
>

const revisionStatusTransitionMap: RevisionStatusTransitionMap = {
  [icfDocumentRevisionStatus.NewDocumentCreated]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: true,
    [icfDocumentRevisionStatus.WaitApproving]: true,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: false,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.Saved]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: true,
    [icfDocumentRevisionStatus.WaitApproving]: true,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: true,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.WaitApproving]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: false,
    [icfDocumentRevisionStatus.WaitApproving]: false,
    [icfDocumentRevisionStatus.Approved]: true,
    [icfDocumentRevisionStatus.Rejected]: true,
    [icfDocumentRevisionStatus.Withdrawn]: true,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: false,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.Approved]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: false,
    [icfDocumentRevisionStatus.WaitApproving]: false,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: false,
    [icfDocumentRevisionStatus.Disabled]: true,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.Rejected]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: true,
    [icfDocumentRevisionStatus.WaitApproving]: true,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: true,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.Withdrawn]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: true,
    [icfDocumentRevisionStatus.WaitApproving]: true,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: true,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.NewRevisionCreated]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: true,
    [icfDocumentRevisionStatus.WaitApproving]: true,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: true,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.NewRevisionCanceled]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: false,
    [icfDocumentRevisionStatus.WaitApproving]: false,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: false,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
  [icfDocumentRevisionStatus.Disabled]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: false,
    [icfDocumentRevisionStatus.WaitApproving]: false,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: false,
    [icfDocumentRevisionStatus.Disabled]: false,
    [icfDocumentRevisionStatus.Enabled]: true,
  },
  [icfDocumentRevisionStatus.Enabled]: {
    [icfDocumentRevisionStatus.NewDocumentCreated]: false,
    [icfDocumentRevisionStatus.Saved]: false,
    [icfDocumentRevisionStatus.WaitApproving]: false,
    [icfDocumentRevisionStatus.Approved]: false,
    [icfDocumentRevisionStatus.Rejected]: false,
    [icfDocumentRevisionStatus.Withdrawn]: false,
    [icfDocumentRevisionStatus.NewRevisionCreated]: false,
    [icfDocumentRevisionStatus.NewRevisionCanceled]: false,
    [icfDocumentRevisionStatus.Disabled]: true,
    [icfDocumentRevisionStatus.Enabled]: false,
  },
}

export const canTransit = (
  from: IcfDocumentRevisionStatus,
  to: IcfDocumentRevisionStatus,
) => revisionStatusTransitionMap[from][to]
