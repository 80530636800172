// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgImportant = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16Z"
    />
    <path
      fill="#fff"
      d="M6.75 4.6c0-.608-.448-1.1-1-1.1s-1 .492-1 1.1v3.3c0 .608.448 1.1 1 1.1s1-.492 1-1.1V4.6ZM5.75 12.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM11.25 4.6c0-.608-.448-1.1-1-1.1s-1 .492-1 1.1v3.3c0 .608.448 1.1 1 1.1s1-.492 1-1.1V4.6ZM10.25 12.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
    />
  </svg>
)
export default SvgImportant
