import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { colors } from 'src/assets/colors'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { useReactHookFormDevTools } from 'src/hooks/use-react-hook-form-dev-tools'
import { Schedule } from 'src/modules/entities/schedule/entity'
import { ScheduleCreateRequest } from 'src/modules/entities/schedule/request'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import * as yup from 'yup'

type Props = {
  errorMessage: string
  requesting: boolean
  onSubmit: (values: ScheduleCreateRequest) => void
  onDelete: () => void
  closePane: () => void
  schedule: Schedule
  canEdit: boolean
}

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  startAfterDays: yup
    .number()
    .typeError('数値を入力してください')
    .min(0, '0以上の数値を入力してください')
    .required('回答日を入力してください'),
  availableFromDays: yup
    .number()
    .typeError('回答可能期間(From): 数値を入力してください')
    .min(0, '回答可能期間(From): 0以上の数値を入力してください')
    .required('回答可能期間(From)を入力してください'),
  availableUpToDays: yup
    .number()
    .typeError('回答可能期間(To): 数値を入力してください')
    .min(0, '回答可能期間(To): 0以上の数値を入力してください')
    .required('回答可能期間(To)を入力してください'),
})

type FormValues = yup.InferType<typeof validationSchema>

export const Edit: React.FC<Props> = props => {
  const { errorMessage, requesting, closePane, canEdit } = props
  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: props.schedule.title,
      startAfterDays: props.schedule.startAfterDays,
      availableFromDays: props.schedule.availableFromDays,
      availableUpToDays: props.schedule.availableUpToDays,
    },
  })
  const { register, formState, control } = methods
  const { isValid, errors } = formState
  const devToolElement = useReactHookFormDevTools(control)

  const onSubmit = (values: FormValues) => {
    if (!isValid) {
      return
    }
    const requestValues: ScheduleCreateRequest = {
      title: values.title,
      startAfterDays: values.startAfterDays,
      availableFromDays: values.availableFromDays,
      availableUpToDays: values.availableUpToDays,
    }
    props.onSubmit(requestValues)
  }

  return (
    <Wrapper>
      <Head>
        <Text fontSize="lg" fontWeight="bold">
          回答日編集
        </Text>
        {canEdit && (
          <Button
            size="S"
            text="回答日を削除"
            buttonType="alert"
            iconName="trash"
            onClick={props.onDelete}
          />
        )}
      </Head>

      <Spacer size={40} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {devToolElement}
        <InputFieldContainer>
          <InputContainer>
            <Label mb={`6px`} required={canEdit}>
              回答日タイトル
            </Label>
            <Input
              width={1}
              placeholder="回答日タイトル"
              disabled={!canEdit}
              {...register('title')}
            />
            {errors.title && (
              <ErrorMessage>回答日タイトルを入力してください</ErrorMessage>
            )}
          </InputContainer>
        </InputFieldContainer>
        <InputFieldContainer>
          <InputContainer>
            <Label mb={`6px`} required={canEdit}>
              回答日
            </Label>
            <InputContentsContainer>
              <InputContentLabel>患者試験開始日から</InputContentLabel>
              <Input
                width={'30%'}
                placeholder="0"
                type="number"
                disabled={!canEdit}
                {...register('startAfterDays')}
              />
              <InputContentLabel>日後</InputContentLabel>
            </InputContentsContainer>
            {errors.startAfterDays && (
              <ErrorMessage>{errors.startAfterDays.message}</ErrorMessage>
            )}
          </InputContainer>
        </InputFieldContainer>
        <InputFieldContainer>
          <InputContainer>
            <Label mb={`6px`} required={canEdit}>
              回答可能期間
            </Label>
            <InputContentsContainer>
              <InputContentLabel>回答日の</InputContentLabel>
              <Input
                width={'30%'}
                placeholder="0"
                type="number"
                disabled={!canEdit}
                {...register('availableFromDays')}
              />
              <InputContentLabel>日前から</InputContentLabel>
              <Input
                width={'30%'}
                placeholder="0"
                type="number"
                disabled={!canEdit}
                {...register('availableUpToDays')}
              />
              <InputContentLabel>日後まで</InputContentLabel>
            </InputContentsContainer>
            {errors.availableFromDays && (
              <ErrorMessage>{errors.availableFromDays.message}</ErrorMessage>
            )}
            {errors.availableUpToDays && (
              <ErrorMessage>{errors.availableUpToDays.message}</ErrorMessage>
            )}
          </InputContainer>
        </InputFieldContainer>
        {canEdit && (
          <BtnContainer>
            <Button
              size="S"
              text="キャンセル"
              buttonType="cancel"
              onClick={closePane}
            ></Button>
            <Button
              size="S"
              text="保存"
              buttonType="important"
              disabled={requesting || !isValid}
              type="submit"
            ></Button>
          </BtnContainer>
        )}
        {errorMessage && <Text color={red[50]}>{props.errorMessage}</Text>}
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InputFieldContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const InputContainer = styled.div<SpaceProps>`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${space}
`

const InputContentsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  justify-content: flex-start;
`

const InputContentLabel = styled.span`
  font-size: 0.75em;
  font-weight: bold;
  margin: 0 10px;
`

const ErrorMessage = styled.div`
  margin-top: 4px;
  font-size: 13px;
  color: ${colors.redDarken5};
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;

  > button:not(:last-child) {
    margin-right: 20px;
  }
`
