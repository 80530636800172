import dayjs from 'dayjs'
import { generateUUID } from 'src/utils/generateUUID'

import {
  icfDocumentTypes,
  IcfDocument,
  IcfDocumentForList,
  icfDocumentRevisionStatus,
  IcfDocumentDetail,
  IcfDocumentRevision,
  IcfDocumentRevisionHistory,
} from './entity'

const icfDocumentFixtureDescription: IcfDocument = {
  uid: 'icfDocumentUid1',
  numberingId: 1,
  type: icfDocumentTypes.Description,
}
const icfDocumentFixtureVideo: IcfDocument = {
  uid: 'icfDocumentUid2',
  numberingId: 2,
  type: icfDocumentTypes.Video,
}
export const icfDocumentFixtureAgreementForm: IcfDocument = {
  uid: 'icfDocumentUid3',
  numberingId: 3,
  type: icfDocumentTypes.AgreementForm,
}
const icfDocumentFixtureCheckUnderstanding: IcfDocument = {
  uid: 'icfDocumentUid4',
  numberingId: 4,
  type: icfDocumentTypes.CheckUnderstanding,
}

export const icfDocumentListFixture: IcfDocumentForList[] = [
  {
    ...icfDocumentFixtureDescription,
    isRequiredPatientSign: false,
    icfDocumentRevisionUid: generateUUID(),
    name: '説明文書',
    version: '1.0',
    status: icfDocumentRevisionStatus.Saved,
    approvedAt: '',
    isRevising: false,
    isLinkedICFDocumentSet: true,
    isRequiredReAgreementConfirmation: false,
  },
  {
    ...icfDocumentFixtureVideo,
    isRequiredPatientSign: false,
    icfDocumentRevisionUid: generateUUID(),
    name: '動画',
    version: '1.0',
    status: icfDocumentRevisionStatus.WaitApproving,
    approvedAt: '',
    isRevising: false,
    isLinkedICFDocumentSet: true,
    isRequiredReAgreementConfirmation: false,
  },
  {
    ...icfDocumentFixtureAgreementForm,
    isRequiredPatientSign: true,
    icfDocumentRevisionUid: generateUUID(),
    name: '同意書',
    version: '1.0',
    status: icfDocumentRevisionStatus.Approved,
    approvedAt: '2022-05-01T12:00:00.000Z',
    isRevising: false,
    isLinkedICFDocumentSet: false,
    isRequiredReAgreementConfirmation: true,
  },
  {
    ...icfDocumentFixtureCheckUnderstanding,
    isRequiredPatientSign: false,
    icfDocumentRevisionUid: generateUUID(),
    name: '理解度チェック',
    version: '1.0',
    status: icfDocumentRevisionStatus.Rejected,
    approvedAt: '',
    isRevising: false,
    isLinkedICFDocumentSet: false,
    isRequiredReAgreementConfirmation: true,
  },
]

export const isRequiredReAgreementConfirmationIcfDocumentListFixture: IcfDocumentForList[] =
  [
    {
      ...icfDocumentFixtureDescription,
      isRequiredPatientSign: false,
      icfDocumentRevisionUid: generateUUID(),
      name: '説明文書',
      version: '1.0',
      status: icfDocumentRevisionStatus.Saved,
      approvedAt: '',
      isRevising: false,
      isLinkedICFDocumentSet: true,
      isRequiredReAgreementConfirmation: true,
    },
    {
      ...icfDocumentFixtureVideo,
      isRequiredPatientSign: false,
      icfDocumentRevisionUid: generateUUID(),
      name: '動画',
      version: '1.0',
      status: icfDocumentRevisionStatus.WaitApproving,
      approvedAt: '',
      isRevising: false,
      isLinkedICFDocumentSet: true,
      isRequiredReAgreementConfirmation: true,
    },
  ]

const icfDocumentRevisionHistories1: IcfDocumentRevisionHistory[] = [
  {
    uid: generateUUID(),
    status: icfDocumentRevisionStatus.Approved,
    operatedAt: '2022-01-01T19:00:00.000Z',
    operatedMemberName: 'sample dr',
    comment: '承認しました。',
  },
  {
    uid: generateUUID(),
    status: icfDocumentRevisionStatus.WaitApproving,
    operatedAt: '2022-01-01T18:00:00.000Z',
    operatedMemberName: 'sample CRC',
    comment: '承認よろしくおねがいします。',
  },
  {
    uid: generateUUID(),
    status: icfDocumentRevisionStatus.Saved,
    operatedAt: '2022-01-01T12:00:00.000Z',
    operatedMemberName: 'sample CRC',
    comment: '一時保存しました。',
  },
]
const icfDocumentRevisionHistories2: IcfDocumentRevisionHistory[] = [
  {
    uid: generateUUID(),
    status: icfDocumentRevisionStatus.Approved,
    operatedAt: '2022-01-01T19:00:00.000Z',
    operatedMemberName: 'sample dr',
    comment: 'version2.0を承認しました。',
  },
  {
    uid: generateUUID(),
    status: icfDocumentRevisionStatus.WaitApproving,
    operatedAt: '2022-01-01T18:00:00.000Z',
    operatedMemberName: 'sample CRC',
    comment: 'version2.0の承認よろしくおねがいします。',
  },
  {
    uid: generateUUID(),
    status: icfDocumentRevisionStatus.Saved,
    operatedAt: '2022-01-01T12:00:00.000Z',
    operatedMemberName: 'sample CRC',
    comment: '一時保存しました。',
  },
]

export const icfDocumentRevisionFixture1: IcfDocumentRevision = {
  uid: generateUUID(),
  icfDocumentUid: icfDocumentFixtureAgreementForm.uid,
  version: '1.0',
  name: '同意書',
  fileName: 'sample.pdf',
  type: icfDocumentTypes.AgreementForm,
  latestStatus: icfDocumentRevisionStatus.Approved,
  approvedAt: dayjs('2022-09-30 12:00').format(),
  isRequiredPatientSign: true,
  signerRoles: [],
  checkRoles: [],
  canCancel: false,
  dsTemplateId: 'docuSignTemplate1',
  histories: icfDocumentRevisionHistories1,
}
export const icfDocumentRevisionFixture2: IcfDocumentRevision = {
  uid: generateUUID(),
  icfDocumentUid: icfDocumentFixtureAgreementForm.uid,
  version: '2.0',
  name: '同意書',
  fileName: 'sample.pdf',
  type: icfDocumentTypes.AgreementForm,
  latestStatus: icfDocumentRevisionStatus.Approved,
  approvedAt: '',
  isRequiredPatientSign: true,
  signerRoles: [],
  checkRoles: [],
  canCancel: true,
  dsTemplateId: 'docuSignTemplate2',
  histories: icfDocumentRevisionHistories2,
}

export const icfDocumentDetailFixture: IcfDocumentDetail = {
  ...icfDocumentFixtureAgreementForm,
  canDelete: false,
  latestRevisionUid: icfDocumentRevisionFixture2.uid,
  revisions: [
    {
      uid: icfDocumentRevisionFixture1.uid,
      version: icfDocumentRevisionFixture1.version,
      status: icfDocumentRevisionFixture1.latestStatus,
      approvedAt: icfDocumentRevisionFixture1.approvedAt,
    },
    {
      uid: icfDocumentRevisionFixture2.uid,
      version: icfDocumentRevisionFixture2.version,
      status: icfDocumentRevisionFixture2.latestStatus,
      approvedAt: icfDocumentRevisionFixture2.approvedAt,
    },
  ],
  sets: [],
}
