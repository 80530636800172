import { ReactNode } from 'react'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { ExplanationRevisionAbout } from 'src/features/explanation/types'
import { getFullName } from 'src/utils/getFullName'

import { SPExplanationRoomHeader } from '../SPExplanationRoomHeader/SPExplanationRoomHeader'

type Props = {
  headerTitle: String
  about: ExplanationRevisionAbout
  children: ReactNode
}

export const SPPatientLobbyLayout: React.FC<Props> = ({
  headerTitle,
  about,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Stack spacing="0" height="100svh">
      <InformationModal isOpen={isOpen} about={about} onClose={onClose} />
      <SPExplanationRoomHeader title={headerTitle} onClickMenu={onOpen} />
      {children}
    </Stack>
  )
}

type ModalProps = {
  isOpen: boolean
  about: ExplanationRevisionAbout
  onClose: () => void
}
const InformationModal: React.FC<ModalProps> = ({ isOpen, about, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px="4" m="0">
          <Stack spacing="8">
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                {about.trialName}
              </Text>
            </Box>
            <Stack spacing="1.5">
              <Text fontSize="lg" fontWeight="bold" as="h2">
                候補ID
              </Text>
              <Text>{about.candidateID}</Text>
            </Stack>
            <Stack spacing="1.5">
              <Text fontSize="lg" fontWeight="bold" as="h2">
                説明担当者
              </Text>
              <Box>
                <Text fontWeight="bold" as="h3">
                  {about.hospitalName}
                </Text>
                <Stack spacing="1" pl="4" mt="2">
                  {about.members
                    .sort((a, b) => a.memberIndex - b.memberIndex)
                    .map(m => (
                      <MemberLabel
                        key={m.memberIndex}
                        role={m.roleName as 'Dr' | 'CRC'}
                        displayName={getFullName(m)}
                      />
                    ))}
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
