import React from 'react'

import { useSelector } from 'react-redux'
import { colors } from 'src/assets/colors'
import { Pagination } from 'src/components/base/pagination/pagination'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import { PatientTable } from './components/table'
import { getPatientList, getTotalCount } from './selector'
import { useFetch } from './use-fetch'
import { usePaginatePatient } from './use-paginate-patient'

type Props = {
  trialUid: string
  perPage: number
}

export const List: React.FC<Props> = props => {
  const { trialUid, perPage } = props

  const patients = useSelector(getPatientList)
  const totalCount = useSelector(getTotalCount)

  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    handlers: { onClickNext, onClickBack, onClickTop, onClickEnd },
  } = usePaginatePatient({ totalCount, perPage })

  const { fetched } = useFetch({ trialUid, per: perPage })

  if (!fetched) {
    return null
  }

  if (patients.length === 0) {
    return <NonPatientText>登録している患者データはありません。</NonPatientText>
  }

  return (
    <Container>
      <PaginationContainer>
        <Pagination
          totalCount={totalCount}
          perPage={perPage}
          currentPage={currentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          onClickNext={onClickNext}
          onClickBack={onClickBack}
          onClickTop={onClickTop}
          onClickEnd={onClickEnd}
        />
      </PaginationContainer>

      <Spacer size={8} />

      <TableContainer>
        <PatientTable patients={patients} trialUid={trialUid} />
      </TableContainer>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const NonPatientText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 200px 0;

  color: ${colors.grayLighten6};
`

const TableContainer = styled.div`
  flex: 1;
`
