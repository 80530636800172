import { definitions } from 'src/lib/api-client/schema.gen'
import { assertNever } from 'src/utils/assertNever'

export const otpVerificationMessage = (
  result: definitions['output.SignAuthVerificationResult'] | undefined,
) => {
  switch (result) {
    case 'Verified':
    case undefined:
      return ''
    case 'Expired':
      return '認証コードが無効です。\n認証コードを再送してください。'
    case 'Incorrect':
      return '認証コードに誤りがあります。\n再度ご入力いただくか、認証コードを再送してください。'
    default:
      return assertNever(result)
  }
}
