import React from 'react'

import { Modal } from 'src/components/base/modal/modal'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import styled, { keyframes } from 'styled-components'

import { AddForm, FormValues } from '../add-form'
import { LookupUserForm } from '../lookup-user-form'
import { LookupUser } from '../request'

type Props = {
  selectedTrial: SelectedTrial
  handlers: {
    onSubmit: (values: FormValues) => void
    onClose: () => void
  }
  user: LookupUser | undefined
  email: string
  lookup: ({ email }: { email: string }) => void
  lookupStatus: {
    requesting: boolean
    errorMessage: string
    lookupDone: boolean
  }
}

export const AddModal: React.FC<Props> = props => {
  const { selectedTrial, user, email, lookup } = props
  const { requesting, errorMessage, lookupDone } = props.lookupStatus

  return (
    <Modal onClose={props.handlers.onClose} size="L">
      {!lookupDone ? (
        <LookupUserForm
          onSubmit={lookup}
          onClose={props.handlers.onClose}
          selectedTrial={selectedTrial}
          requesting={requesting}
          errorMessage={errorMessage}
        />
      ) : (
        <AddFormWrapper>
          <div>
            <AddForm
              onSubmit={props.handlers.onSubmit}
              onClose={props.handlers.onClose}
              selectedTrial={selectedTrial}
              email={email}
              existingUser={user}
            />
          </div>
        </AddFormWrapper>
      )}
    </Modal>
  )
}

const slidein = keyframes`
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const AddFormWrapper = styled.div`
  > div {
    animation: ${slidein} 0.8s;
  }
`
