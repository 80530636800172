import React from 'react'

import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'

type Props = {
  videoStreamUrl: string
}
export const Video: React.FC<Props> = ({ videoStreamUrl }) => {
  return <HlsVideoPlayer url={videoStreamUrl} />
}
