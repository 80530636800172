import { Badge, BadgeProps } from '@chakra-ui/react'
import { CriteriaStatus } from 'src/lib/gql-client'

type Props = {
  status: CriteriaStatus
}

const statusBadgeProps: Record<
  Exclude<CriteriaStatus, 'Deleted'>,
  BadgeProps
> = {
  Created: {
    bg: 'blue.200',
    color: 'blue.700',
    children: '新規',
  },
  Saved: {
    bg: 'yellow.200',
    color: 'yellow.700',
    children: '一時保存',
  },
  Published: {
    bg: 'green.200',
    color: 'green.700',
    children: '公開',
  },
  Disabled: {
    bg: 'gray.100',
    color: 'gray.300',
    children: '無効',
  },
}

export const CriteriaStatusBadge: React.FC<Props> = ({ status }) => {
  if (status === 'Deleted') {
    return null
  }

  const props = statusBadgeProps[status]

  return (
    <Badge
      opacity="1"
      w="44px"
      h="18px"
      display="grid"
      alignContent="center"
      fontSize="10px"
      lineHeight="18px"
      textAlign="center"
      fontWeight="bold"
      px="unset"
      borderRadius="2px"
      {...props}
    />
  )
}
