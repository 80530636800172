// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgExpand = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={30} height={30} x={1} y={1} fill="#fff" rx={15} />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeWidth={2}
      d="m7 7 4.286 4.414L15 15M17 17l4.286 4.414L25 25"
    />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeWidth={2}
      d="M25 19v5.5a.5.5 0 0 1-.5.5H19M7 13V7.5a.5.5 0 0 1 .5-.5H13"
    />
    <circle cx={16} cy={16} r={15.5} stroke={props.color ?? 'currentColor'} />
  </svg>
)
export default SvgExpand
