import React from 'react'

import { colors } from 'src/assets/colors'
import { DataType } from 'src/modules/entities/small-item/entity'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { gray } from '../base/color/palette'

type Props = {
  className?: string // FIXME: classNameは受け取りたくない
  bold?: boolean
  // EDC連携バッジを表示するためのprops。このコンポーネントでは持ちたくない。
  category?: DataType
  // 必須や任意のバッジを表示するためのprops。このコンポーネントでは持ちたくない。
  required?: boolean
  optional?: boolean
  icon?: string
  children: React.ReactNode
} & React.LabelHTMLAttributes<HTMLLabelElement>

const Component: React.FC<Props> = ({
  className,
  bold,
  category,
  required,
  optional,
  icon,
  children,
  ...labelProps
}) => (
  <div className={className}>
    <label className="title" {...labelProps}>
      {children}
    </label>
    {required && <div className="label required">必須</div>}
    {optional && <div className="label optional">任意</div>}
    {category === DataType.Clinical && (
      <div className="label category">EDC連携対象</div>
    )}
  </div>
)

export const Label = styled(Component)<SpaceProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;

  ${space}

  > .title {
    font-size: 16px;
    line-height: 23px;
    color: ${gray[100]};
    font-weight: ${p => (p.bold ? 'bold' : 'normal')};
    display: flex;
    align-items: center;
    flex-direction: row;
    word-break: break-all;

    ${({ icon }) =>
      icon &&
      `
        :after {
          content: url(${icon});
          padding-left: 6px;
          position: relative;
          top: 3px;
        }
      `}
  }

  // FIXME: 付随する部分の定義は消したい。classNameも適切か。
  > .label {
    margin-left: 14px;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    padding: 4px 8px;

    &.required {
      background: ${colors.redLight};
      color: ${colors.red};
    }

    &.optional {
      background: ${colors.greenLight};
      color: ${colors.green};
    }
    &.category {
      background: ${colors.bgGray};
      color: ${colors.grayLighten6};
    }
  }
`
