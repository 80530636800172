import { HttpResponse } from 'msw'
import {
  GetExplanationTrialMembersDocument,
  GetExplanationTrialMembersQuery,
  GetExplanationTrialMembersQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationTrialMembersHandler = graphQLHandlerFactory<
  GetExplanationTrialMembersQuery,
  GetExplanationTrialMembersQueryVariables
>('query', GetExplanationTrialMembersDocument, () => {
  return HttpResponse.json({
    data: {
      latestExplanationRevisionsTrialMembers: [],
    },
  })
})
