import { createSelector } from 'reselect'
import { SmallItem } from 'src/modules/entities/small-item/entity'
import { RootState } from 'src/modules/reducer'
import { getState as getRootState } from 'src/modules/selector'

import { State } from './redux'

export const getState = createSelector(
  getRootState,
  state => state.templateDetail,
)

export const getSmallItems = createSelector<
  RootState,
  { mediumItemUid: string },
  { mediumItemUid: string },
  State,
  SmallItem[]
>(
  (_state, props) => props,
  getState,
  ({ mediumItemUid }, state) => {
    // TODO: medium item も map で持つ？
    const mediumItem = state.mediumItems.find(mi => mi.uid === mediumItemUid)
    if (!mediumItem) return []

    const smallItems = Object.values(mediumItem.smallItemMap)
      .filter(si => si.parentUid === null)
      .sort((a, b) => a.index - b.index)

    return smallItems
  },
)
