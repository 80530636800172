import { useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Button,
  ModalFooter,
  Text,
  Stack,
} from '@chakra-ui/react'
import { NumberOnlyInput } from 'src/components/NumberOnlyInput/NumberOnlyInput'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import {
  useRequestPinSettingWithUpdatePatientMobileNumber,
  useSendTestSMS,
} from '../../api'
import { useRequestPinSetting } from '../../api/requestPinSetting'

type Props = {
  isOpen: boolean
  buttonColorScheme?: 'blue' | 'green'
  explanationRevisionUid: string
  patientUid: string
  fetchedPhoneNumber: string | undefined
  onSubmit: () => void
  onCancel: () => void
}

export const SubmitSMSPinSettingModal: React.FC<Props> = ({
  isOpen,
  buttonColorScheme,
  explanationRevisionUid,
  patientUid,
  fetchedPhoneNumber,
  onSubmit,
  onCancel,
}) => {
  const [inputPhoneNumber, setInputPhoneNumber] = useState<string | undefined>(
    fetchedPhoneNumber,
  )
  const toast = useMirohaToast()

  const {
    request: requestPinSettingWithPhoneNumberUpdate,
    requesting: requestingBulkApi,
  } = useRequestPinSettingWithUpdatePatientMobileNumber({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '患者携帯電話宛に暗証番号設定用URLを送信しました',
      })
      onSubmit()
    },
  })

  const { request: requestPinSetting, requesting: requestingPinOnlyApi } =
    useRequestPinSetting({
      onSuccess: () => {
        toast({
          status: 'success',
          title: '患者携帯電話宛に暗証番号設定用URLを送信しました。',
        })
        onSubmit()
      },
    })

  const handleSubmit = async () => {
    if (
      fetchedPhoneNumber !== undefined &&
      inputPhoneNumber === fetchedPhoneNumber
    ) {
      await requestPinSetting({
        explanationRevisionUid,
        method: 'SMS',
      })
      return
    }

    if (inputPhoneNumber !== undefined) {
      await requestPinSettingWithPhoneNumberUpdate({
        explanationPatientUid: patientUid,
        explanationRevisionUid,
        mobileNumber: inputPhoneNumber,
      })
    }
  }

  const { request: sendTestSMS } = useSendTestSMS({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'テストSMSを送信しました',
      })
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>患者携帯電話へ設定リンクを送信</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Text>患者携帯電話宛にSMSで暗証番号設定リンクを送信します。</Text>
            <Stack spacing="1">
              <HStack spacing="2" marginBottom={2}>
                <NumberOnlyInput
                  w="160px"
                  value={inputPhoneNumber}
                  onChange={setInputPhoneNumber}
                  placeholder="例) 08011223344"
                />
                <Button
                  variant="text"
                  isDisabled={!inputPhoneNumber}
                  onClick={async () => {
                    if (!inputPhoneNumber) return
                    await sendTestSMS({
                      phoneNumber: inputPhoneNumber,
                    })
                  }}
                >
                  テスト送信
                </Button>
              </HStack>
              {!fetchedPhoneNumber && (
                <WarningMessage message="患者電話番号の入力が必須です。" />
              )}
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="戻る" />
          <Button
            colorScheme={buttonColorScheme}
            onClick={handleSubmit}
            isDisabled={
              !inputPhoneNumber || requestingBulkApi || requestingPinOnlyApi
            }
          >
            送信する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
