import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { CreateModal } from './create-modal'
import { getSelectedTrial } from '../../../../../../../entities/account/selector'
import { actions } from '../../../../../../../entities/template-folder/redux'
import { create } from '../../../../../../../entities/template-folder/request'
import { useRequestState } from '../../../../../../../server/use-request-state'

type Props = {
  v2: boolean
  onClose: () => void
}

const useSubmit = () => {
  const trialUid = useSelector(getSelectedTrial)!.uid

  const { requesting, requestDone, requestStarted } = useRequestState()
  const dispatch = useDispatch()
  const toast = useMirohaToast()
  const request = useCallback(
    async ({ name, isFollowup }: { name: string; isFollowup: boolean }) => {
      try {
        requestStarted()
        const res = await create({ trialUid, name, isFollowup })
        dispatch(actions.upsert(res))
        toast({
          status: 'success',
          title: 'フォルダを作成しました',
        })
        requestDone()
      } catch (error) {
        toast({
          status: 'error',
          title: error.message,
        })
      }
    },
    [toast, dispatch, requestDone, requestStarted, trialUid],
  )

  return { request, requesting }
}

export const ModalContainer: React.FC<Props> = props => {
  const { request, requesting } = useSubmit()
  const onSubmit = useCallback(
    async ({ name, isFollowup }: { name: string; isFollowup: boolean }) => {
      await request({ name, isFollowup })
      props.onClose()
    },
    [props, request],
  )

  return (
    <CreateModal
      v2={props.v2}
      isOpen={true}
      onClose={props.onClose}
      onSubmit={onSubmit}
      requesting={requesting}
    />
  )
}
