import { gray } from 'src/components/base/color/palette'
import styled from 'styled-components'

export const Value = styled.div`
  padding: 6px 0;
  font-size: 14px;
  min-height: 23px;
  border-bottom: 1px solid ${gray[40]};
  color: ${gray[100]};
  white-space: pre-wrap;
  word-break: break-all;
`
