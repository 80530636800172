import { useState } from 'react'

import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { GqlError } from 'src/lib/gql-client'
import useSWR from 'swr'

import {
  GeneratePatientVideoCallTokenReturn,
  generatePatientVideoCallToken,
} from '../api/generatePatientVideoCallToken'

export const usePatientVideoCallJwtToken = ({
  patientVideoCallUid,
}: {
  patientVideoCallUid: string
}) => {
  const [jwtToken, setJwtToken] = useState<string | null>(null)

  const toast = useMirohaToast()

  useSWR<GeneratePatientVideoCallTokenReturn, GqlError>(
    !!jwtToken ? null : { patientVideoCallUid },
    generatePatientVideoCallToken,
    {
      onSuccess: data => {
        setJwtToken(data.jwtToken)
      },
      onError: error => {
        toast({
          status: 'error',
          title: error.message,
        })
      },
      errorRetryCount: 1,
    },
  )

  return {
    jwtToken,
  }
}
