import { useState, useCallback, useRef, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useRequestState } from 'src/modules/server/use-request-state'

import { actions } from '../redux'
import {
  uploadFolderTemplates,
  uploadSection,
  uploadField,
  uploadChoice,
} from '../request'
import { getState } from '../selector'
import { UploadTask } from '../task'

export const useUploadCsv = ({
  trialUid,
  task,
}: {
  trialUid: string
  task: UploadTask
}) => {
  const [currentFile, setFile] = useState<File | null>(null)
  const { folderTemplates } = useSelector(getState)
  const crfFolderTemplateUid = folderTemplates?.crfFolderTemplateUid

  //エラー発生後に「戻る」を押下されたら(taskが変化したら)エラーを消す処理を行うため、errorMessageはuseRequestStateとは別で管理する。
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setErrorMessage('')
  }, [task.order])

  const { requestStarted, requestDone, requesting } = useRequestState()
  const dispatch = useDispatch()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const resetFile = () => {
    if (!fileInputRef.current) return

    fileInputRef.current.value = ''
    setFile(null)
  }

  const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      setFile(null)
      return
    }

    setFile(e.target.files[0])
  }, [])

  const uploadCsv = useCallback(async () => {
    if (!currentFile) return

    try {
      requestStarted()
      setErrorMessage('')

      const params = new FormData()
      params.append('file', currentFile)

      switch (task.name) {
        case 'template':
          const folderTemplates = await uploadFolderTemplates({
            trialUid,
            params,
          })
          dispatch(
            actions.uploadFolderTemplates({
              folderTemplates: folderTemplates,
              fileName: currentFile.name,
            }),
          )
          break

        case 'section':
          if (!crfFolderTemplateUid) return

          const sections = await uploadSection({
            trialUid,
            crfFolderTemplateUid,
            params,
          })
          dispatch(
            actions.uploadSection({
              sections: sections,
              fileName: currentFile.name,
            }),
          )
          break

        case 'field':
          if (!crfFolderTemplateUid) return

          await uploadField({
            trialUid,
            crfFolderTemplateUid,
            params,
          })
          dispatch(actions.uploadField({ fileName: currentFile.name }))
          break

        case 'choice':
          if (!crfFolderTemplateUid) return

          await uploadChoice({
            trialUid,
            crfFolderTemplateUid,
            params,
          })
          dispatch(actions.uploadChoice({ fileName: currentFile.name }))
          break

        default:
          const n: never = task.name
          console.error(n)
      }
    } catch (error) {
      setErrorMessage(error.message)
      throw error
    } finally {
      resetFile()
      requestDone()
    }
  }, [
    trialUid,
    currentFile,
    requestStarted,
    requestDone,
    dispatch,
    task,
    crfFolderTemplateUid,
  ])

  return {
    fileInputRef,
    currentFile,
    onChangeFile,
    uploadCsv,
    errorMessage,
    requesting,
  }
}
