import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { BrowseStickyDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const browseSticky = async ({ stickyUid }: { stickyUid: string }) => {
  try {
    await graphqlRequest(BrowseStickyDocument, { stickyUid })
  } catch (error) {
    throw error
  }
}

export const useBrowseSticky = (option?: UseMutationOption<void, GqlError>) => {
  return useMutation(
    ({ stickyUid }: { stickyUid: string }) => browseSticky({ stickyUid }),
    option,
  )
}
