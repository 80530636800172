import { MediumItemAnswer } from 'src/modules/entities/medium-item-answer/entity'
import { createLogline } from 'src/modules/entities/medium-item-answer/fixture'
import {
  MediumItemAnswerResponse,
  parse as parseMediumItemAnswer,
} from 'src/modules/entities/medium-item-answer/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const create = async ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemUid: string
}): Promise<MediumItemAnswer> => {
  if (IS_MOCK_MODE) {
    //ログラインを含むワークシートにて２つのログラインfixtureを用意している。
    return createLogline(3)
  }

  const res = await requestPost<MediumItemAnswerResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/loglines?medium_item_uid=${mediumItemUid}`,
    params: {},
  })

  return parseMediumItemAnswer(res.data)
}
