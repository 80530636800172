import { Member } from 'src/features/member/types'
import { TrialHospital } from 'src/features/trial/types'
import { castUid } from 'src/utils/brandedUid'

import { ExplanationFormSchema } from '../schema/explanationFormSchema'
import { CreateReAgreementExplanationInput } from '../types'

export const schemaToCreateReAgreementInput = (
  schema: ExplanationFormSchema,
): CreateReAgreementExplanationInput => {
  return {
    scheduledAt: schema.type !== 'InPerson' ? schema.scheduledAt : undefined,
    notifyToPatient:
      schema.type === 'Remote' && !!schema.scheduledAt
        ? schema.notifyToPatient
        : undefined,
    type: schema.type,
    withRepresentative: schema.representative === 'with',
    partnerTrialHospitalUid: schema.partnerTrialHospitalUid
      ? castUid<TrialHospital>(schema.partnerTrialHospitalUid)
      : undefined,
    members: schema.members.map(member => ({
      trialHospitalUid: castUid<TrialHospital>(member.trialHospitalUid),
      memberUid: castUid<Member>(member.member.uid),
    })),
    docSets: schema.docSetUids.map((uid, index) => ({
      uid,
      index,
    })),
    candidateId: schema.candidateId,
    expDiseaseId: schema.diseaseId,
    email: schema.email,
    phoneNumber: schema.phoneNumber,
  }
}
