import { ReactNode } from 'react'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { useTrial } from 'src/features/explanation/api/getTrial'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useSessionForPatientSidebar } from '../../api'
import { useUpdateRevisionStatus } from '../../context/ExplanationRoomEvent'
import { PatientSessionChecker } from '../PatientSessionChecker/PatientSessionChecker'
import { SPExplanationRoomHeader } from '../SPExplanationRoomHeader/SPExplanationRoomHeader'

type Props = {
  headerTitle?: String
  trialUid: string
  sessionUid: string
  children: ReactNode
}

export const SPExplanationRoomLayout: React.FC<Props> = ({
  headerTitle,
  trialUid,
  sessionUid,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <PatientSessionChecker sessionUid={sessionUid}>
      <Stack spacing="0" height="100svh">
        <InformationModal
          isOpen={isOpen}
          trialUid={trialUid}
          sessionUid={sessionUid}
          onClose={onClose}
        />
        <SPExplanationRoomHeader title={headerTitle} onClickMenu={onOpen} />
        {children}
      </Stack>
    </PatientSessionChecker>
  )
}

type ModalProps = {
  isOpen: boolean
  trialUid: string
  sessionUid: string
  onClose: () => void
}
const InformationModal: React.FC<ModalProps> = ({
  isOpen,
  trialUid,
  sessionUid,
  onClose,
}) => {
  // PC向けのサイドバーと同じ情報を表示
  const { data: session, mutate: mutateSession } = useSessionForPatientSidebar({
    explanationSessionUid: sessionUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  useUpdateRevisionStatus({
    listener: async () => {
      await mutateSession()
    },
  })

  const { data: trial } = useTrial({
    trialUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  if (!session || !trial) return null

  const {
    startedAt,
    explanationRevision: { explanation },
    members,
  } = session

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px="4" m="0">
          <Stack spacing="8">
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                {trial.name}
              </Text>
            </Box>

            <Stack spacing="1.5">
              <Text fontSize="lg" fontWeight="bold" as="h2">
                説明開始時刻
              </Text>
              <Text>{formatDate(startedAt, 'YYYY/MM/DD (ddd) HH:mm:ss')}</Text>
            </Stack>

            <Stack spacing="1.5">
              <Text fontSize="lg" fontWeight="bold" as="h2">
                候補ID
              </Text>
              <Text>{explanation.patient.candidateId}</Text>
            </Stack>
            <Stack spacing="1.5">
              <Text fontSize="lg" fontWeight="bold" as="h2">
                説明担当者
              </Text>
              <Box>
                <Text fontWeight="bold" as="h3">
                  {explanation.trialHospital.hospital.name}
                </Text>
                <Stack spacing="1" ml="4">
                  {members.map(m => (
                    <MemberLabel
                      key={m.trialMember.uid}
                      role={m.trialMember.role}
                      displayName={getFullName(m.trialMember.user)}
                      isPartner={m.isPartner}
                    />
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
