import React, { useMemo } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { gray, white } from 'src/components/base/color/palette'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { useReactHookFormDevTools } from 'src/hooks/use-react-hook-form-dev-tools'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import { Schedule } from 'src/modules/entities/schedule/entity'
import styled from 'styled-components'

import { QuestionnaireScheduleTable } from './questionnaire-schedule-table'
import { ReleaseButton } from './release/release-button'
import { SaveButton } from './save/save-button'
import { getListRoute } from '../routes'

type Props = {
  trialUid: string
  questionnaires: Pick<
    Questionnaire,
    'uid' | 'title' | 'status' | 'scheduleUids'
  >[]
  schedules: Schedule[]
  canEditSchedule: boolean
  showAddButton: boolean
  showSaveButton: boolean
  showReleaseButton: boolean
  onSave: (data: FormValues) => void
}

export type Form = {
  scheduleUids: Record<string, boolean>
}

export type FormValues = {
  questionnaires: Record<string, Form>
}

export const SchedulePage: React.FC<Props> = ({
  trialUid,
  questionnaires,
  schedules,
  canEditSchedule,
  showAddButton,
  showSaveButton,
  showReleaseButton,
  onSave,
}) => {
  const defaultValues: FormValues = {
    questionnaires: questionnaires.reduce(
      (acc, questionnaire) => {
        acc[questionnaire.uid] = {
          scheduleUids: schedules.reduce(
            (acc, schedule) => {
              acc[schedule.uid] = questionnaire.scheduleUids.includes(
                schedule.uid,
              )
              return acc
            },
            {} as Record<string, boolean>,
          ),
        }
        return acc
      },
      {} as Record<string, Form>,
    ),
  }
  const methods = useForm<FormValues>({ defaultValues })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  const breadcrumbParams: BreadcrumbParam[] = [
    { label: '質問票', isLink: true, path: getListRoute(trialUid) },
    { label: 'スケジュール登録', isLink: false },
  ]

  const isQuestionnaireExisted = useMemo(() => {
    return questionnaires.length >= 1
  }, [questionnaires.length])

  const devToolElement = useReactHookFormDevTools(control)

  return (
    <FormProvider {...methods}>
      {devToolElement}
      <PageContainer>
        <ContentContainer>
          <HeadContainer>
            <Breadcrumb breadcrumbParams={breadcrumbParams} />
            <ButtonContainer>
              {showSaveButton && (
                <SaveButton
                  onSave={handleSubmit(data => {
                    onSave(data)
                    reset(data, { keepDirty: false })
                  })}
                  disabled={!isQuestionnaireExisted || !isDirty}
                />
              )}
              {showReleaseButton && (
                <ReleaseButton disabled={!isQuestionnaireExisted || isDirty} />
              )}
            </ButtonContainer>
          </HeadContainer>

          <TableContainer>
            <QuestionnaireScheduleTable
              questionnaires={questionnaires}
              schedules={schedules}
              canEditSchedule={canEditSchedule}
              showAddButton={showAddButton}
            />
          </TableContainer>
          {!isQuestionnaireExisted && (
            <NonQuestionnaireText>
              登録しているスケジュールデータはありません。
            </NonQuestionnaireText>
          )}
        </ContentContainer>
      </PageContainer>
    </FormProvider>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 28px;
  box-sizing: border-box;
  min-height: 55px;
  background: ${white};
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > button {
    display: flex;
    align-items: center;
    margin-right: 20px;

    > img {
      margin-right: 5px;
    }
  }
`

const NonQuestionnaireText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 200px 0;

  color: ${gray[40]};
`

const TableContainer = styled.div`
  box-sizing: border-box;
  padding-bottom: 30px;
`
