import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { actions as flashActions } from 'src/modules/flash/redux'

import { Questionnaire } from './entity'
import {
  QuestionnaireListRequest,
  fetchList,
  fetch,
  update,
  QuestionnaireUpdateRequest,
} from './request'

export type State = {
  byId: Record<string, Questionnaire>
}

const initialState: State = {
  byId: {},
}

export const fetchQuestionnaireList = createAsyncThunk(
  'questionnaire/fetchList',
  async ({
    trialUid,
    params,
  }: {
    trialUid: string
    params: QuestionnaireListRequest
  }): Promise<Questionnaire[]> => {
    return await fetchList({ trialUid, params })
  },
)

export const fetchQuestionnaire = createAsyncThunk(
  'questionnaire/fetch',
  async ({
    trialUid,
    questionnaireUid,
  }: {
    trialUid: string
    questionnaireUid: string
  }): Promise<Questionnaire> => {
    return await fetch({ trialUid, questionnaireUid })
  },
)

export const updateQuestionnaire = createAsyncThunk(
  'questionnaire/update',
  async (
    {
      trialUid,
      questionnaireUid,
      params,
    }: {
      trialUid: string
      questionnaireUid: string
      params: QuestionnaireUpdateRequest
    },
    thunkAPI,
  ) => {
    try {
      const res = await update({
        trialUid,
        questionnaireUid,
        params,
      })
      thunkAPI.dispatch(
        flashActions.showSuccess({ message: '一時保存しました。' }),
      )
      return res
    } catch (e) {
      thunkAPI.dispatch(flashActions.showError({ message: e?.message }))
      throw e
    }
  },
)

const slice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    upsert: (state: State, action: PayloadAction<Questionnaire>) => {
      const questionnaire = action.payload
      state.byId[questionnaire.uid] = questionnaire
    },
    delete: (state: State, action: PayloadAction<string>) => {
      const questionnaireUid = action.payload
      delete state.byId[questionnaireUid]
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchQuestionnaireList.fulfilled, (state, action) => {
      if (!action.payload) return
      action.payload.forEach(q => {
        state.byId[q.uid] = q
      })
    })
    builder.addCase(fetchQuestionnaire.fulfilled, (state, action) => {
      const questionnaire = action.payload
      if (!questionnaire) return

      state.byId[questionnaire.uid] = questionnaire
    })
    builder.addCase(updateQuestionnaire.fulfilled, (state, action) => {
      const questionnaire = action.payload
      if (!questionnaire) return

      state.byId[questionnaire.uid] = questionnaire
    })
  },
})

export const actions = slice.actions
export const reducer = slice.reducer
