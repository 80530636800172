import React, { useCallback } from 'react'

import { Text } from '@chakra-ui/react'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'

import { useSubmitDelete } from './use-submit-delete'

type Props = {
  onClose: () => void
}

export const DeleteContainer: React.FC<Props> = ({ onClose }) => {
  const { request, requesting, errorMessage } = useSubmitDelete()

  const onSubmit = useCallback(async () => {
    await request()
    onClose()
  }, [onClose, request])

  return (
    <SubmitModal
      title={'質問票を削除する'}
      submitText={'削除'}
      errorMessage={errorMessage || ''}
      requesting={requesting}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Text textAlign="center">質問票を削除しますか？</Text>
      <Spacer size={30} />
    </SubmitModal>
  )
}
