import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import {
  stringToPage,
  stringToPatientRelatedHospitalType,
  stringToPatientSortKey,
  stringToPatientStatusesArray,
  stringToSortOrder,
} from 'src/features/explanation/utils/queryStringToProperty'
import { useLocalStorage } from 'src/hooks/use-local-storage'
import {
  PatientsSortKey,
  PatientStatus,
  SearchPatientsRelatedTrialHospitalType,
  SortOrder,
} from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'

type PaginationInput = { type: 'pagination'; value: number }
type SortKeyInput = { type: 'sortKey'; value: PatientsSortKey }
type SortOrderInput = { type: 'sortOrder'; value: SortOrder }
type SearchInput = { type: 'search'; value: string }
type StatusesInput = { type: 'statuses'; value: PatientStatus[] }
type TrialHospitalInput = { type: 'trialHospital'; value: string | undefined }
type RelatedHospitalInput = {
  type: 'relatedHospital'
  value: RelatedHospitalType
}
type RelatedHospitalType =
  | {
      type: 'WithUid'
      value: string
    }
  | {
      type: 'OnlyNotSet'
    }
  | {
      type: 'Any'
    }

type GetQueryParamInput =
  | PaginationInput
  | SortKeyInput
  | SortOrderInput
  | SearchInput
  | StatusesInput
  | TrialHospitalInput
  | RelatedHospitalInput

const queryParamKeys = {
  searchQuery: 'q',
  statuses: 'statuses',
  trialHospitalUid: 'th',
  relatedHospitalUid: 'rh',
  relatedHospitalType: 'rht',
  page: 'page',
  sortOrder: 'order',
  sortKey: 'key',
} as const

type PatientsQueryParamValue = Partial<
  Record<keyof typeof queryParamKeys, string>
>

type QueryParamObject = {
  page: number
  sortKey: PatientsSortKey
  sortOrder: SortOrder
  statuses: PatientStatus[]
  trialHospitalUid: string | undefined
  relatedHospitalUid: string | undefined
  relatedHospitalType: SearchPatientsRelatedTrialHospitalType
  searchQuery: string
}

const PATIENT_QUERY_STORAGE_KEY = 'patientQuery'

export const usePatientQueryParams = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const query = useMemo(() => new URLSearchParams(location.search), [location])

  // フリーワード入力フォームの値をclearするため
  const queryInputRef = useRef<HTMLInputElement | null>(null)

  const {
    data: storedPatientsQueryParamValue,
    setData: setStoredPatientsQueryParamValue,
  } = useLocalStorage<PatientsQueryParamValue>(PATIENT_QUERY_STORAGE_KEY)

  // 実際に表示中のクエリから取得できる値
  const patientsQueryParamValue: PatientsQueryParamValue = useMemo(
    () => ({
      page: query.get(queryParamKeys.page) ?? '1',
      sortOrder: query.get(queryParamKeys.sortOrder) ?? 'Desc',
      sortKey: query.get(queryParamKeys.sortKey) ?? 'DiseaseUID',
      searchQuery: query.get(queryParamKeys.searchQuery) ?? undefined,
      statuses: query.get(queryParamKeys.statuses) ?? undefined,
      trialHospitalUid: query.get(queryParamKeys.trialHospitalUid) ?? undefined,
      relatedHospitalUid:
        query.get(queryParamKeys.relatedHospitalUid) ?? undefined,
      relatedHospitalType:
        query.get(queryParamKeys.relatedHospitalType) ?? 'Any',
    }),
    [query],
  )

  const addSearchParams = useCallback(
    (value: PatientsQueryParamValue) => {
      const search = generateSearchParams(value)
      navigate({ search })
    },
    [navigate],
  )

  const updateQuery = useCallback(
    (value: PatientsQueryParamValue) => {
      if (!storedPatientsQueryParamValue) return
      const newValue = { ...patientsQueryParamValue, ...value }
      setStoredPatientsQueryParamValue(newValue)
      addSearchParams(newValue)
    },
    [
      storedPatientsQueryParamValue,
      setStoredPatientsQueryParamValue,
      patientsQueryParamValue,
      addSearchParams,
    ],
  )

  const resetQuery = useCallback(() => {
    setStoredPatientsQueryParamValue({})
    if (queryInputRef.current) {
      queryInputRef.current.value = ''
    }
    navigate({ search: undefined })
  }, [navigate, setStoredPatientsQueryParamValue])

  const setupQuery = useCallback(() => {
    if (!!location.search) return

    if (!!storedPatientsQueryParamValue) {
      addSearchParams(storedPatientsQueryParamValue)
    }
  }, [location.search, storedPatientsQueryParamValue, addSearchParams])

  useEffect(() => {
    setupQuery()
  }, [setupQuery])

  useEffect(() => {
    if (!storedPatientsQueryParamValue) {
      setStoredPatientsQueryParamValue(patientsQueryParamValue)
    }
  }, [
    storedPatientsQueryParamValue,
    setStoredPatientsQueryParamValue,
    patientsQueryParamValue,
  ])

  const hasQuery = useMemo(() => !!location.search, [location.search])

  const setQuery = useCallback(
    (input: GetQueryParamInput) => {
      // pagination以外ではページを指定しない(2ページ目以降で行った場合は1ページ目に戻る)。
      // ページ以外についてはqueryパラメータに含まれるものを引き継ぐ
      if (input.type === 'pagination') {
        updateQuery({ page: input.value.toString() })
        return
      }

      if (input.type === 'search') {
        updateQuery({ searchQuery: input.value ?? '', page: undefined })
        return
      }

      if (input.type === 'statuses') {
        updateQuery({ statuses: input.value.join(','), page: undefined })
        return
      }

      if (input.type === 'trialHospital') {
        updateQuery({ trialHospitalUid: input.value, page: undefined })
        return
      }

      if (input.type === 'relatedHospital') {
        updateQuery({
          relatedHospitalUid:
            input.value.type === 'WithUid' ? input.value.value : undefined,
          relatedHospitalType: input.value.type,
          page: undefined,
        })
        return
      }

      if (input.type === 'sortKey') {
        updateQuery({ sortKey: input.value })
        return
      }
      if (input.type === 'sortOrder') {
        updateQuery({ sortOrder: input.value })
        return
      }

      return assertNever(input)
    },
    [updateQuery],
  )

  return {
    queryParamObject: parseQueryParamsObject(patientsQueryParamValue),
    hasQuery,
    setQuery,
    resetQuery,
    queryInputRef,
  }
}

const generateSearchParams = (value: PatientsQueryParamValue) => {
  const searchParam = new URLSearchParams()
  Object.entries(value).forEach(([key, value]) => {
    if (value === undefined) return
    searchParam.set(queryParamKeys[key as keyof typeof queryParamKeys], value)
  })
  return searchParam.toString()
}

const parseQueryParamsObject = (
  value: PatientsQueryParamValue,
): QueryParamObject => {
  return {
    page: stringToPage(value.page) ?? 1,
    searchQuery: value.searchQuery ?? '',
    statuses: stringToPatientStatusesArray(value.statuses),
    trialHospitalUid: value.trialHospitalUid ?? undefined,
    relatedHospitalUid: value.relatedHospitalUid ?? undefined,
    relatedHospitalType: stringToPatientRelatedHospitalType(
      value.relatedHospitalType,
    ),
    sortKey: stringToPatientSortKey(value.sortKey),
    sortOrder: stringToSortOrder(value.sortOrder),
  }
}
