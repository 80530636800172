import React from 'react'

import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Spacer } from 'src/components/spacer/spacer'
import { MembersSettingButton } from 'src/features/explanation/components/MembersSettingButton/MembersSettingButton'
import { Explanation, ExplanationSession } from 'src/features/explanation/types'
import { TrialHospital } from 'src/features/trial/types'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

type Props = {
  trialName: string
  candidateId: string
  explanationUid: Explanation['uid']
  trialHospital: TrialHospital
  partnerTrialHospital?: TrialHospital
  session: ExplanationSession
  isPatient?: boolean
}

export const SidebarContentRenewal: React.FC<Props> = ({
  trialName,
  candidateId,
  explanationUid,
  trialHospital,
  partnerTrialHospital,
  session,
  isPatient = false,
}) => {
  const identifiedAt =
    session.type === 'InPerson'
      ? undefined
      : session.explanationPreSession?.identifiedAt

  const mainHospitalMembers = session.members.filter(
    m => m.trialHospital.uid === trialHospital.uid,
  )
  const partnerMembers = session.members.filter(
    m => m.trialHospital.uid === partnerTrialHospital?.uid,
  )

  return (
    <Stack spacing="8">
      <Text fontSize="xl" fontWeight="bold">
        {trialName}
      </Text>
      {!!session.startedAt && (
        <Stack spacing="1">
          <Text fontSize="xl" fontWeight="bold">
            説明開始時刻
          </Text>
          <Text>{dayjs(session.startedAt).format('YYYY/MM/DD HH:mm:ss')}</Text>
        </Stack>
      )}

      <Stack spacing="1">
        <Text fontSize="xl" fontWeight="bold">
          候補ID
        </Text>
        <Stack>
          <Text fontSize="lg">{candidateId}</Text>
          {!!identifiedAt && (
            <Text fontSize="sm">{`本人確認: ${formatDate(
              identifiedAt,
              'YYYY/MM/DD (ddd) HH:mm',
            )}`}</Text>
          )}
        </Stack>
      </Stack>

      <Box>
        <Text fontSize="xl" fontWeight="bold">
          説明担当者
        </Text>
        <Stack spacing="4" maxHeight="192px" overflowY="scroll" mt="2">
          {/* メンバーが指定されていない場合は医療機関名も表示しない */}
          {mainHospitalMembers.length > 0 && (
            <Box>
              <Text fontWeight="bold" as="h3">
                {trialHospital.name}
              </Text>
              <Stack mt="2" spacing="1" ml="6">
                {mainHospitalMembers.map(m => (
                  <HStack key={m.trialMember.uid} spacing="4">
                    <Text>{m.trialMember.role}</Text>
                    <Text>{getFullName(m.trialMember)}</Text>
                  </HStack>
                ))}
              </Stack>
            </Box>
          )}
          {/* パートナー施設の担当者が未指定の場合は施設名も表示しない */}
          {!!partnerTrialHospital && partnerMembers.length > 0 && (
            <Box>
              <Text fontWeight="bold" as="h3">
                {partnerTrialHospital.name}
              </Text>
              <Stack mt="2" spacing="1" ml="6">
                {partnerMembers.map(m => (
                  <HStack key={m.trialMember.uid} spacing="4">
                    <Text>{m.trialMember.role}</Text>
                    <Text>{getFullName(m.trialMember)}</Text>
                  </HStack>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
        <Spacer size={8} />
        {!isPatient && (
          <MembersSettingButton
            explanationSessionUid={session.uid}
            explanationUid={explanationUid}
            isSignerSet={false} // 旧署名フローでは常にfalse（署名者を設定する時点でstatusで制御できる）
            defaultMembers={session.members.map(m => ({
              trialHospitalUid: m.trialHospital.uid,
              trialMemberUid: m.trialMember.uid,
              role: m.trialMember.role,
            }))}
          />
        )}
      </Box>
    </Stack>
  )
}
