import { useMemo } from 'react'

import { Box, Flex, HStack, Spinner, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { AnchorLink } from 'src/components/base/text-link'
import { Breadcrumb } from 'src/components/breadcrumb/breadcrumb'
import { useModal } from 'src/hooks/use-modal'
import { sortByDate } from 'src/modules/util/sort'

import { WSDataExportJob } from '../../types'
import { wsDataExportJobStatusToText } from '../../utils/jobStatusToText'

type Props = {
  trialName: string
  wsDataExportJobs: WSDataExportJob[]
  onStartJob: () => Promise<void>
  onCancelJob: (uid: WSDataExportJob['uid']) => Promise<void>
  getZipDownloadLink: (uid: WSDataExportJob['uid']) => string
}

export const WSDataExportScreenComponent: React.FC<Props> = ({
  trialName,
  wsDataExportJobs,
  onStartJob,
  onCancelJob,
  getZipDownloadLink,
}) => {
  const sortedJobs = useMemo(
    () => sortByDate(wsDataExportJobs, 'registeredAt', 'desc'),
    [wsDataExportJobs],
  )

  const runningJob = useMemo(
    () =>
      wsDataExportJobs.find(
        job => job.status === 'waiting' || job.status === 'running',
      ),
    [wsDataExportJobs],
  )

  const { showModal } = useModal()

  const textColorError = (job: WSDataExportJob) => {
    return job.status === 'error' ? red[50] : undefined
  }

  return (
    <Flex h="full" direction="column">
      <Breadcrumb
        breadcrumbParams={[
          { label: '設定', isLink: false },
          { label: '症例データ管理', isLink: false },
        ]}
      />

      <Box mt="6">
        <Text fontWeight="bold">症例データダウンロード</Text>
      </Box>

      <Box pos="relative" mt="8">
        <Stack spacing="1" visibility={!!runningJob ? 'hidden' : 'visible'}>
          <Text fontSize="sm">
            CSV形式で<strong>{trialName}</strong>の症例データを作成します。
          </Text>
          <Text fontSize="sm">
            症例データCSVファイルは作成処理完了後にファイル作成履歴より
            ダウンロードできます
          </Text>
          <Message
            type="warning"
            message="ダウンロード期限は処理終了日時より7日間"
          />
        </Stack>
        {!!runningJob && (
          <HStack
            spacing="2"
            align="center"
            pos="absolute"
            top={0}
            left={0}
            h="full"
          >
            <Text fontSize="sm">症例データCSVファイルを作成中です。</Text>
            <Spinner size="sm" />
          </HStack>
        )}
      </Box>

      <Stack mt="8" spacing="4">
        <Box>
          {!!runningJob ? (
            <Button
              text="処理を中止"
              buttonType="normal"
              onClick={() => {
                showModal({
                  title: '症例データCSVファイル作成処理の中止',
                  content: '症例データCSVファイルの作成処理を中止しますか？',
                  submitText: 'はい',
                  cancelText: 'いいえ',
                  onSubmit: () => onCancelJob(runningJob.uid),
                  legacy: true,
                })
              }}
            />
          ) : (
            <Button
              text="CSVファイル作成"
              buttonType="normal"
              onClick={onStartJob}
            />
          )}
        </Box>
      </Stack>

      <Box mt="6" flex={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>処理受付時刻</TableHeadCell>
              <TableHeadCell>処理終了時刻</TableHeadCell>
              <TableHeadCell>最終更新者</TableHeadCell>
              <TableHeadCell>処理結果</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedJobs.map(job => (
              <TableRow borderBottom key={job.uid}>
                <TableBodyCell>
                  {dayjs(job.registeredAt).format('YYYY/MM/DD HH:mm:ss')}
                </TableBodyCell>
                <TableBodyCell>
                  {job.completedAt
                    ? dayjs(job.completedAt).format('YYYY/MM/DD HH:mm:ss')
                    : ''}
                </TableBodyCell>
                <TableBodyCell>{job.lastUpdatedBy}</TableBodyCell>
                <TableBodyCell>
                  {job.status === 'done' ? (
                    <AnchorLink href={getZipDownloadLink(job.uid)} download>
                      ダウンロード
                    </AnchorLink>
                  ) : (
                    <Text color={textColorError(job)}>
                      {wsDataExportJobStatusToText(job.status)}
                    </Text>
                  )}
                </TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Flex>
  )
}
