import { useMemo } from 'react'

import { isMobileOnly } from 'react-device-detect'
import { Route, Routes, useLocation } from 'react-router-dom'
import { getRelativePath, PathKey } from 'src/constants/paths'
import { useFeatureFlags } from 'src/features/trial/api/getFeatureFlags'
import { useQuery } from 'src/hooks/use-query'
import { useMobileOrientationAlert } from 'src/hooks/useMobileOrientationAlert'

import { PatientExplanationRoom } from '../../../modules/dashboard/trial/detail/e-consent/explanation-room/patient-explanation-room'
import {
  PatientLobbyScreen,
  PatientTopScreen,
  SPExplanationLobby,
  SPExplanationPatientDocument,
  SPExplanationPatientTop,
} from '../components'
import { ExplanationRoomNotFound } from '../components/ExplanationRoomNotFound/ExplanationRoomNotFound'
import { PatientDocumentSignScreen } from '../components/PatientDocumentSignScreen/PatientDocumentSignScreen'
import { PatientDocumentViewerScreen } from '../components/PatientDocumentViewerScreen/PatientDocumentViewerScreen'
import { PatientSignCompletedScreen } from '../components/PatientSignCompletedScreen/PatientSignCompletedScreen'
import { SPPatientDocumentSignScreen } from '../components/SPPatientDocumentSignScreen/SPPatientDocumentSignScreen'
import { SPPatientSignCompletedScreen } from '../components/SPPatientSignCompletedScreen/SPPatientSignCompletedScreen'
import { dealSPQueryKey } from '../constants/sp'

export const PatientExplanationRoomRoutes: React.FC = () => {
  const { pathname } = useLocation()

  const trialUid = useMemo(
    () => pathname.match(/\/explanation-room\/p\/trials\/([0-9a-z-]+)/)?.[1],
    [pathname],
  )

  const { data: featureFlags } = useFeatureFlags({
    trialUid: trialUid ?? null,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  if (!featureFlags) return null

  if (!featureFlags.eConsentNewSignFlow) {
    return (
      <Routes>
        <Route path={`*`} element={<PatientExplanationRoom />} />
      </Routes>
    )
  }

  return <NewRoutes />
}

const NewRoutes = () => {
  const query = useQuery()

  const isSP = useMemo(() => {
    // query paramsに指定がある場合はそちらを優先、未指定の場合はuser agentから判定（主にdebug用途）
    const dealSPQuery = query.get(dealSPQueryKey)
    if (dealSPQuery === 'true') return true
    if (dealSPQuery === 'false') return false
    return isMobileOnly
  }, [query])

  useMobileOrientationAlert({
    shouldBe: isSP ? 'portrait' : 'landscape',
    message: isSP
      ? '画面は縦向きでのご利用を推奨しています。'
      : '画面は横向きでのご利用を推奨しています。',
  })

  const getPath = (path: PathKey): string => {
    return getRelativePath('PatientExplanationRoom', path)
  }

  return (
    <Routes>
      <Route
        path={getPath('PatientExplanationRoomLobby')}
        element={isSP ? <SPExplanationLobby /> : <PatientLobbyScreen />}
      />
      <Route
        path={getPath('PatientExplanationRoomTop')}
        element={isSP ? <SPExplanationPatientTop /> : <PatientTopScreen />}
      />
      <Route
        path={getPath('PatientExplanationRoomDocument')}
        element={
          isSP ? (
            <SPExplanationPatientDocument />
          ) : (
            <PatientDocumentViewerScreen />
          )
        }
      />
      <Route
        path={getPath('PatientExplanationRoomDocumentSign')}
        element={
          isSP ? <SPPatientDocumentSignScreen /> : <PatientDocumentSignScreen />
        }
      />
      <Route
        path={getPath('PatientExplanationRoomSignCompleted')}
        element={
          isSP ? (
            <SPPatientSignCompletedScreen />
          ) : (
            <PatientSignCompletedScreen />
          )
        }
      />
      <Route path="*" element={<ExplanationRoomNotFound />} />
    </Routes>
  )
}
