import { MemberFragment } from 'src/lib/gql-client'
import { castUid } from 'src/utils/brandedUid'

import { Member } from '../types'

export const parseGqlMember = (res: MemberFragment): Member => {
  return {
    uid: castUid<Member>(res.uid),
    firstName: res.user.firstName,
    lastName: res.user.lastName,
    role: res.role,
  }
}
