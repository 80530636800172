import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { actions } from 'src/modules/entities/questionnaire/redux'
import { backToDraft } from 'src/modules/entities/questionnaire/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

export const useSubmitBackToDraft = ({
  trialUid,
  questionnaireUid,
}: {
  trialUid: string
  questionnaireUid: string
}) => {
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const request = useCallback(async () => {
    try {
      requestStarted()

      const backToDraftdQuestionnaire = await backToDraft(
        trialUid,
        questionnaireUid,
      )

      dispatch(actions.upsert(backToDraftdQuestionnaire))

      requestDone()
      showSuccess(`質問票を一時保存に戻しました。`)
    } catch (e) {
      requestFailed(e.message)
      throw e
    }
  }, [
    dispatch,
    questionnaireUid,
    requestDone,
    requestFailed,
    requestStarted,
    showSuccess,
    trialUid,
  ])
  return { request, requesting, errorMessage }
}
