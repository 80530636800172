import { useState } from 'react'

import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Edit } from 'src/components/icon'
import { usePermission } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'

import { useUpdateWorksheetName } from '../../api/updateWorkhseetName'
import { WorksheetDetail } from '../../types'
import { validateWorksheetNameLength } from '../../utils/validateNameLength'

type Props = {
  worksheet: WorksheetDetail
  isPatientDisabled: boolean
  mutateWorksheet: () => void
}

export const UpdateWorksheetName: React.FC<Props> = ({
  worksheet,
  isPatientDisabled,
  mutateWorksheet,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { hasPermission } = usePermission()

  const [worksheetName, setWorksheetName] = useState(worksheet.name)

  const handleClose = () => {
    onClose()
    setWorksheetName(worksheet.name)
  }

  const toast = useMirohaToast()

  const { request: updateWorksheetName } = useUpdateWorksheetName({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'ワークシート表示名を変更しました',
      })
      handleClose()
      mutateWorksheet()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  if (worksheet.status === 'disabled' || isPatientDisabled) {
    return null
  }
  if (!hasPermission(PERMISSIONS.Worksheet_Edit)) {
    return null
  }

  const nameLengthError = validateWorksheetNameLength(worksheetName)

  const isValid =
    nameLengthError.isValid &&
    worksheetName.length > 0 &&
    worksheetName !== worksheet.name

  return (
    <>
      <IconButton
        icon={<Edit />}
        aria-label="ワークシート表示名の変更"
        onClick={onOpen}
        variant="customIconButtonGhost"
      />

      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>ワークシート表示名の変更</ModalHeader>
          <ModalBody>
            <Stack spacing="2">
              <Text
                fontSize="sm"
                color="gray.500"
                fontWeight="bold"
              >{`${worksheet.schema.name} #${worksheet.index}`}</Text>
              <Stack>
                <Input
                  value={worksheetName}
                  onChange={e => setWorksheetName(e.target.value)}
                  placeholder="ワークシート表示名を入力してください"
                />
                {!nameLengthError.isValid && (
                  <Text color="red.500" fontSize="xs">
                    {nameLengthError.errorMessage}
                  </Text>
                )}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="blue"
              isDisabled={!isValid}
              onClick={async () => {
                await updateWorksheetName({
                  worksheetUid: worksheet.uid,
                  name: worksheetName,
                })
              }}
            >
              確定
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
