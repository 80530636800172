import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useCurrentMember } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useReEditWorksheet } from '../../api/reEditWorksheet'
import { WorksheetDetail } from '../../types'
import { MAX_TEXT_LENGTH } from '../../utils/validate'

type Props = {
  worksheet: WorksheetDetail
  mutateWorksheet: (worksheet: WorksheetDetail) => void
}

export const ReEditWorksheet: React.FC<Props> = ({
  worksheet,
  mutateWorksheet,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentMember } = useCurrentMember()

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<{ reason: string }>({
    mode: 'onTouched',
  })

  const toast = useMirohaToast()

  const { request: reEditWorksheet } = useReEditWorksheet({
    onSuccess: worksheet => {
      mutateWorksheet(worksheet)
      onClose()
      toast({
        title: 'ワークシートの再編集を開始しました',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  // TODO: use permission definition
  if (currentMember.role === 'DM' || currentMember.role === 'CRA') {
    return null
  }

  if (worksheet.status !== 'confirmed') {
    return null
  }

  return (
    <>
      <Button onClick={onOpen}>再編集</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize="xl" fontWeight="bold">
              再編集
            </Text>
          </ModalHeader>
          <ModalBody>
            <Text>再編集を開始します。</Text>
            <FormControl
              mt="6"
              isInvalid={errors['reason']?.type === 'maxLength'}
            >
              <FormLabel>コメント（任意）</FormLabel>

              <Input
                {...register('reason', {
                  maxLength: MAX_TEXT_LENGTH,
                })}
              />

              {errors['reason']?.type === 'maxLength' && (
                <FormErrorMessage color="red.500" fontSize="xs" mt="1.5" mb="0">
                  {MAX_TEXT_LENGTH}文字以内で入力してください。
                </FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="blue"
              onClick={handleSubmit(async ({ reason }) => {
                await reEditWorksheet({
                  worksheetUid: worksheet.uid,
                  reason,
                })
              })}
              disabled={!isValid}
            >
              再編集する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
