import { createContext } from 'react'

import { Dictionary } from 'lodash'
import { Choice } from 'src/modules/entities/choice/entity'
import { SmallItem } from 'src/modules/entities/small-item/entity'

export type CardContextValue = {
  isClinicalInParent: boolean
  releaseTried: boolean
  smallItemMap: Dictionary<SmallItem>
  onRemoveSmallItem: (args: { smallItemUid: string }) => void
  onChangeSmallItem: (value: {
    smallItemUid: string
    values: { [key in keyof SmallItem]?: SmallItem[key] }
  }) => void
  onChangeSmallItemIndex: (args: {
    smallItemUid: string
    direction: 'up' | 'down'
  }) => void

  onAddChoice: (attrs: { smallItemUid: string }) => void
  onChangeChoice: (value: {
    smallItemUid: string
    choiceIndex: number
    values: { [key in keyof Choice]?: Choice[key] }
  }) => void
  onRemoveChoice: (attrs: { smallItemUid: string; choiceIndex: number }) => void
  onAddSmallItemToChoice: (args: {
    smallItemUid: string
    choiceIndex: number
  }) => void
}

export const CardContext = createContext<CardContextValue>({
  isClinicalInParent: false,
  releaseTried: false,
  smallItemMap: {},
  onRemoveSmallItem: () => {},
  onChangeSmallItem: () => {},
  onChangeSmallItemIndex: () => {},

  onAddChoice: () => {},
  onChangeChoice: () => {},
  onRemoveChoice: () => {},
  onAddSmallItemToChoice: () => {},
})
