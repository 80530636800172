import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { QuestionnaireChoice } from 'src/modules/entities/questionnaire-choice/entity'

import { actions } from './redux'

export const useEditQuestionnaireChoice = ({
  sectionIndex,
  fieldUid,
  index,
}: {
  sectionIndex: number
  fieldUid: string
  index: number
}) => {
  const dispatch = useDispatch()

  const onDeleteChoice = useCallback(
    () =>
      dispatch(
        actions.deleteChoice({
          sectionIndex,
          fieldUid,
          index,
        }),
      ),
    [dispatch, fieldUid, index, sectionIndex],
  )

  const onUpdateChoice = useCallback(
    ({
      values,
    }: {
      values: Partial<Pick<QuestionnaireChoice, 'description' | 'value'>>
    }) =>
      dispatch(
        actions.updateChoice({
          sectionIndex,
          fieldUid,
          index,
          values,
        }),
      ),
    [dispatch, fieldUid, index, sectionIndex],
  )

  return {
    onDeleteChoice,
    onUpdateChoice,
  }
}
