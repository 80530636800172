import React from 'react'

import { Stack, Text } from '@chakra-ui/react'
import { Choice } from 'src/modules/entities/choice/entity'
import { ItemType, SmallItem } from 'src/modules/entities/small-item/entity'
import styled from 'styled-components'
import { FlexItem as OriginalFlexItem } from 'styled-flex-component'
import { space, SpaceProps } from 'styled-system'

import { SortOnlyNestedSmallItem } from './sort-only-nested-small-item'

type Props = {
  choice: Choice
  isClinical: boolean
  smallItems: SmallItem[]
  itemType: ItemType
  v2: boolean

  onChange: (args: { values: { [key in keyof Choice]?: Choice[key] } }) => void
  onRemove: () => void
  onAddSmallItem: () => void
}

const Container = styled.div`
  width: 100%;
  > div > label {
    font-weight: bold;
  }
`

const Fields = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Field = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexItem = styled(OriginalFlexItem)<SpaceProps>`
  ${space}
`
export const SortOnlyChoiceComponent: React.FC<Props> = props => {
  const { isClinical, choice, smallItems, v2 } = props

  return (
    <Container>
      <Stack>
        <Fields>
          <FlexItem grow={5}>
            <Field>
              <Text>{choice.description}</Text>
            </Field>
          </FlexItem>
          {isClinical && (
            <FlexItem ml={3}>
              <Stack spacing="1">
                <Text fontWeight="bold" fontSize="sm" color="gray.600">
                  EDCコード値
                </Text>
                <Text>{choice.edcValue}</Text>
              </Stack>
            </FlexItem>
          )}
        </Fields>
        {smallItems.map(smallItem => (
          <SortOnlyNestedSmallItem
            key={smallItem.uid}
            smallItem={smallItem}
            v2={v2}
          />
        ))}
      </Stack>
    </Container>
  )
}
