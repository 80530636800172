import React from 'react'

import { Text } from '@chakra-ui/react'
import { gray, green } from 'src/components/base/color/palette'
import styled from 'styled-components'

import { UploadTask } from '../../task'
import { uploadTaskToEnText } from '../util'

type Props = {
  task: UploadTask
  completed: boolean
  isCurrentTask: boolean
  isLast: boolean
}

export const Progress: React.FC<Props> = props => {
  const { task, completed, isCurrentTask, isLast } = props
  const text = uploadTaskToEnText(task)
  const inProgress = isCurrentTask && !completed

  const { circleColor, borderColor, textColor } = completed
    ? { circleColor: green[40], borderColor: green[40], textColor: green[40] }
    : inProgress
      ? { circleColor: green[10], borderColor: gray[55], textColor: gray[100] }
      : { circleColor: gray[55], borderColor: gray[55], textColor: gray[55] }

  return (
    <Container>
      <Top>
        <Circle color={circleColor} />
        {!isLast && <Border color={borderColor} />}
      </Top>

      <Bottom>
        <Text fontWeight="bold" color={textColor} wordBreak="keep-all">
          {text}
        </Text>
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Top = styled.div`
  display: flex;
  align-items: center;
`

type CircleColor = (typeof green)[40] | (typeof green)[10] | (typeof gray)[55]
const Circle = styled.div<{ color: CircleColor }>`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${p => p.color};
`

type BorderColor = (typeof gray)[55] | (typeof green)[40]
const Border = styled.div<{ color: BorderColor }>`
  width: 120px;
  border: 1px solid ${p => p.color};
`

const Bottom = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 36px;
  top: 50px;
  left: 0;
`
