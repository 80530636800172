// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgCheckBold = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M6.337 13.5c-.475 0-.792-.155-1.11-.465L.476 8.387c-.633-.62-.633-1.549 0-2.169a1.555 1.555 0 0 1 2.218 0l3.644 3.564 6.97-6.817a1.555 1.555 0 0 1 2.218 0c.633.62.633 1.55 0 2.169l-8.08 7.901a1.73 1.73 0 0 1-1.108.465Z"
    />
  </svg>
)
export default SvgCheckBold
