import {
  Box,
  Center,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Text,
  useBreakpointValue,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Check } from 'src/components/icon'
import { formatDate } from 'src/utils/formatDate'

type Props = {
  order: number
  /** 文書閲覧ページのpath */
  docPath: string
  docName: string
  docVersion: string
  patientBrowsedAt: string | undefined
}

export const DocumentBanner: React.FC<Props> = ({
  order,
  docPath,
  docName,
  docVersion,
  patientBrowsedAt,
}) => {
  const circleSize = useBreakpointValue({ base: '8', md: '10' })
  const supplementaryInfoFontSize = useBreakpointValue({ base: 'xs', md: 'sm' })
  // sm=30em, md=48em see: https://v1.chakra-ui.com/docs/styled-system/features/responsive-styles
  const [isLargerThanSm, isLargerThanMd] = useMediaQuery([
    '(min-width: 30em)',
    '(min-width: 48em)',
  ])

  return (
    <Box as="section" aria-label={docName}>
      <LinkBox
        as={Flex}
        align="center"
        justify="space-between"
        gap={4}
        border="2px solid"
        borderRadius="lg"
        borderColor="green.600"
        bg="white"
        w="full"
        p={{ base: '2', md: '4' }}
        _hover={{ bg: 'green.50' }}
        _active={{ bg: 'green.100' }}
      >
        <HStack align="center" spacing={{ base: '4', md: '6' }} flex="1">
          {!!patientBrowsedAt ? (
            <Center
              bg="green.600"
              color="white"
              borderRadius="full"
              h={circleSize}
              w={circleSize}
            >
              <Check size={isLargerThanMd ? 'lg' : 'md'} />
            </Center>
          ) : (
            <Center
              as="span"
              h={circleSize}
              w={circleSize}
              borderRadius="full"
              border="2px solid"
              borderColor="green.600"
              color="green.600"
              fontWeight="bold"
              fontSize="xl"
            >
              {order}
            </Center>
          )}

          <Text
            fontSize={{ base: 'md', md: '2xl' }}
            fontWeight="bold"
            color="green.600"
            flex="1"
          >
            <LinkOverlay as={Link} to={docPath} overflowWrap="anywhere">
              {docName}
            </LinkOverlay>
          </Text>
        </HStack>

        <HStack
          align="center"
          justify="end"
          spacing={{ base: '4', lg: '12' }}
          wordBreak="keep-all"
        >
          {!!patientBrowsedAt && (
            <VStack align="start">
              {isLargerThanSm && (
                <HStack spacing={{ base: '1', md: '4' }}>
                  <Text
                    fontSize={supplementaryInfoFontSize}
                    fontWeight="bold"
                    as="span"
                  >
                    閲覧時刻
                  </Text>
                  <Text
                    fontSize={supplementaryInfoFontSize}
                    wordBreak="keep-all"
                    as="time"
                    dateTime={patientBrowsedAt}
                  >
                    {formatDate(patientBrowsedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                  </Text>
                </HStack>
              )}
              <HStack
                spacing={{ base: '1', md: '4' }}
                display={{ base: 'none', sm: 'flex' }}
              >
                <Text
                  fontSize={supplementaryInfoFontSize}
                  fontWeight="bold"
                  as="span"
                >
                  閲覧状況
                </Text>
                <HStack>
                  <Text fontSize={supplementaryInfoFontSize} as="span">
                    患者
                  </Text>
                  <Center
                    as="span"
                    borderRadius="full"
                    bg="green.600"
                    h="4"
                    w="4"
                    color="white"
                  >
                    <Check size="sm" />
                  </Center>
                </HStack>
              </HStack>
            </VStack>
          )}
          <Text
            display={{ base: 'none', sm: 'block' }}
            fontSize={supplementaryInfoFontSize}
            as="span"
          >{`${docVersion}版`}</Text>
        </HStack>
      </LinkBox>
    </Box>
  )
}
