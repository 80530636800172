import { Member, MemberRole } from 'src/features/member/types'
import { Trial, TrialHospital } from 'src/features/trial/types'
import { ApiResponse } from 'src/lib/api-client'
import { isPermission } from 'src/lib/permission'
import { castUid } from 'src/utils/brandedUid'

import { Account, CognitoUser } from '../types'

export const parseAccountResponse = (
  res: ApiResponse<'GetUserResponse'>,
  cognitoUser: CognitoUser,
): Account => {
  const base: Account = {
    uid: castUid<Account>(res.uid),
    firstName: res.first_name,
    lastName: res.last_name,
    email: res.email,
    mfaEnabled: cognitoUser.preferredMFA === 'TOTP',
    hasMultipleMemberships: res.has_multiple_memberships,
    hasSelectedTrial: false,
  }

  if (!res.selected_trial) {
    return base
  }

  return {
    ...base,
    hasSelectedTrial: true,
    selectedTrialHospitalUid: !!res.selected_trial.selected_trial_hospital_uid
      ? castUid<TrialHospital>(res.selected_trial.selected_trial_hospital_uid)
      : null,
    selectedTrialHospitalRole: !!res.selected_trial.selected_trial_hospital_role
      ? res.selected_trial.selected_trial_hospital_role
      : null,
    member: {
      uid: castUid<Member>(res.selected_trial.trial_member_uid),
      firstName: res.first_name,
      lastName: res.last_name,
      role: parseMemberRole(res.selected_trial.role),
    },
    selectedTrial: {
      uid: castUid<Trial>(res.selected_trial.uid),
      name: res.selected_trial.name,
      videoEnabled: res.selected_trial.video_enabled,
      pdfOutputEnabled: res.selected_trial.pdf_output_enabled,
      videoCaptureEnabled: res.selected_trial.video_capture_enabled,
      videoCaptureAccessRestricted:
        res.selected_trial.video_capture_access_restricted,
      videoProvider: res.selected_trial.video_provider,
      edcIntegrationEnabled: res.selected_trial.edc_integration_enabled,
      trialHospitals: res.selected_trial.trial_hospitals.map(th => ({
        uid: castUid<TrialHospital>(th.uid),
        name: th.name,
        role: th.role,
      })),
      hasWorksheet: res.selected_trial.has_worksheet,
      eProEnabled: res.selected_trial.epro_enabled,
      questionnaireScheduleReleased:
        res.selected_trial.questionnaire_schedule_released,
      multiSiteVideoCallEnabled: res.selected_trial.multi_site_video_call,
      newEconsentEnabled: res.selected_trial.new_econsent_enabled,
      eRecruitEnabled: res.selected_trial.erecruit_enabled,
      featureChannel: res.selected_trial.feature_channel,
      featureFlags: res.selected_trial.feature_flags,
    },
    permissions: res.selected_trial.permissions.filter(isPermission),
  }
}

const parseMemberRole = (role: number): MemberRole => {
  switch (role) {
    case 2:
      return 'DM'
    case 3:
      return 'CRA'
    case 4:
      return 'Dr'
    case 5:
      return 'CRC'
    default:
      throw new Error('invalid role')
  }
}
