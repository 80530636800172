import {
  GetSessionDocument,
  GetSessionForPatientLegacyDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { ExplanationSession } from '../../types'
import { explanationCacheKey } from '../cacheKey'
import { parseGqlSession, parseGqlSessionForPatient } from '../parser'

export const getExplanationSession = async ({
  explanationSessionUid,
  allowFinished,
}: {
  explanationSessionUid: string
  allowFinished?: boolean
}): Promise<ExplanationSession> => {
  try {
    const res = await graphqlRequest(GetSessionDocument, {
      explanationSessionUid,
      allowFinished,
    })
    return parseGqlSession(res.explanationSession)
  } catch (error) {
    throw error
  }
}

export const getExplanationSessionForPatient = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<ExplanationSession> => {
  try {
    const res = await graphqlRequest(GetSessionForPatientLegacyDocument, {
      explanationSessionUid,
    })
    return parseGqlSessionForPatient(res.explanationSession)
  } catch (error) {
    throw error
  }
}

export const useExplanationSession = ({
  explanationSessionUid,
  forPatient,
  allowFinished,
  ...option
}: {
  explanationSessionUid: string
  forPatient?: boolean
  allowFinished?: boolean
} & SWRConfiguration<ExplanationSession, GqlError>) => {
  return useSWR<ExplanationSession, GqlError>(
    explanationCacheKey.getExplanationSession(explanationSessionUid),
    () =>
      forPatient
        ? getExplanationSessionForPatient({ explanationSessionUid })
        : getExplanationSession({ explanationSessionUid, allowFinished }),
    option,
  )
}
