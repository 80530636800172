import React, { useState } from 'react'

import { Button } from 'src/components/base/button/button'

import { OutputModalContainer } from './modal-container'

type Props = {}

export const OutputButton: React.FC<Props> = props => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button
        size="S"
        text="データ出力"
        buttonType="normal"
        onClick={() => setModalOpen(true)}
      ></Button>
      {modalOpen && (
        <OutputModalContainer onClose={() => setModalOpen(false)} />
      )}
    </>
  )
}
