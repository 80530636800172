import React, { useState } from 'react'

import { Error } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { SectionConfirmationStatus } from 'src/modules/entities/medium-item-answer/entity'
import styled from 'styled-components'

import { SubmitModalContainer } from './container'
type Props = {
  mediumItemAnswerUid: string
  edcLinked: boolean
  disabled: boolean
  confirmationStatus: SectionConfirmationStatus | null
}

export const ConfirmButton: React.FC<Props> = props => {
  const [modalOpen, setModalOpen] = useState(false)
  const { mediumItemAnswerUid, edcLinked, disabled, confirmationStatus } = props

  return (
    <Wrapper>
      <Button
        size="S"
        text="確定"
        onClick={() => setModalOpen(true)}
        buttonType="important"
        disabled={disabled}
      >
        確定
      </Button>
      {confirmationStatus ===
        SectionConfirmationStatus.EdcIntegrationFailed && (
        <IconContainer>
          <Spacer size={8} horizontal />
          <Error color={red[50]} />
        </IconContainer>
      )}

      {modalOpen && (
        <SubmitModalContainer
          mediumItemAnswerUid={mediumItemAnswerUid}
          edcLinked={edcLinked}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
`
