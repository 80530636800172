import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react'
import { Expand3 } from 'src/components/__legacy__icon/monochrome'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useWorksheetMenu } from '../../hooks/useWorksheetMenu'
import { WorksheetDetail } from '../../types'

type Props = {
  patientUid: string
  worksheet: WorksheetDetail
}

export const WorksheetMenu: React.FC<Props> = ({ patientUid, worksheet }) => {
  const { menuItems, renderContent } = useWorksheetMenu({
    patientUid,
    worksheet,
  })
  if (menuItems.length === 0) return null
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          colorScheme="blue"
          variant="customIconButtonGhost"
          aria-label="worksheet-menu"
          icon={<Expand3 color={colors.blue[500]} />}
        />
        <MenuList>
          {menuItems.map(item => (
            <MenuItem key={item.key} onClick={item.onClick} color={item.color}>
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {renderContent()}
    </>
  )
}
