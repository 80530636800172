import React, { useEffect } from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import MirohaLogoFull from 'src/assets/image/miroha-logo-full.svg'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { OnSubmit } from 'src/modules/auth/login/hooks/use-login'
import styled from 'styled-components'
import * as yup from 'yup'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${blue[5]};
`

const ContentContainer = styled.div`
  width: 80%;
  max-width: 600px;
  padding: 60px 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${white};
  border-radius: 10px;
`

const Form = styled.form`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FormItem = styled.div`
  width: 100%;
`

type Props = {
  email: string
  errorMessage: string
  requesting: boolean
  onSubmit: OnSubmit
}

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
})

type FormValues = yup.InferType<typeof validationSchema>

export const TemporaryPasswordLogin: React.FC<Props> = ({
  email,
  errorMessage,
  requesting,
  onSubmit: submit,
}) => {
  const { register, handleSubmit, formState, trigger } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = (value: FormValues) => {
    if (requesting) return
    if (!isValid) return

    submit(value)
  }

  useEffect(() => {
    trigger()
  }, [trigger])

  return (
    <PageContainer>
      <img src={MirohaLogoFull} alt="MiROHA icon" />
      <Spacer size={60} />
      <ContentContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize="lg" fontWeight="bold">
            仮パスワードでログイン
          </Text>

          <Spacer size={40} />
          <Text fontSize="sm">
            メールアドレスに仮パスワードを送信しました。仮パスワードでのログイン後、本パスワードの登録を実施してください。
          </Text>

          <Spacer size={20} />

          <FormItem>
            <Label bold>メールアドレス</Label>
            <Spacer size={10} />
            <Input
              type="email"
              placeholder={email}
              value={email}
              readOnly={true}
              {...register('email')}
            />
          </FormItem>
          <Spacer size={20} />
          <FormItem>
            <Label bold>仮パスワード</Label>
            <Spacer size={10} />
            <Input type={'password'} {...register('password')} />

            {errors.password && touchedFields.password && (
              <div>
                <Spacer size={8} />
                <Message
                  type="error"
                  message="仮パスワードを入力してください"
                  centered
                />
              </div>
            )}
          </FormItem>

          <Spacer size={40} />

          <Button
            size="S"
            text="ログイン"
            type={'submit'}
            buttonType="important"
            onClick={() => handleSubmit(onSubmit)}
            disabled={!isValid || requesting}
          ></Button>

          {errorMessage && (
            <div>
              <Spacer size={16} />
              <Message type="error" message={errorMessage} />
            </div>
          )}
        </Form>
      </ContentContainer>
    </PageContainer>
  )
}
