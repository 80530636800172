import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions as userActions } from 'src/modules/entities/member/redux'
import { routes } from 'src/modules/routes'

export const useInit = (trialUid: string) => {
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(userActions.fetchList({ trialUid, type: 'all' }))
        setInitialized(true)
      } catch (error) {
        // TOOD: login key?
        console.error('userActions.fetchList Error: ', error)
        error.original.response.status === 403
          ? navigate(routes.httpError403)
          : navigate(`${routes.requiredLogin}`)
      }
    }

    fetch()
  }, [dispatch, navigate, setInitialized, trialUid])

  return {
    initialized,
  }
}
