import React, { useEffect } from 'react'

import {
  useLocation,
  Route,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { Spinner } from 'src/components/spinner/spinner'
import { Paths } from 'src/constants/paths'
import { Authenticator } from 'src/features/auth/components'
import {
  ResendDeliveryMail,
  SignAuthScreen,
} from 'src/features/explanation/components'
import { ExplanationRoomPatientSignInScreen } from 'src/features/explanation/components/ExplanationRoomPatientSignInScreen/ExplanationRoomPatientSignInScreen'
import { PatientPinSettingScreen } from 'src/features/explanation/components/PatientPinSettingScreen/PatientPinSettingScreen'
import { ExplanationRoomRoutes } from 'src/features/explanationRoom/routes'
import {
  ExternalTransmissionPolicyScreen,
  PrivacyPolicyScreen,
  TermsOfServiceScreen,
} from 'src/features/misc/components'
import { AuthenticatedTermsScreen } from 'src/features/misc/components/AuthenticatedTermsScreen/AuthenticatedTermsScreen'
import { OperationManualScreen } from 'src/features/misc/components/OperationManualScreen/OperationManualScreen'
import { ReleaseNoteScreen } from 'src/features/misc/components/ReleaseNoteScreen/ReleaseNoteScreen'
import { PatientVideoCallExpiredScreen } from 'src/features/patient/components/PatientVideoCallExpiredScreen/PatientVideoCallExpiredScreen'
import { PatientVideoCallForPatientScreen } from 'src/features/patient/components/PatientVideoCallForPatientScreen/PatientVideoCallForPatientScreen'
import { PatientVideoCallScreen } from 'src/features/patient/components/PatientVideoCallScreen/PatientVideoCallScreen'
import { PatientVideoCallSignInScreen } from 'src/features/patient/components/PatientVideoCallSignInScreen/PatientVideoCallSignInScreen'
import { ReferredPatientFileViewerScreen } from 'src/features/referredPatient/components/ReferredPatientFileViewerScreen/ReferredPatientFileViewerScreen'
import { WorksheetFileViewerScreen } from 'src/features/worksheet/components/WorksheetFileViewerScreen/WorksheetFileViewerScreen'

import { AgreementContainer } from './auth/agreement/agreement-container'
import {
  AuthenticateContainer,
  memberIdParamName,
  membersParamName,
  tokenParamName as AuthenticateTokenParamName,
} from './auth/authenticate/authenticate-container'
import { HttpError403 } from './auth/http-error/http-error-403'
import { LoginContainer } from './auth/login/login-container'
import { TemporaryPasswordLoginContainer } from './auth/login/temporary-password-login/temporary-password-login-container'
import { TwoFactorContainer } from './auth/login/two-factor/two-factor-container'
import { ForgetPasswordContainer } from './auth/password/forget/forget-password-container'
import { RegisterPasswordContainer } from './auth/password/register/register-password-container'
import { RequiredLogin } from './auth/required-login/reuqired-login'
import { DashboardScreen } from './dashboard/screen'
import { CompleteDocuSignAction } from './dashboard/trial/detail/e-consent/explanation-room/complete-docusign-action/complete-docusign-action'
import { PdfContainer as PatientPdfContainer } from './dashboard/trial/detail/patient/detail/output/pdf/container'
import { PdfContainer as WorksheetPdfContainer } from './dashboard/trial/detail/patient/detail/worksheet/common/detail/output/pdf/container'
import { worksheetIdParamName } from './dashboard/trial/detail/patient/detail/worksheet/common/detail/worksheet-detail'
import { patientIdParamName } from './dashboard/trial/detail/patient/detail/worksheet/worksheet'
import { PdfContainer as TrialPdfContainer } from './dashboard/trial/detail/patient/output/pdf/container'
import { trialIdParamName } from './dashboard/trial/detail/trial-detail'
import { EproPage } from './epro/trial/epro-page'
import { routes } from './routes'
import {
  DoctorVideoCallContainer,
  patientUidParamName,
} from './video-call/doctor-container'
import {
  PatientVideoCallContainer,
  peerIdParamName,
  trialUidParamName,
} from './video-call/patient-container'
import {
  VideoWithKeyContainer,
  videoKeyParamName,
} from './videos/video-with-key-container'
import { VideoWithQueryContainer } from './videos/video-with-query-container'
import { DeliveryDocUrlIsExpired } from '../features/explanation/components/DeliveryDocUrlIsExpired/DeliveryDocUrlIsExpired'

/** @deprecated import from src/hooks/use-query */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export enum QueryParamKeys {
  History = 'history',
  Worksheet = 'worksheet',
  CallHistory = 'callHistory',
  downloadFile = 'downloadFile',
}

export const stringToBool = (text: string | null) => {
  if (text === 'true') {
    return true
  }

  return false
}
export const stringToNum = (text: string | null) => {
  if (!text) {
    return null
  }
  if (Number.isNaN(text)) {
    return null
  }

  return Number(text)
}

const Empty: React.FC = () => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    // ログイン直後などにブラウザバックすると空白ページに留まり続けてしまうケースがあるので
    // 3 秒後に /dashboard にリダイレクトさせる
    const f = async () => {
      timeout = setTimeout(() => {
        window.location.assign('/dashboard')
      }, 3000)
    }
    f()
    return () => {
      if (timeout) {
        clearInterval(timeout)
      }
    }
  }, [])

  return <Spinner />
}

export const Router = (props: any) => {
  return <RouterProvider router={router} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={`${routes.login}`} element={<LoginContainer />} />
      <Route path={`${routes.twoFactor}`} element={<TwoFactorContainer />} />
      <Route path={Paths.ReleaseNote} element={<ReleaseNoteScreen />} />
      <Route path={Paths.OperationManual} element={<OperationManualScreen />} />
      <Route
        path={`${routes.temporaryPasswordLogin}`}
        element={<TemporaryPasswordLoginContainer />}
      />
      <Route path={`${routes.requiredLogin}`} element={<RequiredLogin />} />
      <Route path={`${routes.httpError403}`} element={<HttpError403 />} />
      <Route
        path={Paths.WorksheetFileViewer}
        element={
          <Authenticator>
            <WorksheetFileViewerScreen />
          </Authenticator>
        }
      />
      <Route
        path={Paths.ReferredPatientFileViewer}
        element={
          <Authenticator>
            <ReferredPatientFileViewerScreen />
          </Authenticator>
        }
      />
      <Route path={`${routes.dashboard}/*`} element={<DashboardScreen />} />
      <Route path={`${routes.epro}/*`} element={<EproPage />} />

      <Route
        path={Paths.ExplanationRoomPatientSignIn}
        element={<ExplanationRoomPatientSignInScreen />}
      />
      <Route
        path={`${Paths.ExplanationRoom}/*`}
        element={<ExplanationRoomRoutes />}
      />
      <Route
        path={routes.completeDocuSignAction}
        element={<CompleteDocuSignAction />}
      />
      <Route
        path={`${routes.trials}/:${trialIdParamName}/:${membersParamName}/:${memberIdParamName}/:${AuthenticateTokenParamName}`}
        element={<AuthenticateContainer />}
      />
      <Route
        path={`${routes.forgetPassword}`}
        element={<ForgetPasswordContainer />}
      />
      <Route
        path={`${routes.registerPassword}`}
        element={<RegisterPasswordContainer />}
      />
      <Route
        path={`${routes.worksheetPdf}/:${trialIdParamName}/:${patientIdParamName}/:${worksheetIdParamName}`}
        element={<WorksheetPdfContainer />}
      />
      <Route
        path={`${routes.patientPdf}/:${trialIdParamName}/:${patientIdParamName}`}
        element={<PatientPdfContainer />}
      />
      <Route
        path={`${routes.trialPdf}/:${trialIdParamName}`}
        element={<TrialPdfContainer />}
      />
      <Route
        path={`${routes.doctorVideoCall}/:${trialIdParamName}/:${patientUidParamName}/:${worksheetIdParamName}`}
        element={<DoctorVideoCallContainer />}
      />
      <Route
        path={`${routes.patientVideoCall}/:${trialUidParamName}/:${peerIdParamName}/:${patientUidParamName}`}
        element={<PatientVideoCallContainer />}
      />
      <Route
        path={Paths.PatientVideoCallSignIn}
        element={<PatientVideoCallSignInScreen />}
      />
      <Route
        path={Paths.PatientVideoCallForPatient}
        element={<PatientVideoCallForPatientScreen />}
      />
      <Route
        path={Paths.PatientVideoCall}
        element={<PatientVideoCallScreen />}
      />
      <Route
        path={Paths.PatientVideoCallExpired}
        element={<PatientVideoCallExpiredScreen />}
      />
      <Route path={`${routes.videos}`} element={<VideoWithQueryContainer />} />
      <Route
        path={`${routes.videos}/:${videoKeyParamName}`}
        element={<VideoWithKeyContainer />}
      />
      <Route path={routes.switch} element={<Empty />} />
      <Route path={routes.agreement} element={<AgreementContainer />} />
      <Route path={routes.termsOfService} element={<TermsOfServiceScreen />} />
      <Route
        path={routes.termsOfService2}
        element={<AuthenticatedTermsScreen />}
      />
      <Route path={routes.privacyPolicy} element={<PrivacyPolicyScreen />} />
      <Route
        path={routes.externalTransmissionPolicy}
        element={<ExternalTransmissionPolicyScreen />}
      />
      {/*TODO: remove*/}
      <Route
        path={routes.resendDeliveryMail}
        element={<ResendDeliveryMail />}
      />
      <Route
        path={routes.deliveryDocUrlIsExpired}
        element={<DeliveryDocUrlIsExpired />}
      />
      <Route
        path={Paths.ExplanationSignAuthentication}
        element={<SignAuthScreen />}
      />
      <Route
        path={Paths.PatientExplanationPinSetting}
        element={<PatientPinSettingScreen />}
      />
      <Route path={Paths.DocuSignRedirect} element={null} />
      <Route path="" element={<Navigate to={Paths.Dashboard} />} />
    </>,
  ),
)
