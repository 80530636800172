import { useEffect } from 'react'

export const useReloadWindow = () => {
  useEffect(() => {
    window.addEventListener('storage', (e: StorageEvent) => {
      reloadByLoginIdChange(e)
      reloadByVideoCallRoomTokenChange(e)
    })
    return () => {
      window.removeEventListener('storage', (e: StorageEvent) => {
        reloadByLoginIdChange(e)
        reloadByVideoCallRoomTokenChange(e)
      })
    }
  }, [])
}

const reloadByLoginIdChange = (event: StorageEvent) => {
  if (event.storageArea !== localStorage) return
  if (
    window.location.pathname === '/terms' ||
    window.location.pathname === '/privacy'
  )
    return
  if (event.key === 'login_id') {
    window.location.href = '/dashboard'
  }
}

// 同時に２つ以上のRoomに入室出来ないようにする
const reloadByVideoCallRoomTokenChange = (event: StorageEvent) => {
  const VIDEO_CALL_ROOM_URL_REGX = /\/j\/.*/
  if (event.storageArea !== localStorage) return
  if (event.key !== 'video_call_room_token') return

  // この event は、ビデオ通話画面のアクセスに起因するものか確認
  if (!event.url.match(VIDEO_CALL_ROOM_URL_REGX)) return

  // この event を受け取ったページがビデオ通話画面か確認
  if (!VIDEO_CALL_ROOM_URL_REGX.test(window.location.pathname)) return

  // この event を発生させたページと、この event を受け取ったページが同じURLか確認
  const token = window.location.pathname.replace('/j/', '')
  if (token === window.localStorage.getItem('video_call_room_token')) return

  window.location.href = '/dashboard'
}
