import { errorMessages } from 'src/constants/errorMessages'
import { Gender } from 'src/lib/gql-client'
import { isValidDateStr } from 'src/utils/isValidDateStr'
import { isValidYearRange } from 'src/utils/isValidYearRange'
import { hiraganaRegExp, phoneNumberRegExp } from 'src/utils/regExp'
import * as yup from 'yup'

export const patientSchema = (
  selectedTrialHospitalUid: string | null,
  partnerTrialHospitalUid?: string,
) =>
  yup.object({
    trialHospitalUid: yup.string().required(),
    diseaseId: yup.string().required(),
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    firstNameJa: yup
      .string()
      .matches(hiraganaRegExp, {
        excludeEmptyString: true,
        message: errorMessages.hiragana(),
      })
      .optional(),
    lastNameJa: yup
      .string()
      .matches(hiraganaRegExp, {
        excludeEmptyString: true,
        message: errorMessages.hiragana(),
      })
      .optional(),
    birthday: yup
      .string()
      .test('year range', errorMessages.yearRange(), value => {
        if (!value) return true
        const year = value.split('-')[0]
        if (!year) return true
        return isValidYearRange(year)
      })
      .test('is valid date', errorMessages.date(), value => {
        if (!value) return true
        const [year, month, day] =
          value.split('-').length === 3 ? value.split('-') : ['', '', '']
        return isValidDateStr({ year, month, day })
      })
      .optional(),
    gender: yup.mixed<Gender>().nullable(),
    phoneNumber: yup
      .string()
      .matches(phoneNumberRegExp, {
        excludeEmptyString: true,
        message: errorMessages.phoneNumber(),
      })
      .optional(),
    email: yup.string().email(errorMessages.email()).optional(),
    partnerTrialHospitalUid: yup.string().optional(),
    chartId: yup.string().optional(),
  })

export type PatientSchema = yup.InferType<ReturnType<typeof patientSchema>>
