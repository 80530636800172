import React from 'react'

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Image,
  Stack,
  useDisclosure,
  Link,
} from '@chakra-ui/react'
import { generatePath } from 'react-router-dom'
import Logo from 'src/assets/image/miroha-logo-2.svg'
import {
  OpenArrow,
  Account2,
  CheckFill,
} from 'src/components/__legacy__icon/monochrome'
import { ButtonLink } from 'src/components/base/text-link'
import { ExternalLink } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { LogoutModal } from 'src/modules/auth/logout/logout-modal'
import { MfaModalContainer } from 'src/modules/auth/mfa/mfa-modal-container'
import { ForgetPasswordModal } from 'src/modules/auth/password/forget/forget-password-modal'
import { SelectTrialContainer } from 'src/modules/dashboard/select-trial/select-trial-container'

import { useAccountInfo } from '../../hooks/useAccountInfo'
import { useMfa } from '../../hooks/useMfa'
import { useSelectHospital } from '../../hooks/useSelectHospital'
import { FeatureChannelLabel } from '../FeatureChannelLabel/FeatureChannelLabel'
import { NotificationButton } from '../NotificationButton/NotificationButton'
import { ReleaseListModal } from '../ReleaseListModal/ReleaseListModal'

export const HEADER_HEIGHT = 64 as const

export const Header: React.FC<{}> = () => {
  const {
    trialName,
    hospitalName,
    userName,
    role,
    hasMultipleMemberships,
    hospitals,
    featureChannel,
  } = useAccountInfo()

  const { handleSelectHospital } = useSelectHospital()

  const {
    QRSecretKey,
    isActiveMta,
    isOpenMfaModal,
    closeMfaModal,
    handleClickMfaMenu,
  } = useMfa()

  const { isOpen: isOpenSelectTrialModal, onOpen: openSelectTrialModal } =
    useDisclosure()

  const {
    isOpen: isOpenChangePasswordModal,
    onOpen: openChangePasswordModal,
    onClose: closeChangePasswordModal,
  } = useDisclosure()

  const {
    isOpen: isOpenLogOutModal,
    onOpen: openLogOutModal,
    onClose: closeLogOutModal,
  } = useDisclosure()

  const {
    isOpen: isOpenReleaseListModal,
    onOpen: openReleaseListModal,
    onClose: closeReleaseListModal,
  } = useDisclosure()

  return (
    <>
      <HStack
        as="header"
        h={`${HEADER_HEIGHT}px`}
        w="full"
        bg="headerGradation"
        align="center"
        justify="space-between"
        py={4}
        pl={4}
        pr={6}
      >
        <HStack spacing={4} align="center">
          <Image src={Logo} alt="MiROHAアイコン" />

          <Menu autoSelect={false}>
            <MenuButton>
              <HStack align="center" spacing={4}>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="white"
                  textAlign="left"
                >
                  {trialName}
                </Text>

                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="white"
                  textAlign="left"
                >
                  {hospitalName}
                </Text>
                <OpenArrow color={colors.white} cursor="pointer" />
              </HStack>
            </MenuButton>
            <MenuList w="352px">
              <HStack justify="space-between" px={2}>
                <Text fontSize="xs" fontWeight="bold" color="gray.400">
                  試験名
                </Text>
                {hasMultipleMemberships && (
                  <ButtonLink onClick={openSelectTrialModal}>
                    試験を切り替える
                  </ButtonLink>
                )}
              </HStack>
              <Text px={2}>{trialName}</Text>
              {role !== 'DM' && (
                <>
                  <MenuDivider />
                  <Text fontSize="xs" fontWeight="bold" color="gray.400" px={2}>
                    医療機関
                  </Text>
                  {hospitals.map(hospital => (
                    <MenuItem
                      key={hospital.uid}
                      onClick={() => handleSelectHospital(hospital.uid)}
                    >
                      {hospital.name}
                    </MenuItem>
                  ))}
                </>
              )}
            </MenuList>
          </Menu>
        </HStack>

        <HStack spacing={5}>
          <FeatureChannelLabel featureChannel={featureChannel} />
          <NotificationButton />
          <Menu autoSelect={false}>
            <MenuButton>
              <HStack spacing="3" align="center">
                <Account2 cursor="pointer" size="L" color={colors.blue[500]} />

                <Stack align="start" spacing={0}>
                  <Text fontSize="sm" fontWeight="bold" color="white">
                    {role}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="white"
                    wordBreak="keep-all"
                  >
                    {userName}
                  </Text>
                </Stack>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={openChangePasswordModal}>
                パスワード変更
              </MenuItem>
              <MenuItem onClick={handleClickMfaMenu}>
                <Text mr={2}>二段階認証</Text>
                {isActiveMta && (
                  <CheckFill size={20} color={colors.green[500]} />
                )}
              </MenuItem>
              <MenuItem onClick={openReleaseListModal}>バージョン情報</MenuItem>
              <MenuItem
                as={Link}
                isExternal
                href={generatePath(Paths.OperationManual)}
                _hover={{ textDecoration: 'none' }}
              >
                <HStack as="span">
                  <Text as="span">操作マニュアル</Text>
                  <ExternalLink />
                </HStack>
              </MenuItem>
              <MenuItem onClick={openLogOutModal} color="red.500">
                ログアウト
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>

      {isOpenSelectTrialModal && (
        <SelectTrialContainer redirectWithPathSelection={false} />
      )}
      {isOpenChangePasswordModal && (
        <ForgetPasswordModal onClose={closeChangePasswordModal} />
      )}
      {isOpenMfaModal && (
        <MfaModalContainer
          onClose={closeMfaModal}
          QRSecretKey={QRSecretKey}
          withMfa={isActiveMta}
        />
      )}
      {isOpenReleaseListModal && (
        <ReleaseListModal isOpen={true} onClose={closeReleaseListModal} />
      )}
      {isOpenLogOutModal && <LogoutModal onClose={closeLogOutModal} />}
    </>
  )
}
