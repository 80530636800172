import { Route, Routes } from 'react-router-dom'

import { Login } from './login'
import { QuestionnaireAnswerRouter } from './questionnaire-answer/router'

export const trialIdParamName = 'trialUid'

export const EproPage: React.FC = () => {
  return (
    <Routes>
      <Route path={`trials/:${trialIdParamName}/login`} element={<Login />} />
      <Route
        path={`trials/:${trialIdParamName}/questionnaire_answers/*`}
        element={<QuestionnaireAnswerRouter />}
      />
    </Routes>
  )
}
