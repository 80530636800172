import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { Edit } from 'src/components/__legacy__icon/monochrome'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { usePermission } from 'src/features/auth/context'
import { useActiveMembersByTrialHospitalUids } from 'src/features/member/api/getActiveTrialMembersByTrialHospitalUids'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'
import { sortByDate } from 'src/modules/util/sort'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useUpdateSchedule } from '../../api/updateSchedule'
import { ExplanationDetail } from '../../types'
import { willStartSession } from '../../utils/willStartSession'
import { ScheduleInput } from '../ScheduleInput/ScheduleInput'
import { ScheduleTooltip } from '../ScheduleTooltip/ScheduleTooltip'

type Props = {
  explanation: ExplanationDetail
  mutateExplanation: () => void
}

type FormValue = {
  scheduledAt: string
  notifyToPatient?: boolean
}

export const EditSchedule: React.FC<Props> = ({
  explanation,
  mutateExplanation,
}) => {
  const { hasPermission } = usePermission()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { handleSubmit, control, register, watch } = useForm<FormValue>({
    defaultValues: {
      scheduledAt: explanation.latestRevision.scheduledAt ?? '',
      notifyToPatient: true,
    },
  })

  const toast = useMirohaToast()

  const { request: updateSchedule } = useUpdateSchedule({
    onSuccess: () => {
      mutateExplanation()
      toast({
        status: 'success',
        title: '予約日時を変更しました',
      })
      onClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const { data: mainHospitalMembers } = useActiveMembersByTrialHospitalUids({
    trialHospitalUids: [explanation.trialHospital.uid],
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  if (!hasPermission(PERMISSIONS.Explanation_Edit)) return null
  if (!willStartSession(explanation.latestRevision.status)) return null
  if (explanation.latestRevision.type === 'InPerson') return null

  return (
    <>
      <IconButton
        variant="customIconButtonGhost"
        aria-label="予約日時変更"
        icon={<Edit />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <form
          onSubmit={handleSubmit(data => {
            updateSchedule({
              explanationRevisionUid: explanation.latestRevision.uid,
              scheduledAt: data.scheduledAt,
              notifyToPatient:
                (data.notifyToPatient &&
                  explanation.latestRevision.type === 'Remote') ??
                false,
            })
          })}
        >
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>予約日時変更</ModalHeader>
            <ModalBody>
              <Stack spacing="16">
                <Box>
                  <FormLabel
                    id="schedule-label"
                    display="flex"
                    alignItems="center"
                  >
                    予約日時
                    {explanation.latestRevision.type === 'Remote' && (
                      <Box ml="2">
                        <ScheduleTooltip />
                      </Box>
                    )}
                  </FormLabel>
                  <Controller
                    name="scheduledAt"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ScheduleInput
                        aria-labelledby="schedule-label"
                        value={value}
                        onChange={schedule => {
                          onChange(schedule)
                        }}
                        visibleClear={false}
                      />
                    )}
                  />
                  {explanation.latestRevision.type === 'Remote' && (
                    <Box>
                      <Checkbox
                        {...register('notifyToPatient')}
                        mt="4"
                        isDisabled={watch('scheduledAt') === ''}
                      >
                        患者に予約情報を即時通知する
                      </Checkbox>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    変更履歴
                  </Text>
                  <Box maxH="280px" overflow="scroll">
                    <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th>予約日時</Th>
                            <Th>操作日時</Th>
                            <Th>操作者</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sortByDate(
                            explanation.latestRevision.scheduleHistories ?? [],
                            'createdAt',
                            'desc',
                          ).map(history => (
                            <Tr key={history.uid}>
                              <Td>
                                {formatDate(
                                  history.scheduledAt,
                                  'YYYY/MM/DD (ddd) HH:mm',
                                )}
                              </Td>
                              <Td>
                                {formatDate(
                                  history.createdAt,
                                  'YYYY/MM/DD (ddd) HH:mm',
                                )}
                              </Td>
                              <Td maxW="240px">
                                <MemberLabel
                                  role={history.member.role}
                                  displayName={getFullName(history.member)}
                                  // mainへの所属なし === パートナー施設のメンバー
                                  isPartner={
                                    !mainHospitalMembers
                                      ?.map(m => m.uid)
                                      .includes(history.member.uid)
                                  }
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ModalCancelButton />
              <Button
                colorScheme="blue"
                type="submit"
                isDisabled={
                  !dayjs(watch('scheduledAt')).isValid() ||
                  watch('scheduledAt') ===
                    explanation.latestRevision.scheduledAt
                }
              >
                変更する
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  )
}
