import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'
import { useScreenOrientationAlert } from 'src/hooks/use-orientation-alert'
import styled from 'styled-components'

import { Explanation } from './explanation/explanation'
import { IcfDocument } from './icf-document/icf-document'
import { getRelativePath, PathKey } from '../../../../../constants/paths'

export const EConsent: React.FC = () => {
  const getPath = (path: PathKey) => {
    return getRelativePath('EConsent', path)
  }

  // eConsentでは画面を縦向きにした場合にアラートを出す
  useScreenOrientationAlert()

  return (
    <Wrapper>
      <Routes>
        <Route
          path={`${getPath('Explanations')}/*`}
          element={<Explanation />}
        />
        <Route
          path={`${getPath('IcfDocuments')}/*`}
          element={<IcfDocument />}
        />
        <Route
          path={``}
          element={<Navigate replace to={`${getPath('Explanations')}`} />}
        />
      </Routes>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  padding: 24px;
  overflow-y: auto;
`
