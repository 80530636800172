import React from 'react'

import { Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import {
  Circle,
  CircleOutline,
  Down,
  Up,
} from 'src/components/__legacy__icon/monochrome'
import { blue, gray, green } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import { Patient, PatientStatus } from 'src/modules/entities/patient/entity'
import styled from 'styled-components'

import { usePermission } from '../../../common/permission'
import { sortKeys } from '../sort'
import { usePatientQuery } from '../use-patient-query'

type Props = {
  patients: Patient[]
  trialUid: string
}

export const PatientTable: React.FC<Props> = props => {
  const { patients } = props
  const { hasPermission } = usePermission()
  const { sortKey: currentSortKey, sortOrder: currentSortOrder } =
    usePatientQuery()

  return (
    <Container>
      <Table>
        <Head>
          <HeadItems>
            <Spacer size={48} horizontal />

            <Grid>
              <GridItem>
                <Text fontWeight="bold">症例番号</Text>
                <Spacer size={10} horizontal />
                {currentSortKey === sortKeys.diseaseUid && (
                  <>{currentSortOrder === 'desc' ? <Down /> : <Up />}</>
                )}
              </GridItem>

              <GridItem>
                <Text fontWeight="bold">氏名</Text>
                <Spacer size={10} horizontal />
                {currentSortKey === sortKeys.name && (
                  <>{currentSortOrder === 'desc' ? <Down /> : <Up />}</>
                )}
              </GridItem>

              <GridItem>
                <Text fontWeight="bold">ふりがな</Text>
              </GridItem>

              <GridItem>
                {hasPermission(PERMISSIONS.Patient_ReadHospitals) ? (
                  <GridItem>
                    <Text fontWeight="bold">医療機関</Text>
                    <Spacer size={10} horizontal />
                    {currentSortKey === sortKeys.hospitalName && (
                      <>{currentSortOrder === 'desc' ? <Down /> : <Up />}</>
                    )}
                  </GridItem>
                ) : (
                  <WhiteSpace />
                )}
              </GridItem>
            </Grid>
          </HeadItems>
        </Head>

        <Body>
          <Scroll>
            {patients.map((p, i) => {
              const disabled = p.status === PatientStatus.Disable
              const textColor = disabled ? gray[55] : gray[100]

              return (
                <Row key={p.uid + i} to={`${p.uid}`}>
                  <Status>
                    {disabled ? (
                      <CircleOutline color={gray[55]} />
                    ) : (
                      <Circle color={green[40]} />
                    )}
                  </Status>

                  <Grid>
                    <GridItem>
                      <Text color={textColor}>{p.diseaseUid}</Text>
                    </GridItem>

                    <GridItem className="name">
                      <Text color={textColor}>
                        {p.lastName} {p.firstName}
                      </Text>
                    </GridItem>

                    <GridItem>
                      <Text color={textColor}>
                        {p.lastNameJa} {p.firstNameJa}
                      </Text>
                    </GridItem>

                    <GridItem>
                      {hasPermission(PERMISSIONS.Patient_ReadHospitals) ? (
                        <Text color={textColor}>{p.hospitalName}</Text>
                      ) : (
                        <WhiteSpace />
                      )}
                    </GridItem>
                  </Grid>
                </Row>
              )
            })}
          </Scroll>
        </Body>
      </Table>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
`

const Table = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border: 1px solid ${gray[40]};
  border-radius: 12px;
`

const Head = styled.div`
  width: 100%;
  border-bottom: 1px solid ${gray[40]};
`

const SCROLL_BAR_WIDTH = 6
const RIGHT_SPACE = 10 + SCROLL_BAR_WIDTH + 20 //Body部における、スクロールバーの外側（10）、本体、内側（20）のスペースを足した値
const HeadItems = styled.div`
  margin: 0 ${RIGHT_SPACE}px 0 20px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  position: relative;
  flex: 1;
  margin: 0 10px 0 0;
`

const Row = styled(NavLink)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  border-bottom: 1px dashed ${gray[40]};
  :hover {
    background-color: ${blue[10]};
  }
`

const Scroll = styled.div`
  position: absolute;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`

const WhiteSpace = styled.div``

const Grid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 25% 20% 25% 30%;
`

const GridItem = styled.div`
  display: flex;
  align-items: center;
`

const Status = styled.div`
  width: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
