import { HStack, Stack, Tooltip } from '@chakra-ui/react'
import { Path, UseFormRegister } from 'react-hook-form'
import { Hint } from 'src/components/__legacy__icon/monochrome'
import { blue } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'

interface FormValuesWithPassword {
  password: string
}

interface IProps<T extends FormValuesWithPassword> {
  register: UseFormRegister<T>
  isValid: boolean
}

export const NewPasswordInput = <T extends FormValuesWithPassword>({
  register,
  isValid,
}: IProps<T>) => {
  return (
    <Stack width="full" spacing="8px">
      <HStack align="center" spacing="8px">
        <Label bold>新しいパスワード</Label>
        <Tooltip
          label="半角英字（大文字、小文字をそれぞれ1文字以上ずつ）含む、8文字以上の半角英数字記号を入力してください。"
          hasArrow
        >
          <span>
            <Hint size="S" color={blue[70]} />
          </span>
        </Tooltip>
      </HStack>
      <Input
        type="password"
        width={1}
        placeholder="新しいパスワードを入力"
        {...register('password' as Path<T>)}
      />

      {!isValid && (
        <Message type="error" message="パスワードを入力してください" />
      )}
    </Stack>
  )
}
