import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { CallHistory } from './entity'

type CallHsitoryResponse = {
  uid: string
  participant_name: string
  leave_at: string
  created_at: string
}

const parse = (resp: CallHsitoryResponse): CallHistory => {
  return {
    uid: resp.uid,
    participantName: resp.participant_name,
    leaveAt: resp.leave_at,
    createdAt: resp.created_at,
  }
}

export const fetch = async ({
  worksheetUid,
}: {
  worksheetUid: string
}): Promise<CallHistory[]> => {
  if (IS_MOCK_MODE) {
    return []
  }

  const res = await requestGet<CallHsitoryResponse[]>({
    url: `${SERVER_URL}/worksheets/${worksheetUid}/video_call_room_entries`,
    params: {},
  })

  return res.data.map(parse)
}
