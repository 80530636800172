import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'

import { CloseArrow, OpenArrow } from '../__legacy__icon/monochrome'

type Props = {
  status: 'success' | 'error' | 'warning' | 'info'
  title: string
  description?: string
  defaultIsOpen?: boolean
}

export const FoldableAlert: React.FC<Props> = ({
  status,
  title,
  description,
  defaultIsOpen = true,
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  return (
    // px(py)={整数値}だとデフォルト値がうまく上書きされないのでsxで指定
    <Alert status={status} sx={{ padding: isOpen ? '16px' : '10px 16px' }}>
      <AlertIcon />
      <Box w="full">
        <AlertTitle fontSize="md">{title}</AlertTitle>
        {isOpen && !!description && (
          <AlertDescription>{description}</AlertDescription>
        )}
      </Box>
      {!!description && (
        <IconButton
          aria-label=""
          color="gray.400"
          variant="unstyled"
          minW="unset"
          w="24px"
          h="24px"
          display="grid"
          alignContent="center"
          onClick={onToggle}
          icon={isOpen ? <CloseArrow size="S" /> : <OpenArrow size="S" />}
        />
      )}
    </Alert>
  )
}
