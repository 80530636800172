import _ from 'lodash'

import { MediumItemAnswer, MediumItemAnswerStatus } from './entity'
import {
  parse as parseSmallItemAnswer,
  SmallItemAnswerResponse,
} from '../small-item-answer/request'

export type MediumItemAnswerResponse = {
  uid: string
  worksheet_uid: string
  medium_item_uid: string
  title: string
  folder_name: string
  form_name: string
  index: number
  is_logline: boolean
  line_number: number

  small_item_answers: SmallItemAnswerResponse[]
  template_uid: string
  status: MediumItemAnswerStatus
  has_medium_item_answer_log: boolean
  confirmation_status: number | null
}

export const parse = (resp: MediumItemAnswerResponse): MediumItemAnswer => {
  return {
    uid: resp.uid,
    title: resp.title,
    folderName: resp.folder_name,
    formName: resp.form_name,
    index: resp.index,
    status: resp.status,
    hasLog: resp.has_medium_item_answer_log,
    isLogline: resp.is_logline,
    lineNumber: resp.line_number,

    worksheetUid: resp.worksheet_uid,
    mediumItemUid: resp.medium_item_uid,

    smallItemAnswerMap: _.keyBy(
      resp.small_item_answers.map(parseSmallItemAnswer),
      'uid',
    ),

    confirmationStatus: resp.confirmation_status,
  }
}
