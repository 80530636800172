import { parseGqlMember } from 'src/features/member/api/parser'
import { Member } from 'src/features/member/types'
import {
  GqlError,
  GetExplanationTrialMembersDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationCacheKey } from '../cacheKey'

const getExplanationTrialMembers = async ({
  role,
}: {
  role: 'CRC' | 'Dr'
}): Promise<Member[]> => {
  try {
    const { latestExplanationRevisionsTrialMembers } = await graphqlRequest(
      GetExplanationTrialMembersDocument,
      {
        input: {
          Role: role,
        },
      },
    )
    return latestExplanationRevisionsTrialMembers.map(parseGqlMember)
  } catch (e) {
    throw e
  }
}

export const useExplanationTrialMembers = ({
  role,
  ...option
}: {
  role: 'CRC' | 'Dr'
} & SWRConfiguration<Member[], GqlError>) => {
  return useSWR<Member[], GqlError>(
    explanationCacheKey.getExplanationTrialMembers(role),
    () => getExplanationTrialMembers({ role }),
    option,
  )
}
