import {
  GqlError,
  GetSessionInBrowsingFlowQuery,
  GetSessionInBrowsingFlowDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type GetSessionInBrowsingFlowRes =
  GetSessionInBrowsingFlowQuery['explanationSession']

const getSessionInBrowsingFlow = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<GetSessionInBrowsingFlowRes> => {
  try {
    const res = await graphqlRequest(GetSessionInBrowsingFlowDocument, {
      explanationSessionUid,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionInBrowsingFlow = ({
  explanationSessionUid,
  ...option
}: { explanationSessionUid: string } & SWRConfiguration<
  GetSessionInBrowsingFlowRes,
  GqlError
>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionInBrowsingFlow(explanationSessionUid),
    () => getSessionInBrowsingFlow({ explanationSessionUid }),
    option,
  )
}
