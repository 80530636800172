import { useState } from 'react'

import { Button, Text, Stack, Box } from '@chakra-ui/react'
import PinInputImage from 'src/assets/image/pin-input.svg'
import { PatientSimpleScreenContainer } from 'src/components/PatientSimpleScreenContainer/PatientSimpleScreenContainer'
import { PinInput } from 'src/components/PinInput/PinInput'
import { useVerifyPin } from 'src/features/explanation/api/verifyPin'
import { PATIENT_PIN_LENGTH } from 'src/features/explanation/constants/pinSetting'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { getVerifyPinErrorMessage } from '../../utils/getVerifyPinErrorMessage'

type Props = {
  trialUid: string
  explanationSessionUid: string
  challengeSignAuth: () => void
}

export const PinVerification = ({
  trialUid,
  explanationSessionUid,
  challengeSignAuth,
}: Props) => {
  const toast = useMirohaToast()
  const [pinCode, setPinCode] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const { request } = useVerifyPin({
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
    onSuccess: ({ result }) => {
      const errorMessage = getVerifyPinErrorMessage(result)
      if (errorMessage !== undefined) {
        setPinCode('')
        setErrorMessage(errorMessage)
        return
      }
      challengeSignAuth()
    },
  })

  const canConfirm = pinCode?.length === PATIENT_PIN_LENGTH && !errorMessage

  return (
    <PatientSimpleScreenContainer>
      <Stack mb="4" textAlign="center">
        <img src={PinInputImage} alt="暗証番号の入力" />
      </Stack>

      <Stack spacing="8">
        <Text
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
        >{`署名を開始するため\n当人認証を行います`}</Text>
        <Text align="center">はじめに4ケタの暗証番号を入力してください。</Text>
        <Stack spacing="2">
          <PinInput
            length={PATIENT_PIN_LENGTH}
            value={pinCode}
            onChange={pinCode => {
              if (!!errorMessage) {
                setErrorMessage(undefined)
              }
              setPinCode(pinCode)
            }}
          />
          {errorMessage !== undefined && (
            <Text color="red.500">{errorMessage}</Text>
          )}
        </Stack>
        <Box>
          <Stack alignItems="center" spacing="4">
            <Button
              colorScheme="green"
              size="sp"
              onClick={() => {
                if (!pinCode) return
                request({
                  pin: pinCode,
                  explanationSessionUid,
                  trialUid: trialUid,
                })
              }}
              isDisabled={!canConfirm}
            >
              確定
            </Button>
          </Stack>
        </Box>
      </Stack>
    </PatientSimpleScreenContainer>
  )
}
