import { createSelector } from 'reselect'
import { Account } from 'src/modules/entities/account/entity'
import { TrialHospitalsByUid } from 'src/modules/entities/account/util'

import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.account)

export const getCurrentUser = createSelector(getState, state => {
  if (!state.uid) return null

  const account: Account = {
    uid: state.uid,
    email: state.email,
    firstName: state.firstName,
    lastName: state.lastName,
    hasMultipleMemberships: state.hasMultipleMemberships,
    selectedTrial: state.selectedTrial,
  }

  return account
})

export const getSelectedTrial = createSelector(getState, state => {
  return state.selectedTrial
})

export const getTrialHospitalsByUid = createSelector(getState, state => {
  const { selectedTrial } = state
  if (!selectedTrial) return {}

  const trialHospitalsByUid: TrialHospitalsByUid = {}
  selectedTrial.trialHospitals.forEach(hospital => {
    trialHospitalsByUid[hospital.uid] = hospital
  })

  return trialHospitalsByUid
})
