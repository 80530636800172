import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  DeleteStickyDocument,
  DeleteStickyInput,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const deleteSticky = async (input: DeleteStickyInput) => {
  try {
    await graphqlRequest(DeleteStickyDocument, { input })
  } catch (error) {
    throw error
  }
}

export const useDeleteSticky = (option?: UseMutationOption<void, GqlError>) => {
  return useMutation((input: DeleteStickyInput) => deleteSticky(input), option)
}
