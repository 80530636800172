/**
 * 新しいウィンドウを開く関数です。
 *
 * @param url - 開くURL
 * @param windowType - 'tab' は新しいタブで、'newWindow' は新しいウィンドウで開く。デフォルトは 'tab' 。
 * @returns 開かれたウィンドウへの参照。新しいタブやウィンドウが開かれなかった場合はnullが返る
 */
export const openNewWindow = (
  url: string,
  windowType: 'tab' | 'newWindow' = 'tab',
) => {
  if (windowType === 'tab') {
    return window.open(url, '_blank')
  }

  const screenWidth = window.screen.width
  const screenHeight = window.screen.height

  // 画面領域の70%の高さと幅で画面中央に表示する
  const width = screenWidth * 0.7
  const height = screenHeight * 0.7
  const top = (screenHeight - height) / 2 + window.screenY
  const left = (screenWidth - width) / 2 + window.screenX

  const features = `width=${width},height=${height},top=${top},left=${left}`

  return window.open(url, '_blank', features)
}
