import {
  GqlError,
  SendAgreementFormEnvelopeEmailDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const sendAgreementFormEnvelopeEmail = async ({
  explanationDocRevisionUid,
  explanationSessionUid,
  signerActorUids,
}: {
  explanationDocRevisionUid: string
  explanationSessionUid: string
  signerActorUids: string[]
}): Promise<void> => {
  try {
    await graphqlRequest(SendAgreementFormEnvelopeEmailDocument, {
      input: {
        explanationDocRevisionUid,
        explanationSessionUid,
        signerActorUids,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useSendAgreementFormEnvelopeEmail = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationDocRevisionUid,
      explanationSessionUid,
      signerActorUids,
    }: {
      explanationDocRevisionUid: string
      explanationSessionUid: string
      signerActorUids: string[]
    }) =>
      sendAgreementFormEnvelopeEmail({
        explanationDocRevisionUid,
        explanationSessionUid,
        signerActorUids,
      }),
    option,
  )
}
