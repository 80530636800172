import { useMemo } from 'react'

import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useLocation, useNavigate, Link as RRLink } from 'react-router-dom'
import { PaginationNext } from 'src/components/__legacy__icon/monochrome'
import { Followup } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { httpStatus } from 'src/constants/statusCodes'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { usePatient } from 'src/features/patient/api'
import { PatientDetailBox } from 'src/features/patient/components'
import { WorksheetListAccordion } from 'src/features/worksheet/components'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PERMISSIONS } from 'src/lib/permission'

type Props = {
  patientUid: string
  children: React.ReactNode
}

export const PatientDetailLayout: React.FC<Props> = ({
  patientUid,
  children,
}) => {
  const location = useLocation()

  const selectedWorksheetUid = location.pathname.includes('worksheets')
    ? (location.pathname.split('/').pop() ?? null)
    : null

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { hasPermission } = usePermission()

  const navigate = useNavigate()

  const { data: patient } = usePatient({
    patientUid,
    revalidateIfStale: false,
    onSuccess: patient => {
      if (hasPermission(PERMISSIONS.Hospital_BelongAll)) {
        return
      }
      // 参照権限はあっても選択している医療機関で閲覧権限がない場合はDashboardにリダイレクトする
      if (
        patient.trialHospital.uid !== selectedTrialHospitalUid &&
        patient.partnerTrialHospital?.uid !== selectedTrialHospitalUid
      ) {
        navigate(Paths.Dashboard)
      }
    },
    onError: error => {
      if (error.status === httpStatus.Forbidden) {
        // 患者の参照権限がない場合 Dashboardにリダイレクトする（患者一覧に戻す）
        navigate(Paths.Dashboard)
      }
      console.error(error)
    },
  })

  const needActionFollowUpCount = useMemo(() => {
    if (!patient) return 0
    const worksheets = patient.observationFollowUps.flatMap(
      followUp => followUp.worksheets,
    )
    const needActionWorksheets = worksheets.filter(
      w =>
        w.latestWorksheetLog.status === 'Created' ||
        w.latestWorksheetLog.status === 'Saved',
    )
    return needActionWorksheets.length
  }, [patient])

  const isFollowUp = useMemo(() => {
    if (!patient) return false
    return patient.observationFollowUps.some(f =>
      f.worksheets.some(w => w.worksheetUid === selectedWorksheetUid),
    )
  }, [patient, selectedWorksheetUid])

  if (!patient) return null

  return (
    <Flex h="full">
      {/* 2column目 */}
      <Flex w="320px" h="full" direction="column">
        <HStack p="4" pb="2" align="center">
          <Link
            as={RRLink}
            to="../"
            color="blue.500"
            fontSize="sm"
            fontWeight="bold"
          >
            被験者一覧
          </Link>
          <PaginationNext size="M" color={colors.gray[500]} />
          <Text fontSize="sm" fontWeight="bold">
            被験者情報
          </Text>
        </HStack>
        <PatientDetailBox patientUid={patientUid} />

        <Tabs defaultIndex={isFollowUp ? 1 : 0}>
          <TabList mx="4">
            <Tab>Visit</Tab>
            <Tab position="relative">
              {needActionFollowUpCount > 0 && (
                <Center
                  position="absolute"
                  right="0"
                  top="0"
                  w="4"
                  h="4"
                  borderRadius="full"
                  bg="red.500"
                  color="white"
                  fontSize="xs"
                >
                  {needActionFollowUpCount}
                </Center>
              )}
              <Flex align="center" gap="1.5">
                <Box color="gray.500">
                  <Followup />
                </Box>
                随時観察
              </Flex>
            </Tab>
          </TabList>
          <TabPanels pt="2" overflowY="scroll" maxHeight="calc(100vh - 332px)">
            <TabPanel p="0">
              <WorksheetListAccordion
                observationType="Visit"
                patientUid={patientUid}
                selectedWorksheetUid={selectedWorksheetUid}
              />
            </TabPanel>
            <TabPanel p="0">
              <WorksheetListAccordion
                observationType="FollowUp"
                patientUid={patientUid}
                selectedWorksheetUid={selectedWorksheetUid}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <Divider orientation="vertical" />

      {/* メイン部分 */}
      <Box flex="1" overflow="auto">
        {children}
      </Box>
    </Flex>
  )
}
