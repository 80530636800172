import React, { useCallback, useEffect } from 'react'

import { Box, Button, Center, Flex, HStack, IconButton } from '@chakra-ui/react'
import { RemoteUser, RoomSessionStatus } from '@micin-jp/call-lib-core'
import {
  useLocalPlayer,
  useRemotePlayer,
  useRoomSession,
  useRoomUsers,
} from '@micin-jp/call-lib-react'
import { Expand, Shrink } from 'src/components/__legacy__icon/monochrome'
import { CameraOff, CameraOn, MicOff, MicOn } from 'src/components/icon'
import { Anchor } from 'src/components/link'
import { useVolumeMeter } from 'src/features/videoCall/hooks/useVolumeMeter'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useRoomMediaContext } from '../../context/MediaProvider'

export const FloatingVideoPlayer: React.FC<{ isOldFlow?: boolean }> = ({
  isOldFlow,
}) => {
  const { remoteUsers } = useRoomUsers()
  const { sessionStatus } = useRoomSession()
  const {
    toggleMicOnOff,
    localMicEnabled,
    toggleCameraOnOff,
    localCameraEnabled,
  } = useLocalPlayer()

  const {
    videoExpanded: floatingVideoExpanded,
    toggleVideoExpanded: toggleFloatingVideo,
  } = useRoomMediaContext()

  return (
    <Flex justify="end">
      {/* 閉じている場合 */}
      <HStack
        display={floatingVideoExpanded ? 'none' : 'flex'}
        borderRadius="sm"
        p="2"
        bg="gray.800"
        spacing="4"
      >
        <IconButton
          aria-label="マイクをオン/オフにする"
          variant="customIconButtonSolid"
          w="32px"
          h="32px"
          colorScheme={localMicEnabled ? 'green' : 'red'}
          icon={localMicEnabled ? <MicOn /> : <MicOff />}
          isDisabled={sessionStatus !== RoomSessionStatus.Incall}
          onClick={() => toggleMicOnOff()}
        />

        <Anchor display="inline-block" onClick={toggleFloatingVideo}>
          <Expand size="L" color={colors.green[500]} cursor="pointer" />
        </Anchor>
      </HStack>

      {/* 開いている場合 */}
      <Box
        h="auto"
        w="240px"
        bg="gray.800"
        color="white"
        borderRadius="sm"
        display={floatingVideoExpanded ? 'block' : 'none'}
      >
        <Box>
          {/* 通話中ではなくても（join前でも）remoteUserが取得できるが、joinするまで非表示にするためにこの制御を入れている */}
          {sessionStatus === RoomSessionStatus.Incall &&
            remoteUsers.map(u => (
              <RemoteUserPlayer key={u.id} remoteUser={u} />
            ))}
          <LocalUserPlayer isOldFlow={isOldFlow} />
        </Box>

        <Flex
          borderRadius="sm"
          bg="gray.800"
          justify="space-between"
          p="2"
          align="center"
        >
          <Flex align="center" gap={4}>
            <IconButton
              aria-label="マイクをオン/オフにする"
              variant="customIconButtonSolid"
              w="32px"
              h="32px"
              colorScheme={localMicEnabled ? 'green' : 'red'}
              isDisabled={sessionStatus !== RoomSessionStatus.Incall}
              icon={localMicEnabled ? <MicOn /> : <MicOff />}
              onClick={() => toggleMicOnOff()}
            />
            <IconButton
              aria-label="カメラをオン/オフにする"
              variant="customIconButtonSolid"
              w="32px"
              h="32px"
              colorScheme={localCameraEnabled ? 'green' : 'red'}
              isDisabled={sessionStatus !== RoomSessionStatus.Incall}
              icon={localCameraEnabled ? <CameraOn /> : <CameraOff />}
              onClick={toggleCameraOnOff}
            />
          </Flex>

          <Anchor display="inline-block" onClick={toggleFloatingVideo}>
            <Shrink size="L" color={colors.green[500]} cursor="pointer" />
          </Anchor>
        </Flex>
      </Box>
    </Flex>
  )
}

const RemoteUserPlayer: React.FC<{ remoteUser: RemoteUser }> = ({
  remoteUser,
}) => {
  const { playAudio, attachVideo, cameraEnabled, micEnabled, audioTrack } =
    useRemotePlayer(remoteUser)
  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachVideo(elm, { fit: 'contain' }),
    [attachVideo],
  )

  useEffect(() => {
    playAudio()
  }, [playAudio])

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  return (
    <Box
      pos="relative"
      borderBottom="1px solid"
      borderColor="gray.400"
      w="240px"
      h="135px"
    >
      <Box
        pos="absolute"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        zIndex="1"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      {cameraEnabled ? (
        <Center ref={videoRef} w="full" h="full" />
      ) : (
        <Center w="full" h="full">
          <CameraOff />
        </Center>
      )}
      <NameLabel micEnabled={micEnabled}>{remoteUser.name}</NameLabel>
    </Box>
  )
}

const LocalUserPlayer: React.FC<{ isOldFlow?: boolean }> = ({ isOldFlow }) => {
  const {
    attachLocalVideo,
    localCameraEnabled,
    requestLocalAudio,
    requestLocalVideo,
    localAudioTrack: audioTrack,
  } = useLocalPlayer()
  const { sessionStatus, joinRoom } = useRoomSession()

  const { participantName } = useRoomMediaContext()

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachLocalVideo(elm, { fit: 'contain' }),
    [attachLocalVideo],
  )

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  return (
    <Box borderBottom="1px solid" borderColor="gray.400">
      <Box w="240px" h="135px" pos="relative">
        {sessionStatus === RoomSessionStatus.Ready ? (
          <Center w="full" h="full">
            <Button
              colorScheme="green"
              onClick={async () => {
                await joinRoom({ name: participantName ?? '' })
                if (isOldFlow) {
                  await requestLocalAudio()
                  await requestLocalVideo()
                }
              }}
            >
              通話開始
            </Button>
          </Center>
        ) : (
          <Center
            h="full"
            w="full"
            ref={videoRef}
            display={localCameraEnabled ? 'block' : 'none'}
            transform="scaleX(-1)"
          />
        )}
        {!localCameraEnabled && sessionStatus === RoomSessionStatus.Incall && (
          <Center pos="absolute" left={0} top={0} h="120px" w="full">
            <CameraOff />
          </Center>
        )}
        <NameLabel>あなた</NameLabel>
        {/* speaking時の青枠 */}
        <Box
          pos="absolute"
          left="0"
          top="0"
          w="full"
          h="full"
          border="4px"
          display={isSpeaking ? 'block' : 'none'}
          borderColor={isSpeaking ? 'blue.500' : 'transparent'}
        />
      </Box>
    </Box>
  )
}

const NameLabel: React.FC<{
  micEnabled?: boolean
  children: React.ReactNode
}> = ({ micEnabled = true, children }) => {
  return (
    <HStack
      pos="absolute"
      left="2"
      bottom="2"
      h="24px"
      align="center"
      maxW="220px"
      p="1"
      bg="gray.600"
      borderRadius="sm"
      fontSize="xx-small"
    >
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {children}
      </Box>
      {!micEnabled && (
        <Box w="24px">
          <MicOff size="12px" />
        </Box>
      )}
    </HStack>
  )
}
