import React from 'react'

import { IcfDocumentSetList } from './list'
import { useIcfDocumentSetList } from './list.hooks'

export const IcfDocumentSetListContainer: React.FC = () => {
  const {
    icfDocumentSetList,
    canAddDocumentSet,
    navigateToAdd,
    navigateToDetail,
  } = useIcfDocumentSetList()

  return (
    <IcfDocumentSetList
      icfDocumentSetList={icfDocumentSetList}
      canAddDocumentSet={canAddDocumentSet}
      navigateToAdd={navigateToAdd}
      navigateToDetail={navigateToDetail}
    />
  )
}
