import { useState } from 'react'

import {
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Check } from 'src/components/icon'
import { PinInput } from 'src/components/PinInput/PinInput'
import { useSetPin, useSetPinForPatient } from 'src/features/explanation/api'
import { PATIENT_PIN_LENGTH } from 'src/features/explanation/constants/pinSetting'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

type Props = {
  isSP?: boolean
  isPatientScreen?: boolean
  isOpen: boolean
  explanationRevisionUid: string
  trialUid: string
  onClose: () => void
  /** PIN設定完了後、OKを押した際に実行されるcallback */
  onConfirm: () => void
}

export const PinSettingModal: React.FC<Props> = ({
  isSP = false,
  isPatientScreen = false,
  isOpen,
  explanationRevisionUid,
  trialUid,
  onClose,
  onConfirm,
}) => {
  const [step, setStep] = useState<'SettingPin' | 'ConfirmPin' | 'Confirmed'>(
    'SettingPin',
  )
  const [inputPin, setInputPin] = useState<string>()
  const [confirmedPin, setConfirmedPin] = useState<string>()

  const { request: submitPin } = useSetPin({
    onSuccess: () => {
      setStep('Confirmed')
    },
  })
  const { request: submitPinForPatient } = useSetPinForPatient({
    onSuccess: () => {
      setStep('Confirmed')
    },
  })
  const handleSubmit = async () => {
    if (!inputPin || !isMatched) return

    if (isPatientScreen) {
      await submitPinForPatient({
        explanationRevisionUid,
        trialUid,
        code: inputPin,
        method: 'OnScreen',
      })
      return
    }

    await submitPin({ explanationRevisionUid, pin: inputPin })
  }

  const handleForwardStep = () => {
    setStep('ConfirmPin')
  }

  const reset = () => {
    setStep('SettingPin')
    setInputPin(undefined)
    setConfirmedPin(undefined)
  }

  const isMatched = inputPin === confirmedPin

  const title =
    step === 'Confirmed'
      ? '暗証番号の設定が完了しました'
      : 'あなたの暗証番号を設定します'

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
      closeOnOverlayClick={false}
      size={isSP ? 'sm' : '2xl'}
    >
      <ModalOverlay />
      <ModalContent px={isSP ? '16px' : '84px'} py={isSP ? '32px' : '60px'}>
        <ModalCloseButton />
        <ModalHeader
          textAlign="center"
          fontSize={isSP ? 'lg' : '2xl'}
          px={isSP ? '0' : undefined}
        >
          {title}
        </ModalHeader>

        {step === 'SettingPin' && (
          <Stack spacing="12" mt="12">
            <Text display="inline" mx="auto" fontSize={isSP ? 'lg' : '2xl'}>
              お好きな4桁の数字を入力して下さい
            </Text>

            <PinInput
              length={PATIENT_PIN_LENGTH}
              size="lg"
              value={inputPin}
              onChange={setInputPin}
            />
            <Center>
              <Text fontSize="xl">
                {`暗証番号は説明の署名時に利用しますので、\n忘れないようメモなどにお控えください。`}
              </Text>
            </Center>
            <Flex w="full" justify="center">
              <Button
                colorScheme="green"
                fontSize={isSP ? 'md' : 'xl'}
                onClick={handleForwardStep}
                isDisabled={inputPin?.length !== PATIENT_PIN_LENGTH}
                w={isSP ? 'full' : '240px'}
                h={isSP ? '52px' : '64px'}
              >
                次へ
              </Button>
            </Flex>
          </Stack>
        )}

        {step === 'ConfirmPin' && (
          <Stack spacing="12" mt="12">
            <Text fontSize={isSP ? 'lg' : '2xl'} display="inline" mx="auto">
              確認のため、先ほど入力された数字をもう一度ご入力ください。
            </Text>
            <Stack>
              <HStack spacing="6" w="full" justify="center">
                <PinInput
                  length={PATIENT_PIN_LENGTH}
                  size="lg"
                  value={confirmedPin}
                  onChange={setConfirmedPin}
                />
                {isMatched && <Check size="48px" color={colors.green[500]} />}
              </HStack>
              {!isMatched && confirmedPin?.length === PATIENT_PIN_LENGTH && (
                <Center>
                  <Text color="red.500">
                    入力された暗証番号が一致しません。
                    <br />
                    暗証番号が不明な場合、最初から設定してください。
                  </Text>
                </Center>
              )}
            </Stack>
            <Stack spacing="4" align="center">
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                fontSize={isSP ? 'md' : 'xl'}
                isDisabled={!isMatched}
                w={isSP ? 'full' : '240px'}
                h={isSP ? '52px' : '64px'}
              >
                確定
              </Button>
              <Button
                variant="ghost"
                fontSize={isSP ? 'md' : 'xl'}
                colorScheme="gray"
                onClick={reset}
                w={isSP ? 'full' : '240px'}
                h={isSP ? '52px' : '64px'}
              >
                戻る
              </Button>
            </Stack>
          </Stack>
        )}

        {step === 'Confirmed' && (
          <Stack spacing="12" mt="12">
            {!isPatientScreen && (
              <Text fontSize="2xl" display="inline" mx="auto">
                医療スタッフに端末をお返しください。
              </Text>
            )}
            <Flex w="full" justify="center">
              <Button
                colorScheme="green"
                fontSize={isSP ? 'md' : 'xl'}
                onClick={() => {
                  reset()
                  onConfirm()
                }}
                w={isSP ? 'full' : '240px'}
                h={isSP ? '52px' : '64px'}
              >
                OK
              </Button>
            </Flex>
          </Stack>
        )}
      </ModalContent>
    </Modal>
  )
}
