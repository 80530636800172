import { Checkbox, CheckboxGroup, VStack } from '@chakra-ui/react'

import { FieldOf } from '../../utils'
import { ChakraFieldFormControl } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Checkbox'>
  value?: string[]
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string[] | undefined) => void
  errorMessage?: string
  warnMessage?: string
}
export const CheckboxFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  return (
    <ChakraFieldFormControl
      field={field}
      asFieldset
      isDisabled={isDisabled || isPreview}
      isRequired={field.typeDef.validation?.required}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <CheckboxGroup
        value={value ?? []}
        onChange={value => {
          if (value.length === 0) {
            onChange?.(undefined)
            return
          }
          onChange?.(value as string[])
        }}
      >
        <VStack
          spacing="3"
          align="start"
          sx={{
            // 無効な場合も常に通常色を表示する 本来はisReadOnlyなどを使って制御したい
            '.chakra-checkbox__label': {
              opacity: '1 !important',
            },
          }}
        >
          {field.typeDef.choices.map(choice => (
            <Checkbox key={choice.cid} value={choice.cid}>
              {choice.name}
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
    </ChakraFieldFormControl>
  )
}
