import { ButtonHTMLAttributes } from 'react'

import {
  Account,
  Check,
  Calendar,
  Close,
  Edit,
  Expand2,
  Expand3,
  MoveRight,
  MoveLeft,
  PaginationBack,
  PaginationEnd,
  PaginationNext,
  PaginationTop,
  Sort,
  SortLabelAscend,
  SortLabelDescend,
  Time,
  Trash,
} from 'src/components/__legacy__icon/monochrome'
import styled from 'styled-components'

import { blue, gray, Palette } from '../color/palette'

// 単独でボタンになりうるアイコンを定義する場合はここに追加する
const icons = {
  account: Account,
  calendar: Calendar,
  check: Check,
  close: Close,
  edit: Edit,
  expand2: Expand2,
  expand3: Expand3,
  moveRight: MoveRight,
  moveLeft: MoveLeft,
  paginationBack: PaginationBack,
  paginationEnd: PaginationEnd,
  paginationNext: PaginationNext,
  paginationTop: PaginationTop,
  sort: Sort,
  sortLabelAscend: SortLabelAscend,
  sortLabelDescend: SortLabelDescend,
  time: Time,
  trash: Trash,
} as const

type IconName = keyof typeof icons

type Props = {
  iconName: IconName
  color?: Palette
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number
} & ButtonHTMLAttributes<HTMLButtonElement>

export const IconButton: React.FC<Props> = ({
  iconName,
  color,
  size,
  children,
  ...rest
}) => {
  const Icon = icons[iconName]

  return (
    <Button color={color} {...rest}>
      <Icon size={size ?? 'S'} />
    </Button>
  )
}

const Button = styled.button<{ color?: Palette }>`
  all: unset;

  color: ${p => p.color ?? blue[70]};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;

  :hover {
    background: ${gray[5]};
  }
  :active {
    background: ${gray[10]};
  }
  :disabled {
    color: ${gray[40]};
    background: none;
    cursor: not-allowed;
  }
`
