export const TRIAL_HOSPITAL_PARAM_KEY = 'trial_hospital'

export const generateTrialHospitalParam = (param: string) => {
  const searchParam = new URLSearchParams()
  searchParam.set(TRIAL_HOSPITAL_PARAM_KEY, param)
  return searchParam.toString()
}

export const generatePathWithTrialHospitalParam = (
  path: string,
  param: string | undefined,
) => {
  return param ? `${path}?${generateTrialHospitalParam(param)}` : path
}
