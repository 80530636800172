import { HttpResponse } from 'msw'
import {
  GetWorksheetDocument,
  GetWorksheetQuery,
  GetWorksheetQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

import { worksheetWithSchemaResponseFactory } from '../../testUtils/factory'

export const getWorksheetHandler = graphQLHandlerFactory<
  GetWorksheetQuery,
  GetWorksheetQueryVariables
>('query', GetWorksheetDocument, ({ variables }) => {
  return HttpResponse.json({
    data: {
      worksheet: worksheetWithSchemaResponseFactory({
        worksheetUid: variables.worksheetUid,
      }),
    },
  })
})
