import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Trial } from 'src/features/trial/types'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useResendAuthCodeByVoice } from '../../api/resendAuthCodeByVoice'

type Props = {
  trialUid: Trial['uid']
  explanationSignAuthUid: string
}

export const SendAuthCodeByVoice: React.FC<Props> = ({
  trialUid,
  explanationSignAuthUid,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useMirohaToast()

  const { request } = useResendAuthCodeByVoice({
    onSuccess: () => {
      toast({
        title: '音声通話で認証コードを通知します',
        status: 'success',
      })
      onClose()
    },
    onError: e => {
      toast({
        title: e.message,
        status: 'error',
      })
    },
  })

  return (
    <>
      <Button variant="text" onClick={onOpen}>
        SMSが届かない方はこちら
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize="lg" fontWeight="bold">
              自動音声による認証コードを送信します
            </Text>
          </ModalHeader>
          <ModalBody>
            <Stack spacing="4">
              <Text>自動音声通話で認証コードを自動音声にてお伝えします。</Text>
              <Text>
                「認証コードを通知する」ボタンを押下すると、ご登録の携帯電話番号に着信があり、自動音声で認証コードが流れます。
              </Text>
              <Text>認証コード入力画面で案内の番号をご入力ください。</Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="green"
              onClick={async () => {
                await request({ trialUid, explanationSignAuthUid })
              }}
            >
              認証コードを通知する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
