// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgArrowUp = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="m16.7 10.8-7-7c-.2-.2-.5-.3-.7-.3-.2 0-.5.1-.7.3l-7 7c-.3.3-.4.7-.2 1.1.2.4.5.6.9.6h14c.4 0 .8-.2.9-.6.1-.4.1-.8-.2-1.1Z"
    />
  </svg>
)
export default SvgArrowUp
