import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { SERVER_URL } from '../../../../../../../server/const'
import { requestPut } from '../../../../../../../server/request'

export const updateSmallItemVisibility = async ({
  trialUid,
  templateUid,
  smallItemUids,
  trialHospitalUids,
}: {
  trialUid: string
  templateUid: string
  smallItemUids: string[]
  trialHospitalUids: string[]
}) => {
  if (IS_MOCK_MODE) {
    return
  }

  const params = {
    small_item_uids: smallItemUids,
    trial_hospital_uids: trialHospitalUids,
  }

  await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${templateUid}/small_items/visibility`,
    params: params,
  })
}
