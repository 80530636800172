import React from 'react'

import { colors } from 'src/assets/colors'
import styled from 'styled-components'

type Props = {
  message: string
}

export const ErrorPage: React.FC<Props> = props => {
  return (
    <PageContainer>
      <ContentContainer>
        <Text>{props.message}</Text>
      </ContentContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.bgGray};
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 80px;
  padding: 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(133, 141, 168, 0.06);
  border-radius: 10px;
`

const Text = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  margin: 0;
`
