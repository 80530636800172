import React, { useCallback } from 'react'

import { ScheduleCreateRequest } from 'src/modules/entities/schedule/request'

import { Add } from './add'
import { useSubmitRegister } from './use-submit-register'

type Props = {
  closePane: () => void
}

export const AddContainer: React.FC<Props> = props => {
  const { request, requesting, errorMessage } = useSubmitRegister()
  const onSubmit = useCallback(
    async (params: ScheduleCreateRequest) => {
      await request(params)
      props.closePane()
    },
    [props, request],
  )

  return (
    <Add
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
      closePane={props.closePane}
    />
  )
}
