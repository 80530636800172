import { CrfSection, CrfTemplate, CrfFolderTemplates } from '../entity'

type PreviewData = {
  templateFolderName: string
  templateData: PreviewTemplateData[]
}
type PreviewTemplateData = {
  templateName: string
  sections: CrfSection[]
}

export const getPreviewData = (data: {
  folderTemplates: CrfFolderTemplates | undefined
  sections: CrfSection[] | undefined
}): PreviewData[] | null => {
  const { folderTemplates, sections } = data

  if (!folderTemplates || !sections) {
    return null
  }

  return folderTemplates.templateFolders.map(templateFolderName => {
    const filteredTemplates = filterTemplatesByFolderName({
      templateFolderName,
      templates: folderTemplates.templates,
    })
    const templateData = getPreviewTemplateData({
      templates: filteredTemplates,
      sections,
    })

    return {
      templateFolderName,
      templateData,
    }
  })
}

const filterTemplatesByFolderName = ({
  templateFolderName,
  templates,
}: {
  templateFolderName: string
  templates: CrfTemplate[]
}): CrfTemplate[] => {
  return templates.filter(t => t.templateFolderName === templateFolderName)
}

const getPreviewTemplateData = ({
  templates,
  sections,
}: {
  templates: CrfTemplate[]
  sections: CrfSection[]
}): PreviewTemplateData[] => {
  const data: PreviewTemplateData[] = templates.map(template => {
    const templateName = template.templateName
    return {
      templateName,
      sections: sections.filter(s => s.templateName === templateName),
    }
  })

  return data
}
