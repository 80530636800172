import { createContext, useCallback, useContext, useState } from 'react'

import { MediaProvider, MediaProviderProps } from '@micin-jp/call-lib-react'
import { Member } from 'src/features/member/types'
import { getFullName } from 'src/utils/getFullName'

import { useVideoCallJwtToken } from '../hooks/useVideoCallJwtToken'

type Context = {
  roomUid?: string
  participantUid?: string
  participantName?: string
  videoExpanded: boolean
  initializePatientVideoCall: (param: {
    roomUid: string
    candidateId: string
  }) => void
  initializeMemberVideoCall: (param: {
    roomUid: string
    member: Member
  }) => void
  toggleVideoExpanded: () => void
}

const RoomContext = createContext<Context | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const ExplanationRoomMediaProvider: React.FC<Props> = ({ children }) => {
  const [roomUid, setRoomUid] = useState<string>()
  const [participantUid, setParticipantUid] = useState<string>()
  const [participantName, setParticipantName] = useState<string>()

  const [videoExpanded, setFloatingVideoExpanded] = useState(true)

  const jwtParam =
    !!roomUid && !!participantUid ? { roomUid, participantUid } : null

  const { jwtToken } = useVideoCallJwtToken(jwtParam)

  const initializePatientVideoCall = useCallback(
    (param: { roomUid: string; candidateId: string }) => {
      if (param.roomUid === roomUid) return
      setRoomUid(param.roomUid)
      setParticipantUid(param.candidateId)
      setParticipantName(`候補ID:${param.candidateId}`)
    },
    [roomUid],
  )

  const initializeMemberVideoCall = useCallback(
    (param: { roomUid: string; member: Member }) => {
      if (param.roomUid === roomUid) return
      setRoomUid(param.roomUid)
      setParticipantUid(param.member.uid)
      setParticipantName(`${getFullName(param.member)}`)
    },
    [roomUid],
  )

  const toggleVideoExpanded = useCallback(() => {
    setFloatingVideoExpanded(prev => !prev)
  }, [])

  const isDCT = import.meta.env.VITE_APPLICATION_ENV === 'dct'
  const callregEnv: MediaProviderProps['env'] = isDCT ? 'prod' : 'stg'

  return (
    <MediaProvider token={jwtToken} env={callregEnv}>
      <RoomContext.Provider
        value={{
          roomUid,
          participantUid,
          participantName,
          videoExpanded,
          initializePatientVideoCall,
          initializeMemberVideoCall,
          toggleVideoExpanded,
        }}
      >
        {children}
      </RoomContext.Provider>
    </MediaProvider>
  )
}

export const useRoomMediaContext = (): Context => {
  const context = useContext(RoomContext)
  if (!context) {
    throw new Error(
      'useRoomMediaContext must be used within a ExplanationRoomMediaProvider',
    )
  }
  return context
}
