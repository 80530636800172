import { BrowseDocRevisionDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

export const browseDocRevision = async ({
  explanationDocRevisionUid,
}: {
  explanationDocRevisionUid: string
}): Promise<void> => {
  try {
    await graphqlRequest(BrowseDocRevisionDocument, {
      input: {
        explanationDocRevisionUid,
      },
    })
  } catch (error) {
    throw error
  }
}
