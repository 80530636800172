import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useDisableExplanationRevision } from '../../api'
import { ExplanationRevision } from '../../types'

type Props = {
  isOpen: boolean
  explanationRevisionUid: ExplanationRevision['uid']
  candidateId: string
  onClose: () => void
}

export const DisableExplanationRevisionModal: React.FC<Props> = ({
  isOpen,
  explanationRevisionUid,
  candidateId,
  onClose,
}) => {
  const navigate = useNavigate()
  const toast = useMirohaToast()

  const { selectedTrial } = useSelectedTrial()

  const { request: disableExplanationRevision } = useDisableExplanationRevision(
    {
      onSuccess: () => {
        toast({ status: 'success', title: '説明を無効化しました' })
        onClose()
        navigate(
          generatePath(Paths.Explanations, { trialUid: selectedTrial.uid }),
        )
      },
      onError: error => {
        toast({ status: 'error', title: error.message })
      },
    },
  )

  const { register, watch, handleSubmit } = useForm<{ comment: string }>()

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <form
        onSubmit={handleSubmit(data => {
          disableExplanationRevision({
            explanationRevisionUid,
            comment: data.comment,
          })
        })}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>説明を無効化</ModalHeader>
          <ModalBody>
            <Stack>
              <Text>説明同意を無効化します</Text>
              <WarningMessage
                message={`この操作は取り消せません。\n候補ID: ${candidateId} に対する操作であることを確認してください。`}
              />
              <FormControl>
                <FormLabel>コメント</FormLabel>
                <Input {...register('comment')} />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="red"
              isDisabled={!watch('comment')}
              type="submit"
            >
              無効化する
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
