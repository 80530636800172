import { useMemo } from 'react'

import { useAuthenticatedAccount } from 'src/features/auth/context'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'

export const useAccountInfo = () => {
  const { account } = useAuthenticatedAccount()
  const selectedTrial = account.selectedTrial!
  const hasMultipleMemberships = account.hasMultipleMemberships
  const trialName = selectedTrial.name
  const hospitals = selectedTrial.trialHospitals
  const role = account.member.role
  const { hasPermission } = usePermission()

  const canShowHospitalName = !hasPermission(PERMISSIONS.Hospital_BelongAll)
  const hospital = useMemo(() => {
    if (!canShowHospitalName) {
      return undefined
    }

    return selectedTrial.trialHospitals.find(
      th => th.uid === account.selectedTrialHospitalUid,
    )
  }, [account.selectedTrialHospitalUid, canShowHospitalName, selectedTrial])
  const hospitalName = hospital?.name ?? ''
  const userName = `${account.lastName}${account.firstName}`

  return {
    trialName,
    hospitalName,
    userName,
    role,
    hasMultipleMemberships,
    hospitals,
    featureChannel: selectedTrial.featureChannel,
  }
}
