import { useState } from 'react'

import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { Expand3 } from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PERMISSIONS } from 'src/lib/permission'

import { useExplanation } from '../../api'
import {
  ExplanationDetail,
  ExplanationDocRevisionAgreementForm,
} from '../../types'
import { isEditableStatus } from '../../utils/isEditableStatus'
import { isPartner } from '../../utils/isPartner'
import { willStartSession } from '../../utils/willStartSession'
import { DisableExplanationRevisionModal } from '../DisableExplanationRevisionModal/DisableExplanationRevisionModal'
import { RejectAgreementModalWithSelect } from '../RejectAgreementModalWithSelect/RejectAgreementModalWithSelect'
import { ResendNotificationModal } from '../ResendNotificationModal/ResendNotificationModal'
import { WithdrawModal } from '../WithdrawModal/WithdrawModal'

type Props = {
  explanation: ExplanationDetail
}

export const ExplanationMenu: React.FC<Props> = ({ explanation }) => {
  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { mutate: mutateExplanation } = useExplanation({
    explanationUid: explanation.uid,
  })

  const { hasPermission } = usePermission()

  const [modalToOpen, setModalToOpen] = useState<
    'ResendNotification' | 'Disable' | 'Reject' | 'Withdraw' | 'None'
  >('None')

  const openModal = (toOpen: typeof modalToOpen) => {
    setModalToOpen(toOpen)
  }

  const closeModal = () => {
    setModalToOpen('None')
  }

  const status = explanation.latestRevision.status

  const type = explanation.latestRevision.type

  // 患者が署名or拒否をしていない（患者の署名履歴が存在しない）文書の一覧
  const unsignedDocRevs = explanation.latestRevision.docSets
    .flatMap(docSet => docSet.explanationDocRevisions)
    .filter(
      (docRev): docRev is ExplanationDocRevisionAgreementForm =>
        !!docRev && docRev.type === 'AgreementForm',
    )
    .filter(docRev =>
      docRev.signHistories.every(h => h.signerRole !== 'Patient'),
    )

  const conditions = {
    canEdit:
      hasPermission(PERMISSIONS.Explanation_Edit) && isEditableStatus(status),
    canPreview: false, // TODO
    canResendExplanationUrl:
      hasPermission(PERMISSIONS.Explanation_Edit) &&
      type === 'Remote' &&
      !!explanation.patient.email &&
      !!explanation.latestRevision.scheduledAt &&
      willStartSession(status),
    canReject:
      hasPermission(PERMISSIONS.Explanation_Edit) &&
      status !== 'Disabled' &&
      unsignedDocRevs.length > 0,
    canDisable:
      hasPermission(PERMISSIONS.Explanation_Edit) &&
      !!selectedTrialHospitalUid &&
      !isPartner(explanation, selectedTrialHospitalUid) &&
      willStartSession(status),
    canWithdraw:
      hasPermission(PERMISSIONS.Explanation_Edit) && status === 'AgreementDone',
  }

  const canShowMenu = Object.values(conditions).some(Boolean)

  if (!canShowMenu) {
    return null
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          colorScheme="blue"
          variant="customIconButtonGhost"
          aria-label="explanation-menu"
          icon={<Expand3 color={colors.blue[500]} />}
        />
        <MenuList>
          {conditions.canEdit && (
            <MenuItem
              as={Link}
              to={generatePath(Paths.ExplanationEdit, {
                trialUid: selectedTrial.uid,
                explanationUid: explanation.uid,
              })}
            >
              説明内容編集
            </MenuItem>
          )}
          {conditions.canPreview && <MenuItem>説明ルームプレビュー</MenuItem>}
          {conditions.canResendExplanationUrl && (
            <MenuItem onClick={() => openModal('ResendNotification')}>
              患者へ説明URLを再送
            </MenuItem>
          )}
          {conditions.canReject && (
            <MenuItem color="red.500" onClick={() => openModal('Reject')}>
              同意拒否
            </MenuItem>
          )}
          {conditions.canDisable && (
            <MenuItem color="red.500" onClick={() => openModal('Disable')}>
              説明を無効化
            </MenuItem>
          )}
          {conditions.canWithdraw && (
            <MenuItem color="red.500" onClick={() => openModal('Withdraw')}>
              同意撤回
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      {!!explanation.patient.email && (
        <ResendNotificationModal
          isOpen={modalToOpen === 'ResendNotification'}
          explanationRevisionUid={explanation.latestRevision.uid}
          patientEmail={explanation.patient.email}
          onClose={closeModal}
        />
      )}

      <DisableExplanationRevisionModal
        isOpen={modalToOpen === 'Disable'}
        explanationRevisionUid={explanation.latestRevision.uid}
        candidateId={explanation.patient.candidateId}
        onClose={closeModal}
      />

      <RejectAgreementModalWithSelect
        explanation={explanation}
        docRevisions={unsignedDocRevs}
        isOpen={modalToOpen === 'Reject'}
        onClose={closeModal}
      />

      <WithdrawModal
        isOpen={modalToOpen === 'Withdraw'}
        explanationRevisionUid={explanation.latestRevision.uid}
        candidateId={explanation.patient.candidateId}
        onClose={closeModal}
        mutateExplanation={mutateExplanation}
      />
    </>
  )
}
