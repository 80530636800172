import { Stack, Button, Center, Text, Box } from '@chakra-ui/react'
import { useRoomSession, useRoomUsers } from '@micin-jp/call-lib-react'

type Props = {
  participantName?: string
}

export const VideoCallRoomReady: React.FC<Props> = ({ participantName }) => {
  const { joinRoom } = useRoomSession()

  const { remoteUsers } = useRoomUsers()

  const joinOptions: Parameters<typeof joinRoom>[0] = !!participantName
    ? { name: participantName }
    : undefined

  const started = remoteUsers.length > 0

  const message = started
    ? '通話が開始されています'
    : '通話はまだ開始されていません'

  return (
    <Center w="full" h="full">
      <Stack spacing="6" align="center">
        <Text color="white" fontSize="2xl" fontWeight="bold">
          {message}
        </Text>
        <Box>
          <Button
            onClick={async () => {
              await joinRoom(joinOptions)
            }}
          >
            {started ? '今すぐ通話を開始する' : '通話を開始する'}
          </Button>
        </Box>
      </Stack>
    </Center>
  )
}
