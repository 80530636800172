import { GetRelatedTrialHospitalsDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { TrialHospital } from '../../types'
import { trialCacheKey } from '../cacheKey'
import { parseGqlTrailHospital } from '../parser'

const getRelatedTrialHospitals = async (): Promise<TrialHospital[]> => {
  try {
    const res = await graphqlRequest(GetRelatedTrialHospitalsDocument, {})
    return res.searchableTrialHospitalsForPatientSearch.map(
      parseGqlTrailHospital,
    )
  } catch (e) {
    throw e
  }
}

export const useRelatedTrialHospitals = ({
  shouldCancel = false,
  ...options
}: {
  shouldCancel?: boolean
} & SWRConfiguration<TrialHospital[], GqlError>) => {
  return useSWR<TrialHospital[], GqlError>(
    shouldCancel ? null : trialCacheKey.getRelatedTrialHospitals(),
    () => getRelatedTrialHospitals(),
    options,
  )
}
