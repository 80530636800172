import { useMemo, useState } from 'react'

import { Template } from 'src/modules/entities/template/entity'

export const useSelectTemplate = (templates: Template[]) => {
  const [selectedTemplateUids, setSelectedTemplateUids] = useState<string[]>([])

  const handlers = useMemo(
    () => ({
      checked: (uid: string) => {
        return selectedTemplateUids.includes(uid)
      },

      onCheck: (checkedUid: string) => {
        if (selectedTemplateUids.includes(checkedUid)) {
          setSelectedTemplateUids(
            selectedTemplateUids.filter(uid => uid !== checkedUid),
          )
          return
        }

        setSelectedTemplateUids([...selectedTemplateUids, checkedUid])
      },

      onCheckAll: () => {
        //すべてチェックされている場合はすべてのチェックを外す。
        if (templates.every(t => selectedTemplateUids.includes(t.uid))) {
          setSelectedTemplateUids([])
          return
        }

        setSelectedTemplateUids(templates.map(t => t.uid))
      },
    }),
    [selectedTemplateUids, templates],
  )

  return {
    selectedTemplateUids,

    handlers,
  }
}
