import { useState } from 'react'

import styled from 'styled-components'

import { colors } from '../../../../../../../../../assets/colors'
import {
  hospitalNames,
  TrialHospitalsByUid,
} from '../../../../../../../../entities/account/util'

type Props = {
  trialHospitalsByUid: TrialHospitalsByUid
  hospitalUids: string[]
}

export const HospitalNames: React.FC<Props> = props => {
  const { trialHospitalsByUid, hospitalUids } = props
  const initialDisplayableHospitalCount = 3
  const [displayableHospitalCount, setDisplayableHospitalCount] =
    useState<number>(initialDisplayableHospitalCount)

  const needEllipsis = hospitalUids.length > displayableHospitalCount

  return (
    <Container>
      {hospitalNames(trialHospitalsByUid, hospitalUids, {
        maxDisplay: displayableHospitalCount,
      })}
      {needEllipsis && (
        <Ellipsis onClick={() => setDisplayableHospitalCount(Infinity)}>
          ...
        </Ellipsis>
      )}
    </Container>
  )
}

const Container = styled.div``

const Ellipsis = styled.label`
  margin-left: 3px;
  cursor: pointer;
  font-size: 16px;
  color: ${colors.gray};
`
