import { useParams, generatePath, useLocation } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'
import { useFeatureFlags } from 'src/features/trial/api/getFeatureFlags'

import { useDocRevisionForBrowse } from '../../api'
import { useCreateSignAuthentication } from '../../api/createSignAuthentication'
import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForPatient } from '../../hooks/useFloatingVideoCallForPatient'
import { useThisSession } from '../../hooks/useThisSession'
import { DocuSignIframeContent } from '../DocuSignIframeContent/DocuSignIframeContent'
import { PatientLayout } from '../PatientLayout/PatientLayout'
import { SignAuthChecker } from '../SignAuthChecker/SignAuthChecker'

type Param = {
  trialUid: string
  sessionUid: string
  docRevisionUid: string
  signerActorUid: string
}

export const PatientDocumentSignScreen: React.FC = () => {
  const {
    trialUid = '',
    sessionUid = '',
    docRevisionUid = '',
    signerActorUid = '',
  } = useParams<Param>()

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  // TODO: ファイルURL等は不要なので別のqueryを作る
  const { data: docRevision } = useDocRevisionForBrowse({
    explanationDocRevisionUid: docRevisionUid,
  })

  const thisSession = useThisSession({ sessionUid, forPatient: true })

  const { renderFloatingVideoCall } = useFloatingVideoCallForPatient({
    sessionUid,
  })
  const { data: signAuth, isValidating } = useCreateSignAuthentication({
    explanationSessionUid: sessionUid,
    explanationDocRevisionUid: docRevisionUid,
    signerActorUid,
    revalidateOnFocus: false,
  })

  const { data: featureFlags } = useFeatureFlags({
    trialUid: !!trialUid ? trialUid : null,
  })

  if (
    !docRevision ||
    !thisSession.fetched ||
    !signAuth ||
    !featureFlags ||
    isValidating
  ) {
    return null
  }

  const roomTopPath = generatePath(Paths.PatientExplanationRoomTop, {
    trialUid,
    sessionUid,
  })

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      sessionUid={sessionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.trialHospital.uid}
    >
      <PatientLayout
        headerTitle={docRevision.icfDocumentRevision.name}
        trialUid={trialUid}
        sessionUid={sessionUid}
        showSidebar={false}
      >
        <SignAuthChecker
          isSP={false}
          trialUid={trialUid}
          explanationSignAuthUid={signAuth.signAuthenticationUid}
          roomTopPath={roomTopPath}
        >
          <DocuSignIframeContent
            roomTopPath={roomTopPath}
            sessionUid={sessionUid}
            docRevisionUid={docRevisionUid}
            signerDisplayName={`候補ID: ${thisSession.patient.candidateId}`}
            signerActorUid={signerActorUid}
            completePath={
              generatePath(Paths.PatientExplanationRoomSignCompleted, {
                trialUid,
                sessionUid,
              }) +
              `?${RoomEventUidQueryKey}=${searchParams.get(RoomEventUidQueryKey)}`
            }
            roomEventUid={searchParams.get(RoomEventUidQueryKey) ?? ''}
          />
          {renderFloatingVideoCall({ right: 24, bottom: 96 })}
        </SignAuthChecker>
      </PatientLayout>
    </ExplanationRoomEventProvider>
  )
}
