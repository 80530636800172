import { Choice } from './entity'

export const fixture: Choice = {
  uid: 'fooChoiceUid',
  description: '選択肢',
  edcValue: '',
  smallItemUids: ['fooSmallItemUidNested'],
}

export const createFixture = (values?: Partial<Choice>): Choice => {
  const res = {
    uid: 'fooChoiceUid',
    description: '選択肢',
    edcValue: '',
    smallItemUids: [],
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof Choice
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}
