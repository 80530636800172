import { Box, Button, Flex } from '@chakra-ui/react'
import { generatePath, Link, useParams } from 'react-router-dom'
import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'
import { Paths } from 'src/constants/paths'

import { browseDocRevision, useDocRevisionForBrowse } from '../../api'
import {
  locationPaths,
  ExplanationRoomEventProvider,
} from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForPatient } from '../../hooks/useFloatingVideoCallForPatient'
import { useThisSession } from '../../hooks/useThisSession'
import { PatientLayout } from '../PatientLayout/PatientLayout'
import { PdfViewer } from '../PDFViewer/PDFViewer'

type Param = {
  trialUid: string
  sessionUid: string
  docRevisionUid: string
}

// ボタンの高さ + y方向のpadding
const NAV_CONTAINER_HEIGHT = 36 + 24 * 2

export const PatientDocumentViewerScreen: React.FC = () => {
  const {
    trialUid = '',
    docRevisionUid = '',
    sessionUid = '',
  } = useParams<Param>()

  const { data: docRevision } = useDocRevisionForBrowse({
    explanationDocRevisionUid: docRevisionUid,
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onSuccess: async ({ uid: explanationDocRevisionUid }) => {
      // mount時のみfetchするようにしているのでそのfetchが成功したタイミングで閲覧履歴を記録する
      await browseDocRevision({
        explanationDocRevisionUid,
      })
    },
  })
  const thisSession = useThisSession({ sessionUid, forPatient: true })

  const { renderFloatingVideoCall } = useFloatingVideoCallForPatient({
    sessionUid,
  })

  if (!docRevision || !thisSession.fetched) {
    return null
  }
  if (
    docRevision.icfDocumentRevision.__typename ===
    'IcfDocumentRevisionCheckUnderstanding'
  ) {
    throw new Error('CheckUnderstanding is not supported')
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      sessionUid={sessionUid}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.patient.trialHospitalUid}
    >
      <PatientLayout
        headerTitle={docRevision.icfDocumentRevision.name}
        trialUid={trialUid}
        sessionUid={sessionUid}
        showSidebar={false}
      >
        <Box h="full">
          <Flex align="center" justify="space-between" p="6">
            <Button
              as={Link}
              to={generatePath(Paths.PatientExplanationRoomTop, {
                trialUid,
                sessionUid,
              })}
              variant="outline"
              colorScheme="gray"
            >
              説明ルームへ戻る
            </Button>
          </Flex>

          <Box h={`calc(100% - ${NAV_CONTAINER_HEIGHT}px)`}>
            {docRevision.icfDocumentRevision.__typename ===
            'IcfDocumentRevisionVideo' ? (
              <Box h="full" w="full" px="20" pb="10">
                <HlsVideoPlayer
                  url={docRevision.icfDocumentRevision.videoURL ?? ''}
                />
              </Box>
            ) : (
              <Flex h="full" align="center" justify="center">
                <PdfViewer
                  url={docRevision.icfDocumentRevision.fileURL}
                  sessionUid={sessionUid}
                  locationPath={locationPaths.document(docRevisionUid)}
                  trialHospitalUid={thisSession.trialHospital.uid}
                  actorType="Patient"
                  explanationPatientUid={thisSession.patient.uid}
                />
              </Flex>
            )}
          </Box>
        </Box>
        {renderFloatingVideoCall()}
      </PatientLayout>
    </ExplanationRoomEventProvider>
  )
}
