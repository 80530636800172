import { combineReducers } from '@reduxjs/toolkit'

import { reducer as crfReducer, State as CrfState } from './crf/upload/redux'

export type State = {
  crf: CrfState
}

export const reducer = combineReducers({
  crf: crfReducer,
})
