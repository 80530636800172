import { useState, useCallback, useRef } from 'react'

import * as Sentry from '@sentry/react'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

export type UseMutationOption<
  RequestReturn,
  Err extends Error = Error,
> = Partial<{
  onSuccess:
    | ((data: RequestReturn) => void)
    | ((data: RequestReturn) => Promise<void>)
  onError: (error: Err) => void
  onRequestStarted: () => void
  onRequestDone: () => void
}>

type RequestFuncResultSuccess = {
  result: 'success'
}
type RequestFuncResultError<Err> = {
  result: 'error'
  error: Err
}
type RequestFuncResult<Err> =
  | RequestFuncResultSuccess
  | RequestFuncResultError<Err>
// NOTE: POSTやPUTリクエストを行うためのhooks
// 第1引数でPromiseを返す関数を受け取り、第2引数で各オプションを受け取る（optional）
// 関数の引数をarray型にしているのは可変長引数を使って引数がない場合にも対応するため
export const useMutation = <
  RequestArg extends any[],
  RequestReturn,
  Err extends Error = Error,
>(
  requestFn: (...args: RequestArg) => Promise<RequestReturn>,
  options?: UseMutationOption<RequestReturn, Err>,
) => {
  const [requesting, setRequesting] = useState(false)
  // ダブルクリックを防ぐためにrefでリクエスト中かどうかを管理
  const requestingRef = useRef(false)
  const argsRef = useRef<RequestArg | null>(null)

  const requestStart = useCallback(() => {
    setRequesting(true)
    if (!!options?.onRequestStarted) {
      options.onRequestStarted()
    }
  }, [options])

  const requestDone = useCallback(() => {
    requestingRef.current = false
    argsRef.current = null
    setRequesting(false)

    if (!!options?.onRequestDone) {
      options.onRequestDone()
    }
  }, [options])

  const toast = useMirohaToast()

  const request = useCallback(
    async (...args: RequestArg): Promise<RequestFuncResult<Err>> => {
      // リクエスト中の場合は何もしない（resultはsuccessとするがonSuccessの実行などはtriggerしない）
      // argsの値が異なる場合はリクエストを行う
      const argsChanged =
        argsRef.current === null ||
        JSON.stringify(args) !== JSON.stringify(argsRef.current)
      if (requestingRef.current && !argsChanged) {
        return {
          result: 'success',
        }
      }
      requestingRef.current = true
      argsRef.current = args
      try {
        requestStart()
        const data = await requestFn(...args)
        if (!!options?.onSuccess) {
          // AsyncFunctionの場合は完了を待ってからrequestDoneが呼ばれるようにする
          if (options.onSuccess.constructor.name === 'AsyncFunction') {
            await options.onSuccess(data)
          } else {
            options?.onSuccess(data)
          }
        }
        requestDone()
        return {
          result: 'success',
        }
      } catch (e) {
        requestDone()
        if (!!options?.onError) {
          options.onError(e)
        } else {
          // onErrorが未指定の場合はtoastを表示 & Sentryにエラーを送信
          toast({
            status: 'error',
            title: e.message,
          })
          Sentry.captureException(e, {
            level: 'warning',
            tags: {
              type: 'useMutation',
            },
          })
        }
        return {
          result: 'error',
          error: e as Err,
        }
      }
    },
    [requestFn, requestStart, requestDone, options, toast],
  )

  return { request, requesting }
}
