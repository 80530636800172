import { HttpResponse } from 'msw'
import {
  createRequestRevisionPinSetUpPayloadMock,
  RequestPinSettingDocument,
  RequestPinSettingMutation,
  RequestPinSettingMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const requestPinSettingHandler = graphQLHandlerFactory<
  RequestPinSettingMutation,
  RequestPinSettingMutationVariables
>('mutation', RequestPinSettingDocument, () => {
  return HttpResponse.json({
    data: {
      requestExpRevisionPinSetUp: createRequestRevisionPinSetUpPayloadMock(),
    },
  })
})
