import { SectionConfirmationStatus } from 'src/modules/entities/medium-item-answer/entity'

import { MediumItemAnswerLog, MediumItemAnswerLogAction } from './entity'

export const fixtures: MediumItemAnswerLog[] = [
  {
    uid: 'foo',
    mediumItemAnswerUid: 'fooMediumItemUid',
    userName: '治験 太郎',
    action: MediumItemAnswerLogAction.Confirm,
    confirmationStatus: SectionConfirmationStatus.EdcIntegrationFailed,
    message: 'RWS00047 : Data not in dictionary',
    reason: '',
    createdAt: '2021-01-01T08:00:00',
  },
  {
    uid: 'bar',
    mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
    userName: '治験 太郎',
    action: MediumItemAnswerLogAction.Disable,
    message: '',
    reason: '入力誤りのため',
    confirmationStatus: SectionConfirmationStatus.EdcIntegrated,
    createdAt: '2021-01-01T00:00:00',
  },
]
