import React, { useState } from 'react'

import { usePane } from 'src/hooks/use-pane'

import { IcfDocumentList } from './list'
import { useIcfDocumentList } from './list.hooks'
import { ReAgreementConfirmModal } from '../components/re-agreement-confirm-modal'
import { IcfDocumentDetailContainer } from '../detail/detail-container'
import { IcfDocumentReAgreementConfirm } from '../re-agreement-confirm/re-agreement-confirm'
import { useIcfDocumentReAgreementConfirm } from '../re-agreement-confirm/re-agreement-confirm.hooks'

export const IcfDocumentListContainer: React.FC<{}> = () => {
  const {
    icfDocumentList,
    selectedTrialHospitalUid,
    shouldShowReAgreementConfirmButton,
    navigateToAdd,
    navigateToEdit,
  } = useIcfDocumentList()

  const {
    isRequiredReAgreementConfirmDocuments,
    selectedDocUids,
    selectedDocuments,
    reAgreementConfirmModalOpen,
    openReAgreementConfirmPane,
    renderReAgreementConfirmPane,
    handleToggle,
    handleClosePane,
    handleConfirm,
    handleCloseModal,
    handleSubmit,
  } = useIcfDocumentReAgreementConfirm(icfDocumentList)

  const [documentUid, setDocumentUid] = useState('')

  const {
    handlers: { openPane: openDetailPane },
    renderPane: renderDetailPane,
  } = usePane({
    paneType: 'normal',
    closeOnOutsideClick: true,
  })

  const handleClickDocumentSetLink = (documentUid: string) => {
    setDocumentUid(documentUid)
    openDetailPane()
  }

  return (
    <>
      <IcfDocumentList
        icfDocumentList={icfDocumentList ?? []}
        selectedTrialHospitalUid={selectedTrialHospitalUid}
        shouldShowReAgreementConfirmButton={shouldShowReAgreementConfirmButton}
        navigateToAdd={navigateToAdd}
        navigateToEdit={navigateToEdit}
        onClickDocumentSetLink={handleClickDocumentSetLink}
        onClickReAgreementConfirmButton={openReAgreementConfirmPane}
      />
      {renderDetailPane(
        <IcfDocumentDetailContainer documentUid={documentUid} />,
      )}
      {renderReAgreementConfirmPane(
        <IcfDocumentReAgreementConfirm
          isRequiredReAgreementConfirmDocuments={
            isRequiredReAgreementConfirmDocuments
          }
          selectedDocUids={selectedDocUids}
          onToggle={handleToggle}
          onClose={handleClosePane}
          onConfirm={handleConfirm}
        />,
      )}
      {reAgreementConfirmModalOpen && (
        <ReAgreementConfirmModal
          docs={selectedDocuments}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}
