import React from 'react'

import { Box } from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom'
import { getRelativePath, PathKey } from 'src/constants/paths'

import { HospitalExplanationRoomRoutes } from './hospital'
import { PartnerExplanationRoomRoutes } from './partner'
import { PatientExplanationRoomRoutes } from './patient'
import { ExplanationRoomFinished } from '../../../modules/dashboard/trial/detail/e-consent/explanation-room/finished/finished'
import { PreviewExplanationRoom } from '../../../modules/dashboard/trial/detail/e-consent/explanation-room/preview-explanation-room'
import { ExplanationRoomNotFound } from '../components/ExplanationRoomNotFound/ExplanationRoomNotFound'
import { ExplanationRoomMediaProvider } from '../context/MediaProvider'

export const ExplanationRoomRoutes: React.FC = () => {
  const getPath = (path: PathKey): string => {
    return getRelativePath('ExplanationRoom', path)
  }

  return (
    <ExplanationRoomMediaProvider>
      <Box className="disable-webkit-touch-callout" h="full">
        <Routes>
          <Route
            path={`${getPath('HospitalExplanationRoom')}/*`}
            element={<HospitalExplanationRoomRoutes />}
          />
          <Route
            path={`${getPath('PartnerExplanationRoom')}/*`}
            element={<PartnerExplanationRoomRoutes />}
          />
          <Route
            path={`${getPath('PatientExplanationRoom')}/*`}
            element={<PatientExplanationRoomRoutes />}
          />
          <Route
            path={`${getPath('ExplanationRoomFinished')}`}
            element={<ExplanationRoomFinished />}
          />
          <Route
            path={`${getPath('PreviewExplanationRoom')}/*`}
            element={<PreviewExplanationRoom />}
          />

          {/* 共通の静的画面など */}
          <Route path="*" element={<ExplanationRoomNotFound />} />
        </Routes>
      </Box>
    </ExplanationRoomMediaProvider>
  )
}
