import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { routes } from 'src/modules/routes'

import { actions as templateActions } from '../../../../../..//entities/template/redux'
import { actions as templateFolderActions } from '../../../../../../entities/template-folder/redux'

export const useFetch = ({ trialUid }: { trialUid: string }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        await Promise.all([
          dispatch(templateActions.fetchList({ trialUid })),
          dispatch(templateFolderActions.fetchList({ trialUid })),
        ])
        setFetched(true)
      } catch (error) {
        // TOOD: login key?
        console.error('templateActions.fetchList Error: ', error)
        error.original.response.status === 403
          ? navigate(routes.httpError403)
          : navigate(`${routes.requiredLogin}`)
      }
    }

    fetch()
  }, [dispatch, navigate, setFetched, trialUid])

  return {
    fetched,
  }
}
