import { Box, HStack, Link, VStack } from '@chakra-ui/react'
import {
  generatePath,
  Link as ReactRouterLink,
  useParams,
} from 'react-router-dom'
import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'
import { PagePrevious } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import {
  browseDocRevision,
  useDocRevisionForBrowse,
} from 'src/features/explanationRoom/api'

import {
  locationPaths,
  ExplanationRoomEventProvider,
} from '../../context/ExplanationRoomEvent'
import { useRoomMediaContext } from '../../context/MediaProvider'
import { useThisSession } from '../../hooks/useThisSession'
import { PdfViewer } from '../PDFViewer/PDFViewer'
import { SPExplanationRoomLayout } from '../SPExplanationRoomLayout/SPExplanationRoomLayout'
import { SPExplanationVideoCall } from '../SPExplanationVideoCall/SPExplanationVideoCall'

type Param = {
  trialUid: string
  sessionUid: string
  docRevisionUid: string
}

export const SPExplanationPatientDocument: React.FC = () => {
  const {
    trialUid = '',
    docRevisionUid = '',
    sessionUid = '',
  } = useParams<Param>()

  const { data: docRevision } = useDocRevisionForBrowse({
    explanationDocRevisionUid: docRevisionUid,
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onSuccess: async ({ uid: explanationDocRevisionUid }) => {
      // mount時のみfetchするようにしているのでそのfetchが成功したタイミングで閲覧履歴を記録する
      await browseDocRevision({
        explanationDocRevisionUid,
      })
    },
  })
  const thisSession = useThisSession({ sessionUid, forPatient: true })

  const { videoExpanded, toggleVideoExpanded } = useRoomMediaContext()

  if (!docRevision || !thisSession.fetched) {
    return null
  }
  if (
    docRevision.icfDocumentRevision.__typename ===
    'IcfDocumentRevisionCheckUnderstanding'
  ) {
    throw new Error('CheckUnderstanding is not supported')
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      isSP
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      sessionUid={sessionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.patient.trialHospitalUid}
    >
      <SPExplanationRoomLayout
        headerTitle={docRevision.icfDocumentRevision.name}
        trialUid={trialUid}
        sessionUid={sessionUid}
      >
        <SPExplanationVideoCall
          roomUid={thisSession.videoCallRoomUid}
          candidateId={thisSession.patient.candidateId}
          canShrinkVideoCall
        />

        <VStack
          bg="green.50"
          height="full"
          width="full"
          alignItems="start"
          spacing="0"
          overflow="hidden"
        >
          <Box
            height="54px"
            py="3"
            px="2"
            justifyContent="center"
            display="flex"
          >
            <HStack color="blue.500" spacing="1">
              <PagePrevious />
              <Link
                as={ReactRouterLink}
                onClick={() => {
                  // 説明ルームに戻るときにビデオを拡大する
                  if (!videoExpanded) {
                    toggleVideoExpanded()
                  }
                }}
                to={generatePath(Paths.PatientExplanationRoomTop, {
                  trialUid,
                  sessionUid,
                })}
                fontWeight="medium"
              >
                説明ルームへ戻る
              </Link>
            </HStack>
          </Box>
          <Box w="full" h="full" overflow="auto">
            {docRevision.icfDocumentRevision.__typename ===
            'IcfDocumentRevisionVideo' ? (
              <Box h="full" w="full" bg="black">
                <HlsVideoPlayer
                  url={docRevision.icfDocumentRevision.videoURL ?? ''}
                />
              </Box>
            ) : (
              <Box w="full" h="full" overflow="auto">
                <PdfViewer
                  url={docRevision.icfDocumentRevision.fileURL}
                  showThumbnail={false}
                  sessionUid={sessionUid}
                  locationPath={locationPaths.document(docRevisionUid)}
                  trialHospitalUid={thisSession.trialHospital.uid}
                  actorType="Patient"
                  explanationPatientUid={thisSession.patient.uid}
                />
              </Box>
            )}
          </Box>
        </VStack>
      </SPExplanationRoomLayout>
    </ExplanationRoomEventProvider>
  )
}
