import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  ReplyStickyDocument,
  ReplyStickyInput,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const replySticky = async (input: ReplyStickyInput) => {
  try {
    await graphqlRequest(ReplyStickyDocument, { input })
  } catch (error) {
    throw error
  }
}

export const useReplySticky = (option?: UseMutationOption<void, GqlError>) => {
  return useMutation((input: ReplyStickyInput) => replySticky(input), option)
}
