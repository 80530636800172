import { useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  HStack,
  Box,
  FormLabel,
  FormControl,
  Input,
  Badge,
  ModalCloseButton,
  ModalContent,
} from '@chakra-ui/react'
import { Select, SelectItem } from 'src/components/Select/Select'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useExplanation, useRejectAgreement } from '../../api'
import {
  ExplanationDetail,
  ExplanationDocRevision,
  ExplanationDocRevisionAgreementForm,
} from '../../types'

type Props = {
  explanation: ExplanationDetail
  docRevisions: ExplanationDocRevisionAgreementForm[]
  isOpen: boolean
  onClose: () => void
}

export const RejectAgreementModalWithSelect: React.FC<Props> = ({
  explanation,
  docRevisions,
  isOpen,
  onClose,
}) => {
  const [selectedUid, setSelectedUid] =
    useState<ExplanationDocRevision['uid']>()
  const [reason, setReason] = useState('')

  const toast = useMirohaToast()

  const { mutate: mutateExplanation } = useExplanation({
    explanationUid: explanation.uid,
  })

  const { request: rejectAgreement } = useRejectAgreement({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '同意を拒否しました',
      })
      onClose()

      // statusが即座に更新されないケースがあるので、mutateで無理やりstatusを更新する。
      mutateExplanation(
        {
          ...explanation,
          latestRevision: {
            ...explanation.latestRevision,
            statusV2: 'AgreementRejected',
            status: 'AgreementRejected',
          },
        },
        false,
      )
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const selectItems: SelectItem<
    ExplanationDocRevision['uid'],
    React.ReactNode
  >[] = docRevisions.map(docRevision => ({
    value: docRevision.uid,
    label: (
      <HStack spacing="2">
        <Text as="span">{`${docRevision.icfDocumentRevisionName}`}</Text>
        {docRevision.icfDocumentRevisionIsRequiredAgreement && (
          <Badge bg="blue.400" color="white">
            同意必須
          </Badge>
        )}
      </HStack>
    ),
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>同意拒否</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Stack spacing="2">
              <Text>同意文書について患者の同意拒否を記録します</Text>
              <WarningMessage message="この操作は取り消せません。" />
            </Stack>
            <Stack spacing="4">
              <Box>
                <FormLabel>同意書</FormLabel>
                <Select
                  value={selectedUid}
                  items={selectItems}
                  onChange={setSelectedUid}
                />
              </Box>
              <FormControl>
                <FormLabel>コメント</FormLabel>
                <Input
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            colorScheme="red"
            isDisabled={!reason || !selectedUid}
            onClick={async () => {
              if (!reason || !selectedUid) return
              await rejectAgreement({
                explanationDocRevisionUid: selectedUid,
                reason,
              })
            }}
          >
            同意拒否する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
