import {
  Stack,
  Spacer,
  HStack,
  Button,
  useDisclosure,
  Text,
  Box,
  IconButton,
} from '@chakra-ui/react'
import {
  PaginationBack,
  PaginationEnd,
  PaginationNext,
  PaginationTop,
  Seek,
} from 'src/components/__legacy__icon/monochrome'

import { usePatients } from '../../api/searchPatients'
import { usePatientPagination } from '../../hooks/usePatientPagination'
import { CreatePatientButton } from '../CreatePatientButton/CreatePatientButton'
import { PatientFilterDrawer } from '../PatientFilterDrawer/PatientFilterDrawer'
import { PatientsTable } from '../PatientsTable/PatientsTable'

export const PatientListScreen: React.FC = () => {
  const {
    isOpen: isFilterDrawerOpen,
    onOpen: onOpenFilterDrawer,
    onClose: onCloseFilterDrawer,
  } = useDisclosure()
  return (
    <Box overflow="auto">
      <Stack m="6" spacing="1.5rem" direction="column">
        <Stack spacing="0" direction="row" alignItems="center">
          <HStack spacing="8">
            <Text fontWeight="bold" fontSize="18px">
              被験者一覧
            </Text>
            <CreatePatientButton />
          </HStack>
          <Spacer />
          <Button
            variant="text"
            leftIcon={<Seek />}
            onClick={onOpenFilterDrawer}
          >
            検索/絞り込み
          </Button>
        </Stack>
        <Box display="flex" justifyContent="flex-end">
          <PatientPagination />
        </Box>
        <PatientsTable />
        <PatientFilterDrawer
          isOpen={isFilterDrawerOpen}
          onClose={onCloseFilterDrawer}
        />
      </Stack>
    </Box>
  )
}

const PatientPagination = () => {
  const {
    rangeString,
    totalPageCount,
    currentPage,
    totalCount,
    hasFirstPage,
    hasPrevPage,
    hasNextPage,
    hasLastPage,
    handleClickFirstPage,
    handleClickPrevPage,
    handleClickNextPage,
    handleClickLastPage,
  } = usePatientPagination()

  // ページング時のちらつきをなくすため予めページング後のデータをfetchしておく
  const shouldFetchFirst = hasPrevPage && currentPage - 1 !== 1
  usePatients({
    page: shouldFetchFirst ? 1 : undefined,
  })
  const shouldFetchPrev = hasPrevPage
  usePatients({
    page: shouldFetchPrev ? currentPage - 1 : undefined,
  })
  const shouldFetchNext = hasNextPage
  usePatients({
    page: shouldFetchNext ? currentPage + 1 : undefined,
  })

  const shouldFetchLast = hasNextPage && currentPage + 1 < totalPageCount
  usePatients({
    page: shouldFetchLast ? totalPageCount : undefined,
  })

  return (
    <Stack direction="row" spacing="0.25rem">
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination top button"
        icon={<PaginationTop />}
        onClick={handleClickFirstPage}
        disabled={!hasFirstPage}
      />
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination back button"
        icon={<PaginationBack />}
        onClick={handleClickPrevPage}
        disabled={!hasPrevPage}
      />
      <Text fontSize="sm">{rangeString}</Text>
      <Text fontSize="sm">/</Text>
      <Text fontSize="sm">{`${totalCount} 件`}</Text>
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination next button"
        icon={<PaginationNext />}
        onClick={handleClickNextPage}
        disabled={!hasNextPage}
      />
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination end button"
        icon={<PaginationEnd />}
        onClick={handleClickLastPage}
        disabled={!hasLastPage}
      />
    </Stack>
  )
}
