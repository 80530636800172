import { Button } from '@chakra-ui/react'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import {
  SaveCriteriaValueDocument,
  SaveReferredPatientCriteriaFileValueFileInput,
} from 'src/lib/gql-client'
import { useGqlMutation } from 'src/lib/gql-client/request'

import {
  editingCriteriaValuesAtom,
  fetchedCriteriaValuesAtom,
  isCriteriaValuesChangedAtom,
  validateAtom,
} from '../../atom'

type Props = {
  referredPatientCriteriaUid: string
  mutateReferredPatient: () => void
}

export const SaveCriteriaValueButton: React.FC<Props> = ({
  referredPatientCriteriaUid,
  mutateReferredPatient,
}) => {
  const editingValues = useAtomValue(editingCriteriaValuesAtom)
  const isChanged = useAtomValue(isCriteriaValuesChangedAtom)

  const setFetchedCriteriaValues = useSetAtom(fetchedCriteriaValuesAtom)

  const toast = useMirohaToast()

  const validateValues = useSetAtom(validateAtom)

  const { request: saveCriteriaValue } = useGqlMutation(
    SaveCriteriaValueDocument,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: '紹介患者情報を一時保存しました。',
        })
        mutateReferredPatient()
        // 一時保存が成功したら fetchedCriteriaValues を更新する
        setFetchedCriteriaValues(editingValues)
      },
    },
  )
  return (
    <Button
      variant="outline"
      onClick={async () => {
        const validationRes = await validateValues()
        if (!validationRes.requiredValidationOk) {
          return
        }
        await saveCriteriaValue({
          input: {
            referredPatientCriteriaUid,
            values: editingValues.map(v => ({
              fid: v.fid,
              fieldIndex: v.fieldIndex,
              value: v.isFile
                ? (v.value.map(file => ({
                    uploadedFileUid: file.uploadedFile.uid,
                    memo: file.memo,
                    order: file.order,
                  })) satisfies SaveReferredPatientCriteriaFileValueFileInput[])
                : !!v.value
                  ? v.value
                  : undefined,
            })),
          },
        })
      }}
      isDisabled={!isChanged}
    >
      一時保存
    </Button>
  )
}
