import React, { useState } from 'react'

import dayjs from 'dayjs'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spacer } from 'src/components/spacer/spacer'
import { Spinner } from 'src/components/spinner/spinner'
import styled from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'

import { useSetInitialImageUid } from './common/use-set-initial-image-uid'
import { useGetVideoCallRoomImage, useGetVideoCallRoomImages } from './hooks'
import { worksheetIdParamName } from '../../worksheet-detail'

type Props = {
  onClose: () => void
}

export const VideoCallRoomCaptureModalContainer: React.FC<Props> = props => {
  const { worksheetUid = '' } = useParams<{ [worksheetIdParamName]: string }>()
  const [selectedImageUid, setSelectedImageUid] = useState<string | undefined>(
    undefined,
  )

  const {
    error,
    response: videoCallImages,
    requesting,
  } = useGetVideoCallRoomImages(worksheetUid)
  const { response: imageUrl } = useGetVideoCallRoomImage(selectedImageUid)
  useSetInitialImageUid(
    (initialImageUid: string) => setSelectedImageUid(initialImageUid),
    videoCallImages ? videoCallImages[0]?.uid : undefined,
  )

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title="ビデオ通話のキャプチャ一覧" />

      <ModalContent>
        <Flex>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell width="20%">ファイル名</TableHeadCell>
                  <TableHeadCell>撮影日時</TableHeadCell>
                  <TableHeadCell>撮影者</TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {requesting || !videoCallImages ? (
                  <Spinner />
                ) : (
                  videoCallImages.map(videoCallImage => (
                    <TableRow
                      key={videoCallImage.uid}
                      onClick={() => setSelectedImageUid(videoCallImage.uid)}
                      hover
                      borderBottom
                    >
                      <TableBodyCell width="20%">
                        {videoCallImage.name}
                      </TableBodyCell>
                      <TableBodyCell>
                        {dayjs(videoCallImage.createdAt).format(
                          'YYYY/MM/DD HH:mm',
                        )}
                      </TableBodyCell>
                      <TableBodyCell>
                        {videoCallImage.photoByName}
                      </TableBodyCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Spacer size={10} horizontal />

          <ImageContainer>
            {imageUrl && <Image src={imageUrl} />}
          </ImageContainer>
        </Flex>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="閉じる"
          onClick={props.onClose}
          buttonType="normal"
        />
      </ModalActions>
      {error && (
        <div>
          <Spacer size={8} />
          <Message type="error" message={error} centered />
        </div>
      )}
    </Modal>
  )
}

const TableContainer = styled.div`
  flex: 1;
  height: 367px;
`

const ImageContainer = styled(FlexItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 367px;
  background-color: #000;
  border-radius: 8px;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`
