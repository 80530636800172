import React, { useCallback } from 'react'

import { Button } from 'src/components/base/button/button'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { ConfirmModalContainer } from './components/modal-container'
import { useValidate } from './use-validate'
import { useQuestionnaireDetailPermission } from '../use-permission'

export const ConfirmButton: React.FC = () => {
  const { isEdited } = useQuestionnaireDetailPermission()
  const { validate } = useValidate()

  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  const onClick = useCallback(() => {
    validate({ onValid: openModal })
  }, [openModal, validate])

  return (
    <>
      <Button
        size="S"
        text="確定"
        buttonType="important"
        onClick={onClick}
        disabled={isEdited}
      />
      {modalOpen && <ConfirmModalContainer closeModal={closeModal} />}
    </>
  )
}
