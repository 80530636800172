import { useState } from 'react'

import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Modal,
  ModalOverlay,
  Stack,
  Flex,
  Box,
} from '@chakra-ui/react'
import PinInputImage from 'src/assets/image/pin-input.svg'
import { MessageSuccess } from 'src/components/icon'
import { PinInput } from 'src/components/PinInput/PinInput'
import {
  useResendAuthCodeByVoice,
  useResendAuthenticationCode,
} from 'src/features/explanation/api'
import { useVerifyOTP } from 'src/features/explanation/api/verifyOTP'
import { PATIENT_OTP_TOKEN_LENGTH } from 'src/features/explanation/constants/oPTSetting'
import { otpVerificationMessage } from 'src/features/explanation/utils/signAuthMessage'
import { definitions } from 'src/lib/api-client/schema.gen'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

type OTPVerificationModalProps = {
  trialUid: string
  explanationSignAuthUid: string
  isSP: boolean
  challengeSignAuth: () => void
  openVoiceOTPModal: () => void
}

export const OTPVerificationModal = ({
  trialUid,
  explanationSignAuthUid,
  isSP,
  challengeSignAuth,
  openVoiceOTPModal,
}: OTPVerificationModalProps) => {
  const [otpCode, setOTPCode] = useState<string>('')
  const [verificationResult, setVerificationResult] =
    useState<definitions['output.SignAuthVerificationResult']>()
  const toast = useMirohaToast()

  const { request: verifyOTP } = useVerifyOTP({
    onSuccess: data => {
      if (data.result === 'Verified') {
        challengeSignAuth()
      }
      setOTPCode('')
      setVerificationResult(data.result)
    },
    onError: e => {
      setOTPCode('')
      toast({
        title: e.message,
        status: 'error',
      })
    },
  })
  const { request: resendAuthCode } = useResendAuthenticationCode({
    onSuccess: () => {
      toast({
        title: '認証コードを再送しました',
        status: 'success',
      })
    },
    onError: e => {
      setOTPCode('')
      toast({
        title: e.message,
        status: 'error',
      })
    },
  })

  const canConfirm = otpCode.length === PATIENT_OTP_TOKEN_LENGTH

  const resultText = otpVerificationMessage(verificationResult)

  return (
    <Modal
      isOpen={true}
      size={isSP ? 'xs' : '4xl'}
      closeOnOverlayClick={false}
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        {isSP && (
          <Box mb="4">
            <img src={PinInputImage} alt="暗証番号の入力" />
          </Box>
        )}
        <ModalHeader
          textAlign="center"
          fontSize={isSP ? 'lg' : '2xl'}
          px={isSP ? '4' : undefined}
        >
          ご自身の携帯電話番号宛に届いた6ケタの認証コードを入力してください
        </ModalHeader>
        <ModalBody
          textAlign="left"
          mt={isSP ? '8' : '12'}
          px={isSP ? '4' : undefined}
        >
          <Stack spacing={isSP ? '8' : '12'}>
            <PinInput
              otp
              length={PATIENT_OTP_TOKEN_LENGTH}
              size={isSP ? 'sm' : 'md'}
              value={otpCode}
              onChange={setOTPCode}
            />
            {!!resultText &&
              resultText.split('\\n').map(m => (
                <Text key={m} color="red.500">
                  {m}
                </Text>
              ))}
            <Flex justify="center">
              <Stack align="start" spacing="2">
                <Text>認証コードはSMSで携帯電話番号宛に送信しました。</Text>
                <Button
                  variant="link"
                  onClick={openVoiceOTPModal}
                  alignSelf="left"
                >
                  SMSが届かない場合
                </Button>
              </Stack>
            </Flex>
          </Stack>
        </ModalBody>
        <ModalFooter
          flexDirection="column"
          justifyContent="center"
          mt="12"
          gap={4}
          px={isSP ? '4' : undefined}
        >
          <Button
            onClick={() =>
              verifyOTP({
                trialUid: trialUid,
                explanationSignAuthUid: explanationSignAuthUid,
                otpCode: otpCode,
              })
            }
            colorScheme="green"
            isDisabled={!canConfirm}
            // スマホ向けのボタンサイズはvariantで定義しているがそれ以外の患者UIは独自定義
            size={isSP ? 'sp' : undefined}
            w={isSP ? undefined : '200px'}
            h={isSP ? undefined : '64px'}
            fontSize={isSP ? undefined : 'xl'}
          >
            認証
          </Button>
          <Button
            variant="ghost"
            size={isSP ? 'sp' : undefined}
            w={isSP ? undefined : '200px'}
            h={isSP ? undefined : '64px'}
            fontSize={isSP ? undefined : 'xl'}
            onClick={() =>
              resendAuthCode({
                trialUid: trialUid,
                explanationSignAuthUid: explanationSignAuthUid,
              })
            }
          >
            認証コードを再送
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type SendOTPByVoiceModalProps = {
  trialUid: string
  explanationSignAuthUid: string
  isSP: boolean
  onClose: () => void
  isOpen: boolean
}

export const SendOTPByVoiceModal: React.FC<SendOTPByVoiceModalProps> = ({
  trialUid,
  explanationSignAuthUid,
  isSP,
  onClose,
  isOpen,
}) => {
  const toast = useMirohaToast()

  const { request } = useResendAuthCodeByVoice({
    onSuccess: () => {
      toast({
        title: '音声通話で認証コードを通知します',
        status: 'success',
      })
      onClose()
    },
    onError: e => {
      toast({
        title: e.message,
        status: 'error',
      })
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isSP ? 'xs' : '2xl'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent px={isSP ? '4' : undefined} py={isSP ? '8' : undefined}>
        <ModalHeader textAlign="center" fontSize={16}>
          自動音声による認証コードを送信します
        </ModalHeader>
        <ModalBody>
          <Stack>
            <Text>自動音声通話で認証コードを自動音声にてお伝えします。</Text>
            <Text>
              「認証コードを通知する」ボタンを押下すると、ご登録の携帯電話番号に着信があり、自動音声で認証コードが流れます。
            </Text>
            <Text>認証コード入力画面で案内の番号をご入力ください。</Text>
          </Stack>
        </ModalBody>
        <ModalFooter flexDirection="column">
          <Button
            colorScheme="green"
            onClick={async () => {
              await request({ trialUid, explanationSignAuthUid })
            }}
            size={isSP ? 'sp' : undefined}
            h={isSP ? undefined : '64px'}
            fontSize={isSP ? undefined : 'xl'}
          >
            認証コードを通知する
          </Button>
          <Button
            variant="ghost"
            colorScheme="gray"
            onClick={onClose}
            size={isSP ? 'sp' : undefined}
            minW="200px"
            h={isSP ? undefined : '64px'}
            fontSize={isSP ? undefined : 'xl'}
          >
            キャンセル
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type CompleteAuthModalProps = {
  isSP: boolean
  onConfirm: () => void
  isOpen: boolean
}

export const CompleteAuthModal: React.FC<CompleteAuthModalProps> = ({
  isSP,
  onConfirm,
  isOpen,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onConfirm}
        size={isSP ? 'xs' : '2xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent px={isSP ? '4' : undefined} py={isSP ? '8' : undefined}>
          {isSP && (
            <Flex
              justify="center"
              align="start"
              h="100px"
              mb="4"
              color="green.500"
            >
              <MessageSuccess size="72px" />
            </Flex>
          )}
          <ModalHeader textAlign="center" fontSize={isSP ? 'lg' : '2xl'}>
            認証に成功しました
          </ModalHeader>
          <ModalBody mt="12">
            <Stack>
              <Text fontSize={isSP ? 'md' : '2xl'} textAlign="center">
                以下のボタンより署名画面へお進みください
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center" mt="12">
            <Button
              colorScheme="green"
              size={isSP ? 'sp' : undefined}
              w={isSP ? undefined : '200px'}
              h={isSP ? undefined : '64px'}
              fontSize={isSP ? undefined : 'xl'}
              onClick={onConfirm}
            >
              署名を開始する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
