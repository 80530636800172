import { TrialMemberSortOrder, TrialMembersParam } from 'src/lib/gql-client'

export const memberCacheKey = {
  getTrialMembers: (
    input: TrialMembersParam,
    sortOrder: TrialMemberSortOrder,
  ) => ['member', ...input.roles, ...input.trialHospitalUids, sortOrder].join(),
  getActiveMembersByTrialHospitalUids: (trialHospitalUids: string[]) =>
    ['member', ...trialHospitalUids.sort()].join(),
}
