import { useState, useCallback } from 'react'

import { isUploadTask, Task } from './task'

export const useCrf = ({ tasks }: { tasks: Task[] }) => {
  const [currentTask, setTask] = useState(tasks[0])
  const [completedTaskOrders, updateCompletedTaskOrders] = useState<number[]>(
    [],
  )

  const completed = useCallback(
    (task: Task) => completedTaskOrders.includes(task.order),
    [completedTaskOrders],
  )

  const canToggle = useCallback(
    (task: Task) => {
      const lowerOrderTasks = tasks.filter(t => t.order < task.order)

      return lowerOrderTasks.every(completed)
    },
    [completed, tasks],
  )

  const makeComplete = useCallback(
    (task: Task) => {
      if (!canToggle(task)) return

      updateCompletedTaskOrders([...completedTaskOrders, task.order])
    },
    [completedTaskOrders, canToggle],
  )

  const makeIncomplete = useCallback(
    (task: Task) => {
      if (!canToggle(task)) return

      updateCompletedTaskOrders(
        completedTaskOrders.filter(order => order !== task.order),
      )
    },
    [completedTaskOrders, canToggle],
  )

  const onComplete = useCallback(() => {
    makeComplete(currentTask)
  }, [currentTask, makeComplete])

  const onClickNext = useCallback(() => {
    const nextTask = tasks.find(t => t.order === currentTask.order + 1)
    if (!nextTask) return

    //uploadTask以外のは次へ進む段階で完了とする
    if (!isUploadTask(currentTask)) {
      makeComplete(currentTask)
    }

    setTask(nextTask)
  }, [currentTask, tasks, makeComplete])

  const onClickPrev = useCallback(() => {
    if (isUploadTask(currentTask) && completed(currentTask)) {
      makeIncomplete(currentTask)
      return
    }

    const prevTask = tasks.find(t => t.order === currentTask.order - 1)
    if (!prevTask) return

    if (isUploadTask(currentTask) && !completed(currentTask)) {
      makeIncomplete(prevTask)
      setTask(prevTask)
      return
    }

    //uploadTask以外のは前に戻る段階で未完了とする
    makeIncomplete(currentTask)
    setTask(prevTask)
  }, [currentTask, completed, makeIncomplete, tasks])

  const reset = useCallback(() => {
    setTask(tasks[0])
    updateCompletedTaskOrders([])
  }, [tasks])

  return {
    currentTask,
    completed,
    onComplete,
    onClickNext,
    onClickPrev,
    reset,
  }
}
