import { Center, Skeleton, Stack } from '@chakra-ui/react'

/** 説明ルームの文書一覧のskelton */
export const TopScreenSkelton: React.FC = () => {
  return (
    <Stack spacing="14">
      <Center>
        <Skeleton
          startColor="green.50"
          endColor="green.100"
          h="50px"
          w="320px"
        />
      </Center>
      <Stack>
        <Skeleton
          startColor="green.50"
          endColor="green.100"
          h="24px"
          w="240px"
        />
        <Stack>
          <Skeleton startColor="green.50" endColor="green.100" h="72px" />
          <Skeleton startColor="green.50" endColor="green.100" h="72px" />
          <Skeleton startColor="green.50" endColor="green.100" h="72px" />
        </Stack>
      </Stack>
    </Stack>
  )
}
