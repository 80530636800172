import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCognitoUser } from 'src/hooks/use-cognito-user'
import { actions as accountActions } from 'src/modules/entities/account/redux'

import { routes } from '../routes'

export const useInit = (option?: { cancelRedirect?: boolean }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [initialized, setInitialized] = useState(false)
  const { initCognitoUser } = useCognitoUser()

  useEffect(() => {
    const fetch = async () => {
      try {
        await initCognitoUser()
        await dispatch(accountActions.fetchMe(null))
        setInitialized(true)
      } catch (error) {
        console.error('accountActions.fetchMe Error: ', error)
        if (option?.cancelRedirect) {
          setInitialized(true)
          return
        }
        error.original?.response?.status === 403
          ? navigate(routes.httpError403)
          : navigate(`${routes.requiredLogin}`)
      }
    }

    fetch()
  }, [
    dispatch,
    initCognitoUser,
    setInitialized,
    option?.cancelRedirect,
    navigate,
  ])

  return {
    initialized,
  }
}
