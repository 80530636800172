import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Add, Notification } from 'src/components/icon'
import { NotificationPriority } from 'src/lib/gql-client'

import { useGetNotificationsByGroupKey } from '../../api/getNotificationsByGroupKey'
import { useNotification } from '../../hooks/useNotification'
import { NotificationTile } from '../NotificationTile/NotificationTile'

export const NotificationButton: React.FC = () => {
  const { notifications, unreadCount, hasNextPage, loadNext } =
    useNotification()
  return (
    // cssのwarningを抑制するためにBoxで囲う: https://github.com/chakra-ui/chakra-ui/issues/3440
    <Box>
      <Menu closeOnSelect={false}>
        {({ onClose }) => (
          <>
            <MenuButton
              as={IconButton}
              aria-label="Notification button"
              icon={
                <>
                  <Notification size="lg" color="white" />
                  {(unreadCount ?? 0) > 0 && (
                    <Box
                      position="absolute"
                      top="1"
                      right="1"
                      px={2}
                      py={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="4"
                      h="4"
                      fontSize="11px"
                      fontWeight="bold"
                      color="white"
                      transform="translate(50%, -50%)"
                      bg="red.500"
                      borderRadius="full"
                    >
                      {unreadCount}
                    </Box>
                  )}
                </>
              }
              position="relative"
              variant="customIconButtonGhost"
              rounded="full"
              isDisabled={notifications.length === 0}
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            />
            <MenuList maxH="calc(100vh - 64px)" overflow="scroll">
              {notifications.map(({ cursor, node, countGroup }) => (
                <NotificationGroupItem
                  key={node.notificationId}
                  countGroup={countGroup}
                  {...node}
                  cursor={cursor}
                  onCloseMenu={onClose}
                />
              ))}
              {hasNextPage && (
                <MenuItem
                  onClick={loadNext}
                  display="flex"
                  pt="3"
                  justifyContent="center"
                >
                  <Text fontSize="sm" color="blue.500" textAlign="center">
                    もっと見る
                  </Text>
                </MenuItem>
              )}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}

const NotificationGroupItem = (props: {
  notificationId: string
  cursor: string
  countGroup: number
  group: string
  message: string
  link: string
  linkText: string
  savedAt: string
  hasBeenRead: boolean
  priority: NotificationPriority
  onCloseMenu: () => void
}) => {
  const { isOpen, onOpen } = useDisclosure()
  const { data: groupNotifications } = useGetNotificationsByGroupKey({
    shouldCancel: !isOpen,
    after: props.cursor,
    groupKey: props.group,
  })

  return (
    <>
      <NotificationTile {...props} isChildComponent={false} />
      {props.countGroup > 1 && (
        <>
          {isOpen && groupNotifications ? (
            <>
              {groupNotifications.map(({ node }, index) => (
                <>
                  <MenuDivider my="0" mx="8" />
                  <NotificationTile
                    key={node.notificationId}
                    {...node}
                    onCloseMenu={props.onCloseMenu}
                    isChildComponent={true}
                    isFirst={index === 0}
                    isLast={index === groupNotifications.length - 1}
                  />
                </>
              ))}
            </>
          ) : (
            <MenuItem
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg={props.hasBeenRead ? undefined : 'red.50'}
              color="blue.500"
              onClick={onOpen}
              gap="1"
            >
              <Add />
              <Text color="blue.500" fontSize="sm" pt="0.5">
                {`他${props.countGroup - 1}件のメッセージ`}
              </Text>
            </MenuItem>
          )}
        </>
      )}

      <MenuDivider my="0" mx="2" />
    </>
  )
}
