import { fixture } from 'src/modules/entities/patient/fixture'
import {
  PatientResponse,
  parse as parsePatient,
} from 'src/modules/entities/patient/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { PatientList } from './entity'
import { PatientStatusFilterCondition } from './search/status-filter'
import {
  SortKey as PatientSortKey,
  SortOrder as PatientSortOrder,
} from './sort'

type PatientListResponse = {
  patients: PatientResponse[]
  total_count: number
}

const parse = (resp: PatientListResponse): PatientList => {
  return {
    patients: resp.patients.map(parsePatient),
    totalCount: resp.total_count,
  }
}

export type FetchPatientListParam = {
  trialUid: string
  page: number
  per: number
  searchQuery: string
  statusFilter: PatientStatusFilterCondition
  sortKey: PatientSortKey
  sortOrder: PatientSortOrder
}

export const fetch = async (
  params: FetchPatientListParam,
): Promise<PatientList> => {
  const { trialUid, page, per, sortKey, sortOrder, searchQuery, statusFilter } =
    params

  if (IS_MOCK_MODE) {
    return {
      patients: [fixture],
      totalCount: 1,
    }
  }

  const queryParam = `?page=${page}&per=${per}&q=${encodeURIComponent(
    searchQuery,
  )}&status=${statusFilter}&sort=${sortKey}&order=${sortOrder}`
  const resp = await requestGet<PatientListResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients` + queryParam,
    params: [],
  })

  return parse(resp.data)
}
