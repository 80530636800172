import { Field } from '@micin-jp/chicken-schema'

export type FlattenFieldMap = Map<Field['fid'], Field>

/** Schema定義から階層構造を持たないフラットなフィールドのMapを取得する */
export const getFlattenFieldMap = (fields: Field[]): FlattenFieldMap => {
  return new Map(getFlattenFields(fields).map(field => [field.fid, field]))
}

export const getFlattenFields = (fields: Field[]): Field[] => {
  return fields.flatMap(field => {
    if (
      field.typeDef.type === 'RepeatableSection' ||
      field.typeDef.type === 'Section'
    ) {
      return [field, ...getFlattenFields(field.typeDef.fields)]
    }
    return [field]
  })
}
