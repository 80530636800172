import React from 'react'

import { HStack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Button } from 'src/components/base/button/button'
import { gray } from 'src/components/base/color/palette'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { AnchorLink } from 'src/components/base/text-link'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Label } from 'src/components/form-redesigned/label'
import { CommonDisableLabel } from 'src/components/labels/disabled'
import { Spacer } from 'src/components/spacer/spacer'
import styled, { css } from 'styled-components'

import { IcfDocumentRevisionStatusBadge } from '../../icf-document/components/icf-document-status-badge'
import { RequiredPatientSignBadge } from '../../icf-document/components/required-patient-sign-badge'
import {
  icfDocumentRevisionStatus,
  icfDocumentTypeText,
} from '../../icf-document/entity'
import { IcfDocumentSet } from '../entity'

type Props = {
  icfDocumentSet: IcfDocumentSet | undefined
  listPath: string
  canEdit: boolean
  canEnable: boolean
  navigateToEdit: () => void
  getFilePreviewUrl: (revisionUid: string) => string
  onEnable: () => Promise<void>
  shouldShowHospitalName: boolean
  hospitalName: string
}

const documentTableWidth: Record<
  keyof Pick<
    IcfDocumentSet['icfDocuments'][number],
    | 'status'
    | 'numberingId'
    | 'latestApprovedName'
    | 'latestApprovedVersion'
    | 'type'
    | 'latestApprovedAt'
  >,
  string | undefined
> = {
  status: '120px',
  numberingId: '80px',
  latestApprovedName: undefined,
  latestApprovedVersion: '80px',
  type: '160px',
  latestApprovedAt: '180px',
}

export const IcfDocumentSetDetail: React.FC<Props> = ({
  icfDocumentSet,
  listPath,
  canEdit,
  canEnable,
  navigateToEdit,
  getFilePreviewUrl,
  onEnable,
  shouldShowHospitalName,
  hospitalName,
}) => {
  const breadCrumbParams: BreadcrumbParam[] = [
    { label: '文書セット一覧', isLink: true, path: listPath },
    { label: '文書セット詳細', isLink: false },
  ]

  if (!icfDocumentSet) {
    return null
  }

  return (
    <Wrapper>
      <Breadcrumb breadcrumbParams={breadCrumbParams} />
      <Spacer size={32} />
      <ButtonContainer>
        {canEdit && (
          <Button
            size="S"
            text="設定編集"
            buttonType="important"
            onClick={navigateToEdit}
          />
        )}
        {canEnable && (
          <Button
            size="S"
            text="文書セットを有効化"
            buttonType="normal"
            onClick={onEnable}
          />
        )}
      </ButtonContainer>
      {shouldShowHospitalName && (
        <HospitalNameContainer>
          <Text fontSize="sm" fontWeight="bold">
            医療機関：{hospitalName}
          </Text>
        </HospitalNameContainer>
      )}
      <Spacer size={32} />
      <Content>
        <ItemContainer>
          <Item width={120}>
            <Label bold>文書セットID</Label>
          </Item>
          <Item flex>
            <Text
              fontSize="sm"
              color={icfDocumentSet.enabled ? undefined : gray[55]}
              fontWeight="bold"
            >
              {icfDocumentSet.numberingId}
            </Text>
            {!icfDocumentSet.enabled && (
              <>
                <Spacer size={16} horizontal />
                <CommonDisableLabel text="無効" />
              </>
            )}
          </Item>
        </ItemContainer>

        <ItemContainer>
          <Item width={120}>
            <Label bold>文書セット名</Label>
          </Item>
          <Item>
            <Text
              fontSize="sm"
              color={icfDocumentSet.enabled ? undefined : gray[55]}
            >
              {icfDocumentSet.name}
            </Text>
          </Item>
        </ItemContainer>

        <DocumentContainer>
          <Label bold>設定文書</Label>
          <Spacer size={8} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell width={documentTableWidth['status']}>
                    ステータス
                  </TableHeadCell>
                  <TableHeadCell width={documentTableWidth['numberingId']}>
                    文書ID
                  </TableHeadCell>
                  <TableHeadCell
                    width={documentTableWidth['latestApprovedName']}
                  >
                    文書名
                  </TableHeadCell>
                  <TableHeadCell
                    width={documentTableWidth['latestApprovedVersion']}
                  >
                    版数
                  </TableHeadCell>
                  <TableHeadCell width={documentTableWidth['type']}>
                    文書の種類
                  </TableHeadCell>
                  <TableHeadCell width={documentTableWidth['latestApprovedAt']}>
                    承認日時
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {icfDocumentSet.icfDocuments
                  .sort((a, b) => a.index - b.index)
                  .map(document => (
                    <TableRow
                      key={document.uid}
                      borderBottom
                      disabled={
                        document.status === icfDocumentRevisionStatus.Disabled
                      }
                    >
                      <TableBodyCell width={documentTableWidth['status']}>
                        <IcfDocumentRevisionStatusBadge
                          status={
                            document.status ===
                            icfDocumentRevisionStatus.Disabled
                              ? icfDocumentRevisionStatus.Disabled
                              : icfDocumentRevisionStatus.Approved
                          }
                        />
                      </TableBodyCell>
                      <TableBodyCell width={documentTableWidth['numberingId']}>
                        {document.numberingId}
                      </TableBodyCell>
                      <TableBodyCell
                        width={documentTableWidth['latestApprovedName']}
                      >
                        <AnchorLink
                          href={getFilePreviewUrl(
                            document.latestApprovedRevisionUid,
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {document.latestApprovedName}
                        </AnchorLink>
                      </TableBodyCell>
                      <TableBodyCell
                        width={documentTableWidth['latestApprovedVersion']}
                      >
                        {document.latestApprovedVersion}
                      </TableBodyCell>
                      <TableBodyCell width={documentTableWidth['type']}>
                        <HStack spacing="8px">
                          <span>{icfDocumentTypeText[document.type]}</span>{' '}
                          {document.latestIsRequiredPatientSign && (
                            <RequiredPatientSignBadge />
                          )}
                        </HStack>
                      </TableBodyCell>
                      <TableBodyCell
                        width={documentTableWidth['latestApprovedAt']}
                      >
                        {dayjs(document.latestApprovedAt).format(
                          'YYYY/MM/DD HH:mm:ss',
                        )}
                      </TableBodyCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DocumentContainer>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const HospitalNameContainer = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.div`
  max-width: 880px;

  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 24px;
  }
`

const Item = styled.div<{ width?: number; flex?: boolean }>`
  width: ${p => p.width}px;

  ${p => p.flex && flexItemStyle}
`

const flexItemStyle = css`
  display: flex;
  align-items: center;
`

const DocumentContainer = styled.div``

const TableContainer = styled.div`
  height: 350px;
`
