import { generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { routes } from 'src/modules/routes'

import { ExplanationRoomUserAttributeType } from './user-attribute-type'

export const trialUidParamName = 'trialUid'
const expRevUidParamName = 'expRevUid'
const sessionUidParamName = 'sessionUid'
export const docRevisionUidParamName = 'docRevisionUid'
export const explanationUidParamName = 'explanationUid'

const trialUidPath = `trials/:${trialUidParamName}` as const

export const explanationRoomRoutes = {
  lobby: `${trialUidPath}/lobbies/:${expRevUidParamName}`,
  top: `${trialUidPath}/sessions/:${sessionUidParamName}`,
  document: `${trialUidPath}/sessions/:${sessionUidParamName}/documents/:${docRevisionUidParamName}`,
  docuSign: `${trialUidPath}/sessions/:${sessionUidParamName}/documents/:${docRevisionUidParamName}/docusign`,
  completeDocuSign: `${trialUidPath}/sessions/:${sessionUidParamName}/documents/:${docRevisionUidParamName}/complete-docusign`,
  withExplanationUid: `${trialUidPath}/explanations/:${explanationUidParamName}`,
  notFoundError: 'notfound',
  expiredError: 'expired',
  finishedRemote: 'finished-remote',
  finishedInPerson: 'finished-in-person',
} as const

export type ExplanationRoomRoute =
  (typeof explanationRoomRoutes)[keyof typeof explanationRoomRoutes]

export const explanationRoomPreviewRoutes = {
  top: '',
  document: `documents/:${docRevisionUidParamName}`,
} as const

export type ExplanationRoomPreviewRoute =
  (typeof explanationRoomPreviewRoutes)[keyof typeof explanationRoomPreviewRoutes]

const basePath = (type: ExplanationRoomUserAttributeType) =>
  type === 'hospital'
    ? generatePath(Paths.HospitalExplanationRoom)
    : routes.patientExplanationRoom

const trialPath = (type: ExplanationRoomUserAttributeType, trialUid: string) =>
  `${basePath(type)}/trials/${trialUid}`

export const getExplanationRoomTopRoute = ({
  type,
  trialUid,
  sessionUid,
}: {
  trialUid: string
  sessionUid: string
  type: ExplanationRoomUserAttributeType
}) => {
  return `${trialPath(type, trialUid)}/sessions/${sessionUid}`
}

export const getExplanationRoomTopPreviewRoute = (
  trialUid: string,
  expUid: string,
) => {
  return `${routes.previewExplanationRoom}/trials/${trialUid}/explanations/${expUid}`
}

export const getExplanationRoomDocumentRoute = ({
  type,
  trialUid,
  sessionUid,
  docRevisionUid,
}: {
  type: ExplanationRoomUserAttributeType
  trialUid: string
  sessionUid: string
  docRevisionUid: string
}) => {
  return `${trialPath(
    type,
    trialUid,
  )}/sessions/${sessionUid}/documents/${docRevisionUid}`
}

export const getExplanationRoomDocumentPreviewRoute = (
  trialUid: string,
  expUid: string,
  docRevisionUid: string,
) => {
  return `${routes.previewExplanationRoom}/trials/${trialUid}/explanations/${expUid}/documents/${docRevisionUid}`
}
export const getNotFoundRoute = ({
  type,
}: {
  type: ExplanationRoomUserAttributeType
}) => `${basePath(type)}/${explanationRoomRoutes.notFoundError}`
