import React from 'react'

import { Navigate, Route, useLocation, Link, Routes } from 'react-router-dom'
import { Tab, TabPanel } from 'src/components/base/tabs/tabs'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import { CrfDownload } from './download/crf-download'
import { routes } from './routes'
import { CrfUpload } from './upload/crf-upload'

type CrfItem = {
  label: string
  path: string
  component: React.ReactNode
}

const crfItems: CrfItem[] = [
  {
    label: 'ファイルアップロード',
    path: routes.upload,
    component: <CrfUpload />,
  },
  {
    label: 'ファイルダウンロード',
    path: routes.download,
    component: <CrfDownload />,
  },
]

const breadcrumbParams: BreadcrumbParam[] = [
  { label: '設定', isLink: false },
  { label: 'CRF管理', isLink: false },
]

export const Crf: React.FC = () => {
  const location = useLocation()

  const isActive = (item: CrfItem) => {
    return location.pathname.endsWith(item.path)
  }

  return (
    <Wrapper>
      <Breadcrumb breadcrumbParams={breadcrumbParams} />
      <Spacer size={24} />
      <TabPanel>
        {crfItems.map(item => (
          <TabLink key={item.path} to={item.path}>
            <Tab selected={isActive(item)}>{item.label}</Tab>
          </TabLink>
        ))}
      </TabPanel>

      <Spacer size={48} />

      <Routes>
        {crfItems.map(item => (
          <Route
            key={item.path}
            path={`${item.path}`}
            element={item.component}
          />
        ))}
        <Route
          path="*"
          element={<Navigate replace to={`${routes.upload}`} />}
        />
      </Routes>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TabLink = styled(Link)`
  text-decoration: none;
`
