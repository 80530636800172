import { memo } from 'react'

import { Text } from '@chakra-ui/react'
import { white, gray } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { Spacer } from 'src/components/spacer/spacer'
import {
  AnswerType,
  QuestionnaireField,
} from 'src/modules/entities/questionnaire-field/entity'
import { hasChoice } from 'src/modules/util/answer-type-classification'
import styled from 'styled-components'

import { FixedEditableAnswerTypeSelect } from './answer-type-select'
import { FixedQuestionnaireChoice } from './choice'

type Props = {
  field: QuestionnaireField
}

export const FixedQuestionnaireField: React.FC<Props> = memo(({ field }) => {
  const { title, unit, answerType } = field

  return (
    <Container>
      <Content>
        <Item>
          <Label>
            <Text fontWeight="bold">質問名</Text>
          </Label>
          <Spacer size={40} horizontal />
          <Textarea width="480px" value={title} disabled={true} />
        </Item>

        <Spacer size={16} />

        <Item>
          <Label>
            <Text fontWeight="bold">回答タイプ</Text>
          </Label>
          <Spacer size={40} horizontal />
          <FixedEditableAnswerTypeSelect selectedValue={answerType} />
        </Item>

        <Spacer size={16} />

        {hasChoice(field) && <FixedChoiceList field={field} />}

        {field.answerType === AnswerType.Number && (
          <Item>
            <Label>
              <Text fontWeight="bold">入力する数値の単位</Text>
            </Label>
            <Spacer size={40} horizontal />
            <Input width="40%" value={unit} disabled={true} />
          </Item>
        )}
      </Content>
    </Container>
  )
})

type FixedChoiceListProps = {
  field: QuestionnaireField
}

const FixedChoiceList: React.FC<FixedChoiceListProps> = ({ field }) => {
  return (
    <ChoiceItem>
      <Label>
        <Text fontWeight="bold">回答の選択肢</Text>
      </Label>
      <Spacer size={40} horizontal />
      <ChoiceContainer>
        {Object.values(field.choices)
          .sort((a, b) => a.index - b.index)
          .map(c => (
            <FixedQuestionnaireChoice key={c.uid} choice={c} />
          ))}
      </ChoiceContainer>
    </ChoiceItem>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Content = styled.div`
  flex-grow: 1;
  background-color: ${white};
  padding: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${gray[40]};
  position: relative;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ChoiceItem = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
`

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Label = styled.div`
  width: 120px;
`
