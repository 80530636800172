import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

import { VerifyOTPRes } from '.'

export const verifyOTPResMock: VerifyOTPRes = {
  result: 'Verified',
}

export const verifyOTPHandler = restHandlerFactory(
  '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/verify_otp',
  'post',
  () => {
    return HttpResponse.json(verifyOTPResMock, { status: 200 })
  },
)
