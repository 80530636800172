// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgShare = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M13.019 10.074c-.795 0-1.491.296-2.087.79l-4.97-2.469v-.79l4.97-2.47c.497.495 1.292.79 2.087.79 1.69 0 2.981-1.283 2.981-2.962C16 1.283 14.708 0 13.019 0c-1.69 0-2.982 1.284-2.982 2.963v.395l-4.969 2.47c-.497-.495-1.292-.79-2.087-.79C1.291 5.037 0 6.32 0 8c0 1.679 1.292 2.963 2.981 2.963.795 0 1.491-.296 2.087-.79l4.97 2.469v.395c0 1.68 1.291 2.963 2.98 2.963C14.709 16 16 14.716 16 13.037s-1.292-2.963-2.981-2.963Z"
    />
  </svg>
)
export default SvgShare
