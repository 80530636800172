import { ComponentStyleConfig } from '@chakra-ui/react'

export const Checkbox: ComponentStyleConfig = {
  baseStyle: ({ colorScheme: c }) => ({
    container: {
      alignItems: 'start',
      whiteSpace: 'pre-line',
    },
    control: {
      bg: 'white',
      borderRadius: 'base',
      _checked: {
        bg: `${c}.500`,

        _disabled: {
          color: 'white',
          bg: `${c}.500`,
          border: 'none',
          opacity: 0.3,
        },

        _invalid: {
          border: 'none',
        },
      },

      _hover: {
        borderColor: 'gray.400',
      },

      _disabled: {
        borderColor: 'gray.300',
        _hover: {
          borderColor: 'gray.300',
        },
        opacity: 0.3,
      },

      _invalid: {
        _hover: {
          borderColor: 'red.600',
        },
      },
    },
    label: {
      ml: 1.5,

      _invalid: {
        color: 'red.500',
      },
    },
  }),
  sizes: {
    md: {
      control: {
        h: '5',
        w: '5',
      },
      label: {
        fontSize: 'sm',
        lineHeight: '5',
      },
    },
  },
  defaultProps: {
    colorScheme: 'blue',
    size: 'md',
  },
}
