import { Text } from '@chakra-ui/react'
import {
  StaticScreen,
  StaticScreenContent,
} from 'src/components/StaticScreen/StaticScreen'

export const ExplanationRoomNotFound: React.FC = () => {
  return (
    <StaticScreen colorScheme="green">
      <StaticScreenContent>
        <Text>
          ページが見つかりませんでした。お手数ですが、URLをご確認ください。
        </Text>
      </StaticScreenContent>
    </StaticScreen>
  )
}
