import dayjs from 'dayjs'
import { errorMessages } from 'src/constants/errorMessages'
import { isValidDateStr } from 'src/utils/isValidDateStr'
import { isValidYearRange } from 'src/utils/isValidYearRange'

import { assertNever } from '../../../utils/assertNever'
import { CheckedFieldValue, FieldValue } from '../types'

const numberRegex = /^(-?)(\d+)(\.\d+)?$/
export const MAX_TEXT_LENGTH = 255
const MAX_TEXTAREA_LENGTH = 512

export const validateFieldValue = (value: FieldValue): CheckedFieldValue => {
  const typ = value.type
  switch (typ) {
    case 'text':
      if (value.textValue.length > MAX_TEXT_LENGTH) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.maxLength(MAX_TEXT_LENGTH),
        }
      }
      break
    case 'textarea':
      if (value.textareaValue.length > MAX_TEXTAREA_LENGTH) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.maxLength(MAX_TEXTAREA_LENGTH),
        }
      }
      break
    case 'number':
      const numMatch = value.numberValue.match(numberRegex)
      if (!numMatch) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.onlyNumber(),
        }
      }

      const integerInValid = numMatch[2] && numMatch[2].length > 5
      const decimalInValid = numMatch[3] && numMatch[3].length > 5 // .を含む

      if (integerInValid || decimalInValid) {
        return {
          ...value,
          isValid: false,
          errorMessage: '整数部5桁、小数点第4位までの数値を入力してください',
        }
      }
      break
    case 'date':
      const dateSplit = value.dateValue.split('-')
      if (dateSplit.length !== 3) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.date(),
        }
      }

      const dateError = validateDate(dateSplit[0], dateSplit[1], dateSplit[2])
      if (dateError) {
        return {
          ...value,
          isValid: false,
          errorMessage: dateError,
        }
      }
      break
    case 'nullableDate':
      const nullableDateSplit = value.nullableDateValue.split('-')
      if (nullableDateSplit.length !== 3) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.date(),
        }
      }
      // 日はあるが月がない
      if (!nullableDateSplit[1] && nullableDateSplit[2]) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.date(),
        }
      }
      const nullableDateError = validateDate(
        nullableDateSplit[0],
        nullableDateSplit[1] === '' ? '01' : nullableDateSplit[1],
        nullableDateSplit[2] === '' ? '01' : nullableDateSplit[2],
      )
      if (nullableDateError) {
        return {
          ...value,
          isValid: false,
          errorMessage: nullableDateError,
        }
      }
      break
    case 'time':
      const timeSplit = value.timeValue.split(':')
      if (timeSplit.length !== 2) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.time(),
        }
      }
      // どちらも未入力の場合はOK
      if (timeSplit[0] === '' && timeSplit[1] === '') break
      const hour = timeSplit[0] === '' ? '' : timeSplit[0].padStart(2, '0')
      const minute = timeSplit[1] === '' ? '' : timeSplit[1].padStart(2, '0')
      if (!dayjs(`${hour}:${minute}`, 'HH:mm', true).isValid()) {
        return {
          ...value,
          isValid: false,
          errorMessage: errorMessages.time(),
        }
      }
      break
    case 'checkbox':
    case 'radio':
    case 'selectMenu':
    case 'repeatableSection':
    case 'cleared':
    case 'file':
      break
    default:
      assertNever(typ)
  }
  return {
    ...value,
    isValid: true,
  }
}

const validateDate = (
  yearStr: string,
  monthStr: string,
  dayStr: string,
): string | undefined => {
  const year = yearStr.padStart(4, '0')

  // 空文字の場合は範囲チェックしない
  if (yearStr !== '') {
    if (!isValidYearRange(year)) {
      return errorMessages.yearRange()
    }
  }
  if (!isValidDateStr({ year, month: monthStr, day: dayStr })) {
    return errorMessages.date()
  }
}
