import React from 'react'

import { Box } from '@chakra-ui/react'
import { white } from 'src/components/base/color/palette'
import { useOpenModal } from 'src/hooks/use-open-modal'
import styled from 'styled-components'

import { FinishExplanationModal } from './components/finish-explanation-modal'
import { ExplanationRoomHeader } from './components/header'

type Props = {
  headerTitle: string
  sidebarContent: React.ReactNode
  isPreview?: boolean
  children: React.ReactNode
  onFinish?: () => void
}

const HEADER_HEIGHT = 64

// NOTE: previewとルームのみで使用
export const ExplanationRoomLayout: React.FC<Props> = ({
  headerTitle,
  sidebarContent,
  isPreview,
  children,
  onFinish,
}) => {
  const {
    modalOpen: finishModalOpen,
    handlers: { openModal: openFinishModal, closeModal: closeFinishModal },
  } = useOpenModal()

  return (
    <Container>
      {finishModalOpen && !!onFinish && (
        <FinishExplanationModal
          onClose={closeFinishModal}
          onFinish={onFinish}
          isPreview={isPreview}
        />
      )}
      <ExplanationRoomHeader
        title={headerTitle}
        height={HEADER_HEIGHT}
        canFinish={!!onFinish}
        isPreview={isPreview}
        onClickFinish={openFinishModal}
      />
      <ContentContainer>
        <Box flex={1} overflow="auto" h="full" bg="green.50">
          {children}
        </Box>
        {sidebarContent !== null && (
          <SidebarContainer>{sidebarContent}</SidebarContainer>
        )}
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  height: calc(100% - ${HEADER_HEIGHT}px);
`

const SidebarContainer = styled.div`
  width: 25%;
  padding: 48px 24px;
  box-sizing: border-box;
  background-color: ${white};
  height: 100%;
`
