import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  DeleteStickyMessageDocument,
  DeleteStickyMessageInput,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const deleteStickyMessage = async (input: DeleteStickyMessageInput) => {
  try {
    await graphqlRequest(DeleteStickyMessageDocument, { input })
  } catch (error) {
    throw error
  }
}

export const useDeleteStickyMessage = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    (input: DeleteStickyMessageInput) => deleteStickyMessage(input),
    option,
  )
}
