import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { layouts } from 'src/assets/layouts'
import { gray } from 'src/components/base/color/palette'
import { ButtonLink } from 'src/components/base/text-link'
import { CommonDisableLabel } from 'src/components/labels/disabled'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { DataType, ItemType } from 'src/modules/entities/small-item/entity'
import styled from 'styled-components'

import { AddLoglineButton } from './add-logline/button'
import { worksheetIdParamName } from './worksheet-detail'
import { MediumItem } from '../../../../../../../../entities/medium-item/entity'
import {
  MediumItemAnswer,
  MediumItemAnswerStatus,
} from '../../../../../../../../entities/medium-item-answer/entity'
import { statusToText } from '../../../../../../../../entities/medium-item-answer/util'
import { toValueString } from '../../../../../../../../entities/small-item-answer/util'
import { getRootItems } from '../../../../../../../../util/get-root-items'
import { usePermission } from '../../../../../common/permission'
import { patientIdParamName } from '../../worksheet'

const maxShowableItemSize = 4

type Props = {
  mediumItem: MediumItem
  mediumItemAnswers: MediumItemAnswer[]
  worksheetEditable: boolean
}

export const LoglineSection: React.FC<Props> = props => {
  const { mediumItem, mediumItemAnswers, worksheetEditable } = props
  const { patientUid = '', worksheetUid = '' } = useParams<{
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()
  const trial = useSelector(getSelectedTrial)!

  const { hasPermission } = usePermission()

  const navigate = useNavigate()

  const rootSmallItems = getRootItems(Object.values(mediumItem.smallItemMap))

  const needEllipsis = (index: number) => {
    if (rootSmallItems.length <= maxShowableItemSize) {
      return false
    }

    return index + 1 === maxShowableItemSize
  }

  const canAddLogline =
    hasPermission(PERMISSIONS.Worksheet_AddLogline) && worksheetEditable

  const hasClinicalSmallItem = Object.values(mediumItem.smallItemMap).some(
    si => si.dataType === DataType.Clinical,
  )
  const edcLinked = hasClinicalSmallItem && trial.edcIntegrationEnabled

  const disabled = (mia: MediumItemAnswer) =>
    mia.status === MediumItemAnswerStatus.Disabled

  const byLineNumberAsc = (a: MediumItemAnswer, b: MediumItemAnswer) =>
    a.lineNumber - b.lineNumber

  const loglines = mediumItemAnswers.sort(byLineNumberAsc)

  return (
    <Container id={mediumItem.uid}>
      <TitleContainer>
        <Text fontWeight="bold">{mediumItem.title}</Text>
        {canAddLogline && (
          <ButtonContainer>
            <AddLoglineButton
              trialUid={trial.uid}
              patientUid={patientUid}
              worksheetUid={worksheetUid}
              mediumItemUid={mediumItem.uid}
              edcLinked={edcLinked}
            />
          </ButtonContainer>
        )}
      </TitleContainer>

      <Spacer size={18} />

      <Table>
        <Head>
          <LineNumber>
            <Text fontWeight="bold">#</Text>
          </LineNumber>

          <ItemsContainer>
            {rootSmallItems.slice(0, maxShowableItemSize).map((si, i) => (
              <HeadItem key={si.uid}>
                <HeadText hidden={si.itemType === ItemType.Label}>
                  <Text fontWeight="bold">{si.title}</Text>
                </HeadText>
                {needEllipsis(i) && <Ellipsis />}
              </HeadItem>
            ))}
          </ItemsContainer>

          <StatusHead>ステータス</StatusHead>
        </Head>

        <Body>
          {loglines.length === 0 ? (
            <Row>
              <Spacer size={36} horizontal />
              <Text fontSize="sm" color={gray[55]}>
                行の追加をしてください。
              </Text>
            </Row>
          ) : (
            loglines.map(logline => {
              const rootSmallItemAnswers = getRootItems(
                Object.values(logline.smallItemAnswerMap),
              )
              const showableSmallItemAnswers = rootSmallItemAnswers.slice(
                0,
                maxShowableItemSize,
              )

              return (
                <Row key={logline.uid}>
                  <LineNumber>
                    <Text fontWeight="bold">{logline.lineNumber}</Text>
                  </LineNumber>

                  <ItemsContainer>
                    {showableSmallItemAnswers.map(sia => (
                      <BodyItem key={sia.uid} disabled={disabled(logline)}>
                        {sia.itemType === ItemType.Label ? (
                          <Text fontWeight="bold">{sia.title}</Text>
                        ) : (
                          <Text>{toValueString(sia)}</Text>
                        )}
                      </BodyItem>
                    ))}
                  </ItemsContainer>

                  <RightSide>
                    <Status>
                      {disabled(logline) ? (
                        <CommonDisableLabel
                          width={55}
                          height={25}
                          text="無効"
                        />
                      ) : (
                        statusToText(logline.status)
                      )}
                    </Status>
                    <DetailButtonContainer>
                      <ButtonLink
                        onClick={() => navigate(`loglines/${logline.uid}`)}
                      >
                        詳細
                      </ButtonLink>
                    </DetailButtonContainer>
                  </RightSide>
                </Row>
              )
            })
          )}
        </Body>
      </Table>
    </Container>
  )
}

const Container = styled.div``

const TitleContainer = styled.div`
  margin-top: 18px;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Table = styled.div`
  border: 1px solid ${gray[40]};
  border-radius: 10px;
`

const ItemsContainer = styled.div`
  display: flex;
  flex: 1;
`

const Item = styled.div`
  box-sizing: border-box;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  position: relative;
  border-bottom: 1px solid ${gray[40]};
`

const LineNumber = styled.div`
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeadItem = styled(Item)`
  font-weight: bold;
  display: flex;
  align-items: center;
`

const HeadText = styled.div<{ hidden: boolean }>`
  display: block;
  visibility: ${props => props.hidden && 'hidden'};
  word-break: break-all;
`

const Body = styled.div``

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  min-height: 30px;
  margin: 10px;
  border-bottom: 1px dashed ${gray[40]};
`

const BodyItem = styled(Item)<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  word-break: break-all;
  white-space: pre-wrap;
`

const Ellipsis = styled.div`
  padding-left: 20px;
  flex: 1;

  &::after {
    content: '...';
  }
`

const RightSide = styled.div`
  display: flex;
`

const Status = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${layouts.tabletLandscapeWithMin}) {
    width: 120px;
  }
`

const buttonWidth = '60px'
const StatusHead = styled(Status)`
  margin-right: ${buttonWidth};
  font-weight: bold;
`
const DetailButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${buttonWidth};
`
