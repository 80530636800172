import React from 'react'

import { usePane } from 'src/hooks/use-pane'
import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { AddIcfDocumentRevision } from './add-revision'
import { useAddIcfDocumentRevision } from './add-revision.hooks'
import { RevisionHistoryContainer } from '../revision-history/revision-history-container'

export const AddIcfDocumentRevisionContainer: React.FC = () => {
  const {
    icfDocument,
    nextRevision,
    editing,
    filePreviewUrl,
    listPath,
    editPath,
    errors,
    shouldSelectHospital,
    hospitalName,
    hasMultipleRevisionHistories,
    hasTempFile,
    pullDownState,
    submitModalState,
    onChangeName,
    onChangeVersion,
    onChangeRequiredPatientSign,
    onSelectFile,
    onValidate,
    onSubmit,
    onCancel,
  } = useAddIcfDocumentRevision()

  const {
    handlers: { openPane: openRevisionHistoryPane },
    renderPane,
  } = usePane({ paneType: 'modal', closeOnOutsideClick: true })

  useBlockTransition({
    showAlert: editing,
  })

  return (
    <>
      {renderPane(<RevisionHistoryContainer />)}

      <AddIcfDocumentRevision
        icfDocument={icfDocument}
        nextRevision={nextRevision}
        filePreviewUrl={filePreviewUrl}
        listPath={listPath}
        editPath={editPath}
        errors={errors}
        shouldSelectHospital={shouldSelectHospital}
        hospitalName={hospitalName}
        hasMultipleRevisionHistories={hasMultipleRevisionHistories}
        hasTempFile={hasTempFile}
        pullDownState={pullDownState}
        submitModalState={submitModalState}
        onValidate={onValidate}
        onChangeName={onChangeName}
        onChangeVersion={onChangeVersion}
        onChangeRequiredPatientSign={onChangeRequiredPatientSign}
        onSelectFile={onSelectFile}
        onSubmit={onSubmit}
        onCancel={onCancel}
        openRevisionHistoryPane={openRevisionHistoryPane}
      />
    </>
  )
}
