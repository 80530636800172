import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import styled from 'styled-components'

type Props = {
  diseaseUid: string
  errorMessage: string | null
  requesting: boolean
  modalOpen: boolean
  worksheetUid: string
  onSubmit: () => void
  showModal: () => void
  closeModal: () => void
  onClick?: () => void
  changeInputValue: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputValue: string
}

export const EnableButton: React.FC<Props> = props => {
  const {
    diseaseUid,
    errorMessage,
    requesting,
    modalOpen,
    worksheetUid,
    onSubmit,
    showModal,
    closeModal,
    inputValue,
  } = props
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    showModal()
  }

  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: closeModal,
    deps: [worksheetUid],
  })

  return (
    <>
      <Button
        size="S"
        text="患者を有効化"
        onClick={onClick}
        buttonType="normal"
      />

      {modalOpen && (
        <SubmitModal
          title={'患者を有効化'}
          submitText={'有効化'}
          errorMessage={errorMessage || ''}
          requesting={requesting}
          onSubmit={onSubmit}
          onClose={closeModal}
          disabled={!inputValue ? true : false}
        >
          <Text textAlign="center">
            症例番号：<Bold>{diseaseUid}</Bold>さんを有効化します。
            <br />
            よろしいですか？
          </Text>
          <Spacer size={20} />
          <Label bold>有効化理由</Label>
          <Spacer size={10} />
          <Input
            placeholder={'有効化理由を入力してください'}
            onChange={props.changeInputValue}
          />
        </SubmitModal>
      )}
    </>
  )
}

const Bold = styled.span`
  font-weight: bold;
`
