import { Button } from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { useSpinner } from 'src/hooks/use-spinner'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useCreateEnvelope } from '../../api'
import {
  ExplanationDocRevisionCheckUnderstanding,
  ExplanationInPersonSession,
  ExplanationRemotePartnerSession,
} from '../../types'

type Props = {
  session: ExplanationInPersonSession | ExplanationRemotePartnerSession
  docRevision: ExplanationDocRevisionCheckUnderstanding
}

export const StartCheckUnderstanding: React.FC<Props> = ({
  docRevision,
  session,
}) => {
  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const navigate = useNavigate()

  const navigateToDocuSign = () => {
    navigate(
      generatePath(Paths.ExplanationRoomDocuSign, {
        trialUid: selectedTrial.uid,
        sessionUid: session.uid,
        explanationDocRevisionUid: docRevision.uid,
      }),
    )
  }

  const { showSpinner, hideSpinner } = useSpinner()

  const toast = useMirohaToast()

  const { request: createEnvelope } = useCreateEnvelope({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      navigateToDocuSign()
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const handleClick = async () => {
    if (!docRevision.dsEnvelopeId) {
      await createEnvelope({
        type: 'CheckUnderstanding',
        explanationSessionUid: session.uid,
        explanationDocRevisionUid: docRevision.uid,
      })
      return
    }

    // envelope作成済みの場合はそのままDocuSignページに遷移する
    navigateToDocuSign()
  }

  if (docRevision.isCompleted) {
    return null
  }

  const partnerTrialHospitalUid =
    session.explanationRevision.partnerTrialHospital?.uid
  if (
    session.type === 'RemotePartner' &&
    partnerTrialHospitalUid !== selectedTrialHospitalUid
  ) {
    return null
  }

  return (
    <Button colorScheme="green" onClick={handleClick}>
      回答を開始
    </Button>
  )
}
