import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import {
  GqlError,
  SearchPatientsDocument,
  SearchPatientsInput,
  SearchPatientsQuery,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { PERMISSIONS } from 'src/lib/permission'
import useSWR, { SWRConfiguration } from 'swr'

import { PATIENTS_PER_PAGE } from '../../hooks/usePatientPagination'
import { usePatientQueryParams } from '../../hooks/usePatientsQueryParams'
import { patientCacheKey } from '../cacheKey'

export type SearchPatientsReturn = SearchPatientsQuery['searchPatients']

export const searchPatients = async (
  input: SearchPatientsInput,
): Promise<SearchPatientsReturn> => {
  try {
    const res = await graphqlRequest(SearchPatientsDocument, {
      input,
    })
    return res.searchPatients
  } catch (error) {
    throw error
  }
}

type UsePatientsParam = {
  page?: number
} & SWRConfiguration<SearchPatientsReturn, GqlError>

export const usePatients = (param?: UsePatientsParam) => {
  const { page, ...option } = param ?? {}

  const { queryParamObject } = usePatientQueryParams()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()
  const { hasPermission } = usePermission()

  return useSWR<SearchPatientsReturn, GqlError>(
    patientCacheKey.searchPatients(JSON.stringify(queryParamObject)),
    () =>
      searchPatients({
        page: page ?? queryParamObject.page,
        per: PATIENTS_PER_PAGE,
        sortKey: queryParamObject.sortKey,
        sortOrder: queryParamObject.sortOrder,
        statuses: queryParamObject.statuses,
        searchQuery: queryParamObject.searchQuery,
        trialHospitalUid: hasPermission(PERMISSIONS.Hospital_BelongAll)
          ? queryParamObject.trialHospitalUid
          : selectedTrialHospitalUid,
        relatedTrialHospitalUid:
          queryParamObject.relatedHospitalUid ?? undefined,
        relatedTrialHospitalType: queryParamObject.relatedHospitalType,
      }),
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...option,
    },
  )
}
