import { Trial } from 'src/features/trial/types'

import { ExplanationDocRevision, ExplanationRevision } from '../types'

export const explanationCacheKey = {
  getExplanations: (trialUid: Trial['uid'], searchParams: string) =>
    ['explanations', trialUid, searchParams].join(),
  getExplanation: (explanationUid: string, detailed: boolean) =>
    ['explanations', explanationUid, detailed ? 'detailed' : ''].join(),
  getExplanationForPatient: (explanationRevisionUid: string) =>
    ['explanations', explanationRevisionUid, 'forPatient'].join(),
  getExplanationTrialMembers: (role: 'Dr' | 'CRC') => [
    'explanation_trial_members',
    role,
  ],
  getExplanationTrialHospitals: () => ['explanation_trial_hospitals'],
  getExplanationHistories: (
    explanationRevisionUid: ExplanationRevision['uid'],
  ) => ['explanation_histories', explanationRevisionUid].join(),
  getExplanationSession: (explanationSessionUid: string) =>
    ['explanation_session', explanationSessionUid].join(),
  getExplanationDocRevision: (
    explanationDocRevisionUid: ExplanationDocRevision['uid'],
  ) => ['explanationDocRevision', explanationDocRevisionUid].join(),
  getExplanationDocRevisionFileUrl: (
    explanationDocRevisionUid: ExplanationDocRevision['uid'],
  ) => ['explanationDocRevisionFileUrl', explanationDocRevisionUid].join(),
  getExplanationRevisionAbout: (explanationRevisionUid: string) =>
    ['explanationRevisionAbout', explanationRevisionUid].join(),
  getExplanationWithdrawHistory: (
    explanationRevisionUid: ExplanationRevision['uid'],
  ) => ['explanationRevisionHistory', explanationRevisionUid].join(),
  getExplanationRevPinSettingAbout: (
    trialUid: string,
    explanationRevisionUid: string,
  ) => ['explanationRevisionAbout', trialUid, explanationRevisionUid].join(),
  challengeSignAuthV2: (explanationSignAuthenticationUid: string) =>
    ['challengeSignAuthV2', explanationSignAuthenticationUid].join(),
}
