import { Linkify } from '../../../../components/Linkify/Linkify'
import { FieldOf } from '../../utils'
import { FormWrapperWithoutChakra } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Label'>
}

export const LabelFieldForm: React.FC<Props> = ({ field }) => {
  return (
    <FormWrapperWithoutChakra field={field}>
      {field.typeDef.content && <Linkify>{field.typeDef.content}</Linkify>}
    </FormWrapperWithoutChakra>
  )
}
