import React, { useState, useEffect } from 'react'

import { HStack } from '@chakra-ui/react'
import ReactDOM from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { colors } from 'src/assets/colors'
import styled from 'styled-components'

import { Close } from './components/__legacy__icon/monochrome'
import { zIndex } from './modules/util/z-index'
import { sleep } from './utils/sleep'

const Background = styled.div<{ visible: boolean }>`
  position: fixed;
  background: ${colors.bgModal};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.15s;
  z-index: ${zIndex.modalBackground};
  opacity: ${props => (props.visible ? '1' : '0')};
`

const maxWidthBySize = (size: Size) => {
  switch (size) {
    case 'small':
      return '400px'
    case 'medium':
      return '600px'
    case 'large':
      return '800px'
  }
}

const Contents = styled.div<{ size: Size }>`
  background: ${colors.bgWhite};
  transform: translate3d(-50%, -50%, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-height: calc(100% - 100px);
  max-width: ${props => maxWidthBySize(props.size)};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(133, 141, 168, 0.06);
  overflow-y: scroll;
`

const ChildrenContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
`

type Size = 'large' | 'medium' | 'small'

type Props = {
  onClose: () => void
  closable?: boolean
  size?: Size
  children: React.ReactNode
}

const Modal: React.FC<Props> = props => {
  const size = !!props.size ? props.size : 'large'
  const closable = props.closable !== undefined ? props.closable : true
  const [el] = useState(document.createElement('div'))

  const handleClose = () => {
    const animate = async () => {
      setVisible(false)
      await sleep(100)
      props.onClose()
    }

    animate()
  }

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const animate = async () => {
      await sleep(100)
      setVisible(true)
    }

    animate()
  })

  useEffect(() => {
    const modalRoot = document.getElementById('modal')
    modalRoot && modalRoot.appendChild(el)

    return () => {
      modalRoot && modalRoot.removeChild(el)
    }
  }, [el])

  return ReactDOM.createPortal(
    <Background visible={visible} onClick={e => e.stopPropagation()}>
      <OutsideClickHandler onOutsideClick={handleClose} disabled={!closable}>
        <Contents size={size}>
          {closable && (
            <HStack justify="end">
              <Close cursor="pointer" onClick={handleClose} />
            </HStack>
          )}
          <ChildrenContainer>{props.children}</ChildrenContainer>
        </Contents>
      </OutsideClickHandler>
    </Background>,
    el,
  )
}

export default Modal
