import React, { useRef } from 'react'

import { Box, Button, ButtonProps, VisuallyHidden } from '@chakra-ui/react'

export type AcceptableFileType =
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.pdf'
  | 'application/json'
  | '.csv'

type CommonProps = {
  accept?: AcceptableFileType | AcceptableFileType[]
  capture?: 'user' | 'environment'
  text?: string
  buttonProps?: Omit<ButtonProps, 'onClick'>
}

type Props =
  | ({ allowMultiple: true; onChange: (files: File[]) => void } & CommonProps)
  | ({ allowMultiple?: false; onChange: (file: File) => void } & CommonProps)

export const FileSelectButton: React.FC<Props> = ({
  accept,
  capture,
  text,
  buttonProps,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onClick = () => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputRef.current) return
    const files = e.target.files
    if (!files) return

    if (rest.allowMultiple) {
      rest.onChange(Array.from(files))
    } else {
      const file = files[0]
      if (!file) return
      rest.onChange(file)
    }
    // ファイル選択 -> もとに戻す -> 同じファイルを選択 とした場合に期待通りに動くようにする
    inputRef.current.value = ''
  }

  return (
    <Box>
      <Button onClick={onClick} variant="outline" {...buttonProps}>
        {text ?? 'ファイルを選択'}
      </Button>
      <VisuallyHidden>
        <input
          ref={inputRef}
          type="file"
          aria-label="file-input"
          accept={Array.isArray(accept) ? accept.join(', ') : accept}
          onChange={onChange}
          multiple={rest.allowMultiple}
          capture={capture}
        />
      </VisuallyHidden>
    </Box>
  )
}
