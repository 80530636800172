// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgPreparation = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#preparation_svg__a)">
      <path
        stroke={props.color ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m3.6 4 .8.8L6 3.2M3.6 7.2l.8.8L6 6.4M3.6 10.4l.8.8L6 9.6"
      />
      <path
        fill={props.color ?? 'currentColor'}
        d="M14.512 12.4s-.008-.064-.008-.104l.584-.568a.363.363 0 0 0 .064-.44l-.8-1.384a.361.361 0 0 0-.408-.168l-.792.224-.168-.096-.2-.8a.36.36 0 0 0-.352-.272h-1.6a.36.36 0 0 0-.352.272l-.2.8-.168.096-.792-.224a.351.351 0 0 0-.408.168l-.8 1.384a.365.365 0 0 0 .064.44l.584.568s-.008.064-.008.104c0 .04.008.064.008.104l-.584.568a.363.363 0 0 0-.064.44l.8 1.384c.08.144.248.208.408.168l.792-.224.168.096.2.8a.36.36 0 0 0 .352.272h1.6a.36.36 0 0 0 .352-.272l.2-.8.168-.096.792.224a.351.351 0 0 0 .408-.168l.8-1.384a.365.365 0 0 0-.064-.44l-.584-.568s.008-.064.008-.104Zm-2.88 1.44c-.792 0-1.44-.648-1.44-1.44 0-.792.648-1.44 1.44-1.44.792 0 1.44.648 1.44 1.44 0 .792-.648 1.44-1.44 1.44Z"
      />
      <path
        stroke={props.color ?? 'currentColor'}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M10.8 3.6H8.4M10.8 7.2H8.4"
      />
      <path
        fill={props.color ?? 'currentColor'}
        d="M2 14V1.2h10.4v6h1.2V.8c0-.44-.36-.8-.8-.8H1.6C1.16 0 .8.36.8.8v13.6c0 .44.36.8.8.8h5.6V14H2Z"
      />
    </g>
    <defs>
      <clipPath id="preparation_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPreparation
