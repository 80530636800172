import React from 'react'

import { Badge, Text } from '@chakra-ui/react'
import { gray, green, white } from 'src/components/base/color/palette'
import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
} from 'src/components/base/table/table'
import { AnchorLink, ButtonLink } from 'src/components/base/text-link'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import {
  icfDocumentCheckRoles,
  IcfDocumentRevision,
  icfDocumentRoleText,
  icfDocumentTypeText,
  IcfDocumentDetail as IcfDocumentDetailType,
  IcfDocumentSetForIcfDocumentDetail,
  icfDocumentSignerRoles,
  icfDocumentTypes,
  IcfDocumentSignerRole,
  IcfDocumentCheckRole,
} from '../entity'

type Props = {
  document: IcfDocumentDetailType | undefined
  revision: IcfDocumentRevision | undefined
  filePreviewUrl: string
  onRedirectDocuSignPreview: ({
    dsTemplateId,
    role,
  }: {
    dsTemplateId: string
    role: IcfDocumentSignerRole | IcfDocumentCheckRole
  }) => void
}

type TableKey = keyof Pick<
  IcfDocumentSetForIcfDocumentDetail,
  'name' | 'enabled'
>

const tableCellWidth: Record<TableKey, string | undefined> = {
  name: undefined,
  enabled: '120px',
}

export const IcfDocumentDetail: React.FC<Props> = ({
  document,
  revision,
  filePreviewUrl,
  onRedirectDocuSignPreview,
}) => {
  if (!document || !revision) return null

  return (
    <Wrapper>
      <HStack>
        <Label>
          <Text fontWeight="bold">文書ID</Text>
        </Label>
        <Value>
          <Text>{document.numberingId}</Text>
        </Value>
      </HStack>

      <Spacer size={32} />

      <HStack>
        <Label>
          <Text fontWeight="bold">文書名</Text>
        </Label>
        <Value>
          <Text>{revision.name}</Text>
        </Value>
      </HStack>

      <Spacer size={32} />

      <HStack>
        <Label>
          <Text fontWeight="bold">文書の種類</Text>
        </Label>
        <Value>
          <Text>{icfDocumentTypeText[revision.type]}</Text>
        </Value>
      </HStack>

      <Spacer size={32} />

      <HStack>
        <Label>
          <Text fontWeight="bold">患者の同意</Text>
        </Label>
        {/* TODO: isRequiredPatientSignがfalseの場合の表示を確認 */}
        <Value>
          <Text>
            {revision.isRequiredPatientSign ? '必須' : '必須ではない'}
          </Text>
        </Value>
      </HStack>

      <Spacer size={32} />

      <HStack>
        <Label>
          <Text fontWeight="bold">ファイル名</Text>
        </Label>
        <Value>
          <Text>
            <AnchorLink
              href={filePreviewUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {revision.fileName}
            </AnchorLink>
          </Text>
        </Value>
      </HStack>

      <Spacer size={32} />

      {revision.type === icfDocumentTypes.AgreementForm && (
        <>
          <HStack>
            <Label>
              <Text fontWeight="bold">署名ロール</Text>
            </Label>
            <Value>
              <HStack>
                {Object.values(icfDocumentSignerRoles).map(role => (
                  <RoleTextContainer key={role}>
                    <ButtonLink
                      disabled={!revision.signerRoles.includes(role)}
                      onClick={() =>
                        onRedirectDocuSignPreview({
                          dsTemplateId: revision.dsTemplateId,
                          role,
                        })
                      }
                    >
                      {icfDocumentRoleText[role]}
                    </ButtonLink>
                    <Spacer horizontal size={4} />
                  </RoleTextContainer>
                ))}
              </HStack>
            </Value>
          </HStack>

          <Spacer size={32} />
        </>
      )}

      {revision.type === icfDocumentTypes.CheckUnderstanding && (
        <>
          <HStack>
            <Label>
              <Text fontWeight="bold">確認項目</Text>
            </Label>
            <Value>
              <HStack>
                {Object.values(icfDocumentCheckRoles).map(role => (
                  <RoleTextContainer key={role}>
                    <ButtonLink
                      disabled={!revision.checkRoles.includes(role)}
                      onClick={() =>
                        onRedirectDocuSignPreview({
                          dsTemplateId: revision.dsTemplateId,
                          role,
                        })
                      }
                    >
                      {icfDocumentRoleText[role]}
                    </ButtonLink>
                    <Spacer horizontal size={4} />
                  </RoleTextContainer>
                ))}
              </HStack>
            </Value>
          </HStack>

          <Spacer size={32} />
        </>
      )}

      <Label>
        <Text fontWeight="bold">文書セット</Text>
      </Label>

      <Spacer size={16} />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell width={tableCellWidth['enabled']}>
                ステータス
              </TableHeadCell>
              <TableHeadCell width={tableCellWidth['name']}>
                文書セット
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {document.sets.map(set => (
              <TableRow key={set.uid} borderBottom disabled={!set.enabled}>
                <TableBodyCell width={tableCellWidth['enabled']}>
                  <Badge
                    sx={{
                      width: '80px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      color: set.enabled ? green[40] : gray[55],
                      backgroundColor: set.enabled ? green[10] : gray[5],
                      fontWeight: 'normal',
                    }}
                  >
                    {set.enabled ? '有効' : '無効'}
                  </Badge>
                </TableBodyCell>
                <TableBodyCell width={tableCellWidth['name']}>
                  {set.numberingId}. {set.name}
                </TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const Label = styled.div`
  width: 100px;
`

const Value = styled.div`
  flex: 1;
`

const RoleTextContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TableContainer = styled.div`
  height: 300px;
  max-width: 800px;
  background-color: ${white};
`
