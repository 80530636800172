// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgHint = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <circle
      cx={8}
      cy={8}
      r={7}
      fill="#fff"
      stroke={props.color ?? 'currentColor'}
      strokeWidth={2}
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M4 6.5a1 1 0 0 0 2 0H4ZM8 9l-.316-.949A1 1 0 0 0 7 9h1Zm-1 1.5a1 1 0 1 0 2 0H7Zm-1-4c0-.386.185-.738.551-1.023A2.411 2.411 0 0 1 8 5V3a4.41 4.41 0 0 0-2.676.898C4.565 4.488 4 5.386 4 6.5h2ZM8 5c.54 0 1.071.183 1.449.477.366.285.551.637.551 1.023h2c0-1.114-.565-2.012-1.324-2.602A4.41 4.41 0 0 0 8 3v2Zm2 1.5c0 .376-.07.57-.128.67a.703.703 0 0 1-.295.268c-.177.099-.413.183-.753.281-.304.088-.742.2-1.14.332l.632 1.898c.352-.117.664-.193 1.063-.309.363-.105.783-.239 1.17-.453.402-.224.788-.546 1.063-1.03.27-.477.388-1.033.388-1.657h-2ZM7 9v1.5h2V9H7Z"
    />
    <circle cx={8} cy={12.75} r={1} fill={props.color ?? 'currentColor'} />
  </svg>
)
export default SvgHint
