import { ComponentStyleConfig } from '@chakra-ui/react'

export const Radio: ComponentStyleConfig = {
  baseStyle: ({ colorScheme: c }) => ({
    container: {
      alignItems: 'start',
      whiteSpace: 'pre-line',
    },
    control: {
      bg: 'white',
      pos: 'relative',
      borderColor: 'gray.300',

      _hover: {
        borderColor: `gray.400`,
        _invalid: {
          borderColor: 'red.600',
        },
      },

      _disabled: {
        borderColor: 'gray.300',
        bg: 'gray.100',
        opacity: 0.5,

        _hover: {
          borderColor: `gray.300`,
        },
      },

      _checked: {
        bg: `${c}.500`,
        border: 'none',
        _before: {
          h: '5',
          w: '5',
          pos: 'absolute',
          top: 0,
          left: 0,
          // encoded "<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 7.4L6.80805 11L13 5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>" via https://yoksel.github.io/url-encoder/
          bg: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 7.4L6.80805 11L13 5' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`,
          bgPos: 'center',
          bgRepeat: 'no-repeat',
          bgSize: '75%',
          bgColor: `${c}.500`,
        },

        _disabled: {
          opacity: 0.3,
        },
      },
    },

    label: {
      ml: 1.5,

      _invalid: {
        color: 'red.500',
      },
    },
  }),
  sizes: {
    md: {
      control: {
        h: '5',
        w: '5',
      },
      label: {
        fontSize: 'sm',
        lineHeight: '5',
      },
    },
  },
  defaultProps: {
    colorScheme: 'blue',
  },
}
