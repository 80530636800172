import { TrialHospital } from './entity'

export type TrialHospitalsByUid = {
  [uid: string]: TrialHospital
}

type Option = {
  maxDisplay: number
}

export const hospitalNames = (
  trialHospitalsByUid: TrialHospitalsByUid,
  hospitalUids: string[],
  option?: Option,
) => {
  if (hospitalUids.length === 0) {
    return '全ての医療機関'
  }

  if (!option) {
    return hospitalUids.map(uid => trialHospitalsByUid[uid]?.name).join(', ')
  }

  return hospitalUids
    .slice(0, option.maxDisplay)
    .map(uid => trialHospitalsByUid[uid]?.name)
    .join(', ')
}
