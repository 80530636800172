import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const getIcfDocumentSetHandler = restHandlerFactory(
  '/trials/{trial_uid}/trial_hospitals/{trial_hospital_uid}/icf_document_sets/{icf_document_set_uid}',
  'get',
  () => {
    return HttpResponse.json(
      {
        enabled: true,
        name: `ICF Document Set ${Math.floor(Math.random() * 10)}`,
        numberingId: Math.floor(Math.random() * 10),
        uid: generateUUID(),
        icfDocuments: [
          {
            index: 1,
            latestApprovedAt: '',
            latestApprovedName: '説明文書',
            latestApprovedRevisionUid: generateUUID(),
            latestApprovedVersion: '1.0',
            latestIsRequiredPatientSign: false,
            numberingId: 1,
            status: 0,
            type: 1,
            uid: generateUUID(),
          },
          {
            index: 2,
            latestApprovedAt: '',
            latestApprovedName: '同意書1',
            latestApprovedRevisionUid: generateUUID(),
            latestApprovedVersion: '1.0',
            latestIsRequiredPatientSign: false,
            numberingId: 1,
            status: 0,
            type: 1,
            uid: generateUUID(),
          },
        ],
      },
      { status: 200 },
    )
  },
)
