import React, { useState, useEffect } from 'react'

import dayjs from 'dayjs'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spinner } from 'src/components/spinner/spinner'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { CallHistory } from './entity'
import { fetch } from './request'
import { worksheetIdParamName } from '../../worksheet-detail'

type Props = {
  onClose: () => void
}

export const VideoCallRoomHistoryModalContainer: React.FC<Props> = props => {
  const { worksheetUid = '' } = useParams<{ [worksheetIdParamName]: string }>()

  const { requesting, errorMessage, histories } = useFetch({ worksheetUid })

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title="ビデオ通話履歴" />

      <ModalContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell width="30%">通話開始時間</TableHeadCell>
                <TableHeadCell width="30%">通話終了時間</TableHeadCell>
                <TableHeadCell width="40%">アカウント</TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {requesting ? (
                <Spinner />
              ) : (
                histories.map(history => (
                  <TableRow key={history.uid} borderBottom>
                    <TableBodyCell width="30%">
                      {dayjs(history.createdAt).format('YYYY/MM/DD HH:mm')}
                    </TableBodyCell>
                    <TableBodyCell width="30%">
                      {history.leaveAt
                        ? dayjs(history.leaveAt).format('YYYY/MM/DD HH:mm')
                        : '-'}
                    </TableBodyCell>
                    <TableBodyCell width="40%">
                      {history.participantName}
                    </TableBodyCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="閉じる"
          onClick={props.onClose}
          buttonType="normal"
        />
      </ModalActions>
      {errorMessage && <Message type="error" message={errorMessage} centered />}
    </Modal>
  )
}

const useFetch = ({ worksheetUid }: { worksheetUid: string }) => {
  const {
    requesting,
    errorMessage,
    requestStarted,
    requestDone,
    requestFailed,
  } = useRequestState()

  const [histories, setHistories] = useState<CallHistory[]>([])

  useEffect(() => {
    const request = async () => {
      try {
        requestStarted()
        const res = await fetch({ worksheetUid })
        setHistories(res)
        requestDone()
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    }

    request()
  }, [requestDone, requestFailed, requestStarted, worksheetUid])

  return {
    requesting,
    errorMessage,
    histories,
  }
}

const TableContainer = styled.div`
  width: 100%;
  height: 300px;
`
