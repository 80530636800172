import { useCallback } from 'react'

import { useModal } from 'src/hooks/use-modal'

export const useStickyEditingAlert = () => {
  const { showModal } = useModal()

  const showStickyEditingAlert = useCallback(
    (param: { onConfirm: () => void; onCancel?: () => void }) => {
      showModal({
        title: '編集中のメッセージがあります',
        submitText: '破棄する',
        submitButtonColor: 'red',
        content: '内容が破棄されますが操作を実行しますか？',
        onCancel: param.onCancel,
        onSubmit: param.onConfirm,
      })
    },
    [showModal],
  )

  return { showStickyEditingAlert }
}
