import { ComponentStyleConfig } from '@chakra-ui/react'

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    color: 'gray.600',
    _disabled: {
      opacity: 1,
    },
  },
}
