import { useMemo, useCallback, useEffect, useRef } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'src/hooks/use-local-storage'
import { ExplanationOrderKey } from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'

import { ExplanationRevisionStatus } from '../types'
import {
  stringToExplanationOrderKey,
  stringToPage,
  stringToReAgreementArray,
  stringToExplanationStatusesArray,
} from '../utils/queryStringToProperty'

type PaginationInput = { type: 'pagination'; value: number }
type SortOrderKeyInput = { type: 'sortOrderKey'; value: ExplanationOrderKey }
type StatusesInput = { type: 'statuses'; value: ExplanationRevisionStatus[] }
type SearchInput = { type: 'search'; value: string }
type DrInput = { type: 'dr'; value: string | undefined }
type CrcInput = { type: 'crc'; value: string | undefined }
type TrialHospitalInput = { type: 'trialHospital'; value: string | undefined }
type ReAgreementInput = {
  type: 'reAgreement'
  value: boolean[]
}

type GetQueryParamInput =
  | PaginationInput
  | SortOrderKeyInput
  | SearchInput
  | StatusesInput
  | DrInput
  | CrcInput
  | TrialHospitalInput
  | ReAgreementInput

const queryParamKeys = {
  searchQuery: 'q',
  statuses: 'statuses',
  dr: 'dr',
  crc: 'crc',
  page: 'page',
  sortOrderKey: 'sortOrderKey',
  trialHospitalUid: 'th',
  reAgreement: 'ra',
} as const

type QueryParamValue = Partial<Record<keyof typeof queryParamKeys, string>>

type QueryParamObject = {
  page: number
  sortOrderKey: ExplanationOrderKey
  searchQuery: string
  statuses: ExplanationRevisionStatus[]
  dr: string | undefined
  crc: string | undefined
  trialHospitalUid: string | undefined
  reAgreement: boolean[]
}

export const EXPLANATION_QUERY_STORAGE_KEY = 'explanationQuery'

// 説明同意の検索・ページング・フィルタリング・ソートロジックに関わるクエリパラメータの処理を扱うhooks
export const useExplanationsQueryParams = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const query = useMemo(() => new URLSearchParams(location.search), [location])

  const { data: storedQueryParamValue, setData: setStoredQueryParamValue } =
    useLocalStorage<QueryParamValue>(EXPLANATION_QUERY_STORAGE_KEY)

  // 実際に表示中のクエリから取得できる値
  const queryParamValue: QueryParamValue = useMemo(
    () => ({
      page: query.get(queryParamKeys.page) ?? '1',
      sortOrderKey: query.get(queryParamKeys.sortOrderKey) ?? 'CreatedAt',
      searchQuery: query.get(queryParamKeys.searchQuery) ?? undefined,
      statuses: query.get(queryParamKeys.statuses) ?? undefined,
      dr: query.get(queryParamKeys.dr) ?? undefined,
      crc: query.get(queryParamKeys.crc) ?? undefined,
      trialHospitalUid: query.get(queryParamKeys.trialHospitalUid) ?? undefined,
      reAgreement: query.get(queryParamKeys.reAgreement) ?? undefined,
    }),
    [query],
  )

  const addSearchParams = useCallback(
    (value: QueryParamValue) => {
      const search = generateSearchParams(value)
      navigate({ search })
    },
    [navigate],
  )

  const queryInputRef = useRef<HTMLInputElement>(null)

  const updateQuery = useCallback(
    (value: QueryParamValue) => {
      if (!storedQueryParamValue) return
      const newValue = { ...queryParamValue, ...value }
      setStoredQueryParamValue(newValue)
      addSearchParams(newValue)
    },
    [
      storedQueryParamValue,
      setStoredQueryParamValue,
      queryParamValue,
      addSearchParams,
    ],
  )

  const resetQuery = useCallback(() => {
    setStoredQueryParamValue({})
    if (!!queryInputRef.current) {
      queryInputRef.current.value = ''
    }
    navigate({ search: undefined })
  }, [navigate, setStoredQueryParamValue])

  const setupQuery = useCallback(() => {
    if (!!location.search) return

    if (!!storedQueryParamValue) {
      addSearchParams(storedQueryParamValue)
    }
  }, [location.search, storedQueryParamValue, addSearchParams])

  useEffect(() => {
    setupQuery()
  }, [setupQuery])

  useEffect(() => {
    if (!storedQueryParamValue) {
      setStoredQueryParamValue(queryParamValue)
    }
  }, [storedQueryParamValue, setStoredQueryParamValue, queryParamValue])

  const hasQuery = useMemo(() => !!location.search, [location.search])

  const setQuery = useCallback(
    (input: GetQueryParamInput) => {
      //pagination以外ではページを指定しない(2ページ目以降で行った場合は1ページ目に戻る)。
      //ページ以外についてはqueryパラメータに含まれるものを引き継ぐ
      if (input.type === 'pagination') {
        updateQuery({ page: input.value.toString() })
        return
      }

      if (input.type === 'sortOrderKey') {
        updateQuery({ sortOrderKey: input.value })
        return
      }

      if (input.type === 'search') {
        updateQuery({ searchQuery: input.value ?? '', page: undefined })
        return
      }

      if (input.type === 'statuses') {
        updateQuery({ statuses: input.value.join(','), page: undefined })
        return
      }

      if (input.type === 'dr') {
        updateQuery({ dr: input.value, page: undefined })
        return
      }

      if (input.type === 'crc') {
        updateQuery({ crc: input.value, page: undefined })
        return
      }

      if (input.type === 'trialHospital') {
        updateQuery({ trialHospitalUid: input.value, page: undefined })
        return
      }

      if (input.type === 'reAgreement') {
        updateQuery({
          reAgreement: input.value.join(','),
          page: undefined,
        })
        return
      }

      return assertNever(input)
    },
    [updateQuery],
  )

  return {
    queryParamObject: parseQueryParamsObject(queryParamValue),
    queryInputRef,
    hasQuery,
    setQuery,
    resetQuery,
  }
}

const generateSearchParams = (value: QueryParamValue) => {
  const searchParam = new URLSearchParams()
  Object.entries(value).forEach(([key, value]) => {
    if (value === undefined) return
    searchParam.set(queryParamKeys[key as keyof typeof queryParamKeys], value)
  })
  return searchParam.toString()
}

const parseQueryParamsObject = (value: QueryParamValue): QueryParamObject => {
  return {
    page: stringToPage(value.page) ?? 1,
    sortOrderKey: stringToExplanationOrderKey(value.sortOrderKey),
    searchQuery: value.searchQuery ?? '',
    statuses: stringToExplanationStatusesArray(value.statuses),
    dr: value.dr ?? undefined,
    crc: value.crc ?? undefined,
    trialHospitalUid: value.trialHospitalUid ?? undefined,
    reAgreement: stringToReAgreementArray(value.reAgreement),
  }
}
