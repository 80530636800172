import React, { useMemo } from 'react'

import { Text } from '@chakra-ui/react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Input } from 'src/components/form-redesigned/input'
import { SingleCheckboxWithValue } from 'src/components/form-redesigned/single-checkbox'
import { Spacer } from 'src/components/spacer/spacer'
import { useReactHookFormDevTools } from 'src/hooks/use-react-hook-form-dev-tools'
import { toValueString } from 'src/modules/entities/small-item-answer/util'
import styled from 'styled-components'

import { SaveSectionItem } from './save-section-modal-container'

type Props = {
  selectedSmallItemAnswerUids: string[]
  saveSectionItems: SaveSectionItem[]
  requesting: boolean
  onCheck: (smallItemAnswerUid: string) => void
  onSubmit: (data: UpdateSmallItemAnswersParam) => void
  onClose: () => void
}

type FormValues = Record<string, string>

export type UpdateSmallItemAnswersParam = {
  smallItemAnswerUid: string
  reason: string
}[]

export const SaveSectionModal: React.FC<Props> = props => {
  const { saveSectionItems, selectedSmallItemAnswerUids } = props
  const methods = useForm<FormValues>({ mode: 'onChange' })
  const { handleSubmit, control, watch } = methods

  const canSubmit = useMemo(() => {
    if (props.requesting) {
      return false
    }
    if (selectedSmallItemAnswerUids.length === 0) {
      return false
    }

    return selectedSmallItemAnswerUids.every(uid => {
      const smallItemAnswer = saveSectionItems.find(
        item => item.smallItemAnswer.uid === uid,
      )
      if (!smallItemAnswer || !smallItemAnswer.needReason) {
        return true
      }

      return !!watch(uid)
    })
  }, [saveSectionItems, selectedSmallItemAnswerUids, watch, props.requesting])

  const onSubmit = (formValues: FormValues) => {
    if (!canSubmit) {
      return
    }

    const updateSmallItemAnswerParam = selectedSmallItemAnswerUids.map(
      smallItemAnswerUid => {
        return {
          smallItemAnswerUid: smallItemAnswerUid,
          reason: formValues[smallItemAnswerUid],
        }
      },
    )

    props.onSubmit(updateSmallItemAnswerParam)
  }

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title="セクション内容の変更" />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <Text textAlign="center">
              選択したフィールドの入力内容を保存します。
            </Text>
            <Spacer size={10} />
            <Text textAlign="center">
              変更を保存する場合は変更理由を入力してください。
            </Text>
            <Spacer size={20} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell width={32} />
                    <TableHeadCell>フィールド</TableHeadCell>
                    <TableHeadCell>変更後</TableHeadCell>
                    <TableHeadCell width={250}>変更理由</TableHeadCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {saveSectionItems
                    .filter(item => item.showable)
                    .map(item => (
                      <TableRow key={item.smallItemAnswer.uid} borderBottom>
                        <TableBodyCell width={32} align="center">
                          {item.updatable ? (
                            <div>
                              <SingleCheckboxWithValue
                                value={item.smallItemAnswer.uid}
                                checked={selectedSmallItemAnswerUids.includes(
                                  item.smallItemAnswer.uid,
                                )}
                                onChange={props.onCheck}
                              />
                            </div>
                          ) : (
                            <NotCheck>-</NotCheck>
                          )}
                        </TableBodyCell>
                        <TableBodyCell>
                          {displayDataItemTitle(item)}
                        </TableBodyCell>
                        <TableBodyCell>
                          {toValueString(item.smallItemAnswer)}
                        </TableBodyCell>
                        <TableBodyCell width={250}>
                          <Reason
                            item={item}
                            selectedSmallItemAnswerUids={
                              selectedSmallItemAnswerUids
                            }
                          />
                        </TableBodyCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ModalContent>

          <ModalActions>
            <Button
              size="S"
              text="キャンセル"
              onClick={props.onClose}
              buttonType="cancel"
            ></Button>
            <Spacer size={40} horizontal />
            <Button
              size="S"
              text="一時保存"
              onClick={() => handleSubmit(onSubmit)}
              buttonType="important"
              disabled={!canSubmit}
            ></Button>
          </ModalActions>
        </form>

        {useReactHookFormDevTools(control)}
      </FormProvider>
    </Modal>
  )
}

const Reason: React.FC<{
  item: SaveSectionItem
  selectedSmallItemAnswerUids: string[]
}> = props => {
  const { item } = props
  const { register, watch } = useFormContext()

  const checked = useMemo(
    () => props.selectedSmallItemAnswerUids.includes(item.smallItemAnswer.uid),
    [item.smallItemAnswer.uid, props.selectedSmallItemAnswerUids],
  )
  const warning = checked && !watch(item.smallItemAnswer.uid)

  if (!item.updatable) {
    return null
  }

  return item.needReason ? (
    <>
      <Input
        placeholder={'変更理由を入力してください'}
        disabled={!checked}
        warning={warning}
        {...register(item.smallItemAnswer.uid)}
      />
    </>
  ) : (
    <>
      <Text>新規登録</Text>
    </>
  )
}

const displayDataItemTitle = (item: SaveSectionItem) => {
  if (item.indentLevel <= 1) {
    return item.smallItemAnswer.title
  }

  return ['>'.repeat(item.indentLevel - 1), item.smallItemAnswer.title].join(
    ' ',
  )
}

const TableContainer = styled.div`
  height: 300px;
`

const NotCheck = styled.div`
  padding-left: 6px;
`
