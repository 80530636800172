import React from 'react'

import { HStack, ListItem, Stack, UnorderedList, Text } from '@chakra-ui/react'
import { Warning } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { yellow } from 'src/components/base/color/palette'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { ApiResponse } from 'src/lib/api-client'

type Props = {
  docs: ApiResponse<'ListICFDocumentsItem'>[]
  onClose: () => void
  onSubmit: () => void
}

export const ReAgreementConfirmModal: React.FC<Props> = ({
  docs,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal onClose={onClose} closable={false}>
      <ModalTitle title="再同意確認" />
      <ModalContent>
        <Stack spacing="32px" align="center">
          <HStack align="top">
            <Warning size="M" color={yellow[70]} />
            {docs.length >= 1 ? (
              <Text color={yellow[70]}>
                以下の文書を含む説明同意に再同意が必要になります。
                <br />
                説明同意詳細画面から再同意の実施をお願いします。
              </Text>
            ) : (
              <Text color={yellow[70]}>
                改版による文書の再同意は行いません。よろしいですか？
              </Text>
            )}
          </HStack>

          {docs.length >= 1 && (
            <UnorderedList>
              {docs.map(doc => (
                <ListItem key={doc.uid}>
                  <Text fontWeight="bold">{doc.name}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </Stack>
      </ModalContent>
      <ModalActions>
        <HStack spacing="32px">
          <Button
            size="S"
            text="キャンセル"
            buttonType="cancel"
            onClick={onClose}
          />
          <Button
            size="S"
            text="確定する"
            buttonType="important"
            onClick={onSubmit}
          />
        </HStack>
      </ModalActions>
    </Modal>
  )
}
