import { Center } from '@chakra-ui/react'

import { Video } from './video'
import { useQuery } from '../router'

// queryパラメータにビデオのURLを指定する
export const VideoWithQueryContainer = () => {
  const query = useQuery()
  const videoStreamUrl = query.get('video')
  const isConverting = !!query.get('converting')

  if (isConverting) {
    return (
      <ErrorMessage message="動画を準備中のためしばらくお待ち下さい。時間が経っても解決しない場合は運用にお問い合わせください" />
    )
  }
  if (!videoStreamUrl) {
    return <ErrorMessage message="ビデオが指定されていません" />
  }

  return <Video videoStreamUrl={videoStreamUrl} />
}

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return <Center sx={{ height: '100%', width: '100%' }}>{message}</Center>
}
