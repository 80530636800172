import { actions as patientEntitiesActions } from 'src/modules/entities/patient/redux'
import { actions as flashActions } from 'src/modules/flash/redux'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { RequestValues } from './components/form'
import { editPatient } from './request'

const create = actionCreatorFactory('patient/editPatient')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  submit: createAsync<
    {
      trialUid: string
      patientUid: string
      patient: RequestValues
    },
    {},
    { message: string }
  >('SUBMIT', async (params, dispatch, getState) => {
    await editPatient(params)
    await dispatch(
      patientEntitiesActions.fetch({
        trialUid: params.trialUid,
        patientUid: params.patientUid,
      }),
    )
    dispatch(flashActions.showSuccess({ message: '患者情報を変更しました。' }))
  }),
  showModal: create<{}>('SHOW_MODAL'),
}

export type State = {
  requesting: boolean
  errorMessage: string | null
}

const initialState: State = {
  requesting: false,
  errorMessage: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.submit.async.started, (state, payload) => ({
    ...state,
    requesting: true,
    errorMessage: null,
  }))
  .case(actions.submit.async.done, (state, payload) => ({
    ...state,
    requesting: false,
    modalOpen: false,
  }))
  .case(actions.submit.async.failed, (state, payload) => {
    return {
      ...state,
      requesting: false,
      errorMessage: payload.error.message,
    }
  })
  .case(actions.showModal, (state, payload) => {
    return {
      ...state,
      errorMessage: null,
    }
  })
  .build()
