import React from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { white } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { getDetail } from 'src/modules/entities/questionnaire/selector'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { EditableGeneral } from './components/editable/general'
import { EditableSectionGroup } from './components/editable/section-group'
import { FixedGeneral } from './components/fixed/general'
import { FixedSectionGroup } from './components/fixed/section-group'
import { Head } from './components/head'
import { useCreateEditableState } from './use-create-editable-state'
import { useFetch } from './use-fetch'
import { useQuestionnaireDetailPermission } from './use-permission'
import { questionnaireIdParamName } from '../router'

export const MAX_INPUT_LENGTH = 255
export const MAX_UNIT_LENGTH = 100

export const QuestionnaireDetail: React.FC = () => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { questionnaireUid = '' } = useParams<{
    [questionnaireIdParamName]: string
  }>()
  const { fetched } = useFetch({
    trialUid: selectedTrial.uid,
    questionnaireUid: questionnaireUid,
  })

  const questionnaire = useSelector((state: RootState) =>
    getDetail(state, questionnaireUid),
  )

  const { completed } = useCreateEditableState({
    questionnaire,
  })

  const { canEdit } = useQuestionnaireDetailPermission()
  const General = canEdit ? EditableGeneral : FixedGeneral
  const SectionGroup = canEdit ? EditableSectionGroup : FixedSectionGroup

  if (!fetched || !completed) {
    return null
  }
  return (
    <Container>
      <Head />
      <Spacer size={20} />
      <General />
      <Spacer size={20} />
      <SectionGroup />
      <Spacer size={20} />
    </Container>
  )
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  background-color: ${white};
`
