import { useSelectedTrial } from 'src/features/auth/context'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const rejectAgreement = async ({
  trialUid,
  explanationDocRevisionUid,
  explanationSessionUid,
  reason,
}: {
  trialUid: string
  explanationDocRevisionUid: string
  /** 説明ルームから拒否する場合は指定 */
  explanationSessionUid?: string
  reason: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_document_revisions/{explanation_document_revision_uid}/reject',
    httpMethod: 'put',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_document_revision_uid: explanationDocRevisionUid,
      },
      body: {
        sessionUid: explanationSessionUid ?? '',
        signerRole: 3, // NOTE: 患者固定
        rejectedReason: reason,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useRejectAgreement = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()
  return useMutation(
    ({
      explanationDocRevisionUid,
      explanationSessionUid,
      reason,
    }: {
      explanationDocRevisionUid: string
      /** 説明ルームから拒否する場合は指定 */
      explanationSessionUid?: string
      reason: string
    }) =>
      rejectAgreement({
        trialUid: selectedTrial.uid,
        explanationDocRevisionUid,
        explanationSessionUid,
        reason,
      }),
    option,
  )
}
