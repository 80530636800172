import {
  DocRevisionInSignFlowFragment,
  ExpDocRevAgreementFormForSessionInSignFlowFragment,
} from 'src/lib/gql-client'

type DocRevisionInSignFlow_ExplanationDocRevisionAgreementForm_Fragment =
  DocRevisionInSignFlowFragment & {
    __typename: 'ExplanationDocRevisionAgreementForm'
  }

export const isDocRevCompleted = (
  docRev:
    | DocRevisionInSignFlowFragment
    | ExpDocRevAgreementFormForSessionInSignFlowFragment,
) => {
  if (docRev.__typename === 'ExplanationDocRevisionAgreementForm') {
    const isRejected = docRev.signHistories.some(h => h.isRejected)
    // 署名するメンバー+患者が署名ずみか判定
    const allSigned =
      docRev.signHistories.length === docRev.signerMembers.length + 1
    return isRejected || allSigned
  }

  return !!docRev.latestPatientSideBrowsingHistory
}

export const hasSigningNotRequiredAgreements: (
  agreementForms:
    | DocRevisionInSignFlow_ExplanationDocRevisionAgreementForm_Fragment[]
    | ExpDocRevAgreementFormForSessionInSignFlowFragment[],
) => boolean = agreementForms => {
  return (
    agreementForms.filter(
      af =>
        !af.isPatientConsentRequired &&
        !!af.docusignEnvelopeId &&
        !isDocRevCompleted(af),
    ).length > 0
  )
}
