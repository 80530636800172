import { GqlError, GetTrialQuery, GetTrialDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { trialCacheKey } from '../../../trial/api/cacheKey'

const getTrial = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<GetTrialQuery['trial']> => {
  try {
    const res = await graphqlRequest(GetTrialDocument, { trialUid })
    return res.trial
  } catch (e) {
    throw e
  }
}

export const useTrial = ({
  trialUid,
  shouldCancel = false,
  ...options
}: {
  trialUid: string
  shouldCancel?: boolean
} & SWRConfiguration<GetTrialQuery['trial'], GqlError>) => {
  return useSWR<GetTrialQuery['trial'], GqlError>(
    shouldCancel ? null : trialCacheKey.getTrial(trialUid),
    () => getTrial({ trialUid }),
    options,
  )
}
