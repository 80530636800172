import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions as templateActions } from 'src/modules/entities/template/redux'
import { actions as worksheetActions } from 'src/modules/entities/worksheet/redux'
import { routes } from 'src/modules/routes'

export const useFetch = ({
  trialUid,
  patientUid,
  worksheetUid,
  templateUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  templateUid: string | undefined
}) => {
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      if (!templateUid) return

      try {
        await Promise.all([
          dispatch(
            worksheetActions.fetchSingle({
              trialUid,
              patientUid,
              worksheetUid,
            }),
          ),
          dispatch(
            templateActions.fetchSingle({
              trialUid,
              templateUid,
            }),
          ),
        ])
        setFetched(true)
      } catch (error) {
        // TOOD: login key?
        console.error('worksheetActions.fetchSingle Error: ', error)
        error.original.response.status === 403
          ? navigate(routes.httpError403)
          : navigate(`${routes.requiredLogin}`)
      }
    }

    fetch()
  }, [
    dispatch,
    patientUid,
    setFetched,
    trialUid,
    worksheetUid,
    templateUid,
    navigate,
  ])

  return {
    fetched,
  }
}
