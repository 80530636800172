import { Authenticator } from 'src/features/auth/components'

import { TermsOfServiceScreen } from '../TermsOfServiceScreen/TermsOfServiceScreen'

export const AuthenticatedTermsScreen: React.FC = () => {
  return (
    <Authenticator shouldSelectTrial={false}>
      <TermsOfServiceScreen />
    </Authenticator>
  )
}
