import React, { memo, useContext } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  RadioGroup,
  Radio,
  Text,
  Wrap,
  WrapItem,
  Stack,
  Checkbox,
} from '@chakra-ui/react'
import { Label } from 'src/components/form-redesigned/label'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { Add, Close } from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import {
  DataType,
  ItemType,
  SmallItem,
} from 'src/modules/entities/small-item/entity'
import {
  itemTypeToDateFormat,
  itemTypeToTextLabel,
} from 'src/modules/entities/small-item/util'
import { itemTypeToText, rolesToText } from 'src/modules/text'
import { isEmptyLetter } from 'src/modules/util/is-empty-letter'
import styled from 'styled-components'
import { space, SpaceProps, width, WidthProps } from 'styled-system'

import { ChoiceComponent } from './choice'
import {
  DateTypeSelect,
  ItemTypeSelect,
  TextTypeSelect,
} from './item-type-select'
import { RoleCheckbox } from './role-checkbox'
import { Value } from './value'
import { toggleRoles } from '../../../../../../../../entities/member/entity'
import {
  getGeneralItemType,
  hasChoice,
  isDateAndTimeType,
  isTextType,
} from '../../../../../../../../util/item-type-classification'
import { CardContext } from '../context'
import { dataTypeRadioItems } from '../options'

type Props = {
  smallItem: SmallItem
  v2: boolean
}

const ItemContainer = styled.div`
  width: 100%;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Item = styled.div<SpaceProps & WidthProps>`
  ${width};
  ${space};
`

const shouldShowError = (value: any) => {
  if (typeof value === 'number') {
    return value < 0
  }
  return !value
}

export const NestedSmallItem: React.FC<Props> = props => {
  const { smallItem, v2 } = props

  const { onRemoveSmallItem, onChangeSmallItem, releaseTried } =
    useContext(CardContext)

  return (
    <Box
      id={smallItem.uid}
      flex="1"
      border="1px"
      borderColor="gray.100"
      borderRadius="base"
      p="5"
      pos="relative"
    >
      <Box>
        <WithoutChoiceComponent
          smallItem={smallItem}
          releaseTried={releaseTried}
          onChangeSmallItem={onChangeSmallItem}
          v2={v2}
        />

        {hasChoice(smallItem) && <OptionsItem smallItem={smallItem} v2={v2} />}
      </Box>
      {!smallItem.presetUsed && (
        <IconButton
          pos="absolute"
          right="3"
          top="3"
          variant="customIconButtonGhost"
          icon={<Close />}
          aria-label="フィールドを削除"
          onClick={() => onRemoveSmallItem({ smallItemUid: smallItem.uid })}
        />
      )}
    </Box>
  )
}

type WithoutChoiceProps = {
  smallItem: SmallItem
  releaseTried: boolean
  v2: boolean
  onChangeSmallItem: (value: {
    smallItemUid: string
    values: { [key in keyof SmallItem]?: SmallItem[key] }
  }) => void
}

const WithoutChoiceComponent = memo<WithoutChoiceProps>(props => {
  const { smallItem, releaseTried, v2, onChangeSmallItem } = props

  return (
    <>
      <FormControl isInvalid={releaseTried && shouldShowError(smallItem.title)}>
        <FormLabel>フィールド名</FormLabel>
        <Input
          width="580px"
          placeholder={
            smallItem.itemType === ItemType.Label
              ? 'ラベルを入力してください'
              : 'フィールドのタイトルを入力してください'
          }
          value={smallItem.title}
          onChange={e =>
            onChangeSmallItem({
              smallItemUid: smallItem.uid,
              values: { title: e.target.value },
            })
          }
        />
      </FormControl>

      <Spacer size={20} />

      {!v2 && (
        <Wrap spacing="12">
          <WrapItem>
            <FormControl
              isInvalid={
                releaseTried &&
                smallItem.itemType !== ItemType.Label &&
                smallItem.inputRoles.length === 0
              }
            >
              <Item>
                <FormLabel>ロール</FormLabel>
                {smallItem.presetUsed ? (
                  <Value>{rolesToText(smallItem.inputRoles)}</Value>
                ) : (
                  <RoleCheckbox
                    checkedItems={smallItem.inputRoles}
                    onChange={role => {
                      onChangeSmallItem({
                        smallItemUid: smallItem.uid,
                        values: {
                          inputRoles: toggleRoles(smallItem.inputRoles, role),
                        },
                      })
                    }}
                    disabled={smallItem.itemType === ItemType.Label}
                  />
                )}
              </Item>
            </FormControl>
          </WrapItem>

          <WrapItem>
            <FormControl>
              <FormLabel>EDC連携</FormLabel>
              <RadioGroup
                value={smallItem.dataType.toString()}
                onChange={dataType => {
                  onChangeSmallItem({
                    smallItemUid: smallItem.uid,
                    values: { dataType: Number(dataType) as DataType },
                  })
                }}
              >
                <HStack spacing="4">
                  {dataTypeRadioItems.map(item => (
                    <Radio
                      value={item.value.toString()}
                      isChecked={item.value === smallItem.dataType}
                    >
                      {item.name}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            </FormControl>
          </WrapItem>
        </Wrap>
      )}

      <Spacer size={20} />

      <ItemsContainer>
        {smallItem.dataType === DataType.Clinical && (
          <EDCItems
            smallItem={smallItem}
            releaseTried={releaseTried}
            onChangeSmallItem={onChangeSmallItem}
          />
        )}

        <FormControl>
          <FormLabel>フィールドの説明</FormLabel>
          <Input
            width="580px"
            placeholder={
              smallItem.itemType === ItemType.Label
                ? ''
                : 'フィールドに関する説明文や回答例などを入力してください'
            }
            value={smallItem.description}
            onChange={e =>
              onChangeSmallItem({
                smallItemUid: smallItem.uid,
                values: { description: e.target.value },
              })
            }
            isDisabled={smallItem.itemType === ItemType.Label}
          />
        </FormControl>
      </ItemsContainer>

      <Spacer size={20} />

      {!smallItem.presetUsed && (
        <Wrap spacing="4">
          <WrapItem>
            <Stack spacing="5">
              <FormControl>
                <FormLabel>回答タイプ</FormLabel>
                <ItemTypeSelect
                  selectedValue={getGeneralItemType(smallItem)}
                  onSelect={itemType => {
                    type FirstArg<T> = T extends (args: infer U) => any
                      ? U
                      : never
                    const values: FirstArg<
                      WithoutChoiceProps['onChangeSmallItem']
                    >['values'] = {
                      itemType,
                    }
                    if (itemType === ItemType.CheckBox) {
                      values.edcDataItem = '-'
                    }
                    if (itemType === ItemType.Label) {
                      values.inputRoles = []
                      values.description = ''
                    }
                    onChangeSmallItem({
                      smallItemUid: smallItem.uid,
                      values,
                    })
                  }}
                />
              </FormControl>
              {smallItem.itemType === ItemType.File && (
                <Box>
                  <Checkbox
                    onChange={e =>
                      onChangeSmallItem({
                        smallItemUid: smallItem.uid,
                        values: { isCertifiedCopy: e.target.checked },
                      })
                    }
                  >
                    Certified Copyとして扱う
                  </Checkbox>
                </Box>
              )}
            </Stack>
          </WrapItem>

          {isDateAndTimeType(smallItem) && (
            <WrapItem>
              <FormControl>
                <FormLabel>フォーマット</FormLabel>
                <DateTypeSelect
                  selectedValue={smallItem.itemType}
                  onSelect={itemType => {
                    onChangeSmallItem({
                      smallItemUid: smallItem.uid,
                      values: { itemType },
                    })
                  }}
                />
              </FormControl>
            </WrapItem>
          )}

          {isTextType(smallItem) && (
            <WrapItem>
              <FormControl>
                <FormLabel>フォーマット</FormLabel>
                <TextTypeSelect
                  selectedValue={smallItem.itemType}
                  onSelect={itemType => {
                    onChangeSmallItem({
                      smallItemUid: smallItem.uid,
                      values: { itemType },
                    })
                  }}
                />
              </FormControl>
            </WrapItem>
          )}
        </Wrap>
      )}

      {smallItem.presetUsed && (
        <ItemsContainer>
          <Item mr={2} width={160}>
            <Label bold>回答タイプ</Label>
            <Spacer size={10} />
            <Value>{itemTypeToText(smallItem.itemType)}</Value>
          </Item>

          {isDateAndTimeType(smallItem) && (
            <Item mr={2} width={160}>
              <Label bold>フォーマット</Label>
              <Spacer size={10} />
              <Value>{itemTypeToDateFormat(smallItem.itemType)}</Value>
            </Item>
          )}

          {isTextType(smallItem) && (
            <Item mr={2} width={160}>
              <Label bold>フォーマット</Label>
              <Spacer size={10} />
              <Value>{itemTypeToTextLabel(smallItem.itemType)}</Value>
            </Item>
          )}
        </ItemsContainer>
      )}

      <Spacer size={20} />

      {smallItem.itemType === ItemType.Number && (
        <FormControl>
          <FormLabel>入力する数値の単位</FormLabel>
          {smallItem.presetUsed ? (
            <Box w="160px">
              <Text>{smallItem.unit}</Text>
            </Box>
          ) : (
            <Input
              width="100px"
              placeholder="℃"
              value={smallItem.unit}
              onChange={e =>
                onChangeSmallItem({
                  smallItemUid: smallItem.uid,
                  values: { unit: e.target.value },
                })
              }
            />
          )}
        </FormControl>
      )}

      {smallItem.itemType === ItemType.Label &&
        smallItem.dataType === DataType.Clinical && (
          <ItemContainer>
            <Label bold>EDC連携テキスト</Label>
            <Spacer size={10} />
            <Textarea
              value={smallItem.value ?? ''}
              onChange={e => {
                // 未入力の場合はnull。
                // cf)../card.tsx。
                const value = !!e.target.value ? e.target.value : null
                onChangeSmallItem({
                  smallItemUid: smallItem.uid,
                  values: { value },
                })
              }}
              showError={releaseTried && isEmptyLetter(smallItem.value)}
            />
          </ItemContainer>
        )}
    </>
  )
})

type EDCItemsProps = {
  smallItem: SmallItem
  releaseTried: boolean
  onChangeSmallItem: (value: {
    smallItemUid: string
    values: { [key in keyof SmallItem]?: SmallItem[key] }
  }) => void
}

const EDCItems = memo<EDCItemsProps>(props => {
  const { smallItem, releaseTried, onChangeSmallItem } = props

  return (
    <FormControl>
      <FormLabel>EDCフィールド名</FormLabel>
      {smallItem.itemType === ItemType.CheckBox ? (
        <Value>{smallItem.edcDataItem}</Value>
      ) : (
        <Input
          width="160px"
          value={smallItem.edcDataItem}
          onChange={e =>
            onChangeSmallItem({
              smallItemUid: smallItem.uid,
              values: { edcDataItem: e.target.value },
            })
          }
          className={
            releaseTried && shouldShowError(smallItem.edcDataItem)
              ? 'error'
              : ''
          }
        />
      )}
    </FormControl>
  )
})

type OptionsItemProps = {
  smallItem: SmallItem
  v2: boolean
}

const OptionsItem: React.FC<OptionsItemProps> = optionsItemProps => {
  const { smallItem, v2 } = optionsItemProps

  const {
    releaseTried,
    smallItemMap,
    onAddChoice,
    onChangeChoice,
    onRemoveChoice,
    onAddSmallItemToChoice,
  } = useContext(CardContext)

  return (
    <ItemContainer>
      <FormLabel as="span">回答の選択肢</FormLabel>
      {smallItem.choices &&
        smallItem.choices.map((choice, choiceIndex) => (
          <ChoiceComponent
            key={choice.uid}
            choiceNumber={choiceIndex + 1}
            isClinical={smallItem.dataType === DataType.Clinical}
            releaseTried={releaseTried}
            presetUsed={smallItem.presetUsed}
            smallItems={
              choice.smallItemUids
                ? choice.smallItemUids.map(uid => smallItemMap[uid])
                : []
            }
            choice={choice}
            itemType={smallItem.itemType}
            v2={v2}
            onChange={({ values }) =>
              onChangeChoice({
                smallItemUid: smallItem.uid,
                choiceIndex,
                values,
              })
            }
            onRemove={() =>
              onRemoveChoice({ smallItemUid: smallItem.uid, choiceIndex })
            }
            onAddSmallItem={() =>
              onAddSmallItemToChoice({
                smallItemUid: smallItem.uid,
                choiceIndex,
              })
            }
          />
        ))}

      {!smallItem.presetUsed && (
        <Button
          leftIcon={<Add />}
          variant="outline"
          onClick={() => onAddChoice({ smallItemUid: smallItem.uid })}
        >
          新規選択肢作成
        </Button>
      )}

      <Spacer size={8} />

      {releaseTried && smallItem.choices.length === 0 && (
        <Text color="red.500" fontSize="sm">
          選択肢を追加してください
        </Text>
      )}
    </ItemContainer>
  )
}
