// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgNotification = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M8 16c1.1 0 2-.9 2-2H6c0 1.1.9 2 2 2ZM13 6c0-2.4-1.7-4.4-4-4.9V1c0-.6-.4-1-1-1S7 .4 7 1v.1C4.7 1.6 3 3.6 3 6c0 3.3-.9 6-2 6v1h14v-1c-1.1 0-2-2.7-2-6Z"
    />
  </svg>
)
export default SvgNotification
