import React from 'react'

import { Route, Routes } from 'react-router-dom'
import { getRelativePath, PathKey } from 'src/constants/paths'
import {
  AddExplanationScreen,
  AddReAgreementExplanationScreen,
  EditExplanationScreen,
  ExplanationDetailScreen,
  ExplanationListScreen,
} from 'src/features/explanation/components'

export const Explanation: React.FC = () => {
  const getPath = (path: PathKey): string => {
    return getRelativePath('Explanations', path)
  }

  return (
    <Routes>
      <Route path="" element={<ExplanationListScreen />} />
      <Route
        path={getPath('ExplanationsNew')}
        element={<AddExplanationScreen />}
      />
      <Route
        path={getPath('Explanation')}
        element={<ExplanationDetailScreen />}
      />
      <Route
        path={getPath('ExplanationEdit')}
        element={<EditExplanationScreen />}
      />
      <Route
        path={getPath('ExplanationAddReAgreement')}
        element={<AddReAgreementExplanationScreen />}
      />
    </Routes>
  )
}
