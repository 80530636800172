import { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'
import { assertNever } from 'src/utils/assertNever'

import { ModalCancelButton } from '../../../../lib/chakra-theme/components'
import { useDeleteSignSession } from '../../api/deleteSignSession'
import { RequestSign } from '../../context/ExplanationRoomEvent'
import { useRoomMediaContext } from '../../context/MediaProvider'

type Props = {
  isOpen: boolean
  isSP: boolean
  trialUid: string
  sessionUid: string
  actorType: 'Member' | 'Partner' | 'Patient'
  onClose: () => void
  requestSign?: RequestSign
  ownDeviceActorUid: string
}

export const ReceiveRequestSignModal: React.FC<Props> = ({
  isOpen,
  isSP,
  trialUid,
  sessionUid,
  actorType,
  onClose,
  requestSign,
  ownDeviceActorUid,
}) => {
  const navigate = useNavigate()

  const { request: deleteSignSession } = useDeleteSignSession()

  const signPath = useMemo(() => {
    if (!requestSign) {
      return ''
    }
    const pathPostfix = `?${RoomEventUidQueryKey}=${requestSign.explanationRoomEventUid}`
    switch (actorType) {
      case 'Member':
        return (
          generatePath(Paths.HospitalExplanationRoomDocumentSign, {
            trialUid,
            sessionUid,
            docRevisionUid: requestSign.docRevUid,
            signerActorUid: requestSign.signerActorUid,
          }) + pathPostfix
        )
      case 'Partner':
        return (
          generatePath(Paths.PartnerExplanationRoomDocumentSign, {
            trialUid,
            sessionUid,
            docRevisionUid: requestSign.docRevUid,
            signerActorUid: requestSign.signerActorUid,
          }) + pathPostfix
        )
      case 'Patient':
        return (
          generatePath(Paths.PatientExplanationRoomDocumentSign, {
            trialUid,
            sessionUid,
            docRevisionUid: requestSign.docRevUid,
            signerActorUid: requestSign.signerActorUid,
          }) + pathPostfix
        )
      default:
        return assertNever(actorType)
    }
  }, [actorType, requestSign, sessionUid, trialUid])

  const [openReceiveRequestSignModal, setOpenReceiveRequestSignModal] =
    useState(false)

  const [openStartSignModal, setOpenStartSignModal] = useState(false)

  const { videoExpanded, toggleVideoExpanded } = useRoomMediaContext()

  useEffect(() => {
    if (requestSign && requestSign.deviceActorUid === ownDeviceActorUid) {
      if (requestSign.selfRequest) {
        // 自分自身に署名依頼が来た場合は、「署名依頼が届きました」をskip
        setOpenStartSignModal(true)
      } else {
        setOpenReceiveRequestSignModal(true)
      }
    }
  }, [ownDeviceActorUid, requestSign])

  if (!requestSign) {
    return null
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpenStartSignModal(false)
        setOpenReceiveRequestSignModal(false)
        onClose()
      }}
      size={isSP ? 'sm' : 'xl'}
    >
      <ModalOverlay />
      {openReceiveRequestSignModal && (
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader
            textAlign={isSP ? 'center' : 'left'}
            px={isSP ? '4' : undefined}
          >
            {isSP
              ? '署名依頼が届きました'
              : `${requestSign.signerActorDisplayName}さんに署名依頼が届きました`}
          </ModalHeader>
          <ModalBody
            textAlign={isSP ? 'center' : 'left'}
            px={isSP ? '4' : undefined}
          >
            以下のボタンより署名を実施してください。
          </ModalBody>
          <ModalFooter
            flexDirection={isSP ? 'column-reverse' : 'row'}
            px={isSP ? '4' : undefined}
          >
            <ModalCancelButton />
            <Button
              colorScheme="green"
              onClick={() => {
                setOpenReceiveRequestSignModal(false)
                setOpenStartSignModal(true)
              }}
            >
              署名を実施する
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
      {openStartSignModal && (
        <ModalContent>
          <ModalCloseButton />
          {requestSign.signerActorUid === ownDeviceActorUid ? (
            <ModalHeader>これより署名を開始します</ModalHeader>
          ) : (
            <ModalHeader>署名をする方に端末を渡してください</ModalHeader>
          )}
          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={async () => {
                // スマホの場合、署名画面を開く際にビデオ通話のエリアを縮小する
                if (isSP && videoExpanded) {
                  toggleVideoExpanded()
                }
                await deleteSignSession()
                navigate(signPath)
              }}
            >
              確認
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  )
}
