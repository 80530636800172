import { Button, VStack, Image, Spacer, HStack, Text } from '@chakra-ui/react'
import { generatePath, useParams, useNavigate } from 'react-router-dom'
import Logo from 'src/assets/image/miroha-logo.svg'
import { Paths } from 'src/constants/paths'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { usePatientVideoCallSignIn } from '../../api'
import { useChallengePatientVideoCall } from '../../api/challengePatientVideoCall'
import { PatientVideoCallGuard } from '../PatientVideoCallGuard/PatientVideoCallGuard'

type Param = {
  trialUid: string
  patientVideoCallUid: string
}

export const PatientVideoCallSignInScreen: React.FC = () => {
  const { trialUid = '', patientVideoCallUid = '' } = useParams<Param>()

  const { mutate } = useChallengePatientVideoCall({
    trialUid,
    patientVideoCallUid,
  })

  const navigate = useNavigate()
  const toast = useMirohaToast()

  const { request: signIn } = usePatientVideoCallSignIn({
    onSuccess: () => {
      mutate()
      navigate({
        pathname: generatePath(Paths.PatientVideoCallForPatient, {
          trialUid,
          patientVideoCallUid,
        }),
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  return (
    <PatientVideoCallGuard>
      {({ hospitalName }) => (
        <VStack p={4} height="100%">
          <Image src={Logo} alt="MiROHAアイコン" alignSelf="start" />
          <Spacer />
          <VStack spacing={6}>
            <Text fontSize="xl" fontWeight="bold">
              医療機関とのビデオ通話を開始します
            </Text>
            {hospitalName && (
              <HStack spacing={4} pb={2}>
                <Text as="span" color="gray.600">
                  医療機関:
                </Text>
                <Text fontSize="lg" fontWeight="bold" as="span">
                  {hospitalName ?? ''}
                </Text>
              </HStack>
            )}
            <Button
              onClick={async () => {
                await signIn({ trialUid, patientVideoCallUid })
              }}
            >
              ボタンを押してビデオ通話を開始する
            </Button>
          </VStack>
          <Spacer />
          <Text color="gray.200" fontSize="sm">
            © All Right Reserved by MICIN.INC
          </Text>
        </VStack>
      )}
    </PatientVideoCallGuard>
  )
}
