import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { RightArrow } from 'src/components/__legacy__icon/monochrome'
import { zIndex } from 'src/modules/util/z-index'
import styled, { css } from 'styled-components'

import { blue, white } from '../color/palette'

export type PaneType = 'normal' | 'modal'

type Props = {
  paneType: PaneType
  content: React.ReactNode
  onClose: () => void
  closeOnOutsideClick?: boolean
}

/**
 * @deprecated Use chakra-ui Drawer instead.
 */
export const Pane: React.FC<Props> = props => {
  switch (props.paneType) {
    case 'normal':
      return <PaneComponent {...props} />
    case 'modal':
      return (
        <BlurredBackground>
          <PaneComponent {...props} />
        </BlurredBackground>
      )
    default:
      return null
  }
}

const PaneComponent: React.FC<Props> = props => {
  const { paneType, content, onClose, closeOnOutsideClick } = props

  return (
    <OutsideClickHandler
      onOutsideClick={onClose}
      disabled={!closeOnOutsideClick}
    >
      <Wrapper paneType={paneType}>
        <Close onClick={onClose}>
          <RightArrow color={white} />
        </Close>
        <PaneInner paneType={paneType}>{content}</PaneInner>
      </Wrapper>
    </OutsideClickHandler>
  )
}

const HEADER_HEIGHT = 64

const BlurredBackground = styled.div`
  position: fixed;
  background: rgba(83, 83, 83, 0.5); //gray[10] with 50% opacity
  top: ${HEADER_HEIGHT}px;
  left: 0;
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT}px);
  z-index: ${zIndex.blurredPaneBackground};
`

const wrapperStyleByType = (paneType: PaneType) => {
  switch (paneType) {
    case 'normal':
      return css`
        width: calc(100vw / 3);
      `
    case 'modal':
      return css`
        width: calc(100vw * 2 / 3);
        min-width: calc(1024px * 0.8);
      `
    default:
      return css``
  }
}

const Wrapper = styled.div<{ paneType: PaneType }>`
  z-index: ${zIndex.paneBackground};
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  right: 0;
  transform: translateX(480px);
  box-sizing: border-box;
  height: calc(100% - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  background-color: ${blue[5]};
  transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  box-shadow: -4px 4px 4px rgba(83, 83, 83, 0.1);
  border-left: 2px solid ${blue[70]};
  transform: translateX(0px);

  ${p => wrapperStyleByType(p.paneType)}
`

const PaneInner = styled.div<{ paneType: PaneType }>`
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
`

const Close = styled.div`
  background-color: ${blue[70]};
  position: absolute;
  left: 0;
  top: 36.5px;
  border-radius: 0 6px 6px 0;
  width: 16px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
