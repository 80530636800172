import { trialFixture } from 'src/modules/util/fixtureUtils'

import { Template, TemplateStatus } from './entity'
import { createFixture as createChoiceFixture } from '../choice/fixture'
import {
  fixture as mediumItemFixture,
  fixtureDetail as mediumItemFixtureDetail,
  createFixture as createMediumFixture,
} from '../medium-item/fixture'
import { DataType, ItemType } from '../small-item/entity'
import { createFixture as createSmallFixture } from '../small-item/fixture'
import { fixtures as templateFolderFixtures } from '../template-folder/fixture'

export const fixtureDetail: Template = {
  uid: 'fooTemplateUid',
  trialUid: trialFixture[2].uid,
  title: 'foobar',
  templateFolderUid: templateFolderFixtures[1].uid,
  presetUsed: false,
  status: TemplateStatus.Released,
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
  releasedAt: '2021-01-01T00:00:00',
  videoEnabled: true,
  index: 0,
  mediumItems: [
    createMediumFixture({
      uid: 'fooMediumItemUid',
      title: '通常のセクション',
      index: 0,
      smallItemMap: {
        a: createSmallFixture({
          uid: 'a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemUid: 'fooMediumItemUid',
        }),
        b: createSmallFixture({
          uid: 'b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choices: [
            createChoiceFixture({
              uid: 'a',
              smallItemUids: ['c'],
            }),
            createChoiceFixture({
              uid: 'a2',
              smallItemUids: ['e'],
            }),
          ],
          mediumItemUid: 'fooMediumItemUid',
        }),
        c: createSmallFixture({
          uid: 'c',
          itemType: ItemType.Radio,
          parentUid: 'b',
          choices: [
            createChoiceFixture({
              uid: 'b',
              description: '子持ち選択肢A',
              smallItemUids: ['d'],
            }),
            createChoiceFixture({
              uid: 'c',
              description: '選択肢B',
            }),
          ],
          mediumItemUid: 'fooMediumItemUid',
        }),
        d: createSmallFixture({
          uid: 'd',
          parentUid: 'c',
          itemType: ItemType.CheckBox,
          choices: [
            createChoiceFixture({
              uid: 'd',
            }),
            createChoiceFixture({
              uid: 'e',
            }),
            createChoiceFixture({
              uid: 'f',
            }),
          ],
          mediumItemUid: 'fooMediumItemUid',
        }),
        e: createSmallFixture({
          uid: 'e',
          parentUid: 'b',
          itemType: ItemType.CheckBox,
          choices: [
            createChoiceFixture({
              uid: 'd',
            }),
            createChoiceFixture({
              uid: 'e',
            }),
            createChoiceFixture({
              uid: 'f',
            }),
          ],
          mediumItemUid: 'fooMediumItemUid',
        }),
      },
    }),
    createMediumFixture({
      uid: 'loglineMediumItemUid',
      title: 'ログラインセクション 1',
      isLogline: true,
      index: 1,
      smallItemMap: {
        logline1_a: createSmallFixture({
          uid: 'logline1_a',
          dataType: DataType.Clinical,
          parentUid: null,
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_b: createSmallFixture({
          uid: 'logline1_b',
          parentUid: null,
          itemType: ItemType.CheckBox,
          choices: [
            createChoiceFixture({
              uid: 'logline1_a',
              smallItemUids: ['logline1_c'],
            }),
            createChoiceFixture({
              uid: 'logline1_a2',
              smallItemUids: ['logline1_e'],
            }),
          ],
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_c: createSmallFixture({
          uid: 'logline1_c',
          itemType: ItemType.Radio,
          parentUid: 'logline1_b',
          choices: [
            createChoiceFixture({
              uid: 'logline1_b',
              description: '子持ち選択肢A',
              smallItemUids: ['logline1_d'],
            }),
            createChoiceFixture({
              uid: 'logline1_c',
              description: '選択肢B',
            }),
          ],
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_d: createSmallFixture({
          uid: 'logline1_d',
          parentUid: 'logline1_c',
          itemType: ItemType.CheckBox,
          choices: [
            createChoiceFixture({
              uid: 'logline1_d1',
            }),
            createChoiceFixture({
              uid: 'logline1_e1',
            }),
            createChoiceFixture({
              uid: 'logline1_f1',
            }),
          ],
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_e: createSmallFixture({
          uid: 'logline1_e',
          parentUid: 'logline1_b',
          itemType: ItemType.CheckBox,
          choices: [
            createChoiceFixture({
              uid: 'logline1_d2',
            }),
            createChoiceFixture({
              uid: 'logline1_e2',
            }),
            createChoiceFixture({
              uid: 'logline1_f3',
            }),
          ],
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_f: createSmallFixture({
          uid: 'logline1_f',
          itemType: ItemType.Text,
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_g: createSmallFixture({
          uid: 'logline1_g',
          itemType: ItemType.Text,
          mediumItemUid: 'loglineMediumItemUid',
        }),
        logline1_h: createSmallFixture({
          uid: 'logline1_h',
          itemType: ItemType.Text,
          mediumItemUid: 'loglineMediumItemUid',
        }),
      },
    }),
  ],
}

export const fixtures: Template[] = [
  fixtureDetail,
  {
    uid: 'fooTemplateUid2',
    trialUid: trialFixture[1].uid,
    title: 'foobar2',
    templateFolderUid: templateFolderFixtures[2].uid,
    presetUsed: false,
    status: TemplateStatus.Released,
    createdAt: '2019-01-01T00:00:00',
    updatedAt: '2019-01-01T00:00:00',
    releasedAt: '2019-01-01T00:00:00',
    videoEnabled: true,
    index: 0,
    mediumItems: [mediumItemFixture],
  },
  {
    uid: 'presetTemplateUid2',
    trialUid: trialFixture[1].uid,
    title: 'preset だ',
    templateFolderUid: templateFolderFixtures[0].uid,
    presetUsed: true,
    status: TemplateStatus.Released,
    createdAt: '2019-01-01T00:00:00',
    updatedAt: '2019-01-01T00:00:00',
    releasedAt: '2019-01-01T00:00:00',
    videoEnabled: true,
    index: 0,
    mediumItems: [mediumItemFixture],
  },
]

export const fixtureDetails: Template[] = [
  fixtureDetail,
  {
    uid: 'fooTemplateUid2',
    trialUid: trialFixture[2].uid,
    title: 'foobar',
    templateFolderUid: 'folder2',
    presetUsed: false,
    status: TemplateStatus.Draft,
    createdAt: '2019-01-01T00:00:00',
    updatedAt: '2019-01-01T00:00:00',
    releasedAt: null,
    videoEnabled: false,
    index: 0,
    mediumItems: [mediumItemFixtureDetail],
  },
  {
    uid: 'fooTemplateUid2',
    trialUid: trialFixture[3].uid,
    title: 'foobar',
    templateFolderUid: 'folder2',
    presetUsed: false,
    status: TemplateStatus.Released,
    createdAt: '2019-01-01T00:00:00',
    updatedAt: '2019-01-01T00:00:00',
    releasedAt: '2019-01-01T00:00:00',
    videoEnabled: false,
    index: 0,
    mediumItems: [mediumItemFixture],
  },
]

export const fixture: Template = {
  uid: 'barTemplateUid',
  trialUid: trialFixture[4].uid,
  title: 'barbaz',
  templateFolderUid: 'folder1',
  presetUsed: false,
  status: TemplateStatus.Draft,
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
  releasedAt: null,
  videoEnabled: false,
  index: 0,
  mediumItems: [mediumItemFixture],
}
