export type CrfDownloadJob = {
  uid: string
  registeredAt: Date
  completedAt?: Date
  lastUpdatedBy: string
  status: CrfDownloadStatus
}

export enum CrfDownloadStatus {
  Waiting,
  Running,
  Done,
  Canceled,
  Error,
}

export const CrfDownloadStatusToText = (status: CrfDownloadStatus) => {
  switch (status) {
    case CrfDownloadStatus.Waiting:
      return '処理待機中'
    case CrfDownloadStatus.Running:
      return '処理中'
    case CrfDownloadStatus.Done:
      return 'ダウンロード'
    case CrfDownloadStatus.Canceled:
      return '処理中止'
    case CrfDownloadStatus.Error:
      return 'Error'
    default:
      const n: never = status
      throw new Error(n)
  }
}
