import { RoomSessionStatus } from '@micin-jp/call-lib-core'
import { useRoomSession } from '@micin-jp/call-lib-react'
import { assertNever } from 'src/utils/assertNever'

import { VideoCallRoomEnded } from '../VideoCallRoomEnded/VideoCallRoomEnded'
import { VideoCallRoomInCall } from '../VideoCallRoomInCall/VideoCallRoomInCall'
import { VideoCallRoomLoading } from '../VideoCallRoomLoading/VideoCallRoomLoading'
import { VideoCallRoomReady } from '../VideoCallRoomReady/VideoCallRoomReady'

type Props = {
  participantName?: string
  endedText: string
}

export const VideoCallRoomMainContent: React.FC<Props> = ({
  participantName,
  endedText,
}) => {
  const { sessionStatus } = useRoomSession()

  switch (sessionStatus) {
    case RoomSessionStatus.None:
    case RoomSessionStatus.FindingRoom:
    case RoomSessionStatus.Joining:
      return <VideoCallRoomLoading />
    case RoomSessionStatus.Ready:
      return <VideoCallRoomReady participantName={participantName} />
    case RoomSessionStatus.Incall:
      return <VideoCallRoomInCall />
    case RoomSessionStatus.Ended:
      return <VideoCallRoomEnded endedText={endedText} />
    default:
      return assertNever(sessionStatus)
  }
}
