import React from 'react'

import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { ButtonLink } from 'src/components/base/text-link'
import styled from 'styled-components'

import {
  IcfDocumentSignerRole,
  icfDocumentSignerRoles,
  icfDocumentRoleText,
} from '../entity'

type Props = {
  onClose: () => void
  onRedirect: (role: IcfDocumentSignerRole) => void
  signerRoles: IcfDocumentSignerRole[]
}

export const DocuSignAgreementFormPreviewModal: React.FC<Props> = ({
  onClose,
  onRedirect,
  signerRoles,
}) => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle title="確認する署名ロールを選択" />
      <ModalContent>
        <RoleTextContainer>
          {Object.values(icfDocumentSignerRoles).map(role => (
            <ButtonLink
              key={role}
              onClick={() => onRedirect(role)}
              disabled={!signerRoles.includes(role)}
            >
              {icfDocumentRoleText[role]}
            </ButtonLink>
          ))}
        </RoleTextContainer>
      </ModalContent>
      <ModalActions>
        <Button size="S" buttonType="cancel" text="閉じる" onClick={onClose} />
      </ModalActions>
    </Modal>
  )
}

const RoleTextContainer = styled.div`
  display: flex;
  justify-content: center;

  & > button:not(:last-child) {
    margin-right: 24px;
  }
`
