import React from 'react'

import { useParams } from 'react-router-dom'
import { useExplanationForPatient } from 'src/features/explanation/api'
import { ExplanationSession } from 'src/features/explanation/types'
import { ExplanationRoomEventProvider } from 'src/features/explanationRoom/context/ExplanationRoomEvent'
import { useFloatingVideoCallForPatient } from 'src/features/explanationRoom/hooks/useFloatingVideoCallForPatient'
import { Trial } from 'src/features/trial/types'

import { ExplanationRoomTopRenewal } from './top-renewal'
import { useTrial } from '../../../../../../../features/explanation/api/getTrial'
import { UID } from '../../../../../../../utils/brandedUid'
import { SidebarContentRenewal } from '../components/sidebar-renewal'
import { ExplanationRoomLayoutRenewal } from '../layout-renewal'
import { usePatientSession } from '../patient-session.hooks'

type Param = {
  trialUid: Trial['uid']
  sessionUid: ExplanationSession['uid']
}

export const PatientTopContainer: React.FC = () => {
  const {
    trialUid = '' as UID<'Trial'>,
    sessionUid = '' as UID<'ExplanationSession'>,
  } = useParams<Param>()
  const { session, mutateSession } = usePatientSession({
    explanationSessionUid: sessionUid,
  })

  const { data: trial } = useTrial({
    trialUid,
  })

  const { data: explanation } = useExplanationForPatient({
    explanationRevisionUid: session?.explanationRevision.uid ?? null,
  })

  const { renderFloatingVideoCall } = useFloatingVideoCallForPatient({
    sessionUid,
    isOldFlow: true,
  })

  if (!session || !explanation || !trial) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={session.explanationRevision.explanation.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={session.explanationRevision.uid}
      explanationPatientUid={
        session.explanationRevision.explanation.patient.uid
      }
      patientPhoneNumber={undefined} // 旧署名フローでは不要
      sessionUid={sessionUid}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={
        session.explanationRevision.explanation.patient.trialHospitalUid
      }
    >
      <ExplanationRoomLayoutRenewal
        headerTitle="説明ルーム"
        isPatient={true}
        session={session}
        sidebarContent={
          <SidebarContentRenewal
            trialName={trial.name}
            candidateId={explanation.patient.candidateId}
            explanationUid={explanation.uid}
            trialHospital={explanation.trialHospital}
            partnerTrialHospital={
              session.explanationRevision.partnerTrialHospital
            }
            session={session}
            isPatient
          />
        }
      >
        <ExplanationRoomTopRenewal
          userAttributeType="patient"
          hospitalName={explanation.trialHospital.name}
          isAgreementDone={
            session.explanationRevision.status === 'AgreementDone'
          }
          isDelivered={!!session.explanationRevision.deliveredAt}
          docSets={session.explanationRevision.docSets}
          trialUid={trialUid}
          session={session}
          explanationRevisionUid={session.explanationRevision.uid}
          mutateSession={mutateSession}
        />
      </ExplanationRoomLayoutRenewal>
      {renderFloatingVideoCall()}
    </ExplanationRoomEventProvider>
  )
}
