import { ExplanationRevisionStatusV2 } from 'src/lib/gql-client'

const pinSettingNotAvailableStatuses: ExplanationRevisionStatusV2[] = [
  'AgreementCompleted',
  'DeliveryCompleted',
  'Disabled',
  'Withdrawn',
  'AgreementRejected',
  'Invalid',
]

export const isPinSettingEnabledRevisionStatus = (
  revisionStatus: ExplanationRevisionStatusV2,
) => {
  return !pinSettingNotAvailableStatuses.includes(revisionStatus)
}
