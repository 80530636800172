import React from 'react'

import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { EditIcfDocumentSet } from './edit'
import { useEditIcfDocumentSet } from './edit.hooks'

export const EditIcfDocumentSetContainer: React.FC = () => {
  const {
    updateItem,
    icfDocumentSet,
    approvedIcfDocuments,
    editing,
    canUpdate,
    canDisable,
    onChangeName,
    onChangeSelectedDocuments,
    onValidate,
    onSubmit,
    onCancel,
    onDisable,
    errors,
    listPath,
    detailPath,
  } = useEditIcfDocumentSet()

  const pullDownState = useOpenPullDown()

  useBlockTransition({
    showAlert: editing,
  })

  return (
    <>
      <EditIcfDocumentSet
        updateItem={updateItem}
        numberingId={icfDocumentSet?.numberingId}
        approvedIcfDocuments={approvedIcfDocuments}
        canUpdate={canUpdate}
        canDisable={canDisable}
        onChangeName={onChangeName}
        onChangeSelectedDocuments={onChangeSelectedDocuments}
        onValidate={onValidate}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onDisable={onDisable}
        errors={errors}
        pullDownState={pullDownState}
        listPath={listPath}
        detailPath={detailPath}
      />
    </>
  )
}
