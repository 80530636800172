import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import BackgroundImage from 'src/assets/image/epro-patient-background-top.png'
import { Button } from 'src/components/base/button/button'
import { blue, red, white } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import styled from 'styled-components'

import { trialIdParamName } from './epro-page'
import { getListRoute } from './questionnaire-answer/routes'
import { useRequestState } from '../../server/use-request-state'

export const Login: React.FC = () => {
  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()
  const [diseaseUid, setDiseaseUid] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { requestStarted, requestDone, requestFailed, errorMessage } =
    useRequestState()

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      try {
        requestStarted()
        await requestPost({
          url: `${SERVER_URL}/epro_patient_user/trials/${trialUid}/login`,
          params: {
            diseaseUid,
            password,
          },
        })
        // セッションタイムアウト直後の画面遷移がpushだと上手く機能しないのでリロードで対応
        window.location.href = getListRoute({ trialUid })
        requestDone()
      } catch (err) {
        requestFailed(err.message)
      }
    },
    [
      diseaseUid,
      password,
      requestDone,
      requestFailed,
      requestStarted,
      trialUid,
    ],
  )

  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }, [])

  const onChangeDiseaseUid = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDiseaseUid(e.target.value)
  }, [])

  return (
    <Wrapper>
      <div>
        <TitleWrapper>
          {/* TODO: 患者側デザイントークン定義時にサイズ置き換え */}
          <Text fontSize="3xl" color="white" fontWeight="bold">
            e-PRO
          </Text>
          {/* TODO: 患者側デザイントークン定義時にサイズ置き換え */}
          <Text fontSize="3xl" color="white" fontWeight="bold">
            オンライン治験システム
          </Text>
        </TitleWrapper>
        <Spacer size={60}></Spacer>
        <Form onSubmit={onSubmit}>
          <Text fontSize="2xl">ログイン</Text>
          <Spacer size={24}></Spacer>
          <FormContent>
            <Label mb={`6px`}>ID（症例番号）</Label>
            <Input
              name="diseaseUid"
              placeholder="ID（症例番号）"
              value={diseaseUid}
              onChange={onChangeDiseaseUid}
            />
            <Spacer size={24}></Spacer>
            <Label mb={`6px`}>パスワード</Label>
            <Input
              name="password"
              type="password"
              placeholder="パスワード"
              value={password}
              onChange={onChangePassword}
            />
          </FormContent>
          <Spacer size={32}></Spacer>
          <Button
            text="ログイン"
            size="S"
            buttonType="important"
            disabled={!diseaseUid || !password}
          />
          <Spacer size={12} />
          {errorMessage && <Text color={red[50]}>{errorMessage}</Text>}
          <Spacer size={12}></Spacer>
          <Text color={blue[70]}>パスワードをお忘れの場合は、</Text>
          <Text color={blue[70]}>医療施設にお問い合わせください。</Text>
        </Form>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${blue[5]};
  background-image: url(${BackgroundImage});
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Form = styled.form`
  height: 496px;
  width: 568px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${white};
  padding: 48px 100px 36px;
`

const FormContent = styled.div`
  width: 100%;
`
