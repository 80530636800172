import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { login } from './request'

const create = actionCreatorFactory('auth/login')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  submit: createAsync<
    { idToken: string; firstLogin: boolean },
    {},
    { message: string }
  >('SUBMIT', async (params, dispatch, _getState) => {
    await login(params.idToken, params.firstLogin)
  }),
}

export type State = {
  requesting: boolean
  errorMessage: string | null
}

const initialState: State = {
  requesting: false,
  errorMessage: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.submit.async.started, (state, payload) => ({
    ...state,
    requesting: true,
    errorMessage: null,
  }))
  .case(actions.submit.async.done, (state, payload) => ({
    ...state,
    requesting: false,
  }))
  .case(actions.submit.async.failed, (state, payload) => {
    return {
      ...state,
      requesting: false,
      errorMessage: payload.error.message,
    }
  })
  .build()
