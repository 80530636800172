import React from 'react'

import { Alert, AlertDescription, AlertIcon, Box, Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'

interface IProps {
  onClose: () => void
}

export const MfaConfirmationModal: React.FC<IProps> = ({ onClose }) => {
  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="2段階認証設定" />

      <ModalContent>
        <Text textAlign="center">
          MiROHAをお使いの端末を機種変更される場合は、変更前にMiROHAの2段階認証設定を解除の上、再度新しい端末での2段階認証の設定をお願いします。
        </Text>

        <Spacer size={20} />

        <Alert status="warning">
          <AlertIcon />
          <Box>
            <AlertDescription>
              2段階認証を解除せずに、使用している端末やアプリを紛失された場合、
              MiROHAへのログインができなくなります。
              その場合はMiROHAの運用デスクへお問い合わせください。
            </AlertDescription>
          </Box>
        </Alert>
      </ModalContent>

      <ModalActions>
        <Button size="S" text="閉じる" onClick={onClose} buttonType="normal" />
      </ModalActions>
    </Modal>
  )
}
