import React, { useEffect } from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import {
  PaginationNext,
  PaginationBack,
} from 'src/components/__legacy__icon/monochrome'
import { blue, gray } from 'src/components/base/color/palette'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import { MediumItemAnswerStatus } from 'src/modules/entities/medium-item-answer/entity'
import { getSingle as getTemplateFolder } from 'src/modules/entities/template-folder/selector'
import { hasClinicalSmallItem } from 'src/modules/util/has-clinical'
import { trialUidParamName } from 'src/modules/video-call/patient-container'
import styled from 'styled-components'

import { CommonDisableLabel } from '../../../../../../../../../../components/labels/disabled'
import { useBlockTransition } from '../../../../../../../../../../hooks/useBlockTransition'
import { getSelectedTrial } from '../../../../../../../../../entities/account/selector'
import { PatientStatus } from '../../../../../../../../../entities/patient/entity'
import { getSingle as getPatient } from '../../../../../../../../../entities/patient/selector'
import { WorksheetStatus } from '../../../../../../../../../entities/worksheet/entity'
import { getSingle as getWorksheet } from '../../../../../../../../../entities/worksheet/selector'
import { RootState } from '../../../../../../../../../reducer'
import { usePermission } from '../../../../../../common/permission'
import { patientIdParamName } from '../../../../patient-detail'
import {
  getDetailRoute as getWorksheetRoute,
  getLoglineRoute,
} from '../../../routes'
import { MediumItemAnswerLogButton } from '../../medium-item-answer-log/button/button'
import { AddLoglineButton } from '../add-logline/button'
import { ConfirmButton } from '../confirm/button'
import { DisableLoglineButton } from '../disable-logline/button'
import { EnableLoglineButton } from '../enable-logline/button'
import { actions } from '../redux'
import { SaveSectionButton } from '../save-section/save-section-button/save-section-button'
import { useSaveSection } from '../save-section/use-save-section'
import { getRootSmallItemAnswers, getState } from '../selector'
import { SmallItemAnswerComponent } from '../small-item/small-item-answer'
import { worksheetIdParamName } from '../worksheet-detail'

export const loglineIdParamName = 'loglineUid'

type Props = {}

export const LoglineDetail: React.FC<Props> = props => {
  const {
    trialUid = '',
    worksheetUid = '',
    loglineUid = '',
    patientUid = '',
  } = useParams<{
    [trialUidParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
    [loglineIdParamName]: string
  }>()
  const patient = useSelector((state: RootState) =>
    getPatient(state, patientUid),
  )
  const originalWorksheet = useSelector((state: RootState) =>
    getWorksheet(state, worksheetUid),
  )
  const { worksheet, editingSmallItemAnswerUidSet } = useSelector(getState)

  const templateFolder = useSelector((state: RootState) =>
    getTemplateFolder(state, originalWorksheet.templateFolderUid),
  )

  const logline = worksheet?.mediumItemAnswers.find(
    mia => mia.uid === loglineUid,
  )

  const loglines = worksheet?.mediumItemAnswers.filter(
    mia => mia.mediumItemUid === logline?.mediumItemUid,
  )
  const trial = useSelector(getSelectedTrial)!
  const dispatch = useDispatch()

  const smallItemAnswers = useSelector((state: RootState) =>
    getRootSmallItemAnswers(state, {
      mediumItemAnswerUid: loglineUid,
    }),
  )

  useEffect(() => {
    if (!originalWorksheet) return

    dispatch(
      actions.initWithWorksheet({
        worksheet: originalWorksheet,
        currentLoglineUid: loglineUid,
      }),
    )
  }, [dispatch, originalWorksheet, loglineUid])
  useEffect(() => {
    return () => {
      dispatch(actions.reset({}))
    }
  }, [dispatch])
  useBlockTransition({
    message: '編集内容が保存されていない可能性があります。移動しますか？',
    showAlert: editingSmallItemAnswerUidSet.size > 0,
  })

  const { hasPermission } = usePermission()
  const { canSave, canConfirm } = useSaveSection(loglineUid)
  const worksheetEditable =
    patient?.status === PatientStatus.Enable &&
    worksheet?.status !== WorksheetStatus.Disabled

  if (!patient || !originalWorksheet || !logline || !worksheet || !loglines) {
    return null
  }

  const disabled =
    worksheet.status === WorksheetStatus.Disabled ||
    logline.status === MediumItemAnswerStatus.Disabled

  const canAddLogline =
    hasPermission(PERMISSIONS.Worksheet_AddLogline) && worksheetEditable

  const edcLinked =
    trial.edcIntegrationEnabled && hasClinicalSmallItem(smallItemAnswers)

  const canEnableLogline =
    disabled &&
    hasPermission(PERMISSIONS.Worksheet_EnableLogline) &&
    worksheetEditable

  const prevLoglineUid = loglines.find(
    l => l.lineNumber === logline.lineNumber - 1,
  )?.uid
  const nextLoglineUid = loglines.find(
    l => l.lineNumber === logline.lineNumber + 1,
  )?.uid

  const breadcrumbParams: BreadcrumbParam[] = [
    { label: templateFolder.name, isLink: false },
    {
      label: worksheet.title,
      isLink: true,
      path: getWorksheetRoute({ trialUid, patientUid, worksheetUid }),
    },
    {
      label: logline.title,
      isLink: false,
    },
  ]

  return (
    <Container>
      <TitleWrapper>
        <Breadcrumb breadcrumbParams={breadcrumbParams} />
      </TitleWrapper>

      {canAddLogline && (
        <LineButtonWrapper>
          <Spacer size={8} />
          <AddLoglineButton
            trialUid={trial.uid}
            patientUid={patient.uid}
            worksheetUid={worksheet.uid}
            mediumItemUid={logline.mediumItemUid}
            edcLinked={edcLinked}
          />
          <Spacer size={8} />
        </LineButtonWrapper>
      )}

      <Spacer size={8} />

      <PaginationWrapper>
        <AdjacentLogline
          trialUid={trial.uid}
          patientUid={patientUid}
          worksheetUid={worksheetUid}
          loglineUid={prevLoglineUid}
        />

        <Spacer size={10} horizontal />

        <CurrentPageWrapper>
          <Text fontSize="sm">
            # {logline.lineNumber} / {loglines.length}
          </Text>
        </CurrentPageWrapper>

        <Spacer size={10} horizontal />

        <AdjacentLogline
          trialUid={trial.uid}
          patientUid={patientUid}
          worksheetUid={worksheetUid}
          loglineUid={nextLoglineUid}
          isNext
        />
      </PaginationWrapper>

      <LoglineButtonWrapper>
        <LeftSide>
          <Spacer size={20} />
          {logline.status === MediumItemAnswerStatus.Disabled && (
            <CommonDisableLabel text={'無効'} />
          )}
        </LeftSide>

        <RightSide>
          {hasPermission(PERMISSIONS.Worksheet_DisableLogline) && !disabled && (
            <DisableButtonWrapper>
              <div>
                <Spacer size={20} />
                <DisableLoglineButton
                  edcLinked={edcLinked}
                  lineNumber={logline.lineNumber}
                />
              </div>
              <Spacer size={18} horizontal />
            </DisableButtonWrapper>
          )}

          {hasPermission(PERMISSIONS.Worksheet_SaveLogline) && !disabled && (
            <RightSideButton>
              <div>
                <Spacer size={20} />
                <SaveSectionButton
                  mediumItemAnswerUid={logline.uid}
                  disabled={!canSave}
                />
              </div>
              <Spacer size={18} horizontal />
            </RightSideButton>
          )}

          {hasPermission(PERMISSIONS.Worksheet_SubmitLogline) && !disabled && (
            <RightSideButton>
              <div>
                <Spacer size={20} />
                <ConfirmButton
                  mediumItemAnswerUid={logline.uid}
                  edcLinked={edcLinked}
                  disabled={!canConfirm}
                  confirmationStatus={logline.confirmationStatus}
                />
              </div>
              <Spacer size={18} horizontal />
            </RightSideButton>
          )}

          {canEnableLogline && (
            <RightSideButton>
              <div>
                <Spacer size={20} />
                <EnableLoglineButton
                  edcLinked={edcLinked}
                  lineNumber={logline.lineNumber}
                />
              </div>
              <Spacer size={18} horizontal />
            </RightSideButton>
          )}

          {logline.hasLog && (
            <RightSideButton>
              <div>
                <Spacer size={20} />
                <MediumItemAnswerLogButton
                  mediumItemAnswerUid={logline.uid}
                  edcLinked={edcLinked}
                />
              </div>
              <Spacer size={18} horizontal />
            </RightSideButton>
          )}
        </RightSide>
      </LoglineButtonWrapper>

      <LoglineContentWrapper>
        {smallItemAnswers.map(smallItemAnswer => (
          <SmallItemAnswerComponent
            key={smallItemAnswer.uid}
            smallItemAnswer={smallItemAnswer}
            smallItemAnswerMap={logline.smallItemAnswerMap}
            currentRole={trial.role}
            worksheetStatus={worksheet.status}
            isEnablePatient={patient.status === PatientStatus.Enable}
            isDisabledLogline={disabled}
          />
        ))}
      </LoglineContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const PaginationWrapper = styled.div`
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: ${blue[10]};
  border-radius: 3px;
`

const CurrentPageWrapper = styled.div`
  margin: 0 10px;
`

const LoglineContentWrapper = styled.div`
  height: 100%;
  margin-top: 10px;
  padding-right: 5px;
  overflow-y: scroll;
`

const LineButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const LoglineButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const LeftSide = styled.div`
  margin-right: auto;
`

const RightSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const DisableButtonWrapper = styled.div`
  display: flex;
`

const RightSideButton = styled.div`
  display: flex;
`

type OtherLoglineProps = {
  trialUid: string
  patientUid: string
  worksheetUid: string
  loglineUid: string | undefined
  isNext?: boolean
}

const AdjacentLogline: React.FC<OtherLoglineProps> = props => {
  const { trialUid, patientUid, worksheetUid, loglineUid, isNext } = props

  return !!loglineUid ? (
    <NavLink
      to={getLoglineRoute({ trialUid, patientUid, worksheetUid, loglineUid })}
    >
      {!!isNext ? (
        <PaginationNext color={blue[70]} />
      ) : (
        <PaginationBack color={blue[70]} />
      )}
    </NavLink>
  ) : (
    <>
      {!!isNext ? (
        <PaginationNext color={gray[55]} />
      ) : (
        <PaginationBack color={gray[55]} />
      )}
    </>
  )
}
