import { parseGqlTrailHospital } from 'src/features/trial/api/parser'
import {
  GetExplanationTrialHospitalsDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { TrialHospital } from 'src/modules/entities/account/entity'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationCacheKey } from '../cacheKey'

const getExplanationTrialHospitals = async (): Promise<TrialHospital[]> => {
  try {
    const { enabledTrialHospitals } = await graphqlRequest(
      GetExplanationTrialHospitalsDocument,
      {},
    )
    return enabledTrialHospitals.map(parseGqlTrailHospital)
  } catch (e) {
    throw e
  }
}

export const useExplanationTrialHospitals = ({
  ...option
}: {} & SWRConfiguration<TrialHospital[], GqlError>) => {
  return useSWR<TrialHospital[], GqlError>(
    explanationCacheKey.getExplanationTrialHospitals(),
    () => getExplanationTrialHospitals(),
    option,
  )
}
