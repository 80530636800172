import { hasChoice } from 'src/modules/util/answer-type-classification'

import { QuestionnaireChoice } from '../questionnaire-choice/entity'
import { AnswerType, QuestionnaireField } from '../questionnaire-field/entity'
import { QuestionnaireSection } from '../questionnaire-section/entity'

type QuestionnaireChoiceUpdateParams = {
  uid: string
  index: number
  description: string
  value: string
}

type QuestionnaireFieldUpdateParams = {
  uid: string
  title: string
  answerType: AnswerType
  index: number
  unit: string
  choices: QuestionnaireChoiceUpdateParams[]
}

type QuestionnaireSectionUpdateParams = {
  uid: string
  title: string
  index: number
  fields: QuestionnaireFieldUpdateParams[]
}

type QuestionnaireUpdateParams = {
  title: string
  displayName: string
  sections: QuestionnaireSectionUpdateParams[]
}

const choiceToParams = (
  c: QuestionnaireChoice,
): QuestionnaireChoiceUpdateParams => {
  const params: QuestionnaireChoiceUpdateParams = {
    uid: c.uid,
    index: c.index,
    description: c.description,
    value: c.value,
  }

  return params
}

const fieldToParams = (
  f: QuestionnaireField,
): QuestionnaireFieldUpdateParams => {
  const params: QuestionnaireFieldUpdateParams = {
    uid: f.uid,
    title: f.title,
    answerType: f.answerType,
    index: f.index,
    unit: f.unit,
    choices: [],
  }

  params.choices = hasChoice(f) ? f.choices.map(c => choiceToParams(c)) : []

  return params
}

const sectionToParams = (
  s: QuestionnaireSection,
): QuestionnaireSectionUpdateParams => {
  const params: QuestionnaireSectionUpdateParams = {
    uid: s.uid,
    title: s.title,
    index: s.index,
    fields: [],
  }

  params.fields = Object.values(s.fieldMap).map(f => fieldToParams(f))

  return params
}

export const toUpdateParams = ({
  title,
  displayName,
  sections,
}: {
  title: string
  displayName: string
  sections: QuestionnaireSection[]
}): QuestionnaireUpdateParams => {
  return {
    title,
    displayName,
    sections: sections.map(sectionToParams),
  }
}
