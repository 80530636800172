import { useState, useMemo, useRef, useCallback, useEffect } from 'react'

import {
  Box,
  Center,
  Flex,
  Spinner,
  Stack,
  Button,
  Text,
} from '@chakra-ui/react'
import { Document, Thumbnail, Page } from 'react-pdf'

import { ScaleDown, ScaleUp } from '../icon'
import { RightClickBlocker } from '../RightClickBlocker/RightClickBlocker'

type Props = {
  url: string
}

const documentOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

export const PDFViewer: React.FC<Props> = ({ url }) => {
  const [numPages, setNumPages] = useState<number>()
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1)
  const [scale, setScale] = useState(1)

  const pageNumbers = useMemo(() => {
    return Array.from(new Array(numPages), (_, index) => index + 1)
  }, [numPages])

  const pagesContainerRef = useRef<HTMLDivElement>(null)
  const pagesRef = useRef<{ [key in number]: HTMLDivElement }>({})
  const thumbnailContainerRef = useRef<HTMLDivElement>(null)
  const thumbnailsRef = useRef<{ [key in number]: HTMLDivElement }>({})

  const handleScroll = useCallback(() => {
    if (!pagesContainerRef.current) return

    // アクティブなページを取得
    const container = pagesContainerRef.current
    const containerRect = container.getBoundingClientRect()
    const containerCenter = containerRect.top + containerRect.height / 2
    const activePageNumber = pageNumbers.find(pageNumber => {
      const page = pagesRef.current[pageNumber]
      if (!page) return false
      const pageRect = page.getBoundingClientRect()
      return pageRect.top < containerCenter && containerCenter < pageRect.bottom
    })
    if (!activePageNumber) return
    setCurrentPageNumber(activePageNumber)
  }, [pageNumbers])

  // アクティブなサムネイルが表示されるようにする
  useEffect(() => {
    if (!thumbnailContainerRef.current) return
    const activeThumbnail = thumbnailsRef.current[currentPageNumber]
    if (!activeThumbnail) return
    // 表示したいサムネイルが見切れている場合はスクロール
    const container = thumbnailContainerRef.current
    const containerRect = container.getBoundingClientRect()
    const thumbnailRect = activeThumbnail.getBoundingClientRect()
    if (thumbnailRect.top < containerRect.top) {
      // 下方に見切れている場合
      container.scrollTo({
        top: activeThumbnail.offsetTop,
        behavior: 'smooth',
      })
    } else if (thumbnailRect.bottom > containerRect.bottom) {
      // 上方に見切れている場合
      container.scrollTo({
        top:
          activeThumbnail.offsetTop +
          thumbnailRect.height -
          containerRect.height,
        behavior: 'smooth',
      })
    }
  }, [currentPageNumber])

  useEffect(() => {
    if (!pagesContainerRef.current) return
    const container = pagesContainerRef.current
    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Flex bg="gray.100" h="full" w="full" pos="relative">
      <Document
        file={url}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages)
        }}
        options={documentOptions}
        loading={
          <Center pos="absolute" boxSize="full">
            <Spinner />
          </Center>
        }
      >
        <Stack
          ref={thumbnailContainerRef}
          bg="gray.50"
          px="10"
          py="6"
          h="full"
          spacing={6}
          overflowY="auto"
        >
          {pageNumbers.map(pageNumber => (
            <Stack
              key={`thumbnail_${pageNumber}`}
              ref={node => {
                if (node !== null) {
                  thumbnailsRef.current[pageNumber] = node
                }
              }}
              align="center"
            >
              <Box
                border="4px solid"
                borderColor={
                  currentPageNumber === pageNumber ? 'blue.500' : 'transparent'
                }
              >
                <RightClickBlocker>
                  <Thumbnail
                    key={pageNumber}
                    width={96} // px
                    pageNumber={pageNumber}
                    onLoadError={console.error}
                    loading=""
                  />
                </RightClickBlocker>
              </Box>
              <Center
                as="span"
                borderRadius="sm"
                bg={
                  currentPageNumber === pageNumber ? 'blue.500' : 'transparent'
                }
                h="5"
                w="5"
                fontSize="sm"
                color={currentPageNumber === pageNumber ? 'white' : 'gray.800'}
              >
                {pageNumber}
              </Center>
            </Stack>
          ))}
        </Stack>
        <Stack
          ref={pagesContainerRef}
          p="8"
          spacing="8"
          overflow="auto"
          flex="1"
          bg="gray.200"
        >
          {pageNumbers.map(pageNumber => (
            <Center
              key={`page_${pageNumber}`}
              ref={node => {
                if (node !== null) {
                  pagesRef.current[pageNumber] = node
                }
              }}
            >
              <RightClickBlocker>
                <Page pageNumber={pageNumber} scale={scale} loading="" />
              </RightClickBlocker>
            </Center>
          ))}
        </Stack>
        {/* 拡大/縮小ボタン */}
        <Flex
          pos="absolute"
          zIndex="2"
          right="2"
          top="2"
          p="2"
          justifyContent="center"
          opacity={0.8}
          gap={3}
          align="center"
          bg="gray.700"
          color="white"
          borderRadius="base"
        >
          <Button
            onClick={() => {
              setScale(current => {
                if (current <= 0.1) return current
                return current - 0.1
              })
            }}
            minW="unset"
            boxSize="32px"
            variant="ghost"
            borderRadius="base"
            p="0"
            color="white"
            _hover={{ '@media(hover: hover)': { bg: 'gray.500' } }}
            _active={{ bg: 'gray.500' }}
          >
            <ScaleDown />
          </Button>
          <Text as="span" w="44px" textAlign="center">
            {Math.floor(scale * 100)}%
          </Text>
          <Button
            onClick={() => {
              setScale(current => {
                if (current >= 3) return current
                return current + 0.1
              })
            }}
            minW="unset"
            boxSize="32px"
            variant="ghost"
            borderRadius="base"
            p="0"
            color="white"
            _hover={{ '@media(hover: hover)': { bg: 'gray.500' } }}
            _active={{ bg: 'gray.500' }}
          >
            <ScaleUp />
          </Button>
        </Flex>
      </Document>
    </Flex>
  )
}
