import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const submitDisable = async ({
  trialUid,
  patientUid,
  worksheetUid,
  inputValue,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  inputValue: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }
  await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/disable`,
    params: {
      reason: inputValue,
    },
  })
  return {}
}
