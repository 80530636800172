import { actions as entitiesActions } from 'src/modules/entities/reducer'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { logout } from './request'
import { rotateLoginId } from '../../util/rotate-login-id'

const create = actionCreatorFactory('auth/logout')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  submit: createAsync<{}, {}, { message: string }>(
    'SUBMIT',
    async (_params, dispatch, _getState) => {
      await logout()
      rotateLoginId()
      dispatch(entitiesActions.reset({}))
    },
  ),
}

export type State = {
  requesting: boolean
  errorMessage: string | null
}

const initialState: State = {
  requesting: false,
  errorMessage: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.submit.async.started, (state, payload) => ({
    ...state,
    requesting: true,
    errorMessage: null,
  }))
  .case(actions.submit.async.done, (state, payload) => ({
    ...state,
    requesting: false,
  }))
  .case(actions.submit.async.failed, (state, payload) => {
    return {
      ...state,
      requesting: false,
      errorMessage: payload.error.message,
    }
  })
  .build()
