import React from 'react'

import dayjs from 'dayjs'
import { colors } from 'src/assets/colors'
import {
  VideoCall,
  VideoCallCapture,
} from 'src/modules/dashboard/trial/detail/patient/detail/worksheet/common/detail/output/pdf/entity'
import styled from 'styled-components'

type Props = {
  videoCall: VideoCall
}

export const CallHistory: React.FC<Props> = props => {
  return (
    <Container>
      <Left>
        <BodyItem>
          {dayjs(props.videoCall.createdAt).format('YYYY/MM/DD HH:mm')}
        </BodyItem>
        <BodyItem>
          {props.videoCall.finishedAt
            ? dayjs(props.videoCall.finishedAt).format('YYYY/MM/DD HH:mm')
            : '-'}
        </BodyItem>
        <BodyItem>{props.videoCall.callerName}</BodyItem>
      </Left>
      <Right>
        {props.videoCall.images.map((image, i) => (
          <ImageContent
            key={`${image}${i}`}
            image={image}
            lastChild={i === props.videoCall.images.length - 1}
          />
        ))}
      </Right>
    </Container>
  )
}

const ImageContent: React.FC<{
  image: VideoCallCapture
  lastChild: boolean
}> = props => {
  return (
    <ImageContainer className={props.lastChild ? 'margin-none' : ''}>
      <Image>
        <img src={props.image.url} className={`image`} alt="" />
      </Image>
      <ImageText>{props.image.name}</ImageText>
    </ImageContainer>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 4px 12px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 0.5px dashed #000000;
  &:last-child {
    border-bottom: 0.5px solid #000000;
    margin-bottom: 10px;
  }
`

const Left = styled.div`
  flex: 3;
  min-height: 36px;
  display: flex;
  align-items: center;
`

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const BodyItem = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 7px;
  min-height: 36px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  color: ${colors.dark};
  word-break: break-all;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin: 0 0 4px;

  &.margin-none {
    margin: 0;
  }
`

const Image = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  > .image {
    max-width: 32px;
    max-height: 32px;
    margin: 0 auto;
  }
`

const ImageText = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 7px;
  line-height: 11px;
  margin-left: 4px;
  color: ${colors.dark};
  word-break: break-all;
`
