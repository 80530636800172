import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { GqlError } from 'src/lib/gql-client'

import { requestPinSetting } from '../requestPinSetting'
import { updateExpPatientMobileNumber } from '../updatePatientMobileNumber'

const requestPinSettingWithUpdatePatientMobileNumber = async ({
  explanationRevisionUid,
  explanationPatientUid,
  mobileNumber,
}: {
  explanationRevisionUid: string
  explanationPatientUid: string
  mobileNumber: string
}): Promise<void> => {
  try {
    await updateExpPatientMobileNumber({
      explanationPatientUid,
      mobileNumber,
    })
    await requestPinSetting({
      explanationRevisionUid: explanationRevisionUid,
      method: 'SMS',
    })
  } catch (error) {
    throw error
  }
}

export const useRequestPinSettingWithUpdatePatientMobileNumber = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(requestPinSettingWithUpdatePatientMobileNumber, option)
}
