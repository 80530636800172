import { Worksheet } from 'src/features/worksheet/types'
import {
  GqlError,
  GetRepeatableSectionHistoriesQuery,
  GetRepeatableSectionHistoriesDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { worksheetCacheKey } from '../cacheKey'

export type RepeatableSectionHistories =
  GetRepeatableSectionHistoriesQuery['repeatableSectionValues']

export const getRepeatableSectionHistories = async ({
  worksheetUid,
  fid,
}: {
  worksheetUid: Worksheet['uid']
  fid: string
}): Promise<RepeatableSectionHistories> => {
  try {
    const res = await graphqlRequest(GetRepeatableSectionHistoriesDocument, {
      input: {
        worksheetUid,
        fid,
      },
    })
    return res.repeatableSectionValues
  } catch (error) {
    throw error
  }
}

export const useRepeatableSectionHistories = ({
  shouldCancel = false,
  worksheetUid,
  fid,
  ...options
}: {
  shouldCancel?: boolean
  worksheetUid: Worksheet['uid']
  fid: string
} & SWRConfiguration<RepeatableSectionHistories, GqlError>) => {
  return useSWR<RepeatableSectionHistories, GqlError>(
    shouldCancel
      ? null
      : worksheetCacheKey.getRepeatableSectionHistories(worksheetUid, fid),
    () => getRepeatableSectionHistories({ worksheetUid, fid }),
    options,
  )
}
