import {
  GqlError,
  GetPatientQuery,
  GetPatientDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { patientCacheKey } from '../cacheKey'

export type GetPatientReturn = GetPatientQuery['patient']

export const getPatient = async ({
  patientUid,
}: {
  patientUid: string
}): Promise<GetPatientReturn> => {
  try {
    const res = await graphqlRequest(GetPatientDocument, {
      patientUid,
    })

    return res.patient
  } catch (e) {
    throw e
  }
}

export const usePatient = ({
  patientUid,
  ...options
}: { patientUid: string | null } & SWRConfiguration<
  GetPatientReturn,
  GqlError
>) => {
  return useSWR<GetPatientReturn, GqlError>(
    patientUid === null ? null : patientCacheKey.getPatient(patientUid),
    () => {
      if (patientUid === null) {
        throw new Error('patientUid is null')
      }
      return getPatient({ patientUid })
    },
    options,
  )
}
