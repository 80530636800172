import {
  ExplanationRevision,
  GetExplanationWithdrawHistoriesDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { sortByDate } from 'src/modules/util/sort'
import useSWR from 'swr'

import { WithdrawHistory } from '../../types'
import { explanationCacheKey } from '../cacheKey'
import { parseGqlWithdrawHistory } from '../parser'

export const getExplanationWithdrawHistories = async (
  explanationRevisionUid: ExplanationRevision['uid'],
): Promise<WithdrawHistory[]> => {
  try {
    const res = await graphqlRequest(GetExplanationWithdrawHistoriesDocument, {
      explanationRevisionUid,
    })
    return sortByDate(
      res.explanationRevision.withdrawalHistories.map(parseGqlWithdrawHistory),
      'createdAt',
      'desc',
    )
  } catch (error) {
    throw error
  }
}

export const useExplanationWithdrawnHistories = (
  explanationRevisionUid: ExplanationRevision['uid'],
) => {
  return useSWR<WithdrawHistory[], GqlError>(
    explanationCacheKey.getExplanationWithdrawHistory,
    () => getExplanationWithdrawHistories(explanationRevisionUid),
  )
}
