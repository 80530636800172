// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgDocument = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.5 46.5V13c0-.16.16-.53.27-.65L17.35 1.77c.12-.11.49-.26.65-.27h23.5v45h-35Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M40 3v42H8V13.24L18.24 3H40Zm2-3H18c-.55 0-1.32.32-1.71.71L5.71 11.29C5.32 11.68 5 12.45 5 13v34c0 .55.45 1 1 1h36c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1Z"
    />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M8 14h11c.55 0 1-.45 1-1V3"
    />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M26 14h8M14 20h20M14 26h20M14 32h20M14 38h20"
    />
  </svg>
)
export default SvgDocument
