import { Box } from '@chakra-ui/react'

export const TopScreenContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box px={{ base: '4', lg: '10' }} pt="4" pb="12">
      {children}
    </Box>
  )
}
