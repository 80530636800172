import { Schedule } from './entity'
import { fixtures as questionnaireFixtures } from '../questionnaire/fixture'

export const fixture: Schedule = {
  uid: 'dummy-1',
  title: '回答日１',
  shouldDeliverAfterTrial: false,
  startAfterDays: 1,
  availableFromDays: 2,
  availableUpToDays: 3,
}

export const fixtures: Schedule[] = [
  {
    uid: 'dummy-end',
    title: '試験終了時',
    shouldDeliverAfterTrial: true,
    startAfterDays: 0,
    availableFromDays: 0,
    availableUpToDays: 0,
  },
  {
    uid: 'dummy-1',
    title: '回答日１',
    shouldDeliverAfterTrial: false,
    startAfterDays: 1,
    availableFromDays: 2,
    availableUpToDays: 3,
  },
  {
    uid: 'dummy-2',
    title: '回答日２',
    shouldDeliverAfterTrial: false,
    startAfterDays: 2,
    availableFromDays: 2,
    availableUpToDays: 3,
  },
  {
    uid: 'dummy-3',
    title: '回答日３',
    shouldDeliverAfterTrial: false,
    startAfterDays: 6,
    availableFromDays: 2,
    availableUpToDays: 3,
  },
]

export const getFixtures = (): Schedule[] => {
  const fixtures: Schedule[] = []
  Array.from(Array(6).keys()).forEach(c => {
    const questionnaireUids: string[] = []
    questionnaireFixtures.forEach((questionnaire, i) => {
      if ((i + c) % 2 === 0) questionnaireUids.push(questionnaire.uid)
    })
    fixtures.push({
      uid: `dummy-schedule-${c}`,
      shouldDeliverAfterTrial: false,
      title: `回答日${c + 1}`,
      startAfterDays: c + 1,
      availableFromDays: c + 2,
      availableUpToDays: c + 3,
    })
  })
  return fixtures
}
