import React, { useCallback, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import { Followup } from 'src/components/icon'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

const useTemplateFolderAttribute = () => {
  const [templateFolderName, setTemplateFolderName] = useState<string>('')
  const [isFollowup, setIsFollowup] = useState(false)

  const onChangeTemplateFolderName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTemplateFolderName(e.target.value)
    },
    [],
  )

  const onChangeIsFollowup = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsFollowup(e.target.checked)
    },
    [],
  )

  return {
    templateFolderName,
    onChangeTemplateFolderName,
    isFollowup,
    onChangeIsFollowup,
  }
}

type Props = {
  v2: boolean
  isOpen: boolean
  requesting: boolean
  onClose: () => void
  onSubmit: ({
    name,
    isFollowup,
  }: {
    name: string
    isFollowup: boolean
  }) => void
}

export const CreateModal: React.FC<Props> = props => {
  const {
    templateFolderName,
    onChangeTemplateFolderName,
    isFollowup,
    onChangeIsFollowup,
  } = useTemplateFolderAttribute()

  const canSubmit = !props.requesting && !!templateFolderName

  const v2 = props.v2

  return (
    <Modal size="lg" onClose={props.onClose} isOpen={props.isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>追加フォルダ名入力</ModalHeader>

        <ModalBody>
          <Stack spacing="3">
            <Input
              value={templateFolderName}
              onChange={onChangeTemplateFolderName}
            />
            {v2 && (
              <Checkbox isChecked={isFollowup} onChange={onChangeIsFollowup}>
                <Flex gap="1.5" align="center">
                  <Box color="gray.500">
                    <Followup size="14px" />
                  </Box>
                  随時観察
                </Flex>
              </Checkbox>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            onClick={() =>
              props.onSubmit({ name: templateFolderName, isFollowup })
            }
            isDisabled={!canSubmit}
          >
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
