import _ from 'lodash'
import { definitions } from 'src/lib/api-client/schema.gen'

import { MediumItem } from './entity'
import { parse as parseSmallItem } from '../small-item/request'

export const parse = (
  resp: definitions['output.MediumItemResponse'],
): MediumItem => {
  return {
    uid: resp.uid,
    title: resp.title,
    folderName: resp.folder_name,
    formName: resp.form_name,
    index: resp.index,
    templateUid: resp.template_uid,
    presetUsed: resp.preset_used,
    isLogline: resp.is_logline,

    smallItemMap: resp.small_items
      ? _.keyBy(resp.small_items.map(parseSmallItem), 'uid')
      : {},
  }
}
