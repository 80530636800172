import React from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SubmitModal } from 'src/components/modal/submit'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { actions } from 'src/modules/entities/worksheet/redux'
import { getSingle } from 'src/modules/entities/worksheet/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { RootState } from 'src/modules/reducer'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { DeleteWorksheetParam, submitDelete } from './request'
import { patientIdParamName } from '../../../../worksheet'
import { worksheetIdParamName } from '../../worksheet-detail'

type Props = {
  onClose: () => void
}

export const DeleteWorksheetModalContainer: React.FC<Props> = ({ onClose }) => {
  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  const worksheet = useSelector((state: RootState) =>
    getSingle(state, worksheetUid),
  )

  const { request, requesting, errorMessage } = useDeleteWorksheet({
    trialUid,
    patientUid,
    worksheetUid,
  })
  const { showSuccess } = useFlash()
  const dispatch = useDispatch()

  const onSubmit = async () => {
    await request()
    dispatch(actions.delete({ uid: worksheetUid }))

    onClose()
    showSuccess('ワークシートを削除しました')
  }

  return (
    <SubmitModal
      title={'ワークシートを削除'}
      submitText={'削除'}
      errorMessage={errorMessage || ''}
      requesting={requesting}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Text textAlign="center">
        <Bold>{worksheet.title}</Bold>を削除します。
      </Text>
    </SubmitModal>
  )
}

const useDeleteWorksheet = (param: DeleteWorksheetParam) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()

  const request = async () => {
    try {
      requestStarted()
      await submitDelete(param)
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}

const Bold = styled.span`
  font-weight: bold;
`
