import { GetDocRevisionFileUrlDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { ExplanationDocRevision } from '../../types'
import { explanationCacheKey } from '../cacheKey'

const getDocRevisionFileUrl = async ({
  explanationDocRevisionUid,
}: {
  explanationDocRevisionUid: ExplanationDocRevision['uid']
}): Promise<string | undefined> => {
  try {
    const res = await graphqlRequest(GetDocRevisionFileUrlDocument, {
      explanationDocRevisionUid,
    })
    // DocuSignファイルがあれば返す
    if ('file' in res.explanationDocRevision) {
      if (!!res.explanationDocRevision.file.fileUrl) {
        return res.explanationDocRevision.file.fileUrl
      }
    }

    // 文書管理のファイル
    const icfDocRev = res.explanationDocRevision.icfDocumentRevision
    if ('videoURL' in icfDocRev) {
      return icfDocRev.videoURL ?? undefined
    }
    if ('fileURL' in icfDocRev) {
      return icfDocRev.fileURL ?? undefined
    }
  } catch (error) {
    throw error
  }
}

export const useExplanationDocRevisionFileUrl = ({
  explanationDocRevisionUid,
  ...option
}: {
  explanationDocRevisionUid: ExplanationDocRevision['uid']
} & SWRConfiguration<string | undefined, GqlError>) => {
  return useSWR<string | undefined, GqlError>(
    explanationCacheKey.getExplanationDocRevisionFileUrl(
      explanationDocRevisionUid,
    ),
    () => getDocRevisionFileUrl({ explanationDocRevisionUid }),
    option,
  )
}
