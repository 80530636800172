import { Badge } from '@chakra-ui/react'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import { assertNever } from 'src/utils/assertNever'
import { CSSObject } from 'styled-components'

type ColorVariation = 'blue' | 'green' | 'yellow' | 'gray'

const colorMap: Record<ColorVariation, { bg: number; text: number }> = {
  blue: { bg: 200, text: 700 },
  green: { bg: 200, text: 700 },
  yellow: { bg: 200, text: 700 },
  gray: { bg: 100, text: 400 },
}
const style = (c: ColorVariation): CSSObject => {
  const { bg: bgNumber, text: textNumber } = colorMap[c]
  return {
    minW: '44px',
    h: '18px',
    display: 'grid',
    alignContent: 'center',
    fontSize: '10px',
    lineHeight: '18px',
    textAlign: 'center',
    py: '2px',
    color: `${c}.${textNumber}`,
    backgroundColor: `${c}.${bgNumber}`,
  }
}

export const TemplateStatusBadge: React.FC<{ status: TemplateStatus }> = ({
  status,
}) => {
  switch (status) {
    case TemplateStatus.New:
      return <Badge sx={style('blue')}>新規</Badge>
    case TemplateStatus.Draft:
      return <Badge sx={style('yellow')}>一時保存</Badge>
    case TemplateStatus.Released:
      return <Badge sx={style('green')}>公開済</Badge>
    case TemplateStatus.Disabled:
      return <Badge sx={style('gray')}>無効</Badge>
    default:
      return assertNever(status)
  }
}
