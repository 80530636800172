import {
  GqlError,
  TrialMemberSortOrder,
  TrialMembersParam,
  GetTrialMembersQuery,
  GetTrialMembersDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { memberCacheKey } from '../cacheKey'

export type GetTrialMembersReturn = GetTrialMembersQuery['trialMembers']

const getTrialMembers = async (
  input: TrialMembersParam,
  sortOrder: TrialMemberSortOrder,
): Promise<GetTrialMembersReturn> => {
  try {
    const res = await graphqlRequest(GetTrialMembersDocument, { input })
    return res.trialMembers
  } catch (error) {
    throw error
  }
}

export const useTrialMembers = ({
  input,
  sortOrder,
  ...option
}: {
  input: TrialMembersParam
  sortOrder: TrialMemberSortOrder
} & SWRConfiguration<GetTrialMembersReturn, GqlError>) => {
  return useSWR(
    memberCacheKey.getTrialMembers(input, sortOrder),
    () => getTrialMembers(input, sortOrder),
    option,
  )
}
