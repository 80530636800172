import React from 'react'

import { HStack, Text } from '@chakra-ui/react'
import { Check } from 'src/components/__legacy__icon/monochrome'
import { Button } from 'src/components/base/button/button'
import { green } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { sortByDate } from 'src/modules/util/sort'
import styled from 'styled-components'

import {
  browserRoles,
  ExplanationDocSet,
  ExplanationType,
} from '../../explanation/entity'
import { DocumentListCard } from '../components/document-list-card'
import { ExplanationRoomUserAttributeType } from '../user-attribute-type'

type Props = {
  userAttributeType: ExplanationRoomUserAttributeType
  hospitalName: string
  docSets: ExplanationDocSet[]
  isAgreementDone: boolean
  isDelivered: boolean
  canDelivery: boolean
  canAgreementDone?: boolean
  explanationType?: ExplanationType
  navigateToDocumentPage: (docRevisionUid: string) => void
  onClickAgreementDoneButton?: () => void
  onClickDeliveryDocumentButton?: () => void
}

/** @deprecated top-renewalからimportすること */
export const ExplanationRoomTop: React.FC<Props> = ({
  userAttributeType,
  hospitalName,
  docSets,
  isDelivered,
  canDelivery,
  isAgreementDone,
  canAgreementDone,
  explanationType,
  navigateToDocumentPage,
  onClickAgreementDoneButton,
  onClickDeliveryDocumentButton,
}) => {
  return (
    <Wrapper>
      <HStack align="center" justify="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          {hospitalName}
        </Text>

        <HStack spacing="16px">
          {userAttributeType === 'hospital' && (
            <>
              {isAgreementDone ? (
                <HStack align="center" spacing="4px">
                  <Check size="S" color={green[40]} />
                  <Text fontSize="sm" color={green[40]} fontWeight="bold">
                    同意完了済
                  </Text>
                </HStack>
              ) : (
                <Button
                  size="S"
                  text="同意完了"
                  onClick={onClickAgreementDoneButton}
                  buttonType="notice"
                  disabled={!canAgreementDone}
                />
              )}
            </>
          )}

          {userAttributeType === 'hospital' &&
            !!onClickDeliveryDocumentButton && (
              <>
                {isDelivered ? (
                  <HStack align="center" spacing="4px">
                    <Check size="S" color={green[40]} />
                    <Text fontSize="sm" color={green[40]} fontWeight="bold">
                      文書交付済
                    </Text>
                  </HStack>
                ) : (
                  <Button
                    size="S"
                    text="署名済文書交付"
                    buttonType="notice"
                    disabled={!canDelivery}
                    onClick={onClickDeliveryDocumentButton}
                  />
                )}
              </>
            )}
        </HStack>
      </HStack>
      <Spacer size={40} />

      {docSets.length >= 1 &&
        docSets.map((set, setIndex) => {
          if (set.docs.length === 0) {
            return null
          }
          return (
            <div key={set.uid}>
              <Text fontSize="xl" fontWeight="bold">
                {`${set.docSetNumberingId}. ${set.docSetName}`}
              </Text>
              <Spacer size={16} />
              <CardContainer>
                {set.docs
                  .sort((a, b) => a.index - b.index)
                  .map((doc, docIndex) => {
                    const serialDocIndex =
                      docSets
                        .filter(set => docSets.indexOf(set) < setIndex)
                        .flatMap(set => set.docs).length + docIndex
                    return (
                      <DocumentListCard
                        key={doc.docRevisionUid}
                        userAttributeType={userAttributeType}
                        docRevisionUid={doc.docRevisionUid}
                        order={serialDocIndex + 1}
                        docType={doc.docType}
                        docName={doc.docName}
                        docVersion={doc.docVersion}
                        latestPatientBrowsedAt={
                          sortByDate(
                            doc.browsingHistories,
                            'operatedAt',
                            'desc',
                          ).find(
                            h =>
                              h.browserRole === browserRoles.InPerson ||
                              h.browserRole ===
                                browserRoles.PatientOrRepresentative,
                          )?.operatedAt
                        }
                        explanationType={explanationType}
                        isCompleted={doc.isCompleted}
                        isRejected={
                          doc.signHistories.length > 0 &&
                          doc.signHistories.some(h => h.isRejected)
                        }
                        isSkipped={isAgreementDone && !doc.isCompleted}
                        hasDSEnvelope={doc.hasDSEnvelope}
                        allPlacedSignerRoles={doc.signerRoles}
                        completedSignerRoles={Array.from(
                          new Set(doc.signHistories.map(h => h.signerRole)),
                        )}
                        onClick={navigateToDocumentPage}
                      />
                    )
                  })}
              </CardContainer>
              <Spacer size={40} />
            </div>
          )
        })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  padding: 24px 40px;
  box-sizing: border-box;
`

const CardContainer = styled.div`
  > :not(:last-child) {
    margin-bottom: 16px;
  }
`
