// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgSign = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#777"
      d="M5.75 9.948c0 .1 0 .2.05.25.05.05.1.05.2.05h.05l2.6-.75-2.15-2.15-.75 2.6ZM15.9 2.05 14 .15c-.1-.1-.25-.1-.35 0L7 6.8v.05L9.25 9.1h.05l6.65-6.65c.05-.15.05-.3-.05-.4Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M4.25 11.05c-.3-.65-.8-1.15-1.3-1.45-.8-.45-1.6-.45-2.25 0-.45.35-.65.9-.55 1.5.15.8.8 1.45 1.6 1.7.4.1.95.15 1.7-.15-.2 1.1-1.4 1.9-3.35 2.35l.2 1c3.25-.7 3.95-2.3 4.1-3.2.05-.25.05-.45.05-.7.45-.3.9-.7 1.35-1.25l-.75-.65c-.25.3-.55.6-.8.85Zm-2.2.75c-.5-.15-.8-.55-.9-.9-.05-.15-.05-.4.15-.5.15-.1.3-.15.45-.15.25 0 .5.1.65.2.4.25.75.65.95 1.15-.45.25-.9.3-1.3.2Z"
    />
  </svg>
)
export default SvgSign
