import React, { useMemo, useState } from 'react'

import { HStack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { CalendarComponent } from 'src/components/form-redesigned/calendar'
import { ControlledDateInput } from 'src/components/form-redesigned/date-input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { EProStatus } from 'src/modules/entities/patient/components/epro-status'
import { Patient } from 'src/modules/entities/patient/entity'
import { PatientEproUpdateRequest } from 'src/modules/entities/patient/request'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import { shouldShowDateFormatError } from 'src/modules/util/datetime-utils'
import { zIndex } from 'src/modules/util/z-index'
import styled from 'styled-components'
import Flex from 'styled-flex-component'
import * as yup from 'yup'

import { ConfirmAnswerStateDateButton } from './confirm-answer-state-date/confirm-answer-start-date-button'
import { FinishTrialButton } from './finish-trial/finish-trial-button'
import { PasswordIssueButtonContainer } from './password-issue/password-issue-button-container'
import { useTabs } from './tabs'
import { usePROExportPane } from '../../pro-export-pane'
import { getListRoute } from '../../routes'
import { QuestionnaireAnswerContainer } from '../answer/answer-container'

type Props = {
  trialUid: string
  patient: Patient
  questionnaires: Questionnaire[]
  errorMessage: string
  requesting: boolean
  canEdit: boolean
  showUpdate: boolean
  showIssuePassword: boolean
  showConfirmAnswerStartDate: boolean
  showFinishTrial: boolean
  canExportCSV: boolean
  onSubmitUpdate: (values: PatientEproUpdateRequest) => void
  isValidAnswerStartDate: boolean
}

const validationSchema = yup.object().shape({
  firstVisitDate: yup.string(),
  answerStartDate: yup.string(),
})

type FormValues = yup.InferType<typeof validationSchema>

export const EditPatient: React.FC<Props> = props => {
  const { trialUid, patient, questionnaires, requesting, canEdit } = props
  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid } = methods.formState

  const onSubmitUpdate = () => {
    if (!isValid) {
      return
    }

    const requestValues: PatientEproUpdateRequest = {
      firstVisitDate,
      answerStartDate,
    }
    props.onSubmitUpdate(requestValues)
  }
  const [firstVisitDate, setFirstVisitDate] = useState(patient.firstVisitDate)
  const [answerStartDate, setAnswerStartDate] = useState(
    patient.answerStartDate,
  )
  const [firstVisitDateCalendarOpen, setFirstVisitDateCalendarOpen] =
    useState(false)
  const [answerStartDateCalendarOpen, setAnswerStartDateCalendarOpen] =
    useState(false)
  // TODO: components/tabs で置き換える
  const [selectedTab, renderTabs] = useTabs()

  const isNotReadyForSubmit =
    !patient.isPasswordIssued || !patient.answerStartDate

  const setDateValueByDateObject = (
    date: Date,
    setValue: React.Dispatch<React.SetStateAction<string | null>>,
  ) => {
    setValue(dayjs(date).format('YYYY-MM-DD'))
  }

  const onClickCalendarIcon = (
    calendarDate: string | null,
    setCalendarDate: React.Dispatch<React.SetStateAction<string | null>>,
    calendarOpen: boolean,
    setCalenderOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const [year, month, day] = (calendarDate || '').split('-')

    if (!year || !month || !day) {
      setDateValueByDateObject(new Date(), setCalendarDate)
    }

    setCalenderOpen(!calendarOpen)
  }

  const { paneComponent: proExportPane, open: openPROExportPane } =
    usePROExportPane(trialUid, questionnaires, patient.uid)

  const breadcrumbParams: BreadcrumbParam[] = useMemo(
    () => [
      { label: '患者回答', isLink: true, path: getListRoute(trialUid) },
      { label: `症例番号: ${patient.diseaseUid}`, isLink: false },
    ],
    [trialUid, patient],
  )

  return (
    <Wrapper>
      <HStack justify="space-between">
        <Breadcrumb breadcrumbParams={breadcrumbParams} />
        {selectedTab === 'basic' ? (
          <ButtonWrapper>
            {props.showUpdate && (
              <Button
                text="一時保存"
                size="S"
                buttonType="normal"
                disabled={!isValid && requesting}
                onClick={methods.handleSubmit(onSubmitUpdate)}
              />
            )}
            {props.showConfirmAnswerStartDate && (
              <ConfirmAnswerStateDateButton disabled={isNotReadyForSubmit} />
            )}
            {props.showFinishTrial && <FinishTrialButton />}
          </ButtonWrapper>
        ) : (
          <>
            {props.canExportCSV && (
              <Button
                text="データ出力"
                buttonType="important"
                size="S"
                onClick={openPROExportPane}
              />
            )}
          </>
        )}
      </HStack>
      <TabWrapper>{renderTabs()}</TabWrapper>
      <ContentWrapper>
        {selectedTab === 'basic' ? (
          <>
            <FormProvider {...methods}>
              <Content>
                <Row>
                  <LabelArea>
                    <Text fontWeight="bold">ステータス</Text>
                  </LabelArea>
                  <EProStatus patient={patient} />
                </Row>
                <Row>
                  <LabelArea>
                    <Label bold={true} required={canEdit}>
                      パスワード
                    </Label>
                  </LabelArea>
                  <Text fontSize="sm">
                    {patient.isPasswordIssued ? '発行済み' : '未発行'}
                  </Text>
                  {props.showIssuePassword && (
                    <>
                      <Spacer size={16} horizontal />
                      <PasswordIssueButtonContainer />
                    </>
                  )}
                </Row>
                <Row>
                  <LabelArea>
                    <Text fontWeight="bold">初回来院日</Text>
                  </LabelArea>
                  <InputArea>
                    {canEdit ? (
                      <Flex alignCenter>
                        <ControlledDateInput
                          date={firstVisitDate || ''}
                          disabled={!canEdit}
                          onChange={v => setFirstVisitDate(v)}
                          showWarningYear={true}
                          showWarningMonth={true}
                          showWarningDay={true}
                          allowAlphabet={false}
                        />
                        <IconButton
                          iconName="calendar"
                          onClick={() =>
                            onClickCalendarIcon(
                              firstVisitDate,
                              setFirstVisitDate,
                              firstVisitDateCalendarOpen,
                              setFirstVisitDateCalendarOpen,
                            )
                          }
                        />
                      </Flex>
                    ) : (
                      <Text>
                        {firstVisitDate
                          ? dayjs(firstVisitDate).format('YYYY/MM/DD')
                          : ''}
                      </Text>
                    )}
                    {firstVisitDateCalendarOpen && (
                      <CalendarContainer>
                        <CalendarComponent
                          onClose={() => setFirstVisitDateCalendarOpen(false)}
                          onChange={date =>
                            setDateValueByDateObject(date, setFirstVisitDate)
                          }
                        />
                      </CalendarContainer>
                    )}
                    {shouldShowDateFormatError(firstVisitDate) && (
                      <ErrorMessage>日付の入力が不正です</ErrorMessage>
                    )}
                  </InputArea>
                </Row>
                <Row>
                  <LabelArea>
                    <Label bold={true} required={canEdit}>
                      試験開始日
                    </Label>
                  </LabelArea>
                  <InputArea>
                    {canEdit ? (
                      <Flex alignCenter>
                        <ControlledDateInput
                          date={answerStartDate || ''}
                          onChange={v => setAnswerStartDate(v)}
                          showWarningYear={true}
                          showWarningMonth={true}
                          showWarningDay={true}
                          allowAlphabet={false}
                        />
                        <IconButton
                          iconName="calendar"
                          onClick={() =>
                            onClickCalendarIcon(
                              answerStartDate,
                              setAnswerStartDate,
                              answerStartDateCalendarOpen,
                              setAnswerStartDateCalendarOpen,
                            )
                          }
                        />
                      </Flex>
                    ) : (
                      <Text>
                        {answerStartDate
                          ? dayjs(answerStartDate).format('YYYY/MM/DD')
                          : ''}
                      </Text>
                    )}
                    {answerStartDateCalendarOpen && (
                      <CalendarContainer>
                        <CalendarComponent
                          onClose={() => setAnswerStartDateCalendarOpen(false)}
                          onChange={date =>
                            setDateValueByDateObject(date, setAnswerStartDate)
                          }
                        />
                      </CalendarContainer>
                    )}
                    {shouldShowDateFormatError(answerStartDate) && (
                      <ErrorMessage>日付の入力が不正です</ErrorMessage>
                    )}
                    {canEdit && props.isValidAnswerStartDate && (
                      <NoticeMessage>
                        試験を開始する場合、確定ボタンを押してください。確定すると試験を開始します。
                      </NoticeMessage>
                    )}
                  </InputArea>
                </Row>
              </Content>
            </FormProvider>
          </>
        ) : (
          <QuestionnaireAnswerContainer />
        )}
      </ContentWrapper>
      {proExportPane}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 16px 36px;
  box-sizing: border-box;
`

const ContentWrapper = styled.div`
  margin-top: 54px;
  padding-right: 4px;
  overflow-y: auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 18px;
`

const TabWrapper = styled.div`
  margin-top: 68px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const LabelArea = styled.div`
  width: 160px;
`

const InputArea = styled.div`
  width: 235px;
`

const CalendarContainer = styled.div`
  position: absolute;
  top: 230px;
  z-index: ${zIndex.calendarComponent};
  width: 300px;
  height: 300px;
`

const ErrorMessage = styled.div`
  margin-top: 4px;
  font-size: 13px;
  color: ${red[50]};
`

const NoticeMessage = styled.div`
  margin-top: 4px;
  font-size: 13px;
  color: ${red[50]};
  white-space: nowrap;
`
