import { definitions } from 'src/lib/api-client/schema.gen'
import { assertNever } from 'src/utils/assertNever'

export const getVerifyPinErrorMessage = (
  result: definitions['output.VerifyPinResult'],
) => {
  switch (result) {
    case 'Success':
      return undefined
    case 'Failure':
      return '暗証番号が誤っています。再度ご入力ください。'
    case 'Locked':
      return `誤った暗証番号を連続で5回入力されました。\n医療機関へお問い合わせの上、暗証番号の再設定が必要です。`
    case 'SettingNotDone':
      return '暗証番号の設定がされていません。'
    case 'AlreadyLocked':
      return '暗証番号の再設定が必要です。'

    default:
      return assertNever(result)
  }
}
