import { useRoomSession } from '@micin-jp/call-lib-react'
import { useNavigate } from 'react-router-dom'
import { ExplanationSession } from 'src/features/explanation/types'
import { getRoomFinishedRoute } from 'src/features/explanation/utils/getRoomFinishedRoute'

import { getNotFoundRoute } from './routes'
import { useExplanationSession } from '../../../../../../features/explanation/api'

export const usePatientSession = ({
  explanationSessionUid,
}: {
  explanationSessionUid: ExplanationSession['uid']
}) => {
  const navigate = useNavigate()

  const { leaveRoom } = useRoomSession()

  const { data: session, mutate: mutateSession } = useExplanationSession({
    explanationSessionUid,
    forPatient: true,
    refreshInterval: 5000,
    onSuccess: session => {
      if (session.explanationRevision.status === 'Disabled') {
        navigate(getNotFoundRoute({ type: 'patient' }))
      }
    },
    onError: async error => {
      if (error.status === 400) {
        leaveRoom().then()
        navigate(getRoomFinishedRoute())
        return
      }
      navigate(getNotFoundRoute({ type: 'patient' }))
    },
  })

  return {
    session,
    mutateSession,
  }
}
