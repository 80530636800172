import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  EditPatientDocument,
  GqlError,
  UpdatePatientInput,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const editPatient = async (input: UpdatePatientInput): Promise<void> => {
  try {
    await graphqlRequest(EditPatientDocument, { input })
  } catch (error) {
    throw error
  }
}

export const useEditPatient = (option?: UseMutationOption<void, GqlError>) => {
  return useMutation((input: UpdatePatientInput) => editPatient(input), option)
}
