import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  SendAgreementFormEnvelopeEmailDocument,
  SendCheckUnderstandingEnvelopeEmailDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import {
  ExplanationDocRevision,
  ExplanationSession,
  ExplanationSignerRole,
} from '../../types'

const sendEnvelopeEmail = async ({
  type,
  explanationDocRevisionUid,
  explanationSessionUid,
  signerRoles,
}: {
  type: 'AgreementForm' | 'CheckUnderstanding'
  explanationDocRevisionUid: ExplanationDocRevision['uid']
  explanationSessionUid: ExplanationSession['uid']
  signerRoles: ExplanationSignerRole[]
}): Promise<void> => {
  try {
    type === 'AgreementForm'
      ? await graphqlRequest(SendAgreementFormEnvelopeEmailDocument, {
          input: {
            explanationDocRevisionUid,
            explanationSessionUid,
            signerRoles,
          },
        })
      : await graphqlRequest(SendCheckUnderstandingEnvelopeEmailDocument, {
          input: {
            explanationDocRevisionUid,
            explanationSessionUid,
            signerRoles,
          },
        })
  } catch (error) {
    throw error
  }
}

export const useSendEnvelopeEmail = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      type,
      explanationDocRevisionUid,
      explanationSessionUid,
      signerRoles,
    }: {
      type: 'AgreementForm' | 'CheckUnderstanding'
      explanationDocRevisionUid: ExplanationDocRevision['uid']
      explanationSessionUid: ExplanationSession['uid']
      signerRoles: ExplanationSignerRole[]
    }) =>
      sendEnvelopeEmail({
        type,
        explanationDocRevisionUid,
        explanationSessionUid,
        signerRoles,
      }),
    option,
  )
}
