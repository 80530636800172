import React, { useCallback, useMemo, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { AccountBlue } from 'src/components/__legacy__icon/multicolor'
import { PullDown, PullDownItem } from 'src/components/layout/pulldown'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { trialIdParamName } from '../../../epro-page'
import { logout } from '../../request'
import { getLoginRoute } from '../../routes'

const useOpenPulldown = () => {
  const [pulldownOpen, setPulldownOpen] = useState(false)
  const handlers = useMemo(
    () => ({
      openPulldown: () => {
        setPulldownOpen(true)
      },
      closePulldown: () => {
        setPulldownOpen(false)
      },
    }),
    [],
  )

  return { pulldownOpen, handlers }
}

export const PulldownContainer: React.FC = () => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()

  const {
    pulldownOpen,
    handlers: { openPulldown, closePulldown },
  } = useOpenPulldown()

  const navigate = useNavigate()
  const { requestStarted, requestDone, requestFailed } = useRequestState()

  const onLogout = useCallback(async () => {
    try {
      requestStarted()
      await logout(trialUid)
      navigate(getLoginRoute({ trialUid }))
    } catch (e) {
      requestFailed(e.message)
    } finally {
      requestDone()
    }
  }, [navigate, requestDone, requestFailed, requestStarted, trialUid])

  const pulldownItems: PullDownItem[] = [
    {
      name: 'ログアウト',
      onClick: onLogout,
    },
  ]

  return (
    <PullDown
      closePulldown={closePulldown}
      items={pulldownItems}
      pulldownOpen={pulldownOpen}
    >
      <Button onClick={openPulldown}>
        <AccountBlue size="L" />
      </Button>
    </PullDown>
  )
}

const Button = styled.button`
  all: unset;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
`
