import { Role } from 'src/modules/entities/member/entity'
import { TrialSelection } from 'src/modules/entities/select-trial/entity'

//Mock動作用のパラメータ（動作確認の際に任意の値に変更すること）
export const trialFixture: TrialSelection[] = [
  {
    uid: 'ch000',
    name: '治験A',
    role: Role.DM,
    trialHospitals: [
      {
        uid: 'ho001',
        name: '病院A',
      },
      {
        uid: 'ho003',
        name: '病院B',
      },
    ],
  },
  {
    uid: 'ch001',
    name: '治験B',
    role: Role.Dr,
    trialHospitals: [
      {
        uid: 'ho003',
        name: '病院C',
      },
      {
        uid: 'ho004',
        name: '病院D',
      },
    ],
  },
  {
    uid: 'ch002',
    name: '治験C',
    role: Role.Dr,
    trialHospitals: [
      {
        uid: 'ho005',
        name: '病院E',
      },
    ],
  },
  {
    uid: 'ch003',
    name: '治験D',
    role: Role.CRA,
    trialHospitals: [
      {
        uid: 'ho006',
        name: '病院F',
      },
    ],
  },
  {
    uid: 'ch004',
    name: '治験E',
    role: Role.Dr,
    trialHospitals: [
      {
        uid: 'ho007',
        name: '病院G',
      },
      {
        uid: 'ho008',
        name: '病院H',
      },
    ],
  },
  {
    uid: 'ch004',
    name: '治験F',
    role: Role.Dr,
    trialHospitals: [
      {
        uid: 'ho009',
        name: '病院I',
      },
    ],
  },
  {
    uid: 'ch005',
    name: '治験G',
    role: Role.Dr,
    trialHospitals: [
      {
        uid: 'ho010',
        name: '病院J',
      },
    ],
  },
  {
    uid: 'ch06',
    name: '治験H',
    role: Role.Dr,
    trialHospitals: [
      {
        uid: 'ho011',
        name: '病院K',
      },
      {
        uid: 'ho012',
        name: '病院L',
      },
      {
        uid: 'ho013',
        name: '病院M',
      },
      {
        uid: 'ho014',
        name: '病院N',
      },
      {
        uid: 'ho015',
        name: '病院O',
      },
      {
        uid: 'ho016',
        name: '病院P',
      },
      {
        uid: 'ho017',
        name: '病院Q',
      },
      {
        uid: 'ho018',
        name: '病院R',
      },
      {
        uid: 'ho019',
        name: '病院S',
      },
    ],
  },
]

export const getTrialSelections = () => {
  return trialFixture
  // return trialFixture2
  // return trialFixture3
  // return trialFixture4
}

export const selectedTrial = () => {
  //以下の2つの値は動作確認の内容に応じて適宜書き換えること
  //0未満の値を指定すると未選択扱いになる
  const trialNo = 0
  const hospitalNo = 0

  const currentSelections = getTrialSelections()

  const role = currentSelections[trialNo].role

  if (role === Role.DM) {
    return {
      uid: trialNo < 0 ? '' : currentSelections[trialNo].uid,
      name: trialNo < 0 ? '' : currentSelections[trialNo].name,
      hospitalUid: '',
      hospitalName: '',
    }
  } else {
    return {
      uid: trialNo < 0 ? '' : currentSelections[trialNo].uid,
      name: trialNo < 0 ? '' : currentSelections[trialNo].name,
      hospitalUid:
        hospitalNo < 0
          ? ''
          : currentSelections[trialNo].trialHospitals[hospitalNo].uid,
      hospitalName:
        hospitalNo < 0
          ? ''
          : currentSelections[trialNo].trialHospitals[hospitalNo].name,
    }
  }
}
