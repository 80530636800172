import React from 'react'

import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { RightArrow } from 'src/components/__legacy__icon/monochrome'
import { gray, green, red } from 'src/components/base/color/palette'
import { Modal } from 'src/components/base/modal/modal'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import { SmallItemLog, LogType } from '../entity'

type Props = {
  title: string
  logs: SmallItemLog[]
  closeModal: () => void
}

export const SmallItemLogModal: React.FC<Props> = props => {
  const { title, logs, closeModal } = props

  return (
    <Modal onClose={closeModal} size="L">
      <ModalTitle title={title} />

      <ModalContent>
        {logs.map(log => (
          <Content key={log.uid}>
            <Spacer size={40} />
            <Head>
              <Text>{dayjs(log.createdAt).format('YYYY年MM月DD日 HH:mm')}</Text>
              <span>・</span>
              <Text>アカウント：{log.userName}</Text>
            </Head>

            <Spacer size={10} />

            {log.logType === LogType.Change && (
              <>
                <Text>変更理由：{log.reason}</Text>
                <Text>{log.title}</Text>

                <Spacer size={10} />

                <Body>
                  <Item>
                    <Text textAlign="center">【変更前】</Text>
                    <Text color={red[30]}>
                      <LineThrough>{log.oldValue}</LineThrough>
                    </Text>
                  </Item>
                  <RightArrow />
                  <Item>
                    <Text textAlign="center">【変更後】</Text>
                    <Text color={green[40]}>{log.newValue}</Text>
                  </Item>
                </Body>
              </>
            )}

            {log.logType === LogType.Confirm && (
              <>
                <Body>
                  <Item>
                    <Text textAlign="center">【確定】</Text>
                    <Text> {log.newValue}</Text>
                  </Item>
                </Body>
              </>
            )}
          </Content>
        ))}
      </ModalContent>
    </Modal>
  )
}

const Content = styled.div`
  width: 100%;
`

const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Item = styled.div`
  width: 45%;
  min-height: 74px;
  padding: 12px 24px;
  box-sizing: border-box;
  background: ${gray[5]};
  border-radius: 3px;
  white-space: pre-wrap;
`

const LineThrough = styled.span`
  text-decoration-line: line-through;
`
