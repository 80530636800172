export type Hospital = {
  uid: string
  name: string
}

export type TrialSelection = {
  uid: string
  name: string
  role: number
  trialHospitals: Hospital[]
}

export enum ScreenPhase {
  Trial = 0,
  Hospital,
}
