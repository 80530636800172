import { GetWorksheetDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { Worksheet, WorksheetDetail } from '../../types'
import { worksheetCacheKey } from '../cacheKey'
import { parseGqlWorksheetResponse } from '../parser'

const getWorksheet = async ({
  worksheetUid,
}: {
  worksheetUid: Worksheet['uid']
}): Promise<WorksheetDetail> => {
  try {
    const res = await graphqlRequest(GetWorksheetDocument, {
      worksheetUid,
    })
    return parseGqlWorksheetResponse(res.worksheet)
  } catch (e) {
    throw e
  }
}

export const useWorksheet = ({
  worksheetUid,
  ...options
}: {
  worksheetUid: Worksheet['uid']
} & SWRConfiguration<WorksheetDetail, GqlError>) => {
  return useSWR<WorksheetDetail, GqlError>(
    worksheetCacheKey.getWorksheet(worksheetUid),
    () => getWorksheet({ worksheetUid }),
    options,
  )
}
