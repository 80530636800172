import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { colors } from 'src/assets/colors'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { NewPasswordInput } from 'src/modules/auth/common/new-password-input'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'
import * as yup from 'yup'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.bgGray};
`

const ContentContainerForm = styled.form`
  width: 100%;
  max-width: 1024px;
  margin: 80px;
  padding: 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(133, 141, 168, 0.06);
  border-radius: 10px;
`

const FormItem = styled.div`
  width: 420px;
  margin: 0 auto 20px;
`

const Head = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  margin: 0 0 10px;
`

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
  margin: 0 0 30px;
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;

  > button {
    margin-right: 20px;
  }
`

interface IProps {
  next: () => void
  userId: string
}

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup.string().required(),
  verificationCode: yup.string().required(),
})

type IFormValues = yup.InferType<typeof validationSchema>

export const ForgetPasswordForm: React.FC<IProps> = ({ next, userId }) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { register, handleSubmit, formState } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, errors, touchedFields } = formState

  const onSubmit = async ({
    password,
    passwordConfirmation,
    verificationCode,
  }: IFormValues) => {
    if (password !== passwordConfirmation) {
      requestFailed('パスワードが一致しません。')
      return
    }

    try {
      requestStarted()
      await Auth.forgotPasswordSubmit(userId, verificationCode, password)
      next()
    } catch (error) {
      switch (error.code) {
        case 'CodeMismatchException':
          requestFailed('正しい確認コードをご入力下さい。')
          break
        case 'ExpiredCodeException':
          requestFailed(`
            確認コードの有効期限が切れております。
            再度確認コードの送信をお願い致します。
          `)
          break
        case 'InvalidParameterException':
          requestFailed('適切な形式のパスワードを入力下さい。')
          break
        case 'InvalidPasswordException':
          requestFailed(`
            半角英字（大文字、小文字をそれぞれ1文字以上ずつ）含む、
            8文字以上の半角英数字記号を入力してください。
          `)
          break
        case 'LimitExceededException':
          requestFailed(
            'アカウントがロックされています。時間を置いて再度お試し下さい。',
          )
          break
        default:
          requestFailed('問題が発生しました。時間を置いて再度お試し下さい。')
      }
    } finally {
      requestDone()
    }
  }

  return (
    <PageContainer>
      <ContentContainerForm onSubmit={handleSubmit(onSubmit)}>
        <Head>
          MiROHA
          <br />
          パスワードリセット
        </Head>
        <Text>
          登録メールアドレスに確認コードを送信しました。
          <br />
          メール本文の確認コードを入力し、新しいパスワードを設定してください。
        </Text>

        <FormItem>
          <Label bold>確認コード</Label>
          <Spacer size={10} />
          <Input type="text" width={0.5} {...register('verificationCode')} />
          {errors.verificationCode && touchedFields.verificationCode && (
            <div>
              <Spacer size={4} />
              <Message type="error" message="確認コードを入力してください" />
            </div>
          )}
        </FormItem>

        <FormItem>
          <NewPasswordInput
            register={register}
            isValid={!(errors.password && touchedFields.password)}
          />
        </FormItem>

        <FormItem>
          <Label bold>新しいパスワード（確認用）</Label>
          <Spacer size={10} />
          <Input
            type="password"
            placeholder={'新しいパスワード（確認用）'}
            width={1}
            {...register('passwordConfirmation')}
          />
          {errors.passwordConfirmation &&
            touchedFields.passwordConfirmation && (
              <div>
                <Spacer size={4} />
                <Message type="error" message="パスワードを入力してください" />
              </div>
            )}
        </FormItem>

        {errorMessage && (
          <div>
            <Message type="error" message={errorMessage} centered />
            <Spacer size={20} />
          </div>
        )}

        <BtnContainer>
          <Button
            size="S"
            text="リセット"
            onClick={() => handleSubmit(onSubmit)}
            buttonType="important"
            disabled={!isValid || requesting}
          ></Button>
        </BtnContainer>
      </ContentContainerForm>
    </PageContainer>
  )
}
