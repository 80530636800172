import { generatePath, useLocation, useParams } from 'react-router-dom'
import { Paths, RoomEventUidQueryKey } from 'src/constants/paths'

import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForPatient } from '../../hooks/useFloatingVideoCallForPatient'
import { useThisSession } from '../../hooks/useThisSession'
import { PatientLayout } from '../PatientLayout/PatientLayout'
import { SignCompletedContent } from '../SignCompletedContent/SignCompletedContent'

type Param = {
  trialUid: string
  sessionUid: string
  signerActorUid: string
}

export const PatientSignCompletedScreen: React.FC = () => {
  const { trialUid = '', sessionUid = '' } = useParams<Param>()
  const thisSession = useThisSession({ sessionUid, forPatient: true })

  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const { renderFloatingVideoCall } = useFloatingVideoCallForPatient({
    sessionUid,
  })

  if (!thisSession.fetched) return null

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      sessionUid={sessionUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.patient.trialHospitalUid}
    >
      <PatientLayout
        trialUid={trialUid}
        sessionUid={sessionUid}
        showSidebar={false}
      >
        <SignCompletedContent
          isPatient
          sessionUid={sessionUid}
          requestSignRoomEventUid={searchParams.get(RoomEventUidQueryKey) ?? ''}
          roomTopPath={generatePath(Paths.PatientExplanationRoomTop, {
            trialUid,
            sessionUid,
          })}
          signPath={requestSign => {
            return (
              generatePath(Paths.PatientExplanationRoomDocumentSign, {
                trialUid,
                sessionUid: requestSign.sessionUid,
                docRevisionUid: requestSign.docRevUid,
                signerActorUid: requestSign.signerActorUid,
              }) +
              `?${RoomEventUidQueryKey}=${requestSign.explanationRoomEventUid}`
            )
          }}
        />
        {renderFloatingVideoCall()}
      </PatientLayout>
    </ExplanationRoomEventProvider>
  )
}
