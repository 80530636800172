import { Button } from '@chakra-ui/react'
import { useAtomValue, useSetAtom } from 'jotai'

import {
  criteriaValueErrorsAtom,
  editingCriteriaValuesAtom,
  fetchedCriteriaValuesAtom,
  isReEditModeAtom,
} from '../../atom'

export const CancelReEditButton: React.FC = () => {
  const fetchedValues = useAtomValue(fetchedCriteriaValuesAtom)
  const setEditingValues = useSetAtom(editingCriteriaValuesAtom)
  const setIsReEditMode = useSetAtom(isReEditModeAtom)
  const setErrors = useSetAtom(criteriaValueErrorsAtom)

  return (
    <Button
      variant="outline"
      onClick={() => {
        setIsReEditMode(false)
        // 編集中の値を保存済みの値に戻す
        setEditingValues(fetchedValues)
        setErrors({ validated: false })
      }}
    >
      キャンセル
    </Button>
  )
}
