import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { RootState } from 'src/modules/reducer'

import { EnableButton } from './components/button'
import { actions } from './redux'

type Props = {
  diseaseUid: string
  patientUid: string
  trialUid: string
  worksheetUid: string
}

export const Container: React.FC<Props> = props => {
  const { errorMessage, requesting } = useSelector(
    (state: RootState) => state.patient.enable,
  )

  const [modalOpen, setModalOpen] = useState(false)
  const { diseaseUid, patientUid, trialUid, worksheetUid } = props
  const [inputValue, changeInputValue] = useState('')
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()

  const onSubmit = async () => {
    if (requesting) return
    try {
      await dispatch(
        actions.submit({
          trialUid: trialUid,
          patientUid: patientUid,
          inputValue: inputValue,
        }),
      )
      showSuccess(
        '患者を有効化しました。\nワークシートはすべて無効化されているため、有効化作業が必要です。',
        { durationMsec: 30000, closable: true },
      )
      setModalOpen(false)
    } catch (error) {
      console.error('patient enable onSubmit Error: ', error)
    }
  }

  return (
    <EnableButton
      diseaseUid={diseaseUid}
      errorMessage={errorMessage}
      requesting={requesting}
      modalOpen={modalOpen}
      onSubmit={onSubmit}
      inputValue={inputValue}
      worksheetUid={worksheetUid}
      changeInputValue={e => changeInputValue(e.target.value)}
      showModal={() => setModalOpen(true)}
      closeModal={() => setModalOpen(false)}
    />
  )
}
