import React from 'react'

import { Grid, GridItem } from '@chakra-ui/react'
import { Header } from 'src/features/misc/components/Header/Header'
import { LegacyNavigation } from 'src/features/misc/components/LegacyNavigation/LegacyNavigation'

type Props = {
  children: React.ReactNode
}

export const LegacyMainLayout: React.FC<Props> = ({ children }) => {
  return (
    <Grid
      h="100svh"
      w="100svw"
      templateRows="auto 1fr"
      templateColumns="auto 1fr"
    >
      <GridItem gridColumn="1 / -1">
        <Header />
      </GridItem>
      <GridItem gridRow="2">
        <LegacyNavigation />
      </GridItem>
      <GridItem overflow="auto" gridRow="2" gridColumn="2">
        {children}
      </GridItem>
    </Grid>
  )
}
