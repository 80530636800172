import React, { useState, useEffect } from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { MultipleSelectboxItem } from 'src/components/form-redesigned/multiple-selectbox'
import { MultiselectFilter } from 'src/components/form-redesigned/multiselect-filter'
import { Selectbox } from 'src/components/form-redesigned/selectbox'
import { Spacer } from 'src/components/spacer/spacer'
import { shouldSelectHospital } from 'src/modules/dashboard/trial/detail/common/permission'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import { Role } from 'src/modules/entities/member/entity'
import { actions as memberActions } from 'src/modules/entities/member/redux'
import { roleOptions } from 'src/modules/entities/member/util'
import { actions as flashActions } from 'src/modules/flash/redux'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'
import * as yup from 'yup'

import { submitCreate, LookupUser } from './request'
import { useReactHookFormDevTools } from '../../../../../../hooks/use-react-hook-form-dev-tools'

const validationSchema = yup.object().shape({
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  email: yup.string().required(),
  role: yup
    .mixed<Role>()
    .oneOf(Object.values(Role) as Role[])
    .required(),
  hospitals: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    )
    .required(),
})

export type FormValues = yup.InferType<typeof validationSchema>

type Props = {
  onSubmit: (values: FormValues) => void
  onClose: () => void
  selectedTrial: SelectedTrial
  email: string
  existingUser?: LookupUser
}

export const AddForm: React.FC<Props> = props => {
  const { selectedTrial, existingUser } = props
  const [hasHospitalError, setHasHospitalError] = useState<boolean>(false)
  const { request, requesting, errorMessage } = useSubmitCreate()

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      lastName: existingUser?.lastName,
      firstName: existingUser?.firstName,
      email: '',
      hospitals: [],
      role: roleOptions[0].value,
    },
  })
  const { isValid, touchedFields, errors } = methods.formState
  const { register, setValue, setError, trigger, getValues, control, watch } =
    methods
  const devToolElement = useReactHookFormDevTools(control)
  const dispatch = useDispatch()

  // API の返り値を使用するため、useEffect のなかで setValue する。
  useEffect(() => {
    setValue('email', props.email)
    if (!!existingUser) {
      setValue('firstName', existingUser.firstName)
      setValue('lastName', existingUser.lastName)
    }
    trigger()
  }, [existingUser, props.email, setValue, trigger])

  const isHospitalSelectReady = shouldSelectHospital(getValues().role)
    ? !!getValues().hospitals && getValues().hospitals.length !== 0
    : true

  const onSubmit = async (value: FormValues) => {
    if (!isHospitalSelectReady) {
      setHasHospitalError(true)
      return
    }

    if (!isValid || requesting) {
      return
    }

    const hospitals = !getValues().hospitals ? [] : getValues().hospitals

    const values: FormValues = {
      ...value,
      hospitals,
    }

    try {
      await request({ trialUid: selectedTrial.uid, values })
      await dispatch(
        memberActions.fetchList({ trialUid: selectedTrial.uid, type: 'all' }),
      )

      onClose()
    } catch (error) {
      console.warn(error)
    }
  }

  const onClose = () => {
    if (requesting) return

    props.onClose()
  }

  const shouldShowError = (...fields: Array<keyof FormValues>) =>
    fields.some(f => errors[f]) && fields.every(f => !!touchedFields[f])

  const convertedItems: MultipleSelectboxItem[] =
    selectedTrial.trialHospitals.map(hospital => {
      return {
        value: hospital.uid,
        label: hospital.name,
      }
    })

  return (
    <div>
      {devToolElement}
      <ModalTitle title={selectedTrial.name} />

      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <ModalContent>
          {!!existingUser && (
            <div>
              <Message
                type="information"
                message="システム上にユーザーが存在するため氏名を自動入力しました"
                centered
              />
              <Spacer size={20} />
            </div>
          )}
          <Text textAlign="center">追加するアカウント情報を入力</Text>
          <FormContainer>
            <div>
              <Spacer size={20} />
              <Label>氏名</Label>
              <Spacer size={10} />
              <Input
                placeholder="姓"
                width={'150px'}
                mr={'20px'}
                {...register('lastName')}
              ></Input>
              <Input
                placeholder="名"
                width={'150px'}
                {...register('firstName')}
              ></Input>
              {shouldShowError('firstName', 'lastName') && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message="氏名の入力が必要です" />
                </div>
              )}
            </div>
            <div>
              <Spacer size={20} />
              <Label>メールアドレス</Label>
              <Spacer size={10} />
              <Input
                type="email"
                width={'320px'}
                readOnly={true}
                {...register('email')}
              ></Input>
              {shouldShowError('email') && (
                <div>
                  <Spacer size={4} />
                  <Message
                    type="error"
                    message="メールアドレスの入力が必要です"
                  />
                </div>
              )}
            </div>
          </FormContainer>

          <FormContainer>
            <div>
              <Spacer size={20} />
              <Label>ロール</Label>
              <Spacer size={10} />
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <Selectbox<Role>
                      items={roleOptions}
                      selectedValue={watch('role')}
                      name="role"
                      onChange={value => {
                        if (value === undefined || value === null) {
                          setError('role', { type: 'required' })
                        }
                        onChange(Number(value))
                      }}
                      width={320}
                    />
                  )
                }}
              ></Controller>
              {shouldShowError('role') && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message="ロールの選択が必要です" />
                </div>
              )}
            </div>
          </FormContainer>

          <FormContainer>
            <div>
              <Spacer size={20} />
              <Label>医療機関</Label>
              <Spacer size={10} />
              <Controller
                name="hospitals"
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <MultiselectFilter
                      // MultipleSelectboxItem から MultiselectFilter の内部のデータ形式に変換
                      items={selectedTrial.trialHospitals.map(hospital => {
                        return {
                          id: hospital.uid,
                          value: hospital.name,
                        }
                      })}
                      selectedItemIds={watch('hospitals').map(hospital => {
                        return hospital.value
                      })}
                      onChange={ids => {
                        onChange(
                          // MultiselectFilter の内部のデータ形式 から MultipleSelectboxItem に変換
                          convertedItems.filter(item => {
                            return ids.includes(item.value)
                          }),
                        )

                        if (!shouldSelectHospital(watch('role'))) {
                          setHasHospitalError(false)
                        } else if (
                          /* 病院が１つ以上選択されているとき */
                          ids &&
                          Array.isArray(ids) &&
                          ids.length !== 0
                        ) {
                          setHasHospitalError(false)
                        } else {
                          setHasHospitalError(true)
                        }
                      }}
                    ></MultiselectFilter>
                  )
                }}
              ></Controller>
              {
                // TODO(nekootoko3): use shouldShowError
                hasHospitalError && (
                  <div>
                    <Spacer size={4} />
                    <Message type="error" message="医療機関の選択が必要です" />
                  </div>
                )
              }
            </div>
          </FormContainer>
        </ModalContent>

        <ModalActions>
          <Button
            size="S"
            text="キャンセル"
            buttonType="cancel"
            onClick={onClose}
          ></Button>
          <Spacer size={40} horizontal />
          <Button
            size="S"
            text="招待メールを送信"
            buttonType="important"
            onClick={() => methods.handleSubmit(onSubmit)}
            disabled={!isValid || requesting || !isHospitalSelectReady}
          ></Button>
        </ModalActions>
        {errorMessage && (
          <Message type="error" message={errorMessage} centered />
        )}
      </form>
    </div>
  )
}

const useSubmitCreate = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const dispatch = useDispatch()

  const request = async ({
    trialUid,
    values,
  }: {
    trialUid: string
    values: FormValues
  }) => {
    try {
      requestStarted()
      const created = await submitCreate({ trialUid, values })
      dispatch(memberActions.upsert(created))
      const message = '招待メールを送信しました'
      dispatch(flashActions.showSuccess({ message }))
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
