import React, { useCallback } from 'react'

import { useFormContext, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'

import { Input } from './input'

type DateKey = 'year' | 'month' | 'day'

type ContainerProps = {
  date: string
  disabled?: boolean
  className?: string
  showWarningYear: boolean
  showWarningMonth: boolean
  showWarningDay: boolean
  onChange: (value: string) => void
  allowAlphabet: boolean
  readOnly?: boolean
}

type RefProps = {
  yearName: string
  monthName: string
  dayName: string
  className?: string
}

type Props = {
  year?: string | number
  month?: string | number
  day?: string | number
  yearName?: string
  monthName?: string
  dayName?: string
  register?: UseFormRegister<any>
  disabled?: boolean
  className?: string
  showWarningYear?: boolean
  showWarningMonth?: boolean
  showWarningDay?: boolean
  onChange?: (key: DateKey, value: string) => void
  controlled: boolean
  readOnly?: boolean
}

const Component: React.FC<Props> = props => {
  const { year, month, day } = props

  const rhfYearProps =
    props.register && props.yearName ? props.register(props.yearName) : {}
  const rhfMonthProps =
    props.register && props.monthName ? props.register(props.monthName) : {}
  const rhfDayProps =
    props.register && props.dayName ? props.register(props.dayName) : {}

  return (
    <Container className={props.className}>
      <InputContainer width={64}>
        <Input
          placeholder="0000"
          name={props.yearName}
          {...rhfYearProps}
          defaultValue={props.controlled ? undefined : year}
          value={props.controlled ? year : undefined}
          showWarning={props.showWarningYear}
          onChange={e =>
            props.onChange && props.onChange('year', e.target.value)
          }
          type="text"
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
      </InputContainer>
      <Unit>年</Unit>

      <InputContainer width={48}>
        <Input
          placeholder="00"
          name={props.monthName}
          {...rhfMonthProps}
          defaultValue={props.controlled ? undefined : month}
          value={props.controlled ? month : undefined}
          showWarning={props.showWarningMonth}
          onChange={e =>
            props.onChange && props.onChange('month', e.target.value)
          }
          type="text"
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
      </InputContainer>
      <Unit>月</Unit>

      <InputContainer width={48}>
        <Input
          placeholder="00"
          name={props.dayName}
          {...rhfDayProps}
          defaultValue={props.controlled ? undefined : day}
          value={props.controlled ? day : undefined}
          showWarning={props.showWarningDay}
          onChange={e =>
            props.onChange && props.onChange('day', e.target.value)
          }
          type="text"
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
      </InputContainer>
      <Unit>日</Unit>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`

const InputContainer = styled.div<WidthProps>`
  ${width}
`

const Unit = styled.span`
  font-size: 14px;
  margin: 0 16px 0 10px;
`

// ControlledDateInput は Component の外部に保持している date で表示する値を制御する
export const ControlledDateInput: React.FC<ContainerProps> = props => {
  const {
    date,
    disabled,
    showWarningYear,
    showWarningMonth,
    showWarningDay,
    allowAlphabet,
    readOnly,
    className,
  } = props

  const year = date.split('-')[0] ?? ''
  const month = date.split('-')[1] ?? ''
  const day = date.split('-')[2] ?? ''

  const onChange = useCallback(
    (key: DateKey, value: string) => {
      if (
        !isValidDateValue({
          value,
          allowAlphabet,
          isYear: key === 'year',
        })
      ) {
        return
      }

      switch (key) {
        case 'year':
          props.onChange(
            isEmptyDate({ year: value, month, day })
              ? ''
              : `${value}-${month}-${day}`,
          )
          break
        case 'month':
          props.onChange(
            isEmptyDate({ year, month: value, day })
              ? ''
              : `${year}-${value}-${day}`,
          )
          break
        case 'day':
          props.onChange(
            isEmptyDate({ year, month, day: value })
              ? ''
              : `${year}-${month}-${value}`,
          )
          break
      }
    },
    [day, month, props, year, allowAlphabet],
  )

  return (
    <Component
      year={year}
      month={month}
      day={day}
      disabled={disabled}
      className={className}
      showWarningYear={showWarningYear}
      showWarningMonth={showWarningMonth}
      showWarningDay={showWarningDay}
      onChange={onChange}
      controlled={true}
      readOnly={readOnly}
    />
  )
}

export const RefDateInput: React.FC<RefProps> = props => {
  const { register } = useFormContext()

  return <Component {...props} register={register} controlled={false} />
}

const isEmptyDate = ({
  year,
  month,
  day,
}: {
  year: string
  month: string
  day: string
}) => year === '' && month === '' && day === ''

const isValidDateValue = ({
  value,
  allowAlphabet,
  isYear,
}: {
  value: string
  allowAlphabet: boolean
  isYear: boolean
}) => {
  const validDateValueRegex =
    allowAlphabet && !isYear ? /^[a-zA-Z0-9]*$/ : /^\d*$/

  return value.match(validDateValueRegex)
}
