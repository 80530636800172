import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import useSWR, { SWRConfiguration } from 'swr'

import { gqlClient } from './client'
import { parseGqlError, GqlError } from './error'

export const graphqlRequest = async <
  Result extends any,
  Variable extends Record<string, any>,
>(
  document: TypedDocumentNode<Result, Variable>,
  variable: Variable,
) => {
  try {
    return await gqlClient.request<Result>(document, variable)
  } catch (error) {
    throw parseGqlError(error)
  }
}

export const useGqlQuerySWR = <
  Result extends any,
  Variable extends Record<string, any>,
>(
  document: TypedDocumentNode<Result, Variable>,
  variable: Variable | null,
  swrOptions?: Omit<SWRConfiguration<Result, GqlError>, 'suspense'>,
) => {
  const swr = useSWR<Result, GqlError>(
    variable !== null ? [document, variable] : null,
    ([document, variable]) =>
      graphqlRequest<Result, Variable>(document, variable as Variable),
    { suspense: true, ...swrOptions },
  )
  return {
    ...swr,
    data: swr.data as Result, // suspenseを有効にしているのでundefinedにならない（swrの型定義ではundefinedが残るので無理やりassertion）
  }
}

export const useGqlQuerySWRNoSuspense = <
  Result extends any,
  Variable extends Record<string, any>,
>(
  document: TypedDocumentNode<Result, Variable>,
  variable: Variable | null,
  swrOptions?: SWRConfiguration<Result, GqlError>,
) => {
  const swr = useSWR<Result, GqlError>(
    variable !== null ? [document, variable] : null,
    ([document, variable]) =>
      graphqlRequest<Result, Variable>(document, variable as Variable),
    { ...swrOptions },
  )
  return {
    ...swr,
  }
}

export const useGqlMutation = <
  Result extends any,
  Variable extends Record<string, any>,
>(
  document: TypedDocumentNode<Result, Variable>,
  useMutationOptions?: UseMutationOption<Result, GqlError>,
) => {
  return useMutation(
    (variable: Variable) =>
      graphqlRequest<Result, Variable>(document, variable),
    useMutationOptions,
  )
}
