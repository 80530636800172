import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const patientVideoCallSignInHandler = restHandlerFactory(
  '/trials/{trialUid}/patient_video_calls/{patientVideoCallUid}/signin',
  'post',
  () => {
    return HttpResponse.json(undefined, { status: 204 })
  },
)
