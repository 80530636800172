const httpSuccessStatusCodes = {
  OK: 200,
  Created: 201,
  NoContent: 204,
} as const

const httpErrorStatusCodes = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
  TooManyRequests: 429,
  InternalServerError: 500,
  NotImplemented: 501,
  ServiceUnavailable: 503,
} as const

export const httpStatus = {
  ...httpSuccessStatusCodes,
  ...httpErrorStatusCodes,
} as const

export type HttpSuccessCode =
  (typeof httpSuccessStatusCodes)[keyof typeof httpSuccessStatusCodes]
export type HttpErrorCode =
  (typeof httpErrorStatusCodes)[keyof typeof httpErrorStatusCodes]
