import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Menu as MenuIcon } from 'src/components/icon'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSpinner } from '../../../../hooks/use-spinner'
import { useSyncAllEnvelopes } from '../../api/syncAllEnvelopes'
import { useUpdateExpRevisionStatus } from '../../api/updateExplanationRevisionStatus'

type Props = {
  explanationRevisionUid: string
  explanationSessionUid: string
  mutateSession: () => void
}

export const HospitalMenu: React.FC<Props> = ({
  explanationRevisionUid,
  explanationSessionUid,
  mutateSession,
}) => {
  const toast = useMirohaToast()

  const { showSpinner, hideSpinner } = useSpinner()

  const { request: updateRevisionStatus } = useUpdateExpRevisionStatus({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      mutateSession()
      toast({
        status: 'success',
        title: 'ステータスを更新しました',
      })
    },
    onError: console.error, // toastを表示しない
  })

  const { request: syncAllEnvelopes } = useSyncAllEnvelopes({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: async () => {
      await updateRevisionStatus({
        explanationRevisionUid: explanationRevisionUid,
      })
    },
  })

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        colorScheme="green"
        variant="customIconButtonGhost"
        aria-label="explanation-menu"
        icon={<MenuIcon />}
      />
      <MenuList>
        <MenuItem
          onClick={async () => {
            await syncAllEnvelopes({
              explanationSessionUid: explanationSessionUid,
              skipUpdateRevStatusEvent: true,
            })
          }}
        >
          ステータス更新
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
