import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const verifyForgotPassword = async (email: string) => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPost<{}>({
    url: `${SERVER_URL}/accounts/verify-forgot-password`,
    params: { email },
  })

  return {}
}
