import { useState } from 'react'

import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Spacer,
  Stack,
} from '@chakra-ui/react'
import { generatePath, Link, useParams } from 'react-router-dom'
import { DownArrow } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { hasAlreadyPinSet } from 'src/features/explanation/utils/hasAlreadyPinSet'
import { useBlockBrowserBack } from 'src/hooks/useBlockBrowserBack'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSessionInBrowsingFlow } from '../../api'
import {
  ExplanationRoomEventProvider,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { useThisSession } from '../../hooks/useThisSession'
import { DocumentList } from '../DocumentList/DocumentList'
import { ExplanationRoomProgressBar } from '../ExplanationRoomProgressBar/ExplanationRoomProgressBar'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { HospitalMenu } from '../HospitalMenu/HospitalMenu'
import { TopScreenContainer } from '../TopScreenContainer/TopScreenContainer'
import { TopScreenSkelton } from '../TopScreenSkelton/TopScreenSkelton'

type Param = {
  sessionUid: string
}

export const BrowsingFlowScreen: React.FC = () => {
  const { sessionUid = '' } = useParams<Param>()
  const { selectedTrial } = useSelectedTrial()
  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  useBlockBrowserBack()

  if (!selectedTrialHospitalUid || !thisSession.fetched) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      sessionUid={sessionUid}
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout headerTitle="説明ルーム" sessionUid={sessionUid}>
        <Content sessionUid={sessionUid} />
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}

const Content: React.FC<{ sessionUid: string }> = ({ sessionUid }) => {
  const [signReadyNoticeDone, setSignReadyNoticeDone] = useState(false)
  const [shouldShowNoticeIfPinSet, setShouldShowNoticeIfPinSet] =
    useState(false)

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  const toast = useMirohaToast()
  const { data: session, mutate: mutateSession } = useSessionInBrowsingFlow({
    explanationSessionUid: sessionUid,
    revalidateOnMount: true,
    onSuccess: data => {
      if (!thisSession.fetched) return
      const newStatus = data.explanationRevision.latestHistory.statusV2
      if (
        !signReadyNoticeDone &&
        newStatus === 'SignReady' &&
        !thisSession.isSignerMemberSet &&
        thisSession.hasConsentRequiredAgreementForm
      ) {
        toast({
          status: 'success',
          title: 'すべての必須同意書の閲覧が完了しました。署名が実施できます。',
        })
        setSignReadyNoticeDone(true)
      }

      const pinSettingStatus =
        data.explanationRevision.latestPinSettingHistory?.status
      const hasPinSet = !!pinSettingStatus && hasAlreadyPinSet(pinSettingStatus)
      if (!hasPinSet) {
        setShouldShowNoticeIfPinSet(true)
      }
      // 未設定から初回設定がされた場合にtoastを表示
      if (pinSettingStatus === 'Completed' && shouldShowNoticeIfPinSet) {
        toast({
          status: 'success',
          title: '患者の暗証番号の設定が完了しました',
        })
        setShouldShowNoticeIfPinSet(false)
      }
    },
  })

  const { selectedTrial } = useSelectedTrial()

  useUpdateRevisionStatus({
    listener: async () => {
      await mutateSession()
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (!session || !thisSession.fetched) {
    return <TopScreenSkelton />
  }

  // TODO: statusのutilを作る
  const canNavigateToSignFlow =
    !thisSession.hasConsentRequiredAgreementForm ||
    session.explanationRevision.latestHistory.statusV2 === 'SignReady' ||
    session.explanationRevision.latestHistory.statusV2 === 'SignStarted' ||
    session.explanationRevision.latestHistory.statusV2 === 'SignCompleted' ||
    session.explanationRevision.latestHistory.statusV2 ===
      'AgreementCompleted' ||
    session.explanationRevision.latestHistory.statusV2 ===
      'DeliveryCompleted' ||
    session.explanationRevision.latestHistory.statusV2 === 'AgreementRejected'

  return (
    <TopScreenContainer>
      {/* リンクとProgressBarの見た目が崩れないよう、gridを使って画面幅に応じた細かい調整を行っている */}
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          xl: 'repeat(3, 1fr)',
        }}
        gap={{ base: 8, lg: 0 }}
      >
        <GridItem>
          {/* ExplanationRoomProgressBarを真ん中に配置したいので追加している */}
          <Spacer />
        </GridItem>
        <GridItem>
          <Center>
            <ExplanationRoomProgressBar
              isSignerSet={thisSession.isSignerMemberSet}
              status={session.explanationRevision.latestHistory.statusV2}
            />
          </Center>
        </GridItem>
        <GridItem textAlign="right">
          <HospitalMenu
            explanationRevisionUid={session.explanationRevision.uid}
            explanationSessionUid={sessionUid}
            mutateSession={mutateSession}
          />
        </GridItem>
      </Grid>
      <Stack spacing="6" mt="14">
        <DocumentList
          docSets={session.explanationRevision.docSets}
          docPagePath={docRevisionUid =>
            generatePath(Paths.HospitalExplanationRoomDocument, {
              trialUid: selectedTrial.uid,
              sessionUid,
              docRevisionUid,
            })
          }
        />

        <Center color="gray.500">
          <DownArrow size="32px" />
        </Center>

        <Box cursor="not-allowed">
          <Button
            as={Link}
            to={generatePath(Paths.HospitalExplanationRoomSigning, {
              trialUid: selectedTrial.uid,
              sessionUid,
            })}
            tabIndex={canNavigateToSignFlow ? 0 : -1}
            fontSize="2xl"
            fontWeight="bold"
            w="full"
            h="72px"
            borderRadius="lg"
            bg="green.600"
            isDisabled={!canNavigateToSignFlow}
            _disabled={{
              bg: 'green.600',
              opacity: 0.3,
              pointerEvents: 'none',
            }}
            _hover={{
              bg: 'green.700',
              _disabled: { bg: 'green.600' },
            }}
            _active={{ bg: 'green.800', _disabled: { bg: 'green.600' } }}
          >
            署名実施フローへ
          </Button>
        </Box>
      </Stack>
      {renderFloatingVideoCall()}
    </TopScreenContainer>
  )
}
