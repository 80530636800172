import React, { useEffect } from 'react'

import {
  Field,
  initGoLib,
  Schema,
  validateSchema,
} from '@micin-jp/chicken-schema'
import { atom, Provider, WritableAtom } from 'jotai'
import { atomFamily, useHydrateAtoms } from 'jotai/utils'
import { cloneDeep } from 'lodash-es'

import {
  buildSchema,
  FlattenEditingFieldMap,
  emptyField,
  FieldStructure,
  getFlattenFields,
  SchemaStructure,
  fieldToStructure,
  FieldErrorGroup,
  groupErrSchema,
} from './utils'

// core atoms
export const flattenEditableFieldMapAtom = atom<FlattenEditingFieldMap>(
  new Map(),
)
export const schemaStructureAtom = atom<SchemaStructure>({ fields: [] })
export const schemaNameAtom = atom('')
export const fieldErrorAtom = atom<FieldErrorGroup>({})
export const isDeletedAtom = atom(false)

// read only
export const editingSchemaAtom = atom(get => {
  return buildSchema(
    get(schemaNameAtom),
    get(schemaStructureAtom),
    get(flattenEditableFieldMapAtom),
  )
})

export const fieldFamily = atomFamily((fid: string) =>
  atom(
    get => {
      const field = get(flattenEditableFieldMapAtom).get(fid)
      if (!field) throw new Error('field not found')
      return field
    },
    async (get, set, update: Partial<Field>) => {
      const prevMap = get(flattenEditableFieldMapAtom)
      const field = prevMap.get(fid)
      if (!field) throw new Error('field not found')
      const newField = { ...field, ...update }
      set(flattenEditableFieldMapAtom, new Map(prevMap).set(fid, newField))
      // errorがある（一度でもバリデーションが実行されている）場合はフィールドの変更ごとに再バリデーションを行う
      if (Object.keys(get(fieldErrorAtom)).length > 0) {
        const res = await validateSchema(get(editingSchemaAtom))
        if (!res.ok) {
          set(fieldErrorAtom, groupErrSchema(res.error))
        } else {
          set(fieldErrorAtom, {})
        }
      }
    },
  ),
)

/**
 * フィールドのエラーを取得する
 * @param fidOrCidOrRoot FIDまたはCIDまたは"root"
 */
export const fieldErrorFamily = atomFamily((fidOrCidOrRoot: string) =>
  atom(get => {
    return get(fieldErrorAtom)[fidOrCidOrRoot] ?? {}
  }),
)

type AppendFieldParam = {
  parentSectionFid: string | null
  defaultValue?: Field
}
// write only
export const appendFieldAtom = atom(
  null,
  (get, set, { parentSectionFid, defaultValue }: AppendFieldParam) => {
    const newField = defaultValue ?? emptyField()
    set(schemaStructureAtom, prev => {
      // 親が存在ない場合はrootに追加
      if (parentSectionFid === null) {
        return {
          fields: [...prev.fields, fieldToStructure(newField)],
        }
      }
      // parentSectionFidが存在するか確認
      const parentSection = get(flattenEditableFieldMapAtom).get(
        parentSectionFid,
      )
      if (!parentSection) {
        throw new Error('parent section not found')
      }
      // 深い階層まで探索する
      const appendFieldToParent = (
        fields: FieldStructure[],
      ): FieldStructure[] => {
        return fields.map(field => {
          if (field.fid === parentSectionFid) {
            return {
              ...field,
              fields: [...(field.fields ?? []), fieldToStructure(newField)],
            }
          }
          if (field.fields) {
            return {
              ...field,
              fields: appendFieldToParent(field.fields),
            }
          }
          return field
        })
      }
      return { fields: appendFieldToParent(prev.fields) }
    })
    set(flattenEditableFieldMapAtom, prev => {
      const newMap = new Map(prev)
      getFlattenFields([newField]).forEach(f => {
        newMap.set(f.fid, f)
      })
      return newMap
    })
  },
)

// write only
export const deleteFieldAtom = atom(null, (get, set, fid: string) => {
  set(schemaStructureAtom, prev => {
    const deleteFieldWithChildren = (
      fields: FieldStructure[],
    ): FieldStructure[] => {
      return fields.flatMap(field => {
        if (field.fid === fid) {
          return []
        }
        if (field.fields) {
          return [{ ...field, fields: deleteFieldWithChildren(field.fields) }]
        }
        return [field]
      })
    }
    return { fields: deleteFieldWithChildren(prev.fields) }
  })
  set(flattenEditableFieldMapAtom, prev => {
    const newMap = new Map(prev)
    const targetField = prev.get(fid)
    if (!targetField) return newMap
    getFlattenFields([targetField]).forEach(f => {
      newMap.delete(f.fid)
      if (get(fieldErrorAtom)[f.fid]) {
        set(fieldErrorAtom, prev => {
          const newMap = cloneDeep(prev)
          delete newMap[f.fid]
          return newMap
        })
      }
    })
    return newMap
  })
})
// write only
export const validateAtom = atom(null, async (get, set): Promise<boolean> => {
  const res = await validateSchema(get(editingSchemaAtom))
  if (!res.ok) {
    set(fieldErrorAtom, groupErrSchema(res.error))
    return false
  } else {
    set(fieldErrorAtom, {})
    return true
  }
})

type ProviderProps = {
  fetchedSchema?: Schema
  children: React.ReactNode
}

const wasmUrl = import.meta.env.VITE_WEB_DOMAIN + '/schema.wasm'
export const ChickenSchemaProvider: React.FC<ProviderProps> = ({
  fetchedSchema,
  children,
}) => {
  useEffect(() => {
    ;(async () => {
      await initGoLib({ wasmUrl })
    })()
  }, [])

  return (
    <Provider>
      <AtomsHydrator fetchedSchema={fetchedSchema}>{children}</AtomsHydrator>
    </Provider>
  )
}

// 初期値を注入する
// cf) https://jotai.org/docs/guides/initialize-atom-on-render
const AtomsHydrator: React.FC<{
  fetchedSchema?: Schema
  children: React.ReactNode
}> = ({ fetchedSchema, children }) => {
  const defaultSchemaName = fetchedSchema?.name ?? ''
  const defaultStructure: SchemaStructure = fetchedSchema
    ? { fields: fetchedSchema.fields.map(fieldToStructure) }
    : { fields: [] }
  const defaultFieldMap: FlattenEditingFieldMap = fetchedSchema
    ? new Map(getFlattenFields(fetchedSchema.fields).map(f => [f.fid, f]))
    : new Map()

  const atomValues: Iterable<
    readonly [WritableAtom<unknown, [any], unknown>, unknown]
  > = [
    [schemaNameAtom, defaultSchemaName],
    [schemaStructureAtom, defaultStructure],
    [flattenEditableFieldMapAtom, defaultFieldMap],
  ]

  useHydrateAtoms(new Map(atomValues))

  return <>{children}</>
}
