import { HttpResponse } from 'msw'
import {
  createCreateAgreementFormEnvelopePayloadMock,
  CreateAgreementFormEnvelopeDocument,
  CreateAgreementFormEnvelopeMutation,
  CreateAgreementFormEnvelopeMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const createAgreementFormEnvelopeHandler = graphQLHandlerFactory<
  CreateAgreementFormEnvelopeMutation,
  CreateAgreementFormEnvelopeMutationVariables
>('mutation', CreateAgreementFormEnvelopeDocument, () => {
  return HttpResponse.json({
    data: {
      createAgreementFormEnvelope:
        createCreateAgreementFormEnvelopePayloadMock(),
    },
  })
})
