// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgDelete = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M15 2h-5V1c0-.6-.4-1-1-1H7c-.6 0-1 .4-1 1v1H1c-.6 0-1 .4-1 1v1h16V3c0-.6-.4-1-1-1ZM2 14c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5H2v9Zm8-7h1v7h-1V7ZM5 7h1v7H5V7Z"
    />
  </svg>
)
export default SvgDelete
