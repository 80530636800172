import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import {
  GetExplanationsDocument,
  GqlError,
  SearchExplanationsInput,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { PERMISSIONS } from 'src/lib/permission'
import useSWR, { SWRConfiguration } from 'swr'

import { EXPLANATIONS_PER_PAGE } from '../../hooks/useExplanationPagination'
import { useExplanationsQueryParams } from '../../hooks/useExplanationsQueryParams'
import { Explanation } from '../../types'
import { explanationCacheKey } from '../cacheKey'
import { parseGqlExplanation } from '../parser'

type GetExplanationsReturn = {
  explanations: Explanation[]
  totalCount: number
}

export const getExplanations = async (
  input: SearchExplanationsInput,
): Promise<GetExplanationsReturn> => {
  try {
    const res = await graphqlRequest(GetExplanationsDocument, {
      input,
    })

    const { totalCount, explanations } = res.searchExplanations

    return { explanations: explanations.map(parseGqlExplanation), totalCount }
  } catch (error) {
    throw error
  }
}

type UseExplanationsParam = {
  page?: number
} & SWRConfiguration<GetExplanationsReturn, GqlError>

export const useExplanations = (param?: UseExplanationsParam) => {
  const { page, ...option } = param ?? {}

  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { hasPermission } = usePermission()
  const { queryParamObject } = useExplanationsQueryParams()

  return useSWR<{ explanations: Explanation[]; totalCount: number }, GqlError>(
    explanationCacheKey.getExplanations(
      selectedTrial.uid,
      JSON.stringify({
        ...queryParamObject,
        page: page ?? queryParamObject.page,
      }),
    ),
    () =>
      getExplanations({
        searchQuery: queryParamObject.searchQuery,
        statuses: queryParamObject.statuses,
        reAgreementRequired: queryParamObject.reAgreement,
        trialHospitalUid: hasPermission(PERMISSIONS.Hospital_BelongAll)
          ? (queryParamObject.trialHospitalUid ?? undefined)
          : selectedTrialHospitalUid,
        drTrialMemberUid: queryParamObject.dr,
        crcTrialMemberUid: queryParamObject.crc,
        orderKey: queryParamObject.sortOrderKey,
        per: EXPLANATIONS_PER_PAGE,
        page: page ?? queryParamObject.page,
      }),
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...option,
    },
  )
}
