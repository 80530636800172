import { useCallback, useEffect, useMemo, useState } from 'react'

import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { isRequestError } from 'src/modules/server/request'
import useSWR from 'swr'

import { swrKeys } from './../../../../../../../swr/key'
import {
  getIcfDocumentSetDetailRoute,
  getIcfDocumentSetListRoute,
  icfDocumentSetUidParamName,
} from './../../routes'
import { useIcfDocumentHospital } from './../../use-icf-document-hospital'
import { usePermission } from '../../../../common/permission'
import { fetchIcfDocumentList } from '../../icf-document/request'
import { UpdateIcfDocumentSetItem, IcfDocumentUidAndIndex } from '../entity'
import {
  disableIcfDocumentSet,
  fetchIcfDocumentSet,
  updateIcfDocumentSet,
} from '../request'
import { IcfDocumentSetErrors, validateIcfDocumentSet } from '../validate'

export const useEditIcfDocumentSet = () => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid: trialHospitalUid } =
    useIcfDocumentHospital()
  const { icfDocumentSetUid = '' } = useParams<{
    [icfDocumentSetUidParamName]: string
  }>()
  const { hasPermission } = usePermission()
  const navigate = useNavigate()
  const { showSuccess, showError } = useFlash()

  const [updateItem, setUpdateItem] = useState<
    UpdateIcfDocumentSetItem | undefined
  >(undefined)
  const [errors, setErrors] = useState<IcfDocumentSetErrors>({})
  const [submitDone, setSubmitDone] = useState(false)
  const [cancelTried, setCancelTried] = useState(false)

  const { data: icfDocumentSet, mutate: mutateIcfDocumentSet } = useSWR(
    trialHospitalUid
      ? swrKeys.fetchIcfDocumentSet({
          trialUid,
          trialHospitalUid,
          icfDocumentSetUid,
        })
      : null,
    () =>
      fetchIcfDocumentSet({
        trialUid,
        trialHospitalUid: trialHospitalUid!,
        icfDocumentSetUid,
      }),
  )
  const { data: approvedIcfDocuments } = useSWR(
    trialHospitalUid
      ? swrKeys.fetchApprovedIcfDocuments({ trialUid, trialHospitalUid })
      : null,
    () =>
      fetchIcfDocumentList({
        trialUid,
        trialHospitalUid: trialHospitalUid!,
        type: 'approved',
      }),
  )

  useEffect(() => {
    if (!icfDocumentSet) {
      return
    }

    setUpdateItem({
      ...icfDocumentSet,
      icfDocuments: icfDocumentSet.icfDocuments.map(doc => ({
        uid: doc.uid,
        index: doc.index,
      })),
    })
  }, [icfDocumentSet])

  //編集を再開したらエラーをクリアする
  useEffect(() => {
    setErrors({})
  }, [updateItem])

  const canUpdate = useMemo(() => {
    if (!updateItem || !icfDocumentSet) {
      return false
    }

    if (updateItem.name !== icfDocumentSet.name) {
      return true
    }

    return !equal(
      updateItem.icfDocuments,
      icfDocumentSet.icfDocuments.map(doc => ({
        uid: doc.uid,
        index: doc.index,
      })),
    )
  }, [updateItem, icfDocumentSet])

  const editing = useMemo(() => {
    if (submitDone) {
      return false
    }

    if (cancelTried) {
      return false
    }

    return canUpdate
  }, [canUpdate, cancelTried, submitDone])

  const canDisable = useMemo(() => {
    if (!icfDocumentSet) {
      return false
    }
    if (!icfDocumentSet.enabled) {
      return false
    }

    return hasPermission(PERMISSIONS.Icfdocumentset_Disable)
  }, [icfDocumentSet, hasPermission])

  const onChangeName = useCallback(
    (name: string) => {
      if (!updateItem) {
        return
      }

      setUpdateItem({ ...updateItem, name })
    },
    [updateItem],
  )

  const onChangeSelectedDocuments = useCallback(
    (icfDocuments: IcfDocumentUidAndIndex[]) => {
      if (!updateItem) {
        return
      }

      setUpdateItem({ ...updateItem, icfDocuments })
    },
    [updateItem],
  )

  const onValidate = useCallback(() => {
    if (!updateItem) {
      return false
    }

    const errors = validateIcfDocumentSet(updateItem)
    if (errors !== null) {
      setErrors(errors)
      return false
    }

    return true
  }, [updateItem])

  const listPath = useMemo(() => {
    return getIcfDocumentSetListRoute({
      trialUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? trialHospitalUid
        : undefined,
    })
  }, [hasPermission, trialUid, trialHospitalUid])

  const detailPath = useMemo(() => {
    return getIcfDocumentSetDetailRoute({
      trialUid,
      icfDocumentSetUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? trialHospitalUid
        : undefined,
    })
  }, [trialUid, icfDocumentSetUid, trialHospitalUid, hasPermission])

  //ページ遷移でアラートは出すが、submit後は出さないという挙動のため、
  //更新完了のフラグがtrueになることの副作用としてページ遷移を定義する。
  //（遷移とstateの更新を同じ関数内に定義するとstateの更新より先に遷移が走ってしまうため期待する挙動にならない）
  useEffect(() => {
    if (submitDone) {
      navigate(detailPath)
      showSuccess('文書セットを更新しました') //TODO: 説明同意に反映されない旨を小さめの文字で表示
    }
  }, [submitDone, detailPath, showSuccess, navigate])

  const onSubmit = useCallback(async () => {
    if (!updateItem || !trialHospitalUid) {
      return
    }

    try {
      const res = await updateIcfDocumentSet({
        trialUid,
        trialHospitalUid,
        icfDocumentSetUid,
        params: updateItem,
      })
      setSubmitDone(true)
      mutateIcfDocumentSet(res)
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [
    updateItem,
    trialHospitalUid,
    trialUid,
    icfDocumentSetUid,
    mutateIcfDocumentSet,
    showError,
  ])

  const onCancel = useCallback(() => {
    setCancelTried(true)
  }, [])

  const onDisable = useCallback(async () => {
    if (!icfDocumentSet || !trialHospitalUid) {
      return
    }

    try {
      await disableIcfDocumentSet({
        trialUid,
        trialHospitalUid,
        icfDocumentSetUid,
      })
      showSuccess('文書セットを無効化しました') //TODO: 詳細テキスト、開始済みの説明同意に反映されない旨を表示
      mutateIcfDocumentSet({ ...icfDocumentSet, enabled: false }, false)
      navigate(detailPath)
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [
    icfDocumentSet,
    trialHospitalUid,
    trialUid,
    icfDocumentSetUid,
    showSuccess,
    mutateIcfDocumentSet,
    navigate,
    detailPath,
    showError,
  ])

  useEffect(() => {
    if (cancelTried) {
      navigate(detailPath)
    }
  }, [cancelTried, detailPath, navigate])

  return {
    updateItem,
    icfDocumentSet,
    approvedIcfDocuments,
    canUpdate,
    editing,
    canDisable,
    onChangeName,
    onChangeSelectedDocuments,
    onValidate,
    onSubmit,
    onCancel,
    onDisable,
    errors,
    listPath,
    detailPath,
  }
}
