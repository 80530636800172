import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { gray } from 'src/components/base/color/palette'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import styled from 'styled-components'

export const Trial: React.FC = () => {
  const trial = useSelector(getSelectedTrial)!

  return (
    <Container>
      <Text as="h1" fontSize="lg" fontWeight="bold">
        試験情報
      </Text>

      <Content>
        <TrialNameContainer>
          <Text fontWeight="bold">試験</Text>
          <TrialName>
            <Text>{trial.name}</Text>
          </TrialName>
        </TrialNameContainer>

        <Clinic>
          <Text fontWeight="bold">医療機関</Text>
          <ClinicBoxContainer>
            <ClinicBox>
              {trial.trialHospitals.map(hospital => (
                <ClinicName key={hospital.uid}>
                  <Text fontSize="sm">{hospital.name}</Text>
                </ClinicName>
              ))}
            </ClinicBox>
          </ClinicBoxContainer>
        </Clinic>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0;
  height: 100%;
  flex: 1;
`

const TrialNameContainer = styled.div``

const TrialName = styled.div`
  margin: 12px 0 0 12px;
`

const Clinic = styled.div`
  flex: 1;
  margin: 24px 0 0;
  padding: 0 0 16px;
  display: flex;
  flex-direction: column;
`
const ClinicBoxContainer = styled.div`
  height: 100%;
  margin: 12px 0 0 12px;
  flex: 1;
  position: relative;
`

const ClinicBox = styled.div`
  position: absolute;
  width: auto;
  min-width: 240px;
  height: 100%;
  border: 1px solid ${gray[40]};
  box-sizing: border-box;
  overflow-y: auto;
`

const ClinicName = styled.div`
  padding: 6px;
  box-sizing: border-box;
`
