import React from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { PatientStatus } from 'src/modules/entities/patient/entity'
import { getSingle } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'

import { Router } from './router'
import { useFetch } from './use-fetch'
import { patientIdParamName } from '../router'

type Props = {}

export const EProPatientDetail: React.FC<Props> = () => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()

  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )

  const { fetched } = useFetch({
    trialUid: selectedTrial.uid,
    patientUid: patientUid,
  })

  if (!fetched || !patient || patient.status === PatientStatus.Disable)
    return null

  return <Router />
}
