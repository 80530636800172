import React, { useState } from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { getSingle } from 'src/modules/entities/worksheet/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { RootState } from 'src/modules/reducer'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { submitDisable } from './request'
import { actions as worksheetActions } from '../../../../../../../../../entities/worksheet/redux'
import { patientIdParamName } from '../../../../patient-detail'
import { worksheetIdParamName } from '../worksheet-detail'

type Props = {
  onClose: () => void
  edcLinked: boolean
}

export const DisableModal: React.FC<Props> = props => {
  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()
  const [inputValue, changeInputValue] = useState('')
  const worksheet = useSelector((state: RootState) =>
    getSingle(state, worksheetUid),
  )
  const { request, requesting, errorMessage } = useDisableWorksheet({
    trialUid,
    patientUid,
    worksheetUid,
    inputValue,
    edcLinked: props.edcLinked,
  })

  const onSubmit = async () => {
    await request()
    props.onClose()
  }

  return (
    <SubmitModal
      title={`ワークシートを無効化`}
      submitText={`無効化`}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      requesting={requesting}
      onClose={props.onClose}
      disabled={!inputValue ? true : false}
    >
      <Text textAlign="center">
        <Bold>{worksheet.title}</Bold>を無効化します。
      </Text>
      <Spacer size={20} />
      <Label bold>無効化理由</Label>
      <Spacer size={10} />
      <Input
        placeholder={'無効化理由を入力してください'}
        onChange={e => changeInputValue(e.target.value)}
      />
    </SubmitModal>
  )
}

const useDisableWorksheet = ({
  trialUid,
  patientUid,
  worksheetUid,
  inputValue,
  edcLinked,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  inputValue: string
  edcLinked: boolean
}) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()

  const dispatch = useDispatch()
  const { showSuccess } = useFlash()

  const request = async () => {
    try {
      requestStarted()

      await submitDisable({ trialUid, patientUid, worksheetUid, inputValue })
      await dispatch(
        worksheetActions.fetchSingle({ trialUid, patientUid, worksheetUid }),
      )

      if (edcLinked) {
        showSuccess(
          'ワークシートを無効化しました。\nEDCの対象データへは反映されていないため、別途EDC上での無効化作業が必要です。',
          {
            durationMsec: 30000,
            closable: true,
          },
        )
      } else {
        showSuccess('ワークシートを無効化しました。')
      }

      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}

const Bold = styled.span`
  font-weight: bold;
`
