import { Field } from '@micin-jp/chicken-schema'
import { GetWorksheetFieldDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { Worksheet, WorksheetField } from '../../types'
import { worksheetCacheKey } from '../cacheKey'
import { parseGqlWorksheetField } from '../parser'

const getWorksheetField = async ({
  worksheetUid,
  fid,
  index,
}: {
  worksheetUid: Worksheet['uid']
  fid: Field['fid']
  index: number
}): Promise<WorksheetField> => {
  try {
    const res = await graphqlRequest(GetWorksheetFieldDocument, {
      worksheetUid,
      fid,
      fieldIndex: index,
    })
    return parseGqlWorksheetField(res.field)
  } catch (error) {
    throw error
  }
}

export const useWorksheetField = ({
  worksheetUid,
  fid,
  index,
  shouldCancel,
  ...option
}: {
  worksheetUid: Worksheet['uid']
  fid: Field['fid']
  index: number
  shouldCancel?: boolean
} & SWRConfiguration<WorksheetField, GqlError>) => {
  return useSWR(
    shouldCancel
      ? null
      : worksheetCacheKey.getWorksheetField(worksheetUid, fid, index),
    () => getWorksheetField({ worksheetUid, fid, index }),
    option,
  )
}
