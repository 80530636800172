import { VideoCallProvider } from '@micin-jp/call-lib-core'
import { fixture as accountFixture } from 'src/modules/entities/account/fixture'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Account, SelectedTrial } from './entity'
import { definitions } from '../../../lib/api-client/schema.gen'
import { Permission, toPermission } from '../../../lib/permission'

type TrialHospitalResponse = {
  uid: string
  name: string
}

type SelectedTrialResponse = {
  uid: string
  name: string
  role: number
  video_enabled: boolean
  pdf_output_enabled: boolean
  video_capture_enabled: boolean
  video_capture_access_restricted: boolean
  video_provider: VideoCallProvider
  edc_integration_enabled: boolean
  selected_trial_hospital_uid: string
  trial_hospitals: Array<TrialHospitalResponse>
  new_econsent_enabled: boolean
  has_worksheet: boolean
  epro_enabled: boolean
  questionnaire_schedule_released: boolean
  permissions: string[]
  multi_site_video_call: boolean
  feature_channel: definitions['input.FeatureChannel']
  feature_flags: definitions['featureflag.Flags']
}

type AccountResponse = {
  uid: string
  email: string
  first_name: string
  last_name: string
  has_multiple_memberships: boolean
  selected_trial: SelectedTrialResponse | null
}

const toPermissions = (strs: string[]): Permission[] => {
  return strs.map(s => toPermission(s)).filter(s => s) as Permission[]
}

const parse = (resp: AccountResponse): Account => {
  const selectedTrial: SelectedTrial | null = resp.selected_trial && {
    uid: resp.selected_trial.uid,
    name: resp.selected_trial.name,
    role: resp.selected_trial.role,
    videoEnabled: resp.selected_trial.video_enabled,
    pdfOutputEnabled: resp.selected_trial.pdf_output_enabled,
    videoCaptureEnabled: resp.selected_trial.video_capture_enabled,
    videoCaptureAccessRestricted:
      resp.selected_trial.video_capture_access_restricted,
    videoProvider: resp.selected_trial.video_provider,
    edcIntegrationEnabled: resp.selected_trial.edc_integration_enabled,
    selectedTrialHospitalUid: resp.selected_trial.selected_trial_hospital_uid,
    trialHospitals: resp.selected_trial.trial_hospitals,
    newEconsentEnabled: resp.selected_trial.new_econsent_enabled,
    hasWorksheet: resp.selected_trial.has_worksheet,
    eProEnabled: resp.selected_trial.epro_enabled,
    questionnaireScheduleReleased:
      resp.selected_trial.questionnaire_schedule_released,
    permissions: toPermissions(resp.selected_trial.permissions),
    multiSiteVideoCall: resp.selected_trial.multi_site_video_call,
    featureChannel: resp.selected_trial.feature_channel,
    featureFlags: resp.selected_trial.feature_flags,
  }

  const res: Account = {
    uid: resp.uid,
    email: resp.email,
    firstName: resp.first_name,
    lastName: resp.last_name,
    hasMultipleMemberships: resp.has_multiple_memberships,
    selectedTrial: selectedTrial,
  }

  return res
}

export const fetchMe = async (): Promise<Account> => {
  if (IS_MOCK_MODE) {
    return accountFixture
  }

  const resp = await requestGet<AccountResponse>({
    url: `${SERVER_URL}/accounts/user`,
    params: {},
  })

  return parse(resp.data)
}

type SelectTrialResponse = {}

export const selectTrial = async ({
  trialUid,
  trialHospitalUid,
}: {
  trialUid: string
  trialHospitalUid?: string
}) => {
  if (IS_MOCK_MODE) {
    return {}
  }

  return await requestPost<SelectTrialResponse>({
    url: `${SERVER_URL}/accounts/select_trial`,
    params: {
      trial_uid: trialUid,
      trial_hospital_uid: trialHospitalUid,
    },
  })
}
