import { SERVER_URL } from 'src/modules/server/const'
import { requestGet, requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Agreement, AgreementParams } from './entity'
import { fixture } from './fixture'

type AgreementResponse = {
  user_uid: string
  agree_privacy_policy: boolean
  agree_terms_of_service: boolean
  agree_external_transmission_policy: boolean
}

const parse = (resp: AgreementResponse): Agreement => {
  return {
    userUid: resp.user_uid,
    agreeTermsOfService: resp.agree_terms_of_service,
    agreePrivacyPolicy: resp.agree_privacy_policy,
    agreeExternalTransmissionPolicy: resp.agree_external_transmission_policy,
  }
}

export const fetch = async (): Promise<Agreement> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestGet<AgreementResponse>({
    url: `${SERVER_URL}/accounts/agreement`,
    params: {},
  })

  return parse(res.data)
}

export const create = async (params: AgreementParams): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPost<{}>({
    url: `${SERVER_URL}/accounts/agreement`,
    params: {
      agree_terms_of_service: params.agreeTermsOfService,
      agree_privacy_policy: params.agreePrivacyPolicy,
      agree_external_transmission_policy:
        params.agreeExternalTransmissionPolicy,
    },
  })
  return {}
}
