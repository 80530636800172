import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'

import { ExplanationRevisionMember } from '../../types'

type Props = {
  members: ExplanationRevisionMember[]
}

export const MembersTable: React.FC<Props> = ({ members }) => {
  return (
    <TableContainer whiteSpace="break-spaces">
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>医療機関</Th>
            <Th>担当者</Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.map((member, index) => (
            <Tr key={member.trialMember.uid}>
              <Td>{index + 1}</Td>
              <Td>{member.trialHospital.name}</Td>
              <Td maxW="240px">
                <MemberLabel
                  role={member.trialMember.role}
                  displayName={`${member.trialMember.lastName} ${member.trialMember.firstName}`}
                  isPartner={member.isPartner}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
