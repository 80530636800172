import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import { QuestionnaireDetail } from './detail/detail'
import { ListContainer } from './list/list-container'
import { ScheduleContainer } from './schedule/schedule-container'

export const questionnaireIdParamName = 'questionnaireUid'

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={``} element={<ListContainer />} />
      <Route path={`schedules`} element={<ScheduleContainer />} />
      <Route
        path={`:${questionnaireIdParamName}`}
        element={<QuestionnaireDetail />}
      />
      <Route path="" element={<Navigate replace to={``} />} />
    </Routes>
  )
}
