import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { Edit } from 'src/components/__legacy__icon/monochrome'
import { CalendarDateInput } from 'src/components/CalendarDateInput/CalendarDateInput'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { usePermission } from 'src/features/auth/context'
import { icfDocumentTypeToText } from 'src/features/icfDocument/utils/icfDocumentTypeToText'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'
import { sortByDate } from 'src/modules/util/sort'
import { formatDate } from 'src/utils/formatDate'

import { useUpdateDeliveryDate } from '../../api'
import { ExplanationDetail } from '../../types'

type Props = {
  explanation: ExplanationDetail
  mutateExplanation: () => void
}

type FormValue = {
  deliveryDate: Date
  reason: string
}

export const UpdateDeliveryDate: React.FC<Props> = ({
  explanation,
  mutateExplanation,
}) => {
  const { hasPermission } = usePermission()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { handleSubmit, control, register, watch, reset } = useForm<FormValue>({
    defaultValues: {
      deliveryDate: explanation.latestRevision.deliveredAt,
    },
  })

  const dateChanged = !dayjs(watch('deliveryDate')).isSame(
    dayjs(explanation.latestRevision.deliveredAt),
  )

  const canSubmit = !!watch('reason') && dateChanged

  const toast = useMirohaToast()

  const handleClose = () => {
    reset()
    onClose()
  }

  const { request: updateSchedule } = useUpdateDeliveryDate({
    onSuccess: () => {
      mutateExplanation()
      toast({
        status: 'success',
        title: '交付日を変更しました',
      })
      handleClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  if (!hasPermission(PERMISSIONS.Explanation_Edit)) return null

  const docNumberingIdByIcfDocRevUid = new Map(
    explanation.latestRevision.docSets
      .flatMap(docSet => docSet.currentDocs)
      .map(doc => [doc.icfDocumentRevision.uid, doc.numberingId]),
  )

  return (
    <>
      <IconButton
        variant="customIconButtonGhost"
        aria-label="交付日を変更"
        icon={<Edit />}
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <form
          onSubmit={handleSubmit(async ({ deliveryDate, reason }) => {
            await updateSchedule({
              explanationRevisionUid: explanation.latestRevision.uid,
              deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD'),
              reason,
            })
          })}
        >
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>交付日変更</ModalHeader>

            <ModalBody>
              <Stack spacing="16" h="full">
                <Stack spacing="4">
                  <Box>
                    <FormLabel id="schedule-label">交付日</FormLabel>
                    <Controller
                      name="deliveryDate"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CalendarDateInput
                          aria-labelledby="schedule-label"
                          value={value}
                          onChange={schedule => {
                            onChange(schedule)
                          }}
                        />
                      )}
                    />
                  </Box>
                  <FormControl>
                    <FormLabel>変更理由</FormLabel>
                    <Input {...register('reason')} />
                  </FormControl>
                </Stack>
                <Stack spacing="4" h="300px">
                  <Text fontSize="lg" fontWeight="bold">
                    交付文書
                  </Text>
                  <Stack spacing="8">
                    {explanation.latestRevision.docSets.map(docSet => {
                      if (!docSet.explanationDocRevisions) {
                        return null
                      }

                      return (
                        <Stack key={docSet.icfDocSetNumberingId} spacing="2">
                          <TextWithBar as="h2">
                            {docSet.icfDocSetNumberingId}.{docSet.name}
                          </TextWithBar>
                          <TableContainer>
                            <Table>
                              <Thead>
                                <Tr>
                                  <Th>文書ID</Th>
                                  <Th>文書名</Th>
                                  <Th>版数</Th>
                                  <Th>文書の種類</Th>
                                  {/* 署名時刻 */}
                                  <Th w="full" />
                                </Tr>
                              </Thead>
                              <Tbody>
                                {docSet.explanationDocRevisions
                                  .filter(
                                    docRev =>
                                      docRev.type !== 'Video' &&
                                      docRev.isCompleted,
                                  )
                                  .map(docRev => (
                                    <Tr key={docRev.uid}>
                                      <Td>
                                        {docNumberingIdByIcfDocRevUid.get(
                                          docRev.icfDocumentRevisionUid,
                                        )}
                                      </Td>
                                      <Td>{docRev.icfDocumentRevisionName}</Td>
                                      <Td>
                                        {docRev.icfDocumentRevisionVersion}
                                      </Td>
                                      <Td>
                                        {icfDocumentTypeToText(
                                          docRev.icfDocumentRevisionType,
                                        )}
                                      </Td>
                                      <Td>
                                        {docRev.type === 'AgreementForm' && (
                                          <Text fontSize="xs" as="span">
                                            {`署名完了時刻: ${formatDate(
                                              sortByDate(
                                                docRev.signHistories,
                                                'operatedAt',
                                                'desc',
                                              )[0].operatedAt,
                                              'YYYY/MM/DD (ddd) HH:mm',
                                            )}`}
                                          </Text>
                                        )}
                                      </Td>
                                    </Tr>
                                  ))}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      )
                    })}
                  </Stack>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ModalCancelButton />
              <Button colorScheme="blue" type="submit" isDisabled={!canSubmit}>
                変更する
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  )
}
