import { Badge, HStack } from '@chakra-ui/react'
import { Check, Rejection } from 'src/components/__legacy__icon/monochrome'
import {
  blue,
  gray,
  green,
  Palette,
  red,
  yellow,
} from 'src/components/base/color/palette'

import { icfDocumentRevisionStatus, IcfDocumentRevisionStatus } from '../entity'

type Props = {
  status: IcfDocumentRevisionStatus
}

const isSaved = (status: IcfDocumentRevisionStatus) => {
  return [
    icfDocumentRevisionStatus.NewDocumentCreated,
    icfDocumentRevisionStatus.Saved,
    icfDocumentRevisionStatus.Withdrawn,
    icfDocumentRevisionStatus.NewRevisionCreated,
  ].some(s => s === status)
}
const isWaitApproving = (status: IcfDocumentRevisionStatus) => {
  return status === icfDocumentRevisionStatus.WaitApproving
}
const isApproved = (status: IcfDocumentRevisionStatus) => {
  return [
    icfDocumentRevisionStatus.Approved,
    icfDocumentRevisionStatus.Enabled,
    icfDocumentRevisionStatus.NewRevisionCanceled,
  ].some(s => s === status)
}
const isRejected = (status: IcfDocumentRevisionStatus) => {
  return status === icfDocumentRevisionStatus.Rejected
}
const isDisabled = (status: IcfDocumentRevisionStatus) => {
  return status === icfDocumentRevisionStatus.Disabled
}

const fontColor = (status: IcfDocumentRevisionStatus): Palette | undefined => {
  if (isSaved(status)) {
    return blue[70]
  }
  if (isWaitApproving(status)) {
    return yellow[100]
  }
  if (isApproved(status)) {
    return green[40]
  }
  if (isRejected(status)) {
    return red[50]
  }
  if (isDisabled(status)) {
    return gray[55]
  }
  return undefined
}
const backgroundColor = (
  status: IcfDocumentRevisionStatus,
): Palette | undefined => {
  if (isSaved(status)) {
    return blue[20]
  }
  if (isWaitApproving(status)) {
    return yellow[20]
  }
  if (isApproved(status)) {
    return green[10]
  }
  if (isRejected(status)) {
    return red[5]
  }
  if (isDisabled(status)) {
    return gray[5]
  }
  return undefined
}

const icon = (status: IcfDocumentRevisionStatus): JSX.Element | null => {
  if (isApproved(status)) {
    return <Check />
  }
  if (isRejected(status)) {
    return <Rejection />
  }
  return null
}

const text = (status: IcfDocumentRevisionStatus): string | null => {
  if (isSaved(status)) {
    return '一時保存'
  }
  if (isWaitApproving(status)) {
    return '承認依頼中'
  }
  if (isApproved(status)) {
    return '承認'
  }
  if (isRejected(status)) {
    return '承認却下'
  }
  if (isDisabled(status)) {
    return '無効'
  }

  return null
}

export const IcfDocumentRevisionStatusBadge: React.FC<Props> = ({ status }) => {
  return (
    <Badge
      sx={{
        width: '80px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        color: fontColor(status),
        backgroundColor: backgroundColor(status),
        fontWeight: 'normal',
      }}
    >
      <HStack spacing="2px">
        {icon(status)}
        <span>{text(status)}</span>
      </HStack>
    </Badge>
  )
}
