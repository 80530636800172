import React, { useState } from 'react'

import { Box, HStack } from '@chakra-ui/react'
import OutsideClickHandler from 'react-outside-click-handler'
import { colors } from 'src/assets/colors'
import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'

import { DropDown, DropDownItem, Name } from './dropdown'
import { Up, Down } from '../__legacy__icon/monochrome'
import { gray, white, yellow } from '../base/color/palette'

export type SelectboxItem<Value> = {
  value: Value
  name: string
  desc?: string
  required?: boolean
}

type Props<Value> = {
  items: SelectboxItem<Value>[]
  selectedValue: Value | undefined
  name?: string
  className?: string
  placeholder?: string | null
  onChange?: (value: Value) => void
  width?: number
  disabled?: boolean
  withDesc?: boolean
  warning?: boolean
  large?: boolean
}

const Component = <Value extends {}>(props: Props<Value>) => {
  const { selectedValue, onChange } = props
  const [dropDownOpen, setDropDownOpen] = useState(false)

  const handleDropDown = () => {
    setDropDownOpen(!dropDownOpen)
  }

  const onSelect = (value: Value) => {
    onChange && onChange(value)
    handleDropDown()
  }

  return (
    <SelectBoxWrap className={props.className} disabled={props.disabled}>
      <OutsideClickHandler
        onOutsideClick={handleDropDown}
        disabled={!dropDownOpen}
      >
        {!selectedValue && (
          <DropDownShrunk
            onClick={handleDropDown}
            className={'active ' + (props.disabled ? 'disabled ' : ' ')}
            disabled={props.disabled}
            warning={props.warning}
          >
            <HStack align="center" justify="space-between">
              <Name>{props.placeholder}</Name>
              {dropDownOpen ? <Up size="M" /> : <Down size="M" />}
            </HStack>
          </DropDownShrunk>
        )}
        {props.items.map(item => (
          <DropDownShrunk
            key={item.value as any}
            onClick={handleDropDown}
            disabled={props.disabled}
            warning={props.warning}
            className={
              (selectedValue === item.value ? 'active ' : ' ') +
              (props.disabled ? 'disabled ' : ' ') +
              (props.withDesc ? 'desc ' : ' ') +
              (props.large ? 'large' : '')
            }
          >
            <HStack align="center" justify="space-between">
              <Box>
                <Name
                  className={
                    (props.withDesc ? 'desc ' : ' ') +
                    (item.required ? 'required ' : ' ')
                  }
                >
                  {item.name}
                </Name>
                {props.withDesc && <Desc className="shrunk">{item.desc}</Desc>}
              </Box>

              <Box>{dropDownOpen ? <Up size="S" /> : <Down size="S" />}</Box>
            </HStack>
          </DropDownShrunk>
        ))}
        {dropDownOpen && (
          <DropDown>
            {props.items.map(item => (
              <DropDownItem
                key={item.value as any}
                className={
                  (selectedValue === item.value ? 'active ' : ' ') +
                  (props.withDesc ? 'desc ' : ' ')
                }
                onClick={() => {
                  onSelect(item.value)
                }}
              >
                <Name className={item.required ? 'required ' : ' '}>
                  {item.name}
                </Name>
                {props.withDesc && <Desc>{item.desc}</Desc>}
              </DropDownItem>
            ))}
          </DropDown>
        )}
      </OutsideClickHandler>
    </SelectBoxWrap>
  )
}

// ref: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/39136#issuecomment-573626654
const StyledComponent = styled(Component)<
  WidthProps & { warning?: boolean; disabled?: boolean }
>`
  position: relative;
  ${width}
`

/**
 * @deprecated Use chakra-ui Select instead.
 */
export const Selectbox = <T extends {}>(props: Props<T> & WidthProps) => {
  return (
    <StyledComponent<React.FC<Props<T>>>
      warning={props.warning}
      disabled={props.disabled}
      {...props}
    />
  )
}

const SelectBoxWrap = styled.div<{ disabled?: boolean }>`
  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : '')};
  }
`

const DropDownShrunk = styled.div<{ warning?: boolean; disabled?: boolean }>`
  font-size: 14px;
  background: ${white};
  display: none;
  padding: 0 16px;
  text-decoration: none;
  box-sizing: border-box;
  color: ${gray[100]};
  align-items: center;
  border-radius: 3px;
  border: 1px solid;
  border-color: ${gray[40]};
  line-height: 2.5em;
  position: relative;
  min-height: 2.5em;
  user-select: none;
  word-break: break-all;

  &.active {
    display: block;
  }

  transition: 0.1s ease;
  &:hover {
    background-color: ${props => (props.disabled ? colors.hoverGray : '')};
  }

  background-color: ${props => props.warning && yellow[10]};

  &.disabled {
    pointer-events: none;
    color: ${gray[55]};
    background-color: ${gray[10]};
  }
  &.desc {
    padding: 10px 26px;
    height: auto;
    line-height: 1.7;
    min-height: 70px;
  }
  &.large {
    min-height: 70px;
  }
`

const Desc = styled.div`
  font-size: 14px;
  &.shrunk {
    flex-grow: 1;
    line-height: 1.7;
  }
`
