import {
  GqlError,
  UpdateExpPatientMobileNumberDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { useMutation, UseMutationOption } from '../../../../hooks/use-mutation'

export const updateExpPatientMobileNumber = async ({
  explanationPatientUid,
  mobileNumber,
}: {
  explanationPatientUid: string
  mobileNumber: string
}): Promise<void> => {
  try {
    await graphqlRequest(UpdateExpPatientMobileNumberDocument, {
      input: {
        explanationPatientUid,
        mobileNumber,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useUpdateExpPatientMobileNumber = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({
      explanationPatientUid: explanationRevisionUid,
      mobileNumber,
    }: {
      explanationPatientUid: string
      mobileNumber: string
    }) =>
      updateExpPatientMobileNumber({
        explanationPatientUid: explanationRevisionUid,
        mobileNumber,
      }),
    option,
  )
}
