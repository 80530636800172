import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Button } from 'src/components/base/button/button'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'

import { DisableModal } from './container'
import { worksheetIdParamName } from '../worksheet-detail'

type Props = {
  edcLinked: boolean
}

export const DisableButton: React.FC<Props> = props => {
  const [modalOpen, setModalOpen] = useState(false)
  const { worksheetUid = '' } = useParams<{
    [worksheetIdParamName]: string
  }>()

  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: () => setModalOpen(false),
    deps: [worksheetUid],
  })

  return (
    <>
      <Button
        size="S"
        text="無効化"
        onClick={() => setModalOpen(true)}
        buttonType="normal"
      ></Button>

      {modalOpen && (
        <DisableModal
          onClose={() => setModalOpen(false)}
          edcLinked={props.edcLinked}
        />
      )}
    </>
  )
}
