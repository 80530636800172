import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'

import { SmallItemLogModal } from './modal'
import { patientIdParamName } from '../../../../patient-detail'
import { worksheetIdParamName } from '../../detail/worksheet-detail'
import { SmallItemLog } from '../entity'
import { fetchList } from '../request'

type Props = {
  smallItemAnswer: SmallItemAnswer
  closeModal: () => void
}

export const SmallItemLogModalContainer: React.FC<Props> = props => {
  const [logs, setLogs] = useState<SmallItemLog[]>()
  const [fetching, setFetching] = useState(false)
  const { smallItemAnswer, closeModal } = props
  const { title } = smallItemAnswer

  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  useEffect(() => {
    const fetch = async () => {
      const fetched = await fetchList({
        trialUid,
        patientUid,
        worksheetUid,
        mediumItemAnswerUid: smallItemAnswer.mediumItemAnswerUid,
        smallItemAnswerUid: smallItemAnswer.uid,
      })
      setLogs(fetched)
      setFetching(false)
    }

    setFetching(true)
    fetch()
  }, [
    trialUid,
    patientUid,
    smallItemAnswer.mediumItemAnswerUid,
    smallItemAnswer.uid,
    worksheetUid,
  ])

  if (fetching || !logs) return null

  return <SmallItemLogModal title={title} logs={logs} closeModal={closeModal} />
}
