import { HttpResponse } from 'msw'
import {
  createHospitalMock,
  createTrialHospitalMock,
  GetRelatedTrialHospitalsQuery,
  GetRelatedTrialHospitalsDocument,
  GetRelatedTrialHospitalsQueryVariables,
} from 'src/lib/gql-client'
import { authenticatedAccountFactory } from 'src/tests/factory'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const getRelatedTrialHospitalsHandler = graphQLHandlerFactory<
  GetRelatedTrialHospitalsQuery,
  GetRelatedTrialHospitalsQueryVariables
>('query', GetRelatedTrialHospitalsDocument, () => {
  return HttpResponse.json({
    data: {
      searchableTrialHospitalsForPatientSearch: [
        createTrialHospitalMock({
          uid: authenticatedAccountFactory().selectedTrialHospitalUid ?? '',
          hospital: createHospitalMock({
            name: 'test hospital1',
          }),
        }),
        createTrialHospitalMock({
          uid: generateUUID(),
          hospital: createHospitalMock({
            name: 'test hospital2',
          }),
        }),
      ],
    },
  })
})
