import { useEffect, useRef } from 'react'

export const useSetInitialImageUid = (
  setInitialImageUid: (imageUrl: string) => void,
  videoCallRoomImageUid?: string,
) => {
  const initialImageUrlRef = useRef<string | null>(null)

  // NOTE: setInitialImageUid は、ビデオ通話のキャプチャ一覧の初回表示時、
  //       画像プレビュー画面部分に表示される画像の URL を設定するものの為、
  //       初回レンダリング時にのみ呼び出す様にしている
  useEffect(() => {
    if (!videoCallRoomImageUid) return
    if (initialImageUrlRef.current) return

    initialImageUrlRef.current = videoCallRoomImageUid
    setInitialImageUid(videoCallRoomImageUid)
  }, [setInitialImageUid, videoCallRoomImageUid])
}
