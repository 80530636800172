import produce from 'immer'
import { Patient, PatientList } from 'src/modules/entities/patient/entity'
import { RootState } from 'src/modules/reducer'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { fetch, FetchPatientListParam } from './request'

const create = actionCreatorFactory('patient/list')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetch: createAsync<FetchPatientListParam, PatientList, { message: string }>(
    'FETCH',
    async params => {
      return await fetch(params)
    },
  ),
}

export type State = {
  data: Patient[]
  totalCount: number
}

const initialState: State = {
  data: [],
  totalCount: 0,
}

export const reducer = reducerWithInitialState(initialState).case(
  actions.fetch.async.done,
  (state, payload) =>
    produce(state, draft => {
      draft.data = payload.result.patients
      draft.totalCount = payload.result.totalCount
    }),
)
