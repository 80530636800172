import React from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SubmitModal } from 'src/components/modal/submit'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import { actions } from 'src/modules/entities/worksheet/redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { confirm } from './request'
import { patientIdParamName } from '../../../../patient-detail'
import { worksheetIdParamName } from '../worksheet-detail'

type Props = {
  mediumItemAnswerUid: string
  edcLinked: boolean
  onClose: () => void
}

export const SubmitModalContainer: React.FC<Props> = props => {
  const { mediumItemAnswerUid, edcLinked } = props
  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  const { request, requesting } = useConfirm({
    trialUid,
    patientUid,
    worksheetUid,
    mediumItemAnswerUid,
    edcLinked,
  })

  const onSubmit = async () => {
    await request()
    props.onClose()
  }

  return (
    <SubmitModal
      title={`確定`}
      submitText={`確定`}
      onSubmit={onSubmit}
      requesting={requesting}
      onClose={props.onClose}
    >
      <Text textAlign="center">
        {edcLinked
          ? '既に一時保存がされているセクションについてEDC連携の確定リクエストを送信します。よろしいですか？'
          : '既に一時保存されているセクションの項目を確定します。よろしいですか？'}
      </Text>
    </SubmitModal>
  )
}

const useConfirm = ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemAnswerUid,
  edcLinked,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemAnswerUid: string
  edcLinked: boolean
}) => {
  const { requestDone, requestStarted, requesting } = useRequestState({
    withSpinner: edcLinked,
  })

  const { showSuccess, showError } = useFlash()
  const dispatch = useDispatch()

  const request = async () => {
    try {
      requestStarted()
      await confirm({
        trialUid,
        patientUid,
        worksheetUid,
        mediumItemAnswerUid,
      })

      showSuccess(
        edcLinked
          ? 'EDC連携の確定リクエストを送信しました。'
          : 'セクションの値を確定しました。',
      )
    } catch (error) {
      showError(error.message, { durationMsec: 30000, closable: true })
    }

    dispatch(
      actions.fetchSingle({
        trialUid,
        patientUid,
        worksheetUid,
      }),
    )

    requestDone()
  }

  return {
    request,
    requesting,
  }
}
