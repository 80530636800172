import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { updateQuestionnaire } from 'src/modules/entities/questionnaire/redux'
import { QuestionnaireUpdateRequest } from 'src/modules/entities/questionnaire/request'
import { RootState } from 'src/modules/reducer'

import { useQuestionnaireDetailPermission } from '../use-permission'

export const SaveButton = () => {
  const dispatch = useDispatch()
  const trialUid = useSelector(getSelectedTrial)?.uid

  const { isEdited } = useQuestionnaireDetailPermission()

  const questionnaire = useSelector(
    (state: RootState) => state.questionnaireDetail,
  )
  const { uid: questionnaireUid, title, displayName, sections } = questionnaire

  const onSubmit = useCallback(async () => {
    if (!trialUid) return
    const params: QuestionnaireUpdateRequest = {
      title,
      displayName,
      sections,
    }
    await dispatch(updateQuestionnaire({ trialUid, questionnaireUid, params }))
  }, [dispatch, displayName, questionnaireUid, sections, title, trialUid])

  return (
    <Button
      text={'一時保存'}
      size="S"
      buttonType="normal"
      onClick={onSubmit}
      disabled={!isEdited || title.length === 0}
    />
  )
}
