import { Button, useDisclosure } from '@chakra-ui/react'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { isNotNullish } from 'src/utils/isNotNullish'

import { useCompleteAgreement, useExplanation } from '../../api'
import { ExplanationDetail } from '../../types'
import { parseDocRevsToCompleteAgreement } from '../../utils/parseDocRevsToCompleteAgreement'
import { CompleteAgreementModal } from '../CompleteAgreementModal/CompleteAgreementModal'

type Props = {
  explanation: ExplanationDetail
}

export const CompleteAgreementForDetail: React.FC<Props> = ({
  explanation,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useMirohaToast()

  const { mutate: mutateExplanation } = useExplanation({
    explanationUid: explanation.uid,
  })

  const { request: completeAgreement } = useCompleteAgreement({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '同意が完了しました',
      })
      mutateExplanation()
      onClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const { status, docSets } = explanation.latestRevision

  if (status !== 'AgreementNotDone') {
    return null
  }

  const docRevisions = docSets
    .flatMap(docSet => docSet.explanationDocRevisions)
    .filter(isNotNullish)

  const { canComplete, uncompletedDocRevs } =
    parseDocRevsToCompleteAgreement(docRevisions)

  if (!canComplete) {
    return null
  }

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" variant="outline">
        同意完了処理を実施
      </Button>

      <CompleteAgreementModal
        isForRoom={false}
        isOpen={isOpen}
        uncompletedDocRevs={uncompletedDocRevs}
        onClose={onClose}
        onSubmit={async () => {
          await completeAgreement({
            explanationRevisionUid: explanation.latestRevision.uid,
          })
        }}
      />
    </>
  )
}
