import { ExplanationSignerRole } from '../types'

const roleTextMap: Record<ExplanationSignerRole, string> = {
  Dr: 'Dr',
  CRC: 'CRC',
  Patient: '患者',
  Representative: '代諾者',
  None: '',
}

export const explanationSignerRoleToText = (
  role: ExplanationSignerRole,
): string => roleTextMap[role]
