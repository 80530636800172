import { Gender } from 'src/modules/entities/patient/entity'
import { fixture as patientFixture } from 'src/modules/entities/patient/fixture'
import { PatientResponse, parse } from 'src/modules/entities/patient/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { RequestValues } from './components/form'

export type Params = {
  chart_uid: string
  disease_uid: string
  first_name: string
  first_name_ja: string
  last_name: string
  last_name_ja: string
  gender: Gender | null
  birthday: string
  // start_record_on: string
  phone_number: string
  email: string
}

export const addPatient = async ({
  trialUid,
  patient: {
    diseaseUid,
    chartUid,
    firstName,
    firstNameJa,
    lastName,
    lastNameJa,
    gender,
    birthday,
    phoneNumber,
    email,
  },
}: {
  trialUid: string
  patient: RequestValues
}) => {
  if (IS_MOCK_MODE) {
    return patientFixture
  }

  const params: Params = {
    chart_uid: chartUid || '',
    disease_uid: diseaseUid,
    first_name: firstName || '',
    first_name_ja: firstNameJa || '',
    last_name: lastName || '',
    last_name_ja: lastNameJa || '',
    gender: gender || null,
    birthday: birthday || '',
    phone_number: phoneNumber || '',
    email: email || '',
  }

  const resp = await requestPost<PatientResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients`,
    params: params,
  })

  return parse(resp.data)
}
