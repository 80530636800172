import { useEffect, useState } from 'react'

import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { CalendarComponent } from 'src/components/form-redesigned/calendar'
import { Calendar } from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { zeroPadding } from 'src/utils/zeroPadding'

import { FieldOf } from '../../utils'
import { FormWrapperWithoutChakra } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Date'> | FieldOf<'NullableDate'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

type FormDate = {
  year?: string
  month?: string
  day?: string
}

const strToFormDate = (value?: string): FormDate => {
  const [year, month, day] = value?.split('-') ?? []
  return {
    year: year === 'YYYY' ? undefined : year,
    month: month === 'MM' ? undefined : month,
    day: day === 'DD' ? undefined : day,
  }
}

// 一時保存できるよう0埋めなどの処理を行う
const formDate = (date: FormDate): string | undefined => {
  if (date.year && date.month && date.day) {
    return `${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`
  }
  if (date.year && date.month && !date.day) {
    return `${date.year}-${date.month.padStart(2, '0')}`
  }
  if (date.year && !date.month && !date.day) {
    return date.year
  }
  if (!date.year && !date.month && !date.day) {
    return undefined
  }
  // 不正な日付。YYYY-MM-DDで置き換える。後のバリデーションでエラーになる。
  return `${date.year ?? 'YYYY'}-${date.month ?? 'MM'}-${date.day ?? 'DD'}`
}

export const DateFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  // 0埋め等をしないユーザー入力の値を保持して入力のまま表示できるようにする
  const [inputtingDate, setInputtingDate] = useState<FormDate>(
    strToFormDate(value),
  )

  const calendar = useDisclosure()

  const handleChange = (value: FormDate) => {
    setInputtingDate(value)
    onChange?.(formDate(value))
  }

  // 再編集をキャンセルした場合など、入力中の値とvalueが一致しない場合はvalueの値を見た目に反映する
  useEffect(() => {
    if (value !== formDate(inputtingDate)) {
      setInputtingDate(strToFormDate(value))
    }
  }, [value, inputtingDate])

  return (
    <FormWrapperWithoutChakra
      field={field}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      isDisabled={isDisabled}
      isRequired={field.typeDef.validation?.required}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <Box pos="relative" w="max-content">
        <Flex>
          <Flex align="center" mr="4">
            <Input
              w="72px"
              mr="1"
              value={inputtingDate.year ?? ''}
              onChange={e =>
                handleChange({ ...inputtingDate, year: e.target.value })
              }
              isDisabled={isDisabled || isPreview}
            />
            <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
              年
            </Text>
            <Input
              w="56px"
              mr="1"
              value={inputtingDate.month ?? ''}
              onChange={e =>
                handleChange({ ...inputtingDate, month: e.target.value })
              }
              isDisabled={isDisabled || isPreview}
            />
            <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
              月
            </Text>
            <Input
              w="56px"
              mr="1"
              value={inputtingDate.day ?? ''}
              onChange={e =>
                handleChange({ ...inputtingDate, day: e.target.value })
              }
              isDisabled={isDisabled || isPreview}
            />
            <Text as="span" fontSize="sm" fontWeight="bold">
              日
            </Text>
          </Flex>
          {(isPreview || !isDisabled) && (
            <IconButton
              variant="customIconButtonGhost"
              colorScheme="blue"
              aria-label="calendar icon"
              icon={<Calendar color={colors.blue[500]} />}
              onClick={calendar.onOpen}
              isDisabled={isDisabled || isPreview}
            />
          )}
        </Flex>
        {calendar.isOpen && (
          <Flex pos="absolute" right="0" zIndex="1" mt="2">
            <CalendarComponent
              onChange={date => {
                handleChange({
                  year: date.getFullYear().toString(),
                  month: zeroPadding((date.getMonth() + 1).toString(), 2),
                  day: zeroPadding(date.getDate().toString(), 2),
                })
              }}
              onClose={calendar.onClose}
            />
          </Flex>
        )}
      </Box>
    </FormWrapperWithoutChakra>
  )
}
