import { useState, useMemo } from 'react'

export const useOnChangeMultiselect = (initialValues: string[]) => {
  const [values, setValues] = useState<string[]>(initialValues)

  const handlers = useMemo(
    () => ({
      onChangeMultiselect: (ids: string[]) => {
        setValues(ids)
      },
    }),
    [setValues],
  )

  return { values, handlers }
}
