import { useCallback, useMemo } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import {
  QuestionnaireAnswer,
  QuestionnaireAnswerStatus,
} from 'src/modules/entities/questionnaire-answer/entity'
import { QuestionnaireSectionAnswerStatus } from 'src/modules/entities/questionnaire-section-answer/entity'
import { eproPatientUserKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { Complete } from './complete'
import { trialIdParamName } from '../../epro-page'
import { fetchList } from '../request'
import { questionnaireAnswerIdParamName } from '../router'
import { getConfirmRoute, getDetailRoute, getListRoute } from '../routes'

export const CompleteContainer = () => {
  const { trialUid = '', questionnaireAnswerUid = '' } = useParams<{
    [trialIdParamName]: string
    [questionnaireAnswerIdParamName]: string
  }>()

  const { data: questionnaireAnswers, error } = useSWR<
    QuestionnaireAnswer[],
    Error
  >(eproPatientUserKeys.fetchQuestionnaireAnswerList(trialUid), fetchList)

  const questionnaireAnswer = questionnaireAnswers?.find(
    qa => qa.uid === questionnaireAnswerUid,
  )

  const showContinueAnswerButton = useMemo(() => {
    if (!questionnaireAnswers) return false
    return questionnaireAnswers.some(
      qa => qa.status === QuestionnaireAnswerStatus.Unanswered,
    )
  }, [questionnaireAnswers])

  const navigate = useNavigate()
  const onContinue = useCallback(() => {
    if (!questionnaireAnswers) return

    const unansweredQuestionnaireAnswer = questionnaireAnswers.find(
      qa => qa.status === QuestionnaireAnswerStatus.Unanswered,
    )
    if (!unansweredQuestionnaireAnswer) return

    const unansweredQuestionnaireSectionAnswer =
      unansweredQuestionnaireAnswer.sectionAnswers.find(
        sa => sa.status === QuestionnaireSectionAnswerStatus.Unanswered,
      )

    if (unansweredQuestionnaireSectionAnswer) {
      navigate(
        getDetailRoute({
          trialUid,
          questionnaireAnswerUid: unansweredQuestionnaireAnswer.uid,
          questionnaireSectionAnswerUid:
            unansweredQuestionnaireSectionAnswer.uid,
        }),
      )
    } else {
      navigate(
        getConfirmRoute({
          trialUid,
          questionnaireAnswerUid: unansweredQuestionnaireAnswer.uid,
        }),
      )
    }
  }, [navigate, questionnaireAnswers, trialUid])

  const onBackHome = useCallback(() => {
    navigate(
      getListRoute({
        trialUid,
      }),
    )
  }, [navigate, trialUid])

  if (error) return <>{error.message}</>
  if (!questionnaireAnswer) return null

  return (
    <Complete
      questionnaireAnswer={questionnaireAnswer}
      onContinue={onContinue}
      onBackHome={onBackHome}
      showContinueAnswerButton={showContinueAnswerButton}
    />
  )
}
