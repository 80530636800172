import { HttpResponse } from 'msw'
import {
  createBrowseStickyPayloadMock,
  BrowseStickyDocument,
  BrowseStickyMutation,
  BrowseStickyMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const browseStickyHandler = graphQLHandlerFactory<
  BrowseStickyMutation,
  BrowseStickyMutationVariables
>('mutation', BrowseStickyDocument, () => {
  return HttpResponse.json({
    data: { browseSticky: createBrowseStickyPayloadMock() },
  })
})
