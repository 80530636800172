import {
  Box,
  Stack,
  TextProps,
  Wrap,
  WrapItem,
  Text,
  BoxProps,
  HStack,
  Flex,
  Circle,
  Link,
} from '@chakra-ui/react'
import { generatePath } from 'react-router-dom'
import { HasSetBadge } from 'src/components/HasSetBadge/HasSetBadge'
import { Check, ExternalLink } from 'src/components/icon'
import { NotSetBadge } from 'src/components/NotSetBadge/NotSetBadge'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { generatePathWithTrialHospitalParam } from 'src/features/referredPatient/utils/trialHospitalParam'
import { openNewWindow } from 'src/utils/openNewWindow'

import { ExplanationPatient } from '../../types'
import { ExplanationRequestPinSettingButton } from '../ExplanationRequestPinSettingButton/ExplanationRequestPinSettingButton'

type Props = {
  latestRevisionUid: string
  patient: ExplanationPatient
  hasPinSet: boolean
  shouldShowRestPinSettingButton: boolean
  mutateExplanation: () => void
} & BoxProps

const labelStyle: TextProps = {
  fontSize: 'sm',
  color: 'gray.600',
  fontWeight: 'bold',
}

export const ExplanationPatientInformationSection: React.FC<Props> = ({
  latestRevisionUid,
  patient,
  hasPinSet,
  shouldShowRestPinSettingButton,
  mutateExplanation,
  ...rest
}) => {
  const { selectedTrial } = useSelectedTrial()
  return (
    <Box as="section" aria-labelledby="patient_info_heading" {...rest}>
      <HStack spacing="4" px="4" py="2" bg="blue.50">
        <TextWithBar as="h2" id="patient_info_heading">
          患者情報
        </TextWithBar>
        {!!patient.referredPatientUid && (
          <HStack spacing="4">
            <HStack spacing="1.5">
              <Circle size="20px" bg="green.500" color="white">
                <Check size="12px" />
              </Circle>
              <Text as="span" fontSize="sm">
                紹介患者
              </Text>
            </HStack>
            <Link
              as="button"
              fontSize="sm"
              color="blue.500"
              onClick={() => {
                openNewWindow(
                  generatePathWithTrialHospitalParam(
                    generatePath(Paths.ReferredPatient, {
                      trialUid: selectedTrial.uid,
                      referredPatientUid: patient.referredPatientUid!,
                    }),
                    patient.trialHospitalUid,
                  ),
                  'newWindow',
                )
              }}
            >
              <HStack spacing="0.5">
                <span>紹介患者情報</span>
                <ExternalLink />
              </HStack>
            </Link>
          </HStack>
        )}
      </HStack>
      <Wrap p="6" spacing="16">
        <WrapItem>
          <Stack spacing="2">
            <Text {...labelStyle}>候補ID</Text>
            <Text>{patient.candidateId}</Text>
          </Stack>
        </WrapItem>
        <WrapItem textAlign="left">
          <Stack spacing="2">
            <Text {...labelStyle}>症例番号</Text>
            <Text>{patient?.expDiseaseId ?? ''}</Text>
          </Stack>
        </WrapItem>
        {selectedTrial.featureFlags.eConsentNewSignFlow && (
          <WrapItem>
            <Stack spacing="2">
              <Text {...labelStyle}>暗証番号</Text>
              {hasPinSet ? (
                <HStack spacing="3" align="center" h="28px">
                  <HasSetBadge text="設定済" />
                  {shouldShowRestPinSettingButton && (
                    <ExplanationRequestPinSettingButton
                      isReset
                      latestRevisionUid={latestRevisionUid}
                      patientUid={patient.uid}
                      phoneNumber={patient.phoneNumber}
                      mutateExplanation={mutateExplanation}
                    />
                  )}
                </HStack>
              ) : (
                <Flex h="28px" align="center">
                  <NotSetBadge text="未設定" />
                </Flex>
              )}
            </Stack>
          </WrapItem>
        )}
        <WrapItem>
          <Stack spacing="2">
            <Text {...labelStyle}>患者携帯電話番号</Text>
            {selectedTrial.featureFlags.eConsentNewSignFlow &&
            !patient.phoneNumber ? (
              <Flex h="28px" align="center">
                <NotSetBadge text="未設定" />
              </Flex>
            ) : (
              <Text>{patient.phoneNumber}</Text>
            )}
          </Stack>
        </WrapItem>
        <WrapItem>
          <Stack spacing="2">
            <Text {...labelStyle}>患者メールアドレス</Text>
            <Text>{patient.email}</Text>
          </Stack>
        </WrapItem>
      </Wrap>
    </Box>
  )
}
