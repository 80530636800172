import { TemplateFolder } from 'src/modules/entities/template-folder/entity'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { fixtures } from '../../../../../../entities/template-folder/fixture'
import {
  parse,
  TemplateFolderResponse,
} from '../../../../../../entities/template-folder/request'

type SortTemplateFolderParam = {
  uid: string
  index: number
}

type SortTemplateFolderParams = {
  template_folders: SortTemplateFolderParam[]
}

export const submitSortTemplateFolder = async ({
  trialUid,
  params,
}: {
  trialUid: string
  params: SortTemplateFolderParams
}): Promise<TemplateFolder[]> => {
  if (IS_MOCK_MODE) {
    return fixtures
  }

  const resp = await requestPut<TemplateFolderResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/template_folders/sort`,
    params: params,
  })

  return resp.data.map(parse)
}

export const toParams = (
  templateFolders: TemplateFolder[],
): SortTemplateFolderParams => {
  return {
    template_folders: templateFolders.map(tf => {
      return {
        uid: tf.uid,
        index: templateFolders.indexOf(tf),
      }
    }),
  }
}
