import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { getSingle } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'

import { ConfirmAnswerStateDateModal } from './confirm-answer-state-date-modal'
import { useConfirmAnswerStartDate } from './use-confirm-answer-start-date'
import { patientIdParamName } from '../../../router'

type Props = {
  onClose: () => void
}

export const ConfirmAnswerStateDateModalContainer: React.FC<Props> = props => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()
  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )!

  const { request, requesting, errorMessage } = useConfirmAnswerStartDate()
  const onSubmit = useCallback(async () => {
    await request({ trialUid: selectedTrial.uid, patientUid: patient.uid })
    props.onClose()
  }, [patient.uid, props, request, selectedTrial.uid])

  return (
    <ConfirmAnswerStateDateModal
      requesting={requesting}
      errorMessage={errorMessage}
      answerStateDate={patient.answerStartDate!}
      onSubmit={onSubmit}
      onClose={props.onClose}
    />
  )
}
