import React, { useCallback, useMemo, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Selectbox } from 'src/components/form-redesigned/selectbox'
import { usePane } from 'src/hooks/use-pane'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import { SERVER_URL } from 'src/modules/server/const'
import styled from 'styled-components'

type QuestionnaireUid = string

interface Props {
  questionnaires: Questionnaire[]
  onSelectedQuestionnaireChanged: (
    selectedQuestionnaireUid: QuestionnaireUid,
  ) => void
  onSubmit: (selectedQuestionnaireUid: QuestionnaireUid | undefined) => void
  errorMessage?: string
}

const PROExportPane = (props: Props) => {
  const {
    questionnaires,
    onSelectedQuestionnaireChanged,
    onSubmit,
    errorMessage,
  } = props

  const questionnaireSelectboxItems = useMemo(
    () => questionnaires.map(q => ({ value: q.uid, name: q.title })),
    [questionnaires],
  )

  const [selectedQuestionnaireUid, setSelectedQuestionnaireUid] =
    useState<QuestionnaireUid>()

  const onQuestionnaireChanged = useCallback(
    (uid: QuestionnaireUid) => {
      setSelectedQuestionnaireUid(uid)
      onSelectedQuestionnaireChanged(uid)
    },
    [setSelectedQuestionnaireUid, onSelectedQuestionnaireChanged],
  )

  const onExportButtonClicked = useCallback(() => {
    onSubmit(selectedQuestionnaireUid)
  }, [onSubmit, selectedQuestionnaireUid])

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" mb={10}>
        回答出力
      </Text>
      <Text fontWeight="bold" mb={3}>
        質問票名
      </Text>
      <Selectbox<QuestionnaireUid>
        items={questionnaireSelectboxItems}
        selectedValue={selectedQuestionnaireUid}
        placeholder="質問票を選択してください"
        onChange={onQuestionnaireChanged}
      />
      <MessageSect>
        {errorMessage && <Message type="error" message={errorMessage} />}
      </MessageSect>
      <PaneFooter>
        <Button
          text="出力する"
          size="S"
          buttonType="important"
          onClick={onExportButtonClicked}
        />
      </PaneFooter>
    </>
  )
}

export const usePROExportPane = (
  trialUid: string,
  questionnaires: Questionnaire[],
  patientUid?: string,
  onExported?: () => void,
) => {
  const {
    handlers: { openPane, closePane },
    renderPane,
  } = usePane({ paneType: 'normal', closeOnOutsideClick: true })

  const [errorMessage, setErrorMessage] = useState<string>()

  const onSubmit = useCallback(
    (selectedQuestionnaireUid: QuestionnaireUid | undefined) => {
      if (!selectedQuestionnaireUid) {
        setErrorMessage('質問票が選択されていません')
        return
      }
      const url =
        `${SERVER_URL}/trials/${trialUid}/questionnaires/${selectedQuestionnaireUid}/pro/export` +
        (patientUid ? `/${patientUid}` : '')
      window.location.href = url
      closePane()
      onExported?.()
    },
    [trialUid, patientUid, closePane, onExported, setErrorMessage],
  )

  const onSelectedQuestionnaireChanged = useCallback(
    () => setErrorMessage(undefined),
    [setErrorMessage],
  )

  const paneComponent = useMemo(
    () =>
      renderPane(
        <PROExportPane
          questionnaires={questionnaires}
          onSelectedQuestionnaireChanged={onSelectedQuestionnaireChanged}
          onSubmit={onSubmit}
          errorMessage={errorMessage}
        />,
      ),
    [
      renderPane,
      questionnaires,
      onSelectedQuestionnaireChanged,
      onSubmit,
      errorMessage,
    ],
  )

  const open = useCallback(() => {
    setErrorMessage(undefined)
    openPane()
  }, [setErrorMessage, openPane])

  return {
    paneComponent,
    open,
  }
}

const MessageSect = styled.div`
  margin-top: 13.05px;
`

const PaneFooter = styled.div`
  position: absolute;
  bottom: 45px;
  right: 38px;
`
