import { useCallback, useEffect, useRef, useState } from 'react'

import { Trial } from 'src/features/trial/types'

import { useChallengeSignAuth } from '../api'
import { SignAuthResult } from '../types'

export const useSignAuth = ({
  trialUid,
  explanationSignAuthUid,
}: {
  trialUid: Trial['uid']
  explanationSignAuthUid: string
}) => {
  const [signAuthResult, setSignAuthResult] = useState<SignAuthResult>()

  const [error, setError] = useState<Error>()

  const { request: challengeSignAuth } = useChallengeSignAuth({
    onSuccess: res => {
      setSignAuthResult(res)
    },
    onError: err => {
      setError(err)
    },
  })

  const isFirstMount = useRef(true)

  useEffect(() => {
    if (!isFirstMount.current) return
    isFirstMount.current = false
    const req = async () => {
      await challengeSignAuth({ trialUid, explanationSignAuthUid })
    }
    req()
  }, [challengeSignAuth, trialUid, explanationSignAuthUid])

  const mutateSignAuthResult = useCallback((res: SignAuthResult) => {
    setSignAuthResult(res)
  }, [])

  return {
    signAuthResult,
    mutateSignAuthResult,
    error,
  }
}
