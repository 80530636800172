// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgAccount = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill={props.color ?? 'currentColor'} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M4.5 24.774c0-3.95 2.439-7.385 6.03-9.143 1.369 1.318 3.313 2.14 5.47 2.14 2.157 0 4.102-.822 5.47-2.14 3.591 1.758 6.03 5.193 6.03 9.143 0 .075 0 .15-.003.226H4.503a9.587 9.587 0 0 1-.003-.226Z"
      clipRule="evenodd"
    />
    <circle cx={16} cy={10.5} r={5.5} fill="#fff" />
  </svg>
)
export default SvgAccount
