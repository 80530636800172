import { css } from 'styled-components'

export type Size = 'S' | 'M' //  | 'L'

export const getButtonShape = (props: { size: Size }) => {
  switch (props.size) {
    case 'S': {
      return getShapeStyle({
        fontSize: 14,
        padding: { horizontal: 22, vertical: 6 },
        height: 36,
      })
    }
    case 'M': {
      return getShapeStyle({
        fontSize: 18,
        padding: { horizontal: 30, vertical: 6 },
        height: 48,
      })
    }
    default: {
      return css``
    }
  }
}

const MAX_WIDTH = 228
const getShapeStyle = (props: {
  fontSize: number
  padding: {
    horizontal: number
    vertical: number
  }
  height: number
}) => {
  return css`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-radius: 999px;
    font-size: ${props.fontSize}px;
    padding: ${props.padding.vertical}px ${props.padding.horizontal}px;
    height: ${props.height}px;
    max-width: ${MAX_WIDTH}px;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  `
}
