import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { WSDataExportJob } from '../../types'

export const cancelWSDataExportJob = async ({
  trialUid,
  wsDataExportJobUid,
}: {
  trialUid: Trial['uid']
  wsDataExportJobUid: WSDataExportJob['uid']
}) => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/ws_data_export/{ws_data_export_job_uid}/cancel',
    httpMethod: 'put',
    params: {
      paths: {
        trial_uid: trialUid,
        ws_data_export_job_uid: wsDataExportJobUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useCancelWSExportJob = (
  mutateOptions?: UseMutationOption<void>,
) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    ({ wsDataExportJobUid }: { wsDataExportJobUid: WSDataExportJob['uid'] }) =>
      cancelWSDataExportJob({
        trialUid: selectedTrial.uid,
        wsDataExportJobUid,
      }),
    mutateOptions,
  )
}
