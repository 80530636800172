import { combineReducers } from 'redux'

import { reducer as authReducer, State as AuthState } from './auth/reducer'
import {
  reducer as questionnaireDetailReducer,
  State as QuestionnaireDetailState,
} from './dashboard/trial/detail/epro/questionnaire/detail/redux'
import {
  reducer as questionnaireScheduleReducer,
  State as QuestionnaireScheduleState,
} from './dashboard/trial/detail/epro/questionnaire/schedule/redux'
import {
  reducer as worksheetDetailReducer,
  State as WorksheetDetailState,
} from './dashboard/trial/detail/patient/detail/worksheet/common/detail/redux'
import {
  reducer as patientReducer,
  State as PatientState,
} from './dashboard/trial/detail/patient/reducer'
import {
  reducer as settingsReducer,
  State as SettingsState,
} from './dashboard/trial/detail/settings/reducer'
import {
  reducer as templateDetailReducer,
  State as TemplateDetailState,
} from './dashboard/trial/detail/template/common/detail/redux'
import {
  reducer as entitiesReducer,
  State as EntitiesState,
} from './entities/reducer'
import { reducer as flashReducer, State as FlashState } from './flash/redux'
import {
  reducer as screenSpinnerReducer,
  State as ScreenSpinnerState,
} from './screen-spinner/redux'

export type RootState = {
  auth: AuthState
  entities: EntitiesState
  patient: PatientState
  settings: SettingsState
  templateDetail: TemplateDetailState
  worksheetDetail: WorksheetDetailState
  flash: FlashState
  screenSpinner: ScreenSpinnerState
  questionnaireDetail: QuestionnaireDetailState
  questionnaireSchedule: QuestionnaireScheduleState
}

// NOTE: 独立している reducer の構造はディレクトリの構造に合わせず、できるだけトップレベルにフラットに置く形を取ることとする。
export const rootReducer = () =>
  combineReducers<RootState>({
    auth: authReducer,
    entities: entitiesReducer,
    patient: patientReducer,
    settings: settingsReducer,
    templateDetail: templateDetailReducer,
    worksheetDetail: worksheetDetailReducer,
    flash: flashReducer,
    screenSpinner: screenSpinnerReducer,
    questionnaireDetail: questionnaireDetailReducer,
    questionnaireSchedule: questionnaireScheduleReducer,
  })
