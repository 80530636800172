import { Flex, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { generateUUID } from 'src/utils/generateUUID'
import { mentionRegexOfApiResponse } from 'src/utils/mention'

type Props = {
  /** @[*](id)形式のmentionを含む文字列 */
  message: string
  getDisplayName: (id: string) => string
  isMe: (id: string) => boolean
}

const getContents = (props: Props): React.ReactNode[] => {
  const { message, getDisplayName, isMe } = props
  const result: React.ReactNode[] = []
  const nodes = message.split(mentionRegexOfApiResponse)
  nodes.forEach((node, nodeIndex) => {
    if (!node) return
    if (mentionRegexOfApiResponse.test(node)) {
      // "@[*](346fb0a9-df04-487a-9ea0-dc50b2a49a89)" -> "346fb0a9-df04-487a-9ea0-dc50b2a49a89"
      const id = node.match(
        /\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/,
      )?.[1]
      if (!id) return
      result.push(
        <WrapItem key={generateUUID()}>
          <Flex
            as="span"
            align="center"
            bg={isMe(id) ? 'blue.100' : 'gray.100'}
            color="blue.600"
            borderRadius="full"
            fontSize="xs"
            fontWeight="bold"
            h="5"
            px="1.5"
            data-mention-id={id}
            aria-label={isMe(id) ? 'mention to me' : `mention to ${id}`}
          >
            {`@${getDisplayName(id)}`}
          </Flex>
        </WrapItem>,
      )
    } else {
      const chars = node.split(/(\n)/) // 改行文字も含めて分割
      let lastChar: string | undefined = undefined
      chars.forEach((char, charIndex) => {
        if (!char) return
        // 改行文字が連続している場合、もしくは文頭で改行している場合は高さを持たせる
        const shouldContainHeight =
          lastChar === '\n' || (charIndex === 0 && nodeIndex === 0)

        if (char === '\n') {
          result.push(
            <WrapItem
              key={generateUUID()}
              w="full"
              h={shouldContainHeight ? '5' : 0}
            />,
          )
        } else {
          result.push(
            <WrapItem key={generateUUID()}>
              <Text as="span" fontSize="sm">
                {char}
              </Text>
            </WrapItem>,
          )
        }
        lastChar = char
      })
    }
  })
  return result
}

export const StickyMessageContent: React.FC<Props> = props => {
  return (
    <Wrap
      bg="blue.50"
      borderRadius="base"
      px="3"
      py="1.5"
      align="center"
      spacing="0.5"
    >
      {getContents(props)}
    </Wrap>
  )
}
