import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'

import { worksheetIdParamName } from './worksheet-detail'
import { WorksheetStatus } from '../../../../../../../../entities/worksheet/entity'
import { getSingle as getSingleWorksheet } from '../../../../../../../../entities/worksheet/selector'
import { RootState } from '../../../../../../../../reducer'
import { routes } from '../../../../../../../../routes'
import { usePermission } from '../../../../../common/permission'
import { trialIdParamName } from '../../../../../trial-detail'
import { patientIdParamName } from '../../../patient-detail'
import { actions } from '../detail/redux'

export const useVideoCall = () => {
  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  const { hasPermission } = usePermission()
  const dispatch = useDispatch()

  const worksheet = useSelector((state: RootState) =>
    getSingleWorksheet(state, worksheetUid),
  )

  const canUseVideoCall =
    hasPermission(PERMISSIONS.Worksheet_UseVideoCall) &&
    !!worksheet &&
    worksheet.videoEnabled &&
    worksheet.status !== WorksheetStatus.Disabled

  const onClick = useCallback(async () => {
    if (!canUseVideoCall) return

    const videoCallUrl = `${window.location.origin}${routes.doctorVideoCall}/${trialUid}/${patientUid}/${worksheetUid}`
    window.open(videoCallUrl, 'videoーcall', getFeatures())

    dispatch(actions.startVideoCall())
  }, [canUseVideoCall, dispatch, patientUid, trialUid, worksheetUid])

  return {
    canUseVideoCall,
    onClick,
  }
}

const getFeatures = (width: number = 769, height: number = 524): string => {
  let features =
    'location=no, menubar=no, status=no, scrollbars=no, resizable=no, toolbar=no'

  const winWidth: number = window.screen.width
  const winHeight: number = window.screen.height

  if (width) {
    if (winWidth > width) {
      features += `, left=${(winWidth - width) / 2}`
    } else {
      width = winWidth
    }
    features += `, width=${width}`
  }

  if (height) {
    if (winHeight > height) {
      features += `, top=${(winHeight - height) / 2}`
    } else {
      height = winHeight
    }
    features += `, height=${height}`
  }

  return features
}
