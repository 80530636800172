import React from 'react'

import { Tooltip } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Hospitals,
  Hospital,
  Logline,
} from 'src/components/__legacy__icon/monochrome'
import { blue } from 'src/components/base/color/palette'
import { Modal } from 'src/components/base/modal/modal'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { PERMISSIONS } from 'src/lib/permission'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { Role } from 'src/modules/entities/member/entity'
import { SmallItem, DataType } from 'src/modules/entities/small-item/entity'
import { getSingle } from 'src/modules/entities/template/selector'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { usePermission } from '../../../../../common/permission'
import { templateIdParamName } from '../../template-detail'

type Props = {
  onClose: () => void
}

type VisibilityIconType = 'none' | 'public' | 'restricted'

type Cell = {
  text: string
  isLoglineTitle?: boolean
  indentLevel?: number // 0 もしくは undefined ならそのまま、1 なら 1 段下げる
  roles?: Role[]
  type?: DataType
  visibilityIconType: VisibilityIconType
}

type TableProps = {
  rows: Cell[]
}

type StyledProps = {
  indentLevel?: number
}

export const RoleMapModal: React.FC<Props> = props => {
  const { templateUid = '' } = useParams<{
    [templateIdParamName]: string
  }>()

  const template = useSelector((state: RootState) =>
    getSingle(state, templateUid),
  )

  const { hasPermission } = usePermission()

  const rows = template.mediumItems
    ? template.mediumItems
        .slice()
        .sort((a, b) => a.index - b.index)
        .flatMap(mi =>
          mediumItemCells(mi, hasPermission(PERMISSIONS.Template_SetHospitals)),
        )
    : []

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title="フィールド×ロール対応表" />

      <ModalContent>
        <TableComponent rows={rows} />
      </ModalContent>
    </Modal>
  )
}

const TableComponent: React.FC<TableProps> = props => {
  const { rows } = props
  const modalContentHeight = `${window.innerHeight - 206}px`

  return (
    <Container style={{ maxHeight: modalContentHeight }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell align="center" width="50%">
              フィールド
            </TableHeadCell>
            <TableHeadCell align="center">ロール</TableHeadCell>
            <TableHeadCell align="center">EDC連携対象</TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map(row => {
            return (
              <TableRow key={row.text} borderBottom>
                <TableBodyCell width="50%">
                  <FieldItem indentLevel={row.indentLevel}>
                    <FieldText>
                      {row.text}
                      {row.isLoglineTitle && <Logline color={blue[70]} />}
                    </FieldText>

                    <VisibilityIconImg
                      visibilityIconType={row.visibilityIconType}
                    />
                  </FieldItem>
                </TableBodyCell>

                <TableBodyCell align="center">
                  {row.roles && row.roles.map(role => Role[role]).join('・')}
                </TableBodyCell>

                <TableBodyCell align="center">
                  {dataTypeToDataTypeSymbol(row.type)}
                </TableBodyCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Container>
  )
}

const VisibilityIconImg: React.FC<{
  visibilityIconType: VisibilityIconType
}> = ({ visibilityIconType }) => {
  if (visibilityIconType === 'none') {
    return null
  }

  return (
    <>
      {visibilityIconType === 'public' ? (
        <Tooltip label="未設定" hasArrow>
          <span>
            <Hospitals size="S" color={blue[70]} />
          </span>
        </Tooltip>
      ) : (
        <Tooltip label="設定済" hasArrow>
          <span>
            <Hospital color={blue[70]} />
          </span>
        </Tooltip>
      )}
    </>
  )
}

const dataTypeToDataTypeSymbol = (dataType?: DataType) => {
  switch (dataType) {
    case DataType.Operational:
      return '-'
    case DataType.Clinical:
      return '○'
    default:
      return ''
  }
}

const mediumItemCells = (mediumItem: MediumItem, canEditTemplate: boolean) => {
  const res: Cell[] = []
  res.push({
    text: mediumItem.title,
    isLoglineTitle: mediumItem.isLogline,
    visibilityIconType: 'none',
  })

  const pushSmallItemToRows = (
    smallItem: SmallItem,
    currentIndentLevel: number,
    prefixes: string[],
    visibilityIconType: VisibilityIconType,
  ) => {
    const prefix = prefixes.join(' - ')
    const text = prefix ? `${prefix} - ${smallItem.title}` : smallItem.title
    res.push({
      text,
      indentLevel: currentIndentLevel,
      roles: smallItem.inputRoles,
      type: smallItem.dataType,
      visibilityIconType: visibilityIconType,
    })

    smallItem.choices.forEach(choice => {
      const newPrefixes = prefixes.concat(choice.description)

      const nextSmallItems = choice.smallItemUids.map(
        uid => mediumItem.smallItemMap[uid],
      )

      nextSmallItems.forEach(nextSmallItem => {
        pushSmallItemToRows(nextSmallItem, 2, newPrefixes, visibilityIconType)
      })
    })
  }

  const rootSmallItems = Object.values(mediumItem.smallItemMap)
    .sort((a, b) => a.index - b.index)
    .filter(si => si.parentUid === null)

  rootSmallItems.forEach(si =>
    pushSmallItemToRows(
      si,
      1,
      [],
      computeVisibilityIconType(si, canEditTemplate),
    ),
  )
  return res
}

const computeVisibilityIconType = (
  smallItem: SmallItem,
  canEditTemplate: boolean,
) => {
  if (!canEditTemplate || smallItem.dataType === DataType.Clinical) {
    return 'none'
  }

  return smallItem.viewableHospitalUids.length === 0 ? 'public' : 'restricted'
}

const Container = styled.div`
  width: 100%;
  height: 300px;
`

const FieldItem = styled.div<StyledProps>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: ${props => props.indentLevel && `${24 * props.indentLevel}px`};
  justify-content: space-between;
`

const FieldText = styled.div`
  display: flex;
  align-items: center;
`
