import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePath, Link, useParams, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { useCreateEnvelopeViewUrlWithSignerRole } from 'src/features/explanation/api'
import { useExplanationDocRevision } from 'src/features/explanation/api/getDocRevision'
import {
  ExplanationDocRevision,
  ExplanationSession,
  ExplanationSignerRole,
} from 'src/features/explanation/types'
import { explanationSignerRoleToText } from 'src/features/explanation/utils/explanationSignerRoleToText'
import { getExplanationRoomDocuSignReturnUrl } from 'src/features/explanation/utils/getExplanationRoomDocuSignRedirectUrl'
import { ExplanationRoomEventProvider } from 'src/features/explanationRoom/context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from 'src/features/explanationRoom/hooks/useFloatingVideoCallForMember'
import { useQuery } from 'src/modules/router'
import { getFullName } from 'src/utils/getFullName'

import { UID } from '../../../../../../../utils/brandedUid'
import { ExplanationDocBrowseState } from '../../explanation/entity'
import { ConfirmRoleModal } from '../components/confirm-role-modal'
import { useHospitalSession } from '../hospital-session.hooks'
import { ExplanationRoomLayoutRenewal } from '../layout-renewal'

type Param = {
  sessionUid: ExplanationSession['uid']
  docRevisionUid: ExplanationDocRevision['uid']
}

export const ExplanationRoomDocuSignContainer: React.FC<{}> = () => {
  const navigate = useNavigate()
  const query = useQuery()

  const {
    docRevisionUid = '' as UID<'ExplanationDocRevision'>,
    sessionUid = '' as UID<'ExplanationSession'>,
  } = useParams<Param>()

  const roleStr = query.get('role') ?? undefined
  const role: ExplanationSignerRole = !!roleStr
    ? (roleStr as ExplanationSignerRole)
    : 'Patient' // クエリが未設定の場合患者（対面の理解度確認など）

  const [viewUrl, setViewUrl] = useState<string>()

  const [createViewUrlError, setCreateViewUrlError] = useState<
    Error | undefined
  >()

  const { selectedTrial } = useSelectedTrial()

  const { session } = useHospitalSession({ explanationSessionUid: sessionUid })

  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  const { data: docRevision } = useExplanationDocRevision({
    explanationDocRevisionUid: docRevisionUid,
    revalidateIfStale: false,
  })

  const { request: createEnvelopeViewUrl } =
    useCreateEnvelopeViewUrlWithSignerRole({
      onSuccess: ({ url }) => {
        setViewUrl(url)
      },
      onError: error => {
        setCreateViewUrlError(error)
      },
    })

  const returnUrl = getExplanationRoomDocuSignReturnUrl()

  // DocuSignView用URL生成
  useEffect(() => {
    if (!!viewUrl) return
    if (!docRevision) return
    if (createViewUrlError) return
    ;(async () => {
      await createEnvelopeViewUrl({
        explanationDocRevisionUid: docRevision.uid,
        explanationSessionUid: sessionUid,
        returnUrl,
        signerRole: role,
      })
    })()
  }, [
    docRevision,
    role,
    createEnvelopeViewUrl,
    viewUrl,
    returnUrl,
    sessionUid,
    createViewUrlError,
  ])

  // 署名/回答完了時、iframeの自動遷移を検知して親ページをリダイレクトさせるようにする
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const iframe = iframeRef.current

  const navigateToCompleteDocuSign = useCallback(() => {
    if (!session || !docRevision) return
    const searchParam = new URLSearchParams({
      type: session.type,
      role,
    })
    navigate(
      [
        generatePath(Paths.HospitalExplanationRoomCompleteDocuSign, {
          trialUid: selectedTrial.uid,
          sessionUid: session.uid,
          explanationDocRevisionUid: docRevision.uid,
        }),
        searchParam.toString(),
      ].join('?'),
    )
  }, [session, docRevision, role, navigate, selectedTrial.uid])

  useEffect(() => {
    const handleRedirect = async () => {
      const contentUrl = iframe?.contentWindow?.location.href
      if (!contentUrl) return
      if (!contentUrl.includes(returnUrl)) return
      // DocuSignでReturnURLに遷移されている場合の操作
      // リダイレクトされている場合、'{return_url}?event={event_name}'という形式のURLになる
      // eventは https://developers.docusign.com/docs/esign-rest-api/reference/envelopes/envelopeviews/createrecipient/ 参照
      if (contentUrl.includes('event=ttl_expired')) {
        // キャンセル後に再度アクセスした場合でtokenがexpiredしている場合などは再度URLを生成する
        await createEnvelopeViewUrl({
          explanationDocRevisionUid: docRevisionUid,
          explanationSessionUid: sessionUid,
          returnUrl,
          signerRole: role,
        })
        return
      }

      if (contentUrl.includes('event=signing_complete')) {
        navigateToCompleteDocuSign()
      }
    }

    iframe?.addEventListener('load', handleRedirect)

    return () => {
      iframe?.removeEventListener('load', handleRedirect)
    }
  }, [
    iframe,
    returnUrl,
    navigateToCompleteDocuSign,
    createEnvelopeViewUrl,
    docRevisionUid,
    role,
    sessionUid,
  ])

  const signerMember = useMemo(() => {
    if (docRevision?.type !== 'AgreementForm') return undefined
    // 現状ロールと署名者が1対1の関係なので、roleで署名者を特定する
    // 今後複数人になる可能性がある場合は、署名者のuidをqueryに含めるようにするなどの対応が必要
    return docRevision.signerMembers.find(m => m.role === role)
  }, [docRevision, role])

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
    isOldFlow: true,
  })

  if (!docRevision || !session || !role || !selectedTrialHospitalUid) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={session.explanationRevision.uid}
      sessionUid={sessionUid}
      explanationPatientUid={
        session.explanationRevision.explanation.patient.uid
      }
      patientPhoneNumber={undefined} // 旧署名フローでは不要
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <ExplanationRoomLayoutRenewal
        headerTitle={docRevision.icfDocumentRevisionName}
        session={session}
        sidebarContent={null}
      >
        <Stack h="full" spacing={0}>
          <Grid
            borderBottom="1px solid"
            borderBottomColor="gray.400"
            p="6"
            templateColumns="repeat(3, 1fr)"
          >
            <GridItem>
              <HStack spacing="24px">
                <Button
                  colorScheme="gray"
                  variant="outline"
                  as={Link}
                  to={generatePath(Paths.HospitalExplanationRoomTop, {
                    trialUid: selectedTrial.uid,
                    sessionUid: session.uid,
                  })}
                >
                  説明ルームに戻る
                </Button>

                <Button
                  colorScheme="gray"
                  variant="outline"
                  as={Link}
                  to={{
                    pathname: generatePath(
                      Paths.HospitalExplanationRoomDocument,
                      {
                        trialUid: selectedTrial.uid,
                        sessionUid: session.uid,
                        docRevisionUid: docRevision.uid,
                      },
                    ),
                  }}
                  state={{ notCreateLog: true } as ExplanationDocBrowseState}
                >{`${
                  docRevision.type === 'AgreementForm' ? '署名' : '回答'
                }をキャンセル`}</Button>
              </HStack>
            </GridItem>

            <GridItem>
              {docRevision.type === 'AgreementForm' && (
                <Center w="full" px="24px">
                  {role === 'Patient' ? (
                    <Text fontSize="2xl" color="green.500">
                      <strong>{explanationSignerRoleToText(role)}</strong>
                      が署名中
                    </Text>
                  ) : !!signerMember ? (
                    <Text fontSize="2xl" color="green.500">
                      <strong>{`${explanationSignerRoleToText(
                        role,
                      )}: ${getFullName(signerMember)}`}</strong>
                      が署名中
                    </Text>
                  ) : null}
                </Center>
              )}
            </GridItem>

            <GridItem>
              <Spacer />
            </GridItem>
          </Grid>

          {createViewUrlError ? (
            <Center w="full" h="full">
              {createViewUrlError.message}
            </Center>
          ) : (
            <iframe
              ref={iframeRef}
              title="DocuSign page"
              src={viewUrl}
              width="100%"
              height="100%"
            />
          )}
        </Stack>
        {renderFloatingVideoCall({ right: 24, bottom: 96 })}
      </ExplanationRoomLayoutRenewal>

      <ConfirmRoleModal
        isOpen={isOpen}
        role={role}
        accountName={!!signerMember ? getFullName(signerMember) : ''}
        onClose={onClose}
      />
    </ExplanationRoomEventProvider>
  )
}
