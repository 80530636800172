import React, { memo, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { Spacer } from 'src/components/spacer/spacer'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { ErrorMessage } from './error-message'
import { EditableSection } from './section'
import { actions } from '../../redux'
import { useValidationError } from '../../use-validation-error'

export const EditableSectionGroup: React.FC = memo(() => {
  const dispatch = useDispatch()
  const sections = useSelector(
    (state: RootState) => state.questionnaireDetail.sections,
  )

  const sectionsForSort = [...sections]

  const {
    validationError: { sectionSize: sectionSizeError },
    resetValidationError,
  } = useValidationError()

  const onAddSection = useCallback(() => {
    dispatch(actions.addSection())
    resetValidationError({ target: 'sectionSize' })
  }, [dispatch, resetValidationError])

  return (
    <Container>
      <List>
        {sectionsForSort
          .sort((a, b) => a.index - b.index)
          .map((s, i) => (
            <EditableSection key={s.uid} section={s} index={i} />
          ))}
      </List>
      <Spacer size={20} />
      <AddPageButton
        iconName="add"
        text={'ページを追加'}
        size="S"
        buttonType="important"
        onClick={onAddSection}
      />

      <Spacer size={20} />
      {sectionSizeError && <ErrorMessage>{sectionSizeError}</ErrorMessage>}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

const List = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

const AddPageButton = styled(Button)`
  width: 168px;
`
