import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const resendAuthenticationCode = async ({
  trialUid,
  explanationSignAuthUid,
}: {
  trialUid: string
  explanationSignAuthUid: string
}) => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/resend',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useResendAuthenticationCode = (
  options?: UseMutationOption<void>,
) => {
  return useMutation(
    ({
      trialUid,
      explanationSignAuthUid,
    }: {
      trialUid: string
      explanationSignAuthUid: string
    }) => resendAuthenticationCode({ trialUid, explanationSignAuthUid }),
    options,
  )
}
