import dayjs from 'dayjs'

type Order = 'asc' | 'desc'

// プロパティのvalueがDateかstringであるkeyのみ受け付ける
type DateOrStringKey<T, K> = K extends keyof T
  ? T[K] extends Date | string
    ? K
    : never
  : never

export const sortByDate = <T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: DateOrStringKey<T, K> | ((param: T) => Date | string),
  order: Order,
): T[] => {
  return [...array].sort((a, b) => {
    const aKey = typeof key === 'function' ? key(a) : a[key]
    const bKey = typeof key === 'function' ? key(b) : b[key]
    return (
      dayjs(order === 'asc' ? aKey : bKey).unix() -
      dayjs(order === 'asc' ? bKey : aKey).unix()
    )
  })
}
