import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

interface DisablePatientResponse {
  params: string
}

export const disablePatient = async ({
  trialUid,
  patientUid,
  inputValue,
}: {
  trialUid: string
  patientUid: string
  inputValue: string
}) => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPut<DisablePatientResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/disable`,
    params: {
      reason: inputValue,
    },
  })
  return {}
}
