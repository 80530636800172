import { useCallback, useEffect, useMemo, useState } from 'react'

import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { MessageOption } from 'src/components/layout/message-tab'
import { useOpenModal } from 'src/hooks/use-open-modal'
import { usePrevious } from 'src/hooks/use-previous'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { SERVER_URL } from 'src/modules/server/const'
import { isRequestError } from 'src/modules/server/request'
import { swrKeys } from 'src/modules/swr/key'
import useSWR, { KeyedMutator } from 'swr'

import { useOpenPullDown } from './../../../../../../../../hooks/use-open-pull-down'
import { usePermission } from './../../../../common/permission'
import {
  IcfDocumentCheckRole,
  IcfDocumentDetail,
  IcfDocumentRevision,
  icfDocumentRevisionStatus,
  IcfDocumentSignerRole,
  icfDocumentSignerRoles,
  IcfDocumentType,
  icfDocumentTypes,
  isDocuSignType,
} from './../entity'
import {
  approveIcfDocumentRevision,
  cancelIcfDocumentRevision,
  deleteIcfDocument,
  disableIcfDocumentRevision,
  enableIcfDocumentRevision,
  fetchIcfDocument,
  fetchIcfDocumentRevision,
  getFilePreviewRequestUrl,
  rejectIcfDocumentRevision,
  requestIcfDocumentRevisionApproval,
  updateIcfDocumentRevision,
  UpdateIcfDocumentRevisionParam,
  uploadDocuSignFile,
  uploadTempFile,
  withdrawIcfDocumentRevision,
} from './../request'
import {
  getAddIcfDocumentRevisionRoute,
  getIcfDocumentListRoute,
  getIcfDocumentRevisionVideoStreamRoute,
  icfDocumentUidParamName,
} from '../../routes'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import { COMPLETE_DOCUSIGN_EDIT_URL } from '../complete-docusign-edit/complete-docusign-edit'
import { canTransit } from '../revision-status-transition'
import { IcfDocumentErrors, validateIcfDocument } from '../validate'

type FileUploadInfo = {
  tempFileUid: string | undefined
  tempFileUrl: string | undefined
  dsTemplateId: string | undefined
}

const roleQueryParamValue: Record<
  IcfDocumentSignerRole | IcfDocumentCheckRole,
  string
> = {
  [icfDocumentSignerRoles.Dr]: 'dr',
  [icfDocumentSignerRoles.CRC]: 'crc',
  [icfDocumentSignerRoles.Patient]: 'patient',
  [icfDocumentSignerRoles.Representative]: 'representative',
}

const docuSignNoticeOption: MessageOption = {
  closable: true,
  durationMsec: 15000,
}

export const useEditIcfDocument = () => {
  const { uid: trialUid, trialHospitals } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { icfDocumentUid = '' } = useParams<{
    [icfDocumentUidParamName]: string
  }>()
  const { hasPermission } = usePermission()

  const [latestRevision, setLatestRevision] = useState<
    IcfDocumentRevision | undefined
  >(undefined)
  const [fileUploadInfo, setFileUploadInfo] = useState<FileUploadInfo>({
    tempFileUid: undefined,
    tempFileUrl: undefined,
    dsTemplateId: undefined,
  })

  const [errors, setErrors] = useState<IcfDocumentErrors>({})

  const { showSuccess, showInfo, showWarning, showError } = useFlash()

  const { data: icfDocument, mutate: mutateDocument } = useSWR(
    selectedTrialHospitalUid !== undefined
      ? swrKeys.fetchIcfDocument({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentUid,
        })
      : null,
    () =>
      fetchIcfDocument({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentUid: icfDocumentUid,
      }),
  )

  const { data: latestRevisionData, mutate: mutateLatestRevision } = useSWR(
    icfDocument && selectedTrialHospitalUid
      ? swrKeys.fetchIcfDocumentRevision({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: icfDocument.latestRevisionUid,
        })
      : null,
    () =>
      fetchIcfDocumentRevision({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentRevisionUid: icfDocument?.latestRevisionUid ?? '',
      }),
  )

  const hasSetDocuSignRole = useMemo(() => {
    if (!latestRevisionData) {
      return false
    }

    return (
      latestRevisionData.signerRoles.length >= 1 ||
      latestRevisionData.checkRoles.length >= 1
    )
  }, [latestRevisionData])

  const previousRevisionData = usePrevious(latestRevision)

  useEffect(() => {
    if (!previousRevisionData || !latestRevisionData) {
      return
    }

    if (
      previousRevisionData.uid === latestRevisionData.uid &&
      previousRevisionData.dsTemplateModifiedAt !==
        latestRevisionData.dsTemplateModifiedAt
    ) {
      switch (latestRevisionData.type) {
        case icfDocumentTypes.AgreementForm:
          showInfo('署名ロールが更新されました', docuSignNoticeOption)
          break
        case icfDocumentTypes.CheckUnderstanding:
          showInfo('確認項目が更新されました', docuSignNoticeOption)
          break
      }
    }
  }, [latestRevisionData, previousRevisionData, showInfo])

  //変更可能なrevisionはローカルstateで保持する。
  //swrのキャッシュが更新されたタイミング（fetch時、mutate時）でキャッシュの値とローカルstateを一致させる
  useEffect(() => {
    setLatestRevision(latestRevisionData)
  }, [latestRevisionData])

  //編集を再開したらエラーをクリアする
  useEffect(() => {
    setErrors({})
  }, [latestRevision])

  const shouldSelectHospital = hasPermission(PERMISSIONS.Hospital_BelongAll)

  const hospitalName = useMemo(() => {
    const hospital = trialHospitals.find(
      hospital => hospital.uid === selectedTrialHospitalUid,
    )

    return hospital?.name ?? ''
  }, [selectedTrialHospitalUid, trialHospitals])

  const latestFilePreviewUrl = useMemo(() => {
    if (!latestRevision || !selectedTrialHospitalUid) {
      return ''
    }
    if (fileUploadInfo.tempFileUrl !== undefined) {
      return fileUploadInfo.tempFileUrl
    }

    return latestRevision.type === icfDocumentTypes.Video
      ? getIcfDocumentRevisionVideoStreamRoute({
          trialUid,
          icfDocumentRevisionUid: latestRevision.uid,
        })
      : getFilePreviewRequestUrl({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: latestRevision.uid,
        })
  }, [
    fileUploadInfo.tempFileUrl,
    selectedTrialHospitalUid,
    latestRevision,
    trialUid,
  ])

  const onRedirectDocuSignEdit = useCallback(() => {
    if (!selectedTrialHospitalUid || !latestRevision) {
      return
    }
    if (!latestRevision.dsTemplateId && !fileUploadInfo.dsTemplateId) {
      return
    }

    const returnUrl = encodeURIComponent(
      `${COMPLETE_DOCUSIGN_EDIT_URL}?trial-hospital=${selectedTrialHospitalUid}`,
    )
    const baseUrl = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${selectedTrialHospitalUid}/docusign_templates`
    const url = fileUploadInfo.dsTemplateId
      ? `${baseUrl}/${fileUploadInfo.dsTemplateId}/edit?return_url=${returnUrl}`
      : `${baseUrl}/${latestRevision.dsTemplateId}/edit?return_url=${returnUrl}`
    window.open(url, '_blank', 'noreferrer')
  }, [latestRevision, selectedTrialHospitalUid, trialUid, fileUploadInfo])

  const onRedirectDocuSignAgreementFormPreview = useCallback(
    (role: IcfDocumentSignerRole | IcfDocumentCheckRole) => {
      if (!selectedTrialHospitalUid || !latestRevision?.dsTemplateId) {
        return
      }

      const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${selectedTrialHospitalUid}/docusign_templates/${latestRevision.dsTemplateId}/preview/${roleQueryParamValue[role]}?return_url=${COMPLETE_DOCUSIGN_EDIT_URL}`
      window.open(url, '_blank', 'noreferrer')
    },
    [latestRevision, selectedTrialHospitalUid, trialUid],
  )

  const navigate = useNavigate()

  const pullDownState = useOpenPullDown()
  const deleteModalState = useOpenModal()
  const disableModalState = useOpenModal()
  const enableModalState = useOpenModal()

  const onChangeName = useCallback(
    (value: string) => {
      if (!latestRevision) {
        return
      }

      setLatestRevision({ ...latestRevision, name: value })
    },
    [latestRevision],
  )

  const onChangeVersion = useCallback(
    (value: string) => {
      if (!latestRevision) {
        return
      }

      setLatestRevision({ ...latestRevision, version: value })
    },
    [latestRevision],
  )

  const onChangeDocumentType = useCallback(
    (type: IcfDocumentType) => {
      if (!latestRevision) {
        return
      }
      if (latestRevisionData?.type !== icfDocumentTypes.Undefined) {
        return
      }

      if (type === icfDocumentTypes.AgreementForm) {
        setLatestRevision({
          ...latestRevision,
          type,
          isRequiredPatientSign: true,
        })
        return
      }

      setLatestRevision({
        ...latestRevision,
        type,
        isRequiredPatientSign: false,
      })
    },
    [latestRevision, latestRevisionData],
  )

  const onChangeRequiredPatientSign = useCallback(() => {
    if (!latestRevision) {
      return
    }

    setLatestRevision({
      ...latestRevision,
      isRequiredPatientSign: !latestRevision.isRequiredPatientSign,
    })
  }, [latestRevision])

  const listPath = useMemo(
    () =>
      getIcfDocumentListRoute({
        trialUid,
        trialHospitalUid: shouldSelectHospital
          ? selectedTrialHospitalUid
          : undefined,
      }),
    [shouldSelectHospital, selectedTrialHospitalUid, trialUid],
  )

  const uploadDocuSign = useCallback(
    async ({
      trialUid,
      trialHospitalUid,
      formData,
      type,
    }: {
      trialUid: string
      trialHospitalUid: string
      formData: FormData
      type: IcfDocumentType
    }) => {
      try {
        const docuSignResp = await uploadDocuSignFile({
          trialUid,
          trialHospitalUid,
          params: {
            formData,
            type,
          },
        })
        setFileUploadInfo({
          tempFileUid: docuSignResp.tempFileUid,
          tempFileUrl: docuSignResp.fileUrl,
          dsTemplateId: docuSignResp.dsTemplateId,
        })
      } catch (e) {
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [showError],
  )

  const uploadTemp = useCallback(
    async ({
      trialUid,
      trialHospitalUid,
      params,
    }: {
      trialUid: string
      trialHospitalUid: string
      params: FormData
    }) => {
      try {
        const tempFileResp = await uploadTempFile({
          trialUid,
          trialHospitalUid,
          params,
        })
        setFileUploadInfo({
          tempFileUid: tempFileResp.tempFileUid,
          tempFileUrl: tempFileResp.fileUrl,
          dsTemplateId: undefined,
        })
      } catch (e) {
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [showError],
  )

  const onSelectFile = useCallback(
    async (file: File) => {
      if (!latestRevision) {
        return
      }
      if (!selectedTrialHospitalUid) {
        throw new Error('trialHospitalUid does not set')
      }

      const fileSizeError = validateIcfDocument({
        key: 'fileSize',
        value: file,
      })
      if (fileSizeError !== null) {
        setErrors(fileSizeError)
        return
      }

      setErrors({ ...errors, fileSize: undefined })

      const formData = new FormData()
      formData.append('file', file)

      isDocuSignType(latestRevision.type)
        ? await uploadDocuSign({
            trialUid,
            trialHospitalUid: selectedTrialHospitalUid,
            formData: formData,
            type: latestRevision.type,
          })
        : await uploadTemp({
            trialUid,
            trialHospitalUid: selectedTrialHospitalUid,
            params: formData,
          })
      setLatestRevision({ ...latestRevision, fileName: file.name })
    },
    [
      latestRevision,
      errors,
      selectedTrialHospitalUid,
      trialUid,
      uploadDocuSign,
      uploadTemp,
    ],
  )

  const canUpdate = useMemo(() => {
    if (!latestRevisionData) {
      return false
    }
    //latestRevisionData.statusによるハンドリング

    return (
      !equal(latestRevision, latestRevisionData) ||
      fileUploadInfo.tempFileUrl !== undefined
    )
  }, [fileUploadInfo.tempFileUrl, latestRevision, latestRevisionData])

  const canSetDocuSignRole = useMemo(() => {
    if (!latestRevisionData) {
      return false
    }

    return (
      !!latestRevisionData.fileName && fileUploadInfo.tempFileUrl === undefined
    )
  }, [fileUploadInfo.tempFileUrl, latestRevisionData])

  const canChangeDocumentType = useMemo(() => {
    if (!latestRevisionData) {
      return false
    }

    if (!!latestRevision?.fileName) {
      return false
    }

    //オリジナル（DBに保存されているデータ）の文書タイプが未指定の場合は文書タイプの設定が可能
    return latestRevisionData.type === icfDocumentTypes.Undefined
  }, [latestRevision?.fileName, latestRevisionData])

  const onValidate = useCallback((): boolean => {
    if (!latestRevision) {
      return false
    }
    const errors = validateIcfDocument(
      { key: 'name', value: latestRevision.name },
      { key: 'version', value: latestRevision.version },
      { key: 'documentType', value: latestRevision.type },
      { key: 'fileName', value: latestRevision.fileName },
    )
    if (errors !== null) {
      setErrors(errors)
      return false
    }

    return true
  }, [latestRevision])

  const onSubmitUpdate = useCallback(async () => {
    if (!icfDocument || !latestRevision) {
      return
    }
    if (!selectedTrialHospitalUid) {
      throw new Error('trialHospitalUid does not set')
    }

    if (!onValidate()) {
      return
    }

    // 一時ファイルの情報をリセット
    setFileUploadInfo({
      tempFileUid: undefined,
      dsTemplateId: undefined,
      tempFileUrl: undefined,
    })

    //バージョンが1~99の整数値で設定された場合は"n.0"の形式に変換して更新する
    const validIntegerRegex = /^[1-9][0-9]?$/

    try {
      const params: UpdateIcfDocumentRevisionParam = {
        version: !!latestRevision.version.match(validIntegerRegex)
          ? `${latestRevision.version}.0`
          : latestRevision.version,
        name: latestRevision.name,
        fileName: latestRevision.fileName,
        type: latestRevision.type,
        isRequiredPatientSign: latestRevision.isRequiredPatientSign,
        tempFileUid: fileUploadInfo.tempFileUid,
        dsTemplateId: fileUploadInfo.dsTemplateId,
      }

      const updated = await updateIcfDocumentRevision({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid,
        icfDocumentRevisionUid: icfDocument.latestRevisionUid,
        params,
      })
      showSuccess('一時保存しました')

      // 動画ファイルが更新されていたらメッセージを表示
      if (
        previousRevisionData != null &&
        latestRevision.type === icfDocumentTypes.Video &&
        fileUploadInfo.tempFileUid != null
      ) {
        showWarning(
          [
            '動画ファイルの更新には時間がかかるため、更新直後のプレビュー時には更新前の動画が表示されます。',
            'しばらく経った後に更新後の動画がプレビューできることを再度ご確認ください。',
          ].join('\n'),
          {
            closable: true,
            durationMsec: 15000,
          },
        )
      }

      mutateLatestRevision(updated)
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [
    icfDocument,
    latestRevision,
    onValidate,
    mutateLatestRevision,
    trialUid,
    selectedTrialHospitalUid,
    fileUploadInfo.tempFileUid,
    fileUploadInfo.dsTemplateId,
    showSuccess,
    showError,
    showWarning,
    previousRevisionData,
  ])

  const onSubmitDelete = useCallback(async () => {
    try {
      if (!selectedTrialHospitalUid) {
        throw new Error('trialHospitalUid does not set')
      }
      await deleteIcfDocument({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid,
        icfDocumentUid,
      })
      showSuccess('文書を削除しました')
      navigate(listPath)
    } catch (e) {
      if (isRequestError(e)) {
        deleteModalState.handlers.closeModal()
        showError(e.message)
      }

      throw e
    }
  }, [
    selectedTrialHospitalUid,
    trialUid,
    icfDocumentUid,
    showSuccess,
    navigate,
    listPath,
    deleteModalState.handlers,
    showError,
  ])

  const onDisable = useCallback(
    async (comment: string) => {
      if (!latestRevision) {
        return
      }
      try {
        if (!selectedTrialHospitalUid) {
          throw new Error('trialHospitalUid does not set')
        }
        await disableIcfDocumentRevision({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: latestRevision.uid,
          params: { comment },
        })
        disableModalState.handlers.closeModal()
        mutateLatestRevision()
        showSuccess('文書を無効化しました')
      } catch (e) {
        if (isRequestError(e)) {
          disableModalState.handlers.closeModal()
          showError(e.message)
        }

        throw e
      }
    },
    [
      trialUid,
      selectedTrialHospitalUid,
      latestRevision,
      showSuccess,
      showError,
      disableModalState.handlers,
      mutateLatestRevision,
    ],
  )

  const onEnable = useCallback(
    async (comment: string) => {
      if (!latestRevision) {
        return
      }
      try {
        if (!selectedTrialHospitalUid) {
          throw new Error('trialHospitalUid does not set')
        }
        await enableIcfDocumentRevision({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: latestRevision.uid,
          params: { comment },
        })
        enableModalState.handlers.closeModal()
        mutateLatestRevision()
        showSuccess('文書を有効化しました')
      } catch (e) {
        if (isRequestError(e)) {
          enableModalState.handlers.closeModal()
          showError(e.message)
        }

        throw e
      }
    },
    [
      trialUid,
      selectedTrialHospitalUid,
      latestRevision,
      showSuccess,
      showError,
      enableModalState.handlers,
      mutateLatestRevision,
    ],
  )

  const onCancelRevision = useCallback(async () => {
    if (!latestRevision) {
      return
    }
    if (!selectedTrialHospitalUid) {
      throw new Error('trialHospitalUid does not set')
    }

    try {
      await cancelIcfDocumentRevision({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid,
        icfDocumentRevisionUid: latestRevision.uid,
      })
      mutateDocument() //documentを再フェッチしてlatestRevisionUidを更新 -> 1つ前のrevisionを画面に表示
      showSuccess('改版を取り消しました')
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [
    latestRevision,
    selectedTrialHospitalUid,
    trialUid,
    mutateDocument,
    showSuccess,
    showError,
  ])

  const navigateToAddRevision = useCallback(() => {
    navigate(
      getAddIcfDocumentRevisionRoute({
        trialUid,
        trialHospitalUid: shouldSelectHospital
          ? selectedTrialHospitalUid
          : undefined,
        icfDocumentUid,
      }),
    )
  }, [
    navigate,
    trialUid,
    shouldSelectHospital,
    selectedTrialHospitalUid,
    icfDocumentUid,
  ])

  const approvalHandlers = useApproval({
    trialUid,
    trialHospitalUid: selectedTrialHospitalUid,
    icfDocumentRevisionUid: icfDocument?.latestRevisionUid ?? '',
    listPath,
    mutateLatestRevision,
    mutateDocument,
  })

  const latestRevisionStatus = useRevisionStatus({
    latestRevision: latestRevisionData,
  })

  const hasMultipleRevisionHistories = useMemo(() => {
    if (!icfDocument) {
      return false
    }

    return icfDocument.revisions.length >= 2
  }, [icfDocument])

  const hasTempFile: boolean = !!fileUploadInfo.tempFileUrl

  // FIXME: onChange系も別hooksに切り出す
  return {
    icfDocument,
    latestRevision,
    errors,
    listPath,
    shouldSelectHospital,
    hospitalName,
    latestFilePreviewUrl,
    canUpdate,
    canChangeDocumentType,
    canSetDocuSignRole,
    hasSetDocuSignRole,
    approvalHandlers,
    pullDownState,
    deleteModalState,
    disableModalState,
    enableModalState,
    latestRevisionStatus,
    hasMultipleRevisionHistories,
    hasTempFile,
    onRedirectDocuSignEdit,
    onRedirectDocuSignAgreementFormPreview,
    onChangeName,
    onChangeVersion,
    onChangeDocumentType,
    onChangeRequiredPatientSign,
    onSelectFile,
    onValidate,
    onSubmitUpdate,
    onSubmitDelete,
    onDisable,
    onEnable,
    onCancelRevision,
    navigateToAddRevision,
  }
}

const useApproval = ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  listPath,
  mutateLatestRevision,
  mutateDocument,
}: {
  trialUid: string
  trialHospitalUid: string | undefined
  icfDocumentRevisionUid: string
  listPath: string
  mutateLatestRevision: KeyedMutator<IcfDocumentRevision>
  mutateDocument: KeyedMutator<IcfDocumentDetail>
}) => {
  const { showSuccess, showError } = useFlash()
  const navigate = useNavigate()

  const requestApprovalModalState = useOpenModal()
  const withdrawModalState = useOpenModal()
  const approveModalState = useOpenModal()
  const rejectModalState = useOpenModal()

  const onRequestApproval = useCallback(
    async (comment: string) => {
      try {
        if (!trialHospitalUid) {
          throw new Error('trialHospitalUid does not set')
        }

        await requestIcfDocumentRevisionApproval({
          trialUid,
          trialHospitalUid,
          icfDocumentRevisionUid,
          params: {
            comment,
          },
        })
        showSuccess('承認依頼が完了しました')
        mutateLatestRevision()
        navigate(listPath)
      } catch (e) {
        requestApprovalModalState.handlers.closeModal()
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [
      trialHospitalUid,
      trialUid,
      icfDocumentRevisionUid,
      showSuccess,
      mutateLatestRevision,
      navigate,
      listPath,
      requestApprovalModalState.handlers,
      showError,
    ],
  )

  const onWithdrawApprovalRequest = useCallback(
    async (comment: string) => {
      try {
        if (!trialHospitalUid) {
          throw new Error('trialHospitalUid does not set')
        }

        await withdrawIcfDocumentRevision({
          trialUid,
          trialHospitalUid,
          icfDocumentRevisionUid,
          params: {
            comment,
          },
        })
        showSuccess('承認依頼を取り下げました')
        mutateLatestRevision()
        mutateDocument()
        withdrawModalState.handlers.closeModal()
      } catch (e) {
        withdrawModalState.handlers.closeModal()
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [
      trialUid,
      trialHospitalUid,
      icfDocumentRevisionUid,
      mutateLatestRevision,
      mutateDocument,
      showSuccess,
      showError,
      withdrawModalState,
    ],
  )

  const onApprove = useCallback(
    async (comment: string) => {
      try {
        if (!trialHospitalUid) {
          throw new Error('trialHospitalUid does not set')
        }

        await approveIcfDocumentRevision({
          trialUid,
          trialHospitalUid,
          icfDocumentRevisionUid,
          params: {
            comment,
          },
        })
        showSuccess('承認が完了しました')
        mutateLatestRevision()
        navigate(listPath)
      } catch (e) {
        approveModalState.handlers.closeModal()
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [
      trialHospitalUid,
      trialUid,
      icfDocumentRevisionUid,
      showSuccess,
      mutateLatestRevision,
      navigate,
      listPath,
      approveModalState.handlers,
      showError,
    ],
  )

  const onReject = useCallback(
    async (comment: string) => {
      try {
        if (!trialHospitalUid) {
          throw new Error('trialHospitalUid does not set')
        }

        await rejectIcfDocumentRevision({
          trialUid,
          trialHospitalUid,
          icfDocumentRevisionUid,
          params: {
            comment,
          },
        })
        showSuccess('承認を却下しました')
        mutateLatestRevision()
        navigate(listPath)
      } catch (e) {
        rejectModalState.handlers.closeModal()
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [
      trialHospitalUid,
      trialUid,
      icfDocumentRevisionUid,
      showSuccess,
      mutateLatestRevision,
      navigate,
      listPath,
      rejectModalState.handlers,
      showError,
    ],
  )

  return {
    onRequestApproval,
    onWithdrawApprovalRequest,
    onApprove,
    onReject,
    requestApprovalModalState,
    withdrawModalState,
    approveModalState,
    rejectModalState,
  }
}

const useRevisionStatus = ({
  latestRevision,
}: {
  latestRevision: IcfDocumentRevision | undefined
}) => {
  const { hasPermission } = usePermission()

  const canEdit = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    return canTransit(
      latestRevision.latestStatus,
      icfDocumentRevisionStatus.Saved,
    )
  }, [latestRevision])

  const canRequestApproval = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    return canTransit(
      latestRevision.latestStatus,
      icfDocumentRevisionStatus.WaitApproving,
    )
  }, [latestRevision])

  const canApprove = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    if (!hasPermission(PERMISSIONS.Icfdocument_ApproveRevision)) {
      return false
    }

    return canTransit(
      latestRevision.latestStatus,
      icfDocumentRevisionStatus.Approved,
    )
  }, [latestRevision, hasPermission])

  const canReject = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    if (!hasPermission(PERMISSIONS.Icfdocument_RejectRevision)) {
      return false
    }

    return canTransit(
      latestRevision.latestStatus,
      icfDocumentRevisionStatus.Rejected,
    )
  }, [latestRevision, hasPermission])

  const canWithdraw = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    if (!hasPermission(PERMISSIONS.Icfdocument_WithdrawRevision)) {
      return false
    }

    return canTransit(
      latestRevision.latestStatus,
      icfDocumentRevisionStatus.Withdrawn,
    )
  }, [latestRevision, hasPermission])

  const canDisable = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    return canTransit(
      latestRevision.latestStatus,
      icfDocumentRevisionStatus.Disabled,
    )
  }, [latestRevision])

  const canAddRevision = useMemo(() => {
    if (!latestRevision) {
      return false
    }

    if (!hasPermission(PERMISSIONS.Icfdocument_CreateRevision)) {
      return false
    }

    return (
      latestRevision.latestStatus === icfDocumentRevisionStatus.Approved ||
      latestRevision.latestStatus === icfDocumentRevisionStatus.Enabled
    )
  }, [latestRevision, hasPermission])

  return {
    canEdit,
    canRequestApproval,
    canApprove,
    canReject,
    canWithdraw,
    canDisable,
    canAddRevision,
  }
}
