import { Box, Flex, Link, Stack, VStack } from '@chakra-ui/react'
import { Field, Schema } from '@micin-jp/chicken-schema'
import { useAtom, useAtomValue } from 'jotai'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import {
  CheckboxFieldForm,
  DateFieldForm,
  LabelFieldForm,
  NumberFieldForm,
  RadioFieldForm,
  SelectMenuFieldForm,
  TextareaFieldForm,
  TextFieldForm,
  TimeFieldForm,
} from 'src/lib/chicken-schema/components'
import { FileFieldForm } from 'src/lib/chicken-schema/components/FileFieldForm/FileFieldForm'
import {
  NotSectionField,
  withValidateFieldType,
} from 'src/lib/chicken-schema/utils'
import { assertNever } from 'src/utils/assertNever'

import {
  criteriaValueErrorsFamily,
  criteriaValueFamily,
  isCriteriaValuesChangedAtom,
  isReEditModeAtom,
} from '../../atom'

type Props = {
  schema: Schema
  isDisabled: boolean
  fileViewerPath: (param: {
    fid: string
    fieldIndex: number
    order: number
  }) => string
}

export const EditCriteriaForm: React.FC<Props> = ({
  schema,
  isDisabled,
  fileViewerPath,
}) => {
  return (
    <>
      <Navigation schema={schema} />
      <Flex direction="column" gap={12} mt="6" w="full">
        {schema.fields.map(field => (
          <FieldComponent
            key={field.fid}
            field={field}
            isDisabled={isDisabled}
            fileViewerPath={fileViewerPath}
          />
        ))}
      </Flex>
    </>
  )
}

const Navigation: React.FC<{ schema: Schema }> = ({ schema }) => {
  return (
    <Box px="4" py="2" bg="blue.50" borderRadius="sm">
      <Flex gap={6} minH="1.5rem">
        {schema.fields
          .filter(
            ({ typeDef: { type } }) =>
              type === 'Section' || type === 'RepeatableSection',
          )
          .map(section => (
            <Flex key={section.fid} align="center" gap={1}>
              <Box h="20px" minW="4px" bg="blue.300" />
              <Link
                key={section.fid}
                as="button"
                textAlign="start"
                color="blue.500"
                onClick={() => {
                  const target = document.getElementById(section.fid)
                  if (target) {
                    target.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
                {section.name}
              </Link>
            </Flex>
          ))}
      </Flex>
    </Box>
  )
}

const FieldComponent: React.FC<{
  field: Field
  isDisabled: boolean
  index?: number
  fileViewerPath: (param: {
    fid: string
    fieldIndex: number
    order: number
  }) => string
}> = ({ field, isDisabled, index = 1, fileViewerPath }) => {
  const type = field.typeDef.type
  return (
    <>
      {type === 'Section' || type === 'RepeatableSection' ? (
        <Stack spacing="3" as="section" aria-label={field.name} id={field.fid}>
          <TextWithBar>{field.name}</TextWithBar>
          <VStack px="2" spacing="8" align="start">
            {field.typeDef.fields.map(field => (
              <FieldComponent
                key={field.fid}
                field={field}
                index={index}
                isDisabled={isDisabled}
                fileViewerPath={fileViewerPath}
              />
            ))}
          </VStack>
        </Stack>
      ) : (
        <FormComponent
          field={field as NotSectionField}
          isDisabled={isDisabled}
          fieldIndex={index}
          fileViewerPath={fileViewerPath}
        />
      )}
    </>
  )
}

const FormComponent: React.FC<{
  field: NotSectionField
  fieldIndex: number
  isDisabled: boolean
  fileViewerPath: (param: {
    fid: string
    fieldIndex: number
    order: number
  }) => string
}> = ({ field, fieldIndex, isDisabled, fileViewerPath }) => {
  const [value, setValue] = useAtom(
    criteriaValueFamily({ fid: field.fid, fieldIndex: 1 }),
  )

  const isReEditMode = useAtomValue(isReEditModeAtom)

  const isEditing = useAtomValue(isCriteriaValuesChangedAtom)

  const valueErrors = useAtomValue(criteriaValueErrorsFamily(field.fid))

  // 再編集時は全てエラー
  const errorMessage =
    valueErrors.length > 0
      ? valueErrors
          .filter(
            e =>
              e.validationType === 'Required' ||
              (isReEditMode && e.validationType === 'User'),
          )
          .map(e => e.message)
          .join('')
      : undefined

  const warnMessage =
    valueErrors.length > 0
      ? valueErrors
          .filter(e => e.validationType === 'User' && !isReEditMode)
          .map(e => e.message)
          .join('')
      : undefined

  switch (field.typeDef.type) {
    case 'Text':
      return (
        <TextFieldForm
          field={withValidateFieldType(field, 'Text')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'Textarea':
      return (
        <TextareaFieldForm
          field={withValidateFieldType(field, 'Textarea')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'Number':
      return (
        <NumberFieldForm
          field={withValidateFieldType(field, 'Number')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'Checkbox':
      return (
        <CheckboxFieldForm
          field={withValidateFieldType(field, 'Checkbox')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'Radio':
      return (
        <RadioFieldForm
          field={withValidateFieldType(field, 'Radio')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'SelectMenu':
      return (
        <SelectMenuFieldForm
          field={withValidateFieldType(field, 'SelectMenu')}
          selectWidth="320px"
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'Time':
      return (
        <TimeFieldForm
          field={withValidateFieldType(field, 'Time')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'Date':
      return (
        <DateFieldForm
          field={withValidateFieldType(field, 'Date')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'NullableDate':
      return (
        <DateFieldForm
          field={withValidateFieldType(field, 'NullableDate')}
          value={value}
          onChange={setValue}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          isDisabled={isDisabled}
        />
      )
    case 'File':
      return (
        <FileFieldForm
          field={withValidateFieldType(field, 'File')}
          value={value}
          onChange={fileValue => {
            setValue(fileValue, true)
          }}
          errorMessage={errorMessage}
          warnMessage={warnMessage}
          fileViewerPath={order =>
            fileViewerPath({ fid: field.fid, fieldIndex, order })
          }
          isEditing={isEditing}
          isDisabled={isDisabled}
        />
      )
    case 'Label':
      return <LabelFieldForm field={withValidateFieldType(field, 'Label')} />
    default:
      return assertNever(field.typeDef)
  }
}
