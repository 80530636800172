import {
  GqlError,
  GetSessionAgreementFormsQuery,
  GetSessionAgreementFormsDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { isNotNullish } from 'src/utils/isNotNullish'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

export type GetSessionAgreementFormRes = NonNullable<
  GetSessionAgreementFormsQuery['explanationSession']['explanationRevision']['docSets'][number]['documentRevisions']
>[number] & { __typename: 'ExplanationDocRevisionAgreementForm' }

const getSessionAgreementForms = async ({
  sessionUid,
}: {
  sessionUid: string
}): Promise<GetSessionAgreementFormRes[]> => {
  try {
    const res = await graphqlRequest(GetSessionAgreementFormsDocument, {
      sessionUid,
    })
    const agreementForms = res.explanationSession.explanationRevision.docSets
      .flatMap(docSet => docSet.documentRevisions)
      .flatMap(doc =>
        doc?.__typename === 'ExplanationDocRevisionAgreementForm' ? doc : null,
      )
    return agreementForms.filter(isNotNullish)
  } catch (error) {
    throw error
  }
}

export const useSessionAgreementForms = ({
  sessionUid,
  ...option
}: {
  sessionUid: string
} & SWRConfiguration<GetSessionAgreementFormRes[], GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionAgreementForms(sessionUid),
    () => getSessionAgreementForms({ sessionUid }),
    option,
  )
}
