import { useState } from 'react'

import { GqlError } from 'src/lib/gql-client'
import useSWR from 'swr'

import { createOrGetPreSession, CreateOrGetPreSessionRes } from '../api'

export const usePreSession = ({
  isPatient,
  explanationRevisionUid,
  onSetup,
  onAdditionalFetch,
  onError,
}: {
  isPatient: boolean
  explanationRevisionUid: string
  onSetup: (preSession: CreateOrGetPreSessionRes) => void
  onAdditionalFetch?: (preSession: CreateOrGetPreSessionRes) => void
  onError?: (error: GqlError) => void
}) => {
  const [setupDone, setSetupDone] = useState(false)
  // mutationだがmount時に実行するためSWRを使って制御する（useEffectの使用を避ける）
  const {
    data: preSession,
    mutate,
    error: setupPreSessionError,
  } = useSWR<CreateOrGetPreSessionRes, GqlError>(
    { explanationRevisionUid },
    () => createOrGetPreSession({ isPatient, explanationRevisionUid }),
    {
      onSuccess: data => {
        if (setupDone) {
          onAdditionalFetch?.(data)
          return
        }
        onSetup(data)
        setSetupDone(true)
      },
      onError: onError ?? console.error,
      revalidateIfStale: false,
      refreshInterval: 3000,
      revalidateOnFocus: false,
    },
  )

  return {
    preSession,
    setupPreSessionError,
    mutate,
  }
}
