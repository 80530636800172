import { Button } from '@chakra-ui/react'
import { Schema } from '@micin-jp/chicken-schema'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import {
  editingSchemaAtom,
  fieldErrorAtom,
  validateAtom,
} from 'src/lib/chicken-schema/atom'
import {
  equalIgnoreVersion,
  omitFalsyValidation,
} from 'src/lib/chicken-schema/utils'
import { SaveCriteriaDocument } from 'src/lib/gql-client'
import { useGqlMutation } from 'src/lib/gql-client/request'

import { useMutateDetailScreen } from '../../hooks/useMutateDetailScreen'

type Props = {
  criteriaUid: string
  fetchedSchema: Schema
}

export const SaveCriteriaButton: React.FC<Props> = ({
  criteriaUid,
  fetchedSchema,
}) => {
  const schema = useAtomValue(editingSchemaAtom)

  const [errorMap, setErrorMap] = useAtom(fieldErrorAtom)

  const validateSchema = useSetAtom(validateAtom)

  const isChanged = !equalIgnoreVersion(
    omitFalsyValidation(schema),
    omitFalsyValidation(fetchedSchema),
  )

  const toast = useMirohaToast()

  const mutateDetailScreen = useMutateDetailScreen({ criteriaUid })

  const { request: saveCriteria } = useGqlMutation(SaveCriteriaDocument, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: '選択除外基準を一時保存しました。',
      })
      mutateDetailScreen('Saved')
    },
  })

  return (
    <Button
      variant="outline"
      isDisabled={!isChanged}
      onClick={async () => {
        const isValidated = await validateSchema()
        if (!isValidated) {
          toast({
            status: 'error',
            title: '入力に問題があるため一時保存ができません。',
            description:
              '設定項目に表示されたエラーメッセージをご確認のうえ、修正してください。',
          })
          return
        }
        if (Object.keys(errorMap).length > 0) {
          setErrorMap({})
        }
        await saveCriteria({
          input: {
            criteriaUid,
            title: schema.name,
            schema: JSON.stringify(schema),
          },
        })
      }}
    >
      一時保存
    </Button>
  )
}
