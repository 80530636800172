export type AgreementType =
  | 'termsOfService'
  | 'privacyPolicy'
  | 'externalTransmissionPolicy'

export const agreementTypeStrings: Record<AgreementType, string> = {
  termsOfService: '利用規約',
  privacyPolicy: 'プライバシーポリシー',
  externalTransmissionPolicy: '外部送信ポリシー',
}

export type Agreement = {
  userUid: string
  agreeTermsOfService: boolean
  agreePrivacyPolicy: boolean
  agreeExternalTransmissionPolicy: boolean
}

export type AgreementParams = Omit<Agreement, 'userUid'>

// 厳密に、trueのものだけtrueに、undefinedはfalseにする
export const toStrictly: (
  params: Partial<AgreementParams>,
) => AgreementParams = params => {
  return {
    agreeTermsOfService: params.agreeTermsOfService ?? false,
    agreePrivacyPolicy: params.agreePrivacyPolicy ?? false,
    agreeExternalTransmissionPolicy:
      params.agreeExternalTransmissionPolicy ?? false,
  }
}
