import React, { memo } from 'react'

import { useSelector } from 'react-redux'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { FixedQuestionnaireSection } from './section'

export const FixedSectionGroup: React.FC = memo(() => {
  const sections = useSelector(
    (state: RootState) => state.questionnaireDetail.sections,
  )

  const sectionsForSort = [...sections]

  return (
    <Container>
      <List>
        {sectionsForSort
          .sort((a, b) => a.index - b.index)
          .map((s, i) => (
            <FixedQuestionnaireSection key={s.uid} section={s} />
          ))}
      </List>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

const List = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`
