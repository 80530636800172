import { useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ModalContent,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useRejectAgreement } from '../../api'
import {
  ExplanationDocRevisionAgreementForm,
  ExplanationSession,
} from '../../types'

type Props = {
  docRevision: ExplanationDocRevisionAgreementForm
  sessionUid: ExplanationSession['uid']
  mutateDocRevision: () => void
}

export const RejectAgreementForRoom: React.FC<Props> = ({
  docRevision,
  sessionUid,
  mutateDocRevision,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { selectedTrial } = useSelectedTrial()

  const [reason, setReason] = useState('')

  const toast = useMirohaToast()

  const navigate = useNavigate()

  const { request: rejectAgreement } = useRejectAgreement({
    onSuccess: () => {
      onClose()
      mutateDocRevision()
      navigate(
        generatePath(Paths.HospitalExplanationRoomTop, {
          trialUid: selectedTrial.uid,
          sessionUid,
        }),
      )
      toast({
        status: 'success',
        title: '同意を拒否しました',
      })
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const isRejected = docRevision.signHistories.some(h => h.isRejected)

  const isInProgress = !!docRevision.dsEnvelopeId && !docRevision.isCompleted

  const handleClose = () => {
    onClose()
    setReason('')
  }

  if (isRejected) {
    return null
  }

  return (
    <>
      <Button colorScheme="red" variant="outline" onClick={onOpen}>
        同意拒否
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{`${docRevision.icfDocumentRevisionName}の同意を拒否します。よろしいですか？`}</ModalHeader>
          <ModalBody>
            <Stack spacing="4">
              {isInProgress && (
                <WarningMessage message="文書への署名は進行中です。本当によろしいですか？" />
              )}
              <FormControl>
                <FormLabel>コメント</FormLabel>
                {/* TODO: 必須ラベル */}
                <Input
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="red"
              isDisabled={!reason}
              onClick={async () => {
                if (!reason) return
                await rejectAgreement({
                  explanationDocRevisionUid: docRevision.uid,
                  explanationSessionUid: sessionUid,
                  reason,
                })
              }}
            >
              同意拒否する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
