import React, { useState } from 'react'

import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import { generatePath, Link, useParams, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { useSyncEnvelope } from 'src/features/explanation/api'
import { useExplanationDocRevision } from 'src/features/explanation/api/getDocRevision'
import { PatientSignConfirmation } from 'src/features/explanation/components/PatientSignConfirmation/PatientSignConfirmation'
import { StartInPersonSign } from 'src/features/explanation/components/StartInPersonSign/StartInPersonSign'
import {
  ExplanationDocRevision,
  ExplanationSession,
  ExplanationSignerRole,
} from 'src/features/explanation/types'
import { useFloatingVideoCallForMember } from 'src/features/explanationRoom/hooks/useFloatingVideoCallForMember'
import { useSpinner } from 'src/hooks/use-spinner'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { useQuery } from 'src/modules/router'

import { UID } from '../../../../../../../utils/brandedUid'
import { ExplanationDocBrowseState } from '../../explanation/entity'
import { useHospitalSession } from '../hospital-session.hooks'
import { ExplanationRoomLayoutRenewal } from '../layout-renewal'

type Param = {
  sessionUid: ExplanationSession['uid']
  explanationDocRevisionUid: ExplanationDocRevision['uid']
}

export const ExplanationRoomCompleteDocuSignContainer: React.FC = () => {
  const {
    sessionUid = '' as UID<'ExplanationSession'>,
    explanationDocRevisionUid = '' as UID<'ExplanationDocRevision'>,
  } = useParams<Param>()

  const { selectedTrial } = useSelectedTrial()

  const query = useQuery()
  const roleStr = query.get('role') ?? undefined
  const role: ExplanationSignerRole = roleStr
    ? (roleStr as ExplanationSignerRole)
    : 'Patient'
  const isSignedByPatient = role === 'Patient'

  const { session } = useHospitalSession({ explanationSessionUid: sessionUid })

  const { showSpinner, hideSpinner } = useSpinner()

  const navigate = useNavigate()

  const [synced, setSynced] = useState(false)

  const toast = useMirohaToast()

  const { request: syncEnvelope } = useSyncEnvelope({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      setSynced(true)
      mutateDocRevision()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const { data: docRevision, mutate: mutateDocRevision } =
    useExplanationDocRevision({
      explanationDocRevisionUid,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: true,
      onSuccess: async () => {
        if (!synced) {
          await syncEnvelope({
            explanationDocRevisionUid,
            explanationSessionUid: sessionUid,
          })
        }
      },
      onError: error => {
        toast({
          status: 'error',
          title: error.message,
        })
      },
    })

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
    isOldFlow: true,
  })

  if (!docRevision || !session) {
    return null
  }

  const documentTypeText =
    docRevision.type === 'AgreementForm' ? '署名' : '回答'

  const docPagePath = generatePath(Paths.HospitalExplanationRoomDocument, {
    trialUid: selectedTrial.uid,
    sessionUid: sessionUid,
    docRevisionUid: explanationDocRevisionUid,
  })

  return (
    <>
      <ExplanationRoomLayoutRenewal
        headerTitle={docRevision.icfDocumentRevisionName}
        session={session}
        sidebarContent={null}
      >
        <Stack h="full" p="24px">
          <HStack justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="gray"
              as={Link}
              to={generatePath(Paths.HospitalExplanationRoomTop, {
                trialUid: selectedTrial.uid,
                sessionUid: sessionUid,
              })}
            >
              説明ルームに戻る
            </Button>

            <HStack spacing="24px">
              {(session.type === 'InPerson' ||
                docRevision.type === 'CheckUnderstanding' ||
                (session.type === 'RemotePartner' && role !== 'Patient')) && (
                <Button
                  colorScheme="green"
                  as={Link}
                  to={{
                    pathname: docPagePath,
                  }}
                  state={
                    {
                      notCreateLog: true,
                    } as ExplanationDocBrowseState
                  }
                >{`${documentTypeText}結果を表示`}</Button>
              )}

              {session.type === 'InPerson' &&
                docRevision.type === 'AgreementForm' && (
                  <StartInPersonSign
                    docRevision={docRevision}
                    session={session}
                    startSignText="続けて署名する"
                  />
                )}
            </HStack>
          </HStack>

          <Stack
            h="full"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            spacing="24px"
          >
            <Text fontSize="xl" fontWeight="bold">
              {documentTypeText}が完了しました
            </Text>
            {isSignedByPatient && (
              <Text>医療スタッフに端末を返却して下さい。</Text>
            )}
            {session.type === 'RemotePartner' &&
              docRevision.type === 'AgreementForm' &&
              role === 'Patient' && (
                <PatientSignConfirmation
                  sessionUid={session.uid}
                  docRevision={docRevision}
                  onConfirm={() => {
                    navigate(
                      {
                        pathname: docPagePath,
                      },
                      {
                        state: {
                          notCreateLog: true,
                        } as ExplanationDocBrowseState,
                      },
                    )
                  }}
                />
              )}
          </Stack>
        </Stack>
        {renderFloatingVideoCall({ right: 24, bottom: 96 })}
      </ExplanationRoomLayoutRenewal>
    </>
  )
}
