import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'

import { actions } from './redux'

export const useCreateEditableState = ({
  questionnaire,
}: {
  questionnaire: Questionnaire
}) => {
  const dispatch = useDispatch()
  const [completed, setCompleted] = useState(false)

  // 更新前のquestionnaireを引数にして更新用のstateを作成
  useEffect(() => {
    if (!questionnaire) return

    dispatch(actions.initWithQuestionnaire({ questionnaire }))
    setCompleted(true)
  }, [dispatch, questionnaire])

  return { completed }
}
