import {
  AnswerType,
  QuestionnaireField,
} from 'src/modules/entities/questionnaire-field/entity'

export const hasChoice = (questionnaireField: QuestionnaireField) => {
  switch (questionnaireField.answerType) {
    case AnswerType.Radio:
    case AnswerType.CheckBox:
      return true
    case AnswerType.Text:
    case AnswerType.Number:
      return false
    default:
      const n: never = questionnaireField.answerType
      throw new Error(n)
  }
}
