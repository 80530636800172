import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { Schedule } from 'src/modules/entities/schedule/entity'
import { ScheduleCreateRequest } from 'src/modules/entities/schedule/request'

import { Edit } from './edit'
import { useSubmitRegister } from './use-submit-register'

type Props = {
  closePane: () => void
  schedule: Schedule
}

export const EditContainer: React.FC<Props> = props => {
  const { updateRequest, deleteRequest, requesting, errorMessage } =
    useSubmitRegister({
      scheduleUid: props.schedule.uid,
    })
  const onSubmit = useCallback(
    async (params: ScheduleCreateRequest) => {
      await updateRequest(params)
      props.closePane()
    },
    [props, updateRequest],
  )

  const onDelete = useCallback(async () => {
    await deleteRequest()
    props.closePane()
  }, [deleteRequest, props])

  const selectedTrial = useSelector(getSelectedTrial)!
  const { hasPermission } = usePermission()
  const canEdit =
    hasPermission(PERMISSIONS.Questionnaire_Edit) &&
    !selectedTrial.questionnaireScheduleReleased

  return (
    <Edit
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
      onDelete={onDelete}
      closePane={props.closePane}
      schedule={props.schedule}
      canEdit={canEdit}
    />
  )
}
