import { TrialHospital } from 'src/features/trial/types'

import { Explanation } from '../types'

export const isPartner = (
  explanation: Explanation,
  selectedTrialHospitalUid: TrialHospital['uid'] | null,
) => {
  return explanation.trialHospital.uid !== selectedTrialHospitalUid
}
