import { useMemo } from 'react'

import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  Check,
  Mail,
  MessageInformation,
  Reject,
  Sign,
} from 'src/components/icon'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import {
  ExplanationRevisionStatusV2,
  ExpDocRevAgreementFormForSessionInSignFlowFragment,
} from 'src/lib/gql-client'

import { assertNever } from '../../../../utils/assertNever'
import {
  hasSigningNotRequiredAgreements,
  isDocRevCompleted,
} from '../../utils/explanationDocRevisionUtils'
import {
  DocRevAllSignStatuses,
  DocRevSignStatus,
} from '../SignFlowScreen/SignFlowScreen'

type Props = {
  revStatus: ExplanationRevisionStatusV2
  isSignerMemberSet: boolean
  agreementForms: ExpDocRevAgreementFormForSessionInSignFlowFragment[]
  hasCrcField: boolean
  docRevSignStatusesMap: Map<string, DocRevAllSignStatuses>
}

export const SignStatus: React.FC<Props> = ({
  revStatus,
  isSignerMemberSet,
  agreementForms,
  hasCrcField,
  docRevSignStatusesMap,
}) => {
  //  誰も署名していない状態
  const neverSigned = useMemo(() => {
    const statuses = Array.from(docRevSignStatusesMap.values())
    return statuses.every(
      status =>
        status.Dr === 'NotSigned' &&
        status.CRC === 'NotSigned' &&
        status.Patient === 'NotSigned',
    )
  }, [docRevSignStatusesMap])

  const allSignCompleted = useMemo(() => {
    return agreementForms.every(af => isDocRevCompleted(af))
  }, [agreementForms])

  const informationMessage = useMemo(() => {
    if (agreementForms.length === 0) {
      return undefined
    }
    switch (revStatus) {
      case 'SignReady':
      case 'SignStarted':
        if (!isSignerMemberSet) {
          return undefined
        }
        // 1件でも署名中or署名済みがあれば実施中メッセージを出す
        for (const statuses of docRevSignStatusesMap.values()) {
          if (
            statuses.Dr !== 'NotSigned' ||
            statuses.CRC !== 'NotSigned' ||
            statuses.Patient !== 'NotSigned'
          ) {
            return '同意書へ署名を実施中です。'
          }
        }
        return '署名実施バナーより同意書へ署名できます。'
      case 'SignCompleted':
        if (hasSigningNotRequiredAgreements(agreementForms)) {
          return '同意書へ署名を実施中です。'
        }
        if (allSignCompleted) {
          return '同意書への署名が完了しました。'
        }
        return '同意書へ署名を実施中です。▲必須同意書への署名は完了しており、このまま説明を同意完了できます。'
      case 'AgreementCompleted':
      case 'DeliveryCompleted':
        if (neverSigned) {
          return undefined
        }
        return '同意書への署名が完了しました。'
      case 'AgreementRejected':
        return '同意拒否されました。'
      default:
        return undefined
    }
  }, [
    revStatus,
    isSignerMemberSet,
    docRevSignStatusesMap,
    allSignCompleted,
    agreementForms,
    neverSigned,
  ])

  if (informationMessage === undefined) {
    return null
  }

  const isRejected = revStatus === 'AgreementRejected'

  return (
    <Box bg="white" p="6" borderRadius="lg">
      <HStack>
        <MessageInformation
          color={isRejected ? colors.gray[600] : colors.green[500]}
        />
        <Text fontWeight="bold">{informationMessage}</Text>
      </HStack>
      <TableContainer color="gray.600" mt="4" ml="6">
        <Table variant="unstyled">
          <Thead>
            <Tr>
              <Th fontSize="sm" border="none" minW="160px">
                同意書
              </Th>
              <Th fontSize="sm" border="none" minW="160px">
                患者
              </Th>
              <Th
                fontSize="sm"
                border="none"
                minW="160px"
                w={hasCrcField ? undefined : 'full'}
                textTransform="none"
              >
                Dr
              </Th>
              {hasCrcField && (
                <Th fontSize="sm" border="none" w="full">
                  CRC
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {agreementForms.map(af => (
              <Tr key={af.uid} color="gray.600">
                <Td border="none" fontSize="sm">
                  <HStack align="center" spacing="1.5">
                    <Box
                      minW="2"
                      h="2"
                      bg={isRejected ? 'gray.600' : 'green.700'}
                      borderRadius="full"
                    />
                    <Text>{af.icfDocumentRevision.name}</Text>
                  </HStack>
                </Td>
                <Td border="none" fontSize="sm">
                  <SignStatusElement
                    status={
                      docRevSignStatusesMap.get(af.uid)?.Patient ?? 'NotSigned'
                    }
                    isRejected={isRejected}
                  />
                </Td>
                <Td border="none" fontSize="sm">
                  <SignStatusElement
                    status={
                      docRevSignStatusesMap.get(af.uid)?.Dr ?? 'NotSigned'
                    }
                    isRejected={isRejected}
                  />
                </Td>
                {hasCrcField && (
                  <Td border="none" fontSize="sm">
                    <SignStatusElement
                      status={
                        docRevSignStatusesMap.get(af.uid)?.CRC ?? 'NotSigned'
                      }
                      isRejected={isRejected}
                    />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const SignStatusElement: React.FC<{
  status: DocRevSignStatus
  isRejected: boolean
}> = ({ status, isRejected }) => {
  switch (status) {
    case 'NotSigned':
      return null
    case 'SigningOnScreen':
      return (
        <HStack color="gray.600" spacing="1.5">
          <Sign />
          <Text fontSize="sm">署名中</Text>
        </HStack>
      )
    case 'SigningSendLink':
      return (
        <HStack color="gray.600" spacing="1.5">
          <Mail />
          <Text fontSize="sm">署名中</Text>
        </HStack>
      )
    case 'Signed':
      return (
        <HStack color={isRejected ? 'gray.600' : 'green.600'} spacing="1.5">
          <Check />
          <Text fontSize="sm">署名完了</Text>
        </HStack>
      )
    case 'Rejected':
      return (
        <HStack color="red.500" spacing="1.5">
          <Reject />
          <Text fontSize="sm" fontWeight="bold">
            同意拒否
          </Text>
        </HStack>
      )
    default:
      return assertNever(status)
  }
}
