import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { AfterPinSettingPhoneNumberSettingModal } from 'src/features/explanation/components/AfterPinSettingPhoneNumberSettingModal/AfterPinSettingPhoneNumberSettingModal'
import { PinSettingModal } from 'src/features/explanation/components/PinSettingModal/PinSettingModal'
import { mutate } from 'swr'

import { explanationRoomCacheKeys } from '../../api/cacheKey'

type Props = {
  isOpen: boolean
  isSP?: boolean
  trialUid: string
  explanationSessionUid?: string
  explanationRevisionUid: string
  explanationPatientUid: string
  patientPhoneNumber: string | undefined
  actor: 'Patient' | 'Partner'
  onClose: () => void
}

export const ReceivePinSettingRequestModal: React.FC<Props> = ({
  isOpen,
  isSP,
  trialUid,
  explanationRevisionUid,
  explanationPatientUid,
  explanationSessionUid,
  patientPhoneNumber,
  actor,
  onClose,
}) => {
  const pinSettingModal = useDisclosure()
  const phoneNumberSettingModal = useDisclosure()

  // partner → 通常のstyle
  // 患者 → 大きめの文字で表示 & スマホ対応あり
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={actor === 'Partner' ? 'md' : isSP ? 'sm' : 'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader
            textAlign={actor === 'Partner' ? 'left' : 'center'}
            fontSize={actor === 'Partner' ? 'xl' : isSP ? 'lg' : '2xl'}
          >
            暗証番号の設定依頼が届きました
          </ModalHeader>
          <ModalBody>
            {actor === 'Partner' && <Text>患者に端末をお渡しください。</Text>}
          </ModalBody>
          <ModalFooter justifyContent={actor === 'Partner' ? 'end' : 'center'}>
            <Button
              h={actor === 'Partner' ? undefined : isSP ? '56px' : '64px'}
              w={actor === 'Partner' ? undefined : isSP ? 'full' : '240px'}
              colorScheme="green"
              onClick={() => {
                onClose()
                pinSettingModal.onOpen()
              }}
            >
              設定する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <PinSettingModal
        isOpen={pinSettingModal.isOpen}
        isSP={isSP}
        isPatientScreen={actor === 'Patient'}
        explanationRevisionUid={explanationRevisionUid}
        trialUid={trialUid}
        onClose={pinSettingModal.onClose}
        onConfirm={() => {
          if (actor === 'Partner' && patientPhoneNumber === undefined) {
            pinSettingModal.onClose()
            phoneNumberSettingModal.onOpen()
            return
          }
          pinSettingModal.onClose()
        }}
      />

      {actor === 'Partner' && (
        <AfterPinSettingPhoneNumberSettingModal
          isOpen={phoneNumberSettingModal.isOpen}
          onClose={() => {
            // サイドバーの表示を更新
            if (!!explanationSessionUid) {
              const cacheKey = explanationRoomCacheKeys.getSessionForSidebar(
                explanationSessionUid,
              )
              mutate(cacheKey)
            }
            phoneNumberSettingModal.onClose()
          }}
          patientUid={explanationPatientUid}
          buttonColorScheme="green"
        />
      )}
    </>
  )
}
