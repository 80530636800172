import React from 'react'

import { Dictionary } from 'lodash'
import { ChoiceAnswer } from 'src/modules/entities/choice-answer/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import styled from 'styled-components'

import {
  hasChoice,
  hasValue,
} from '../../modules/util/item-type-classification'

type Props = {
  smallItemAnswer: SmallItemAnswer
  smallItemAnswerMap: Record<string, SmallItemAnswer>
}

export const NestedSmallItemAnswerComponent: React.FC<Props> = props => {
  const { smallItemAnswer, smallItemAnswerMap } = props

  const checkedChoices = smallItemAnswer.choiceAnswers.filter(ca => ca.checked)

  const withQuestion =
    smallItemAnswer.choiceAnswers.length !== 0 &&
    checkedChoices.some(ca => ca.smallItemAnswerUids.length !== 0)

  const withoutQuestion =
    smallItemAnswer.choiceAnswers.length !== 0 &&
    checkedChoices.every(ca => ca.smallItemAnswerUids.length === 0)

  return (
    <>
      {!hasChoice(smallItemAnswer) && (
        <TextAnswer smallItemAnswer={smallItemAnswer} />
      )}

      {withoutQuestion && (
        <WithoutQuestionChoiceAnswer smallItemAnswer={smallItemAnswer} />
      )}

      {withQuestion &&
        checkedChoices.map((choice, i) => (
          <Border
            key={choice.uid}
            className={checkedChoices.length === i + 1 ? 'last' : ''}
          >
            <WithQuestionChoiceAnswer
              key={choice.uid}
              choice={choice}
              smallItemAnswer={smallItemAnswer}
              smallItemAnswerMap={smallItemAnswerMap}
            />
          </Border>
        ))}
    </>
  )
}

type TextAnswerProps = {
  smallItemAnswer: SmallItemAnswer
}

const TextAnswer: React.FC<TextAnswerProps> = props => {
  const { smallItemAnswer } = props
  const showValue = hasValue(smallItemAnswer) && smallItemAnswer.hasLog

  return (
    <Container>
      <Title>{smallItemAnswer.title}</Title>

      {showValue && (
        <ValueContainer>
          <Value>{smallItemAnswer.value}</Value>
        </ValueContainer>
      )}
    </Container>
  )
}

type WithoutQuestionChoiceAnswerProps = {
  smallItemAnswer: SmallItemAnswer
}

const WithoutQuestionChoiceAnswer: React.FC<
  WithoutQuestionChoiceAnswerProps
> = props => {
  const { smallItemAnswer } = props

  return (
    <Container>
      <Title>{smallItemAnswer.title}</Title>

      <ValueContainer>
        {smallItemAnswer.choiceAnswers.map(
          choice =>
            choice.checked && (
              <Value key={choice.uid}>{choice.description}</Value>
            ),
        )}
      </ValueContainer>
    </Container>
  )
}

type WithQuestionChoiceAnswerProps = {
  choice: ChoiceAnswer
  smallItemAnswer: SmallItemAnswer
  smallItemAnswerMap: Dictionary<SmallItemAnswer>
}

const WithQuestionChoiceAnswer: React.FC<
  WithQuestionChoiceAnswerProps
> = props => {
  const { choice, smallItemAnswer, smallItemAnswerMap } = props

  const nestedSmallItemAnswers = choice.smallItemAnswerUids.map(
    uid => smallItemAnswerMap[uid],
  )

  return (
    <>
      <Border>
        <Container>
          <Title>{smallItemAnswer.title}</Title>

          <ValueContainer>
            <Value>{choice.description}</Value>
          </ValueContainer>
        </Container>
      </Border>

      {nestedSmallItemAnswers.map((nestSmallItemAnswer, i) => (
        <Border
          key={nestSmallItemAnswer.uid}
          className={nestedSmallItemAnswers.length === i + 1 ? 'last' : ''}
        >
          <NestedSmallItemContainer>
            <NestedSmallItemAnswerItem
              smallItemAnswer={nestSmallItemAnswer}
              smallItemAnswerMap={smallItemAnswerMap}
            />
          </NestedSmallItemContainer>
        </Border>
      ))}
    </>
  )
}

type NestedSmallItemAnswerItemProps = {
  smallItemAnswer: SmallItemAnswer
  smallItemAnswerMap: Record<string, SmallItemAnswer>
}

const NestedSmallItemAnswerItem: React.FC<
  NestedSmallItemAnswerItemProps
> = props => {
  const { smallItemAnswer, smallItemAnswerMap } = props

  return (
    <NestedSmallItemAnswerComponent
      smallItemAnswer={smallItemAnswer}
      smallItemAnswerMap={smallItemAnswerMap}
    />
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Title = styled.p`
  width: 100px;
  font-weight: bold;
  font-size: 9px;
  padding: 9px 0;
  margin: 0;
  word-break: break-all;
`

const ValueContainer = styled.div`
  width: 350px;
  display: flex;
  justify-content: flex-start;
  word-break: break-all;
`

const Value = styled.p`
  font-size: 9px;
  padding: 9px 0;
  margin: 0;
  white-space: pre-wrap;

  &:after {
    content: '/';
    margin: 0 4px;
  }
  &:last-child:after {
    content: '';
  }
`

const NestedSmallItemContainer = styled.div`
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
`

const Border = styled.div`
  border-bottom: 0.5px dashed #000000;

  &.last {
    border: none;
  }
`
