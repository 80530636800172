import { generateUUID } from 'src/utils/generateUUID'

import {
  MediumItemAnswer,
  MediumItemAnswerStatus,
  SectionConfirmationStatus,
} from './entity'
import { createFixture as createChoiceAnswerFixture } from '../choice-answer/fixture'
import { DataType, ItemType } from '../small-item/entity'
import { createFixture as createSmallItemAnswerFixture } from '../small-item-answer/fixture'

export const createFixture = (
  values?: Partial<MediumItemAnswer>,
): MediumItemAnswer => {
  const res = {
    uid: 'fooMediumItemAnswerUid',
    title: '中項目タイトル',
    folderName: '',
    formName: '',
    columnName: '',
    index: 1,
    isLogline: false,
    lineNumber: 1,

    smallItemAnswerMap: {},

    worksheetUid: 'fooWorksheetUid',
    mediumItemUid: 'fooMediumItemUid',
    status: MediumItemAnswerStatus.New,
    hasLog: false,
    confirmationStatus: SectionConfirmationStatus.Pending,
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof MediumItemAnswer
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}

export const createLogline = (lineNumber: number): MediumItemAnswer => {
  return createFixture({
    uid: generateUUID(),
    mediumItemUid: 'loglineMediumItemUid',
    title: 'ログラインセクション 1',
    isLogline: true,
    index: 1,
    lineNumber: lineNumber,
    smallItemAnswerMap: {
      logline1_a: createSmallItemAnswerFixture({
        uid: 'logline1_a',
        smallItemUid: 'logline1_a',
        dataType: DataType.Clinical,
        parentUid: null,
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_b: createSmallItemAnswerFixture({
        uid: 'logline1_b',
        smallItemUid: 'logline1_b',
        parentUid: null,
        itemType: ItemType.CheckBox,
        choiceAnswers: [
          createChoiceAnswerFixture({
            uid: 'logline1_a',
            checked: true,
            smallItemAnswerUids: ['logline1_c'],
          }),
          createChoiceAnswerFixture({
            uid: 'logline1_a2',
            checked: true,
            smallItemAnswerUids: ['logline1_e'],
          }),
        ],
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_c: createSmallItemAnswerFixture({
        uid: 'logline1_c',
        smallItemUid: 'logline1_c',
        itemType: ItemType.Radio,
        parentUid: 'logline1_b',
        choiceAnswers: [
          createChoiceAnswerFixture({
            uid: 'logline1_b',
            description: '子持ち選択肢A',
            checked: true,
            smallItemAnswerUids: ['logline1_d'],
          }),
          createChoiceAnswerFixture({
            uid: 'logline1_c',
            description: '選択肢B',
          }),
        ],
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_d: createSmallItemAnswerFixture({
        uid: 'logline1_d',
        smallItemUid: 'logline1_d',
        parentUid: 'logline1_c',
        itemType: ItemType.CheckBox,
        choiceAnswers: [
          createChoiceAnswerFixture({
            uid: 'logline1_d1',
            checked: true,
          }),
          createChoiceAnswerFixture({
            uid: 'logline1_e1',
          }),
          createChoiceAnswerFixture({
            uid: 'logline1_f1',
          }),
        ],
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_e: createSmallItemAnswerFixture({
        uid: 'logline1_e',
        smallItemUid: 'logline1_e',
        parentUid: 'logline1_b',
        itemType: ItemType.CheckBox,
        choiceAnswers: [
          createChoiceAnswerFixture({
            uid: 'logline1_d2',
            checked: true,
          }),
          createChoiceAnswerFixture({
            uid: 'logline1_e2',
          }),
          createChoiceAnswerFixture({
            uid: 'logline1_f3',
          }),
        ],
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_f: createSmallItemAnswerFixture({
        uid: 'logline1_f',
        smallItemUid: 'logline1_f',
        itemType: ItemType.Text,
        value: '回答1f',
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_g: createSmallItemAnswerFixture({
        uid: 'logline1_g',
        smallItemUid: 'logline1_g',
        itemType: ItemType.Text,
        value: '回答1f',
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
      logline1_h: createSmallItemAnswerFixture({
        uid: 'logline1_h',
        smallItemUid: 'logline1_h',
        itemType: ItemType.Text,
        value: '回答1f',
        mediumItemAnswerUid: 'loglineMediumItemAnswerUid1',
      }),
    },
  })
}
