import React from 'react'

import { Button, Center, Stack, Text } from '@chakra-ui/react'
import { ExplanationType } from 'src/features/explanation/types'
import { useLogout } from 'src/modules/auth/logout/use-logout'
import { useQuery } from 'src/modules/router'

import { Information } from '../../components/information'

// クエリなどを使って対面の場合と共通化
export const ExplanationRoomFinished: React.FC = () => {
  const query = useQuery()

  const isLobby = query.get('isLobby') === 'true'
  const isLeft = query.get('isLeft') === 'true'
  const type = query.get('type')
    ? (query.get('type') as ExplanationType)
    : undefined
  const isPartnerMember = query.get('isPartnerMember') === 'true'

  const actionText =
    isLeft || isLobby ? '説明ルームから退出しました。' : '説明を終了しました。'

  // ログアウト処理を事前に行うと自動的にログイン画面へリダイレクトされてしまうので、ボタン押下時にログアウト処理を行う
  const { handleLogout } = useLogout()

  // 患者と同じ場所いるケース（対面 or パートナー施設側のユーザー）
  const patientPresent =
    type === 'InPerson' || (type === 'RemotePartner' && isPartnerMember)

  return (
    <Information
      body={`${actionText}${
        patientPresent ? '' : 'ブラウザのタブを閉じてください。'
      }`}
    >
      {patientPresent && (
        <Stack spacing="8">
          <Text>再度ログインする場合は以下のボタンを押してください。</Text>
          <Center>
            <Button colorScheme="green" onClick={handleLogout}>
              ログイン画面へ戻る
            </Button>
          </Center>
        </Stack>
      )}
    </Information>
  )
}
