import {
  CreatePatientVideoCallInput,
  CreatePatientVideoCallMutation,
  CreatePatientVideoCallDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type CreatePatientVideoCallReturn =
  CreatePatientVideoCallMutation['createPatientVideoCall']

export const createPatientVideoCall = async (
  input: CreatePatientVideoCallInput,
): Promise<CreatePatientVideoCallReturn> => {
  try {
    const res = await graphqlRequest(CreatePatientVideoCallDocument, { input })
    return res.createPatientVideoCall
  } catch (error) {
    throw error
  }
}
