import { ComponentStyleConfig } from '@chakra-ui/react'

export const Menu: ComponentStyleConfig = {
  baseStyle: {
    list: {
      zIndex: 'dropdown',
      p: 2,
      minW: '100px',
    },
    item: {
      _focus: {
        bg: 'gray.50',
      },
    },
  },
}
