import { ComponentProps, createContext, useContext, useState } from 'react'

import {
  ScreenSpinner,
  ScreenSpinnerWithRedux,
} from 'src/modules/screen-spinner/screen-spinner'

const Context = createContext<
  | {
      showSpinner: (
        props?: ComponentProps<typeof ScreenSpinnerWithRedux>,
      ) => void
      hideSpinner: () => void
    }
  | undefined
>(undefined)

export const SpinnerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [spinnerProps, setSpinnerProps] =
    useState<ComponentProps<typeof ScreenSpinnerWithRedux>>()

  const show = (props?: ComponentProps<typeof ScreenSpinnerWithRedux>) => {
    setShowSpinner(true)
    setSpinnerProps(props)
  }
  const hide = () => {
    setShowSpinner(false)
    setSpinnerProps(undefined)
  }

  return (
    <Context.Provider value={{ showSpinner: show, hideSpinner: hide }}>
      {showSpinner && <ScreenSpinner {...spinnerProps} />}
      {children}
    </Context.Provider>
  )
}

export const useSpinner = () => {
  const context = useContext(Context)
  // contextがundefined（デフォルト値）である場合、初期化ががなされていないためエラーとする
  if (context === undefined) {
    const error = 'useSpinner must be called inside SpinnerProvider'
    console.error(error)
    throw new Error(error)
  }

  return context
}
