import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const verifyPinHandler = restHandlerFactory(
  '/explanation_revision_pin/trials/{trial_uid}/explanation_sessions/{explanation_session_uid}/verify_pin',
  'post',
  () => {
    return HttpResponse.json(undefined, { status: 204 })
  },
)
