import { HttpResponse } from 'msw'
import {
  createStickyMessageMock,
  createStickyMock,
  createTrialMemberMock,
  createUserMock,
  GetStickyDocument,
  GetStickyQuery,
  GetStickyQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getStickyHandler = graphQLHandlerFactory<
  GetStickyQuery,
  GetStickyQueryVariables
>('query', GetStickyDocument, () => {
  const member = createTrialMemberMock({
    user: createUserMock({
      lastName: 'MICIN',
      firstName: '太郎',
    }),
  })
  return HttpResponse.json({
    data: {
      sticky: createStickyMock({
        stickyMessages: [
          createStickyMessageMock({
            message: '付箋のメッセージ1',
            trialMember: member,
          }),
          createStickyMessageMock({
            message: '付箋のメッセージ2',
            trialMember: member,
          }),
          createStickyMessageMock({
            message: '付箋のメッセージ3',
            trialMember: member,
          }),
        ],
      }),
    },
  })
})
