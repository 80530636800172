import { Button, useDisclosure } from '@chakra-ui/react'

import { ExplanationDetail } from '../../types'
import { ExplanationHistoryDrawer } from '../ExplanationHistoryDrawer/ExplanationHistoryDrawer'

type Props = {
  explanation: ExplanationDetail
}
export const ExplanationHistoryDrawerButton: React.FC<Props> = ({
  explanation,
}) => {
  const {
    isOpen: isOpenHistoryDrawer,
    onOpen: onOpenHistoryDrawer,
    onClose: onCloseHistoryDrawer,
  } = useDisclosure()

  const {
    latestRevision: { status },
    revisionUids,
  } = explanation

  if (revisionUids.length === 0) {
    return null
  }

  if (status === 'SessionNotStarted' && revisionUids.length === 1) {
    return null
  }

  return (
    <>
      <Button variant="text" onClick={onOpenHistoryDrawer}>
        説明履歴
      </Button>
      <ExplanationHistoryDrawer
        isOpen={isOpenHistoryDrawer}
        onClose={onCloseHistoryDrawer}
        explanation={explanation}
      />
    </>
  )
}
