import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { DisableWorksheetDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { Worksheet, WorksheetDetail } from '../../types'
import { parseGqlWorksheetResponse } from '../parser'

const disableWorksheet = async ({
  worksheetUid,
  reason,
}: {
  worksheetUid: Worksheet['uid']
  reason: string
}): Promise<WorksheetDetail> => {
  try {
    const res = await graphqlRequest(DisableWorksheetDocument, {
      input: {
        worksheetUid,
        reason,
      },
    })
    return parseGqlWorksheetResponse(res.disableWorksheet.worksheet)
  } catch (error) {
    throw error
  }
}

export const useDisableWorksheet = (
  option?: UseMutationOption<WorksheetDetail, GqlError>,
) => {
  return useMutation(
    ({
      worksheetUid,
      reason,
    }: {
      worksheetUid: Worksheet['uid']
      reason: string
    }) => disableWorksheet({ worksheetUid, reason }),
    option,
  )
}
