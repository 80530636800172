import React from 'react'

import { IcfDocumentDetail } from './detail'
import { useIcfDocumentDetail } from './detail.hooks'
import { useDocuSignPreview } from '../../use-docusign-preview'

type Props = {
  documentUid: string
}

export const IcfDocumentDetailContainer: React.FC<Props> = ({
  documentUid,
}) => {
  const { document, latestRevision, filePreviewUrl } =
    useIcfDocumentDetail(documentUid)

  const { redirect: redirectDocuSignPreview } = useDocuSignPreview()

  return (
    <IcfDocumentDetail
      document={document}
      revision={latestRevision}
      filePreviewUrl={filePreviewUrl}
      onRedirectDocuSignPreview={redirectDocuSignPreview}
    />
  )
}
