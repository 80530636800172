import React, { ButtonHTMLAttributes } from 'react'

import { Spacer } from 'src/components/spacer/spacer'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { ButtonType, getButtonColor } from './color-style-helper'
import { ButtonIconName, getIcon } from './icon-style-helper'
import { Size, getButtonShape } from './shape-style-helper'

type Props = {
  text: string
  size?: Size
  buttonType: ButtonType
  iconName?: ButtonIconName
} & ButtonHTMLAttributes<HTMLButtonElement>

/**
 * @deprecated Use chakra-ui Button instead.
 */
export const Button: React.FC<Props> = props => {
  const { text, size = 'S', buttonType, iconName, ...rest } = props
  const shapeStyle = getButtonShape({ size })
  const colorStyle = getButtonColor({ buttonType })
  const Icon = getIcon({ iconName })

  return (
    <ButtonBase
      {...rest}
      title={rest.title || props.text}
      shapeStyle={shapeStyle}
      colorStyle={colorStyle}
    >
      {Icon ? (
        <>
          <IconContainer>
            <Icon size={props.size === 'S' ? 'S' : 'M'} />
          </IconContainer>
          <Spacer size={8} horizontal />
          <Text>{props.text}</Text>
        </>
      ) : (
        <Text>{props.text}</Text>
      )}
    </ButtonBase>
  )
}

const reset = css`
  all: unset;
`

const ButtonBase = styled.button<{
  shapeStyle: FlattenSimpleInterpolation
  colorStyle: FlattenSimpleInterpolation
}>`
  ${reset}
  ${p => p.shapeStyle}
  ${p => p.colorStyle}
`

const IconContainer = styled.span`
  display: flex;
  align-items: center;
`

const Text = styled.span`
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;
  line-height: 20px;
`
