import {
  GetExplanationDetailDocument,
  GetExplanationDocument,
  GetExplanationForPatientDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { Explanation, ExplanationDetail } from '../../types'
import { explanationCacheKey } from '../cacheKey'
import { parseGqlExplanation, parseGqlExplanationDetail } from '../parser'

type ExplanationType<Detailed extends boolean> = Detailed extends true
  ? ExplanationDetail
  : Explanation

const getExplanation = async <Detailed extends boolean>({
  explanationUid,
  detailed,
}: {
  explanationUid: string
  detailed: Detailed
}): Promise<ExplanationType<Detailed>> => {
  try {
    if (detailed) {
      const res = await graphqlRequest(GetExplanationDetailDocument, {
        explanationUid,
      })
      return parseGqlExplanationDetail(res.explanation)
    } else {
      const res = await graphqlRequest(GetExplanationDocument, {
        explanationUid,
      })
      // ここでDetailedがfalseに絞り込まれないので as でアサーション
      return parseGqlExplanation(res.explanation) as ExplanationType<Detailed>
    }
  } catch (e) {
    throw e
  }
}

const getExplanationForPatient = async ({
  explanationRevisionUid,
}: {
  explanationRevisionUid: string
}): Promise<Explanation> => {
  try {
    const res = await graphqlRequest(GetExplanationForPatientDocument, {
      explanationRevisionUid,
    })
    return parseGqlExplanation(res.explanationRevision.explanation)
  } catch (e) {
    throw e
  }
}

type SWRConfig<Detailed extends boolean> = SWRConfiguration<
  ExplanationType<Detailed>,
  GqlError
>
export const useExplanation = <Detailed extends boolean = true>({
  explanationUid,
  detailed,
  ...option
}: {
  /** nullを渡すことでfetchをスキップできる */
  explanationUid: string | null
  detailed?: Detailed
} & SWRConfig<Detailed>) => {
  return useSWR<ExplanationType<Detailed>, GqlError>(
    explanationUid
      ? explanationCacheKey.getExplanation(explanationUid, detailed ?? true)
      : null,
    () => {
      if (!explanationUid) {
        throw new Error('explanationUid is null')
      }
      return getExplanation({ explanationUid, detailed: detailed ?? true })
    },
    option,
  )
}

export const useExplanationForPatient = ({
  explanationRevisionUid,
  ...option
}: {
  explanationRevisionUid: string | null
} & SWRConfiguration<Explanation, GqlError>) => {
  return useSWR<Explanation, GqlError>(
    explanationRevisionUid
      ? explanationCacheKey.getExplanationForPatient(explanationRevisionUid)
      : null,
    () => {
      if (!explanationRevisionUid) {
        throw new Error('explanationUid is null')
      }
      return getExplanationForPatient({ explanationRevisionUid })
    },
    option,
  )
}
