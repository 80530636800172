import { Box } from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { useModal } from 'src/hooks/use-modal'
import { useQuery } from 'src/hooks/use-query'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { AddExplanationScreen_ReferredPatientDocument } from 'src/lib/gql-client'
import { useGqlQuerySWRNoSuspense } from 'src/lib/gql-client/request'

import { useCreateExplanation } from '../../api/createExplanation'
import { expFormSchemaToCreateInput } from '../../utils/schemaToCreateInput'
import { ExplanationForm } from '../ExplanationForm/ExplanationForm'

export const AddExplanationScreen: React.FC = () => {
  const toast = useMirohaToast()

  const navigate = useNavigate()

  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { request: createExplanation } = useCreateExplanation({
    onSuccess: ({ explanationUid }) => {
      toast({
        status: 'success',
        title: '登録が完了しました',
      })
      navigate(
        generatePath(Paths.Explanation, {
          trialUid: selectedTrial.uid,
          explanationUid,
        }),
      )
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const { showModal } = useModal()

  const query = useQuery()

  const referredPatientUid = query.get('referredPatientUid') ?? undefined

  const { data } = useGqlQuerySWRNoSuspense(
    AddExplanationScreen_ReferredPatientDocument,
    !!referredPatientUid ? { referredPatientUid } : null,
    { revalidateIfStale: false },
  )

  const referredPatient = data?.referredPatient

  if (!!referredPatientUid && !referredPatient) {
    return null
  }

  return (
    <Box as="main">
      <ExplanationForm
        formType="add"
        referredPatient={referredPatient}
        onSubmit={schema => {
          showModal({
            title: '説明同意を登録',
            content: '説明同意を登録します',
            submitText: '登録する',
            onSubmit: () => {
              if (!selectedTrialHospitalUid) {
                throw new Error('selectedTrialHospitalUid is not defined')
              }
              createExplanation({
                input: expFormSchemaToCreateInput(
                  selectedTrialHospitalUid,
                  schema,
                  referredPatient?.patientMasterUid,
                ),
              })
            },
          })
        }}
      />
    </Box>
  )
}
