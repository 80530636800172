import { Box, Center } from '@chakra-ui/react'
import { Sticky } from 'src/components/icon'
import { isNotNullish } from 'src/utils/isNotNullish'

import { useWorksheetFieldStickiesContext } from '../../context/WorksheetFieldStickies'
import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { useStickyEditingAlert } from '../../hooks/useStickyEditingAlert'
import { useStickyQuery } from '../../hooks/useStickyQuery'
import { WorksheetDetail } from '../../types'

type Props = {
  worksheet: WorksheetDetail
}

export const StickyBadge: React.FC<Props> = ({ worksheet }) => {
  const { stickyQuery, setStickyQuery } = useStickyQuery()

  const { isEditing, toggleEditing, onChangePopoverOpenForNew } =
    useWorksheetFieldStickiesContext()

  const { showStickyEditingAlert } = useStickyEditingAlert()

  const { isVisibleField } = useWorksheetValueContext()

  const activeStickyUids = worksheet.fields
    .filter(field => isVisibleField(field.fid, field.fieldIndex))
    .map(field => field.stickyUid)
    .filter(isNotNullish)

  const stickyCount = activeStickyUids.length

  const nextStickyUid = (): string | undefined => {
    if (stickyCount === 0) return undefined

    if (stickyCount === 1) {
      return !stickyQuery ? activeStickyUids[0] : undefined
    }

    if (!stickyQuery) {
      return activeStickyUids[0]
    }

    const currentIndex = activeStickyUids.indexOf(stickyQuery)
    if (currentIndex === -1) return undefined

    const nextIndex =
      currentIndex === activeStickyUids.length - 1 ? 0 : currentIndex + 1
    return activeStickyUids[nextIndex]
  }

  const handleChangeStickyUid = (stickyUid: string) => {
    onChangePopoverOpenForNew(null)
    setStickyQuery(stickyUid)
  }

  const handleClick = () => {
    const nextUid = nextStickyUid()
    if (nextUid === undefined) return
    if (isEditing) {
      showStickyEditingAlert({
        onConfirm: () => {
          handleChangeStickyUid(nextUid)
          toggleEditing(false)
        },
      })
      return
    }
    handleChangeStickyUid(nextUid)
  }

  const hasSticky = stickyCount > 0

  return (
    <Box
      minW="7"
      pos="relative"
      as="button"
      onClick={handleClick}
      cursor={hasSticky ? 'pointer' : 'default'}
      color={hasSticky ? 'yellow.500' : 'gray.400'}
      _hover={{ color: hasSticky ? 'yellow.600' : 'gray.400' }}
      _active={{ color: hasSticky ? 'yellow.700' : 'gray.400' }}
    >
      {hasSticky && (
        <Center
          as="span"
          pos="absolute"
          bg="red.500"
          h="4"
          w="4"
          borderRadius="full"
          color="white"
          fontSize="xs"
          left="2.5"
          top="-2.5"
        >
          {stickyCount}
        </Center>
      )}
      <Sticky />
    </Box>
  )
}
