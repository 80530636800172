import React, { Suspense } from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { Provider } from 'react-redux'
import { isTouchDevice } from 'src/modules/util/is-touch-device'

import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import { LoadingDots } from './components/LoadingDots/LoadingDots'
import { ModalProvider } from './hooks/use-modal'
import { OrientationAlertProvider } from './hooks/use-orientation-alert'
import { useReloadWindow } from './hooks/use-reload-window'
import { SpinnerProvider } from './hooks/use-spinner'
import { theme as chakraUITheme } from './lib/chakra-theme'
import { GaProvider } from './lib/ga/ga-provider'
import { Flash } from './modules/flash/flash'
import { Router } from './modules/router'
import { ScreenSpinnerWithRedux } from './modules/screen-spinner/screen-spinner'
import { store } from './modules/store'

const App: React.FC = () => {
  const dndProviderBackend = isTouchDevice() ? TouchBackend : HTML5Backend

  useReloadWindow()

  return (
    <Provider store={store}>
      <DndProvider backend={dndProviderBackend}>
        <ChakraProvider theme={chakraUITheme}>
          <ErrorBoundary>
            <Suspense fallback={<LoadingDots />}>
              <OrientationAlertProvider>
                <GaProvider>
                  <ModalProvider>
                    <SpinnerProvider>
                      <Flash />
                      <ScreenSpinnerWithRedux />
                      <Router />
                    </SpinnerProvider>
                  </ModalProvider>
                </GaProvider>
              </OrientationAlertProvider>
            </Suspense>
          </ErrorBoundary>
        </ChakraProvider>
      </DndProvider>
    </Provider>
  )
}

export default App
