import { ComponentStyleConfig } from '@chakra-ui/react'

export const Textarea: ComponentStyleConfig = {
  variants: {
    outline: () => ({
      bg: 'white',
      _hover: {
        borderColor: 'gray.400',
      },
      _focusVisible: {
        borderColor: 'blue.500',
      },
      _invalid: {
        bg: 'red.50',
        _focusVisible: {
          bg: 'white',
        },
      },
      _disabled: {
        bg: 'gray.50',
        border: '1px solid',
        borderColor: 'gray.200',
        opacity: 1,
      },
    }),
  },
}
