import { HttpResponse } from 'msw'
import {
  createTrialMemberMock,
  createUserMock,
  GetTrialMembersDocument,
  GetTrialMembersQuery,
  GetTrialMembersQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getTrialMembersHandler = graphQLHandlerFactory<
  GetTrialMembersQuery,
  GetTrialMembersQueryVariables
>('query', GetTrialMembersDocument, () => {
  return HttpResponse.json({
    data: {
      trialMembers: [
        createTrialMemberMock({
          role: 'Dr',
          user: createUserMock({
            firstName: 'Dr',
            lastName: 'MICIN',
          }),
        }),
        createTrialMemberMock({
          role: 'CRC',
          user: createUserMock({
            firstName: 'CRC',
            lastName: 'MICIN',
          }),
        }),
      ],
    },
  })
})
