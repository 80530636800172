import {
  HStack,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { JobListTable_JobFragment } from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

type Props = {
  jobs: JobListTable_JobFragment[]
}

export const JobListTable: React.FC<Props> = ({ jobs }) => {
  return (
    // Tableをヘッダ固定にしている。他には適用されていないがこちらの方が使い勝手が良さそうなので、他のTableも同様に変更することを検討する
    <Table>
      <Thead position="sticky" zIndex="1" top={0} bg="white">
        <Tr>
          <Th>処理受付時刻</Th>
          <Th>処理終了時刻</Th>
          <Th>最終更新者</Th>
          <Th>処理結果</Th>
        </Tr>
      </Thead>
      <Tbody>
        {jobs.map(({ jobUid, logs }) => {
          if (logs.length === 0) {
            return null
          }
          const latestLog = logs[0]
          const firstLog = logs[logs.length - 1]

          const lastActorMember = logs.find(
            log => !!log.actorTrialMember,
          )?.actorTrialMember
          if (!lastActorMember) {
            return null
          }
          return (
            <Tr key={jobUid}>
              <Td minW="160px">
                {formatDate(firstLog.savedAt, 'YYYY/MM/DD (ddd) HH:mm:ss')}
              </Td>
              <Td minW="160px">
                {latestLog.status !== 'Queued' &&
                  formatDate(latestLog.savedAt, 'YYYY/MM/DD (ddd) HH:mm:ss')}
              </Td>
              <Td maxW="300px">
                <MemberLabel
                  role={lastActorMember.role}
                  displayName={getFullName(lastActorMember.user)}
                />
              </Td>
              <Td minW="100px">
                {latestLog.isExpired
                  ? '期限切れ'
                  : (() => {
                      switch (latestLog.status) {
                        case 'Queued':
                          return (
                            <HStack>
                              <Text as="span">処理中</Text>
                              <Spinner />
                            </HStack>
                          )
                        case 'Cancelled':
                          return <Text as="span">処理中止</Text>
                        case 'Failed':
                          return <Text as="span">エラー</Text>
                        case 'Completed':
                          return !!latestLog.uploadedFile ? (
                            <Link
                              as="a"
                              color="blue.500"
                              href={latestLog.uploadedFile.url}
                              download
                            >
                              ダウンロード
                            </Link>
                          ) : null
                        default:
                          return assertNever(latestLog.status)
                      }
                    })()}
              </Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
