import { combineReducers } from '@reduxjs/toolkit'

import {
  reducer as agreementReducer,
  State as AgreementState,
} from './agreement/redux'
import { reducer as loginReducer, State as LoginState } from './login/redux'
import { reducer as logoutReducer, State as LogoutState } from './logout/redux'

export type State = {
  login: LoginState
  logout: LogoutState
  agreement: AgreementState
}

export const reducer = combineReducers({
  login: loginReducer,
  logout: logoutReducer,
  agreement: agreementReducer,
})
