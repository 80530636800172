import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getIcfDocumentSetsHandler = restHandlerFactory(
  '/trials/{trial_uid}/trial_hospitals/{trial_hospital_uid}/icf_document_sets',
  'get',
  () => {
    const icfDocumentSets = Array.from({ length: 12 }, (_, i) => {
      const numberingId = i + 1
      const enabled = true
      const hasDocuments = true
      const hasRequiredAgreementForm = numberingId % 2 === 0 // 偶数番目のみtrue
      return {
        uid: `icf_document_set_uid_${numberingId}`,
        name: `ICF Document Set ${numberingId}`,
        enabled,
        hasDocuments,
        hasRequiredAgreementForm,
        numberingId,
      }
    })
    return HttpResponse.json(icfDocumentSets, { status: 200 })
  },
)
