import React, { Reducer, useCallback, useReducer } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { PatientEProStatus } from 'src/modules/entities/patient/entity'
import { actions } from 'src/modules/entities/patient/redux'
import { getSingle } from 'src/modules/entities/patient/selector'
import { RootState } from 'src/modules/reducer'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'

import { PasswordIssueButton, State } from './password-issue-button'
import { patientIdParamName } from '../../../router'

type Action =
  | { type: 'open-password-issue-modal' }
  | { type: 'close-modal' }
  | { type: 'loading'; isLoading: boolean }
  | { type: 'password-issued'; password: string }

export const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'open-password-issue-modal': {
      return { ...state, openingModal: 'password-issue-modal' }
    }
    case 'close-modal': {
      return { ...state, openingModal: 'none' }
    }
    case 'loading': {
      const { isLoading } = action
      return {
        ...state,
        isLoading,
      }
    }
    case 'password-issued': {
      const { password } = action
      return {
        ...state,
        password,
        openingModal: 'password-display-modal',
      }
    }
  }
}

const initialState: State = {
  openingModal: 'none',
  isLoading: false,
  password: undefined,
}

export const PasswordIssueButtonContainer: React.FC = () => {
  const patientDispatch = useDispatch()
  const [state, dispatch] = useReducer(reducer, initialState)
  const selectedTrial = useSelector(getSelectedTrial)!
  const { uid: trialUid } = selectedTrial

  const { patientUid = '' } = useParams<{ [patientIdParamName]: string }>()
  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )!
  const { isPasswordIssued } = patient

  const issuePassword = useCallback(async () => {
    try {
      dispatch({ type: 'loading', isLoading: true })
      const res = await requestPost<{ new_password: string }>({
        url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/password_reissue`,
      })
      const { new_password: newPassword } = res.data
      const eproStatus = isPasswordIssued
        ? undefined // 再発行時はステータスを更新しない
        : PatientEProStatus.BeforeTrial
      dispatch({ type: 'password-issued', password: newPassword })
      patientDispatch(
        actions.updateEProPasswordIssued({
          uid: patientUid,
          isPasswordIssued: true,
          eproStatus,
        }),
      )
    } finally {
      dispatch({ type: 'loading', isLoading: false })
    }
  }, [patientUid, trialUid, patientDispatch, isPasswordIssued])

  const onClickIssue = useCallback(async () => {
    await issuePassword()
  }, [issuePassword])

  const onClose = useCallback(() => {
    dispatch({ type: 'close-modal' })
  }, [])

  const onClickButton = useCallback(() => {
    dispatch({ type: 'open-password-issue-modal' })
  }, [])

  return (
    <PasswordIssueButton
      isPasswordIssued={patient.isPasswordIssued}
      state={state}
      onClickButton={onClickButton}
      onClose={onClose}
      onClickIssue={onClickIssue}
    ></PasswordIssueButton>
  )
}
