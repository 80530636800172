import { useEffect, useState } from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useSpinner } from 'src/hooks/use-spinner'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useCreateEnvelope, useExplanation } from '../../api'
import { useSendEnvelopeEmail } from '../../api/sendEnvelopeEmail'
import {
  ExplanationDocRevisionAgreementForm,
  ExplanationDocRevisionCheckUnderstanding,
  ExplanationRemoteSession,
  ExplanationSignerRole,
} from '../../types'

type Props = {
  docRevision:
    | ExplanationDocRevisionAgreementForm
    | ExplanationDocRevisionCheckUnderstanding
  mutateDocRevision: () => void
  session: ExplanationRemoteSession
}

export const SendEnvelopeEmail: React.FC<Props> = ({
  docRevision,
  mutateDocRevision,
  session,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [modalMode, setModalMode] = useState<'Send' | 'Sent'>('Send')
  const [isFirstSent, setIsFirstSent] = useState(true)

  const { showSpinner, hideSpinner } = useSpinner()
  const toast = useMirohaToast()

  const { request: createEnvelope } = useCreateEnvelope({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      mutateDocRevision()
      setModalMode('Sent')
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const { request: sendEmail } = useSendEnvelopeEmail({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      mutateDocRevision()
      setModalMode('Sent')
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const typeText = docRevision.type === 'AgreementForm' ? '署名' : '回答'

  const { data: explanation } = useExplanation({
    explanationUid: session.explanationRevision.explanationUid,
    detailed: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  useEffect(() => {
    if (docRevision.dsEnvelopeId !== '') {
      setIsFirstSent(false)
    }

    // NOTE: docRevisionの更新を監視すると、初回送信モーダルが表示されなくなるのでレンダリング時のみ判定する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!explanation) {
    return null
  }
  const isRejected =
    docRevision.type === 'AgreementForm'
      ? docRevision.signHistories.some(h => h.isRejected)
      : false

  if (isRejected) {
    return null
  }
  if (docRevision.isCompleted) {
    return null
  }

  const onSubmit = async () => {
    if (isFirstSent) {
      // メール送信も同時に行われる
      await createEnvelope({
        type: docRevision.type,
        explanationSessionUid: session.uid,
        explanationDocRevisionUid: docRevision.uid,
      })
      return
    }

    const signerRoles: ExplanationSignerRole[] =
      docRevision.type === 'AgreementForm'
        ? (
            (includeCRC
              ? ['Patient', 'Dr', 'CRC']
              : ['Patient', 'Dr']) as ExplanationSignerRole[]
          ).filter(
            // 署名未完了のRoleのみに送信
            role =>
              !docRevision.signHistories.some(
                signHist => signHist.signerRole === role,
              ),
          )
        : ['Patient']

    // メール送信のみ行う
    await sendEmail({
      type: docRevision.type,
      explanationDocRevisionUid: docRevision.uid,
      explanationSessionUid: session.uid,
      signerRoles: signerRoles,
    })
  }

  const handleClose = () => {
    setModalMode('Send')

    // 初回送信モーダルを閉じる際に、初回送信フラグをfalseにする
    if (isFirstSent) {
      setIsFirstSent(false)
    }

    onClose()
  }

  const includeCRC =
    docRevision.type === 'AgreementForm' &&
    docRevision.signerMembers.some(m => m.role === 'CRC')

  return (
    <>
      <Button colorScheme="green" onClick={onOpen}>
        {isFirstSent
          ? `${typeText}用リンクを送信`
          : `${typeText}用リンクを再送`}
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />

        {modalMode === 'Send' ? (
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {isFirstSent
                ? `${typeText}用リンクを送信します`
                : `${typeText}用リンクを再送します`}
            </ModalHeader>
            <ModalBody>
              <HStack>
                <Text>患者:</Text>
                <Text>{explanation.patient.email}</Text>
              </HStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing="4">
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={handleClose}
                >
                  キャンセル
                </Button>
                <Button colorScheme="green" onClick={onSubmit}>
                  {isFirstSent ? '送信する' : '再送する'}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>
              {isFirstSent
                ? `${typeText}用リンクを送信しました`
                : `${typeText}用リンクを再送しました`}
            </ModalHeader>
            <ModalBody>
              <Text
                size="sm"
                color="green.500"
              >{`受信者の${typeText}完了後、「最新状態に更新」ボタンを押して${typeText}結果を取得してください。`}</Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" onClick={handleClose}>
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
