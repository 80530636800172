import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { PatientEProStatus } from 'src/modules/entities/patient/entity'
import { actions } from 'src/modules/entities/patient/redux'
import { confirmAnswerStateDate } from 'src/modules/entities/patient/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

export const useConfirmAnswerStartDate = () => {
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const request = useCallback(
    async ({
      trialUid,
      patientUid,
    }: {
      trialUid: string
      patientUid: string
    }) => {
      try {
        requestStarted()

        await confirmAnswerStateDate({ trialUid, patientUid })
        requestDone()
        dispatch(
          actions.updateEProStatus({
            uid: patientUid,
            eproStatus: PatientEProStatus.InTrial,
          }),
        )
        showSuccess(`試験開始日を確定しました`)
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [dispatch, requestDone, requestFailed, requestStarted, showSuccess],
  )
  return { request, requesting, errorMessage }
}
