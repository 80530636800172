import React, { useCallback, useMemo } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FileSelectButton } from 'src/components/FileSelectButton/FileSelectButton'
import { Select } from 'src/components/Select/Select'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { useBuildTemplate } from './use-build-template'
import { Template } from '../../../../../entities/template/entity'

export const createTypes = ['New', 'Import', 'Preset']
export type CreateType = 'New' | 'Import' | 'Preset'

const createTypeItems: { value: CreateType; name: string }[] = [
  {
    value: 'New',
    name: '新規作成',
  },
  {
    value: 'Import',
    name: 'インポートで作成',
  },
  {
    value: 'Preset',
    name: 'プリセットを使用',
  },
]

export type SubmitValues = {
  type: CreateType
  title: string
  fileName: string
  content: string
  presetTemplate: Template | undefined
}

type Props = {
  templateFolderName: string
  presetTemplates: Template[]
  requesting: boolean
  errorMessage: string
  onClose: () => void
  onSubmit: (params: SubmitValues) => void
}

export const CreateTemplateModal: React.FC<Props> = props => {
  const { presetTemplates } = props
  const {
    title,
    presetTemplateUid,
    fileName,
    selectedType,
    content,
    handlers: {
      onChangeTemplateTitle,
      onChangeCreateType,
      onSelectPresetTemplate,
      onChangeFile,
    },
  } = useBuildTemplate()

  const presetTemplate = presetTemplates.find(t => t.uid === presetTemplateUid)

  const onSubmit = useCallback(() => {
    props.onSubmit({
      type: selectedType,
      title,
      content,
      fileName,
      presetTemplate,
    })
  }, [content, fileName, props, selectedType, title, presetTemplate])

  const canSubmit = useMemo(() => {
    if (!title || props.requesting) return false

    switch (selectedType) {
      case 'New':
        return true
      case 'Import':
        return !!fileName && !!content
      case 'Preset':
        return !!presetTemplateUid
    }
  }, [
    content,
    fileName,
    presetTemplateUid,
    props.requesting,
    selectedType,
    title,
  ])

  return (
    <Modal onClose={props.onClose} size="2xl" isOpen={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>テンプレートを追加</ModalHeader>
        <ModalBody>
          <Stack spacing="8">
            <Text fontWeight="bold">{props.templateFolderName}</Text>
            <FormControl>
              <FormLabel>テンプレート名</FormLabel>
              <Input value={title} onChange={onChangeTemplateTitle} />
            </FormControl>
            <RadioGroup
              mt="3"
              onChange={onChangeCreateType}
              value={selectedType}
            >
              <HStack spacing="10">
                {createTypeItems.map(item => (
                  <Radio key={item.value} value={item.value}>
                    {item.name}
                  </Radio>
                ))}
              </HStack>
            </RadioGroup>
            <Box mt="4">
              {selectedType === 'Preset' && (
                <Select
                  items={presetTemplates.map(t => ({
                    value: t.uid,
                    label: t.title,
                  }))}
                  value={presetTemplateUid}
                  onChange={onSelectPresetTemplate}
                />
              )}
              {selectedType === 'Import' && (
                <HStack spacing="4">
                  {!!fileName && <Text>{fileName}</Text>}

                  <FileSelectButton
                    accept="application/json"
                    onChange={file => onChangeFile(file)}
                    buttonProps={{ variant: 'text' }}
                  />
                </HStack>
              )}
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button onClick={onSubmit} isDisabled={!canSubmit}>
            確定
          </Button>
        </ModalFooter>
        {props.errorMessage && (
          <Text fontSize="sm" color="red.500">
            {props.errorMessage}
          </Text>
        )}
      </ModalContent>
    </Modal>
  )
}
