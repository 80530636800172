import { useState } from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {
  ArrowLeft,
  ArrowRight,
  Attachment,
  Document,
} from 'src/components/icon'
import { RightClickBlocker } from 'src/components/RightClickBlocker/RightClickBlocker'
import { useMirohaSwiper } from 'src/hooks/useMirohaSwiper'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { openNewWindow } from 'src/utils/openNewWindow'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

type FileListItem = {
  fileUid: string
  fileName: string
  fileExtension: string
  fileUrl: string
  order: number
  memo: string
}

type Props = {
  isEditing: boolean
  canEdit: boolean
  fileItems: FileListItem[]
  isRepeatableSectionChild?: boolean
  onChangeMemo: (fileUid: string, memo: string) => void
  fileViewerPath: (order: number) => string
}

const slideWidth = 160
const gapWidth = 20
const MAX_MEMO_LENGTH = 300

export const FileListComponent: React.FC<Props> = ({
  isEditing,
  canEdit,
  fileItems,
  isRepeatableSectionChild,
  onChangeMemo,
  fileViewerPath,
}) => {
  const [memoEditingFile, setMemoEditingFile] = useState<FileListItem>()

  return (
    <Box w="full">
      {!!memoEditingFile && (
        <MemoModal
          memoEditingFile={memoEditingFile}
          canEdit={canEdit}
          onChangeMemo={onChangeMemo}
          onClose={() => {
            setMemoEditingFile(undefined)
          }}
        />
      )}
      <Divider />
      <Box w="full" my="6" pos="relative">
        <Swiper
          spaceBetween={gapWidth}
          slidesPerView="auto"
          // NOTE: keyboard操作時のbuttonの位置を保つためstaticにして親をrelativeにする（Swiperのdefaultはrelative）
          style={{ padding: '0 54px', position: 'static' }}
        >
          <Flex
            pos="absolute"
            w="54px"
            left="0"
            top="0"
            bg={isRepeatableSectionChild ? 'blue.50' : 'white'}
            h="full"
            align="center"
            justify="start"
            zIndex="1"
          >
            <SwiperButton direction="prev" />
          </Flex>
          {fileItems.map(item => (
            <SwiperSlide key={item.fileUid} style={{ width: slideWidth }}>
              <Stack w="full" spacing="2.5">
                <Center pos="relative">
                  <Text>{item.order}</Text>
                  <IconButton
                    pos="absolute"
                    isDisabled={!canEdit && !item.memo}
                    right={0}
                    icon={<Attachment />}
                    aria-label="メモ"
                    variant="customIconButtonGhost"
                    color={!!item.memo ? 'blue.500' : 'gray.200'}
                    onClick={() => {
                      setMemoEditingFile(item)
                    }}
                  />
                </Center>
                <Box pos="relative">
                  <RightClickBlocker>
                    <AspectRatio
                      key={item.fileUrl}
                      ratio={1}
                      aria-label={item.fileName}
                      border="1px solid"
                      w="full"
                      borderColor="gray.200"
                      pos="relative"
                      cursor="pointer"
                      as={isEditing ? 'button' : Link}
                      {...(isEditing
                        ? {}
                        : {
                            href: fileViewerPath(item.order),
                            isExternal: true,
                          })}
                      display="block"
                      onClick={() => {
                        if (isEditing) {
                          openNewWindow(item.fileUrl, 'newWindow')
                          return
                        }
                      }}
                    >
                      {item.fileExtension.toLowerCase() !== 'pdf' ? (
                        <Image
                          src={item.fileUrl}
                          alt={item.fileName}
                          objectFit="cover"
                        />
                      ) : (
                        <Center>
                          <Document size="48" />
                        </Center>
                      )}
                    </AspectRatio>
                  </RightClickBlocker>
                </Box>
              </Stack>
            </SwiperSlide>
          ))}
          <Flex
            pos="absolute"
            w="54px"
            right="0"
            top="0"
            bg={isRepeatableSectionChild ? 'blue.50' : 'white'}
            h="full"
            align="center"
            justify="end"
            zIndex="1"
          >
            <SwiperButton direction="next" />
          </Flex>
        </Swiper>
      </Box>
      <Divider />
    </Box>
  )
}

const SwiperButton: React.FC<{ direction: 'prev' | 'next' }> = ({
  direction,
}) => {
  const swiper = useMirohaSwiper()

  return (
    <IconButton
      variant="customIconButtonGhost"
      icon={direction === 'prev' ? <ArrowLeft /> : <ArrowRight />}
      aria-label={direction === 'prev' ? '手前に移動する' : '奥に移動する'}
      isDisabled={
        direction === 'prev'
          ? !swiper.prevSlideEnabled
          : !swiper.nextSlideEnabled
      }
      onClick={() => {
        if (direction === 'prev') {
          swiper.slidePrev()
        } else {
          swiper.slideNext()
        }
      }}
    />
  )
}

type MemoModalProps = {
  memoEditingFile: FileListItem
  canEdit: boolean
  onChangeMemo: (fileUid: string, memo: string) => void
  onClose: () => void
}

const MemoModal: React.FC<MemoModalProps> = ({
  memoEditingFile,
  canEdit,
  onChangeMemo,
  onClose,
}) => {
  const [inputMemo, setInputMemo] = useState<string>(memoEditingFile.memo)

  const validated = inputMemo.length <= MAX_MEMO_LENGTH

  return (
    <Modal isOpen onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader wordBreak="break-all">
          メモ: {memoEditingFile.fileName}
        </ModalHeader>
        <ModalBody>
          <Textarea
            value={inputMemo}
            onChange={e => setInputMemo(e.target.value)}
            placeholder={'テキスト'}
            minH="200px"
            isDisabled={!canEdit}
            wordBreak="keep-all"
          />
          {!validated && (
            <Text color="red.500" fontSize="sm">
              {`メモは${MAX_MEMO_LENGTH}文字以内で入力してください`}
            </Text>
          )}
        </ModalBody>
        {canEdit && (
          <ModalFooter>
            <ModalCancelButton />
            <Button
              isDisabled={memoEditingFile.memo === inputMemo || !validated}
              onClick={() => {
                onChangeMemo(memoEditingFile.fileUid, inputMemo)
                onClose()
              }}
            >
              登録
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}
