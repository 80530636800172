import React from 'react'

import { Route, Routes } from 'react-router-dom'
import { getRelativePath, PathKey } from 'src/constants/paths'
import { Authenticator } from 'src/features/auth/components'

import { DocumentViewerScreen, PartnerTopScreen } from '../components'
import { PartnerDocumentSignScreen } from '../components/PartnerDocumentSignScreen/PartnerDocumentSignScreen'
import { PartnerLobbyScreen } from '../components/PartnerLobbyScreen/PartnerLobbyScreen'
import { PartnerSignCompletedScreen } from '../components/PartnerSignCompletedScreen/PartnerSignCompletedScreen'

export const PartnerExplanationRoomRoutes: React.FC = () => {
  const getPath = (path: PathKey): string => {
    return getRelativePath('PartnerExplanationRoom', path)
  }

  return (
    <Authenticator>
      <Routes>
        <Route
          path={getPath('PartnerExplanationRoomLobby')}
          element={<PartnerLobbyScreen />}
        />
        <Route
          path={getPath('PartnerExplanationRoomTop')}
          element={<PartnerTopScreen />}
        />
        <Route
          path={getPath('PartnerExplanationRoomDocument')}
          element={<DocumentViewerScreen isPartner />}
        />
        <Route
          path={getPath('PartnerExplanationRoomDocumentSign')}
          element={<PartnerDocumentSignScreen />}
        />
        <Route
          path={getPath('PartnerExplanationRoomSignCompleted')}
          element={<PartnerSignCompletedScreen />}
        />
      </Routes>
    </Authenticator>
  )
}
