import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { OutputModal } from 'src/components/modal/output'
import { Spacer } from 'src/components/spacer/spacer'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { getSingle as getPatient } from 'src/modules/entities/patient/selector'
import { getSingle as getTemplateFolder } from 'src/modules/entities/template-folder/selector'
import { getSingle } from 'src/modules/entities/worksheet/selector'
import { RootState } from 'src/modules/reducer'
import { QueryParamKeys } from 'src/modules/router'
import { routes } from 'src/modules/routes'
import styled from 'styled-components'

import { downloadFile } from './request'
import { patientIdParamName } from '../../../../patient-detail'
import { worksheetIdParamName } from '../worksheet-detail'

type Props = {
  onClose: () => void
}

export const OutputModalContainer: React.FC<Props> = props => {
  const { patientUid = '', worksheetUid = '' } = useParams<{
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()
  const trial = useSelector(getSelectedTrial)!

  const onSubmit = ({
    isHistory,
    isWorksheet,
    isCallHistory,
  }: {
    isHistory: boolean
    isWorksheet: boolean
    isCallHistory: boolean
  }) => {
    const url = `${routes.worksheetPdf}/${trial.uid}/${patientUid}/${worksheetUid}?${QueryParamKeys.History}=${isHistory}&${QueryParamKeys.Worksheet}=${isWorksheet}&${QueryParamKeys.CallHistory}=${isCallHistory}`
    window.open(url, '_blank')
    props.onClose()
  }

  const { videoEnabled } = trial

  const worksheet = useSelector((state: RootState) =>
    getSingle(state, worksheetUid),
  )
  const templateFolder = useSelector((state: RootState) =>
    getTemplateFolder(state, worksheet.templateFolderUid),
  )

  const patient = useSelector((state: RootState) =>
    getPatient(state, patientUid),
  )

  if (!patient) {
    return null
  }

  return (
    <OutputModal
      title={worksheet.title}
      submitText={`出力`}
      errorMessage={``}
      requesting={false}
      videoEnabled={videoEnabled && worksheet.videoEnabled}
      onSubmit={onSubmit}
      onClose={props.onClose}
      onDownloadCapture={() => {
        const download = async () => {
          const res = await downloadFile({
            trialUid: trial.uid,
            patientUid,
            worksheetUid,
          })
          const link = document.createElement('a')
          link.href = res.url
          link.click()
          link.remove()
        }

        download()
      }}
    >
      <Text textAlign="center">
        症例番号：<Bold>{`${patient.diseaseUid}`}</Bold>さんの
        {templateFolder.name}
        <Bold>「{worksheet.title}」</Bold>
        を画面出力します。
      </Text>
      <Spacer size={10} />
      <Text textAlign="center">
        出力結果を印刷機能を使ってPDF形式などで保存をお願いします。
      </Text>
      <Spacer size={10} />
      <Text fontSize="xs" textAlign="center">
        ※出力完了まで時間がかかる場合があります。
      </Text>
    </OutputModal>
  )
}

const Bold = styled.span`
  font-weight: bold;
`
