import React from 'react'

import { Box, Grid, GridItem } from '@chakra-ui/react'

import { List } from './common/list/list'
import { Head } from './head'

export const Template = () => {
  return (
    <Grid boxSize="full" overflow="auto" templateColumns="320px 1fr">
      <Box
        h="full"
        overflowX="auto"
        borderRight="1px solid"
        borderColor="gray.100"
      >
        <Head />
      </Box>

      <GridItem h="full" overflowY="auto">
        <List />
      </GridItem>
    </Grid>
  )
}
