import React from 'react'

import { Button, Flex, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'

type Props = {
  title: string
  height: number
  canFinish: boolean
  isPreview?: boolean
  onClickFinish?: () => void
}

// NOTE: previewとルームのみで使用
export const ExplanationRoomHeader: React.FC<Props> = ({
  title,
  height,
  canFinish,
  isPreview,
  onClickFinish,
}) => {
  return (
    <Flex
      h={`${height}px`}
      w="full"
      bg="white"
      align="center"
      justify="space-between"
      p="6"
      borderBottom="1px solid"
      borderBottomColor="gray.400"
    >
      <img src={MirohaLogo} alt="miroha-logo" />
      <Text fontSize="2xl" fontWeight="bold" color="green.500">
        {title}
      </Text>
      <div>
        {canFinish && !!onClickFinish && (
          <Button
            colorScheme="red"
            variant="outline"
            // NOTE: 遠隔パートナー実装により、ここは説明ロビーからしか利用されない想定
            onClick={onClickFinish}
          >
            {isPreview ? 'プレビューを終了' : `説明ロビーから退出する`}
          </Button>
        )}
      </div>
    </Flex>
  )
}
