import dayjs from 'dayjs'
import { errorMessages } from 'src/constants/errorMessages'
import { IcfDocumentSet } from 'src/features/icfDocument/types'
import { MemberRole } from 'src/features/member/types'
import {
  alphanumericsAndSpecialCharsRegExp,
  phoneNumberRegExp,
} from 'src/utils/regExp'
import * as yup from 'yup'

import { ExplanationRevisionStatus, ExplanationType } from '../types'

const memberSchema = yup.object({
  id: yup.string().required(),
  trialHospitalUid: yup.string().required(),
  member: yup.object({
    uid: yup.string().required(errorMessages.shouldSelect('担当者')),
    role: yup.mixed<MemberRole>(),
  }),
})

export type MemberSchema = yup.InferType<typeof memberSchema>

export const explanationMembersSchema = (explanationType?: ExplanationType) =>
  yup
    .array()
    .of(memberSchema)
    .test('duplicate member', '同一の担当者が複数行指定されています', value => {
      if (!value) return false
      const uids = value.map(v => v.member.uid)
      return new Set(uids).size === uids.length
    })
    .test(
      'if selected, Dr required',
      'Drの担当者を1名以上選択してください',
      value => {
        if (!value || value.length === 0) return true
        return value.some(v => v.member.role === 'Dr')
      },
    )
    .test('only one Dr', '担当者に設定できるDrは1名です', (value, ctx) => {
      if (!value) return false
      const type = explanationType ?? (ctx.parent.type as ExplanationType)
      if (type === 'RemotePartner') return true
      return value.filter(m => m.member.role === 'Dr').length <= 1
    })
    .test('only one CRC', '担当者に設定できるCRCは1名です', (value, ctx) => {
      if (!value) return false
      const type = explanationType ?? (ctx.parent.type as ExplanationType)
      if (type === 'RemotePartner') return true
      return value.filter(m => m.member.role === 'CRC').length <= 1
    })
    .required()

export type ExplanationMembersSchema = yup.InferType<
  ReturnType<typeof explanationMembersSchema>
>

export const explanationFormSchema = ({
  status,
  email,
  phoneNumber,
  eConsentNewSignFlow,
  underEditSchedule,
}: {
  status: ExplanationRevisionStatus | undefined
  email: string | undefined
  phoneNumber: string | undefined
  eConsentNewSignFlow: boolean
  underEditSchedule: boolean
}) =>
  yup.object({
    type: yup
      .mixed<ExplanationType>()
      .required(errorMessages.requiredWithLabel('説明方式')),
    candidateId: yup
      .string()
      .matches(alphanumericsAndSpecialCharsRegExp, {
        message: errorMessages.halfSizeAlphanumericAndSymbol('候補ID'),
      })
      .max(10, errorMessages.maxLength(10, '候補ID'))
      .required(errorMessages.requiredWithLabel('候補ID')),
    diseaseId: yup
      .string()
      .matches(alphanumericsAndSpecialCharsRegExp, {
        excludeEmptyString: true,
        message: errorMessages.halfSizeAlphanumericAndSymbol('症例番号'),
      })
      .max(20, errorMessages.maxLength(20, '症例番号'))
      .optional(),
    email: yup
      .string()
      .email(errorMessages.email())
      .when('type', {
        is: 'Remote',
        then: schema =>
          schema.required(errorMessages.requiredWithLabel('メールアドレス')),
        otherwise: schema => schema.transform(() => email),
      }),
    phoneNumber: yup
      .string()
      .matches(phoneNumberRegExp, {
        excludeEmptyString: true,
        message: errorMessages.phoneNumber(),
      })
      .max(11, errorMessages.maxLength(11, '携帯電話番号'))
      .when('type', {
        is: (type: ExplanationType) =>
          !eConsentNewSignFlow && type === 'Remote',
        then: schema =>
          schema.required(errorMessages.requiredWithLabel('携帯電話番号')),
      }),
    docSetUids: yup
      .array(yup.mixed<IcfDocumentSet['uid']>().required())
      .test(
        'at least one docSetUids',
        errorMessages.requiredWithLabel('文書セット'),
        value => {
          if (!value) return false
          return value.length > 0
        },
      )
      .default([]),
    scheduledAt: yup.string().when('type', {
      is: (type: ExplanationType) =>
        (type === 'Remote' || type === 'RemotePartner') &&
        status !== 'AgreementDone',
      then: schema =>
        schema
          .test('incomplete', errorMessages.incompleteDate(), value => {
            return !!value || !underEditSchedule
          })
          .test('valid date', errorMessages.afterNow(), value => {
            return (
              !value || !dayjs(value).isValid() || dayjs(value).isAfter(dayjs())
            )
          }),
      otherwise: schema => schema.optional(),
    }),
    notifyToPatient: yup.boolean().optional(),
    partnerTrialHospitalUid: yup.string().when('type', {
      is: 'RemotePartner',
      then: schema =>
        schema.required(errorMessages.shouldSelect('パートナー施設')),
      otherwise: schema => schema.optional(),
    }),
    representative: yup
      .mixed<'with' | 'without'>()
      .oneOf(['with', 'without'])
      .required(),
    members: explanationMembersSchema(),
  })

export type ExplanationFormSchema = yup.InferType<
  ReturnType<typeof explanationFormSchema>
>
