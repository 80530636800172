import { HttpResponse } from 'msw'
import {
  createWorksheetLogMock,
  ConfirmWorksheetDocument,
  ConfirmWorksheetMutation,
  ConfirmWorksheetMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

import { worksheetWithSchemaResponseFactory } from '../../testUtils/factory'

export const confirmWorksheetHandler = graphQLHandlerFactory<
  ConfirmWorksheetMutation,
  ConfirmWorksheetMutationVariables
>('mutation', ConfirmWorksheetDocument, () => {
  return HttpResponse.json({
    data: {
      confirmWorksheet: {
        worksheet: worksheetWithSchemaResponseFactory({
          latestWorksheetLog: createWorksheetLogMock({
            status: 'Confirmed',
          }),
        }),
      },
    },
  })
})
