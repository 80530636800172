import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSendEnvelopeEmail } from '../../api/sendEnvelopeEmail'
import {
  ExplanationDocRevisionAgreementForm,
  ExplanationSession,
} from '../../types'
import { memberRoleToSignerRole } from '../../utils/memberRoleToSignerRole'

type Props = {
  /** 署名リンクを送る場合、送らない場合のいずれも呼ばれる */
  onConfirm: () => void
  docRevision: ExplanationDocRevisionAgreementForm
  sessionUid: ExplanationSession['uid']
}

export const PatientSignConfirmation: React.FC<Props> = ({
  onConfirm,
  docRevision,
  sessionUid,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    onConfirm()
    onClose()
  }

  const toast = useMirohaToast()

  const { request: sendEnvelopeEmail } = useSendEnvelopeEmail({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '署名用リンクを送信しました',
      })
      onConfirm()
      onClose()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  return (
    <>
      <Button onClick={onOpen} colorScheme="green">
        確認
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>患者の署名が完了しました</ModalHeader>
          <ModalBody>
            <Stack spacing="4">
              <Text>続いて説明担当者の署名を実施してください</Text>
              <Text fontWeight="bold">
                署名担当者が既に説明ルームを退出されていたり、後ほど署名を実施される場合は「署名用リンクを送信する」ボタンよりメールで署名用リンクを送信してください。
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="green"
              onClick={async () => {
                await sendEnvelopeEmail({
                  type: 'AgreementForm',
                  explanationDocRevisionUid: docRevision.uid,
                  explanationSessionUid: sessionUid,
                  signerRoles: docRevision.signerMembers.map(m =>
                    memberRoleToSignerRole(m.role),
                  ),
                })
              }}
            >
              署名用リンクを送信する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
