import {
  GqlError,
  GetDocRevisionForBrowseQuery,
  GetDocRevisionForBrowseDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type GetDocRevisionForBrowseRes =
  GetDocRevisionForBrowseQuery['explanationDocRevision']

const getDocRevisionForBrowse = async ({
  explanationDocRevisionUid,
}: {
  explanationDocRevisionUid: string
}): Promise<GetDocRevisionForBrowseRes> => {
  try {
    const res = await graphqlRequest(GetDocRevisionForBrowseDocument, {
      explanationDocRevisionUid,
    })
    return res.explanationDocRevision
  } catch (error) {
    throw error
  }
}

export const useDocRevisionForBrowse = ({
  explanationDocRevisionUid,
  ...option
}: {
  explanationDocRevisionUid: string
} & SWRConfiguration<GetDocRevisionForBrowseRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getDocRevisionForBrowse(explanationDocRevisionUid),
    () => getDocRevisionForBrowse({ explanationDocRevisionUid }),
    option,
  )
}
