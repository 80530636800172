import { useMemo } from 'react'

import { useReleases } from '../api/getReleases'

export const useCurrentVersion = () => {
  const { data: releases } = useReleases({ revalidateIfStale: false })

  const currentVersion = useMemo(() => {
    if (!releases) return ''
    if (releases.length === 0) return ''
    return releases?.[0].version
  }, [releases])

  return {
    currentVersion,
  }
}
