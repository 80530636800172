import { Button } from '@chakra-ui/react'
import { useAuth } from 'src/features/auth/hooks'
import { useSpinner } from 'src/hooks/use-spinner'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSyncEnvelope } from '../../api'
import { ExplanationDocRevision, ExplanationSession } from '../../types'

type Props = {
  session: ExplanationSession
  docRevision: ExplanationDocRevision
  mutateDocRevision: () => void
}

const shouldShow = (
  session: ExplanationSession,
  docRevision: ExplanationDocRevision,
) => {
  const { explanationRevision } = session
  const { type } = docRevision

  if (explanationRevision.status === 'AgreementRejected') {
    return false
  }

  if (type !== 'AgreementForm' && type !== 'CheckUnderstanding') {
    return false
  }

  return !!docRevision.dsEnvelopeId
}

export const SyncEnvelope: React.FC<Props> = ({
  session,
  docRevision,
  mutateDocRevision,
}) => {
  const { showSpinner, hideSpinner } = useSpinner()

  const toast = useMirohaToast()

  const { request: syncEnvelope } = useSyncEnvelope({
    onRequestStarted: () => {
      showSpinner()
    },
    onRequestDone: () => {
      hideSpinner()
    },
    onSuccess: () => {
      mutateDocRevision()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const authState = useAuth()

  if (authState.state === 'Challenging' || authState.state === 'Failed') {
    return null
  }

  if (!shouldShow(session, docRevision)) {
    return null
  }

  return (
    <Button
      colorScheme="green"
      onClick={async () => {
        await syncEnvelope({
          explanationSessionUid: session.uid,
          explanationDocRevisionUid: docRevision.uid,
        })
      }}
    >
      最新状態に更新
    </Button>
  )
}
