import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import {
  ScheduleCreateRequest,
  update,
  destroy,
} from 'src/modules/entities/schedule/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { actions } from '../../redux'

export const useSubmitRegister = (props: { scheduleUid: string }) => {
  const dispatch = useDispatch()
  const trialUid = useSelector(getSelectedTrial)!.uid
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const { showSuccess } = useFlash()

  const updateRequest = useCallback(
    async (params: ScheduleCreateRequest) => {
      try {
        requestStarted()

        const newSchedule = await update(trialUid, props.scheduleUid, params)
        dispatch(actions.updateSchedule(newSchedule))

        requestDone()
        showSuccess(`回答日を更新しました。`)
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [
      dispatch,
      props.scheduleUid,
      requestDone,
      requestFailed,
      requestStarted,
      showSuccess,
      trialUid,
    ],
  )

  const deleteRequest = useCallback(async () => {
    try {
      requestStarted()

      await destroy(trialUid, props.scheduleUid)
      dispatch(actions.deleteSchedule(props.scheduleUid))

      requestDone()
      showSuccess(`回答日を削除しました。`)
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }, [
    dispatch,
    props.scheduleUid,
    requestDone,
    requestFailed,
    requestStarted,
    showSuccess,
    trialUid,
  ])

  return { updateRequest, deleteRequest, requesting, errorMessage }
}
