import { isMobile } from 'react-device-detect'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { Select } from 'src/components/Select/Select'
import { useActiveMembersByTrialHospitalUids } from 'src/features/member/api/getActiveTrialMembersByTrialHospitalUids'
import { Member, MemberRole } from 'src/features/member/types'
import { TrialHospital } from 'src/features/trial/types'
import { getFullName } from 'src/utils/getFullName'

type Props = {
  trialHospitalUid: TrialHospital['uid'] | undefined
  memberUid: Member['uid'] | undefined
  isDisabled?: boolean
  isInvalid: boolean
  isPartner?: boolean
  width?: number
  onChange: (value: { uid: Member['uid']; role: MemberRole }) => void
}

export const MemberSelect: React.FC<Props> = ({
  trialHospitalUid,
  memberUid,
  isDisabled = false,
  isInvalid,
  isPartner,
  width,
  onChange,
}) => {
  const { data: members } = useActiveMembersByTrialHospitalUids({
    shouldCancel: !trialHospitalUid,
    trialHospitalUids: [trialHospitalUid!],
  })

  return (
    <Select
      isSearchable={!isMobile}
      width={width ?? 320}
      value={memberUid}
      isDisabled={isDisabled}
      items={(members ?? [])
        .filter(m => m.role === 'Dr' || m.role === 'CRC')
        .map(m => ({
          label: (
            <MemberLabel
              role={m.role}
              displayName={getFullName(m)}
              isPartner={isPartner}
            />
          ),
          value: m.uid,
          filterValue: `${getFullName(m)} ${m.role}`,
        }))}
      onChange={value => {
        const role = members?.find(m => m.uid === value)?.role
        if (!role) return
        onChange({
          uid: value,
          role,
        })
      }}
      isInvalid={isInvalid}
    />
  )
}
