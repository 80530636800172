import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { TrialSelection, Hospital } from './entity'
import { fixture } from './fixture'

type TrialSelectionResponse = {
  uid: string
  name: string
  role: number
  trial_hospitals: Array<{
    uid: string
    name: string
  }>
}

const parse = (resp: TrialSelectionResponse): TrialSelection => {
  const res: TrialSelection = {
    uid: resp.uid,
    name: resp.name,
    role: resp.role,
    trialHospitals: [],
  }

  resp.trial_hospitals.forEach(h => {
    const hospital: Hospital = {
      uid: h.uid,
      name: h.name,
    }
    res.trialHospitals.push(hospital)
  })

  return res
}

export const fetchList = async (): Promise<TrialSelection[]> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const resp = await requestGet<TrialSelectionResponse[]>({
    url: `${SERVER_URL}/trials_with_trial_hospitals`,
    params: [],
  })
  return resp.data.map(parse)
}
