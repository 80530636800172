import { Checkbox, CheckboxGroup, Wrap, WrapItem } from '@chakra-ui/react'
import { CheckboxTypeDef, Choice, Field } from '@micin-jp/chicken-schema'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  checkboxField: Field & {
    typeDef: CheckboxTypeDef
  }
  index: number
}

export const WorksheetCheckboxField: React.FC<Props> = ({
  checkboxField,
  index,
}) => {
  const {
    findFieldValue,
    onChangeCheckboxFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: checkboxField.fid,
    index,
  })

  const checkboxValue = value?.type === 'checkbox' ? value.checkboxValue : []

  const onChange = (cid: Choice['cid'], checked: boolean) => {
    const isEmpty =
      !value || value.type === 'cleared' || checkboxValue.length === 0

    if (isEmpty && !checked) return

    const newCheckboxValue = isEmpty
      ? [cid]
      : checked
        ? [...checkboxValue, cid]
        : checkboxValue.filter(c => c !== cid)

    onChangeCheckboxFieldValue({
      fid: checkboxField.fid,
      index,
      checkboxValue: newCheckboxValue,
    })
  }

  return (
    <WorksheetFieldFormControl
      field={checkboxField}
      index={index}
      isDisabled={
        !canEdit || !isRepeatableSectionEnabled(index, checkboxField.fid)
      }
      errorMessage={getErrorMessage(value)}
    >
      <CheckboxGroup>
        <Wrap spacing="8">
          {checkboxField.typeDef.choices.map(choice => (
            <WrapItem key={choice.cid}>
              <Checkbox
                className={choice.cid}
                isChecked={
                  value?.type === 'checkbox' &&
                  value.checkboxValue.includes(choice.cid)
                }
                onChange={e => onChange(choice.cid, e.target.checked)}
              >
                {choice.name}
              </Checkbox>
            </WrapItem>
          ))}
        </Wrap>
      </CheckboxGroup>
    </WorksheetFieldFormControl>
  )
}
