import { definitions } from 'src/lib/api-client/schema.gen'

import { Choice } from './entity'

export const parse = (resp: definitions['output.ChoiceResponse']): Choice => {
  return {
    uid: resp.uid,
    description: resp.description,
    edcValue: resp.edc_value,
    smallItemUids: resp.next_small_item_uids || [],
  }
}
