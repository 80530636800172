import React from 'react'

import { ErrorPage } from 'src/components/layout/error-page'

export const HttpError403 = () => (
  <ErrorPage
    message={
      'アクセスが制限されています。アクセス可能な環境から接続してください。'
    }
  />
)
