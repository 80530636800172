export type WorksheetTime = {
  hour: string | undefined
  minute: string | undefined
}

export const toWorksheetTime = (timeStr: string | undefined): WorksheetTime => {
  const [hour, minute] = timeStr?.split(':') ?? []
  return {
    hour,
    minute,
  }
}

export const toTimeString = (time: WorksheetTime): string => {
  const { hour, minute } = time

  if (!hour && !minute) return ''
  return `${hour ?? ''}:${minute ?? ''}`
}
