import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { actions as templateActions } from 'src/modules/entities/template/redux'
import { useRequestState } from 'src/modules/server/use-request-state'

import { requestRelease } from './request'

type Props = {
  trialUid: string
  templateUid: string
  templateTitle: string
  onClose: () => void
}

export const ReleaseTemplateModal: React.FC<Props> = ({
  trialUid,
  templateUid,
  templateTitle,
  onClose,
}) => {
  const { request, requesting } = useReleaseTemplate({
    trialUid,
    templateUid,
  })
  const dispatch = useDispatch()

  const toast = useMirohaToast()

  const onSubmit = () => {
    const submit = async () => {
      try {
        await request()
        dispatch(templateActions.release({ uid: templateUid }))
        toast({
          status: 'success',
          title: 'テンプレートを公開しました',
        })
      } catch (e) {
        toast({
          status: 'error',
          title: e.message,
        })
      }
    }

    submit()
  }

  return (
    <Modal isOpen onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>テンプレートを公開</ModalHeader>
        <ModalBody>
          <Text>
            <Text as="span" fontWeight="bold">
              {templateTitle}
            </Text>
            を公開します。
            <br />
            以後、各セクションの編集ができなくなります。
          </Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button onClick={onSubmit} isDisabled={requesting}>
            公開する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const useReleaseTemplate = ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}) => {
  const { requestDone, requestStarted, requesting, errorMessage } =
    useRequestState()

  const request = async () => {
    try {
      requestStarted()
      await requestRelease({ trialUid, uid: templateUid })
      requestDone()
    } catch (error) {
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}
