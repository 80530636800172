import { ExplanationRevisionStatus } from '../types'

const statusTextMap: Record<ExplanationRevisionStatus, string> = {
  SessionNotStarted: '説明未実施',
  SessionNotDone: '説明未完了',
  AgreementNotDone: '同意未完了',
  AgreementDone: '同意完了',
  AgreementRejected: '同意拒否',
  Disabled: '無効',
  Withdrawn: '同意撤回',
}

export const explanationRevisionStatusToText = (
  status: ExplanationRevisionStatus,
): string => statusTextMap[status]
