import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { ExplanationRevision } from '../../types'

const disableExplanationRevision = async ({
  trialUid,
  explanationRevisionUid,
  comment,
}: {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
  comment: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/disable',
    httpMethod: 'put',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: {
        comment,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useDisableExplanationRevision = (
  option?: UseMutationOption<void>,
) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    ({
      explanationRevisionUid,
      comment,
    }: {
      explanationRevisionUid: ExplanationRevision['uid']
      comment: string
    }) =>
      disableExplanationRevision({
        trialUid: selectedTrial.uid,
        explanationRevisionUid,
        comment,
      }),
    option,
  )
}
