import React from 'react'

import { useNavigate } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { Button } from 'src/components/base/button/button'
import { routes } from 'src/modules/routes'
import styled from 'styled-components'

export const ConfirmForgetPassword: React.FC<{}> = () => {
  const navigate = useNavigate()

  return (
    <PageContainer>
      <ContentContainer>
        <Head>パスワードを再設定しました。</Head>
        <Text>新しいパスワードで再ログインしてください。</Text>

        <BtnContainer>
          <Button
            size="S"
            text="ログイン画面"
            onClick={() => navigate(routes.login)}
            buttonType="normal"
          />
        </BtnContainer>
      </ContentContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.bgGray};
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 80px;
  padding: 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(133, 141, 168, 0.06);
  border-radius: 10px;
`

const Head = styled.p`
  width: 100%;
  margin: 0 0 24px;
  text-align: center;
  color: ${colors.dark};
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
`

const Text = styled.p`
  width: 100%;
  margin: 20px 0 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;

  > button {
    margin-right: 20px;
  }
`
