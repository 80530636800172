import { SmallItemAnswer } from './entity'
import { Role } from '../member/entity'
import { ItemType, DataType } from '../small-item/entity'

export const createFixture = (
  values?: Partial<SmallItemAnswer>,
): SmallItemAnswer => {
  const res: SmallItemAnswer = {
    type: 'worksheet',
    uid: '1',
    title: 'タイトル',
    itemType: ItemType.Date,
    description:
      '説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明',
    index: 0,
    dataType: DataType.Operational,
    inputRoles: [Role.Dr, Role.CRC],
    choiceAnswers: [],
    parentUid: null,
    hasLog: true,
    fixed: false,
    value: '',
    unit: '',
    smallItemUid: '',
    mediumItemAnswerUid: '',
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof SmallItemAnswer
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}
