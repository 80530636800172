export enum LogType {
  Change,
  Confirm,
}

export type SmallItemLog = {
  uid: string
  userName: string
  oldValue: string
  newValue: string
  reason: string
  title: string
  logType: LogType
  depth: number
  createdAt: string
}
