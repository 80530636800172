import React from 'react'

import { DevTool } from '@hookform/devtools'
import { Control, FieldValues } from 'react-hook-form'

export function useReactHookFormDevTools<T extends FieldValues>(
  control: Control<T>,
) {
  if (import.meta.env.PROD) {
    return null
  }
  if (import.meta.env.VITE_USE_REACT_HOOK_FORM_DEVTOOLS !== 'true') {
    return null
  }

  return <DevTool control={control} />
}
