import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import MirohaLogoFull from 'src/assets/image/miroha-logo-full.svg'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { RHFSingleCheckbox } from 'src/components/form-redesigned/single-checkbox'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'
import * as yup from 'yup'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${blue[5]};
`

const ContentContainerForm = styled.form`
  width: 80%;
  max-width: 600px;
  padding: 60px 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${white};
  border-radius: 10px;
`

const FormItem = styled.div`
  width: 100%;
  margin: 0 auto 10px;
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export interface IProps {
  errorMessage: string
  requesting: boolean
  onCancel: () => void
  onSubmit: (value: IFormValue) => Promise<void>
}

const validationSchema = yup.object().shape({
  authCode: yup.string().required(),
  rememberDevice: yup.boolean().default(false),
})

type IFormValue = yup.InferType<typeof validationSchema>

export const TwoFactor: React.FC<IProps> = ({
  errorMessage,
  requesting,
  onCancel,
  onSubmit: submit,
}) => {
  const { register, handleSubmit, formState } = useForm<IFormValue>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = (value: IFormValue) => {
    if (!isValid) return

    submit(value)
  }

  return (
    <PageContainer>
      <img src={MirohaLogoFull} alt="MiROHA icon" />
      <Spacer size={60} />
      <ContentContainerForm onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize="lg" fontWeight="bold">
          2段階認証ログイン
        </Text>
        <Spacer size={40} />
        <Text>
          認証用アプリに表示されている、認証コードを入力してください。
        </Text>

        <Spacer size={20} />

        <FormItem>
          <Label>認証コード</Label>
          <Spacer size={10} />
          <Input type="text" {...register('authCode')} />
          {errors.authCode && touchedFields.authCode && (
            <div>
              <Spacer size={4} />
              <Message type="error" message="認証コードを入力して下さい" />
            </div>
          )}
        </FormItem>

        <CheckboxItem>
          <Label>
            <RHFSingleCheckbox
              defaultChecked={false}
              name="rememberDevice"
              register={register}
            />
            <Spacer size={10} horizontal />
            このデバイスを信頼済みデバイスとして記録する
          </Label>
        </CheckboxItem>

        {errorMessage && (
          <div>
            <Spacer size={4} />
            <Message type="error" message={errorMessage} centered />
          </div>
        )}

        <Spacer size={40} />

        <BtnContainer>
          <Button
            size="S"
            text="キャンセル"
            onClick={onCancel}
            buttonType="cancel"
          ></Button>
          <Spacer size={40} horizontal />
          <Button
            size="S"
            text="ログイン"
            onClick={() => handleSubmit(onSubmit)}
            buttonType="important"
            disabled={!isValid || requesting}
          ></Button>
        </BtnContainer>
      </ContentContainerForm>
    </PageContainer>
  )
}
