import { Button, Center, Stack, Text } from '@chakra-ui/react'
import { MessageSuccess } from 'src/components/icon'
import { PatientSimpleScreenContainer } from 'src/components/PatientSimpleScreenContainer/PatientSimpleScreenContainer'

type Props = {
  docuSignUrl: string
  trialName: string
  documentName: string
}

export const SignAuthSuccess: React.FC<Props> = ({
  docuSignUrl,
  trialName,
  documentName: fileName,
}) => {
  return (
    <PatientSimpleScreenContainer>
      <Stack spacing="8" w="full">
        <Center color="green.500">
          <MessageSuccess size="72px" />
        </Center>
        <Text fontWeight="bold" fontSize="lg" textAlign="center">
          認証に成功しました
        </Text>
        <Center>
          <Text>下のボタンより署名画面へお進みください</Text>
        </Center>
        <Center w="full">
          <Button
            colorScheme="green"
            as="a"
            size="sp"
            href={docuSignUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            署名を開始する
          </Button>
        </Center>
      </Stack>
    </PatientSimpleScreenContainer>
  )
}
