import React from 'react'

import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Label } from 'src/components/form-redesigned/label'
import { MultiselectFilter } from 'src/components/form-redesigned/multiselect-filter'
import { SingleCheckboxWithValue } from 'src/components/form-redesigned/single-checkbox'
import { Spacer } from 'src/components/spacer/spacer'
import { TrialHospitalsByUid } from 'src/modules/entities/account/util'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { DataType } from 'src/modules/entities/small-item/entity'
import styled from 'styled-components'

import { HospitalNames } from '../../components/common/hospital-names'

type Handlers = {
  onChangeSmallItems: (ids: string[]) => void
  onChangeTrialHospitals: (ids: string[]) => void
  onClose: () => void
  onSubmit: () => void
}

type Props = {
  mediumItems?: MediumItem[]
  trialHospitalsByUid: TrialHospitalsByUid
  selectedSmallItemUids: string[]
  selectedTrialHospitalUids: string[]
  requesting: boolean
  handlers: Handlers
  errorMessage: string
}

export const VisibilityBulkSetModalComponent: React.FC<Props> = props => {
  const {
    mediumItems,
    trialHospitalsByUid,
    selectedSmallItemUids,
    selectedTrialHospitalUids,
    requesting,
    handlers,
    errorMessage,
  } = props

  const onClose = () => {
    if (requesting) return

    handlers.onClose()
  }

  const onSubmit = () => {
    if (requesting) return

    handlers.onSubmit()
  }

  const canSubmit = !requesting && selectedSmallItemUids.length > 0

  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="医療機関設定" />

      <ModalContent>
        <Label>フィールドを選択</Label>
        <Spacer size={10} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell width={48} />
                <TableHeadCell>セクション</TableHeadCell>
                <TableHeadCell>フィールド</TableHeadCell>
                <TableHeadCell width="50%">表示される医療機関</TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!!mediumItems &&
                mediumItems.flatMap(mi => {
                  const topLevelOperationalSavedSmallItem = Object.values(
                    mi.smallItemMap,
                  )
                    .filter(
                      si =>
                        !si.parentUid &&
                        !si.unsaved &&
                        si.dataType === DataType.Operational,
                    )
                    .sort((a, b) => a.index - b.index)
                  return topLevelOperationalSavedSmallItem.map(si => {
                    return (
                      <label key={si.uid}>
                        <TableRow borderBottom hover>
                          <TableBodyCell width={48} align="center">
                            <SingleCheckboxWithValue
                              value={si.uid}
                              checked={selectedSmallItemUids.some(
                                uid => uid === si.uid,
                              )}
                              onChange={(value: string) => {
                                const checked =
                                  selectedSmallItemUids.includes(value)

                                if (checked) {
                                  handlers.onChangeSmallItems(
                                    selectedSmallItemUids.filter(
                                      uid => uid !== value,
                                    ),
                                  )
                                } else {
                                  handlers.onChangeSmallItems(
                                    selectedSmallItemUids.concat([value]),
                                  )
                                }
                              }}
                            />
                          </TableBodyCell>
                          <TableBodyCell>{mi.title}</TableBodyCell>
                          <TableBodyCell>{si.title}</TableBodyCell>
                          <TableBodyCell width="50%">
                            <HospitalNames
                              trialHospitalsByUid={trialHospitalsByUid}
                              hospitalUids={si.viewableHospitalUids}
                            ></HospitalNames>
                          </TableBodyCell>
                        </TableRow>
                      </label>
                    )
                  })
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Spacer size={40} />

        <Label>医療機関を選択</Label>
        <Spacer size={10} />
        <MultiselectFilter
          items={Object.values(trialHospitalsByUid).map(th => ({
            id: th.uid,
            value: th.name,
          }))}
          selectedItemIds={selectedTrialHospitalUids}
          onChange={handlers.onChangeTrialHospitals}
        ></MultiselectFilter>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="決定"
          onClick={onSubmit}
          buttonType="important"
          disabled={!canSubmit}
        ></Button>
      </ModalActions>

      {errorMessage && (
        <div>
          <Spacer size={4} />
          <Message type="error" message={errorMessage} centered />
        </div>
      )}
    </Modal>
  )
}

const TableContainer = styled.div`
  width: 100%;
  height: 240px;
`
