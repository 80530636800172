import React from 'react'

import { useForm } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'

type Props = {
  title: string
  onClose: () => void
  onSubmit: (comment: string) => void
  required: boolean
  submitText: string
  submitButtonType: React.ComponentProps<typeof Button>['buttonType']
  descriptionComponent: React.ReactNode
}

type FormValue = {
  comment: string
}

const MAX_COMMENT_LENGTH = 255

export const WithCommentModal: React.FC<Props> = ({
  title,
  onClose,
  onSubmit: _onSubmit,
  required,
  descriptionComponent,
  submitText,
  submitButtonType,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValue>()
  const currentValue = watch('comment')

  const onSubmit = (data: FormValue) => {
    _onSubmit(data.comment)
  }

  return (
    <Modal onClose={onClose}>
      <ModalTitle title={title} />
      <ModalContent>
        {descriptionComponent}
        {descriptionComponent !== null && <Spacer size={16} />}
        <Label bold>コメント{!required && <span>(任意)</span>}</Label>
        <Spacer size={8} />
        <Input
          required={required}
          {...register('comment', { maxLength: MAX_COMMENT_LENGTH })}
        />
        {!!errors.comment && currentValue.length > MAX_COMMENT_LENGTH && (
          <div>
            <Spacer size={8} />
            <Message
              type="error"
              message={`${MAX_COMMENT_LENGTH}文字以内で入力してください`}
            />
          </div>
        )}
      </ModalContent>
      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          buttonType="cancel"
          onClick={onClose}
        />
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text={submitText}
          buttonType={submitButtonType}
          onClick={handleSubmit(onSubmit)}
          disabled={required && !currentValue}
        />
      </ModalActions>
    </Modal>
  )
}
