import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import {
  MediumItemAnswerStatus,
  SectionConfirmationStatus,
} from '../../../../../../../../../entities/medium-item-answer/entity'

type ConfirmRawResponse = {
  uid: string
  status: MediumItemAnswerStatus
  confirmation_status: SectionConfirmationStatus
}

type ConfirmResponse = {
  uid: string
  status: MediumItemAnswerStatus
  confirmationStatus: SectionConfirmationStatus
}

export const confirm = async ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemAnswerUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemAnswerUid: string
}): Promise<ConfirmResponse> => {
  if (IS_MOCK_MODE) {
    return {
      uid: mediumItemAnswerUid,
      status: MediumItemAnswerStatus.Confirmed,
      confirmationStatus: SectionConfirmationStatus.EdcIntegrated,
    }
  }

  const res = await requestPut<ConfirmRawResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/medium_item_answers/${mediumItemAnswerUid}/confirm`,
    params: {},
  })

  return {
    uid: res.data.uid,
    status: res.data.status,
    confirmationStatus: res.data.confirmation_status,
  }
}
