import { ItemType } from './entity'

export const itemTypeToDateFormat = (itemType: ItemType) => {
  switch (itemType) {
    case ItemType.Date:
      return 'YYYY/MM/DD'
    case ItemType.NullableDate:
      return 'YYYY/MM~/DD~'
    case ItemType.HourMinute:
      return 'HH:MI'
    default:
      return ''
  }
}

export const itemTypeToTextLabel = (itemType: ItemType) => {
  switch (itemType) {
    case ItemType.Text:
      return '単行テキスト'
    case ItemType.TextArea:
      return '複数行テキスト'
    default:
      return ''
  }
}
