import React from 'react'

import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { white } from 'src/components/base/color/palette'
import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
} from 'src/components/base/table/table'
import { ToggleButton } from 'src/components/form-redesigned/toggle-button'
import { ApiResponse } from 'src/lib/api-client'

import { IcfDocumentType, icfDocumentTypeText } from '../entity'

type Props = {
  isRequiredReAgreementConfirmDocuments: ApiResponse<'ListICFDocumentsItem'>[]
  selectedDocUids: string[]
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
  onConfirm: () => void
}

type TableKey =
  | 'docNumberingId'
  | 'docName'
  | 'version'
  | 'docType'
  | 'isRequiredReAgreementConfirm'

const tableCellWidth: Record<TableKey, `${number}px` | undefined> = {
  docNumberingId: '80px',
  docName: undefined,
  version: '80px',
  docType: '120px',
  isRequiredReAgreementConfirm: '110px',
}

export const IcfDocumentReAgreementConfirm: React.FC<Props> = ({
  isRequiredReAgreementConfirmDocuments,
  selectedDocUids,
  onToggle,
  onClose,
  onConfirm,
}) => {
  return (
    <Stack h="full" spacing="24px">
      <Text fontWeight="bold">再同意確認</Text>
      <Text>
        ※再同意が必要な文書を含む同意済状態の説明にはアラートが表示され、再同意を実施する必要があります。
        <br />
        再同意不当の場合、対象文書を含む説明に再同意は不要となります。
      </Text>

      <Box h="full">
        <Box h="445px" borderRadius="10px">
          <Table backgroundColor={white}>
            <TableHead>
              <TableRow>
                <TableHeadCell width={tableCellWidth['docNumberingId']}>
                  文書ID
                </TableHeadCell>
                <TableHeadCell width={tableCellWidth['docName']}>
                  文書名
                </TableHeadCell>
                <TableHeadCell width={tableCellWidth['version']}>
                  版数
                </TableHeadCell>
                <TableHeadCell width={tableCellWidth['docType']}>
                  文書の種類
                </TableHeadCell>
                <TableHeadCell
                  width={tableCellWidth['isRequiredReAgreementConfirm']}
                >
                  再同意
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isRequiredReAgreementConfirmDocuments.map(doc => {
                return (
                  <TableRow key={doc.uid} borderBottom>
                    <TableBodyCell width={tableCellWidth['docNumberingId']}>
                      {doc.numberingId}
                    </TableBodyCell>
                    <TableBodyCell width={tableCellWidth['docName']}>
                      {doc.name}
                    </TableBodyCell>
                    <TableBodyCell width={tableCellWidth['version']}>
                      {doc.version}
                    </TableBodyCell>
                    <TableBodyCell width={tableCellWidth['docType']}>
                      {icfDocumentTypeText[doc.type as IcfDocumentType]}
                    </TableBodyCell>
                    <TableBodyCell
                      width={tableCellWidth['isRequiredReAgreementConfirm']}
                    >
                      <ToggleButton
                        text={'必要'}
                        offText={'不要'}
                        value={doc.uid}
                        onChange={onToggle}
                        checked={selectedDocUids.includes(doc.uid)}
                      />
                    </TableBodyCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>

      <HStack w="full" spacing="24px" align="center" justify="center">
        <Button
          text="キャンセル"
          size="S"
          buttonType="cancel"
          onClick={onClose}
        />
        <Button
          text="確認"
          size="S"
          buttonType="important"
          onClick={onConfirm}
        />
      </HStack>
    </Stack>
  )
}
