/** http(s)から始まるURLのpath部分のみを抽出 */
export const omitDomain = (fullUrl: string) => {
  try {
    const urlObj = new URL(fullUrl)
    return urlObj.href.replace(urlObj.origin, '')
  } catch {
    // URLのパースに失敗した場合はそのまま返す
    return fullUrl
  }
}
