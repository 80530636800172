import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  CreateEnvelopeViewUrlDocument,
  ExplanationDocRevisionCreateEnvelopeViewUrlCreateType,
  ExplanationSignerRole,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

/** @deprecated use useCreateEnvelopeViewUrlFromSignAuth */
const createEnvelopeViewUrlWithSignerRole = async ({
  explanationDocRevisionUid,
  explanationSessionUid,
  returnUrl,
  signerRole,
}: {
  explanationDocRevisionUid: string
  explanationSessionUid: string
  returnUrl: string
  signerRole: ExplanationSignerRole
}): Promise<{ url?: string }> => {
  try {
    const res = await graphqlRequest(CreateEnvelopeViewUrlDocument, {
      input: {
        explanationDocRevisionUid,
        explanationSessionUid,
        returnUrl,
        signerRole,
        createType: 'FromExplanationRoom',
      },
    })

    return {
      url: res.createEnvelopeViewUrl.viewUrl ?? undefined,
    }
  } catch (error) {
    throw error
  }
}

/** @deprecated use useCreateEnvelopeViewUrlFromSignAuth */
export const useCreateEnvelopeViewUrlWithSignerRole = (
  option?: UseMutationOption<{ url?: string }, GqlError>,
) => {
  return useMutation(
    ({
      explanationDocRevisionUid,
      explanationSessionUid,
      returnUrl,
      signerRole,
    }: {
      explanationDocRevisionUid: string
      explanationSessionUid: string
      returnUrl: string
      signerRole: ExplanationSignerRole
    }) =>
      createEnvelopeViewUrlWithSignerRole({
        explanationDocRevisionUid,
        explanationSessionUid,
        returnUrl,
        signerRole,
      }),
    option,
  )
}

const createEnvelopeViewUrl = async ({
  explanationDocRevisionUid,
  explanationSessionUid,
  returnUrl,
  signerActorUid,
  createType,
}: {
  explanationDocRevisionUid: string
  explanationSessionUid: string
  returnUrl: string
  signerActorUid: string
  createType: ExplanationDocRevisionCreateEnvelopeViewUrlCreateType
}): Promise<{ url?: string; alreadyFinished: boolean }> => {
  try {
    const res = await graphqlRequest(CreateEnvelopeViewUrlDocument, {
      input: {
        explanationDocRevisionUid,
        explanationSessionUid,
        returnUrl,
        signerActorUid,
        createType: createType,
      },
    })

    return {
      url: res.createEnvelopeViewUrl.viewUrl ?? undefined,
      alreadyFinished: res.createEnvelopeViewUrl.alreadyFinished,
    }
  } catch (error) {
    throw error
  }
}

/** @deprecated use useCreateEnvelopeViewUrlFromSignAuth */
export const useCreateEnvelopeViewUrl = (
  option?: UseMutationOption<
    { url?: string; alreadyFinished: boolean },
    GqlError
  >,
) => {
  return useMutation(
    ({
      explanationDocRevisionUid,
      explanationSessionUid,
      returnUrl,
      signerActorUid,
      createType = 'FromExplanationRoom',
    }: {
      explanationDocRevisionUid: string
      explanationSessionUid: string
      returnUrl: string
      signerActorUid: string
      createType?: ExplanationDocRevisionCreateEnvelopeViewUrlCreateType
    }) =>
      createEnvelopeViewUrl({
        explanationDocRevisionUid,
        explanationSessionUid,
        returnUrl,
        signerActorUid,
        createType,
      }),
    option,
  )
}
