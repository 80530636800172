import { useCallback } from 'react'

import {
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { usePatient } from 'src/features/patient/api'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { UID } from '../../../../utils/brandedUid'
import { useWorksheet } from '../../api'
import { useSticky } from '../../api/getSticky'
import { WorksheetProvider } from '../../context'
import { useStickyQuery } from '../../hooks/useStickyQuery'
import { Worksheet } from '../../types'
import { isSection } from '../../utils/findSection'
import { generateFieldComponentId } from '../../utils/generateFieldComponentId'
import { ConfirmWorksheet } from '../ConfirmWorksheet/ConfirmWorksheet'
import { ReEditWorksheet } from '../ReEditWorksheet/ReEditWorksheet'
import { SaveWorksheet } from '../SaveWorksheet/SaveWorksheet'
import { StickyAutoScroll } from '../StickyAutoScroll/StickyAutoScroll'
import { StickyBadge } from '../StickyBadge/StickyBadge'
import { UpdateWorksheetName } from '../UpdateWorksheetName/UpdateWorksheetName'
import { WorksheetFieldComponent } from '../WorksheetField/WorksheetField'
import { WorksheetMenu } from '../WorksheetMenu/WorksheetMenu'
import { WorksheetStatusBadge } from '../WorksheetStatusBadge/WorksheetStatusBadge'
import { WorksheetTransitionBlocker } from '../WorksheetTransitionBlocker/WorksheetTransitionBlocker'

type Param = {
  patientUid: string
  worksheetUid: Worksheet['uid']
}

export const WorksheetDetailScreen: React.FC = () => {
  const { patientUid = '', worksheetUid = '' as UID<'Worksheet'> } =
    useParams<Param>()

  const { data: patient, mutate: mutatePatient } = usePatient({
    patientUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })
  const { data: worksheet, mutate: mutateWorksheet } = useWorksheet({
    worksheetUid,
  })
  const { currentMember } = useCurrentMember()

  const { selectedTrial } = useSelectedTrial()

  const { stickyQuery, resetQuery } = useStickyQuery()

  const toast = useMirohaToast()

  // クエリに付箋のuidが指定されている場合に付箋を取得
  // 削除されている場合などにtoastでエラーを表示する
  useSticky({
    stickyUid: stickyQuery ?? null,
    shouldRetryOnError: false,
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
      resetQuery()
    },
  })

  const mutate = useCallback(() => {
    mutateWorksheet()
    mutatePatient()
  }, [mutateWorksheet, mutatePatient])

  if (!patient || !worksheet) return null

  const observation = [
    ...patient.observationVisits,
    ...patient.observationFollowUps,
  ].find(o => o.patientObservationUid === worksheet.patientObservationUid)

  const isPatientDisabled = patient.status === 'Disable'

  return (
    // IMPORTANT NOTE: WorksheetProviderのkeyを消さないこと
    // keyがない場合、ワークシートを切り替えるとstateが正しくリセットされない問題が発生しうる。
    // cf) https://beta-reactjs-org-git-you-might-not-fbopensource.vercel.app/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
    <WorksheetProvider
      key={worksheet.uid}
      fetchedWorksheet={worksheet}
      mutateWorksheet={mutateWorksheet}
      member={currentMember}
      patient={patient}
    >
      <StickyAutoScroll />
      <WorksheetTransitionBlocker>
        <Flex direction="column" px="4" py="5">
          <Flex
            justify="space-between"
            align="center"
            w="full"
            as="header"
            aria-label="worksheet header"
            wrap="wrap"
            rowGap={4}
          >
            <Flex gap={3} wrap="wrap" align="center" maxW="full">
              <Box maxW="full" overflowX="auto">
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  as="h1"
                  opacity={isPatientDisabled ? 0.3 : 1}
                  wordBreak="keep-all"
                >
                  <HStack as="span" spacing="3" align="center">
                    <Flex gap="1.5" align="center">
                      <span>{observation?.name}</span>
                    </Flex>
                    <Box as="span" color="gray.400">
                      /
                    </Box>
                    {observation?.__typename === 'PatientObservationVisit' && (
                      <span>{worksheet.name}</span>
                    )}
                    {observation?.__typename ===
                      'PatientObservationFollowUp' && (
                      <HStack spacing="3">
                        <span>{`#${worksheet.index} ${worksheet.name}`}</span>
                        <Text
                          as="span"
                          fontSize="sm"
                          fontWeight="bold"
                          color="gray.500"
                        >
                          {worksheet.schema.name}
                        </Text>
                        <UpdateWorksheetName
                          key={worksheet.name}
                          worksheet={worksheet}
                          isPatientDisabled={patient.status === 'Disable'}
                          mutateWorksheet={mutate}
                        />
                      </HStack>
                    )}
                  </HStack>
                </Text>
              </Box>
              <Box>
                <WorksheetStatusBadge
                  status={worksheet.status}
                  detailed
                  isDisabled={isPatientDisabled}
                />
              </Box>
              <StickyBadge worksheet={worksheet} />
            </Flex>
            <HStack spacing="4" ml="auto">
              {!isPatientDisabled && (
                <>
                  <SaveWorksheet
                    isFollowUp={
                      observation?.__typename === 'PatientObservationFollowUp'
                    }
                    worksheet={worksheet}
                    mutateWorksheet={mutate}
                  />
                  <ConfirmWorksheet
                    worksheet={worksheet}
                    mutateWorksheet={mutate}
                  />
                  <ReEditWorksheet
                    worksheet={worksheet}
                    mutateWorksheet={mutate}
                  />
                </>
              )}
              <WorksheetMenu patientUid={patient.uid} worksheet={worksheet} />
            </HStack>
          </Flex>

          <Wrap
            mt="8"
            bg="blue.50"
            px="4"
            py="2"
            spacing="6"
            as="nav"
            aria-label="section navigation"
          >
            {worksheet.schema.fields.filter(isSection).map(section => (
              <WrapItem key={section.fid}>
                <HStack spacing="1">
                  <Box h="20px" w="4px" borderRadius="full" bg="blue.300" />
                  <Link
                    as={HashLink}
                    smooth
                    to={`#${generateFieldComponentId(section.fid)}`}
                    color="blue.500"
                  >
                    {section.name}
                  </Link>
                </HStack>
              </WrapItem>
            ))}
          </Wrap>

          <Stack spacing="10" flex="1" px="4" mt="6" w="full">
            {worksheet.schema.fields.map(field => (
              <WorksheetFieldComponent key={field.fid} field={field} />
            ))}
          </Stack>
        </Flex>
      </WorksheetTransitionBlocker>
      {selectedTrial.featureChannel === 'Canary' && (
        <div
          id="currentWorksheetSchema"
          style={{
            display: 'none',
          }}
        >
          {JSON.stringify(worksheet.schema)}
        </div>
      )}
    </WorksheetProvider>
  )
}
