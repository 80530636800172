import React from 'react'

import dayjs from 'dayjs'
import { colors } from 'src/assets/colors'
import { MediumItemAnswerLog } from 'src/modules/dashboard/trial/detail/patient/detail/worksheet/common/medium-item-answer-log/entity'
import {
  MediumItemAnswer,
  MediumItemAnswerStatus,
} from 'src/modules/entities/medium-item-answer/entity'
import styled, { css } from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'

import { SmallItemAnswerComponent } from './small-item-answer'

type Props = {
  loglineTitle: string
  loglines: MediumItemAnswer[]
  disableLogs: MediumItemAnswerLog[]
}

export const LoglinesComponent: React.FC<Props> = props => {
  const { loglineTitle, loglines, disableLogs } = props

  return (
    <Container>
      <Title>{loglineTitle}</Title>

      {loglines.map(logline => (
        <MediumItemAnswerComponent
          key={logline.uid}
          logline={logline}
          disableLogs={disableLogs}
        />
      ))}
    </Container>
  )
}

const MediumItemAnswerComponent: React.FC<{
  logline: MediumItemAnswer
  disableLogs: MediumItemAnswerLog[]
}> = props => {
  const { logline } = props

  const smallItemAnswers = Object.values(logline.smallItemAnswerMap)
    .filter(sia => sia.parentUid === null)
    .sort((a, b) => a.index - b.index)

  return (
    <MediumItemAnswerContainer>
      <Head top={logline.lineNumber === 1}>
        <LineNumber>{`line${logline.lineNumber}`}</LineNumber>
        {logline.status === MediumItemAnswerStatus.Disabled && (
          <DisableLog {...props} />
        )}
      </Head>

      <Flex column>
        {smallItemAnswers.map((smallItemAnswer, i) => (
          <FlexItem key={smallItemAnswer.uid}>
            <Border last={smallItemAnswers.length === i + 1}>
              <SmallItemAnswerComponent
                smallItemAnswer={smallItemAnswer}
                smallItemAnswerMap={logline.smallItemAnswerMap}
              />
            </Border>
          </FlexItem>
        ))}
      </Flex>
    </MediumItemAnswerContainer>
  )
}

const DisableLog: React.FC<{
  logline: MediumItemAnswer
  disableLogs: MediumItemAnswerLog[]
}> = ({ logline, disableLogs }) => {
  const disableLog = disableLogs.find(
    log => log.mediumItemAnswerUid === logline.uid,
  )
  return (
    <LogContainer>
      <LogItem>【無効】</LogItem>
      {disableLog && (
        <>
          <LogItem>
            {dayjs(disableLog.createdAt).format('YYYY/MM/DD HH:mm:ss')}
          </LogItem>
          <LogItem>{disableLog.userName}</LogItem>
          <LogItem>{disableLog.reason}</LogItem>
        </>
      )}
    </LogContainer>
  )
}

const Container = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  padding: 6px 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  background: ${colors.bgGray};
  border-top: 0.5px solid #000000;
  border-bottom: 0.5px dashed #000000;
  word-break: break-word;
`

const MediumItemAnswerContainer = styled.div`
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
`

const Head = styled.div<{ top: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 10px;
  box-sizing: border-box;
  font-size: 10px;
  background: ${colors.hoverGray};
  border-top: ${({ top }) => (top ? '' : '0.5px solid #000000')};
  border-bottom: 0.5px dashed #000000;
`

const LineNumber = styled.div`
  flex: 1;
  font-weight: 600;
`

const LogContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
`

const LogItem = styled.div`
  padding: 0 5px;
`

const Border = styled.div<{ last: boolean }>`
  border-bottom: 0.5px dashed #000000;

  ${props =>
    props.last &&
    css`
      border: none;
    `}
`
