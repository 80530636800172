import { Dictionary } from 'lodash'

import { SmallItemAnswer } from '../small-item-answer/entity'

export enum MediumItemAnswerStatus {
  New = 0,
  Saved,
  Confirmed,
  Disabled,
}

export enum SectionConfirmationStatus {
  Pending = 0,
  EdcIntegrated,
  EdcIntegrationFailed,
}

export type MediumItemAnswer = {
  uid: string
  title: string
  folderName: string
  formName: string
  index: number
  status: MediumItemAnswerStatus
  hasLog: boolean
  isLogline: boolean
  lineNumber: number

  smallItemAnswerMap: Dictionary<SmallItemAnswer>

  worksheetUid: string
  mediumItemUid: string
  confirmationStatus: SectionConfirmationStatus | null // 一度も確定したことがなければ null
}
