import {
  Box,
  Button,
  HStack,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Add, Delete } from 'src/components/__legacy__icon/monochrome'
import { Select } from 'src/components/Select/Select'
import { Member } from 'src/features/member/types'
import { TrialHospital } from 'src/features/trial/types'
import { castUid } from 'src/utils/brandedUid'
import { DeeplyPartial } from 'src/utils/deeplyPartial'

import { ExplanationFormErrors } from '../../hooks/useExplanationForm'
import { MemberSchema } from '../../schema/explanationFormSchema'
import { ExplanationType } from '../../types'
import { MemberSelect } from '../MemberSelect/MemberSelect'

type Props = {
  currentType: ExplanationType | undefined
  mainTrialHospital: TrialHospital
  partnerTrialHospital?: TrialHospital
  isPartner: boolean
  members: DeeplyPartial<MemberSchema>[]
  appendMember: () => void
  removeMember: (index: number) => void
  onChangeMember: ({
    index,
    member,
  }: {
    index: number
    member: DeeplyPartial<MemberSchema>
  }) => void
  errors: ExplanationFormErrors
}

export const MemberSelectForm: React.FC<Props> = ({
  currentType,
  mainTrialHospital,
  partnerTrialHospital,
  isPartner,
  members,
  appendMember,
  removeMember,
  onChangeMember,
  errors,
}) => {
  const trialHospitals = [
    mainTrialHospital,
    ...(!!partnerTrialHospital ? [partnerTrialHospital] : []),
  ]

  const MAX_MEMBER_COUNT = currentType === 'RemotePartner' ? 10 : 2

  return (
    <Stack spacing="2">
      <TableContainer pt="6" h="auto" overflowX="unset" overflowY="unset">
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>
                <Text as="span" display="block" w="320px">
                  医療機関
                </Text>
              </Th>
              <Th w="full">担当者</Th>
            </Tr>
          </Thead>
          <Tbody>
            {members.map((member, index) => (
              <Tr key={`${member.id}-${index}`} alignItems="start">
                <Td>{`${index + 1}`}</Td>
                <Td verticalAlign="top">
                  <Select
                    width={320}
                    value={member?.trialHospitalUid}
                    items={trialHospitals.map(h => ({
                      value: h.uid,
                      label: h.name,
                    }))}
                    onChange={value => {
                      onChangeMember({
                        index,
                        member: { trialHospitalUid: value, member: undefined },
                      })
                    }}
                  />
                </Td>
                <Td verticalAlign="top">
                  <HStack spacing="4" alignItems="center">
                    <Stack>
                      <MemberSelect
                        trialHospitalUid={
                          !!member?.trialHospitalUid
                            ? castUid<TrialHospital>(member?.trialHospitalUid)
                            : undefined
                        }
                        memberUid={
                          !!member.member?.uid
                            ? castUid<Member>(member.member.uid)
                            : undefined
                        }
                        onChange={value => {
                          const trialHospitalUid = member.trialHospitalUid
                          onChangeMember({
                            index,
                            member: { trialHospitalUid, member: value },
                          })
                        }}
                        isInvalid={
                          errors?.members?.hasOwnProperty(index) ?? false
                        }
                        isPartner={
                          member.trialHospitalUid === partnerTrialHospital?.uid
                        }
                      />
                      {errors?.members?.hasOwnProperty(index) &&
                        errors.members[index].message !== undefined && (
                          <Text color="red.500" fontSize="xs" mt="1.5" mb="0">
                            {errors.members[index].message}
                          </Text>
                        )}
                    </Stack>
                    <IconButton
                      aria-label="担当者を削除"
                      colorScheme="gray"
                      minW="unset"
                      w="32px"
                      h="32px"
                      borderRadius="base"
                      variant="ghost"
                      color="blue.500"
                      icon={<Delete />}
                      onClick={() => {
                        removeMember(index)
                      }}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {errors?.members?.message !== undefined && (
        <Text fontSize="sm" color="red.500" mt="2" as="div">
          {errors.members.message}
        </Text>
      )}
      <Box>
        <Button
          colorScheme="blue"
          variant="outline"
          leftIcon={<Add />}
          isDisabled={!currentType || members.length >= MAX_MEMBER_COUNT}
          onClick={appendMember}
        >
          担当者を追加
        </Button>
      </Box>
    </Stack>
  )
}
