import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { RootState } from '../../../reducer'
import { routes } from '../../../routes'
import { useRequestState } from '../../../server/use-request-state'
import { AgreementParams, toStrictly } from '../entity'
import { actions } from '../redux'
import { create, fetch } from '../request'

export const useAgreement = () => {
  const dispatch = useDispatch()

  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()

  const { agreements } = useSelector((state: RootState) => state.auth.agreement)

  const navigate = useNavigate()
  const location = useLocation()

  const fetchWithCache = useCallback(
    async ({
      forceFetch,
    }: {
      forceFetch: boolean
    }): Promise<AgreementParams> => {
      if (forceFetch || agreements === null) {
        const fetched = await fetch()
        dispatch(actions.setAgreementState({ agreements }))
        return {
          agreeTermsOfService: fetched.agreeTermsOfService,
          agreePrivacyPolicy: fetched.agreePrivacyPolicy,
          agreeExternalTransmissionPolicy:
            fetched.agreeExternalTransmissionPolicy,
        }
      } else {
        return {
          agreeTermsOfService: agreements.agreeTermsOfService,
          agreePrivacyPolicy: agreements.agreePrivacyPolicy,
          agreeExternalTransmissionPolicy:
            agreements.agreeExternalTransmissionPolicy,
        }
      }
    },
    [agreements, dispatch],
  )

  const jumpAfterLogin = async () => {
    const fetched: AgreementParams = await fetchWithCache({ forceFetch: true })

    dispatch(
      actions.setAgreementState({
        agreements: {
          agreeTermsOfService: fetched.agreeTermsOfService,
          agreePrivacyPolicy: fetched.agreePrivacyPolicy,
          agreeExternalTransmissionPolicy:
            fetched.agreeExternalTransmissionPolicy,
        },
      }),
    )
    if (
      fetched.agreePrivacyPolicy &&
      fetched.agreeTermsOfService &&
      fetched.agreeExternalTransmissionPolicy
    ) {
      if (!!location.state?.from) {
        window.location.href = location.state.from
        return
      }
      // reloadを伴う遷移にして強制的にstateをリセットする
      window.location.href = routes.dashboard
    } else {
      navigate(routes.agreement)
    }
  }

  const submit: (
    params: Partial<AgreementParams>,
  ) => Promise<void> = async params => {
    try {
      requestStarted()
      await create(toStrictly(params))
      navigate(routes.dashboard)
      dispatch(actions.setAgreementPartially(params))
    } catch (_error) {
      requestFailed('リクエストに失敗しました。')
    } finally {
      requestDone()
    }
  }

  return {
    jumpAfterLogin,
    fetchWithCache,
    submit,
    requesting,
    errorMessage,
  }
}
