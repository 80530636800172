import React, { useCallback, useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { useExplanation } from 'src/features/explanation/api'
import { ExplanationSession } from 'src/features/explanation/types'
import { ExplanationRoomEventProvider } from 'src/features/explanationRoom/context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from 'src/features/explanationRoom/hooks/useFloatingVideoCallForMember'

import { ExplanationRoomTopRenewal } from './top-renewal'
import { UID } from '../../../../../../../utils/brandedUid'
import { SidebarContentRenewal } from '../components/sidebar-renewal'
import { useHospitalSession } from '../hospital-session.hooks'
import { ExplanationRoomLayoutRenewal } from '../layout-renewal'

type Param = {
  sessionUid: ExplanationSession['uid']
}

export const HospitalTopContainer: React.FC = () => {
  const navigate = useNavigate()

  const { sessionUid = '' as UID<'ExplanationSession'> } = useParams<Param>()

  const { session, mutateSession } = useHospitalSession({
    explanationSessionUid: sessionUid,
  })

  const { selectedTrial } = useSelectedTrial()

  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()

  const { data: explanation } = useExplanation({
    explanationUid: session?.explanationRevision.explanationUid ?? null,
    detailed: false,
  })

  const forwardHistory = useCallback(() => {
    navigate(1)
  }, [navigate])

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
    isOldFlow: true,
  })

  // 説明ルームから説明同意詳細画面へはブラウザバックできないようにする
  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', forwardHistory)

    return () => {
      window.removeEventListener('popstate', forwardHistory)
    }
  }, [forwardHistory])

  if (!session || !explanation || !selectedTrialHospitalUid) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={session.explanationRevision.uid}
      sessionUid={sessionUid}
      explanationPatientUid={
        session.explanationRevision.explanation.patient.uid
      }
      patientPhoneNumber={undefined} // 旧署名フローでは不要
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <ExplanationRoomLayoutRenewal
        headerTitle="説明ルーム"
        session={session}
        sidebarContent={
          <SidebarContentRenewal
            trialName={selectedTrial.name}
            candidateId={explanation.patient.candidateId}
            explanationUid={explanation.uid}
            trialHospital={explanation.trialHospital}
            partnerTrialHospital={
              session.explanationRevision.partnerTrialHospital
            }
            session={session}
          />
        }
      >
        <ExplanationRoomTopRenewal
          userAttributeType="hospital"
          hospitalName={explanation.trialHospital.name}
          explanationType={session.explanationType}
          docSets={session.explanationRevision.docSets}
          isAgreementDone={
            session.explanationRevision.status === 'AgreementDone'
          }
          isDelivered={!!session.explanationRevision.deliveredAt}
          trialUid={selectedTrial.uid}
          session={session}
          explanationRevisionUid={session.explanationRevision.uid}
          mutateSession={mutateSession}
        />
      </ExplanationRoomLayoutRenewal>
      {renderFloatingVideoCall()}
    </ExplanationRoomEventProvider>
  )
}
