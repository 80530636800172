import { SERVER_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'

// cookie がセットされる
type LoginResponse = {}

export const login = async (idToken: string, firstLogin: boolean) => {
  return await requestPost<LoginResponse>({
    url: `${SERVER_URL}/accounts/signin`,
    params: { firstLogin: firstLogin },
    idToken,
  })
}
