import React from 'react'

export const useCloseModalByDeps = (options: {
  deps: (string | null)[]
  condition: boolean
  onClose: () => void
}) => {
  React.useEffect(() => {
    if (options.condition) {
      options.onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, options.deps)
}
