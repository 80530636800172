import React from 'react'

import { routes as dashboardRoutes } from 'src/modules/dashboard/routes'
import { routes } from 'src/modules/routes'

import { Information } from '../../../components/information'

export const COMPLETE_DOCUSIGN_EDIT_URL = `${import.meta.env.VITE_WEB_DOMAIN}${
  routes.dashboard
}/${dashboardRoutes.completeDocuSign}`

export const CompleteDocuSignEdit: React.FC = () => {
  return (
    <Information body="DocuSignの編集が完了しました。タブを閉じてください。" />
  )
}
