import { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    boxSizing: 'border-box',

    _hover: {
      '@media(hover: hover)': {
        _disabled: {
          opacity: 0.3,
        },
      },
    },

    _disabled: {
      opacity: 0.3,
    },
  },
  sizes: {
    md: {
      h: '36px',
      minW: '96px',
      fontSize: 'sm',
      fontWeight: 'medium',
      py: 2,
      px: 5,
    },
    lg: {
      h: '64px',
      fontSize: 'md',
      fontWeight: 'bold',
    },
    sp: {
      h: '52px',
      maxW: '380px',
      w: 'full',
      fontSize: 'md',
      fontWeight: 'medium',
      px: '5',
    },
  },
  variants: {
    solid: ({ colorScheme: c }) => ({
      bg: `${c}.500`,
      color: 'white',

      _hover: {
        '@media(hover: hover)': {
          bg: `${c}.600`,

          _disabled: {
            bg: `${c}.500`,
          },
        },
      },

      _disabled: {
        bg: `${c}.500`,
      },

      _active: { bg: `${c}.700` },
    }),
    outline: ({ colorScheme: c }) => ({
      bg: 'white',
      color: `${c}.500`,
      border: '2px solid',
      borderColor: `${c}.500`,

      _hover: {
        '@media(hover: hover)': {
          color: `${c}.600`,
          borderColor: `${c}.600`,

          _disabled: {
            color: `${c}.500`,
            borderColor: `${c}.500`,
          },
        },
      },

      _disabled: {
        color: `${c}.500`,
        borderColor: `${c}.500`,
      },

      _active: {
        color: `${c}.700`,
        borderColor: `${c}.800`,
      },
    }),
    ghost: ({ colorScheme: c }) => ({
      color: `${c}.500`,

      _hover: {
        '@media(hover: hover)': {
          bg: `${c}.50`,
        },
      },

      _active: { bg: `${c}.100` },
    }),
    text: ({ colorScheme: c }) => ({
      minW: 'unset',
      h: '7', // 4 + 1.5 * 2
      color: `${c}.500`,
      borderRadius: 'sm',
      px: '2',
      py: '1.5',

      _hover: {
        '@media(hover: hover)': {
          color: `${c}.600`,
          bgColor: `${c}.50`,
        },
      },

      _active: { color: `${c}.700`, bgColor: `${c}.100` },
    }),

    // custom icon button
    customIconButtonSolid: ({ colorScheme: c }) => ({
      bg: `${c}.500`,
      color: 'white',
      borderRadius: 'full',
      minW: 'unset',
      w: '36px',
      _hover: {
        '@media(hover: hover)': {
          bg: `${c}.600`,
          _disabled: {
            bg: `${c}.500`,
          },
        },
      },
      _active: {
        bg: `${c}.700`,
      },
    }),
    customIconButtonGhost: ({ colorScheme: c }) => ({
      color: `${c}.500`,
      borderRadius: 'base',
      minW: 'unset',
      w: '36px',
      _hover: {
        '@media(hover: hover)': {
          bg: 'gray.50',
          _disabled: {
            bg: 'unset',
          },
        },
      },
      _active: {
        bg: 'gray.100',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'blue',
  },
}
