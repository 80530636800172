import { ComponentStyleConfig } from '@chakra-ui/react'

export const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tab: {
      minW: '96px',
      color: 'gray.500',
      fontWeight: 'medium',
      _focus: {
        boxShadow: 'none',
      },
      // replace with _focusVisible when upgrading to Chakra v2
      ':focus-visible': {
        boxShadow: 'outline',
      },
      _active: {
        bg: 'white',
      },
      _selected: {
        color: 'gray.800',
      },
    },
  },
  // 以下で定義されているstyleはvariantの内容として定義されているのでbaseStyleで定義すると上書きされてしまう
  variants: {
    line: {
      tab: {
        marginBottom: 0,
        _selected: {
          color: 'gray.800',
          borderColor: 'blue.500',
        },
        _active: {
          bg: 'unset',
        },
      },
      tablist: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
    },
  },
  sizes: {
    md: {
      tab: {
        py: '1',
        px: '4',
      },
    },
  },
}
