import { useState } from 'react'

import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import { PinInput } from 'src/components/PinInput/PinInput'
import {
  StaticScreen,
  StaticScreenContent,
  StaticScreenFooter,
  StaticScreenTitle,
} from 'src/components/StaticScreen/StaticScreen'
import { Trial } from 'src/features/trial/types'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useResendAuthenticationCode } from '../../api/resendAuthentiationCode'
import { useVerifyAuthenticationCode } from '../../api/verifyAuthenticationCode'
import { PATIENT_OTP_TOKEN_LENGTH } from '../../constants/oPTSetting'
import { SignAuthResult } from '../../types'
import { SendAuthCodeByVoice } from '../SendAuthCodeByVoice/SendAuthCodeByVoice'

type Props = {
  trialUid: Trial['uid']
  explanationSignAuthUid: string
  message: string
  mutateSignAuthResult: (res: SignAuthResult) => void
}

export const SignAuthForm: React.FC<Props> = ({
  trialUid,
  explanationSignAuthUid,
  message,
  mutateSignAuthResult,
}) => {
  const [code, setCode] = useState<string>()

  const { request: verify, requesting } = useVerifyAuthenticationCode({
    onSuccess: res => {
      mutateSignAuthResult(res)
    },
  })

  const toast = useMirohaToast()

  const { request: resend } = useResendAuthenticationCode({
    onSuccess: () => {
      toast({
        title: '携帯電話番号宛に認証コードを再送しました',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const onSubmit = async () => {
    if (code?.length !== PATIENT_OTP_TOKEN_LENGTH) {
      return
    }

    await verify({
      trialUid,
      explanationSignAuthUid,
      authenticationCode: code,
    })
  }

  return (
    <StaticScreen colorScheme="green">
      <StaticScreenTitle>認証コードの入力</StaticScreenTitle>

      <StaticScreenContent>
        <Stack spacing="4">
          <Text>SMSまたは音声通知で届いた認証コードを入力してください</Text>
          <Text fontSize="xs" color="gray.400" textAlign="center">
            SMSは登録された携帯電話番号に送信されました
          </Text>
          <HStack justify="center">
            <PinInput
              length={PATIENT_OTP_TOKEN_LENGTH}
              value={code}
              onChange={setCode}
            />
          </HStack>
          {message &&
            message.split('\\n').map(m => (
              <Text key={m} color="red.500">
                {m}
              </Text>
            ))}
        </Stack>
      </StaticScreenContent>

      <StaticScreenFooter>
        <Stack textAlign="center" spacing="4">
          <Button
            colorScheme="green"
            onClick={onSubmit}
            isDisabled={code?.length !== PATIENT_OTP_TOKEN_LENGTH || requesting}
          >
            認証
          </Button>
          <Button
            variant="text"
            onClick={async () => {
              await resend({ trialUid, explanationSignAuthUid })
            }}
          >
            認証コードを再送
          </Button>
          <SendAuthCodeByVoice
            trialUid={trialUid}
            explanationSignAuthUid={explanationSignAuthUid}
          />
        </Stack>
      </StaticScreenFooter>
    </StaticScreen>
  )
}
