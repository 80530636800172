import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { createApiClient } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { WSDataExportJob } from '../../types'
import { wsDataExportCacheKey } from '../cacheKey'
import { parseWSExportJobResponse } from '../parser'

export const getWSDataExportJobs = async ({
  trialUid,
}: {
  trialUid: Trial['uid']
}): Promise<WSDataExportJob[]> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/ws_data_export',
    httpMethod: 'get',
    params: {
      paths: {
        trial_uid: trialUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data.map(parseWSExportJobResponse)
}

export const useWSDataExportJobs = (
  options?: SWRConfiguration<WSDataExportJob[], Error>,
) => {
  const { selectedTrial } = useSelectedTrial()

  return useSWR<WSDataExportJob[], Error>(
    wsDataExportCacheKey.getWsDataExportJobs(selectedTrial.uid),
    () => getWSDataExportJobs({ trialUid: selectedTrial.uid }),
    options,
  )
}
