import { TemplateResponse } from 'src/modules/entities/template/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const requestRelease = async ({
  uid,
  trialUid,
}: {
  uid: string
  trialUid: string
}) => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPut<TemplateResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${uid}/release`,
    params: {},
  })

  return {}
}
