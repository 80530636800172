import { useCallback } from 'react'

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { PageNext } from 'src/components/icon'

type BreadcrumbParam =
  | { isCurrentPage?: undefined; link: string; text: string }
  | { isCurrentPage: true; text: string }

export const useBreadcrumb = () => {
  const renderBreadcrumb = useCallback((params: BreadcrumbParam[]) => {
    return (
      <Breadcrumb display="flex" alignItems="center" separator={<PageNext />}>
        {params.map(param => (
          <BreadcrumbItem key={param.text} isCurrentPage={param.isCurrentPage}>
            {param.isCurrentPage ? (
              <BreadcrumbLink
                fontSize="lg"
                fontWeight="bold"
                isCurrentPage={param.isCurrentPage}
                _active={{ outline: 'none' }}
              >
                {param.text}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbLink
                as={Link}
                to={param.link}
                color="blue.500"
                fontSize="lg"
                fontWeight="bold"
              >
                {param.text}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    )
  }, [])

  return {
    renderBreadcrumb,
  }
}
