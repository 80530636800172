import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'

import { ExplanationRevision, UpdateExplanationInput } from '../../types'
import { updateExplanationRevision } from '../updateExplanationRevision'

const updateExplanation = async ({
  trialUid,
  explanationRevisionUid,
  input,
}: {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
  input: UpdateExplanationInput
}) => {
  try {
    const requests = [
      updateExplanationRevision({
        trialUid,
        explanationRevisionUid,
        input: input,
      }),
    ]
    await Promise.all(requests)
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
    throw new Error('説明情報の更新に失敗しました')
  }
}

export const useUpdateExplanation = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()
  return useMutation(
    ({
      explanationRevisionUid,
      input,
    }: {
      explanationRevisionUid: ExplanationRevision['uid']
      input: UpdateExplanationInput
    }) =>
      updateExplanation({
        trialUid: selectedTrial.uid,
        explanationRevisionUid,
        input,
      }),
    option,
  )
}
