import { Fragment } from 'react'

import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { useCompleteAgreement } from 'src/features/explanation/api'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ExplanationRevisionStatusV2,
  DocRevisionInSignFlowFragment,
} from 'src/lib/gql-client'

import { hasSigningNotRequiredAgreements } from '../../utils/explanationDocRevisionUtils'
import { SignFlowBannerButton } from '../SignFlowBannerButton/SignFlowBannerButton'
import { SignFlowBannerStatic } from '../SignFlowBannerStatic/SignFlowBannerStatic'

type DocRevisionInSignFlow_ExplanationDocRevisionAgreementForm_Fragment =
  DocRevisionInSignFlowFragment & {
    __typename: 'ExplanationDocRevisionAgreementForm'
  }

type Props = {
  notCompletedDocRevisions: DocRevisionInSignFlowFragment[]
  explanationRevisionUid: string
  explanationRevisionStatus: ExplanationRevisionStatusV2
  hasRequiredAgreementForm: boolean
  isRejected: boolean
  mutateSession: () => void
}

export const CompleteAgreementBanner: React.FC<Props> = ({
  notCompletedDocRevisions,
  explanationRevisionUid,
  explanationRevisionStatus,
  hasRequiredAgreementForm,
  isRejected,
  mutateSession,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useMirohaToast()

  const { request: completeAgreement } = useCompleteAgreement({
    onSuccess: () => {
      toast({
        status: 'success',
        title: '同意完了しました',
      })
      mutateSession()
      onClose()
    },
  })

  const title = '同意完了'

  const agreementFormRevisions: DocRevisionInSignFlow_ExplanationDocRevisionAgreementForm_Fragment[] =
    notCompletedDocRevisions.filter(
      (
        docRev,
      ): docRev is DocRevisionInSignFlow_ExplanationDocRevisionAgreementForm_Fragment => {
        return docRev.__typename === 'ExplanationDocRevisionAgreementForm'
      },
    )

  const done =
    explanationRevisionStatus === 'AgreementCompleted' ||
    explanationRevisionStatus === 'DeliveryCompleted'
  const ready = hasRequiredAgreementForm
    ? // 必須同意書署名完了後に任意同意書の署名を開始した場合、ステータスはSignCompletedだが署名完了にできない
      explanationRevisionStatus === 'SignCompleted' &&
      !hasSigningNotRequiredAgreements(agreementFormRevisions)
    : // 任意同意書のみ説明の場合、署名中のものがあれば同意完了にできない
      !hasSigningNotRequiredAgreements(agreementFormRevisions)
  return (
    <Fragment>
      {done ? (
        <SignFlowBannerStatic title={title} />
      ) : (
        <SignFlowBannerButton
          isGrayedOut={isRejected}
          title={title}
          onClick={onOpen}
          isDisabled={!ready || isRejected}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            {notCompletedDocRevisions.length === 0
              ? 'すべての文書の説明・署名が完了しました'
              : '未完了の文書があります'}
          </ModalHeader>
          <ModalBody>
            {notCompletedDocRevisions.length === 0 ? (
              <Text>
                「同意完了する」ボタンより
                <br />
                本説明への同意を完了してください。
              </Text>
            ) : (
              <Stack spacing="8">
                <Text>
                  以下の文書の説明/署名が未完了です。このまま同意を完了しますか？
                </Text>
                <UnorderedList stylePosition="inside">
                  {notCompletedDocRevisions.map(docRev => (
                    <ListItem key={docRev.uid} wordBreak="break-word">
                      {docRev.icfDocumentRevision.name}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Stack>
            )}
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="green"
              onClick={async () => {
                await completeAgreement({
                  explanationRevisionUid,
                })
              }}
            >
              同意完了する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}
