import { Box, CSSObject } from '@chakra-ui/react'
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

export type MentionItem = {
  id: string
  display: string
}

const defaultMentionMarkup = '@[__display__](__id__)'

const mentionInputClassName = 'mentions'

type ElementKey =
  | 'control'
  | 'input'
  | 'highlighter'
  | 'highlighter__substring'
  | 'suggestions'
  | 'suggestions__list'
  | 'suggestions__item'
  | 'suggestions__item--focused'

const getClassName = (elementKey: ElementKey) => {
  return `.${mentionInputClassName}__${elementKey}`
}

type StyleOverride = {
  [key in ElementKey]?: CSSObject
}

const styles = (override?: StyleOverride): CSSObject => {
  return {
    [getClassName('control')]: {
      minH: '80px',
      fontSize: 'sm',
      ...override?.['control'],
    },
    [getClassName('input')]: {
      padding: '8px 16px',
      border: '1px solid silver',
      borderRadius: 'base',
      color: 'transparent',
      caretColor: colors.gray[800], // 'gray.800'の記法だと適用されない,
      ...override?.['input'],
    },
    [getClassName('highlighter')]: {
      padding: '8px 16px',
      overflow: 'hidden',
      // mention部分であるstrongタグのスタイルを指定
      strong: {
        color: override?.['highlighter']?.color ?? colors.blue[500],
        bg: 'gray.50',
        borderRadius: 'full',
        h: '5',
        fontWeight: 'bold',
        ...override?.['highlighter'],
      },
    },
    [getClassName('highlighter__substring')]: {
      visibility: 'visible !important',
      color: 'gray.800',
      ...override?.['highlighter__substring'],
    },
    [getClassName('suggestions')]: {
      px: '2',
      py: '2.5',
      w: '180px',
      borderRadius: 'base',
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)',
      ...override?.['suggestions'],
    },
    [getClassName('suggestions__list')]: {
      maxH: '320px',
      w: 'full',
      overflow: 'auto',
      px: '8px',
      py: '16px',
      bg: 'white',
      ...override?.['suggestions__list'],
    },
    [getClassName('suggestions__item')]: {
      px: '8px',
      py: '4px',
      fontSize: 'sm',
      borderRadius: 'base',
      ...override?.['suggestions__item'],
    },
    [getClassName('suggestions__item--focused')]: {
      bg: 'blue.50',
      ...override?.['suggestions__item--focused'],
    },
  }
}
type Props = {
  /** メンション表現を含む文字列を渡してください */
  value: string
  onChange: (value: string) => void
  mentionItems: MentionItem[]
  placeholder?: string
  /**
   *  メンション部分はは指定した形式に変換されて文字列に埋め込まれます
   *
   * valueとして与えた文字列にこのフォーマットが含まれている場合にメンション用のstyleが適用されます
   */
  mentionMarkup?: '@[__display__](__id__)'
  styles?: StyleOverride
  suggestionStyle?: (display: SuggestionDataItem) => React.ReactNode
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | null>
}

export const MentionTextarea: React.FC<Props> = ({
  value,
  onChange,
  mentionItems,
  placeholder,
  mentionMarkup = defaultMentionMarkup,
  styles: stylesOverride,
  suggestionStyle,
  inputRef,
}) => {
  return (
    <Box sx={styles(stylesOverride)}>
      <MentionsInput
        inputRef={inputRef}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        className={mentionInputClassName}
        placeholder={placeholder}
        allowSpaceInQuery
      >
        <Mention
          trigger="@"
          data={mentionItems}
          displayTransform={(_, display) => `@${display} `}
          markup={mentionMarkup}
          renderSuggestion={suggestionStyle}
          appendSpaceOnAdd
        />
      </MentionsInput>
    </Box>
  )
}
