import { ExplanationRevisionStatus } from '../types'

export const isEditableStatus = (
  status: ExplanationRevisionStatus,
): boolean => {
  return (
    status === 'SessionNotStarted' ||
    status === 'SessionNotDone' ||
    status === 'AgreementDone'
  )
}
