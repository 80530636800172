import React from 'react'

import { Select } from 'src/components/Select/Select'
import { useSelectedTrial } from 'src/features/auth/context'
import { ItemType } from 'src/modules/entities/small-item/entity'
import {
  itemTypeToDateFormat,
  itemTypeToTextLabel,
} from 'src/modules/entities/small-item/util'

type Item = {
  value: ItemType
  name: string
  desc?: string
}
type ComponentProps = {
  selectedValue: ItemType
  onSelect: (value: ItemType) => void
  items: Item[]
  width: number
}

const Component: React.FC<ComponentProps> = props => {
  const { selectedValue, onSelect, items, width } = props
  const selectedItem = items.find(item => item.value === selectedValue)

  const getText = (item: Item) => {
    return item.desc ? `${item.name}（${item.desc}）` : `${item.name}`
  }

  if (!selectedItem) {
    return null
  }

  return (
    <Select
      items={items.map(item => ({
        value: item.value,
        label: getText(item),
      }))}
      value={selectedValue}
      onChange={onSelect}
      width={width}
    />
  )
}

type Props = {
  selectedValue: ItemType
  onSelect: (value: ItemType) => void
}

const items: Item[] = [
  {
    value: ItemType.Radio,
    name: 'ラジオボタン',
    desc: '複数の選択肢の中から1つだけ選択できる',
  },
  {
    value: ItemType.CheckBox,
    name: 'チェックボックス',
    desc: '複数の選択肢の中からいくつも選択可能',
  },
  {
    value: ItemType.SelectMenu,
    name: 'セレクトメニュー',
    desc: '選択肢リストの中から1つだけ選択できる',
  },
  {
    value: ItemType.Date,
    name: '日時入力',
    desc: '日時形式の値が入力できる',
  },
  {
    value: ItemType.Number,
    name: '数値入力',
    desc: '数値の単位を設定して数字入力できる',
  },
  {
    value: ItemType.Text,
    name: 'テキスト',
    desc: '文字列を入力できる',
  },
  {
    value: ItemType.File,
    name: 'ファイルアップロード',
    desc: '画像・PDFファイルをアップロードできる',
  },
  {
    value: ItemType.Label,
    name: 'ラベル',
    desc: '表示用の固定文字を設定できる',
  },
]

export const ItemTypeSelect: React.FC<Props> = props => {
  const { selectedValue, onSelect } = props

  const { selectedTrial } = useSelectedTrial()

  return (
    <Component
      items={items.filter(
        item =>
          item.value !== ItemType.File || selectedTrial.featureFlags.eSourceV2,
      )}
      selectedValue={selectedValue}
      onSelect={onSelect}
      width={580}
    />
  )
}

const dateTypeItems: Item[] = [
  {
    value: ItemType.Date,
    name: itemTypeToDateFormat(ItemType.Date),
  },
  {
    value: ItemType.NullableDate,
    name: itemTypeToDateFormat(ItemType.NullableDate),
  },
  {
    value: ItemType.HourMinute,
    name: itemTypeToDateFormat(ItemType.HourMinute),
  },
]

const textTypeItems: Item[] = [
  {
    value: ItemType.Text,
    name: itemTypeToTextLabel(ItemType.Text),
  },
  {
    value: ItemType.TextArea,
    name: itemTypeToTextLabel(ItemType.TextArea),
  },
]

export const DateTypeSelect: React.FC<Props> = props => {
  const { selectedValue, onSelect } = props

  return (
    <Component
      items={dateTypeItems}
      selectedValue={selectedValue}
      onSelect={onSelect}
      width={200}
    />
  )
}

export const TextTypeSelect: React.FC<Props> = props => {
  const { selectedValue, onSelect } = props

  return (
    <Component
      items={textTypeItems}
      selectedValue={selectedValue}
      onSelect={onSelect}
      width={200}
    />
  )
}
