import React from 'react'

import { Button } from '@chakra-ui/react'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSendTestEmail } from '../../api'

type Props = {
  email?: string
}
export const SendTestEmailButton: React.FC<Props> = ({ email }) => {
  const toast = useMirohaToast()
  const { request: sendTestEmail } = useSendTestEmail({
    onSuccess: () => {
      toast({
        title: 'メールアドレス確認のメッセージを送信しました。',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  return (
    <Button
      variant="text"
      isDisabled={!email}
      onClick={async () => {
        if (!email) return
        await sendTestEmail({
          email,
        })
        return
      }}
    >
      テスト送信
    </Button>
  )
}
