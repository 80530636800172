import React from 'react'

import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useCognitoUser } from 'src/hooks/use-cognito-user'
import { actions } from 'src/modules/auth/login/redux'
import { routes } from 'src/modules/routes'
import { useRequestState } from 'src/modules/server/use-request-state'

import {
  IProps as IRegisterPasswordProps,
  RegisterPassword,
} from './register-password'
import { useAgreement } from '../../agreement/hooks/use-agreement'

export const RegisterPasswordContainer: React.FC<{}> = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const dispatch = useDispatch()
  const { cognitoUser } = useCognitoUser()
  const { jumpAfterLogin } = useAgreement()

  const onSubmit: IRegisterPasswordProps['onSubmit'] = async ({
    password,
    passwordConfirmation,
  }) => {
    if (password !== passwordConfirmation) {
      requestFailed('パスワードが一致しません。')
      return
    }

    try {
      requestStarted()
      const currentUser = await Auth.completeNewPassword(cognitoUser, password)
      const idToken = currentUser.signInUserSession.idToken.jwtToken
      await dispatch(actions.submit({ idToken: idToken, firstLogin: true }))
      await jumpAfterLogin()
    } catch (error) {
      switch (error.code) {
        case 'InvalidParameterException':
          requestFailed('適切な形式のパスワードを入力下さい。')
          break
        case 'InvalidPasswordException':
          requestFailed(`
            半角英字（大文字、小文字をそれぞれ1文字以上ずつ）含む、
            8文字以上の半角英数字記号を入力してください。
          `)
          break
        case 'LimitExceededException':
          requestFailed(
            'アカウントがロックされています。時間を置いて再度お試し下さい。',
          )
          break
        default:
          // MiROHAのサーバーのエラーはこちらで受け取る
          if (error.message) {
            requestFailed(error.message)
            return
          }
          requestFailed('問題が発生しました。時間を置いて再度お試し下さい。')
      }
    } finally {
      requestDone()
    }
  }

  // 送信中はlogin画面にredirectしないようにする。
  if (!requesting && cognitoUser?.challengeName !== 'NEW_PASSWORD_REQUIRED') {
    return <Navigate replace to={routes.login} />
  }

  return (
    <RegisterPassword
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
    />
  )
}
