import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { getIcfDocumentRevisionVideoStreamRoute } from '../../routes'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import { icfDocumentTypes } from '../entity'
import {
  fetchIcfDocument,
  fetchIcfDocumentRevision,
  getFilePreviewRequestUrl,
} from '../request'

export const useIcfDocumentDetail = (documentUid: string) => {
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()

  const { data: document } = useSWR(
    selectedTrialHospitalUid !== undefined
      ? swrKeys.fetchIcfDocument({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentUid: documentUid,
        })
      : null,
    () =>
      fetchIcfDocument({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentUid: documentUid,
      }),
  )

  const { data: latestRevision } = useSWR(
    document && selectedTrialHospitalUid
      ? swrKeys.fetchIcfDocumentRevision({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: document.latestRevisionUid,
        })
      : null,
    () =>
      fetchIcfDocumentRevision({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        icfDocumentRevisionUid: document?.latestRevisionUid ?? '',
      }),
  )

  const filePreviewUrl = useMemo(() => {
    if (!trialUid || !selectedTrialHospitalUid || !latestRevision) {
      return ''
    }

    return latestRevision.type === icfDocumentTypes.Video
      ? getIcfDocumentRevisionVideoStreamRoute({
          trialUid,
          icfDocumentRevisionUid: latestRevision.uid,
        })
      : getFilePreviewRequestUrl({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
          icfDocumentRevisionUid: latestRevision.uid,
        })
  }, [latestRevision, selectedTrialHospitalUid, trialUid])

  return {
    document,
    latestRevision,
    filePreviewUrl,
  }
}
