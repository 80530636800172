import { generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { FileViewerSearchParam } from 'src/features/misc/hooks/useFileViewerSearchParam'

type Param = {
  worksheetUid: string
  fileFieldValueUid: string
  selectedFileOrder: number
}

export const generateFileViewerPath = ({
  worksheetUid,
  fileFieldValueUid,
  selectedFileOrder,
}: Param) => {
  const basePath = generatePath(Paths.WorksheetFileViewer, {
    worksheetUid,
    fileFieldValueUid,
  })
  const searchParam: FileViewerSearchParam = {
    selectedFile: selectedFileOrder.toString(),
  }
  return `${basePath}?${new URLSearchParams(searchParam).toString()}`
}
