import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import {
  QuestionnaireScheduleSaveRequest,
  save,
} from 'src/modules/entities/questionnaire-schedule/request'
import { useFlash } from 'src/modules/flash/use-flash'

import { Form, FormValues, SchedulePage } from './schedule'
import { getState } from './selector'
import { useFetch } from './use-fetch'

export const ScheduleContainer: React.FC = () => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { hasPermission } = usePermission()

  const { fetched } = useFetch({
    trialUid: selectedTrial.uid,
  })

  const { questionnaires, schedules } = useSelector(getState)

  const { showSuccess, showError } = useFlash()
  const onSave = useCallback(
    async (data: FormValues) => {
      const params = formToParams(data.questionnaires)

      try {
        await save({
          trialUid: selectedTrial.uid,
          params,
        })

        showSuccess('一時保存しました。')
      } catch (error) {
        showError(error.message)
      }
    },
    [selectedTrial.uid, showError, showSuccess],
  )

  if (!fetched) return null

  return (
    <SchedulePage
      trialUid={selectedTrial.uid}
      questionnaires={questionnaires}
      schedules={schedules}
      onSave={onSave}
      canEditSchedule={
        hasPermission(PERMISSIONS.Schedule_EditSchedule) &&
        !selectedTrial.questionnaireScheduleReleased
      }
      showAddButton={
        hasPermission(PERMISSIONS.Questionnaire_CreateSchedule) &&
        !selectedTrial.questionnaireScheduleReleased
      }
      showSaveButton={
        hasPermission(PERMISSIONS.Questionnaire_SaveSchedule) &&
        !selectedTrial.questionnaireScheduleReleased
      }
      showReleaseButton={
        hasPermission(PERMISSIONS.Questionnaire_ReleaseSchedule) &&
        !selectedTrial.questionnaireScheduleReleased
      }
    />
  )
}

const formToParams = (
  questionnaires: Record<string, Form>,
): QuestionnaireScheduleSaveRequest => {
  return {
    questionnaires: Object.entries(questionnaires).map(([uid, value]) => ({
      uid,
      scheduleUids: Object.entries(value.scheduleUids)
        .filter(v => v[1] === true)
        .map(v => v[0]),
    })),
  }
}
