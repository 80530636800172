import { TrialHospital } from 'src/features/trial/types'
import { ApiResponse } from 'src/lib/api-client'
import { IcfDocumentRevisionFragment } from 'src/lib/gql-client'
import { castUid } from 'src/utils/brandedUid'

import {
  IcfDocument,
  IcfDocumentRevision,
  IcfDocumentRevisionStatus,
  IcfDocumentSet,
  IcfDocumentSetDetail,
} from '../types'

export const parseIcfDocumentSetListItem = (
  res: ApiResponse<'ListICFDocumentSetsItem'>,
): IcfDocumentSet => {
  return {
    uid: castUid<IcfDocumentSet>(res.uid),
    numberingId: res.numberingId,
    name: res.name,
    status: res.enabled ? 'Enabled' : 'Disabled',
    hasDocuments: res.hasDocuments,
    hasRequiredAgreementForm: res.hasRequiredAgreementForm,
  }
}

export const parseIcfDocumentSetDetail = (
  res: ApiResponse<'GetICFDocumentSet'>,
): IcfDocumentSetDetail => ({
  uid: castUid<IcfDocumentSet>(res.uid),
  numberingId: res.numberingId,
  name: res.name,
  status: res.enabled ? 'Enabled' : 'Disabled',
  hasDocuments: res.icfDocuments.length > 0, // OK??
  hasRequiredAgreementForm: res.icfDocuments.some(
    doc => doc.latestIsRequiredPatientSign,
  ),
  icfDocuments: res.icfDocuments.map(icfDocument => ({
    uid: castUid<IcfDocument>(icfDocument.uid),
    numberingId: icfDocument.numberingId,
    docSetIndex: icfDocument.index,
    trialHospitalUid: castUid<TrialHospital>(''), // TODO
    latestRevision: {
      uid: castUid<IcfDocumentRevision>(icfDocument.latestApprovedRevisionUid),
      type:
        icfDocument.type === 1
          ? 'AgreementForm'
          : icfDocument.type === 2
            ? 'CheckUnderstanding'
            : icfDocument.type === 3
              ? 'Description'
              : icfDocument.type === 4
                ? 'Video'
                : 'Undefined',
      icfDocumentUid: castUid<IcfDocument>(icfDocument.uid),
      version: icfDocument.latestApprovedVersion,
      name: icfDocument.latestApprovedName,
      status: parseIcfDocRevStatus(icfDocument.status),
      isRequiredAgreement: icfDocument.latestIsRequiredPatientSign,
    },
  })),
})

const parseIcfDocRevStatus = (status: number): IcfDocumentRevisionStatus => {
  switch (status) {
    case 1:
      return 'NewDocumentCreated'
    case 2:
      return 'Saved'
    case 3:
      return 'WaitApproving'
    case 4:
      return 'Approved'
    case 5:
      return 'Rejected'
    case 6:
      return 'Withdrawn'
    case 7:
      return 'NewRevisionCreated'
    case 8:
      return 'NewRevisionCanceled'
    case 9:
      return 'Disabled'
    case 10:
      return 'Enabled'
    default:
      throw new Error(`invalid status: ${status}`)
  }
}

export const parseIcfDocumentRevision = (
  res: IcfDocumentRevisionFragment,
): IcfDocumentRevision => {
  if ('fileURL' in res) {
    return {
      uid: castUid<IcfDocumentRevision>(res.uid),
      type: res.icfDocumentType,
      icfDocumentUid: castUid<IcfDocument>(res.icfDocumentUid),
      version: res.version,
      name: res.name,
      status: res.latestHistory.status,
      isRequiredAgreement: (() => {
        // 同意書の場合
        if ('isConsentRequired' in res) {
          return res.isConsentRequired
        }
        return false
      })(),
      fileUrl: res.fileURL,
    }
  }
  if ('videoURL' in res) {
    return {
      uid: castUid<IcfDocumentRevision>(res.uid),
      type: res.icfDocumentType,
      icfDocumentUid: castUid<IcfDocument>(res.icfDocumentUid),
      version: res.version,
      name: res.name,
      status: res.latestHistory.status,
      isRequiredAgreement: false, // 同意書の場合のみ
      fileUrl: res.videoURL ?? undefined,
    }
  }

  throw new Error('invalid response')
}
