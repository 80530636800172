import { useCallback, useMemo } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { trialUidParamName } from 'src/modules/video-call/patient-container'

import { getRoute } from '../routes'
import { SortKey, SortOrder } from '../sort'
import { usePatientQuery } from '../use-patient-query'

export const useSearchPatient = () => {
  const {
    getQuery,
    searchQuery: currentSearchQuery,
    statusFilter: currentStatusFilter,
    sortKey: currentSortKey,
    sortOrder: currentSortOrder,
  } = usePatientQuery()
  const { trialUid = '' } = useParams<{ [trialUidParamName]: string }>()

  const navigate = useNavigate()

  const onSearch = useCallback(
    (searchQuery: string) => {
      if (searchQuery === currentSearchQuery) {
        return
      }

      const queryParam = getQuery({ type: 'search', searchQuery })
      navigate(getRoute({ trialUid, queryParam }))
    },
    [currentSearchQuery, getQuery, navigate, trialUid],
  )

  const statusFilterHandlers = useMemo(
    () => ({
      onCheckEnabled: () => {
        if (currentStatusFilter === 'all') {
          const queryParam = getQuery({ type: 'status', condition: 'disabled' })
          navigate(getRoute({ trialUid, queryParam }))
          return
        }

        if (currentStatusFilter === 'disabled') {
          const queryParam = getQuery({ type: 'status', condition: 'all' })
          navigate(getRoute({ trialUid, queryParam }))
          return
        }

        const queryParam = getQuery({ type: 'status', condition: 'enabled' })
        navigate(getRoute({ trialUid, queryParam }))
      },

      onCheckDisabled: () => {
        if (currentStatusFilter === 'all') {
          const queryParam = getQuery({ type: 'status', condition: 'enabled' })
          navigate(getRoute({ trialUid, queryParam }))
          return
        }

        if (currentStatusFilter === 'enabled') {
          const queryParam = getQuery({ type: 'status', condition: 'all' })
          navigate(getRoute({ trialUid, queryParam }))
          return
        }

        const queryParam = getQuery({ type: 'status', condition: 'disabled' })
        navigate(getRoute({ trialUid, queryParam }))
      },
    }),
    [currentStatusFilter, getQuery, navigate, trialUid],
  )

  const sortHandlers = useMemo(
    () => ({
      onChangeSortKey: (sortKey: SortKey) => {
        if (sortKey === currentSortKey) {
          return
        }

        const queryParam = getQuery({
          type: 'sort',
          sortType: { key: sortKey, order: currentSortOrder },
        })
        navigate(getRoute({ trialUid, queryParam }))
      },

      onChangeSortOrder: (sortOrder: SortOrder) => {
        if (sortOrder === currentSortOrder) {
          return
        }

        const queryParam = getQuery({
          type: 'sort',
          sortType: { key: currentSortKey, order: sortOrder },
        })
        navigate(getRoute({ trialUid, queryParam }))
      },
    }),
    [currentSortKey, getQuery, currentSortOrder, navigate, trialUid],
  )
  return {
    onSearch,
    statusFilterHandlers,
    sortHandlers,
  }
}
