import produce from 'immer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { Trial } from './entity'

const create = actionCreatorFactory('entities/trial')

const actions = {
  upsert: create<Trial>('UPSERT'),
}

export type State = {
  byId: {
    [uid: string]: Trial
  }
}

const initialState: State = {
  byId: {},
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.upsert, (state, payload) =>
    produce(state, draft => {
      const trial = payload
      draft.byId[trial.uid] = trial
    }),
  )
  .build()
