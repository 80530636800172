import React, { useEffect, useMemo, useState } from 'react'

import { Box, Stack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Modal } from 'src/components/base/modal/modal'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { PERMISSIONS } from 'src/lib/permission'
import { Template } from 'src/modules/entities/template/entity'
import { Worksheet } from 'src/modules/entities/worksheet/entity'

import { PreviewLoglineDetail } from './logline-detail'
import { createWorksheetWithLogline } from './utils'
import { PreviewWorksheet } from './worksheet'
import { usePermission } from '../../../../common/permission'
import { patientIdParamName } from '../../../../patient/detail/patient-detail'
import { actions } from '../../../../patient/detail/worksheet/common/detail/redux'
import { getState } from '../../../../patient/detail/worksheet/common/detail/selector'

const useInitWorksheet = (originalWorksheet: Worksheet) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!originalWorksheet) return

    dispatch(
      actions.initWithWorksheet({
        worksheet: originalWorksheet,
      }),
    )

    return () => {
      dispatch(actions.reset({}))
    }
  }, [dispatch, originalWorksheet])

  const { worksheet } = useSelector(getState)
  return { worksheet }
}

type Props = {
  onClose: () => void
  template: Template
  templateFolderName: string
}

export const PreviewModal: React.FC<Props> = props => {
  const { template, templateFolderName } = props
  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()
  const { hasPermission } = usePermission()

  const visibilityIconVisible = hasPermission(PERMISSIONS.Template_SetHospitals)

  const [currentLoglineUid, setLoglineUid] = useState<string | null>(null)
  const onChangeLoglineUid = (uid: string | null) => {
    setLoglineUid(uid)
  }

  const originalWorksheet: Worksheet = useMemo(
    () =>
      createWorksheetWithLogline({
        template,
        patientUid,
        visibilityIconVisible,
      }),
    [template, patientUid, visibilityIconVisible],
  )

  const { worksheet } = useInitWorksheet(originalWorksheet)

  if (!worksheet) {
    return null
  }

  const breadCrumbParams: BreadcrumbParam[] = [
    { label: templateFolderName, isLink: false },
    { label: worksheet.title, isLink: false },
  ]

  return (
    <Modal onClose={props.onClose} size="L">
      <Stack w="100%" h="100%">
        {!!currentLoglineUid ? (
          <Box>
            <PreviewLoglineDetail
              worksheet={worksheet}
              currentLoglineUid={currentLoglineUid}
              onChangeLoglineUid={onChangeLoglineUid}
              templateFolderName={templateFolderName}
            />
          </Box>
        ) : (
          <Box>
            <Breadcrumb breadcrumbParams={breadCrumbParams} />

            <PreviewWorksheet
              worksheet={worksheet}
              onChangeLoglineUid={onChangeLoglineUid}
            />
          </Box>
        )}
      </Stack>
    </Modal>
  )
}
