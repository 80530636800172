import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { FinishSessionDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

export const finishExplanationSession = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<void> => {
  try {
    await graphqlRequest(FinishSessionDocument, {
      input: {
        explanationSessionUid,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useFinishExplanationSession = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({ explanationSessionUid }: { explanationSessionUid: string }) =>
      finishExplanationSession({ explanationSessionUid }),
    option,
  )
}
