import React, { useState } from 'react'

import { Button } from 'src/components/base/button/button'
import { SelectedTrial } from 'src/modules/entities/account/entity'
import { Member } from 'src/modules/entities/member/entity'
import { fetchDetail } from 'src/modules/entities/member/request'
import { useFlash } from 'src/modules/flash/use-flash'

import { EditModal } from './edit-modal/edit-modal'

type Props = {
  memberUid: string
  selectedTrial: SelectedTrial
}

export const MemberEditButton: React.FC<Props> = props => {
  const { memberUid, selectedTrial } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [member, setMember] = useState<Member>()

  const { showError } = useFlash()

  const onClick = () => {
    const fetchMemberDetail = async () => {
      try {
        const res = await fetchDetail({
          trialUid: selectedTrial.uid,
          memberUid,
        })
        if (res) {
          setMember(res)
        }
        setModalOpen(true)
      } catch (error) {
        showError(error.message)
        setModalOpen(false)
      }
    }
    fetchMemberDetail()
  }

  return (
    <>
      <Button
        text="編集"
        size="S"
        buttonType="normal"
        iconName="edit"
        onClick={onClick}
      />
      {modalOpen && !!member && (
        <EditModal
          member={member}
          onClose={() => setModalOpen(false)}
          onSubmit={values => console.warn(values)}
          selectedTrial={selectedTrial}
        />
      )}
    </>
  )
}
