import React from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { getRoute as getDetailRoute } from './../detail/routes'
import { AddButton } from './components/button'
import { RequestValues } from './components/form'
import { addPatient } from './request'

type Props = {}

export const Container: React.FC<Props> = props => {
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const trial = useSelector(getSelectedTrial)!

  const navigate = useNavigate()

  const { showSuccess } = useFlash()

  const onSubmit = async (values: RequestValues) => {
    if (requesting) return

    requestStarted()

    try {
      const created = await addPatient({
        trialUid: trial.uid,
        patient: values,
      })
      navigate(getDetailRoute({ trialUid: trial.uid, patientUid: created.uid }))
      showSuccess('患者を追加しました')
      requestDone()
    } catch (error) {
      requestFailed(error.message)
    }
  }

  return (
    <AddButton
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
    />
  )
}
