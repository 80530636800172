import { combineReducers } from '@reduxjs/toolkit'

import {
  reducer as disableReducer,
  State as DisableState,
} from './disable/redux'
import { reducer as editReducer, State as EditState } from './edit/redux'
import { reducer as enableReducer, State as EnableState } from './enable/redux'
import { reducer as listReducer, State as ListState } from './list/redux'

export type State = {
  list: ListState
  disable: DisableState
  enable: EnableState
  edit: EditState
}

export const reducer = combineReducers({
  list: listReducer,
  disable: disableReducer,
  enable: enableReducer,
  edit: editReducer,
})
