import { HttpResponse } from 'msw'
import {
  createFinishExpSessionPayloadMock,
  FinishSessionDocument,
  FinishSessionMutation,
  FinishSessionMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const finishSessionHandler = graphQLHandlerFactory<
  FinishSessionMutation,
  FinishSessionMutationVariables
>('mutation', FinishSessionDocument, () => {
  return HttpResponse.json({
    data: {
      finishExpSession: createFinishExpSessionPayloadMock(),
    },
  })
})
