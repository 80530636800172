import React, { useMemo } from 'react'

import {
  Box,
  Button,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link, generatePath } from 'react-router-dom'
import {
  Add,
  Seek,
  PaginationTop,
  PaginationBack,
  PaginationNext,
  PaginationEnd,
} from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import {
  useCurrentMember,
  usePermission,
  useSelectedTrial,
} from 'src/features/auth/context'
import { PERMISSIONS } from 'src/lib/permission'

import { useExplanationPagination } from '../../hooks/useExplanationPagination'
import { ExplanationFilterDrawer } from '../ExplanationFilterDrawer/ExplanationFilterDrawer'
import { ExplanationsTable } from '../ExplanationsTable/ExplanationsTable'

export const ExplanationList = () => {
  const { selectedTrial } = useSelectedTrial()

  const { selectedTrialHospitalRole } = useCurrentMember()

  const { hasPermission } = usePermission()

  const {
    isOpen: isFilterDrawerOpen,
    onOpen: onOpenFilterDrawer,
    onClose: onCloseFilterDrawer,
  } = useDisclosure()

  const canAddExplanation = useMemo(() => {
    if (!hasPermission(PERMISSIONS.Explanation_Edit)) {
      return false
    }
    // 遠隔プレスク対応以降は実施医療機関のユーザーのみ説明同意を登録できる
    if (
      selectedTrial.featureFlags.preScreening &&
      selectedTrialHospitalRole !== 'Main'
    ) {
      return false
    }
    return true
  }, [
    hasPermission,
    selectedTrial.featureFlags.preScreening,
    selectedTrialHospitalRole,
  ])

  return (
    <Stack spacing="1.5rem" direction="column">
      <Stack spacing="0" direction="row" alignItems="center">
        <Text fontWeight="bold" fontSize="18px">
          説明同意一覧
        </Text>
        <Spacer />
        <HStack spacing="4">
          {hasPermission(PERMISSIONS.Explanation_Edit) && (
            <Button
              variant="outline"
              leftIcon={<Add />}
              as={Link}
              to={generatePath(Paths.ExplanationsNew, {
                trialUid: selectedTrial.uid,
              })}
            >
              新規作成
            </Button>
          )}
          <Button
            variant="text"
            leftIcon={<Seek />}
            onClick={onOpenFilterDrawer}
          >
            検索/絞り込み
          </Button>
        </HStack>
      </Stack>
      <Box display="flex" justifyContent="flex-end">
        <ExplanationPagination />
      </Box>
      <ExplanationsTable />
      <ExplanationFilterDrawer
        isOpen={isFilterDrawerOpen}
        onClose={onCloseFilterDrawer}
      />
    </Stack>
  )
}

const ExplanationPagination = () => {
  const {
    rangeString,
    totalCount,
    hasFirstPage,
    hasPrevPage,
    hasNextPage,
    hasLastPage,
    handleClickFirstPage,
    handleClickPrevPage,
    handleClickNextPage,
    handleClickLastPage,
  } = useExplanationPagination()

  return (
    <Stack direction="row" spacing="0.25rem">
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination top button"
        icon={<PaginationTop />}
        onClick={handleClickFirstPage}
        disabled={!hasFirstPage}
      />
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination back button"
        icon={<PaginationBack />}
        onClick={handleClickPrevPage}
        disabled={!hasPrevPage}
      />
      <Text fontSize="sm">{rangeString}</Text>
      <Text fontSize="sm">/</Text>
      <Text fontSize="sm">{`${totalCount} 件`}</Text>
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination next button"
        icon={<PaginationNext />}
        onClick={handleClickNextPage}
        disabled={!hasNextPage}
      />
      <IconButton
        variant="ghost"
        height="1.5rem"
        minWidth="1.5rem"
        borderRadius="sm"
        aria-label="pagination end button"
        icon={<PaginationEnd />}
        onClick={handleClickLastPage}
        disabled={!hasLastPage}
      />
    </Stack>
  )
}
