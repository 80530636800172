import React, { useMemo, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import { PullDown, PullDownItem } from 'src/components/layout/pulldown'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import { SERVER_URL } from 'src/modules/server/const'

import { DeleteContainer } from './delete/delete-container'
import { PreviewContainer } from './preview/preview-container'
import { ExportModal } from '../components/export-modal'
import { useQuestionnaireDetailPermission } from '../use-permission'

type ModalType = 'Delete' | 'Preview'

const useOpenPulldown = () => {
  const [pulldownOpen, setPulldownOpen] = useState(false)
  const handlers = useMemo(
    () => ({
      openPulldown: () => {
        setPulldownOpen(true)
      },
      closePulldown: () => {
        setPulldownOpen(false)
      },
    }),
    [],
  )

  return { pulldownOpen, handlers }
}

const useOpenModal = () => {
  /**
   * modalType: 「その他の操作」 のドロップダウンから開くモーダルの種類
   * modalType === null は、どのモーダルも開いていないことを意味する。
   */
  const [modalType, setModalType] = useState<ModalType | null>(null)

  const handlers = useMemo(
    () => ({
      closeModal: () => {
        setModalType(null)
      },
      openDeleteModal: () => {
        setModalType('Delete')
      },
      openPreviewModal: () => {
        setModalType('Preview')
      },
    }),
    [],
  )

  return { modalType, handlers }
}

type Props = {
  trialUid: string
  questionnaireUid: string
}

export const PulldownContainer: React.FC<Props> = ({
  trialUid,
  questionnaireUid,
}) => {
  const {
    pulldownOpen,
    handlers: { openPulldown, closePulldown },
  } = useOpenPulldown()
  const {
    modalType,
    handlers: { closeModal, openDeleteModal, openPreviewModal },
  } = useOpenModal()

  useCloseModalByDeps({
    condition: modalType !== null,
    onClose: closeModal,
    deps: [questionnaireUid],
  })

  const { canDelete, canExport } = useQuestionnaireDetailPermission()

  const {
    isOpen: exportModalOpen,
    onOpen: openExportModal,
    onClose: closeExportModal,
  } = useDisclosure()

  const pulldownItems: PullDownItem[] = [
    {
      name: 'プレビューを表示',
      onClick: openPreviewModal,
    },
  ]

  if (canDelete) {
    pulldownItems.push({
      name: '質問票を削除',
      onClick: openDeleteModal,
    })
  }

  const exportUrl = useMemo(() => {
    return `${SERVER_URL}/trials/${trialUid}/questionnaires/${questionnaireUid}/export`
  }, [questionnaireUid, trialUid])

  if (canExport) {
    pulldownItems.push({
      name: 'エクスポート',
      onClick: openExportModal,
    })
  }

  return (
    <>
      <PullDown
        closePulldown={closePulldown}
        items={pulldownItems}
        pulldownOpen={pulldownOpen}
      >
        <IconButton iconName="expand2" onClick={openPulldown} />
      </PullDown>

      {modalType === 'Delete' && <DeleteContainer onClose={closeModal} />}
      {modalType === 'Preview' && <PreviewContainer onClose={closeModal} />}
      {exportModalOpen && (
        <ExportModal url={exportUrl} onClose={closeExportModal} />
      )}
    </>
  )
}
