import { useCallback, useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { isRequestError } from './../../../../../../../server/request'
import { fetchIcfDocumentList } from './../../icf-document/request'
import { usePermission } from '../../../../common/permission'
import { getIcfDocumentSetListRoute } from '../../routes'
import { useIcfDocumentHospital } from '../../use-icf-document-hospital'
import { CreateIcfDocumentSetItem } from '../entity'
import { createIcfDocumentSet } from '../request'
import { IcfDocumentSetErrors, validateIcfDocumentSet } from '../validate'

const initialCreateSetItem: CreateIcfDocumentSetItem = {
  name: '',
  icfDocuments: [],
}

export const useAddIcfDocumentSet = () => {
  const [createSetItem, setCreateSetItem] =
    useState<CreateIcfDocumentSetItem>(initialCreateSetItem)
  const [errors, setErrors] = useState<IcfDocumentSetErrors>({})
  const [submitDone, setSubmitDone] = useState(false)
  const [cancelTried, setCancelTried] = useState(false)

  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const navigate = useNavigate()
  const { showSuccess, showError } = useFlash()
  const { hasPermission } = usePermission()

  const { data: approvedIcfDocuments } = useSWR(
    selectedTrialHospitalUid !== undefined
      ? swrKeys.fetchApprovedIcfDocuments({
          trialUid,
          trialHospitalUid: selectedTrialHospitalUid,
        })
      : null,
    () =>
      fetchIcfDocumentList({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid ?? '',
        type: 'approved',
      }),
  )

  //編集を再開したらエラーをクリア
  useEffect(() => {
    setErrors({})
  }, [createSetItem])

  const editing = useMemo(() => {
    if (submitDone) {
      return false
    }

    if (cancelTried) {
      return false
    }

    return createSetItem.name !== '' || createSetItem.icfDocuments.length !== 0
  }, [
    submitDone,
    cancelTried,
    createSetItem.name,
    createSetItem.icfDocuments.length,
  ])

  const onChangeName = useCallback(
    (value: string) => {
      setCreateSetItem({ ...createSetItem, name: value })
    },
    [createSetItem],
  )

  const onChangeSelectedIcfDocuments = useCallback(
    (items: CreateIcfDocumentSetItem['icfDocuments']) => {
      setCreateSetItem({ ...createSetItem, icfDocuments: items })
    },
    [createSetItem],
  )

  const listPath = useMemo(() => {
    return getIcfDocumentSetListRoute({
      trialUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? selectedTrialHospitalUid
        : undefined,
    })
  }, [hasPermission, trialUid, selectedTrialHospitalUid])

  const canSubmit = useMemo(() => {
    return createSetItem.name !== ''
  }, [createSetItem])

  const onSubmit = useCallback(async () => {
    if (!selectedTrialHospitalUid) {
      return
    }

    const errors = validateIcfDocumentSet(createSetItem)
    if (errors !== null) {
      setErrors(errors)
      return
    }

    try {
      await createIcfDocumentSet({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid,
        params: createSetItem,
      })
      setSubmitDone(true)
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    }
  }, [createSetItem, selectedTrialHospitalUid, trialUid, showError])

  const onCancel = useCallback(() => {
    setCancelTried(true)
  }, [])

  //ページ遷移でアラートは出すが、submit後は出さないという挙動のため、
  //更新完了のフラグがtrueになることの副作用としてページ遷移を定義する。
  //（遷移とstateの更新を同じ関数内に定義するとstateの更新より先に遷移が走ってしまうため期待する挙動にならない）
  useEffect(() => {
    if (submitDone) {
      navigate(listPath)
      showSuccess('文書セットを作成しました')
    }
  }, [submitDone, listPath, showSuccess, navigate])

  useEffect(() => {
    if (cancelTried) {
      navigate(listPath)
    }
  }, [cancelTried, listPath, navigate])

  return {
    createSetItem,
    approvedIcfDocuments,
    editing,
    listPath,
    onChangeName,
    onChangeSelectedIcfDocuments,
    canSubmit,
    onSubmit,
    onCancel,
    errors,
  }
}
