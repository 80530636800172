import { useState } from 'react'

import { Button } from '@chakra-ui/react'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useRequestPinSetting } from '../../api/requestPinSetting'
import { AfterPinSettingPhoneNumberSettingModal } from '../AfterPinSettingPhoneNumberSettingModal/AfterPinSettingPhoneNumberSettingModal'
import { ConfirmOnScreenPinSettingModal } from '../ConfirmOnScreenPinSettingModal/ConfirmOnScreenPinSettingModal'
import { PassToPatientModal } from '../PassToPatientModal/PassToPatientModal'
import { PinSettingModal } from '../PinSettingModal/PinSettingModal'
import { SelectPinSettingMethodModal } from '../SelectPinSettingMethodModal/SelectPinSettingMethodModal'
import { SubmitSMSPinSettingModal } from '../SubmitSMSPinSettingModal/SubmitSMSPinSettingModal'

type Props = {
  isReset?: boolean
  latestRevisionUid: string
  patientUid: string
  phoneNumber: string | undefined
  mutateExplanation: () => void
}

type ModalKey =
  | 'None'
  | 'SelectSettingPinMethod'
  | 'ConfirmationStartPinSettingOnScreen'
  | 'SubmitSMSPinSetting'
  | 'PassToPatient'
  | 'PinSettingOnScreen'
  | 'AfterPinSettingPhoneNumberSetting'

export const ExplanationRequestPinSettingButton: React.FC<Props> = ({
  isReset = false,
  latestRevisionUid,
  patientUid,
  phoneNumber,
  mutateExplanation,
}) => {
  const [modalKey, setModalKey] = useState<ModalKey>('None')

  const {
    account: { uid: trialMemberUid, selectedTrial },
  } = useAuthenticatedAccount()

  const { request: requestPinSettingOnScreen } = useRequestPinSetting({
    onSuccess: () => {
      setModalKey('PinSettingOnScreen')
    },
  })

  const toast = useMirohaToast()

  return (
    <>
      <Button
        onClick={() => setModalKey('SelectSettingPinMethod')}
        variant={isReset ? 'text' : 'outline'}
      >
        {isReset ? '再設定' : '当人認証設定'}
      </Button>
      <SelectPinSettingMethodModal
        isOpen={modalKey === 'SelectSettingPinMethod'}
        isReset={isReset}
        onClose={() => setModalKey('None')}
        onSelectSMS={() => {
          setModalKey('SubmitSMSPinSetting')
        }}
        onSelectOnScreen={() =>
          setModalKey('ConfirmationStartPinSettingOnScreen')
        }
      />
      {modalKey === 'SubmitSMSPinSetting' && (
        <SubmitSMSPinSettingModal
          isOpen
          onCancel={() => setModalKey('SelectSettingPinMethod')}
          onSubmit={() => {
            setModalKey('None')
            mutateExplanation()
          }}
          explanationRevisionUid={latestRevisionUid}
          patientUid={patientUid}
          fetchedPhoneNumber={phoneNumber}
        />
      )}

      {/* OnScreen */}
      {modalKey === 'ConfirmationStartPinSettingOnScreen' && (
        <ConfirmOnScreenPinSettingModal
          isOpen
          onClose={() => setModalKey('SelectSettingPinMethod')}
          onSubmit={async () => {
            // ログの記録用
            await requestPinSettingOnScreen({
              explanationRevisionUid: latestRevisionUid,
              method: 'OnScreen',
              deviceActorUid: trialMemberUid,
            })
            setModalKey('PassToPatient')
          }}
        />
      )}
      {modalKey === 'PassToPatient' && (
        <PassToPatientModal
          isOpen
          onConfirm={() => setModalKey('PinSettingOnScreen')}
        />
      )}
      {modalKey === 'PinSettingOnScreen' && (
        <PinSettingModal
          isOpen
          onConfirm={() => {
            if (phoneNumber === undefined) {
              setModalKey('AfterPinSettingPhoneNumberSetting')
              return
            }
            setModalKey('None')
            toast({
              status: 'success',
              title: '患者の暗証番号の設定が完了しました',
            })
            mutateExplanation()
          }}
          onClose={() => {
            setModalKey('None')
          }}
          explanationRevisionUid={latestRevisionUid}
          trialUid={selectedTrial.uid}
        />
      )}
      {modalKey === 'AfterPinSettingPhoneNumberSetting' && (
        <AfterPinSettingPhoneNumberSettingModal
          isOpen
          onClose={() => {
            setModalKey('None')
            mutateExplanation()
          }}
          patientUid={patientUid}
        />
      )}
    </>
  )
}
