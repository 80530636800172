import { ComponentStyleConfig } from '@chakra-ui/react'

const disabledStyle = {
  bg: 'gray.50',
  border: '1px solid',
  borderColor: 'gray.200',
  opacity: 1,
}

export const Input: ComponentStyleConfig = {
  variants: {
    outline: () => ({
      field: {
        bg: 'white',
        _hover: {
          borderColor: 'gray.400',
        },
        _focusVisible: {
          borderColor: 'blue.500',
        },
        _invalid: {
          bg: 'red.50',

          _focusVisible: {
            bg: 'white',
          },
        },
        _disabled: disabledStyle,
        _readOnly: {
          _focus: {
            border: '1px solid',
            borderColor: 'gray.200',
          },
        },
      },
    }),
  },
}
