import { Dictionary } from 'lodash'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'
import {
  SmallItemAnswerResponse,
  parse as parseSmallItemAnswer,
} from 'src/modules/entities/small-item-answer/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { hasChoice } from '../../../../../../../../../util/item-type-classification'

type UpdateParams = {
  reason?: string
  small_item_answers: UpdateSmallItemAnswerParams[]
}

type UpdateSmallItemAnswerParams = {
  small_item_answer_uid: string
  value: string | null // null は choice を持つ ItemType
  choice_answers: UpdateChoiceAnswerParams[]
}

type UpdateChoiceAnswerParams = {
  choice_answer_uid: string
  checked: boolean
}

export const updateSmallItemAnswersBulk = async ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemAnswerUid,
  params,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemAnswerUid: string
  params: UpdateParams[]
}): Promise<SmallItemAnswer[]> => {
  if (IS_MOCK_MODE) {
    return []
  }

  const res = await requestPut<SmallItemAnswerResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/medium_item_answers/${mediumItemAnswerUid}/small_item_answers/bulk`,
    params,
  })

  return res.data.map(parseSmallItemAnswer)
}

export const toParams = ({
  smallItemAnswer,
  value,
  selectedChoiceAnswerUids,
  smallItemAnswerMap,
  reason,
}: {
  smallItemAnswer: SmallItemAnswer
  value: string
  selectedChoiceAnswerUids: string[]
  smallItemAnswerMap: Dictionary<SmallItemAnswer>
  reason?: string
}): UpdateParams => {
  if (!hasChoice(smallItemAnswer)) {
    return {
      reason,
      small_item_answers: [
        {
          small_item_answer_uid: smallItemAnswer.uid,
          value: value!,
          choice_answers: [],
        },
      ],
    }
  }

  const res: UpdateParams = {
    reason,
    small_item_answers: [
      {
        small_item_answer_uid: smallItemAnswer.uid,
        value: '',
        choice_answers: smallItemAnswer.choiceAnswers.map(ca => {
          return {
            choice_answer_uid: ca.uid,
            checked: selectedChoiceAnswerUids.includes(ca.uid),
          }
        }),
      },
    ],
  }

  const choiceAnswersBecomingNonSelected = smallItemAnswer.choiceAnswers.filter(
    ca => ca.checked && !selectedChoiceAnswerUids.includes(ca.uid),
  )

  choiceAnswersBecomingNonSelected.forEach(ca => {
    ca.smallItemAnswerUids
      .map(uid => smallItemAnswerMap[uid])
      .forEach(nextSmallItemAnswer => {
        fillParamsWithEmpty(
          nextSmallItemAnswer,
          res.small_item_answers,
          smallItemAnswerMap,
        )
      })
  })

  return res
}

const fillParamsWithEmpty = (
  smallItemAnswer: SmallItemAnswer,
  smallItemAnswerParams: UpdateSmallItemAnswerParams[],
  smallItemAnswerMap: Dictionary<SmallItemAnswer>,
) => {
  if (!hasChoice(smallItemAnswer)) {
    smallItemAnswerParams.push({
      small_item_answer_uid: smallItemAnswer.uid,
      value: '',
      choice_answers: [],
    })

    return
  }

  smallItemAnswerParams.push({
    small_item_answer_uid: smallItemAnswer.uid,
    value: '',
    choice_answers: smallItemAnswer.choiceAnswers.map(ca => ({
      choice_answer_uid: ca.uid,
      checked: false,
    })),
  })

  smallItemAnswer.choiceAnswers.forEach(choiceAnswer => {
    choiceAnswer.smallItemAnswerUids.forEach(nextSmallItemAnswerUid => {
      const nextSmallItem = smallItemAnswerMap[nextSmallItemAnswerUid]
      fillParamsWithEmpty(
        nextSmallItem,
        smallItemAnswerParams,
        smallItemAnswerMap,
      )
    })
  })
}
