import React, { useState } from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { SubmitModal } from 'src/components/modal/submit'
import { Spacer } from 'src/components/spacer/spacer'
import { useFlash } from 'src/modules/flash/use-flash'
import { RootState } from 'src/modules/reducer'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'

import { actions } from './redux'

type Props = {
  trialUid: string
  patientUid: string
  diseaseUid: string
  onClose: () => void
  edcLinked?: boolean
  worksheetUid: string
}

export const Container: React.FC<Props> = props => {
  const { showSuccess } = useFlash()
  const { errorMessage, requesting } = useSelector(
    (state: RootState) => state.patient.disable,
  )
  const { diseaseUid, patientUid, trialUid, onClose, edcLinked, worksheetUid } =
    props
  const dispatch = useDispatch()
  const [inputValue, changeInputValue] = useState('')
  const { requestStarted, requestDone, requestFailed } = useRequestState()
  const onSubmit = async () => {
    if (requesting) return
    try {
      requestStarted()

      await dispatch(
        actions.submit({
          trialUid,
          patientUid,
          inputValue,
          worksheetUid,
        }),
      )

      requestDone()

      if (edcLinked) {
        showSuccess(
          '患者を無効化しました。\nEDCの対象データへは反映されていないため、別途EDC上での無効化作業が必要です。',
          { durationMsec: 30000, closable: true },
        )
      } else {
        showSuccess('患者を無効化しました')
      }

      onClose()
    } catch (error) {
      console.error('patient disable onSubmit Error: ', error)
      requestFailed(error.message)
    }
  }

  return (
    <SubmitModal
      title={'患者を無効化'}
      submitText={'無効化'}
      errorMessage={errorMessage || ''}
      requesting={requesting}
      onSubmit={onSubmit}
      onClose={onClose}
      disabled={!inputValue ? true : false}
    >
      <Text textAlign="center">
        症例番号：<Bold>{diseaseUid}</Bold>さんを無効化します。
      </Text>
      <Spacer size={10} />
      <Text fontSize="xs" textAlign="center">
        ※患者に紐付くワークシートのデータを無効化します。
      </Text>
      <Spacer size={10} />
      <Text textAlign="center">よろしいですか？</Text>
      <Spacer size={20} />
      <Label bold>無効化理由</Label>
      <Spacer size={10} />
      <Input
        placeholder={'無効化理由を入力してください'}
        onChange={e => changeInputValue(e.target.value)}
      />
    </SubmitModal>
  )
}

const Bold = styled.span`
  font-weight: bold;
`
