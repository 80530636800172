import dayjs from 'dayjs'

import { ExplanationFormSchema } from '../schema/explanationFormSchema'

export const explanationFormDefaultValues: Partial<ExplanationFormSchema> = {
  notifyToPatient: true,
  docSetUids: [],
  members: [],
  representative: 'without',
  scheduledAt: dayjs()
    .set('hour', dayjs().get('hour') + 1)
    .set('minute', 0)
    .format(),
}
