import { createContext, useCallback, useContext } from 'react'

import { Permission, PERMISSIONS } from 'src/lib/permission'

import { Account } from '../types'

type AuthenticatedAccount = Account & { hasSelectedTrial: true }

const context = createContext<AuthenticatedAccount | undefined>(undefined)

type Props = {
  account: AuthenticatedAccount
  children: React.ReactNode
}

/** 配下で認証済みアカウントの情報を取得できる */
export const AuthenticatedAccountProvider: React.FC<Props> = ({
  account,
  children,
}) => {
  return <context.Provider value={account}>{children}</context.Provider>
}

export const useAuthenticatedAccount = () => {
  const account = useContext(context)

  if (account === undefined) {
    throw new Error(
      'useAuthenticatedAccount must be called inside AuthenticatedAccountProvider',
    )
  }

  return {
    account,
  }
}

export const useSelectedTrial = () => {
  const account = useContext(context)

  if (account === undefined) {
    throw new Error(
      'useSelectedTrial must be called inside AuthenticatedAccountProvider',
    )
  }

  return {
    selectedTrial: account.selectedTrial,
  }
}

export const useCurrentMember = () => {
  const account = useContext(context)

  if (account === undefined) {
    throw new Error(
      'useCurrentMember must be called inside AuthenticatedAccountProvider',
    )
  }

  return {
    currentMember: account.member,
    selectedTrialHospitalUid: account.selectedTrialHospitalUid,
    selectedTrialHospitalRole: account.selectedTrialHospitalRole,
  }
}

export const usePermission = () => {
  const account = useContext(context)

  if (account === undefined) {
    throw new Error(
      'usePermission must be called inside AuthenticatedAccountProvider',
    )
  }

  const hasPermission = useCallback(
    (permission: Permission) => {
      if (
        permission === PERMISSIONS.Worksheet_ReadVideoCapture ||
        permission === PERMISSIONS.Patient_DownloadVideoCallCapture
      ) {
        if (!account.selectedTrial.videoCaptureAccessRestricted) {
          return true
        }
      }
      return account.permissions.includes(permission)
    },
    [account.permissions, account.selectedTrial.videoCaptureAccessRestricted],
  )

  return { hasPermission }
}
