import dayjs from 'dayjs'
import { generateUUID } from 'src/utils/generateUUID'

import { ExplanationFormSchema } from '../schema/explanationFormSchema'
import { ExplanationDetail } from '../types'

export const explanationToSchema = ({
  explanation,
  onlyPatient = false,
}: {
  explanation: ExplanationDetail
  onlyPatient: boolean
}): Partial<ExplanationFormSchema> => {
  if (onlyPatient) {
    return {
      candidateId: explanation.patient.candidateId,
      diseaseId: explanation.patient.expDiseaseId,
      email: explanation.patient.email,
      phoneNumber: explanation.patient.phoneNumber,
    }
  }

  return {
    type: explanation.latestRevision.type,
    candidateId: explanation.patient.candidateId,
    diseaseId: explanation.patient.expDiseaseId,
    email: explanation.patient.email,
    phoneNumber: explanation.patient.phoneNumber,
    docSetUids: explanation.latestRevision.docSets.map(
      docSet => docSet.icfDocSetUid,
    ),
    scheduledAt: explanation.latestRevision.scheduledAt
      ? explanation.latestRevision.scheduledAt
      : dayjs()
          .set('hour', dayjs().get('hour') + 1)
          .set('minute', 0)
          .format(),
    partnerTrialHospitalUid:
      explanation.latestRevision.partnerTrialHospital?.uid,
    members: explanation.latestRevision.members.map(member => ({
      id: generateUUID(), // 識別用のIDなのでランダムな値を設定
      trialHospitalUid: member.trialHospital.uid,
      member: {
        role: member.trialMember.role,
        uid: member.trialMember.uid,
      },
    })),
  }
}
