import { castUid } from 'src/utils/brandedUid'

import { DocRevisionBase, ExplanationDocBrowsingHistory } from '../types'

function parseDocRevisionToBrowsingHistories(
  rev: DocRevisionBase,
): ExplanationDocBrowsingHistory[] {
  const {
    browsingHistories,
    icfDocumentNumberingId,
    icfDocumentRevisionName,
    icfDocumentRevisionVersion,
    icfDocumentRevisionType,
  } = rev
  return browsingHistories.map(
    ({ uid, operatedAt, isPartner, member, browserRole }) => {
      return {
        uid: castUid<ExplanationDocBrowsingHistory>(uid),
        operatedAt: operatedAt,
        member: member,
        isPartner,
        numberingId: (() => {
          if (!icfDocumentNumberingId) {
            throw new Error('numberingId is required')
          }
          return icfDocumentNumberingId
        })(),
        name: icfDocumentRevisionName,
        version: icfDocumentRevisionVersion,
        documentType: icfDocumentRevisionType,
        browserRole,
      }
    },
  )
}

export function getExplanationDocBrowsingHistories(
  revs: DocRevisionBase[],
): ExplanationDocBrowsingHistory[] {
  const histories = revs.flatMap(parseDocRevisionToBrowsingHistories)
  return histories
}
