import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { useCognitoUser } from 'src/hooks/use-cognito-user'
import { NewPasswordInput } from 'src/modules/auth/common/new-password-input'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'
import * as yup from 'yup'

interface IProps {
  onClose: () => void
}

const validationSchema = yup.object().shape({
  prevPassword: yup.string().required(),
  password: yup.string().required(),
  passwordConfirmation: yup.string().required(),
})

type IFormValues = yup.InferType<typeof validationSchema>

export const ForgetPasswordModal: React.FC<IProps> = ({ onClose }) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { cognitoUser, initCognitoUser } = useCognitoUser()
  const { showSuccess } = useFlash()
  const { register, handleSubmit, formState } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = async ({
    prevPassword,
    password,
    passwordConfirmation,
  }: IFormValues) => {
    if (password !== passwordConfirmation) {
      requestFailed('パスワードが一致しません。')
      return
    }

    if (password === prevPassword) {
      requestFailed(
        '新しいパスワードは、現在のパスワードとは異なるパスワードをご入力下さい。',
      )
      return
    }

    try {
      requestStarted()
      await Auth.changePassword(cognitoUser, prevPassword, password)
      await initCognitoUser()
      onClose()
      showSuccess('パスワードを変更しました。')
    } catch (error) {
      switch (error.code) {
        case 'InvalidParameterException':
          requestFailed('適切な形式のパスワードを入力下さい。')
          break
        case 'InvalidPasswordException':
          requestFailed(`
            半角英字（大文字、小文字をそれぞれ1文字以上ずつ）含む、
            8文字以上の半角英数字記号を入力してください。
          `)
          break
        case 'LimitExceededException':
          requestFailed(
            'アカウントがロックされています。時間を置いて再度お試し下さい。',
          )
          break
        case 'NotAuthorizedException':
          requestFailed(`
            現在のパスワードが誤っております。
            正しいパスワードをご入力下さい。
          `)
          break
        default:
          requestFailed('問題が発生しました。時間を置いて再度お試し下さい。')
      }
    } finally {
      requestDone()
    }
  }

  return (
    <Modal onClose={onClose}>
      <ModalTitle title="パスワード変更" />

      <ModalContent>
        <Text>
          新しいパスワードを設定します。
          現在のパスワードと新しいパスワードを入力し、「パスワード変更」ボタンを押してください。
        </Text>

        <Spacer size={20} />

        <Label bold>現在のパスワード</Label>
        <Spacer size={10} />
        <Input type="password" width={1} {...register('prevPassword')} />
        {errors.prevPassword && touchedFields.prevPassword && (
          <div>
            <Spacer size={4} />
            <Message type="error" message="パスワードを入力してください" />
          </div>
        )}

        <Spacer size={20} />

        <NewPasswordInput
          isValid={!(errors.password && touchedFields.password)}
          register={register}
        />

        <Spacer size={20} />

        <Label bold>新しいパスワード（確認用）</Label>
        <Spacer size={10} />
        <Input
          type="password"
          placeholder="新しいパスワード（確認用）"
          width={1}
          {...register('passwordConfirmation')}
        />
        {errors.passwordConfirmation && touchedFields.passwordConfirmation && (
          <div>
            <Spacer size={4} />
            <Message type="error" message="パスワードを入力してください" />
          </div>
        )}

        {errorMessage && (
          <div>
            <Message type="error" message={errorMessage} centered />
            <Spacer size={12} />
          </div>
        )}
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="パスワード変更"
          onClick={handleSubmit(onSubmit)}
          buttonType="important"
          disabled={!isValid || requesting}
        ></Button>
      </ModalActions>
    </Modal>
  )
}
