import { useEffect } from 'react'

import { Center, Text } from '@chakra-ui/react'
import { useRoomUsers } from '@micin-jp/call-lib-react'
import AgoraRTC from 'agora-rtc-sdk-ng'

import { useModal } from '../../../../hooks/use-modal'
import { VideoCallRoomRemotePlayers } from '../VideoCallRoomRemotePlayers/VideoCallRoomRemotePlayers'

export const VideoCallRoomInCall: React.FC = () => {
  const { remoteUsers } = useRoomUsers()
  const { showModal } = useModal()

  useEffect(() => {
    // iOS系の場合、Agoraの音声の自動再生時にユーザビリティ設定のために失敗することがある。
    // 何かしらユーザーの画面操作を挟めば音声が再生されるため、モーダルを表示・ボタンクリックを促すようにする。
    // See: https://docportal.shengwang.cn/cn/voice-legacy/autoplay_policy_web_ng?platform=iOS
    AgoraRTC.onAudioAutoplayFailed = () => {
      showModal({
        title: '通話が開始されています',
        content: 'マイクをONにしてください',
        submitText: '確認',
        submitButtonColor: 'blue',
        size: 'sm',
        hideCancel: true,
        onSubmit: () => {},
      })
    }
  }, [showModal])

  if (remoteUsers.length === 0) {
    return (
      <Center h="full" w="full" color="white">
        {/* TODO: 文言は仮なのでfixする */}
        <Text fontSize="2xl" fontWeight="bold" color="white">
          あなた以外に参加者がいません
        </Text>
      </Center>
    )
  }

  return <VideoCallRoomRemotePlayers remoteUsers={remoteUsers} />
}
