import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation, Link } from 'react-router-dom'
import { layouts } from 'src/assets/layouts'
import { Tab, TabPanel } from 'src/components/base/tabs/tabs'
import { Select, SelectItem } from 'src/components/Select/Select'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import styled from 'styled-components'

import { AddIcfDocumentContainer } from './icf-document/add/add-container'
import { AddIcfDocumentRevisionContainer } from './icf-document/add-revision/add-revision-container'
import { EditIcfDocumentContainer } from './icf-document/edit/edit-container'
import { IcfDocumentListContainer } from './icf-document/list/list-container'
import { AddIcfDocumentSetContainer } from './icf-document-set/add/add-container'
import { IcfDocumentSetDetailContainer } from './icf-document-set/detail/detail-container'
import { EditIcfDocumentSetContainer } from './icf-document-set/edit/edit-container'
import { IcfDocumentSetListContainer } from './icf-document-set/list/list-container'
import { getIcfDocumentListRoute, getIcfDocumentSetListRoute } from './routes'
import { useIcfDocumentHospital } from './use-icf-document-hospital'
import { getRelativePath } from '../../../../../../constants/paths'
import { usePermission } from '../../common/permission'

export const IcfDocument: React.FC = () => {
  return (
    <Routes>
      <Route
        path={`${getRelativePath('IcfDocuments', 'IcfDocuments')}/*`}
        element={
          <Routes>
            <Route
              path={``}
              element={<ListComponent ListNode={IcfDocumentListContainer} />}
            />
            <Route
              path={getRelativePath('IcfDocuments', 'IcfDocumentsNew')}
              element={<AddIcfDocumentContainer />}
            />
            <Route
              path={getRelativePath('IcfDocuments', 'IcfDocumentAddRevision')}
              element={<AddIcfDocumentRevisionContainer />}
            />
            <Route
              path={getRelativePath('IcfDocuments', 'IcfDocumentEdit')}
              element={<EditIcfDocumentContainer />}
            />
          </Routes>
        }
      />
      <Route
        path={`${getRelativePath('IcfDocuments', 'IcfDocumentSets')}/*`}
        element={
          <Routes>
            <Route
              path={``}
              element={<ListComponent ListNode={IcfDocumentSetListContainer} />}
            />
            <Route
              path={getRelativePath('IcfDocumentSets', 'IcfDocumentSetNew')}
              element={<AddIcfDocumentSetContainer />}
            />
            <Route
              path={getRelativePath('IcfDocumentSets', 'IcfDocumentSet')}
              element={<IcfDocumentSetDetailContainer />}
            />
            <Route
              path={getRelativePath('IcfDocumentSets', 'IcfDocumentSetEdit')}
              element={<EditIcfDocumentSetContainer />}
            />
          </Routes>
        }
      />
    </Routes>
  )
}

const ListComponent: React.FC<{ ListNode: React.FC }> = ({ ListNode }) => {
  const location = useLocation()
  const { hasPermission } = usePermission()

  const shouldSelectHospital = hasPermission(PERMISSIONS.Hospital_BelongAll)

  const { uid: trialUid, trialHospitals } = useSelector(getSelectedTrial)!

  const selectItem: SelectItem[] = trialHospitals.map(trialHospital => ({
    value: trialHospital.uid,
    label: trialHospital.name,
  }))

  const { selectedTrialHospitalUid, onChangeTrialHospital } =
    useIcfDocumentHospital()

  const selected = location.pathname.endsWith('sets') ? 'Sets' : 'Documents'

  return (
    <ListWrapper>
      <ContentMenu shouldSelectHospital={shouldSelectHospital}>
        <Text as="h1" fontSize="lg" fontWeight="bold">
          文書管理
        </Text>

        <TabContainer>
          <TabPanel>
            <TabLink
              to={getIcfDocumentListRoute({
                trialUid,
                trialHospitalUid: shouldSelectHospital
                  ? selectedTrialHospitalUid
                  : undefined,
              })}
            >
              <Tab selected={selected === 'Documents'}>文書一覧</Tab>
            </TabLink>
            <TabLink
              to={getIcfDocumentSetListRoute({
                trialUid,
                trialHospitalUid: shouldSelectHospital
                  ? selectedTrialHospitalUid
                  : undefined,
              })}
            >
              <Tab selected={selected === 'Sets'}>文書セット一覧</Tab>
            </TabLink>
          </TabPanel>
        </TabContainer>

        {shouldSelectHospital && (
          <Select
            onChange={onChangeTrialHospital}
            value={selectedTrialHospitalUid}
            items={selectItem}
            placeholder="医療機関を選択してください"
            width="100%"
          />
        )}
      </ContentMenu>

      <Spacer size={32} />

      <Content>
        <ListNode />
      </Content>
    </ListWrapper>
  )
}

const ListWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ContentMenu = styled.div<{ shouldSelectHospital: boolean }>`
  display: grid;
  grid-template-columns: ${p =>
    p.shouldSelectHospital ? '280px 1fr 280px' : '80px 1fr 80px'};

  @media (max-width: ${layouts.tabletLandscapeWithMax}) {
    grid-template-columns: ${p =>
      p.shouldSelectHospital ? '80px 1fr 280px' : '80px 1fr'};
  }
`

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  flex: 1;
`

const TabLink = styled(Link)`
  text-decoration: none;
`
