import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { actions } from 'src/modules/entities/patient/redux'
import {
  finishTrial,
  TrialFinishReason,
} from 'src/modules/entities/patient/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

export const useFinishTrial = () => {
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const request = useCallback(
    async ({
      trialUid,
      patientUid,
      reason,
    }: {
      trialUid: string
      patientUid: string
      reason: TrialFinishReason
    }) => {
      try {
        requestStarted()

        const resp = await finishTrial({ trialUid, patientUid, reason })
        dispatch(
          actions.updateEProStatus({
            uid: patientUid,
            eproStatus: resp.epro_status,
          }),
        )

        switch (reason) {
          case TrialFinishReason.End:
            showSuccess(`試験を終了しました`)
            break
          case TrialFinishReason.Discontinue:
            showSuccess(`試験を中止しました`)
            break
          default:
            break
        }
      } catch (error) {
        requestFailed(error.message)
        throw error
      } finally {
        requestDone()
      }
    },
    [dispatch, requestDone, requestFailed, requestStarted, showSuccess],
  )
  return { request, requesting, errorMessage }
}
