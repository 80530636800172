import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { BackToDraftModal } from './modal'
import { useSubmitBackToDraft } from './use-submit-back-to-draft'
import { questionnaireIdParamName } from '../../../router'

type Props = {
  closeModal: () => void
}

export const BackToDraftModalContainer: React.FC<Props> = props => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const { questionnaireUid = '' } = useParams<{
    [questionnaireIdParamName]: string
  }>()

  const { request, requesting, errorMessage } = useSubmitBackToDraft({
    trialUid: selectedTrial.uid,
    questionnaireUid,
  })
  const onSubmit = useCallback(async () => {
    await request()
    props.closeModal()
  }, [props, request])

  return (
    <BackToDraftModal
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
      closeModal={props.closeModal}
    />
  )
}
