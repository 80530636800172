import {
  GqlError,
  GetNotificationsQuery,
  GetNotificationsDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { miscCacheKey } from '../cacheKey'

type GetNotificationReturnType = GetNotificationsQuery['notifications']

export const getNotifications = async (input: {
  after?: string
  limit: number
}): Promise<GetNotificationReturnType> => {
  const res = await graphqlRequest(GetNotificationsDocument, {
    input,
  })
  return res.notifications
}

export const useGetNotifications = ({
  after,
  limit,
  ...option
}: { after?: string; limit: number } & SWRConfiguration<
  GetNotificationReturnType,
  GqlError
>) => {
  return useSWR<GetNotificationReturnType, GqlError>(
    miscCacheKey.getNotifications({ after: after ?? '', limit }),
    () => getNotifications({ after, limit }),
    option,
  )
}
