import { Information } from './entity'
import { requestGet } from '../server/request'

type InformationParam = {
  uid: string
  title: string
  content: string
  created_at: string
  started_at: string
  ended_at: string
}

const PATH = 'unexpired/information.json'

export const fetchList = async () => {
  try {
    const res = await requestGet<InformationParam[]>({ url: PATH })
    return res.data.map(parse)
  } catch {
    return []
  }
}

const parse = (param: InformationParam): Information => {
  return {
    uid: param.uid,
    title: param.title,
    content: param.content,
    createdAt: param.created_at,
    startedAt: param.started_at,
    endedAt: param.ended_at,
  }
}
