import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { CreateTemplateModal, SubmitValues } from './create-template-modal'
import { getSelectedTrial } from '../../../../../entities/account/selector'
import { actions } from '../../../../../entities/template/redux'
import {
  create,
  importTemplate,
} from '../../../../../entities/template/request'
import { getSingleByTemplateFolderUid } from '../../../../../entities/template/selector'
import {
  getSingle,
  presetSelector,
} from '../../../../../entities/template-folder/selector'
import { RootState } from '../../../../../reducer'
import { useRequestState } from '../../../../../server/use-request-state'
import { getDetailRoute } from '../routes'

const useSubmit = (templateFolderUid: string) => {
  const trialUid = useSelector(getSelectedTrial)!.uid

  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
  } = useRequestState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useMirohaToast()
  const request = useCallback(
    async ({
      type,
      title,
      fileName,
      content,
      presetTemplate,
    }: SubmitValues) => {
      try {
        requestStarted()

        switch (type) {
          case 'New':
            const newTemplate = await create({
              trialUid,
              title,
              templateFolderUid,
              presetTemplateUid: presetTemplate?.uid,
            })

            dispatch(actions.upsert(newTemplate))
            navigate(getDetailRoute(newTemplate))

            break
          case 'Import':
            const importedTemplate = await importTemplate({
              trialUid,
              title,
              fileName,
              content,
              templateFolderUid,
            })

            dispatch(actions.upsert(importedTemplate))
            navigate(getDetailRoute(importedTemplate))

            break
          case 'Preset':
            if (!presetTemplate) return

            const presetUsedTemplate = await create({
              trialUid,
              title,
              templateFolderUid,
              presetTemplateUid: presetTemplate.uid,
              videoEnabled: presetTemplate.videoEnabled,
            })

            dispatch(actions.upsert(presetUsedTemplate))
            navigate(getDetailRoute(presetUsedTemplate))

            break
          default:
            const n: never = type
            throw new Error(n)
        }

        requestDone()
        toast({ status: 'success', title: 'テンプレートを作成しました。' })
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [
      requestStarted,
      requestDone,
      toast,
      trialUid,
      templateFolderUid,
      dispatch,
      navigate,
      requestFailed,
    ],
  )

  return { request, requesting, errorMessage }
}

type Props = {
  templateFolderUid: string

  onClose: () => void
}

export const CreateTemplateModalContainer: React.FC<Props> = props => {
  const { templateFolderUid } = props

  const templateFolder = useSelector((state: RootState) =>
    getSingle(state, templateFolderUid),
  )
  const presetTemplateFolder = useSelector(presetSelector)
  const presetTemplates = useSelector((state: RootState) =>
    getSingleByTemplateFolderUid(
      state,
      presetTemplateFolder ? presetTemplateFolder.uid : '',
    ),
  )

  const { request, requesting, errorMessage } = useSubmit(templateFolderUid)
  const onSubmit = useCallback(
    async (params: SubmitValues) => {
      await request(params)
      props.onClose()
    },
    [props, request],
  )

  return (
    <CreateTemplateModal
      templateFolderName={templateFolder.name}
      presetTemplates={presetTemplates}
      requesting={requesting}
      errorMessage={errorMessage}
      onClose={props.onClose}
      onSubmit={onSubmit}
    />
  )
}
