import React from 'react'

import { Badge, Box, HStack, Stack, Text } from '@chakra-ui/react'
import { Share } from 'src/components/icon'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { usePatient } from 'src/features/patient/api'

import { getRelatedHospitalName } from '../../utils/getReleatedHospital'
import { EditPatient } from '../EditPatient/EditPatient'
import { PatientVideoCallHistory } from '../PatientVideoCallHistory/PatientVideoCallHistory'
import { StartPatientVideoCall } from '../StartPatientVideoCall/StartPatientVideoCall'

type Props = {
  patientUid: string
}

export const PatientDetailBox: React.FC<Props> = ({ patientUid }) => {
  const { data: patient, mutate } = usePatient({
    patientUid,
    revalidateIfStale: false,
    // revalidateOnFocus: false,
  })

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  if (!patient) return null

  const isDisabled = patient.status === 'Disable'

  const relatedHospitalName = getRelatedHospitalName(
    patient,
    selectedTrialHospitalUid,
  )

  return (
    <Box p="4">
      <Stack spacing="2" pb="6" as="section" aria-label="patient_information">
        <Stack spacing="2" mb="4">
          <HStack spacing="4">
            <Text as="span" fontWeight="bold" opacity={isDisabled ? 0.3 : 1}>
              {patient.diseaseUid}
            </Text>
            {!!relatedHospitalName && (
              <HStack color="gray.400" spacing="1">
                <Share size="12px" />
                <Text fontSize="sm">{relatedHospitalName}</Text>
              </HStack>
            )}
            {isDisabled && <Badge>無効</Badge>}
          </HStack>
          <HStack spacing="2">
            <Text
              as="span"
              fontWeight="bold"
              opacity={isDisabled ? 0.3 : 1}
            >{`${patient.lastName} ${patient.firstName}`}</Text>

            <EditPatient patient={patient} mutate={() => mutate()} />
          </HStack>
        </Stack>
        <HStack spacing="6">
          <StartPatientVideoCall patientUid={patientUid} />
          <PatientVideoCallHistory patientUid={patientUid} />
        </HStack>
      </Stack>
    </Box>
  )
}
