import { Navigate, Route, Routes } from 'react-router-dom'

import { CompleteContainer } from './complete/complete-container'
import { ConfirmContainer } from './confirm/confirm-container'
import { DetailContainer } from './detail/detail-container'
import { ListContainer } from './list/list-container'
import { useLoginGuard } from '../use-login-guard'

export const questionnaireAnswerIdParamName = 'questionnaireAnswerUid'
export const questionnaireSectionAnswerIdParamName =
  'questionnaireSectionAnswerUid'

export const QuestionnaireAnswerRouter: React.FC = () => {
  const { loading } = useLoginGuard()

  if (loading) return null
  return (
    <Routes>
      <Route path={``} element={<ListContainer />} />
      <Route
        path={`:${questionnaireAnswerIdParamName}/pages/:${questionnaireSectionAnswerIdParamName}`}
        element={<DetailContainer />}
      />
      <Route
        path={`:${questionnaireAnswerIdParamName}/confirm`}
        element={<ConfirmContainer />}
      />
      <Route
        path={`:${questionnaireAnswerIdParamName}/complete`}
        element={<CompleteContainer />}
      />
      <Route path="*" element={<Navigate replace to={``} />} />
    </Routes>
  )
}
