import { useMemo } from 'react'

import { MenuItemProps, Text } from '@chakra-ui/react'
import { useSetAtom } from 'jotai'
import { generatePath, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { useModal } from 'src/hooks/use-modal'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { isDeletedAtom } from 'src/lib/chicken-schema/atom'
import {
  CriteriaDetailMenu_CriteriaFragment,
  DeleteCriteriaDocument,
  DisableCriteriaDocument,
  EnableCriteriaDocument,
} from 'src/lib/gql-client'
import { useGqlMutation } from 'src/lib/gql-client/request'
import { PERMISSIONS } from 'src/lib/permission'

import { useMutateDetailScreen } from '../../hooks/useMutateDetailScreen'

type MenuItem = {
  label: string
  isVisible: boolean
  color?: MenuItemProps['color']
  onClick: () => void
}

type Props = {
  criteria: CriteriaDetailMenu_CriteriaFragment
}

export const useCriteriaDetailMenuItems = ({ criteria }: Props) => {
  const { showModal } = useModal()

  const {
    account: { selectedTrial, selectedTrialHospitalUid, member },
  } = useAuthenticatedAccount()

  const setDeleted = useSetAtom(isDeletedAtom)

  const { hasPermission } = usePermission()

  const canEditCriteria =
    hasPermission(PERMISSIONS.Criteria_Edit) &&
    ((member.role === 'DM' && criteria.belongAllTrialHospitals) ||
      (member.role !== 'DM' &&
        !!criteria.trialHospitalUid &&
        criteria.trialHospitalUid === selectedTrialHospitalUid))

  const navigate = useNavigate()

  const status = criteria.latestLog.status
  const title = criteria.latestDetail.title

  const criteriaUid = criteria.criteriaUid

  const toast = useMirohaToast()

  const mutateDetailScreen = useMutateDetailScreen({ criteriaUid })

  const enableCriteria = useGqlMutation(EnableCriteriaDocument, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: '選択除外基準を有効化しました。',
      })
      mutateDetailScreen('Published')
    },
  })
  const disableCriteria = useGqlMutation(DisableCriteriaDocument, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: '選択除外基準を無効化しました。',
      })
      mutateDetailScreen('Disabled')
    },
  })
  const deleteCriteria = useGqlMutation(DeleteCriteriaDocument, {
    onSuccess: () => {
      setDeleted(true)
      toast({
        status: 'success',
        title: '選択除外基準を削除しました。',
      })
      navigate(generatePath(Paths.Criteria, { trialUid: selectedTrial.uid }))
    },
  })

  const menuItems: MenuItem[] = useMemo(
    () =>
      [
        {
          label: '有効化',
          isVisible: canEditCriteria && status === 'Disabled',
          onClick: () => {
            showModal({
              title: '選択除外基準を有効化',
              content: (
                <Text>
                  <strong>{title}</strong>を有効化します。
                </Text>
              ),
              submitText: '有効化する',
              onSubmit: async () => {
                await enableCriteria.request({
                  input: { criteriaUid },
                })
              },
            })
          },
        },
        {
          label: '無効化',
          isVisible: canEditCriteria && status === 'Published',
          color: 'red.500',
          onClick: () => {
            showModal({
              title: '選択除外基準を無効化',
              submitButtonColor: 'red',
              content: (
                <Text>
                  <strong>{title}</strong>を無効化します。
                </Text>
              ),
              submitText: '無効化する',
              onSubmit: async () => {
                await disableCriteria.request({
                  input: { criteriaUid },
                })
              },
            })
          },
        },
        {
          label: '削除',
          color: 'red.500',
          isVisible:
            canEditCriteria && (status === 'Created' || status === 'Saved'),
          onClick: () => {
            showModal({
              title: '選択除外基準を削除',
              content: (
                <Text>
                  <strong>{title}</strong>を削除します。
                </Text>
              ),
              submitButtonColor: 'red',
              submitText: '削除する',
              onSubmit: async () => {
                await deleteCriteria.request({
                  input: { criteriaUid },
                })
              },
            })
          },
        },
      ].filter(item => item.isVisible),
    [
      showModal,
      status,
      title,
      canEditCriteria,
      enableCriteria,
      disableCriteria,
      deleteCriteria,
      criteriaUid,
    ],
  )

  return menuItems
}
