import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { GqlError, SyncEnvelopeDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const syncEnvelope = async ({
  explanationSessionUid,
  explanationDocRevisionUid,
}: {
  explanationSessionUid: string
  explanationDocRevisionUid: string
}): Promise<void> => {
  try {
    await graphqlRequest(SyncEnvelopeDocument, {
      input: {
        explanationSessionUid,
        explanationDocRevisionUid,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useSyncEnvelope = (option?: UseMutationOption<void, GqlError>) => {
  return useMutation(
    ({
      explanationSessionUid,
      explanationDocRevisionUid,
    }: {
      explanationSessionUid: string
      explanationDocRevisionUid: string
    }) =>
      syncEnvelope({
        explanationSessionUid,
        explanationDocRevisionUid,
      }),
    option,
  )
}
