import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { getSingle } from 'src/modules/entities/member/selector'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { EnableModal } from './enable-modal'
import { trialIdParamName } from '../../trial-detail'

type Props = {
  memberUid: string
}

const EnableButtonContainer = styled.div`
  margin: 0 8px 0 0;
`

export const MemberEnableButton: React.FC<Props> = props => {
  const { memberUid } = props
  const [modalOpen, setModalOpen] = useState(false)

  const user = useSelector((state: RootState) => getSingle(state, memberUid))

  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()

  return (
    <>
      <EnableButtonContainer>
        <Button
          text="有効化"
          size="S"
          buttonType="normal"
          onClick={() => setModalOpen(true)}
        />
      </EnableButtonContainer>
      {modalOpen && (
        <EnableModal
          userName={`${user.lastName} ${user.firstName}`}
          trialUid={trialUid}
          memberUid={memberUid}
          closeModal={() => setModalOpen(false)}
        />
      )}
    </>
  )
}
