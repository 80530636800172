// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgHospital = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <mask id="hospital_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M3 0h10v16H3V0Zm1 .889h8V15.11h-2v-3.555H6v3.555H4V.889ZM9 15.11H7v-2.667h2v2.667ZM7.5 6V4.5H6v-1h1.5V2h1v1.5H10v1H8.5V6h-1ZM6 9.776h4v.89H6v-.89Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#3770E8"
      fillRule="evenodd"
      d="M3 0h10v16H3V0Zm1 .889h8V15.11h-2v-3.555H6v3.555H4V.889ZM9 15.11H7v-2.667h2v2.667ZM7.5 6V4.5H6v-1h1.5V2h1v1.5H10v1H8.5V6h-1ZM6 9.776h4v.89H6v-.89Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M13 0h2v-2h-2v2ZM3 0v-2H1v2h2Zm10 16v2h2v-2h-2ZM3 16H1v2h2v-2ZM12 .889h2v-2h-2v2Zm-8 0v-2H2v2h2Zm8 14.222v2h2v-2h-2Zm-2 0H8v2h2v-2Zm0-3.555h2v-2h-2v2Zm-4 0v-2H4v2h2Zm0 3.555v2h2v-2H6Zm-2 0H2v2h2v-2Zm3 0H5v2h2v-2Zm2 0v2h2v-2H9Zm-2-2.667v-2H5v2h2Zm2 0h2v-2H9v2ZM7.5 4.5h2v-2h-2v2Zm0 1.5h-2v2h2V6ZM6 4.5H4v2h2v-2Zm0-1v-2H4v2h2Zm1.5 0v2h2v-2h-2Zm0-1.5V0h-2v2h2Zm1 0h2V0h-2v2Zm0 1.5h-2v2h2v-2Zm1.5 0h2v-2h-2v2Zm0 1v2h2v-2h-2Zm-1.5 0v-2h-2v2h2Zm0 1.5v2h2V6h-2ZM10 9.777h2v-2h-2v2Zm-4 0v-2H4v2h2Zm4 .89v2h2v-2h-2Zm-4 0H4v2h2v-2ZM13-2H3v4h10v-4Zm2 18V0h-4v16h4ZM3 18h10v-4H3v4ZM1 0v16h4V0H1Zm11-1.111H4v4h8v-4Zm2 16.222V.889h-4V15.11h4Zm-4 2h2v-4h-2v4Zm2-2v-3.555H8v3.555h4Zm-2-5.555H6v4h4v-4Zm-6 2v3.555h4v-3.555H4Zm0 5.555h2v-4H4v4ZM2 .889V15.11h4V.889H2ZM7 17.11h2v-4H7v4Zm-2-4.667v2.667h4v-2.667H5Zm4-2H7v4h2v-4Zm2 4.667v-2.667H7v2.667h4ZM5.5 4.5V6h4V4.5h-4Zm.5 2h1.5v-4H6v4Zm-2-3v1h4v-1H4Zm3.5-2H6v4h1.5v-4Zm-2 .5v1.5h4V2h-4Zm3-2h-1v4h1V0Zm2 3.5V2h-4v1.5h4Zm-.5-2H8.5v4H10v-4Zm2 3v-1H8v1h4Zm-3.5 2H10v-4H8.5v4Zm2-.5V4.5h-4V6h4Zm-3 2h1V4h-1v4Zm2.5-.224H6v4h4v-4Zm2 2.89v-.89H8v.89h4Zm-6 2h4v-4H6v4Zm-2-2.89v.89h4v-.89H4Z"
      mask="url(#hospital_svg__a)"
    />
  </svg>
)
export default SvgHospital
