const generateCacheKey = (...keys: string[]) => keys.join('/')

export const explanationRoomCacheKeys = {
  getDocRevisionForBrowse: (explanationDocRevisionUid: string) =>
    generateCacheKey('getDocRevisionForBrowse', explanationDocRevisionUid),
  getDocRevisionForSign: (explanationDocRevisionUid: string) =>
    generateCacheKey('getDocRevisionForSign', explanationDocRevisionUid),
  getSessionAgreementForms: (explanationSessionUid: string) =>
    generateCacheKey('getSessionAgreementForms', explanationSessionUid),
  getSessionBasicInfo: (explanationSessionUid: string) =>
    generateCacheKey('getSessionBasicInfo', explanationSessionUid),
  getSessionForPartner: (explanationSessionUid: string) =>
    generateCacheKey('getSessionForPartner', explanationSessionUid),
  getSessionForPatient: (explanationSessionUid: string) =>
    generateCacheKey('getSessionForPatient', explanationSessionUid),
  getSessionForPatientSidebar: (explanationSessionUid: string) =>
    generateCacheKey('getSessionForPatientSidebar', explanationSessionUid),
  getSessionForSidebar: (explanationSessionUid: string) =>
    generateCacheKey('getSessionForSidebar', explanationSessionUid),
  getSessionInBrowsingFlow: (explanationSessionUid: string) =>
    generateCacheKey('getSessionInBrowsingFlow', explanationSessionUid),
  getSessionInSignFlow: (
    explanationSessionUid: string,
    participantTrialMemberUids: string[],
  ) =>
    generateCacheKey(
      'getSessionInSignFlow',
      explanationSessionUid,
      ...participantTrialMemberUids,
    ),
  getSessionForFinishSession: (explanationSessionUid: string) =>
    generateCacheKey('getSessionForFinishSession', explanationSessionUid),
  createSignAuthentication: (
    docRevisionUid: string,
    sessionUid: string,
    signerActorUid: string,
  ) =>
    generateCacheKey(
      'createSignAuthentication',
      docRevisionUid,
      sessionUid,
      signerActorUid,
    ),
}
