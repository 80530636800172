import React, { useCallback, useMemo, useState } from 'react'

import { Text, IconButton } from '@chakra-ui/react'
import { blue, gray } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { ButtonLink } from 'src/components/base/text-link'
import {
  ArrowDown,
  ArrowUp,
  ItemMoveLeft,
  ItemMoveRight,
} from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import {
  IcfDocumentForList,
  icfDocumentRevisionStatus,
} from '../../icf-document/entity'
import { IcfDocumentUidAndIndex } from '../entity'

const MAX_DOCUMENT_COUNT = 20

type Props = {
  icfDocuments: IcfDocumentForList[]
  selectedIcfDocuments: IcfDocumentUidAndIndex[]
  onChangeSelectedDocuments: (documents: IcfDocumentUidAndIndex[]) => void
  errorMessage?: string
}

export const SortableMultiSelect: React.FC<Props> = ({
  icfDocuments,
  selectedIcfDocuments,
  onChangeSelectedDocuments,
  errorMessage,
}) => {
  const [selectedUidsToAttach, setSelectedUidsToAttach] = useState<string[]>([])

  const [selectedUidToDetach, setSelectedUidToDetach] = useState<
    string | undefined
  >()

  const docMap = new Map(icfDocuments.map(doc => [doc.uid, doc]))

  const unselectedDocuments = useMemo(
    () =>
      icfDocuments.filter(
        doc => !selectedIcfDocuments.map(doc => doc.uid).includes(doc.uid),
      ),
    [icfDocuments, selectedIcfDocuments],
  )

  const onCheckToAttach = useCallback(
    (uid: string) => {
      if (selectedUidsToAttach.includes(uid)) {
        setSelectedUidsToAttach(selectedUidsToAttach.filter(i => i !== uid))
        return
      }

      setSelectedUidsToAttach([...selectedUidsToAttach, uid])
    },
    [selectedUidsToAttach],
  )

  const onCheckToDetach = useCallback(
    (uid: string) => {
      if (selectedUidToDetach === uid) {
        setSelectedUidToDetach(undefined)
        return
      }

      setSelectedUidToDetach(uid)
    },
    [selectedUidToDetach],
  )

  const onMoveToAttach = useCallback(() => {
    const newItems: IcfDocumentUidAndIndex[] = []
    const nextIndex =
      selectedIcfDocuments.length > 0
        ? Math.max(...selectedIcfDocuments.map(i => i.index)) + 1
        : 0

    selectedUidsToAttach.forEach((uid, i) => {
      const item = icfDocuments.find(doc => doc.uid === uid)
      if (!item) {
        return
      }

      newItems.push({ uid, index: nextIndex + i })
    })

    onChangeSelectedDocuments([...selectedIcfDocuments, ...newItems])
    setSelectedUidsToAttach([])
  }, [
    icfDocuments,
    onChangeSelectedDocuments,
    selectedIcfDocuments,
    selectedUidsToAttach,
  ])

  const onMoveToDetach = useCallback(() => {
    const item = icfDocuments.find(doc => doc.uid === selectedUidToDetach)
    if (!item) {
      return
    }

    //残った選択済みアイテムのindexは連番になるようにしておく
    onChangeSelectedDocuments(
      selectedIcfDocuments
        .filter(doc => doc.uid !== item.uid)
        .sort((a, b) => a.index - b.index)
        .map((doc, i) => ({ ...doc, index: i })),
    )
    setSelectedUidToDetach(undefined)
  }, [
    selectedUidToDetach,
    selectedIcfDocuments,
    icfDocuments,
    onChangeSelectedDocuments,
  ])

  const onBulkDetach = useCallback(() => {
    onChangeSelectedDocuments([])
    setSelectedUidToDetach(undefined)
  }, [onChangeSelectedDocuments])

  const onUp = useCallback(() => {
    const selectedItem = selectedIcfDocuments.find(
      doc => doc.uid === selectedUidToDetach,
    )
    if (!selectedItem) {
      return
    }

    const targetItem = selectedIcfDocuments.find(
      doc => doc.index === selectedItem.index - 1,
    )
    if (!targetItem) {
      return
    }

    const restItems = selectedIcfDocuments.filter(
      doc => ![selectedItem.uid, targetItem.uid].includes(doc.uid),
    )

    onChangeSelectedDocuments([
      { ...selectedItem, index: targetItem.index },
      { ...targetItem, index: selectedItem.index },
      ...restItems,
    ])
  }, [selectedUidToDetach, onChangeSelectedDocuments, selectedIcfDocuments])

  const onDown = useCallback(() => {
    const selectedItem = selectedIcfDocuments.find(
      doc => doc.uid === selectedUidToDetach,
    )
    if (!selectedItem) {
      return
    }

    const targetItem = selectedIcfDocuments.find(
      doc => doc.index === selectedItem.index + 1,
    )
    if (!targetItem) {
      return
    }

    const restItems = selectedIcfDocuments.filter(
      doc => ![selectedItem.uid, targetItem.uid].includes(doc.uid),
    )

    onChangeSelectedDocuments([
      { ...selectedItem, index: targetItem.index },
      { ...targetItem, index: selectedItem.index },
      ...restItems,
    ])
  }, [selectedUidToDetach, onChangeSelectedDocuments, selectedIcfDocuments])

  const isOverMax = useMemo(() => {
    return (
      selectedUidsToAttach.length + selectedIcfDocuments.length >
      MAX_DOCUMENT_COUNT
    )
  }, [selectedUidsToAttach, selectedIcfDocuments])

  const canAttach = useMemo(() => {
    if (selectedUidsToAttach.length === 0) {
      return false
    }
    if (isOverMax) {
      return false
    }

    return true
  }, [selectedUidsToAttach, isOverMax])

  const canUp = useMemo(() => {
    const selected = selectedIcfDocuments.find(
      doc => doc.uid === selectedUidToDetach,
    )
    if (!selected) {
      return false
    }

    const lowerIndexDoc = selectedIcfDocuments.find(
      doc => doc.index < selected.index,
    )

    return !!lowerIndexDoc
  }, [selectedIcfDocuments, selectedUidToDetach])

  const canDown = useMemo(() => {
    const selected = selectedIcfDocuments.find(
      doc => doc.uid === selectedUidToDetach,
    )
    if (!selected) {
      return false
    }

    const higherIndexDoc = selectedIcfDocuments.find(
      doc => doc.index > selected.index,
    )
    return !!higherIndexDoc
  }, [selectedIcfDocuments, selectedUidToDetach])

  return (
    <Wrapper>
      <Flex>
        <div>
          <Text fontWeight="bold">未設定文書</Text>
          <Spacer size={8} />
          <SelectContainer>
            {unselectedDocuments.map(doc => (
              <Option
                key={doc.uid}
                disabled={
                  selectedIcfDocuments.length === MAX_DOCUMENT_COUNT ||
                  doc.status === icfDocumentRevisionStatus.Disabled
                }
              >
                <HiddenCheckbox
                  id={doc.uid}
                  value={doc.uid}
                  checked={selectedUidsToAttach.includes(doc.uid)}
                  onChange={() => onCheckToAttach(doc.uid)}
                  disabled={selectedIcfDocuments.length === MAX_DOCUMENT_COUNT}
                />
                <label htmlFor={doc.uid}>
                  {`${doc.numberingId}. ${doc.name}`}
                </label>
              </Option>
            ))}
          </SelectContainer>
        </div>

        <Spacer size={16} horizontal />

        <CenterButtonArea>
          <IconButton
            aria-label="move-right"
            variant="customIconButtonGhost"
            icon={<ItemMoveRight />}
            onClick={onMoveToAttach}
            disabled={!canAttach}
          />
          <Spacer size={8} />
          <IconButton
            aria-label="move-left"
            variant="customIconButtonGhost"
            icon={<ItemMoveLeft />}
            onClick={onMoveToDetach}
            disabled={!selectedUidToDetach}
          />
        </CenterButtonArea>

        <Spacer size={16} horizontal />

        <div>
          <Text fontWeight="bold">設定文書</Text>
          <Spacer size={8} />
          <Flex>
            <SelectContainer>
              {selectedIcfDocuments
                .sort((a, b) => a.index - b.index)
                .map(selectedDoc => {
                  const doc = docMap.get(selectedDoc.uid)
                  if (!doc) {
                    return null
                  }

                  return (
                    <Option
                      key={selectedDoc.uid}
                      disabled={
                        doc.status === icfDocumentRevisionStatus.Disabled
                      }
                    >
                      <HiddenCheckbox
                        id={doc.uid}
                        value={doc.uid}
                        checked={selectedUidToDetach === doc.uid}
                        onChange={() => onCheckToDetach(doc.uid)}
                      />
                      <label htmlFor={doc.uid}>
                        {`${doc.numberingId}. ${doc.name}`}
                      </label>
                    </Option>
                  )
                })}
            </SelectContainer>

            <Spacer size={8} horizontal />

            <SortButtons>
              <IconButton
                aria-label="sort-up"
                variant="customIconButtonGhost"
                icon={<ArrowUp />}
                onClick={onUp}
                disabled={!canUp}
              />
              <Spacer size={8} />
              <IconButton
                aria-label="sort-down"
                variant="customIconButtonGhost"
                icon={<ArrowDown />}
                onClick={onDown}
                disabled={!canDown}
              />
            </SortButtons>
          </Flex>
        </div>
      </Flex>

      <Spacer size={8} />

      <Bottom>
        <div>
          {!!errorMessage && <Message type="error" message={errorMessage} />}
          {(isOverMax ||
            selectedIcfDocuments.length === MAX_DOCUMENT_COUNT) && (
            <Message
              type="warning"
              message={`登録できる文書の上限は${MAX_DOCUMENT_COUNT}件です`}
            />
          )}
        </div>
        <div>
          <ButtonLink onClick={onBulkDetach}>一括解除</ButtonLink>
          <Spacer size={40} horizontal />
        </div>
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
`

const SelectContainer = styled.div`
  width: 322px;
  height: 240px;
  border-radius: 5px;
  border: 2px solid ${gray[40]};
  overflow-y: scroll;
  word-break: break-all;
`

const Option = styled.div<{ disabled?: boolean }>`
  font-size: 14px;
  font-weight: normal;
  ${p =>
    p.disabled &&
    css`
      color: ${gray[40]};
    `}
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  & + label {
    display: block;
    cursor: pointer;
    padding: 4px;
    box-sizing: border-box;

    &:hover {
      background-color: ${blue[5]};
    }
  }

  &:checked + label {
    background-color: ${blue[10]};
  }
  &:disabled + label {
    cursor: not-allowed;
    background-color: unset;
  }
`

const CenterButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const SortButtons = styled.div`
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
