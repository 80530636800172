import {
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Badge,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Flex,
  Text,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import { Select } from 'src/components/Select/Select'
import { useTrialHospitals } from 'src/features/trial/api'

import { PatientSchema } from '../../schema'
import { genderList } from '../../utils/genderList'
import { PatientBirthdayForm } from '../PatientBirthdayForm/PatientBirthdayForm'
import { SendTestEmailButton } from '../SendTestEmailButton/SendTestEmailButton'
import { SendTestSmsButton } from '../SendTestSmsButton/SendTestSmsButton'

type Props = {
  /** ログイン中の医療機関 */
  myHospitalSelected: boolean
  isDisabled?: boolean
  defaultValues: Partial<PatientSchema>
  /** 自身がパートナー施設側のユーザーか否か */
  isPartner?: boolean
}

export const PatientFormContent: React.FC<Props> = ({
  myHospitalSelected,
  isDisabled,
  defaultValues,
  isPartner = false,
}) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<PatientSchema>()

  const { data: trialHospitals } = useTrialHospitals({})

  const selectedTrialHospitalUid = watch('trialHospitalUid')

  return (
    <Stack spacing="6">
      <FormControl w="full" isDisabled={isDisabled} isRequired={true}>
        <FormLabel
          requiredIndicator={<RequiredBadge ml="1" />}
          alignItems="center"
          display="flex"
        >
          症例番号
        </FormLabel>
        <HStack spacing="8">
          <Input aria-label="症例番号" w="160px" {...register('diseaseId')} />
          {isDisabled && <Badge>無効</Badge>}
        </HStack>
      </FormControl>

      <Stack>
        <Flex w="full">
          <FormControl isRequired isDisabled={isDisabled}>
            <FormLabel
              alignItems="center"
              display="flex"
              requiredIndicator={<RequiredBadge ml="1" />}
            >
              実施医療機関
            </FormLabel>
            <Controller
              name="trialHospitalUid"
              control={control}
              defaultValue={defaultValues.trialHospitalUid}
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  width={328}
                  items={(trialHospitals ?? []).map(th => ({
                    value: th.uid,
                    label: th.name,
                  }))}
                  value={value}
                  onChange={selectedUid => {
                    // 実施医療機関が変更された場合、パートナー施設をクリア
                    if (selectedUid !== selectedTrialHospitalUid) {
                      setValue('partnerTrialHospitalUid', '')
                    }
                    onChange(selectedUid)
                  }}
                  onBlur={onBlur}
                  isDisabled={true} // 実施医療機関は変更不可
                />
              )}
            />
          </FormControl>

          <FormControl isDisabled={isDisabled}>
            <FormLabel>パートナー施設</FormLabel>
            <Controller
              name="partnerTrialHospitalUid"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  width={328}
                  value={value}
                  isDisabled={isDisabled || isPartner}
                  isClearable
                  placeholder="未選択"
                  unselectText="未選択"
                  items={(trialHospitals ?? [])
                    .filter(th => th.uid !== selectedTrialHospitalUid)
                    .map(th => ({
                      value: th.uid,
                      label: th.name,
                    }))}
                  onChange={uid => {
                    // undefinedで更新するとdefaultValueが反映されてしまうため、空文字を渡す
                    onChange(uid ?? '')
                  }}
                  onBlur={onBlur}
                />
              )}
            />
          </FormControl>
        </Flex>
        {!myHospitalSelected && (
          <Text color="red.500" fontSize="sm">
            実施医療機関かパートナー施設にご自身の所属する医療機関を選択してください
          </Text>
        )}
      </Stack>

      <Flex justify="space-between">
        <FormControl isDisabled={isDisabled}>
          <FormLabel>氏名</FormLabel>
          <HStack>
            <Input w="160px" placeholder="姓" {...register('lastName')} />
            <Input w="160px" placeholder="名" {...register('firstName')} />
          </HStack>
        </FormControl>
        <FormControl
          isInvalid={!!errors.firstNameJa || !!errors.lastNameJa}
          isDisabled={isDisabled}
        >
          <FormLabel>ふりがな</FormLabel>
          <HStack>
            <Input
              w="160px"
              isInvalid={!!errors.lastNameJa}
              placeholder="せい"
              {...register('lastNameJa')}
            />
            <Input
              w="160px"
              isInvalid={!!errors.firstNameJa}
              placeholder="めい"
              {...register('firstNameJa')}
            />
          </HStack>
          <FormErrorMessage>
            {errors.firstNameJa?.message ?? errors.lastNameJa?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex justify="space-between">
        <FormControl isInvalid={!!errors.birthday} isDisabled={isDisabled}>
          <FormLabel>生年月日</FormLabel>
          <Controller
            name="birthday"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <PatientBirthdayForm
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <FormErrorMessage>{errors.birthday?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isDisabled={isDisabled}>
          <FormLabel>性別</FormLabel>
          <RadioGroup defaultValue={defaultValues.gender ?? undefined}>
            <HStack spacing="8">
              {genderList.map(item => (
                <Radio
                  key={item.gender}
                  value={item.gender}
                  {...register('gender')}
                >
                  {item.label}
                </Radio>
              ))}
            </HStack>
          </RadioGroup>
        </FormControl>
      </Flex>

      <Flex justify="space-between">
        <FormControl isInvalid={!!errors.phoneNumber} isDisabled={isDisabled}>
          <HStack align="baseline" justify="space-between" w="328px">
            <FormLabel>携帯電話番号</FormLabel>
            {!isDisabled && (
              <SendTestSmsButton phoneNumber={watch('phoneNumber')} />
            )}
          </HStack>
          <Input w="328px" type="tel" {...register('phoneNumber')} />
          <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.email} isDisabled={isDisabled}>
          <HStack align="baseline" justify="space-between" w="328px">
            <FormLabel>メールアドレス</FormLabel>
            {!isDisabled && <SendTestEmailButton email={watch('email')} />}
          </HStack>
          <Input w="328px" type="email" {...register('email')} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <FormControl isDisabled={isDisabled}>
        <FormLabel>電子カルテID</FormLabel>
        <Input w="328px" {...register('chartId')} />
      </FormControl>
    </Stack>
  )
}
