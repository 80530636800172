import React, { useEffect, useRef, useState } from 'react'

import { Box, Center, Text } from '@chakra-ui/react'
import Hls from 'hls.js'

type Props = {
  url: string
}

export const HlsVideoPlayer: React.FC<Props> = ({ url }) => {
  const [isSupported, setIsSupported] = useState(true)

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    // safariはHLS形式の動画再生に対応しているのでhls.jsを使わない
    if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = url
      return
    }
    if (!Hls.isSupported()) {
      setIsSupported(false)
      return
    }

    const hls = new Hls()
    hls.loadSource(url)
    hls.attachMedia(videoRef.current)

    return () => {
      hls.removeAllListeners()
      hls.stopLoad()
    }
  }, [isSupported, url])

  if (!isSupported) {
    return (
      <Center h="full" w="full">
        <Text fontSize="sm">動画を再生することができません。</Text>
      </Center>
    )
  }

  return (
    <Box h="full" w="full" sx={{ '> video': { h: 'full', w: 'full' } }}>
      <video ref={videoRef} controls />
    </Box>
  )
}
