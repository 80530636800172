import { HttpResponse } from 'msw'
import {
  createCompleteAgreementPayloadMock,
  CompleteAgreementDocument,
  CompleteAgreementMutation,
  CompleteAgreementMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const completeAgreementHandler = graphQLHandlerFactory<
  CompleteAgreementMutation,
  CompleteAgreementMutationVariables
>('mutation', CompleteAgreementDocument, () => {
  return HttpResponse.json({
    data: { completeAgreement: createCompleteAgreementPayloadMock() },
  })
})
