// MiROHAで表示する全画面のURLをルートからのパスでここに定義する

// FiXME: /dashboardという階層を消したい。また、e-consentやeproという階層も不要ではないか。
export const Paths = {
  // common
  Login: '/login',
  TemporaryPasswordLogin: '/temporary-password-login',
  RequiredLogin: '/required-login',
  Http403: '/403',
  Agreement: '/agreement',
  Terms: '/terms',
  Privacy: '/privacy',

  Dashboard: '/dashboard',
  Trials: '/dashboard/trials',
  Trial: '/dashboard/trials/:trialUid',

  ReleaseNote: '/release-notes/:version',
  OperationManual: '/operation-manual',

  // settings
  Settings: '/dashboard/trials/:trialUid/settings',
  SettingsTrial: '/dashboard/trials/:trialUid/settings/trials',
  SettingsMember: '/dashboard/trials/:trialUid/settings/members',
  SettingsCrf: '/dashboard/trials/:trialUid/settings/crf',
  SettingsCrfUpload: '/dashboard/trials/:trialUid/settings/crf/upload',
  SettingsCrfDownload: '/dashboard/trials/:trialUid/settings/crf/download',
  SettingsWSDataExport: '/dashboard/trials/:trialUid/settings/ws-data-export',

  Videos: '/videos',

  // eSource
  Patients: '/dashboard/trials/:trialUid/patients',
  Patient: '/dashboard/trials/:trialUid/patients/:patientUid',
  PatientWorksheets:
    '/dashboard/trials/:trialUid/patients/:patientUid/worksheets',
  PatientWorksheet:
    '/dashboard/trials/:trialUid/patients/:patientUid/worksheets/:worksheetUid',
  Templates: '/dashboard/trials/:trialUid/template', // to be template"s"
  Template: '/dashboard/trials/:trialUid/template/:templateUid',
  WorksheetFileViewer:
    '/worksheets/:worksheetUid/file-viewer/:fileFieldValueUid',

  // patientVideoCall
  PatientVideoCall: '/calls/:trialUid/:patientVideoCallUid',
  PatientVideoCallForPatient:
    '/calls/:trialUid/:patientVideoCallUid/for-patient',
  PatientVideoCallSignIn: '/calls/:trialUid/:patientVideoCallUid/sign-in',
  PatientVideoCallExpired: '/calls/expired',

  // eConsent<Explanation>
  EConsent: '/dashboard/trials/:trialUid/e-consent',
  Explanations: '/dashboard/trials/:trialUid/e-consent/explanations',
  ExplanationsNew: '/dashboard/trials/:trialUid/e-consent/explanations/new',
  Explanation:
    '/dashboard/trials/:trialUid/e-consent/explanations/:explanationUid',
  ExplanationEdit:
    '/dashboard/trials/:trialUid/e-consent/explanations/:explanationUid/edit',
  ExplanationAddReAgreement:
    '/dashboard/trials/:trialUid/e-consent/explanations/:explanationUid/add-re-agreement',
  IcfDocuments: '/dashboard/trials/:trialUid/e-consent/icf-documents',
  IcfDocument:
    '/dashboard/trials/:trialUid/e-consent/icf-documents/:icfDocumentUid',
  IcfDocumentsNew: '/dashboard/trials/:trialUid/e-consent/icf-documents/new',
  IcfDocumentsSets: '/dashboard/trials/:trialUid/e-consent/icf-documents/sets',
  IcfDocumentEdit:
    '/dashboard/trials/:trialUid/e-consent/icf-documents/:icfDocumentUid',
  IcfDocumentAddRevision:
    '/dashboard/trials/:trialUid/e-consent/icf-documents/:icfDocumentUid/add-revision',
  IcfDocumentSets: '/dashboard/trials/:trialUid/e-consent/icf-documents/sets',
  IcfDocumentSet:
    '/dashboard/trials/:trialUid/e-consent/icf-documents/sets/:icfDocumentSetUid',
  IcfDocumentSetNew:
    '/dashboard/trials/:trialUid/e-consent/icf-documents/sets/new',
  IcfDocumentSetEdit:
    '/dashboard/trials/:trialUid/e-consent/icf-documents/sets/:icfDocumentSetUid/edit',

  // eConsent<ExplanationRoom>
  ExplanationRoom: '/explanation-room',
  // main hospital
  HospitalExplanationRoom: '/explanation-room/h',
  HospitalExplanationRoomLobby:
    '/explanation-room/h/trials/:trialUid/lobbies/:explanationRevisionUid',
  HospitalExplanationRoomTop:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid',
  HospitalExplanationRoomDocument:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid',
  HospitalExplanationRoomDocumentSignLegacy:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid/docusign',
  HospitalExplanationRoomDocumentSign:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid/sign/:signerActorUid',
  HospitalExplanationRoomSigning:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/signing',
  HospitalExplanationRoomCompleteDocuSign:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/documents/:explanationDocRevisionUid/complete-docusign',
  HospitalExplanationRoomSignCompleted:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/sign-completed/:signerActorUid',
  HospitalExplanationRoomExpired: '/explanation-room/h/expired',
  HospitalExplanationRoomNotFound: '/explanation-room/h/not-found',
  // partner
  PartnerExplanationRoom: '/explanation-room/ph',
  PartnerExplanationRoomLobby:
    '/explanation-room/ph/trials/:trialUid/lobbies/:explanationRevisionUid',
  PartnerExplanationRoomTop:
    '/explanation-room/ph/trials/:trialUid/sessions/:sessionUid',
  PartnerExplanationRoomDocument:
    '/explanation-room/ph/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid',
  PartnerExplanationRoomDocumentSign:
    '/explanation-room/ph/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid/sign/:signerActorUid',
  PartnerExplanationRoomSignCompleted:
    '/explanation-room/ph/trials/:trialUid/sessions/:sessionUid/sign-completed/:signerActorUid',
  // patient
  PatientExplanationRoom: '/explanation-room/p',
  PatientExplanationRoomLobby:
    '/explanation-room/p/trials/:trialUid/lobbies/:explanationRevisionUid',
  PatientExplanationRoomTop:
    '/explanation-room/p/trials/:trialUid/sessions/:sessionUid',
  PatientExplanationRoomDocument:
    '/explanation-room/p/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid',
  PatientExplanationRoomDocumentSign:
    '/explanation-room/p/trials/:trialUid/sessions/:sessionUid/documents/:docRevisionUid/sign/:signerActorUid',
  PatientExplanationRoomSignCompleted:
    '/explanation-room/p/trials/:trialUid/sessions/:sessionUid/sign-completed',
  PatientExplanationRoomExpired: '/explanation-room/p/expired',
  PatientExplanationRoomNotFound: '/explanation-room/p/not-found',
  PatientExplanationPinSetting:
    '/trials/:trialUid/:explanationRevisionUid/pin-setting',

  ExplanationSignAuthentication:
    '/explanation-sign-authentication/trials/:trialUid/:explanationSignAuthUid',
  ExplanationRoomFinished: '/explanation-room/finished',
  ExplanationRoomDocuSign:
    '/explanation-room/h/trials/:trialUid/sessions/:sessionUid/documents/:explanationDocRevisionUid/docusign',
  ExplanationRoomPatientSignIn:
    '/trials/:trialUid/explanation-revisions/:explanationRevisionUid/patient-sign-in',
  PreviewExplanationRoom: '/explanation-room/preview',

  CompleteDocuSignAction: '/complete-docusign-action',
  DocuSignRedirect: '/docusign-redirect',

  // eRecruit
  Criteria: '/dashboard/trials/:trialUid/criteria',
  CriteriaDetail: '/dashboard/trials/:trialUid/criteria/:criteriaUid',
  ReferredPatients: '/dashboard/trials/:trialUid/referred-patients',
  ReferredPatient:
    '/dashboard/trials/:trialUid/referred-patients/:referredPatientUid',
  ReferredPatientFileViewer:
    '/referred-patients/:referredPatientUid/file-viewer/:referredPatientCriteriaValueFileUid',

  // ePRO<Hospital User>
  EPRO: '/dashboard/trials/:trialUid/epro',
  PatientAnswers: '/dashboard/trials/:trialUid/epro/patient_answers',
  PatientAnswer:
    '/dashboard/trials/:trialUid/epro/patient-answers/:patientAnswerUid',
  Questionnaires: '/dashboard/trials/:trialUid/epro/questionnaires',
  Questionnaire:
    '/dashboard/trials/:trialUid/epro/questionnaires/:questionnaireUid',

  // ePRO<Patient User>
  EproPatientLogin: '/epro/trials/:trialUid/login',
  QuestionnaireAnswers: '/epro/trials/:trialUid/questionnaire-answers',
  QuestionnaireSectionAnswer:
    '/epro/trials/:trialUid/questionnaire-answers/:questionnaireAnswerUid/pages/:questionnaireSectionAnswerUid',
  QuestionnaireAnswerConfirm:
    '/epro/trials/:trialUid/questionnaire-answers/:questionnaireAnswerUid/confirm',
  QuestionnaireAnswerComplete:
    '/epro/trials/:trialUid/questionnaire-answers/:questionnaireAnswerUid/complete',
} as const

export const IgnoredRequestSignModalPaths: Readonly<string[]> = [
  // DocuSign画面
  Paths.HospitalExplanationRoomDocumentSign,
  Paths.PartnerExplanationRoomDocumentSign,
  Paths.PatientExplanationRoomDocumentSign,
  // 署名完了画面
  Paths.HospitalExplanationRoomSignCompleted,
  Paths.PartnerExplanationRoomSignCompleted,
  Paths.PatientExplanationRoomSignCompleted,
] as const

export const RoomEventUidQueryKey = 'eventUid'

export type PathKey = keyof typeof Paths

/**
 * Paths の要素を利用して、相対パスを取得する
 * React Router v6 のRouteでは相対パスを指定する必要があるため。
 * @param root 親パス
 * @param target 子パス
 * @throws {Error} target が root から始まっていない場合
 */
export const getRelativePath = (root: PathKey, target: PathKey): string => {
  const rootPath = Paths[root]
  const targetPath = Paths[target]
  if (!targetPath.startsWith(rootPath)) {
    throw new Error(`"${target}" does not start with "${root}".`)
  }
  return targetPath.replace(rootPath, '').substring(1)
}
