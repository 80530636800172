import React from 'react'

import { Route, Routes, useParams } from 'react-router-dom'
import { useScreenOrientationAlert } from 'src/hooks/use-orientation-alert'
import { useInit } from 'src/modules/dashboard/use-init'

import { PreviewDocumentContainer } from './document/preview-container'
import { PreviewContextProvider } from './preview-context'
import {
  explanationRoomPreviewRoutes,
  explanationRoomRoutes,
  explanationUidParamName,
} from './routes'
import { PreviewTopContainer } from './top/preview-container'

export const PreviewExplanationRoom: React.FC = () => {
  const { initialized } = useInit()

  useScreenOrientationAlert()

  if (!initialized) {
    return null
  }

  return (
    <Routes>
      <Route
        path={`${explanationRoomRoutes.withExplanationUid}/*`}
        element={<WithExplanationUidRoute />}
      />
    </Routes>
  )
}

const WithExplanationUidRoute = () => {
  const { explanationUid = '' } = useParams<{
    [explanationUidParamName]: string
  }>()

  return (
    <PreviewContextProvider explanationUid={explanationUid}>
      <Routes>
        <Route
          path={explanationRoomPreviewRoutes.top}
          element={<PreviewTopContainer />}
        />
        <Route
          path={explanationRoomPreviewRoutes.document}
          element={<PreviewDocumentContainer />}
        />
      </Routes>
    </PreviewContextProvider>
  )
}
