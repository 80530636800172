import {
  Box,
  Center,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Down, Up, Warning } from 'src/components/__legacy__icon/monochrome'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { PartnerBadge } from 'src/components/PartnerBadge/PartnerBadge'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PERMISSIONS } from 'src/lib/permission'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useExplanations } from '../../api'
import { useSortExplanations } from '../../hooks/useSortExplanations'
import { ExplanationRevisionMember } from '../../types'
import { ExplanationStatusBadge } from '../ExplanationStatusBadge/ExplanationStatusBadge'

type ExplanationTableField =
  | 'isRequiredReAgreement'
  | 'status'
  | 'candidateId'
  | 'hospitalName'
  | 'diseaseId'
  | 'member'
  | 'agreementCompletedAt'
  | 'deliveredAt'

const tableHeaderProperties: {
  fieldLabel: string
  field: ExplanationTableField
}[] = [
  {
    fieldLabel: '',
    field: 'isRequiredReAgreement',
  },
  {
    fieldLabel: 'ステータス',
    field: 'status',
  },
  {
    fieldLabel: '候補ID',
    field: 'candidateId',
  },
  {
    fieldLabel: '医療機関',
    field: 'hospitalName',
  },
  {
    fieldLabel: '症例番号',
    field: 'diseaseId',
  },
  {
    fieldLabel: '担当者',
    field: 'member',
  },
  {
    fieldLabel: '同意完了日',
    field: 'agreementCompletedAt',
  },
  {
    fieldLabel: '交付日',
    field: 'deliveredAt',
  },
]

export const ExplanationsTable = () => {
  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { field: sortField, order: sortOrder } = useSortExplanations()

  const { data } = useExplanations()
  const navigate = useNavigate()
  const { hasPermission } = usePermission()

  const explanations = data?.explanations

  return (
    <TableContainer>
      <Table variant="hoverable">
        <Thead>
          <Tr borderBottomWidth="0.5">
            {tableHeaderProperties.map(({ fieldLabel, field }) => {
              if (
                field === 'hospitalName' &&
                !hasPermission(PERMISSIONS.Hospital_BelongAll)
              )
                return null

              return (
                <Th key={field}>
                  <Flex justifyContent="flex-start">
                    <Text fontWeight="bold" textAlign="left">
                      {fieldLabel}
                    </Text>
                    <Box px="1" />
                    {field === sortField &&
                      (sortOrder === 'desc' ? <Down /> : <Up />)}
                  </Flex>
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {explanations?.map(
            ({
              uid,
              trialHospital,
              latestRevision,
              agreementCompletedAt,
              patient,
              isRequiredReAgreement,
            }) => {
              return (
                <Tr
                  key={uid}
                  onClick={() =>
                    navigate(
                      generatePath(Paths.Explanation, {
                        trialUid: selectedTrial.uid,
                        explanationUid: uid,
                      }),
                    )
                  }
                >
                  <Td>
                    {isRequiredReAgreement && (
                      <Tooltip label="再同意が必要です" hasArrow>
                        <Center>
                          <Warning color={colors.yellow[500]} />
                        </Center>
                      </Tooltip>
                    )}
                  </Td>

                  <Td>
                    <ExplanationStatusBadge status={latestRevision.status} />
                  </Td>
                  <Td>
                    <HStack spacing="2">
                      <Text>{patient.candidateId}</Text>
                      {/* 自身がパートナー医療機関側だったら非表示 */}
                      {/* 選択医療機関がない（DM）場合は表示 */}
                      {latestRevision.type === 'RemotePartner' &&
                        (trialHospital.uid === selectedTrialHospitalUid ||
                          !selectedTrialHospitalUid) && <PartnerBadge />}
                    </HStack>
                  </Td>
                  {hasPermission(PERMISSIONS.Hospital_BelongAll) && (
                    <Td>{trialHospital.name}</Td>
                  )}
                  <Td>{patient.expDiseaseId}</Td>
                  <Td maxW="240px">
                    <MembersCell members={latestRevision.members} />
                  </Td>
                  <Td>
                    {agreementCompletedAt !== undefined
                      ? formatDate(agreementCompletedAt, 'YYYY/MM/DD (ddd)')
                      : ''}
                  </Td>
                  <Td>
                    {latestRevision.deliveredAt !== undefined
                      ? formatDate(
                          latestRevision.deliveredAt,
                          'YYYY/MM/DD (ddd)',
                        )
                      : ''}
                  </Td>
                </Tr>
              )
            },
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const MembersCell = ({ members }: { members: ExplanationRevisionMember[] }) => {
  const DISPLAY_COUNT = 4

  const overflowCount = members.length - DISPLAY_COUNT

  return (
    <Box>
      <Flex gap={4} wrap="wrap" columnGap={4}>
        {members
          .sort((a, b) => a.index - b.index)
          .slice(0, DISPLAY_COUNT)
          .map(({ trialMember, isPartner }) => (
            <MemberLabel
              key={trialMember.uid}
              role={trialMember.role}
              displayName={getFullName(trialMember)}
              isPartner={isPartner}
            />
          ))}
        {overflowCount > 0 && <Text as="span">他{overflowCount}名</Text>}
      </Flex>
    </Box>
  )
}
