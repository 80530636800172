import { Button, useDisclosure } from '@chakra-ui/react'

import { useExplanationWithdrawnHistories } from '../../api/getExplanationWithdrawHistories'
import { ExplanationRevision } from '../../types'
import { ExplanationWithdrawHistoryModal } from '../ExplanationWithdrawHistoryModal/ExplanationWithdrawHistoryModal'

type Props = {
  explanationRevisionUid: ExplanationRevision['uid']
}
export const ExplanationWithdrawHistoryButton: React.FC<Props> = ({
  explanationRevisionUid,
}) => {
  const {
    isOpen: isOpenWithdrawHistoryModal,
    onOpen: onOpenWithdrawHistoryModal,
    onClose: onCloseWithdrawHistoryModal,
  } = useDisclosure()

  const { data: withdrawHistories } = useExplanationWithdrawnHistories(
    explanationRevisionUid,
  )

  return (
    <>
      <Button
        variant="ghost"
        onClick={onOpenWithdrawHistoryModal}
        color="red.400"
        minWidth="0"
        padding="2"
      >
        同意撤回
      </Button>
      {withdrawHistories && (
        <ExplanationWithdrawHistoryModal
          explanationRevisionUid={explanationRevisionUid}
          isOpen={isOpenWithdrawHistoryModal}
          handleClose={onCloseWithdrawHistoryModal}
          withdrawHistories={withdrawHistories}
          key={withdrawHistories[0].uid}
        />
      )}
    </>
  )
}
