export const routes = {
  top: '/',
  login: '/login',
  temporaryPasswordLogin: '/temporary-password-login',
  twoFactor: '/login/two-factor',
  requiredLogin: '/required-login',
  httpError403: '/http-error-403',
  logout: '/logout',
  dashboard: '/dashboard',
  forgetPassword: '/accounts/forget-password',
  registerPassword: '/accounts/register-password',
  trials: '/trials',
  worksheetPdf: '/worksheet-pdf',
  patientPdf: '/patient-pdf',
  trialPdf: '/trial-pdf',
  patientVideoCall: '/video-call/p',
  doctorVideoCall: '/video-call/d',
  switch: '/switch',
  agreement: '/agreement',
  termsOfService: '/terms',
  // 要認証の利用規約ページ
  termsOfService2: '/terms2',
  privacyPolicy: '/privacy',
  externalTransmissionPolicy: '/external-transmission-policy',
  epro: '/epro',
  room: '/rconsent/room',
  videoCallRoom: '/j',
  patientExplanationRoom: '/explanation-room/p',
  previewExplanationRoom: '/explanation-room/preview',
  completeDocuSignAction: '/complete-docusign-action',
  videos: '/videos',
  // deprecated: use /delivery-doc-url-is-expired
  resendDeliveryMail: '/resend-delivery-mail',
  deliveryDocUrlIsExpired: '/delivery-doc-url-is-expired',
} as const
