import { useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Select } from 'src/components/Select/Select'

import { FieldOf } from '../../utils'
import { FormWrapperWithoutChakra } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'SelectMenu'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  selectWidth?: `${number}px`
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

export const SelectMenuFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  selectWidth,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  // プレビューなどで値が変わったときにフォームの値を更新するためのダミーのstate
  const [dummyValue, setDummyValue] = useState<string | undefined>(value)
  const dummyMode = !onChange
  return (
    <FormWrapperWithoutChakra
      field={field}
      isRequired={field.typeDef.validation?.required}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <Box
        sx={{
          '.select-single-value': {
            // 無効な場合も常に通常色で選択済みの値を表示する 本来はisReadOnlyなどを使って制御したい
            color: 'gray.800',
          },
        }}
      >
        <Select
          value={dummyMode ? dummyValue : value}
          items={field.typeDef.choices.map(choice => ({
            value: choice.cid,
            label: choice.name,
          }))}
          isClearable
          onChange={value => {
            if (dummyMode) {
              setDummyValue(value)
              return
            }
            onChange(value)
          }}
          width={selectWidth ?? '100%'}
          isDisabled={isDisabled || isPreview}
          isInvalid={!!errorMessage}
        />
      </Box>
    </FormWrapperWithoutChakra>
  )
}
