import {
  GqlError,
  GetDocRevisionAndSessionForSignQuery,
  GetDocRevisionAndSessionForSignDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { getFullName } from 'src/utils/getFullName'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

const getDocRevisionAndSessionForSign = async ({
  explanationDocRevisionUid,
  explanationSessionUid,
}: {
  explanationDocRevisionUid: string
  explanationSessionUid: string
}): Promise<GetDocRevisionAndSessionForSignQuery> => {
  try {
    const res = await graphqlRequest(GetDocRevisionAndSessionForSignDocument, {
      explanationDocRevisionUid,
      explanationSessionUid,
    })
    return res
  } catch (error) {
    throw error
  }
}

export const useDocRevisionAndSessionForSign = ({
  explanationDocRevisionUid,
  explanationSessionUid,
  ...option
}: {
  explanationDocRevisionUid: string
  explanationSessionUid: string
} & SWRConfiguration<GetDocRevisionAndSessionForSignQuery, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getDocRevisionForSign(explanationDocRevisionUid),
    () =>
      getDocRevisionAndSessionForSign({
        explanationDocRevisionUid,
        explanationSessionUid,
      }),
    option,
  )
}

export const getSignerDisplayName = (
  res: GetDocRevisionAndSessionForSignQuery,
  signerActorUid: string,
): string => {
  if (
    res.explanationSession.explanationRevision.explanation.patient.uid ===
    signerActorUid
  ) {
    return (
      '候補ID: ' +
      res.explanationSession.explanationRevision.explanation.patient.candidateId
    )
  }
  if (
    res.explanationDocRevision.__typename ===
    'ExplanationDocRevisionAgreementForm'
  ) {
    const found = res.explanationDocRevision.signerMembers.find(
      sm => sm.trialMemberUid === signerActorUid,
    )
    if (found) {
      return `${found.trialMember.role}: ${getFullName(found.trialMember.user)}`
    }
  }
  return '不明'
}
