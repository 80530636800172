import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'

type Props = {
  onClose: () => void
  onSubmit: () => void
}

export const AddRevisionModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle title="改版登録" />
      <ModalContent>
        <Text textAlign="center">文書の改版を登録します</Text>
      </ModalContent>
      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          buttonType="cancel"
          onClick={onClose}
        />
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="改版登録"
          buttonType="important"
          onClick={onSubmit}
        />
      </ModalActions>
    </Modal>
  )
}
