import { useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import PinInputImage from 'src/assets/image/pin-input.svg'
import { CheckDone, MessageSuccess } from 'src/components/icon'
import { PatientSimpleScreenContainer } from 'src/components/PatientSimpleScreenContainer/PatientSimpleScreenContainer'
import { PinInput } from 'src/components/PinInput/PinInput'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { PATIENT_PIN_LENGTH } from 'src/features/explanation/constants/pinSetting'
import { useExplanationRevPinSettingAbout } from 'src/features/explanationRoom/api/getExplanationRevPinSettingAbout'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { assertNever } from 'src/utils/assertNever'

import { useSetPinForPatient } from '../../api/setPinForPatient'

type Param = {
  trialUid: string
  explanationRevisionUid: string
}

type PinSettingStep = 'Input' | 'Confirmation' | 'Completed'

export const PatientPinSettingScreen: React.FC = () => {
  const { trialUid = '', explanationRevisionUid = '' } = useParams<Param>()
  const { data: expRevPinSettingAbout, error } =
    useExplanationRevPinSettingAbout({
      trialUid,
      explanationRevisionUid,
    })
  const [step, setStep] = useState<PinSettingStep>('Input')
  const [code, setCode] = useState<string>()

  const { request: setPin } = useSetPinForPatient({
    onSuccess: () => {
      setStep('Completed')
    },
  })

  const onSubmit = () => {
    if (!code) return
    setPin({ trialUid, explanationRevisionUid, code, method: 'SMS' })
  }

  if (!!error || !expRevPinSettingAbout) {
    return null
  }

  if (expRevPinSettingAbout.latestRevisionPinStatus === 'Completed') {
    return <AlreadyCompletedComponent />
  }

  switch (step) {
    case 'Input':
      return (
        <InputComponent
          code={code}
          onChangeCode={setCode}
          onSubmit={() => {
            setStep('Confirmation')
          }}
        />
      )
    case 'Confirmation':
      return (
        <ConfirmationComponent
          code={code ? code : ''}
          onReturn={() => {
            setStep('Input')
            setCode(undefined)
          }}
          onSubmit={onSubmit}
        />
      )
    case 'Completed':
      return <CompletedComponent />
    default:
      return assertNever(step)
  }
}

const AlreadyCompletedComponent: React.FC = () => {
  return (
    <PatientSimpleScreenContainer>
      <Stack spacing="8">
        <Text fontSize="lg" fontWeight="bold" textAlign="center">
          暗証番号はすでに設定されています
        </Text>
        <Text>暗証番号を再設定する場合は医療スタッフへお問合せください</Text>
        <Text>ブラウザのタブを閉じてください</Text>
      </Stack>
    </PatientSimpleScreenContainer>
  )
}

type InputComponentProps = {
  code: string | undefined
  onChangeCode: (code: string) => void
  onSubmit: () => void
}
const InputComponent: React.FC<InputComponentProps> = ({
  code,
  onChangeCode,
  onSubmit,
}) => {
  return (
    <PatientSimpleScreenContainer>
      <Flex direction="column" align="center">
        <img src={PinInputImage} alt="暗証番号の入力" />
        <Stack spacing="8">
          <Text
            fontSize="lg"
            fontWeight="bold"
            as="h2"
            mt="4"
            display="inline-block"
            mx="auto"
          >
            あなたの暗証番号を設定します
          </Text>
          <Center>
            <Text display="inline" mx="auto">
              お好きな4桁の数字を入力してください
            </Text>
          </Center>
          <PinInput
            length={PATIENT_PIN_LENGTH}
            value={code}
            onChange={onChangeCode}
          />
          <Center>
            <Text>
              暗証番号は説明の署名時に利用しますので、忘れないようメモなどにお控えください。
            </Text>
          </Center>
        </Stack>
        <Box mt="8" w="full">
          <Stack spacing={4} w="full" align="center">
            <Button
              size="sp"
              colorScheme="green"
              onClick={onSubmit}
              isDisabled={code?.length !== PATIENT_PIN_LENGTH}
            >
              次へ
            </Button>
          </Stack>
        </Box>
      </Flex>
    </PatientSimpleScreenContainer>
  )
}

type ConfirmationComponentProps = {
  code: string
  onReturn: () => void
  onSubmit: () => void
}

const ConfirmationComponent: React.FC<ConfirmationComponentProps> = ({
  code,
  onReturn,
  onSubmit,
}) => {
  const [confirmCode, setConfirmCode] = useState<string>()

  const confirmed = !!confirmCode && code === confirmCode

  return (
    <PatientSimpleScreenContainer>
      <img src={PinInputImage} alt="暗証番号の入力" />
      <Stack spacing="8">
        <Text
          fontSize="lg"
          fontWeight="bold"
          as="h2"
          mt="4"
          display="inline-block"
          mx="auto"
        >
          確認のため、先ほど入力した数値をもう一度入力してください
        </Text>
        <HStack spacing="3" w="full" justify="center">
          <PinInput
            length={PATIENT_PIN_LENGTH}
            value={confirmCode}
            isDisabled={confirmed}
            onChange={setConfirmCode}
          />
          {confirmed && <CheckDone color={colors.green[500]} size="lg" />}
        </HStack>
        {confirmCode?.length === PATIENT_PIN_LENGTH && !confirmed && (
          <Center>
            <Text color="red.500">
              入力された暗証番号が一致しません。
              <br />
              暗証番号が不明な場合、最初から設定してください。
            </Text>
          </Center>
        )}
        <Flex w="full" align="center">
          <Stack w="full" align="center">
            <Button
              size="sp"
              colorScheme="green"
              onClick={onSubmit}
              isDisabled={!confirmed}
            >
              確定
            </Button>
            <Button
              colorScheme="gray"
              variant="ghost"
              onClick={onReturn}
              size="sp"
            >
              戻る
            </Button>
          </Stack>
        </Flex>
      </Stack>
    </PatientSimpleScreenContainer>
  )
}

const CompletedComponent: React.FC = () => {
  return (
    <PatientSimpleScreenContainer>
      <Box color="green.500" h="100px">
        <MessageSuccess size="72px" />
      </Box>
      <Stack spacing="8">
        <Text fontSize="lg" fontWeight="bold" textAlign="center">
          暗証番号の設定が完了しました
        </Text>
        <Text>設定された暗証番号をご自身の携帯電話番号宛に送信しました。</Text>
        <WarningMessage message="暗証番号は忘れないよう大切に保管してください。" />
        <Text>ブラウザのタブを閉じてください。</Text>
      </Stack>
    </PatientSimpleScreenContainer>
  )
}
