import React from 'react'

import { Text } from '@chakra-ui/react'
import { Message } from 'src/components/base/message/message'
import { Spacer } from 'src/components/spacer/spacer'

import { WithCommentModal } from './with-comment-modal'
import { IcfDocument, IcfDocumentRevision } from '../entity'

type Props = {
  onClose: () => void
  numberingId: IcfDocument['numberingId']
  documentName: IcfDocumentRevision['name']
  onSubmit: (comment: string) => void
}

export const DisableModal: React.FC<Props> = ({
  onClose,
  numberingId,
  documentName,
  onSubmit,
}) => {
  return (
    <WithCommentModal
      title="文書を無効化"
      onClose={onClose}
      onSubmit={onSubmit}
      required={false}
      submitButtonType="important"
      submitText="無効化する"
      descriptionComponent={
        <div>
          <Text textAlign="center">
            <strong>{`文書ID:${numberingId} ${documentName}`}</strong>
            を無効化します。
          </Text>
          <Spacer size={8} />
          <Message
            type="warning"
            message="説明開始済みの説明同意には反映されません"
            centered
          />
        </div>
      }
    />
  )
}
