import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IState {
  user: any
}

const initialState: IState = {
  user: null,
}

const slice = createSlice({
  name: 'cognito',
  initialState,
  reducers: {
    setCognitoUser: (state: IState, action: PayloadAction<any>) => ({
      ...state,
      user: action.payload,
    }),
  },
})

export const actions = slice.actions
export const reducer = slice.reducer
