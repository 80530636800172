import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { rootReducer } from './reducer'

declare let window: Window
interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
}

const configureStore = () => {
  if (
    import.meta.env.DEV ||
    import.meta.env.VITE_APPLICATION_ENV === 'stage1' ||
    import.meta.env.VITE_APPLICATION_ENV === 'stage2' ||
    import.meta.env.VITE_APPLICATION_ENV === 'stage3'
  ) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          maxAge: 300,
          serialize: {
            options: true,
          },
        })
      : compose

    return createStore(
      rootReducer(),
      composeEnhancers(applyMiddleware(thunkMiddleware)),
    )
  } else {
    return createStore(rootReducer(), compose(applyMiddleware(thunkMiddleware)))
  }
}

export const store = configureStore()
