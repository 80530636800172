import { Navigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'

/** Loginページに遷移 認証後は再度指定したURLに遷移する */
export const RedirectLogin: React.FC = () => {
  return (
    <Navigate
      to={{ pathname: Paths.Login }}
      state={{ from: window.location.href }}
    />
  )
}
