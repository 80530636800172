import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSingle } from 'src/modules/entities/patient/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { RootState } from 'src/modules/reducer'

import { patientIdParamName } from '../../../router'

export const useValidate = () => {
  const { showError } = useFlash()

  const { patientUid = '' } = useParams<{
    [patientIdParamName]: string
  }>()
  const patient = useSelector((state: RootState) =>
    getSingle(state, patientUid),
  )!

  const { answerStartDate } = patient

  const validate = useCallback(
    (onValid: () => void) => {
      if (!answerStartDate) {
        showError('試験開始日は必須です')
        return
      }
      onValid()
    },
    [answerStartDate, showError],
  )

  return {
    validate,
  }
}
