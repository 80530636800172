import { Badge, BadgeProps } from '@chakra-ui/react'

type Props = BadgeProps
export const RequiredBadge: React.FC<Props> = ({ ...props }) => {
  return (
    <Badge
      py="0.5"
      px="1.5"
      bgColor="blue.400"
      color="white"
      fontSize="10px"
      {...props}
    >
      必須
    </Badge>
  )
}
