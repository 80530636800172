import { HttpResponse } from 'msw'
import {
  createExplanationRevisionMock,
  GetExplanationHistoryDocument,
  GetExplanationHistoryQuery,
  GetExplanationHistoryQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationHistoryHandler = graphQLHandlerFactory<
  GetExplanationHistoryQuery,
  GetExplanationHistoryQueryVariables
>('query', GetExplanationHistoryDocument, () => {
  return HttpResponse.json({
    data: {
      explanationRevision: createExplanationRevisionMock({}),
    },
  })
})
