import React from 'react'

import { WithCommentModal } from './with-comment-modal'

type Props = {
  onClose: () => void
  onSubmit: (comment: string) => void
}

export const RequestApprovalModal: React.FC<Props> = ({
  onClose,
  onSubmit,
}) => {
  return (
    <WithCommentModal
      title="承認依頼"
      onClose={onClose}
      onSubmit={onSubmit}
      required={false}
      submitText="承認を依頼する"
      submitButtonType="important"
      descriptionComponent={null}
    />
  )
}
