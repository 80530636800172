import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'
import { castUid } from 'src/utils/brandedUid'

import { CreateExplanationInput, Explanation } from '../../types'

const createExplanation = async ({
  trialUid,
  input,
}: {
  trialUid: Trial['uid']
  input: CreateExplanationInput
}): Promise<{ explanationUid: Explanation['uid'] }> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanations',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
      },
      body: {
        trialHospitalUid: input.trialHospitalUid,
        patientMasterUid: input.patientMasterUid,
        patient: {
          candidateId: input.candidateId,
          expDiseaseId: input.expDiseaseId,
          mobileNumber: input.phoneNumber,
          email: input.email,
        },
        revision: {
          partnerTrialHospital: input.partnerTrialHospitalUid,
          docSets: input.docSets.map(docSet => ({
            icfDocumentSetUID: docSet.uid,
            index: docSet.index,
          })),
          members: input.members.map(member => ({
            trialHospitalUid: member.trialHospitalUid,
            trialMemberUid: member.memberUid,
          })),
          type: input.type,
          withRepresentative: input.withRepresentative,
          scheduledAt: input.scheduledAt,
          notifyToPatient: input.notifyToPatient,
        },
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return {
    explanationUid: castUid<Explanation>(res.data.explanationUid),
  }
}

export const useCreateExplanation = (
  option?: UseMutationOption<{ explanationUid: Explanation['uid'] }>,
) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    ({ input }: { input: CreateExplanationInput }) =>
      createExplanation({ trialUid: selectedTrial.uid, input }),
    option,
  )
}
