// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgHome = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#home_svg__a)">
      <path
        fill={props.color ?? 'currentColor'}
        d="M15.856 8.648 8.352 1.144a.502.502 0 0 0-.704 0L.144 8.648a.51.51 0 0 0-.112.544c.08.184.256.312.464.312h2v6h4v-4h3v4h4v-6h2c.2 0 .384-.12.464-.312a.495.495 0 0 0-.112-.544h.008Z"
      />
    </g>
    <defs>
      <clipPath id="home_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgHome
