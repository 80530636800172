import {
  Button,
  ButtonProps,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { usePatient } from '../../api'
import { useStartPatientVideoCall } from '../../hooks/useStartPatientVideoCall'
import { ResendPatientVideoCallNotificationButton } from '../ResendPatientVideoCallNotificationButton/ResendPatientVideoCallNotificationButton'

type Props = {
  patientUid: string
}

export const StartPatientVideoCall: React.FC<Props> = ({ patientUid }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { data: patient } = usePatient({ patientUid, revalidateIfStale: false })

  const isPartner =
    patient?.partnerTrialHospital?.uid === selectedTrialHospitalUid

  const {
    existingPatientVideoCallUid,
    joinedMemberCountWithPatient,
    joinedMemberCountWithPartner,
    totalJoinedMemberCount,
    hasPermission,
    canCallWithPatient,
    canCallWithPartner,
    handleStartVideoCalWithPartner,
    handleStartVideoCalWithPatient,
  } = useStartPatientVideoCall({ patientUid, onCallStart: onClose })

  if (!hasPermission || !patient || patient.status === 'Disable') return null

  const patientName =
    !!patient.lastName || !!patient.firstName
      ? [patient.lastName, patient.firstName].join(' ')
      : undefined

  const isDisabledPatientCall =
    joinedMemberCountWithPartner !== 0 && joinedMemberCountWithPatient === 0

  const isDisabledPartnerCall =
    joinedMemberCountWithPatient !== 0 && joinedMemberCountWithPartner === 0

  return (
    <>
      <WithBadgeButton
        onClick={onOpen}
        joinedMemberCount={totalJoinedMemberCount}
        isDisabled={!canCallWithPartner && !canCallWithPatient}
      >
        {totalJoinedMemberCount > 0 ? '通話中' : 'ビデオ通話'}
      </WithBadgeButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>ビデオ通話を開始します</ModalHeader>
          <ModalBody>
            <Stack spacing="6">
              <Text>通話先を選択してください。</Text>
              <Stack spacing="4">
                {canCallWithPatient && (
                  <Stack alignItems="end" pb="2">
                    <WithBadgeButton
                      w="full"
                      px="6"
                      py="4"
                      h="64px"
                      joinedMemberCount={joinedMemberCountWithPatient}
                      onClick={handleStartVideoCalWithPatient}
                      isDisabled={isDisabledPatientCall}
                    >
                      <Stack textAlign="left" w="full">
                        <Text>{patient.diseaseUid}</Text>
                        {!!patientName && (
                          <Text as="span" fontWeight="bold">
                            {patientName}
                          </Text>
                        )}
                      </Stack>
                    </WithBadgeButton>
                    {!!existingPatientVideoCallUid && (
                      <ResendPatientVideoCallNotificationButton
                        patientVideoCallUid={existingPatientVideoCallUid}
                      />
                    )}
                  </Stack>
                )}
                {canCallWithPartner && (
                  <WithBadgeButton
                    w="full"
                    px="6"
                    py="4"
                    display="inline-flex"
                    h="64px"
                    onClick={handleStartVideoCalWithPartner}
                    joinedMemberCount={joinedMemberCountWithPartner}
                    disabled={isDisabledPartnerCall}
                  >
                    <Text as="span" fontWeight="bold" textAlign="left" w="full">
                      {isPartner
                        ? patient.trialHospital.hospital.name
                        : patient.partnerTrialHospital?.hospital.name}
                    </Text>
                  </WithBadgeButton>
                )}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <ModalCancelButton />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const WithBadgeButton: React.FC<
  {
    joinedMemberCount: number
    children: React.ReactNode
  } & ButtonProps
> = ({ joinedMemberCount, children, ...buttonProps }) => {
  return (
    <Button pos="relative" {...buttonProps}>
      {joinedMemberCount > 0 && (
        <Center
          pos="absolute"
          w="24px"
          h="24px"
          bg="red.500"
          color="white"
          right="-6px"
          top="-6px"
          borderRadius="full"
          border="1px solid"
          borderColor="white"
        >
          {joinedMemberCount}
        </Center>
      )}
      {children}
    </Button>
  )
}
