import { ComponentStyleConfig } from '@chakra-ui/react'

export const Drawer: ComponentStyleConfig = {
  defaultProps: {
    size: 'sm',
    autoFocus: false,
  },
  baseStyle: {
    dialog: {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
    closeButton: {
      _focus: { boxShadow: 'none' },
      ':focus-visible': { boxShadow: 'outline' },
    },
  },
}
