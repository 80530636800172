import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import styled from 'styled-components'

import { displayAnswerDuration } from '../list/list'

type Props = {
  displayName: string
  answerStartDate: string
  answerEndDate: string
  shouldDeliverAfterTrial: boolean
  showStopButton: boolean
  onStop?: () => void
}

export const Header: React.FC<Props> = ({
  displayName,
  answerStartDate,
  answerEndDate,
  shouldDeliverAfterTrial,
  showStopButton,
  onStop,
}) => {
  return (
    <Container>
      <Content>
        <Text fontSize="xl" fontWeight="bold" color="white">
          {displayName}
        </Text>
        <AnswerDeadline>
          <Text fontSize="xl" color="white">
            回答期間:
            {shouldDeliverAfterTrial ? (
              <>なし</>
            ) : (
              displayAnswerDuration(answerStartDate, answerEndDate)
            )}
          </Text>
        </AnswerDeadline>
      </Content>
      {showStopButton && (
        <Button
          text={'中断する'}
          size="M"
          buttonType="normal"
          onClick={onStop}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    140.3deg,
    #5cb9ff 3.27%,
    #4b7ee8 71.69%,
    #1e4eb1 110.67%
  );
`

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 70%;
`

const AnswerDeadline = styled.div`
  display: flex;
  align-items: center;
`
