import { Fragment, useState } from 'react'

import {
  Button,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Edit } from 'src/components/icon'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { NumberOnlyInput } from 'src/components/NumberOnlyInput/NumberOnlyInput'
import { Select } from 'src/components/Select/Select'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import {
  useSendTestSMS,
  useSetExpDocRevisionSignerMember,
} from 'src/features/explanation/api'
import { useUpdateExpPatientMobileNumber } from 'src/features/explanation/api/updatePatientMobileNumber'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ExplanationRevisionStatusV2,
  SessionMemberFragment,
} from 'src/lib/gql-client'
import { getFullName } from 'src/utils/getFullName'
import { isNotNullish } from 'src/utils/isNotNullish'

import { SignFlowBannerButton } from '../SignFlowBannerButton/SignFlowBannerButton'
import { SignFlowBannerStatic } from '../SignFlowBannerStatic/SignFlowBannerStatic'

type SignerMembers = {
  dr: SessionMemberFragment
  crc: SessionMemberFragment | null
}

type Props = {
  hasAgreementForms: boolean
  explanationRevisionStatus: ExplanationRevisionStatusV2
  sessionMembers: SessionMemberFragment[]
  agreementFormDocRevUids: string[]
  patientUid: string
  candidateId: string
  fetchedPatientMobileNumber: string | undefined
  /** docRevUid -> hasCrcField */
  hasCrcFieldMap: Map<string, boolean>
  drSignerTrialMemberUid: string | undefined
  crcSignerTrialMemberUid: string | undefined
  mutateSession: () => void
}

export const SignerSetupBanner: React.FC<Props> = ({
  hasAgreementForms,
  explanationRevisionStatus,
  sessionMembers,
  agreementFormDocRevUids,
  patientUid,
  candidateId,
  fetchedPatientMobileNumber,
  hasCrcFieldMap,
  drSignerTrialMemberUid,
  crcSignerTrialMemberUid,
  mutateSession,
}) => {
  const {
    isOpen: isSelectModalOpen,
    onOpen: onOpenSelectModal,
    onClose: onCloseSelectModal,
  } = useDisclosure()

  const {
    isOpen: isConfirmModalOpen,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure()

  const toast = useMirohaToast()

  const { request } = useSetExpDocRevisionSignerMember({
    onError: error => {
      throw new Error(error.message)
    },
  })

  const title = '署名準備 / 署名者設定'

  const drMembers = sessionMembers.filter(
    m => m.trialMember.role === 'Dr' && m.isSetMember,
  )
  const crcMembers = sessionMembers.filter(
    m => m.trialMember.role === 'CRC' && m.isSetMember,
  )

  // index最上位のDrを初期値とする（Drの担当者への設定は必須なので0人のケースは考慮しない）
  const initialSelectedDr = drMembers[0]
  const [dr, setDr] = useState<SessionMemberFragment>(drMembers[0])
  const [crc, setCrc] = useState<SessionMemberFragment>()

  const [signerMembers, setSignerMembers] = useState<SignerMembers>()

  const resetMembers = () => {
    setDr(initialSelectedDr)
    setCrc(undefined)
    setSignerMembers(undefined)
  }

  const waitingStatuses: ExplanationRevisionStatusV2[] = [
    'New',
    'SessionStarted',
    'SignReady',
  ]
  const waiting =
    waitingStatuses.includes(explanationRevisionStatus) &&
    drSignerTrialMemberUid === undefined &&
    crcSignerTrialMemberUid === undefined

  const drSignerMember = sessionMembers.find(
    sm => sm.trialMember.uid === drSignerTrialMemberUid,
  )
  const crcSignerMember = sessionMembers.find(
    sm => sm.trialMember.uid === crcSignerTrialMemberUid,
  )

  const isRejected = explanationRevisionStatus === 'AgreementRejected'

  const handleSubmit = async (members: SignerMembers) => {
    const requests = agreementFormDocRevUids.map(docRevUid =>
      request({
        explanationDocRevisionUid: docRevUid,
        drSignerMemberUid: members.dr.trialMember.uid,
        crcSignerMemberUid: hasCrcFieldMap.get(docRevUid)
          ? members.crc?.trialMember.uid
          : undefined,
      }),
    )
    await Promise.all(requests)
      .then(() => {
        toast({
          status: 'success',
          title: '署名者設定が完了しました',
        })
        onCloseConfirmModal()
        resetMembers()
        mutateSession()
      })
      .catch(error => {
        toast({
          status: 'error',
          title: error.message,
        })
      })
  }

  return (
    <Fragment>
      {waiting || !hasAgreementForms ? (
        <SignFlowBannerButton
          title={title}
          onClick={onOpenSelectModal}
          isDisabled={!hasAgreementForms}
          isGrayedOut={!hasAgreementForms}
        />
      ) : (
        <SignFlowBannerStatic
          title={title}
          isGrayedOut={isRejected}
          supplementaryElement={
            <Stack w="full">
              {[drSignerMember, crcSignerMember].filter(isNotNullish).map(m => (
                <HStack
                  color={isRejected ? 'white' : 'green.700'}
                  spacing="4"
                  fontSize="sm"
                >
                  <Text wordBreak="keep-all">{m.trialMember.role}</Text>
                  <Text
                    flex="1"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >{`[${
                    m.trialHospital.hospital.name
                  }] ${getFullName(m.trialMember.user)}`}</Text>
                </HStack>
              ))}
            </Stack>
          }
        />
      )}

      <SelectSignerModal
        isOpen={isSelectModalOpen}
        drMembers={drMembers}
        crcMembers={crcMembers}
        dr={dr}
        crc={crc}
        hasCrcField={Array.from(hasCrcFieldMap.values()).some(v => v === true)}
        patientUid={patientUid}
        candidateId={candidateId}
        fetchedPatientMobileNumber={fetchedPatientMobileNumber}
        mutateSession={mutateSession}
        onChangeDr={setDr}
        onChangeCrc={setCrc}
        onClose={() => {
          onCloseSelectModal()
          resetMembers()
        }}
        onSubmit={members => {
          setSignerMembers(members)
          onCloseSelectModal()
          onOpenConfirmModal()
        }}
      />
      {!!signerMembers && (
        <ConfirmSignerModal
          isOpen={isConfirmModalOpen}
          signerMembers={signerMembers}
          mobileNumber={fetchedPatientMobileNumber}
          candidateId={candidateId}
          onClose={() => {
            onCloseConfirmModal()
            onOpenSelectModal()
          }}
          onSubmit={handleSubmit}
        />
      )}
    </Fragment>
  )
}

type SelectSignerModalProps = {
  isOpen: boolean
  drMembers: SessionMemberFragment[]
  crcMembers: SessionMemberFragment[]
  dr: SessionMemberFragment | undefined
  crc: SessionMemberFragment | undefined
  hasCrcField: boolean
  patientUid: string
  candidateId: string
  fetchedPatientMobileNumber: string | undefined
  mutateSession: () => void
  onChangeDr: (dr: SessionMemberFragment) => void
  onChangeCrc: (crc: SessionMemberFragment) => void
  onClose: () => void
  onSubmit: (signerMembers: SignerMembers) => void
}
const SelectSignerModal: React.FC<SelectSignerModalProps> = ({
  isOpen,
  drMembers,
  crcMembers,
  dr,
  crc,
  hasCrcField,
  patientUid,
  candidateId,
  fetchedPatientMobileNumber,
  mutateSession,
  onChangeDr,
  onChangeCrc,
  onClose,
  onSubmit,
}) => {
  const membersByUid = new Map(
    [...drMembers, ...crcMembers].map(m => [m.trialMember.uid, m]),
  )

  const toast = useMirohaToast()

  const { request: updateMobileNumber } = useUpdateExpPatientMobileNumber({
    onSuccess: () => {
      mutateSession()
    },
  })

  const { request: sendTestSMS } = useSendTestSMS({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'テストSMSを送信しました',
      })
    },
  })

  const [mobileNumber, setMobileNumber] = useState(fetchedPatientMobileNumber)
  const [isMobileNumberEditing, setIsMobileNumberEditing] = useState(
    fetchedPatientMobileNumber === undefined,
  )

  const restMobileNumberState = () => {
    setMobileNumber(fetchedPatientMobileNumber)
    setIsMobileNumberEditing(fetchedPatientMobileNumber === undefined)
  }

  const canGoNext = !!dr && (!hasCrcField || !!crc) && !!mobileNumber

  const handleSubmit = async (signerMembers: SignerMembers) => {
    if (!mobileNumber) return

    if (fetchedPatientMobileNumber === mobileNumber) {
      onSubmit(signerMembers)
      restMobileNumberState()
      return
    }

    // 電話番号の変更があった場合はここで更新を行う
    const res = await updateMobileNumber({
      explanationPatientUid: patientUid,
      mobileNumber,
    })
    if (res.result === 'error') {
      return
    }
    onSubmit(signerMembers)
    restMobileNumberState()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        restMobileNumberState()
      }}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>署名者設定</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">同意書への署名者を一括で設定します。</Text>
          <Stack spacing="6">
            <Stack spacing="1.5" mt="8">
              <Text fontSize="sm" color="gray.600">
                候補ID
              </Text>
              <Text>{candidateId}</Text>
            </Stack>
            <Stack spacing="1.5">
              <HStack spacing="3">
                <Text fontSize="sm" color="gray.600">
                  患者携帯電話番号
                </Text>
                <Text fontSize="xs" color="gray.600">
                  ※署名時の多要素認証コード送信先
                </Text>
              </HStack>
              {isMobileNumberEditing ? (
                <HStack>
                  <NumberOnlyInput
                    w="300px"
                    value={mobileNumber}
                    onChange={setMobileNumber}
                    placeholder="例) 08011223344"
                  />
                  <Button
                    variant="text"
                    isDisabled={!mobileNumber}
                    onClick={async () => {
                      if (!mobileNumber) return
                      await sendTestSMS({
                        phoneNumber: mobileNumber,
                      })
                    }}
                  >
                    テスト送信
                  </Button>
                </HStack>
              ) : (
                <HStack spacing="2">
                  <Text>{fetchedPatientMobileNumber}</Text>
                  <IconButton
                    variant="customIconButtonGhost"
                    icon={<Edit />}
                    aria-label="電話番号を編集"
                    onClick={() => setIsMobileNumberEditing(true)}
                  />
                </HStack>
              )}
            </Stack>
          </Stack>
          <Stack spacing="6" mt="6">
            <Stack spacing="1.5">
              <FormLabel m="0">Dr</FormLabel>
              <Select
                width="100%"
                value={dr?.trialMember.uid ?? undefined}
                items={drMembers.map(m => ({
                  value: m.trialMember.uid,
                  label: (
                    <MemberLabel
                      role={m.trialMember.role}
                      displayName={getFullName(m.trialMember.user)}
                      isPartner={m.isPartner}
                    />
                  ),
                }))}
                onChange={uid => {
                  if (!uid) return
                  const dr = membersByUid.get(uid)
                  if (!dr) return
                  onChangeDr(dr)
                }}
              />
            </Stack>

            <Stack>
              <Stack spacing="1.5">
                <FormLabel m="0">CRC</FormLabel>
                <Select
                  width="100%"
                  value={crc?.trialMember.uid ?? undefined}
                  isDisabled={!hasCrcField || crcMembers.length === 0}
                  items={crcMembers.map(m => ({
                    value: m.trialMember.uid,
                    label: (
                      <MemberLabel
                        role={m.trialMember.role}
                        displayName={getFullName(m.trialMember.user)}
                        isPartner={m.isPartner}
                      />
                    ),
                  }))}
                  onChange={uid => {
                    if (!uid) return
                    const crc = membersByUid.get(uid)
                    if (!crc) return
                    onChangeCrc(crc)
                  }}
                />
              </Stack>
              {hasCrcField && !crc && (
                <WarningMessage
                  message={
                    crcMembers.length === 0
                      ? `同意書にCRC署名欄があるため署名者の設定が必要です。\n説明担当者設定にCRCを追加してください。`
                      : 'CRC署名欄が設定された同意書があります。CRCを設定してください。'
                  }
                />
              )}
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton />
          <Button
            variant="outline"
            colorScheme="green"
            isDisabled={!canGoNext}
            onClick={() => {
              if (!canGoNext) return
              handleSubmit({ dr, crc: crc ?? null })
            }}
          >
            設定確認へ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type ConfirmSignerModalProps = {
  isOpen: boolean
  signerMembers: SignerMembers
  candidateId: string
  mobileNumber: string | undefined // 当人人署が有効な場合必須
  onClose: () => void
  onSubmit: (members: SignerMembers) => void
}

const ConfirmSignerModal: React.FC<ConfirmSignerModalProps> = ({
  isOpen,
  signerMembers,
  candidateId,
  mobileNumber,
  onClose,
  onSubmit,
}) => {
  if (!mobileNumber) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>署名者設定確認</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">以下の情報で署名者を設定します。</Text>
          <Stack spacing="6" mt="6">
            <Stack spacing="1.5">
              <Text fontSize="sm" color="gray.600">
                患者
              </Text>
              <HStack spacing="8">
                <Text fontWeight="bold">{`候補ID: ${candidateId}`}</Text>
                <Text fontWeight="bold">{`携帯電話番号: ${mobileNumber}`}</Text>
              </HStack>
            </Stack>
            <MemberLabel
              role="Dr"
              displayName={getFullName(signerMembers.dr.trialMember.user)}
              isPartner={signerMembers.dr.isPartner}
            />
            {!!signerMembers.crc && (
              <MemberLabel
                role="CRC"
                displayName={getFullName(signerMembers.crc.trialMember.user)}
                isPartner={signerMembers.crc.isPartner}
              />
            )}
            <WarningMessage message="一度設定した署名者は変更できません。確定前に今一度ご確認ください。" />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="戻る" />
          <Button colorScheme="green" onClick={() => onSubmit(signerMembers)}>
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
