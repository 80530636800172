import React, { useState } from 'react'

import { SelectedTrial } from 'src/modules/entities/account/entity'
import { useRequestState } from 'src/modules/server/use-request-state'

import { AddModal } from './add-modal'
import { FormValues } from '../add-form'
import { submitLookupUser, LookupUser } from '../request'

type Props = {
  selectedTrial: SelectedTrial
  handlers: {
    onSubmit: (values: FormValues) => void
    onClose: () => void
  }
}

export const AddModalContainer: React.FC<Props> = props => {
  const { selectedTrial } = props

  const { request, requesting, errorMessage, user, lookedupEmail, lookupDone } =
    useLookupUser()

  const onClose = () => {
    if (requesting) return

    props.handlers.onClose()
  }

  return (
    <AddModal
      selectedTrial={selectedTrial}
      handlers={{
        onSubmit: props.handlers.onSubmit,
        onClose,
      }}
      user={user}
      email={lookedupEmail}
      lookup={request}
      lookupStatus={{
        requesting,
        errorMessage,
        lookupDone,
      }}
    ></AddModal>
  )
}

const useLookupUser = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const [user, setUser] = useState<LookupUser>()
  const [lookedupEmail, setLookedupEmail] = useState('')
  const [lookupDone, setLookupDone] = useState(false)

  const request = async ({ email }: { email: string }) => {
    try {
      requestStarted()
      const user = await submitLookupUser({ email })
      requestDone()
      setLookupDone(true)
      setLookedupEmail(user?.email ?? email)
      if (!!user) {
        setUser(user)
      }
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
    user,
    lookedupEmail,
    lookupDone,
  }
}
