import { ComponentProps, memo } from 'react'

import { Button, useDisclosure } from '@chakra-ui/react'
import { useSelectedTrial } from 'src/features/auth/context'
import { mutate } from 'swr'

import { useExplanation } from '../../api'
import { explanationCacheKey } from '../../api/cacheKey'
import { MembersSettingModal } from '../MembersSettingModal/MembersSettingModal'

type Props = {
  explanationSessionUid: string | undefined
  explanationUid: string
  mutateSession?: () => void
  isSignerSet: boolean
  defaultMembers: ComponentProps<typeof MembersSettingModal>['defaultMembers']
}

// NOTE: sessionのpollingに伴う再レンダリングによってSelectのスクロールがリセットされてしまう問題が発生するためmemo
export const MembersSettingButton: React.FC<Props> = memo(
  ({
    explanationSessionUid,
    explanationUid,
    mutateSession,
    isSignerSet,
    defaultMembers,
  }) => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { selectedTrial } = useSelectedTrial()

    const { data: explanation, mutate: mutateExplanation } = useExplanation({
      explanationUid,
      detailed: false,
    })
    // サイドバー等の表示に即座に反映されるようmutateする
    const mutateExpAndSession = () => {
      mutateExplanation()
      mutateSession?.()
      if (
        !selectedTrial.featureFlags.eConsentNewSignFlow &&
        !!explanationSessionUid
      ) {
        mutate(explanationCacheKey.getExplanationSession(explanationSessionUid))
      }
    }

    if (!explanation) {
      return null
    }

    return (
      <>
        <Button
          variant="outline"
          colorScheme="green"
          onClick={onOpen}
          isDisabled={
            (explanation.latestRevision.status !== 'SessionNotStarted' &&
              explanation.latestRevision.status !== 'SessionNotDone') ||
            isSignerSet
          }
        >
          説明担当者設定
        </Button>
        {/* 非表示時はunmountすることでstateが初期化され、最新に追随するようにする */}
        {isOpen && (
          <MembersSettingModal
            isOpen={true}
            submitButtonColor="green"
            onClose={onClose}
            explanation={explanation}
            explanationSessionUid={explanationSessionUid}
            defaultMembers={defaultMembers}
            mutate={mutateExpAndSession}
          />
        )}
      </>
    )
  },
)
