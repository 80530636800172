import { Box, HStack, Text, TextProps } from '@chakra-ui/react'

type Props = {
  children: React.ReactNode
} & TextProps

/** テキストの左側にバーを表示する 主にページ内の見出しで使用する */
export const TextWithBar: React.FC<Props> = ({ children, ...textProps }) => {
  return (
    <HStack>
      <Box
        as="span"
        h="20px"
        minW="4px"
        w="4px"
        borderRadius="full"
        bg="blue.300"
      />
      <Text {...textProps}>{children}</Text>
    </HStack>
  )
}
