import { Button } from 'src/components/base/button/button'
import { usePane } from 'src/hooks/use-pane'

import { AddContainer } from './components/add-container'

type Props = {}
export const AddButton: React.FC<Props> = () => {
  const {
    handlers: { openPane, closePane },
    renderPane,
  } = usePane({ paneType: 'normal', closeOnOutsideClick: true })

  return (
    <>
      <Button
        iconName="add"
        text={'追加'}
        size="S"
        buttonType="normal"
        onClick={openPane}
      />

      {renderPane(<AddContainer closePane={closePane} />)}
    </>
  )
}
