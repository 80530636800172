import { HttpResponse } from 'msw'
import {
  createEnablePatientPayloadMock,
  EnablePatientDocument,
  EnablePatientMutation,
  EnablePatientMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const enablePatientHandler = graphQLHandlerFactory<
  EnablePatientMutation,
  EnablePatientMutationVariables
>('mutation', EnablePatientDocument, () => {
  return HttpResponse.json({
    data: { enablePatient: createEnablePatientPayloadMock() },
  })
})
