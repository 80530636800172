import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { ExplanationRevision } from '../../types'

type Param = {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
  scheduledAt: string
  notifyToPatient: boolean
}

const updateSchedule = async ({
  trialUid,
  explanationRevisionUid,
  scheduledAt,
  notifyToPatient,
}: Param): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/schedules',
    httpMethod: 'put',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
      body: {
        scheduledAt,
        notifyToPatient,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useUpdateSchedule = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()

  return useMutation(
    ({
      explanationRevisionUid,
      scheduledAt,
      notifyToPatient,
    }: {
      explanationRevisionUid: ExplanationRevision['uid']
      scheduledAt: string
      notifyToPatient: boolean
    }) =>
      updateSchedule({
        trialUid: selectedTrial.uid,
        explanationRevisionUid,
        scheduledAt,
        notifyToPatient,
      }),
    option,
  )
}
