import _ from 'lodash'

import { QuestionnaireSection } from './entity'
import {
  QuestionnaireFieldResponse,
  parse as parseQuestionnaireField,
} from '../questionnaire-field/request'

export type QuestionnaireSectionResponse = {
  uid: string
  title: string
  index: number
  fields: QuestionnaireFieldResponse[]
}

export const parse = (
  res: QuestionnaireSectionResponse,
): QuestionnaireSection => {
  return {
    uid: res.uid,
    title: res.title,
    index: res.index,
    fieldMap: res.fields
      ? _.keyBy(res.fields.map(parseQuestionnaireField), 'uid')
      : {},
  }
}
