import {
  GqlError,
  GetSessionInSignFlowQuery,
  GetSessionInSignFlowDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type GetSessionInSignFlowRes = GetSessionInSignFlowQuery['explanationSession']

type ParticipantTrialMemberInput = {
  trialHospitalUid: string
  trialMemberUid: string
}

const getSessionInSignFlow = async ({
  explanationSessionUid,
  participantTrialMemberInputs,
}: {
  explanationSessionUid: string
  participantTrialMemberInputs: ParticipantTrialMemberInput[]
}): Promise<GetSessionInSignFlowRes> => {
  try {
    const res = await graphqlRequest(GetSessionInSignFlowDocument, {
      explanationSessionUid,
      participantTrialMemberInputs,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionInSignFlow = ({
  explanationSessionUid,
  participantTrialMemberInputs,
  ...option
}: {
  explanationSessionUid: string
  participantTrialMemberInputs: ParticipantTrialMemberInput[]
} & SWRConfiguration<GetSessionInSignFlowRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionInSignFlow(
      explanationSessionUid,
      participantTrialMemberInputs.map(p => p.trialMemberUid),
    ),
    () =>
      getSessionInSignFlow({
        explanationSessionUid,
        participantTrialMemberInputs,
      }),
    option,
  )
}
