import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { DisablePatientDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const disablePatient = async ({
  patientUid,
  reason,
}: {
  patientUid: string
  reason: string
}): Promise<void> => {
  try {
    await graphqlRequest(DisablePatientDocument, {
      input: {
        patientUid,
        reason,
      },
    })
  } catch (error) {
    throw error
  }
}

export const useDisablePatient = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    ({ patientUid, reason }: { patientUid: string; reason: string }) =>
      disablePatient({ patientUid, reason }),
    option,
  )
}
