import React from 'react'

import { Stack, Text } from '@chakra-ui/react'
import BackgroundImage from 'src/assets/image/epro-patient-background-list.png'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { Patient } from 'src/modules/entities/patient/entity'
import {
  QuestionnaireAnswer,
  QuestionnaireAnswerStatus,
} from 'src/modules/entities/questionnaire-answer/entity'
import { formatTimestamp } from 'src/modules/util/datetime-utils'
import styled from 'styled-components'

import { PulldownContainer } from './pulldown/pulldown-container'

type Props = {
  patientInfo: Pick<Patient, 'uid' | 'diseaseUid' | 'eproStatus'>
  questionnaireAnswers: QuestionnaireAnswer[]
  onClick: (questionnaireAnswer: QuestionnaireAnswer) => void
}

export const List: React.FC<Props> = ({
  patientInfo,
  questionnaireAnswers,
  onClick,
}) => {
  return (
    <Container>
      <Header>
        <Info>
          <Text fontSize="xl" color="white">
            ID（症例番号）: {patientInfo.diseaseUid}
          </Text>
          <Spacer size={16} horizontal />
          <PulldownContainer />
        </Info>
      </Header>
      <Stack alignItems="center">
        {/* TODO: 患者側デザイントークン定義時にサイズ置き換え */}
        <Text fontSize="3xl" color="white" fontWeight="bold">
          e-PRO
        </Text>
        {/* TODO: 患者側デザイントークン定義時にサイズ置き換え */}
        <Text fontSize="3xl" color="white" fontWeight="bold">
          オンライン治験システム
        </Text>
      </Stack>
      <Spacer size={40} />
      <ContentWrapper>
        {questionnaireAnswers.length === 0 ? (
          <Empty>
            <Text fontSize="2xl">現在回答する質問はございません</Text>
          </Empty>
        ) : (
          <ItemList>
            <Label>
              <Spacer size={48} />
              <Text fontSize="2xl">こちらの質問票にご回答ください</Text>
              <Spacer size={48} />
            </Label>

            {questionnaireAnswers.map(qa => {
              return (
                <Card key={qa.uid}>
                  <TextContainer>
                    <Text fontSize="xl" fontWeight="bold">
                      {qa.displayName}
                    </Text>

                    <Text>
                      回答期間:
                      {qa.shouldDeliverAfterTrial ? (
                        <>なし</>
                      ) : (
                        displayAnswerDuration(
                          qa.answerStartDate,
                          qa.answerEndDate,
                        )
                      )}
                    </Text>
                  </TextContainer>
                  {qa.status === QuestionnaireAnswerStatus.Unanswered && (
                    <Button
                      text={'回答する'}
                      size="M"
                      buttonType="important"
                      onClick={() => onClick(qa)}
                    />
                  )}
                  {qa.status === QuestionnaireAnswerStatus.Answered && (
                    <Button
                      text={'回答済み'}
                      size="M"
                      buttonType="normal"
                      disabled={true}
                    />
                  )}
                </Card>
              )
            })}
          </ItemList>
        )}
      </ContentWrapper>
    </Container>
  )
}

// Header componentでも使われるため、export化
export const displayAnswerDuration = (
  answerStartDate: string,
  answerEndDate: string,
): string => {
  if (answerStartDate === answerEndDate) {
    return ` ${formatTimestamp({
      timestamp: answerStartDate,
      onlyMonth: true,
    })} (0:00 〜 23:59)`
  }

  return ` ${formatTimestamp({
    timestamp: answerStartDate,
    onlyMonth: true,
  })} 00:00 〜 ${formatTimestamp({
    timestamp: answerEndDate,
    onlyMonth: true,
  })} 23:59 まで`
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 0 32px;
  background-color: ${blue[5]};
  background-image: url(${BackgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`

const Info = styled.div`
  display: flex;
  align-items: center;
`

const ContentWrapper = styled.div`
  width: 80%;
  height: 772px;
  padding: 0 40px;
  background-color: ${white};
  box-shadow: 0px 2px 20px rgba(187, 187, 187, 0.3);
  border-radius: 6px;
  overflow: scroll;
`

const Empty = styled.div`
  text-align: center;
  padding-top: 160px;
`

const ItemList = styled.div``

const Label = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #9ab8c1;
`

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px 0;
  border-bottom: 1px solid #9ab8c1;
`

const TextContainer = styled.div`
  width: 70%;
`
