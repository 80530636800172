import dayjs from 'dayjs'
import { HttpResponse } from 'msw'
import {
  createPatientVideoCallMock,
  createVideoCallRoomMock,
  GetPatientVideoCallsForHistoryDocument,
  GetPatientVideoCallsForHistoryQuery,
  GetPatientVideoCallsForHistoryQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { assertNever } from 'src/utils/assertNever'

const day1 = dayjs().subtract(5, 'day').set('hour', 12).set('minute', 0)
const day2 = day1.subtract(1, 'day')
const day3 = day2.subtract(1, 'day')

export const getPatientVideoCallsForHistoryHandler = graphQLHandlerFactory<
  GetPatientVideoCallsForHistoryQuery,
  GetPatientVideoCallsForHistoryQueryVariables
>('query', GetPatientVideoCallsForHistoryDocument, ({ variables }) => {
  const order = variables.order

  const call1 = createPatientVideoCallMock({
    patientVideoCallUid: 'call1',
    videoCallRoom: createVideoCallRoomMock({
      createdAt: day1.toISOString(),
      callStartedAt: day1.toISOString(),
      callEndedAt: day1.add(30, 'minute').toISOString(),
    }),
  })
  const call2 = createPatientVideoCallMock({
    patientVideoCallUid: 'call2',
    videoCallRoom: createVideoCallRoomMock({
      createdAt: day2.toISOString(),
      callStartedAt: day2.toISOString(),
      callEndedAt: day2.add(30, 'minute').toISOString(),
    }),
  })
  const call3 = createPatientVideoCallMock({
    patientVideoCallUid: 'call3',
    videoCallRoom: createVideoCallRoomMock({
      createdAt: day3.toISOString(),
      callStartedAt: day3.toISOString(),
      callEndedAt: day3.add(30, 'minute').toISOString(),
    }),
  })

  const videoCalls = (() => {
    switch (order) {
      case 'CreatedAtAsc':
        // 古い順
        return [call3, call2, call1]
      case 'CreatedAtDesc':
        // 新しい順
        return [call1, call2, call3]
      default:
        return assertNever(order)
    }
  })()
  return HttpResponse.json({
    data: {
      patient: {
        videoCalls,
      },
    },
  })
})
