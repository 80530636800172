import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Message } from 'src/components/base/message/message'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { ExplanationRevisionStatus } from '../../types'

type Props = {
  isOpen: boolean
  candidateId: string
  status: ExplanationRevisionStatus
  onClose: () => void
  onConfirm: () => void
}

export const ExplanationStartReAgreementConfirmModal: React.FC<Props> = ({
  isOpen,
  candidateId,
  status,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>再同意実施確認</ModalHeader>

        <ModalBody>
          <Text
            mb={4}
          >{`候補ID: ${candidateId} について再同意を開始しますか？`}</Text>
          {status !== 'AgreementDone' && (
            <Message type="warning">
              本説明は同意が完了していません。
              <br />
              再同意登録を実施すると新規の説明同意が開始されますが、よろしいですか？
            </Message>
          )}
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button variant="solid" onClick={onConfirm}>
            実施する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
