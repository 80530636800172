import React from 'react'

import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { AddIcfDocument } from './add'
import { useAddIcfDocument } from './add.hooks'

export const AddIcfDocumentContainer: React.FC = () => {
  const {
    createIcfDocumentItems,
    editing,
    onAddItem,
    onDeleteItem,
    onChangeDocumentType,
    onChangeName,
    onChangeVersion,
    onSelectFile,
    onResetFile,
    onChangeRequiredPatientSign,
    tempFileUrlMap,
    submitModalOpen,
    openSubmitModal,
    closeSubmitModal,
    errorMap,
    onValidate,
    shouldSelectHospital,
    hospitalName,
    listPath,
    onCancel,
    onSubmit,
  } = useAddIcfDocument()
  const pullDownState = useOpenPullDown()

  const MAX_DOCUMENT_COUNT = 20

  useBlockTransition({
    showAlert: editing,
  })

  return (
    <>
      <AddIcfDocument
        createIcfDocumentItems={createIcfDocumentItems}
        onAddItem={onAddItem}
        onDeleteItem={onDeleteItem}
        onChangeDocumentType={onChangeDocumentType}
        onChangeName={onChangeName}
        onChangeVersion={onChangeVersion}
        onSelectFile={onSelectFile}
        onResetFile={onResetFile}
        onChangeRequiredPatientSign={onChangeRequiredPatientSign}
        tempFileUrlMap={tempFileUrlMap}
        submitModalOpen={submitModalOpen}
        openSubmitModal={openSubmitModal}
        closeSubmitModal={closeSubmitModal}
        onValidate={onValidate}
        errorMap={errorMap}
        shouldSelectHospital={shouldSelectHospital}
        hospitalName={hospitalName}
        listPath={listPath}
        maxDocumentCount={MAX_DOCUMENT_COUNT}
        pullDownState={pullDownState}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  )
}
