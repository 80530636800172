import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Stack,
  HStack,
  Text,
  TableContainer,
  Table,
  Th,
  Tr,
  Thead,
  Tbody,
  Td,
  IconButton,
  Badge,
  Flex,
  Link,
} from '@chakra-ui/react'
import { Check, Down, Up } from 'src/components/__legacy__icon/monochrome'
import { useIcfDocumentSet } from 'src/features/icfDocument/api/getIcfDocumentSet'
import {
  IcfDocumentSet,
  IcfDocumentSetDetail,
  IcfDocumentType,
  IcfDocumentRevision,
} from 'src/features/icfDocument/types'
import { getFilePreviewUrl } from 'src/features/icfDocument/utils/getFilePreviewUrl'
import { icfDocumentTypeToText } from 'src/features/icfDocument/utils/icfDocumentTypeToText'
import { Trial, TrialHospital } from 'src/features/trial/types'

import { DisplayingExplanationDocSet } from '../../types'

type Props =
  | {
      /**選択中の場合 動的に内容が変化する */
      type: 'edit'
      trialUid: Trial['uid']
      trialHospitalUid: TrialHospital['uid']
      docSetsUids: IcfDocumentSet['uid'][]
      onUp: (uid: IcfDocumentSet['uid']) => void
      onDown: (uid: IcfDocumentSet['uid']) => void
    }
  | {
      /** 説明用文書セット内容がfixしている場合 */
      type: 'fixed'
      shouldShowUpdateAlert: boolean
      docSets: DisplayingExplanationDocSet[]
    }

const getDocUrl = (doc: IcfDocumentRevision) => {
  if (doc.type === 'Video') {
    const convertingQuery = `converting=1`
    const withVideoQuery = `video=${doc.fileUrl}`
    return `${import.meta.env.VITE_WEB_DOMAIN}/videos?${
      doc.fileUrl ? withVideoQuery : convertingQuery
    }`
  }
  return doc.fileUrl ?? ''
}

type SetToDisplay = {
  icfDocumentSetUid: IcfDocumentSetDetail['uid']
  numberingId: number
  name: string
  updatedAfterChecked?: boolean
  docs: {
    numberingId: number
    docSetIndex: number
    name: string
    version: string
    type: IcfDocumentType
    fileUrl: string
    isPatientConsentRequired: boolean
    disabled?: boolean
  }[]
}
const parseIcfDocSet = (
  docSet: IcfDocumentSetDetail,
  trialUid: Trial['uid'],
  trialHospitalUid: TrialHospital['uid'],
): SetToDisplay => ({
  icfDocumentSetUid: docSet.uid,
  numberingId: docSet.numberingId,
  name: docSet.name,
  docs: docSet.icfDocuments
    .map(doc => ({
      numberingId: doc.numberingId,
      docSetIndex: doc.docSetIndex,
      name: doc.latestRevision.name,
      version: doc.latestRevision.version,
      type: doc.latestRevision.type,
      fileUrl: getFilePreviewUrl({
        trialUid,
        trialHospitalUid,
        icfDocumentRevisionUid: doc.latestRevision.uid,
      }),
      isPatientConsentRequired: doc.latestRevision.isRequiredAgreement,
      disabled: doc.latestRevision.status === 'Disabled',
    }))
    .sort((a, b) => a.docSetIndex - b.docSetIndex),
})
const parseExpDocSet = (docSet: DisplayingExplanationDocSet): SetToDisplay => ({
  icfDocumentSetUid: docSet.icfDocSetUid,
  numberingId: docSet.icfDocSetNumberingId,
  name: docSet.currentName,
  updatedAfterChecked: docSet.updatedAfterChecked,
  docs: docSet.currentDocs
    .sort((a, b) => a.index - b.index)
    .map(doc => ({
      numberingId: doc.numberingId,
      docSetIndex: doc.index,
      name: doc.icfDocumentRevision.name,
      version: doc.icfDocumentRevision.version,
      type: doc.icfDocumentRevision.type,
      fileUrl: getDocUrl(doc.icfDocumentRevision),
      isPatientConsentRequired: doc.icfDocumentRevision.isRequiredAgreement,
    })),
})

export const DocSetAccordion: React.FC<Props> = props => {
  return (
    <Accordion allowMultiple w="full" maxW="832px">
      <Stack spacing="2">
        {props.type === 'edit'
          ? props.docSetsUids.map((docSetUid, index) => (
              <IcfDocSetAccordionItem
                key={docSetUid}
                icfDocumentSetUid={docSetUid}
                trialUid={props.trialUid}
                trialHospitalUid={props.trialHospitalUid}
                canUp={index !== 0}
                canDown={index !== props.docSetsUids.length - 1}
                onUp={props.onUp}
                onDown={props.onDown}
              />
            ))
          : props.docSets.map(docSet => (
              <DocSetAccordionItem
                key={docSet.icfDocSetUid}
                set={parseExpDocSet(docSet)}
                shouldShowUpdateAlert={props.shouldShowUpdateAlert}
              />
            ))}
      </Stack>
    </Accordion>
  )
}

const IcfDocSetAccordionItem: React.FC<{
  icfDocumentSetUid: IcfDocumentSet['uid']
  trialUid: Trial['uid']
  trialHospitalUid: TrialHospital['uid']
  canUp: boolean
  canDown: boolean
  onUp: (uid: IcfDocumentSet['uid']) => void
  onDown: (uid: IcfDocumentSet['uid']) => void
}> = ({
  icfDocumentSetUid,
  trialUid,
  trialHospitalUid,
  canUp,
  canDown,
  onUp,
  onDown,
}) => {
  const { data: icfDocSet } = useIcfDocumentSet({
    trialHospitalUid,
    icfDocumentSetUid,
    revalidateOnFocus: false,
  })

  if (!icfDocSet) return null

  return (
    <HStack w="full" spacing="2">
      <DocSetAccordionItem
        set={parseIcfDocSet(icfDocSet, trialUid, trialHospitalUid)}
      />
      <Stack spacing="1">
        <IconButton
          variant="unstyled"
          h="4"
          minW="unset"
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
          icon={<Up />}
          aria-label="上へ"
          onClick={() => {
            onUp(icfDocSet.uid)
          }}
          isDisabled={!canUp}
        />
        <IconButton
          variant="unstyled"
          h="4"
          minW="unset"
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
          icon={<Down />}
          aria-label="下へ"
          onClick={() => {
            onDown(icfDocSet.uid)
          }}
          isDisabled={!canDown}
        />
      </Stack>
    </HStack>
  )
}

const DocSetAccordionItem: React.FC<{
  set: SetToDisplay
  shouldShowUpdateAlert?: boolean
}> = ({ set, shouldShowUpdateAlert }) => {
  return (
    <AccordionItem
      borderWidth="1px"
      borderColor="gray.300"
      borderRadius="base"
      w="full"
    >
      <h2>
        <AccordionButton
          border="none"
          display="flex"
          justifyContent="space-between"
          px="5"
          py="2.5"
          borderRadius="base"
        >
          <HStack spacing="2" display="inline" textAlign="left">
            <Text
              as="span"
              fontWeight="bold"
              color={
                set.updatedAfterChecked && shouldShowUpdateAlert
                  ? 'green.500'
                  : 'gray.600'
              }
            >{`${set.numberingId}.`}</Text>
            <Text
              as="span"
              color={
                set.updatedAfterChecked && shouldShowUpdateAlert
                  ? 'green.500'
                  : 'gray.800'
              }
            >
              {set.name}
            </Text>
          </HStack>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>ステータス</Th>
                <Th>文書ID</Th>
                <Th>文書名</Th>
                <Th>版数</Th>
                <Th>文書の種類</Th>
              </Tr>
            </Thead>
            <Tbody>
              {set.docs.map(doc => (
                <Tr key={doc.numberingId}>
                  <Td>
                    {/* TODO: Badge部分は共通コンポーネント化する（文書管理のデザインをリプレイスしていくタイミング） */}
                    <Badge
                      w="80px"
                      h="25px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color={doc.disabled ? 'gray.400' : 'green.600'}
                      bg={doc.disabled ? 'gray.100' : 'green.100'}
                      fontWeight="normal"
                    >
                      <HStack spacing="0.5">
                        {!doc.disabled && <Check />}
                        <Text as="span">{doc.disabled ? '無効' : '承認'}</Text>
                      </HStack>
                    </Badge>
                  </Td>
                  <Td>{doc.numberingId}</Td>
                  <Td>
                    <Flex alignItems="center" gap="2">
                      <Link isExternal color="blue.500" href={doc.fileUrl}>
                        {doc.name}
                        {/* TODO: external link icon */}
                      </Link>
                      {doc.isPatientConsentRequired && (
                        <Badge bg="blue.400" color="white">
                          同意必須
                        </Badge>
                      )}
                    </Flex>
                  </Td>
                  <Td>{doc.version}</Td>
                  <Td>{icfDocumentTypeToText(doc.type)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </AccordionPanel>
    </AccordionItem>
  )
}
