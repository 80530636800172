import { parse as parseTemplate } from 'src/modules/entities/template/request'
import { parse as worksheetParse } from 'src/modules/entities/worksheet/request'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { PatientPDFData } from './entity'
import { fixture } from './fixture'
import {
  WorksheetPDFDataResponse,
  patientParse,
  smallItemAnswerLogParse,
  parseVideoCall,
} from '../../worksheet/common/detail/output/pdf/request'
import { parse as parseMediumItemAnswerLog } from '../../worksheet/common/medium-item-answer-log/request'

export type PatientPDFDataResponse = WorksheetPDFDataResponse[]

export const parse = (resp: PatientPDFDataResponse): PatientPDFData => {
  return {
    worksheetPDFDataArr: resp.map(r => {
      return {
        uid: r.uid,
        trialName: r.trial_name,
        worksheet: worksheetParse(r.worksheet),
        worksheetLog: r.worksheetLog,
        patient: patientParse(r.patient),
        smallItemAnswerLogs: r.small_item_answer_logs.map(sial =>
          smallItemAnswerLogParse(sial),
        ),
        videoCalls: r.video_calls.map(parseVideoCall),
        loglineDisableLogs: r.logline_disable_logs.map(
          parseMediumItemAnswerLog,
        ),
        template: parseTemplate(r.template),
        templateFolderName: r.folder_name,
      }
    }),
  }
}

export const fetch = async ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}): Promise<PatientPDFData> => {
  if (IS_MOCK_MODE) {
    return fixture
  }
  const res = await requestGet<WorksheetPDFDataResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/pdf_data`,
    params: {},
  })

  return parse(res.data)
}
