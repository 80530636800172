import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { gray } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { MediumItemAnswer } from 'src/modules/entities/medium-item-answer/entity'
import { Role } from 'src/modules/entities/member/entity'
import { ItemType } from 'src/modules/entities/small-item/entity'
import { getSingle } from 'src/modules/entities/template/selector'
import { Worksheet } from 'src/modules/entities/worksheet/entity'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { SmallItemAnswerComponent } from '../../../../patient/detail/worksheet/common/detail/small-item/small-item-answer'

type Props = {
  worksheet: Worksheet
  onChangeLoglineUid: (uid: string | null) => void
}

export const PreviewWorksheet: React.FC<Props> = props => {
  const { worksheet, onChangeLoglineUid } = props
  const modalContentHeight = `${window.innerHeight - 206}px`
  const template = useSelector((state: RootState) =>
    getSingle(state, worksheet.templateUid),
  )

  return (
    <Container style={{ maxHeight: modalContentHeight }}>
      <SectionsContainer>
        {template.mediumItems.map(mi => {
          const mediumItemAnswer = worksheet.mediumItemAnswers.find(
            mia => mia.mediumItemUid === mi.uid,
          )
          if (!mediumItemAnswer) return null

          return mi.isLogline ? (
            <LoglineSection
              key={mi.uid}
              mediumItem={mi}
              worksheet={worksheet}
              onChangeLoglineUid={onChangeLoglineUid}
            />
          ) : (
            <SingleSection key={mi.uid} mediumItemAnswer={mediumItemAnswer} />
          )
        })}
      </SectionsContainer>
    </Container>
  )
}

type SingleSectionProps = {
  mediumItemAnswer: MediumItemAnswer
}

const SingleSection: React.FC<SingleSectionProps> = props => {
  const { mediumItemAnswer } = props

  const smallItemAnswers = Object.values(mediumItemAnswer.smallItemAnswerMap)
    .filter(sia => sia.parentUid === null)
    .sort((a, b) => a.index - b.index)

  return (
    <SectionContainer>
      <Text fontWeight="bold">{mediumItemAnswer.title}</Text>

      {smallItemAnswers.map(smallItemAnswer => (
        <QuestionContainer key={smallItemAnswer.uid}>
          <SmallItemAnswerComponent
            smallItemAnswer={smallItemAnswer}
            smallItemAnswerMap={mediumItemAnswer.smallItemAnswerMap}
            isPreview={true}
            currentRole={Role.Dr}
          />
        </QuestionContainer>
      ))}
    </SectionContainer>
  )
}

type LoglineSectionProps = {
  mediumItem: MediumItem
  worksheet: Worksheet
  onChangeLoglineUid: (uid: string | null) => void
}

const LoglineSection: React.FC<LoglineSectionProps> = props => {
  const { mediumItem, worksheet, onChangeLoglineUid } = props

  const onClickAddButton = () => {
    const loglines = worksheet.mediumItemAnswers.filter(
      mia => mia.mediumItemUid === mediumItem.uid,
    )
    const lastLogline = loglines.find(l => l.lineNumber === loglines.length)
    if (!lastLogline) return

    //実際のワークシートの挙動と同様に最新行に遷移するように見せる
    onChangeLoglineUid(lastLogline.uid)
  }

  const rootSmallItems = Object.values(mediumItem.smallItemMap)
    .filter(si => si.parentUid === null)
    .sort((a, b) => a.index - b.index)
  const maxShowableItemSize = 4
  const needEllipsis = (index: number) => {
    if (rootSmallItems.length <= maxShowableItemSize) {
      return false
    }

    return index + 1 === maxShowableItemSize
  }

  return (
    <SectionContainer>
      <Text fontWeight="bold">{mediumItem.title}</Text>

      <AddButton>
        <Button
          size="S"
          text="行の追加"
          onClick={onClickAddButton}
          buttonType="normal"
        ></Button>
      </AddButton>

      <Table>
        <TableHead>
          <LineNumber>#</LineNumber>

          <ItemsContainer>
            {rootSmallItems.slice(0, maxShowableItemSize).map((si, i) => (
              <HeadItem key={si.uid}>
                {si.itemType !== ItemType.Label && (
                  <Text fontWeight="bold">{si.title}</Text>
                )}
                {needEllipsis(i) && <Ellipsis />}
              </HeadItem>
            ))}
          </ItemsContainer>

          <StatusHead>ステータス</StatusHead>
        </TableHead>

        <WhiteRow>
          <Spacer size={36} horizontal />
          <Text fontSize="sm" color={gray[55]}>
            行の追加をしてください。
          </Text>
        </WhiteRow>
      </Table>
    </SectionContainer>
  )
}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const SectionsContainer = styled.div``

const SectionContainer = styled.div`
  margin-top: 50px;
`

const QuestionContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;

  .log-link {
    pointer-events: none;
  }
`

const AddButton = styled.div`
  margin: 10px 0 15px;
  text-align: right;
`

const Table = styled.div`
  border: 1px solid ${gray[40]};
  border-radius: 10px;
`

const TableHead = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 16px;
  border-bottom: 1px solid ${gray[40]};
`

const LineNumber = styled.div`
  width: 36px;
  display: flex;
  align-items: center;
`

const ItemsContainer = styled.div`
  display: flex;
  flex: 1;
`

const HeadItem = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.8rem;
`

const Ellipsis = styled.div`
  padding-left: 20px;
  flex: 1;

  &::after {
    content: '...';
  }
`

const StatusHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 0 12px;
  font-size: 0.8rem;
  font-weight: bold;
`

const WhiteRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  min-height: 30px;
  margin: 10px;
  border-bottom: 1px dashed ${gray[40]};
`
