import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Stack,
  DrawerCloseButton,
  Text,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { sortByDate } from 'src/modules/util/sort'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { WorksheetDetail } from '../../types'
import { worksheetActionToText } from '../../utils/actionToText'

type Props = {
  worksheet: WorksheetDetail
  isOpen: boolean
  isFollowUp: boolean
  onClose: () => void
}
export const WorksheetHistoryDrawer: React.FC<Props> = ({
  worksheet,
  isOpen,
  isFollowUp,
  onClose,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>操作履歴</DrawerHeader>
        <DrawerBody>
          <Stack spacing="3">
            <TextWithBar as="h2">
              {isFollowUp ? (
                <HStack>
                  <Text
                    as="span"
                    fontWeight="bold"
                  >{`#${worksheet.index} ${worksheet.name}`}</Text>
                  <Text
                    as="span"
                    fontSize="sm"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    {worksheet.schema.name}
                  </Text>
                </HStack>
              ) : (
                <Text>{worksheet.name}</Text>
              )}
            </TextWithBar>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>日時</Th>
                    <Th>操作者</Th>
                    <Th>操作</Th>
                    <Th minW="240px">備考</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortByDate(worksheet.worksheetLogs, 'savedAt', 'desc').map(
                    log => (
                      <Tr key={log.uid}>
                        <Td>
                          {formatDate(log.savedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                        </Td>
                        <Td maxW="240px">
                          <MemberLabel
                            role={log.trialMember.role}
                            displayName={getFullName(log.trialMember)}
                          />
                        </Td>
                        <Td>{worksheetActionToText(log.action)}</Td>
                        <Td wordBreak="break-word" whiteSpace="pre-line">
                          {log.reason}
                        </Td>
                      </Tr>
                    ),
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
