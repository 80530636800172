import { Box, Button } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useExplanationForPatient } from 'src/features/explanation/api'

import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { usePatientLobby } from '../../hooks/usePatientLobby'
import { RedirectToPatientSignIn } from '../RedirectToPatientSignIn/RedirectToPatientSignIn'
import { SPExplanationVideoCall } from '../SPExplanationVideoCall/SPExplanationVideoCall'
import { SPPatientLobbyLayout } from '../SPPatientLobbyLayout/SPPatientLobbyLayout'

type Param = {
  trialUid: string
  explanationRevisionUid: string
}

export const SPExplanationLobby: React.FC = () => {
  const { trialUid = '', explanationRevisionUid = '' } = useParams<Param>()

  const { preSession, revisionAbout, isUnauthorized, moveToRoom } =
    usePatientLobby({ trialUid, explanationRevisionUid })

  const { data: explanation } = useExplanationForPatient({
    explanationRevisionUid: preSession
      ? preSession.explanationRevision.uid
      : null,
  })

  if (isUnauthorized) {
    return (
      <RedirectToPatientSignIn
        trialUid={trialUid}
        explanationRevisionUid={explanationRevisionUid}
      />
    )
  }

  if (!preSession || !revisionAbout || !explanation) return null

  return (
    <ExplanationRoomEventProvider
      roomType="PreSession"
      preSessionUid={preSession.uid}
      trialUid={trialUid}
      explanationRevisionUid={explanationRevisionUid}
      explanationPatientUid={explanation.patient.uid}
      patientPhoneNumber={explanation.patient.phoneNumber}
      deviceActorType="Patient"
      deviceActorUid={explanation.patient.uid}
      deviceActorTrialHospitalUid={explanation.trialHospital.uid}
    >
      <SPPatientLobbyLayout headerTitle="説明ロビー" about={revisionAbout}>
        <SPExplanationVideoCall
          roomUid={preSession.videoCallRoomUid}
          candidateId={revisionAbout.candidateID}
        />
        <Box
          height="full"
          bg="green.50"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            colorScheme="green"
            onClick={() => {
              if (!preSession.explanationSessionUid) return
              moveToRoom(preSession.explanationSessionUid)
            }}
            isDisabled={!preSession.explanationSessionUid}
          >
            説明ルームへ
          </Button>
        </Box>
      </SPPatientLobbyLayout>
    </ExplanationRoomEventProvider>
  )
}
