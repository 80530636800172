import { generateUUID } from 'src/utils/generateUUID'

export type QuestionnaireChoice = {
  uid: string
  index: number
  description: string
  value: string
}

export const createEmptyQuestionnaireChoice = (
  index: number,
): QuestionnaireChoice => ({
  uid: generateUUID(),
  index: index,
  description: '',
  value: '',
})
