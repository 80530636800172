import useSWR, { SWRConfiguration } from 'swr'

import { Release } from '../../types'

const getReleases = async (): Promise<Release[]> => {
  try {
    const res = await fetch('/release-note/index.json', {
      headers: {
        'cache-control': 'no-cache',
      },
    })
    const releases = JSON.parse(await res.text()) as Release[]
    return releases
  } catch (error) {
    throw new Error(error)
  }
}

export const useReleases = (options?: SWRConfiguration<Release[], Error>) => {
  return useSWR<Release[], Error>('releases', getReleases, options)
}
