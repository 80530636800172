import { SectionConfirmationStatus } from 'src/modules/entities/medium-item-answer/entity'

export enum MediumItemAnswerLogAction {
  Confirm = 1,
  Save,
  Enable,
  Disable,
  EnableWithWorksheet,
  DisableWithWorksheet,
}

export type MediumItemAnswerLog = {
  uid: string
  mediumItemAnswerUid: string
  userName: string
  action: MediumItemAnswerLogAction
  confirmationStatus: SectionConfirmationStatus
  message: string
  reason: string
  createdAt: string
}
