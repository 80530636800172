import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import { Schedule } from 'src/modules/entities/schedule/entity'
import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { fixtures } from './fixture'

export type QuestionnaireAnswerResponse = {
  questionnaires: Questionnaire[]
  schedules: Schedule[]
  questionnaireAnswers: QuestionnaireAnswer[]
}

export const fetchAnswers = async (
  trialUid: string,
  patientUid: string,
): Promise<QuestionnaireAnswerResponse> => {
  if (IS_MOCK_MODE) {
    return fixtures
  }

  const resp = await requestGet<QuestionnaireAnswerResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/questionnaire_answers`,
  })

  return resp.data
}
