import React from 'react'

import { Text } from '@chakra-ui/react'

type Props = {
  title: string
}

/**
 * @deprecated Use chakra-ui ModalTitle instead.
 */
export const ModalTitle: React.FC<Props> = ({ title }) => {
  return (
    <Text fontSize="lg" fontWeight="bold" textAlign="center">
      {title}
    </Text>
  )
}
