import {
  Input,
  IconButton,
  Stack,
  useDisclosure,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { Calendar } from '../__legacy__icon/monochrome'
import { CalendarComponent } from '../form-redesigned/calendar'

type Props = {
  value: Date | undefined
  onChange: (date: Date) => void
  isDisabled?: boolean
}

export const CalendarDateInput: React.FC<Props> = ({
  value: date,
  onChange,
  isDisabled = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Stack display="inline-block" pos="relative" spacing="2">
      <Flex align="center" w="full">
        <Flex align="center" mr="4">
          <Input
            w="72px"
            mr="1"
            value={date ? date.getFullYear() : ''}
            isReadOnly
          />
          <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
            年
          </Text>
          <Input
            w="52px"
            mr="1"
            value={date ? date.getMonth() + 1 : ''}
            isReadOnly
          />
          <Text as="span" fontSize="sm" fontWeight="bold" mr="3">
            月
          </Text>
          <Input
            w="52px"
            mr="1"
            value={date ? date.getDate() : ''}
            isReadOnly
          />
          <Text as="span" fontSize="sm" fontWeight="bold">
            日
          </Text>
        </Flex>
        {!isDisabled && (
          <IconButton
            variant="customIconButtonGhost"
            colorScheme="blue"
            aria-label="calendar icon"
            icon={<Calendar color={colors.blue[500]} />}
            onClick={onOpen}
            pos="relative"
          />
        )}
      </Flex>
      {isOpen && (
        <Box pos="absolute" right="0" zIndex="1">
          <CalendarComponent onChange={onChange} onClose={onClose} />
        </Box>
      )}
    </Stack>
  )
}
