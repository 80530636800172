import { HttpResponse } from 'msw'
import {
  createSyncEnvelopePayloadMock,
  SyncEnvelopeDocument,
  SyncEnvelopeMutation,
  SyncEnvelopeMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const syncEnvelopeHandler = graphQLHandlerFactory<
  SyncEnvelopeMutation,
  SyncEnvelopeMutationVariables
>('mutation', SyncEnvelopeDocument, () => {
  return HttpResponse.json({
    data: {
      syncEnvelope: createSyncEnvelopePayloadMock(),
    },
  })
})
