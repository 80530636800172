import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'src/components/base/button/button'
import { gray, white } from 'src/components/base/color/palette'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Spacer } from 'src/components/spacer/spacer'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import { PERMISSIONS } from 'src/lib/permission'
import {
  CardNav,
  CardNavItem,
  NavItem,
} from 'src/modules/dashboard/trial/detail/card-nav'
import { Container as EnableButtonContainer } from 'src/modules/dashboard/trial/detail/patient/enable/container'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { Patient, PatientStatus } from 'src/modules/entities/patient/entity'
import { Template, TemplateStatus } from 'src/modules/entities/template/entity'
import { getList as getTemplates } from 'src/modules/entities/template/selector'
import { TemplateFolder } from 'src/modules/entities/template-folder/entity'
import { nonPresetSelector as getTemplateFolders } from 'src/modules/entities/template-folder/selector'
import {
  Worksheet,
  WorksheetStatus,
} from 'src/modules/entities/worksheet/entity'
import { getList as getWorksheets } from 'src/modules/entities/worksheet/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import styled from 'styled-components'

import { OutputModalContainer } from './output/modal-container'
import { TemplateSelectModal } from './worksheet/common/list/template-select-modal'
import { useSubmitCreate } from './worksheet/common/list/use-submit-create'
import { getEmptyRoute } from './worksheet/routes'
import { usePermission } from '../../common/permission'
import { EditButtonContainer } from '../edit/components/container'
import { actions as patientEditActions } from '../edit/redux'

type Props = {
  patient: Patient
  backUrl: string
  worksheetUid: string
}

const toNavItem = (worksheet: Worksheet): NavItem => {
  return {
    type: 'worksheet',
    path: `worksheet/${worksheet.uid}`,
    name: worksheet.title,
    disabled: worksheet.status === WorksheetStatus.Disabled,
  }
}

const useOnAddWorksheet = ({ worksheets }: { worksheets: Worksheet[] }) => {
  const [templateSelectModalOpen, setTemplateSelectModalOpen] = useState(false)
  const [templateFolder, setTemplateFolder] = useState<TemplateFolder | null>(
    null,
  )
  const [worksheetMakeableTemplates, setWorksheetMakeableTemplates] = useState<
    Template[]
  >([])
  const templates = useSelector(getTemplates)
  const usedTemplateUids = worksheets.map(w => w.templateUid)

  const { request: submitCreate } = useSubmitCreate()
  const { showError } = useFlash()

  const navigate = useNavigate()

  const onAddWorksheet = useCallback(
    async ({
      templateFolder,
      trialUid,
      patientUid,
    }: {
      templateFolder: TemplateFolder
      trialUid: string
      patientUid: string
    }) => {
      const releasedTemplates = templates.filter(
        t =>
          t.templateFolderUid === templateFolder.uid &&
          t.status === TemplateStatus.Released,
      )

      if (releasedTemplates.length === 0) {
        navigate(
          getEmptyRoute({
            trialUid,
            patientUid,
          }),
        )

        return
      }

      //公開テンプレートが1つだけで、かつワークシート未作成の場合はそのテンプレートをもとに即座に作成する
      if (
        releasedTemplates.length === 1 &&
        !usedTemplateUids.includes(releasedTemplates[0].uid)
      ) {
        try {
          await submitCreate({
            trialUid,
            patientUid,
            templateUids: [releasedTemplates[0].uid],
          })

          return
        } catch (error) {
          showError(error.message)

          throw error
        }
      }

      setTemplateSelectModalOpen(true)
      setTemplateFolder(templateFolder)

      //公開テンプレートが複数ある場合はワークシートが未作成のテンプレートをindexの昇順でモーダルに表示する。
      //未作成のワークシートが1つ、またはない場合もモーダルを表示する
      setWorksheetMakeableTemplates(
        releasedTemplates
          .filter(t => !usedTemplateUids.includes(t.uid))
          .sort((a, b) => a.index - b.index),
      )
    },
    [templates, usedTemplateUids, navigate, submitCreate, showError],
  )

  const closeTemplateSelectModal = useCallback(() => {
    setTemplateSelectModalOpen(false)
    setTemplateFolder(null)
  }, [])

  return {
    templateSelectModalOpen,
    templateFolder,
    onAddWorksheet,
    closeTemplateSelectModal,
    worksheetMakeableTemplates,
  }
}

export const Head: React.FC<Props> = ({ patient, backUrl, worksheetUid }) => {
  const [outputModalOpen, setOutputModalOpen] = useState(false)

  const templateFolders = useSelector(getTemplateFolders)
  const worksheets = useSelector(getWorksheets).filter(
    w => w.patientUid === patient.uid,
  )
  const {
    templateSelectModalOpen,
    templateFolder,
    onAddWorksheet,
    closeTemplateSelectModal,
    worksheetMakeableTemplates,
  } = useOnAddWorksheet({ worksheets })

  const { hasPermission } = usePermission()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const activeWorksheetUid =
    pathname.split('/')[
      pathname.split('/').findIndex(p => p === 'worksheet') + 1
    ]
  const worksheet = worksheets.find(w => w.uid === activeWorksheetUid)
  const activeTemplateFolderUid = worksheet?.templateFolderUid ?? null

  const cardNavItem: CardNavItem[] = templateFolders.map(tf => ({
    templateFolderUid: tf.uid,
    title: tf.name,
    deletable: false,
    navItems: worksheets
      .filter(w => w.templateFolderUid === tf.uid)
      .sort((a, b) => a.index - b.index)
      .map(toNavItem),
    onAdd: () =>
      onAddWorksheet({
        templateFolder: tf,
        trialUid: trial.uid,
        patientUid: patient.uid,
      }),
    addText: '新規作成',
  }))

  const breadcrumbParams: BreadcrumbParam[] = [
    { label: '患者一覧', isLink: true, path: backUrl },
    { label: '患者情報', isLink: false },
  ]

  const isDisabledPatient = patient.status === PatientStatus.Disable
  const trial = useSelector(getSelectedTrial)!

  const hasClinicalSmallItem = worksheets.some(w => w.hasClinicalSmallItem)
  const edcLinked = hasClinicalSmallItem && trial.edcIntegrationEnabled

  const onClickOutputButton = () => {
    dispatch(patientEditActions.showModal({}))
    setOutputModalOpen(true)
  }

  useCloseModalByDeps({
    condition: templateSelectModalOpen === true || outputModalOpen === true,
    onClose:
      outputModalOpen === true
        ? () => setOutputModalOpen(false)
        : () => closeTemplateSelectModal(),
    deps: [worksheetUid],
  })

  const textColorByStatus = (disabled: boolean) => {
    return disabled ? gray[55] : gray[100]
  }

  const v2 = trial.featureFlags.eSourceV2

  return (
    <>
      <PageContainer>
        <ContentContainer>
          <TopContainer>
            <PatientContainer>
              <Breadcrumb breadcrumbParams={breadcrumbParams} />

              <DiseaseUid>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={textColorByStatus(
                    patient.status === PatientStatus.Disable,
                  )}
                >
                  {patient.diseaseUid}
                </Text>
                <Spacer size={10} horizontal />
                {hasPermission(PERMISSIONS.Patient_Edit) &&
                  !isDisabledPatient && (
                    <EditButtonContainer
                      trialUid={trial.uid}
                      patient={patient}
                      edcLinked={edcLinked}
                      worksheetUid={worksheetUid}
                    />
                  )}
              </DiseaseUid>

              <Name>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={textColorByStatus(
                    patient.status === PatientStatus.Disable,
                  )}
                >
                  {patient.lastName} {patient.firstName}
                </Text>
              </Name>
            </PatientContainer>

            <BtnContainer>
              {isDisabledPatient &&
                hasPermission(PERMISSIONS.Patient_Enable) && (
                  <EnableButtonContainer
                    diseaseUid={patient.diseaseUid}
                    patientUid={patient.uid}
                    trialUid={trial.uid}
                    worksheetUid={worksheetUid}
                  />
                )}

              {trial?.pdfOutputEnabled &&
                hasPermission(PERMISSIONS.Patient_Download) && (
                  <div>
                    <Spacer size={10} />
                    <Button
                      size="S"
                      text="データ出力"
                      onClick={onClickOutputButton}
                      buttonType="normal"
                      disabled={!worksheets.length}
                      //TODO: Add iconName
                    ></Button>
                  </div>
                )}
            </BtnContainer>
          </TopContainer>

          <CardNavContainer>
            <CardNav
              v2={v2}
              items={cardNavItem}
              canAdd={
                hasPermission(PERMISSIONS.Worksheet_Create) &&
                !isDisabledPatient
              }
              activeTemplateFolderUid={activeTemplateFolderUid}
              templateFolderEditable={false}
            />
          </CardNavContainer>
        </ContentContainer>
      </PageContainer>

      {outputModalOpen && (
        <OutputModalContainer
          patientUid={patient.uid}
          diseaseUid={`${patient.diseaseUid}`}
          onClose={() => setOutputModalOpen(false)}
        />
      )}

      {templateSelectModalOpen && !!templateFolder && (
        <TemplateSelectModal
          templateFolder={templateFolder}
          onClose={closeTemplateSelectModal}
          templates={worksheetMakeableTemplates}
        />
      )}
    </>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${white};
`

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  overflow-y: auto;
`

const TopContainer = styled.div`
  padding: 32px 32px 0;
  max-height: 60%;
`

const PatientContainer = styled.div``

const BtnContainer = styled.div`
  display: block;
  padding: 12px 0;
`

const Name = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  word-break: break-all;
`

const DiseaseUid = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`

const CardNavContainer = styled.div`
  padding: 12px;
  margin: 0 12px 16px 0;
  overflow-y: scroll;
`
