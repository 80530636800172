import { QuestionnaireField, AnswerType } from './entity'

export const createFixture = (
  values?: Partial<QuestionnaireField>,
): QuestionnaireField => {
  const res: QuestionnaireField = {
    uid: 'QuestionnaireFieldUid',
    title: 'フィールドタイトル',
    answerType: AnswerType.CheckBox,
    index: 0,
    unit: '単位',
    choices: [],
  }

  if (values) {
    Object.keys(values).forEach(key => {
      const k = key as keyof QuestionnaireField
      ;(res[k] as any) = values[k] as any
    })
  }

  return res
}

export const fixture: QuestionnaireField = {
  uid: 'QuestionnaireFieldUid',
  title: 'フィールドタイトル',
  answerType: AnswerType.CheckBox,
  index: 0,
  unit: '単位',
  choices: [],
}
