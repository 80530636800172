import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'
import { useFlash } from 'src/modules/flash/use-flash'

import { useConfirmCrf } from './use-confirm-crf'
import { getState } from '../selector'

type Props = {
  trialUid: string
  onComplete: () => void
  onClickPrev: () => void
}

export const ConfirmComponent: React.FC<Props> = props => {
  const { trialUid, onComplete, onClickPrev } = props
  const { request, requesting, errorMessage } = useConfirmCrf()
  const { folderTemplates } = useSelector(getState)
  const { showSuccess } = useFlash()

  const onConfirm = async () => {
    if (!folderTemplates) return

    try {
      await request({
        trialUid,
        crfFolderTemplateUid: folderTemplates.crfFolderTemplateUid,
      })

      onComplete()
      showSuccess('CRFをeSourceへ反映しました。')
    } catch {
      /* empty */
    }
  }

  return (
    <Modal onClose={onClickPrev} closable={!requesting}>
      <ModalTitle title="反映確認" />

      <ModalContent>
        <Text textAlign="center">CRFをMiROHA eSourceへ反映しますか？</Text>
        <Spacer size={10} />
        <Text fontSize="xs" textAlign="center">
          *既存のeSourceフォルダ、テンプレートはすべてクリアされます。
        </Text>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="戻る"
          onClick={onClickPrev}
          buttonType="normal"
          disabled={requesting}
        />
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="確定"
          onClick={onConfirm}
          buttonType="important"
          disabled={requesting}
        />
      </ModalActions>

      {errorMessage && (
        <div>
          <Spacer size={8} />
          <Message type="error" message={errorMessage} centered />
        </div>
      )}
    </Modal>
  )
}
