import produce, { enableMapSet } from 'immer'
import {
  Worksheet,
  WorksheetStatus,
} from 'src/modules/entities/worksheet/entity'
import { actions as flashActions } from 'src/modules/flash/redux'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import asyncFactory from 'typescript-fsa-redux-thunk'

import { UpdateSmallItemAnswersParam } from './save-section/save-section-modal/save-section-modal'
import { toParams, updateSmallItemAnswersBulk } from './small-item/request'
import {
  MediumItemAnswer,
  MediumItemAnswerStatus,
} from '../../../../../../../../entities/medium-item-answer/entity'
import { SmallItemAnswer } from '../../../../../../../../entities/small-item-answer/entity'
import { equal } from '../../../../../../../../entities/small-item-answer/util'

enableMapSet()
const create = actionCreatorFactory('worksheetDetail')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  // Worksheet
  initWithWorksheet: create<{
    worksheet: Worksheet
    currentLoglineUid?: string
  }>('initWithWorksheet'),
  reset: create<{}>('reset'),
  updateSmallItemAnswer: create<{
    smallItemAnswerUid: string
    value: string
  }>('updateSmallItemAnswer'),
  updateChoiceAnswer: create<{
    smallItemAnswerUid: string
    selectedChoiceAnswerUids: string[]
  }>('updateChoiceAnswer'),
  addLogline: create<{ logline: MediumItemAnswer }>('addLogline'),
  startVideoCall: create('startVideoCall'),

  submitUpdateSmallItemAnswers: createAsync<
    {
      trialUid: string
      originalMediumItemAnswer: MediumItemAnswer
      mediumItemAnswer: MediumItemAnswer
      updateSmallItemAnswersParam: UpdateSmallItemAnswersParam
    },
    { smallItemAnswers: SmallItemAnswer[] },
    { message: string }
  >('SUBMIT_UPDATE_SMALL_ITEM_ANSWERS', async (params, dispatch, getState) => {
    const {
      mediumItemAnswer,
      originalMediumItemAnswer,
      updateSmallItemAnswersParam,
    } = params
    const worksheet = getState().worksheetDetail.worksheet!

    const updateParams = updateSmallItemAnswersParam.map(param => {
      const smallItemAnswer =
        mediumItemAnswer.smallItemAnswerMap[param.smallItemAnswerUid]
      const selectedChoiceAnswerUids = smallItemAnswer.choiceAnswers
        .filter(ca => ca.checked)
        .map(ca => ca.uid)

      return toParams({
        smallItemAnswer:
          originalMediumItemAnswer.smallItemAnswerMap[smallItemAnswer.uid],
        selectedChoiceAnswerUids: selectedChoiceAnswerUids,
        smallItemAnswerMap: originalMediumItemAnswer.smallItemAnswerMap,
        value: smallItemAnswer.value ?? '',
        reason: param.reason,
      })
    })

    try {
      const updatedAnswers = await updateSmallItemAnswersBulk({
        trialUid: params.trialUid,
        patientUid: worksheet.patientUid,
        worksheetUid: worksheet.uid,
        mediumItemAnswerUid: mediumItemAnswer.uid,
        params: updateParams,
      })

      dispatch(flashActions.showSuccess({ message: '一時保存しました。' }))

      return { smallItemAnswers: updatedAnswers }
    } catch (error) {
      dispatch(flashActions.showError({ message: error.message }))
      throw error
    }
  }),
}

export type State = {
  // initWithWorksheet を呼び出すことで entities 以下の worksheet をコピーする
  // 編集中の worksheet の値と比較するために利用する
  originalWorksheet: Worksheet | null
  // ワークシート編集画面での編集中のワークシートの値を保持する
  // initWithWorksheet を呼び出すことで entities 以下の worksheet をコピーする
  worksheet: Worksheet | null

  submitting: boolean

  //変更中のセクションの値はサーバーの値と同期せずに保持するという処理を行うため、変更中のセクションのuidを保持する
  editingMediumItemAnswerUidSet: Set<MediumItemAnswer['uid']>

  // オリジナルと差分があるフィールドの uid を保持する
  editingSmallItemAnswerUidSet: Set<SmallItemAnswer['uid']>

  errorMessage: string
}

const initialState: State = {
  originalWorksheet: null,
  worksheet: null,

  submitting: false,
  editingMediumItemAnswerUidSet: new Set(),
  editingSmallItemAnswerUidSet: new Set(),
  errorMessage: '',
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.initWithWorksheet, (state, payload) =>
    produce(state, draft => {
      const { worksheet: originalWorksheet, currentLoglineUid } = payload
      const { worksheet: writableWorksheet } = draft
      const currentLogline = originalWorksheet.mediumItemAnswers.find(
        mia => mia.uid === currentLoglineUid,
      )

      draft.originalWorksheet = originalWorksheet

      // initWithWorksheet は以下の場合に呼ばれる。例えば以下の場合
      // - ワークシート画面・ログライン画面への遷移時
      // - ワークシート・セクションの状態の変更をサーバーに送った時（一時保存、確定、有効化、無効化時、ログライン行追加
      // ワークシート・ログライン画面への遷移時、ワークシート切替時、ワークシート・ログライン無効化時には詳細画面の状態を初期化する
      const changed = writableWorksheet?.uid !== originalWorksheet.uid
      const disabled = originalWorksheet.status === WorksheetStatus.Disabled
      const loglineDisabled =
        currentLogline?.status === MediumItemAnswerStatus.Disabled
      if (!writableWorksheet || changed || disabled || loglineDisabled) {
        draft.worksheet = originalWorksheet
        draft.editingMediumItemAnswerUidSet = new Set()
        draft.editingSmallItemAnswerUidSet = new Set()
        return
      }

      // currentLoglineUid（編集中のログライン）が指定されている場合
      if (!!currentLoglineUid) {
        // フィールドとセクションの編集状態を変更する
        originalWorksheet?.mediumItemAnswers.forEach(mia => {
          if (mia.uid === currentLoglineUid) return

          Object.values(mia.smallItemAnswerMap).forEach(sia => {
            // 編集中のログラインに紐づくフィールドの編集状態は変更しない
            draft.editingSmallItemAnswerUidSet.delete(sia.uid)
          })
          // セクション・ログラインが編集状態であることは変更しない
          draft.editingMediumItemAnswerUidSet.delete(mia.uid)
        })
      }

      // 編集中のセクションは値を破棄せずそのままにし、それ以外は DB に保存されているワークシートの値と一致させる
      const editingMediumItemAnswers =
        writableWorksheet.mediumItemAnswers.filter(mia =>
          draft.editingMediumItemAnswerUidSet.has(mia.uid),
        )
      const originalMediumItemAnswers =
        originalWorksheet.mediumItemAnswers.filter(
          mia => !draft.editingMediumItemAnswerUidSet.has(mia.uid),
        )
      const mediumItemAnswers = [
        ...editingMediumItemAnswers,
        ...originalMediumItemAnswers,
      ]

      draft.worksheet = {
        ...originalWorksheet,
        mediumItemAnswers,
      }
    }),
  )
  .case(actions.updateSmallItemAnswer, (state, payload) =>
    produce(state, draft => {
      const { worksheet, originalWorksheet } = draft
      if (!worksheet || !originalWorksheet) return

      const { smallItemAnswerUid } = payload

      const mediumItemAnswer = worksheet.mediumItemAnswers.find(mia =>
        Object.keys(mia.smallItemAnswerMap).includes(smallItemAnswerUid),
      )
      if (!mediumItemAnswer) return

      const originalMediumItemAnswer = originalWorksheet.mediumItemAnswers.find(
        mia => Object.keys(mia.smallItemAnswerMap).includes(smallItemAnswerUid),
      )
      if (!originalMediumItemAnswer) return

      //所属するセクションを編集中のセクションとして保持する。
      draft.editingMediumItemAnswerUidSet.add(mediumItemAnswer.uid)

      const smallItemAnswer =
        mediumItemAnswer.smallItemAnswerMap[smallItemAnswerUid]
      if (!smallItemAnswer) return

      const originalSmallItemAnswer =
        originalMediumItemAnswer.smallItemAnswerMap[smallItemAnswerUid]
      if (!originalSmallItemAnswer) return

      smallItemAnswer.value = payload.value

      if (equal(smallItemAnswer, originalSmallItemAnswer)) {
        draft.editingSmallItemAnswerUidSet.delete(smallItemAnswer.uid)
      } else {
        draft.editingSmallItemAnswerUidSet.add(smallItemAnswer.uid)
      }
    }),
  )
  .case(actions.updateChoiceAnswer, (state, payload) =>
    produce(state, draft => {
      const { worksheet, originalWorksheet } = draft
      if (!worksheet || !originalWorksheet) return

      const { smallItemAnswerUid, selectedChoiceAnswerUids } = payload

      const mediumItemAnswer = worksheet.mediumItemAnswers.find(mia =>
        Object.keys(mia.smallItemAnswerMap).includes(smallItemAnswerUid),
      )
      if (!mediumItemAnswer) return

      const originalMediumItemAnswer = originalWorksheet.mediumItemAnswers.find(
        mia => Object.keys(mia.smallItemAnswerMap).includes(smallItemAnswerUid),
      )
      if (!originalMediumItemAnswer) return

      //所属するセクションを編集中のセクションとして保持する。
      draft.editingMediumItemAnswerUidSet.add(mediumItemAnswer.uid)

      const smallItemAnswer =
        mediumItemAnswer.smallItemAnswerMap[smallItemAnswerUid]
      if (!smallItemAnswer) return

      const originalSmallItemAnswer =
        originalMediumItemAnswer.smallItemAnswerMap[smallItemAnswerUid]
      if (!originalSmallItemAnswer) return

      smallItemAnswer.choiceAnswers.forEach(ca => {
        ca.checked = selectedChoiceAnswerUids.includes(ca.uid)
      })

      if (equal(smallItemAnswer, originalSmallItemAnswer)) {
        draft.editingSmallItemAnswerUidSet.delete(smallItemAnswer.uid)
      } else {
        draft.editingSmallItemAnswerUidSet.add(smallItemAnswer.uid)
      }
    }),
  )
  .case(actions.addLogline, (state, payload) =>
    produce(state, draft => {
      const { worksheet } = draft
      if (!worksheet) return

      const { logline } = payload
      worksheet.mediumItemAnswers.push(logline)
    }),
  )
  .case(actions.startVideoCall, state =>
    produce(state, draft => {
      const { worksheet } = draft
      if (!worksheet) return

      if (worksheet.status === WorksheetStatus.New) {
        worksheet.status = WorksheetStatus.Saved
      }
    }),
  )

  .case(actions.submitUpdateSmallItemAnswers.async.started, (state, payload) =>
    produce(state, draft => {
      draft.submitting = true
      draft.errorMessage = ''
    }),
  )
  .case(actions.submitUpdateSmallItemAnswers.async.done, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false

      const { mediumItemAnswer } = payload.params
      const { worksheet } = draft
      if (!worksheet) return

      if (worksheet.status === WorksheetStatus.New) {
        worksheet.status = WorksheetStatus.Saved
      }

      const writableMediumItemAnswer = worksheet.mediumItemAnswers.find(
        mia => mia.uid === mediumItemAnswer.uid,
      )
      if (!writableMediumItemAnswer) return

      //紐づくセクションを編集中のセクション set から外し、original の状態と同期されるようにする
      draft.editingMediumItemAnswerUidSet.delete(mediumItemAnswer.uid)

      writableMediumItemAnswer.status = MediumItemAnswerStatus.Saved
      writableMediumItemAnswer.confirmationStatus = null

      if (!writableMediumItemAnswer.hasLog) {
        writableMediumItemAnswer.hasLog = true
      }

      payload.params.updateSmallItemAnswersParam.forEach(sia => {
        draft.editingSmallItemAnswerUidSet.delete(sia.smallItemAnswerUid)
      })
    }),
  )
  .case(actions.submitUpdateSmallItemAnswers.async.failed, (state, payload) =>
    produce(state, draft => {
      draft.submitting = false
      draft.errorMessage = payload.error.message
    }),
  )
  .case(actions.reset, state =>
    produce(state, draft => {
      draft.worksheet = null
      draft.editingMediumItemAnswerUidSet.clear()
      draft.editingSmallItemAnswerUidSet.clear()
    }),
  )
  .build()
