import React from 'react'

import styled from 'styled-components'

type Props = {
  children: React.ReactNode
}

/**
 * @deprecated Use chakra-ui ModalContent instead.
 */
export const ModalContent: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.div`
  padding: 40px 0;
`
