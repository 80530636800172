import { Stack } from '@chakra-ui/react'
import { Field, FileTypeDef } from '@micin-jp/chicken-schema'
import {
  FileListComponent,
  FileUploadComponent,
} from 'src/features/misc/components'
import { SavedFileItem } from 'src/features/misc/utils/fileUploadUtils'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { FileFieldValue } from '../../types'
import { findSection } from '../../utils/findSection'
import { generateFileViewerPath } from '../../utils/generateFileViewerPath'
import { getFlattenFieldMap } from '../../utils/getFlattenFields'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  fileField: Field & {
    typeDef: FileTypeDef
  }
  index: number
}

const buildDefaultFileItems = (
  fileFieldValue: FileFieldValue | undefined,
): SavedFileItem[] => {
  if (!fileFieldValue) {
    return []
  }
  return fileFieldValue.files.map(file => ({
    isNew: false,
    uid: file.uploadedFile.uid,
    fileName: file.uploadedFile.name,
    fileUrl: file.uploadedFile.url,
    fileSize: file.uploadedFile.size,
    fileExtension: file.uploadedFile.extension,
    savedAt: file.uploadedFile.savedAt,
  }))
}

export const WorksheetFileField: React.FC<Props> = ({ fileField, index }) => {
  const {
    fetchedWorksheet,
    findFieldValue,
    onChangeFileFieldValue,
    canEdit,
    onChangeFileMemo,
    isChanged,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: fileField.fid,
    index,
  })

  const fields = getFlattenFieldMap(fetchedWorksheet.schema.fields)

  const parentSection = findSection(fields, fileField.fid)

  const isDisabled =
    !canEdit || !isRepeatableSectionEnabled(index, fileField.fid)

  const defaultFileItems: SavedFileItem[] =
    value?.type === 'file' ? buildDefaultFileItems(value) : []

  return (
    <WorksheetFieldFormControl
      field={fileField}
      index={index}
      isDisabled={isDisabled}
      // ファイルがアップロードされている状態のみアイコンを表示
      showCertifiedCopyIcon={
        fileField.typeDef.isCertifiedCopy &&
        value?.type === 'file' &&
        value.files.length > 0
      }
    >
      <Stack mt="2" spacing="2" w="full">
        {!isDisabled && (
          <FileUploadComponent
            defaultFileItems={defaultFileItems}
            isCertifiedCopy={fileField.typeDef.isCertifiedCopy}
            onUploaded={fileItems => {
              onChangeFileFieldValue({
                fid: fileField.fid,
                index,
                uploadedFiles: fileItems.map(fileItem => ({
                  uid: fileItem.uid,
                  name: fileItem.fileName,
                  url: fileItem.fileUrl,
                  size: fileItem.fileSize,
                  extension: fileItem.fileExtension,
                  savedAt: fileItem.savedAt,
                })),
              })
            }}
          />
        )}
        {value?.type === 'file' && value.files.length > 0 && (
          <FileListComponent
            isEditing={isChanged} // ワークシート全体が編集中の場合は編集中
            canEdit={!isDisabled}
            fileItems={value.files.map(file => ({
              fileUid: file.uploadedFile.uid,
              fileName: file.uploadedFile.name,
              fileUrl: file.uploadedFile.url,
              fileExtension: file.uploadedFile.extension,
              order: file.order,
              memo: file.memo,
            }))}
            isRepeatableSectionChild={
              parentSection?.typeDef.type === 'RepeatableSection'
            }
            onChangeMemo={(fileUid, memo) => {
              onChangeFileMemo({
                fid: fileField.fid,
                index,
                fileUid,
                memo,
              })
            }}
            fileViewerPath={order =>
              generateFileViewerPath({
                worksheetUid: fetchedWorksheet.uid,
                fileFieldValueUid: value.uid,
                selectedFileOrder: order,
              })
            }
          />
        )}
      </Stack>
    </WorksheetFieldFormControl>
  )
}
