import { useEffect, useState } from 'react'

import { Auth } from 'aws-amplify'
import { generatePath } from 'react-router-dom'
import { Paths } from 'src/constants/paths'

import { useAccount } from '../api/getAccount'
import { Account } from '../types'

type UseAuthReturn =
  | {
      state: 'Challenging'
      account: undefined
    }
  | {
      state: 'Authenticated'
      account: Account
    }
  | {
      state: 'Failed'
      account: undefined
    }

export const useAuth = (): UseAuthReturn => {
  const [cognitoSessionState, setCognitoSessionState] = useState<{
    checked: boolean
    hasSession: boolean
  }>({
    checked: false,
    hasSession: false,
  })

  const {
    data: account,
    error,
    isLoading,
  } = useAccount({
    shouldCancel: !cognitoSessionState.hasSession,
  })

  useEffect(() => {
    Auth.currentSession()
      .then(() => setCognitoSessionState({ checked: true, hasSession: true }))
      .catch(() => setCognitoSessionState({ checked: true, hasSession: false }))
  }, [])

  // ログイン画面に遷移したら必ずSignOutしてaccountのcacheも削除する(cognitoのsessionがなければaccountのfetchがskipされる)
  useEffect(() => {
    if (location.pathname === generatePath(Paths.Login)) {
      Auth.signOut()
      setCognitoSessionState(state => ({
        ...state,
        hasSession: false,
      }))
    }
  }, [])

  if (isLoading || !cognitoSessionState.checked) {
    return { state: 'Challenging', account: undefined }
  }
  if (cognitoSessionState.hasSession && !error && !!account) {
    return { state: 'Authenticated', account }
  }
  if (!!error || !cognitoSessionState.hasSession) {
    return { state: 'Failed', account: undefined }
  }

  return { state: 'Challenging', account: undefined }
}
