import { UseMutationOption, useMutation } from 'src/hooks/use-mutation'
import { createApiClient, RequestResponse } from 'src/lib/api-client'

type VerifyPin = Extract<
  RequestResponse<
    '/explanation_revision_pin/trials/{trial_uid}/explanation_sessions/{explanation_session_uid}/verify_pin',
    'post'
  >,
  { result: 'success' }
>['data']

const verifyPin = async ({
  trialUid,
  explanationSessionUid: explanationRevisionUid,
  pin,
}: {
  trialUid: string
  explanationSessionUid: string
  pin: string
}): Promise<VerifyPin> => {
  const res = await createApiClient({
    path: '/explanation_revision_pin/trials/{trial_uid}/explanation_sessions/{explanation_session_uid}/verify_pin',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_session_uid: explanationRevisionUid,
      },
      body: {
        pin: pin,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useVerifyPin = (options?: UseMutationOption<VerifyPin>) => {
  return useMutation(
    ({
      pin,
      trialUid,
      explanationSessionUid,
    }: {
      pin: string
      trialUid: string
      explanationSessionUid: string
    }) =>
      verifyPin({
        trialUid,
        explanationSessionUid,
        pin,
      }),
    options,
  )
}
