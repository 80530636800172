import OutsideClickHandler from 'react-outside-click-handler'
import { Close } from 'src/components/__legacy__icon/monochrome'
import { gray, white } from 'src/components/base/color/palette'
import { zIndex } from 'src/modules/util/z-index'
import styled from 'styled-components'

type ModalSize = 'S' | 'M' | 'L'

type Props = {
  onClose: () => void
  closable?: boolean
  size?: ModalSize
  children: React.ReactNode
}

/**
 * @deprecated Use chakra-ui Modal instead.
 */
export const Modal: React.FC<Props> = ({
  onClose,
  closable = true,
  size,
  children,
}) => {
  return (
    <BlurredBackground>
      <OutsideClickHandler onOutsideClick={onClose} disabled={!closable}>
        <ContentContainer size={size ?? 'M'}>
          <Content>
            {closable && (
              <DeleteButton onClick={onClose}>
                <Close color={gray[80]} />
              </DeleteButton>
            )}
            <Inner>{children}</Inner>
          </Content>
        </ContentContainer>
      </OutsideClickHandler>
    </BlurredBackground>
  )
}

const BlurredBackground = styled.div`
  position: fixed;
  background: rgba(83, 83, 83, 0.5); //gray[10] with 50% opacity
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.modalBackground};
`

const maxWidthBySize = (size: ModalSize) => {
  switch (size) {
    case 'S':
      return '400px'
    case 'M':
      return '600px'
    case 'L':
      return '800px'
  }
}

const ContentContainer = styled.div<{ size: ModalSize }>`
  background: ${white};
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-height: calc(100vh - 100px);
  max-width: ${props => maxWidthBySize(props.size)};
  border-radius: 10px;
  overflow-y: scroll;
`

const SCROLL_BAR_WIDTH = 6

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px ${40 - SCROLL_BAR_WIDTH}px 40px 40px;
  box-sizing: border-box;
`

const DeleteButton = styled.button`
  border: unset;
  outline: unset;
  background-color: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
`
