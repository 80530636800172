export enum Gender {
  Male = 1,
  Female,
  Unspecified,
}

export enum PatientStatus {
  Disable = 0,
  Enable,
}

export enum PatientEProStatus {
  Invalid = 0,
  Unspecified = 1,
  BeforeTrial,
  InTrial,
  TrialCompleted,
  TrialDiscontinued,
}

export type Patient = {
  uid: string
  diseaseUid: string
  hospitalUid: string | null
  hospitalName: string
  firstName: string
  firstNameJa: string
  lastName: string
  lastNameJa: string
  gender: Gender
  birthday: string
  phoneNumber: string | null
  email: string | null
  status: PatientStatus
  eproStatus: PatientEProStatus
  isPasswordIssued: boolean

  chartUid: string
  ratio: string

  firstVisitDate: string | null
  answerStartDate: string | null

  createdAt: string
  updatedAt: string
}

export type PatientList = {
  patients: Patient[]
  totalCount: number
}
