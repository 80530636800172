import { createApiClient, RequestResponse } from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationCacheKey } from '../cacheKey'

export type ChallengeSignAuthV2Res = Extract<
  RequestResponse<
    '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/challenge_v2',
    'post'
  >,
  { result: 'success' }
>['data']

const challengeSignAuthV2 = async ({
  trialUid,
  explanationSignAuthenticationUid,
}: {
  trialUid: string
  explanationSignAuthenticationUid: string
}): Promise<ChallengeSignAuthV2Res> => {
  const res = await createApiClient({
    path: '/explanation_sign_authentication/trials/{trial_uid}/{explanation_sign_authentication_uid}/challenge_v2',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_sign_authentication_uid: explanationSignAuthenticationUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }

  return res.data
}

export const useChallengeSignAuthV2 = ({
  shouldCancel,
  trialUid,
  explanationSignAuthenticationUid,
  ...option
}: {
  shouldCancel?: boolean
  trialUid: string
  explanationSignAuthenticationUid: string
} & SWRConfiguration<ChallengeSignAuthV2Res, Error>) => {
  return useSWR(
    shouldCancel
      ? null
      : explanationCacheKey.challengeSignAuthV2(
          explanationSignAuthenticationUid,
        ),
    () =>
      challengeSignAuthV2({
        trialUid,
        explanationSignAuthenticationUid,
      }),
    option,
  )
}
