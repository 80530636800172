import React, { useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { useExplanationDocRevision } from 'src/features/explanation/api/getDocRevision'
import { useExplanationDocRevisionFileUrl } from 'src/features/explanation/api/getDocRevisionFileUrl'
import {
  ExplanationDocRevision,
  ExplanationSession,
} from 'src/features/explanation/types'
import { browseDocRevision } from 'src/features/explanationRoom/api'
import {
  locationPaths,
  ExplanationRoomEventProvider,
} from 'src/features/explanationRoom/context/ExplanationRoomEvent'
import { useFloatingVideoCallForPatient } from 'src/features/explanationRoom/hooks/useFloatingVideoCallForPatient'
import { Trial } from 'src/features/trial/types'

import { ExplanationRoomDocumentRenewal } from './document-renewal'
import { UID } from '../../../../../../../utils/brandedUid'
import { ExplanationRoomLayoutRenewal } from '../layout-renewal'
import { usePatientSession } from '../patient-session.hooks'
import { getExplanationRoomTopRoute } from '../routes'

type Param = {
  sessionUid: ExplanationSession['uid']
  trialUid: Trial['uid']
  docRevisionUid: ExplanationDocRevision['uid']
}

export const PatientDocumentContainer: React.FC = () => {
  const {
    sessionUid = '' as UID<'ExplanationSession'>,
    trialUid = '' as UID<'Trial'>,
    docRevisionUid = '' as UID<'ExplanationDocRevision'>,
  } = useParams<Param>()
  const [browsingRecorded, setBrowsingRecorded] = useState(false)
  const [previousSignHistoryCount, setPreviousSignHistoryCount] = useState(0)

  const { session, mutateSession } = usePatientSession({
    explanationSessionUid: sessionUid,
  })

  const { data: fileUrl, mutate: mutateFileUrl } =
    useExplanationDocRevisionFileUrl({
      explanationDocRevisionUid: docRevisionUid,
      revalidateOnFocus: false,
    })

  const { data: docRevision } = useExplanationDocRevision({
    explanationDocRevisionUid: docRevisionUid,
    forPatient: true,
    refreshInterval: previous => {
      if (
        previous?.type !== 'AgreementForm' &&
        previous?.type !== 'CheckUnderstanding'
      ) {
        return 0
      }
      if (previous.isCompleted) {
        return 0
      }
      // 同意書or理解度確認かつ未完了の場合はpolling
      return 3000
    },
    onSuccess: async docRevision => {
      if (
        docRevision.type === 'AgreementForm' &&
        docRevision.signHistories.length !== previousSignHistoryCount
      ) {
        mutateFileUrl()
        setPreviousSignHistoryCount(docRevision.signHistories.length)
      }
      if (
        docRevision.type === 'CheckUnderstanding' &&
        docRevision.isCompleted
      ) {
        mutateFileUrl()
      }
      // 正常にデータ取得できたタイミングで（一回のmountingにつき）一度だけ閲覧履歴の記録を行う
      if (browsingRecorded) return

      await browseDocRevision({
        explanationDocRevisionUid: docRevision.uid,
      })
      mutateSession()
      setBrowsingRecorded(true)
    },
  })

  const navigate = useNavigate()

  const navigateToRoomTop = () => {
    navigate(
      getExplanationRoomTopRoute({
        type: 'patient',
        trialUid,
        sessionUid,
      }),
    )
  }

  const { renderFloatingVideoCall } = useFloatingVideoCallForPatient({
    sessionUid,
    isOldFlow: true,
  })

  if (!docRevision || !session || !fileUrl) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={session.explanationRevision.explanation.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={session.explanationRevision.uid}
      sessionUid={sessionUid}
      explanationPatientUid={
        session.explanationRevision.explanation.patient.uid
      }
      patientPhoneNumber={undefined} // 旧署名フローでは不要
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={
        session.explanationRevision.explanation.patient.trialHospitalUid
      }
    >
      <ExplanationRoomLayoutRenewal
        headerTitle={docRevision.icfDocumentRevisionName}
        session={session}
        sidebarContent={null}
        isPatient={true}
      >
        <ExplanationRoomDocumentRenewal
          forPatient={true}
          session={session}
          locationPath={locationPaths.document(docRevisionUid)}
          docRevision={docRevision}
          trialHospitalUid={
            session.explanationRevision.explanation.patient.trialHospitalUid
          }
          explanationPatientUid={
            session.explanationRevision.explanation.patient.uid
          }
          fileUrl={fileUrl}
          navigateToRoomTop={navigateToRoomTop}
        />
        {renderFloatingVideoCall()}
      </ExplanationRoomLayoutRenewal>
    </ExplanationRoomEventProvider>
  )
}
