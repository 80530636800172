// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | number, // NOTE: イレギュラーサイズはそのまま数値を渡すようにする
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
/** @deprecated src/components/iconに定義されている新しいアイコンに置き換えます。
 *
 * 特段の理由がない場合、新規実装でこちらは使用しないでください。 */
const SvgSort = ({ size = 'S', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    height={
      size === 'XS'
        ? '8px'
        : size === 'S'
        ? '16px'
        : size === 'M'
        ? '24px'
        : size === 'L'
        ? '32px'
        : size === 'XL'
        ? '40px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeWidth={2}
      d="M11.5 15V3"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M14.293 5.207a1 1 0 1 0 1.414-1.414l-1.414 1.414ZM11.5 1l.707-.707a1 1 0 0 0-1.414 0L11.5 1ZM7.293 3.793a1 1 0 0 0 1.414 1.414L7.293 3.793Zm8.414 0-3.5-3.5-1.414 1.414 3.5 3.5 1.414-1.414Zm-4.914-3.5-3.5 3.5 1.414 1.414 3.5-3.5L10.793.293Z"
    />
    <path
      stroke={props.color ?? 'currentColor'}
      strokeLinecap="round"
      strokeWidth={2}
      d="M4.5 1v12"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M1.707 10.793a1 1 0 0 0-1.414 1.414l1.414-1.414ZM4.5 15l-.707.707a1 1 0 0 0 1.414 0L4.5 15Zm4.207-2.793a1 1 0 1 0-1.414-1.414l1.414 1.414Zm-8.414 0 3.5 3.5 1.414-1.414-3.5-3.5-1.414 1.414Zm4.914 3.5 3.5-3.5-1.414-1.414-3.5 3.5 1.414 1.414Z"
    />
  </svg>
)
export default SvgSort
