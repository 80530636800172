import React from 'react'

import { useLogin } from 'src/modules/auth/login/hooks/use-login'

import { Login } from './login'

export const LoginContainer: React.FC<{}> = () => {
  const { errorMessage, requesting, onSubmit } = useLogin()

  return (
    <Login
      errorMessage={errorMessage}
      requesting={requesting}
      onSubmit={onSubmit}
    />
  )
}
