import { Patient } from 'src/modules/entities/patient/entity'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'
import {
  fixtures,
  fixture,
} from 'src/modules/entities/questionnaire-answer/fixture'
import { SERVER_URL } from 'src/modules/server/const'
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { fixture as patientInfoFixture } from './fixture'

export const logout = async (trialUid: string): Promise<void> => {
  await requestDelete<void>({
    url: `${SERVER_URL}/epro_patient_user/trials/${trialUid}/logout`,
  })
}

type PatientInfoResponse = {
  uid: string
  disease_uid: string
  epro_status: number
}

export const fetchMe = async (
  path: string,
): Promise<Pick<Patient, 'uid' | 'diseaseUid' | 'eproStatus'>> => {
  if (IS_MOCK_MODE) {
    return patientInfoFixture
  }

  const res = await requestGet<PatientInfoResponse>({
    url: `${SERVER_URL}${path}`,
  })

  return {
    uid: res.data.uid,
    diseaseUid: res.data.disease_uid,
    eproStatus: res.data.epro_status,
  }
}

type QuestionnaireAnswerResponse = {
  data: QuestionnaireAnswer[]
}

export const fetchList = async (
  path: string,
): Promise<QuestionnaireAnswer[]> => {
  if (IS_MOCK_MODE) {
    return fixtures
  }

  const res = await requestGet<QuestionnaireAnswerResponse>({
    url: `${SERVER_URL}${path}`,
  })

  return res.data.data
}

export const fetchSingle = async (
  path: string,
): Promise<QuestionnaireAnswer> => {
  if (IS_MOCK_MODE) {
    return fixture
  }

  const res = await requestGet<QuestionnaireAnswer>({
    url: `${SERVER_URL}${path}`,
  })

  return res.data
}

export type UpdateChoiceAnswerParam = {
  uid: string
  checked: boolean
}

export type UpdateQuestionnaireFieldAnswerParam = {
  uid: string
  value: string | null
  choiceAnswers: UpdateChoiceAnswerParam[]
}

export type UpdateQuestionnaireFieldAnswersParam = {
  fieldAnswers: UpdateQuestionnaireFieldAnswerParam[]
}

export const save = async ({
  trialUid,
  questionnaireAnswerUid,
  questionnaireSectionAnswerUid,
  params,
}: {
  trialUid: string
  questionnaireAnswerUid: string
  questionnaireSectionAnswerUid: string
  params: UpdateQuestionnaireFieldAnswersParam
}): Promise<void> => {
  await requestPut<void>({
    url: `${SERVER_URL}/epro_patient_user/trials/${trialUid}/questionnaire_answers/${questionnaireAnswerUid}/pages/${questionnaireSectionAnswerUid}`,
    params,
  })
}

export const confirm = async ({
  trialUid,
  questionnaireAnswerUid,
}: {
  trialUid: string
  questionnaireAnswerUid: string
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  await requestPost<void>({
    url: `${SERVER_URL}/epro_patient_user/trials/${trialUid}/questionnaire_answers/${questionnaireAnswerUid}/confirm`,
  })
}
