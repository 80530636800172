import produce from 'immer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

const create = actionCreatorFactory('screenSpinner')

export const actions = {
  show: create<{}>('show'),
  hide: create<{}>('hide'),
}

export type State = {
  showable: boolean
}

const initialState: State = {
  showable: false,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.show, state =>
    produce(state, draft => {
      draft.showable = true
    }),
  )
  .case(actions.hide, state =>
    produce(state, draft => {
      draft.showable = false
    }),
  )
  .build()
