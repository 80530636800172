import React from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { getFullName } from 'src/utils/getFullName'

import {
  ExplanationDetail,
  ExplanationRevisionMember,
  ExplanationType,
} from '../../types'
import { isPartner } from '../../utils/isPartner'

type Props = {
  isOpen: boolean
  onClose: () => void
  explanation: ExplanationDetail
  onOpenSettingModal: () => void
} & (
  | {
      type: 'ExternalLink'
      href: string
    }
  | {
      type: 'Button'
      onClick: () => void
    }
)
export const MembersConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  explanation,
  onOpenSettingModal,
  ...rest
}) => {
  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  if (!selectedTrialHospitalUid) {
    throw new Error('selectedTrialHospitalUid is not defined')
  }

  const proceedButtonLabel =
    explanation.latestRevision.type !== 'InPerson' &&
    explanation.latestRevision.members.length === 0
      ? 'スキップして説明ロビーへ進む'
      : '確認済'

  const proceedButtonDisabled =
    explanation.latestRevision.type === 'InPerson' &&
    explanation.latestRevision.members.length === 0

  const isSignerSet = explanation.latestRevision.docSets
    .flatMap(ds => ds.explanationDocRevisions)
    .some(dr => dr?.type === 'AgreementForm' && dr.signerMembers.length > 0)
  const memberSetButtonDisabled =
    isSignerSet ||
    (explanation.latestRevision.status !== 'SessionNotDone' &&
      explanation.latestRevision.status !== 'SessionNotStarted')

  const explanationType = explanation.latestRevision.type

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>説明担当者確認</ModalHeader>
        <ModalBody>
          <Stack spacing="2">
            <>
              <Text>
                説明担当者 (
                <Text as="span" fontWeight="bold">
                  説明担当医師・説明補助者
                </Text>
                ) が正しく設定されていることをご確認ください。
              </Text>
              {explanationType !== 'InPerson' && (
                <Text fontSize="14">
                  不明な場合はそのまま説明ロビーへお進みください。
                </Text>
              )}
            </>

            <ExplanationRevisionMembersTable
              members={explanation.latestRevision.members}
              explanationType={explanationType}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ModalCancelButton />
          <Button
            key="setting"
            variant="outline"
            type="button"
            onClick={onOpenSettingModal}
            disabled={memberSetButtonDisabled}
          >
            担当者を設定する
          </Button>
          {rest.type === 'Button' ? (
            <Button onClick={rest.onClick} disabled={proceedButtonDisabled}>
              {proceedButtonLabel}
            </Button>
          ) : (
            <Button
              as="a"
              href={rest.href}
              target={
                isPartner(explanation, selectedTrialHospitalUid) &&
                explanation.latestRevision.type === 'RemotePartner'
                  ? '_self'
                  : '_blank'
              }
              rel="noopener noreferrer"
              onClick={onClose}
              disabled={proceedButtonDisabled}
            >
              {proceedButtonLabel}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

/* MemberTableと共通化を検討する。*/
const ExplanationRevisionMembersTable: React.FC<{
  members: ExplanationRevisionMember[]
  explanationType: ExplanationType
}> = ({ members, explanationType }) => {
  return (
    <TableContainer whiteSpace="break-spaces">
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>医療機関</Th>
            <Th>担当者</Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.map((member, index) => (
            <Tr key={member.trialMember.uid}>
              <Td>{index + 1}</Td>
              <Td>{member.trialHospital.name}</Td>
              <Td>
                <MemberLabel
                  role={member.trialMember.role}
                  displayName={getFullName(member.trialMember)}
                  isPartner={member.isPartner}
                />
              </Td>
            </Tr>
          ))}
          {members.length === 0 && (
            <Tr>
              <Td></Td>
              <Td py="6">
                <WarningMessage
                  message={
                    explanationType === 'InPerson'
                      ? `担当者が設定されていません。説明開始には担当者設定が必要です。`
                      : '担当者が設定されていません。'
                  }
                />
              </Td>
              <Td></Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
