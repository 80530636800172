import React, { useMemo } from 'react'

import { Box, Flex, HStack, VStack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { CheckFill, Rejection } from 'src/components/__legacy__icon/monochrome'
import { gray, green, red, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

import { ExplanationType, explanationTypes } from '../../explanation/entity'
import {
  icfDocumentRoleText,
  IcfDocumentSignerRole,
  icfDocumentSignerRoles,
  IcfDocumentType,
  icfDocumentTypes,
} from '../../icf-document/icf-document/entity'
import { ExplanationRoomUserAttributeType } from '../user-attribute-type'

type Props = {
  userAttributeType: ExplanationRoomUserAttributeType
  docRevisionUid: string
  order: number
  docType: IcfDocumentType
  docName: string
  docVersion: string
  explanationType?: ExplanationType
  latestPatientBrowsedAt?: string
  isCompleted: boolean
  isRejected: boolean
  isSkipped: boolean
  hasDSEnvelope: boolean
  allPlacedSignerRoles: IcfDocumentSignerRole[]
  completedSignerRoles: IcfDocumentSignerRole[]
  onClick: (docRevisionUid: string) => void
}

export const DocumentListCard: React.FC<Props> = ({
  userAttributeType,
  docRevisionUid,
  order,
  docType,
  docName,
  docVersion,
  explanationType,
  latestPatientBrowsedAt,
  isCompleted,
  isRejected,
  isSkipped,
  hasDSEnvelope,
  allPlacedSignerRoles,
  completedSignerRoles,
  onClick,
}) => {
  return (
    <Card
      onClick={() => onClick(docRevisionUid)}
      isRejected={isRejected}
      isSkipped={isSkipped}
      disabled={isSkipped}
    >
      <Flex align="center" flex={1}>
        {isCompleted ? (
          <CheckFill size="XL" color={green[40]} />
        ) : isRejected ? (
          <Rejection size="XL" color={gray[40]} />
        ) : (
          <Circle isSkipped={isSkipped}>
            <span>{order}</span>
          </Circle>
        )}
        <Spacer size={24} horizontal />
        <DocName>{docName}</DocName>
      </Flex>
      {userAttributeType === 'hospital' && (
        <ForHospitalInformation
          {...{
            docType,
            explanationType,
            latestPatientBrowsedAt,
            docVersion,
            isRejected,
            isCompleted,
            hasDSEnvelope,
            allPlacedSignerRoles,
            completedSignerRoles,
          }}
        />
      )}
      {userAttributeType === 'patient' && (
        <ForPatientInformation
          {...{
            latestPatientBrowsedAt,
          }}
        />
      )}
    </Card>
  )
}

const ForHospitalInformation: React.FC<
  Pick<
    Props,
    | 'docType'
    | 'explanationType'
    | 'latestPatientBrowsedAt'
    | 'docVersion'
    | 'isRejected'
    | 'isCompleted'
    | 'hasDSEnvelope'
    | 'allPlacedSignerRoles'
    | 'completedSignerRoles'
  >
> = ({
  docType,
  explanationType,
  latestPatientBrowsedAt,
  docVersion,
  isRejected,
  isCompleted,
  hasDSEnvelope,
  allPlacedSignerRoles,
  completedSignerRoles,
}) => {
  // 同意済みロールの並び順を定義
  const roleOrder: IcfDocumentSignerRole[] = [
    icfDocumentSignerRoles.Patient,
    icfDocumentSignerRoles.Representative,
    icfDocumentSignerRoles.CRC,
    icfDocumentSignerRoles.Dr,
  ]

  const warningMessage = useMemo(() => {
    if (!hasDSEnvelope) return undefined
    if (isRejected) return undefined

    if (docType === icfDocumentTypes.AgreementForm) {
      return allPlacedSignerRoles.length > completedSignerRoles.length
        ? '署名実施中'
        : undefined
    }

    return undefined
  }, [
    hasDSEnvelope,
    isRejected,
    docType,
    allPlacedSignerRoles.length,
    completedSignerRoles.length,
  ])

  const shouldShowAgreement =
    (explanationType === explanationTypes.remote &&
      (completedSignerRoles.length > 0 || isRejected)) ||
    (explanationType === explanationTypes.inPerson &&
      (hasDSEnvelope || isRejected))

  return (
    <Flex align="center" flex={1} justify="end">
      {!!warningMessage && (
        <Box ml={6} mr={12}>
          <Message type="warning" message={warningMessage} noWrap />
        </Box>
      )}
      <HospitalInformationContainer>
        <Status>
          {(docType === icfDocumentTypes.Description ||
            docType === icfDocumentTypes.Video) &&
            !!latestPatientBrowsedAt && (
              <>
                <Flex>
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    閲覧時刻
                  </Text>
                  <Spacer size={16} horizontal />
                  <Text fontSize="sm">
                    {dayjs(latestPatientBrowsedAt).format(
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                  </Text>
                </Flex>
                <Spacer size={4} />
                <Flex>
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    閲覧状況
                  </Text>
                  <Spacer size={16} horizontal />
                  <Flex align="center">
                    <Text fontSize="sm" wordBreak="keep-all">
                      患者
                    </Text>
                    <Spacer size={4} horizontal />
                    <CheckFill size="S" color={green[40]} />
                  </Flex>
                </Flex>
              </>
            )}
          {docType === icfDocumentTypes.CheckUnderstanding && (
            <>
              <VStack spacing="4px" align="start">
                {!!latestPatientBrowsedAt && (
                  <HStack spacing="16px">
                    <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                      閲覧時刻
                    </Text>
                    <Text fontSize="sm">
                      {dayjs(latestPatientBrowsedAt).format(
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </Text>
                  </HStack>
                )}
                {explanationType === explanationTypes.remote && isCompleted && (
                  <HStack spacing="16px">
                    <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                      回答状況
                    </Text>
                    <HStack align="center" spacing="4px">
                      {/* NOTE: 現状は患者のみ */}
                      <Text fontSize="sm" wordBreak="keep-all">
                        患者
                      </Text>
                      <CheckFill size="S" color={green[40]} />
                    </HStack>
                  </HStack>
                )}
                {explanationType === explanationTypes.inPerson &&
                  hasDSEnvelope && (
                    <HStack spacing="16px">
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        wordBreak="keep-all"
                      >
                        回答状況
                      </Text>
                      <HStack align="center" spacing="4px">
                        {/* NOTE: 現状は患者のみ */}
                        <Text fontSize="sm" wordBreak="keep-all">
                          患者
                        </Text>
                        {isCompleted ? (
                          <CheckFill size="S" color={green[40]} />
                        ) : (
                          <Spacer size={16} horizontal />
                        )}
                      </HStack>
                    </HStack>
                  )}
              </VStack>
            </>
          )}

          {docType === icfDocumentTypes.AgreementForm && (
            <VStack spacing="4px" align="start">
              {!!latestPatientBrowsedAt && (
                <HStack spacing="16px">
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    閲覧時刻
                  </Text>
                  <Text fontSize="sm">
                    {dayjs(latestPatientBrowsedAt).format(
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                  </Text>
                </HStack>
              )}
              {shouldShowAgreement && (
                <HStack spacing="16px">
                  <Text fontSize="sm" fontWeight="bold" wordBreak="keep-all">
                    署名状況
                  </Text>
                  <Flex align="center">
                    {isRejected ? (
                      <Text fontSize="sm" color={red[40]}>
                        同意拒否
                      </Text>
                    ) : (
                      allPlacedSignerRoles
                        .sort(
                          (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b),
                        )
                        .map(signerRole => (
                          <React.Fragment key={signerRole}>
                            <Text fontSize="sm" wordBreak="keep-all">
                              {icfDocumentRoleText[signerRole]}
                            </Text>
                            <Spacer size={4} horizontal />
                            {completedSignerRoles.includes(signerRole) ? (
                              <CheckFill size="S" color={green[40]} />
                            ) : (
                              <Spacer size={16} horizontal />
                            )}
                            <Spacer size={16} horizontal />
                          </React.Fragment>
                        ))
                    )}
                  </Flex>
                </HStack>
              )}
            </VStack>
          )}
        </Status>

        <Version>
          <Text>{`${docVersion}版`}</Text>
        </Version>
      </HospitalInformationContainer>
    </Flex>
  )
}

const ForPatientInformation: React.FC<
  Pick<Props, 'latestPatientBrowsedAt'>
> = ({ latestPatientBrowsedAt }) => {
  return (
    <Flex>
      {!!latestPatientBrowsedAt && (
        <Status>
          <Flex flexDirection="column">
            <Flex>
              <Text fontSize="sm" fontWeight="bold">
                閲覧時刻
              </Text>
              <Spacer size={16} horizontal />
              <Text fontSize="sm">
                {dayjs(latestPatientBrowsedAt).format('YYYY/MM/DD HH:mm:ss')}
              </Text>
            </Flex>
          </Flex>
        </Status>
      )}
    </Flex>
  )
}

const Card = styled.button<{
  isRejected: boolean
  isSkipped: boolean
}>`
  all: unset;
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${p => (p.isRejected || p.isSkipped ? gray[40] : green[40])};
  border-radius: 10px;
  width: 100%;

  cursor: ${p => (p.isRejected || p.isSkipped ? 'not-allowed' : 'pointer')};
  padding: 16px;
  box-sizing: border-box;
  font-size: 24px;
  color: ${p => (p.isRejected || p.isSkipped ? gray[40] : green[60])};
  font-weight: bold;
  :hover {
    background-color: ${p => (p.isRejected || p.isSkipped ? white : green[5])};
  }
  :active {
    background-color: ${p => (p.isRejected || p.isSkipped ? white : green[10])};
  }

  > :first-child {
    flex: 1;
  }
`

const Circle = styled.span<{ isSkipped: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  width: 40px;
  height: 40px;
  border: 1px solid ${p => (p.isSkipped ? gray[40] : green[40])};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${p => (p.isSkipped ? gray[55] : green[60])};
  font-weight: bold;
`

const DocName = styled.span`
  flex: 1;
  word-break: break-word;
`

const HospitalInformationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
`

const Status = styled.div`
  flex: 1;
`

const Version = styled.div`
  white-space: nowrap;
`
