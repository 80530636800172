import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { OutputModal } from 'src/components/modal/output'
import { Spacer } from 'src/components/spacer/spacer'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { QueryParamKeys } from 'src/modules/router'
import { routes } from 'src/modules/routes'

import { downloadFile } from './request'

type Props = {
  onClose: () => void
}

export const OutputModalContainer: React.FC<Props> = props => {
  const trial = useSelector(getSelectedTrial)!
  const { hasPermission } = usePermission()

  const onSubmit = ({
    isHistory,
    isWorksheet,
    isCallHistory,
  }: {
    isHistory: boolean
    isWorksheet: boolean
    isCallHistory: boolean
  }) => {
    const url = `${routes.trialPdf}/${trial.uid}/?${QueryParamKeys.History}=${isHistory}&${QueryParamKeys.Worksheet}=${isWorksheet}&${QueryParamKeys.CallHistory}=${isCallHistory}`
    window.open(url, '_blank')
    props.onClose()
  }

  const { videoEnabled } = trial

  const modalText = hasPermission(PERMISSIONS.Hospital_BelongAll)
    ? `${trial.name}に参加しているすべての患者ワークシートを画面出力します。`
    : `選択中の医療機関に参加しているすべての患者ワークシートを画面出力します。`

  return (
    <OutputModal
      title={`すべての患者ワークシートのデータ出力`}
      submitText={`出力`}
      errorMessage={``}
      requesting={false}
      videoEnabled={videoEnabled}
      onSubmit={onSubmit}
      onClose={props.onClose}
      onDownloadCapture={() => {
        const download = async () => {
          const res = await downloadFile({
            trialUid: trial.uid,
          })
          const link = document.createElement('a')
          link.href = res.url
          link.click()
          link.remove()
        }

        download()
      }}
    >
      <Text textAlign="center">{modalText}</Text>
      <Spacer size={10} />
      <Text textAlign="center">
        出力結果を印刷機能を使ってPDF形式などで保存をお願いします。
      </Text>
      <Spacer size={10} />
      <Text fontSize="xs" textAlign="center">
        ※出力完了まで時間がかかる場合があります。
      </Text>
    </OutputModal>
  )
}
