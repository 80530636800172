import { createContext, useState } from 'react'

import { Schedule } from 'src/modules/entities/schedule/entity'

type PaneType = Schedule['uid'] | 'add' | null

const OpeningPaneContext = createContext<PaneType | undefined>(undefined)
const SetOpeningPaneContext = createContext<
  ((arg: PaneType) => void) | undefined
>(undefined)

export const PaneProvider: React.FC<{ children: React.ReactNode }> = props => {
  const [openingPane, setOpeningPane] = useState<PaneType | undefined>(null)
  return (
    <OpeningPaneContext.Provider value={openingPane}>
      <SetOpeningPaneContext.Provider value={setOpeningPane}>
        {props.children}
      </SetOpeningPaneContext.Provider>
    </OpeningPaneContext.Provider>
  )
}
