import { createSelector } from 'reselect'
import { RootState } from 'src/modules/reducer'

import { Template } from './entity'
import { State } from './redux'
import { getState as getParentState } from '../selector'

const getState = createSelector(getParentState, state => state.template)

export const getList = createSelector(getState, state =>
  Object.values(state.byId),
)

export const getSingle = createSelector<
  RootState,
  string,
  string,
  State,
  Template
>(
  (_state, props) => props,
  getState,
  (uid, state) => state.byId[uid],
)

// Ordered by createdAt desc
export const getSingleByTemplateFolderUid = createSelector(
  (_state: RootState, props: string) => props,
  getState,
  (templateFolderUid: string, state: State) =>
    Object.values(state.byId)
      .filter(t => t.templateFolderUid === templateFolderUid)
      .sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      }),
)
