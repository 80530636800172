import { assertNever } from 'src/utils/assertNever'

import { ExplanationRevisionStatus } from '../types'

export const willStartSession: (
  status: ExplanationRevisionStatus,
) => boolean = status => {
  switch (status) {
    case 'SessionNotStarted':
    case 'SessionNotDone':
    case 'AgreementNotDone':
      return true
    case 'AgreementDone':
    case 'AgreementRejected':
    case 'Disabled':
    case 'Withdrawn':
      return false
    default:
      return assertNever(status)
  }
}
