import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import { Message } from 'src/components/base/message/message'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { PullDown, PullDownItem } from 'src/components/layout/pulldown'
import { Spacer } from 'src/components/spacer/spacer'
import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import styled from 'styled-components'

import { IcfDocumentForList } from '../../icf-document/entity'
import { SortableMultiSelect } from '../components/sortable-multi-select'
import {
  UpdateIcfDocumentSetItem,
  IcfDocumentSet,
  IcfDocumentUidAndIndex,
} from '../entity'
import { IcfDocumentSetErrors } from '../validate'

type Props = {
  updateItem: UpdateIcfDocumentSetItem | undefined
  numberingId: IcfDocumentSet['numberingId'] | undefined
  approvedIcfDocuments: IcfDocumentForList[] | undefined
  canUpdate: boolean
  canDisable: boolean
  onChangeName: (value: string) => void
  onChangeSelectedDocuments: (items: IcfDocumentUidAndIndex[]) => void
  onValidate: () => boolean
  onSubmit: () => Promise<void>
  onCancel: () => void
  onDisable: () => Promise<void>
  errors: IcfDocumentSetErrors
  pullDownState: ReturnType<typeof useOpenPullDown>
  listPath: string
  detailPath: string
}

export const EditIcfDocumentSet: React.FC<Props> = ({
  updateItem,
  numberingId,
  approvedIcfDocuments,
  canUpdate,
  canDisable,
  onChangeName,
  onChangeSelectedDocuments,
  onValidate,
  onSubmit,
  onCancel,
  onDisable,
  errors,
  pullDownState,
  listPath,
  detailPath,
}) => {
  const breadCrumbParams: BreadcrumbParam[] = [
    { label: '文書セット一覧', isLink: true, path: listPath },
    { label: '文書セット詳細', isLink: true, path: detailPath },
    { label: '文書セット編集', isLink: false },
  ]

  const pullDownItems: PullDownItem[] = [
    { name: 'キャンセル', onClick: onCancel, fontColor: red[40] },
    ...(canDisable
      ? [{ name: '文書セットを無効化', onClick: onDisable, fontColor: red[40] }]
      : []),
  ]

  if (!updateItem || !numberingId) {
    return null
  }

  return (
    <Wrapper>
      <Breadcrumb breadcrumbParams={breadCrumbParams} />
      <Spacer size={32} />
      <ButtonContainer>
        <Button
          size="S"
          text="更新"
          buttonType="important"
          disabled={!canUpdate}
          onClick={() => {
            if (!onValidate()) {
              return
            }

            onSubmit()
          }}
        />
        <Spacer size={16} horizontal />
        <PullDown
          pulldownOpen={pullDownState.pullDownOpen}
          closePulldown={pullDownState.handlers.closePullDown}
          items={pullDownItems}
        >
          <IconButton
            iconName="expand3"
            onClick={pullDownState.handlers.openPullDown}
          />
        </PullDown>
      </ButtonContainer>
      <Spacer size={32} />
      <Content>
        <ItemContainer>
          <Item width={120}>
            <Label bold>文書セットID</Label>
          </Item>
          <Item>
            <Text fontSize="sm" fontWeight="bold">
              {numberingId}
            </Text>
          </Item>
        </ItemContainer>

        <ItemsContainer>
          <ItemContainer>
            <Item width={120}>
              <Label bold>文書セット名</Label>
            </Item>
            <Item>
              <Input
                maxWidth={300}
                value={updateItem.name}
                onChange={e => onChangeName(e.target.value)}
              />
            </Item>
          </ItemContainer>
          {errors.name && <ErrorMessage errorMessage={errors.name} />}
        </ItemsContainer>

        {approvedIcfDocuments !== undefined && (
          <SortableMultiSelect
            icfDocuments={approvedIcfDocuments}
            onChangeSelectedDocuments={onChangeSelectedDocuments}
            selectedIcfDocuments={updateItem.icfDocuments}
            errorMessage={errors.documents}
          />
        )}
      </Content>
    </Wrapper>
  )
}

const ErrorMessage: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <ItemContainer>
      <Spacer size={120} horizontal />
      <Message type="error" message={errorMessage} />
    </ItemContainer>
  )
}

const Wrapper = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Content = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

const ItemsContainer = styled.div``

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;

  & > div:first-child {
    margin-right: 24px;
  }
  & > div:nth-child(2) {
    flex: 1;
  }
`

const Item = styled.div<{ width?: number }>`
  width: ${p => p.width}px;
`
