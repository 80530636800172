import { QuestionnaireSectionAnswer } from '../questionnaire-section-answer/entity'

export enum QuestionnaireAnswerStatus {
  Invalid = 0,
  Unanswered,
  Answered,
}

export enum QuestionnaireAnswerAnswerStatus {
  Invalid = 0,
  BeforeAnswer,
  Answered,
  Unanswered,
  Expired,
}

export type QuestionnaireAnswer = {
  uid: string
  questionnaireUid: string
  scheduleUid: string
  patientUid: string
  title: string
  displayName: string
  status: QuestionnaireAnswerStatus // 回答したかどうかのステータス
  answerStatus: QuestionnaireAnswerAnswerStatus
  shouldDeliverAfterTrial: boolean
  answerStartDate: string
  answerEndDate: string
  answeredAt: string | null
  sectionAnswers: QuestionnaireSectionAnswer[]
}
