import { useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDetailRoute } from 'src/modules/dashboard/trial/detail/epro/questionnaire/routes'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { actions } from 'src/modules/entities/questionnaire/redux'
import {
  QuestionnaireRegisterRequest,
  register,
} from 'src/modules/entities/questionnaire/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

export const useSubmitRegister = () => {
  const trialUid = useSelector(getSelectedTrial)!.uid
  const {
    requesting,
    errorMessage,
    requestDone,
    requestFailed,
    requestStarted,
    clearErrorMessage,
  } = useRequestState()
  const dispatch = useDispatch()
  const { showSuccess } = useFlash()
  const navigate = useNavigate()

  const request = useCallback(
    async (mode: string, params: QuestionnaireRegisterRequest) => {
      try {
        requestStarted()

        const newQuestionnaire = await register(trialUid, params)

        dispatch(actions.upsert(newQuestionnaire))
        if (mode === 'edit-new') {
          navigate(getDetailRoute(trialUid, newQuestionnaire))
        }

        requestDone()
        showSuccess(
          mode === 'edit-new'
            ? '質問票を作成しました。'
            : 'ファイルのインポートに成功しました。',
        )
        return true
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [
      dispatch,
      navigate,
      requestDone,
      requestFailed,
      requestStarted,
      showSuccess,
      trialUid,
    ],
  )
  return { request, requesting, errorMessage, clearErrorMessage }
}
