import React from 'react'

import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Label } from 'src/components/form-redesigned/label'
import { MultiselectFilter } from 'src/components/form-redesigned/multiselect-filter'
import { Spacer } from 'src/components/spacer/spacer'
import { TrialHospitalsByUid } from 'src/modules/entities/account/util'
import { SmallItem } from 'src/modules/entities/small-item/entity'
import { hasSameElements } from 'src/modules/util/array-comparison'
import styled from 'styled-components'

import { HospitalNames } from '../../components/common/hospital-names'

type Handlers = {
  onChangeMultiselect: (ids: string[]) => void
  onClose: () => void
  onSubmit: () => void
  onDelete: () => void
}

type Props = {
  mediumItemTitle: string
  smallItem: SmallItem
  trialHospitalsByUid: TrialHospitalsByUid
  selectedTrialHospitalUids: string[]
  canDelete: boolean
  requesting: boolean
  errorMessage: string
  handlers: Handlers
}

export const VisibilitySetModalComponent: React.FC<Props> = props => {
  const {
    errorMessage,
    mediumItemTitle,
    smallItem,
    trialHospitalsByUid,
    selectedTrialHospitalUids,
    canDelete,
    requesting,
    handlers,
  } = props

  const onClose = () => {
    if (requesting) return

    handlers.onClose()
  }

  const onSubmit = () => {
    if (requesting) return

    handlers.onSubmit()
  }

  const onDelete = () => {
    if (requesting) return

    handlers.onDelete()
  }

  const canSubmit =
    !requesting &&
    !hasSameElements(smallItem.viewableHospitalUids, selectedTrialHospitalUids)

  const modalContentHeight = `${window.innerHeight - 320}px`

  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="医療機関設定" />

      <ModalContent>
        <Label>対象フィールド</Label>
        <Spacer size={10} />
        <TableContainer style={{ maxHeight: modalContentHeight }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>セクション</TableHeadCell>
                <TableHeadCell>フィールド</TableHeadCell>
                <TableHeadCell>表示される医療機関</TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow borderBottom>
                <TableBodyCell>{mediumItemTitle}</TableBodyCell>
                <TableBodyCell>{smallItem.title}</TableBodyCell>
                <TableBodyCell>
                  <HospitalNames
                    trialHospitalsByUid={trialHospitalsByUid}
                    hospitalUids={smallItem.viewableHospitalUids}
                  ></HospitalNames>
                </TableBodyCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Spacer size={40} />

        <Label>医療機関を選択</Label>
        <Spacer size={10} />
        <MultiselectFilter
          items={Object.values(trialHospitalsByUid).map(th => ({
            id: th.uid,
            value: th.name,
          }))}
          selectedItemIds={selectedTrialHospitalUids}
          onChange={handlers.onChangeMultiselect}
        ></MultiselectFilter>
      </ModalContent>

      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="決定"
          onClick={onSubmit}
          buttonType="important"
          disabled={!canSubmit}
        ></Button>
        <Spacer size={80} horizontal />
        <Button
          size="S"
          text="設定を解除"
          onClick={onDelete}
          buttonType="alert"
          disabled={!canDelete}
        ></Button>
      </ModalActions>

      {errorMessage && (
        <div>
          <Spacer size={4} />
          <Message type="error" message={errorMessage} centered />
        </div>
      )}
    </Modal>
  )
}

const TableContainer = styled.div`
  width: 100%;
  height: 120px;
`
