import { SERVER_URL } from 'src/modules/server/const'
import { requestDelete } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

type LogoutResponse = {}

export const logout = async () => {
  if (IS_MOCK_MODE) {
    return
  }

  return await requestDelete<LogoutResponse>({
    url: `${SERVER_URL}/accounts/signout`,
    params: {},
  })
}
