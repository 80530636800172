import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { IconButton } from 'src/components/base/icon-button/icon-button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Breadcrumb,
  BreadcrumbParam,
} from 'src/components/breadcrumb/breadcrumb'
import { PullDown } from 'src/components/layout/pulldown'
import { Spacer } from 'src/components/spacer/spacer'
import { useOpenPullDown } from 'src/hooks/use-open-pull-down'
import styled from 'styled-components'

import { IcfDocumentCard } from '../components/document-card'
import { CreateIcfDocumentItem, IcfDocument, IcfDocumentType } from '../entity'
import { IcfDocumentErrors } from '../validate'

export type IcfDocumentErrorMap = Record<IcfDocument['uid'], IcfDocumentErrors>

type Props = {
  createIcfDocumentItems: CreateIcfDocumentItem[]
  onAddItem: () => void
  onDeleteItem: (uid: string) => void
  onChangeDocumentType: ({
    uid,
    type,
  }: {
    uid: string
    type: IcfDocumentType
  }) => void
  onChangeName: ({ uid, value }: { uid: string; value: string }) => void
  onChangeVersion: ({ uid, value }: { uid: string; value: string }) => void
  onSelectFile: ({ uid, file }: { uid: string; file: File }) => Promise<void>
  onResetFile: ({ uid }: { uid: string }) => void
  onChangeRequiredPatientSign: ({ uid }: { uid: string }) => void
  tempFileUrlMap: Map<CreateIcfDocumentItem['uid'], string>
  submitModalOpen: boolean
  openSubmitModal: () => void
  closeSubmitModal: () => void
  onValidate: () => boolean
  errorMap: IcfDocumentErrorMap
  shouldSelectHospital: boolean
  hospitalName: string
  listPath: string
  pullDownState: ReturnType<typeof useOpenPullDown>
  maxDocumentCount: number
  onCancel: () => void
  onSubmit: () => Promise<void>
}

export const AddIcfDocument: React.FC<Props> = ({
  createIcfDocumentItems,
  onAddItem,
  onDeleteItem,
  onChangeDocumentType,
  onChangeName,
  onChangeVersion,
  onSelectFile,
  onResetFile,
  onChangeRequiredPatientSign,
  tempFileUrlMap,
  submitModalOpen,
  openSubmitModal,
  closeSubmitModal,
  onValidate,
  errorMap,
  shouldSelectHospital,
  hospitalName,
  listPath,
  onCancel,
  maxDocumentCount,
  pullDownState,
  onSubmit,
}) => {
  const breadcrumbParams: BreadcrumbParam[] = [
    {
      label: '文書一覧',
      isLink: true,
      path: listPath,
    },
    { label: '文書登録', isLink: false },
  ]

  return (
    <Wrapper>
      {submitModalOpen && (
        <SubmitModal
          onClose={closeSubmitModal}
          onSubmit={onSubmit}
          itemCount={createIcfDocumentItems.length}
        />
      )}
      <Breadcrumb breadcrumbParams={breadcrumbParams} />
      <Spacer size={32} />
      <ContentMenu>
        {shouldSelectHospital && (
          <HospitalNameContainer>
            <Text fontSize="sm" fontWeight="bold">
              医療機関 : {hospitalName}
            </Text>
          </HospitalNameContainer>
        )}
        <FlexSpacer />
        <Button
          size="S"
          text="登録"
          buttonType="important"
          onClick={() => {
            if (!onValidate()) {
              return
            }
            openSubmitModal()
          }}
          disabled={createIcfDocumentItems.length === 0}
        />
        <Spacer size={16} horizontal />
        <PullDown
          pulldownOpen={pullDownState.pullDownOpen}
          closePulldown={pullDownState.handlers.closePullDown}
          items={[
            { name: 'キャンセル', onClick: onCancel, fontColor: red[40] },
          ]}
        >
          <IconButton
            iconName="expand3"
            onClick={pullDownState.handlers.openPullDown}
          />
        </PullDown>
      </ContentMenu>
      <Spacer size={32} />
      {createIcfDocumentItems.map(item => (
        <CardContainer key={item.uid}>
          <IcfDocumentCard
            cardType="new"
            documentType={item.type}
            name={item.revision.name}
            version={item.revision.version}
            fileName={item.revision.fileName}
            isRequiredPatientSign={item.revision.isRequiredPatientSign}
            onChangeName={(value: string) => {
              onChangeName({ uid: item.uid, value })
            }}
            onChangeVersion={(value: string) => {
              onChangeVersion({ uid: item.uid, value })
            }}
            onSelectFile={async (file: File) => {
              await onSelectFile({ uid: item.uid, file })
            }}
            onResetFile={() => onResetFile({ uid: item.uid })}
            onChangeDocumentType={(type: IcfDocumentType) => {
              onChangeDocumentType({ uid: item.uid, type })
            }}
            onChangeRequiredPatientSign={() =>
              onChangeRequiredPatientSign({ uid: item.uid })
            }
            filePreviewUrl={tempFileUrlMap.get(item.uid)}
            hasTempFile={!!tempFileUrlMap.get(item.uid)}
            errors={errorMap[item.uid]}
          />
          <Spacer size={8} horizontal />
          <IconButton iconName="close" onClick={() => onDeleteItem(item.uid)} />
        </CardContainer>
      ))}
      <Spacer size={8} />
      {createIcfDocumentItems.length === maxDocumentCount ? (
        <Message
          type="information"
          message={`一度に追加できる文書は${maxDocumentCount}件までです`}
        />
      ) : (
        <Button
          size="S"
          text="文書を追加"
          buttonType="normal"
          iconName="add"
          onClick={onAddItem}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ContentMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HospitalNameContainer = styled.div`
  display: flex;
  align-items: center;
`

const FlexSpacer = styled.div`
  flex: 1;
`

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 8px;
`

type ModalProps = {
  onClose: () => void
  onSubmit: () => Promise<void>
  itemCount: number
}
const SubmitModal: React.FC<ModalProps> = ({
  onClose,
  onSubmit,
  itemCount,
}) => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle title="文書登録" />
      <ModalContent>
        <Text textAlign="center">{`${itemCount}件の文書新規登録します。`}</Text>
      </ModalContent>
      <ModalActions>
        <Button size="S" text="閉じる" onClick={onClose} buttonType="normal" />
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="登録する"
          onClick={onSubmit}
          buttonType="important"
        />
      </ModalActions>
    </Modal>
  )
}
