import React from 'react'

import { Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { RightArrow } from '../__legacy__icon/monochrome'
import { blue, gray } from '../base/color/palette'
import { Spacer } from '../spacer/spacer'

// isLink === true の場合、pathを指定させる
export type BreadcrumbParam =
  | {
      label: string
      isLink: true
      path: string
    }
  | {
      label: string
      isLink: false
    }

type Props = {
  breadcrumbParams: BreadcrumbParam[]
}

export const Breadcrumb: React.FC<Props> = ({ breadcrumbParams }) => {
  return (
    <Container>
      {breadcrumbParams.map((param, i) => (
        <Flex key={param.label} alignCenter>
          {i > 0 && (
            <Flex alignCenter>
              <Spacer size={8} horizontal />
              <RightArrow />
              <Spacer size={8} horizontal />
            </Flex>
          )}

          {param.isLink ? (
            <Link to={param.path}>{param.label}</Link>
          ) : (
            <Text fontWeight="bold" color={gray[80]}>
              {param.label}
            </Text>
          )}
        </Flex>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const Link = styled(NavLink)`
  font-size: 16px; // same as H6
  line-height: 23px; // same as H6
  font-weight: bold;
  text-decoration: none;
  color: ${blue[70]};
  :hover {
    color: ${blue[40]};
  }
  :active {
    color: ${blue[10]};
  }
`
