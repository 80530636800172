import {
  ExplanationOrderKey,
  PatientsSortKey,
  PatientStatus,
  SearchPatientsRelatedTrialHospitalType,
  SortOrder,
} from 'src/lib/gql-client'

import { allExplanationRevisionStatuses } from './allExplanationRevisionStatuses'
import { ExplanationRevisionStatus } from '../types'

export const stringToPage = (val?: string) => {
  if (val === undefined) return 1
  if (isNaN(Number(val))) return 1
  return Number(val)
}

const canCastExplanationOrderKey = (
  value: string,
): value is ExplanationOrderKey => {
  return [
    'AgreementCompletedAtAsc',
    'AgreementCompletedAtDesc',
    'CandidateIdAsc',
    'CandidateIdDesc',
    'CreatedAt',
    'DeliveredAtAsc',
    'DeliveredAtDesc',
    'DiseaseIdAsc',
    'DiseaseIdDesc',
  ].includes(value)
}

export const stringToExplanationOrderKey = (
  val?: string,
): ExplanationOrderKey => {
  if (val === undefined) return 'CreatedAt'
  if (canCastExplanationOrderKey(val)) {
    return val
  }
  return 'CreatedAt'
}

const fallbackReAgreement: boolean[] = [true, false]
export function stringToReAgreementArray(val?: string): boolean[] {
  const result: boolean[] = []
  if (val === undefined) return fallbackReAgreement
  if (val.includes('true')) result.push(true)
  if (val.includes('false')) result.push(false)
  return result
}

export const stringToExplanationStatusesArray = (
  val?: string,
): ExplanationRevisionStatus[] => {
  if (val === undefined) {
    const fallbackStatuses: ExplanationRevisionStatus[] = [
      'SessionNotStarted',
      'SessionNotDone',
      'AgreementNotDone',
      'AgreementDone',
      'AgreementRejected',
      'Withdrawn',
    ]

    return fallbackStatuses
  }
  const result: ExplanationRevisionStatus[] = []
  for (const status of allExplanationRevisionStatuses) {
    if (val.includes(status)) result.push(status)
  }
  return result
}

const fallbackRelatedHospitalType: SearchPatientsRelatedTrialHospitalType =
  'Any'
const canCastPatientRelatedHospitalType = (
  value: string,
): value is SearchPatientsRelatedTrialHospitalType => {
  return ['Any', 'OnlyNotSet', 'WithUid'].includes(value)
}
export const stringToPatientRelatedHospitalType = (
  val?: string,
): SearchPatientsRelatedTrialHospitalType => {
  if (val === undefined) return fallbackRelatedHospitalType
  if (canCastPatientRelatedHospitalType(val)) return val
  return fallbackRelatedHospitalType
}

const fallbackPatientSortKey: PatientsSortKey = 'DiseaseUID'
const canCastPatientSortKey = (value: string): value is PatientsSortKey => {
  return ['DiseaseUID', 'HospitalName', 'Name'].includes(value)
}
export const stringToPatientSortKey = (val?: string): PatientsSortKey => {
  if (val === undefined) return fallbackPatientSortKey
  if (canCastPatientSortKey(val)) {
    return val
  }
  return fallbackPatientSortKey
}

const fallbackPatientStatuses: PatientStatus[] = ['Enable', 'Disable']
export const stringToPatientStatusesArray = (val?: string): PatientStatus[] => {
  if (val === undefined) {
    return fallbackPatientStatuses
  }
  const result: PatientStatus[] = []
  for (const status of fallbackPatientStatuses) {
    if (val.includes(status)) result.push(status)
  }
  return result
}

const fallbackSortOrder: SortOrder = 'Desc'
const canCastSortOrder = (value: string): value is SortOrder => {
  return ['Desc', 'Asc'].includes(value)
}
export const stringToSortOrder = (val?: string): SortOrder => {
  if (val === undefined) {
    return fallbackSortOrder
  }
  if (canCastSortOrder(val)) return val
  return fallbackSortOrder
}
