import { useState } from 'react'

import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  TextProps,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import {
  generatePath,
  Link as ReactRouterLink,
  useParams,
} from 'react-router-dom'
import { PaginationNext } from 'src/components/__legacy__icon/monochrome'
import { FoldableAlert } from 'src/components/FoldableAlert/FoldableAlert'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  usePermission,
} from 'src/features/auth/context'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { PERMISSIONS } from 'src/lib/permission'
import { downloadObject } from 'src/utils/downloadObject'
import { formatDate } from 'src/utils/formatDate'
import { isNotNullish } from 'src/utils/isNotNullish'

import { UID } from '../../../../utils/brandedUid'
import {
  useDeliveryDocumentByDownload,
  useExplanation,
  useUpdateRevisionLastCheckedAt,
} from '../../api'
import { Explanation } from '../../types'
import { hasAlreadyPinSet } from '../../utils/hasAlreadyPinSet'
import { isPartner } from '../../utils/isPartner'
import { isPinSettingEnabledRevisionStatus } from '../../utils/isPinSettingEnabledRevisionStatus'
import { parseDocRevsToCompleteAgreement } from '../../utils/parseDocRevsToCompleteAgreement'
import { explanationRevisionStatusToText } from '../../utils/revisionStatusToText'
import { explanationTypeToText } from '../../utils/typeToText'
import { CompleteAgreementForDetail } from '../CompleteAgreementForDetail/CompleteAgreementForDetail'
import { DocSetAccordion } from '../DocSetAccordion/DocSetAccordion'
import { EditSchedule } from '../EditSchedule/EditSchedule'
import { ExplanationHistoryDrawerButton } from '../ExplanationHistoryDrawerButton/ExplanationHistoryDrawerButton'
import { ExplanationMenu } from '../ExplanationMenu/ExplanationMenu'
import { ExplanationPatientInformationSection } from '../ExplanationPatientInformationSection/ExplanationPatientInformationSection'
import { ExplanationRequestPinSettingButton } from '../ExplanationRequestPinSettingButton/ExplanationRequestPinSettingButton'
import { ExplanationStartReAgreementButton } from '../ExplanationStartReAgreementButton/ExplanationStartReAgreementButton'
import { ExplanationWithdrawHistoryButton } from '../ExplanationWithdrawHistoryButton/ExplanationWithdrawHistoryButton'
import { MembersTable } from '../MembersTable/MembersTable'
import { ScheduleTooltip } from '../ScheduleTooltip/ScheduleTooltip'
import { StartExplanationSession } from '../StartExplanationSession/StartExplanationSession'
import { SyncAllEnvelopes } from '../SyncAllEnvelopes/SyncAllEnvelopes'
import { UpdateDeliveryDate } from '../UpdateDeliveryDate/UpdateDeliveryDate'

type Param = {
  explanationUid: Explanation['uid']
}

const labelStyle: TextProps = {
  fontSize: 'sm',
  color: 'gray.600',
  fontWeight: 'bold',
}

export const ExplanationDetailScreen: React.FC = () => {
  const { hasPermission } = usePermission()

  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { explanationUid = '' as UID<'Explanation'> } = useParams<Param>()

  const toast = useMirohaToast()

  const { data: explanation, mutate: mutateExplanation } = useExplanation({
    explanationUid,
  })
  const { request: downloadDeliveredDocs } = useDeliveryDocumentByDownload({
    onSuccess: ({ downloadUrl }) => {
      downloadObject({ url: downloadUrl })
      mutateExplanation()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const [hasNoPinSettingAlert, setHasNoPinSettingAlert] = useState(false)

  const { request: updateCheckedAt } = useUpdateRevisionLastCheckedAt({
    onSuccess: () => {
      mutateExplanation()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  if (!explanation) return null

  const {
    uid: latestRevisionUid,
    status,
    statusV2,
    type,
    latestSessionStartedAt,
    deliveredAt,
    scheduledAt,
    docSets,
    partnerTrialHospital,
    members,
  } = explanation.latestRevision

  const canDownloadDeliveryDocs = (() => {
    if (!hasPermission(PERMISSIONS.Explanation_Edit)) return false
    return status === 'AgreementDone' || status === 'Withdrawn'
  })()

  const shouldShowReAgreementAlert = (() => {
    if (!hasPermission(PERMISSIONS.Explanation_Edit)) return false
    return explanation.isRequiredReAgreement
  })()

  const shouldShowDocUpdateAlert = (() => {
    if (!hasPermission(PERMISSIONS.Explanation_Edit)) return false
    if (status !== 'SessionNotStarted') return false
    return explanation.latestRevision.docSets.some(
      docSet => docSet.updatedAfterChecked,
    )
  })()

  const pinSettingStatus = explanation.latestRevision.pinSettingStatus
  const hasPinSet = pinSettingStatus
    ? hasAlreadyPinSet(pinSettingStatus)
    : false

  const pinSettingEnabled =
    isPinSettingEnabledRevisionStatus(statusV2) &&
    selectedTrial.featureFlags.eConsentNewSignFlow &&
    hasPermission(PERMISSIONS.Explanation_Edit)

  const shouldShowInitialPinSettingButton = pinSettingEnabled && !hasPinSet

  const docRevisions = docSets
    .flatMap(docSet => docSet.explanationDocRevisions)
    .filter(isNotNullish)
  const { canComplete } = parseDocRevsToCompleteAgreement(docRevisions)

  const shouldShowNotAgreementDoneExistedAlert = (() => {
    return (
      (type === 'Remote' || type === 'RemotePartner') &&
      status === 'AgreementNotDone' &&
      !canComplete
    )
  })()

  const shouldShowCanAgreementDoneAlert = (() => {
    if (!hasPermission(PERMISSIONS.Explanation_Edit)) return false
    return (
      (type === 'Remote' || type === 'RemotePartner') &&
      status === 'AgreementNotDone' &&
      canComplete
    )
  })()

  const latestSessionUid = explanation.latestRevision.latestSessionUid

  const canSyncEnvelopes =
    type !== 'InPerson' &&
    status === 'AgreementNotDone' &&
    !canComplete &&
    !!latestSessionUid

  if (
    shouldShowInitialPinSettingButton &&
    explanation.latestRevision.pinSettingStatus !== 'Completed' &&
    !hasNoPinSettingAlert
  ) {
    setHasNoPinSettingAlert(true)
    toast({
      status: 'info',
      title: '署名実施時には患者の当人認証設定が必要です',
      description:
        '「当人認証設定」ボタンより患者の当人認証情報の事前設定が可能です。',
    })
  }

  return (
    <Box as="main">
      {shouldShowReAgreementAlert && (
        <Box mb="6">
          <FoldableAlert
            status="warning"
            title="説明の再同意が必要です"
            // partner施設の場合ボタンの表示等はないので省略
            description={
              isPartner(explanation, selectedTrialHospitalUid)
                ? undefined
                : '再同意実施ボタンから、説明の再同意を実施してください。'
            }
          />
        </Box>
      )}
      {shouldShowDocUpdateAlert && (
        <Box mb="6">
          <FoldableAlert
            status="warning"
            title="文書セット情報が更新されています"
            description="設定中の文書セットを確認の上、「更新文書を確認」ボタンを押下してください。"
          />
        </Box>
      )}
      {shouldShowNotAgreementDoneExistedAlert && (
        <Box mb="6">
          <FoldableAlert
            status="warning"
            title="署名が未完了の同意書があります"
            description="「同意書の最新状態を取得」ボタンを押して、署名状態を確認してください"
          />
        </Box>
      )}
      {shouldShowCanAgreementDoneAlert && (
        <Box mb="6">
          <FoldableAlert
            status="warning"
            title="説明の同意完了が可能です"
            description="「同意完了処理を実施」ボタンを押して、同意を完了できます。"
          />
        </Box>
      )}
      <Flex justify="space-between">
        <Breadcrumb
          display="flex"
          alignItems="center"
          separator={<PaginationNext size="M" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              as={ReactRouterLink}
              to={generatePath(Paths.Explanations, {
                trialUid: selectedTrial.uid,
              })}
              color="blue.500"
              fontSize="lg"
              fontWeight="bold"
            >
              説明同意一覧
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem fontSize="lg" isCurrentPage>
            <BreadcrumbLink
              fontWeight="bold"
              cursor="text"
              _hover={{ textDecoration: 'none' }}
            >
              説明同意詳細
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack spacing="4">
          {shouldShowInitialPinSettingButton && (
            <ExplanationRequestPinSettingButton
              latestRevisionUid={explanation.latestRevision.uid}
              patientUid={explanation.patient.uid}
              phoneNumber={explanation.patient.phoneNumber}
              mutateExplanation={() => {
                mutateExplanation()
              }}
            />
          )}
          {shouldShowDocUpdateAlert && (
            <Button
              onClick={async () => {
                updateCheckedAt({ explanationRevisionUid: latestRevisionUid })
              }}
            >
              更新文書を確認
            </Button>
          )}
          {!shouldShowDocUpdateAlert && (
            <ExplanationStartReAgreementButton explanation={explanation} />
          )}
          {!shouldShowDocUpdateAlert && (
            <StartExplanationSession
              explanation={explanation}
              mutateExplanation={mutateExplanation}
            />
          )}
          <ExplanationMenu explanation={explanation} />
        </HStack>
      </Flex>

      <Box as="section" aria-label="content" mt="6">
        <ExplanationPatientInformationSection
          patient={explanation.patient}
          latestRevisionUid={explanation.latestRevision.uid}
          hasPinSet={hasPinSet}
          shouldShowRestPinSettingButton={pinSettingEnabled && hasPinSet}
          mutateExplanation={mutateExplanation}
        />
        <Box as="section" aria-labelledby="explanation_information_heading">
          <Box px="4" py="2" bg="blue.50">
            <TextWithBar as="h2" id="explanation_information_heading">
              実施情報
            </TextWithBar>
          </Box>
          <Grid
            templateColumns="132px 1fr"
            p="6"
            rowGap="5"
            alignItems="baseline"
          >
            <GridItem>
              <Text {...labelStyle}>ステータス</Text>
            </GridItem>
            <GridItem>
              <HStack alignItems="center" spacing="4" mb={2}>
                {status === 'Withdrawn' ? (
                  <ExplanationWithdrawHistoryButton
                    explanationRevisionUid={latestRevisionUid}
                  />
                ) : (
                  <Text>{explanationRevisionStatusToText(status)}</Text>
                )}
                {explanation.revisionUids.length >= 2 && <Badge>再同意</Badge>}
                <ExplanationHistoryDrawerButton explanation={explanation} />
              </HStack>
              <HStack>
                {hasPermission(PERMISSIONS.Explanation_Edit) && (
                  <CompleteAgreementForDetail explanation={explanation} />
                )}
                {canSyncEnvelopes && (
                  <SyncAllEnvelopes
                    buttonType="text"
                    sessionUid={latestSessionUid}
                    onSyncDone={() => mutateExplanation()}
                  />
                )}
              </HStack>
            </GridItem>

            {type !== 'InPerson' && (
              <>
                <GridItem>
                  <HStack spacing="2">
                    <Text {...labelStyle}>予約日時</Text>
                    {type === 'Remote' && <ScheduleTooltip />}
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack spacing="3">
                    {scheduledAt ? (
                      <Text>
                        {formatDate(scheduledAt, 'YYYY/MM/DD (ddd) HH:mm')}
                      </Text>
                    ) : null}
                    <EditSchedule
                      explanation={explanation}
                      key={explanation.latestRevision.scheduledAt}
                      mutateExplanation={() => {
                        mutateExplanation()
                      }}
                    />
                  </HStack>
                </GridItem>
              </>
            )}
            <GridItem>
              <Text {...labelStyle}>前回説明日時</Text>
            </GridItem>
            <GridItem>
              {latestSessionStartedAt ? (
                <Text>
                  {formatDate(latestSessionStartedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                </Text>
              ) : null}
            </GridItem>
            <GridItem>
              <Text {...labelStyle}>交付日</Text>
            </GridItem>
            <GridItem>
              <HStack spacing="4">
                {!!deliveredAt && (
                  <Text>{formatDate(deliveredAt, 'YYYY/MM/DD (ddd)')}</Text>
                )}
                {(status === 'AgreementDone' || status === 'Withdrawn') && (
                  <UpdateDeliveryDate
                    explanation={explanation}
                    mutateExplanation={mutateExplanation}
                  />
                )}
              </HStack>
            </GridItem>
            {canDownloadDeliveryDocs && (
              <>
                <GridItem />
                <GridItem>
                  <Box>
                    <Button
                      variant="outline"
                      onClick={async () => {
                        await downloadDeliveredDocs({
                          explanationRevisionUid: latestRevisionUid,
                          // 交付日が存在しない場合はlatestRevisionの交付日を更新する。
                          deliveryDate:
                            deliveredAt === undefined
                              ? dayjs(new Date()).format('YYYY-MM-DD')
                              : undefined,
                        })
                      }}
                    >
                      交付文書ダウンロード
                    </Button>
                  </Box>
                </GridItem>
              </>
            )}
          </Grid>
        </Box>

        <Box as="section" aria-labelledby="explanation_content_heading">
          <Box px="4" py="2" bg="blue.50">
            <TextWithBar as="h2" id="explanation_content_heading">
              説明内容
            </TextWithBar>
          </Box>
          <Stack p="6" spacing="8">
            <Box>
              <Text {...labelStyle}>対象文書</Text>
              <Box mt="2">
                <DocSetAccordion
                  type="fixed"
                  docSets={docSets}
                  shouldShowUpdateAlert={shouldShowDocUpdateAlert}
                />
              </Box>
            </Box>
            <Grid templateColumns="132px 1fr" rowGap="5" alignItems="center">
              <GridItem>
                <Text {...labelStyle}>説明方式</Text>
              </GridItem>
              <GridItem>
                <Text>{explanationTypeToText(type)}</Text>
              </GridItem>
              {type === 'RemotePartner' && (
                <>
                  <GridItem>
                    <Text {...labelStyle}>パートナー施設</Text>
                  </GridItem>
                  <GridItem>
                    <Text>{partnerTrialHospital?.name}</Text>
                  </GridItem>
                </>
              )}
              <GridItem>
                <Text {...labelStyle}>患者代諾者</Text>
              </GridItem>
              <GridItem>
                {/* 現状は固定で無し */}
                <Text>無し</Text>
              </GridItem>
            </Grid>
          </Stack>
        </Box>

        <Box as="section" aria-labelledby="explanation_members_heading">
          <Box px="4" py="2" bg="blue.50">
            <TextWithBar as="h2" id="explanation_members_heading">
              説明担当者
            </TextWithBar>
          </Box>
          <Box p="6">
            <MembersTable members={members} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
