import React from 'react'

import { Button, IconButton, Input, Text } from '@chakra-ui/react'
import { Message } from 'src/components/base/message/message'
import { Add, Delete } from 'src/components/icon'
import { Spacer } from 'src/components/spacer/spacer'
import { Choice } from 'src/modules/entities/choice/entity'
import { ItemType, SmallItem } from 'src/modules/entities/small-item/entity'
import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'

import { NestedSmallItem } from './nested-small-item'
import { Value } from './value'

type Props = {
  choice: Choice
  choiceNumber: number
  presetUsed: boolean
  smallItems: SmallItem[]
  itemType: ItemType
  isClinical: boolean
  releaseTried: boolean
  v2: boolean

  onChange: (args: { values: { [key in keyof Choice]?: Choice[key] } }) => void
  onRemove: () => void
  onAddSmallItem: () => void
}

const Container = styled.div`
  width: 100%;
  > div > label {
    font-weight: bold;
  }
`

const ItemsContainer = styled.div`
  flex: 1;
  display: flex;
`

const Item = styled.div<WidthProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${width}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const NestedSmallItemContainer = styled.div`
  margin: 0 0 0 24px;
`

const shouldShowError = (value: any) => {
  if (typeof value === 'number') {
    return value < 0
  }

  return !value
}

const shouldShowEdcValuePipeError = (value: string) => {
  return value.includes('|')
}

export const ChoiceComponent: React.FC<Props> = props => {
  const {
    choice,
    choiceNumber,
    presetUsed,
    smallItems,
    onChange,
    onRemove,
    onAddSmallItem,
    isClinical,
    releaseTried,
    v2,
  } = props

  return (
    <Container id={choice.uid}>
      <ItemsContainer>
        <Item width={24}>
          <Text fontWeight="bold">{choiceNumber}</Text>
        </Item>

        <Spacer size={8} horizontal />

        <Item>
          {presetUsed && <Value>{choice.description}</Value>}

          {!presetUsed && (
            <Input
              width={'100%'}
              value={choice.description}
              placeholder="選択肢"
              onChange={e =>
                onChange({ values: { description: e.target.value } })
              }
              className={
                releaseTried && shouldShowError(choice.description)
                  ? 'error'
                  : ''
              }
            />
          )}
        </Item>

        <Spacer size={20} horizontal />

        {isClinical && (
          <Item>
            <Input
              width={'100%'}
              value={choice.edcValue}
              placeholder="EDCコード値"
              onChange={e => onChange({ values: { edcValue: e.target.value } })}
              className={
                releaseTried && shouldShowError(choice.edcValue) ? 'error' : ''
              }
            />

            <Spacer size={8} horizontal />
          </Item>
        )}

        <Item>
          <IconButton
            icon={<Delete />}
            aria-label="選択肢を削除"
            onClick={onRemove}
            variant="customIconButtonGhost"
            colorScheme="gray"
          />
        </Item>

        <Spacer size={16} horizontal />

        {!presetUsed && (
          <ButtonContainer>
            <Button
              leftIcon={<Add />}
              variant="outline"
              onClick={onAddSmallItem}
            >
              階層追加
            </Button>
          </ButtonContainer>
        )}
      </ItemsContainer>

      {releaseTried && shouldShowEdcValuePipeError(choice.edcValue) && (
        <div>
          <Spacer size={8} />
          <Message type="error" message="「|」は使用できません。" />
        </div>
      )}

      <Spacer size={20} />

      {smallItems.map(smallItem => (
        <NestedSmallItemContainer key={smallItem.uid}>
          <NestedSmallItem smallItem={smallItem} v2={v2} />
          <Spacer size={10} />
        </NestedSmallItemContainer>
      ))}
    </Container>
  )
}
