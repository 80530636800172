import {
  GqlError,
  GetSessionForPartnerQuery,
  GetSessionForPartnerDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type GetSessionForPartnerRes = GetSessionForPartnerQuery['explanationSession']

const getSessionForPartner = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<GetSessionForPartnerRes> => {
  try {
    const res = await graphqlRequest(GetSessionForPartnerDocument, {
      explanationSessionUid,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionForPartner = ({
  explanationSessionUid,
  ...option
}: {
  explanationSessionUid: string
} & SWRConfiguration<GetSessionForPartnerRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionForPartner(explanationSessionUid),
    () => getSessionForPartner({ explanationSessionUid }),
    option,
  )
}
