import { MediumItemAnswer, MediumItemAnswerStatus } from './entity'

export const getAncestorSmallItemAnswerUids = (
  mediumItemAnswer: MediumItemAnswer,
  smallItemAnswerUid: string,
): string[] => {
  const smallItemAnswer =
    mediumItemAnswer.smallItemAnswerMap[smallItemAnswerUid]
  if (!smallItemAnswer || !smallItemAnswer.parentUid) {
    return []
  }

  return getAncestorSmallItemAnswerUids(
    mediumItemAnswer,
    smallItemAnswer.parentUid,
  ).concat(smallItemAnswer.parentUid)
}

export const getDescendantSmallItemAnswerUids = (
  mediumItemAnswer: MediumItemAnswer,
  smallItemAnswerUid: string,
): string[] => {
  const smallItemAnswer =
    mediumItemAnswer.smallItemAnswerMap[smallItemAnswerUid]
  if (!smallItemAnswer) {
    return []
  }

  const descendantSmallItemAnswerUids = smallItemAnswer.choiceAnswers.flatMap(
    ca => ca.smallItemAnswerUids,
  )
  return descendantSmallItemAnswerUids
    .flatMap(uid => getDescendantSmallItemAnswerUids(mediumItemAnswer, uid))
    .concat(...descendantSmallItemAnswerUids)
}

export const statusToText = (status: MediumItemAnswerStatus) => {
  switch (status) {
    case MediumItemAnswerStatus.New:
      return '新規'
    case MediumItemAnswerStatus.Saved:
      return '一時保存'
    case MediumItemAnswerStatus.Confirmed:
      return '確定'
    case MediumItemAnswerStatus.Disabled:
      return '無効'
    default:
      const n: never = status
      console.error(n)
  }
}
