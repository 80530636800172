import { useCallback, useMemo, useState } from 'react'

import { useCurrentMember } from 'src/features/auth/context'
import { IcfDocumentSet } from 'src/features/icfDocument/types'
import { TrialHospital } from 'src/features/trial/types'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { DeeplyPartial } from 'src/utils/deeplyPartial'
import { generateUUID } from 'src/utils/generateUUID'
import { updateElementAtIndex } from 'src/utils/updateElementAtIndex'
import { ValidationError } from 'yup'

import {
  explanationFormSchema,
  ExplanationFormSchema,
  MemberSchema,
} from '../schema/explanationFormSchema'
import { ExplanationRevisionStatus, ExplanationType } from '../types'
import { getIndexFromObjectString } from '../utils/getIndexFromObjectString'

export type ExplanationFormErrors = {
  [K in keyof Omit<ExplanationFormSchema, 'members'>]?: { message: string }
} & {
  members?: {
    message?: string
    [index: number]: { message: string }
  }
}

export const useExplanationForm = ({
  selectedTrialHospitalUid,
  defaultValue,
  status,
  eConsentNewSignFlow,
  underEditSchedule,
}: {
  selectedTrialHospitalUid: TrialHospital['uid']
  defaultValue: DeeplyPartial<ExplanationFormSchema>
  status: ExplanationRevisionStatus | undefined
  eConsentNewSignFlow: boolean
  underEditSchedule: boolean
}) => {
  const toast = useMirohaToast()

  const { currentMember } = useCurrentMember()

  const schema = explanationFormSchema({
    status,
    phoneNumber: defaultValue.phoneNumber,
    email: defaultValue.email,
    eConsentNewSignFlow: eConsentNewSignFlow,
    underEditSchedule,
  })

  const [formValue, setFormValue] =
    useState<DeeplyPartial<ExplanationFormSchema>>(defaultValue)

  const [errors, setErrors] = useState<ExplanationFormErrors>({})

  const handleSubmit = useCallback(
    async (onSubmit: (formValue: ExplanationFormSchema) => void) => {
      const gotErrors: typeof errors = {}
      try {
        const validated = await schema.validate(formValue, {
          abortEarly: false,
        })
        onSubmit(validated)
      } catch (error) {
        if (error instanceof ValidationError) {
          error.inner.forEach(e => {
            if (!e.path) return
            // 複数バリデーションがある場合は最初のエラーのみを表示する
            if (!!gotErrors[e.path as keyof ExplanationFormSchema]) return
            if (!e.path.includes('.')) {
              gotErrors[e.path as keyof ExplanationFormSchema] = {
                message: e.message,
              }
              return
            }

            // membersについてのみnestされたエラーが存在する。
            // 簡単のため各memberについてObjectで一つのmessageを持つようにする。
            const index = getIndexFromObjectString(e.path)
            if (index === undefined) return

            if (gotErrors['members'] === undefined) gotErrors['members'] = {}
            gotErrors['members'][index] = { message: e.message }
          })
          toast({
            status: 'error',
            title:
              '入力値に誤りがあります。フィールドのエラーを確認してください。',
          })
          return
        }
        throw error
      } finally {
        setErrors(gotErrors)
      }
    },
    [formValue, schema, toast],
  )

  const onChangeCandidateId = useCallback((candidateId: string) => {
    setFormValue(formValue => ({ ...formValue, candidateId }))
  }, [])

  const onChangeDiseaseId = useCallback((diseaseId: string) => {
    setFormValue(formValue => ({ ...formValue, diseaseId }))
  }, [])

  const onChangeEmail = useCallback((email: string) => {
    setFormValue(formValue => ({ ...formValue, email }))
  }, [])

  const onChangePhoneNumber = useCallback((phoneNumber: string) => {
    setFormValue(formValue => ({ ...formValue, phoneNumber }))
  }, [])

  const onChangeDocSetUids = useCallback(
    (docSetUids: IcfDocumentSet['uid'][]) => {
      setFormValue(formValue => ({ ...formValue, docSetUids }))
    },
    [],
  )

  const sortDocSet = useCallback(
    (uid: IcfDocumentSet['uid'], direction: 'up' | 'down'): void => {
      const docSetUids = formValue.docSetUids
      if (!docSetUids) return
      const index = docSetUids.indexOf(uid)
      if (
        index === -1 ||
        (direction === 'up' && index === 0) ||
        (direction === 'down' && index === docSetUids.length - 1)
      ) {
        return
      }

      const newUids = [...docSetUids]
      if (direction === 'up') {
        ;[newUids[index - 1], newUids[index]] = [
          newUids[index],
          newUids[index - 1],
        ]
      }
      if (direction === 'down') {
        ;[newUids[index], newUids[index + 1]] = [
          newUids[index + 1],
          newUids[index],
        ]
      }

      setFormValue(formValue => ({ ...formValue, docSetUids: newUids }))
    },
    [formValue.docSetUids],
  )

  const onChangeType = useCallback((type: ExplanationType) => {
    setFormValue(formValue => ({ ...formValue, type }))
  }, [])

  const onChangePartnerTrialHospitalUid = useCallback(
    (partnerTrialHospitalUid: TrialHospital['uid'] | undefined) => {
      setFormValue(formValue => ({ ...formValue, partnerTrialHospitalUid }))
    },
    [],
  )

  const onChangeScheduledAt = useCallback((scheduledAt: string) => {
    setFormValue(formValue => ({ ...formValue, scheduledAt }))
  }, [])

  const onChangeNotifyToPatient = useCallback((notifyToPatient: boolean) => {
    setFormValue(formValue => ({ ...formValue, notifyToPatient }))
  }, [])

  const defaultMember: DeeplyPartial<MemberSchema> = useMemo(
    () => ({
      id: generateUUID(),
      trialHospitalUid: selectedTrialHospitalUid, // memberを追加するときは所属する医療機関をデフォルト選択
      member: formValue.members?.length === 0 ? currentMember : undefined,
    }),
    [formValue.members, currentMember, selectedTrialHospitalUid],
  )

  const appendMember = useCallback(() => {
    setFormValue(formValue => ({
      ...formValue,
      members: [...(formValue.members ?? []), defaultMember],
    }))
  }, [setFormValue, defaultMember])

  const removeMember = useCallback((index: number) => {
    setFormValue(formValue => ({
      ...formValue,
      members: formValue.members?.filter((_, i) => i !== index),
    }))
  }, [])

  const resetMembers = useCallback(() => {
    setFormValue(formValue => ({
      ...formValue,
      members: [],
    }))
  }, [])

  const onChangeMember = useCallback(
    ({
      index,
      member,
    }: {
      index: number
      member: DeeplyPartial<MemberSchema>
    }) => {
      setFormValue(formValue => {
        if (!formValue.members) return formValue
        const newMember = { ...formValue.members[index], ...member }
        return {
          ...formValue,
          members: updateElementAtIndex(formValue.members, index, newMember),
        }
      })
    },
    [],
  )

  return {
    formValue,
    errors,
    handleSubmit,
    onChangeCandidateId,
    onChangeDiseaseId,
    onChangeEmail,
    onChangePhoneNumber,
    onChangeDocSetUids,
    sortDocSet,
    onChangeType,
    onChangePartnerTrialHospitalUid,
    onChangeScheduledAt,
    onChangeNotifyToPatient,
    appendMember,
    removeMember,
    resetMembers,
    onChangeMember,
  }
}
