import {
  GetActiveMembersByTrialHospitalUidsDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { Member } from '../../types'
import { memberCacheKey } from '../cacheKey'
import { parseGqlMember } from '../parser'

const getActiveMembersByTrialHospitalUids = async ({
  trialHospitalUids,
}: {
  trialHospitalUids: string[]
}): Promise<Member[]> => {
  try {
    const res = await graphqlRequest(
      GetActiveMembersByTrialHospitalUidsDocument,
      {
        trialHospitalUids,
      },
    )
    return res.activeTrialMembersByTrialHospitalUids.map(parseGqlMember)
  } catch (e) {
    throw e
  }
}

export const useActiveMembersByTrialHospitalUids = ({
  trialHospitalUids,
  shouldCancel,
  ...option
}: {
  trialHospitalUids: string[]
  shouldCancel?: boolean
} & SWRConfiguration<Member[], GqlError>) => {
  return useSWR<Member[], GqlError>(
    shouldCancel
      ? null
      : memberCacheKey.getActiveMembersByTrialHospitalUids(trialHospitalUids),
    () => getActiveMembersByTrialHospitalUids({ trialHospitalUids }),
    option,
  )
}
