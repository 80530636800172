import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const signInPatient = async ({
  trialUid,
  explanationRevisionUid,
}: {
  trialUid: string
  explanationRevisionUid: string
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/signin_patient',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useSignInPatient = (option?: UseMutationOption<void>) => {
  return useMutation(
    ({
      trialUid,
      explanationRevisionUid,
    }: {
      trialUid: string
      explanationRevisionUid: string
    }) => signInPatient({ trialUid, explanationRevisionUid }),
    option,
  )
}
