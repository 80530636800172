// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgMicOn = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M8 10c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3S5 1.3 5 3v4c0 1.7 1.3 3 3 3Z"
    />
    <path
      fill={props.color ?? 'currentColor'}
      d="M14 7c0-.6-.4-1-1-1s-1 .4-1 1c0 2.2-1.8 4-4 4S4 9.2 4 7c0-.6-.4-1-1-1s-1 .4-1 1c0 3 2.2 5.4 5 5.9V14H5c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1H9v-1.1c2.8-.5 5-2.9 5-5.9Z"
    />
  </svg>
)
export default SvgMicOn
