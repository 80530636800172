import React, { useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Document, Page } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { SizeMe } from 'react-sizeme'

type Props = {
  url: string
  isSPScreen?: boolean
}

// 日本語がレンダリングできないなどのケース防ぐための設定。 cMapUrl は publicディレクトリ配下の camps ディレクトリを指している
const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

export const LegacyPdfViewer: React.FC<Props> = ({
  url,
  isSPScreen = false,
}) => {
  const [numPages, setNumPages] = useState<number>()
  const [scale, setScale] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const onChangeScale = (action: 'zoomIn' | 'zoomOut') => {
    setScale(current => (action === 'zoomIn' ? current + 0.1 : current - 0.1))
  }

  return (
    <Flex direction="column" h="full" w="full" bg="gray.100">
      <Flex bg="gray.600" w="full" h="56px" justify="center">
        <HStack spacing="16px">
          <HStack spacing="4">
            <Button
              aria-label="zoom out"
              colorScheme="gray"
              onClick={() => onChangeScale('zoomOut')}
              minW="unset"
              variant="ghost"
              borderRadius="base"
              w="32px"
              h="32px"
              p="0"
              color="white"
              _hover={{ bg: 'gray.500' }}
              _active={{ bg: 'gray.500' }}
            >
              ー
            </Button>
            <Text color="white">{`${Math.floor(scale * 100)}%`}</Text>
            <Button
              aria-label="zoom out"
              colorScheme="gray"
              onClick={() => onChangeScale('zoomIn')}
              minW="unset"
              variant="ghost"
              borderRadius="base"
              w="32px"
              h="32px"
              p="0"
              color="white"
              _hover={{ bg: 'gray.500' }}
              _active={{ bg: 'gray.500' }}
            >
              ＋
            </Button>
          </HStack>
        </HStack>
      </Flex>

      <Box overflow="scroll" py={isSPScreen ? '0' : '6'} flex="1">
        {isSPScreen ? (
          <SizeMe monitorHeight refreshRate={128} refreshMode={'debounce'}>
            {({ size }) => (
              <PdfDocumentContent
                url={url}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                scale={scale}
                width={size.width ?? undefined}
              />
            )}
          </SizeMe>
        ) : (
          <PdfDocumentContent
            url={url}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            numPages={numPages}
            scale={scale}
          />
        )}
      </Box>
    </Flex>
  )
}

const PdfDocumentContent = ({
  url,
  onDocumentLoadSuccess,
  numPages,
  scale,
  width,
}: {
  url: string
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void
  numPages: number | undefined
  scale: number
  width?: number
}) => (
  <Document
    file={url}
    options={options}
    onLoadSuccess={onDocumentLoadSuccess}
    loading={<MessageComponent message="ファイルを読み込んでいます" />}
    error={<MessageComponent message="ファイルの読み込みに失敗しました" />}
    // NOTE: URLがから文字の場合などにnodataとなる。ユーザーに見せる必要のない情報なので、エラーと同じメッセージを表示する
    noData={<MessageComponent message="ファイルの読み込みに失敗しました" />}
    onLoadError={e => {
      const err = new Error(
        `failed to load PDF in LegacyPDFViewer: "${
          e.message
        }", url: "${url}", options: ${JSON.stringify(options)}`,
      )
      Sentry.captureException(err)
      console.error(err)
    }}
  >
    <VStack
      spacing="6"
      h="full"
      overflow={width !== undefined ? 'hidden' : undefined}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          width={width}
          scale={scale}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          loading=""
        />
      ))}
    </VStack>
  </Document>
)

const MessageComponent: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Center w="full" h="full">
      <p>{message}</p>
    </Center>
  )
}
