import { useMemo } from 'react'

import { useRequestState } from 'src/modules/server/use-request-state'
import { swrKeys } from 'src/modules/swr/key'
import useSWR from 'swr'

import { CrfDownloadJob } from './entity'
import { createDownloadJob, fetchList } from './request'

export const useDownloadCrf = ({ trialUid }: { trialUid: string }) => {
  const { data, mutate, error } = useSWR<CrfDownloadJob[], Error>(
    swrKeys.fetchCrfDownloadJobs,
    () => fetchList({ trialUid }),
  )

  const createRequestState = useRequestState()
  const createCrfDownloadJob = useMemo(() => {
    return {
      request: async () => {
        try {
          createRequestState.requestStarted()
          const res = await createDownloadJob({ trialUid })
          mutate(data ? [...data, res] : [res], false) // 本来は再検証したほうが良さそう（第2引数をtrueにする）
          createRequestState.requestDone()
        } catch (error) {
          createRequestState.requestFailed(error.message)
          throw error
        }
      },
      requesting: createRequestState.requesting,
      errorMessage: createRequestState.errorMessage,
    }
  }, [createRequestState, mutate, trialUid, data])

  const registeredAtDesc = (a: CrfDownloadJob, b: CrfDownloadJob) =>
    new Date(b.registeredAt).getTime() - new Date(a.registeredAt).getTime()

  return {
    crfDownloadJobList: data ? data.sort(registeredAtDesc) : undefined,
    fetchError: error,

    createCrfDownloadJob,
  }
}
