import { CreateIcfDocumentSetItem, UpdateIcfDocumentSetItem } from './entity'

export type IcfDocumentSetErrors = Partial<{
  name: string
  documents: string
}>

export const validateIcfDocumentSet = (
  item: CreateIcfDocumentSetItem | UpdateIcfDocumentSetItem,
): IcfDocumentSetErrors | null => {
  const errors: IcfDocumentSetErrors = {}

  const maxNameLength = 100
  const maxDocumentCount = 20

  if (item.name === '') {
    errors.name = '文書セット名は必須です'
  }
  if (item.name.length > maxNameLength) {
    errors.name = `文書セット名は${maxNameLength}文字以内で入力してください`
  }
  if (item.icfDocuments.length > maxDocumentCount) {
    errors.documents = `登録可能な文書の上限は${maxDocumentCount}件です`
  }

  return Object.keys(errors).length > 0 ? errors : null
}
