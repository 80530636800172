import produce from 'immer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'

import { Worksheet, WorksheetStatus } from './entity'
import { fetchList, fetchSingle } from './request'
import { actions as worksheetDetailActions } from '../../dashboard/trial/detail/patient/detail/worksheet/common/detail/redux'
import { RootState } from '../../reducer'
import { MediumItemAnswerStatus } from '../medium-item-answer/entity'
import { SmallItemAnswer } from '../small-item-answer/entity'

const create = actionCreatorFactory('entities/worksheet')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  fetchList: createAsync<
    {
      trialUid: string
      patientUid: string
    },
    Worksheet[],
    { message: string }
  >('FETCH_LIST', async (params, dispatch, getState) => {
    return await fetchList(params)
  }),
  fetchSingle: createAsync<
    {
      trialUid: string
      patientUid: string
      worksheetUid: string
    },
    Worksheet,
    { message: string }
  >('FETCH_SINGLE', async (params, dispatch, getState) => {
    return await fetchSingle(params)
  }),

  upsert: create<Worksheet>('UPSERT'),
  updateSmallItemAnswers: create<{
    worksheetUid: string
    mediumItemAnswerUid: string
    smallItemAnswers: SmallItemAnswer[]
  }>('UPDATE_SMALL_ITEM_ANSWERS'),

  delete: create<{ uid: string }>('DELETE'),
}

export type State = {
  byId: Record<Worksheet['uid'], Worksheet>
}

const initialState: State = {
  byId: {},
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.fetchList.async.done, (state, payload) =>
    produce(state, draft => {
      const newWorksheets: Record<Worksheet['uid'], Worksheet> = {}
      payload.result.forEach(t => {
        const ms = draft.byId[t.uid] ? draft.byId[t.uid].mediumItemAnswers : []
        newWorksheets[t.uid] = {
          ...t,
          mediumItemAnswers: ms,
        }
      })

      draft.byId = newWorksheets
    }),
  )
  .case(actions.fetchSingle.async.done, (state, payload) =>
    produce(state, draft => {
      const worksheet = payload.result
      draft.byId[worksheet.uid] = worksheet
    }),
  )
  .case(actions.upsert, (state, payload) =>
    produce(state, draft => {
      const worksheet = payload
      draft.byId[worksheet.uid] = worksheet
    }),
  )
  .case(actions.updateSmallItemAnswers, (state, payload) =>
    produce(state, draft => {
      const worksheet = draft.byId[payload.worksheetUid]
      const mediumItemAnswer = worksheet.mediumItemAnswers.find(
        mia => mia.uid === payload.mediumItemAnswerUid,
      )!

      payload.smallItemAnswers.forEach(sia => {
        mediumItemAnswer.smallItemAnswerMap[sia.uid] = sia
      })
    }),
  )
  .case(worksheetDetailActions.addLogline, (state, payload) =>
    produce(state, draft => {
      const { logline } = payload
      const worksheet = draft.byId[logline.worksheetUid]
      worksheet.mediumItemAnswers.push(logline)
    }),
  )

  .case(
    worksheetDetailActions.submitUpdateSmallItemAnswers.async.done,
    (state, payload) =>
      produce(state, draft => {
        const { mediumItemAnswer } = payload.params
        const worksheet = draft.byId[mediumItemAnswer.worksheetUid]
        if (!worksheet) return

        if (worksheet.status === WorksheetStatus.New) {
          worksheet.status = WorksheetStatus.Saved
        }

        const writableMediumItemAnswer = worksheet.mediumItemAnswers.find(
          mia => mia.uid === mediumItemAnswer.uid,
        )!
        if (!writableMediumItemAnswer) return

        payload.result.smallItemAnswers.forEach(sia => {
          writableMediumItemAnswer.smallItemAnswerMap[sia.uid] = sia
        })
        writableMediumItemAnswer.status = MediumItemAnswerStatus.Saved
        writableMediumItemAnswer.confirmationStatus = null

        if (!writableMediumItemAnswer.hasLog) {
          writableMediumItemAnswer.hasLog = true
        }
      }),
  )
  .case(actions.delete, (state, payload) =>
    produce(state, draft => {
      delete draft.byId[payload.uid]
    }),
  )
  .build()
