import { useState } from 'react'

import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Modal,
  ModalOverlay,
  Stack,
  Box,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import PinInputImage from 'src/assets/image/pin-input.svg'
import { PinInput } from 'src/components/PinInput/PinInput'
import { useVerifyPin } from 'src/features/explanation/api/verifyPin'
import { PATIENT_PIN_LENGTH } from 'src/features/explanation/constants/pinSetting'
import { getVerifyPinErrorMessage } from 'src/features/explanation/utils/getVerifyPinErrorMessage'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useRequestSign } from '../../context/ExplanationRoomEvent'

type Props = {
  trialUid: string
  explanationSessionUid: string
  isSP: boolean
  roomTooPath: string
  challengeSignAuth: () => void
}

export const PinVerificationModal = ({
  trialUid,
  explanationSessionUid,
  isSP,
  roomTooPath,
  challengeSignAuth,
}: Props) => {
  const toast = useMirohaToast()
  const [pinCode, setPinCode] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const { request } = useVerifyPin({
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
    onSuccess: ({ result }) => {
      const errorMessage = getVerifyPinErrorMessage(result)
      if (errorMessage !== undefined) {
        setPinCode('')
        setErrorMessage(errorMessage)
        return
      }
      // challengeを再実行してstepを更新
      challengeSignAuth()
    },
  })

  const onChange = (value: string) => {
    if (errorMessage !== undefined) {
      setErrorMessage(undefined)
    }
    setPinCode(value)
  }

  const canConfirm =
    pinCode?.length === PATIENT_PIN_LENGTH && errorMessage === undefined

  const navigate = useNavigate()

  const { cancelRequestSignBySessionUid } = useRequestSign()

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        cancelRequestSignBySessionUid(explanationSessionUid)
        navigate(roomTooPath)
      }}
      size={isSP ? 'xs' : 'xl'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        {isSP && (
          <Box mb="4">
            <img src={PinInputImage} alt="暗証番号の入力" />
          </Box>
        )}
        <ModalCloseButton />
        <ModalHeader
          textAlign="center"
          fontSize={isSP ? 'lg' : '2xl'}
          px={isSP ? '4' : undefined}
        >
          {isSP
            ? `署名を開始するため\n当人認証を行います`
            : '署名を開始するため当人認証を行います'}
        </ModalHeader>
        <ModalBody mt="12" px={isSP ? '4' : undefined}>
          <Stack spacing="12">
            <Text
              fontSize={isSP ? 'md' : 'lg'}
              display="inline-block"
              mx="auto"
            >
              はじめに4ケタの暗証番号を入力してください。
            </Text>
            <Stack align="center" spacing="6">
              <PinInput
                length={PATIENT_PIN_LENGTH}
                size={isSP ? 'md' : 'lg'}
                value={pinCode}
                onChange={onChange}
              />
              {errorMessage !== undefined && (
                <Text color="red.500" display="inline-block" mx="auto">
                  {errorMessage}
                </Text>
              )}
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          mt="12"
          px={isSP ? '4' : undefined}
        >
          <Button
            // スマホ向けのボタンサイズはvariantで定義しているがそれ以外の患者UIは独自定義
            size={isSP ? 'sp' : undefined}
            w={isSP ? undefined : '200px'}
            h={isSP ? undefined : '64px'}
            fontSize="xl"
            colorScheme="green"
            onClick={() => {
              if (!pinCode) return
              request({
                pin: pinCode,
                explanationSessionUid,
                trialUid: trialUid,
              })
            }}
            isDisabled={!canConfirm}
          >
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
