import { ButtonLink } from 'src/components/base/text-link'
import { useOpenModal } from 'src/hooks/use-open-modal'

import { MediumItemAnswerLogModalContainer } from '../modal/modal-container'

type Props = {
  mediumItemAnswerUid: string
  edcLinked: boolean
}

export const MediumItemAnswerLogButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <>
      <ButtonLink onClick={openModal}>変更履歴</ButtonLink>

      {modalOpen && (
        <MediumItemAnswerLogModalContainer
          onClose={closeModal}
          mediumItemAnswerUid={props.mediumItemAnswerUid}
          edcLinked={props.edcLinked}
        />
      )}
    </>
  )
}
