import React from 'react'

import dayjs from 'dayjs'
import { SmallItemAnswerLog } from 'src/modules/dashboard/trial/detail/patient/detail/worksheet/common/detail/output/pdf/entity'
import { MediumItemAnswer } from 'src/modules/entities/medium-item-answer/entity'
import styled from 'styled-components'

type Props = {
  history: SmallItemAnswerLog
  mediumItemAnswer: MediumItemAnswer
}

export const History: React.FC<Props> = props => {
  const { history, mediumItemAnswer } = props
  const sectionInfo = mediumItemAnswer.isLogline
    ? `${mediumItemAnswer.title}  line${mediumItemAnswer.lineNumber}`
    : `${mediumItemAnswer.title}`

  return (
    <Container>
      <EditorText>
        {dayjs(history.createdAt).format('YYYY年MM月DD日 HH:mm')}
        {'　'}
        {history.userName}
      </EditorText>

      <HistoryText>
        <HistoryTitle>変更箇所：</HistoryTitle>
        <HistoryValue>{`[${sectionInfo}] ${history.title}`}</HistoryValue>
      </HistoryText>

      <HistoryText>
        <HistoryTitle>変更理由：</HistoryTitle>
        <HistoryValue>{history.reason}</HistoryValue>
      </HistoryText>

      <HistoryText>
        <HistoryTitle>変更前：</HistoryTitle>
        <HistoryValue>{history.oldValue}</HistoryValue>
      </HistoryText>

      <HistoryText>
        <HistoryTitle>変更後：</HistoryTitle>
        <HistoryValue>{history.newValue}</HistoryValue>
      </HistoryText>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  border-bottom: 0.5px dashed #000000;
  &:last-child {
    border: none;
  }
`

const EditorText = styled.div`
  width: 100%;
  padding: 10px 0;
  margin: 0;
  font-weight: bold;
  font-size: 9px;
`

const HistoryText = styled.p`
  width: 100%;
  display: flex;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0;
  font-weight: 500;
  font-size: 9px;
`

const HistoryTitle = styled.p`
  margin: 0;
`

const HistoryValue = styled.p`
  margin: 0;
  white-space: pre-wrap;
  flex: 1;
  word-break: break-all;
`
