import {
  GqlError,
  GetNotificationsByGroupKeyQuery,
  GetNotificationsByGroupKeyDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { miscCacheKey } from '../cacheKey'

type GetNotificationReturnType =
  GetNotificationsByGroupKeyQuery['notificationsByGroupKey']['edges']

export const getNotificationsByGroupKey = async (input: {
  after: string
  groupKey: string
}): Promise<GetNotificationReturnType> => {
  const res = await graphqlRequest(GetNotificationsByGroupKeyDocument, {
    input,
  })
  return res.notificationsByGroupKey.edges
}

export const useGetNotificationsByGroupKey = ({
  shouldCancel,
  after,
  groupKey,
  ...option
}: {
  shouldCancel: boolean
  after: string
  groupKey: string
} & SWRConfiguration<GetNotificationReturnType, GqlError>) => {
  return useSWR<GetNotificationReturnType, GqlError>(
    shouldCancel
      ? null
      : miscCacheKey.getNotificationsByGroupKey({ after, groupKey }),
    () => getNotificationsByGroupKey({ after, groupKey }),
    option,
  )
}
