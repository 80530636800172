import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { formatDate } from 'src/utils/formatDate'

import {
  GetPatientVideoCallsForHistoryReturn,
  usePatientVideoCallsForHistory,
} from '../../api'

type Props = {
  patientUid: string
}

export const PatientVideoCallHistory: React.FC<Props> = ({ patientUid }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: patientVideoCalls } = usePatientVideoCallsForHistory({
    patientUid,
    order: 'CreatedAtDesc',
  })

  if (!patientVideoCalls) return null

  // 必要？
  // if (videoCalls.length === 0) return null

  const getHistoryText = (
    call: GetPatientVideoCallsForHistoryReturn[number],
  ): string => {
    const callStartedAt = call.videoCallRoom.callStartedAt
    const callEndedAt = call.videoCallRoom.callEndedAt

    const from = !!callStartedAt
      ? formatDate(callStartedAt, 'YYYY/MM/DD (ddd) HH:mm')
      : ''
    const to = !!callEndedAt ? formatDate(callEndedAt, 'HH:mm') : ''

    return `${from} - ${to}`
  }

  return (
    <>
      <Button variant="text" onClick={onOpen}>
        通話履歴
      </Button>

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontWeight="bold" fontSize="lg" as="h2">
              通話履歴
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>通話日時</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {patientVideoCalls.map(patientVideoCall => (
                    <Tr key={patientVideoCall.patientVideoCallUid}>
                      <Td>{getHistoryText(patientVideoCall)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
