import {
  Box,
  Checkbox,
  Flex,
  HStack,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  DateTypeDef,
  Field,
  NullableDateTypeDef,
  NumberTypeDef,
  TextareaTypeDef,
  TextTypeDef,
  TimeTypeDef,
} from '@micin-jp/chicken-schema'
import {
  HasValidationTypeDef,
  hasValidationTypeDef,
  isChoiceTypeDef,
  typeString,
} from 'src/lib/chicken-schema/utils'
import { assertNever } from 'src/utils/assertNever'
import { formatDate } from 'src/utils/formatDate'

import { Linkify } from '../../../../components/Linkify/Linkify'

type Props = {
  field: Field
}

export const FieldViewer: React.FC<Props> = ({ field }) => {
  return (
    <Box
      as="section"
      aria-label={field.name}
      p="5"
      bg="white"
      borderRadius="base"
      border="1px solid"
      borderColor="gray.100"
    >
      <Flex direction="column" gap="6">
        <Dl>
          <Dt>フィールド名</Dt>
          <Dd>{field.name}</Dd>
        </Dl>
        {!!field.description && (
          <Dl>
            <Dt>フィールドの説明</Dt>
            <Dd>{field.description}</Dd>
          </Dl>
        )}
        <Dl>
          <Dt>回答タイプ</Dt>
          <Dd>
            {field.typeDef.type === 'File' && field.typeDef.isCertifiedCopy
              ? `${typeString(field.typeDef.type)}（Certified Copyとして扱う）`
              : typeString(field.typeDef.type)}
          </Dd>
        </Dl>
        {isChoiceTypeDef(field.typeDef) && (
          <Dl>
            <Dt>回答の選択肢</Dt>
            <Dd>
              <OrderedList>
                {field.typeDef.choices.map(choice => (
                  <ListItem key={choice.cid}>
                    <HStack spacing="4" align="center">
                      <span>{choice.name}</span>
                      {choice.validation?.required && (
                        <Checkbox isChecked isDisabled wordBreak="keep-all">
                          必須
                        </Checkbox>
                      )}
                    </HStack>
                  </ListItem>
                ))}
              </OrderedList>
            </Dd>
          </Dl>
        )}
        {field.typeDef.type === 'Number' && !!field.typeDef.unit && (
          <Dl>
            <Dt>数値の単位</Dt>
            <Dd>{field.typeDef.unit}</Dd>
          </Dl>
        )}
        {field.typeDef.type === 'Label' && !!field.typeDef.content && (
          <Dl>
            <Dt>本文テキスト</Dt>
            <Dd>
              <Linkify>{field.typeDef.content}</Linkify>
            </Dd>
          </Dl>
        )}
        {hasValidationTypeDef(field.typeDef) && !!field.typeDef.validation && (
          <FieldValidationViewer typeDef={field.typeDef} />
        )}
      </Flex>
    </Box>
  )
}

const Dl: React.FC<{
  children: React.ReactNode
  direction?: 'column' | 'row'
}> = ({ children, direction = 'column' }) => (
  <Stack
    direction={direction}
    spacing="2"
    as="dl"
    align={direction === 'column' ? 'start' : 'center'}
  >
    {children}
  </Stack>
)
const Dt: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Text as="dt" fontSize="sm" fontWeight="bold" color="gray.600">
    {children}
  </Text>
)
const Dd: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Flex as="dd" whiteSpace="pre-wrap" align="center">
    {children}
  </Flex>
)

const FieldValidationViewer: React.FC<{ typeDef: HasValidationTypeDef }> = ({
  typeDef,
}) => {
  return (
    <Flex
      px="5"
      py="2"
      wrap="wrap"
      columnGap="8"
      rowGap="2"
      bg="gray.50"
      border="1px"
      borderColor="gray.100"
      borderRadius="base"
    >
      <TypeDefValidationComponent typeDef={typeDef} />
    </Flex>
  )
}

const TypeDefValidationComponent: React.FC<{
  typeDef: HasValidationTypeDef
}> = ({ typeDef }) => {
  switch (typeDef.type) {
    case 'Text':
    case 'Textarea':
      return <TextTypeValidationViewer validation={typeDef.validation} />
    case 'Date':
    case 'NullableDate':
      return <DateTypeValidationViewer validation={typeDef.validation} />
    case 'Time':
      return <TimeTypeValidationViewer validation={typeDef.validation} />
    case 'Number':
      return <NumberTypeValidationViewer validation={typeDef.validation} />
    case 'Checkbox':
    case 'Radio':
    case 'SelectMenu':
    case 'File':
      return <RequiredViewer required={typeDef.validation?.required ?? false} />
    default:
      return assertNever(typeDef)
  }
}

const RequiredViewer: React.FC<{ required: boolean }> = ({ required }) => {
  return (
    <Dl direction="row">
      <Dt>必須</Dt>
      <Dd>
        <Checkbox isDisabled isChecked={required} />
      </Dd>
    </Dl>
  )
}

type TextTypeValidation =
  | TextTypeDef['validation']
  | TextareaTypeDef['validation']
const TextTypeValidationViewer: React.FC<{
  validation: TextTypeValidation
}> = ({ validation }) => {
  return (
    <>
      <RequiredViewer required={validation?.required ?? false} />
      {!!validation?.max && (
        <Dl direction="row">
          <Dt>上限文字数</Dt>
          <Dd>{validation?.max ?? ''}</Dd>
        </Dl>
      )}
    </>
  )
}

type NumberTypeValidation = NumberTypeDef['validation']
const NumberTypeValidationViewer: React.FC<{
  validation: NumberTypeValidation
}> = ({ validation }) => {
  return (
    <>
      <RequiredViewer required={validation?.required ?? false} />
      {(validation?.min || validation?.max) && (
        <Dl direction="row">
          <Dt>入力可能範囲（最小〜最大）</Dt>
          <Dd>
            {validation?.min ?? ''} 〜 {validation?.max ?? ''}
          </Dd>
        </Dl>
      )}
    </>
  )
}

type DateTypeValidation =
  | DateTypeDef['validation']
  | NullableDateTypeDef['validation']
const DateTypeValidationViewer: React.FC<{
  validation: DateTypeValidation
}> = ({ validation }) => {
  return (
    <>
      <RequiredViewer required={validation?.required ?? false} />
      {(validation?.min || validation?.max) && (
        <Dl direction="row">
          <Dt>入力可能範囲（最小〜最大）</Dt>
          <Dd>
            {validation?.min ? formatDate(validation.min, 'YYYY/MM/DD') : ''} 〜{' '}
            {validation?.max ? formatDate(validation.max, 'YYYY/MM/DD') : ''}
          </Dd>
        </Dl>
      )}
    </>
  )
}

type TimeTypeValidation = TimeTypeDef['validation']
const TimeTypeValidationViewer: React.FC<{
  validation: TimeTypeValidation
}> = ({ validation }) => {
  return (
    <>
      <RequiredViewer required={validation?.required ?? false} />
      {(validation?.min || validation?.max) && (
        <Dl direction="row">
          <Dt>入力可能範囲（最小〜最大）</Dt>
          <Dd>
            {validation?.min ?? ''} 〜 {validation?.max ?? ''}
          </Dd>
        </Dl>
      )}
    </>
  )
}
