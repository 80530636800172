import { Box, Flex, Link } from '@chakra-ui/react'
import { useAtomValue } from 'jotai'
import { HashLink } from 'react-router-hash-link'
import { editingSchemaAtom } from 'src/lib/chicken-schema/atom'

import { SortFieldButton } from '../SortFieldButton/SortFieldButton'

type Props = {
  sortable?: boolean
}

export const SectionNavigation: React.FC<Props> = ({ sortable }) => {
  // NOTE: 編集中も参照のみの場合も表示するのでeditingSchemaAtomを使う
  const schema = useAtomValue(editingSchemaAtom)
  return (
    <Flex
      px="4"
      py="2"
      bg="blue.50"
      borderRadius="sm"
      justify="space-between"
      align="center"
      gap={10}
    >
      <Flex gap={6} minH="1.5rem">
        {schema.fields
          .filter(
            ({ typeDef: { type } }) =>
              type === 'Section' || type === 'RepeatableSection',
          )
          .map(section => (
            <Flex key={section.fid} align="center" gap={1}>
              <Box h="20px" minW="4px" bg="blue.300" />
              <Link
                key={section.fid}
                as={HashLink}
                smooth
                to={`#${section.fid}`}
                color="blue.500"
              >
                {section.name}
              </Link>
            </Flex>
          ))}
      </Flex>
      {sortable && <SortFieldButton />}
    </Flex>
  )
}
