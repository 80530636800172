import React, { useContext } from 'react'

import { Box, HStack, IconButton, Stack, Text } from '@chakra-ui/react'
import { ArrowUp, ArrowDown } from 'src/components/icon'
import {
  DataType,
  ItemType,
  SmallItem,
} from 'src/modules/entities/small-item/entity'
import {
  itemTypeToDateFormat,
  itemTypeToTextLabel,
} from 'src/modules/entities/small-item/util'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import { itemTypeToText, rolesToText } from 'src/modules/text'
import { SpaceProps } from 'styled-system'

import { VisibilityIcon } from './common/visibility-icon'
import { SortOnlyChoiceComponent } from './components/sort-only-choice'
import { CardContext, CardContextValue } from './context'
import {
  hasChoice,
  isDateAndTimeType,
  isLabelTypeWithEdc,
  isTextType,
} from '../../../../../../../util/item-type-classification'

type Props = Omit<
  {
    smallItem: SmallItem
    orderEditable: boolean
    canEditTemplate: boolean
    templateStatus: TemplateStatus
    v2: boolean
    canChangeSmallItemIndex: ({ smallItemUid }: { smallItemUid: string }) => {
      canUp: boolean
      canDown: boolean
    }
  } & CardContextValue,
  'isClinicalInParent'
>

export const SortOnlyCard: React.FC<Props & SpaceProps> = props => {
  const {
    releaseTried,
    smallItem,
    smallItemMap,
    canEditTemplate,
    v2,
    onAddChoice,
    onRemoveSmallItem,
    onRemoveChoice,
    onChangeSmallItem,
    onChangeSmallItemIndex,
    onChangeChoice,
    onAddSmallItemToChoice,
    canChangeSmallItemIndex,
    orderEditable,
  } = props

  const { canUp, canDown } = canChangeSmallItemIndex({
    smallItemUid: smallItem.uid,
  })

  return (
    <CardContext.Provider
      value={{
        isClinicalInParent: smallItem.dataType === DataType.Clinical,
        releaseTried,
        smallItemMap,
        onChangeSmallItem,
        onChangeSmallItemIndex,
        onRemoveSmallItem,
        onAddChoice,
        onRemoveChoice,
        onChangeChoice,
        onAddSmallItemToChoice,
      }}
    >
      <HStack spacing="1">
        {!v2 && (
          <Stack>
            <IconButton
              icon={<ArrowUp />}
              variant="customIconButtonGhost"
              aria-label="フィールドを上に移動する"
              isDisabled={!canUp || !orderEditable}
              onClick={() =>
                onChangeSmallItemIndex({
                  smallItemUid: smallItem.uid,
                  direction: 'up',
                })
              }
            />

            <IconButton
              icon={<ArrowDown />}
              variant="customIconButtonGhost"
              aria-label="フィールドを下に移動する"
              isDisabled={!canDown || !orderEditable}
              onClick={() =>
                onChangeSmallItemIndex({
                  smallItemUid: smallItem.uid,
                  direction: 'down',
                })
              }
            />
          </Stack>
        )}
        <Box
          key={smallItem.uid}
          w="full"
          border="1px"
          borderRadius="base"
          borderColor="gray.100"
          p="5"
        >
          <Stack spacing="5">
            <LabelAndValue label="フィールド名" value={smallItem.title} />

            {!v2 && (
              <HStack spacing="12" align="start">
                <LabelAndValue
                  label="ロール"
                  value={rolesToText(smallItem.inputRoles)}
                />

                <LabelAndValue
                  label="EDC連携"
                  value={
                    smallItem.dataType === DataType.Clinical ? '有り' : '無し'
                  }
                />

                {canEditTemplate &&
                  props.templateStatus !== TemplateStatus.Disabled && (
                    <Stack>
                      <Text fontWeight="bold" fontSize="sm" color="gray.600">
                        医療機関設定
                      </Text>
                      <VisibilityIcon smallItem={smallItem} size={'small'} />
                    </Stack>
                  )}
              </HStack>
            )}

            <HStack spacing="12">
              {smallItem.dataType === DataType.Clinical && (
                <EDCItems smallItem={smallItem} />
              )}
              <LabelAndValue
                label="フィールドの説明"
                value={smallItem.description}
              />
            </HStack>

            <HStack spacing="12">
              <LabelAndValue
                label="回答タイプ"
                value={
                  smallItem.itemType === ItemType.File &&
                  smallItem.isCertifiedCopy
                    ? `${itemTypeToText(smallItem.itemType)}（Certified Copyとして扱う）`
                    : itemTypeToText(smallItem.itemType)
                }
              />

              {isDateAndTimeType(smallItem) && (
                <LabelAndValue
                  label="フォーマット"
                  value={itemTypeToDateFormat(smallItem.itemType)}
                />
              )}

              {isTextType(smallItem) && (
                <LabelAndValue
                  label="フォーマット"
                  value={itemTypeToTextLabel(smallItem.itemType)}
                />
              )}
            </HStack>

            {hasChoice(smallItem) && (
              <OptionsItem smallItem={smallItem} v2={v2} />
            )}

            {smallItem.itemType === ItemType.Number && (
              <LabelAndValue
                label="入力する数値の単位"
                value={smallItem.unit}
              />
            )}

            {!v2 && isLabelTypeWithEdc(smallItem) && (
              <LabelAndValue
                label="EDC連携テキスト"
                value={smallItem.value ?? ''}
              />
            )}
          </Stack>
        </Box>
      </HStack>
    </CardContext.Provider>
  )
}

type EDCItemsProps = {
  smallItem: SmallItem
}

const EDCItems: React.FC<EDCItemsProps> = EDCItemsProps => {
  const { smallItem } = EDCItemsProps

  return <LabelAndValue label="EDCフィールド名" value={smallItem.edcDataItem} />
}

type OptionsItemProps = {
  smallItem: SmallItem
  v2: boolean
}

const OptionsItem: React.FC<OptionsItemProps> = optionsItemProps => {
  const { smallItem, v2 } = optionsItemProps

  const {
    smallItemMap,
    isClinicalInParent,
    onChangeChoice,
    onRemoveChoice,
    onAddSmallItemToChoice,
  } = useContext(CardContext)
  return (
    <Box>
      <Text fontWeight="bold" fontSize="sm" color="gray.600">
        回答の選択肢
      </Text>
      {smallItem.choices &&
        smallItem.choices.map((choice, choiceIndex) => (
          <SortOnlyChoiceComponent
            key={choice.uid}
            isClinical={isClinicalInParent}
            smallItems={
              choice.smallItemUids
                ? choice.smallItemUids.map(uid => smallItemMap[uid])
                : []
            }
            choice={choice}
            itemType={smallItem.itemType}
            v2={v2}
            onChange={({ values }) =>
              onChangeChoice({
                smallItemUid: smallItem.uid,
                choiceIndex,
                values,
              })
            }
            onRemove={() =>
              onRemoveChoice({ smallItemUid: smallItem.uid, choiceIndex })
            }
            onAddSmallItem={() =>
              onAddSmallItemToChoice({
                smallItemUid: smallItem.uid,
                choiceIndex,
              })
            }
          />
        ))}
    </Box>
  )
}

const LabelAndValue: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <Stack spacing="1">
    <Text fontWeight="bold" fontSize="sm" color="gray.600">
      {label}
    </Text>
    <Text minH="6">{value}</Text>
  </Stack>
)
