import { useParams } from 'react-router-dom'
import { useExplanationForPatient } from 'src/features/explanation/api'

import { ExplanationRoomEventProvider } from '../../context/ExplanationRoomEvent'
import { usePatientLobby } from '../../hooks/usePatientLobby'
import { ExplanationRoomLobbyVideoCallContent } from '../ExplanationRoomLobbyVideoCallContent/ExplanationRoomLobbyVideoCallContent'
import { PatientLobbyLayout } from '../PatientLobbyLayout/PatientLobbyLayout'
import { RedirectToPatientSignIn } from '../RedirectToPatientSignIn/RedirectToPatientSignIn'

type Param = {
  trialUid: string
  explanationRevisionUid: string
}

export const PatientLobbyScreen: React.FC = () => {
  const { trialUid = '', explanationRevisionUid = '' } = useParams<Param>()

  const { preSession, revisionAbout, isUnauthorized, moveToRoom } =
    usePatientLobby({ trialUid, explanationRevisionUid })

  const { data: explanation } = useExplanationForPatient({
    explanationRevisionUid: preSession
      ? preSession.explanationRevision.uid
      : null,
  })

  if (isUnauthorized) {
    return (
      <RedirectToPatientSignIn
        trialUid={trialUid}
        explanationRevisionUid={explanationRevisionUid}
      />
    )
  }

  if (!preSession || !revisionAbout || !explanation) return null

  const sessionUid = preSession.explanationSessionUid

  return (
    <ExplanationRoomEventProvider
      roomType="PreSession"
      preSessionUid={preSession.uid}
      trialUid={trialUid}
      explanationRevisionUid={explanationRevisionUid}
      explanationPatientUid={explanation.patient.uid}
      patientPhoneNumber={explanation.patient.phoneNumber}
      deviceActorType="Patient"
      deviceActorUid={explanation.patient.uid}
      deviceActorTrialHospitalUid={explanation.trialHospital.uid}
    >
      <PatientLobbyLayout
        about={revisionAbout}
        canMoveToRoom={!!sessionUid}
        onMoveToRoom={() => {
          if (!!sessionUid) {
            moveToRoom(sessionUid)
          }
        }}
      >
        <ExplanationRoomLobbyVideoCallContent
          hospitalName={revisionAbout.hospitalName}
          isSessionStarted={!!preSession.explanationSessionUid}
        />
      </PatientLobbyLayout>
    </ExplanationRoomEventProvider>
  )
}
