// Define global style
export const styles = {
  global: {
    'html, body': {
      height: '100%',
      margin: 0,
      padding: 0,
      fontFamily: 'Noto Sans JP, sans-serif',
      fontSize: '14px',
      '@media (min-width: 768px)': {
        fontSize: '16px',
      },
    },
    '#root': {
      width: '100%',
      height: '100%',
      wordBreak: 'break-word',
    },
    'input, textarea, button': {
      fontFamily: 'inherit',
    },
    '::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#939393',
      borderRadius: '10px',
    },

    // HACK: Collapseを内部的に使用しているAccordionコンポーネントでoverflow部分が見切れてしまう問題に対処
    // できればこの対応は避けたいがコンポーネント側で指定することは不可能そうなのでglobalで指定
    // cf) https://github.com/chakra-ui/chakra-ui/issues/2966
    '.chakra-collapse': {
      overflow: 'initial !important',
    },
  },
}
