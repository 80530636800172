import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { PatientPDFData } from '../../detail/output/pdf/entity'
import { fixture } from '../../detail/output/pdf/fixture'
import { parse, PatientPDFDataResponse } from '../../detail/output/pdf/request'

export const fetch = async ({
  trialUid,
}: {
  trialUid: string
}): Promise<PatientPDFData[]> => {
  if (IS_MOCK_MODE) {
    return [fixture, fixture]
  }

  const res = await requestGet<PatientPDFDataResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/worksheets/pdf_data`,
    params: {},
  })

  return res.data.map(parse)
}
