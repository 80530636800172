import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'

import { MediumItemAnswerLogModal } from './modal'
import { patientIdParamName } from '../../../../patient-detail'
import { worksheetIdParamName } from '../../detail/worksheet-detail'
import { MediumItemAnswerLog } from '../entity'
import { fetchList } from '../request'

type Props = {
  onClose: () => void
  mediumItemAnswerUid: string
  edcLinked: boolean
}

export const MediumItemAnswerLogModalContainer: React.FC<Props> = props => {
  const [logs, setLogs] = useState<MediumItemAnswerLog[]>()
  const [fetching, setFetching] = useState(false)
  const { onClose, mediumItemAnswerUid, edcLinked } = props

  const {
    trialUid = '',
    patientUid = '',
    worksheetUid = '',
  } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
    [worksheetIdParamName]: string
  }>()

  useEffect(() => {
    const fetch = async () => {
      const fetched = await fetchList({
        trialUid,
        patientUid,
        worksheetUid,
        mediumItemAnswerUid,
      })
      setLogs(fetched)
      setFetching(false)
    }

    setFetching(true)
    fetch()
  }, [trialUid, patientUid, worksheetUid, mediumItemAnswerUid])

  if (fetching || !logs) {
    return null
  }

  return (
    <MediumItemAnswerLogModal
      onClose={onClose}
      logs={logs}
      edcLinked={edcLinked}
    />
  )
}
