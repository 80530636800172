import { ReactNode } from 'react'

import { Stack, Text } from '@chakra-ui/react'

type Props = {
  body: string
  children?: ReactNode
}

// 画面全体を使ってインフォメーションを表示するコンポーネント。
export const Information: React.FC<Props> = ({ body, children }) => {
  return (
    <Stack
      w="full"
      h="full"
      bgColor="#f8fbfa"
      align="center"
      justify="center"
      spacing="16px"
    >
      <Text>{body}</Text>
      {children}
    </Stack>
  )
}
