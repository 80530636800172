import { HttpResponse } from 'msw'
import {
  createExplanationDocSetMock,
  createExplanationMock,
  createExplanationPatientMock,
  createExplanationRevisionMemberMock,
  createExplanationRevisionMock,
  createHospitalMock,
  createTrialHospitalMock,
  createTrialMemberMock,
  createUserMock,
  GetExplanationDocument,
  GetExplanationQuery,
  GetExplanationQueryVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const getExplanationHandler = graphQLHandlerFactory<
  GetExplanationQuery,
  GetExplanationQueryVariables
>('query', GetExplanationDocument, () => {
  return HttpResponse.json({
    data: {
      explanation: createExplanationMock({
        patient: createExplanationPatientMock({
          candidateId: '0001',
          expDiseaseId: '0000',
          email: 'test@example.com',
          mobileNumber: '08011223344',
        }),
        latestRevision: createExplanationRevisionMock({
          members: [
            createExplanationRevisionMemberMock({
              trialHospital: createTrialHospitalMock({
                hospital: createHospitalMock({
                  name: "test's hospital",
                }),
              }),
              trialMember: createTrialMemberMock({
                user: createUserMock({
                  firstName: '太郎',
                  lastName: 'テスト',
                }),
              }),
            }),
          ],
          docSets: [
            createExplanationDocSetMock({
              index: 0,
            }),
          ],
        }),
      }),
    },
  })
})
