import React from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { generatePath, Link as ReactRouterLink } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'
import { usePatient } from 'src/features/patient/api'

import { AddFollowUpWorksheet } from '../AddFollowUpWorksheet/AddFollowUpWorksheet'
import { AddVisitWorksheet } from '../AddVisitWorksheet/AddVisitWorksheet'
import { WorksheetStatusBadge } from '../WorksheetStatusBadge/WorksheetStatusBadge'

type Props = {
  observationType: 'Visit' | 'FollowUp'
  patientUid: string
  selectedWorksheetUid: string | null
}

export const WorksheetListAccordion: React.FC<Props> = ({
  observationType,
  patientUid,
  selectedWorksheetUid,
}) => {
  const { data: patient } = usePatient({ patientUid, revalidateIfStale: false })

  const { selectedTrial } = useSelectedTrial()

  if (!patient) return null

  const observations =
    observationType === 'FollowUp'
      ? patient.observationFollowUps
      : patient.observationVisits

  const isPatientDisabled = patient.status === 'Disable'

  const defaultIndex = observations.findIndex(observation =>
    observation.worksheets.some(ws => ws.worksheetUid === selectedWorksheetUid),
  )

  return (
    <Accordion
      allowToggle
      // https://v1.chakra-ui.com/docs/components/disclosure/accordion#expand-multiple-items-at-once
      // > If you pass this prop, ensure that the index or defaultIndex prop is an array.
      defaultIndex={[defaultIndex]}
      overflowY="scroll"
      allowMultiple
    >
      {observations.map(observation => (
        <AccordionItem border="none" key={observation.patientObservationUid}>
          {({ isExpanded }) => (
            <>
              <h2>
                <Box
                  p="2"
                  _hover={{ bgColor: 'blue.50' }}
                  bgColor={isExpanded ? 'blue.50' : 'unset'}
                >
                  <AccordionButton
                    p="2"
                    display="flex"
                    justifyContent="space-between"
                    _hover={{ bgColor: 'blue.50' }}
                  >
                    <Text
                      fontSize={isExpanded ? 'md' : 'sm'}
                      fontWeight={isExpanded ? 'bold' : 'normal'}
                      lineHeight="5"
                      as="span"
                    >
                      {observation.name}
                    </Text>
                    <AccordionIcon color="blue.500" />
                  </AccordionButton>
                </Box>
              </h2>
              <AccordionPanel
                px="2"
                bgColor={isExpanded ? 'blue.50' : 'unset'}
                borderBottomRadius="base"
              >
                <Stack overflow="auto" spacing="4">
                  {observation.worksheets.map(ws => (
                    <Box
                      key={ws.worksheetUid}
                      as={ReactRouterLink}
                      to={generatePath(Paths.PatientWorksheet, {
                        trialUid: selectedTrial.uid,
                        patientUid,
                        worksheetUid: ws.worksheetUid,
                      })}
                      p="2"
                      textAlign="left"
                      fontWeight="normal"
                      borderRadius="base"
                      cursor="pointer"
                      _hover={{
                        bgColor:
                          ws.worksheetUid === selectedWorksheetUid
                            ? 'blue.300'
                            : 'blue.100',
                      }}
                      _active={{
                        bgColor:
                          ws.worksheetUid === selectedWorksheetUid
                            ? 'blue.300'
                            : 'blue.200',
                      }}
                      bgColor={
                        ws.worksheetUid === selectedWorksheetUid
                          ? 'blue.300'
                          : 'unset'
                      }
                    >
                      <HStack spacing="1">
                        <WorksheetStatusBadge
                          status={ws.latestWorksheetLog.status}
                          isDisabled={isPatientDisabled}
                        />
                        {observation.__typename ===
                          'PatientObservationVisit' && (
                          <Text as="span" opacity={isPatientDisabled ? 0.3 : 1}>
                            {ws.name}
                          </Text>
                        )}
                        {/* 随時観察の場合はindexと表示名、schemaに設定されている名前を表示する */}
                        {observation.__typename ===
                          'PatientObservationFollowUp' && (
                          <Stack spacing="0.5">
                            <Text fontWeight="bold">{`#${ws.index} ${ws.name}`}</Text>
                            <Text
                              fontSize="xs"
                              color="gray.500"
                              fontWeight="bold"
                            >
                              {ws.worksheetSchema.name}
                            </Text>
                          </Stack>
                        )}
                      </HStack>
                    </Box>
                  ))}
                  {observation.creatableWorksheetSchemas.length > 0 && (
                    <Box>
                      {observation.__typename === 'PatientObservationVisit' && (
                        <AddVisitWorksheet
                          trialUid={selectedTrial.uid}
                          patientUid={patientUid}
                          actualVisit={observation}
                        />
                      )}
                      {observation.__typename ===
                        'PatientObservationFollowUp' && (
                        <AddFollowUpWorksheet
                          trialUid={selectedTrial.uid}
                          patientUid={patientUid}
                          followUp={observation}
                        />
                      )}
                    </Box>
                  )}
                </Stack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  )
}
