import { Textarea } from '@chakra-ui/react'

import { FieldOf } from '../../utils'
import { ChakraFieldFormControl } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Textarea'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

export const TextareaFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  return (
    <ChakraFieldFormControl
      field={field}
      isRequired={field.typeDef.validation?.required}
      isDisabled={isDisabled || isPreview}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <Textarea
        value={value ?? ''}
        onChange={e => {
          if (e.target.value === '') {
            onChange?.(undefined)
            return
          }
          onChange?.(e.target.value)
        }}
      />
    </ChakraFieldFormControl>
  )
}
