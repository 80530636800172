import { HttpResponse } from 'msw'
import { restHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const setPinForPatientHandler = restHandlerFactory(
  '/explanation_revision_pin/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/set_pin',
  'post',
  () => {
    return HttpResponse.json(undefined, { status: 204 })
  },
)
