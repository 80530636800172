import { HStack, Input } from '@chakra-ui/react'

import { FieldOf } from '../../utils'
import { ChakraFieldFormControl } from '../FormWrapper/FormWrapper'

type Props = {
  field: FieldOf<'Number'>
  value?: string
  isDisabled?: boolean
  isPreview?: boolean
  onChange?: (value: string | undefined) => void
  errorMessage?: string
  warnMessage?: string
}

export const NumberFieldForm: React.FC<Props> = ({
  field,
  value,
  isDisabled,
  isPreview,
  onChange,
  errorMessage,
  warnMessage,
}) => {
  return (
    <ChakraFieldFormControl
      field={field}
      isRequired={field.typeDef.validation?.required}
      isDisabled={isDisabled || isPreview}
      errorMessage={errorMessage}
      warnMessage={warnMessage}
      showRequiredBadge={isPreview || !isDisabled}
    >
      <HStack spacing="2">
        <Input
          value={value ?? ''}
          maxW="120px"
          onChange={e => {
            if (e.target.value === '') {
              onChange?.(undefined)
              return
            }
            onChange?.(e.target.value)
          }}
        />
        {field.typeDef.unit && <span>{field.typeDef.unit}</span>}
      </HStack>
    </ChakraFieldFormControl>
  )
}
