import React, { useEffect } from 'react'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import MirohaLogoFull from 'src/assets/image/miroha-logo-full.svg'
import TopImage from 'src/assets/image/top-image.svg'
import { layouts } from 'src/assets/layouts'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { ButtonLink } from 'src/components/base/text-link'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Linkify } from 'src/components/Linkify/Linkify'
import { Spacer } from 'src/components/spacer/spacer'
import { useCurrentVersion } from 'src/features/misc/hooks/useCurrentVersion'
import { OnSubmit } from 'src/modules/auth/login/hooks/use-login'
import { useFetch as useFetchInformation } from 'src/modules/information/use-fetch'
import { routes } from 'src/modules/routes'
import styled, { css } from 'styled-components'
import * as yup from 'yup'

const largeScreen = `min-width: ${layouts.tabletLandscapeWithMin}`

const PageContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${largeScreen}) {
    flex-direction: row;
  }
`

const FormContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${white};

  @media (${largeScreen}) {
    flex: 1;
    background: ${blue[5]};
  }
`

const Form = styled.form`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FormItem = styled.div`
  width: 100%;
  max-width: 420px;
`

const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const backgroundImage = (hasInfo: boolean) =>
  hasInfo
    ? css`
        background-image: linear-gradient(
            rgba(256, 256, 256, 0.7),
            rgba(256, 256, 256, 0.7)
          ),
          url(${TopImage});
      `
    : css`
        background-image: url(${TopImage});
      `
const SecondContainer = styled.div<{ hasInfo: boolean }>`
  height: ${p => p.hasInfo && '250px'};
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${white};

  @media (${largeScreen}) {
    flex: 1;
    height: 100%;

    ${p => backgroundImage(p.hasInfo)}
    background-repeat: no-repeat;
    background-position: center;
  }
`

type Props = {
  errorMessage: string
  requesting: boolean
  onSubmit: OnSubmit
}

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
})

type FormValues = yup.InferType<typeof validationSchema>

export const Login: React.FC<Props> = ({
  errorMessage,
  requesting,
  onSubmit: submit,
}) => {
  const navigate = useNavigate()
  const { register, handleSubmit, formState, trigger } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = (value: FormValues) => {
    if (requesting) return
    if (!isValid) return

    submit(value)
  }

  const { informationList } = useFetchInformation()
  const hasInfo = informationList.length > 0

  const [isLargeScreen] = useMediaQuery(`(${largeScreen})`)

  const { currentVersion } = useCurrentVersion()

  useEffect(() => {
    trigger()
  }, [trigger])

  return (
    <PageContainer>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <img src={MirohaLogoFull} alt="MiROHA icon" />

          <Spacer size={50} />

          <FormItem>
            <Label bold>メールアドレス</Label>
            <Spacer size={10} />
            <Input
              type="email"
              placeholder="メールアドレス"
              {...register('email')}
            ></Input>
            {errors.email && touchedFields.email && (
              <div>
                <Spacer size={4} />
                <Message
                  type="error"
                  message="メールアドレスを入力してください"
                />
              </div>
            )}
          </FormItem>

          <Spacer size={50} />

          <FormItem>
            <Label bold>パスワード</Label>
            <Spacer size={10} />
            <Input
              type="password"
              placeholder="パスワード"
              {...register('password')}
            ></Input>
            {errors.password && touchedFields.password && (
              <div>
                <Spacer size={4} />
                <Message type="error" message="パスワードを入力してください" />
              </div>
            )}
          </FormItem>

          <Spacer size={50} />

          <LoginButtonContainer>
            <Button
              text="ログイン"
              size="S"
              buttonType="important"
              onClick={() => handleSubmit(onSubmit)}
            ></Button>
          </LoginButtonContainer>

          {errorMessage && (
            <div>
              <Spacer size={8} />
              <Message type="error" message={errorMessage} />
            </div>
          )}

          <Spacer size={40} />

          <ButtonLink onClick={() => navigate(routes.forgetPassword)}>
            パスワードをお忘れですか？
          </ButtonLink>
        </Form>
      </FormContainer>

      <SecondContainer hasInfo={hasInfo}>
        {hasInfo && (
          <Stack
            w="100%"
            overflow="scroll"
            spacing={6}
            px={12}
            py={isLargeScreen ? '120px' : '0'}
          >
            <Text fontWeight="bold">お知らせ</Text>
            {informationList.map(info => (
              <Alert
                status="info"
                key={info.uid}
                overflow="scroll"
                minH="120px"
              >
                <AlertIcon />
                <Box>
                  <AlertTitle>{info.title}</AlertTitle>
                  <AlertDescription whiteSpace="pre-wrap">
                    <Linkify>{info.content}</Linkify>
                  </AlertDescription>
                </Box>
              </Alert>
            ))}
          </Stack>
        )}
      </SecondContainer>

      <Text position="absolute" bottom="4" left="4">
        {`ver.${currentVersion}`}
      </Text>
    </PageContainer>
  )
}
