import { useCallback } from 'react'

import { useRequestState } from 'src/modules/server/use-request-state'

import { confirm } from '../request'

export const useConfirmCrf = () => {
  const {
    requestStarted,
    requestFailed,
    requestDone,
    requesting,
    errorMessage,
  } = useRequestState()

  const request = useCallback(
    async ({
      trialUid,
      crfFolderTemplateUid,
    }: {
      trialUid: string
      crfFolderTemplateUid: string
    }) => {
      try {
        requestStarted()
        await confirm({ trialUid, crfFolderTemplateUid })
        requestDone()
      } catch (error) {
        requestFailed(error.message)
        throw error
      }
    },
    [requestStarted, requestFailed, requestDone],
  )

  return {
    request,

    requesting,
    errorMessage,
  }
}
