import { assertNever } from 'src/utils/assertNever'

import { ItemType } from '../entities/small-item/entity'

export const itemTypeToText = (type: ItemType) => {
  switch (type) {
    case ItemType.Radio:
      return 'ラジオボタン'
    case ItemType.CheckBox:
      return 'チェックボックス'
    case ItemType.SelectMenu:
      return 'セレクトメニュー'
    case ItemType.Number:
      return '数値入力'

    case ItemType.Text:
    case ItemType.TextArea:
      return 'テキスト'

    case ItemType.Label:
      return 'ラベル'

    case ItemType.Date:
    case ItemType.NullableDate:
    case ItemType.HourMinute:
      return '日時入力'

    case ItemType.File:
      return 'ファイルアップロード'

    default:
      return assertNever(type)
  }
}
