import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { ButtonLink } from 'src/components/base/text-link'
import { RefDateInput } from 'src/components/form-redesigned/date-input'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Radio } from 'src/components/form-redesigned/radio'
import { Spacer } from 'src/components/spacer/spacer'
import { Patient, Gender } from 'src/modules/entities/patient/entity'
import { zeroPadding } from 'src/utils/zeroPadding'
import styled from 'styled-components'
import Flex from 'styled-flex-component'
import { width, WidthProps } from 'styled-system'
import * as yup from 'yup'

import { useReactHookFormDevTools } from '../../../../../../../hooks/use-react-hook-form-dev-tools'
import { trialIdParamName } from '../../../trial-detail'
import { useSendEmail } from '../../use-send-email'
import { useSendSMS } from '../../use-send-sms'

type Props = {
  patient: Patient
  errorMessage: string
  requesting: boolean
  onSubmit: (values: RequestValues) => void
  closeModal: () => void
  showModal: () => void
}

const genderItems = [
  {
    value: Gender.Male,
    name: '男性',
  },
  {
    value: Gender.Female,
    name: '女性',
  },
  {
    value: Gender.Unspecified,
    name: '未選択',
  },
]

export type RequestValues = {
  diseaseUid: string
  chartUid: string | null
  firstName: string | null
  firstNameJa: string | null
  lastName: string | null
  lastNameJa: string | null
  gender: Gender
  birthday: string | null
  phoneNumber: string | null
  email: string | null
}

const validationSchema = yup.object().shape({
  diseaseUid: yup.string().required(),
  chartUid: yup.string().nullable(),
  firstName: yup.string().nullable(),
  firstNameJa: yup.string().nullable(),
  lastName: yup.string().nullable(),
  lastNameJa: yup.string().nullable(),
  birthYear: yup.string().nullable(),
  birthMonth: yup.string().nullable(),
  birthDay: yup.string().nullable(),
  phoneNumber: yup.string().nullable(),
  email: yup.string().nullable(),
  gender: yup
    .mixed<Gender>()
    .oneOf(Object.values(Gender) as Gender[])
    .required(),
})

type FormValues = yup.InferType<typeof validationSchema>

export const EditForm: React.FC<Props> = props => {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      diseaseUid: props.patient.diseaseUid,
      chartUid: props.patient.chartUid || '',
      firstName: props.patient.firstName,
      firstNameJa: props.patient.firstNameJa,
      lastName: props.patient.lastName,
      lastNameJa: props.patient.lastNameJa,
      gender: props.patient.gender,
      birthYear: props.patient.birthday
        ? dayjs(props.patient.birthday, 'YYYY-MM-DD').format('YYYY')
        : '',
      birthMonth: props.patient.birthday
        ? dayjs(props.patient.birthday, 'YYYY-MM-DD').format('MM')
        : '',
      birthDay: props.patient.birthday
        ? dayjs(props.patient.birthday, 'YYYY-MM-DD').format('DD')
        : '',
      phoneNumber: props.patient.phoneNumber || '',
      email: props.patient.email || '',
    },
  })
  const { isValid, touchedFields, isDirty, errors } = methods.formState
  const { register, getValues, setValue, setError, control, watch } = methods
  const devToolElement = useReactHookFormDevTools(control)

  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()

  const {
    request: sendSMSRequest,
    requesting: sendSMSRequesting,
    doneMessage: sendSMSDoneMessage,
    errorMessage: sendSMSErrorMessage,
  } = useSendSMS()

  const {
    request: sendEmailRequest,
    requesting: sendEmailRequesting,
    doneMessage: sendEmailDoneMessage,
    errorMessage: sendEmailErrorMessage,
  } = useSendEmail()

  const onChangeGender = (value: string) => {
    if (!value) {
      setError('gender', { type: 'required' })
    }
    setValue('gender', Number(value), {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const canSubmit = isDirty && isValid && !props.requesting

  const onSendSMS = () => {
    const phoneNumber = getValues().phoneNumber
    if (!phoneNumber || sendSMSRequesting) {
      return
    }
    sendSMSRequest({ trialUid, phoneNumber })
  }

  const onSendEmail = () => {
    const email = getValues().email
    if (!email || sendEmailRequesting) {
      return
    }
    sendEmailRequest({ trialUid, email })
  }

  const onSubmit = (values: FormValues) => {
    if (!canSubmit) {
      return
    }

    // 年・月・日が全て未入力の場合は空文字としてSubmitする
    const birthday =
      !values.birthYear && !values.birthMonth && !values.birthDay
        ? ''
        : `${values.birthYear}-${zeroPadding(
            values.birthMonth ?? null,
            2,
          )}-${zeroPadding(values.birthDay ?? null, 2)}`

    const requestValues: RequestValues = {
      diseaseUid: values.diseaseUid,
      chartUid: values.chartUid ?? null,
      firstName: values.firstName ?? null,
      firstNameJa: values.firstNameJa ?? null,
      lastName: values.lastName ?? null,
      lastNameJa: values.lastNameJa ?? null,
      gender: values.gender,
      birthday: birthday,
      phoneNumber: values.phoneNumber ?? null,
      email: values.email ?? null,
    }

    props.onSubmit(requestValues)
  }

  const shouldShowError = (...fields: Array<keyof FormValues>) =>
    fields.some(f => errors[f]) && fields.some(f => !!touchedFields[f])

  return (
    <>
      {devToolElement}
      <FormProvider {...methods}>
        <ContentCotnainerForm
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <Text fontSize="lg" fontWeight="bold">
            患者情報を入力
          </Text>

          <Spacer size={20} />

          <Row>
            <ItemContainer>
              <Spacer size={20} />
              <Label bold>症例番号（必須）</Label>
              <Spacer size={10} />
              <Item width={160}>
                <Input placeholder="0000" {...register('diseaseUid')} />
              </Item>
              {errors.diseaseUid && touchedFields.diseaseUid && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message="症例番号を入力してください" />
                </div>
              )}
            </ItemContainer>
          </Row>

          <Row>
            <ItemContainer>
              <Spacer size={20} />
              <Label bold>氏名（任意）</Label>
              <Flex>
                <Item width={160}>
                  <Input placeholder="姓" {...register('lastName')} />
                </Item>
                <Spacer size={5} horizontal />
                <Item width={160}>
                  <Input placeholder="名" {...register('firstName')} />
                </Item>
              </Flex>
              {shouldShowError('firstName', 'lastName') && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message="氏名を入力してください" />
                </div>
              )}
            </ItemContainer>

            <FlexSpacer />

            <ItemContainer>
              <Spacer size={20} />
              <Label bold>ふりがな（任意）</Label>
              <Flex>
                <Item width={160}>
                  <Input placeholder="せい" {...register('lastNameJa')} />
                </Item>
                <Spacer size={5} horizontal />
                <Item width={160}>
                  <Input placeholder="めい" {...register('firstNameJa')} />
                </Item>
              </Flex>
              {shouldShowError('firstNameJa', 'lastNameJa') && (
                <div>
                  <Spacer size={4} />
                  <Message
                    type="error"
                    message="氏名ふりがなを入力してください"
                  />
                </div>
              )}
            </ItemContainer>
          </Row>

          <Row>
            <ItemContainer>
              <Spacer size={20} />
              <Label bold>生年月日（任意）</Label>
              <Spacer size={10} />
              <RefDateInput
                yearName="birthYear"
                monthName="birthMonth"
                dayName="birthDay"
              />
              {shouldShowError('birthYear', 'birthMonth', 'birthDay') && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message="生年月日を入力してください" />
                </div>
              )}
            </ItemContainer>

            <FlexSpacer />

            <ItemContainer>
              <Spacer size={20} />
              <Label bold>性別（任意）</Label>
              <Spacer size={10} />
              <Controller
                name="gender"
                control={control}
                render={() => {
                  return (
                    <Radio
                      name="gender"
                      selectedValue={watch('gender')}
                      items={genderItems}
                      onChange={onChangeGender}
                    />
                  )
                }}
              ></Controller>
              {errors.gender && touchedFields.gender && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message="性別を入力してください" />
                </div>
              )}
            </ItemContainer>
          </Row>

          <Row>
            <ItemContainer>
              <Spacer size={20} />
              <Flex>
                <Label bold>電話番号（任意）</Label>
                <ButtonLink onClick={onSendSMS}>テスト送信</ButtonLink>
              </Flex>
              <Spacer size={10} />
              <Item width={325}>
                <Input placeholder="00000000000" {...register('phoneNumber')} />
              </Item>
              {sendSMSDoneMessage && (
                <div>
                  <Spacer size={4} />
                  <Message type="information" message={sendSMSDoneMessage} />
                </div>
              )}
              {sendSMSErrorMessage && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message={sendSMSErrorMessage} />
                </div>
              )}
            </ItemContainer>

            <FlexSpacer />

            <ItemContainer>
              <Spacer size={20} />
              <Flex>
                <Label bold>メールアドレス（任意）</Label>
                <ButtonLink onClick={onSendEmail}>テスト送信</ButtonLink>
              </Flex>
              <Spacer size={10} />
              <Item width={325}>
                <Input
                  type="email"
                  placeholder="miroha@example.com"
                  {...register('email')}
                />
              </Item>
              {sendEmailDoneMessage && (
                <div>
                  <Spacer size={4} />
                  <Message type="information" message={sendEmailDoneMessage} />
                </div>
              )}
              {sendEmailErrorMessage && (
                <div>
                  <Spacer size={4} />
                  <Message type="error" message={sendEmailErrorMessage} />
                </div>
              )}
            </ItemContainer>
          </Row>

          <Row>
            <ItemContainer>
              <Spacer size={20} />
              <Label bold>電子カルテID（任意）</Label>
              <Spacer size={10} />
              <Item width={325}>
                <Input width={1} placeholder="0000" {...register('chartUid')} />
              </Item>
            </ItemContainer>
          </Row>

          <Spacer size={100} />

          <BtnContainer>
            <Button
              size="S"
              text="キャンセル"
              type="button"
              onClick={props.closeModal}
              buttonType="cancel"
            ></Button>
            <Spacer size={40} horizontal />
            <Button
              size="S"
              text="確定"
              iconName="check"
              type="submit"
              onClick={() => methods.handleSubmit(onSubmit)}
              buttonType="important"
              disabled={!canSubmit}
            ></Button>
            <Spacer size={40} horizontal />
            <Button
              size="S"
              text="無効化"
              type="button"
              onClick={props.showModal}
              buttonType="alert"
            ></Button>
          </BtnContainer>
          {props.errorMessage && (
            <Message type="error" message={props.errorMessage} centered />
          )}
        </ContentCotnainerForm>
      </FormProvider>
    </>
  )
}

const ContentCotnainerForm = styled.form`
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  overflow-x: scroll;
`

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Item = styled.div<WidthProps>`
  ${width};
`

const ItemContainer = styled.div`
  width: 325px;
`

const FlexSpacer = styled.div`
  flex-grow: 1;
  min-width: 50px; //左右のアイテム間にminimumであけるスペース
`

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
`
