import React from 'react'

import { colors } from 'src/assets/colors'
import { History } from 'src/components/pdf/history'
import { LoglinesComponent } from 'src/components/pdf/logline'
import { SingleSectionComponent } from 'src/components/pdf/single-section'
import { WorksheetPDFData } from 'src/modules/dashboard/trial/detail/patient/detail/worksheet/common/detail/output/pdf/entity'
import { MediumItemAnswer } from 'src/modules/entities/medium-item-answer/entity'
import { Role } from 'src/modules/entities/member/entity'
import { WorksheetStatus } from 'src/modules/entities/worksheet/entity'
import { sortByDate } from 'src/modules/util/sort'
import { zeroPadding } from 'src/utils/zeroPadding'
import styled from 'styled-components'
import Flex, { FlexItem as OriginalFlexIem } from 'styled-flex-component'
import { space, SpaceProps, typography, TypographyProps } from 'styled-system'

import { CallHistory } from './call-history'

type Props = {
  isHistory: boolean
  isWorksheet: boolean
  isCallHistory: boolean
  worksheetPDFData: WorksheetPDFData
  // callHistories: CallHistoryType[]
  datetime: string
  currentRole: Role
  accountName: string
  accountEmail: string
}

export const Pdf: React.FC<Props> = props => {
  const {
    isHistory,
    isWorksheet,
    isCallHistory,
    worksheetPDFData,
    // callHistories,
    datetime,
    currentRole,
    accountName,
    accountEmail,
  } = props

  const worksheetDisabled =
    props.worksheetPDFData.worksheet.status === WorksheetStatus.Disabled
  const worksheetLog = props.worksheetPDFData.worksheetLog
  const createdDate = new Date(worksheetLog.created_at)
  const formatCreatedDate = `
    ${createdDate.getFullYear()}/${zeroPadding(
      String(createdDate.getMonth() + 1),
      2,
    )}/${zeroPadding(String(createdDate.getDate()), 2)} ${zeroPadding(
      String(createdDate.getHours()),
      2,
    )}:${zeroPadding(String(createdDate.getMinutes()), 2)}:${zeroPadding(
      String(createdDate.getSeconds()),
      2,
    )}
  `
  const { template, templateFolderName } = worksheetPDFData

  const byLineNumberAsc = (a: MediumItemAnswer, b: MediumItemAnswer) =>
    a.lineNumber - b.lineNumber
  const loglinesByMediumItemUid = (mediumItemUid: string) =>
    worksheetPDFData.worksheet.mediumItemAnswers
      .filter(mia => mia.isLogline && mia.mediumItemUid === mediumItemUid)
      .sort(byLineNumberAsc)

  const parentMediumItemAnswer = (smallItemAnswerUid: string) => {
    return worksheetPDFData.worksheet.mediumItemAnswers.find(mia =>
      Object.keys(mia.smallItemAnswerMap).includes(smallItemAnswerUid),
    )!
  }

  return (
    <PageContainer>
      <Flex justifyBetween alignCenter>
        <FlexItem fontSize={`10px`} marginBottom={`12px`}>
          {worksheetPDFData.trialName}{' '}
        </FlexItem>
        <FlexItem fontSize={`10px`} marginBottom={`12px`}>
          {currentRole === Role.Dr || currentRole === Role.CRC ? (
            <Flex>
              <FlexItem
                mr={`16px`}
              >{`出力者：${accountName} ${accountEmail}`}</FlexItem>
              <FlexItem>{datetime}</FlexItem>
            </Flex>
          ) : (
            datetime
          )}
        </FlexItem>
      </Flex>

      <PatientName>
        <FlexItem fontSize={`14px`} fontWeight={'bold'} marginRight={`10px`}>
          {worksheetPDFData.patient.lastName}{' '}
          {worksheetPDFData.patient.firstName}
        </FlexItem>
        <FlexItem fontSize={`10px`} marginBottom={`2px`}>
          {worksheetPDFData.patient.lastNameJa}{' '}
          {worksheetPDFData.patient.firstNameJa} | 症例番号：
          {worksheetPDFData.patient.diseaseUid}
        </FlexItem>
      </PatientName>

      <WorksheetTitle>
        {worksheetDisabled && <DisabledTitle>【無効】</DisabledTitle>}
        {`${worksheetPDFData.worksheet.title} [${templateFolderName}]`}
      </WorksheetTitle>

      {worksheetDisabled && (
        <Box>
          <BoxTitle>ステータス【無効】</BoxTitle>
          <DisableTexts>
            <DisableText>無効化日時：{formatCreatedDate}</DisableText>
            <DisableText>
              アカウント：{worksheetLog && worksheetLog.user_name}
            </DisableText>
            <DisableText>
              理由：{worksheetLog && worksheetLog.reason}
            </DisableText>
          </DisableTexts>
        </Box>
      )}

      {isWorksheet && (
        <>
          <MediumItemAnswersContainer>
            {template.mediumItems
              .sort((a, b) => a.index - b.index)
              .map(mediumItem =>
                mediumItem.isLogline ? (
                  <LoglinesComponent
                    key={mediumItem.uid}
                    loglineTitle={mediumItem.title}
                    loglines={loglinesByMediumItemUid(mediumItem.uid)}
                    disableLogs={worksheetPDFData.loglineDisableLogs}
                  />
                ) : (
                  <SingleSectionComponent
                    key={mediumItem.uid}
                    worksheet={worksheetPDFData.worksheet}
                    mediumItem={mediumItem}
                  />
                ),
              )}
          </MediumItemAnswersContainer>

          {isHistory && (
            <Box>
              <BoxTitle>変更履歴</BoxTitle>
              {worksheetPDFData.smallItemAnswerLogs.map(history => (
                <History
                  key={history.uid}
                  history={history}
                  mediumItemAnswer={parentMediumItemAnswer(
                    history.smallItemAnswerUid,
                  )}
                />
              ))}
            </Box>
          )}
        </>
      )}

      {isCallHistory && worksheetPDFData.worksheet.videoEnabled && (
        <Box>
          <BoxTitle>ビデオ通話履歴</BoxTitle>
          <Table>
            <Head>
              <HeadItem>通話開始日時</HeadItem>
              <HeadItem>通話終了日時</HeadItem>
              <HeadItem>アカウント</HeadItem>
              <HeadItem>画面キャプチャ</HeadItem>
            </Head>
            {sortByDate(worksheetPDFData.videoCalls, 'createdAt', 'desc').map(
              v => (
                <CallHistory key={v.uid} videoCall={v} />
              ),
            )}
          </Table>
        </Box>
      )}
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 210mm;
  min-height: 297mm;
  height: 100%;
  padding: 46px 38px;
  margin: 0 auto 10px;
  box-sizing: border-box;
  background-color: #fff;
`

const FlexItem = styled(OriginalFlexIem)<SpaceProps & TypographyProps>`
  ${space}
  ${typography}
`

const PatientName = styled.div`
  display: flex;
  align-items: flex-end;
`

const WorksheetTitle = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 12px 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
`

const MediumItemAnswersContainer = styled.div`
  border-bottom: 0.5px solid #000000;
`

const Box = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 24px 0 24px;
  border: 0.5px solid #000000;
`

const BoxTitle = styled.p`
  font-weight: bold;
  font-size: 10px;
  padding: 10px 0;
  margin: 0;
  text-align: center;
  border-bottom: 0.5px solid #000000;
`

const Table = styled.div`
  width: 100%;
`

const Head = styled.div`
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.bgGray};
  border-bottom: 0.5px dashed #000000;
`

const HeadItem = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 7px;
  color: ${colors.dark};
`

const DisabledTitle = styled.div`
  font-size: 18px;
  display: inline;
`

const DisableText = styled.p`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0;
  font-weight: 500;
  font-size: 9px;
`

const DisableTexts = styled.div`
  padding: 12px 0 16px;
`
