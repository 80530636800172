import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchQuestionnaire } from 'src/modules/entities/questionnaire/redux'
import { useFlash } from 'src/modules/flash/use-flash'
import { routes } from 'src/modules/routes'

export const useFetch = ({
  trialUid,
  questionnaireUid,
}: {
  trialUid: string
  questionnaireUid: string
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetched, setFetched] = useState(false)

  const { showError } = useFlash()

  useEffect(() => {
    const fetch = async () => {
      setFetched(false)
      try {
        await dispatch(fetchQuestionnaire({ trialUid, questionnaireUid }))
        setFetched(true)
      } catch (error) {
        console.error('QuestionnaireActions.fetchDetail Error: ', error)
        if (error?.original?.response?.status === 403) {
          navigate(routes.httpError403)
          return
        }
        showError(error.message)
      }
    }

    fetch()
  }, [dispatch, navigate, questionnaireUid, showError, trialUid])

  return {
    fetched,
  }
}
