import React from 'react'

import { Text } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { gray, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { RHFSingleRadio } from 'src/components/form-redesigned/radio'
import { RHFSingleCheckbox } from 'src/components/form-redesigned/single-checkbox'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { Spacer } from 'src/components/spacer/spacer'
import { useReactHookFormDevTools } from 'src/hooks/use-react-hook-form-dev-tools'
import { AnswerType } from 'src/modules/entities/questionnaire-field/entity'
import { QuestionnaireSectionAnswer } from 'src/modules/entities/questionnaire-section-answer/entity'
import styled from 'styled-components'

import { Header } from '../components/header'

export type ChoiceAnswers = {
  selectedUid?: string // ラジオボタン
  allUids?: Record<string, boolean> // チェックボックス
}

type FieldAnswer = {
  value?: string // テキスト・数値
  choiceAnswers?: ChoiceAnswers
}

export type FormValues = {
  fieldAnswers: Record<string, FieldAnswer>
}

type Props = {
  displayName: string
  answerStartDate: string
  answerEndDate: string
  shouldDeliverAfterTrial: boolean
  sectionAnswer: QuestionnaireSectionAnswer
  displayAnsweredCount: string
  onAnswer: (data: FormValues) => void
  onBack: (previousQuestionnaireSectionAnswerUid: string) => void
  onStop: () => void
  hasPrev: boolean
}

export const Detail: React.FC<Props> = ({
  displayName,
  answerStartDate,
  answerEndDate,
  shouldDeliverAfterTrial,
  sectionAnswer,
  displayAnsweredCount,
  onAnswer,
  onBack,
  onStop,
  hasPrev,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    shouldUnregister: true, // unmount時に値を保持しないようにする
  })

  const devToolElement = useReactHookFormDevTools(control)

  return (
    <Container>
      <Header
        displayName={displayName}
        answerStartDate={answerStartDate}
        answerEndDate={answerEndDate}
        shouldDeliverAfterTrial={shouldDeliverAfterTrial}
        onStop={onStop}
        showStopButton={true}
      />

      <Page>
        {devToolElement}
        <Spacer size={24} />

        <Text fontSize="lg" color={gray[50]} textAlign="right">
          {displayAnsweredCount} 回答済
        </Text>

        <Text fontSize="5xl" color={gray[30]}>
          {sectionAnswer.title}
        </Text>
        <Spacer size={60} />
        {sectionAnswer.fieldAnswers
          .sort((a, b) => a.index - b.index)
          .map(fa => {
            return (
              <Item key={fa.uid}>
                <Text whiteSpace="pre-wrap">{fa.title}</Text>
                <Spacer size={10} />
                {fa.answerType === AnswerType.Radio && (
                  <Options>
                    {fa.choiceAnswers
                      .sort((a, b) => a.index - b.index)
                      .map(ca => (
                        <RHFSingleRadio
                          key={ca.uid}
                          name={`fieldAnswers.${fa.uid}.choiceAnswers.selectedUid`}
                          defaultChecked={ca.checked}
                          label={ca.description}
                          value={ca.uid}
                          register={register}
                        />
                      ))}
                  </Options>
                )}
                {fa.answerType === AnswerType.CheckBox && (
                  <Options>
                    {fa.choiceAnswers
                      .sort((a, b) => a.index - b.index)
                      .map(ca => (
                        <RHFSingleCheckbox
                          name={`fieldAnswers.${fa.uid}.choiceAnswers.allUids.${ca.uid}`}
                          key={ca.uid}
                          defaultChecked={ca.checked}
                          label={ca.description}
                          register={register}
                        />
                      ))}
                    <Spacer size={10} />
                  </Options>
                )}
                {fa.answerType === AnswerType.Text && (
                  <Textarea
                    defaultValue={fa.value}
                    {...register(`fieldAnswers.${fa.uid}.value`)}
                  />
                )}
                {fa.answerType === AnswerType.Number && (
                  <>
                    <Number>
                      <Input
                        defaultValue={fa.value}
                        type="text"
                        inputMode="numeric"
                        {...register(`fieldAnswers.${fa.uid}.value`, {
                          pattern: {
                            value: /^([+-])?([0-9]+)(\.)?([0-9]*)?$/,
                            message: '数値を入力してください(半角)。',
                          },
                        })}
                      />
                      <Text fontSize="sm" wordBreak="break-all">
                        {fa.unit}
                      </Text>
                    </Number>
                    <ErrorMessage
                      errors={errors}
                      name={`fieldAnswers.${fa.uid}.value`}
                      render={({ message }) => (
                        <>
                          <Spacer size={4} />
                          <Message type="error" message={message} />
                        </>
                      )}
                    />
                  </>
                )}
                <Spacer size={30} />
              </Item>
            )
          })}
      </Page>

      <Footer>
        <Button
          text={'前の質問に戻る'}
          size="M"
          buttonType="normal"
          onClick={() =>
            onBack(sectionAnswer.previousQuestionnaireSectionAnswerUid)
          }
          disabled={!hasPrev}
        />
        <Spacer horizontal size={40} />
        <Button
          text={'回答する'}
          size="M"
          buttonType="important"
          onClick={handleSubmit(onAnswer)}
          disabled={!isValid}
        />
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Page = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 120px;
`

const Item = styled.div``

const Options = styled.div`
  display: flex;
  flex-direction: column;
`

const Number = styled.div`
  display: flex;
  align-items: center;
`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  border-top: 1px solid #9ab8c1;
`
