// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgEdit = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M0 16.352c0 .2 0 .399.1.498.1.1.2.1.4.1h.1l3.4-.997-3-2.99-1 3.39ZM15.9 3.592 13.4 1.1c-.2-.2-.5-.2-.7 0l-11 10.966 3.2 3.19 11-10.966c.1-.1.1-.499 0-.698Z"
    />
  </svg>
)
export default SvgEdit
