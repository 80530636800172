import { actions as patientActions } from 'src/modules/entities/patient/redux'
import { actions as worksheetActions } from 'src/modules/entities/worksheet/redux'
import { RootState } from 'src/modules/reducer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { asyncFactory } from 'typescript-fsa-redux-thunk'
import { validate } from 'uuid'

import { disablePatient } from './request'

const create = actionCreatorFactory('patient/disablePatient')
const createAsync = asyncFactory<RootState>(create)

export const actions = {
  submit: createAsync<
    {
      trialUid: string
      patientUid: string
      inputValue: string
      worksheetUid: string
    },
    {},
    { message: string }
  >('SUBMIT', async (params, dispatch) => {
    await disablePatient(params)
    dispatch(
      patientActions.fetch({
        trialUid: params.trialUid,
        patientUid: params.patientUid,
      }),
    )
    dispatch(
      worksheetActions.fetchList({
        trialUid: params.trialUid,
        patientUid: params.patientUid,
      }),
    )

    if (validate(params.worksheetUid)) {
      dispatch(
        worksheetActions.fetchSingle({
          trialUid: params.trialUid,
          patientUid: params.patientUid,
          worksheetUid: params.worksheetUid,
        }),
      )
    }
  }),
}

export type State = {
  requesting: boolean
  errorMessage: string | null
}

const initialState: State = {
  requesting: false,
  errorMessage: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.submit.async.started, (state, payload) => ({
    ...state,
    requesting: true,
    errorMessage: null,
  }))
  .case(actions.submit.async.done, (state, payload) => ({
    ...state,
    requesting: false,
    modalOpen: false,
  }))
  .case(actions.submit.async.failed, (state, payload) => {
    return {
      ...state,
      requesting: false,
      errorMessage: payload.error.message,
    }
  })
  .build()
