import { useCallback, useEffect } from 'react'

import { Box, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { gray } from 'src/components/base/color/palette'
import { FileSelectButton } from 'src/components/base/file-select-button'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Radio } from 'src/components/form-redesigned/radio'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'
import * as yup from 'yup'

type Props = {
  errorMessage: string
  importErrorMessage: string
  requesting: boolean
  onSubmit: (title: string) => void
  onCancel: () => void
  mode: string
  onChangeMode: (newMode: string) => void
  importingJsonFileName?: string
  onImportingJsonFileChanged: (file: File) => void
}

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required('質問票名を入力してください')
    .max(255, '質問票名は255文字以内で入力してください'),
  createMode: yup.string(),
  importFile: yup.mixed().when('createMode', ([createMode], b) => {
    return createMode === 'import' ? b.required() : b
  }),
})

type FormValues = yup.InferType<typeof validationSchema>

export const Add: React.FC<Props> = props => {
  const {
    errorMessage,
    importErrorMessage,
    requesting,
    onCancel,
    mode,
    onChangeMode,
    importingJsonFileName,
    onImportingJsonFileChanged,
  } = props
  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { register, handleSubmit, formState, setValue, trigger } = methods
  const { isValid, touchedFields, errors } = formState

  const onSubmit = (values: FormValues) => {
    if (!isValid) {
      return
    }
    props.onSubmit(values.title)
  }

  useEffect(() => {
    register('importFile', { required: true })
    setValue('importFile', undefined)
    register('createMode', { required: true })
    setValue('createMode', 'edit-new')
    trigger()
  }, [register, setValue, trigger])

  const onChangeModeRadio = useCallback(
    (mode: string) => {
      onChangeMode(mode)
      setValue('createMode', mode)
      trigger()
    },
    [onChangeMode, setValue, trigger],
  )

  const onChangeFileSelect = useCallback(
    (file: File) => {
      onImportingJsonFileChanged(file)
      setValue('importFile', file)
      trigger()
    },
    [onImportingJsonFileChanged, setValue, trigger],
  )

  return (
    <Wrapper>
      <Text fontSize="lg" fontWeight="bold">
        質問票初期登録
      </Text>
      <Spacer size={40} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <Label mb={10}>質問票名</Label>
            <Input
              width={1}
              placeholder="質問票名を入力"
              {...register('title')}
            />
            {errors.title && touchedFields.title && (
              <Message type="error" message={errors.title.message} />
            )}
          </InputContainer>
          <>
            <Radio
              items={[
                {
                  value: 'edit-new',
                  name: '新規作成',
                },
                {
                  value: 'import',
                  name: 'インポートして作成',
                },
              ]}
              selectedValue={mode}
              onChange={onChangeModeRadio}
              name="createMode"
              vertical
            />
            {mode === 'import' && (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="10px"
                  backgroundColor={gray[5]}
                >
                  <Text fontSize="sm">
                    {importingJsonFileName ??
                      'インポートするファイルを選択してください'}
                  </Text>
                  <Spacer size={10} horizontal />
                  <FileSelectButton
                    accept="application/json"
                    onChange={onChangeFileSelect}
                    text="ファイル選択"
                  />
                </Box>
                {importErrorMessage && (
                  <Message type="error" message={importErrorMessage} />
                )}
              </>
            )}
          </>
          <Spacer size={40} />
          <BtnContainer>
            <Button
              size="S"
              text="キャンセル"
              buttonType="cancel"
              onClick={onCancel}
            ></Button>
            <Button
              size="S"
              text="作成"
              buttonType="important"
              disabled={!isValid || requesting}
              onClick={handleSubmit(onSubmit)}
            ></Button>
          </BtnContainer>
          {errorMessage && (
            <Message type="error" message={props.errorMessage} />
          )}
        </form>
      </FormProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;

  > button:not(:last-child) {
    margin-right: 20px;
  }
`
