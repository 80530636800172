import React from 'react'

import { Text } from '@chakra-ui/react'
import { HashLink } from 'react-router-hash-link'
import { RectangleVertical } from 'src/components/__legacy__icon/monochrome'
import { blue, gray, green, red } from 'src/components/base/color/palette'
import styled from 'styled-components'

export type Item = {
  name: string
  path: string
  error?: boolean
  confirmed?: boolean
}

type Props = {
  items: Item[]
}

export const Anchors: React.FC<Props> = props => {
  const { items } = props

  return (
    <Container>
      {items.map((item, i) => {
        return (
          <React.Fragment key={item.path}>
            <ItemLink smooth to={item.path}>
              {item.confirmed !== undefined && (
                <ConfirmationStatus>
                  {item.confirmed ? (
                    <RectangleVertical color={green[20]} />
                  ) : (
                    <RectangleVertical color={gray[30]} />
                  )}
                </ConfirmationStatus>
              )}
              <Text color={item.error ? red[50] : blue[70]}>{item.name}</Text>
            </ItemLink>

            {i < items.length - 1 && <Separator>|</Separator>}
          </React.Fragment>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  padding: 2px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background: ${blue[10]};
  border-radius: 3px;
`

const ItemLink = styled(HashLink)`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  text-decoration: none;
  transition: 0.1s ease;
`

const ConfirmationStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px 0 0;
`

const Separator = styled.div`
  color: ${gray[55]};
`
