import {
  StaticScreen,
  StaticScreenTitle,
} from 'src/components/StaticScreen/StaticScreen'

export const PatientVideoCallExpiredScreen: React.FC = () => {
  return (
    <StaticScreen>
      <StaticScreenTitle>URLの有効期限が切れています。</StaticScreenTitle>
    </StaticScreen>
  )
}
