import React, { useMemo } from 'react'

import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ToggleButton } from 'src/components/form-redesigned/toggle-button'
import { Spacer } from 'src/components/spacer/spacer'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { CreateOrGetPreSessionRes } from 'src/features/explanation/api'
import { MembersSettingButton } from 'src/features/explanation/components/MembersSettingButton/MembersSettingButton'
import { Explanation } from 'src/features/explanation/types'
import { getFullName } from 'src/utils/getFullName'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

type Props = {
  trialName: string
  explanation: Explanation
  preSession: CreateOrGetPreSessionRes
  isPartner: boolean
  onIdentifyPatient: (isIdentified: boolean) => void
  onStartSession: () => void
  onMoveToRoom: () => void
}

export const HospitalLobbySidebar: React.FC<Props> = ({
  trialName,
  explanation,
  preSession,
  isPartner,
  onIdentifyPatient,
  onStartSession,
  onMoveToRoom,
}) => {
  const {
    patient,
    uid: explanationUid,
    latestRevision,
    trialHospital,
  } = explanation

  const { isIdentified, identifiedAt } = preSession

  const isSessionStarted = !!preSession.explanationSessionUid

  const disabledStartSession =
    !isIdentified || explanation.latestRevision.members.length === 0

  const mainHospitalMembers = useMemo(
    () =>
      latestRevision.members.filter(
        m => m.trialHospital.uid === trialHospital?.uid,
      ),
    [latestRevision, trialHospital],
  )

  const partnerHospitalMembers = useMemo(
    () =>
      latestRevision.members.filter(
        m => m.trialHospital.uid === latestRevision.partnerTrialHospital?.uid,
      ),
    [latestRevision],
  )

  return (
    <Wrapper>
      <div>
        <Text fontSize="xl" fontWeight="bold">
          {trialName}
        </Text>
        <Spacer size={32} />
        <Text fontSize="xl" fontWeight="bold">
          候補ID
        </Text>
        <Spacer size={8} />
        <Text>{patient.candidateId}</Text>
        <Spacer size={32} />
        {patient.expDiseaseId && (
          <>
            <Text fontSize="xl" fontWeight="bold">
              症例番号
            </Text>
            <Spacer size={8} />
            <Text>{patient.expDiseaseId}</Text>
            <Spacer size={32} />
          </>
        )}
        <Text fontSize="xl" fontWeight="bold">
          本人確認
        </Text>
        <Spacer size={8} />
        <Flex row>
          <Text>患者</Text>
          <Spacer size={64} horizontal />
          <Flex column>
            {!isPartner && (
              <ToggleButton
                toggleAriaLabel="identify-patient"
                checked={isIdentified}
                text="確認済"
                offText="未確認"
                onChange={() => {
                  onIdentifyPatient(isIdentified)
                }}
              />
            )}
            {identifiedAt && (
              <Text>{dayjs(identifiedAt).format('YYYY/MM/DD HH:mm:ss')}</Text>
            )}
          </Flex>
        </Flex>
        <Spacer size={32} />
        <Text fontSize="xl" fontWeight="bold">
          説明担当者
        </Text>
        <Box overflowY="scroll" maxHeight="192px">
          {latestRevision.members.length === 0 && (
            <Box my={1}>
              <WarningMessage
                message="説明担当者が設定されていません。"
                subMessage="署名を実施するには説明ルームで説明担当者の設定が必要です。"
              />
            </Box>
          )}
          {mainHospitalMembers.length > 0 && (
            <Box mt="2">
              <Text fontWeight="bold" as="h3">
                {trialHospital.name}
              </Text>
              <Stack mt="2" spacing="1" ml="6">
                {mainHospitalMembers.map(m => (
                  <HStack key={m.trialMember.uid} spacing="4">
                    <Text>{m.trialMember.role}</Text>
                    <Text>{getFullName(m.trialMember)}</Text>
                  </HStack>
                ))}
              </Stack>
            </Box>
          )}
          {partnerHospitalMembers.length > 0 && (
            <Box mt="4">
              <Text fontWeight="bold" as="h3">
                {latestRevision.partnerTrialHospital?.name}
              </Text>
              <Stack mt="2" spacing="1" ml="6">
                {partnerHospitalMembers.map(m => (
                  <HStack key={m.trialMember.uid} spacing="4">
                    <Text>{m.trialMember.role}</Text>
                    <Text>{getFullName(m.trialMember)}</Text>
                  </HStack>
                ))}
              </Stack>
            </Box>
          )}
        </Box>
        <Spacer size={8} />
        <MembersSettingButton
          explanationUid={explanationUid}
          explanationSessionUid={preSession.explanationSessionUid ?? undefined}
          isSignerSet={false} // 旧署名フローでは常にfalse（署名者を設定する時点でstatusで制御できる）
          defaultMembers={latestRevision.members.map(m => ({
            trialHospitalUid: m.trialHospital.uid,
            trialMemberUid: m.trialMember.uid,
            role: m.trialMember.role,
          }))}
        />
      </div>
      <ButtonContainer>
        {!isSessionStarted && !isPartner && (
          <Button
            colorScheme="green"
            onClick={onStartSession}
            isDisabled={disabledStartSession}
          >
            説明開始
          </Button>
        )}
        {isSessionStarted &&
          (latestRevision.status === 'SessionNotDone' ||
            latestRevision.status === 'AgreementNotDone') && (
            <Button colorScheme="green" onClick={onMoveToRoom}>
              説明ルームへ
            </Button>
          )}
        {/* パートナー施設で同意未完了の場合はセッション作成ができるが、ボタンの文言は「説明ルームへ」 */}
        {isPartner &&
          !isSessionStarted &&
          latestRevision.status === 'AgreementNotDone' && (
            <Button colorScheme="green" onClick={onStartSession}>
              説明ルームへ
            </Button>
          )}
      </ButtonContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
