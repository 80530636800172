import { useState } from 'react'

import { Center, HStack, Stack, Text } from '@chakra-ui/react'
import { generatePath, useParams } from 'react-router-dom'
import { MessageInformation } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { useBlockBrowserBack } from 'src/hooks/useBlockBrowserBack'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useSessionForPartner } from '../../api'
import {
  ExplanationRoomEventProvider,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { useThisSession } from '../../hooks/useThisSession'
import { getStatusInformationMessage } from '../../utils/getStatusInformationMessage'
import { DocumentList } from '../DocumentList/DocumentList'
import { ExplanationRoomProgressBar } from '../ExplanationRoomProgressBar/ExplanationRoomProgressBar'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { TopScreenContainer } from '../TopScreenContainer/TopScreenContainer'
import { TopScreenSkelton } from '../TopScreenSkelton/TopScreenSkelton'

type Param = {
  sessionUid: string
}

export const PartnerTopScreen: React.FC = () => {
  const { sessionUid = '' } = useParams<Param>()
  const { selectedTrial } = useSelectedTrial()
  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()

  useBlockBrowserBack()

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  if (!selectedTrialHospitalUid || !thisSession.fetched) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      sessionUid={sessionUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Partner"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout
        headerTitle="説明ルーム"
        sessionUid={sessionUid}
        isPartner
      >
        <Content sessionUid={sessionUid} />
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}

const Content: React.FC<{ sessionUid: string }> = ({ sessionUid }) => {
  const [signReadyNoticeDone, setSignReadyNoticeDone] = useState(false)

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  const toast = useMirohaToast()

  const { data: session, mutate: mutateSession } = useSessionForPartner({
    explanationSessionUid: sessionUid,
    revalidateOnMount: true,
    onSuccess: data => {
      if (!thisSession.fetched) return
      const newStatus = data.explanationRevision.latestHistory.statusV2
      if (
        !signReadyNoticeDone &&
        newStatus === 'SignReady' &&
        !thisSession.isSignerMemberSet &&
        thisSession.hasConsentRequiredAgreementForm
      ) {
        toast({
          status: 'success',
          title: 'すべての必須同意書の閲覧が完了しました。署名が実施できます。',
        })
        setSignReadyNoticeDone(true)
      }
    },
  })

  useUpdateRevisionStatus({
    listener: () => {
      mutateSession()
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  const { selectedTrial } = useSelectedTrial()

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (!session || !thisSession.fetched) {
    return <TopScreenSkelton />
  }

  const status = session.explanationRevision.latestHistory.statusV2

  const informationMessage = getStatusInformationMessage({
    status,
    hasConsentRequiredAgreementForm:
      thisSession.hasConsentRequiredAgreementForm,
    isSignerSet: thisSession.isSignerMemberSet,
  })

  return (
    <TopScreenContainer>
      <Stack spacing="14">
        <Center>
          <ExplanationRoomProgressBar
            status={session.explanationRevision.latestHistory.statusV2}
            isSignerSet={thisSession.isSignerMemberSet}
          />
        </Center>
        <DocumentList
          docSets={session.explanationRevision.docSets}
          docPagePath={docRevisionUid =>
            generatePath(Paths.PartnerExplanationRoomDocument, {
              trialUid: selectedTrial.uid,
              sessionUid,
              docRevisionUid,
            })
          }
        />
        {!!informationMessage && (
          <Center>
            <HStack spacing="2" color="blue.500" align="center">
              <MessageInformation />
              <Text fontWeight="bold" fontSize="lg">
                {informationMessage}
              </Text>
            </HStack>
          </Center>
        )}
      </Stack>
      {renderFloatingVideoCall()}
    </TopScreenContainer>
  )
}
