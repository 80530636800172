import React, { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { PagePrevious } from 'src/components/icon'
import {
  useCreateEnvelopeViewUrl,
  useSyncEnvelope,
} from 'src/features/explanation/api'
import { getExplanationRoomDocuSignReturnUrl } from 'src/features/explanation/utils/getExplanationRoomDocuSignRedirectUrl'
import { GqlError } from 'src/lib/gql-client'

import { useRequestSign } from '../../context/ExplanationRoomEvent'
import { useRoomMediaContext } from '../../context/MediaProvider'

type Props = {
  isSP?: boolean
  roomTopPath: string
  sessionUid: string
  docRevisionUid: string
  signerDisplayName: string
  signerActorUid: string
  completePath: string
  roomEventUid: string
}

export const DocuSignIframeContent: React.FC<Props> = ({
  isSP = false,
  roomTopPath,
  sessionUid,
  docRevisionUid,
  signerDisplayName,
  signerActorUid,
  completePath,
  roomEventUid,
}) => {
  const [viewUrl, setViewUrl] = useState<string>()
  const { videoExpanded, toggleVideoExpanded } = useRoomMediaContext()

  const [loading, setLoading] = useState(true)

  const {
    isOpen: isOpenAlreadyCompletedModal,
    onOpen: onOpenAlreadyCompletedModal,
    onClose: onCloseAlreadyCompletedModal,
  } = useDisclosure()

  const [createViewUrlError, setCreateViewUrlError] = useState<
    GqlError | undefined
  >()

  const {
    request: createEnvelopeViewUrl,
    requesting: creatingEnvelopeViewUrl,
  } = useCreateEnvelopeViewUrl({
    onSuccess: ({ url, alreadyFinished }) => {
      if (alreadyFinished) {
        onOpenAlreadyCompletedModal()
        setViewUrl(undefined)
        return
      }
      setViewUrl(url)
    },
    onError: error => {
      setCreateViewUrlError(error)
      setLoading(false)
    },
  })

  const { completeRequestSign, cancelRequestSignBySessionUid } =
    useRequestSign()

  const cancelRequestSign = () => {
    cancelRequestSignBySessionUid(sessionUid)
  }

  const returnUrl = getExplanationRoomDocuSignReturnUrl()

  // DocuSignView用URL生成
  useEffect(() => {
    if (!!viewUrl) return
    if (createViewUrlError) return
    if (creatingEnvelopeViewUrl) return
    ;(async () => {
      await createEnvelopeViewUrl({
        explanationDocRevisionUid: docRevisionUid,
        explanationSessionUid: sessionUid,
        returnUrl,
        signerActorUid,
      })
    })()
  }, [
    viewUrl,
    createViewUrlError,
    docRevisionUid,
    sessionUid,
    signerActorUid,
    createEnvelopeViewUrl,
    returnUrl,
    creatingEnvelopeViewUrl,
  ])

  // 署名/回答完了時、iframeの自動遷移を検知して親ページをリダイレクトさせるようにする
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const iframe = iframeRef.current

  const navigate = useNavigate()
  const { request: syncEnvelope } = useSyncEnvelope()

  useEffect(() => {
    const handleRedirect = async () => {
      // iframeのURLがDocuSignのURLの場合、Same-origin policyにひっかかる
      // https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
      // リダイレクト後のURLであれば取得できる
      let contentUrl: string | undefined = undefined
      try {
        contentUrl = iframe?.contentWindow?.location.href
      } catch (_) {
        return
      }
      if (!contentUrl) return
      if (!contentUrl.includes(returnUrl)) return
      // DocuSignでReturnURLに遷移されている場合の操作
      // リダイレクトされている場合、'{return_url}?event={event_name}'という形式のURLになる
      // eventは https://developers.docusign.com/docs/esign-rest-api/reference/envelopes/envelopeviews/createrecipient/ 参照
      if (contentUrl.includes('event=ttl_expired')) {
        // キャンセル後に再度アクセスした場合でtokenがexpiredしている場合などは再度URLを生成する
        await createEnvelopeViewUrl({
          explanationDocRevisionUid: docRevisionUid,
          explanationSessionUid: sessionUid,
          returnUrl,
          signerActorUid,
        })
        return
      }

      // TODO: 署名がすでに完了している場合も制御する

      if (contentUrl.includes('event=signing_complete')) {
        completeRequestSign({
          signerActorUid,
          sessionUid,
          docRevUid: docRevisionUid,
        })

        syncEnvelope({
          explanationDocRevisionUid: docRevisionUid,
          explanationSessionUid: sessionUid,
        })
        navigate(completePath)
      }
    }
    iframe?.addEventListener('load', handleRedirect)
    return () => {
      iframe?.removeEventListener('load', handleRedirect)
    }
  }, [
    iframe,
    returnUrl,
    createEnvelopeViewUrl,
    docRevisionUid,
    sessionUid,
    signerActorUid,
    completePath,
    completeRequestSign,
    syncEnvelope,
    navigate,
  ])

  return (
    <>
      <Flex direction="column" h="full">
        {isSP ? (
          <Flex px="2" py="6" bg="green.50">
            <HStack color="blue.500" spacing="1">
              <PagePrevious />
              <Link
                as={ReactRouterLink}
                to={roomTopPath}
                onClick={() => {
                  // 説明ルームに戻るときにビデオを拡大する
                  if (!videoExpanded) {
                    toggleVideoExpanded()
                  }
                }}
              >
                説明ルームへ戻る
              </Link>
            </HStack>
          </Flex>
        ) : (
          <Flex w="full" justify="space-between" p="6">
            <Box w="full">
              <Button
                as={ReactRouterLink}
                to={roomTopPath}
                colorScheme="gray"
                variant="outline"
                onClick={cancelRequestSign}
              >
                署名をキャンセル
              </Button>
            </Box>
            <Box w="full">
              <Text
                fontSize="xl"
                color="green.600"
                fontWeight="bold"
                textAlign="center"
              >
                {signerDisplayName} が署名中
              </Text>
            </Box>
            <Box w="full" />
          </Flex>
        )}

        {loading && (
          <Center
            position="fixed"
            left="0"
            top="0"
            w="full"
            h="full"
            color="green.600"
          >
            <Spinner />
          </Center>
        )}

        {!!createViewUrlError ? (
          <Center w="full" h="full">
            {createViewUrlError.message}
          </Center>
        ) : (
          <iframe
            ref={iframeRef}
            title="DocuSign page"
            src={viewUrl}
            onLoad={() => {
              setLoading(false)
            }}
            width="100%"
            height="100%"
          />
        )}
      </Flex>
      <Modal
        isOpen={isOpenAlreadyCompletedModal}
        onClose={onCloseAlreadyCompletedModal}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            すでに署名が完了しているため、説明ルームへお戻りください。
          </ModalHeader>
          <ModalFooter>
            <Stack>
              <HStack>
                <Button
                  colorScheme="green"
                  as={ReactRouterLink}
                  to={roomTopPath}
                  onClick={cancelRequestSign}
                >
                  説明ルームへ戻る
                </Button>
              </HStack>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
