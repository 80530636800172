import React from 'react'

import { useSelector } from 'react-redux'
import { Logline } from 'src/components/__legacy__icon/monochrome'
import { blue } from 'src/components/base/color/palette'
import { Modal } from 'src/components/base/modal/modal'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { Spacer } from 'src/components/spacer/spacer'
import { getSingle } from 'src/modules/entities/template/selector'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

type Props = {
  onClose: () => void
  templateUid: string
}

export const FolderMapModal: React.FC<Props> = props => {
  const template = useSelector((state: RootState) =>
    getSingle(state, props.templateUid),
  )
  const heads = ['タイトル', 'セクション', 'EDCフォルダ名']

  return (
    <Modal onClose={props.onClose} size="L">
      <ModalTitle title="セクション×EDCフォルダ名" />

      <ModalContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {heads.map(head => (
                  <TableHeadCell key={head}>{head}</TableHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {template &&
                template.mediumItems &&
                template.mediumItems.map(mediumItem => (
                  <TableRow borderBottom key={template.uid}>
                    <TableBodyCell>{template.title}</TableBodyCell>
                    <TableBodyCell>
                      <Flex alignCenter>
                        <div>{mediumItem.title}</div>
                        <Spacer size={10} horizontal />
                        {mediumItem.isLogline && <Logline color={blue[70]} />}
                      </Flex>
                    </TableBodyCell>
                    <TableBodyCell>{mediumItem.folderName}</TableBodyCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>
    </Modal>
  )
}

const TableContainer = styled.div`
  width: 100%;
  height: 300px;
`
