import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

const selectTrialHospital = async (params: {
  trialUid: string
  trialHospitalUid: string
}) => {
  const res = await createApiClient({
    path: '/accounts/select_trial',
    httpMethod: 'post',
    params: {
      body: {
        trial_uid: params.trialUid,
        trial_hospital_uid: params.trialHospitalUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useSelectTrialHospital = (option?: UseMutationOption<void>) => {
  return useMutation(
    (params: { trialUid: string; trialHospitalUid: string }) =>
      selectTrialHospital(params),
    option,
  )
}
