import { useState } from 'react'

import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Schema } from '@micin-jp/chicken-schema'
import { generatePath, useNavigate } from 'react-router-dom'
import { Add } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { Select } from '../../../../components/Select/Select'
import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import { parseSchema } from '../../../../lib/chicken-schema/utils'
import {
  AddReferredPatientButton_ActiveTrialMembersDocument,
  AddReferredPatientButton_CreateReferredPatientDocument,
  AddReferredPatientButton_CriteriaDocument,
  AddReferredPatientButton_CriteriaListDocument,
  AddReferredPatientButton_MainTrialHospitalDocument,
} from '../../../../lib/gql-client'
import {
  useGqlMutation,
  useGqlQuerySWR,
  useGqlQuerySWRNoSuspense,
} from '../../../../lib/gql-client/request'
import { useCurrentMember, useSelectedTrial } from '../../../auth/context'
import { PreviewCriteria } from '../../../criteria/components/PreviewCriteria/PreviewCriteria'
import { EditReferredPatient } from '../EditReferredPatient/EditReferredPatient'

export const AddReferredPatientButton: React.FC = () => {
  const {
    isOpen: isCriteriaSelectModalOpen,
    onOpen: onCriteriaSelectModalOpen,
    onClose: onCriteriaSelectModalClose,
  } = useDisclosure()

  const {
    isOpen: isEditPatientModalOpen,
    onOpen: onEditPatientModalOpen,
    onClose: onEditPatientModalClose,
  } = useDisclosure()

  const [criteriaUid, setCriteriaUid] = useState<string | undefined>()

  const [schema, setSchema] = useState<Schema | undefined>()

  const toast = useMirohaToast()

  const { selectedTrialHospitalUid } = useCurrentMember()

  const { data: criteriaListAndMembers } = useGqlQuerySWRNoSuspense(
    AddReferredPatientButton_CriteriaListDocument,
    isCriteriaSelectModalOpen || isEditPatientModalOpen ? {} : null,
  )

  const { data: criteriaData } = useGqlQuerySWRNoSuspense(
    AddReferredPatientButton_CriteriaDocument,
    criteriaUid
      ? {
          criteriaUid,
        }
      : null,
    {
      onSuccess: data => {
        setSchema(parseSchema(data.criteria.latestDetail.criteriaSchema.schema))
      },
    },
  )

  const {
    data: {
      trialHospital: { mainTrialHospital },
    },
  } = useGqlQuerySWR(
    AddReferredPatientButton_MainTrialHospitalDocument,
    {},
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  )

  const criteria = criteriaData?.criteria
  const mainHospitalName = mainTrialHospital?.hospital.name

  const { selectedTrial } = useSelectedTrial()

  const navigate = useNavigate()

  const { request: createPatient } = useGqlMutation(
    AddReferredPatientButton_CreateReferredPatientDocument,
    {
      onSuccess: ({ createReferredPatient: { referredPatientUid } }) => {
        toast({
          status: 'success',
          title: '紹介患者を登録しました',
        })
        onEditPatientModalClose()
        setCriteriaUid(undefined)
        setSchema(undefined)
        navigate(
          generatePath(Paths.ReferredPatient, {
            trialUid: selectedTrial.uid,
            referredPatientUid,
          }),
        )
      },
    },
  )

  const { data: activeMembersData } = useGqlQuerySWRNoSuspense(
    AddReferredPatientButton_ActiveTrialMembersDocument,
    selectedTrialHospitalUid ? { selectedTrialHospitalUid } : null,
  )

  const trialMembers =
    activeMembersData?.activeTrialMembersByTrialHospitalUids ?? []

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<Add />}
        onClick={onCriteriaSelectModalOpen}
      >
        新規登録
      </Button>
      {(isCriteriaSelectModalOpen || isEditPatientModalOpen) && (
        <>
          <Modal
            isOpen={isCriteriaSelectModalOpen}
            onClose={() => {
              onCriteriaSelectModalClose()
              setCriteriaUid(undefined)
              setSchema(undefined)
            }}
            size="3xl"
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent h="full">
              <ModalCloseButton />
              <ModalHeader>新規紹介患者登録</ModalHeader>
              <Box px="14">
                <VStack spacing="6" align="start" mt="8">
                  <Text>
                    患者に関連する選択除外基準を選択してください。（プレビューが開きます。）
                  </Text>
                  <Select
                    width={480}
                    value={criteriaUid}
                    items={
                      criteriaListAndMembers?.criteriaList.criteriaList.map(
                        c => ({
                          label: c.latestDetail.title,
                          value: c.criteriaUid,
                        }),
                      ) ?? []
                    }
                    isDisabled={
                      criteriaListAndMembers?.criteriaList.criteriaList
                        .length === 0
                    }
                    onChange={setCriteriaUid}
                  />
                  <HStack spacing="4" justify="end" w="full">
                    <ModalCancelButton />
                    <Button
                      isDisabled={!criteriaUid || !schema}
                      onClick={async () => {
                        onCriteriaSelectModalClose()
                        onEditPatientModalOpen()
                      }}
                    >
                      次へ
                    </Button>
                  </HStack>
                </VStack>
                <Divider my="6" />
              </Box>
              <ModalBody h="full">
                {!!criteria && schema && (
                  <Stack spacing={3} overflow="auto">
                    <Text as="h1" fontSize="xl" fontWeight="bold">
                      {criteria.latestDetail.title}
                    </Text>
                    <Text fontSize="sm">実施医療機関： {mainHospitalName}</Text>
                    <PreviewCriteria schema={schema} />
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <EditReferredPatient
            modalType="Create"
            isOpen={isEditPatientModalOpen}
            onClose={() => {
              onEditPatientModalClose()
              setCriteriaUid(undefined)
              setSchema(undefined)
            }}
            onBack={() => {
              onEditPatientModalClose()
              onCriteriaSelectModalOpen()
            }}
            mutate={async res => {
              await createPatient({
                input: {
                  criteriaUid: criteriaUid ?? '',
                  name: !!res.name ? res.name : undefined,
                  chartId: !!res.chartId ? res.chartId : undefined,
                  gender: res.gender,
                  age: res.age,
                  assigneeName: res.assigneeName,
                  assigneeRole: !!res.assigneeRole
                    ? res.assigneeRole
                    : undefined,
                  assigneeEmail: !!res.assigneeEmail
                    ? res.assigneeEmail
                    : undefined,
                  assigneePhoneNumber: !!res.assigneePhoneNumber
                    ? res.assigneePhoneNumber
                    : undefined,
                },
              })
            }}
            trialMembers={trialMembers ?? []}
          />
        </>
      )}
    </>
  )
}
