import { useCallback, useMemo, useState } from 'react'

import { Choice, Field } from '@micin-jp/chicken-schema'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { Member, MemberRole } from 'src/features/member/types'
import { GetPatientReturn } from 'src/features/patient/api'
import { useModal } from 'src/hooks/use-modal'
import { UploadedFile } from 'src/lib/gql-client'
import { assertNever } from 'src/utils/assertNever'
import { groupBy } from 'src/utils/groupBy'

import { useStickyQuery } from './useStickyQuery'
import { castUid } from '../../../utils/brandedUid'
import { generateUUID } from '../../../utils/generateUUID'
import {
  CheckboxFieldValue,
  CheckedFieldValue,
  ClearedFieldValue,
  DateFieldValue,
  FieldValue,
  FileFieldValue,
  NullableDateFieldValue,
  NumberFieldValue,
  RadioFieldValue,
  RepeatableSectionFieldValue,
  SelectMenuFieldValue,
  TextareaFieldValue,
  TextFieldValue,
  TimeFieldValue,
  ValidFieldValue,
  WorksheetDetail,
  WorksheetStatus,
} from '../types'
import { findSection } from '../utils/findSection'
import { formatFieldValue } from '../utils/format'
import { getFlattenFieldMap } from '../utils/getFlattenFields'
import { isFieldValueChanged } from '../utils/isChanged'
import {
  ChoiceTypeFieldValue,
  isChoiceTypeFieldValue,
} from '../utils/isChoiceTypeFieldValue'
import { isChosen } from '../utils/isChosen'
import { moveForwardIndex } from '../utils/moveForwardIndex'
import { validateFieldValue } from '../utils/validate'

type FieldValueDiffState =
  | {
      fetchedValue: ValidFieldValue
      newValue: CheckedFieldValue
      isChanged: boolean
      isNew: false
    }
  | {
      fetchedValue: undefined
      newValue: CheckedFieldValue
      isChanged: boolean
      isNew: true
    }

export type FieldValueParamToSave = {
  fetchedFieldValue: ValidFieldValue | undefined
  fieldValue: CheckedFieldValue
  isNew: boolean
  cleared: boolean
  toBeClearedWithCondition: boolean
}

type FieldValuesByFidMap = Map<Field['fid'], CheckedFieldValue[]>
type FieldValueDiffMap = Map<FieldValue['uid'], FieldValueDiffState>

type State = {
  fetchedWorksheet: WorksheetDetail
  fieldValuesByFidMap: FieldValuesByFidMap
  fieldValueDiffMap: FieldValueDiffMap
  droppedIndex: number[]
}

const initializeState = (fetchedWorksheet: WorksheetDetail): State => {
  const fetchedFieldValues: ValidFieldValue[] =
    fetchedWorksheet.latestWorksheetLog.fieldValues.map(value => ({
      ...value,
      isChecked: true,
      isValid: true,
    }))

  const fetchedFieldValuesByFid = groupBy(
    fetchedFieldValues,
    fieldValue => fieldValue.fid,
  )

  const fieldValuesByFidMap = new Map<Field['fid'], CheckedFieldValue[]>(
    Object.entries(fetchedFieldValuesByFid),
  )
  const fieldValueDiffMap = new Map<FieldValue['uid'], FieldValueDiffState>(
    fetchedFieldValues.map(fetchedValidValue => [
      fetchedValidValue.uid,
      {
        fetchedValue: fetchedValidValue,
        newValue: fetchedValidValue,
        isChanged: false,
        isNew: false,
      },
    ]),
  )

  return {
    fetchedWorksheet,
    fieldValuesByFidMap,
    fieldValueDiffMap,
    droppedIndex: [],
  }
}

export const useWorksheetValue = ({
  fetchedWorksheet,
  member,
  patient,
}: {
  fetchedWorksheet: WorksheetDetail
  member: Member
  patient: GetPatientReturn
}) => {
  const [state, setState] = useState<State>(initializeState(fetchedWorksheet))

  const fieldMap = useMemo(() => {
    return getFlattenFieldMap(fetchedWorksheet.schema.fields)
  }, [fetchedWorksheet.schema.fields])

  const findFieldValues = useCallback(
    ({ fid }: { fid: Field['fid'] }): CheckedFieldValue[] | undefined => {
      return state.fieldValuesByFidMap.get(fid)
    },
    [state.fieldValuesByFidMap],
  )

  const findFieldValue = useCallback(
    ({
      fid,
      index,
    }: {
      fid: Field['fid']
      index: number
    }): CheckedFieldValue | undefined => {
      return state.fieldValuesByFidMap
        .get(fid)
        ?.find(fieldValue => fieldValue.index === index)
    },
    [state.fieldValuesByFidMap],
  )

  // 自分自身に依存するフィールドを取得
  // 依存先の依存先まで含めて再起的に取得する
  const getDependencyFields = useCallback(
    (fid: string): Field[] => {
      const fields = Array.from(fieldMap.values())
      const findFields = (fid: string) =>
        fields.filter(field => {
          if (!field.visibleIf) return false
          return (
            field.visibleIf.operand === 'hasChoice' &&
            field.visibleIf.fid === fid
          )
        })

      return findFields(fid).flatMap(field => {
        return [field, ...getDependencyFields(field.fid)]
      })
    },
    [fieldMap],
  )

  const isCidSelected = useCallback(
    (value: ChoiceTypeFieldValue, cid: string) => {
      switch (value.type) {
        case 'checkbox':
          return value.checkboxValue.includes(cid)
        case 'radio':
          return value.radioValue === cid
        case 'selectMenu':
          return value.selectMenuValue === cid
        default:
          return assertNever(value)
      }
    },
    [],
  )

  const isVisibleField = useCallback(
    (fid: Field['fid'], index: number): boolean => {
      const field = fieldMap.get(fid)
      if (!field) return false

      if (!field.visibleIf) return true

      const operand = field.visibleIf.operand
      switch (operand) {
        case 'hasChoice':
          const relatedValue = findFieldValue({
            fid: field.visibleIf.fid,
            index,
          })
          if (!relatedValue) return false
          if (!isChoiceTypeFieldValue(relatedValue)) return false
          if (!isChosen(relatedValue, field.visibleIf.cid)) return false
          return isVisibleField(relatedValue.fid, relatedValue.index)

        default:
          return assertNever(operand)
      }
    },
    [findFieldValue, fieldMap],
  )

  const { showModal } = useModal()

  const { resetQuery } = useStickyQuery()

  const onChangeFieldValue = useCallback(
    (newValue: FieldValue) => {
      const doUpdate = () => {
        const newFidMap = new Map(state.fieldValuesByFidMap)
        const newDiffMap = new Map(state.fieldValueDiffMap)

        const checkedValue = validateFieldValue(newValue)

        const newFidMapValue = (checked: CheckedFieldValue) => {
          const values = newFidMap.get(checked.fid)
          if (!values) return [checked]
          return [...values.filter(v => v.index !== checked.index), checked]
        }
        newFidMap.set(checkedValue.fid, newFidMapValue(checkedValue))

        const newDiffState = (
          checked: CheckedFieldValue,
        ): FieldValueDiffState | undefined => {
          const current = newDiffMap.get(checked.uid)
          // 元の状態が存在しない && 値クリアの場合、diffStateから削除
          if (!current?.fetchedValue && checked.type === 'cleared') {
            return
          }
          return !!current
            ? {
                ...current,
                newValue: checked,
                isChanged: isFieldValueChanged(current.fetchedValue, checked),
              }
            : {
                fetchedValue: undefined,
                newValue: checked,
                isChanged: true,
                isNew: true,
              }
        }
        const diffState = newDiffState(checkedValue)
        if (diffState) {
          newDiffMap.set(newValue.uid, diffState)
        } else {
          newDiffMap.delete(newValue.uid)
        }

        // 消したindexで追加する場合（例: index:1を消去 -> index:1を再度追加）、droppedIndexから削除
        const droppedIndex = state.droppedIndex.filter(
          i => i !== checkedValue.index,
        )

        setState({
          ...state,
          droppedIndex,
          fieldValuesByFidMap: newFidMap,
          fieldValueDiffMap: newDiffMap,
        })
      }

      // 依存するフィールド、かつ付箋がついているフィールドが非表示になるケースではwarningモーダルを表示する
      const dependencyFields = getDependencyFields(newValue.fid)
      const willStickyHidden = dependencyFields.some(schemaField => {
        if (schemaField.visibleIf?.operand !== 'hasChoice') return false
        const wsField = fetchedWorksheet.fields.find(
          f => f.fid === schemaField.fid && f.fieldIndex === newValue.index,
        )
        if (!wsField || !wsField.stickyUid) return false
        if (!isVisibleField(wsField.fid, wsField.fieldIndex)) return false

        const willHiddenWithParentCondition = (targetField: Field): boolean => {
          if (targetField.visibleIf?.operand !== 'hasChoice') return false
          const parentField = fieldMap.get(targetField.visibleIf.fid)
          if (!parentField) return false
          const parentFieldValue = findFieldValue({
            fid: parentField.fid,
            index: newValue.index,
          })
          // 条件の選択肢（cid）が選択されているかチェック
          if (parentFieldValue?.uid === newValue.uid) {
            return isChoiceTypeFieldValue(newValue)
              ? !isCidSelected(newValue, targetField.visibleIf.cid)
              : parentFieldValue.type === 'cleared'
          }
          // 親フィールドが編集中のフィールド（newValue）でない場合、newValueに到達するまで編集中の再起的にチェックする
          // 孫階層に付箋がある状態で先端の選択肢を変更する場合など
          return willHiddenWithParentCondition(parentField)
        }

        return (
          newValue.type === 'cleared' ||
          willHiddenWithParentCondition(schemaField)
        )
      })
      if (willStickyHidden) {
        showModal({
          title: '入力値を変更します。',
          size: 'lg',
          submitText: '続ける',
          submitButtonColor: 'red',
          content: (
            <WarningMessage message="このまま一時保存すると、この選択肢に紐づくフィールドの入力値と付箋が削除されますのでご注意ください。" />
          ),
          onSubmit: () => {
            resetQuery()
            doUpdate()
          },
        })
        return
      }
      doUpdate()
    },
    [
      state,
      fetchedWorksheet.fields,
      fieldMap,
      findFieldValue,
      getDependencyFields,
      isCidSelected,
      isVisibleField,
      showModal,
      resetQuery,
    ],
  )

  const onChangeTextFieldValue = useCallback(
    (args: { fid: Field['fid']; index: number; textValue: string }) => {
      const found = findFieldValue({ ...args })
      const newValue: TextFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.textValue === '' ? 'cleared' : 'text',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeTextareaFieldValue = useCallback(
    (args: { fid: Field['fid']; index: number; textareaValue: string }) => {
      const found = findFieldValue({ ...args })
      const newValue: TextareaFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.textareaValue === '' ? 'cleared' : 'textarea',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeNumberFieldValue = useCallback(
    (args: { fid: Field['fid']; index: number; numberValue: string }) => {
      const found = findFieldValue({ ...args })
      const newValue: NumberFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.numberValue === '' ? 'cleared' : 'number',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeDateFieldValue = useCallback(
    (args: { fid: Field['fid']; index: number; dateValue: string }) => {
      const found = findFieldValue({ ...args })
      const newValue: DateFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.dateValue === '' ? 'cleared' : 'date',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeNullableDateFieldValue = useCallback(
    (args: { fid: Field['fid']; index: number; nullableDateValue: string }) => {
      const found = findFieldValue({ ...args })
      const newValue: NullableDateFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.nullableDateValue === '' ? 'cleared' : 'nullableDate',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeTimeFieldValue = useCallback(
    (args: { fid: Field['fid']; index: number; timeValue: string }) => {
      const found = findFieldValue({ ...args })
      const newValue: TimeFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.timeValue === '' ? 'cleared' : 'time',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeCheckboxFieldValue = useCallback(
    (args: {
      fid: Field['fid']
      index: number
      checkboxValue: Choice['cid'][]
    }) => {
      const found = findFieldValue({ ...args })
      const newValue: CheckboxFieldValue | ClearedFieldValue = {
        ...args,
        uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
        type: args.checkboxValue.length === 0 ? 'cleared' : 'checkbox',
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeRadioFieldValue = useCallback(
    (args: {
      fid: Field['fid']
      index: number
      radioValue: Choice['cid'] | undefined
    }) => {
      const found = findFieldValue({ ...args })
      const newValue: RadioFieldValue | ClearedFieldValue = !!args.radioValue
        ? {
            ...args,
            uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
            type: 'radio',
            radioValue: args.radioValue,
          }
        : {
            ...args,
            uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
            type: 'cleared',
          }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeSelectMenuFieldValue = useCallback(
    (args: {
      fid: Field['fid']
      index: number
      selectMenuValue: Choice['cid'] | undefined
    }) => {
      const found = findFieldValue({ ...args })
      const uid = found?.uid ?? castUid<FieldValue>(generateUUID())
      const newValue: SelectMenuFieldValue | ClearedFieldValue =
        !!args.selectMenuValue
          ? {
              ...args,
              uid,
              type: 'selectMenu',
              selectMenuValue: args.selectMenuValue,
            }
          : {
              ...args,
              uid,
              type: 'cleared',
            }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onAddRepeatableSectionRow = useCallback(
    ({ fid, onSuccess }: { fid: Field['fid']; onSuccess?: () => void }) => {
      const values = findFieldValues({ fid })
      // 並べ替えは不可なので連番で追加していく
      const index =
        values && values.length > 0
          ? Math.max(...values.map(f => f.index)) + 1
          : 1
      const newValue: RepeatableSectionFieldValue = {
        type: 'repeatableSection',
        uid: castUid<FieldValue>(generateUUID()),
        fid,
        index,
        isEnabled: true,
        isNew: true,
      }
      onChangeFieldValue(newValue)
      onSuccess?.()
    },
    [findFieldValues, onChangeFieldValue],
  )

  const onChangeFileFieldValue = useCallback(
    (args: {
      fid: Field['fid']
      index: number
      uploadedFiles: UploadedFile[]
    }) => {
      const found = findFieldValue({ ...args })
      if (args.uploadedFiles.length === 0) {
        return onChangeFieldValue({
          ...args,
          uid: found?.uid ?? castUid<FieldValue>(generateUUID()),
          type: 'cleared',
        })
      }
      const valueUid = found?.uid ?? castUid<FieldValue>(generateUUID())
      const newValue: FileFieldValue = {
        fid: args.fid,
        index: args.index,
        uid: valueUid,
        type: 'file',
        files: args.uploadedFiles.map((file, index) => ({
          worksheetFieldValueUid: valueUid,
          uploadedFileUID: file.uid,
          order: index + 1,
          // 保存済みのメモがあるチェック。メモは引き継がれるようにする。
          memo:
            found?.type === 'file'
              ? (found.files.find(f => f.uploadedFile.uid === file.uid)?.memo ??
                '')
              : '',
          uploadedFile: {
            uid: file.uid,
            name: file.name,
            url: file.url,
            size: file.size,
            extension: file.extension,
            savedAt: file.savedAt,
          },
        })),
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onChangeFileMemo = useCallback(
    (args: {
      fid: Field['fid']
      index: number
      fileUid: string
      memo: string
    }) => {
      const found = findFieldValue({ fid: args.fid, index: args.index })
      if (!found || found.type !== 'file') return
      const newValue: FileFieldValue = {
        ...found,
        files: found.files.map(file =>
          file.uploadedFile.uid === args.fileUid
            ? { ...file, memo: args.memo }
            : file,
        ),
      }
      return onChangeFieldValue(newValue)
    },
    [findFieldValue, onChangeFieldValue],
  )

  const onDeleteRepeatableSectionRow = useCallback(
    (row: RepeatableSectionFieldValue) => {
      const field = fieldMap.get(row.fid)
      if (!field || field.typeDef.type !== 'RepeatableSection') return

      // 保存済みの場合削除不可
      if (!state.fieldValueDiffMap.get(row.uid)?.isNew) return

      const values = findFieldValues({ fid: row.fid })
      if (!values) return
      const newFidMap = new Map(state.fieldValuesByFidMap)
      const newDiffMap = new Map(state.fieldValueDiffMap)

      newFidMap.set(
        row.fid,
        values.filter(v => v.uid !== row.uid),
      )
      newDiffMap.delete(row.uid)

      // 配下のフィールドも削除
      field.typeDef.fields.forEach(field => {
        const value = findFieldValue({ fid: field.fid, index: row.index })
        if (!value) return
        newFidMap.set(
          field.fid,
          newFidMap.get(field.fid)?.filter(v => v.uid !== value.uid) ?? [],
        )
        newDiffMap.delete(value.uid)
      })

      setState({
        ...state,
        droppedIndex: [...state.droppedIndex, row.index],
        fieldValuesByFidMap: newFidMap,
        fieldValueDiffMap: newDiffMap,
      })
    },
    [fieldMap, findFieldValues, findFieldValue, state],
  )

  /** 行データを無効化する
   *
   * @param row 無効化する行データ
   * @param onSuccess 無効化成功時のコールバック 引数は保存するか否か（保存済みの値と異なるか）のフラグ
   */
  const onDisableRepeatableSectionRow = useCallback(
    (
      row: RepeatableSectionFieldValue,
      onSuccess?: (shouldSave: boolean) => void,
    ) => {
      const field = fieldMap.get(row.fid)

      if (!field || field.typeDef.type !== 'RepeatableSection') return

      const values = findFieldValues({ fid: row.fid })
      if (!values) return
      const newFidMap = new Map(state.fieldValuesByFidMap)
      const newDiffMap = new Map(state.fieldValueDiffMap)
      const diff = state.fieldValueDiffMap.get(row.uid)
      if (
        diff === undefined ||
        diff.newValue.type !== 'repeatableSection' ||
        diff.fetchedValue?.type !== 'repeatableSection'
      )
        return

      newFidMap.set(
        row.fid,
        values.map(v =>
          v.index !== row.index ? v : { ...v, isEnabled: false },
        ),
      )

      newDiffMap.set(row.uid, {
        ...diff,
        isChanged: diff.fetchedValue.isEnabled,
        newValue: { ...diff.newValue, isEnabled: false },
      })

      setState({
        ...state,
        fieldValuesByFidMap: newFidMap,
        fieldValueDiffMap: newDiffMap,
      })
      if (!!onSuccess) {
        onSuccess(diff.fetchedValue.isEnabled)
      }
    },
    [state, fieldMap, findFieldValues],
  )

  /** 行データを有効化する
   *
   * @param row 有効化する行データ
   * @param onSuccess 有効化成功時のコールバック 引数は保存するか否か（保存済みの値と異なるか）のフラグ
   */
  const onEnabledRepeatableSectionRow = useCallback(
    (
      row: RepeatableSectionFieldValue,
      onSuccess?: (shouldSave: boolean) => void,
    ) => {
      const field = fieldMap.get(row.fid)

      if (!field || field.typeDef.type !== 'RepeatableSection') return

      const values = findFieldValues({ fid: row.fid })
      if (!values) return
      const newFidMap = new Map(state.fieldValuesByFidMap)
      const newDiffMap = new Map(state.fieldValueDiffMap)
      const diff = state.fieldValueDiffMap.get(row.uid)
      if (
        diff === undefined ||
        diff.newValue.type !== 'repeatableSection' ||
        diff.fetchedValue?.type !== 'repeatableSection'
      )
        return

      newFidMap.set(
        row.fid,
        values.map(v =>
          v.index !== row.index ? v : { ...v, isEnabled: true },
        ),
      )
      newDiffMap.set(row.uid, {
        ...diff,
        isChanged: !diff.fetchedValue.isEnabled,
        newValue: { ...diff.newValue, isEnabled: true },
      })

      setState({
        ...state,
        fieldValuesByFidMap: newFidMap,
        fieldValueDiffMap: newDiffMap,
      })

      if (!!onSuccess) {
        onSuccess(!diff.fetchedValue.isEnabled)
      }
    },
    [state, fieldMap, findFieldValues],
  )

  const updateFieldValueOnSave = useCallback(
    (param: FieldValueParamToSave): CheckedFieldValue => {
      if (param.cleared || param.toBeClearedWithCondition) {
        return {
          ...param.fieldValue,
          index: moveForwardIndex(param.fieldValue.index, state.droppedIndex),
          type: 'cleared',
        }
      }

      return {
        ...formatFieldValue(param.fieldValue),
        index: moveForwardIndex(param.fieldValue.index, state.droppedIndex),
      }
    },
    [state.droppedIndex],
  )

  const fieldValueDiffList: FieldValueDiffState[] = useMemo(() => {
    return Array.from(state.fieldValueDiffMap.values())
  }, [state.fieldValueDiffMap])

  const fieldValueParamsToSave: FieldValueParamToSave[] = useMemo(() => {
    return fieldValueDiffList.reduce((acc, diff) => {
      const isVisible = isVisibleField(diff.newValue.fid, diff.newValue.index)
      // 以下の場合は一時保存の対象外
      // - 値変更なし && 表示
      // - 新規作成 && 非表示
      // - 保存済みの値がクリア && 非表示
      if (!diff.isChanged && isVisible) return acc
      if (diff.isNew && !isVisible) return acc
      if (diff.fetchedValue?.type === 'cleared' && !isVisible) return acc

      const newParam: FieldValueParamToSave = {
        fetchedFieldValue: diff.fetchedValue,
        fieldValue: diff.newValue,
        isNew: diff.isNew,
        cleared: diff.newValue.type === 'cleared',
        toBeClearedWithCondition: !isVisible,
      }
      return [...acc, newParam]
    }, [] as FieldValueParamToSave[])
  }, [fieldValueDiffList, isVisibleField])

  const isChanged = useMemo(() => {
    return (
      fieldValueDiffList.filter(
        diff =>
          diff.isChanged &&
          isVisibleField(diff.newValue.fid, diff.newValue.index),
      ).length > 0
    )
  }, [fieldValueDiffList, isVisibleField])

  const areValuesToSaveValid = useMemo(() => {
    return fieldValueParamsToSave.every(param => param.fieldValue.isValid)
  }, [fieldValueParamsToSave])

  const canEdit = useMemo(() => {
    const notEditableStatus: WorksheetStatus[] = ['confirmed', 'disabled']
    const notEditableMemberRole: MemberRole[] = ['DM', 'CRA']
    if (notEditableMemberRole.includes(member.role)) return false

    if (patient.status === 'Disable') {
      return false
    }

    return !notEditableStatus.includes(
      fetchedWorksheet.latestWorksheetLog.status,
    )
  }, [fetchedWorksheet, member, patient])

  /** 親がrepeatableSectionである場合、その行の有効/無効をチェック */
  const isRepeatableSectionEnabled = useCallback(
    (index: number, fid: string) => {
      const targetSectionField = findSection(fieldMap, fid)

      if (targetSectionField?.typeDef.type !== 'RepeatableSection') return true

      const fieldValue = findFieldValue({ fid: targetSectionField.fid, index })
      return fieldValue?.type === 'repeatableSection' && fieldValue.isEnabled
    },
    [findFieldValue, fieldMap],
  )

  const reset = useCallback((worksheet: WorksheetDetail) => {
    setState(initializeState(worksheet))
  }, [])

  return {
    fetchedWorksheet,
    patient,
    findFieldValues,
    findFieldValue,
    droppedIndex: state.droppedIndex,
    onChangeTextFieldValue,
    onChangeTextareaFieldValue,
    onChangeNumberFieldValue,
    onChangeDateFieldValue,
    onChangeNullableDateFieldValue,
    onChangeTimeFieldValue,
    onChangeCheckboxFieldValue,
    onChangeRadioFieldValue,
    onChangeSelectMenuFieldValue,
    onChangeFileFieldValue,
    onChangeFileMemo,
    onAddRepeatableSectionRow,
    onDeleteRepeatableSectionRow,
    onDisableRepeatableSectionRow,
    onEnabledRepeatableSectionRow,
    isVisibleField,
    updateFieldValueOnSave,
    fieldValueParamsToSave,
    fieldValueDiffList,
    canEdit,
    isRepeatableSectionEnabled,
    isChanged,
    areValuesToSaveValid,
    reset,
  }
}
