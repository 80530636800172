import { useCallback, useMemo, useState } from 'react'

import { useCurrentMember } from 'src/features/auth/context'
import { usePatient } from 'src/features/patient/api'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

import { useWorksheet } from '../api'
import { DisableWorksheetModal } from '../components/DisableWorksheetModal/DisableWorksheetModal'
import { EnableWorksheetModal } from '../components/EnableWorksheetModal/EnableWorksheetModal'
import { WorksheetHistoryDrawer } from '../components/WorksheetHistoryDrawer/WorksheetHistoryDrawer'
import { WorksheetDetail } from '../types'

const menuItemKeys = [
  'EDC',
  'EDCHistory',
  'WorksheetHistory',
  'DataOutput',
  'EnableWorksheet',
  'DisableWorksheet',
] as const

type MenuItemKey = (typeof menuItemKeys)[number]

type MenuItem = {
  key: MenuItemKey
  label: string
  color?: string
  onClick: () => void
}

export const useWorksheetMenu = ({
  patientUid,
  worksheet,
}: {
  patientUid: string
  worksheet: WorksheetDetail
}) => {
  const { currentMember } = useCurrentMember()

  const { data: patient, mutate: mutatePatient } = usePatient({
    patientUid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const { mutate: mutateWorksheet } = useWorksheet({
    worksheetUid: worksheet.uid,
  })

  // TODO: use permission definition
  const isWorksheetEditableRole = useMemo(() => {
    return currentMember.role === 'Dr' || currentMember.role === 'CRC'
  }, [currentMember.role])

  const isPatientEnabled = useMemo(() => {
    return patient?.status !== 'Disable'
  }, [patient?.status])

  const [selectedMenuItemKey, setSelectedMenuItemKey] =
    useState<MenuItemKey | null>(null)

  const onChange = useCallback((key: MenuItemKey) => {
    setSelectedMenuItemKey(key)
  }, [])

  const menuConditions: Record<MenuItemKey, boolean> = useMemo(
    () => ({
      EDC: false, // TBD
      EDCHistory: false, // TBD
      WorksheetHistory: true, // 常に表示
      DataOutput: false, // TBD
      EnableWorksheet:
        worksheet.status === 'disabled' &&
        isWorksheetEditableRole &&
        isPatientEnabled,
      DisableWorksheet:
        worksheet.status !== 'disabled' &&
        isWorksheetEditableRole &&
        isPatientEnabled,
    }),
    [worksheet, isWorksheetEditableRole, isPatientEnabled],
  )

  const menuLabels: Record<MenuItemKey, string> = useMemo(
    () => ({
      EDC: 'EDC連携',
      EDCHistory: 'EDC連携履歴',
      WorksheetHistory: '操作履歴',
      DataOutput: 'データ出力',
      EnableWorksheet: '有効化',
      DisableWorksheet: '無効化',
    }),
    [],
  )

  const menuItems: MenuItem[] = useMemo(() => {
    return menuItemKeys
      .filter(key => menuConditions[key])
      .map(key => ({
        key,
        label: menuLabels[key],
        color: key === 'DisableWorksheet' ? colors.red[500] : undefined,
        onClick: () => onChange(key),
      }))
  }, [onChange, menuConditions, menuLabels])

  const isFollowUp = useMemo(() => {
    if (!patient) return false

    const observation = [
      ...patient.observationVisits,
      ...patient.observationFollowUps,
    ].find(o => o.patientObservationUid === worksheet.patientObservationUid)

    return observation?.__typename === 'PatientObservationFollowUp'
  }, [patient, worksheet.patientObservationUid])

  const renderContent = useCallback((): React.ReactNode => {
    return (
      <>
        <WorksheetHistoryDrawer
          worksheet={worksheet}
          isOpen={selectedMenuItemKey === 'WorksheetHistory'}
          isFollowUp={isFollowUp}
          onClose={() => setSelectedMenuItemKey(null)}
        />

        <EnableWorksheetModal
          worksheet={worksheet}
          isFollowUp={isFollowUp}
          isOpen={selectedMenuItemKey === 'EnableWorksheet'}
          onCancel={() => setSelectedMenuItemKey(null)}
          onComplete={async worksheet => {
            await Promise.all([
              mutateWorksheet(worksheet, false),
              mutatePatient(),
            ])
            setSelectedMenuItemKey(null)
          }}
        />

        <DisableWorksheetModal
          worksheet={worksheet}
          isFollowUp={isFollowUp}
          isOpen={selectedMenuItemKey === 'DisableWorksheet'}
          onCancel={() => setSelectedMenuItemKey(null)}
          onComplete={async worksheet => {
            await Promise.all([
              mutateWorksheet(worksheet, false),
              mutatePatient(),
            ])
            setSelectedMenuItemKey(null)
          }}
        />
      </>
    )
  }, [
    mutateWorksheet,
    isFollowUp,
    selectedMenuItemKey,
    mutatePatient,
    worksheet,
  ])

  return {
    menuItems,
    renderContent,
  }
}
