import { SERVER_URL } from 'src/modules/server/const'
import { requestDelete } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const submitDelete = async ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestDelete<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${templateUid}`,
    params: {},
  })

  return {}
}
