import React from 'react'

import { Box, HStack, Text } from '@chakra-ui/react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'

import { Hamburger } from '../__legacy__icon/monochrome'
import { gray } from '../base/color/palette'

type DragAndDropItemProps = {
  itemKey: string
  id: string
  title: string
  top: boolean

  findItem: (uid: string) => { index: number }
  moveItem: (uid: string, atIndex: number) => void
  onDrop?: () => void
}

type HoveredItem = {
  index: number
  id: string
}

export const SortableDragAndDropItem: React.FC<DragAndDropItemProps> = ({
  itemKey,
  id,
  findItem,
  moveItem,
  title,
  top,
}) => {
  const { index: originalIndex } = findItem(id)

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: itemKey,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      item: { id, originalIndex },

      // drag が終了した時に行う処理
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item

        // drop が順序変更可能なコンポーネント以外に対して行われた場合、元の index に戻す
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveItem(droppedId, originalIndex)
        }
      },
    }),
    [id, originalIndex, moveItem],
  )

  const [, drop] = useDrop(
    () => ({
      accept: itemKey,

      // drag されたコンポーネントがこのコンポーネントを hover した時に行う処理
      // このコンポーネントの index に drag されたコンポーネントを移動している
      hover: (draggedItem: HoveredItem, monitor: DropTargetMonitor) => {
        if (id === draggedItem.id) return

        const { index: overIndex } = findItem(id)
        moveItem(draggedItem.id, overIndex)
      },
    }),
    [findItem, moveItem],
  )

  return (
    <HStack
      ref={preview}
      opacity={isDragging ? 0.3 : 1}
      borderBottom="1px"
      borderColor="gray.100"
      py="3"
    >
      <Box ref={node => drag(drop(node))} p="8px" color="gray.500">
        <Hamburger cursor="pointer" color={gray[20]} />
      </Box>
      <Text wordBreak="break-all">{title}</Text>
    </HStack>
  )
}
