import { roleToText } from 'src/modules/text'

import { Role } from './entity'

export const roleOptions: Array<{ name: string; value: Role }> = [
  Role.Dr,
  Role.CRC,
  Role.DM,
  Role.CRA,
].map(r => ({
  name: roleToText(r),
  value: r,
}))
