import { useCallback, useState } from 'react'

import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useRoomSession } from '@micin-jp/call-lib-react'
import { useNavigate } from 'react-router-dom'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

import { useFinishExplanationSession } from '../../../explanation/api/finishSession'
import { useSessionForFinishSession } from '../../api/getSessionForFinishSession'
import {
  useActiveDevice,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useThisSession } from '../../hooks/useThisSession'
import { isAgreementCompletedOrRejected } from '../../utils/explanationRevisionStatusUtils'
import { getRoomFinishedRoute } from '../../utils/getRoomFinishedRoute'

type Props = {
  sessionUid: string
}

export const FinishSession: React.FC<Props> = ({ sessionUid }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  const { data: session, mutate: mutateSession } = useSessionForFinishSession({
    explanationSessionUid: sessionUid,
  })

  useUpdateRevisionStatus({
    listener: async () => {
      await mutateSession()
    },
  })
  const [deliveryChecked, setDeliveryChecked] = useState<boolean>()

  const {
    account: { selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const isPartnerMember =
    session?.explanationRevision.partnerTrialHospital?.uid ===
    selectedTrialHospitalUid

  const { request: finishSession } = useFinishExplanationSession({
    onSuccess: () => {
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  const navigate = useNavigate()

  const { sendDeleted } = useActiveDevice()

  const { leaveRoom: leaveVideoCallRoom } = useRoomSession()

  const leaveRoom = useCallback(async () => {
    leaveVideoCallRoom().then()
    await sendDeleted()
    navigate(
      getRoomFinishedRoute({
        type: session?.explanationRevision.explanationType,
        isLeft: true,
        isPartnerMember,
      }),
    )
  }, [
    leaveVideoCallRoom,
    sendDeleted,
    navigate,
    session?.explanationRevision.explanationType,
    isPartnerMember,
  ])

  if (!session) {
    return null
  }

  const browsedDocRevs = session.explanationRevision.docSets
    .flatMap(docRev => docRev.documentRevisions)
    .filter(docRev => !!docRev?.latestPatientSideBrowsingHistory)

  const status = session.explanationRevision.latestHistory.statusV2
  const isCompleted = isAgreementCompletedOrRejected(status)

  const canFinish =
    session.explanationRevision.explanationType !== 'RemotePartner' ||
    isCompleted

  // 同意完了かつ未交付の場合はチェックを要求する
  // 一つも閲覧していない場合は交付不可なのでチェック不要
  const isAgreementDoneButNotDelivered =
    status === 'AgreementCompleted' && browsedDocRevs.length > 0

  const handleOpen = () => {
    // モーダルを開く時点のsessionの状態に基づく
    setDeliveryChecked(!isAgreementDoneButNotDelivered)
    onOpen()
  }

  const finishActionLabel = isCompleted ? '終了' : '中断'

  return (
    <>
      <Button onClick={handleOpen} variant="outline" colorScheme="red">
        {canFinish
          ? `説明を${finishActionLabel}する`
          : `説明ルームから退出する`}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        {canFinish ? (
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {isCompleted ? `説明を終了しますか？` : '説明を中断する'}
            </ModalHeader>
            <ModalBody>
              <Stack spacing="8">
                {session.explanationRevision.explanationType !== 'InPerson' &&
                  isCompleted && (
                    <Text>
                      説明を終了すると、現在説明ルームを開いている全参加者の説明画面が自動的に終了します。
                    </Text>
                  )}
                {isAgreementDoneButNotDelivered && (
                  <Stack spacing="4">
                    <WarningMessage message="署名済文書の交付が完了していません。終了しますか？" />
                    <Checkbox
                      isChecked={deliveryChecked}
                      onChange={e => setDeliveryChecked(e.target.checked)}
                      colorScheme="green"
                    >
                      このまま説明を終了する
                    </Checkbox>
                  </Stack>
                )}
                {!isCompleted && (
                  <Text>同意は完了していません。説明を中断しますか？</Text>
                )}
              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing="4">
                <Button colorScheme="gray" variant="ghost" onClick={onClose}>
                  キャンセル
                </Button>
                <Button
                  colorScheme="red"
                  onClick={async () => {
                    await finishSession({ explanationSessionUid: session.uid })
                  }}
                  isDisabled={!deliveryChecked}
                >{`${finishActionLabel}する`}</Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>{`説明ルームから退出する`}</ModalHeader>
            <ModalBody>
              <Text>同意は完了していません。説明ルームから退出しますか？</Text>
            </ModalBody>

            <ModalFooter>
              <ModalCancelButton />
              <Button
                colorScheme="red"
                onClick={leaveRoom}
              >{`退出する`}</Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
