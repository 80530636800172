import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  EditStickyMessageDocument,
  EditStickyMessageInput,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const editStickyMessage = async (input: EditStickyMessageInput) => {
  try {
    await graphqlRequest(EditStickyMessageDocument, { input })
  } catch (error) {
    throw error
  }
}

export const useEditStickyMessage = (
  option?: UseMutationOption<void, GqlError>,
) => {
  return useMutation(
    (input: EditStickyMessageInput) => editStickyMessage(input),
    option,
  )
}
