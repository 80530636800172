import React from 'react'

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type Props = {
  onClose: () => void
  onFinish: () => void
  warnMessage?: string
  isPreview?: boolean
}

export const FinishExplanationModal: React.FC<Props> = ({
  onClose,
  onFinish,
  isPreview,
}) => {
  const title = isPreview
    ? 'プレビューを終了しますか?'
    : `説明ロビーから退出しますか？`

  const onSubmit = () => {
    try {
      onFinish()
      onClose()
    } catch (e) {
      throw e
    }
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalFooter>
          <ModalCancelButton />
          <Button colorScheme="red" onClick={onSubmit}>
            退出する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
