import React from 'react'

import { Button, ComponentStyleConfig, useModalContext } from '@chakra-ui/react'

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      borderRadius: '8px',
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
      py: '10',
      maxH: 'calc(100vh - 2rem)',
    },
    header: {
      px: '14',
      py: '0',
    },
    body: {
      pl: '14',
      // 14=3.5rem スクロールbarの右側の余白を6px（0.375rem）取るためにpaddingとmarginを調整
      pr: '3.125rem',
      mr: '0.375rem',
      pt: '0',
      pb: '4',
      mt: '8',
    },
    footer: {
      px: '14',
      py: '0',
      mt: '4',
      display: 'flex',
      gap: '4',
    },
    closeButton: {
      _focus: { boxShadow: 'none' },
      ':focus-visible': { boxShadow: 'outline' },
    },
  },
  defaultProps: {
    isCentered: true,
    scrollBehavior: 'inside',
  },
}

/** モーダルのfooterに表示するキャンセルボタン
 *
 * クリックするとModalのonCloseが呼ばれます
 * @param text ボタンに表示するテキスト デフォルトは「キャンセル」
 *
 */
export const ModalCancelButton: React.FC<{ text?: string }> = ({
  text = 'キャンセル',
}) => {
  const { onClose } = useModalContext()
  return (
    <Button variant="ghost" colorScheme="gray" onClick={onClose}>
      {text}
    </Button>
  )
}
