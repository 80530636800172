import { ApiResponse } from 'src/lib/api-client'
import { castUid } from 'src/utils/brandedUid'

import { WSDataExportJob, WSExportJobStatus } from '../types'

export const parseWSExportJobResponse = (
  res: ApiResponse<'WsDataExportJobV1'>,
): WSDataExportJob => {
  return {
    uid: castUid<WSDataExportJob>(res.uid),
    completedAt: res.completed_at,
    registeredAt: res.registered_at,
    lastUpdatedBy: res.last_updated_by,
    status: parseStatus(res.status),
  }
}

const statusMap: Record<number, WSExportJobStatus> = {
  0: 'waiting',
  1: 'running',
  2: 'done',
  3: 'canceled',
  4: 'error',
  5: 'expired',
}

const parseStatus = (status: number): WSExportJobStatus => {
  if (!statusMap[status]) {
    throw new Error('status is invalid')
  }
  return statusMap[status]
}
