import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { white } from 'src/components/base/color/palette'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import { getList as getTemplates } from 'src/modules/entities/template/selector'
import styled from 'styled-components'

import { Empty } from './empty'
import { useFetch } from './use-fetch'
import { trialIdParamName } from '../../../../../trial-detail'
import { patientIdParamName } from '../../../patient-detail'
import { emptyPath } from '../../routes'
import { worksheetIdParamName } from '../detail/worksheet-detail'
import { WorksheetDetailRouter } from '../detail/worksheet-detail-router'

const Card = styled.div`
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${white};
`

export const List = () => {
  const { trialUid = '', patientUid = '' } = useParams<{
    [trialIdParamName]: string
    [patientIdParamName]: string
  }>()
  const { fetched } = useFetch({ trialUid, patientUid })

  const templates = useSelector(getTemplates).filter(
    t => t.status === TemplateStatus.Released,
  )

  const redirectPath = templates.length === 0 ? `${emptyPath}` : ``

  if (!fetched) {
    return null
  }

  return (
    <Card>
      <Routes>
        <Route path={`${emptyPath}`} element={<Empty />} />
        <Route
          path={`:${worksheetIdParamName}/*`}
          element={<WorksheetDetailRouter />}
        />
        <Route path={`*`} element={<Navigate replace to={redirectPath} />} />
      </Routes>
    </Card>
  )
}
