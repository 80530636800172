import React from 'react'

import { Text } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { Warning } from 'src/components/__legacy__icon/monochrome'
import { blue, gray, yellow } from 'src/components/base/color/palette'
import { RHFSingleCheckbox } from 'src/components/form-redesigned/single-checkbox'
import { Spacer } from 'src/components/spacer/spacer'
import {
  Questionnaire,
  QuestionnaireStatus,
} from 'src/modules/entities/questionnaire/entity'
import { Schedule } from 'src/modules/entities/schedule/entity'
import styled from 'styled-components'

import { AddButton } from './add/add-button'
import { PaneProvider } from './contexts/pane-context'
import { WithEditPane } from './edit/with-edit-pane'

type Props = {
  questionnaires: Pick<
    Questionnaire,
    'uid' | 'title' | 'status' | 'scheduleUids'
  >[]
  schedules: Schedule[]
  canEditSchedule: boolean
  showAddButton: boolean
}

export const QuestionnaireScheduleTable: React.FC<Props> = ({
  questionnaires,
  schedules,
  canEditSchedule,
  showAddButton,
}) => {
  const { register } = useFormContext()

  const scheduleAfterTrial = schedules.find(s => s.shouldDeliverAfterTrial)
  const schedulesInTrial = schedules.filter(s => !s.shouldDeliverAfterTrial)

  return (
    <PaneProvider>
      <TableGroup>
        <FixedTable>
          <THead>
            <THeadRow>
              <THeadTitleItem>質問票名</THeadTitleItem>
              <THeadItem>回答日</THeadItem>
            </THeadRow>
            <THeadRowGroup>
              <THeadRow>
                <THeadTitleItem />
                <THeadItem />
              </THeadRow>
              <THeadRowForTitle>
                <THeadTitleItem />
                <THeadItemNormal>試験終了時</THeadItemNormal>
              </THeadRowForTitle>
            </THeadRowGroup>
          </THead>
          <TBody>
            {questionnaires.map((questionnaire, index) => (
              <TBodyRowGroup key={index}>
                <TBodyTitleItem>
                  <Text
                    display="-webkit-flex"
                    overflow="hidden"
                    sx={{
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {questionnaire.title}
                  </Text>
                  {questionnaire.status === QuestionnaireStatus.Draft && (
                    <Warning size="S" color={yellow[70]} />
                  )}
                </TBodyTitleItem>
                <TBodyItem>
                  <RHFSingleCheckbox
                    name={`questionnaires.${questionnaire.uid}.scheduleUids.${scheduleAfterTrial?.uid}`}
                    register={register}
                    defaultChecked={questionnaire.scheduleUids.some(
                      uid => uid === scheduleAfterTrial?.uid,
                    )}
                    disabled={!canEditSchedule}
                  />
                </TBodyItem>
              </TBodyRowGroup>
            ))}
          </TBody>
        </FixedTable>
        <Table>
          <TableColumnGroup>
            {schedulesInTrial.map(schedule => {
              return (
                <TableColumn key={schedule.uid}>
                  <THead>
                    <THeadRow />
                    <THeadRow>
                      <THeadItemNormal>
                        <span>+{schedule.startAfterDays}</span>
                      </THeadItemNormal>
                    </THeadRow>
                    <THeadRowForTitle>
                      <WithEditPane
                        schedule={schedule}
                        renderTitle={(props: { onClick: () => void }) => {
                          return (
                            <THeadItemScheduleTitle onClick={props.onClick}>
                              <span>{schedule.title}</span>
                            </THeadItemScheduleTitle>
                          )
                        }}
                      ></WithEditPane>
                    </THeadRowForTitle>
                  </THead>

                  {questionnaires.map((questionnaire, index) => (
                    <TBodyRow key={index}>
                      <TBodyItem>
                        <RHFSingleCheckbox
                          name={`questionnaires.${questionnaire.uid}.scheduleUids.${schedule.uid}`}
                          register={register}
                          defaultChecked={questionnaire.scheduleUids.some(
                            scheduleUid => scheduleUid === schedule.uid,
                          )}
                          disabled={!canEditSchedule}
                        />
                      </TBodyItem>
                    </TBodyRow>
                  ))}
                </TableColumn>
              )
            })}
            {showAddButton && (
              <TableColumnGroup>
                <TableColumn>
                  <THead>
                    <THeadRow />
                    <THeadRow>
                      <Spacer horizontal size={10} />
                      <AddButton />
                    </THeadRow>
                    <THeadRowForTitle />
                  </THead>
                </TableColumn>
              </TableColumnGroup>
            )}
          </TableColumnGroup>
        </Table>
      </TableGroup>
    </PaneProvider>
  )
}

const THead = styled.div`
  border-bottom: 2px solid #00a79a;
`

const THeadRowGroup = styled.div`
  width: 100%;
  border-right: 1px dashed ${gray[40]};
`

const THeadRow = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 20px;
  margin: 12px 0;
  :last-child {
    margin-bottom: 18px;
  }
`

const THeadRowForTitle = styled(THeadRow)`
  height: 2em;
  display: flex;
  align-items: flex-start;
`

const THeadItem = styled.div`
  flex-shrink: 0;
  width: 100px;
  font-size: 16px;
  line-height: 1.2em;
  height: 2.4em;
  font-weight: bold;
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  :not(:last-child) {
    margin-right: 12px;
  }
`

const THeadTitleItem = styled(THeadItem)`
  width: 200px;
`

const THeadItemNormal = styled(THeadItem)`
  font-weight: normal;
  text-align: center;
`

const THeadItemScheduleTitle = styled(THeadItemNormal)`
  text-decoration-line: underline;
  color: ${blue[70]};
  cursor: pointer;
  text-align: center;
`

const TBody = styled.div`
  width: 100%;
  border-right: 1px dashed ${gray[40]};
`

const TBodyRow = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 0;
  text-decoration: none;
  color: ${gray[90]};
  &:not(:last-child) {
    border-bottom: 1px dashed ${gray[40]};
  }
`

const TBodyRowGroup = styled(TBodyRow)`
  width: 100%;
  padding-right: 50px;
`

const TBodyItem = styled.div`
  flex-shrink: 0;
  width: 90px;
  word-break: break-all;
  display: flex;
  justify-content: center;

  :not(:last-child) {
    margin-right: 12px;
  }
`

const TBodyTitleItem = styled(TBodyItem)`
  width: 200px;
  justify-content: space-between;
`

const TableGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const Table = styled.div`
  height: 100%;
  flex-direction: column;
`

const FixedTable = styled(Table)`
  padding-left: 36px;
  width: auto;
`

const TableColumnGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const TableColumn = styled.div`
  height: 100%;
`
