import styled, { css } from 'styled-components'
import { width, WidthProps } from 'styled-system'

import { blue, gray, Palette } from '../color/palette'

/**
 * @deprecated Use chakra-ui Table instead.
 */
export const Table = styled.div<{ backgroundColor?: Palette }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${gray[40]};
  border-radius: 10px;
  background-color: ${p => p.backgroundColor};
`

const SCROLL_BAR_WIDTH = 6
const RIGHT_SPACE = 10 + SCROLL_BAR_WIDTH + 20 //Body部における、スクロールバーの外側（10）、本体、内側（20）のスペースを足した値

/**
 * @deprecated Use chakra-ui Thead instead.
 */
export const TableHead = styled.div`
  width: 100%;
  padding: 0 ${RIGHT_SPACE}px 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${gray[40]};
`

type TableBodyProps = {
  children: React.ReactNode
}

/**
 * @deprecated Use chakra-ui Tbody instead.
 */
export const TableBody: React.FC<TableBodyProps> = ({ children }) => {
  return (
    <TableBodyContainer>
      <ScrollableContainer>{children}</ScrollableContainer>
    </TableBodyContainer>
  )
}

const TableBodyContainer = styled.div`
  position: relative;
  flex: 1;
  margin-right: 10px;
`

const ScrollableContainer = styled.div`
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`

type TableRowProps = {
  borderBottom?: boolean
  hover?: boolean
  disabled?: boolean
}

/**
 * @deprecated Use chakra-ui Tr instead.
 */
export const TableRow = styled.div<TableRowProps>`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-bottom: ${p => p.borderBottom && `1px dashed ${gray[40]}`};
  color: ${p => (p.disabled ? gray[55] : gray[100])};
  cursor: ${p => p.hover && 'pointer'};
  :hover {
    background-color: ${p => p.hover && blue[5]};
  }
  :active {
    background-color: ${p => p.hover && blue[10]};
  }
`

type CellProps = {
  align?: 'left' | 'center' | 'right'
}

const getJustifyContent = (align: CellProps['align']) => {
  switch (align) {
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

const cellStyle = css<CellProps & WidthProps>`
  font-size: 16px;
  line-height: 23px;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: ${p => getJustifyContent(p.align)};
  padding: 10px;
  box-sizing: border-box;
  white-space: pre-wrap;
  flex: ${p => !p.width && 1}; //widthが指定されない場合は均等になるようにする
  ${width}
`

/**
 * @deprecated Use chakra-ui Th instead.
 */
export const TableHeadCell = styled.div`
  font-weight: bold;
  ${cellStyle}
`

/**
 * @deprecated Use chakra-ui Td instead.
 */
export const TableBodyCell = styled.div`
  font-weight: normal;
  ${cellStyle}
  flex-wrap: wrap
`
