import React from 'react'

import { Text } from '@chakra-ui/react'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

export const CompleteDocuSignAction: React.FC = () => {
  return (
    <Wrapper>
      <div>
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          フォームの送信が完了しました
        </Text>
        <Spacer size={16} />
        <Text textAlign="center">ブラウザのタブを閉じてください</Text>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #f8fbfa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`
