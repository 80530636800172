import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { GqlError, SaveWorksheetDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { FieldValueInput, Worksheet, WorksheetDetail } from '../../types'
import { parseGqlWorksheetResponse, toGqlFieldValueInput } from '../parser'

const saveWorksheet = async ({
  worksheetUid,
  fieldValues,
}: {
  worksheetUid: Worksheet['uid']
  fieldValues: FieldValueInput[]
}): Promise<WorksheetDetail> => {
  try {
    const res = await graphqlRequest(SaveWorksheetDocument, {
      input: {
        worksheetUid,
        fieldValues: fieldValues.map(toGqlFieldValueInput),
      },
    })
    return parseGqlWorksheetResponse(res.saveWorksheet.worksheet)
  } catch (e) {
    throw e
  }
}

export const useSaveWorksheet = (
  options?: UseMutationOption<WorksheetDetail, GqlError>,
) => {
  return useMutation(
    ({
      worksheetUid,
      fieldValues,
    }: {
      worksheetUid: Worksheet['uid']
      fieldValues: FieldValueInput[]
    }) => saveWorksheet({ worksheetUid, fieldValues }),
    options,
  )
}
