import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { CreateSessionDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import { castUid } from 'src/utils/brandedUid'

import { ExplanationSession } from '../../types'

type CreateSessionParam =
  | {
      type: 'fromPreSession'
      explanationPreSessionUid: string
    }
  | {
      type: 'inPerson'
      explanationRevisionUid: string
    }

type CreateSessionReturn = {
  explanationSessionUid: string
}

export const createExplanationSession = async (
  param: CreateSessionParam,
): Promise<CreateSessionReturn> => {
  try {
    const res = await graphqlRequest(CreateSessionDocument, {
      input: {
        explanationPreSessionUid:
          param.type === 'fromPreSession'
            ? param.explanationPreSessionUid
            : undefined,
        explanationRevisionUid:
          param.type === 'inPerson' ? param.explanationRevisionUid : undefined,
      },
    })
    return {
      explanationSessionUid: castUid<ExplanationSession>(
        res.createExpSession.explanationSessionUid,
      ),
    }
  } catch (error) {
    throw error
  }
}

export const useCreateExplanationSession = (
  option?: UseMutationOption<CreateSessionReturn, GqlError>,
) => {
  return useMutation(
    (param: CreateSessionParam) => createExplanationSession(param),
    option,
  )
}
