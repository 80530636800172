import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  CreateAgreementFormEnvelopeDocument,
  CreateCheckUnderstandingEnvelopeDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { ExplanationDocRevision, ExplanationSession } from '../../types'

const createEnvelope = async ({
  type,
  explanationSessionUid,
  explanationDocRevisionUid,
}: {
  type: 'AgreementForm' | 'CheckUnderstanding'
  explanationSessionUid: ExplanationSession['uid']
  explanationDocRevisionUid: ExplanationDocRevision['uid']
}): Promise<{ dsEnvelopeId: string }> => {
  try {
    if (type === 'AgreementForm') {
      const res = await graphqlRequest(CreateAgreementFormEnvelopeDocument, {
        input: {
          explanationDocRevisionUid,
          explanationSessionUid,
        },
      })

      return {
        dsEnvelopeId: res.createAgreementFormEnvelope.dsEnvelopeId,
      }
    }
    const res = await graphqlRequest(CreateCheckUnderstandingEnvelopeDocument, {
      input: {
        explanationDocRevisionUid,
        explanationSessionUid,
      },
    })
    return {
      dsEnvelopeId: res.createCheckUnderstandingEnvelope.dsEnvelopeId,
    }
  } catch (error) {
    throw error
  }
}

export const useCreateEnvelope = (
  option?: UseMutationOption<{ dsEnvelopeId: string }, GqlError>,
) => {
  return useMutation(
    ({
      type,
      explanationSessionUid,
      explanationDocRevisionUid,
    }: {
      type: 'AgreementForm' | 'CheckUnderstanding'
      explanationSessionUid: ExplanationSession['uid']
      explanationDocRevisionUid: ExplanationDocRevision['uid']
    }) =>
      createEnvelope({
        type,
        explanationSessionUid,
        explanationDocRevisionUid,
      }),
    option,
  )
}
