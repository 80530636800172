import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Filter,
  Seek,
  Sort,
  SortAscend,
  SortDescend,
} from 'src/components/__legacy__icon/monochrome'
import { Select } from 'src/components/Select/Select'
import { usePermission } from 'src/features/auth/context'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PERMISSIONS } from 'src/lib/permission'

import { useExplanationTrialHospitals } from '../../api/getTrialHospitals'
import { useExplanationTrialMembers } from '../../api/getTrialMembers'
import { useExplanationsQueryParams } from '../../hooks/useExplanationsQueryParams'
import { useFilterExplanations } from '../../hooks/useFilterExplanations'
import { useSearchExplanations } from '../../hooks/useSearchExplanations'
import {
  ExplanationSortField,
  useSortExplanations,
} from '../../hooks/useSortExplanations'
import { ExplanationRevisionStatus } from '../../types'

const statusCheckboxItems: {
  label: string
  value: ExplanationRevisionStatus
}[] = [
  { label: '説明未実施', value: 'SessionNotStarted' },
  { label: '説明未完了', value: 'SessionNotDone' },
  { label: '同意未完了', value: 'AgreementNotDone' },
  { label: '同意完了', value: 'AgreementDone' },
  { label: '同意拒否', value: 'AgreementRejected' },
  { label: '同意撤回', value: 'Withdrawn' },
  { label: '無効', value: 'Disabled' },
]

type Props = {
  isOpen: boolean
  onClose: () => void
}
export const ExplanationFilterDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const { resetQuery, queryInputRef } = useExplanationsQueryParams()

  return (
    <Drawer size="md" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader px="10" py="8">
          <Flex justify="space-between">
            <Text>検索/絞り込み</Text>
            <Button variant="text" onClick={resetQuery}>
              検索条件クリア
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody px="10" pb="8" pt="0">
          <VStack alignItems="start" spacing="2rem">
            <SearchQuerySection queryInputRef={queryInputRef} />
            <FilterSection />
            <SortSection />
          </VStack>
        </DrawerBody>
        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  )
}

const SearchQuerySection: React.FC<{
  queryInputRef: React.RefObject<HTMLInputElement>
}> = ({ queryInputRef }) => {
  const { searchQuery, handleChangeSearchQuery } = useSearchExplanations()

  return (
    <Box w="full">
      <Flex alignItems="center">
        <Seek />
        <Box width="0.5rem" />
        <Text fontWeight="bold" fontSize="1rem">
          検索
        </Text>
      </Flex>
      <Input
        mt="2"
        placeholder="候補ID・症例番号"
        bgColor="white"
        padding="0.5rem"
        defaultValue={searchQuery}
        onChange={handleChangeSearchQuery}
        ref={queryInputRef}
      />
    </Box>
  )
}

const FilterSection = () => {
  const {
    isCheckAllStatus,
    statuses,
    handleCheckStatus,
    reAgreement,
    handleCheckReAgreement,
    hospitalUid,
    handleChangeHospital,
    doctorUid,
    handleChangeDoctor,
    crcUid,
    handleChangeCrc,
  } = useFilterExplanations()

  const { hasPermission } = usePermission()

  const { data: doctorMembers } = useExplanationTrialMembers({ role: 'Dr' })
  const { data: crcMembers } = useExplanationTrialMembers({ role: 'CRC' })
  const { data: hospitals } = useExplanationTrialHospitals({})

  return (
    <Box>
      <Flex alignItems="center">
        <Filter />
        <Box width="0.5rem" />
        <Text fontWeight="bold" fontSize="1rem">
          絞り込み
        </Text>
      </Flex>
      <VStack p="0.5rem 1.5rem" alignItems="start" spacing="2rem">
        <Box>
          <Text fontSize="1rem">ステータス</Text>
          <Box height="0.5rem" />
          <Flex>
            <Checkbox
              bgColor="white"
              onChange={() => handleCheckStatus('all')}
              isChecked={isCheckAllStatus}
            >
              すべて
            </Checkbox>
          </Flex>
          <Grid
            p="1rem 2rem"
            rowGap="1rem"
            columnGap="2.5rem"
            templateRows="repeat(4, 1fr)"
            templateColumns="repeat(2, 1fr)"
          >
            {statusCheckboxItems.map(({ label, value }) => (
              <GridItem key={value}>
                <Flex>
                  <Checkbox
                    bgColor="white"
                    onChange={() => handleCheckStatus(value)}
                    isChecked={statuses.includes(value)}
                  >
                    {label}
                  </Checkbox>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Box>
        <Box>
          <Text fontSize="1rem">再同意</Text>
          <Box height="0.5rem" />
          <Grid
            px="2rem"
            rowGap="1rem"
            columnGap="2.5rem"
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(2, 1fr)"
          >
            <GridItem pr="2.5rem">
              <Flex>
                <Checkbox
                  bgColor="white"
                  isChecked={reAgreement.includes(true)}
                  onChange={_ => handleCheckReAgreement(true)}
                >
                  必要
                </Checkbox>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex>
                <Checkbox
                  bgColor="white"
                  isChecked={reAgreement.includes(false)}
                  onChange={_ => handleCheckReAgreement(false)}
                >
                  不要
                </Checkbox>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
        {hasPermission(PERMISSIONS.Hospital_BelongAll) && (
          <Box w="full">
            <Text fontSize="1rem">医療機関</Text>
            <Box height="0.5rem" />
            <Select
              isClearable
              items={(hospitals ?? []).map(h => ({
                label: h.name,
                value: h.uid,
              }))}
              value={hospitalUid}
              onChange={handleChangeHospital}
            />
          </Box>
        )}
        <Box w="full">
          <Text fontSize="1rem">担当Dr</Text>
          <Box height="0.5rem" />
          <Select
            isClearable
            width="100%"
            items={(doctorMembers ?? []).map(m => ({
              label: `${m.lastName} ${m.firstName}`,
              value: m.uid,
            }))}
            value={doctorUid}
            onChange={handleChangeDoctor}
          />
        </Box>
        <Box w="full">
          <Text fontSize="1rem">担当CRC</Text>
          <Box height="0.5rem" />
          <Select
            isClearable
            width="100%"
            items={(crcMembers ?? []).map(m => ({
              label: `${m.lastName} ${m.firstName}`,
              value: m.uid,
            }))}
            value={crcUid}
            onChange={handleChangeCrc}
          />
        </Box>
      </VStack>
    </Box>
  )
}

const explanationSortFields: { field: string; value: ExplanationSortField }[] =
  [
    { field: '候補ID', value: 'candidateId' },
    { field: '症例番号', value: 'diseaseId' },
    { field: '同意完了日', value: 'agreementCompletedAt' },
    { field: '交付日', value: 'deliveredAt' },
  ]

const SortSection = () => {
  const { field, order, handleChangeSortField, handleChangeSortType } =
    useSortExplanations()
  return (
    <Box width="100%">
      <Flex alignItems="center">
        <Sort />
        <Box width="0.5rem" />
        <Text fontWeight="bold" fontSize="1rem">
          並べ替え
        </Text>
      </Flex>
      <Box height="0.5rem" />
      <Flex justifyContent="space-between" alignItems="center">
        <Select
          width="100%"
          isClearable
          items={explanationSortFields.map(field => ({
            label: field.field,
            value: field.value,
          }))}
          value={field}
          onChange={handleChangeSortField}
        />
        <RadioGroup
          color={colors.blue[600]}
          onChange={val => handleChangeSortType(val as 'desc' | 'asc')}
        >
          <Box display="flex" alignItems="center">
            <Radio
              paddingRight="0.5rem"
              value="asc"
              bg="transparent"
              borderColor="transparent"
              _hover={{ bg: 'transparent', borderColor: 'transparent' }}
              _checked={{ bg: 'transparent', borderColor: 'transparent' }}
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing="0.25rem">
                <Box
                  padding="0.25rem"
                  rounded="sm"
                  boxShadow={
                    order === 'asc'
                      ? 'inset 5px 5px 5px rgba(0, 0, 0, 0.3)'
                      : undefined
                  }
                >
                  <SortAscend />
                </Box>
                <Text>昇順</Text>
              </HStack>
            </Radio>
            <Radio
              paddingRight="0.5rem"
              value="desc"
              borderColor="transparent"
              _hover={{ bg: 'transparent', borderColor: 'transparent' }}
              _checked={{ bg: 'transparent', borderColor: 'transparent' }}
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing="0.25rem">
                <Box
                  padding="0.25rem"
                  rounded="sm"
                  boxShadow={
                    order === 'desc'
                      ? 'inset 5px 5px 5px rgba(0, 0, 0, 0.3)'
                      : undefined
                  }
                >
                  <SortDescend />
                </Box>
                <Text>降順</Text>
              </HStack>
            </Radio>
          </Box>
        </RadioGroup>
      </Flex>
    </Box>
  )
}
