import React from 'react'

import { Box } from '@chakra-ui/react'
import { Route, Routes, useParams } from 'react-router-dom'

import { useFetch } from './use-fetch'
import { getRelativePath } from '../../../../../../../constants/paths'
import { trialIdParamName } from '../../../trial-detail'
import { TemplateDetail } from '../detail/template-detail'

type Props = {}

export const List: React.FC<Props> = props => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()
  const { fetched } = useFetch({ trialUid })

  if (!fetched) {
    return null
  }

  return (
    <Box px="4" py="5">
      <Routes>
        <Route
          path={`${getRelativePath('Templates', 'Template')}`}
          element={<TemplateDetail />}
        />
      </Routes>
    </Box>
  )
}
