import { Questionnaire, QuestionnaireStatus } from './entity'
import { createFixture as createFieldFixture } from '../questionnaire-field/fixture'
import { createFixture as createSectionFixture } from '../questionnaire-section/fixture'

export const fixture: Questionnaire = {
  uid: 'dummy-1',
  title: '質問票１',
  displayName: '体調に関するアンケート',
  status: QuestionnaireStatus.Draft,
  registeredAt: '2021/11/16',
  releasedAt: '2021/11/16',
  sections: [],
  scheduleUids: [],
}

export const fixtures: Questionnaire[] = [
  {
    uid: 'dummy-1',
    title: '質問票１',
    displayName: '体調に関するアンケート その１',
    status: QuestionnaireStatus.Draft,
    registeredAt: '2021/11/16',
    releasedAt: '2021/11/16',
    sections: [],
    scheduleUids: [],
  },
  {
    uid: 'dummy-2',
    title: '質問票２',
    displayName: '体調に関するアンケート その２',
    status: QuestionnaireStatus.Released,
    registeredAt: '2021/11/15',
    releasedAt: '2021/11/16',
    sections: [
      createSectionFixture({
        uid: 'QuestionnaireSectionUid-2',
        fieldMap: {
          a: createFieldFixture({
            uid: 'QuestionnaireFieldUid-2',
          }),
        },
      }),
    ],
    scheduleUids: [],
  },
  {
    uid: 'dummy-3',
    title: '質問票３',
    displayName: '体調に関するアンケート その３',
    status: QuestionnaireStatus.Draft,
    registeredAt: '2021/11/15',
    releasedAt: '2021/11/16',
    sections: [
      createSectionFixture({
        uid: 'QuestionnaireSectionUid-3a',
        fieldMap: {
          a: createFieldFixture({
            uid: 'QuestionnaireFieldUid-3a-1',
          }),
          b: createFieldFixture({
            uid: 'QuestionnaireFieldUid-3a-2',
          }),
        },
      }),
      createSectionFixture({
        uid: 'QuestionnaireSectionUid-3b',
        fieldMap: {
          a: createFieldFixture({
            uid: 'QuestionnaireFieldUid-3b-1',
          }),
          b: createFieldFixture({
            uid: 'QuestionnaireFieldUid-3b-2',
          }),
        },
      }),
    ],
    scheduleUids: [],
  },
]
