import { assertNever } from 'src/utils/assertNever'

import { WorksheetAction } from '../types'

export const worksheetActionToText = (action: WorksheetAction): string => {
  switch (action) {
    case 'create':
      return '新規追加'
    case 'save':
      return '一時保存'
    case 'disable':
      return '無効化'
    case 'enable':
      return '有効化'
    case 'confirm':
      return '確定'
    case 'reEdit':
      return '再編集'
    default:
      return assertNever(action)
  }
}
