import { useEffect, useMemo, useState } from 'react'

import {
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  generatePath,
  Link as ReactRouterLink,
  useParams,
} from 'react-router-dom'
import { PagePrevious } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { useCurrentMember, useSelectedTrial } from 'src/features/auth/context'
import { hasAlreadyPinSet } from 'src/features/explanation/utils/hasAlreadyPinSet'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { isNotNullish } from 'src/utils/isNotNullish'
import { byIndexAsc } from 'src/utils/sortBy'

import { ExplanationSignerRole } from '../../../../lib/gql-client'
import { useSessionInSignFlow } from '../../api'
import {
  RequestSign,
  ExplanationRoomEventProvider,
  useActiveDevice,
  useRequestSign,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForMember } from '../../hooks/useFloatingVideoCallForMember'
import { useThisSession } from '../../hooks/useThisSession'
import { isDocRevCompleted } from '../../utils/explanationDocRevisionUtils'
import { CompleteAgreementBanner } from '../CompleteAgreementBanner/CompleteAgreementBanner'
import { DeliveryBanner } from '../DeliveryBanner/DeliveryBanner'
import { ExplanationRoomProgressBar } from '../ExplanationRoomProgressBar/ExplanationRoomProgressBar'
import { HospitalLayout } from '../HospitalLayout/HospitalLayout'
import { HospitalMenu } from '../HospitalMenu/HospitalMenu'
import { ImplementSignBanner } from '../ImplementSignBanner/ImplementSignBanner'
import { RejectAgreementForRoom } from '../RejectAgreementForRoom/RejectAgreementForRoom'
import { SignerSetupBanner } from '../SignerSetupBanner/SignerSetupBanner'
import { SignStatus } from '../SignStatus/SignStatus'
import { TopScreenContainer } from '../TopScreenContainer/TopScreenContainer'
import { TopScreenSkelton } from '../TopScreenSkelton/TopScreenSkelton'

type Param = {
  sessionUid: string
}

export const SignFlowScreen: React.FC = () => {
  const { sessionUid = '' } = useParams<Param>()
  const { selectedTrial } = useSelectedTrial()
  const { currentMember, selectedTrialHospitalUid } = useCurrentMember()

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  if (!selectedTrialHospitalUid || !thisSession.fetched) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={currentMember.uid}
      trialUid={selectedTrial.uid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      sessionUid={sessionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      deviceActorType="Member"
      deviceActorTrialHospitalUid={selectedTrialHospitalUid}
    >
      <HospitalLayout headerTitle="説明ルーム" sessionUid={sessionUid}>
        <Content sessionUid={sessionUid} />
      </HospitalLayout>
    </ExplanationRoomEventProvider>
  )
}

export type DocRevSignStatus =
  | 'NotSigned'
  | 'SigningOnScreen'
  | 'SigningSendLink'
  | 'Signed'
  | 'Rejected'

type ValidSignerRoles = Extract<ExplanationSignerRole, 'Dr' | 'CRC' | 'Patient'>

export type DocRevAllSignStatuses = Record<ValidSignerRoles, DocRevSignStatus>

const Content: React.FC<{ sessionUid: string }> = ({ sessionUid }) => {
  const [shouldShowNoticeIfPinSet, setShouldShowNoticeIfPinSet] =
    useState(false)
  const { requestSignList, setRequestSignList } = useRequestSign()

  const { activeDevices } = useActiveDevice()

  const toast = useMirohaToast()

  const thisSession = useThisSession({ sessionUid, forPatient: false })

  const { data: session, mutate: mutateSession } = useSessionInSignFlow({
    explanationSessionUid: sessionUid,
    participantTrialMemberInputs: activeDevices.map(device => ({
      trialMemberUid: device.actorUid,
      trialHospitalUid: device.actorTrialHospitalUid,
    })),
    onSuccess: data => {
      const pinSettingStatus =
        data.explanationRevision.latestPinSettingHistory?.status
      const hasPinSet = !!pinSettingStatus && hasAlreadyPinSet(pinSettingStatus)
      if (!hasPinSet) {
        setShouldShowNoticeIfPinSet(true)
      }
      // 未設定から初回設定がされた場合にtoastを表示
      if (pinSettingStatus === 'Completed' && shouldShowNoticeIfPinSet) {
        toast({
          status: 'success',
          title: '当人認証設定が完了しました',
        })
        setShouldShowNoticeIfPinSet(false)
      }

      if (requestSignList.length === 0) {
        return
      }
      // 署名済みの署名依頼のステータスをCompletedにする
      const newRequestSignList: RequestSign[] = requestSignList.map(
        requestSign => {
          const isSigned =
            requestSign.status === 'CompletedOnThisDevice' ||
            data.explanationRevision.docSets.some(
              docSet =>
                docSet.documentRevisions?.some(
                  docRev =>
                    docRev.uid === requestSign.docRevUid &&
                    docRev.__typename ===
                      'ExplanationDocRevisionAgreementForm' &&
                    docRev.signHistories.some(
                      signHistory =>
                        signHistory.actorUid === requestSign.signerActorUid,
                    ),
                ) ?? false,
            )
          return {
            ...requestSign,
            status: isSigned ? 'CompletedOnThisDevice' : requestSign.status,
          }
        },
      )
      if (
        newRequestSignList.every(rs => rs.status === 'CompletedOnThisDevice')
      ) {
        setRequestSignList([])
        return
      }
      setRequestSignList(newRequestSignList)
    },
  })

  useEffect(() => {
    // アクティブな端末が更新されたらSessionを再取得する
    mutateSession()
  }, [activeDevices.length, mutateSession])

  // DocRevisionごとのDr,CRC,Patientの署名状況を保持するMap
  // sessionの署名状態と、pendingRequestSignListの署名依頼状態から算出する
  const docRevSignStatusesMap = useMemo<
    Map<string, DocRevAllSignStatuses>
  >(() => {
    const res: Map<string, DocRevAllSignStatuses> = new Map()
    if (!session) {
      return res
    }
    const patientActorUid = session.explanationRevision.explanation.patient.uid
    for (const docSet of session.explanationRevision.docSets) {
      if (!docSet.documentRevisions) {
        continue
      }
      for (const docRev of docSet.documentRevisions) {
        if (docRev.__typename !== 'ExplanationDocRevisionAgreementForm') {
          continue
        }
        let status: DocRevAllSignStatuses = {
          Dr: 'NotSigned',
          CRC: 'NotSigned',
          Patient: 'NotSigned',
        }
        const drActorUid = docRev.signerMembers.find(
          sm => sm.trialMember.role === 'Dr',
        )?.trialMemberUid
        const crcActorUid = docRev.signerMembers.find(
          sm => sm.trialMember.role === 'CRC',
        )?.trialMemberUid
        const docRevWaitingRequestSignList = requestSignList.filter(
          rs =>
            rs.sessionUid === sessionUid &&
            rs.docRevUid === docRev.uid &&
            (rs.status === 'Waiting' || rs.status === 'PendingOnThisDevice'),
        )
        const drWaiting = docRevWaitingRequestSignList.find(
          rs => rs.signerActorUid === drActorUid,
        )
        if (drWaiting) {
          status = {
            ...status,
            Dr:
              drWaiting.signType === 'OnScreen'
                ? 'SigningOnScreen'
                : 'SigningSendLink',
          }
        }
        const crcWaiting = docRevWaitingRequestSignList.find(
          rs => rs.signerActorUid === crcActorUid,
        )
        if (crcWaiting) {
          status = {
            ...status,
            CRC:
              crcWaiting.signType === 'OnScreen'
                ? 'SigningOnScreen'
                : 'SigningSendLink',
          }
        }
        const patientWaiting = docRevWaitingRequestSignList.find(
          rs => rs.signerActorUid === patientActorUid,
        )
        if (patientWaiting) {
          status = {
            ...status,
            Patient:
              patientWaiting.signType === 'OnScreen'
                ? 'SigningOnScreen'
                : 'SigningSendLink',
          }
        }
        for (const signHistory of docRev.signHistories) {
          switch (signHistory.signerRole) {
            case 'Dr':
              status = {
                ...status,
                Dr: signHistory.isRejected ? 'Rejected' : 'Signed',
              }
              break
            case 'CRC':
              status = {
                ...status,
                CRC: signHistory.isRejected ? 'Rejected' : 'Signed',
              }
              break
            case 'Patient':
              status = {
                ...status,
                Patient: signHistory.isRejected ? 'Rejected' : 'Signed',
              }
              break
          }
        }
        res.set(docRev.uid, status)
      }
    }
    return res
  }, [requestSignList, session, sessionUid])

  useUpdateRevisionStatus({
    listener: async () => {
      await mutateSession()
    },
  })

  const { selectedTrial } = useSelectedTrial()

  const { currentMember } = useCurrentMember()

  const { renderFloatingVideoCall } = useFloatingVideoCallForMember({
    sessionUid,
  })

  if (!session || !thisSession.fetched) {
    return <TopScreenSkelton />
  }

  const status = session.explanationRevision.latestHistory.statusV2

  const docRevisions = session.explanationRevision.docSets
    .sort(byIndexAsc)
    .flatMap(docSet => docSet.documentRevisions?.sort(byIndexAsc))
    .filter(isNotNullish)

  // docRevUidからCRCフィールドの有無を判定するためのMap
  const hasCrcFieldMap = new Map(
    docRevisions.map(docRev => {
      const hasCrcField =
        docRev.__typename === 'ExplanationDocRevisionAgreementForm'
          ? docRev.icfDocumentRevision.__typename ===
            'IcfDocumentRevisionAgreementForm'
            ? docRev.icfDocumentRevision.hasCrcField
            : false
          : false
      return [docRev.uid, hasCrcField]
    }),
  )

  const hasCrcField = Array.from(hasCrcFieldMap.values()).some(Boolean)

  const agreementForms = docRevisions.flatMap(docRev => {
    return docRev.__typename === 'ExplanationDocRevisionAgreementForm'
      ? docRev
      : []
  })

  const isSignerMemberSet = agreementForms.every(
    af => af.signerMembers.length > 0,
  )

  // 旧仕様の名残でそれぞれの文書に一括で署名者を設定している
  // CRCの設定がある場合はCRCの設定がある文書を、それ以外は最初の文書を参照する6
  const signerMemberTargetIndex = Math.max(
    agreementForms.findIndex(af => {
      if (hasCrcField) {
        return af.signerMembers.some(sm => sm.trialMember.role === 'CRC')
      }
      return true
    }),
    0,
  )

  const signerMembers =
    agreementForms.length > 0 && isSignerMemberSet
      ? agreementForms[signerMemberTargetIndex].signerMembers
      : undefined

  const drSignerTrialMember = session.members.find(sm => {
    const signer = signerMembers?.find(
      signer => signer.trialMember.role === 'Dr',
    )
    return signer?.trialMemberUid === sm.trialMember.uid
  })

  const crcSignerTrialMember = session.members.find(sm => {
    const signer = signerMembers?.find(
      signer => signer.trialMember.role === 'CRC',
    )
    return signer?.trialMemberUid === sm.trialMember.uid
  })

  const notCompletedDocRevisions = docRevisions.filter(
    docRev => !isDocRevCompleted(docRev),
  )

  const hasAgreementForms = session.explanationRevision.docSets
    .flatMap(docSet => docSet.documentRevisions)
    .some(
      docRev => docRev?.__typename === 'ExplanationDocRevisionAgreementForm',
    )

  const pinSettingStatus =
    session.explanationRevision.latestPinSettingHistory?.status

  const hasPinSet = !!pinSettingStatus && hasAlreadyPinSet(pinSettingStatus)

  return (
    <TopScreenContainer>
      {/* リンクとProgressBarの見た目が崩れないよう、gridを使って画面幅に応じた細かい調整を行っている */}
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          xl: 'repeat(3, 1fr)',
        }}
        gap={{ base: 8, lg: 0 }}
      >
        <GridItem display="flex" alignItems="center">
          <HStack color="green.600">
            <PagePrevious />
            <Link
              fontSize="sm"
              fontWeight="medium"
              as={ReactRouterLink}
              to={generatePath(Paths.HospitalExplanationRoomTop, {
                trialUid: selectedTrial.uid,
                sessionUid,
              })}
            >
              説明実施フローへ戻る
            </Link>
          </HStack>
        </GridItem>
        <GridItem>
          <Center>
            <ExplanationRoomProgressBar
              status={status}
              isSignerSet={isSignerMemberSet}
            />
          </Center>
        </GridItem>
        <GridItem textAlign="right">
          <HospitalMenu
            explanationRevisionUid={session.explanationRevision.uid}
            explanationSessionUid={sessionUid}
            mutateSession={mutateSession}
          />
        </GridItem>
      </Grid>

      <Flex justify="end" mt={{ base: '12', xl: '2' }}>
        <RejectAgreementForRoom
          docRevisions={agreementForms}
          sessionUid={session.uid}
          status={status}
          mutateSession={mutateSession}
        />
      </Flex>

      <Stack mt="2">
        <Text as="h2" fontSize="xl" fontWeight="bold">
          署名実施フロー
        </Text>
        <Flex direction="column" gap={4}>
          <SignerSetupBanner
            hasAgreementForms={hasAgreementForms}
            explanationRevisionStatus={status}
            sessionMembers={session.members}
            agreementFormDocRevUids={agreementForms.map(af => af.uid)}
            patientUid={session.explanationRevision.explanation.patient.uid}
            candidateId={
              session.explanationRevision.explanation.patient.candidateId
            }
            fetchedPatientMobileNumber={
              session.explanationRevision.explanation.patient.mobileNumber ??
              undefined
            }
            hasCrcFieldMap={hasCrcFieldMap}
            drSignerTrialMemberUid={drSignerTrialMember?.trialMember.uid}
            crcSignerTrialMemberUid={crcSignerTrialMember?.trialMember.uid}
            mutateSession={mutateSession}
          />
          <Stack spacing="1">
            <ImplementSignBanner
              // HACK: デフォルトで選択される署名者が更新されるよう、署名履歴の更新(historyの数が変わる)があった場合に意図的に再レンダリングを引き起こす
              key={agreementForms.flatMap(af => af.signHistories).length}
              hasPinSet={hasPinSet}
              sessionUid={sessionUid}
              phoneNumber={
                session.explanationRevision.explanation.patient.mobileNumber ??
                undefined
              }
              partnerTrialHospitalUid={thisSession.partnerTrialHospital?.uid}
              hasAgreementForms={hasAgreementForms}
              explanationType={session.explanationRevision.explanationType}
              explanationRevisionUid={session.explanationRevision.uid}
              explanationRevisionStatus={status}
              agreementForms={agreementForms}
              isSignerSet={isSignerMemberSet}
              sessionMembers={session.members}
              drSignerTrialMemberUid={drSignerTrialMember?.trialMember.uid}
              crcSignerTrialMemberUid={crcSignerTrialMember?.trialMember.uid}
              patientUid={session.explanationRevision.explanation.patient.uid}
              candidateId={
                session.explanationRevision.explanation.patient.candidateId
              }
              ownDeviceActorUid={currentMember.uid}
              notCompletedDocRevisions={notCompletedDocRevisions}
              mutateSession={mutateSession}
            />
            <SignStatus
              revStatus={status}
              isSignerMemberSet={isSignerMemberSet}
              agreementForms={agreementForms}
              hasCrcField={hasCrcField}
              docRevSignStatusesMap={docRevSignStatusesMap}
            />
          </Stack>

          <CompleteAgreementBanner
            notCompletedDocRevisions={notCompletedDocRevisions}
            explanationRevisionUid={session.explanationRevision.uid}
            explanationRevisionStatus={status}
            hasRequiredAgreementForm={
              agreementForms.filter(af => af.isPatientConsentRequired).length >
              0
            }
            isRejected={status === 'AgreementRejected'}
            mutateSession={mutateSession}
          />
          <DeliveryBanner
            noDocumentToDeliver={
              notCompletedDocRevisions.length === docRevisions.length
            }
            explanationRevisionStatus={status}
            explanationRevision={session.explanationRevision}
            mutationSession={() => {
              mutateSession()
            }}
          />
        </Flex>
      </Stack>
      {renderFloatingVideoCall()}
    </TopScreenContainer>
  )
}
