import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  GqlError,
  ResendPatientVideoCallNotificationDocument,
  ResendPatientVideoCallNotificationInput,
  ResendPatientVideoCallNotificationMutation,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

type ResendPatientVideoCallNotificationReturn =
  ResendPatientVideoCallNotificationMutation['resendPatientVideoCallNotification']

export const resendPatientVideoCallNotification = async (
  input: ResendPatientVideoCallNotificationInput,
): Promise<ResendPatientVideoCallNotificationReturn> => {
  try {
    const res = await graphqlRequest(
      ResendPatientVideoCallNotificationDocument,
      {
        input,
      },
    )
    return res.resendPatientVideoCallNotification
  } catch (error) {
    throw error
  }
}

export const useResendPatientVideoCallNotification = (
  option?: UseMutationOption<
    ResendPatientVideoCallNotificationReturn,
    GqlError
  >,
) => {
  return useMutation(
    ({ patientVideoCallUid }: { patientVideoCallUid: string }) =>
      resendPatientVideoCallNotification({ patientVideoCallUid }),
    option,
  )
}
