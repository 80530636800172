import React from 'react'

import { useParams, Navigate, Route, Routes } from 'react-router-dom'
import { white } from 'src/components/base/color/palette'
import { useSelectedTrial } from 'src/features/auth/context'
import { WSDataExportV1Screen } from 'src/features/wsdataexport/components'
import { WSDataExportScreen } from 'src/features/wsdataexport/components/WSDataExportScreen/WSDataExportScreen'
import styled from 'styled-components'

import { Crf } from './crf/crf'
import { Member } from './member/member'
import { routes } from './routes'
import { Trial } from './trial/trial'
import { useInit } from './use-init'
import { trialIdParamName } from '../trial-detail'

const Content = styled.div`
  height: 100%;
  padding: 16px 36px;
  background-color: ${white};
  overflow-x: auto;
`

type NavItem = {
  route: string
  component: React.ReactNode
}

const navItems = (csvExportV2Enabled: boolean): NavItem[] => [
  { route: routes.trial, component: <Trial /> },
  {
    route: routes.member,
    component: <Member />,
  },
  {
    route: `${routes.crf}/*`,
    component: <Crf />,
  },
  {
    route: routes.wsDataExport,
    component: csvExportV2Enabled ? (
      <WSDataExportScreen />
    ) : (
      <WSDataExportV1Screen />
    ),
  },
]

export const Settings: React.FC = () => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()
  const { initialized } = useInit(trialUid)

  const { selectedTrial } = useSelectedTrial()

  if (!initialized) {
    return null
  }

  const csvExportV2Enabled = selectedTrial.featureFlags.eSourceV2

  return (
    <Content>
      <Routes>
        {navItems(csvExportV2Enabled).map(item => (
          <Route
            key={`${item.route}`}
            path={`${item.route}`}
            element={item.component}
          />
        ))}
        <Route path="*" element={<Navigate replace to={`${routes.trial}`} />} />
      </Routes>
    </Content>
  )
}
