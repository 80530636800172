import { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { actions as patientActions } from 'src/modules/entities/patient/redux'

export const useFetch = ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}) => {
  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetch = async () => {
      await dispatch(patientActions.fetch({ trialUid, patientUid }))

      setFetched(true)
    }

    fetch()
  }, [dispatch, patientUid, trialUid])

  return {
    fetched,
  }
}
