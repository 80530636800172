import {
  Button,
  Grid,
  GridItem,
  GridItemProps,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { generatePath, useParams } from 'react-router-dom'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import {
  StaticScreen,
  StaticScreenContent,
  StaticScreenFooter,
  StaticScreenTitle,
} from 'src/components/StaticScreen/StaticScreen'
import { Paths } from 'src/constants/paths'
import { Trial } from 'src/features/trial/types'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { UID } from '../../../../utils/brandedUid'
import { useExplanationRevisionAbout } from '../../api'
import { useSignInPatient } from '../../api/signInPatient'
import { ExplanationRevision } from '../../types'

type Param = {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
}

export const ExplanationRoomPatientSignInScreen: React.FC = () => {
  const {
    trialUid = '' as UID<'Trial'>,
    explanationRevisionUid = '' as UID<'ExplanationRevision'>,
  } = useParams<Param>()

  const toast = useMirohaToast()

  const { request: signIn } = useSignInPatient({
    onSuccess: () => {
      window.location.pathname = generatePath(
        Paths.PatientExplanationRoomLobby,
        {
          trialUid,
          explanationRevisionUid,
        },
      )
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const { data: revisionAbout } = useExplanationRevisionAbout({
    trialUid,
    explanationRevisionUid,
  })

  if (!revisionAbout) {
    return null
  }

  const headStyle: GridItemProps = {
    fontSize: 'sm',
    color: 'gray.600',
    fontWeight: 'bold',
  }

  return (
    <StaticScreen colorScheme="green">
      <StaticScreenTitle>{revisionAbout.trialName}</StaticScreenTitle>
      <StaticScreenContent>
        <Stack spacing="8">
          <Grid
            gridTemplateColumns={{
              md: '120px minmax(0, 1fr)',
              base: '80px minmax(0, 1fr)',
              lg: '160px minmax(0, 1fr)',
            }}
            textAlign="left"
            alignItems="center"
            rowGap={4}
          >
            <GridItem {...headStyle}>医療機関</GridItem>
            <GridItem>{revisionAbout.hospitalName}</GridItem>

            <GridItem {...headStyle}>担当者</GridItem>
            <GridItem>
              <Stack>
                {revisionAbout.members.map(m => (
                  <MemberLabel
                    key={m.memberIndex}
                    role={m.roleName as 'Dr' | 'CRC'}
                    displayName={getFullName(m)}
                  />
                ))}
              </Stack>
            </GridItem>

            <GridItem {...headStyle}>候補ID</GridItem>
            <GridItem>
              <Wrap>
                <WrapItem>
                  <Text display="inline">{revisionAbout.candidateID}</Text>
                </WrapItem>
                <WrapItem>
                  <Text display="inline">
                    {revisionAbout.revisionCountName}
                  </Text>
                </WrapItem>
              </Wrap>
            </GridItem>

            <GridItem {...headStyle}>予約日時</GridItem>
            <GridItem>
              {formatDate(revisionAbout.scheduledAt, 'YYYY/MM/DD (ddd) HH:mm')}
            </GridItem>
          </Grid>

          <Stack>
            <Text fontSize={{ base: 'xs', md: 'sm' }}>
              「説明ロビーへ」ボタンより説明ロビーへお入りください。
            </Text>
            <Text fontSize={{ base: 'xs', md: 'sm' }}>
              医療スタッフが参りますので、ビデオ通話の準備をしてお待ちください。
            </Text>
          </Stack>
        </Stack>
      </StaticScreenContent>
      <StaticScreenFooter>
        <Button
          colorScheme="green"
          onClick={async () => {
            await signIn({
              trialUid,
              explanationRevisionUid,
            })
          }}
        >
          説明ロビーへ
        </Button>
      </StaticScreenFooter>
    </StaticScreen>
  )
}
