import { Patient, Gender, PatientEProStatus } from './entity'

export const fixture: Patient = {
  uid: 'fooPatientUid',
  diseaseUid: 'foo',
  hospitalUid: 'foo',
  hospitalName: 'foo病院',
  firstName: '希',
  firstNameJa: 'のぞみ',
  lastName: '江原',
  lastNameJa: 'えはら',
  gender: Gender.Female,
  birthday: '1991-11-11',
  phoneNumber: '08011223344',
  email: 'miroha@miroha.co',
  chartUid: '000000',
  status: 1,
  eproStatus: PatientEProStatus.BeforeTrial,
  isPasswordIssued: false,
  ratio: '3/5',
  firstVisitDate: '2022-03-03',
  answerStartDate: '2022-03-13',
  createdAt: '2019-01-01T00:00:00',
  updatedAt: '2019-01-01T00:00:00',
}

export const createFixture = (param: Partial<Patient>): Patient => {
  const patient = { ...fixture }

  Object.keys(param).forEach(key => {
    const k = key as keyof Patient
    ;(patient[k] as any) = param[k]
  })

  return patient
}
