export type NewFileItem = {
  isNew: true
  fileName: string
  fileUrl: string
  fileSize: number
  file: File
}
export type SavedFileItem = {
  uid: string
  isNew: false
  fileName: string
  fileUrl: string
  fileSize: number
  fileExtension: string
  savedAt: string
}

export type FileItem = NewFileItem | SavedFileItem

const MAX_IMAGE_FILE_SIZE = 10 * 1024 * 1024 // 10MB
const MAX_PDF_FILE_SIZE = 15 * 1024 * 1024 // 15MB
const MAX_FILE_COUNT = 15
const MAX_AMOUNT_FILE_SIZE = 200 * 1024 * 1024 // 200MB

export type FileType = 'Image' | 'PDF' | 'Other'

export const validateFileItems = (
  files: FileItem[],
): { isValid: boolean; errorMessage?: string } => {
  if (files.some(file => getFileType(file) === 'Other')) {
    return {
      isValid: false,
      errorMessage:
        'PNG・JPEG・PDF形式以外のファイルはアップロードできません。',
    }
  }
  if (files.some(file => !checkFileNameCharacters(file.fileName))) {
    return {
      isValid: false,
      errorMessage: 'ファイル名に¥/:*?"<>|の文字は使用できません。',
    }
  }
  if (files.some(file => !checkFileNameLength(file.fileName))) {
    return {
      isValid: false,
      errorMessage: 'ファイル名は拡張子を除き100文字以内にしてください。',
    }
  }
  if (files.some(file => !checkFileSize(file))) {
    return {
      isValid: false,
      errorMessage:
        'アップロードの上限サイズ(画像:10MB、PDF:15MB)を超えているファイルがあります。',
    }
  }
  if (files.length > MAX_FILE_COUNT) {
    return {
      isValid: false,
      errorMessage: 'アップロード可能なファイル数（15個）を超えています。',
    }
  }
  const totalSize = files.reduce((acc, file) => acc + file.fileSize, 0)
  if (totalSize > MAX_AMOUNT_FILE_SIZE) {
    return {
      isValid: false,
      errorMessage:
        'ファイルの合計サイズは200MB以下でアップロードしてください。',
    }
  }

  return {
    isValid: true,
  }
}

export const checkFileSize = (file: FileItem): boolean => {
  if (getFileType(file) === 'Image') {
    return file.fileSize <= MAX_IMAGE_FILE_SIZE
  } else {
    return file.fileSize <= MAX_PDF_FILE_SIZE
  }
}

export const checkFileNameCharacters = (fileName: string): boolean => {
  // :, \, /, *, ?, ", <, >, | はファイル名に使えない
  return !/[\\/:*?"<>|]/.test(fileName)
}

export const checkFileNameLength = (fileName: string): boolean => {
  return fileName.split('.').slice(0, -1).join('.').length <= 100
}

export const getFileType = (file: FileItem): FileType => {
  const extension = file.fileName.split('.').pop()
  if (!extension) return 'Other'
  const extensionLowerCase = extension.toLowerCase()
  if (
    extensionLowerCase === 'jpeg' ||
    extensionLowerCase === 'jpg' ||
    extensionLowerCase === 'png'
  ) {
    return 'Image'
  } else if (extensionLowerCase === 'pdf') {
    return 'PDF'
  } else {
    return 'Other'
  }
}

export const isNewFileItem = (item: FileItem): item is NewFileItem => item.isNew
export const isSavedFileItem = (item: FileItem): item is SavedFileItem =>
  !item.isNew

export const truncateFileName = (fileName: string): string => {
  const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.')
  if (fileNameWithoutExtension.length <= 22) {
    return fileName
  } else {
    const extension = fileName.split('.').pop()
    const abbreviatedFileName =
      fileNameWithoutExtension.substring(0, 20) +
      '...' +
      fileNameWithoutExtension.slice(-2) +
      '.' +
      extension
    return abbreviatedFileName
  }
}

export const fileSizeWithUnit = (fileSize: number): string => {
  // 0.1KB未満の場合は0.1KBと表示する
  if (fileSize < 100) {
    return '0.1KB'
  } else if (fileSize < 1024 * 1024) {
    // 有効桁数を1桁にする
    return `${(Math.round((fileSize / 1024) * 10) / 10).toFixed(1)}KB`
  } else {
    return `${(Math.round((fileSize / 1024 ** 2) * 10) / 10).toFixed(1)}MB`
  }
}

/** 例えばimage.pngが複数アップロードされた場合、二つ目のファイルをimage(1).pngにrenameする */
export const renameFileNameToUnique = (
  targetFileName: string,
  existingFilenames: string[],
): string => {
  // 同一のファイル名が存在しない場合はそのまま返す
  if (existingFilenames.every(fileName => fileName !== targetFileName)) {
    return targetFileName
  }
  // 拡張子がない場合はそのまま返す
  if (!targetFileName.includes('.')) {
    return targetFileName
  }

  // 例: image.png -> image, image(2).png -> image （拡張子直前のカッコは取り除く）
  const extractBaseName = (fileName: string) => {
    return fileName
      .replace(/\(\d+\)\./, '.')
      .split('.')
      .slice(0, -1)
      .join('.')
  }

  const targetBaseName = extractBaseName(targetFileName)
  const targetExtension = targetFileName.split('.').pop()

  // 採番済みのファイル名から番号を抽出し、昇順に並べる
  const existingNumbers = existingFilenames
    .filter(fileName => extractBaseName(fileName) === targetBaseName)
    .map(fileName => {
      const match = fileName.match(/\((\d+)\)\./)
      return match ? parseInt(match[1]) : 0
    })
    .sort((a, b) => a - b)

  // 欠番があればそのうち最小の番号、なければ続く数を返す 例) [1, 2, 4] -> 3, [1, 2, 3] -> 4
  const nextCount = existingNumbers.reduce((acc, num) => {
    return acc === num ? acc + 1 : acc
  }, 1)

  return `${targetBaseName}(${nextCount}).${targetExtension}`
}
