import { useParams } from 'react-router'
import { VideoCallRoom } from 'src/features/videoCall/components'

import { usePatientVideoCallJwtToken } from '../../hooks/usePatientVideoCallJwtToken'
import { PatientVideoCallGuard } from '../PatientVideoCallGuard/PatientVideoCallGuard'

type Param = {
  patientVideoCallUid: string
}

export const PatientVideoCallForPatientScreen: React.FC = () => {
  const { patientVideoCallUid = '' } = useParams<Param>()

  return (
    <PatientVideoCallGuard>
      {({ diseaseUid }) => {
        return (
          <PatientVideoCallForPatientBase
            patientVideoCallUid={patientVideoCallUid}
            diseaseUid={diseaseUid}
          />
        )
      }}
    </PatientVideoCallGuard>
  )
}

type Props = {
  patientVideoCallUid: string
  diseaseUid?: string
}

const PatientVideoCallForPatientBase: React.FC<Props> = ({
  patientVideoCallUid,
  diseaseUid,
}) => {
  const { jwtToken } = usePatientVideoCallJwtToken({
    patientVideoCallUid,
  })

  return <VideoCallRoom jwtToken={jwtToken} participantName={diseaseUid} />
}
