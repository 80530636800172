const MAX_WORKSHEET_NAME_LENGTH = 20
const MIN_WORKSHEET_NAME_LENGTH = 1

type ValidateWorksheetNameResult =
  | {
      isValid: true
    }
  | {
      isValid: false
      errorMessage: string
    }

export const validateWorksheetNameLength = (
  name: string,
): ValidateWorksheetNameResult => {
  if (name.length > MAX_WORKSHEET_NAME_LENGTH) {
    return {
      isValid: false,
      errorMessage: `ワークシート表示名は${MAX_WORKSHEET_NAME_LENGTH}文字以内で入力してください`,
    }
  }
  if (name.length < MIN_WORKSHEET_NAME_LENGTH) {
    return {
      isValid: false,
      errorMessage: `ワークシート表示名は必須です`,
    }
  }
  return { isValid: true }
}
