import {
  Box,
  Button,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { useSelectedTrial } from 'src/features/auth/context'
import { useMutation } from 'src/hooks/use-mutation'
import {
  CancelExportJobDocument,
  CancelExportJobMutationVariables,
  RegisterExportJobDocument,
  WsDataExportScreenQuery,
  WsDataExportScreenQueryVariables,
  WsDataExportScreenDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR from 'swr'

import { JobListTable } from '../JobListTable/JobListTable'

const isJobRunning = (
  job: WsDataExportScreenQuery['worksheetCSVExportJobs'][number],
) => {
  const latestLog = job.logs?.[0]
  return latestLog?.status === 'Queued' && !latestLog.isExpired
}

// NOTE: 実験的にGraphQLのColocationを試している（Tableを切り出してTableで必要なデータはFragmentとして定義）
export const WSDataExportScreen: React.FC = () => {
  const { selectedTrial } = useSelectedTrial()

  const variable: WsDataExportScreenQueryVariables = {
    input: {
      order: 'SavedAtDesc',
    },
  }

  const { data, mutate } = useSWR(
    variable,
    variable => graphqlRequest(WsDataExportScreenDocument, variable),
    {
      refreshInterval: lastData => {
        if (!lastData) {
          return 0
        }
        // 実行中のjobがあるときは1秒ごとにポーリング
        const runningJob = lastData.worksheetCSVExportJobs.find(isJobRunning)
        return runningJob ? 1000 : 0
      },
    },
  )

  const { request: registerJob } = useMutation(
    () => graphqlRequest(RegisterExportJobDocument, {}),
    {
      onSuccess: () => {
        mutate()
      },
    },
  )
  const { request: cancelJob } = useMutation(
    (variable: CancelExportJobMutationVariables) =>
      graphqlRequest(CancelExportJobDocument, variable),
    {
      onSuccess: () => {
        mutate()
      },
    },
  )

  if (!data) {
    return null
  }

  const runningJob = data.worksheetCSVExportJobs.find(isJobRunning)

  const isRunning = runningJob !== undefined

  return (
    <Flex as="main" direction="column" h="full">
      <Stack spacing="8">
        <Text as="h1" fontSize="lg" fontWeight="bold">
          症例データダウンロード
        </Text>
        <Box pos="relative">
          {isRunning && (
            <HStack align="center" pos="absolute" top="0" bottom="0" my="auto">
              <Text>症例データCSVファイルを作成中です。</Text>
              <Spinner />
            </HStack>
          )}
          <Stack
            spacing="2"
            justify="center"
            visibility={isRunning ? 'hidden' : 'visible'}
          >
            <Text>
              CSV形式で
              <strong>{selectedTrial.name}</strong>
              の症例データを作成します。
            </Text>
            <Text>
              症例データCSVファイルは作成処理完了後にファイル作成履歴より
              ダウンロードできます。
            </Text>
            <WarningMessage message="ダウンロード期限は処理終了日時より7日間です。" />
          </Stack>
        </Box>

        <Box>
          {isRunning ? (
            <Button
              variant="outline"
              onClick={async () => {
                await cancelJob({ input: { jobUid: runningJob.jobUid } })
              }}
            >
              処理を中止
            </Button>
          ) : (
            <Button
              variant="outline"
              onClick={async () => {
                await registerJob()
              }}
            >
              CSVファイル作成
            </Button>
          )}
        </Box>
      </Stack>

      <Box mt="8" flex="1" overflow="auto">
        <JobListTable jobs={data.worksheetCSVExportJobs} />
      </Box>
    </Flex>
  )
}
