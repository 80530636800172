import React from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { colors } from 'src/assets/colors'
import { Button } from 'src/components/base/button/button'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getListRoute } from 'src/modules/dashboard/trial/detail/template/routes'
import { trialIdParamName } from 'src/modules/dashboard/trial/detail/trial-detail'
import styled from 'styled-components'

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.bgWhite};
  padding: 32px;
`

const NonSheet = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;

  text-align: center;
  color: ${colors.grayLighten6};
`

export const Empty: React.FC = () => {
  const { hasPermission } = usePermission()
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()
  const navigate = useNavigate()

  const onNavigate = () => {
    navigate(getListRoute(trialUid))
  }

  return (
    <PageContainer>
      <ContentContainer>
        <NonSheet>
          ワークシートテンプレートが作成されていないか、
          <br />
          公開のものがありません。
        </NonSheet>

        {hasPermission(PERMISSIONS.Template_Create) && (
          <Button
            size="S"
            text="テンプレート作成画面へ"
            onClick={onNavigate}
            buttonType="important"
          />
        )}
      </ContentContainer>
    </PageContainer>
  )
}
