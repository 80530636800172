import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { MediumItemAnswerLog } from './entity'
import { fixtures } from './fixture'

export type MediumItemAnswerLogResponse = {
  uid: string
  medium_item_answer_uid: string
  user_name: string
  action: number
  confirmation_status: number
  message: string
  reason: string
  created_at: string
}

export const parse = (
  resp: MediumItemAnswerLogResponse,
): MediumItemAnswerLog => {
  return {
    uid: resp.uid,
    mediumItemAnswerUid: resp.medium_item_answer_uid,
    userName: resp.user_name,
    action: resp.action,
    confirmationStatus: resp.confirmation_status,
    message: resp.message,
    reason: resp.reason,
    createdAt: resp.created_at,
  }
}

const createdAtDesc = (a: MediumItemAnswerLog, b: MediumItemAnswerLog) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

export const fetchList = async ({
  trialUid,
  patientUid,
  worksheetUid,
  mediumItemAnswerUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
  mediumItemAnswerUid: string
}): Promise<MediumItemAnswerLog[]> => {
  if (IS_MOCK_MODE) {
    return fixtures.sort(createdAtDesc)
  }

  const resp = await requestGet<MediumItemAnswerLogResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}/medium_item_answers/${mediumItemAnswerUid}/logs`,
    params: {},
  })

  return resp.data.map(parse).sort(createdAtDesc)
}
