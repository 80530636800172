import {
  GqlError,
  GetSessionForPatientSidebarQuery,
  GetSessionForPatientSidebarDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

type GetSessionForPatientSidebarRes =
  GetSessionForPatientSidebarQuery['explanationSession']

const getSessionForPatientSidebar = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<GetSessionForPatientSidebarRes> => {
  try {
    const res = await graphqlRequest(GetSessionForPatientSidebarDocument, {
      explanationSessionUid,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionForPatientSidebar = ({
  explanationSessionUid,
  ...option
}: {
  explanationSessionUid: string
} & SWRConfiguration<GetSessionForPatientSidebarRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionForPatientSidebar(explanationSessionUid),
    () => getSessionForPatientSidebar({ explanationSessionUid }),
    option,
  )
}
