import React from 'react'

import { Stack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { Choice } from 'src/modules/entities/choice/entity'
import { MediumItem } from 'src/modules/entities/medium-item/entity'
import { SmallItem } from 'src/modules/entities/small-item/entity'
import { TemplateStatus } from 'src/modules/entities/template/entity'
import { RootState } from 'src/modules/reducer'
import styled from 'styled-components'

import { SortOnlyCard } from './components/sort-only-card'
import { SortOnlyGroup } from './components/sort-only-group'
import { actions } from './redux'
import { getSmallItems } from './selector'
import { useEditCard } from './use-edit-card'
import { usePermission } from '../../../common/permission'

type Props = {
  index: number
  mediumItem: MediumItem
  releaseTried: boolean
  orderEditable: boolean
  templateStatus: TemplateStatus
}

const Container = styled.div``

export const SortOnlyMediumItemGroup: React.FC<Props> = props => {
  const { index, mediumItem, releaseTried, orderEditable, templateStatus } =
    props

  const { hasPermission } = usePermission()

  const selectedTrial = useSelector(getSelectedTrial)!
  const v2 = selectedTrial.featureFlags.eSourceV2

  const dispatch = useDispatch()

  const onUpMediumItem = () => {
    dispatch(
      actions.changeIndexMediumItem({
        index,
        direction: 'up',
      }),
    )
  }

  const onDownMediumItem = () => {
    dispatch(
      actions.changeIndexMediumItem({
        index,
        direction: 'down',
      }),
    )
  }

  const onChangeMediumItem = (values: Partial<MediumItem>) => {
    dispatch(
      actions.updateMediumItem({
        index,
        values,
      }),
    )
  }

  const onDeleteMediumItem = () => {
    dispatch(
      actions.deleteMediumItem({
        index,
      }),
    )
  }

  const smallItems = useSelector((state: RootState) =>
    getSmallItems(state, { mediumItemUid: mediumItem.uid }),
  )

  const onChangeSmallItem = ({
    smallItemUid,
    values,
  }: {
    smallItemUid: string
    values: Partial<SmallItem>
  }) => {
    dispatch(
      actions.updateSmallItem({
        mediumItemIndex: index,
        uid: smallItemUid,
        values,
      }),
    )
  }

  const onChangeSmallItemIndex = ({
    smallItemUid,
    direction,
  }: {
    smallItemUid: string
    direction: 'up' | 'down'
  }) => {
    dispatch(
      actions.changeIndexSmallItem({
        mediumItemIndex: index,
        uid: smallItemUid,
        direction,
      }),
    )
  }

  const onRemoveSmallItem = ({ smallItemUid }: { smallItemUid: string }) => {
    dispatch(
      actions.deleteSmallItem({ mediumItemIndex: index, uid: smallItemUid }),
    )
  }

  const onAddChoice = ({ smallItemUid }: { smallItemUid: string }) => {
    dispatch(actions.addChoice({ mediumItemIndex: index, smallItemUid }))
  }

  const onChangeChoice = ({
    smallItemUid,
    choiceIndex,
    values,
  }: {
    smallItemUid: string
    choiceIndex: number
    values: Partial<Choice>
  }) => {
    dispatch(
      actions.updateChoice({
        mediumItemIndex: index,
        smallItemUid,
        choiceIndex,
        values,
      }),
    )
  }

  const onRemoveChoice = ({
    smallItemUid,
    choiceIndex,
  }: {
    smallItemUid: string
    choiceIndex: number
  }) => {
    dispatch(
      actions.deleteChoice({
        mediumItemIndex: index,
        smallItemUid,
        choiceIndex,
      }),
    )
  }

  const onAddSmallItemToChoice = ({
    smallItemUid,
    choiceIndex,
  }: {
    smallItemUid: string
    choiceIndex: number
  }) => {
    dispatch(
      actions.addSmallItemToChoice({
        mediumItemIndex: index,
        smallItemUid,
        choiceIndex,
        v2,
      }),
    )
  }

  const { canChangeSmallItemIndex } = useEditCard(index)

  return (
    <Container id={mediumItem.uid}>
      <Stack spacing="5">
        <SortOnlyGroup
          index={index}
          mediumItem={mediumItem}
          orderEditable={orderEditable}
          onUp={onUpMediumItem}
          onDown={onDownMediumItem}
          onChange={onChangeMediumItem}
          onDelete={onDeleteMediumItem}
          v2={v2}
        />

        <Stack spacing="5" pl="10">
          {smallItems.map(smallItem => (
            <SortOnlyCard
              key={smallItem.uid}
              smallItem={smallItem}
              smallItemMap={mediumItem.smallItemMap}
              orderEditable={orderEditable}
              canEditTemplate={hasPermission(PERMISSIONS.Template_SetHospitals)}
              onAddChoice={onAddChoice}
              onChangeSmallItem={onChangeSmallItem}
              onChangeSmallItemIndex={onChangeSmallItemIndex}
              onChangeChoice={onChangeChoice}
              onRemoveSmallItem={onRemoveSmallItem}
              onRemoveChoice={onRemoveChoice}
              onAddSmallItemToChoice={onAddSmallItemToChoice}
              marginBottom={12}
              releaseTried={releaseTried}
              templateStatus={templateStatus}
              v2={v2}
              canChangeSmallItemIndex={canChangeSmallItemIndex}
            />
          ))}
        </Stack>
      </Stack>
    </Container>
  )
}
