import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { getSingle as getWorksheet } from 'src/modules/entities/worksheet/selector'

import {
  LoglineDetail,
  loglineIdParamName,
} from './logline-detail/logline-detail'
import { useFetch } from './use-fetch'
import { WorksheetDetail, worksheetIdParamName } from './worksheet-detail'
import { getSelectedTrial } from '../../../../../../../../entities/account/selector'
import { getSingle as getSingleTemplate } from '../../../../../../../../entities/template/selector'
import { RootState } from '../../../../../../../../reducer'
import { patientIdParamName } from '../../../patient-detail'

type Props = {}

export const WorksheetDetailRouter: React.FC<Props> = props => {
  const { worksheetUid = '', patientUid = '' } = useParams<{
    [worksheetIdParamName]: string
    [patientIdParamName]: string
  }>()

  const trial = useSelector(getSelectedTrial)!
  const worksheet = useSelector((state: RootState) =>
    getWorksheet(state, worksheetUid),
  )
  const template = useSelector((state: RootState) =>
    getSingleTemplate(state, worksheet?.templateUid),
  )
  const { fetched } = useFetch({
    trialUid: trial.uid,
    patientUid,
    worksheetUid,
    templateUid: worksheet?.templateUid,
  })

  if (!fetched) {
    return null
  }
  if (!worksheet || !template) {
    return <Navigate replace to={'./'} />
  }

  return (
    <Routes>
      <Route
        path={`loglines/:${loglineIdParamName}`}
        element={<LoglineDetail />}
      />
      <Route path={``} element={<WorksheetDetail />}></Route>
    </Routes>
  )
}
