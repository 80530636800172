import React, { useCallback, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  SaveSectionModal,
  UpdateSmallItemAnswersParam,
} from './save-section-modal'
import { getSelectedTrial } from '../../../../../../../../../../entities/account/selector'
import { MediumItemAnswer } from '../../../../../../../../../../entities/medium-item-answer/entity'
import {
  getAncestorSmallItemAnswerUids,
  getDescendantSmallItemAnswerUids,
} from '../../../../../../../../../../entities/medium-item-answer/util'
import { SmallItemAnswer } from '../../../../../../../../../../entities/small-item-answer/entity'
import { toValueString } from '../../../../../../../../../../entities/small-item-answer/util'
import { getSingle } from '../../../../../../../../../../entities/worksheet/selector'
import { RootState } from '../../../../../../../../../../reducer'
import { actions } from '../../redux'
import { getState } from '../../selector'

export type SaveSectionItem = {
  smallItemAnswer: SmallItemAnswer
  indentLevel: number
  showable: boolean
  updatable: boolean
  needReason: boolean
}

const convertMediumItemAnswerToSaveSectionItems = (
  originalMediumItemAnswer: MediumItemAnswer,
  mediumItemAnswer: MediumItemAnswer,
) => {
  const { smallItemAnswerMap: originalSmallItemAnswerMap } =
    originalMediumItemAnswer
  const items: SaveSectionItem[] = []

  const pushSmallItemAnswerToItems = (
    smallItemAnswer: SmallItemAnswer,
    currentIndentLevel: number,
  ) => {
    const changed =
      toValueString(smallItemAnswer) !==
      toValueString(originalSmallItemAnswerMap[smallItemAnswer.uid])

    // ネストしたフィールドが変更された場合、どのフィールドからネストしたフィールドか分かるよう祖先も表示されるようにする
    if (changed && !!smallItemAnswer.parentUid) {
      makeAncestorsShowable(items, smallItemAnswer.parentUid)
    }

    items.push({
      smallItemAnswer: smallItemAnswer,
      indentLevel: currentIndentLevel,
      showable: changed,
      updatable: changed,
      needReason: changed && smallItemAnswer.hasLog,
    })

    smallItemAnswer.choiceAnswers.forEach(ca => {
      if (!ca.checked) return

      const nextSmallItemAnswers = ca.smallItemAnswerUids.map(
        uid => mediumItemAnswer.smallItemAnswerMap[uid],
      )

      nextSmallItemAnswers.forEach(nextSmallItemAnswer => {
        pushSmallItemAnswerToItems(nextSmallItemAnswer, currentIndentLevel + 1)
      })
    })
  }

  const rootSmallItemAnswers = Object.values(
    mediumItemAnswer.smallItemAnswerMap,
  )
    .sort((a, b) => a.index - b.index)
    .filter(sia => sia.parentUid === null)

  rootSmallItemAnswers.forEach(sia => pushSmallItemAnswerToItems(sia, 1))

  return items
}

const useCheckSmallItemAnswers = (
  mediumItemAnswer: MediumItemAnswer,
  items: SaveSectionItem[],
) => {
  const [selectedSmallItemAnswerUids, setSlectedSmallItemAnswerUids] = useState<
    string[]
  >(items.filter(item => item.updatable).map(item => item.smallItemAnswer.uid))

  const handlers = useMemo(
    () => ({
      onCheck: (smallItemAnswerUid: string) => {
        // チェックを外す場合、チェックしたフィールドに加えて子孫のフィールドのチェックも外す
        if (selectedSmallItemAnswerUids.includes(smallItemAnswerUid)) {
          const checkUidWithDescendantUids = getDescendantSmallItemAnswerUids(
            mediumItemAnswer,
            smallItemAnswerUid,
          ).concat(smallItemAnswerUid)

          const selectedSmallItemAnswerUidsSet = new Set(
            selectedSmallItemAnswerUids,
          )
          checkUidWithDescendantUids.forEach(uid => {
            selectedSmallItemAnswerUidsSet.delete(uid)
          })
          setSlectedSmallItemAnswerUids(
            Array.from(selectedSmallItemAnswerUidsSet),
          )
          // チェックをつける場合、チェックした項目に加えて祖先のフィールドのチェックもつける
        } else {
          const checkUidWithAncestorUids = getAncestorSmallItemAnswerUids(
            mediumItemAnswer,
            smallItemAnswerUid,
          ).concat(smallItemAnswerUid)

          setSlectedSmallItemAnswerUids(
            // 祖先のフィールドの uid は既に selectedSmallItemAnswerUids に含まれている可能性があるので重複排除している
            Array.from(
              new Set(
                selectedSmallItemAnswerUids.concat(...checkUidWithAncestorUids),
              ),
            ),
          )
        }
      },
    }),
    [selectedSmallItemAnswerUids, mediumItemAnswer],
  )

  return { selectedSmallItemAnswerUids, handlers }
}

const makeAncestorsShowable = (items: SaveSectionItem[], parentUid: string) => {
  items.forEach(item => {
    if (item.smallItemAnswer.uid === parentUid) {
      item.showable = true
      if (!!item.smallItemAnswer.parentUid) {
        makeAncestorsShowable(items, item.smallItemAnswer.parentUid)
      }
      return
    }
  })
}

type Props = {
  mediumItemAnswerUid: string
  onClose: () => void
}

export const SaveSectionModalContainer: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const trial = useSelector(getSelectedTrial)!
  const { submitting, worksheet: currentWorksheet } = useSelector(getState)
  const originalWorksheet = useSelector((state: RootState) =>
    getSingle(state, currentWorksheet!.uid),
  )
  const currentMediumItemAnswer = currentWorksheet!.mediumItemAnswers.find(
    mia => mia.uid === props.mediumItemAnswerUid,
  )!
  const originalMediumItemAnswer = originalWorksheet!.mediumItemAnswers.find(
    mia => mia.uid === props.mediumItemAnswerUid,
  )!

  const saveSectionItems = convertMediumItemAnswerToSaveSectionItems(
    originalMediumItemAnswer,
    currentMediumItemAnswer,
  )

  const {
    selectedSmallItemAnswerUids,
    handlers: { onCheck },
  } = useCheckSmallItemAnswers(currentMediumItemAnswer, saveSectionItems)

  const onClose = useCallback(() => {
    props.onClose()
  }, [props])

  const onSubmit = async (param: UpdateSmallItemAnswersParam) => {
    await dispatch(
      actions.submitUpdateSmallItemAnswers({
        trialUid: trial.uid,
        originalMediumItemAnswer,
        mediumItemAnswer: currentMediumItemAnswer,
        updateSmallItemAnswersParam: param,
      }),
    )

    onClose()
  }

  return (
    <SaveSectionModal
      selectedSmallItemAnswerUids={selectedSmallItemAnswerUids}
      saveSectionItems={saveSectionItems}
      requesting={submitting}
      onCheck={onCheck}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}
