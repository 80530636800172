import { HttpResponse } from 'msw'
import {
  createCreateAgreementFormEnvelopePayloadMock,
  CreateEnvelopeViewUrlDocument,
  CreateEnvelopeViewUrlMutationVariables,
  CreateAgreementFormEnvelopeMutation,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const createEnvelopeViewUrlHandler = graphQLHandlerFactory<
  CreateAgreementFormEnvelopeMutation,
  CreateEnvelopeViewUrlMutationVariables
>('mutation', CreateEnvelopeViewUrlDocument, () => {
  return HttpResponse.json({
    data: {
      createAgreementFormEnvelope:
        createCreateAgreementFormEnvelopePayloadMock(),
    },
  })
})
