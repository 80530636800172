import { useState } from 'react'

import { useRoomSession } from '@micin-jp/call-lib-react'
import { useNavigate } from 'react-router-dom'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { useExplanationSession } from 'src/features/explanation/api'
import {
  ExplanationRevision,
  ExplanationSession,
} from 'src/features/explanation/types'
import { getRoomFinishedRoute } from 'src/features/explanation/utils/getRoomFinishedRoute'

import { getNotFoundRoute } from './routes'

/** sessionの取得とそれに係る処理を扱う */
export const useHospitalSession = ({
  explanationSessionUid,
}: {
  explanationSessionUid: ExplanationSession['uid']
}) => {
  const navigate = useNavigate()

  const { account } = useAuthenticatedAccount()

  const [rev, setRev] = useState<ExplanationRevision>()

  const { leaveRoom } = useRoomSession()

  const { data: session, mutate: mutateSession } = useExplanationSession({
    explanationSessionUid,
    refreshInterval: 5000,
    onSuccess: session => {
      setRev(session.explanationRevision)

      if (session.explanationRevision.status === 'Disabled') {
        navigate(getNotFoundRoute({ type: 'hospital' }))
        return
      }
    },
    onError: async error => {
      // sessionが終了している場合400が返ってくる。
      // 別の400の可能性もあるのでできればより詳細にハンドリングしたい
      if (error.status === 400 && !!rev) {
        leaveRoom().then()
        navigate(
          getRoomFinishedRoute({
            isPartnerMember:
              account.selectedTrialHospitalUid ===
              rev.partnerTrialHospital?.uid,
            type: rev.type,
          }),
        )
        return
      }
    },
  })

  return {
    session,
    mutateSession,
  }
}
