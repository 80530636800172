import { Flex, HStack, Stack, Text } from '@chakra-ui/react'

import { MessageInformation } from '../icon'

type Props = {
  message: string
  subMessage?: string
}

export const InformationMessage: React.FC<Props> = ({
  message,
  subMessage,
}) => {
  return (
    <HStack spacing="1.5" color="blue.500" align="start">
      <Flex align="center" h="1.5rem">
        <MessageInformation size="16px" />
      </Flex>
      <Stack spacing="1">
        <Text fontSize="md" fontWeight="normal">
          {message}
        </Text>
        {!!subMessage && (
          <Text fontSize="sm" fontWeight="normal">
            {subMessage}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}
