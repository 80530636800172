import { HttpResponse } from 'msw'
import {
  createSetExpDocRevisionSignerMemberPayloadMock,
  SetExpDocRevisionSignerMemberDocument,
  SetExpDocRevisionSignerMemberMutation,
  SetExpDocRevisionSignerMemberMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const setExpDocRevisionSignerMemberHandler = graphQLHandlerFactory<
  SetExpDocRevisionSignerMemberMutation,
  SetExpDocRevisionSignerMemberMutationVariables
>('mutation', SetExpDocRevisionSignerMemberDocument, () => {
  return HttpResponse.json({
    data: {
      setExpDocRevisionSignerMember:
        createSetExpDocRevisionSignerMemberPayloadMock(),
    },
  })
})
