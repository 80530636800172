import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useApiClientFetch } from 'src/hooks/use-api-client-fetch'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'

import { getAddIcfDocumentRoute, getEditIcfDocumentRoute } from './../../routes'
import { useIcfDocumentHospital } from './../../use-icf-document-hospital'
import { PERMISSIONS } from '../../../../../../../../lib/permission'
import { usePermission } from '../../../../common/permission'

export const useIcfDocumentList = () => {
  const navigate = useNavigate()
  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { hasPermission } = usePermission()
  const { showInfo, showError } = useFlash()

  const hasPermissionReAgreement = hasPermission(PERMISSIONS.Explanation_Edit)

  const { data: icfDocumentList, error: fetchError } = useApiClientFetch({
    path: '/trials/{trial_uid}/trial_hospitals/{trial_hospital_uid}/icf_documents',
    params: {
      paths: {
        trial_uid: trialUid,
        trial_hospital_uid: selectedTrialHospitalUid!,
      },
    },
    onSuccess: data => {
      const hasRequiredReAgreementConfirmationDoc = data.some(
        doc => doc.isRequiredReAgreementConfirmation,
      )

      if (hasPermissionReAgreement && hasRequiredReAgreementConfirmationDoc) {
        showInfo('再同意確認が必要な文書があります。', {
          description:
            '再同意確認ボタンから、改版文書の再同意実施有無を設定してください。',
          durationMsec: 15000,
          closable: true,
        })
      }
    },
    onError: e => {
      showError(e.data.message)
    },
    shouldCancel: selectedTrialHospitalUid === undefined,
    revalidateOnFocus: false,
  })

  const shouldShowReAgreementConfirmButton = useMemo(() => {
    if (!icfDocumentList) return false
    if (!hasPermissionReAgreement) return false
    return icfDocumentList.some(doc => doc.isRequiredReAgreementConfirmation)
  }, [hasPermissionReAgreement, icfDocumentList])

  const navigateToAdd = useCallback(() => {
    navigate(
      getAddIcfDocumentRoute({
        trialUid,
        trialHospitalUid: hasPermission(
          PERMISSIONS.Icfdocument_SelectTrialHospital,
        )
          ? selectedTrialHospitalUid
          : undefined,
      }),
    )
  }, [navigate, trialUid, hasPermission, selectedTrialHospitalUid])

  const navigateToEdit = useCallback(
    ({
      icfDocumentUid,
      latestRevisionUid,
    }: {
      icfDocumentUid: string
      latestRevisionUid: string
    }) => {
      navigate(
        getEditIcfDocumentRoute({
          trialUid,
          icfDocumentUid,
          trialHospitalUid: hasPermission(
            PERMISSIONS.Icfdocument_SelectTrialHospital,
          )
            ? selectedTrialHospitalUid
            : undefined,
        }),
      )
    },
    [navigate, trialUid, hasPermission, selectedTrialHospitalUid],
  )

  return {
    icfDocumentList: icfDocumentList,
    fetchError,
    selectedTrialHospitalUid,
    shouldShowReAgreementConfirmButton,
    navigateToAdd,
    navigateToEdit,
  }
}
