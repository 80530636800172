export const colors = {
  yellow: {
    50: '#FFFBF2',
    100: '#FFEBC2',
    200: '#FFDA91',
    300: '#FDC961',
    400: '#F7B631',
    500: '#EEA200',
    600: '#D48C00',
    700: '#AC7500',
    800: '#835B00',
    900: '#594000',
  },
  orange: {
    900: '#4D1500',
    800: '#792A00',
    700: '#A64400',
    600: '#D26100',
    500: '#F48000',
    400: '#FC9431',
    300: '#FFA961',
    200: '#FFC091',
    100: '#FFDAC2',
    50: '#FFF7F2',
  },
  red: {
    50: '#FFF5F5',
    100: '#FDD0D0',
    200: '#FAADAD',
    300: '#F58B8B',
    400: '#EF6B6B',
    500: '#E64D4D',
    600: '#C93939',
    700: '#A82727',
    800: '#831919',
    900: '#590E0E',
  },
  green: {
    50: '#F5FCFB',
    100: '#CFF0EB',
    200: '#AAE3DA',
    300: '#87D4C8',
    400: '#65C4B6',
    500: '#44B3A2',
    600: '#339C8C',
    700: '#258274',
    800: '#18665A',
    900: '#0E483F',
  },
  blue: {
    50: '#F4F8FE',
    100: '#CADCF7',
    200: '#A1C0EF',
    300: '#7BA5E5',
    400: '#568AD9',
    500: '#336FCC',
    600: '#235BB3',
    700: '#164897',
    800: '#0B3677',
    900: '#052455',
  },
  gray: {
    50: '#EEEEEE',
    100: '#DDDDDD',
    200: '#C4C4C4',
    300: '#B1B1B1',
    400: '#999999',
    500: '#777777',
    600: '#555555',
    700: '#333333',
    800: '#1C1C1C',
    900: '#080808',
  },
  black: '#000000',
  white: '#FFFFFF',
  headerGradation: 'linear-gradient(90.11deg, #4679E2 0%, #5DBAFF 100%)',
}
