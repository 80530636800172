import React, { useRef } from 'react'

import { Box, VisuallyHidden } from '@chakra-ui/react'

import { Button } from '../button/button'

type Props = {
  onChange: (file: File) => void
  accept?: '.pdf' | 'application/json' | '.csv'
  text?: string
}

/**
 * @deprecated Use component in `src/components/FileSelectButton/FileSelectButton.tsx`
 *
 */
export const FileSelectButton: React.FC<Props> = ({
  onChange: _onChange,
  text,
  accept,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onClick = () => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputRef.current) return
    if (!e.target.files) return
    const file = e.target.files[0]
    if (!file) return
    _onChange(file)
    // ファイル選択 -> もとに戻す -> 同じファイルを選択 とした場合に期待通りに動くようにする
    inputRef.current.value = ''
  }

  return (
    <Box>
      <Button
        size="S"
        buttonType="normal"
        text={text ?? 'ファイルを選択'}
        onClick={onClick}
        type="button"
      />
      <VisuallyHidden>
        <input
          ref={inputRef}
          type="file"
          aria-label="file-input"
          accept={accept}
          onChange={onChange}
        />
      </VisuallyHidden>
    </Box>
  )
}
