import { GetPatientReturn } from '../api'
import { SearchPatientsReturn } from '../api/searchPatients'

type Patient = GetPatientReturn | SearchPatientsReturn['patients'][number]

export const getRelatedHospitalName = (
  patient: Patient,
  selectedTrialHospitalUid: string | null,
): string | undefined => {
  const partnerTrialHospital = patient.partnerTrialHospital
  const trialHospital = patient.trialHospital

  if (!selectedTrialHospitalUid) {
    return partnerTrialHospital?.hospital.name
  }

  const isPartner = partnerTrialHospital?.uid === selectedTrialHospitalUid
  return isPartner
    ? trialHospital.hospital.name
    : partnerTrialHospital?.hospital.name
}
