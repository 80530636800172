import { Trial, TrialHospital } from 'src/features/trial/types'

import { IcfDocumentRevision } from '../types'

export const getFilePreviewUrl = ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
}: {
  trialUid: Trial['uid']
  trialHospitalUid: TrialHospital['uid']
  icfDocumentRevisionUid: IcfDocumentRevision['uid']
}) => {
  return `${
    import.meta.env.VITE_SERVER_URL
  }/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/file`
}
