import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export const submitTemplateEnable = async ({
  trialUid,
  templateUid,
}: {
  trialUid: string
  templateUid: string
}): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestPut<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/templates/${templateUid}/enable`,
    params: {},
  })

  return {}
}
