import React from 'react'

import { Tooltip } from '@chakra-ui/react'
import { Hint } from 'src/components/__legacy__icon/monochrome'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

export const ScheduleTooltip: React.FC = () => {
  return (
    <Tooltip
      label={
        <>
          予約日時情報は下記のスケジュールで患者にメール配信されます。
          <br />
          ・予約日前日の9:00
          <br />
          ・予約日当日の開始30~60分前
        </>
      }
      fontSize="xs"
      lineHeight="tall"
    >
      <span>
        <Hint color={colors.blue[500]} />
      </span>
    </Tooltip>
  )
}
