import React from 'react'

import { Stack, Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { AnchorLink } from 'src/components/base/text-link'

export type Props = {
  url: string
  onClose: () => void
}

export const ExportModal: React.FC<Props> = ({ url, onClose }) => {
  return (
    <Modal onClose={onClose} closable={true}>
      <ModalTitle title="エクスポート" />
      <ModalContent>
        <Stack align="center" justify="center" spacing="16px">
          <Text>質問票のエクスポートが完了しました。</Text>
          <AnchorLink href={url} download>
            ダウンロード
          </AnchorLink>
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button size="S" buttonType="cancel" text="閉じる" onClick={onClose} />
      </ModalActions>
    </Modal>
  )
}
