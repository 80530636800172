import { GraphQLClient } from 'graphql-request'
import { createClient } from 'graphql-sse'
import { SERVER_URL } from 'src/modules/server/const'

export const gqlClient = new GraphQLClient(`${SERVER_URL}/query`, {
  mode: 'cors',
  credentials: 'include',
})

export const gqlSseClient = () =>
  createClient({
    url: `${SERVER_URL}/query`,
    credentials: 'include',
  })
