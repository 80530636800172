import React, { useEffect, useState } from 'react'

import {
  generatePath,
  useParams,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useExplanationRevisionAbout } from 'src/features/explanation/api'
import { usePreSession } from 'src/features/explanation/hooks/usePreSession'
import { ExplanationRevision } from 'src/features/explanation/types'
import { ExplanationRoomLobbyVideoCallContent } from 'src/features/explanationRoom/components/ExplanationRoomLobbyVideoCallContent/ExplanationRoomLobbyVideoCallContent'
import { useRoomMediaContext } from 'src/features/explanationRoom/context/MediaProvider'
import { Trial } from 'src/features/trial/types'

import { UID } from '../../../../../../../utils/brandedUid'
import { Information } from '../../components/information'
import { PatientLobbySidebar } from '../components/patient-lobby-sidebar'
import { ExplanationRoomLayout } from '../layout'
import { getExplanationRoomTopRoute, getNotFoundRoute } from '../routes'

type Param = {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
}

export const PatientLobbyContainer: React.FC<{}> = () => {
  const {
    trialUid = '' as UID<'Trial'>,
    explanationRevisionUid = '' as UID<'ExplanationRevision'>,
  } = useParams<Param>()

  const { initializePatientVideoCall } = useRoomMediaContext()

  const { data: revisionAbout } = useExplanationRevisionAbout({
    trialUid,
    explanationRevisionUid,
    onError: () => {
      navigate(getNotFoundRoute({ type: 'patient' }))
    },
  })

  const moveToRoom = (sessionUid: string) => {
    navigate(
      getExplanationRoomTopRoute({
        type: 'patient',
        trialUid,
        sessionUid,
      }),
    )
  }

  const [isWaitingSession, setIsWaitingSession] = useState(false)

  const { preSession, setupPreSessionError } = usePreSession({
    isPatient: true,
    explanationRevisionUid,
    onSetup: preSession => {
      if (preSession.explanationRevision.latestHistory.status === 'Disabled') {
        navigate(getNotFoundRoute({ type: 'patient' }))
        return
      }

      if (!preSession.explanationSessionUid) {
        setIsWaitingSession(true)
      }
    },
    onAdditionalFetch: preSession => {
      // セッションが開始したら自動でトップ画面へ（最初からセッションが開始している場合は手動で遷移）
      if (!!preSession.explanationSessionUid && isWaitingSession) {
        moveToRoom(preSession.explanationSessionUid)
      }
    },
  })

  useEffect(() => {
    if (!preSession?.videoCallRoomUid || !revisionAbout?.candidateID) return
    initializePatientVideoCall({
      roomUid: preSession.videoCallRoomUid,
      candidateId: revisionAbout.candidateID,
    })
  }, [
    preSession?.videoCallRoomUid,
    revisionAbout?.candidateID,
    initializePatientVideoCall,
  ])

  const navigate = useNavigate()

  const isSessionStarted = !!preSession?.explanationSessionUid

  if (!!setupPreSessionError) {
    // "Unauthorized(401)"の場合はsignInページに遷移
    if (setupPreSessionError.status === 401) {
      return (
        <Navigate
          replace
          to={generatePath(Paths.ExplanationRoomPatientSignIn, {
            trialUid,
            explanationRevisionUid,
          })}
        />
      )
    }
    return <Information body={setupPreSessionError.message} />
  }

  if (!preSession || !revisionAbout) return null

  return (
    <ExplanationRoomLayout
      headerTitle="説明ロビー"
      sidebarContent={
        <PatientLobbySidebar
          trialName={revisionAbout.trialName}
          candidateId={revisionAbout.candidateID}
          hospitalName={revisionAbout.hospitalName}
          members={revisionAbout.members}
          canMoveToRoom={isSessionStarted}
          onMoveToRoom={() => {
            if (preSession.explanationSessionUid) {
              moveToRoom(preSession.explanationSessionUid)
            }
          }}
        />
      }
    >
      <ExplanationRoomLobbyVideoCallContent
        isOldFlow
        isSessionStarted={isSessionStarted}
        hospitalName={revisionAbout.hospitalName}
      />
    </ExplanationRoomLayout>
  )
}
