import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { Worksheet } from './entity'
import { fixture, fixtureDetail } from './fixture'
import {
  parse as parseMediumItemAnswer,
  MediumItemAnswerResponse,
} from '../medium-item-answer/request'

export type WorksheetResponse = {
  uid: string
  trial_uid: string
  template_uid: string
  patient_uid: string
  title: string
  template_folder_uid: string
  video_enabled: boolean
  has_clinical_small_item: boolean
  medium_item_answers?: Array<MediumItemAnswerResponse>
  status: number
  index: number
  created_at: string
  updated_at: string
}

export const parse = (resp: WorksheetResponse): Worksheet => {
  return {
    uid: resp.uid,
    templateUid: resp.template_uid,
    patientUid: resp.patient_uid,
    title: resp.title,
    templateFolderUid: resp.template_folder_uid,
    videoEnabled: resp.video_enabled,
    createdAt: resp.created_at,
    updatedAt: resp.updated_at,
    status: resp.status,
    index: resp.index,
    hasClinicalSmallItem: resp.has_clinical_small_item,

    mediumItemAnswers: resp.medium_item_answers
      ? resp.medium_item_answers
          .map(parseMediumItemAnswer)
          .sort((a, b) => a.index - b.index)
      : [],
  }
}

export const fetchList = async ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}): Promise<Worksheet[]> => {
  if (IS_MOCK_MODE) {
    return [fixture]
  }

  const resp = await requestGet<WorksheetResponse[]>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets`,
    params: {},
  })

  return resp.data.map(parse)
}

export const fetchSingle = async ({
  trialUid,
  patientUid,
  worksheetUid,
}: {
  trialUid: string
  patientUid: string
  worksheetUid: string
}): Promise<Worksheet> => {
  if (IS_MOCK_MODE) {
    return fixtureDetail
  }

  const resp = await requestGet<WorksheetResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}`,
    params: {},
  })

  return parse(resp.data)
}
