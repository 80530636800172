// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgEConsent = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill={props.color ?? 'currentColor'} clipPath="url(#e_consent_svg__a)">
      <path d="M5.76 9.928a.248.248 0 0 0 .24.32c.024 0 .048 0 .072-.008l2.6-.744-2.16-2.16-.744 2.6-.008-.008ZM15.888 2.032l-1.92-1.92a.251.251 0 0 0-.352 0l-6.64 6.64s-.016.024-.016.032l2.264 2.264s.024-.008.032-.016l6.632-6.648a.251.251 0 0 0 0-.352ZM4.224 11.048c-.296-.64-.776-1.152-1.28-1.448-.776-.472-1.616-.472-2.248 0-.448.336-.648.896-.528 1.504.152.8.776 1.456 1.6 1.672.376.104.968.152 1.712-.128-.192 1.088-1.376 1.92-3.344 2.36l.216.976c3.24-.72 3.968-2.312 4.112-3.2.04-.24.048-.472.032-.696a8.316 8.316 0 0 0 1.376-1.264l-.752-.656c-.296.344-.6.64-.896.88Zm-2.192.768a1.221 1.221 0 0 1-.88-.896c-.024-.136-.04-.376.144-.52a.798.798 0 0 1 .472-.16c.264 0 .504.112.656.208.384.232.744.656.936 1.16-.488.24-.944.312-1.344.2l.016.008Z" />
    </g>
    <defs>
      <clipPath id="e_consent_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgEConsent
