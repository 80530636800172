import React from 'react'

import { VideoViewer } from './video-viewer'
import { useVideoViewer } from './video-viewer.hooks'

export const VideoViewerContainer: React.FC = () => {
  const { videoStreamUrl, error } = useVideoViewer()

  return (
    <VideoViewer
      videoStreamUrl={videoStreamUrl}
      errorMessage={error?.message}
    />
  )
}
