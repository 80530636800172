import { Center, HStack, Stack, Text } from '@chakra-ui/react'
import { generatePath, useParams } from 'react-router-dom'
import { MessageInformation } from 'src/components/icon'
import { Paths } from 'src/constants/paths'

import { useSessionForPatient } from '../../api'
import {
  ExplanationRoomEventProvider,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useFloatingVideoCallForPatient } from '../../hooks/useFloatingVideoCallForPatient'
import { useThisSession } from '../../hooks/useThisSession'
import { getStatusInformationMessage } from '../../utils/getStatusInformationMessage'
import { DocumentList } from '../DocumentList/DocumentList'
import { ExplanationRoomProgressBar } from '../ExplanationRoomProgressBar/ExplanationRoomProgressBar'
import { PatientLayout } from '../PatientLayout/PatientLayout'
import { TopScreenContainer } from '../TopScreenContainer/TopScreenContainer'
import { TopScreenSkelton } from '../TopScreenSkelton/TopScreenSkelton'

type Param = {
  trialUid: string
  sessionUid: string
}

export const PatientTopScreen: React.FC = () => {
  const { trialUid = '', sessionUid = '' } = useParams<Param>()

  const thisSession = useThisSession({ sessionUid, forPatient: true })

  if (!thisSession.fetched) {
    return null
  }

  return (
    <ExplanationRoomEventProvider
      roomType="Session"
      deviceActorUid={thisSession.patient.uid}
      trialUid={trialUid}
      explanationRevisionUid={thisSession.explanationRevisionUid}
      explanationPatientUid={thisSession.patient.uid}
      patientPhoneNumber={thisSession.patient.mobileNumber ?? undefined}
      sessionUid={sessionUid}
      deviceActorType="Patient"
      deviceActorTrialHospitalUid={thisSession.trialHospital.uid}
    >
      <PatientLayout
        trialUid={trialUid}
        headerTitle="説明ルーム"
        sessionUid={sessionUid}
      >
        <Content trialUid={trialUid} sessionUid={sessionUid} />
      </PatientLayout>
    </ExplanationRoomEventProvider>
  )
}

const Content: React.FC<{ trialUid: string; sessionUid: string }> = ({
  trialUid,
  sessionUid,
}) => {
  const { data: session, mutate: mutateSession } = useSessionForPatient({
    explanationSessionUid: sessionUid,
  })

  const thisSession = useThisSession({ sessionUid, forPatient: true })

  useUpdateRevisionStatus({
    listener: () => {
      mutateSession()
      if (thisSession.fetched) {
        thisSession.mutate()
      }
    },
  })

  const { renderFloatingVideoCall } = useFloatingVideoCallForPatient({
    sessionUid,
  })

  if (!session || !thisSession.fetched) {
    return <TopScreenSkelton />
  }

  const status = session.explanationRevision.latestHistory.statusV2

  const informationMessage = getStatusInformationMessage({
    status,
    hasConsentRequiredAgreementForm:
      thisSession.hasConsentRequiredAgreementForm,
    isSignerSet: thisSession.isSignerMemberSet,
  })

  return (
    <TopScreenContainer>
      <Stack spacing="14">
        <Center>
          <ExplanationRoomProgressBar
            status={session.explanationRevision.latestHistory.statusV2}
            isSignerSet={thisSession.isSignerMemberSet}
          />
        </Center>
        <DocumentList
          docSets={session.explanationRevision.docSets}
          docPagePath={docRevisionUid =>
            generatePath(Paths.PatientExplanationRoomDocument, {
              trialUid,
              sessionUid,
              docRevisionUid,
            })
          }
        />
        {!!informationMessage && (
          <Center>
            <HStack spacing="2" color="blue.500" align="center">
              <MessageInformation />
              <Text fontWeight="bold" fontSize="lg">
                {informationMessage}
              </Text>
            </HStack>
          </Center>
        )}
      </Stack>
      {renderFloatingVideoCall()}
    </TopScreenContainer>
  )
}
