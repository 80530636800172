import { createContext } from 'react'

import { Dictionary } from 'lodash'
import { Role } from 'src/modules/entities/member/entity'
import { SmallItemAnswer } from 'src/modules/entities/small-item-answer/entity'

type SmallItemAnswerContextValue = {
  smallItemAnswer: SmallItemAnswer | null
  smallItemAnswerMap: Dictionary<SmallItemAnswer>
  currentRole: Role | null
}

export const SmallItemAnswerContext =
  createContext<SmallItemAnswerContextValue>({
    smallItemAnswer: null,
    smallItemAnswerMap: {},
    currentRole: null,
  })
