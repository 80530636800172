import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'
import { History } from 'src/components/__legacy__icon/monochrome'
import { PageNext } from 'src/components/icon'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useWorksheetField } from '../../api/getWorksheetField'
import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { FieldValueLog } from '../../types'
import { getFlattenFieldMap } from '../../utils/getFlattenFields'
import { getFieldValueText } from '../../utils/getValueText'
import { FileFieldValueView } from '../FileFieldValueView/FileFieldValueView'

type Props = {
  field: Field
  index: number
}

export const WorksheetFieldHistory: React.FC<Props> = ({ field, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { fetchedWorksheet } = useWorksheetValueContext()

  const { data: worksheetField } = useWorksheetField({
    shouldCancel: !isOpen,
    worksheetUid: fetchedWorksheet.uid,
    fid: field.fid,
    index: index,
  })

  const hasHistory = fetchedWorksheet.latestWorksheetLog.fieldValues.some(
    value => value.fid === field.fid && value.index === index,
  )

  const flattenFieldMap = getFlattenFieldMap(fetchedWorksheet.schema.fields)

  const getChangedValueText = (
    newFieldValue: FieldValueLog | null,
    oldFieldValue: FieldValueLog | null,
    type: 'new' | 'old',
  ): React.ReactNode => {
    const value = type === 'new' ? newFieldValue : oldFieldValue

    if (!value) {
      return null
    }

    if (value.type === 'file' && type === 'new') {
      return (
        <FileFieldValueView
          isNewValue={true}
          oldFileFieldValue={
            oldFieldValue?.type === 'file' ? oldFieldValue : undefined
          }
          newFileFieldValue={value}
        />
      )
    }
    if (value.type === 'file' && type === 'old') {
      const newValue =
        newFieldValue?.type === 'file' ? newFieldValue : undefined
      return (
        <FileFieldValueView
          isNewValue={false}
          oldFileFieldValue={
            oldFieldValue?.type === 'file' ? oldFieldValue : undefined
          }
          newFileFieldValue={newValue?.type === 'file' ? newValue : undefined}
        />
      )
    }

    const lineDecoration = type === 'new' ? 'none' : 'line-through'
    const color =
      type === 'old' || value.type === 'cleared' ? 'red.500' : 'green.600'

    return (
      <Text color={color} lineHeight="6" textDecoration={lineDecoration}>
        {value.type !== 'cleared'
          ? getFieldValueText(value, flattenFieldMap)
          : '入力内容をクリア'}
      </Text>
    )
  }

  // 変更履歴がない場合は非表示
  if (!hasHistory) return null

  return (
    <Box>
      <Tooltip label="変更履歴">
        <IconButton
          aria-label="変更履歴を表示"
          variant="customIconButtonGhost"
          colorScheme="blue"
          icon={<History />}
          onClick={onOpen}
        />
      </Tooltip>

      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        {!!worksheetField && (
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerHeader>
              <Text as="h2" fontSize="lg" fontWeight="bold">
                変更履歴
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Stack spacing="3" w="full">
                <TextWithBar as="h2">{field.name}</TextWithBar>

                <Box h="full" flex="1">
                  <VStack spacing="8" align="start">
                    {worksheetField.fieldValues.map((fieldValueLog, i) => {
                      const previousFieldValue =
                        i + 1 < worksheetField.fieldValues.length
                          ? worksheetField.fieldValues[i + 1]
                          : null
                      return (
                        <Box
                          key={fieldValueLog.uid}
                          w="full"
                          as="section"
                          aria-labelledby={fieldValueLog.uid}
                        >
                          <Stack spacing="1">
                            <HStack spacing="2" id={fieldValueLog.uid}>
                              <Text>
                                {formatDate(
                                  fieldValueLog.worksheetLog.savedAt,
                                  'YYYY/MM/DD (ddd) HH:mm',
                                )}
                              </Text>
                              <MemberLabel
                                role={
                                  fieldValueLog.worksheetLog.trialMember.role
                                }
                                displayName={getFullName(
                                  fieldValueLog.worksheetLog.trialMember,
                                )}
                              />
                            </HStack>
                            <HStack spacing="1">
                              <Text fontWeight="bold" wordBreak="keep-all">
                                変更理由：
                              </Text>
                              <Text wordBreak="break-all">
                                {fieldValueLog.reason}
                              </Text>
                            </HStack>
                          </Stack>
                          <Flex w="full" mt="2.5" align="start" gap={4}>
                            <Box
                              w="full"
                              wordBreak="break-all"
                              whiteSpace="pre-line"
                              bg="gray.50"
                              px="3"
                              py="2"
                              minH="64px"
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="bold"
                                color="gray.600"
                              >
                                変更前
                              </Text>
                              {!!previousFieldValue
                                ? getChangedValueText(
                                    fieldValueLog,
                                    previousFieldValue,
                                    'old',
                                  )
                                : null}
                            </Box>

                            <Box mt={4} color="gray.500">
                              <PageNext size="20px" />
                            </Box>

                            <Box
                              w="full"
                              wordBreak="break-all"
                              whiteSpace="pre-line"
                              bg="blue.50"
                              px="3"
                              py="2"
                              minH="64px"
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="bold"
                                color="gray.600"
                              >
                                変更後
                              </Text>
                              {getChangedValueText(
                                fieldValueLog,
                                previousFieldValue,
                                'new',
                              )}
                            </Box>
                          </Flex>
                        </Box>
                      )
                    })}
                  </VStack>
                </Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        )}
      </Drawer>
    </Box>
  )
}
