import { useDispatch } from 'react-redux'

import { updateSmallItemVisibility } from './request'
import { useFlash } from '../../../../../../../flash/use-flash'
import { useRequestState } from '../../../../../../../server/use-request-state'
import { actions } from '../redux'

export const useUpdateSmallItemVisibility = () => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { showSuccess } = useFlash()
  const dispatch = useDispatch()

  const request = async ({
    trialUid,
    templateUid,
    smallItemUids,
    trialHospitalUids,
  }: {
    trialUid: string
    templateUid: string
    smallItemUids: string[]
    trialHospitalUids: string[]
  }) => {
    try {
      requestStarted()
      await updateSmallItemVisibility({
        trialUid,
        templateUid,
        smallItemUids,
        trialHospitalUids,
      })

      showSuccess(
        '医療機関設定を更新しました。テンプレート編集内容は保存されていないのでご注意ください。',
      )
      dispatch(
        actions.updateSmallItemViewableHospitalUids({
          smallItemUids,
          trialHospitalUids,
        }),
      )
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }

  return {
    request,
    requesting,
    errorMessage,
  }
}
