import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Tag,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Document, Page, Thumbnail } from 'react-pdf'
import { NavigateArrow, ScaleDown, ScaleUp } from 'src/components/icon'
import {
  CurrentLocation,
  useCurrentLocations,
  usePointLocations,
  useSendCurrentLocation,
  useSendPointLocation,
} from 'src/features/explanationRoom/context/ExplanationRoomEvent'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { uniqueArray } from 'src/utils/uniqueArray'
import { useDebouncedCallback } from 'use-debounce'

type Props = {
  url: string
  sessionUid: string
  locationPath: string
  trialHospitalUid: string
  showThumbnail?: boolean
} & (
  | {
      actorType: 'Patient'
      explanationPatientUid: string
    }
  | {
      actorType: 'Member'
      trialMemberUid: string
      isPartner: boolean
    }
)

const options = {
  // 日本語がレンダリングできないなどのケース防ぐための設定。 cMapUrl は publicディレクトリ配下の camps ディレクトリを指している
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

export const PdfViewer: React.FC<Props> = memo(props => {
  const { url } = props
  const [numPages, setNumPages] = useState<number>()

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  return (
    <Box h="full" w="full">
      <Document
        file={url}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<MessageComponent message="ファイルを読み込んでいます" />}
        error={<MessageComponent message="ファイルの読み込みに失敗しました" />}
        // NOTE: URLがから文字の場合などにnodataとなる。ユーザーに見せる必要のない情報なので、エラーと同じメッセージを表示する
        noData={<MessageComponent message="ファイルの読み込みに失敗しました" />}
        onLoadError={e => {
          const err = new Error(
            `failed to load PDF in PDFViewer: "${
              e.message
            }", url: "${url}", options: ${JSON.stringify(options)}`,
          )
          Sentry.captureException(err)
          console.error(err)
        }}
      >
        {!!numPages && numPages > 0 && (
          <ViewerComponent numPages={numPages} {...props} />
        )}
      </Document>
    </Box>
  )
})

const MessageComponent: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Center w="full" h="full">
      <p>{message}</p>
    </Center>
  )
}

// 前後1ページのみ読み込み可能とする
const getLoadablePagesFromTarget = (targetPage: number, numPages: number) => {
  return [targetPage - 1, targetPage, targetPage + 1].filter(
    n => n > 0 && n <= numPages,
  )
}

const ViewerComponent: React.FC<Props & { numPages: number }> = props => {
  const {
    numPages,
    locationPath,
    trialHospitalUid,
    actorType,
    showThumbnail = true,
  } = props

  const zoomRatio = useBreakpointValue({ base: 0.8, sm: 1 }) ?? 1

  const [scale, setScale] = useState(1)
  const [focusedPageNum, setFocusedPageNum] = useState<number>(1)
  const [loadablePages, setLoadablePages] = useState<number[]>(
    getLoadablePagesFromTarget(1, numPages),
  )
  const [currentLocations, setCurrentLocations] = useState<
    Record<number, CurrentLocation[]>
  >({})
  const [showPointButton, setShowPointButton] = useState<boolean>(false)

  // 現在ページの送信を遅延させるためのタイマー
  const [, setSendTimeout] = useState<NodeJS.Timeout | null>(null)

  const pageNumbers = Array.from(new Array(numPages), (_, index) => index + 1)

  // Thumbnail, Pageのref。ページ番号をキーにしている
  const thumbnailRefs = useRef<Record<number, HTMLDivElement>>({})
  const pageRefs = useRef<Record<number, HTMLDivElement>>({})

  const thumbnailsContainerRef = useRef<HTMLDivElement | null>(null)
  const pagesContainerRef = useRef<HTMLDivElement | null>(null)

  const showThumbnailMenu = useMemo(() => {
    if (actorType !== 'Member') {
      return false
    }
    if (props.isPartner) {
      return false
    }
    // ページ数が1ページ以下なら表示なし
    if (numPages <= 1) {
      return false
    }
    // CurrentLocation情報が1つもなければ表示なし
    const sumOfCurrentLocations = Object.values(currentLocations).reduce(
      (acc, cur) => acc + cur.length,
      0,
    )
    return sumOfCurrentLocations > 0
  }, [actorType, currentLocations, numPages, props])

  const onChangeScale = (action: 'zoomIn' | 'zoomOut') => {
    setScale(current => {
      let newVal = Math.floor(current * 10)
      newVal = action === 'zoomIn' ? newVal + 1 : newVal - 1
      newVal = newVal / 10
      if (newVal < 0.1) return current
      if (newVal >= 3.1) return current
      return newVal
    })
  }

  const { send: sendCurrentLocation } = useSendCurrentLocation({
    path: locationPath,
    page: 1,
    sendOnLanding: true,
  })

  const onThumbnailClicked = async (pageNumber: number) => {
    setFocusedPageNum(pageNumber)
    setLoadablePages(prev =>
      uniqueArray([
        ...prev,
        ...getLoadablePagesFromTarget(pageNumber, numPages),
      ]),
    )
    setShowPointButton(false)
    pageRefs.current[pageNumber].scrollIntoView({
      block: 'start',
    })
  }

  const onMoveFocus = useCallback(
    async (pageNumber: number) => {
      setFocusedPageNum(pageNumber)
      setShowPointButton(false)
      // 現在ページ送信を500ms遅延させる。すでにセットされたタイマーがあればキャンセルする
      setSendTimeout(prev => {
        if (prev) {
          clearTimeout(prev)
        }
        return setTimeout(async () => {
          await sendCurrentLocation({
            page: pageNumber,
          })
        }, 500)
      })
    },
    [sendCurrentLocation],
  )

  // 連続でスクロールイベントが発生場合は処理を間引いて(debounce)最後の処理のみ実行する
  const handlePageScroll = useDebouncedCallback(async () => {
    if (!pagesContainerRef.current) {
      return
    }
    const container = pagesContainerRef.current
    const containerRect = container.getBoundingClientRect()
    const containerCenterY = containerRect.top + containerRect.height / 2
    const activePageNum = pageNumbers.find(pageNum => {
      const page = pageRefs.current[pageNum]
      if (!page) {
        return false
      }
      const pageRect = page.getBoundingClientRect()
      return (
        pageRect.top < containerCenterY && containerCenterY < pageRect.bottom
      )
    })
    if (!activePageNum) {
      return
    }
    setLoadablePages(prev =>
      uniqueArray([
        ...prev,
        ...getLoadablePagesFromTarget(activePageNum, numPages),
      ]),
    )

    await onMoveFocus(activePageNum)
  }, 100)

  useEffect(() => {
    const container = pagesContainerRef.current
    if (!container) {
      return
    }
    container.addEventListener('scroll', handlePageScroll)
    return () => {
      container.removeEventListener('scroll', handlePageScroll)
    }
  }, [handlePageScroll])

  // サムネイルのスクロールを制御
  useEffect(() => {
    if (!thumbnailsContainerRef.current) return
    const activeThumbnail = thumbnailRefs.current[focusedPageNum]
    if (!activeThumbnail) return

    const containerRect = thumbnailsContainerRef.current.getBoundingClientRect()
    const activeThumbnailRect = activeThumbnail.getBoundingClientRect()
    if (activeThumbnailRect.top < containerRect.top) {
      thumbnailsContainerRef.current.scrollTo({
        top: activeThumbnail.offsetTop - 32,
        behavior: 'smooth',
      })
    } else if (activeThumbnailRect.bottom > containerRect.bottom) {
      thumbnailsContainerRef.current.scrollTo({
        top:
          activeThumbnail.offsetTop +
          activeThumbnailRect.height -
          containerRect.height +
          32,
        behavior: 'smooth',
      })
    }
  }, [focusedPageNum])

  const { getCurrentLocationsByPath } = useCurrentLocations()

  useEffect(() => {
    const locations = getCurrentLocationsByPath({
      path: locationPath,
    })
    setCurrentLocations(
      locations.reduce<Record<number, CurrentLocation[]>>((acc, cur) => {
        if (!cur.page) {
          return acc
        }
        if (!acc[cur.page]) {
          acc[cur.page] = []
        }
        acc[cur.page] = [...acc[cur.page], cur]
        acc[cur.page].sort((a, b) => {
          // Theirs -> Ours の順に並べる
          if (a.actorSide === 'Theirs' && b.actorSide === 'Ours') {
            return -1
          }
          if (a.actorSide === 'Ours' && b.actorSide === 'Theirs') {
            return 1
          }
          return 0
        })
        // 同一サムネイルに対して6人までしか表示しない
        acc[cur.page] = acc[cur.page].slice(0, 6)
        return acc
      }, {}),
    )
  }, [actorType, getCurrentLocationsByPath, locationPath, trialHospitalUid])

  const toast = useMirohaToast()

  usePointLocations({
    path: locationPath,
    listener: async loc => {
      const newPage = loc.page
      if (!newPage || newPage === focusedPageNum) {
        return
      }
      toast({
        status: 'success',
        title: `${loc.displayName}がページ${loc.page}に移動させました`,
      })
      setLoadablePages(prev =>
        uniqueArray([
          ...prev,
          ...getLoadablePagesFromTarget(newPage, numPages),
        ]),
      )
      pageRefs.current[newPage].scrollIntoView({
        block: 'center',
      })
    },
  })

  const { send: sendPointLocation } = useSendPointLocation({
    path: locationPath,
  })

  const onClickPoint = useCallback(
    async ({ page }: { page: number }) => {
      if (actorType !== 'Member') {
        return
      }
      await sendPointLocation({
        trialHospitalUid,
        actorType: 'Member',
        trialMemberUid: props.trialMemberUid,
        page,
      })
    },
    [actorType, props, sendPointLocation, trialHospitalUid],
  )

  const pageHeight = 840 * zoomRatio

  return (
    <Flex position="relative" direction="row" h="full" w="full" bg="gray.200">
      <Box
        ref={thumbnailsContainerRef}
        overflow="scroll"
        h="full"
        w={showThumbnail ? '176px' : 0}
        bg="gray.50"
        visibility={showThumbnail ? undefined : 'hidden'}
      >
        <VStack py="24px" spacing="24px" h="full">
          {pageNumbers.map(pageNum => {
            const focused = pageNum === focusedPageNum
            return (
              <VStack spacing="4px" align="center" key={`thumbnail_${pageNum}`}>
                <Box
                  position="relative"
                  border={focused ? '4px solid' : ''}
                  borderColor={focused ? 'green.400' : ''}
                  ref={el => el && (thumbnailRefs.current[pageNum] = el)}
                >
                  <Thumbnail
                    width={96}
                    pageNumber={pageNum}
                    onItemClick={() => onThumbnailClicked(pageNum)}
                    onLoadError={console.error}
                  />
                  {!!currentLocations[pageNum] &&
                    currentLocations[pageNum].map((loc, index) => (
                      <Tag
                        key={`current_location_${loc.actorUid}`}
                        fontSize="10px"
                        fontWeight="700"
                        display="block"
                        color={
                          loc.actorSide === 'Theirs'
                            ? 'yellow.800'
                            : 'green.800'
                        }
                        bgColor={
                          loc.actorSide === 'Theirs'
                            ? 'yellow.100'
                            : 'green.400'
                        }
                        borderRadius="2px"
                        position="absolute"
                        p="6px"
                        top={`${index * 26}px`}
                        left="30%"
                        width="96px"
                        height="18px"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {loc.displayName}
                      </Tag>
                    ))}
                  {focused && showThumbnailMenu && (
                    <>
                      {showPointButton ? (
                        <Button
                          size="xs"
                          colorScheme="green"
                          position="absolute"
                          bottom="4px"
                          left="0"
                          right="0"
                          margin="auto"
                          w="72px"
                          h="26px"
                          opacity="0.8"
                          onClick={() => onClickPoint({ page: pageNum })}
                        >
                          ここに移動
                        </Button>
                      ) : (
                        <Box
                          as="button"
                          onClick={() => setShowPointButton(true)}
                          position="absolute"
                          left="2"
                          bottom="1"
                          opacity="0.8"
                          color="green.500"
                          _hover={{ color: 'green.600' }}
                          _active={{ color: 'green.700' }}
                        >
                          <NavigateArrow size="28px" />
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <Box
                  bgColor={focused ? 'green.500' : ''}
                  borderRadius="2px"
                  px="6px"
                >
                  <Text fontWeight={500} color={focused ? 'white' : ''}>
                    {pageNum}
                  </Text>
                </Box>
              </VStack>
            )
          })}
        </VStack>
      </Box>

      <Box overflow="scroll" py="6" flex="2" ref={pagesContainerRef}>
        {/* サムネイルを表示しない場合は現在のページを表示 */}
        {!showThumbnail && (
          <Flex
            align="center"
            position="absolute"
            px="16px"
            py="8px"
            top="8px"
            left="8px"
            bg="gray.50"
            borderRadius="4px"
            opacity={0.5}
            zIndex={1}
            gap={3}
          >{`${focusedPageNum} / ${numPages}`}</Flex>
        )}
        <Flex
          align="center"
          p="8px"
          position="absolute"
          top="8px"
          right="8px"
          bg="gray.700"
          borderRadius="4px"
          opacity={0.8}
          zIndex={1}
          gap={3}
        >
          <Button
            aria-label="zoom out"
            colorScheme="gray"
            onClick={() => onChangeScale('zoomOut')}
            minW="unset"
            variant="ghost"
            borderRadius="base"
            w="32px"
            h="32px"
            p="0"
            color="white"
            _hover={{ '@media(hover: hover)': { bg: 'gray.500' } }}
            _active={{ bg: 'gray.500' }}
          >
            <ScaleDown />
          </Button>
          <Text
            display={{ base: 'none', sm: 'block' }}
            color="white"
          >{`${Math.floor(scale * 100)}%`}</Text>
          <Button
            aria-label="zoom out"
            colorScheme="gray"
            onClick={() => onChangeScale('zoomIn')}
            minW="unset"
            variant="ghost"
            borderRadius="base"
            w="32px"
            h="32px"
            p="0"
            color="white"
            _hover={{ '@media(hover: hover)': { bg: 'gray.500' } }}
            _active={{ bg: 'gray.500' }}
          >
            <ScaleUp />
          </Button>
        </Flex>
        {/* NOTE: page数が多い場合scaleをPageコンポーネントのpropsとして定義するとクラッシュするケースがあるので親要素のscaleを設定する */}
        <VStack spacing="6" h="full" transform={`scale(${scale * zoomRatio})`}>
          {pageNumbers.map(pageNum => {
            return (
              <Box
                key={`page_${pageNum}`}
                ref={el => el && (pageRefs.current[pageNum] = el)}
                h={`${pageHeight}px`}
              >
                {loadablePages.includes(pageNum) ? (
                  <Page
                    pageNumber={pageNum}
                    loading=""
                    onLoadError={console.error}
                    height={pageHeight}
                  />
                ) : (
                  <Center bg="transparent" w="400px" h={`${pageHeight}px`}>
                    <Spinner />
                  </Center>
                )}
              </Box>
            )
          })}
        </VStack>
      </Box>
    </Flex>
  )
}
