import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import MirohaLogoFull from 'src/assets/image/miroha-logo-full.svg'
import { Button } from 'src/components/base/button/button'
import { blue, white } from 'src/components/base/color/palette'
import { Message } from 'src/components/base/message/message'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { LOOSE_EMAIL_REGX } from 'src/modules/auth/common/const'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'
import * as yup from 'yup'

import { verifyForgotPassword } from './request'

interface IProps {
  next: () => void
  setUserId: (userId: string) => void
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('メールアドレスを入力して下さい')
    .matches(LOOSE_EMAIL_REGX, '適切な形式のメールアドレスを入力して下さい'),
})

type IFormValues = yup.InferType<typeof validationSchema>

export const ForgetPassword: React.FC<IProps> = ({ next, setUserId }) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { register, handleSubmit, formState } = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = async ({ email }: IFormValues) => {
    try {
      requestStarted()
      await verifyForgotPassword(email)
      setUserId(email)
      next()
    } catch (_error) {
      requestFailed('存在しないメールアドレスです。')
    } finally {
      requestDone()
    }
  }

  return (
    <PageContainer>
      <img src={MirohaLogoFull} alt="MiROHA icon" />
      <Spacer size={60} />
      <ContentContainerForm onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize="lg" fontWeight="bold">
          パスワードリセット
        </Text>

        <Spacer size={40} />
        <Text fontSize="sm">
          パスワードリセット用の確認コードを送信しますので、登録しているメールアドレスをご入力ください。
        </Text>

        <Spacer size={20} />

        <FormItem>
          <Label bold>メールアドレス</Label>
          <Spacer size={10} />
          <Input
            type="email"
            width={1}
            placeholder="example@miroha.co"
            {...register('email')}
          />

          {errors.email && touchedFields.email && errors.email.message && (
            <div>
              <Spacer size={8} />
              <Message type="error" message={errors.email.message} />
            </div>
          )}
        </FormItem>

        {errorMessage && (
          <div>
            <Spacer size={8} />
            <Message type="error" message={errorMessage} centered />
          </div>
        )}

        <Spacer size={40} />

        <Button
          size="S"
          text="確認コードの送信"
          onClick={() => handleSubmit(onSubmit)}
          buttonType="important"
          disabled={!isValid || requesting}
        ></Button>
      </ContentContainerForm>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${blue[5]};
`

const ContentContainerForm = styled.form`
  width: 80%;
  max-width: 600px;
  padding: 60px 40px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${white};
  border-radius: 10px;
`

const FormItem = styled.div`
  width: 100%;
`
