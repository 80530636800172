import { Buffer } from 'buffer'

import React from 'react'

import dayjs from 'dayjs'
import { createRoot } from 'react-dom/client'
import { pdfjs } from 'react-pdf'

import App from './App'
import { initialize as initializeAmplify } from './lib/aws-amplify'
import { initialize as initializeSentry } from './lib/sentry'
import './global.css'
import { IS_MOCK_MODE } from './modules/util/feature-flag'
import { worker as MockServiceWorker } from './tests/mocks/browser'

import 'dayjs/locale/ja'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

initializeSentry()
initializeAmplify()

dayjs.locale('ja')
globalThis.Buffer = Buffer

if (IS_MOCK_MODE) {
  MockServiceWorker.start()
}

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
