import { QuestionnaireChoice } from './entity'

export type QuestionnaireChoiceResponse = {
  uid: string
  index: number
  description: string
  value: string
}

export const parse = (
  res: QuestionnaireChoiceResponse,
): QuestionnaireChoice => {
  return {
    uid: res.uid,
    index: res.index,
    description: res.description,
    value: res.value,
  }
}
