import { MediumItemAnswerLogAction } from '../dashboard/trial/detail/patient/detail/worksheet/common/medium-item-answer-log/entity'

export const MediumItemAnswerLogActionToText = (
  action: MediumItemAnswerLogAction,
) => {
  switch (action) {
    case MediumItemAnswerLogAction.Confirm:
      return '確定'
    case MediumItemAnswerLogAction.Save:
      return '一時保存'
    case MediumItemAnswerLogAction.Enable:
      return '有効化'
    case MediumItemAnswerLogAction.Disable:
      return '無効化'
    case MediumItemAnswerLogAction.EnableWithWorksheet:
      return '有効化：all'
    case MediumItemAnswerLogAction.DisableWithWorksheet:
      return '無効化：all'
  }
}
