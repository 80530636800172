import { Flex, HStack, Text } from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { Member } from 'src/features/member/types'
import { getFullName } from 'src/utils/getFullName'

import { ExplanationBrowserRole } from '../../../../lib/gql-client'
import { ExplanationSignerRole } from '../../types'
import { explanationSignerRoleToText } from '../../utils/explanationSignerRoleToText'

type Props = {
  signerRole?: ExplanationSignerRole
  browserRole?: ExplanationBrowserRole
  candidateId?: string
  member?: Member
  isPartner: boolean
  isRejected?: boolean
}
// 無駄に共通化されていて見通しが悪いので要リファクタ
export const ExplanationMemberLabel: React.FC<Props> = ({
  signerRole,
  browserRole,
  candidateId,
  member,
  isPartner,
  isRejected,
}) => {
  return (
    <Flex alignItems="center" w="full">
      <Text verticalAlign="center" w="full">
        {(signerRole === 'Patient' ||
          browserRole === 'PatientOrRepresentative') && (
          <HStack spacing="1.5">
            <Text
              as="span"
              sx={{ marginRight: '1.5' }}
            >{`${explanationSignerRoleToText(signerRole ?? 'Patient')}`}</Text>
            <Text
              as="span"
              sx={{ marginRight: '1' }}
            >{`候補ID: ${candidateId}`}</Text>
          </HStack>
        )}
        {!isRejected && !!member && (
          <MemberLabel
            role={member.role}
            displayName={getFullName(member)}
            isPartner={isPartner}
          />
        )}
      </Text>
    </Flex>
  )
}
