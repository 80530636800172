import React from 'react'

import { Information } from '../../components/information'

export const NotFoundError: React.FC = () => {
  return (
    <Information body="ページが見つかりませんでした。お手数ですが、URLをご確認ください。" />
  )
}

export const SessionHasBeenExpired: React.FC = () => {
  return (
    <Information body="説明ルームが有効期限切れです。お手数ですが、説明ロビーから入室しなおしてください。" />
  )
}
