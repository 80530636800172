import React from 'react'

import { useSelector } from 'react-redux'

import { VisibilityBulkSetModalComponent } from './visibility-bulk-set-modal-component'
import { getTrialHospitalsByUid } from '../../../../../../../../entities/account/selector'
import { RootState } from '../../../../../../../../reducer'
import { getState } from '../../selector'
import { useOnChangeMultiselect } from '../use-on-change-multiselect'
import { useUpdateSmallItemVisibility } from '../use-update-small-item-visibility'

type Props = {
  onClose: () => void
}

export const VisibilityBulkSetModalContainer: React.FC<Props> = props => {
  const { onClose } = props

  const template = useSelector((state: RootState) => getState(state))
  const trialHospitalsByUid = useSelector(getTrialHospitalsByUid)
  const { request, requesting, errorMessage } = useUpdateSmallItemVisibility()

  const {
    values: selectedTrialHospitalUids,
    handlers: { onChangeMultiselect: onChangeTrialHospitals },
  } = useOnChangeMultiselect([])

  const {
    values: selectedSmallItemUids,
    handlers: { onChangeMultiselect: onChangeSmallItems },
  } = useOnChangeMultiselect([])

  const onSubmit = () => {
    const submit = async () => {
      const { originalTemplate } = template
      if (!originalTemplate) return

      try {
        await request({
          trialUid: originalTemplate.trialUid,
          templateUid: originalTemplate.uid,
          smallItemUids: selectedSmallItemUids,
          trialHospitalUids: selectedTrialHospitalUids,
        })

        onClose()
      } catch (error) {
        console.error('update smallItemVisibility Error', error)
      }
    }

    submit()
  }

  return (
    <VisibilityBulkSetModalComponent
      mediumItems={template.mediumItems}
      trialHospitalsByUid={trialHospitalsByUid}
      selectedSmallItemUids={selectedSmallItemUids}
      selectedTrialHospitalUids={selectedTrialHospitalUids}
      requesting={requesting}
      handlers={{
        onChangeSmallItems: onChangeSmallItems,
        onChangeTrialHospitals: onChangeTrialHospitals,
        onClose: onClose,
        onSubmit: onSubmit,
      }}
      errorMessage={errorMessage}
    ></VisibilityBulkSetModalComponent>
  )
}
