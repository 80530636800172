import { SERVER_URL } from 'src/modules/server/const'
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import {
  CreateIcfDocumentItem,
  IcfDocumentForList,
  IcfDocumentRevision,
  IcfDocumentDetail,
  NextIcfDocumentRevisionTemplate,
  CreateIcfDocumentRevisionItem,
  IcfDocumentType,
} from './entity'
import {
  icfDocumentListFixture,
  icfDocumentRevisionFixture1,
  icfDocumentRevisionFixture2,
  icfDocumentDetailFixture,
} from './fixture'

export const fetchIcfDocumentList = async ({
  trialUid,
  trialHospitalUid,
  type,
}: {
  trialUid: string
  trialHospitalUid: string
  type: 'latest' | 'approved'
}): Promise<IcfDocumentForList[]> => {
  if (IS_MOCK_MODE) {
    return icfDocumentListFixture
  }

  const url =
    type === 'approved'
      ? `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents?type=approved`
      : `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents`
  const res = await requestGet<IcfDocumentForList[]>({ url })

  return res.data
}

type TempFileResponse = {
  tempFileUid: string
  fileUrl: string
}
export const uploadTempFile = async ({
  trialUid,
  trialHospitalUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  params: FormData
}): Promise<TempFileResponse> => {
  if (IS_MOCK_MODE) {
    return {
      tempFileUid: '',
      fileUrl: '',
    }
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/temp`
  const res = await requestPost<TempFileResponse>({ url, params })

  return res.data
}

type UploadDocuSignFileParam = {
  type: IcfDocumentType
  formData: FormData
}

type DocuSignResponse = TempFileResponse & {
  dsTemplateId: string
}
export const uploadDocuSignFile = async ({
  trialUid,
  trialHospitalUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  params: UploadDocuSignFileParam
}): Promise<DocuSignResponse> => {
  if (IS_MOCK_MODE) {
    return {
      tempFileUid: '',
      fileUrl: '',
      dsTemplateId: '',
    }
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/docusign?type=${params.type}`
  const res = await requestPost<DocuSignResponse>({
    url,
    params: params.formData,
  })

  return res.data
}

export const getFilePreviewRequestUrl = ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
}) => {
  return `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/file`
}

export const bulkCreateIcfDocument = async ({
  trialUid,
  trialHospitalUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  params: CreateIcfDocumentItem[]
}) => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/bulk`
  await requestPost({ url, params })
}

export const fetchIcfDocument = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentUid: string
}): Promise<IcfDocumentDetail> => {
  if (IS_MOCK_MODE) {
    return icfDocumentDetailFixture
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/${icfDocumentUid}`
  const res = await requestGet<IcfDocumentDetail>({ url })

  return res.data
}

export const deleteIcfDocument = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentUid: string
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/${icfDocumentUid}`
  await requestDelete({ url })
}

export const fetchIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
}): Promise<IcfDocumentRevision> => {
  if (IS_MOCK_MODE) {
    return (
      [icfDocumentRevisionFixture1, icfDocumentRevisionFixture2].find(
        revision => revision.uid === icfDocumentRevisionUid,
      ) ?? icfDocumentRevisionFixture1
    )
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}`
  const res = await requestGet<IcfDocumentRevision>({ url })

  return res.data
}

export type UpdateIcfDocumentRevisionParam = Pick<
  IcfDocumentRevision,
  'version' | 'name' | 'fileName' | 'type' | 'isRequiredPatientSign'
> & {
  tempFileUid?: string
  dsTemplateId?: string
}
export const updateIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: UpdateIcfDocumentRevisionParam
}): Promise<IcfDocumentRevision> => {
  if (IS_MOCK_MODE) {
    return { ...icfDocumentRevisionFixture1, ...params }
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}`
  const res = await requestPut<IcfDocumentRevision>({ url, params })

  return res.data
}

type WithCommentParams = {
  comment: string
}

export const disableIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: WithCommentParams
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/disable`
  await requestPut({ url, params })
}

export const enableIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: WithCommentParams
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/enable`
  await requestPut({ url, params })
}

export const requestIcfDocumentRevisionApproval = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: WithCommentParams
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/request_approval`
  await requestPut({ url, params })
}

export const approveIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: WithCommentParams
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/approve`
  await requestPut({ url, params })
}

export const rejectIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: WithCommentParams
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/reject`
  await requestPut({ url, params })
}

export const withdrawIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
  params: WithCommentParams
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/withdraw`
  await requestPut({ url, params })
}

export const fetchNextRevisionTemplate = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentUid: string
}): Promise<NextIcfDocumentRevisionTemplate> => {
  if (IS_MOCK_MODE) {
    return {
      ...icfDocumentRevisionFixture1,
      version: '2.0',
    }
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_documents/${icfDocumentUid}/next_revision`
  const res = await requestGet<NextIcfDocumentRevisionTemplate>({ url })

  return res.data
}

export const createIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  params,
}: {
  trialUid: string
  trialHospitalUid: string
  params: CreateIcfDocumentRevisionItem
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions`
  await requestPost({ url, params })
}

export const cancelIcfDocumentRevision = async ({
  trialUid,
  trialHospitalUid,
  icfDocumentRevisionUid,
}: {
  trialUid: string
  trialHospitalUid: string
  icfDocumentRevisionUid: string
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/icf_document_revisions/${icfDocumentRevisionUid}/cancel`
  await requestPut({ url })
}

export const completeEditDocuSignTemplate = async ({
  trialUid,
  trialHospitalUid,
  dsTemplateId,
}: {
  trialUid: string
  trialHospitalUid: string
  dsTemplateId: string
}): Promise<void> => {
  if (IS_MOCK_MODE) {
    return
  }

  const url = `${SERVER_URL}/trials/${trialUid}/trial_hospitals/${trialHospitalUid}/docusign_templates/${dsTemplateId}/complete`
  await requestPut({ url })
}

type VideoStreamResponse = {
  url: string
}

export const getVideoStreamUrl = async ({
  trialUid,
  icfDocumentRevisionUid,
}: {
  trialUid: string
  icfDocumentRevisionUid: string
}): Promise<string> => {
  if (IS_MOCK_MODE) {
    return 'https://example.com/video-stream.m3u8'
  }

  const url = `${SERVER_URL}/trials/${trialUid}/icf_document_revisions/${icfDocumentRevisionUid}/video_stream_url`
  const res = await requestGet<VideoStreamResponse>({ url })

  return res.data.url
}
