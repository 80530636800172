import React, { ReactElement, useEffect } from 'react'

import { Box, useDisclosure } from '@chakra-ui/react'
import ReactSelect, {
  StylesConfig,
  Theme as ReactSelectTheme,
  SingleValueProps,
  MenuListProps,
  DropdownIndicatorProps,
  components,
} from 'react-select'
import { colors } from 'src/lib/chakra-theme/foundations/colors'

type ValueTypeBase = string | number | undefined
type LabelTypeBase = string | React.ReactNode

export type SelectItem<
  ValueType extends ValueTypeBase = string,
  LabelType extends LabelTypeBase = string,
> = {
  value: ValueType
  label: LabelType
  /** 検索時のフィルタリングに使用する文字列 入力値が与えた文字列を含む場合に絞り込みの対象となる */
  filterValue?: string
}

// clearableの場合はundefinedに変更できるようにする
type OnChange<
  ValueType extends ValueTypeBase,
  Clearable extends boolean,
> = Clearable extends true
  ? (value: ValueType | undefined) => void
  : (value: ValueType) => void

type Props<
  ValueType extends ValueTypeBase,
  LabelType extends LabelTypeBase,
  Clearable extends boolean = false,
> = {
  items: SelectItem<ValueType, LabelType>[]
  value: ValueType | undefined
  onChange: OnChange<ValueType, Clearable>
  onFocus?: () => void
  onBlur?: () => void
  placeholder?: string
  unselectText?: string
  isSearchable?: boolean
  isClearable?: Clearable
  isDisabled?: boolean
  isInvalid?: boolean
  width?: number | string
}

export const Select = <
  ValueType extends ValueTypeBase,
  LabelType extends LabelTypeBase,
  Clearable extends boolean = false,
>({
  items,
  onChange,
  onFocus,
  onBlur,
  value,
  placeholder,
  unselectText,
  isSearchable,
  isDisabled,
  isInvalid,
  width,
  isClearable,
}: Props<ValueType, LabelType, Clearable>): ReactElement => {
  const menu = useDisclosure()

  // themeを上書きしてMiROHAで適用する色が表示されるようにする
  // https://react-select.com/styles#overriding-the-theme
  const customTheme = (theme: ReactSelectTheme): ReactSelectTheme => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.blue[500], // borderColor for control(:focus, :hover)
        primary25: 'none',
        neutral10: colors.gray[200], // borderColor for control and options
        neutral20: colors.gray[200], // borderColor for control(:hover) *same as neutral10
        neutral50: colors.gray[200], // color for placeholder
      },
    }
  }

  // styleの上書き・themeの上書きでカバーできない部分も定義
  // https://react-select.com/styles#styles
  const customStyles: StylesConfig<SelectItem<ValueType, LabelType>, false> = {
    container: provided => {
      return {
        ...provided,
        whiteSpace: 'pre-line',
        minHeight: 40,
        boxSizing: 'border-box',
        width: width ?? provided.width,
      }
    },
    control: (provided, { isFocused }) => {
      return {
        ...provided,
        minHeight: 40,
        boxSizing: 'border-box',
        fontSize: '1rem',
        cursor: 'pointer',
        backgroundColor: isInvalid ? colors.red[50] : provided.backgroundColor,
        borderWidth: isInvalid || isFocused ? 2 : provided.borderWidth,
        borderColor: isInvalid ? colors.red[500] : provided.borderColor,
        borderRadius: '0.375rem', // same as chakra border-radius md
        boxShadow: 'none',
        ':hover': {
          borderColor: isInvalid
            ? colors.red[500]
            : isFocused
              ? colors.blue[500]
              : colors.gray[400],
        },
      }
    },
    indicatorSeparator: provided => {
      return { ...provided, display: 'none' }
    },
    indicatorsContainer: provided => {
      return {
        ...provided,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    },
    option: (provided, { isSelected }) => {
      return {
        ...provided,
        fontSize: '1rem',
        color: colors.gray[800],
        cursor: 'pointer',
        backgroundColor: isSelected
          ? colors.blue[50]
          : provided.backgroundColor,
        ':hover': {
          backgroundColor: isSelected ? colors.blue[50] : colors.gray[50],
        },
        ':active': {
          backgroundColor: isSelected ? colors.blue[50] : colors.gray[100],
        },
      }
    },
    noOptionsMessage: provided => {
      return { ...provided, fontSize: 14 }
    },
    menuList: provided => {
      return {
        ...provided,
        padding: 8,
        borderRadius: '0.375rem',
      }
    },
    menu: provided => {
      return {
        ...provided,
        marginTop: 1,
      }
    },
    valueContainer: provided => {
      return {
        ...provided,
        paddingLeft: 14,
      }
    },
    singleValue: provided => {
      return {
        ...provided,
        whiteSpace: 'pre-line',
      }
    },
  }

  // clearableがtrueの場合undefinedを引数に取れることはPropsの型で保証している
  const isClearableOnChange = <T extends ValueTypeBase>(
    f: OnChange<T, boolean>,
  ): f is (value: T | undefined) => void => {
    return isClearable === true
  }

  // 独自アイコンを表示
  // https://react-select.com/components
  const DropdownIndicator = (
    props: DropdownIndicatorProps<SelectItem<ValueType, LabelType>, false>,
  ) => (
    <components.DropdownIndicator {...props}>
      <IndicatorSvg />
    </components.DropdownIndicator>
  )

  const MenuList = ({
    children,
    ...props
  }: MenuListProps<SelectItem<ValueType, LabelType>, false>) => (
    <components.MenuList {...props}>
      {isClearable && isClearableOnChange(onChange) && (
        <Box
          cursor="pointer"
          fontSize="sm"
          px="2"
          py="3"
          color="gray.500"
          _hover={{ color: 'gray.600' }}
          onClick={() => {
            props.setValue(null, 'deselect-option')
          }}
        >
          {unselectText ?? '選択してください'}
        </Box>
      )}
      {children}
    </components.MenuList>
  )

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<SelectItem<ValueType, LabelType>>) => {
    const _value = value

    useEffect(() => {
      if (!_value) {
        props.setValue(null, 'deselect-option')
      }
    }, [_value, props])

    return (
      <components.SingleValue {...props}>{children}</components.SingleValue>
    )
  }

  return (
    <ReactSelect
      options={items}
      value={items.find(item => item.value === value)}
      onChange={item => {
        if (item === null) {
          isClearableOnChange(onChange) && onChange(undefined)
          return
        }
        onChange(item.value)
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      isSearchable={isSearchable ?? false}
      placeholder={placeholder ?? '選択してください'}
      theme={customTheme}
      styles={customStyles}
      menuIsOpen={menu.isOpen}
      onMenuOpen={menu.onOpen}
      onMenuClose={menu.onClose}
      formatOptionLabel={option => {
        if (typeof option.label === 'string') {
          return option.label
        }
        return <Box ml={isSearchable ? '1' : '0'}>{option.label}</Box>
      }}
      filterOption={(option, input) => {
        if (!isSearchable) {
          return true
        }
        const filterValue = option.data?.filterValue ?? option.label
        if (typeof filterValue === 'string') {
          return filterValue.toLowerCase().includes(input.toLowerCase())
        }
        return true
      }}
      noOptionsMessage={() => 'データがありません'}
      isDisabled={isDisabled}
      components={{ DropdownIndicator, SingleValue, MenuList }}
      menuPlacement="auto"
      className="select"
      classNames={{
        control: () => 'select-control',
        singleValue: () => 'select-single-value',
      }}
    />
  )
}
const IndicatorSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9999 3.5H0.999938C0.599938 3.5 0.229938 3.74 0.0799376 4.12C-0.0700624 4.5 0.00993761 4.92 0.299938 5.21L7.29994 12.21C7.49994 12.41 7.74994 12.5 8.00994 12.5C8.26994 12.5 8.51994 12.4 8.71994 12.21L15.6899 5.24C15.8899 5.06 16.0099 4.8 16.0099 4.5C16.0099 3.95 15.5599 3.5 15.0099 3.5H14.9999Z"
        fill="#B1B1B1"
      />
    </svg>
  )
}
