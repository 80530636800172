import { Button } from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import { useSelectedTrial } from 'src/features/auth/context'

type Props = {
  referredPatientUid: string
}

export const CreateExplanationButton: React.FC<Props> = ({
  referredPatientUid,
}) => {
  const { selectedTrial } = useSelectedTrial()

  return (
    <Button
      as={Link}
      to={{
        pathname: generatePath(Paths.ExplanationsNew, {
          trialUid: selectedTrial.uid,
        }),
        search: `referredPatientUid=${referredPatientUid}`,
      }}
    >
      説明同意登録
    </Button>
  )
}
