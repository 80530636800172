import { Badge, BadgeProps } from '@chakra-ui/react'
import { ReferredPatientStatus } from 'src/lib/gql-client'

import { assertNever } from '../../../../utils/assertNever'

type Props = {
  status: ReferredPatientStatus
}

const statusBadgeProps: (
  status: ReferredPatientStatus,
) => BadgeProps = status => {
  switch (status) {
    case 'Created':
      return {
        bg: 'blue.200',
        color: 'blue.700',
        children: '新規',
      }
    case 'Saved':
      return {
        bg: 'yellow.200',
        color: 'yellow.700',
        children: '一時保存',
      }
    case 'Referred':
      return {
        bg: 'green.200',
        color: 'green.700',
        children: '紹介済',
      }
    case 'NotBrowsed':
      return {
        bg: 'blue.200',
        color: 'blue.700',
        children: '未閲覧',
      }
    case 'Awaiting':
      return {
        bg: 'green.200',
        color: 'green.700',
        children: '確認中',
      }
    case 'Browsed':
      return {
        bg: 'yellow.200',
        color: 'yellow.700',
        children: '閲覧済',
      }
    case 'Candidate':
      return {
        bg: 'green.200',
        color: 'green.700',
        children: '候補化',
      }
    case 'Excluded':
      return {
        bg: 'gray.100',
        color: 'gray.300',
        children: '除外',
      }
    case 'Deleted':
      // Not used
      return {
        children: '削除',
      }
    default:
      return assertNever(status)
  }
}

export const ReferredPatientStatusBadge: React.FC<Props> = ({ status }) => {
  if (status === 'Deleted') {
    return null
  }

  return (
    <Badge
      opacity="1"
      w="44px"
      h="18px"
      display="grid"
      alignContent="center"
      fontSize="10px"
      lineHeight="18px"
      textAlign="center"
      fontWeight="bold"
      px="unset"
      borderRadius="2px"
      {...statusBadgeProps(status)}
    />
  )
}
