import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { actions as accountActions } from 'src/modules/entities/account/redux'
import { release } from 'src/modules/entities/questionnaire-schedule/request'
import { useFlash } from 'src/modules/flash/use-flash'
import { useRequestState } from 'src/modules/server/use-request-state'

import { actions as questionnaireScheduleActions } from '../../redux'

export const useSubmitRelease = ({ trialUid }: { trialUid: string }) => {
  const { requesting, errorMessage, requestDone, requestStarted } =
    useRequestState()

  const { showSuccess, showError } = useFlash()
  const dispatch = useDispatch()
  const request = useCallback(async () => {
    try {
      requestStarted()

      await release({ trialUid })

      dispatch(questionnaireScheduleActions.releaseQuestionnaireSchedule())
      dispatch(accountActions.enableSelectTrialQuestionnaireScheduleReleased())
      showSuccess(`スケジュールを配信開始しました。`)
      requestDone()
    } catch (e) {
      showError(e.message, { closable: true, durationMsec: 6000 })
      requestDone()
      throw e
    }
  }, [dispatch, requestDone, requestStarted, showError, showSuccess, trialUid])
  return { request, requesting, errorMessage }
}
