import { GqlError, GetStickyQuery, GetStickyDocument } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { worksheetCacheKey } from '../cacheKey'

export type GetStickyReturn = GetStickyQuery['sticky']

const getSticky = async ({ stickyUid }: { stickyUid: string }) => {
  try {
    const res = await graphqlRequest(GetStickyDocument, {
      stickyUid,
    })
    return res.sticky
  } catch (error) {
    throw error
  }
}

export const useSticky = ({
  stickyUid,
  ...option
}: { stickyUid: string | null } & SWRConfiguration<
  GetStickyReturn,
  GqlError
>) => {
  return useSWR(
    stickyUid === null ? null : worksheetCacheKey.getSticky(stickyUid),
    () => {
      if (stickyUid === null) {
        throw new Error('stickyUid is null')
      }
      return getSticky({ stickyUid })
    },
    option,
  )
}
