import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useCurrentMember } from 'src/features/auth/context'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useConfirmWorksheet } from '../../api/confirmWorksheet'
import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { WorksheetDetail } from '../../types'

type Props = {
  worksheet: WorksheetDetail
  mutateWorksheet: (worksheet: WorksheetDetail) => void
}

export const ConfirmWorksheet: React.FC<Props> = ({
  worksheet,
  mutateWorksheet,
}) => {
  const { isChanged } = useWorksheetValueContext()
  const { currentMember } = useCurrentMember()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useMirohaToast()

  const { request: confirmWorksheet } = useConfirmWorksheet({
    onSuccess: confirmedWorksheet => {
      mutateWorksheet(confirmedWorksheet)
      onClose()
      toast({
        status: 'success',
        title: 'ワークシートを確定しました',
      })
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  // TODO: use permission definition
  if (currentMember.role !== 'Dr') {
    return null
  }

  if (worksheet.status === 'confirmed' || worksheet.status === 'disabled') {
    return null
  }

  return (
    <>
      <Button
        colorScheme="blue"
        isDisabled={isChanged || worksheet.status !== 'saved'}
        onClick={onOpen}
      >
        確定
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize="xl" fontWeight="bold">
              確定
            </Text>
          </ModalHeader>
          <ModalBody>
            <Text>ワークシートを確定します。</Text>
            <Text>必要な値がすべて入力されていることを確認してください。</Text>
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="blue"
              onClick={async () => {
                await confirmWorksheet({ worksheetUid: worksheet.uid })
              }}
            >
              確定する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
