import { Schema } from '@micin-jp/chicken-schema'
import { useAtomValue } from 'jotai'
import { useBlockTransition } from 'src/hooks/useBlockTransition'
import { editingSchemaAtom, isDeletedAtom } from 'src/lib/chicken-schema/atom'
import {
  equalIgnoreVersion,
  omitFalsyValidation,
} from 'src/lib/chicken-schema/utils'
import { CriteriaStatus } from 'src/lib/gql-client'

type Props = {
  fetchedSchema: Schema
  criteriaStatus: CriteriaStatus
}

export const CriteriaTransitionBlocker: React.FC<Props> = ({
  fetchedSchema,
  criteriaStatus,
}) => {
  const editingSchema = useAtomValue(editingSchemaAtom)
  const isChanged = !equalIgnoreVersion(
    omitFalsyValidation(editingSchema),
    omitFalsyValidation(fetchedSchema),
  )

  const isDeleted = useAtomValue(isDeletedAtom)

  const isEditingStatus =
    criteriaStatus === 'Created' || criteriaStatus === 'Saved'

  useBlockTransition({
    showAlert: isChanged && isEditingStatus && !isDeleted,
  })

  return null
}
