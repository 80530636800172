import React from 'react'

import { Text } from '@chakra-ui/react'
import { Button } from 'src/components/base/button/button'
import { Modal } from 'src/components/base/modal/modal'
import { ModalActions } from 'src/components/base/modal/modal-actions'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Spacer } from 'src/components/spacer/spacer'

type Props = {
  onClose: () => void
  onSubmitDelete: () => void
}

export const DeleteIcfDocumentModal: React.FC<Props> = ({
  onClose,
  onSubmitDelete,
}) => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle title="文書を削除" />
      <ModalContent>
        <Text textAlign="center">文書を削除します。よろしいですか？</Text>
      </ModalContent>
      <ModalActions>
        <Button
          size="S"
          text="キャンセル"
          buttonType="cancel"
          onClick={onClose}
        />
        <Spacer size={40} horizontal />
        <Button
          size="S"
          text="削除"
          buttonType="alert"
          onClick={onSubmitDelete}
        />
      </ModalActions>
    </Modal>
  )
}
