export const routes = {
  worksheet: 'worksheet',
}

export const getRoute = ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}) => {
  return `/dashboard/trials/${trialUid}/patients/${patientUid}`
}
