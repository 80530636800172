import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Button } from 'src/components/base/button/button'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'

import { EnableModal } from './container'
import { worksheetIdParamName } from '../worksheet-detail'

type Props = {
  edcLinked: boolean
}

export const EnableButton: React.FC<Props> = props => {
  const [modalOpen, setModalOpen] = useState(false)
  const { worksheetUid = '' } = useParams<{
    [worksheetIdParamName]: string
  }>()

  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: () => setModalOpen(false),
    deps: [worksheetUid],
  })
  // Todo：enable, disableフォルダの共通化（リファクタリング）
  return (
    <>
      <Button
        size="S"
        text="有効化"
        onClick={() => setModalOpen(true)}
        buttonType="normal"
      ></Button>

      {modalOpen && (
        <EnableModal
          edcLinked={props.edcLinked}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  )
}
