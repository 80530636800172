import { QuestionnaireAnswer, QuestionnaireAnswerAnswerStatus } from './entity'

export const statusString = ({
  questionnaireAnswer,
}: {
  questionnaireAnswer: QuestionnaireAnswer
}) => {
  switch (questionnaireAnswer.answerStatus) {
    case QuestionnaireAnswerAnswerStatus.Invalid:
      return '-'
    case QuestionnaireAnswerAnswerStatus.BeforeAnswer:
      return '回答開始前'
    case QuestionnaireAnswerAnswerStatus.Unanswered:
      return '回答予定'
    case QuestionnaireAnswerAnswerStatus.Answered:
      return '回答済み'
    case QuestionnaireAnswerAnswerStatus.Expired:
      return '未回答'
    default:
      const n: never = questionnaireAnswer.answerStatus
      throw n
  }
}
