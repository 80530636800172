import { createContext, useContext } from 'react'

import { Member } from 'src/features/member/types'
import { GetPatientReturn } from 'src/features/patient/api'

import { useWorksheetValue } from '../hooks/useWorksheetValue'
import { WorksheetDetail } from '../types'

type Context = ReturnType<typeof useWorksheetValue>

const context = createContext<Context | undefined>(undefined)

type ProviderProps = {
  fetchedWorksheet: WorksheetDetail
  children: React.ReactNode
  member: Member
  patient: GetPatientReturn
}

/** provider配下でuseWorksheetValueContextを呼び出すことでfieldの値の読み取り、更新を行える */
export const WorksheetValueProvider: React.FC<ProviderProps> = ({
  fetchedWorksheet,
  member,
  patient,
  children,
}) => {
  return (
    <context.Provider
      value={useWorksheetValue({
        fetchedWorksheet,
        member,
        patient,
      })}
    >
      {children}
    </context.Provider>
  )
}

/** ワークシートの値の読み取り、更新を行うためのhooks 実装の詳細はuseWorksheetValue */
export const useWorksheetValueContext = () => {
  const hooks = useContext(context)

  if (hooks === undefined) {
    throw new Error(
      'useWorksheetValueContext must be called inside WorksheetValueProvider',
    )
  }

  return hooks
}
