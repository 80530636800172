import React from 'react'

import { IconButton } from 'src/components/base/icon-button/icon-button'
import styled from 'styled-components'

import { useOpenModal } from '../../../../../../../../../hooks/use-open-modal'
import { UpdateTitleModalContainer } from '../modal/update-title-modal-container'

type Props = {
  templateUid: string
}

export const UpdateTitleButton: React.FC<Props> = props => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  return (
    <Wrapper>
      <IconButton iconName="edit" onClick={openModal} />
      {modalOpen && (
        <UpdateTitleModalContainer
          onClose={closeModal}
          templateUid={props.templateUid}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
