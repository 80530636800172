import { Gender } from 'src/modules/entities/patient/entity'
import { fixtureDetail as templateFixture } from 'src/modules/entities/template/fixture'
import { fixtures as templateFolderFixtures } from 'src/modules/entities/template-folder/fixture'
import {
  fixtureDetail as worksheetFixtureDetail,
  fixtureDetail2 as worksheetFixtureDetail2,
} from 'src/modules/entities/worksheet/fixture'

import {
  WorksheetPDFData,
  Patient,
  SmallItemAnswerLog,
  VideoCall,
  WorksheetLogs,
} from './entity'
import { MediumItemAnswerLog } from '../../../medium-item-answer-log/entity'

const trialName = 'トライアル治験1'

const patient: Patient = {
  uid: 'fooPatientUid',
  diseaseUid: 'fooDiseaseUid',
  firstName: '希',
  lastName: '江原',
  firstNameJa: 'のぞみ',
  lastNameJa: 'えはら',
  gender: Gender.Female,
  birthday: '2000-11-11',
}

const smallItemAnswerLogs: SmallItemAnswerLog[] = [
  {
    uid: 'fooSmallItemAnswerLogUid',
    smallItemAnswerUid: 'a',
    userName: '山田 太郎',
    oldValue: '不要',
    newValue: '必要',
    reason: '後日先生と相談のうえ用量を変更したため',
    sectionName: '通常のセクション',
    title: ' 変更理由',
    createdAt: '2020-01-01',
  },
  {
    uid: 'barSmallItemAnswerLogUid',
    smallItemAnswerUid: 'a',
    userName: '山田 太郎',
    oldValue: '不要',
    newValue: '必要',
    reason: '後日先生と相談のうえ用量を変更したため',
    title: ' 変更理由',
    sectionName: 'ログラインセクション1',
    lineNumber: 2,
    createdAt: '2020-01-01',
  },
]

const videoCallFixture: VideoCall = {
  uid: 'fooVideoCallUid',
  createdAt: '2020-02-19T14:36',
  finishedAt: '2020-02-19T14:36',
  callerName: '小林 敏夫',
  images: [
    {
      uid: 'fooVideoCallCaptureUid1',
      name: 'foo',
      url: 'https://via.placeholder.com/200x400',
    },
    {
      uid: 'fooVideoCallCaptureUid2',
      name: 'bar',
      url: 'https://via.placeholder.com/1024x600',
    },
  ],
}

const worksheetLogsFixtureDetail: WorksheetLogs = {
  uid: 'fooWorksheetLogsUid1',
  user_name: 'name1',
  action: 2,
  created_at: '2019-01-01T00:00:00',
  reason: '理由1',
}

const worksheetLogsFixtureDetail2: WorksheetLogs = {
  uid: 'fooWorksheetLogsUid2',
  user_name: 'name2',
  action: 2,
  created_at: '2019-01-01T00:00:00',
  reason: '理由2',
}

const loglineDisableLogsFixture: MediumItemAnswerLog[] = []

export const fixture: WorksheetPDFData = {
  uid: 'fooWorksheetPDFDataUid',
  trialName: trialName,
  patient: patient,
  smallItemAnswerLogs: smallItemAnswerLogs,
  worksheet: worksheetFixtureDetail,
  worksheetLog: worksheetLogsFixtureDetail,
  videoCalls: [videoCallFixture],
  loglineDisableLogs: loglineDisableLogsFixture,
  template: templateFixture,
  templateFolderName: templateFolderFixtures[1].name,
}

export const fixture2: WorksheetPDFData = {
  uid: 'barWorksheetPDFDataUid',
  trialName: trialName,
  patient: patient,
  smallItemAnswerLogs: smallItemAnswerLogs,
  worksheet: worksheetFixtureDetail2,
  worksheetLog: worksheetLogsFixtureDetail2,
  videoCalls: [videoCallFixture],
  loglineDisableLogs: loglineDisableLogsFixture,
  template: templateFixture,
  templateFolderName: templateFolderFixtures[1].name,
}
