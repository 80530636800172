import React, { useEffect, useState } from 'react'

import { Box, Text } from '@chakra-ui/react'
import { useRoomSession } from '@micin-jp/call-lib-react'
import { generatePath, useParams, useNavigate } from 'react-router-dom'
import { Paths } from 'src/constants/paths'
import {
  useAuthenticatedAccount,
  useCurrentMember,
} from 'src/features/auth/context'
import {
  CreateOrGetPreSessionRes,
  useCreateExplanationSession,
  useExplanation,
  useUpdatePreSessionIdentification,
} from 'src/features/explanation/api'
import { usePreSession } from 'src/features/explanation/hooks/usePreSession'
import { ExplanationRevision } from 'src/features/explanation/types'
import { getRoomFinishedRoute } from 'src/features/explanation/utils/getRoomFinishedRoute'
import { ExplanationRoomLobbyVideoCallContent } from 'src/features/explanationRoom/components/ExplanationRoomLobbyVideoCallContent/ExplanationRoomLobbyVideoCallContent'
import { useRoomMediaContext } from 'src/features/explanationRoom/context/MediaProvider'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { UID } from '../../../../../../../utils/brandedUid'
import { Information } from '../../components/information'
import { HospitalLobbySidebar } from '../components/hospital-lobby-sidebar'
import { ExplanationRoomLayout } from '../layout'
import { getNotFoundRoute } from '../routes'

type Param = {
  explanationRevisionUid: ExplanationRevision['uid']
}

export const HospitalLobbyContainer: React.FC<{}> = () => {
  const { explanationRevisionUid = '' as UID<'ExplanationRevision'> } =
    useParams<Param>()

  const { account } = useAuthenticatedAccount()

  const { leaveRoom } = useRoomSession()
  const { initializeMemberVideoCall } = useRoomMediaContext()

  const isPartner = (preSession: CreateOrGetPreSessionRes) => {
    return (
      preSession.explanationRevision.explanationType === 'RemotePartner' &&
      account.selectedTrialHospitalUid ===
        preSession.explanationRevision.partnerTrialHospital?.uid
    )
  }

  // session開始を待機中かどうか
  const [isWaitingSession, setIsWaitingSession] = useState(false)

  const topPath = (sessionUid: string) => {
    return generatePath(Paths.HospitalExplanationRoomTop, {
      trialUid: selectedTrial.uid,
      sessionUid,
    })
  }

  const {
    preSession,
    setupPreSessionError,
    mutate: mutatePreSession,
  } = usePreSession({
    isPatient: false,
    explanationRevisionUid,
    onSetup: preSession => {
      // revisionが無効な場合はNotFoundへ遷移
      if (preSession.explanationRevision.latestHistory.status === 'Disabled') {
        navigate(getNotFoundRoute({ type: 'hospital' }))
        return
      }
      if (isPartner(preSession) && !preSession.explanationSessionUid) {
        setIsWaitingSession(true)
      }
    },
    onAdditionalFetch: preSession => {
      // パートナー医療機関かつセッション作成済の場合自動的にトップ画面に遷移する
      // setup時は自動遷移はなし
      if (isWaitingSession && !!preSession.explanationSessionUid) {
        navigate(topPath(preSession.explanationSessionUid))
      }
    },
  })

  const { data: explanation } = useExplanation({
    explanationUid: preSession
      ? preSession.explanationRevision.explanationUid
      : null,
    detailed: false,
    refreshInterval: 10000,
  })

  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const isPartnerMember =
    preSession?.explanationRevision.partnerTrialHospital?.uid ===
    selectedTrialHospitalUid

  const { request: createSession } = useCreateExplanationSession({
    onSuccess: ({ explanationSessionUid }) => {
      navigate(topPath(explanationSessionUid))
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const navigate = useNavigate()

  const toast = useMirohaToast()

  const { currentMember } = useCurrentMember()

  useEffect(() => {
    if (!preSession?.videoCallRoomUid || !explanation?.patient.candidateId)
      return
    initializeMemberVideoCall({
      roomUid: preSession.videoCallRoomUid,
      member: currentMember,
    })
  }, [
    preSession?.videoCallRoomUid,
    explanation?.patient.candidateId,
    initializeMemberVideoCall,
    currentMember,
  ])

  const { request: updateIdentification } = useUpdatePreSessionIdentification({
    onSuccess: () => {
      mutatePreSession()
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  if (!!setupPreSessionError) {
    if (setupPreSessionError.status === 403) {
      return (
        <Information body={'説明を開始できないユーザーです。'}>
          <Text>
            説明を開始する操作ユーザーは説明情報の担当者に登録されている必要があります。
          </Text>
        </Information>
      )
    }
    return <Information body={setupPreSessionError.message} />
  }

  if (!preSession || !explanation) return null

  return (
    <Box h="full">
      <ExplanationRoomLayout
        headerTitle="説明ロビー"
        onFinish={async () => {
          leaveRoom().then()
          navigate(
            getRoomFinishedRoute({
              isLobby: true,
              isPartnerMember,
              type: preSession.explanationRevision.explanationType,
            }),
          )
        }}
        sidebarContent={
          <HospitalLobbySidebar
            trialName={selectedTrial.name}
            explanation={explanation}
            preSession={preSession}
            isPartner={isPartner(preSession)}
            onIdentifyPatient={async () => {
              await updateIdentification({
                explanationPreSessionUid: preSession.uid,
                isIdentified: !preSession.isIdentified,
              })
            }}
            onStartSession={async () => {
              await createSession({
                type: 'fromPreSession',
                explanationPreSessionUid: preSession.uid,
              })
            }}
            onMoveToRoom={() => {
              if (!preSession.explanationSessionUid) return
              navigate(topPath(preSession.explanationSessionUid))
            }}
          />
        }
      >
        <ExplanationRoomLobbyVideoCallContent
          isOldFlow
          isSessionStarted={!!preSession.explanationSessionUid}
          hospitalName={explanation.trialHospital.name}
        />
      </ExplanationRoomLayout>
    </Box>
  )
}
