import React from 'react'

import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { Check } from 'src/components/__legacy__icon/monochrome'
import { Spacer } from 'src/components/spacer/spacer'
import { CompleteAgreementForRoom } from 'src/features/explanation/components'
import { DeliveryDocs } from 'src/features/explanation/components/DeliveryDocs/DeliveryDocs'
import {
  ExplanationDocSet,
  ExplanationRevision,
  ExplanationSession,
  ExplanationType,
} from 'src/features/explanation/types'
import { Trial } from 'src/features/trial/types'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { sortByDate } from 'src/modules/util/sort'

import { DocumentListCardRenewal } from '../components/document-list-card-renewal'
import { getExplanationRoomDocumentRoute } from '../routes'
import { ExplanationRoomUserAttributeType } from '../user-attribute-type'

type Props = {
  userAttributeType: ExplanationRoomUserAttributeType
  trialUid: Trial['uid']
  session: ExplanationSession
  explanationRevisionUid: ExplanationRevision['uid']
  hospitalName: string
  docSets: ExplanationDocSet[]
  isAgreementDone: boolean
  isDelivered: boolean
  explanationType?: ExplanationType
  mutateSession: () => void
}

export const ExplanationRoomTopRenewal: React.FC<Props> = ({
  userAttributeType,
  trialUid,
  session,
  explanationRevisionUid,
  hospitalName,
  docSets,
  isDelivered,
  isAgreementDone,
  explanationType,
  mutateSession,
}) => {
  return (
    <Box h="full" py="6" px="10">
      <HStack align="center" justify="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          {hospitalName}
        </Text>

        {userAttributeType === 'hospital' && (
          <HStack spacing="16px">
            {isAgreementDone ? (
              <HStack align="center" spacing="4px">
                <Check size="S" color={colors.green[500]} />
                <Text fontSize="sm" color={colors.green[500]} fontWeight="bold">
                  同意完了済
                </Text>
              </HStack>
            ) : (
              <CompleteAgreementForRoom
                explanationRevisionUid={explanationRevisionUid}
                docRevisions={docSets.flatMap(docSet => docSet.docRevisions)}
                explanationSessionUid={session.uid}
              />
            )}

            {isDelivered ? (
              <HStack align="center" spacing="4px">
                <Check size="S" color={colors.green[500]} />
                <Text fontSize="sm" color={colors.green[500]} fontWeight="bold">
                  文書交付済
                </Text>
              </HStack>
            ) : (
              <DeliveryDocs
                explanationRevision={session.explanationRevision}
                mutateSession={mutateSession}
              />
            )}
          </HStack>
        )}
      </HStack>

      <Spacer size={40} />

      {docSets.length >= 1 &&
        docSets.map((set, setIndex) => {
          if (set.docRevisions.length === 0) {
            return null
          }
          return (
            <div key={set.uid}>
              <Text fontSize="xl" fontWeight="bold">
                {`${set.icfDocSetNumberingId}. ${set.name}`}
              </Text>
              <Spacer size={16} />
              <Stack spacing="4">
                {set.docRevisions
                  .sort((a, b) => a.index - b.index)
                  .map((docRev, docIndex) => {
                    const serialDocIndex =
                      docSets
                        .filter(set => docSets.indexOf(set) < setIndex)
                        .flatMap(set => set.docRevisions).length + docIndex
                    const includeCrc =
                      docRev.type === 'AgreementForm' &&
                      docRev.signerMembers.some(m => m.role === 'CRC')
                    return (
                      <DocumentListCardRenewal
                        key={docRev.uid}
                        userAttributeType={userAttributeType}
                        order={serialDocIndex + 1}
                        docType={docRev.icfDocumentRevisionType}
                        docName={docRev.icfDocumentRevisionName}
                        docVersion={docRev.icfDocumentRevisionVersion}
                        latestPatientBrowsedAt={sortByDate(
                          docRev.browsingHistories,
                          'operatedAt',
                          'desc',
                        )
                          .find(
                            h =>
                              h.browserRole === 'InPerson' ||
                              h.browserRole === 'PatientOrRepresentative' ||
                              h.isPartner,
                          )
                          ?.operatedAt?.toString()}
                        explanationType={explanationType}
                        isCompleted={docRev.isCompleted}
                        isRejected={
                          docRev.type === 'AgreementForm' &&
                          docRev.signHistories.some(h => h.isRejected)
                        }
                        isSkipped={isAgreementDone && !docRev.isCompleted}
                        hasDSEnvelope={
                          (docRev.type === 'AgreementForm' ||
                            docRev.type === 'CheckUnderstanding') &&
                          !!docRev.dsEnvelopeId
                        }
                        allPlacedSignerRoles={
                          includeCrc
                            ? ['Patient', 'Dr', 'CRC']
                            : ['Patient', 'Dr']
                        }
                        completedSignerRoles={
                          docRev.type === 'AgreementForm'
                            ? Array.from(
                                new Set(
                                  docRev.signHistories.map(h => h.signerRole),
                                ),
                              )
                            : []
                        }
                        documentPagePath={getExplanationRoomDocumentRoute({
                          type: userAttributeType,
                          trialUid,
                          sessionUid: session.uid,
                          docRevisionUid: docRev.uid,
                        })}
                      />
                    )
                  })}
              </Stack>
              <Spacer size={40} />
            </div>
          )
        })}
    </Box>
  )
}
