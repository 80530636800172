import { useMemo } from 'react'

import {
  Box,
  HStack,
  SkeletonText,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { HasSetBadge } from 'src/components/HasSetBadge/HasSetBadge'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { NotSetBadge } from 'src/components/NotSetBadge/NotSetBadge'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { useTrial } from 'src/features/explanation/api/getTrial'
import { MembersSettingButton } from 'src/features/explanation/components/MembersSettingButton/MembersSettingButton'
import { hasAlreadyPinSet } from 'src/features/explanation/utils/hasAlreadyPinSet'
import { isPinSettingEnabledRevisionStatus } from 'src/features/explanation/utils/isPinSettingEnabledRevisionStatus'
import { formatDate } from 'src/utils/formatDate'
import { getFullName } from 'src/utils/getFullName'

import { useSessionForSidebar } from '../../api'
import {
  useActiveDevice,
  useUpdateRevisionStatus,
} from '../../context/ExplanationRoomEvent'
import { useThisSession } from '../../hooks/useThisSession'
import { RequestPinSettingButton } from '../RequestPinSettingButton/RequestPinSettingButton'

type Props = {
  explanationSessionUid: string
}

export const HospitalSidebar: React.FC<Props> = ({ explanationSessionUid }) => {
  const {
    account: { selectedTrial, selectedTrialHospitalUid },
  } = useAuthenticatedAccount()

  const { data: trial } = useTrial({
    trialUid: selectedTrial.uid,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const { data: session, mutate: mutateSession } = useSessionForSidebar({
    explanationSessionUid,
    revalidateIfStale: false,
  })

  useUpdateRevisionStatus({
    listener: async () => {
      await mutateSession()
    },
  })

  const thisSession = useThisSession({
    sessionUid: explanationSessionUid,
    forPatient: false,
  })

  const mainHospital = session?.explanationRevision.explanation.trialHospital

  const partnerHospital = session?.explanationRevision.partnerTrialHospital

  const mainHospitalMembers = useMemo(
    () =>
      session?.members
        .filter(m => m.trialHospital.uid === mainHospital?.uid)
        .map(m => m.trialMember),
    [session, mainHospital],
  )

  const partnerHospitalMembers = useMemo(() => {
    if (!partnerHospital) return undefined
    return session?.members
      .filter(m => m.trialHospital.uid === partnerHospital?.uid)
      .map(m => m.trialMember)
  }, [session, partnerHospital])

  const identifiedAt =
    session?.__typename === 'ExplanationInPersonSession'
      ? undefined
      : (session?.explanationPreSession.identifiedAt ?? undefined)

  const { activeDevices } = useActiveDevice()

  if (!session || !thisSession.fetched)
    return (
      <Stack h="full" spacing="4">
        <SkeletonText skeletonHeight="4" noOfLines={8} spacing="4" />
      </Stack>
    )

  const pinSettingHistory =
    session.explanationRevision.latestPinSettingHistory?.status

  const hasPinSet = pinSettingHistory
    ? hasAlreadyPinSet(pinSettingHistory)
    : false

  const hasPhoneNumberSet =
    !!session.explanationRevision.explanation.patient.mobileNumber

  const partnerTrialHospitalUid = thisSession.partnerTrialHospital?.uid

  const iamPartnerMember = selectedTrialHospitalUid === partnerTrialHospitalUid

  return (
    <Stack spacing="8">
      <Text fontSize="xl" fontWeight="bold" as="span">
        {trial?.name}
      </Text>
      <Stack spacing="1">
        <Text fontSize="xl" fontWeight="bold" as="span">
          説明開始時刻
        </Text>
        <Text as="time" dateTime={session.startedAt}>
          {formatDate(session.startedAt, 'YYYY/MM/DD (ddd) HH:mm:ss')}
        </Text>
      </Stack>

      <Stack spacing="3">
        <Stack spacing="1">
          <Text fontSize="xl" fontWeight="bold">
            候補ID
          </Text>
          <Stack>
            <Text fontSize="lg">
              {session.explanationRevision.explanation.patient.candidateId}
            </Text>
            {identifiedAt !== undefined && (
              <Wrap>
                <WrapItem>
                  <Text fontSize="sm" as="span">
                    本人確認:{' '}
                  </Text>
                </WrapItem>
                <WrapItem>
                  <Text fontSize="sm" as="time" dateTime={identifiedAt}>
                    {formatDate(identifiedAt, 'YYYY/MM/DD (ddd) HH:mm')}
                  </Text>
                </WrapItem>
              </Wrap>
            )}
          </Stack>
        </Stack>
        {selectedTrial.featureFlags.eConsentNewSignFlow && (
          <Stack>
            <HStack spacing="4">
              {hasPinSet ? (
                <HasSetBadge text="暗証番号" />
              ) : (
                <NotSetBadge text="暗証番号" />
              )}
              {hasPhoneNumberSet ? (
                <HasSetBadge text="携帯電話番号" />
              ) : (
                <NotSetBadge text="携帯電話番号" />
              )}
            </HStack>
            {!iamPartnerMember &&
              isPinSettingEnabledRevisionStatus(
                session.explanationRevision.latestHistory.statusV2,
              ) && (
                <Box>
                  <RequestPinSettingButton
                    isReset={hasPinSet}
                    phoneNumber={
                      session.explanationRevision.explanation.patient
                        .mobileNumber ?? undefined
                    }
                    explanationRevisionUid={thisSession.explanationRevisionUid}
                    patientUid={thisSession.patient.uid}
                    explanationType={thisSession.explanationType}
                    partnerTrialHospitalUid={partnerTrialHospitalUid}
                    activePartnerDeviceActorUids={activeDevices
                      .filter(
                        ad =>
                          ad.actorTrialHospitalUid === partnerTrialHospitalUid,
                      )
                      .map(ad => ad.actorUid)}
                    mutateSession={mutateSession}
                  />
                </Box>
              )}
          </Stack>
        )}
      </Stack>

      <Box>
        <Text fontSize="xl" fontWeight="bold">
          説明担当者
        </Text>
        <Stack spacing="4" maxHeight="192px" mt="2" w="full">
          {/* メンバーが指定されていない場合は医療機関名も表示しない */}
          {mainHospital !== undefined &&
            mainHospitalMembers !== undefined &&
            mainHospitalMembers.length > 0 && (
              <Box w="full">
                <Text fontWeight="bold" as="h3" color="gray.600">
                  {mainHospital.hospital.name}
                </Text>
                <Stack mt="2" spacing="1" pl="6">
                  {mainHospitalMembers.map(member => (
                    <MemberLabel
                      key={member.uid}
                      role={member.role}
                      displayName={getFullName(member.user)}
                      isPartner={false}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          {/* パートナー施設の担当者が未指定の場合は施設名も表示しない */}
          {!!partnerHospital &&
            partnerHospitalMembers !== undefined &&
            partnerHospitalMembers.length > 0 && (
              <Box w="full">
                <Text fontWeight="bold" as="h3" color="gray.600">
                  {partnerHospital.hospital.name}
                </Text>
                <Stack mt="2" spacing="1" pl="6">
                  {partnerHospitalMembers.map(member => (
                    <MemberLabel
                      key={member.uid}
                      role={member.role}
                      displayName={getFullName(member.user)}
                      isPartner
                    />
                  ))}
                </Stack>
              </Box>
            )}
        </Stack>
        <Box height={8} />
        <MembersSettingButton
          explanationSessionUid={session.uid}
          explanationUid={session.explanationRevision.explanation.uid}
          mutateSession={mutateSession}
          isSignerSet={thisSession.isSignerMemberSet}
          defaultMembers={session.members.map(m => ({
            trialHospitalUid: m.trialHospital.uid,
            trialMemberUid: m.trialMember.uid,
            role: m.trialMember.role,
          }))}
        />
      </Box>
    </Stack>
  )
}
