import { useMemo, useState } from 'react'

import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { RedirectLogin } from 'src/components/RedirectLogin/RedirectLogin'
import { dealSPQueryKey } from 'src/features/explanationRoom/constants/sp'
import { useFeatureFlags } from 'src/features/trial/api/getFeatureFlags'
import { Trial } from 'src/features/trial/types'
import { assertNever } from 'src/utils/assertNever'

import { UID } from '../../../../utils/brandedUid'
import { useChallengeSignAuthV2 } from '../../api/challengeSignAuthV2'
import { useCreateEnvelopeViewUrlFromSignAuth } from '../../api/createEnvelopeViewUrlFromSignAuth'
import { useSignAuth } from '../../hooks/useSignAuth'
import { getEmailDocuSignReturnUrl } from '../../utils/getEmailDocuSignReturnUrl'
import { CompletedSignAuth } from '../CompletedSignAuth/CompletedSignAuth'
import { InvalidSignAuth } from '../InvalidSignAuth/InvalidSignAuth'
import { OTPForm } from '../OTPForm/OTPForm'
import { PinVerification } from '../PinVerification/PinVerification'
import { SignAuthForm } from '../SignAuthForm/SignAuthForm'
import { SignAuthSuccess } from '../SignAuthSuccess/SignAuthSuccess'

type Param = {
  trialUid: Trial['uid']
  explanationSignAuthUid: string
}

export const SignAuthScreen: React.FC = () => {
  const { trialUid = '' as UID<'Trial'>, explanationSignAuthUid = '' } =
    useParams<Param>()
  const [searchParams] = useSearchParams()
  const { data: featureFlags } = useFeatureFlags({
    trialUid: !!trialUid ? trialUid : null,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })

  const isSP = useMemo(() => {
    // query paramsに指定がある場合はそちらを優先、未指定の場合はuser agentから判定（主にdebug用途）
    const dealSPQuery = searchParams.get(dealSPQueryKey)
    if (dealSPQuery === 'true') return true
    if (dealSPQuery === 'false') return false
    return isMobileOnly
  }, [searchParams])

  if (!featureFlags) return null

  if (featureFlags.eConsentNewSignFlow) {
    return (
      <SignAuth
        trialUid={trialUid}
        explanationSignAuthUid={explanationSignAuthUid}
        isSP={isSP}
      />
    )
  }
  return (
    <OldSignAuth
      trialUid={trialUid}
      explanationSignAuthUid={explanationSignAuthUid}
    />
  )
}

type OldSignAuthParam = {
  trialUid: Trial['uid']
  explanationSignAuthUid: string
}

const OldSignAuth = ({
  trialUid,
  explanationSignAuthUid,
}: OldSignAuthParam) => {
  const { signAuthResult, mutateSignAuthResult, error } = useSignAuth({
    trialUid,
    explanationSignAuthUid,
  })

  if (error) {
    return (
      <SignAuthForm
        trialUid={trialUid}
        explanationSignAuthUid={explanationSignAuthUid}
        mutateSignAuthResult={mutateSignAuthResult}
        message={error.message}
      />
    )
  }

  if (!signAuthResult) {
    return null
  }

  if (signAuthResult.urlStatus === 'ExpiredAndReSent') {
    return (
      <InvalidSignAuth
        explanationSignAuthUid={explanationSignAuthUid}
        trialUid={trialUid}
      />
    )
  }

  if (signAuthResult.urlStatus === 'AlreadyCompleted') {
    return <CompletedSignAuth />
  }

  // --- URLが有効な場合 ↓ ---

  if (
    signAuthResult.status === 'CheckedWithSession' &&
    !!signAuthResult.docuSignUrl
  ) {
    // DocuSignの署名ページにリダイレクト
    window.location.replace(signAuthResult.docuSignUrl)
    return null
  }

  if (signAuthResult.status === 'NoSession') {
    return <RedirectLogin />
  }

  if (signAuthResult.status === 'CheckedWithCode') {
    return (
      <SignAuthSuccess
        docuSignUrl={signAuthResult.docuSignUrl}
        trialName={signAuthResult.trialName}
        documentName={signAuthResult.documentName}
      />
    )
  }
  if (signAuthResult.status === 'IncorrectCode') {
    return (
      <SignAuthForm
        trialUid={trialUid}
        explanationSignAuthUid={explanationSignAuthUid}
        mutateSignAuthResult={mutateSignAuthResult}
        message="認証コードに誤りがあります。\n再度ご入力いただくか、認証コードを再送してください。"
      />
    )
  }
  if (signAuthResult.status === 'ExpiredCode') {
    return (
      <SignAuthForm
        trialUid={trialUid}
        explanationSignAuthUid={explanationSignAuthUid}
        mutateSignAuthResult={mutateSignAuthResult}
        message="認証コードが無効です。\n認証コードを再送してください。"
      />
    )
  }
  return (
    <SignAuthForm
      trialUid={trialUid}
      explanationSignAuthUid={explanationSignAuthUid}
      mutateSignAuthResult={mutateSignAuthResult}
      message=""
    />
  )
}

type SignAuthParam = {
  trialUid: string
  explanationSignAuthUid: string
  isSP: boolean
}

const SignAuth = ({
  trialUid,
  explanationSignAuthUid,
  isSP,
}: SignAuthParam) => {
  const [docusignURL, setDocusignURL] = useState<string>('')
  const [isAlreadyFinished, setIsAlreadyFinished] = useState<boolean>(false)
  const { request: createViewUrl, requesting } =
    useCreateEnvelopeViewUrlFromSignAuth({
      onSuccess: async data => {
        setIsAlreadyFinished(data.alreadyFinished)
        if (data.alreadyFinished) {
          setDocusignURL('')
        } else {
          setDocusignURL(data.viewUrl ? data.viewUrl : '')
        }
      },
    })

  const {
    mutate: challengeAuth,
    data: challengeResult,
    error,
  } = useChallengeSignAuthV2({
    trialUid: trialUid,
    explanationSignAuthenticationUid: explanationSignAuthUid,
    revalidateOnFocus: false,
    onSuccess: async data => {
      if (
        data.result === 'MemberSignAvailable' ||
        data.result === 'PatientSignAvailable'
      ) {
        await createViewUrl({
          trialUid: trialUid,
          explanationSignAuthenticationUid: explanationSignAuthUid,
          returnUrl: getEmailDocuSignReturnUrl(),
          createType: 'FromEmailLink',
        })
      }
    },
  })

  if (error) {
    return (
      <InvalidSignAuth
        explanationSignAuthUid={explanationSignAuthUid}
        trialUid={trialUid}
      />
    )
  }
  if (!challengeResult || requesting) {
    return null
  }

  if (isAlreadyFinished) {
    return <CompletedSignAuth />
  }

  switch (challengeResult.result) {
    case 'RequiredPatientPinAuthentication':
      return (
        <PinVerification
          trialUid={trialUid}
          explanationSessionUid={challengeResult.explanationSessionUid}
          challengeSignAuth={() => {
            challengeAuth()
          }}
        />
      )
    case 'RequiredPatientOTPAuthentication':
      return (
        <OTPForm
          trialUid={trialUid}
          explanationSignAuthUid={explanationSignAuthUid}
          mutateChallenge={() => {
            challengeAuth()
          }}
          isSP={isSP}
        />
      )
    case 'PatientSignAvailable':
      return (
        <SignAuthSuccess
          docuSignUrl={docusignURL}
          trialName={challengeResult.trialName}
          documentName={challengeResult.documentName}
        />
      )
    case 'ExpiredAndReSent':
      return (
        <InvalidSignAuth
          explanationSignAuthUid={explanationSignAuthUid}
          trialUid={trialUid}
        />
      )
    case 'MemberSignAvailable':
      if (!!docusignURL) {
        window.location.replace(docusignURL)
      }
      return null
    case 'RequiredMemberLogin':
      return <RedirectLogin />
    case 'AlreadySignCompleted':
      return <CompletedSignAuth />
    default:
      return assertNever(challengeResult.result)
  }
}
