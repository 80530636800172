import { Box, Button, Center, HStack, Text } from '@chakra-ui/react'
import { Check, PageNext } from 'src/components/icon'

type Props = {
  title: string
  isDisabled?: boolean
  onClick: () => void
  checked?: boolean
  isGrayedOut?: boolean
}

export const SignFlowBannerButton: React.FC<Props> = ({
  title,
  isDisabled,
  onClick,
  checked = false,
  isGrayedOut = false,
}) => {
  return (
    <Button
      name={title}
      onClick={onClick}
      isDisabled={isDisabled}
      w="full"
      borderRadius="lg"
      minH="unset"
      h="72px"
      p="4"
      justifyContent="space-between"
      alignItems="center"
      bg="green.600"
      _disabled={{
        bg: isGrayedOut ? 'gray.600' : 'green.600',
        opacity: 0.3,
        cursor: 'not-allowed',
      }}
      _hover={{
        bg: 'green.700',
        _disabled: { bg: isGrayedOut ? 'gray.600' : 'green.600' },
      }}
      _active={{
        bg: 'green.800',
        _disabled: { bg: isGrayedOut ? 'gray.600' : 'green.600' },
      }}
    >
      <HStack spacing="6" as="span">
        {checked ? (
          <Center w="40px" h="40px" color="white">
            <Check size="32px" />
          </Center>
        ) : (
          <Box
            as="span"
            w="40px"
            h="40px"
            border="4px solid"
            borderRadius="full"
            borderColor="white"
          />
        )}
        <Text as="span" fontSize="2xl" fontWeight="bold" color="white">
          {title}
        </Text>
      </HStack>
      <Box color="white" as="span">
        {!isGrayedOut && <PageNext size="lg" />}
      </Box>
    </Button>
  )
}
