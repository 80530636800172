import { useEffect } from 'react'

import { useRequest } from 'src/hooks/use-request'

import { getVideoCallRoomImage } from '../request'

export const useGetVideoCallRoomImage = (videoCallRoomImageUid?: string) => {
  const {
    data: response,
    doRequest,
    requesting,
  } = useRequest(getVideoCallRoomImage)

  useEffect(() => {
    if (!videoCallRoomImageUid) return

    doRequest({ videoCallRoomImageUid })
  }, [doRequest, videoCallRoomImageUid])

  return { response, requesting }
}
