import React, { useCallback } from 'react'

import { Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { SubmitModal } from 'src/components/modal/submit'
import { useRequestState } from 'src/modules/server/use-request-state'
import { swrKeys } from 'src/modules/swr/key'
import { mutate } from 'swr'

import { trialIdParamName } from '../../../../trial-detail'
import { cancelDownloadJob } from '../request'

type Props = {
  onClose: () => void
  crfDownloadJobUid: string
}

export const CancelCrfDownloadModal: React.FC<Props> = props => {
  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()

  const { request, requesting, errorMessage } = useCancelCrfDownloadJob({
    trialUid,
    crfDownloadJobUid: props.crfDownloadJobUid,
  })

  const onSubmit = async () => {
    try {
      await request()
    } catch (exception) {
      return
    }
    props.onClose()
  }

  return (
    <SubmitModal
      title="CRFファイル作成中止"
      submitText="はい"
      cancelText="いいえ"
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      requesting={requesting}
      onClose={props.onClose}
    >
      <Text textAlign="center">{`CRFファイルの作成を中止しますか？`}</Text>
    </SubmitModal>
  )
}

const useCancelCrfDownloadJob = ({
  trialUid,
  crfDownloadJobUid,
}: {
  trialUid: string
  crfDownloadJobUid: string
}) => {
  const {
    requestStarted,
    requestDone,
    requesting,
    requestFailed,
    errorMessage,
  } = useRequestState()
  const request = useCallback(async () => {
    try {
      requestStarted()
      await cancelDownloadJob({ trialUid, crfDownloadJobUid })
      mutate(swrKeys.fetchCrfDownloadJobs)
      requestDone()
    } catch (error) {
      requestFailed(error.message)
      throw error
    }
  }, [requestStarted, requestDone, requestFailed, trialUid, crfDownloadJobUid])
  return {
    request,
    requesting,
    errorMessage,
  }
}
