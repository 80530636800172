import { SERVER_URL } from 'src/modules/server/const'
import { requestPut } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

type AuthenticateResponse = {
  email: string
  required_password_change: boolean
}

export const submitAuthenticate = async ({
  trialUid,
  members,
  memberUid,
  token,
}: {
  trialUid: string
  members: string
  memberUid: string
  token: string
}) => {
  if (IS_MOCK_MODE) {
    return { email: '', requiredPasswordChange: false }
  }

  const resp = await requestPut<AuthenticateResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/${members}/${memberUid}/${token}`,
    params: {},
  })

  return {
    email: resp.data.email,
    requiredPasswordChange: resp.data.required_password_change,
  }
}
