import produce from 'immer'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { CrfFolderTemplates, CrfSection } from './entity'
import { UploadTask } from './task'

const create = actionCreatorFactory('entities/trial')

export const actions = {
  uploadFolderTemplates: create<{
    folderTemplates: CrfFolderTemplates
    fileName: string
  }>('uploadWorksheet'),
  uploadSection: create<{ sections: CrfSection[]; fileName: string }>(
    'uploadSection',
  ),
  uploadField: create<{ fileName: string }>('uploadField'),
  uploadChoice: create<{ fileName: string }>('uploadDictionary'),
}

export type State = {
  folderTemplates: CrfFolderTemplates | undefined
  sections: CrfSection[] | undefined
  files: Record<UploadTask['name'], string>
}

const initialState: State = {
  folderTemplates: undefined,
  sections: undefined,
  files: {
    template: '',
    section: '',
    field: '',
    choice: '',
  },
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.uploadFolderTemplates, (state, payload) =>
    produce(state, draft => {
      const { folderTemplates, fileName } = payload

      draft.folderTemplates = folderTemplates
      draft.files.template = fileName
    }),
  )
  .case(actions.uploadSection, (state, payload) =>
    produce(state, draft => {
      const { sections, fileName } = payload

      draft.sections = sections
      draft.files.section = fileName
    }),
  )
  .case(actions.uploadField, (state, payload) =>
    produce(state, draft => {
      draft.files.field = payload.fileName
    }),
  )
  .case(actions.uploadChoice, (state, payload) =>
    produce(state, draft => {
      draft.files.choice = payload.fileName
    }),
  )
  .build()
