import React, { useCallback } from 'react'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Trash } from 'src/components/__legacy__icon/monochrome'
import { CalendarDateInput } from 'src/components/CalendarDateInput/CalendarDateInput'
import { FileSelectButton } from 'src/components/FileSelectButton/FileSelectButton'
import { RequiredBadge } from 'src/components/RequiredBadge/RequiredBadge'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { useUpdateExpRevisionStatus } from 'src/features/explanationRoom/api/updateExplanationRevisionStatus'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'

import { useWithdrawExplanation } from '../../api/withdrawExplanation'
import { useExplanationWithdrawForm } from '../../hooks/useExplanationWithdrawForm'
import { ExplanationRevision } from '../../types'

type Props = {
  isOpen: boolean
  onClose: () => void
  candidateId: string
  explanationRevisionUid: ExplanationRevision['uid']
  mutateExplanation: () => void
}
export const WithdrawModal: React.FC<Props> = ({
  explanationRevisionUid,
  isOpen,
  onClose,
  candidateId,
  mutateExplanation,
}) => {
  const toast = useMirohaToast()

  const { request: updateRevisionStatus } = useUpdateExpRevisionStatus({
    onSuccess: () => {
      mutateExplanation()
    },
  })

  const { request: withdrawExplanation, requesting } = useWithdrawExplanation({
    onSuccess: () => {
      toast({ status: 'success', title: '同意撤回しました' })
      onClose()
      // 非同期にイベント経由でステータスを更新すると、ステータス更新前にmutateExplanationでデータを取得してしまいステータスが更新されていないことがあるため、
      // API経由でステータスを更新したのち、mutateExplanationで再取得する
      updateRevisionStatus({ explanationRevisionUid })
    },
    onError: error => {
      toast({
        status: 'error',
        title: error.message,
      })
    },
  })

  const {
    field,
    onChangeWithdrawnAt,
    onCommentChange,
    onFileChange,
    onFileRemove,
    isValid,
  } = useExplanationWithdrawForm({})

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault() // ページのリロードを防止する
      await withdrawExplanation({
        explanationRevisionUid: explanationRevisionUid,
        data: field,
      })
    },
    [withdrawExplanation, explanationRevisionUid, field],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text textAlign="start">同意撤回を実施します</Text>
            <Box height="2" />
            <WarningMessage
              message={`この操作は取り消せません。候補ID: ${candidateId}への操作であることを確認してください。`}
            />
          </ModalHeader>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel
                display="flex"
                alignItems="center"
                requiredIndicator={<RequiredBadge ml="1" />}
                fontSize="medium"
                fontWeight="bold"
              >
                同意撤回日
              </FormLabel>
              <Box h="2" />
              <CalendarDateInput
                value={field.withdrawAt}
                onChange={onChangeWithdrawnAt}
              />
            </FormControl>
            <Box h="6" />
            <FormControl isRequired>
              <FormLabel
                display="flex"
                alignItems="center"
                requiredIndicator={<RequiredBadge ml="1" />}
                fontSize="medium"
                fontWeight="bold"
              >
                コメント
              </FormLabel>

              <Input
                value={field.comment}
                onChange={e => {
                  onCommentChange(e.target.value)
                }}
                bg="white"
                placeholder="同意撤回理由を入力してください"
              />
            </FormControl>
            <Box h="6" />
            <FormControl>
              <FormLabel fontSize="md" fontWeight="bold">
                同意撤回書
              </FormLabel>
              <Flex alignItems="center">
                {!!field.file && (
                  <Link
                    href={URL.createObjectURL(field.file)}
                    isExternal
                    sx={{ pr: 6 }}
                  >
                    {field?.file?.name}
                  </Link>
                )}
                <FileSelectButton
                  onChange={onFileChange}
                  text="ファイルを選択"
                  accept=".pdf"
                  buttonProps={{ variant: 'text', px: '0', mx: '0' }}
                />

                {field?.file !== undefined && (
                  <IconButton
                    aria-label="delete-file"
                    icon={<Trash />}
                    variant="text"
                    onClick={onFileRemove}
                    pl={4}
                  />
                )}
              </Flex>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="red"
              type="submit"
              isDisabled={requesting || !isValid}
            >
              同意撤回する
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
