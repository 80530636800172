import produce from 'immer'
import { Message, MessageOption } from 'src/components/layout/message-tab'
import { generateUUID } from 'src/utils/generateUUID'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

const create = actionCreatorFactory('flash')

export const actions = {
  showSuccess: create<{ message: string; option?: MessageOption }>(
    'SHOW_SUCCESS',
  ),
  showInfo: create<{ message: string; option?: MessageOption }>('SHOW_INFO'),
  showWarning: create<{ message: string; option?: MessageOption }>(
    'SHOW_WARNING',
  ),
  showError: create<{ message: string; option?: MessageOption }>('SHOW_ERROR'),
  complete: create<{}>('COMPLETE'),
}

export type State = {
  messages: Message[]
}

const initialState: State = {
  messages: [],
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.showSuccess, (state, payload) =>
    produce(state, draft => {
      draft.messages.push({
        body: payload.message,
        type: 'Success',
        option: payload.option,
        uid: generateUUID(),
      })
    }),
  )
  .case(actions.showInfo, (state, payload) =>
    produce(state, draft => {
      draft.messages.push({
        body: payload.message,
        type: 'Info',
        option: payload.option,
        uid: generateUUID(),
      })
    }),
  )
  .case(actions.showError, (state, payload) =>
    produce(state, draft => {
      draft.messages.push({
        body: payload.message,
        type: 'Error',
        option: payload.option,
        uid: generateUUID(),
      })
    }),
  )
  .case(actions.showWarning, (state, payload) =>
    produce(state, draft => {
      draft.messages.push({
        body: payload.message,
        type: 'Warning',
        option: payload.option,
        uid: generateUUID(),
      })
    }),
  )
  .case(actions.complete, (state, payload) =>
    produce(state, draft => {
      draft.messages.splice(0, 1)
    }),
  )
  .build()
