import {
  CheckboxTypeDef,
  Choice,
  Condition,
  DateTypeDef,
  Field,
  FileTypeDef,
  NullableDateTypeDef,
  NumberTypeDef,
  RadioTypeDef,
  RepeatableSectionTypeDef,
  Schema,
  SectionTypeDef,
  SelectMenuTypeDef,
  TextareaTypeDef,
  TextTypeDef,
  TimeTypeDef,
} from '@micin-jp/chicken-schema'
import dayjs from 'dayjs'
import { Member } from 'src/features/member/types'
import {
  createWorksheetMock,
  createWorksheetSchemaMock,
  createWorksheetLogMock,
  createTextFieldValueMock,
  Worksheet as GqlWorksheet,
  createNumberFieldValueMock,
  createCheckboxFieldValueMock,
  createRadioFieldValueMock,
  createTextareaFieldValueMock,
  createSelectMenuFieldValueMock,
  createDateFieldValueMock,
  createNullableDateFieldValueMock,
  createTimeFieldValueMock,
} from 'src/lib/gql-client'
import { castUid } from 'src/utils/brandedUid'
import { generateUUID } from 'src/utils/generateUUID'

import { parseGqlWorksheetResponse } from '../api/parser'
import {
  FieldValue,
  FieldValueBase,
  Worksheet,
  WorksheetDetail,
  WorksheetLogWithFieldValue,
} from '../types'

export const worksheetSchemaFieldFactory = <T extends Field['typeDef']>({
  fid,
  name,
  typeDef,
  visibleIf,
}: {
  fid?: string
  name: string
  typeDef: T
  visibleIf?: Condition
}): Field & { typeDef: T } => {
  return {
    fid: fid ?? generateUUID(),
    name,
    typeDef,
    visibleIf,
  }
}

export const textField = worksheetSchemaFieldFactory<TextTypeDef>({
  fid: generateUUID(),
  name: 'テキスト',
  typeDef: { type: 'Text' },
})
/** RepeatableSection内のTextフィールド */
export const repeatableSectionTextField =
  worksheetSchemaFieldFactory<TextTypeDef>({
    fid: generateUUID(),
    name: 'テキスト2',
    typeDef: { type: 'Text' },
  })
export const numberField = worksheetSchemaFieldFactory<NumberTypeDef>({
  fid: generateUUID(),
  name: '数値',
  typeDef: { type: 'Number', unit: '℃' },
})
/** RepeatableSection内のNumberフィールド */
export const repeatableSectionNumberField =
  worksheetSchemaFieldFactory<NumberTypeDef>({
    fid: generateUUID(),
    name: '数値2',
    typeDef: { type: 'Number', unit: '℃' },
  })

export const textareaField = worksheetSchemaFieldFactory<TextareaTypeDef>({
  fid: generateUUID(),
  name: 'テキストエリア',
  typeDef: { type: 'Textarea' },
})
export const selectMenuCid1 = generateUUID()
export const selectMenuCid2 = generateUUID()
export const selectMenuField = worksheetSchemaFieldFactory<SelectMenuTypeDef>({
  fid: generateUUID(),
  name: 'セレクトメニュー',
  typeDef: {
    type: 'SelectMenu',
    choices: [
      { name: '選択肢1', cid: selectMenuCid1 },
      {
        name: '選択肢2',
        cid: selectMenuCid2,
      },
    ],
  },
})
export const radioCid1 = generateUUID()
export const radioCid2 = generateUUID()
export const radioField = worksheetSchemaFieldFactory<RadioTypeDef>({
  fid: generateUUID(),
  name: 'ラジオ',
  typeDef: {
    type: 'Radio',
    choices: [
      { name: '選択肢1', cid: radioCid1 },
      {
        name: '選択肢2',
        cid: radioCid2,
      },
    ],
  },
})
export const checkboxCid1 = generateUUID()
export const checkboxCid2 = generateUUID()
export const checkboxField = worksheetSchemaFieldFactory<CheckboxTypeDef>({
  fid: generateUUID(),
  name: 'チェックボックス',
  typeDef: {
    type: 'Checkbox',
    choices: [
      { name: '選択肢1', cid: checkboxCid1 },
      {
        name: '選択肢2',
        cid: checkboxCid2,
      },
    ],
  },
})
export const dateField = worksheetSchemaFieldFactory<DateTypeDef>({
  fid: generateUUID(),
  name: '日付',
  typeDef: { type: 'Date' },
})
export const nullableDateField =
  worksheetSchemaFieldFactory<NullableDateTypeDef>({
    fid: generateUUID(),
    name: '日付',
    typeDef: { type: 'NullableDate' },
  })
export const timeField = worksheetSchemaFieldFactory<TimeTypeDef>({
  fid: generateUUID(),
  name: '時間',
  typeDef: { type: 'Time' },
})
export const fileFieldFid = generateUUID()
export const fileField = worksheetSchemaFieldFactory<FileTypeDef>({
  fid: fileFieldFid,
  name: 'ファイル',
  typeDef: { type: 'File', isCertifiedCopy: true },
})
/** checkboxFieldの"選択肢1"が選択された場合に表示 */
export const nestedTextField = worksheetSchemaFieldFactory<TextTypeDef>({
  fid: generateUUID(),
  name: 'ネストテキスト',
  typeDef: { type: 'Text' },
  visibleIf: {
    operand: 'hasChoice',
    fid: checkboxField.fid,
    cid: checkboxField.typeDef.choices[0].cid,
  },
})
/** radioFieldの"選択肢1"がｈ選択された場合に表示 */
export const nestedNumberField = worksheetSchemaFieldFactory<NumberTypeDef>({
  fid: generateUUID(),
  name: 'ネスト数値',
  typeDef: { type: 'Number', unit: '℃' },
  visibleIf: {
    operand: 'hasChoice',
    fid: radioField.fid,
    cid: radioField.typeDef.choices[0].cid,
  },
})
/** selectMenuFieldの"選択肢1"が選択された場合に表示 */
export const nestedTextareaField = worksheetSchemaFieldFactory<TextareaTypeDef>(
  {
    fid: generateUUID(),
    name: 'ネストテキストエリア',
    typeDef: { type: 'Textarea' },
    visibleIf: {
      operand: 'hasChoice',
      fid: selectMenuField.fid,
      cid: selectMenuField.typeDef.choices[0].cid,
    },
  },
)
export const nestedCheckboxCid1 = generateUUID()
export const nestedCheckboxCid2 = generateUUID()
/** checkboxFieldの"選択肢1"が選択された場合に表示 */
export const nestedCheckboxField = worksheetSchemaFieldFactory<CheckboxTypeDef>(
  {
    fid: generateUUID(),
    name: 'ネストチェックボックス',
    typeDef: {
      type: 'Checkbox',
      choices: [
        { name: 'ネスト選択肢1', cid: nestedCheckboxCid1 },
        {
          name: 'ネスト選択肢2',
          cid: nestedCheckboxCid2,
        },
      ],
    },
    visibleIf: {
      operand: 'hasChoice',
      fid: checkboxField.fid,
      cid: checkboxField.typeDef.choices[0].cid,
    },
  },
)
/** nestedCheckboxFieldの"ネスト選択肢1"が選択された場合に表示 */
export const deeplyNestedTextField = worksheetSchemaFieldFactory<TextTypeDef>({
  fid: generateUUID(),
  name: '深いネストテキスト',
  typeDef: { type: 'Text' },
  visibleIf: {
    operand: 'hasChoice',
    fid: nestedCheckboxField.fid,
    cid: nestedCheckboxField.typeDef.choices[0].cid,
  },
})

/** text, number, checkboxを配下に持つ */
export const sectionField1 = worksheetSchemaFieldFactory<SectionTypeDef>({
  fid: generateUUID(),
  name: 'セクション1',
  typeDef: {
    type: 'Section',
    fields: [
      textField,
      numberField,
      checkboxField,
      nestedTextField,
      nestedCheckboxField,
      deeplyNestedTextField,
    ],
  },
})
/** radio, textarea, selectMenu, date, nullableDate, timeを配下にも持つ */
export const sectionField2 = worksheetSchemaFieldFactory<SectionTypeDef>({
  fid: generateUUID(),
  name: 'セクション2',
  typeDef: {
    type: 'Section',
    fields: [
      radioField,
      textareaField,
      selectMenuField,
      dateField,
      nullableDateField,
      timeField,
      fileField,
      nestedTextareaField,
      nestedNumberField,
    ],
  },
})

export const repeatableSectionField =
  worksheetSchemaFieldFactory<RepeatableSectionTypeDef>({
    fid: generateUUID(),
    name: '繰り返しセクション',
    typeDef: {
      type: 'RepeatableSection',
      fields: [repeatableSectionTextField, repeatableSectionNumberField],
    },
  })

export const schema: Schema = {
  schemaVersion: '0.1',
  name: 'ワークシートスキーマ',
  fields: [sectionField1, sectionField2, repeatableSectionField],
}

/** schemaの値も含んだGraphQLのワークシートレスポンスを返す（そのままでも使用でき、値を上書きすることも可） */
export const worksheetWithSchemaResponseFactory = (
  override: Partial<GqlWorksheet> = {},
): GqlWorksheet => {
  return createWorksheetMock({
    name: 'ワークシート',
    worksheetSchema: createWorksheetSchemaMock({
      schemaFile: JSON.stringify(schema),
    }),
    worksheetLogs: [
      createWorksheetLogMock({
        status: 'Created',
        action: 'Create',
        savedAt: '2024-01-01T08:40:00.000Z',
      }),
      createWorksheetLogMock({
        status: 'Saved',
        action: 'Save',
        savedAt: '2024-01-01T09:00:00.000Z',
      }),
    ],
    latestWorksheetLog: createWorksheetLogMock({
      status: 'Saved',
      fieldValues: [
        createTextFieldValueMock({
          fieldIndex: 1,
          fid: textField.fid,
          textValue: 'text value',
        }),
        createNumberFieldValueMock({
          fieldIndex: 1,
          fid: numberField.fid,
          numberValue: '36.5',
        }),
        createCheckboxFieldValueMock({
          fieldIndex: 1,
          fid: checkboxField.fid,
          checkboxValue: [checkboxCid1],
        }),
        createRadioFieldValueMock({
          fieldIndex: 1,
          fid: radioField.fid,
          radioValue: radioCid1,
        }),
        createTextareaFieldValueMock({
          fieldIndex: 1,
          fid: textareaField.fid,
          textareaValue: 'textarea value',
        }),
        createSelectMenuFieldValueMock({
          fieldIndex: 1,
          fid: selectMenuField.fid,
          selectMenuValue: selectMenuCid1,
        }),
        createDateFieldValueMock({
          fieldIndex: 1,
          fid: dateField.fid,
          dateValue: '2021-01-01',
        }),
        createNullableDateFieldValueMock({
          fieldIndex: 1,
          fid: nullableDateField.fid,
          nullableDateValue: '2021-01',
        }),
        createTimeFieldValueMock({
          fieldIndex: 1,
          fid: timeField.fid,
          timeValue: '12:00',
        }),
        createTextFieldValueMock({
          fieldIndex: 1,
          fid: nestedTextField.fid,
          textValue: 'nested text value',
        }),
        createNumberFieldValueMock({
          fieldIndex: 1,
          fid: nestedNumberField.fid,
          numberValue: '36.5',
        }),
        createTextareaFieldValueMock({
          fieldIndex: 1,
          fid: nestedTextareaField.fid,
          textareaValue: 'nested textarea value',
        }),
      ],
    }),
    ...override,
  })
}

/** schemaの値も含んだWorksheetDetail型のオブジェクトを返す（そのままでも使用でき、値を上書きすることも可） */
export const worksheetDetailFactory = (
  override: Partial<WorksheetDetail> = {},
): WorksheetDetail => {
  return {
    ...parseGqlWorksheetResponse(worksheetWithSchemaResponseFactory()),
    ...override,
  }
}

export const worksheetLogFactory = (
  override: Partial<WorksheetLogWithFieldValue> = {},
): WorksheetLogWithFieldValue => {
  const memberUid = castUid<Member>(generateUUID())
  return {
    uid: castUid<WorksheetLogWithFieldValue>(generateUUID()),
    worksheetUid: castUid<Worksheet>(generateUUID()),
    trialMemberUid: memberUid,
    trialMember: {
      uid: memberUid,
      firstName: '太郎',
      lastName: 'MICIN',
      role: 'Dr',
    },
    status: 'saved',
    action: 'save',
    savedAt: dayjs().format(),
    fieldValues: [],
    ...override,
  }
}

export const worksheetFieldValueBaseFactory = (
  override: Partial<FieldValueBase> = {},
): FieldValueBase => {
  return {
    uid: castUid<FieldValue>(generateUUID()),
    fid: generateUUID(),
    index: 1,
    ...override,
  }
}

export const worksheetSchemaChoiceFactory = (
  props: Partial<Choice> = {},
): Choice => {
  return {
    cid: generateUUID(),
    name: '',
    ...props,
  }
}
