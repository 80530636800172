import { HttpResponse } from 'msw'
import {
  createSetExpRevisionPinPayloadMock,
  SetPinDocument,
  SetPinMutation,
  SetPinMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const setPinHandler = graphQLHandlerFactory<
  SetPinMutation,
  SetPinMutationVariables
>('mutation', SetPinDocument, () => {
  return HttpResponse.json({
    data: {
      setExpRevisionPin: createSetExpRevisionPinPayloadMock(),
    },
  })
})
