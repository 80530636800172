// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgProgress = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 30 24"
    {...props}
  >
    <path
      fill="#44B3A2"
      d="m29.775 11.46-7.5-6.96C19.38 1.59 15.795 0 12 0 5.37 0 0 5.265 0 12s5.37 12 12 12c3.795 0 7.38-1.62 10.275-4.515l7.5-6.96a.761.761 0 0 0 0-1.08v.015Z"
    />
    <circle cx={12} cy={12} r={7} fill="#fff" />
  </svg>
)
export default SvgProgress
