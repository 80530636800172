import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import {
  DownloadWorksheetFieldFileInput,
  DownloadWorksheetFieldFilePayload,
  DownloadWorksheetFieldFilesDocument,
  GqlError,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

const downloadWorksheetFieldFiles = async (
  input: DownloadWorksheetFieldFileInput,
): Promise<DownloadWorksheetFieldFilePayload> => {
  try {
    const res = await graphqlRequest(DownloadWorksheetFieldFilesDocument, {
      input,
    })
    return res.downloadWorksheetFieldFile
  } catch (error) {
    throw error
  }
}

export const useDownloadWorksheetFieldFiles = (
  option?: UseMutationOption<DownloadWorksheetFieldFilePayload, GqlError>,
) => {
  return useMutation(downloadWorksheetFieldFiles, option)
}
