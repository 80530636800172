import { HStack, Input, Text } from '@chakra-ui/react'
import { Field, NumberTypeDef } from '@micin-jp/chicken-schema'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  numberField: Field & {
    typeDef: NumberTypeDef
  }
  index: number
}

export const WorksheetNumberField: React.FC<Props> = ({
  index,
  numberField,
}) => {
  const {
    findFieldValue,
    onChangeNumberFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: numberField.fid,
    index,
  })

  const onChange = (numberValue: string) => {
    onChangeNumberFieldValue({
      fid: numberField.fid,
      index,
      numberValue,
    })
  }

  return (
    <WorksheetFieldFormControl
      field={numberField}
      index={index}
      isDisabled={
        !canEdit || !isRepeatableSectionEnabled(index, numberField.fid)
      }
      errorMessage={getErrorMessage(value)}
    >
      <HStack align="center" spacing="1.5">
        <Input
          w="100px"
          value={value?.type === 'number' ? value.numberValue : undefined}
          onChange={e => onChange(e.target.value)}
        />
        {numberField.typeDef.unit && (
          <Text fontSize="sm" as="span">
            {numberField.typeDef.unit}
          </Text>
        )}
      </HStack>
    </WorksheetFieldFormControl>
  )
}
