import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Menu as MenuIcon } from 'src/components/icon'

import { Paths } from '../../../../constants/paths'
import { useModal } from '../../../../hooks/use-modal'
import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ReferredPatientDetailMenu_DeleteDocument,
  ReferredPatientDetailScreenQuery,
} from '../../../../lib/gql-client'
import { useGqlMutation } from '../../../../lib/gql-client/request'
import { useAuthenticatedAccount } from '../../../auth/context'
import { ExcludeReferredPatientButton } from '../ExcludeReferredPatientButton/ExcludeReferredPatientButton'

type Props = {
  referredPatient: ReferredPatientDetailScreenQuery['referredPatient']
  mutateReferredPatient: () => void
  referredPatientsPath: string
  showDelete: boolean
  showExclude: boolean
}

export const ReferredPatientDetailMenu: React.FC<Props> = ({
  referredPatient,
  mutateReferredPatient,
  referredPatientsPath,
  showDelete,
  showExclude,
}) => {
  const {
    account: { selectedTrial },
  } = useAuthenticatedAccount()

  const navigate = useNavigate()

  const toast = useMirohaToast()

  const { showModal } = useModal()

  const deleteReferredPatient = useGqlMutation(
    ReferredPatientDetailMenu_DeleteDocument,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: '紹介患者情報を削除しました。',
        })
        navigate(
          generatePath(Paths.ReferredPatients, { trialUid: selectedTrial.uid }),
        )
      },
    },
  )

  if (!showDelete && !showExclude) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="open menu"
        icon={<MenuIcon />}
        variant="customIconButtonGhost"
      />
      <MenuList>
        {showDelete && (
          <MenuItem
            onClick={() => {
              showModal({
                title: '紹介患者情報を削除',
                content: <Text>紹介患者情報を削除します。</Text>,
                submitButtonColor: 'red',
                submitText: '削除する',
                onSubmit: async () => {
                  await deleteReferredPatient.request({
                    input: {
                      referredPatientUid: referredPatient.referredPatientUid,
                    },
                  })
                },
              })
            }}
            color="red.500"
          >
            削除
          </MenuItem>
        )}
        {showExclude && !!referredPatient.latestRefer && (
          <ExcludeReferredPatientButton
            componentType="MenuItem"
            referredPatientReferUid={
              referredPatient.latestRefer.referredPatientReferUid
            }
            numberingID={referredPatient.numberingId}
            age={referredPatient.latestDetail.age}
            gender={referredPatient.latestDetail.gender}
            patientHospitalName={
              referredPatient.patientTrialHospital.hospital.name
            }
            mutateReferredPatient={mutateReferredPatient}
            referredPatientsPath={referredPatientsPath}
          />
        )}
      </MenuList>
    </Menu>
  )
}
