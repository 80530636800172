import {
  ExplanationDocRevisionAgreementForm,
  ExplanationSignerRole,
} from '../types'

/** NOTE: completedSignerRolesを使えば良いので、APIで実装されたら削除する */
export const isSignedBy = (
  docRevision: ExplanationDocRevisionAgreementForm,
  signerRole: ExplanationSignerRole,
): boolean => {
  return docRevision.signHistories.some(
    signHistory => signHistory.signerRole === signerRole,
  )
}
