import React from 'react'

import { Button } from 'src/components/base/button/button'
import { HlsVideoPlayer } from 'src/components/HlsVideoPlayer/HlsVideoPlayer'
import styled from 'styled-components'

import { LegacyPdfViewer } from '../../../../../../../features/explanation/components/PDFViewer/LegacyPDFViewer'
import { ExplanationDocRevision } from '../../explanation/entity'
import { icfDocumentTypes } from '../../icf-document/icf-document/entity'

type Props = {
  docRevision: ExplanationDocRevision
  fetchingResult: boolean
  shouldShowSendLinkButton: boolean
  shouldShowResendLinkButton: boolean
  shouldShowFetchResultButton: boolean
  shouldShowRejectButton: boolean
  shouldShowStartDocuSignButton: boolean
  navigateToRoomTop: () => void
  onClickSendLinkButton?: () => void
  onClickResendLinkButton?: () => void
  onClickFetchResultButton?: () => void
  onClickRejectButton?: () => void
  onClickStartDocuSignButton?: () => void
}

export const ExplanationRoomDocument: React.FC<Props> = ({
  docRevision,
  fetchingResult,
  shouldShowSendLinkButton,
  shouldShowResendLinkButton,
  shouldShowFetchResultButton,
  shouldShowRejectButton,
  shouldShowStartDocuSignButton,
  navigateToRoomTop,
  onClickSendLinkButton,
  onClickResendLinkButton,
  onClickFetchResultButton,
  onClickRejectButton,
  onClickStartDocuSignButton,
}) => {
  const isVideo = docRevision.docType === icfDocumentTypes.Video

  const sendLinkText =
    docRevision.docType === icfDocumentTypes.AgreementForm
      ? '署名用リンクを送信'
      : '回答用リンクを送信'
  const resendLinkText =
    docRevision.docType === icfDocumentTypes.AgreementForm
      ? '署名用リンクを再送'
      : '回答用リンクを再送'
  const startDocuSignText =
    docRevision.docType === icfDocumentTypes.AgreementForm
      ? '署名を開始する'
      : '回答を開始する'
  // const continueDocuSignText =
  //   docRevision.docType === icfDocumentTypes.AgreementForm
  //     ? '続けて署名する'
  //     : '続けて回答する'

  return (
    <Wrapper>
      <NavigationContainer>
        <Button
          size="S"
          buttonType="cancel"
          text="説明ルームに戻る"
          onClick={navigateToRoomTop}
        />
        <RightButtonsContainer>
          {shouldShowRejectButton && (
            <Button
              size="S"
              buttonType="alert"
              text="同意拒否"
              onClick={onClickRejectButton}
            />
          )}
          {shouldShowSendLinkButton && (
            <Button
              size="S"
              buttonType="notice"
              text={sendLinkText}
              onClick={onClickSendLinkButton}
            />
          )}
          {shouldShowResendLinkButton && (
            // NOTE: figmaだと他で使用されていない緑枠の白ボタンだがどうするか
            <Button
              size="S"
              buttonType="notice"
              text={resendLinkText}
              onClick={onClickResendLinkButton}
            />
          )}
          {shouldShowFetchResultButton && (
            <Button
              size="S"
              buttonType="notice"
              text="最新状態に更新"
              onClick={onClickFetchResultButton}
              disabled={fetchingResult}
            />
          )}
          {shouldShowStartDocuSignButton && (
            <Button
              size="S"
              buttonType="notice"
              text={startDocuSignText}
              onClick={onClickStartDocuSignButton}
            />
          )}
        </RightButtonsContainer>
      </NavigationContainer>
      <ContentContainer>
        {isVideo ? (
          <VideoPlayerContainer>
            <HlsVideoPlayer url={docRevision.fileUrl} />
          </VideoPlayerContainer>
        ) : (
          <PdfViewerContainer>
            <LegacyPdfViewer url={docRevision.fileUrl} />
          </PdfViewerContainer>
        )}
      </ContentContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
`

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  box-sizing: border-box;
`

const RightButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: 24px;
  }
`

// ボタンの高さ + y方向のpadding
const NAV_CONTAINER_HEIGHT = 36 + 24 * 2

const ContentContainer = styled.div`
  height: calc(100% - ${NAV_CONTAINER_HEIGHT}px);
`

const VideoPlayerContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 80px 40px;
  box-sizing: border-box;
`

const PdfViewerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
