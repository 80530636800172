import { Fragment } from 'react'

import { useDisclosure } from '@chakra-ui/react'
import { MemberLabel } from 'src/components/MemberLabel/MemberLabel'
import { SelectItem } from 'src/components/Select/Select'
import { DeliveryDocsModal } from 'src/features/explanation/components/DeliveryDocs/DeliveryDocsModal'
import {
  ExplanationRevisionMemberFragment,
  ExplanationRevisionStatusV2,
  ExplanationRevisionForSessionInSignFrowFragment,
} from 'src/lib/gql-client'
import { getFullName } from 'src/utils/getFullName'

import { SignFlowBannerButton } from '../SignFlowBannerButton/SignFlowBannerButton'
import { SignFlowBannerStatic } from '../SignFlowBannerStatic/SignFlowBannerStatic'

type Props = {
  noDocumentToDeliver: boolean
  explanationRevision: ExplanationRevisionForSessionInSignFrowFragment
  explanationRevisionStatus: ExplanationRevisionStatusV2
  mutationSession: () => void
}

export const DeliveryBanner: React.FC<Props> = ({
  noDocumentToDeliver,
  explanationRevision,
  explanationRevisionStatus,
  mutationSession: mutateSession,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const title = '署名済文書交付'
  const done = explanationRevisionStatus === 'DeliveryCompleted'
  const ready =
    explanationRevisionStatus === 'AgreementCompleted' && !noDocumentToDeliver

  const toMailMemberSelectItem = (
    revMember: ExplanationRevisionMemberFragment,
  ): SelectItem<string, React.ReactNode> => ({
    value: revMember.trialMember.uid,
    label: (
      <MemberLabel
        role={revMember.trialMember.role}
        displayName={getFullName(revMember.trialMember.user)}
        isPartner={revMember.isPartner}
      />
    ),
  })

  return (
    <Fragment>
      {done ? (
        <SignFlowBannerStatic title={title} />
      ) : (
        <SignFlowBannerButton
          isGrayedOut={explanationRevisionStatus === 'AgreementRejected'}
          title={title}
          onClick={onOpen}
          isDisabled={!ready}
        />
      )}
      <DeliveryDocsModal
        explanationRevisionUID={explanationRevision.uid}
        isRemote={explanationRevision.explanationType === 'Remote'}
        explanationRevisionMemberItem={explanationRevision.members.map(
          toMailMemberSelectItem,
        )}
        mutateSession={mutateSession}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Fragment>
  )
}
