import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Add, Delete } from 'src/components/icon'
import { Paths } from 'src/constants/paths'
import { usePermission } from 'src/features/auth/context'
import { GetPatientReturn, usePatient } from 'src/features/patient/api'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'
import { useMirohaToast } from 'src/lib/chakra-theme/components/toast/use-miroha-toast'
import { FollowUpWorksheetInput } from 'src/lib/gql-client'
import { PERMISSIONS } from 'src/lib/permission'

import { useCreateFollowUpWorksheets } from '../../api/createFollowUpWorksheets'
import { useAddFollowUpWorksheetParam } from '../../hooks/useAddFollowUpWorksheetParam'

type Props = {
  trialUid: string
  patientUid: string
  followUp: GetPatientReturn['observationFollowUps'][number]
}

export const AddFollowUpWorksheet: React.FC<Props> = ({
  trialUid,
  patientUid,
  followUp,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { hasPermission } = usePermission()

  const { data: patient, mutate: mutatePatient } = usePatient({
    patientUid,
    revalidateIfStale: false,
  })

  const {
    onAddParam,
    onChangeName,
    onDeleteParam,
    getParams,
    reset,
    nameLengthErrors,
    isValid,
  } = useAddFollowUpWorksheetParam({
    fetchedFollowUps: patient?.observationFollowUps,
  })

  const navigate = useNavigate()
  const toast = useMirohaToast()

  const { request: createWorksheets } = useCreateFollowUpWorksheets({
    onSuccess: ({ worksheetUids }) => {
      // ワークシート一覧が更新されるように、患者情報を再取得する
      mutatePatient()
      handleClose()
      navigate(
        generatePath(Paths.PatientWorksheet, {
          trialUid,
          patientUid,
          worksheetUid: worksheetUids[0],
        }),
      )
      toast({
        title: 'ワークシートを追加しました',
        status: 'success',
      })
    },
    onError: error => {
      toast({
        title: error.message,
        status: 'error',
      })
    },
  })

  const handleSubmit = async () => {
    const inputs: FollowUpWorksheetInput[] =
      followUp.creatableWorksheetSchemas.flatMap(schema => {
        return getParams(schema.worksheetSchemaUid).map(param => ({
          worksheetSchemaUid: schema.worksheetSchemaUid,
          name: param.name,
          index: param.index,
        }))
      })

    await createWorksheets({
      followUpWorksheetInputs: inputs,
      patientObservationUid: followUp.patientObservationUid,
    })
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  if (!hasPermission(PERMISSIONS.Worksheet_Create)) {
    return null
  }

  if (!patient || patient.status === 'Disable') {
    return null
  }

  return (
    <>
      <Button
        colorScheme="blue"
        variant="outline"
        leftIcon={<Add />}
        onClick={onOpen}
      >
        ワークシート追加
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize="lg" fontWeight="bold">
              ワークシート追加
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box borderBottom="1px" borderBottomColor="gray.500" py="3">
              <Text fontWeight="bold">{followUp.name}</Text>
            </Box>

            {followUp.creatableWorksheetSchemas.map(schema => (
              <Box key={schema.worksheetSchemaUid}>
                <Flex
                  py="3"
                  justify="space-between"
                  align="center"
                  borderBottom="1px"
                  borderBottomColor="gray.100"
                >
                  <Text>{schema.name}</Text>
                  <Button
                    variant="text"
                    leftIcon={<Add />}
                    onClick={() => onAddParam(schema.worksheetSchemaUid)}
                  >
                    追加
                  </Button>
                </Flex>
                {getParams(schema.worksheetSchemaUid).map(param => (
                  <HStack
                    key={param.id}
                    spacing="4"
                    px="8"
                    py="3"
                    justify="space-between"
                    align="center"
                    borderBottom="1px"
                    borderBottomColor="gray.100"
                  >
                    <HStack spacing="3" w="full">
                      <Text
                        wordBreak="keep-all"
                        fontWeight="bold"
                      >{`#${param.index}`}</Text>
                      <Stack w="full">
                        <Input
                          value={param.name}
                          onChange={e =>
                            onChangeName({
                              schemaUid: schema.worksheetSchemaUid,
                              id: param.id,
                              name: e.target.value,
                            })
                          }
                          placeholder="ワークシート表示名を入力してください"
                        />
                        {!!nameLengthErrors[param.id] && (
                          <Text color="red.500" fontSize="xs">
                            {nameLengthErrors[param.id]}
                          </Text>
                        )}
                      </Stack>
                    </HStack>
                    <IconButton
                      icon={<Delete />}
                      aria-label="追加をキャンセル"
                      variant="customIconButtonGhost"
                      onClick={() =>
                        onDeleteParam({
                          id: param.id,
                          schemaUid: schema.worksheetSchemaUid,
                        })
                      }
                    />
                  </HStack>
                ))}
              </Box>
            ))}
          </ModalBody>

          <ModalFooter>
            <ModalCancelButton />
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isDisabled={!isValid}
            >
              追加する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
