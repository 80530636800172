// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgArrange = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="M6 15.9.3 10.2c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L4 11.1V1c0-.6.4-1 1-1s1 .4 1 1v14.9ZM11 16c-.6 0-1-.4-1-1V.1l5.7 5.7c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 4.9V15c0 .6-.4 1-1 1Z"
    />
  </svg>
)
export default SvgArrange
