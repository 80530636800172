import React, { LiHTMLAttributes } from 'react'

import { Text } from '@chakra-ui/react'
import { blue, gray, white } from 'src/components/base/color/palette'
import styled from 'styled-components'

export type TabElement = {
  id: string
  label: string
  selected: boolean
  onClick: () => void
}

/**
 * @deprecated Use chakra-ui Tabs instead.
 */
export const Tabs: React.FC<{
  tabElements: TabElement[]
}> = ({ tabElements }) => {
  return (
    <TabPanel>
      {tabElements.map(element => (
        <Tab
          key={element.id}
          id={element.id}
          onClick={element.onClick}
          selected={element.selected}
        >
          {element.label}
        </Tab>
      ))}
    </TabPanel>
  )
}

type TabProps = {
  selected?: boolean
  children: React.ReactNode
} & LiHTMLAttributes<HTMLLIElement>

/**
 * @deprecated Use chakra-ui Tab instead.
 */
export const Tab: React.FC<TabProps> = ({ selected, children, ...rest }) => {
  return (
    <TabItem selected={selected} {...rest}>
      <Text>{children}</Text>
    </TabItem>
  )
}

/**
 * @deprecated Use chakra-ui TabPanel instead.
 */
export const TabPanel = styled.ul`
  background-color: ${gray[10]};
  margin: 0;
  list-style-type: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 4px;
  width: fit-content;
`

const TabItem = styled.li<{ selected?: boolean }>`
  cursor: pointer;
  padding: 4px 36px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => (p.selected ? white : gray[100])};
  background-color: ${p => (p.selected ? blue[70] : gray[10])};
`
