import { useBlockTransition } from 'src/hooks/useBlockTransition'

import { useWorksheetFieldStickiesContext } from '../../context/WorksheetFieldStickies'
import { useWorksheetValueContext } from '../../context/WorksheetValue'

type WorksheetTransitionBlockerProps = {
  children: React.ReactNode
}

export const WorksheetTransitionBlocker: React.FC<
  WorksheetTransitionBlockerProps
> = ({ children }) => {
  const { isChanged: isWorksheetValueChanged } = useWorksheetValueContext()

  const { isEditing: isEditingSticky } = useWorksheetFieldStickiesContext()

  useBlockTransition({
    showAlert: isWorksheetValueChanged || isEditingSticky,
  })

  return <>{children}</>
}
