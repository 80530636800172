import { useCallback, useMemo } from 'react'

import {
  createApiClient,
  RequestResponse,
  ApiErrorFromPathAndMethod,
  ApiPathParamFromPathAndMethod,
  ApiQueryParam,
  ApiResponseFromPathAndMethod,
  ExactPathByHttpMethod,
} from 'src/lib/api-client'
import useSWR, { SWRConfiguration } from 'swr'

type GetPath = ExactPathByHttpMethod<'get'>

type UseFetchOption<Path extends GetPath> = {
  path: Path
  params?: {
    paths?: ApiPathParamFromPathAndMethod<Path, 'get'>
    query?: ApiQueryParam<Path, 'get'>
  }
  shouldCancel?: boolean
  onSuccess?: (data: ApiResponseFromPathAndMethod<Path, 'get'>) => void
  onError?: (error: ApiErrorFromPathAndMethod<Path, 'get'>) => void
} & Omit<SWRConfiguration, 'onSuccess' | 'onError'>

export const useApiClientFetch = <Path extends GetPath>(
  options: UseFetchOption<Path>,
) => {
  const { path, params, onSuccess, onError, shouldCancel, ...swrOptions } =
    options

  const api = createApiClient({ path, httpMethod: 'get', params })

  const {
    data: swrData,
    mutate: swrMutate,
    isValidating,
  } = useSWR<RequestResponse<Path, 'get'>>(
    shouldCancel ? null : api.path(),
    () => api.request(),
    {
      onSuccess: res => {
        if (res.result === 'success' && !!onSuccess) {
          onSuccess(res.data)
          return
        }
        if (res.result === 'error' && !!onError) {
          onError(res.error)
          return
        }
      },
      // リクエストエラー以外のエラーを処理する
      // onError: e => {}

      ...swrOptions,
    },
  )

  const data = useMemo(() => {
    if (!swrData) return undefined
    if (swrData.result !== 'success') return undefined
    return swrData.data
  }, [swrData])

  const error = useMemo(() => {
    if (!swrData) return undefined
    if (swrData.result !== 'error') return undefined
    return swrData.error
  }, [swrData])

  const mutate = useCallback(
    (
      data?: ApiResponseFromPathAndMethod<Path, 'get'>,
      shouldRevalidate?: boolean,
    ) => {
      swrMutate(
        data ? { result: 'success', data } : undefined,
        shouldRevalidate,
      )
    },
    [swrMutate],
  )

  return { data, error, mutate, isValidating }
}
