import {
  GqlError,
  GetSessionBasicInfoQuery,
  GetSessionBasicInfoDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

const getSessionBasicInfo = async ({
  explanationSessionUid,
  forPatient,
}: {
  explanationSessionUid: string
  forPatient: boolean
}): Promise<GetSessionBasicInfoQuery> => {
  try {
    const res = await graphqlRequest(GetSessionBasicInfoDocument, {
      explanationSessionUid,
      forPatient,
    })
    return res
  } catch (error) {
    throw error
  }
}

export const useSessionBasicInfo = ({
  explanationSessionUid,
  forPatient,
  ...option
}: {
  explanationSessionUid: string
  forPatient: boolean
} & SWRConfiguration<GetSessionBasicInfoQuery, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionBasicInfo(explanationSessionUid),
    () => getSessionBasicInfo({ explanationSessionUid, forPatient }),
    option,
  )
}
