import React from 'react'

import { Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { OutputModal } from 'src/components/modal/output'
import { Spacer } from 'src/components/spacer/spacer'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { QueryParamKeys } from 'src/modules/router'
import { routes } from 'src/modules/routes'
import styled from 'styled-components'

import { downloadFile } from './request'

type Props = {
  patientUid: string
  diseaseUid: string
  onClose: () => void
}

export const OutputModalContainer: React.FC<Props> = props => {
  const { patientUid } = props

  const trial = useSelector(getSelectedTrial)!
  const { videoEnabled } = trial

  const onSubmit = ({
    isHistory,
    isWorksheet,
    isCallHistory,
  }: {
    isHistory: boolean
    isWorksheet: boolean
    isCallHistory: boolean
  }) => {
    const url = `${routes.patientPdf}/${trial.uid}/${patientUid}?${QueryParamKeys.History}=${isHistory}&${QueryParamKeys.Worksheet}=${isWorksheet}&${QueryParamKeys.CallHistory}=${isCallHistory}`
    window.open(url, '_blank')
    props.onClose()
  }

  return (
    <OutputModal
      title={`患者ワークシートのデータ出力`}
      submitText={`出力`}
      errorMessage={``}
      requesting={false}
      videoEnabled={videoEnabled}
      onSubmit={onSubmit}
      onClose={props.onClose}
      onDownloadCapture={() => {
        const download = async () => {
          const res = await downloadFile({
            trialUid: trial.uid,
            patientUid,
          })
          const link = document.createElement('a')
          link.href = res.url
          link.click()
          link.remove()
        }

        download()
      }}
    >
      <Text textAlign="center">
        症例番号：<Bold>{props.diseaseUid}</Bold>
        さんの患者ワークシートを画面出力します。
      </Text>
      <Spacer size={10} />
      <Text textAlign="center">
        出力結果を印刷機能を使ってPDF形式などで保存をお願いします。
      </Text>
      <Spacer size={10} />
      <Text fontSize="xs" textAlign="center">
        ※出力完了まで時間がかかる場合があります。
      </Text>
    </OutputModal>
  )
}

const Bold = styled.span`
  font-weight: bold;
`
