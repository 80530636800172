import React, { useState, useEffect } from 'react'

import { Text } from '@chakra-ui/react'
import { zIndex } from 'src/modules/util/z-index'
import { sleep } from 'src/utils/sleep'
import styled from 'styled-components'

import {
  Success,
  Info,
  Close,
  Error,
  Warning,
} from '../__legacy__icon/monochrome'
import { blue, green, red, yellow } from '../base/color/palette'
import { Spacer } from '../spacer/spacer'

export type Message = {
  body: string
  type: 'Success' | 'Info' | 'Error' | 'Warning'
  uid: string
  option?: MessageOption
}

export type MessageOption = {
  description?: string
  durationMsec?: number
  closable?: boolean
}

type Props = {
  message: Message
  onComplete: () => void
}

const assertUnreachable = (type: never) => {
  throw type
}

const backgroundColor = (type: Message['type']) => {
  switch (type) {
    case 'Success':
      return blue[10]
    case 'Info':
      return green[10]
    case 'Error':
      return red[10]
    case 'Warning':
      return yellow[10]
    default:
      return assertUnreachable(type)
  }
}
const borderColor = (type: Message['type']) => {
  switch (type) {
    case 'Success':
      return blue[70]
    case 'Info':
      return green[40]
    case 'Error':
      return red[50]
    case 'Warning':
      return yellow[70]
    default:
      return assertUnreachable(type)
  }
}
const statusIcon = (type: Message['type']) => {
  switch (type) {
    case 'Success':
      return <Success size="S" color={blue[70]} />
    case 'Info':
      return <Info size="S" color={green[40]} />
    case 'Error':
      return <Error size="S" color={red[50]} />
    case 'Warning':
      return <Warning size="S" color={yellow[70]} />
    default:
      return assertUnreachable(type)
  }
}

const closeIcon = (type: Message['type']) => {
  switch (type) {
    case 'Success':
      return <Close size="S" color={blue[70]} />
    case 'Info':
      return <Close size="S" color={green[40]} />
    case 'Error':
      return <Close size="S" color={red[50]} />
    case 'Warning':
      return <Close size="S" color={yellow[70]} />
    default:
      return assertUnreachable(type)
  }
}

const Container = styled.div`
  width: 100%;
`

const MessageContainer = styled.div<{
  visible: boolean
  type: Message['type']
}>`
  width: 100%;
  max-width: 800px;
  margin: 16px auto 0;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: ${zIndex.messageTab};
  transform: ${props =>
    props.visible
      ? 'translatey(0)'
      : 'translatey(-130%)'}; // 上に消える際のカクツキを無くすため比率を上げて対応
  top: 0;
  right: 0;
  left: 0;
  background: ${props => backgroundColor(props.type)};
  border: 1px solid ${props => borderColor(props.type)};
  border-radius: 6px;

  transition: 0.12s ease-in-out;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`

const TextWrapper = styled.div`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  word-wrap: break-word;
`

const DeleteIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
`

const DeleteButton = styled.button`
  outline: none;
  border: unset;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

/**
 * @deprecated Use chakra-ui Alert instead.
 */
export const MessageTab: React.FC<Props> = props => {
  const { message, onComplete } = props
  const { option } = message

  const [visible, setVisible] = useState(false)

  const duration = option && option.durationMsec ? option.durationMsec : 3000
  const closable = option && option.closable
  const description = option && option.description

  useEffect(() => {
    let unmounted = false

    const animate = async () => {
      await sleep(100)
      if (unmounted) return
      setVisible(true)

      await sleep(duration)
      if (unmounted) return
      setVisible(false)
      await sleep(500)
      if (unmounted) return
      onComplete()
    }

    animate()

    return () => {
      unmounted = true
    }
  }, [onComplete, message.uid, duration])

  const handleClose = () => {
    setVisible(false)
    onComplete()
  }

  const text = message.body.split('\n')
  const hasMultipleLine = text.length > 1

  return (
    <Container>
      <MessageContainer visible={visible} type={message.type}>
        <TextContainer>
          {statusIcon(message.type)}

          <Spacer size={10} horizontal />

          <TextWrapper>
            {hasMultipleLine ? (
              text.map(t => (
                <Text key={t} fontSize="lg" fontWeight="bold">
                  {t}
                  <br />
                </Text>
              ))
            ) : (
              <Text fontSize="lg" fontWeight="bold">
                {text[0]}
              </Text>
            )}
            {description && <Text fontSize="sm">{description}</Text>}
          </TextWrapper>
        </TextContainer>

        {closable && (
          <DeleteIconContainer>
            <DeleteButton onClick={handleClose}>
              {closeIcon(message.type)}
            </DeleteButton>
          </DeleteIconContainer>
        )}
      </MessageContainer>
    </Container>
  )
}
