import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Button } from 'src/components/base/button/button'
import { getSingle } from 'src/modules/entities/member/selector'
import { RootState } from 'src/modules/reducer'

import { DisableModal } from './disable-modal'
import { trialIdParamName } from '../../trial-detail'

type Props = {
  memberUid: string
}

export const MemberDisableButton: React.FC<Props> = props => {
  const { memberUid } = props
  const [modalOpen, setModalOpen] = useState(false)

  const user = useSelector((state: RootState) => getSingle(state, memberUid))

  const { trialUid = '' } = useParams<{ [trialIdParamName]: string }>()

  return (
    <>
      <Button
        text="無効化"
        size="S"
        buttonType="normal"
        onClick={() => setModalOpen(true)}
      />
      {modalOpen && (
        <DisableModal
          userName={`${user.lastName} ${user.firstName}`}
          closeModal={() => setModalOpen(false)}
          trialUid={trialUid}
          memberUid={memberUid}
        />
      )}
    </>
  )
}
