import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { ConfirmWorksheetDocument, GqlError } from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'

import { Worksheet, WorksheetDetail } from '../../types'
import { parseGqlWorksheetResponse } from '../parser'

const confirmWorksheet = async ({
  worksheetUid,
}: {
  worksheetUid: Worksheet['uid']
}): Promise<WorksheetDetail> => {
  try {
    const res = await graphqlRequest(ConfirmWorksheetDocument, {
      worksheetUid,
    })
    return parseGqlWorksheetResponse(res.confirmWorksheet.worksheet)
  } catch (error) {
    throw error
  }
}

export const useConfirmWorksheet = (
  options?: UseMutationOption<WorksheetDetail, GqlError>,
) => {
  return useMutation(
    ({ worksheetUid }: { worksheetUid: Worksheet['uid'] }) =>
      confirmWorksheet({ worksheetUid }),
    options,
  )
}
