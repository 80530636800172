import { HttpResponse } from 'msw'
import {
  createUpdateWorksheetNamePayloadMock,
  UpdateWorksheetNameDocument,
  UpdateWorksheetNameMutation,
  UpdateWorksheetNameMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const updateWorksheetNameHandler = graphQLHandlerFactory<
  UpdateWorksheetNameMutation,
  UpdateWorksheetNameMutationVariables
>('mutation', UpdateWorksheetNameDocument, () => {
  return HttpResponse.json({
    data: {
      updateWorksheetName: createUpdateWorksheetNamePayloadMock(),
    },
  })
})
