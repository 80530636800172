import { HttpResponse } from 'msw'
import {
  createPatientObservationVisitMock,
  createPatientMock,
  createTrialHospitalMock,
  createWorksheetLogMock,
  createWorksheetMock,
  Patient,
  createHospitalMock,
  createPatientObservationFollowUpMock,
  createWorksheetSchemaMock,
  GetPatientDocument,
  GetPatientQuery,
  GetPatientQueryVariables,
} from 'src/lib/gql-client'
import { hospitalName1 } from 'src/tests/factory'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'
import { generateUUID } from 'src/utils/generateUUID'

export const getPatientHandler = graphQLHandlerFactory<
  GetPatientQuery,
  GetPatientQueryVariables
>('query', GetPatientDocument, () => {
  return HttpResponse.json({
    data: {
      patient: patientMock(),
    },
  })
})

const schemaUid1 = '078a3b7e-0b0a-4e1a-9b1a-0b3b9a1a1a1a'

export const patientMock = (
  override?: Partial<Patient>,
): GetPatientQuery['patient'] => {
  return createPatientMock({
    diseaseUid: '0001',
    firstName: '太郎',
    lastName: '邁進',
    status: 'Enable',
    email: '',
    partnerTrialHospital: createTrialHospitalMock({
      hospital: createHospitalMock({
        name: hospitalName1,
      }),
    }),
    observationVisits: [
      createPatientObservationVisitMock({
        name: 'visit1',
        worksheets: Array.from({ length: 5 }, (_, i) =>
          worksheet(`worksheet-${i + 1}`),
        ),
      }),
      createPatientObservationVisitMock({
        name: 'visit2',
        worksheets: Array.from({ length: 5 }, (_, i) =>
          worksheet(`worksheet-${i + 6}`),
        ),
      }),
    ],
    observationFollowUps: [
      createPatientObservationFollowUpMock({
        name: 'followup1',
        creatableWorksheetSchemas: [
          createWorksheetSchemaMock({
            name: 'follow-up-1',
            worksheetSchemaUid: schemaUid1,
          }),
          createWorksheetSchemaMock({ name: 'follow-up-2' }),
        ],
        worksheets: Array.from({ length: 2 }, (_, i) =>
          worksheet(`follow-up-1（${i + 1}）`, {
            schemaUid: schemaUid1,
            schemaName: 'follow-up-1',
            index: i + 1,
          }),
        ),
      }),
      createPatientObservationFollowUpMock({
        name: 'followup2',
      }),
    ],
    ...override,
  }) as GetPatientQuery['patient']
}

type Option = {
  schemaUid?: string
  index?: number
  schemaName?: string
}

const worksheet = (name: string, option?: Option) => {
  return createWorksheetMock({
    worksheetSchemaUid: option?.schemaUid ?? generateUUID(),
    name,
    index: option?.index ?? 0,
    worksheetSchema: createWorksheetSchemaMock({
      name: option?.schemaName ?? name,
    }),
    latestWorksheetLog: createWorksheetLogMock({
      status: 'Saved',
    }),
  })
}
