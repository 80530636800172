import { useState } from 'react'

import { useSelector } from 'react-redux'
import { useLocation, generatePath } from 'react-router-dom'
import {
  Telemedicine,
  Template,
  EConsent,
  EPro,
  Setting,
} from 'src/components/__legacy__icon/monochrome'
import { Paths } from 'src/constants/paths'
import { colors } from 'src/lib/chakra-theme/foundations/colors'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { getCurrentUser } from 'src/modules/entities/account/selector'
import { assertNever } from 'src/utils/assertNever'

import { definitions } from '../../../lib/api-client/schema.gen'

type IconName = 'patient' | 'template' | 'eConsent' | 'ePRO' | 'setting'

type NavItemWithoutSubmenu = {
  hasSubmenu: false
  path: string
  name: string
  iconName: IconName
}

type SubmenuItem = {
  name: string
  path: string
}

type NavItemWithSubmenu = {
  hasSubmenu: true
  basePath: string
  name: string
  iconName: IconName
  submenus: SubmenuItem[]
}

type NavItem = NavItemWithoutSubmenu | NavItemWithSubmenu

export const useLegacyNavigation = () => {
  const account = useSelector(getCurrentUser)!
  const selectedTrial = account.selectedTrial!
  const { hasPermission } = usePermission()

  const navItems = getNavItems({
    trialUid: selectedTrial.uid,
    showPatients: hasPermission(PERMISSIONS.Menu_ShowPatients),
    showTemplate: hasPermission(PERMISSIONS.Menu_ShowTemplate),
    showNewEconsent: selectedTrial.newEconsentEnabled,
    showEPro: selectedTrial.eProEnabled,
    showSettings: hasPermission(PERMISSIONS.Menu_ShowSettings),
    featureFlags: selectedTrial.featureFlags,
  })

  const [navOpen, setNavOpen] = useState(true)

  const generateIcon = (iconName: IconName) => {
    switch (iconName) {
      case 'patient':
        return <Telemedicine size="L" color={colors.gray[600]} />
      case 'template':
        return <Template size="L" color={colors.gray[600]} />
      case 'eConsent':
        return <EConsent size="L" color={colors.gray[600]} />
      case 'ePRO':
        return <EPro size="L" color={colors.gray[600]} />
      case 'setting':
        return <Setting size="L" color={colors.gray[600]} />
      default:
        return assertNever(iconName)
    }
  }

  const { pathname } = useLocation()

  const checkIsOpenMainMenu = (navItem: NavItem) => {
    if (navItem.hasSubmenu) {
      return pathname.startsWith(navItem.basePath)
    }

    return pathname.startsWith(navItem.path)
  }

  const checkIsOpenSubMenu = (submenuItem: SubmenuItem) => {
    return pathname.startsWith(submenuItem.path)
  }

  const handleToggleNavOpen = () => {
    setNavOpen(prevState => !prevState)
  }

  return {
    navOpen,
    navItems,
    generateIcon,
    checkIsOpenMainMenu,
    checkIsOpenSubMenu,
    handleToggleNavOpen,
  }
}

const getNavItems = ({
  trialUid,
  showSettings,
  showPatients,
  showTemplate,
  showNewEconsent,
  showEPro,
  featureFlags,
}: {
  trialUid: string
  showSettings: boolean
  showPatients: boolean
  showTemplate: boolean
  showNewEconsent: boolean
  showEPro: boolean
  featureFlags: definitions['featureflag.Flags']
}): NavItem[] => {
  const navItems: NavItem[] = []

  if (showPatients) {
    navItems.push({
      hasSubmenu: false,
      path: generatePath(Paths.Patients, { trialUid }),
      name: '患者',
      iconName: 'patient',
    })
  }

  if (showTemplate) {
    navItems.push({
      hasSubmenu: false,
      path: generatePath(Paths.Templates, { trialUid }),
      name: 'テンプレート',
      iconName: 'template',
    })
  }

  if (showNewEconsent) {
    navItems.push({
      hasSubmenu: true,
      basePath: generatePath(Paths.EConsent, { trialUid }),
      name: 'eConsent',
      iconName: 'eConsent',

      submenus: [
        {
          name: '説明同意',
          path: generatePath(Paths.Explanations, { trialUid }),
        },
        {
          name: '文書管理',
          path: generatePath(Paths.IcfDocuments, { trialUid }),
        },
      ],
    })
  }

  if (showEPro) {
    navItems.push({
      hasSubmenu: true,
      basePath: generatePath(Paths.EPRO, { trialUid }),
      name: 'ePRO',
      iconName: 'ePRO',

      submenus: [
        {
          name: '患者回答',
          path: generatePath(Paths.PatientAnswers, { trialUid }),
        },
        {
          name: '質問票',
          path: generatePath(Paths.Questionnaires, { trialUid }),
        },
      ],
    })
  }

  if (showSettings) {
    const submenuItems = [
      {
        name: '試験情報',
        path: generatePath(Paths.SettingsTrial, { trialUid }),
        disable: false,
      },
      {
        name: 'アカウント情報',
        path: generatePath(Paths.SettingsMember, { trialUid }),
        disable: false,
      },
      {
        name: 'CRF管理',
        path: generatePath(Paths.SettingsCrfUpload, { trialUid }),
        disable: !featureFlags.eSourceV1,
      },
      {
        name: '症例データ管理',
        path: generatePath(Paths.SettingsWSDataExport, { trialUid }),
      },
    ]
    navItems.push({
      hasSubmenu: true,
      basePath: generatePath(Paths.Settings, { trialUid }),
      name: '設定',
      iconName: 'setting',

      submenus: submenuItems.filter(m => !m.disable),
    })
  }

  return navItems
}
