import { explanationsHandlers } from 'src/features/explanation/api/_mocks_'
import { icfDocumentHandlers } from 'src/features/icfDocument/api/_mocks_'
import { memberHandlers } from 'src/features/member/api/_mocks_'
import { patientHandlers } from 'src/features/patient/api/_mocks_'
import { trialHandlers } from 'src/features/trial/api/_mocks_'
import { worksheetHandlers } from 'src/features/worksheet/api/_mocks_'

export const handlers = [
  ...explanationsHandlers,
  ...icfDocumentHandlers,
  ...memberHandlers,
  ...patientHandlers,
  ...trialHandlers,
  ...worksheetHandlers,
]
