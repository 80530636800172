import { HttpResponse } from 'msw'
import {
  createDeleteStickyMessagePayloadMock,
  DeleteStickyMessageDocument,
  DeleteStickyMessageMutation,
  DeleteStickyMessageMutationVariables,
} from 'src/lib/gql-client'
import { graphQLHandlerFactory } from 'src/tests/mocks/handlerFactory'

export const deleteStickyMessageHandler = graphQLHandlerFactory<
  DeleteStickyMessageMutation,
  DeleteStickyMessageMutationVariables
>('mutation', DeleteStickyMessageDocument, () => {
  return HttpResponse.json({
    data: { deleteStickyMessage: createDeleteStickyMessagePayloadMock() },
  })
})
