import React from 'react'

import { useSelector } from 'react-redux'
import { PERMISSIONS } from 'src/lib/permission'
import { usePermission } from 'src/modules/dashboard/trial/detail/common/permission'
import { useFetch } from 'src/modules/dashboard/trial/detail/patient/list/use-fetch'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { getList as getQuestionnaireList } from 'src/modules/entities/questionnaire/selector'

import { List } from './list'
import { getPatientList } from '../../../patient/list/selector'
import { useFetchList as useFetchQuestionnaireList } from '../../questionnaire/list/list-container'

type Props = {}

// 初期実装ではページネーションは行わないので十分に大きな数で fetch を行う
const perPage: number = 100000

export const ListContainer: React.FC<Props> = props => {
  const selectedTrial = useSelector(getSelectedTrial)!
  const patients = useSelector(getPatientList)
  const { fetched } = useFetch({ trialUid: selectedTrial.uid, per: perPage })

  const { uid: trialUid } = useSelector(getSelectedTrial)!
  const { fetched: isQuestionnairesFetched } = useFetchQuestionnaireList({
    trialUid,
  })
  const questionnaires = useSelector(getQuestionnaireList)

  const { hasPermission } = usePermission()
  const canExportCSV = hasPermission(PERMISSIONS.Questionnaire_ExportAnswerCsv)

  if (!fetched || !isQuestionnairesFetched) return null

  return (
    <List
      patients={patients}
      trialUid={selectedTrial.uid}
      questionnaires={questionnaires}
      canExportCSV={canExportCSV}
    />
  )
}
