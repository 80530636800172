import dayjs from 'dayjs'
import { hasSameElements } from 'src/modules/util/array-comparison'
import { assertNever } from 'src/utils/assertNever'

import { FieldValue } from '../types'

export const isFieldValueChanged = (
  value1: FieldValue | undefined,
  value2: FieldValue | undefined,
): boolean => {
  if (value1 === undefined && value2 === undefined) {
    return false
  }
  if (value1?.type === 'cleared' || value2?.type === 'cleared') {
    return value1?.type !== value2?.type
  }

  switch (value1?.type) {
    case undefined:
      return isFieldValueChanged(value2, value1)

    case 'text':
      if (value2 === undefined) {
        return value1.textValue !== ''
      }
      if (value2.type !== 'text') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.textValue !== value2.textValue

    case 'textarea':
      if (value2 === undefined) {
        return value1.textareaValue !== ''
      }
      if (value2.type !== 'textarea') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.textareaValue !== value2.textareaValue

    case 'number':
      if (value2 === undefined) {
        return value1.numberValue !== ''
      }
      if (value2.type !== 'number') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.numberValue !== value2.numberValue

    case 'selectMenu':
      if (value2 === undefined) {
        return value1.selectMenuValue !== ''
      }
      if (value2.type !== 'selectMenu') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.selectMenuValue !== value2.selectMenuValue

    case 'checkbox':
      if (value2 === undefined) {
        return value1.checkboxValue.length > 0
      }
      if (value2.type !== 'checkbox') {
        throw new Error('cannot compare different type of field value')
      }
      if (value1.checkboxValue.length !== value2.checkboxValue.length) {
        return true
      }
      return !hasSameElements(value1.checkboxValue, value2.checkboxValue)

    case 'radio':
      if (value2 === undefined) {
        return value1.radioValue !== ''
      }
      if (value2.type !== 'radio') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.radioValue !== value2.radioValue

    case 'date':
      if (value2 === undefined) {
        return value1.dateValue !== ''
      }
      if (value2.type !== 'date') {
        throw new Error('cannot compare different type of field value')
      }
      return (
        dayjs(value1.dateValue).format('YYYY-MM-DD') !==
        dayjs(value2.dateValue).format('YYYY-MM-DD')
      )

    case 'nullableDate':
      if (value2 === undefined) {
        return value1.nullableDateValue !== ''
      }
      if (value2.type !== 'nullableDate') {
        throw new Error('cannot compare different type of field value')
      }

      // NOTE: 以前の値と入力値の正規表現が若干異なっているので、それを考慮して比較する。要リファクタ。
      // dayjsでのキャスト比較では月日が空文字の場合に01-01が入るので1月の場合に比較がうまくいかないので使用しない
      const prevRegex = /^(\d{4})(-(\d+))?(-(\d+))?$/
      const prevValue = value1.nullableDateValue.match(prevRegex)
      const prevYear = prevValue ? prevValue[1] : undefined
      const prevMonth = prevValue ? prevValue[3] : undefined
      const prevDay = prevValue ? prevValue[5] : undefined

      const inputRegex = /^(\d{4})-(\d+)?-(\d+)?$/
      const inputValue = value2.nullableDateValue.match(inputRegex)
      const inputYear = inputValue ? inputValue[1] : undefined
      const inputMonth = inputValue
        ? inputValue[2]?.padStart(2, '0')
        : undefined
      const inputDay = inputValue ? inputValue[3]?.padStart(2, '0') : undefined

      return (
        prevYear !== inputYear ||
        prevMonth !== inputMonth ||
        prevDay !== inputDay
      )

    case 'time':
      if (value2 === undefined) {
        return value1.timeValue !== ''
      }
      if (value2.type !== 'time') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.timeValue !== value2.timeValue

    case 'file':
      if (value2 === undefined) {
        return true
      }
      if (value2.type !== 'file') {
        throw new Error('cannot compare different type of field value')
      }
      if (value1.files.length === 0) {
        return false
      }
      return (
        value1.files.length !== value2.files.length ||
        value1.files.some(
          (file, i) =>
            file.order !== value2.files[i].order ||
            file.memo !== value2.files[i].memo ||
            file.uploadedFile.uid !== value2.files[i].uploadedFile.uid,
        )
      )

    case 'repeatableSection':
      if (value2 === undefined) {
        return true
      }
      if (value2.type !== 'repeatableSection') {
        throw new Error('cannot compare different type of field value')
      }
      return value1.isEnabled !== value2.isEnabled

    default:
      return assertNever(value1)
  }
}
