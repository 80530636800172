import { useCallback, useEffect, useMemo, useState } from 'react'

import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'src/lib/permission'
import { getSelectedTrial } from 'src/modules/entities/account/selector'
import { useFlash } from 'src/modules/flash/use-flash'
import { isRequestError } from 'src/modules/server/request'
import { generateUUID } from 'src/utils/generateUUID'

import { useIcfDocumentHospital } from './../../use-icf-document-hospital'
import {
  CreateIcfDocumentItem,
  IcfDocumentType,
  isDocuSignType,
} from './../entity'
import {
  bulkCreateIcfDocument,
  uploadDocuSignFile,
  uploadTempFile,
} from './../request'
import { IcfDocumentErrorMap } from './add'
import { usePermission } from '../../../../common/permission'
import { getIcfDocumentListRoute } from '../../routes'
import { validateIcfDocument } from '../validate'

export const useAddIcfDocument = () => {
  const initialItem = createEmpty()
  const initialMap = new Map([[initialItem.uid, initialItem]])

  const [createIcfDocumentItemMap, setCreateIcfDocumentMap] =
    useState<Map<CreateIcfDocumentItem['uid'], CreateIcfDocumentItem>>(
      initialMap,
    )

  const [submitModalOpen, setSubmitModalOpen] = useState(false)
  const [tempFileUrlMap, setTempFileUrlMap] = useState<
    Map<CreateIcfDocumentItem['uid'], string>
  >(new Map())
  const [errorMap, setErrorMap] = useState<IcfDocumentErrorMap>({})
  const [submitTried, setSubmitTried] = useState(false)
  const [cancelTried, setCancelTried] = useState(false)

  const { uid: trialUid, trialHospitals } = useSelector(getSelectedTrial)!
  const { showSuccess, showError } = useFlash()
  const navigate = useNavigate()
  const { selectedTrialHospitalUid } = useIcfDocumentHospital()
  const { hasPermission } = usePermission()

  const createIcfDocumentItems = Object.values(
    Object.fromEntries(createIcfDocumentItemMap),
  )

  const editing = useMemo(() => {
    if (submitTried) {
      return false
    }

    if (cancelTried) {
      return false
    }

    return !equal(createIcfDocumentItems, [initialItem])
  }, [submitTried, cancelTried, createIcfDocumentItems, initialItem])

  const onAddItem = useCallback(() => {
    const newItem = createEmpty()

    const newMap = new Map(createIcfDocumentItemMap)
    newMap.set(newItem.uid, newItem)
    setCreateIcfDocumentMap(newMap)
  }, [createIcfDocumentItemMap])

  const onDeleteItem = useCallback(
    (uid: string) => {
      const item = createIcfDocumentItemMap.get(uid)
      if (item === undefined) {
        return
      }

      const newMap = new Map(createIcfDocumentItemMap)
      newMap.delete(uid)
      setCreateIcfDocumentMap(newMap)
    },
    [createIcfDocumentItemMap],
  )

  //ネストした項目もオプショナルにするための型。Partialの拡張
  type NestedPartial<T> = {
    [P in keyof T]?: T[P] extends object ? NestedPartial<T[P]> : T[P]
  }

  const updateMap = useCallback(
    (uid: string, partialItem: NestedPartial<CreateIcfDocumentItem>) => {
      const item = createIcfDocumentItemMap.get(uid)
      if (item === undefined) {
        return
      }

      const newMap = new Map(createIcfDocumentItemMap)
      newMap.set(uid, {
        ...item,
        ...partialItem,
        revision: { ...item.revision, ...partialItem.revision },
      })
      setCreateIcfDocumentMap(newMap)
    },
    [createIcfDocumentItemMap],
  )

  const onChangeDocumentType = useCallback(
    ({ uid, type }: { uid: string; type: IcfDocumentType }) => {
      updateMap(uid, { type })
    },
    [updateMap],
  )

  const onChangeVersion = useCallback(
    ({ uid, value }: { uid: string; value: string }) => {
      updateMap(uid, { revision: { version: value } })
    },
    [updateMap],
  )

  const onChangeName = useCallback(
    ({ uid, value }: { uid: string; value: string }) => {
      updateMap(uid, { revision: { name: value } })
    },
    [updateMap],
  )

  const uploadDocuSign = useCallback(
    async ({
      uid,
      trialUid,
      trialHospitalUid,
      file,
      formData,
      type,
    }: {
      uid: string
      trialUid: string
      trialHospitalUid: string
      file: File
      formData: FormData
      type: IcfDocumentType
    }) => {
      try {
        const docuSignResp = await uploadDocuSignFile({
          trialUid,
          trialHospitalUid,
          params: {
            formData,
            type,
          },
        })

        updateMap(uid, {
          tempFileUid: docuSignResp.tempFileUid,
          dsTemplateId: docuSignResp.dsTemplateId,
          revision: { fileName: file.name },
        })
        setTempFileUrlMap(currentMap =>
          new Map(currentMap).set(uid, docuSignResp.fileUrl),
        )
      } catch (e) {
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [updateMap, showError],
  )

  const uploadTemp = useCallback(
    async ({
      uid,
      trialUid,
      trialHospitalUid,
      file,
      params,
    }: {
      uid: string
      trialUid: string
      trialHospitalUid: string
      file: File
      params: FormData
    }) => {
      try {
        const tempFileResp = await uploadTempFile({
          trialUid,
          trialHospitalUid,
          params,
        })

        updateMap(uid, {
          tempFileUid: tempFileResp.tempFileUid,
          revision: { fileName: file.name },
        })
        // NOTE: 一時ファイルの時点では、動画ファイルはMediaConvertで変換したものではなく、元ファイルを返す
        setTempFileUrlMap(currentMap =>
          new Map(currentMap).set(uid, tempFileResp.fileUrl),
        )
      } catch (e) {
        if (isRequestError(e)) {
          showError(e.message)
        }

        throw e
      }
    },
    [updateMap, showError],
  )

  const onSelectFile = useCallback(
    async ({ uid, file }: { uid: string; file: File }) => {
      if (selectedTrialHospitalUid === undefined) {
        throw new Error('trialHospitalUid does not set')
      }

      const item = createIcfDocumentItemMap.get(uid)
      if (item?.type === undefined) {
        return
      }

      const errors = validateIcfDocument({ key: 'fileSize', value: file })
      if (errors !== null) {
        setErrorMap({ ...errorMap, [item.uid]: errors })
        return
      }

      setErrorMap({ ...errorMap, [item.uid]: { fileName: undefined } })

      const formData = new FormData()
      formData.append('file', file)

      isDocuSignType(item.type)
        ? await uploadDocuSign({
            uid,
            trialUid,
            trialHospitalUid: selectedTrialHospitalUid,
            file,
            formData: formData,
            type: item.type,
          })
        : await uploadTemp({
            uid,
            trialUid,
            trialHospitalUid: selectedTrialHospitalUid,
            file,
            params: formData,
          })
    },
    [
      createIcfDocumentItemMap,
      trialUid,
      errorMap,
      selectedTrialHospitalUid,
      uploadDocuSign,
      uploadTemp,
    ],
  )

  const onResetFile = useCallback(
    ({ uid }: { uid: string }) => {
      updateMap(uid, {
        tempFileUid: '',
        dsTemplateId: '',
        revision: { fileName: '' },
      })
    },
    [updateMap],
  )

  const onChangeRequiredPatientSign = useCallback(
    ({ uid }: { uid: string }) => {
      const item = createIcfDocumentItemMap.get(uid)

      updateMap(uid, {
        revision: {
          isRequiredPatientSign: !item?.revision.isRequiredPatientSign,
        },
      })
    },
    [createIcfDocumentItemMap, updateMap],
  )

  const openSubmitModal = useCallback(() => {
    setSubmitModalOpen(true)
  }, [])

  const closeSubmitModal = useCallback(() => {
    setSubmitModalOpen(false)
  }, [])

  const onValidate = useCallback((): boolean => {
    const newErrorMap: IcfDocumentErrorMap = {}

    //validationがsubmit直前に呼び出されるという前提の元、ここでsubmitTriedの値を更新する
    setSubmitTried(true)

    createIcfDocumentItems.forEach(item => {
      const errors = validateIcfDocument(
        { key: 'name', value: item.revision.name },
        { key: 'version', value: item.revision.version },
      )
      if (errors === null) {
        return
      }

      newErrorMap[item.uid] = errors
    })

    setErrorMap(newErrorMap)

    return Object.keys(newErrorMap).length === 0
  }, [createIcfDocumentItems])

  const shouldSelectHospital = useMemo(() => {
    return hasPermission(PERMISSIONS.Icfdocument_SelectTrialHospital)
  }, [hasPermission])

  const hospitalName = useMemo(() => {
    const hospital = trialHospitals.find(
      hospital => hospital.uid === selectedTrialHospitalUid,
    )

    return hospital?.name ?? ''
  }, [selectedTrialHospitalUid, trialHospitals])

  const listPath = useMemo(() => {
    return getIcfDocumentListRoute({
      trialUid,
      trialHospitalUid: hasPermission(
        PERMISSIONS.Icfdocument_SelectTrialHospital,
      )
        ? selectedTrialHospitalUid
        : undefined,
    })
  }, [hasPermission, selectedTrialHospitalUid, trialUid])

  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false)

  const onSubmit = useCallback(async () => {
    if (isProcessingSubmit) {
      return
    }
    setIsProcessingSubmit(true)
    if (selectedTrialHospitalUid === undefined) {
      throw new Error('trialHospitalUid does not set')
    }

    //バージョンが1~99の整数値で設定された場合は"n.0"の形式に変換して更新する
    const validIntegerRegex = /^[1-9][0-9]?$/

    createIcfDocumentItems.forEach(item => {
      item.revision.version = !!item.revision.version.match(validIntegerRegex)
        ? `${item.revision.version}.0`
        : item.revision.version
    })

    try {
      await bulkCreateIcfDocument({
        trialUid,
        trialHospitalUid: selectedTrialHospitalUid,
        params: createIcfDocumentItems,
      })
      showSuccess('文書を登録しました')
      navigate(listPath)
    } catch (e) {
      if (isRequestError(e)) {
        showError(e.message)
      }

      throw e
    } finally {
      setIsProcessingSubmit(false)
    }
  }, [
    isProcessingSubmit,
    selectedTrialHospitalUid,
    createIcfDocumentItems,
    trialUid,
    showSuccess,
    navigate,
    listPath,
    showError,
  ])

  const onCancel = useCallback(() => {
    setCancelTried(true)
  }, [])

  useEffect(() => {
    setSubmitTried(false)
  }, [createIcfDocumentItemMap])

  useEffect(() => {
    if (cancelTried) {
      navigate(listPath)
    }
  }, [cancelTried, listPath, navigate])

  return {
    createIcfDocumentItems,
    editing,
    onAddItem,
    onDeleteItem,
    onChangeDocumentType,
    onChangeVersion,
    onChangeName,
    onSelectFile,
    onResetFile,
    onChangeRequiredPatientSign,
    tempFileUrlMap,
    submitModalOpen,
    openSubmitModal,
    closeSubmitModal,
    onValidate,
    errorMap,
    shouldSelectHospital,
    hospitalName,
    listPath,
    onCancel,
    onSubmit,
  }
}

const createEmpty = (): CreateIcfDocumentItem => ({
  uid: generateUUID(),
  type: undefined,
  tempFileUid: '',
  dsTemplateId: '',
  revision: {
    uid: generateUUID(),
    version: '1.0',
    name: '',
    fileName: '',
    isRequiredPatientSign: true,
  },
})
