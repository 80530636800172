import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSelectedTrial } from 'src/modules/entities/account/selector'

import { ConfirmComponent } from './confirm/confirm'
import { UploadComponent } from './file-upload/upload'
import { PreviewComponent } from './preview/preview'
import { Task, TASK_NAMES, isUploadTask } from './task'
import { useCrf } from './use-crf-upload'
import { WorksheetExists } from './worksheet-exists/worksheet-exists'
import { trialIdParamName } from '../../../routes'

const tasks: Task[] = TASK_NAMES.map(taskName => {
  return {
    order: TASK_NAMES.indexOf(taskName),
    name: taskName,
  }
})

export const CrfUpload: React.FC = () => {
  const { trialUid = '' } = useParams<{
    [trialIdParamName]: string
  }>()
  const trial = useSelector(getSelectedTrial)!
  const {
    currentTask,
    completed,
    onComplete,
    onClickNext,
    onClickPrev,
    reset,
  } = useCrf({
    tasks,
  })
  const allTaskCompleted = tasks.every(completed)

  const isPreview = currentTask.name === 'preview'
  const isConfirm = currentTask.name === 'confirm'

  const uploadTasks = tasks.filter(isUploadTask)
  const allUploadCompleted = uploadTasks.every(completed)

  useEffect(() => {
    if (allTaskCompleted) {
      reset()
    }
  }, [allTaskCompleted, reset])

  if (trial.hasWorksheet) {
    return <WorksheetExists />
  }

  if (isUploadTask(currentTask)) {
    return (
      <UploadComponent
        trialUid={trialUid}
        currentTask={currentTask}
        uploadTasks={uploadTasks}
        allUploadCompleted={allUploadCompleted}
        onComplete={onComplete}
        completed={completed}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
      />
    )
  }

  if (isPreview) {
    return (
      <PreviewComponent onClickNext={onClickNext} onClickPrev={onClickPrev} />
    )
  }

  if (isConfirm) {
    return (
      <ConfirmComponent
        trialUid={trialUid}
        onComplete={onComplete}
        onClickPrev={onClickPrev}
      />
    )
  }

  return null
}
