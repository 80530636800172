// This component is generated by svgr. DO NOT EDIT.
import { SVGProps } from 'react'
type Props = {
  size?: 'sm' | 'md' | 'lg' | (string & {}),
} & Omit<SVGProps<SVGSVGElement>, 'width' | 'height'>
const SvgMicOff = ({ size = 'md', ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    height={
      size === 'sm'
        ? '8px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '24px'
        : size
    }
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props.color ?? 'currentColor'}
      d="m10.8 12.3 3 3 1.4-1.4-13-13L.8 2.3 5 6.5v.6c0 1.7 1.3 3 3 3 .2 0 .4 0 .5-.1l.8.8c-.4.2-.9.2-1.3.2-2.2 0-4-1.8-4-4 0-.6-.4-1-1-1s-1 .4-1 1c0 3 2.2 5.4 5 5.9V14H5c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1H9v-1.1c.7-.1 1.3-.3 1.8-.6ZM14 7c0-.6-.4-1-1-1s-1 .4-1 1-.1 1.1-.4 1.6l1.5 1.5c.6-.9.9-2 .9-3.1ZM11 7V3c0-1.7-1.3-3-3-3-1.4 0-2.5.9-2.9 2.1l5.7 5.7c.1-.2.2-.5.2-.8Z"
    />
  </svg>
)
export default SvgMicOff
