import React, { useState } from 'react'

import { Button } from 'src/components/base/button/button'
import { SelectedTrial } from 'src/modules/entities/account/entity'

import { AddModalContainer } from './add-modal/add-modal-container'

type Props = {
  selectedTrial: SelectedTrial
}

export const MemberAddButton: React.FC<Props> = props => {
  const { selectedTrial } = props
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button
        text="新規作成"
        size="S"
        buttonType="normal"
        iconName="add"
        onClick={() => setModalOpen(true)}
      />

      {modalOpen && (
        <AddModalContainer
          selectedTrial={selectedTrial}
          handlers={{
            onClose: () => setModalOpen(false),
            onSubmit: values => console.warn(values),
          }}
        />
      )}
    </>
  )
}
