import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'
import { ModalCancelButton } from 'src/lib/chakra-theme/components'

type Props = {
  isOpen: boolean
  buttonColorScheme?: 'blue' | 'green'
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmOnScreenPinSettingModal: React.FC<Props> = ({
  isOpen,
  buttonColorScheme,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>患者本人が操作画面上で設定</ModalHeader>
        <ModalBody>
          <Text>この端末の画面上で暗証番号を設定します。</Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton text="戻る" />
          <Button colorScheme={buttonColorScheme} onClick={onSubmit}>
            設定する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
