import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Expand3 } from 'src/components/__legacy__icon/monochrome'
import { blue } from 'src/components/base/color/palette'
import { PullDown, PullDownItem } from 'src/components/layout/pulldown'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'

import { CaptureModalContainer } from './capture/container'
import { DeleteWorksheetModalContainer } from './delete/container'
import { HistoryModalContainer } from './history/container'
import { VideoCallRoomCaptureModalContainer } from './video-call-room-capture/container'
import { VideoCallRoomHistoryModalContainer } from './video-call-room-history/container'

type ModalType =
  | 'History'
  | 'VideoCallRoomHistory'
  | 'Capture'
  | 'VideoCallRoomCapture'
  | 'Delete'

type Props = {
  videoEnabled: boolean
  videoCaptureEnabled: boolean
  canViewVideoCapture: boolean
  canViewVideoHistory: boolean
  deletable: boolean
}

export const PulldownContainer: React.FC<Props> = ({
  videoEnabled,
  videoCaptureEnabled,
  canViewVideoCapture,
  canViewVideoHistory,
  deletable,
}) => {
  const [pulldownOpen, setPulldownOpen] = useState(false)
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const { worksheetUid = '' } = useParams<{ worksheetUid: string }>()

  const pulldownItems: PullDownItem[] = []
  if (videoEnabled && canViewVideoHistory) {
    pulldownItems.push({
      name: 'ビデオ通話履歴',
      onClick: () => setModalType('History'),
    })
  }
  if (videoEnabled && videoCaptureEnabled && canViewVideoCapture) {
    pulldownItems.push({
      name: 'ビデオ通話のキャプチャ一覧',
      onClick: () => setModalType('Capture'),
    })
  }
  if (deletable) {
    pulldownItems.push({
      name: 'ワークシートを削除',
      onClick: () => setModalType('Delete'),
    })
  }

  const closeModal = () => setModalType(null)

  useCloseModalByDeps({
    condition: modalType !== null,
    onClose: closeModal,
    deps: [worksheetUid],
  })

  return (
    <>
      <PullDown
        closePulldown={() => setPulldownOpen(false)}
        items={pulldownItems}
        pulldownOpen={pulldownOpen}
      >
        {pulldownItems.length > 0 && (
          <Expand3
            cursor="pointer"
            onClick={() => setPulldownOpen(true)}
            color={blue[70]}
          />
        )}
      </PullDown>

      {modalType === 'History' && (
        <HistoryModalContainer onClose={closeModal} />
      )}
      {modalType === 'VideoCallRoomHistory' && (
        <VideoCallRoomHistoryModalContainer onClose={closeModal} />
      )}
      {modalType === 'Capture' && (
        <CaptureModalContainer onClose={closeModal} />
      )}
      {modalType === 'VideoCallRoomCapture' && (
        <VideoCallRoomCaptureModalContainer onClose={closeModal} />
      )}
      {modalType === 'Delete' && (
        <DeleteWorksheetModalContainer onClose={closeModal} />
      )}
    </>
  )
}
