import { Box, Button, Radio, Wrap, WrapItem } from '@chakra-ui/react'
import { Choice, Field, RadioTypeDef } from '@micin-jp/chicken-schema'

import { useWorksheetValueContext } from '../../context/WorksheetValue'
import { getErrorMessage } from '../../utils/getErrorMessage'
import { WorksheetFieldFormControl } from '../WorksheetFieldFormControl/WorksheetFieldFormControl'

type Props = {
  radioField: Field & {
    typeDef: RadioTypeDef
  }
  index: number
}

export const WorksheetRadioField: React.FC<Props> = ({ radioField, index }) => {
  const {
    findFieldValue,
    onChangeRadioFieldValue,
    canEdit,
    isRepeatableSectionEnabled,
  } = useWorksheetValueContext()

  const value = findFieldValue({
    fid: radioField.fid,
    index,
  })

  const onChange = (radioValue: Choice['cid'] | undefined) => {
    onChangeRadioFieldValue({
      fid: radioField.fid,
      index,
      radioValue,
    })
  }

  const isDisabled =
    !canEdit || !isRepeatableSectionEnabled(index, radioField.fid)

  return (
    <WorksheetFieldFormControl
      field={radioField}
      index={index}
      isDisabled={isDisabled}
      errorMessage={getErrorMessage(value)}
    >
      {/* 値をクリアできるようRadioGroupを使わずにisCheckedを制御する実装を行なっている。正当な実装から離れているので見直したい。。。 */}
      <Wrap spacing="8">
        {radioField.typeDef.choices.map(choice => (
          <WrapItem key={choice.cid} alignItems="center">
            {/* label部分もクリックできるようwrapperを設定（Radioに渡すonClickはinput要素に渡される） */}
            <Box
              onClick={() => {
                if (isDisabled) return
                onChange(choice.cid)
              }}
            >
              <Radio
                isChecked={
                  value?.type === 'radio'
                    ? value.radioValue === choice.cid
                    : false
                }
                value={choice.cid}
              >
                {choice.name}
              </Radio>
            </Box>
          </WrapItem>
        ))}
        {!isDisabled && (
          <WrapItem>
            <Button variant="text" onClick={() => onChange(undefined)}>
              選択クリア
            </Button>
          </WrapItem>
        )}
      </Wrap>
    </WorksheetFieldFormControl>
  )
}
