import { useCallback } from 'react'

import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'src/modules/entities/cognito/redux'
import { RootState } from 'src/modules/reducer'

export const useCognitoUser = () => {
  const dispatch = useDispatch()
  const cognitoUser = useSelector(
    (state: RootState) => state.entities.cognito.user,
  )
  const initCognitoUser = useCallback(
    async (bypassCache = false) => {
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache,
      })
      dispatch(actions.setCognitoUser(currentUser))
    },
    [dispatch],
  )

  return { cognitoUser, initCognitoUser }
}
