import React from 'react'

import { Button } from 'src/components/base/button/button'
import { gray } from 'src/components/base/color/palette'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import styled from 'styled-components'

type Props = {
  currentFile: File | null
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
  completed: boolean
  fileInputRef: React.RefObject<HTMLInputElement>
  onUpload: () => void
}

export const FileUploader: React.FC<Props> = props => {
  const { currentFile, onChangeFile, completed, fileInputRef } = props

  const onClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <Wrapper className={completed ? 'hidden' : ''}>
      <InputContainer>
        <Label>アップロードファイルの選択</Label>
        <Spacer size={10} />
        <Input
          readOnly
          value={currentFile ? currentFile.name : ''}
          placeholder="ファイルを選択してください"
        />
      </InputContainer>

      <Spacer size={16} horizontal />

      <ButtonItem>
        <Button
          size="S"
          text="ファイルを選択"
          buttonType="normal"
          onClick={onClick}
        ></Button>
        <FileInput
          id="file"
          type="file"
          name="file"
          accept=".csv"
          ref={fileInputRef}
          onChange={e => onChangeFile(e)}
        />
      </ButtonItem>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${gray[5]};

  &.hidden {
    display: none;
  }
`

const InputContainer = styled.div`
  flex: 1;
`

const ButtonItem = styled.div`
  display: flex;
  align-items: flex-end;
`

const FileInput = styled.input`
  display: none;
`
