import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  AspectRatio,
  Box,
  Flex,
  Grid,
  Text,
  Button,
  VStack,
  Center,
  useBreakpointValue,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { RemoteUser, RoomSessionStatus } from '@micin-jp/call-lib-core'
import {
  useRoomSession,
  useRoomUsers,
  useLocalPlayer,
  useRemotePlayer,
} from '@micin-jp/call-lib-react'
import { CameraOff, CameraOn, MicOff, MicOn } from 'src/components/icon'
import { useVolumeMeter } from 'src/features/videoCall/hooks/useVolumeMeter'
import { ExplanationRoomDisconnect } from 'src/modules/dashboard/trial/detail/e-consent/explanation-room/components/disconnect'

import { useRoomMediaContext } from '../../context/MediaProvider'

type Props = {
  isSessionStarted: boolean
  hospitalName: string
  /** @deprecated */
  isOldFlow?: boolean
}

const MAX_DISPLAY_REMOTE_USER_COUNT = 4

export const ExplanationRoomLobbyVideoCallContent: React.FC<Props> = ({
  isSessionStarted,
  hospitalName,
  isOldFlow = false,
}) => {
  const [videoStarted, setVideoStarted] = useState(false)
  const { sessionStatus, joinRoom } = useRoomSession()
  const { remoteUsers } = useRoomUsers()
  const { roomUid, participantUid, participantName } = useRoomMediaContext()

  const isRoomDisconnected = sessionStatus === RoomSessionStatus.Ready

  const {
    localMicEnabled,
    localCameraEnabled,
    toggleMicOnOff,
    toggleCameraOnOff,
    requestLocalAudio,
    requestLocalVideo,
  } = useLocalPlayer()

  const startVideo = useCallback(async () => {
    if (!roomUid || !participantUid) return
    await joinRoom({ name: participantName ?? '' })
    // 旧フローの場合はデフォルトでカメラとマイクをONにする
    if (isOldFlow) {
      await requestLocalAudio()
      await requestLocalVideo()
    }
    setVideoStarted(true)
  }, [
    joinRoom,
    participantName,
    participantUid,
    roomUid,
    isOldFlow,
    requestLocalAudio,
    requestLocalVideo,
  ])

  const gridTemplateRows = useMemo(() => {
    if (remoteUsers.length === 0) return undefined
    if (remoteUsers.length === 1) return '1fr'
    if (remoteUsers.length === 2) return '50%'
    if (remoteUsers.length <= 4) return 'repeat(2, 1fr)'
    return 'repeat(2, 30%)'
  }, [remoteUsers])

  const gridTemplateColumns = useMemo(() => {
    if (remoteUsers.length === 0) return undefined
    if (remoteUsers.length === 1) return '1fr'
    if (remoteUsers.length <= 4) return 'repeat(2, 1fr)'
    return 'repeat(3, 1fr)'
  }, [remoteUsers])

  if (videoStarted && isRoomDisconnected) {
    return <ExplanationRoomDisconnect onJoinRoom={startVideo} />
  }

  return (
    <VStack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      {videoStarted ? (
        <Box w="100%" h="100%" position="relative">
          <Grid
            w="100%"
            h="calc(100% - 88px)"
            bg="gray.200"
            alignContent="center"
            justifyContent="center"
            gap="8px"
            gridTemplateRows={gridTemplateRows}
            gridTemplateColumns={gridTemplateColumns}
          >
            {remoteUsers.length === 0 && (
              <Text fontSize="xl" fontWeight="bold">
                あなた以外に参加者がいません
              </Text>
            )}

            {remoteUsers.slice(0, MAX_DISPLAY_REMOTE_USER_COUNT).map(ru => {
              return <RemoteUserPlayer key={ru.id} remoteUser={ru} />
            })}
            {/* MAX超過分は音声のみ */}
            {remoteUsers.slice(MAX_DISPLAY_REMOTE_USER_COUNT).map(ru => {
              return <AudioOnlyRemotePlayer key={ru.id} remoteUser={ru} />
            })}

            {remoteUsers.length > MAX_DISPLAY_REMOTE_USER_COUNT && (
              <RemainRemoteUsers count={remoteUsers.length} />
            )}
          </Grid>
          <Flex
            w="100%"
            h="88px"
            bg="gray.100"
            alignItems="center"
            justifyContent="center"
            boxSizing="border-box"
            px="0"
            py="16px"
            gap={8}
          >
            <IconButton
              aria-label="マイクをオン/オフにする"
              variant="customIconButtonSolid"
              w="48px"
              h="48px"
              colorScheme={localMicEnabled ? 'green' : 'red'}
              icon={
                localMicEnabled ? <MicOn size="lg" /> : <MicOff size="lg" />
              }
              onClick={() => toggleMicOnOff()}
            />
            <IconButton
              aria-label="カメラをオン/オフにする"
              variant="customIconButtonSolid"
              w="48px"
              h="48px"
              colorScheme={localCameraEnabled ? 'green' : 'red'}
              icon={
                localCameraEnabled ? (
                  <CameraOn size="lg" />
                ) : (
                  <CameraOff size="lg" />
                )
              }
              onClick={toggleCameraOnOff}
            />
          </Flex>
          <AspectRatio
            w="25%"
            ratio={16 / 9}
            position="absolute"
            zIndex="1"
            bottom="16px"
            right="16px"
          >
            <LocalUserPlayer />
          </AspectRatio>
        </Box>
      ) : (
        <VStack
          justifyContent="space-between"
          h="100%"
          w="100%"
          py="6"
          px="10"
          boxSizing="border-box"
        >
          <Box alignSelf="start">
            <Text fontSize="2xl" fontWeight="bold">
              {hospitalName}
            </Text>
          </Box>
          <VStack alignItems="center" justifyContent="center" h="full" gap="8">
            {isSessionStarted ? (
              <Text fontSize="sm" textAlign="center">
                説明が開始されています。
                <br />
                通話開始ボタンを押して通話を開始したのち、説明ルームへボタンから説明ルームへお入りください。
              </Text>
            ) : (
              <Text fontWeight="bold">
                通話を開始するには通話開始ボタンを押してください
              </Text>
            )}
            <Button colorScheme="green" onClick={startVideo}>
              通話開始
            </Button>
          </VStack>
        </VStack>
      )}
    </VStack>
  )
}

const RemoteUserPlayer: React.FC<{ remoteUser: RemoteUser }> = ({
  remoteUser,
}) => {
  const { playAudio, attachVideo, cameraEnabled, micEnabled, audioTrack } =
    useRemotePlayer(remoteUser)

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachVideo(elm, { fit: 'contain' }),
    [attachVideo],
  )

  useEffect(() => {
    playAudio()
  }, [playAudio])

  const { isSpeaking } = useVolumeMeter({ audioTrack })

  return (
    <Box position="relative">
      <Box
        pos="absolute"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        zIndex="1"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      <Box
        ref={videoRef}
        w="full"
        h="full"
        overflow="hidden"
        bg={cameraEnabled ? 'transparent' : 'gray.400'}
      />
      {!cameraEnabled && (
        <Center pos="absolute" top="50%" w="full" h="hull" color="gray.600">
          <CameraOff size="56px" />
        </Center>
      )}
      <NameLabel micEnabled={micEnabled}>{remoteUser.name}</NameLabel>
    </Box>
  )
}

// MAX表示数より多い分のRemoteUserについては映像のレンダリングはせずAudioの再生のみを行う
const AudioOnlyRemotePlayer: React.FC<{
  remoteUser: RemoteUser
}> = ({ remoteUser }) => {
  const { playAudio } = useRemotePlayer(remoteUser)
  useEffect(() => playAudio(), [playAudio])
  return null
}

const RemainRemoteUsers: React.FC<{ count: number }> = ({ count }) => {
  return (
    <Flex
      w="full"
      h="full"
      bg="gray.800"
      borderRadius="4px"
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="5xl" fontWeight="bold" color="white">
        +{count - MAX_DISPLAY_REMOTE_USER_COUNT}
      </Text>
    </Flex>
  )
}

const LocalUserPlayer: React.FC = () => {
  const { attachLocalVideo, localCameraEnabled, localAudioTrack } =
    useLocalPlayer()

  const videoRef = useCallback(
    (elm: HTMLDivElement) => attachLocalVideo(elm, { fit: 'contain' }),
    [attachLocalVideo],
  )

  const { isSpeaking } = useVolumeMeter({ audioTrack: localAudioTrack })

  const iconSize = useBreakpointValue({ base: 'md', md: 'lg' })

  return (
    <Box w="full" h="full" position="relative" borderRadius="base">
      <Box
        pos="absolute"
        left="0"
        top="0"
        w="full"
        h="full"
        border="4px"
        borderColor={isSpeaking ? 'blue.500' : 'transparent'}
      />
      {localCameraEnabled ? (
        <Box
          ref={videoRef}
          w="100%"
          h="100%"
          overflow="hidden"
          bg="gray.700"
          transform="scaleX(-1)"
        />
      ) : (
        <Center h="full" w="full" color="white" bg="gray.700">
          <CameraOff size={iconSize} />
        </Center>
      )}
      <NameLabel>あなた</NameLabel>
    </Box>
  )
}

const NameLabel: React.FC<{
  micEnabled?: boolean
  children: React.ReactNode
}> = ({ micEnabled = true, children }) => {
  return (
    <HStack
      pos="absolute"
      left="2"
      bottom="2"
      h="24px"
      align="center"
      maxW="50%"
      p="1"
      bg="gray.600"
      borderRadius="sm"
      fontSize="x-small"
      color="white"
    >
      <Box
        as="span"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {children}
      </Box>
      {!micEnabled && (
        <Box w="24px">
          <MicOff size="12px" />
        </Box>
      )}
    </HStack>
  )
}
