import {
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field } from '@micin-jp/chicken-schema'
import { Navigate } from 'react-router-dom'
import {
  ArrowLeft,
  ArrowRight,
  Attachment,
  Certification,
  Document as DocumentIcon,
} from 'src/components/icon'
import { PDFViewer } from 'src/components/PDFViewer/PDFViewer'
import { RightClickBlocker } from 'src/components/RightClickBlocker/RightClickBlocker'
import { truncateFileName } from 'src/features/misc/utils/fileUploadUtils'
import { useMirohaSwiper } from 'src/hooks/useMirohaSwiper'
import { formatDate } from 'src/utils/formatDate'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useFileViewerSearchParam } from '../../hooks/useFileViewerSearchParam'

type FileItem = {
  order: number
  memo: string
  uploadedFile: {
    uid: string
    name: string
    extension: string
    savedAt: string
    url: string
  }
}

type Props = {
  field: Field
  files: FileItem[]
  menuComponent?: React.ReactNode
}

const slideWidth = 120
const gapWidth = 20

export const FileViewer: React.FC<Props> = ({
  field,
  files,
  menuComponent,
}) => {
  const { selectedFileOrder, onChangeSelectedFileOrder } =
    useFileViewerSearchParam()

  const selectedFile = files.find(f => f.order === selectedFileOrder)

  if (!selectedFile) {
    // 先頭のファイルに遷移
    return <Navigate to="." />
  }

  if (field.typeDef.type !== 'File') {
    return null
  }

  return (
    <Flex direction="column" h="100svh" w="100svw">
      <Flex
        as="header"
        w="100svw"
        minH="64px"
        bg="white"
        align="center"
        justify="space-between"
        px="6"
        gap={2}
      >
        <Box w="240px" overflow="hidden">
          <Text
            fontSize="lg"
            fontWeight="bold"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {/* 20文字まで表示 */}
            {field.name.length > 20
              ? `${field.name.slice(0, 20)}...`
              : field.name}
          </Text>
        </Box>
        <Flex gap={1} align="center" flex="1" justify="center">
          {field.typeDef.isCertifiedCopy && (
            <Box color="green.500">
              <Certification />
            </Box>
          )}
          <Flex wrap="wrap" columnGap={2} rowGap={1}>
            <Text fontSize="sm" fontWeight="bold" wordBreak="break-all">
              {selectedFile.uploadedFile.name}
            </Text>
            <Text fontSize="sm">
              {formatDate(
                selectedFile.uploadedFile.savedAt,
                'YYYY/MM/DD (ddd) HH:mm',
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex w="240px" justify="end">
          {!!menuComponent && menuComponent}
        </Flex>
      </Flex>
      <Box
        w="full"
        flex="1"
        alignItems="stretch"
        px="6"
        overflow={
          selectedFile.uploadedFile.extension.toLowerCase() === 'pdf'
            ? 'hidden'
            : 'auto'
        }
      >
        {selectedFile.uploadedFile.extension.toLowerCase() === 'pdf' ? (
          <PDFViewer url={selectedFile.uploadedFile.url} />
        ) : (
          <RightClickBlocker w="full" h="full">
            <Center bg="gray.200" w="full" h="full">
              <Image
                src={selectedFile.uploadedFile.url}
                h="full"
                objectFit="contain"
                alt={selectedFile.uploadedFile.name}
              />
            </Center>
          </RightClickBlocker>
        )}
      </Box>
      <Flex p="6" justify="space-between">
        {/* 明示的に幅を指定する */}
        <Box width="calc(100% - (512px + 40px))" pos="relative">
          <Swiper
            spaceBetween={gapWidth}
            slidesPerView="auto"
            initialSlide={files.findIndex(f => f.order === selectedFileOrder)}
            // NOTE: keyboard操作時のbuttonの位置を保つためstaticにして親をrelativeにする（Swiperのdefaultはrelative）
            style={{ padding: '0 54px', position: 'static' }}
          >
            <Flex
              pos="absolute"
              w="54px"
              left="0"
              top="0"
              bg="white"
              h="full"
              align="center"
              justify="start"
              zIndex="1"
            >
              <SwiperButton direction="prev" />
            </Flex>
            {files.map(f => (
              <SwiperSlide
                key={f.uploadedFile.uid}
                style={{ width: slideWidth }}
              >
                <Stack spacing="2" overflow="hidden">
                  <Flex w="full" align="center">
                    <Spacer />
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      flex="1"
                      textAlign="center"
                    >
                      {f.order}
                    </Text>
                    <Flex
                      flex="1"
                      h="full"
                      align="center"
                      color="blue.500"
                      justify="end"
                    >
                      {!!f.memo && <Attachment />}
                    </Flex>
                  </Flex>
                  <RightClickBlocker>
                    <Box
                      aria-label={f.uploadedFile.name}
                      w="full"
                      h="148px"
                      border={
                        f.order === selectedFileOrder ? '3px solid' : 'none'
                      }
                      borderColor="blue.500"
                      as="button"
                      onClick={() =>
                        onChangeSelectedFileOrder({ order: f.order })
                      }
                    >
                      {f.uploadedFile.extension.toLowerCase() === 'pdf' ? (
                        <Center bg="gray.50" h="full" w="full">
                          <DocumentIcon size="48px" />
                        </Center>
                      ) : (
                        <Image
                          src={f.uploadedFile.url}
                          alt={f.uploadedFile.name}
                          objectFit="fill"
                          w="full"
                          h="full"
                        />
                      )}
                    </Box>
                  </RightClickBlocker>
                </Stack>
              </SwiperSlide>
            ))}
            <Flex
              pos="absolute"
              w="54px"
              right="0"
              top="0"
              bg="white"
              h="full"
              align="center"
              justify="end"
              zIndex="1"
            >
              <SwiperButton direction="next" />
            </Flex>
          </Swiper>
        </Box>
        <Stack w="512px" justify="space-between">
          <Text fontWeight="bold">
            メモ:
            {selectedFile && truncateFileName(selectedFile.uploadedFile.name)}
          </Text>
          <Box
            flex="1"
            bg="gray.50"
            p="3"
            borderRadius="md"
            wordBreak="keep-all"
            whiteSpace="pre-wrap"
            maxH="140px"
            overflow="auto"
          >
            {selectedFile.memo}
          </Box>
        </Stack>
      </Flex>
    </Flex>
  )
}

const SwiperButton: React.FC<{ direction: 'prev' | 'next' }> = ({
  direction,
}) => {
  const swiper = useMirohaSwiper()

  return (
    <IconButton
      variant="customIconButtonGhost"
      icon={direction === 'prev' ? <ArrowLeft /> : <ArrowRight />}
      aria-label={direction === 'prev' ? '手前に移動する' : '奥に移動する'}
      isDisabled={
        direction === 'prev'
          ? !swiper.prevSlideEnabled
          : !swiper.nextSlideEnabled
      }
      onClick={() => {
        if (direction === 'prev') {
          swiper.slidePrev()
        } else {
          swiper.slideNext()
        }
      }}
    />
  )
}
