import { IcfDocumentSet, IcfDocumentSetForList } from './entity'
import { icfDocumentRevisionStatus } from '../icf-document/entity'
import {
  icfDocumentFixtureAgreementForm,
  icfDocumentRevisionFixture1,
} from '../icf-document/fixture'

export const icfDocumentSetFixture1: IcfDocumentSet = {
  uid: 'd00076e5-99c6-42ca-9ef3-df14595cdc4b',
  numberingId: 1,
  name: '文書セット1',
  enabled: true,
  icfDocuments: [
    {
      ...icfDocumentFixtureAgreementForm,
      index: 0,
      latestApprovedRevisionUid: icfDocumentRevisionFixture1.uid,
      latestApprovedName: icfDocumentRevisionFixture1.name,
      latestApprovedVersion: icfDocumentRevisionFixture1.version,
      latestApprovedAt: icfDocumentRevisionFixture1.approvedAt,
      status: icfDocumentRevisionStatus.Approved,
      latestIsRequiredPatientSign: true,
    },
  ],
}

export const icfDocumentSetFixture2: IcfDocumentSet = {
  uid: '2cb71a40-d4c9-4739-98ec-b859e3dce04f',
  numberingId: 2,
  name: '文書セット2',
  enabled: true,
  icfDocuments: [],
}

export const icfDocumentSetListFixture: IcfDocumentSetForList[] = [
  {
    ...icfDocumentSetFixture1,
    hasDocuments: true,
    hasRequiredAgreementForm: true,
  },
  {
    ...icfDocumentSetFixture2,
    hasDocuments: false,
    hasRequiredAgreementForm: false,
  },
]
