import { AnswerType, QuestionnaireField } from './entity'
import {
  QuestionnaireChoiceResponse,
  parse as parseQuestionnaireChoice,
} from '../questionnaire-choice/request'

export type QuestionnaireFieldResponse = {
  uid: string
  title: string
  answerType: AnswerType
  index: number
  unit: string
  choices: QuestionnaireChoiceResponse[]
}

// TODO: unitは未実装のためプロパティに含めていない
export const parse = (res: QuestionnaireFieldResponse): QuestionnaireField => {
  return {
    uid: res.uid,
    title: res.title,
    answerType: res.answerType,
    index: res.index,
    unit: res.unit,
    choices: res.choices ? res.choices.map(parseQuestionnaireChoice) : [],
  }
}
