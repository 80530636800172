import React from 'react'

import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Button } from 'src/components/base/button/button'
import { red } from 'src/components/base/color/palette'
import { Message as MessageComponent } from 'src/components/base/message/message'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/base/table/table'
import { AnchorLink } from 'src/components/base/text-link'
import { SERVER_URL } from 'src/modules/server/const'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { CancelCrfDownloadButton } from './cancel/button'
import { CrfDownloadStatus, CrfDownloadStatusToText } from './entity'
import { useDownloadCrf } from './use-download-crf'

type Props = {
  trialUid: string
}

export const DownloadComponent: React.FC<Props> = props => {
  const { trialUid } = props
  const { crfDownloadJobList, createCrfDownloadJob } = useDownloadCrf({
    trialUid,
  })

  if (!crfDownloadJobList) {
    return null
  }

  const runningJob = crfDownloadJobList.find(
    job =>
      job.status === CrfDownloadStatus.Running ||
      job.status === CrfDownloadStatus.Waiting,
  )

  const formatDateTime = (date: Date | undefined) =>
    dayjs(date).format('YYYY/MM/DD HH:mm:ss')

  return (
    <Container>
      <Text as="h1" fontSize="lg" fontWeight="bold">
        CRFファイルダウンロード
      </Text>

      <ItemContainer>
        {runningJob ? (
          <Message>
            <Text fontSize="sm">
              テンプレートのダウンロードファイルを作成中です。
            </Text>
          </Message>
        ) : (
          <Message>
            <Text fontSize="sm">
              有効なテンプレートからCRFファイルを作成します。
            </Text>
            <Text fontSize="sm">
              ※CRFファイルの作成には時間が掛かる場合があります。
            </Text>
          </Message>
        )}
      </ItemContainer>

      <ItemContainer>
        <ButtonItem>
          {runningJob ? (
            <CancelCrfDownloadButton crfDownloadJobUid={runningJob.uid} />
          ) : (
            <Button
              size="S"
              text="CRFファイル作成"
              onClick={async () => {
                await createCrfDownloadJob.request()
              }}
              buttonType="normal"
            />
          )}
        </ButtonItem>
      </ItemContainer>
      {createCrfDownloadJob.errorMessage && (
        <ErrorMessagesContainer>
          <MessageComponent
            type="error"
            message={createCrfDownloadJob.errorMessage}
          />
        </ErrorMessagesContainer>
      )}

      <ItemContainer>
        <Message>
          <Text fontSize="sm">ファイル履歴</Text>
        </Message>
      </ItemContainer>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell width="30%">処理受付時刻</TableHeadCell>
              <TableHeadCell width="30%">処理完了時刻</TableHeadCell>
              <TableHeadCell width="25%">最終更新者</TableHeadCell>
              <TableHeadCell width="15%">結果</TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {crfDownloadJobList.map(crfDownloadJob => {
              const textColorError =
                crfDownloadJob.status === CrfDownloadStatus.Error
                  ? red[50]
                  : undefined

              const downloadUrl =
                crfDownloadJob.status === CrfDownloadStatus.Done
                  ? `${SERVER_URL}/trials/${trialUid}/crf/download_jobs/${crfDownloadJob.uid}/zip`
                  : ''

              return (
                <TableRow key={crfDownloadJob.uid} borderBottom>
                  <TableBodyCell width="30%">
                    {formatDateTime(crfDownloadJob.registeredAt)}
                  </TableBodyCell>
                  <TableBodyCell width="30%">
                    {crfDownloadJob.completedAt
                      ? formatDateTime(crfDownloadJob.completedAt)
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell width="25%">
                    {crfDownloadJob.lastUpdatedBy}
                  </TableBodyCell>
                  <TableBodyCell width="15%">
                    {crfDownloadJob.status === CrfDownloadStatus.Done ? (
                      <Text color={textColorError}>
                        <AnchorLink href={downloadUrl} download>
                          {CrfDownloadStatusToText(crfDownloadJob.status)}
                        </AnchorLink>
                      </Text>
                    ) : (
                      <Text color={textColorError}>
                        {CrfDownloadStatusToText(crfDownloadJob.status)}
                      </Text>
                    )}
                  </TableBodyCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
`

const ItemContainer = styled(Flex)`
  margin: 15px 0 15px;
  flex-wrap: wrap;
`

const Message = styled.div`
  font-weight: bolder;
`

const ErrorMessagesContainer = styled.div`
  min-height: 50px;
  overflow-y: auto;
`

const ButtonItem = styled.div`
  margin-top: 10px;
`

const TableContainer = styled.div`
  height: 100%;
`
