import { generateUUID } from 'src/utils/generateUUID'

import { SmallItem } from '../small-item/entity'

export type MediumItem = {
  uid: string
  title: string
  folderName: string
  formName: string
  index: number
  isLogline: boolean

  smallItemMap: Record<string, SmallItem>

  templateUid: string
  presetUsed: boolean
}

export const createEmpty = (): MediumItem => ({
  uid: generateUUID(),
  title: '',
  folderName: '',
  formName: '',
  index: -1,
  isLogline: false,
  smallItemMap: {},
  templateUid: '',
  presetUsed: false,
})
