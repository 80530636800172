import { SERVER_URL } from 'src/modules/server/const'
import { requestDelete } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

export type DeleteWorksheetParam = {
  trialUid: string
  patientUid: string
  worksheetUid: string
}

export const submitDelete = async ({
  trialUid,
  patientUid,
  worksheetUid,
}: DeleteWorksheetParam): Promise<{}> => {
  if (IS_MOCK_MODE) {
    return {}
  }

  await requestDelete<{}>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/${worksheetUid}`,
    params: {},
  })

  return {}
}
