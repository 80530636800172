import { Box, VStack } from '@chakra-ui/react'
import { Field, SectionTypeDef } from '@micin-jp/chicken-schema'
import { TextWithBar } from 'src/components/TextWithBar/TextWithBar'

import { WorksheetFieldComponent } from '../WorksheetField/WorksheetField'

type Props = {
  sectionField: Field & {
    typeDef: SectionTypeDef
  }
}

export const WorksheetSectionField: React.FC<Props> = ({ sectionField }) => {
  const id = `${sectionField.fid}_title`
  return (
    <Box as="section" aria-labelledby={id}>
      <TextWithBar as="h2" fontWeight="bold" id={id}>
        {sectionField.name}
      </TextWithBar>
      <VStack mt="3" pl="2" spacing="6">
        {sectionField.typeDef.fields.map(field => (
          <WorksheetFieldComponent key={field.fid} field={field} />
        ))}
      </VStack>
    </Box>
  )
}
