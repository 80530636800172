import { useCallback, useMemo } from 'react'

import { ExplanationOrderKey } from 'src/lib/gql-client'

import { useExplanationsQueryParams } from './useExplanationsQueryParams'

const DEFAULT_EXPLANATION_ORDER_KEY: ExplanationOrderKey = 'CreatedAt'

type DEFAULT_EXPLANATION_ORDER_FIELD = 'createdAt'

type ExplanationSortType = 'asc' | 'desc'
export type ExplanationSortField =
  | DEFAULT_EXPLANATION_ORDER_FIELD
  | 'agreementCompletedAt'
  | 'candidateId'
  | 'diseaseId'
  | 'deliveredAt'

export const useSortExplanations = () => {
  const { queryParamObject, setQuery } = useExplanationsQueryParams()

  const currentOrderKey = useMemo(
    () => queryParamObject.sortOrderKey ?? DEFAULT_EXPLANATION_ORDER_KEY,
    [queryParamObject.sortOrderKey],
  )

  const handleChangeSortField = useCallback(
    (field: ExplanationSortField | undefined) => {
      const currentSortType = explanationSortTypeMap[currentOrderKey] ?? 'asc'
      setQuery({
        type: 'sortOrderKey',
        value: !!field
          ? explanationOrderMap[field][currentSortType]
          : explanationOrderMap['createdAt'][currentSortType],
      })
    },
    [currentOrderKey, setQuery],
  )

  const handleChangeSortType = useCallback(
    (sortType: ExplanationSortType) => {
      const currentSortField = explanationSortFieldMap[currentOrderKey]
      setQuery({
        type: 'sortOrderKey',
        value:
          currentSortField !== undefined
            ? explanationOrderMap[currentSortField][sortType]
            : 'CreatedAt',
      })
    },
    [currentOrderKey, setQuery],
  )

  return {
    handleChangeSortType,
    handleChangeSortField,
    field: useMemo(
      () => explanationSortFieldMap[currentOrderKey],
      [currentOrderKey],
    ),
    order: useMemo(
      () => explanationSortTypeMap[currentOrderKey],
      [currentOrderKey],
    ),
  }
}

const explanationSortFieldMap: Record<
  ExplanationOrderKey,
  ExplanationSortField | undefined
> = {
  CreatedAt: undefined,
  CandidateIdAsc: 'candidateId',
  CandidateIdDesc: 'candidateId',
  DiseaseIdAsc: 'diseaseId',
  DiseaseIdDesc: 'diseaseId',
  AgreementCompletedAtAsc: 'agreementCompletedAt',
  AgreementCompletedAtDesc: 'agreementCompletedAt',
  DeliveredAtAsc: 'deliveredAt',
  DeliveredAtDesc: 'deliveredAt',
}

const explanationSortTypeMap: Record<
  ExplanationOrderKey,
  ExplanationSortType | undefined
> = {
  CreatedAt: undefined,
  CandidateIdAsc: 'asc',
  CandidateIdDesc: 'desc',
  DiseaseIdAsc: 'asc',
  DiseaseIdDesc: 'desc',
  AgreementCompletedAtAsc: 'asc',
  AgreementCompletedAtDesc: 'desc',
  DeliveredAtAsc: 'asc',
  DeliveredAtDesc: 'desc',
}

const explanationOrderMap: Record<
  ExplanationSortField,
  Record<ExplanationSortType, ExplanationOrderKey>
> = {
  candidateId: {
    desc: 'CandidateIdDesc',
    asc: 'CandidateIdAsc',
  },
  diseaseId: {
    desc: 'DiseaseIdDesc',
    asc: 'DiseaseIdAsc',
  },
  agreementCompletedAt: {
    desc: 'AgreementCompletedAtDesc',
    asc: 'AgreementCompletedAtAsc',
  },
  deliveredAt: {
    desc: 'DeliveredAtDesc',
    asc: 'DeliveredAtAsc',
  },
  createdAt: {
    desc: 'CreatedAt',
    asc: 'CreatedAt',
  },
}
