import React from 'react'

import { Center, Flex, Grid, GridItem, HStack, Text } from '@chakra-ui/react'
import MirohaLogo from 'src/assets/image/miroha-logo.svg'

import { FeatureChannelLabel } from '../../../misc/components/FeatureChannelLabel/FeatureChannelLabel'

const HEADER_HEIGHT = 64
type Props = {
  featureChannel?: 'Stable' | 'Beta' | 'Canary'
  headerTitle?: string
  hospitalName?: string
  finishButton?: React.ReactNode
  height?: number
}

export const ExplanationRoomHeader: React.FC<Props> = ({
  featureChannel,
  headerTitle,
  hospitalName,
  finishButton,
  height = HEADER_HEIGHT,
}) => {
  return (
    <Grid
      templateColumns="1fr 1fr 1fr"
      h={`${height}px`}
      w="full"
      as="header"
      alignContent="center"
      p="6"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      boxSizing="border-box"
    >
      <GridItem alignItems="center" display="flex">
        <HStack spacing="4">
          <img src={MirohaLogo} alt="miroha-logo" />
          {!!hospitalName && (
            <Text
              as="span"
              fontSize={{ base: 'md', md: 'lg' }}
              color="blue.500"
              fontWeight="bold"
            >
              {hospitalName}
            </Text>
          )}
          <FeatureChannelLabel featureChannel={featureChannel} />
        </HStack>
      </GridItem>
      <GridItem alignItems="center">
        {!!headerTitle && (
          <Center h="full">
            <Text
              as="h1"
              fontSize={{
                base: headerTitle.length >= 20 ? 'xs' : 'lg',
                md: headerTitle.length >= 20 ? 'sm' : 'md',
                lg: headerTitle.length >= 20 ? 'md' : '2xl',
              }}
              fontWeight="bold"
              color="green.500"
              textAlign="center"
            >
              {headerTitle}
            </Text>
          </Center>
        )}
      </GridItem>
      <GridItem w="full" textAlign="right" alignContent="center">
        {!!finishButton && (
          <Flex h="full" align="center" justify="end">
            {finishButton}
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}
