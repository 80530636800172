import { useSelectedTrial } from 'src/features/auth/context'
import { Trial } from 'src/features/trial/types'
import { useMutation, UseMutationOption } from 'src/hooks/use-mutation'
import { createApiClient } from 'src/lib/api-client'

import { ExplanationRevision } from '../../types'

const resendNotification = async ({
  trialUid,
  explanationRevisionUid,
}: {
  trialUid: Trial['uid']
  explanationRevisionUid: ExplanationRevision['uid']
}): Promise<void> => {
  const res = await createApiClient({
    path: '/trials/{trial_uid}/explanation_revisions/{explanation_revision_uid}/resend_notification',
    httpMethod: 'post',
    params: {
      paths: {
        trial_uid: trialUid,
        explanation_revision_uid: explanationRevisionUid,
      },
    },
  }).request()

  if (res.result === 'error') {
    throw new Error(res.error.data.message)
  }
}

export const useResendNotification = (option?: UseMutationOption<void>) => {
  const { selectedTrial } = useSelectedTrial()
  return useMutation(
    ({
      explanationRevisionUid,
    }: {
      explanationRevisionUid: ExplanationRevision['uid']
    }) =>
      resendNotification({
        trialUid: selectedTrial.uid,
        explanationRevisionUid,
      }),
    option,
  )
}
