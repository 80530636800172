import { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { QuestionnaireField } from 'src/modules/entities/questionnaire-field/entity'

import { actions } from './redux'

export const useEditQuestionnaireField = ({
  sectionIndex,
  uid,
}: {
  sectionIndex: number
  uid: string
}) => {
  const dispatch = useDispatch()

  const onDeleteField = useCallback(
    () =>
      dispatch(
        actions.deleteField({
          sectionIndex,
          uid,
        }),
      ),
    [dispatch, sectionIndex, uid],
  )

  const onUpdateField = useCallback(
    ({
      values,
    }: {
      values: Partial<Pick<QuestionnaireField, 'title' | 'answerType' | 'unit'>>
    }) =>
      dispatch(
        actions.updateField({
          sectionIndex,
          uid,
          values,
        }),
      ),
    [dispatch, sectionIndex, uid],
  )

  const onUpField = useCallback(
    () =>
      dispatch(
        actions.changeIndexField({
          sectionIndex,
          uid,
          direction: 'up',
        }),
      ),
    [dispatch, sectionIndex, uid],
  )

  const onDownField = useCallback(
    () =>
      dispatch(
        actions.changeIndexField({
          sectionIndex,
          uid,
          direction: 'down',
        }),
      ),
    [dispatch, sectionIndex, uid],
  )

  return {
    onDeleteField,
    onUpdateField,
    onUpField,
    onDownField,
  }
}
