import React, { useCallback, useState } from 'react'

import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Button } from 'src/components/base/button/button'
import { gray, blue, white } from 'src/components/base/color/palette'
import { AnchorLink } from 'src/components/base/text-link'
import { Input } from 'src/components/form-redesigned/input'
import { RHFSingleRadio } from 'src/components/form-redesigned/radio'
import { RHFSingleCheckbox } from 'src/components/form-redesigned/single-checkbox'
import { Textarea } from 'src/components/form-redesigned/textarea'
import { Spacer } from 'src/components/spacer/spacer'
import { useOpenModal } from 'src/hooks/use-open-modal'
import Modal from 'src/Modal'
import { Patient } from 'src/modules/entities/patient/entity'
import { isTrialStarted } from 'src/modules/entities/patient/util'
import { Questionnaire } from 'src/modules/entities/questionnaire/entity'
import {
  QuestionnaireAnswer,
  QuestionnaireAnswerStatus,
  QuestionnaireAnswerAnswerStatus,
} from 'src/modules/entities/questionnaire-answer/entity'
import { statusString } from 'src/modules/entities/questionnaire-answer/util'
import { AnswerType } from 'src/modules/entities/questionnaire-field/entity'
import { Schedule } from 'src/modules/entities/schedule/entity'
import { answerStartDate } from 'src/modules/entities/schedule/util'
import { formatTimestamp } from 'src/modules/util/datetime-utils'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

export type Props = {
  patient: Patient
  questionnaires: Questionnaire[]
  questionnaireAnswers: QuestionnaireAnswer[]
  schedules: Schedule[]
  showQuestionnaireAnswers: boolean
}

export const QuestionnaireAnswerPage: React.FC<Props> = props => {
  const {
    patient,
    questionnaires,
    questionnaireAnswers,
    showQuestionnaireAnswers,
  } = props

  const schedules = props.schedules.sort((a, b) => {
    if (a.shouldDeliverAfterTrial) return 1
    if (b.shouldDeliverAfterTrial) return -1

    return a.startAfterDays - b.startAfterDays
  })

  if (!patient.answerStartDate || !isTrialStarted(patient)) return <Empty />

  const latestAnswer = questionnaireAnswers.reduce((a, b) => {
    if (!a.answeredAt) return b
    if (!b.answeredAt) return a

    return dayjs(a.answeredAt).isAfter(dayjs(b.answeredAt)) ? a : b
  }, {} as QuestionnaireAnswer)

  if (!showQuestionnaireAnswers) return null
  return (
    <Wrapper>
      <Summary>
        <Row>
          <LabelArea>
            <Text fontWeight="bold">最終回答日時</Text>
          </LabelArea>
          <Text fontSize="sm">
            {latestAnswer.answeredAt
              ? formatTimestamp({ timestamp: latestAnswer.answeredAt })
              : '-'}
          </Text>
        </Row>
        <Row>
          <LabelArea>
            <Text fontWeight="bold">総回答</Text>
          </LabelArea>
          <Text fontSize="sm">{`${
            questionnaireAnswers.filter(
              qa => qa.status === QuestionnaireAnswerStatus.Answered,
            ).length
          } / ${questionnaireAnswers.length}`}</Text>
        </Row>
        <Row>
          <LabelArea>
            <Text fontWeight="bold">未回答数</Text>
          </LabelArea>
          <Text fontSize="sm">
            {
              questionnaireAnswers.filter(
                qa => qa.status === QuestionnaireAnswerStatus.Unanswered,
              ).length
            }
          </Text>
        </Row>
      </Summary>
      <Spacer horizontal size={32} />
      <TableWrapper>
        <Table>
          <TableRow>
            <TableCell wide></TableCell>
            {schedules.map(schedule => (
              <TableCell key={schedule.uid}>
                <Text
                  fontWeight="bold"
                  textAlign="center"
                  w="100%"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {schedule.title}
                </Text>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell wide>
              <Text>日付</Text>
            </TableCell>
            {schedules.map(schedule => (
              <TableCell key={schedule.uid}>
                <Text>
                  {answerStartDate({
                    schedule,
                    answerStartDate: patient.answerStartDate,
                  })}
                </Text>
              </TableCell>
            ))}
          </TableRow>
          {questionnaires.map(questionnaire => {
            const filteredQuestionnaireAnswers = questionnaireAnswers.filter(
              qa => qa.questionnaireUid === questionnaire.uid,
            )

            if (filteredQuestionnaireAnswers.length === 0) return null

            return (
              <TableRow key={questionnaire.uid}>
                <TableCell tall wide>
                  <Text
                    fontWeight="bold"
                    textAlign="center"
                    w="100%"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {filteredQuestionnaireAnswers[0].title}
                  </Text>
                  <Text>{`${
                    filteredQuestionnaireAnswers.filter(
                      qa => qa.status === QuestionnaireAnswerStatus.Answered,
                    ).length
                  }/${filteredQuestionnaireAnswers.length}`}</Text>
                </TableCell>
                {schedules.map(schedule => {
                  const questionnaireAnswer = filteredQuestionnaireAnswers.find(
                    qa => qa.scheduleUid === schedule.uid,
                  )

                  return (
                    <TableCell tall key={schedule.uid}>
                      <QuestionnaireAnswerCell
                        questionnaireAnswer={questionnaireAnswer}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

type QuestionnaireAnswerCellProps = {
  questionnaireAnswer: QuestionnaireAnswer | undefined
}

const QuestionnaireAnswerCell: React.FC<QuestionnaireAnswerCellProps> = ({
  questionnaireAnswer,
}) => {
  const {
    modalOpen,
    handlers: { openModal, closeModal },
  } = useOpenModal()

  if (!questionnaireAnswer) {
    return <Text>-</Text>
  }

  return (
    <>
      {questionnaireAnswer.answerStatus ===
      QuestionnaireAnswerAnswerStatus.Answered ? (
        <>
          <AnchorLink color={blue[50]} onClick={openModal}>
            {statusString({ questionnaireAnswer })}
          </AnchorLink>
          {modalOpen && (
            <QuestionnaireAnswerDetailModal
              onClose={closeModal}
              questionnaireAnswer={questionnaireAnswer}
            />
          )}
        </>
      ) : (
        <Text fontSize="sm">{statusString({ questionnaireAnswer })}</Text>
      )}
    </>
  )
}

type QuestionnaireAnswerDetailModalProps = {
  onClose: () => void
  questionnaireAnswer: QuestionnaireAnswer
}

const QuestionnaireAnswerDetailModal: React.FC<
  QuestionnaireAnswerDetailModalProps
> = ({ onClose, questionnaireAnswer }) => {
  const { displayName, sectionAnswers, answeredAt } = questionnaireAnswer
  const [page, setPage] = useState(0)
  const totalPages = sectionAnswers.length

  const sortedSectionAnswers = sectionAnswers.sort((a, b) => a.index - b.index)
  const sa = sortedSectionAnswers[page]

  const onClickPrevPage = useCallback(() => {
    if (page > 0) setPage(page - 1)
  }, [page, setPage])

  const onClickNextPage = useCallback(() => {
    if (page < totalPages - 1) setPage(page + 1)
  }, [page, setPage, totalPages])

  if (!sa) return null

  return (
    <Modal onClose={onClose} closable={true}>
      <ModalContent>
        <Text fontSize="lg" fontWeight="bold">
          {displayName}
        </Text>
        <Spacer size={30} />
        <Header>
          <Text color={blue[50]}>{statusString({ questionnaireAnswer })}</Text>
          <Spacer horizontal size={8} />
          <Text>回答日時</Text>
          <Spacer horizontal size={8} />
          <Text>{formatTimestamp({ timestamp: answeredAt })}</Text>
        </Header>
        <Spacer size={8} />
        <Page>
          <Text fontSize="lg" fontWeight="bold">
            {sa.title}
          </Text>
          <Spacer size={8} />
          {sa.fieldAnswers
            .sort((a, b) => a.index - b.index)
            .map(fa => {
              return (
                <FieldContainer key={fa.uid}>
                  <Field>
                    <Text whiteSpace="pre-wrap">{fa.title}</Text>
                    <Spacer size={10} />
                    {fa.answerType === AnswerType.Radio && (
                      <Options>
                        {fa.choiceAnswers
                          .sort((a, b) => a.index - b.index)
                          .map(ca => (
                            <RHFSingleRadio
                              key={ca.uid}
                              name={ca.uid}
                              defaultChecked={ca.checked}
                              label={ca.description}
                              value={ca.uid}
                              disabled={true}
                            />
                          ))}
                      </Options>
                    )}
                    {fa.answerType === AnswerType.CheckBox && (
                      <Options>
                        {fa.choiceAnswers
                          .sort((a, b) => a.index - b.index)
                          .map(ca => (
                            <RHFSingleCheckbox
                              key={ca.uid}
                              name={ca.uid}
                              defaultChecked={ca.checked}
                              label={ca.description}
                              disabled={true}
                            />
                          ))}
                        <Spacer size={10} />
                      </Options>
                    )}

                    {fa.answerType === AnswerType.Text && (
                      <Textarea defaultValue={fa.value} disabled={true} />
                    )}

                    {fa.answerType === AnswerType.Number && (
                      <Number>
                        <Input
                          type="text"
                          defaultValue={fa.value}
                          disabled={true}
                        />
                        <Text fontSize="sm" wordBreak="break-all">
                          {fa.unit}
                        </Text>
                      </Number>
                    )}
                  </Field>
                  <Spacer size={10} />
                </FieldContainer>
              )
            })}
          <Spacer size={20} />
        </Page>
        <Pager>
          <Flex alignCenter>
            <Button
              text="< <"
              size="S"
              buttonType="normal"
              disabled={page === 0}
              onClick={onClickPrevPage}
            />
            <PagerLabels>
              <PagerNumberLabel>{page + 1}</PagerNumberLabel>
              <PagerLabelDivider />
              <PagerNumberLabel>{totalPages}</PagerNumberLabel>
            </PagerLabels>
            <Button
              text="> >"
              size="S"
              buttonType="normal"
              disabled={page >= totalPages - 1}
              onClick={onClickNextPage}
            />
          </Flex>
        </Pager>
      </ModalContent>
    </Modal>
  )
}

const Empty: React.FC = () => {
  return (
    <Wrapper>
      <Text>試験が開始されていません</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const LabelArea = styled.div`
  width: 140px;
`

const TableWrapper = styled.div``

const Table = styled.div`
  display: inline-block;
  height: 100%;
  border: 1px solid ${gray[40]};
  border-radius: 12px;
`

const TableRow = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    border-bottom: 1px solid ${gray[40]};
  }
`

const TableCell = styled.div<{ tall?: boolean; wide?: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 4px;
  padding-right: 4px;
  height: ${props => (props.tall ? '62px' : '42px')};
  width: ${props => (props.wide ? '180px' : '140px')};

  :not(:last-child) {
    border-right: 1px solid ${gray[40]};
  }
`

const ModalContent = styled.div`
  width: 581px;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 80vh;
  overflow: auto;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const Page = styled.div`
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${blue[10]};
  border-radius: 10px;
  overflow-y: auto;
`

const FieldContainer = styled.div``

const Field = styled.div`
  background-color: ${white};
  border-radius: 5px;
  padding: 16px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
`

const Number = styled.div`
  display: flex;
  align-items: center;
`

const Pager = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`

const PagerLabels = styled.div`
  display: inline-block;
  margin: 0 30px;
`

const PagerNumberLabel = styled.span``

const PagerLabelDivider = styled.span`
  &:after {
    content: '/';
    margin: 0 20px;
  }
`
