import { Badge, BadgeProps } from '@chakra-ui/react'

type Props = {
  role: 'Dr' | 'CRC' | 'DM' | 'CRA'
  isPartner?: boolean
}

const badgeStyle = (isPartner: boolean): BadgeProps => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    w: '28px',
    h: '18px',
    p: '0',
    fontWeight: 'bold',
    textTransform: 'none',
    bg: isPartner ? 'gray.100' : 'gray.600',
    color: isPartner ? 'gray.700' : 'white',
  }
}

export const RoleBadge: React.FC<Props> = ({ role, isPartner = false }) => {
  return <Badge {...badgeStyle(isPartner)}>{role}</Badge>
}
