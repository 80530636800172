import { Flex, HStack, Stack, Text } from '@chakra-ui/react'

import { MessageWarning } from '../icon'

type Props = {
  message: string
  subMessage?: string
  iconSize?: string
  fontSize?: string
}

export const WarningMessage: React.FC<Props> = ({
  message,
  subMessage,
  iconSize = 'md',
  fontSize = 'md',
}) => {
  return (
    <HStack spacing="1.5" color="warning" align="start">
      <Flex align="center" h="1.5rem">
        <MessageWarning size={iconSize} />
      </Flex>
      <Stack spacing="1">
        <Text fontSize={fontSize} fontWeight="normal">
          {message}
        </Text>
        {!!subMessage && (
          <Text fontSize={fontSize} fontWeight="normal">
            {subMessage}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}
