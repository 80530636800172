import { Center, Divider, Spinner, Stack, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Markdown } from 'src/components/Markdown/Markdown'
import { RedirectLogin } from 'src/components/RedirectLogin/RedirectLogin'
import { formatDate } from 'src/utils/formatDate'

import { useAuth } from '../../../auth/hooks'
import { useReleaseNote } from '../../api/getReleaseNote'
import { NotFound } from '../NotFound/NotFound'
import { SimpleScreenLayout } from '../SimpleScreenLayout/SimpleScreenLayout'

export const ReleaseNoteScreen: React.FC = () => {
  const { state } = useAuth()

  const { version = '' } = useParams<{ version: string }>()

  const { data } = useReleaseNote({ version })

  if (state === 'Failed') {
    return <RedirectLogin />
  }

  if (!data || state === 'Challenging')
    return (
      <Center h="full" w="full">
        <Spinner />
      </Center>
    )

  if (data.content === null) {
    return <NotFound />
  }

  return (
    <SimpleScreenLayout>
      <Stack my="8" spacing="8" as="section" aria-label="release information">
        <Stack>
          <Text fontSize="lg" fontWeight="bold" as="h2">
            リリース日
          </Text>
          <Text>
            {formatDate(data.metadata.releaseDate, 'YYYY/MM/DD (ddd)')}
          </Text>
        </Stack>
        <Stack>
          <Text fontSize="lg" fontWeight="bold" as="h2">
            バージョン
          </Text>
          <Text>{data.metadata.version}</Text>
        </Stack>
      </Stack>
      <Divider />
      <Markdown content={data.content} imagePath="/release-note/images" />
    </SimpleScreenLayout>
  )
}
