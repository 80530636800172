import {
  Field,
  RepeatableSectionTypeDef,
  SectionTypeDef,
} from '@micin-jp/chicken-schema'

import { FlattenFieldMap } from './getFlattenFields'

type SectionField =
  | (Field & { typeDef: SectionTypeDef })
  | (Field & { typeDef: RepeatableSectionTypeDef })

export const findSection = (
  fieldMap: FlattenFieldMap,
  fid: Field['fid'],
): SectionField | undefined => {
  const field = fieldMap.get(fid)
  if (!field) {
    return undefined
  }
  if (isSection(field)) {
    return field
  }

  const sections = Array.from(fieldMap.values()).filter(isSection)

  return sections.find(section =>
    section.typeDef.fields.some(f => f.fid === fid),
  )
}

export const isSection = (field: Field): field is SectionField => {
  return (
    field.typeDef.type === 'Section' ||
    field.typeDef.type === 'RepeatableSection'
  )
}
