import React from 'react'

import { WithCommentModal } from './with-comment-modal'

type Props = {
  onClose: () => void
  onSubmit: (comment: string) => void
}

export const WithdrawModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  return (
    <WithCommentModal
      title="承認依頼取り下げ"
      onClose={onClose}
      onSubmit={onSubmit}
      required={false}
      submitText="承認依頼取り下げ"
      submitButtonType="important"
      descriptionComponent={null}
    />
  )
}
