import React, { useState } from 'react'

import { ForgetPasswordForm } from 'src/modules/auth/password/forget/forget-password-form'

import { ConfirmForgetPassword } from './confirm-forget-password'
import { ForgetPassword } from './forget-password'

export const ForgetPasswordContainer: React.FC<{}> = () => {
  const [userId, setUserId] = useState('')
  const [resettingPasssword, setResettingPasssword] = useState<
    'Yet' | 'Doing' | 'Done'
  >('Yet')

  switch (resettingPasssword) {
    case 'Yet':
      return (
        <ForgetPassword
          next={() => setResettingPasssword('Doing')}
          setUserId={(userId: string) => setUserId(userId)}
        />
      )
    case 'Doing':
      return (
        <ForgetPasswordForm
          userId={userId}
          next={() => setResettingPasssword('Done')}
        />
      )
    case 'Done':
      return <ConfirmForgetPassword />
    default:
      return <></>
  }
}
