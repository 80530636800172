import React from 'react'

import { Button, Flex } from '@chakra-ui/react'
import { Add } from 'src/components/icon'
import { SpaceProps } from 'styled-system'

type Props = {
  onAdd: () => void
}

export const AddSmallItem: React.FC<Props & SpaceProps> = props => {
  const { onAdd } = props

  return (
    <Flex justify="end">
      <Button leftIcon={<Add />} variant="outline" onClick={onAdd}>
        新規フィールド作成
      </Button>
    </Flex>
  )
}
