import React, { useEffect } from 'react'

import { CompleteDocuSignEdit } from './complete-docusign-edit'
import { useCompleteDocuSignEdit } from './complete-docusign-edit.hook'

export const CompleteDocuSignEditContainer: React.FC = () => {
  const { onShown } = useCompleteDocuSignEdit()

  useEffect(() => {
    onShown()
  }, [onShown])

  return <CompleteDocuSignEdit />
}
