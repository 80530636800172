import React from 'react'

import { Text } from '@chakra-ui/react'
import {
  PaginationBack,
  PaginationNext,
  RightArrow,
} from 'src/components/__legacy__icon/monochrome'
import { blue, gray } from 'src/components/base/color/palette'
import { Spacer } from 'src/components/spacer/spacer'
import { Role } from 'src/modules/entities/member/entity'
import { Worksheet } from 'src/modules/entities/worksheet/entity'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { SmallItemAnswerComponent } from '../../../../patient/detail/worksheet/common/detail/small-item/small-item-answer'

type Props = {
  worksheet: Worksheet
  currentLoglineUid: string
  onChangeLoglineUid: (uid: string | null) => void
  templateFolderName: string
}

export const PreviewLoglineDetail: React.FC<Props> = props => {
  const {
    worksheet,
    currentLoglineUid,
    onChangeLoglineUid,
    templateFolderName,
  } = props

  const logline = worksheet.mediumItemAnswers.find(
    mia => mia.uid === currentLoglineUid,
  )

  if (!logline) return null

  const loglines = worksheet.mediumItemAnswers.filter(
    mia => mia.mediumItemUid === logline.mediumItemUid,
  )

  const prevLoglineUid = loglines.find(
    l => l.lineNumber === logline.lineNumber - 1,
  )?.uid
  const nextLoglineUid = loglines.find(
    l => l.lineNumber === logline.lineNumber + 1,
  )?.uid

  const rootSmallItemAnswers = Object.values(logline.smallItemAnswerMap)
    .filter(sia => sia.parentUid === null)
    .sort((a, b) => a.index - b.index)

  const onClickBackLink = () => {
    //currentLoglineUidがなければワークシート詳細を表示する
    onChangeLoglineUid(null)
  }
  const onClickPrev = () => {
    if (!prevLoglineUid) return
    onChangeLoglineUid(prevLoglineUid)
  }
  const onClickNext = () => {
    if (!nextLoglineUid) return
    onChangeLoglineUid(nextLoglineUid)
  }

  return (
    <Wrapper>
      <Head>
        <Flex alignCenter wrap>
          {/* リンクの動きが異なるのでBreadcrumbコンポーネントを使用せず同様のスタイルをここで定義している */}
          <BreadcrumbContainer>
            <Text fontWeight="bold" color={gray[80]}>
              {templateFolderName}
            </Text>
            <Spacer size={8} horizontal />
            <RightArrow color={gray[80]} />
            <Spacer size={8} horizontal />
            <BreadcrumbLink onClick={onClickBackLink}>
              {worksheet.title}
            </BreadcrumbLink>
            <Spacer size={8} horizontal />
            <RightArrow color={gray[80]} />
            <Spacer size={8} horizontal />
            <Text fontWeight="bold" color={gray[80]}>
              {logline.title}
            </Text>
          </BreadcrumbContainer>
        </Flex>

        <Spacer size={10} />

        <LoglineNavigation
          currentLineNumber={logline.lineNumber}
          totalCount={loglines.length}
          prevLoglineUid={prevLoglineUid}
          onClickPrev={onClickPrev}
          nextLoglineUid={nextLoglineUid}
          onClickNext={onClickNext}
        />
      </Head>

      <Spacer size={40} />

      <SmallItemAnswersWrapper>
        {rootSmallItemAnswers.map(sia => (
          <SmallItemAnswerComponent
            key={sia.uid}
            smallItemAnswer={sia}
            smallItemAnswerMap={logline.smallItemAnswerMap}
            isPreview={true}
            currentRole={Role.Dr}
          />
        ))}
      </SmallItemAnswersWrapper>
    </Wrapper>
  )
}

type NavigationProps = {
  currentLineNumber: number
  totalCount: number
  prevLoglineUid: string | undefined
  onClickPrev: () => void
  nextLoglineUid: string | undefined
  onClickNext: () => void
}

const LoglineNavigation: React.FC<NavigationProps> = props => {
  const {
    currentLineNumber,
    totalCount,
    prevLoglineUid,
    onClickPrev,
    nextLoglineUid,
    onClickNext,
  } = props

  return (
    <NavigationWrapper>
      {prevLoglineUid ? (
        <AdjacentLoglineLink onClick={onClickPrev}>
          <PaginationBack color={blue[70]} />
        </AdjacentLoglineLink>
      ) : (
        <PaginationBack color={gray[55]} />
      )}

      <Spacer size={10} horizontal />

      <Text fontSize="sm">
        # {currentLineNumber} / {totalCount}
      </Text>

      <Spacer size={10} horizontal />

      {nextLoglineUid ? (
        <AdjacentLoglineLink onClick={onClickNext}>
          <PaginationNext color={blue[70]} />
        </AdjacentLoglineLink>
      ) : (
        <PaginationNext color={gray[55]} />
      )}
    </NavigationWrapper>
  )
}

const Wrapper = styled.div`
  .log-link {
    pointer-events: none;
  }
`

const Head = styled.div``

const BreadcrumbContainer = styled.div`
  width: 100%;
  display: flex;
`

const BreadcrumbLink = styled.div`
  cursor: pointer;
  font-size: 16px; // same as H6
  line-height: 23px; // same as H6
  font-weight: bold;
  text-decoration: none;
  color: ${blue[70]};
  :hover {
    color: ${blue[40]};
  }
  :active {
    color: ${blue[10]};
  }
`

const NavigationWrapper = styled.div`
  margin-top: 10px;
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: ${blue[10]};
  border-radius: 3px;
`

const SmallItemAnswersWrapper = styled.div`
  overflow-y: scroll;
  max-height: 60vh;
  padding: 24px;
`

const AdjacentLoglineLink = styled.div`
  cursor: pointer;
`
