import { useMemo } from 'react'

import { useParams } from 'react-router'
import { Authenticator } from 'src/features/auth/components'
import { useAuthenticatedAccount } from 'src/features/auth/context'
import { Member } from 'src/features/member/types'
import { VideoCallRoom } from 'src/features/videoCall/components'
import { getFullName } from 'src/utils/getFullName'

import {
  GetPatientVideoCallReturn,
  usePatientVideoCall,
} from '../../api/getPatientVideoCall'
import { usePatientVideoCallJwtToken } from '../../hooks/usePatientVideoCallJwtToken'

type Param = {
  patientVideoCallUid: string
  trialUid: string
}

const getEndedText = (isPartner: boolean) => {
  if (isPartner) {
    return 'ビデオ通話を終了しました。\n端末を医療スタッフへお返しください。'
  }
  return 'ビデオ通話を終了しました。'
}

export const PatientVideoCallScreen: React.FC = () => {
  const { patientVideoCallUid = '' } = useParams<Param>()

  const { jwtToken } = usePatientVideoCallJwtToken({
    patientVideoCallUid,
  })

  const { data: patientVideoCall } = usePatientVideoCall({
    patientVideoCallUid,
  })

  return (
    <Authenticator>
      {patientVideoCall && jwtToken && (
        <AuthChecker patientVideoCall={patientVideoCall}>
          {({ isPartner, member }) => (
            <VideoCallRoom
              jwtToken={jwtToken}
              endedText={getEndedText(isPartner)}
              participantName={getFullName(member)}
            />
          )}
        </AuthChecker>
      )}
    </Authenticator>
  )
}

type AuthCheckerProps = {
  patientVideoCall: GetPatientVideoCallReturn
  children: ({
    isPartner,
    member,
  }: {
    isPartner: boolean
    member: Member
  }) => React.ReactNode
}

const AuthChecker: React.FC<AuthCheckerProps> = ({
  patientVideoCall,
  children,
}) => {
  const {
    account: { selectedTrialHospitalUid, member },
  } = useAuthenticatedAccount()

  const childNode = useMemo(() => {
    if (
      patientVideoCall.__typename === 'PatientVideoCallPartnerTrialHospital' &&
      patientVideoCall.trialHospitalUid === selectedTrialHospitalUid
    ) {
      return children({ isPartner: true, member })
    }
    return children({ isPartner: false, member })
  }, [children, patientVideoCall, selectedTrialHospitalUid, member])

  return <>{childNode}</>
}
