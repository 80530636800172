import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import produce from 'immer'

import { AgreementParams } from './entity'

export type State = {
  agreements: Agreements | null
}

type Agreements = {
  agreeTermsOfService: boolean
  agreePrivacyPolicy: boolean
  agreeExternalTransmissionPolicy: boolean
}

const initialState: State = {
  agreements: null,
}

const slice = createSlice({
  name: 'auth/agreement',
  initialState,
  reducers: {
    setAgreementState: (state: State, action: PayloadAction<State>) =>
      produce(state, draft => {
        draft.agreements = action.payload.agreements
      }),
    setAgreementPartially: (
      state: State,
      action: PayloadAction<Partial<AgreementParams>>,
    ) => {
      if (state.agreements === null) {
        return state
      }
      return produce(state, draft => {
        draft.agreements = {
          agreeTermsOfService:
            action.payload.agreeTermsOfService !== undefined
              ? action.payload.agreeTermsOfService
              : state.agreements?.agreeTermsOfService!,
          agreePrivacyPolicy:
            action.payload.agreePrivacyPolicy !== undefined
              ? action.payload.agreePrivacyPolicy
              : state.agreements?.agreePrivacyPolicy!,
          agreeExternalTransmissionPolicy:
            action.payload.agreeExternalTransmissionPolicy !== undefined
              ? action.payload.agreeExternalTransmissionPolicy
              : state.agreements?.agreeExternalTransmissionPolicy!,
        }
      })
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer
