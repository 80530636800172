import { Box, Flex } from '@chakra-ui/react'

import { useSelectedTrial } from '../../../auth/context'
import { useThisSession } from '../../hooks/useThisSession'
import { ExplanationRoomHeader } from '../ExplanationRoomHeader/ExplanationRoomHeader'
import { FinishSession } from '../FinishSession/FinishSession'
import { HospitalSessionChecker } from '../HospitalSessionChecker/HopitalSessionChecker'
import { HospitalSidebar } from '../HospitalSidebar/HospitalSidebar'

const HEADER_HEIGHT = 64

type Props = {
  headerTitle?: string
  sessionUid: string
  children: React.ReactNode
  isPartner?: boolean
  showSidebar?: boolean
}
export const HospitalLayout: React.FC<Props> = ({
  headerTitle,
  sessionUid,
  children,
  isPartner = false,
  showSidebar = true,
}) => {
  const { selectedTrial } = useSelectedTrial()

  const thisSession = useThisSession({
    sessionUid: sessionUid,
    forPatient: false,
  })

  return (
    <HospitalSessionChecker sessionUid={sessionUid} isPartner={isPartner}>
      <Flex direction="column" w="full" h="full">
        <ExplanationRoomHeader
          featureChannel={selectedTrial.featureChannel}
          headerTitle={headerTitle}
          hospitalName={
            thisSession.fetched
              ? isPartner
                ? thisSession.partnerTrialHospital?.hospital.name
                : thisSession.trialHospital.hospital.name
              : undefined
          }
          finishButton={<FinishSession sessionUid={sessionUid} />}
        />
        <Flex h={`calc(100svh - ${HEADER_HEIGHT}px)`}>
          <Box as="main" bg="green.50" flex="1" h="full" overflowY="auto">
            {children}
          </Box>
          {showSidebar && (
            <Box
              as="section"
              aria-label="sidebar"
              w={{ base: '240px', lg: '360px' }}
              p="6"
              bg="white"
              h="full"
              overflow="auto"
            >
              <HospitalSidebar explanationSessionUid={sessionUid} />
            </Box>
          )}
        </Flex>
      </Flex>
    </HospitalSessionChecker>
  )
}
