// 汎用的なエラーメッセージは適宜ここに追加していく
export const errorMessages = {
  requiredWithLabel: (label?: string) =>
    `${label ? `${label}は` : ''}必須です。`,
  required: () => '必須項目です。',
  shouldSelect: (label?: string) =>
    `${label ? `${label}を` : ''}選択してください。`,
  wrongFormat: (label?: string) =>
    `${label ? `${label}の` : ''}フォーマットが不正です。`,
  hiragana: (label?: string) =>
    `${label ? `${label}は` : ''}ひらがなで入力してください。`,
  maxLength: (max: number, label?: string) =>
    `${label ? `${label}は` : ''}${max}文字以内で入力してください。`,
  maxDigits: (max: number) => `${max}桁以内で入力してください。`,
  halfSizeAlphanumericAndSymbol: (label?: string) =>
    `${label ? `${label}は` : ''}半角英数字記号で入力してください。`,
  afterNow: () => '現在時刻よりも後の時間を設定してください。',
  onlyNumber: () => '数値のみ入力可能です。',
  email: () => 'メールアドレスの形式が正しくありません。',
  phoneNumber: () => '電話番号の形式が正しくありません。',
  yearRange: () => '年は1000-9999の範囲で入力してください。',
  date: () => '入力日付が不正です。',
  time: () => '入力時刻が不正です。',
  incompleteDate: () =>
    '予約日時に不備があります。全てのフォームに入力してください。',
  numberRange: (min: number, max: number) =>
    `${min}〜${max}の範囲で入力してください。`,
}
