import {
  GqlError,
  PatientVideoCallsOrder,
  GetAvailablePatientVideoCallsQuery,
  GetAvailablePatientVideoCallsDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { patientCacheKey } from '../cacheKey'

type GetPatientVideoCallsReturn =
  GetAvailablePatientVideoCallsQuery['patient']['videoCalls']

type GetPatientVideoCallsInput = {
  patientUid: string
  order?: PatientVideoCallsOrder
}

const getAvailablePatientVideoCalls = async (
  input: GetPatientVideoCallsInput,
) => {
  try {
    const res = await graphqlRequest(GetAvailablePatientVideoCallsDocument, {
      patientUid: input.patientUid,
      order: input.order ?? 'CreatedAtAsc',
    })
    return res.patient.videoCalls
  } catch (error) {
    throw error
  }
}

export const useAvailablePatientVideoCalls = ({
  patientUid,
  order,
  ...option
}: GetPatientVideoCallsInput &
  SWRConfiguration<GetPatientVideoCallsReturn, GqlError>) => {
  return useSWR<GetPatientVideoCallsReturn, GqlError>(
    patientCacheKey.getAvailablePatientVideoCalls(patientUid),
    () => getAvailablePatientVideoCalls({ patientUid, order }),
    option,
  )
}
