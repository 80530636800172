import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions as templateActions } from 'src/modules/entities/template/redux'
import { routes } from 'src/modules/routes'

export const useFetch = ({
  trialUid,
  templateUid,
  pathForNotFound,
}: {
  trialUid: string
  templateUid: string
  pathForNotFound: string
}) => {
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(templateActions.fetchSingle({ trialUid, templateUid }))
        setFetched(true)
      } catch (error) {
        // TOOD: login key?
        console.error('templateActions.fetchSingle Error: ', error)
        if (error.original.response.status === 404) {
          navigate(pathForNotFound)
        } else if (error.original.response.status === 403) {
          navigate(routes.httpError403)
        } else {
          navigate(`${routes.requiredLogin}`)
        }
      }
    }

    fetch()
  }, [dispatch, navigate, pathForNotFound, setFetched, templateUid, trialUid])

  return {
    fetched,
  }
}
