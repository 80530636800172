import React from 'react'

import { Hospital, Hospitals } from 'src/components/__legacy__icon/monochrome'
import { blue } from 'src/components/base/color/palette'
import styled from 'styled-components'

import { DataType } from '../../../../../../../../../entities/small-item/entity'
import { SmallItemAnswer } from '../../../../../../../../../entities/small-item-answer/entity'

type Props = {
  smallItemAnswer: SmallItemAnswer
}

export const VisibilityIcon: React.FC<Props> = props => {
  const { smallItemAnswer } = props

  if (smallItemAnswer.type === 'worksheet') {
    return null
  }
  if (smallItemAnswer.dataType === DataType.Clinical) {
    return null
  }
  if (!smallItemAnswer.visibilityIconVisible) {
    return null
  }

  return (
    <Container>
      {smallItemAnswer.viewableHospitalUids.length === 0 ? (
        <Hospitals color={blue[70]} />
      ) : (
        <Hospital color={blue[70]} />
      )}
    </Container>
  )
}

const Container = styled.div`
  padding-left: 10px;
  display: flex;
`
