import React, { useState, useEffect, useCallback } from 'react'

import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions } from 'src/modules/entities/account/redux'
import { Role } from 'src/modules/entities/member/entity'
import {
  Hospital,
  TrialSelection,
  ScreenPhase,
} from 'src/modules/entities/select-trial/entity'
import { routes } from 'src/modules/routes'
import styled, { keyframes } from 'styled-components'

import { blue, gray } from '../base/color/palette'
import { Message } from '../base/message/message'
import { Modal } from '../base/modal/modal'
import { ModalContent } from '../base/modal/modal-content'
import { ModalTitle } from '../base/modal/modal-title'

type Props = {
  trialSelections: TrialSelection[]
}

export const SelectTrialModal: React.FC<Props> = props => {
  const { trialSelections } = props

  const [selectedTrial, setSelectedTrial] = useState<TrialSelection>()
  const [hospitals, setHospitals] = useState<Hospital[]>([])
  const [phase, setPhase] = useState(ScreenPhase.Trial)
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const confirmSelection = useCallback(
    (trialSelection: TrialSelection, trialHospitalUid?: string) => {
      try {
        dispatch(
          actions.selectTrialHospital({
            trialUid: trialSelection.uid,
            trialHospitalUid: trialHospitalUid,
            path: window.location.href,
          }),
        )
        navigate('/switch')
      } catch (error) {
        setErrorMessage(error.Message)
        if (error.original.response.status === 403) {
          navigate(routes.httpError403)
        }
      }
    },
    [dispatch, navigate],
  )

  const transitionLogic = useCallback(
    (selectedTrial: TrialSelection) => {
      if (selectedTrial.trialHospitals.length === 0) {
        setErrorMessage('試験・医療機関情報の取得に失敗しました。')
      }

      //ロールがDMの場合は医療機関を選択せずにダッシュボードに遷移
      if (selectedTrial.role === Role.DM) {
        confirmSelection(selectedTrial)
        return
      }

      //関連する医療施設が１つだけの場合はダッシュボードに遷移
      if (selectedTrial.trialHospitals.length === 1) {
        const singleHospitalUid = selectedTrial.trialHospitals[0].uid
        confirmSelection(selectedTrial, singleHospitalUid)
        return
      }

      setPhase(ScreenPhase.Hospital)
    },
    [confirmSelection],
  )

  useEffect(() => {
    if (trialSelections.length === 0) {
      setErrorMessage('試験・医療機関情報の取得に失敗しました。')
    }
    if (trialSelections.length === 1) {
      //単一の治験にしか紐づいていない場合は治験選択画面を省略
      const singleTrial = trialSelections[0]
      setHospitals(singleTrial.trialHospitals)
      setSelectedTrial(singleTrial)
      transitionLogic(singleTrial)
    }
  }, [transitionLogic, trialSelections])

  const onSelectTrial = (selectedTrialUid: string) => {
    const selectedTrial = getSingleTrial(selectedTrialUid)

    if (selectedTrial) {
      setHospitals(selectedTrial.trialHospitals)
      setSelectedTrial(selectedTrial)
      transitionLogic(selectedTrial)
    } else {
      setErrorMessage('試験情報の取得に失敗しました。')
    }
  }

  const onSelectHospital = (selectedHospitalUid: string) => {
    if (selectedTrial) {
      confirmSelection(selectedTrial, selectedHospitalUid)
    } else {
      setErrorMessage('試験の選択に失敗しました。操作をやり直してください。')
    }
  }

  const getSingleTrial = (selectedTrialUid: string) => {
    return trialSelections.find(item => {
      return item.uid === selectedTrialUid
    })
  }

  const titleLabel =
    phase === 0 ? '臨床試験を選んでください' : '医療機関を選んでください'

  return trialSelections.length === 1 &&
    (trialSelections[0].trialHospitals.length === 1 ||
      trialSelections[0].role === Role.DM) ? (
    <></>
  ) : (
    <>
      {!!errorMessage ? (
        <Message type="error" message={errorMessage} centered />
      ) : (
        <Modal onClose={() => {}} closable={false}>
          <ModalTitle title={titleLabel} />
          <ModalContent>
            <Content>
              {phase === ScreenPhase.Trial && (
                <>
                  {trialSelections.map(item => {
                    return (
                      <Select
                        key={item.uid}
                        onClick={() => onSelectTrial(item.uid)}
                      >
                        <Text>{item.name}</Text>
                      </Select>
                    )
                  })}
                </>
              )}
              {phase === ScreenPhase.Hospital && (
                <>
                  {hospitals.map(item => {
                    return (
                      <SlideInSelect
                        key={item.uid}
                        onClick={() => onSelectHospital(item.uid)}
                      >
                        <Text>{item.name}</Text>
                      </SlideInSelect>
                    )
                  })}
                </>
              )}
            </Content>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

const slidein = keyframes`
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const Content = styled.div`
  width: 100%;
  max-height: 200px;
  box-sizing: border-box;
  overflow-y: auto;
`

const Select = styled.div`
  display: flex;
  padding: 14px;
  box-sizing: border-box;
  border-bottom: 1px dashed ${gray[40]};
  cursor: pointer;
  word-break: break-word;
  &:hover {
    background-color: ${blue[10]};
  }
`

const SlideInSelect = styled(Select)`
  animation: ${slidein} 0.8s;
`
