import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Button } from 'src/components/base/button/button'
import { useCloseModalByDeps } from 'src/hooks/use-close-modal-by-deps'
import { OutputModalContainer } from 'src/modules/dashboard/trial/detail/patient/detail/worksheet/common/detail/output/modal-container'

export const OutputButton = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { worksheetUid = '' } = useParams<{ worksheetUid: string }>()
  useCloseModalByDeps({
    condition: modalOpen === true,
    onClose: () => setModalOpen(false),
    deps: [worksheetUid],
  })

  return (
    <>
      <Button
        size="S"
        text="データ出力"
        onClick={() => setModalOpen(true)}
        buttonType="normal"
      ></Button>

      {modalOpen && (
        <OutputModalContainer onClose={() => setModalOpen(false)} />
      )}
    </>
  )
}
