import {
  GqlError,
  GetSessionForFinishSessionQuery,
  GetSessionForFinishSessionDocument,
} from 'src/lib/gql-client'
import { graphqlRequest } from 'src/lib/gql-client/request'
import useSWR, { SWRConfiguration } from 'swr'

import { explanationRoomCacheKeys } from '../cacheKey'

export type GetSessionForFinishSessionRes =
  GetSessionForFinishSessionQuery['explanationSession']

const getSessionForFinishSession = async ({
  explanationSessionUid,
}: {
  explanationSessionUid: string
}): Promise<GetSessionForFinishSessionRes> => {
  try {
    const res = await graphqlRequest(GetSessionForFinishSessionDocument, {
      explanationSessionUid,
    })
    return res.explanationSession
  } catch (error) {
    throw error
  }
}

export const useSessionForFinishSession = ({
  explanationSessionUid,
  ...option
}: {
  explanationSessionUid: string
} & SWRConfiguration<GetSessionForFinishSessionRes, GqlError>) => {
  return useSWR(
    explanationRoomCacheKeys.getSessionForFinishSession(explanationSessionUid),
    () => getSessionForFinishSession({ explanationSessionUid }),
    option,
  )
}
