import React from 'react'

import { FolderMapModal } from './modal'

type Props = {
  onClose: () => void
  templateUid: string
}

export const FolderMapModalContainer: React.FC<Props> = props => {
  return (
    <FolderMapModal onClose={props.onClose} templateUid={props.templateUid} />
  )
}
