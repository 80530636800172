import { useSelectedTrial } from 'src/features/auth/context'
import { useFlash } from 'src/modules/flash/use-flash'

import { WSDataExportScreenComponent } from './WSDataExportV1ScreenComponent'
import {
  useCancelWSExportJob,
  useCreateWSDataExportJob,
  useWSDataExportJobs,
} from '../../api'
import { WSDataExportJob } from '../../types'
import { getWSDataExportZipDownloadLink } from '../../utils/getZipDownloadLink'

// NOTE: 本来はContainer層を設けずに実装したいが、Reduxのstoreへの依存とAPIのmock化ができていないのでわける
export const WSDataExportV1Screen: React.FC = () => {
  const { selectedTrial } = useSelectedTrial()

  const { showError } = useFlash()

  const { data: wsDataExportJobs, mutate } = useWSDataExportJobs({
    refreshInterval: 3000,
  })

  const createWSDataExportJobApi = useCreateWSDataExportJob({
    onSuccess: data => {
      mutate(current => (current ? [...current, data] : [data]))
    },
    onError: e => {
      showError(e.message)
    },
  })

  const cancelWSDataExportJobApi = useCancelWSExportJob({
    onSuccess: () => {
      mutate()
    },
    onError: e => {
      showError(e.message)
    },
  })

  if (!wsDataExportJobs) return null

  return (
    <WSDataExportScreenComponent
      trialName={selectedTrial.name}
      wsDataExportJobs={wsDataExportJobs}
      onStartJob={async () => {
        await createWSDataExportJobApi.request()
      }}
      onCancelJob={async (wsDataExportJobUid: WSDataExportJob['uid']) => {
        await cancelWSDataExportJobApi.request({ wsDataExportJobUid })
      }}
      getZipDownloadLink={(wsDataExportJobUid: WSDataExportJob['uid']) =>
        getWSDataExportZipDownloadLink({
          trialUid: selectedTrial.uid,
          wsDataExportJobUid,
        })
      }
    />
  )
}
