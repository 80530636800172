import { useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtomValue, useSetAtom } from 'jotai'

import { InformationMessage } from '../../../../components/InformationMessage/InformationMessage'
import { ModalCancelButton } from '../../../../lib/chakra-theme/components'
import { useMirohaToast } from '../../../../lib/chakra-theme/components/toast/use-miroha-toast'
import {
  ReferReferredPatientButton_RefereeTrialHospitalDocument,
  ReferReferredPatientDocument,
} from '../../../../lib/gql-client'
import {
  useGqlMutation,
  useGqlQuerySWRNoSuspense,
} from '../../../../lib/gql-client/request'
import {
  criteriaValueErrorsAtom,
  isCriteriaValuesChangedAtom,
  validateAtom,
} from '../../atom'

type Props = {
  referredPatientUid: string
  onComplete: () => Promise<void>
}

export const ReferReferredPatientButton: React.FC<Props> = ({
  referredPatientUid,
  onComplete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isChanged = useAtomValue(isCriteriaValuesChangedAtom)

  const allErrors = useAtomValue(criteriaValueErrorsAtom)

  const toast = useMirohaToast()

  const [refereeTrialHospital, setRefereeTrialHospital] = useState<{
    uid: string
    hospitalName: string
  } | null>(null)

  useGqlQuerySWRNoSuspense(
    ReferReferredPatientButton_RefereeTrialHospitalDocument,
    isOpen ? {} : null,
    {
      onSuccess: data => {
        if (data.trialHospital.mainTrialHospital) {
          setRefereeTrialHospital({
            uid: data.trialHospital.mainTrialHospital.uid,
            hospitalName: data.trialHospital.mainTrialHospital.hospital.name,
          })
        } else {
          toast({
            status: 'error',
            title: '紹介先の医療機関が見つかりませんでした',
          })
          onClose()
        }
      },
    },
  )

  const { request: refer } = useGqlMutation(ReferReferredPatientDocument, {
    onSuccess: async () => {
      toast({
        status: 'success',
        title: `紹介患者情報を${refereeTrialHospital?.hospitalName}に送信しました。`,
      })
      await onComplete()
    },
  })

  const validateWithUserValidation = useSetAtom(validateAtom)

  return (
    <>
      <Button
        onClick={async () => {
          const validationRes = await validateWithUserValidation()
          if (!validationRes.allOk) {
            return
          }
          onOpen()
        }}
        isDisabled={
          isChanged ||
          !allErrors.validated ||
          (allErrors.validated && !!allErrors.errors)
        }
      >
        紹介
      </Button>
      {isOpen && !!refereeTrialHospital && (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>紹介を実行しますか？</ModalHeader>
            <ModalBody>
              <Text mb="8">
                入力情報が
                {refereeTrialHospital.hospitalName}
                へ送信されます。
              </Text>
              <InformationMessage message="患者の氏名・カルテIDは実施医療機関には表示されません。" />
            </ModalBody>
            <ModalFooter>
              <ModalCancelButton />
              <Button
                onClick={async () => {
                  await refer({
                    input: {
                      referredPatientUid,
                      refereeTrialHospitalUid: refereeTrialHospital.uid,
                    },
                  })
                  onClose()
                }}
              >
                送信する
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
