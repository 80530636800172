import { SERVER_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { IS_MOCK_MODE } from 'src/modules/util/feature-flag'

import { ZipFile } from '../worksheet/common/detail/output/pdf/entity'
import { ZipFileResponse } from '../worksheet/common/detail/output/request'

export const downloadFile = async ({
  trialUid,
  patientUid,
}: {
  trialUid: string
  patientUid: string
}): Promise<ZipFile> => {
  if (IS_MOCK_MODE) {
    return { url: 'https://www.google.com/' }
  }

  const res = await requestGet<ZipFileResponse>({
    url: `${SERVER_URL}/trials/${trialUid}/patients/${patientUid}/worksheets/video_calls/images`,
    params: {},
  })

  return { url: res.data.url }
}
