import React from 'react'

import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import QRCode from 'qrcode.react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/base/button/button'
import { Message } from 'src/components/base/message/message'
import { Modal } from 'src/components/base/modal/modal'
import { ModalContent } from 'src/components/base/modal/modal-content'
import { ModalTitle } from 'src/components/base/modal/modal-title'
import { Input } from 'src/components/form-redesigned/input'
import { Label } from 'src/components/form-redesigned/label'
import { Spacer } from 'src/components/spacer/spacer'
import { useCognitoUser } from 'src/hooks/use-cognito-user'
import { useRequestState } from 'src/modules/server/use-request-state'
import styled from 'styled-components'
import * as yup from 'yup'

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;

  > button:not(:last-child) {
    margin-right: 20px;
  }
`

const QRCodeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IProps {
  onClose: () => void
  onConfirm: () => void
  QRSecretKey: string
}

interface IFormValue {
  authCode: string
}

const validationSchema = yup.object().shape({
  authCode: yup.string().required(),
})

export const MfaModal: React.FC<IProps> = ({
  onClose,
  onConfirm,
  QRSecretKey,
}) => {
  const {
    requestDone,
    requestFailed,
    requestStarted,
    requesting,
    errorMessage,
  } = useRequestState()
  const { cognitoUser, initCognitoUser } = useCognitoUser()
  const { register, handleSubmit, formState } = useForm<IFormValue>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const { isValid, touchedFields, errors } = formState

  const onSubmit = async (value: IFormValue) => {
    try {
      requestStarted()
      await Auth.verifyTotpToken(cognitoUser, value.authCode)
      await Auth.setPreferredMFA(cognitoUser, 'TOTP')
      await initCognitoUser()
      onConfirm()
    } catch (error) {
      switch (error.code) {
        case 'EnableSoftwareTokenMFAException':
          requestFailed(
            '二次元コードを読み取り後、表示された認証コードを入力下さい。',
          )
          break
        case 'InvalidParameterException':
          requestFailed('適切な形式の認証コードを入力下さい。')
          break
        case 'LimitExceededException':
          requestFailed(
            'アカウントがロックされています。時間を置いて再度お試し下さい。',
          )
          break
        default:
          requestFailed('問題が発生しました。時間を置いて再度お試し下さい。')
      }
    } finally {
      requestDone()
    }
  }

  return (
    <Modal onClose={onClose} size="L">
      <ModalTitle title="2段階認証設定" />
      <ModalContent>
        <Text>
          Google Authenticator などの
          認証コード生成用のモバイルアプリで二次元コードを読み取るか、
          シークレットコードを手動で入力し、表示される認証コードを入力してください。
        </Text>
        <Spacer size={20} />
        <Text fontSize="xs">{`シークレットコード：${QRSecretKey}`}</Text>
        <Spacer size={10} />
        <Text fontSize="xs">{`アカウントID：${cognitoUser.attributes.email}`}</Text>
        <Spacer size={20} />
        <QRCodeBox>
          <QRCode
            value={`otpauth://totp/MiROHA-${cognitoUser.attributes.email}?secret=${QRSecretKey}`}
            size={168}
          />
        </QRCodeBox>
        <Spacer size={20} />

        <Label mb={`6px`}>認証コード</Label>
        <Input type="text" width={1} {...register('authCode')} />
        {errors.authCode && touchedFields.authCode && (
          <div>
            <Spacer size={4} />
            <Message type="error" message="認証コードを入力して下さい" />
          </div>
        )}

        {errorMessage && (
          <div>
            <Spacer size={8} />
            <Message type="error" message={errorMessage} centered />
          </div>
        )}
      </ModalContent>

      <BtnContainer>
        <Button
          size="S"
          text="キャンセル"
          onClick={onClose}
          buttonType="cancel"
        ></Button>
        <Button
          size="S"
          text="認証"
          onClick={handleSubmit(onSubmit)}
          buttonType="important"
          disabled={!isValid || requesting}
        ></Button>
      </BtnContainer>
    </Modal>
  )
}
